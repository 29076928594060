import React from "react";
import Highlighter from "react-highlight-words";
import { thousandsSeparators } from "../../../../config/utils";
import moment from "moment";
import { DYNAMIC_FIELD_TYPES } from "../../constants";

export const DynamicField = ({ rowData, config, keyItem, numberSearchArray, filterHighlighter }) => {
    const fieldObject = rowData?.dynamic_fields;
    const type = config[keyItem]?.type;
    const { thousand_separator = false, prefix = "", suffix = "" } = config[keyItem]?.rules;

    let data = fieldObject?.[keyItem]?.value || "";
    if (data && type === DYNAMIC_FIELD_TYPES.DATE) {
        data = moment(data).format("MM-DD-YYYY");
    }
    if (thousand_separator || type === DYNAMIC_FIELD_TYPES.CURRENCY) {
        data = thousandsSeparators(data);
    }
    if ((prefix || suffix) && data) {
        data = `${prefix}${data}${suffix}`;
    }
    return (
        <>
            {data ? (
                <Highlighter
                    className="text-highlighter"
                    unhighlightClassName="text-unhighlight"
                    searchWords={filterHighlighter(config[keyItem]?.searchKey, keyItem) ? numberSearchArray : []}
                    textToHighlight={data?.toString() ?? "-"}
                    data-tip={data}
                    data-effect="solid"
                    data-place="bottom"
                    data-for={`table-row${rowData.id}`}
                    data-class="rc-tooltip-custom-class"
                />
            ) : (
                "-"
            )}
        </>
    );
};
