import React from "react";
import gridIcon from "../../../../assets/img/grid1.svg";
import listIcon from "../../../../assets/img/grid.svg";
import ReactTooltip from "react-tooltip";

const MasterFilter = ({
    setGridView,
    selectedImages,
    handleSelectAll,
    handleClearAll,
    allSelected,
    exportImagesZip,
    exportImageZipLoader,
    isAsset,
    onEditCaption
}) => {
    const zipExportError = selectedImages?.length > 500 ? "Please select a maximum of 500 images" : "";
    return (
        <div className="nav-fil-top">
            <div className="top-bar sort-otr mb-2 p-0">
                <div className="left-control">
                    {!isAsset && (
                        <div className="btn">
                            <label className="container-check">
                                <input type="checkbox" checked={allSelected} onClick={handleSelectAll} />
                                <span className="checkmark"></span>
                                {`Select All ${selectedImages?.length ? `(${selectedImages.length})` : ""}`}
                            </label>
                        </div>
                    )}
                    {selectedImages.length > 0 && !isAsset && (
                        <>
                            <button className="btn btn-edit" onClick={handleClearAll}>
                                <i className="fas fa-times-circle"></i>
                                Clear All
                            </button>
                            {
                                <button
                                    className={`btn btn-edit ${zipExportError ? "cursor-diabled" : ""}`}
                                    data-tip={zipExportError}
                                    data-place="bottom"
                                    data-effect="solid"
                                    data-background-color="#007bff"
                                    onClick={() => (!zipExportError ? exportImagesZip(selectedImages) : undefined)}
                                >
                                    {!exportImageZipLoader ? (
                                        <i class="fas fa-file-archive"></i>
                                    ) : (
                                        <div className="edit-icn-bx icon-btn-sec d-inline-block mr-1">
                                            <div className="spinner-border" role="status"></div>
                                        </div>
                                    )}
                                    Download <span>{selectedImages?.length} </span> (zip)
                                </button>
                            }
                            <ReactTooltip />

                            <button type="button" className="btn btn-edit" onClick={onEditCaption}>
                                Edit Caption{" "}
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div className="top-bar icon-bar">
                <div className="itm-tp" data-tip={"List View"} onClick={() => setGridView(false)}>
                    <img src={listIcon} alt="" />
                </div>
                <div className="itm-tp" data-tip={"Grid View"} onClick={() => setGridView(true)}>
                    <img src={gridIcon} alt="" />
                </div>
            </div>
            <ReactTooltip id="master-img-filter" effect="solid" backgroundColor="#1383D9" />
        </div>
    );
};

export default MasterFilter;
