export const stringFilterKeys = [
    { value: "contains", label: "Contains" },
    { value: "exclude", label: "Doesn't Contain" },
    { value: "string_equals", label: "Equals" },
    { value: "unequals", label: "Doesn't Equal" },
    { value: "begins_with", label: "Begins With" },
    { value: "ends_with", label: "Ends With" },
    { value: "not_null", label: "Not Null" },
    { value: "null", label: "Null" },
    { value: "count_gt_1", label: "Count > 1" },
    { value: "like", label: "Like (%,_)" }
];
export const dateFilterKeys = [
    { value: "in_between", label: "In between" },
    { value: null, label: null },
    { value: "yesterday", label: "Yesterday" },
    { value: "last_month", label: "Last Month" },
    { value: "today", label: "Today" },
    { value: "this_month", label: "This Month" },
    { value: "tomorrow", label: "Tomorrow" },
    { value: "next_month", label: "Next Month" },
    { value: "last_week", label: "Last Week" },
    { value: "last_year", label: "Last Year" },
    { value: "this_week", label: "This Week" },
    { value: "this_year", label: "This Year" },
    { value: "next_week", label: "Next Week" },
    { value: "next_year", label: "Next Year" }
];
export const numberFilterKeys = config => {
    const { hasTopNFilter = false } = config || {};
    const filters = [
        { value: "equals", label: "Equals" },
        { value: "unequals", label: "Doesn't Equal" },
        { value: "less_than", label: "Less Than" },
        { value: "less_than_or_equal", label: "Less Than or Equal to" },
        { value: "greater_than", label: "Greater Than" },
        { value: "greater_than_or_equal", label: "Greater Than or Equal to" },
        ...(hasTopNFilter ? [{ value: "top_n", label: "Top N" }] : []),
        { value: "not_null", label: "Not Null" },
        { value: "null", label: "Null" },
        { value: "count_gt_1", label: "Count > 1" },
        { value: "like", label: "Like (%,_)" }
    ];
    return filters;
};
