import { CKEditor } from "@ckeditor/ckeditor5-react";
import React from "react";
import ReactTooltip from "react-tooltip";
import { editorConfiguration } from "../../../../utils/ckEditorUtils";
import Editor from "ckeditor5-custom-build/build/ckeditor";

export const TextArea = ({
    placeholder,
    label,
    name,
    value,
    handleChange,
    type,
    showErrorBorder,
    isDisabled = false,
    col = 3,
    dataTip,
    hasExpandView = true,
    handleClickExpandView,
    isRichText
}) => {
    return (
        <div className={`col-md-${col} basic-box`}>
            <ReactTooltip id="input_field" effect="solid" backgroundColor="#007bff" place="top" />
            <div className="codeOtr" data-tip={dataTip} data-for="input_field">
                <div class="expand-window-icon">
                    <h4>{label}</h4>
                    {hasExpandView && (
                        <button class="butn-expansion" data-for="input_field" data-tip="Expand Field" onClick={handleClickExpandView}>
                            <img src="/img/expand1.svg" alt="" />
                        </button>
                    )}
                </div>
                {isRichText ? (
                    <div className={showErrorBorder ? "error-border" : ""}>
                        <CKEditor
                            config={{ ...editorConfiguration, toolbar: [] }}
                            data={value || ""}
                            editor={Editor}
                            id={`${name}-edit`}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                handleChange(data);
                            }}
                            onReady={editor => {
                                editor.setData(value || "");
                            }}
                        />
                    </div>
                ) : (
                    <textarea
                        autoComplete={"off"}
                        className={`${showErrorBorder ? "error-border " : ""} ${isDisabled ? "cursor-diabled" : ""} text-area-control form-control`}
                        value={value}
                        name={name}
                        onChange={handleChange}
                        placeholder={placeholder || label.replace("*", "")}
                        disabled={isDisabled}
                    ></textarea>
                )}
            </div>
        </div>
    );
};
