import React from "react";

export const RecommendationBand = ({
    label,
    bandId,
    handleBandClick,
    MainFields,
    RestFields,
    showBand,
    isDefaultOpen,
    ExtraFields,
    filteredCount
}) => {
    // const bandId = band || label?.toLowerCase()?.split(" ").join("_");
    return (
        <div className={`card`}>
            <div className="card-header" id={"heading" + bandId}>
                <div className="otr-recom-div">
                    <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target={`#${bandId}`}
                        aria-expanded={showBand?.[bandId] ? "true" : "false"}
                        aria-controls={bandId}
                        onClick={event => {
                            event.preventDefault();
                            handleBandClick(bandId, false);
                        }}
                    >
                        {label}
                        {filteredCount > 0 && <span className="badge-outer">{filteredCount}</span>}
                    </button>
                    {MainFields}
                </div>
            </div>
            <div id={bandId} className={showBand?.[bandId] ? "collapse show" : "collapse"} aria-labelledby={"heading" + bandId}>
                <div className="card-body">
                    <div className="outer-rcm mt-1">{RestFields}</div>
                    {ExtraFields}
                </div>
            </div>
        </div>
    );
};
