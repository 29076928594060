export const GET_HELPER_DATA_REQUEST = "GET_HELPER_DATA_REQUEST";
export const GET_HELPER_DATA_SUCCESS = "GET_HELPER_DATA_SUCCESS";
export const GET_HELPER_DATA_FAILURE = "GET_HELPER_DATA_FAILURE";

export const UPLOAD_HELPER_DOC_TO_AWS_REQUEST = "UPLOAD_HELPER_DOC_TO_AWS_REQUEST";
export const UPLOAD_HELPER_DOC_TO_AWS_SUCCESS = "UPLOAD_HELPER_DOC_TO_AWS_SUCCESS";
export const UPLOAD_HELPER_DOC_TO_AWS_FAILURE = "UPLOAD_HELPER_DOC_TO_AWS_FAILURE";

export const UPDATE_HELPER_REQUEST = "UPDATE_HELPER_REQUEST";
export const UPDATE_HELPER_SUCCESS = "UPDATE_HELPER_SUCCESS";
export const UPDATE_HELPER_FAILURE = "UPDATE_HELPER_FAILURE";
