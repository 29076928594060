import Editor from "../../../src/assets/css/ckEditor.css";
const customColorPalette = [
    {
        color: "hsl(4, 90%, 58%)",
        label: "Red"
    },
    {
        color: "hsl(340, 82%, 52%)",
        label: "Pink"
    },
    {
        color: "hsl(291, 64%, 42%)",
        label: "Purple"
    },
    {
        color: "hsl(262, 52%, 47%)",
        label: "Deep Purple"
    },
    {
        color: "hsl(231, 48%, 48%)",
        label: "Indigo"
    },
    {
        color: "hsl(207, 90%, 54%)",
        label: "Blue"
    }
];

// More colors.
// ...
export const CkeditorConfiguration = {
    toolbar: [
        "heading",
        "fontFamily",
        "fontSize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underLine",
        "|",
        "alignment:left",
        "alignment:right",
        "alignment:center",
        "alignment:justify",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        // "highlight",
        // "|",
        "outdent",
        "indent",
        "|",
        "undo",
        "redo",
        "|",
        // "insertTable",
        "imageUpload"
    ],
    removePlugins: ["Title", "ListStyle"],
    alignment: {
        options: ["left", "right", "center", "justify"]
    },
    // highlight: {
    //     options: [
    //         {
    //             model: "yellowMarker",
    //             class: "marker-yellow",
    //             title: "Yellow marker",
    //             color: "var(--ck-highlight-marker-yellow)",
    //             type: "marker"
    //         }
    //         // {
    //         //     model: "limeGreenMarker",
    //         //     class: "marker-limeGreen",
    //         //     title: "Green marker",
    //         //     color: "#003f2d",
    //         //     type: "marker"
    //         // }
    //     ]
    // },
    placeholder: "Type Here...",
    contentsCss: Editor,
    fontFamily: {
        options: [
            "default",
            "Barlow Condensed Medium",
            "Barlow Condensed",
            "Calibre",
            "Calibre (Body)",
            "Calibre Medium",
            "Calibre Semi Bold",
            "Calibri (Body)",
            "Financier Display",
            "Futura Bk BT",
            "Futura Md BT",
            "Poppins Bold",
            "Times New Roman"
        ]
    },
    fontSize: {
        options: ["8px", "10px", "12px", "14px", "16px", "18px", "20px", "24px"]
    },
    fontColor: {
        colors: [
            { color: "#000000", label: "Black" },
            { color: "#ff0000", label: "Red" },
            { color: "#003f2d", label: "Green" },
            { color: "#0000ff", label: "Blue" },
            { color: "#ffff00", label: "Yellow" },
            { color: "#ff00ff", label: "Magenta" },
            { color: "#00ffff", label: "Cyan" },
            { color: "#800000", label: "Maroon" },
            { color: "#008000", label: "Green (Dark)" },
            { color: "#000080", label: "Navy" },
            { color: "#808000", label: "Olive" },
            { color: "#800080", label: "Purple" },
            { color: "#008080", label: "Teal" },
            { color: "#c0c0c0", label: "Silver" },
            { color: "#808080", label: "Gray" }
        ],
        colorPicker: true
    },
    image: {
        toolbar: [
            "imageStyle:block",
            // "imageStyle:inline",
            // "imageStyle:alignLeft",
            // "imageStyle:alignRight",
            "imageStyle:alignBlockLeft",
            "imageStyle:alignBlockRight",
            "|",
            "toggleImageCaption"
        ]
        // insert: {
        //     type: "auto"
        // }
    },
    table: {
        cellProperties: {
            borderColors: customColorPalette,
            backgroundColors: customColorPalette
        }
    },

    heading: {
        options: [
            { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
            { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
            { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
            { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" }
        ]
    }
};
