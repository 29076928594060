import React from "react";
import ReactTooltip from "react-tooltip";

import { getDisplayNameFromKey } from "../utils";

const ViewChartItemForModal = ({
    item,
    chartItemData = null,
    mainEntity,
    currentBand,
    openChartItemConfigModal,
    provided,
    index,
    entity,
    toggleImageListForBandModal,
    openFilterModal,
    isExcelExport = false,
    ...props
}) => {
    return (
        <div className="drag-item">
            <div className="top-sec">
                <div className="icon">
                    <img src="/img/icon-squre.svg" />
                </div>
                <div className="icon-rght-b">
                    {item.isIndividualBuilding ? (
                        <>
                            <ReactTooltip id={`sm-charts-main${entity}`} effect="solid" place="bottom" backgroundColor="#007bff" />
                            <div
                                className="icon-b"
                                data-tip="This chart/table will repeat for every building in scope"
                                data-for={`sm-charts-main${entity}`}
                            >
                                <img src={`/img/sm-charts-all-buildings.svg`} />
                            </div>
                        </>
                    ) : null}
                </div>
                <div class="wrap-icon d-flex">
                    {item.isMemo ? (
                        <>
                            <ReactTooltip id={`view text`} effect="solid" place="bottom" backgroundColor="#007bff" />
                            <div
                                class="edt-icn ey-icn cursor-hand"
                                data-for={`view text`}
                                data-tip="View Text"
                                onClick={() =>
                                    openChartItemConfigModal({
                                        bandName: currentBand,
                                        chartKey: item.isImageBand || item?.isMemo ? item.docKey : item.name,
                                        entity: entity,
                                        itemConfig: item.config,
                                        isImageBand: item.isImageBand,
                                        isMemo: item?.isMemo
                                    })
                                }
                            >
                                <img src="/img/sm-charts-img-eye.svg" />
                            </div>
                        </>
                    ) : null}
                    {item.isImageBand ? (
                        <>
                            <div
                                class="edt-icn ey-icn cursor-hand"
                                onClick={() =>
                                    toggleImageListForBandModal(
                                        {
                                            bandName: currentBand,
                                            chartKey: item.isImageBand ? item.docKey : item.name,
                                            entity: entity,
                                            itemConfig: item.config,
                                            isImageBand: item.isImageBand
                                        },
                                        true
                                    )
                                }
                            >
                                <img src="/img/sm-charts-img-eye.svg" />
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            <div className="cont-sec">
                <div className="icons-sec">
                    <img src={`/img/${item.entityIcon}`} />
                </div>
                <h3>{getDisplayNameFromKey(item.name, item.isUserDoc)}</h3>
            </div>
            {item.hasConfig && entity === "project" && item?.name !== "asset_band" && item.name !== "BBI" ? (
                <div className="fot-sec top-border">
                    <div className="fot-select">
                        {!isExcelExport && item.name !== "Text Band" && item?.name !== "asset_band" && item.name !== "BBI" && (
                            <>
                                <span className="badge">{item?.totalChartCount}</span>
                                <span className="nme">
                                    {item.name === "multi_recommendation" ? "Recommendations" : `Selected ${item.isImageBand ? "Images" : "Charts"}`}
                                </span>
                            </>
                        )}
                    </div>
                    <div className="btn-area">
                        <div className="icon"></div>
                        {item.name !== "multi_recommendation" && item?.name !== "asset_band" && item.name !== "BBI" ? (
                            <button
                                className="btn btn-conf"
                                onClick={() =>
                                    openChartItemConfigModal({
                                        bandName: currentBand,
                                        chartKey: item.isImageBand || item?.isMemo ? item.docKey : item.name,
                                        entity: entity,
                                        itemConfig: item.config,
                                        isImageBand: item.isImageBand,
                                        isMemo: item?.isMemo
                                    })
                                }
                            >
                                View Config <img src="/img/blue_arrow_right.svg" />
                            </button>
                        ) : null}
                    </div>
                </div>
            ) : null}
            <div className="fot-sec top-border">
                <div className="btn-area">
                    <div className="icon"></div>
                    {(item.name === "Recommendation Table" ||
                        item.name === "Recommendation Summary" ||
                        item.name === "Recommendation One Pager" ||
                        item.name?.startsWith("Comparative Analysis Band")) &&
                    item.filter ? (
                        <button className="btn btn-conf" onClick={() => openFilterModal(item?.docKey)}>
                            View Filters <img src="/img/blue_arrow_right.svg" />
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
export default ViewChartItemForModal;
