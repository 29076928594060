export const permissions = {
    // project settings items
    PROJECT_REPORT_PROPERTIES: "project_report_properties",
    PROJECT_REPORT_TEMPLATES: "project_report_templates",
    PROJECT_TRADES: "trades",
    PROJECT_SYSTEMS: "systems",
    PROJECT_SUBSYSTEMS: "sub_systems",
    PROJECT_RECOMMENDATION_TEMPLATE: "project_recommendation_templates",
    GENERAL: "year_limits",
    EFCI_COLOR: "efci_colors",
    SFCI_COLOR: "sfci_colors",
    TERM: "priorities",
    CAPITAL_TYPE: "capital_types",
    FUNDING_SOURCE: "funding_sources",
    RESPONSIBILITY: "responsibility",
    CATEGORIES: "categories",
    DEPARTMENT: "consultancies",
    PROJECT_ASSET_CONDITION: "asset_conditions",
    BUILDING_TYPES: "building_types",
    MISCELLANEOUS: "miscellaneous",
    RECOMMENDATION_PRIORITIES: "recommendation_priority",
    CRITICALITY: "criticalities",
    CAPITAL_REQUEST: "old_capital_request",
    //modules
    SMART_CHARTS: "smart_charts",
    ASSET_IMPORT_HISTORY: "asset_import_history",
    SHIFT_CSP_HISTORY: "shift_csp_history",
    DYNAMIC_FIELDS: "recommendation_dynamic_fields",
    RECOMMENDATION_FIELD_CONFIG: "recommendation_field_config",
    PROJECT_REVIEWER: "project_reviewer",
    CR_RECOMMENDATIONS: "new_capital_request",
    RECOMMENDATIONS: "recommendations",
    INITIATIVES: "initiatives"
};
