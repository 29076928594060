import React, { useState } from "react";
import { DYNAMIC_FIELD_TYPES } from "../../../common/constants";
import NumberFormat from "react-number-format";
import SelectBox from "../../../images/components/SelectBox";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { editorConfiguration } from "../../../../utils/ckEditorUtils";
import { RichEditorPopup } from "../../../common/components/formFields/RichEditorPopup";
import Portal from "../../../common/components/Portal";
export const FormField = ({
    fieldType = "text",
    label,
    fieldKey,
    fieldParam,
    value: data,
    onChange,
    bandShown,
    bandId,
    handleBandClick,
    fieldItem,
    showErrorBorder,
    multiselectClass,
    isFieldLocked,
    isLoading,
    hasEmptyOption = true,
    isMaintenanceYear,
    placeholder,
    isCapitalType,
    tooltip = "",
    isPriority,
    name = "",
    isAsset,
    disabledMessage = {},
    hasRemove = false,
    onRemove,
    isManufacturer = false,
    handleInputChange,
    isLoadingDrop = false,
    manufacturerCount = null,
    isCreatable = false,
    createOption
}) => {
    const [showRichTextPopup, setShowRichTextPopup] = useState({ value: "", label: "", key: "", onSaveData: () => {}, show: false });

    const toggleShowRichTextPopup = value => setShowRichTextPopup(value);

    const renderRichTextPopup = () => {
        const { label, key, onSaveData, show, value } = showRichTextPopup;
        if (!show) return null;
        return (
            <Portal
                body={
                    <RichEditorPopup
                        value={value}
                        label={label}
                        key={key}
                        onCancel={() => toggleShowRichTextPopup({ ...showRichTextPopup, show: false })}
                        saveData={onSaveData}
                    />
                }
                onCancel={() => toggleShowRichTextPopup({ ...showRichTextPopup, show: false })}
            />
        );
    };

    const {
        mandatory = false,
        prefix = "",
        suffix = "",
        thousand_separator = false,
        read_only = false,
        decimal_scale = 0,
        format = null,
        allow_negative = true
    } = fieldItem?.rules || {};
    const isLocked = isFieldLocked && isFieldLocked(fieldKey);
    const value = isMaintenanceYear || isPriority || isAsset ? data : data[fieldParam] ?? "";
    const isError = showErrorBorder && mandatory && (!value || (value && !value?.toString()?.trim()?.length));
    return (
        <div className={`txt-rcm ${fieldItem.className ? fieldItem.className : ""}`} key={fieldKey}>
            {renderRichTextPopup()}
            <ReactTooltip id="form-field" effect="solid" place="top" backgroundColor="#007bff" className="rc-tooltip-custom-class" html={true} />
            <div className="content-inp-card position-relative" {...disabledMessage}>
                <div
                    className={`form-group  ${fieldType === DYNAMIC_FIELD_TYPES.MEMO ? "ckeditor-band recom-ck-band " : ""}`}
                    data-target={bandShown?.[bandId] ? `#${bandId}` : "false"}
                    aria-expanded={bandShown?.[bandId] ? true : "false"}
                    onClick={() => handleBandClick(bandId, true)}
                    data-tip={isLocked ? "This field is locked and cannot be edited" : tooltip}
                    data-for="recommendation_details"
                >
                    <label>
                        {`${label} ${mandatory ? "*" : ""}`}
                        {fieldType === DYNAMIC_FIELD_TYPES.MEMO && (
                            <button
                                class="butn-expansion"
                                data-for="input_field"
                                data-tip="Expand Field"
                                onClick={() =>
                                    toggleShowRichTextPopup({
                                        value: value,
                                        label: label,
                                        key: fieldKey,
                                        onSaveData: value => {
                                            onChange(value);
                                            toggleShowRichTextPopup({ ...showRichTextPopup, value, show: false });
                                        },
                                        show: true
                                    })
                                }
                            >
                                <img src="/img/expand1.svg" alt="" />
                            </button>
                        )}
                    </label>
                    {fieldType === DYNAMIC_FIELD_TYPES.TEXT ? (
                        <input
                            type="text"
                            autoComplete={"off"}
                            className={`custom-input form-control ${read_only ? "cursor-diabled" : ""} ${isError ? "error-border" : ""} ${
                                isLocked ? "grayed-out" : ""
                            } ${multiselectClass && multiselectClass(fieldParam)}`}
                            placeholder={label}
                            value={value}
                            disabled={read_only || isLocked}
                            name={name || fieldParam}
                            onChange={onChange}
                        />
                    ) : fieldType === DYNAMIC_FIELD_TYPES.MEMO ? (
                        <div
                            className={`form-control ${read_only ? "cursor-diabled" : ""} ${isError ? "error-border" : ""} ${
                                multiselectClass ? multiselectClass(fieldParam, null, "dynamic_field") : ""
                            } ${isLocked ? "grayed-out" : ""}`}
                        >
                            <CKEditor
                                editor={Editor}
                                config={{ ...editorConfiguration, toolbar: [] }}
                                id="report-note-template"
                                data={value ?? ""}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    onChange(data);
                                }}
                                onReady={editor => {
                                    if (editor) {
                                        editor.setData(value || "");
                                    }
                                }}
                            />
                        </div>
                    ) : fieldType === DYNAMIC_FIELD_TYPES.SELECT ? (
                        <div className={`sel-recom-react ${multiselectClass && multiselectClass(fieldParam)}`}>
                            <SelectBox
                                className={`selectOtr not-draggable build-select-otr ${isLocked ? "grayed-out" : ""}`}
                                optionsList={fieldItem?.field_options ?? []}
                                name={name || fieldParam}
                                disabled={read_only || isLocked}
                                value={value ?? ""}
                                handleChange={onChange}
                                showErrorBorder={isError}
                                isBuilding={label === "Building"}
                                isRecommendation
                                isCapitalType={isCapitalType}
                                hasEmptySelect={hasEmptyOption}
                                isClearable={false}
                                isLoading={isLoading}
                                isManufacturer={isManufacturer}
                                handleInputChange={handleInputChange}
                                isAsset={isAsset}
                                isLoadingDrop={isLoadingDrop}
                                manufacturerCount={manufacturerCount}
                                isCreatable={isCreatable}
                                createOption={createOption}
                            />
                        </div>
                    ) : fieldType === DYNAMIC_FIELD_TYPES.NUMBER ? (
                        <NumberFormat
                            autoComplete={"off"}
                            className={`form-control ${read_only ? "cursor-diabled" : ""} ${isError ? "error-border" : ""} ${
                                isLocked ? "grayed-out" : ""
                            } ${multiselectClass && multiselectClass(fieldParam)}`}
                            placeholder={placeholder || label}
                            thousandSeparator={thousand_separator}
                            prefix={prefix}
                            suffix={suffix}
                            decimalScale={decimal_scale}
                            value={value ?? ""}
                            format={format}
                            disabled={isLocked || read_only}
                            displayType={"input"}
                            allowNegative={allow_negative}
                            onValueChange={values => (onChange ? onChange(values) : undefined)}
                        />
                    ) : fieldType === DYNAMIC_FIELD_TYPES.DATE ? (
                        <DatePicker
                            autoComplete={"nope"}
                            placeholderText="MM/DD/YYYY"
                            className={`custom-input form-control ${multiselectClass && multiselectClass(fieldParam)}`}
                            selected={value}
                            onChange={onChange}
                        />
                    ) : null}
                    {isMaintenanceYear && hasRemove && (
                        <button className="btn-remove-rec btn-abs-top" data-tip={"Remove Year"} data-for="form-field" onClick={() => onRemove()}>
                            <i className="far fa-trash-alt" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
