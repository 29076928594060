import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reorderArray } from "../../../config/utils";
import actions from "../actions";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import Portal from "../../common/components/Portal";
import _ from "lodash";
import qs from "query-string";
import NumberFormat from "react-number-format";
import Dropzone from "react-dropzone";
import ReactTooltip from "react-tooltip";
import ReactSelect from "react-select";
import smartChartActions from "../../smartCharts/actions";
import buildingActions from "../../building/actions";
import OuterDraggable from "react-draggable";
import { downloadPresignedUrlFile } from "../../common/actions";
import { RECOM_FIRST_LEVEL_GROUPING } from "../../smartCharts/constants";
import SelectBox from "../../images/components/SelectBox";

const initialState = {
    isLoading: true,
    viewFilter: "all",
    keyList: [],
    searchKey: "",
    alertMessage: "",
    initialValues: {},
    showConfirmationModal: false,
    newFile: {},
    download_file: "",
    exportProperties: [],
    recom_property: "",
    initial_recom_property: "",
    exportTemplates: [],
    selectedFirstLevel: "",
    secondLevelGrouping: [...RECOM_FIRST_LEVEL_GROUPING],
    selectedSecondLevel: "",
    recom_template: "",
    settings_id: "",
    showErrorBorder: false,
    initial_recom_template: "",
    updateLoading: false,
    transform: { left: 0, top: 0 }
};
class ViewExportModal extends Component {
    state = initialState;
    componentDidMount = async () => {
        const {
            location: { search },
            projectIdForSmartChart = "",
            clientIdForSmartChart = "",
            isSmartChartView = false,
            isBuildingView = false,
            isAssetExportSettingsview = false
        } = this.props;
        ReactTooltip.rebuild();

        const query = qs.parse(search);
        await this.setState({
            isLoading: false
        });
        let exportProperties = {};
        let exportTemplates = {};
        let exportSettingData = {};
        let exportFirstLevelData = "";
        let exportSecondLevelData = "";
        let selectedRecomTemplate = null;
        if (isSmartChartView || isAssetExportSettingsview) {
            let selectedSettingsId = this.props?.smartChartTileConfig?.band1?.settings_id || null;
            await this.props.getExportTemplateListForSmartChartRecom({ project_id: projectIdForSmartChart, client_id: clientIdForSmartChart });
            await this.props.getExportPropertyListForSmartChartRecom({ project_id: projectIdForSmartChart, client_id: clientIdForSmartChart });
            if (isAssetExportSettingsview) {
                let selectedSettingsId = this.props?.smartChartTileConfig?.settings_id || null;
                await this.props?.getSmartchartAssetExportSettings({
                    project_id: projectIdForSmartChart,
                    client_id: clientIdForSmartChart,
                    settings_id: selectedSettingsId || this.props?.settingsId
                });
                const { AssetExportSettingsData } = this.props.smartChartReducer;
                exportSettingData = AssetExportSettingsData?.data ?? {};
            } else {
                await this.props.getExportSettingsForSmartChartRecom({
                    project_id: projectIdForSmartChart,
                    settings_id: selectedSettingsId || this.props?.settingsId,
                    client_id: clientIdForSmartChart
                });
                exportSettingData = this.props.smartChartReducer?.exportSettingsData?.data || {};
            }
            exportProperties = this.props.smartChartReducer?.exportPropertyList || "";
            exportTemplates = this.props.smartChartReducer?.exportTemplateList || "";
            if (this.props?.grouping) {
                const selectedFirst = RECOM_FIRST_LEVEL_GROUPING?.find(
                    option =>
                        option.value ===
                        ((this.props?.smartChartTileConfig?.band1?.grouping || this.props?.groupingValue)?.split("_")?.[0] ||
                            this.props?.smartChartTileConfig?.band1?.grouping)
                )?.value;
                const selectedSecond = RECOM_FIRST_LEVEL_GROUPING?.find(
                    option => option.value === (this.props?.smartChartTileConfig?.band1?.grouping || this.props?.groupingValue)?.split("_")?.[1]
                )?.value;
                exportFirstLevelData = selectedFirst || "";
                exportSecondLevelData = selectedSecond || "";
            }

            let master_templates = [];
            let local_templates = [];
            if (exportTemplates?.without_client?.length) {
                exportTemplates.without_client.forEach(elem => master_templates.push({ value: elem.id, label: elem.name }));
            }
            if (exportTemplates?.with_client?.length) {
                exportTemplates.with_client.forEach(elem => local_templates.push({ value: elem.id, label: elem.name }));
            }
            selectedRecomTemplate = [...master_templates, ...local_templates].find(elem => elem?.value === exportSettingData?.template);
            exportTemplates = [
                {
                    label: "From Local",
                    options: local_templates
                },
                {
                    label: "From Master",
                    options: master_templates
                }
            ];
        } else if (isBuildingView) {
            const { clientId: client_id } = this.props;
            await this.props.getExportPropertyListForSmartChartRecom({ project_id: query.pid, client_id });
            exportProperties = this.props.smartChartReducer?.exportPropertyList || {};
            await this.props.getExportTemplateListForBuildings({ project_id: query.pid, building_id: this.props.match.params.id, client_id });
            const { getExportPropertyResponse } = this.props.buildingReducer;
            exportSettingData = getExportPropertyResponse?.data ?? {};
        } else {
            await this.props.getExportPropertyDropdown({ project_id: query.pid || this.props.match.params.id });
            exportProperties = this.props.recommendationsReducer?.exportPropertyList || {};
            await this.props.getExportRecom({ project_id: query.pid || this.props.match.params.id || projectIdForSmartChart });
            exportSettingData = this.props.recommendationsReducer?.getExportItems?.data || {};
        }

        const { properties = [], s3_file, recom_property, template_property, id } = exportSettingData;
        let master_props = [];
        let local_props = [];
        if (exportProperties?.without_client?.length) {
            exportProperties.without_client.forEach(elem => master_props.push({ value: elem.id, label: elem.name }));
        }
        if (exportProperties?.with_client?.length) {
            exportProperties.with_client.forEach(elem => local_props.push({ value: elem.id, label: elem.name }));
        }
        const propertyToFind = isAssetExportSettingsview ? template_property : recom_property;
        let selectedRecomProperty = [...master_props, ...local_props].find(elem => elem?.value === propertyToFind);
        exportProperties = [
            {
                label: "From Local",
                options: local_props
            },
            {
                label: "From Master",
                options: master_props
            }
        ];
        const selectedIndex = RECOM_FIRST_LEVEL_GROUPING.findIndex(option => option.value === exportFirstLevelData);
        this.setState({
            keyList: properties,
            download_file: s3_file,
            initialValues: _.cloneDeep(properties),
            recom_property: selectedRecomProperty || "",
            initial_recom_property: selectedRecomProperty || "",
            exportProperties,
            exportTemplates: isSmartChartView || isAssetExportSettingsview ? exportTemplates : [],
            settings_id: id,
            recom_template: selectedRecomTemplate || "",
            initial_recom_template: selectedRecomTemplate || "",
            selectedFirstLevel: exportFirstLevelData,
            selectedSecondLevel: exportSecondLevelData,
            secondLevelGrouping: RECOM_FIRST_LEVEL_GROUPING?.filter(key => key?.value !== exportFirstLevelData)
        });
    };

    handleDropdownChange = (event = null) => {
        this.setState({
            viewFilter: event.target.value
        });
    };

    isAllSelected = () => {
        const { keyList } = this.state;
        let count = 0;
        keyList?.forEach(keyItem => {
            if (keyItem["is_active"]) {
                count++;
            }
        });
        if (keyList.length === count) {
            return true;
        }
        return false;
    };

    handleWidth = async (e, keyItem) => {
        const newArray = this.state.keyList;
        const newKeyItem = newArray.findIndex(item => item.heading === keyItem.heading);
        newArray[newKeyItem] = { ...keyItem, width: Number(e) };
        await this.setState({
            keyList: newArray
        });
    };

    handleHideColumn = async keyItem => {
        if (keyItem !== "selectAll" && keyItem !== "deselectAll") {
            const newArray = this.state.keyList;
            const newKeyItem = newArray.findIndex(item => item.heading === keyItem.heading);
            newArray[newKeyItem] = { ...keyItem, is_active: !keyItem.is_active };
            await this.setState({
                keyList: newArray
            });
        } else {
            this.setState({
                keyList:
                    keyItem === "selectAll"
                        ? this.state.keyList.map(item => ({ ...item, is_active: true }))
                        : this.state.keyList.map(item => ({ ...item, is_active: false }))
            });
        }
        return true;
    };

    handleColumn = async keyItem => {
        await this.handleHideColumn(keyItem);
    };

    onEnd = result => {
        if (!result.destination) {
            return;
        }
        const keyList = reorderArray(this.state.keyList, result.source.index, result.destination.index);
        this.setState({
            keyList
        });
    };

    handleSave = async () => {
        const {
            location: { search },
            isSmartChartView = false,
            isBuildingView = false,
            isAssetExportSettingsview = false,
            clientIdForSmartChart
        } = this.props;
        const query = qs.parse(search);
        const { keyList, newFile, recom_property, recom_template, settings_id, selectedFirstLevel, selectedSecondLevel } = this.state;
        let data = {};
        if (isSmartChartView || isBuildingView || isAssetExportSettingsview) {
            if (this.validate()) {
                data.recom_property = recom_property?.value || "";
                if (!isBuildingView) data.template = recom_template?.value || "";
                if (isAssetExportSettingsview) {
                    data.template_property = recom_property?.value || "";
                    data.clientId = clientIdForSmartChart || "";
                }

                data.settings_id = settings_id;
                data.properties = keyList;
                this.setState({ updateLoading: true });
                let status, message;
                if (!isAssetExportSettingsview) {
                    await this.props.saveExportSettingsForSmartChartRecom(data);
                    this.setState({ updateLoading: false });
                    ({ status, message } = this.props.smartChartReducer.saveExportSettingsData);
                } else {
                    await this.props.saveExportSettingsForSmartChartAsset(data);
                    this.setState({ updateLoading: false });
                    ({ status, message } = this.props.smartChartReducer.saveAssetExportSettingsData);
                }
                if (status) {
                    let grouping = "";
                    if (this.props?.grouping) {
                        grouping = selectedFirstLevel ? `${selectedFirstLevel}${selectedSecondLevel ? `_${selectedSecondLevel}` : ""}` : "";
                    }
                    await this.setState({ alertMessage: message, initialValues: keyList }, () => this.showLongAlert());
                    if (!isBuildingView) this.props.setNormalRecomData(settings_id, this.props?.grouping ? grouping : "", this.props?.grouping);
                    this.props.onCancel();
                }
            }
        } else {
            data = {
                project_id: query.pid || this.props.match.params.id,
                properties: keyList,
                recom_property: recom_property?.value || ""
            };
            if (newFile?.path) {
                data.s3_file = newFile;
            }
            await this.props.postExportRecom(data);
            const { status, message } = this.props.recommendationsReducer.AddExportItems;
            if (status) {
                await this.setState({ alertMessage: message, initialValues: keyList }, () => this.showLongAlert());
                this.props.onCancel();
            }
        }
    };

    handleDownloadWord = () => {
        const { download_file } = this.state;
        if (download_file) {
            downloadPresignedUrlFile(download_file);
        }
    };

    showLongAlert = () => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show-long-alert";
            x.innerText = this.state.alertMessage;
            setTimeout(function () {
                x.className = x.className.replace("show-long-alert", "");
            }, 6000);
        }
    };

    confirmCancel = () => {
        const { initialValues, keyList, newFile, initial_recom_property, recom_property, initial_recom_template, recom_template } = this.state;
        if (
            _.isEqual(initialValues, keyList) &&
            _.isEqual(initial_recom_property, recom_property) &&
            !newFile?.path &&
            _.isEqual(initial_recom_template, recom_template)
        ) {
            this.props.onCancel();
        } else {
            this.setState({
                showConfirmationModal: true
            });
        }
    };
    renderConfirmationModal = () => {
        const { showConfirmationModal } = this.state;
        if (!showConfirmationModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={"Do you want to clear and lose all changes?"}
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => this.setState({ showConfirmationModal: false })}
                        onYes={() => {
                            this.setState(initialState);
                            this.props.onCancel();
                        }}
                        cancel={() => this.setState({ showConfirmationModal: false })}
                    />
                }
                onCancel={() => this.setState({ showConfirmationModal: false })}
            />
        );
    };

    validate = () => {
        const { recom_property, recom_template } = this.state;
        const { isBuildingView = false, isAssetExportSettingsview = false } = this.props;
        let showErrorBorder = false;
        if (!isAssetExportSettingsview) {
            if (!recom_property?.value?.trim()?.length) {
                showErrorBorder = true;
            } else if (!recom_template?.value?.trim()?.length) {
                if (!isBuildingView) showErrorBorder = true;
            }
        }
        this.setState({ showErrorBorder });
        if (showErrorBorder) return false;
        return true;
    };

    handleSelectExportProperty = async value => {
        const {
            isSmartChartView,
            projectIdForSmartChart,
            location: { search },
            isBuildingView,
            isAssetExportSettingsview = false,
            clientIdForSmartChart,
            selectedSettingsId
        } = this.props;

        this.setState({ recom_property: value });
        if (isSmartChartView) {
            await this.props.getExportSettingsForSmartChartRecom({
                project_id: projectIdForSmartChart,
                recom_property_id: value?.value || null
            });
            let exportSettingData = this.props.smartChartReducer?.exportSettingsData || {};
            const { properties = [] } = exportSettingData;
            this.setState({
                keyList: properties,
                initialValues: _.cloneDeep(properties)
            });
        }
        if (!isSmartChartView && !isBuildingView) {
            const query = qs.parse(search);
            let exportSettingData = {};
            if (isAssetExportSettingsview) {
                await this.props?.getSmartchartAssetExportSettings({
                    project_id: query.pid || this.props.match.params.id || projectIdForSmartChart,
                    client_id: clientIdForSmartChart,
                    // settings_id: selectedSettingsId || this.props?.settingsId,
                    asset_property_id: value?.value
                });
                const { AssetExportSettingsData } = this.props.smartChartReducer;
                exportSettingData = AssetExportSettingsData ?? {};
            } else {
                await this.props.getExportRecom({
                    project_id: query.pid || this.props.match.params.id || projectIdForSmartChart,
                    property_id: value?.value
                });
                exportSettingData = this.props.recommendationsReducer.getExportItems.data || {};
            }

            const { properties = [] } = exportSettingData;
            this.setState({
                keyList: properties,
                initialValues: _.cloneDeep(properties)
            });
        }
    };
    handleDrag = (e, ui) => {
        const { x, y } = ui;
        this.setState({
            transform: {
                left: x,
                top: y
            }
        });
        this.props?.isDraggable && this.props?.isDraggable(false);
    };
    handleFirstLevelChange = e => {
        this.setState({
            selectedFirstLevel: e,
            selectedSecondLevel: "",
            secondLevelGrouping: RECOM_FIRST_LEVEL_GROUPING?.filter(key => key?.value !== e)
        });
    };

    render() {
        const {
            keyList,
            viewFilter,
            download_file,
            newFile,
            exportProperties,
            recom_property,
            exportTemplates,
            recom_template,
            showErrorBorder,
            updateLoading
        } = this.state;
        const { isSmartChartView = false, isBuildingView = false, isAssetExportSettingsview = false } = this.props;

        let KeyLists =
            viewFilter === "all"
                ? keyList
                : viewFilter === "visible"
                ? keyList.filter(item => item.is_active === true)
                : viewFilter === "notVisible"
                ? keyList.filter(item => item.is_active === false)
                : keyList;
        let file_name_recommendation = download_file?.split("/");
        file_name_recommendation = file_name_recommendation ? file_name_recommendation[file_name_recommendation?.length - 1] : "";

        return (
            <React.Fragment>
                <div className="modal modal-region modal-view reco-view-mdl" id="modalId" style={{ display: "block" }} tabIndex="-1">
                    {this.renderConfirmationModal()}
                    <div className="modal-dialog" role="document">
                        <OuterDraggable
                            handle=".draggable"
                            cancel=".not-draggable"
                            onDrag={this.handleDrag}
                            onStart={this.props?.isDraggable && this.props?.isDraggable(true)}
                        >
                            <div className="modal-content outerModal_draggable" style={this.state.transform}>
                                <div className="modal-header draggable">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        <div className="txt-hed">Export Settings</div>
                                        <div className="selct-otr">
                                            <select
                                                className="form-control"
                                                onChange={e => this.handleDropdownChange(e)}
                                                value={this.state.viewFilter}
                                            >
                                                <option value="all">All</option>
                                                <option value="visible">Visible</option>
                                                <option value="notVisible">Not Visible</option>
                                            </select>
                                        </div>
                                    </h5>
                                    <button type="button" className="close" onClick={this.confirmCancel}>
                                        <span aria-hidden="true">
                                            <img src="/img/close.svg" alt="" />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body region-otr draggable">
                                    <DragDropContext onDragEnd={this.onEnd}>
                                        <Droppable droppableId="droppable">
                                            {provided => (
                                                <div ref={provided.innerRef} className="col-md-12 check-otr d-flex checkbox-sec not-draggable">
                                                    <div className="col-md-12 p-0 d-flex">
                                                        {viewFilter === "all" ? (
                                                            <div className="col-md-4 box-otr">
                                                                <div className="rem-txt">
                                                                    <label className="container-check">
                                                                        Select All
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={this.isAllSelected()}
                                                                            onChange={() =>
                                                                                this.handleColumn(this.isAllSelected() ? "deselectAll" : "selectAll")
                                                                            }
                                                                        />
                                                                        <span className="checkmark" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                        <div className="col-md-8 formInp search pl-0">
                                                            <i className="fas fa-search" />
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                onChange={async e => {
                                                                    await this.setState({
                                                                        searchKey: e.target.value
                                                                    });
                                                                }}
                                                                value={this.state.searchKey}
                                                                placeholder="Search Columns"
                                                            />
                                                        </div>
                                                    </div>
                                                    {!(isSmartChartView || isBuildingView || isAssetExportSettingsview) ? (
                                                        <Dropzone
                                                            accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                                                            onDrop={acceptedFiles => {
                                                                this.setState({ newFile: acceptedFiles[0] });
                                                            }}
                                                            multiple={false}
                                                        >
                                                            {({ getRootProps, getInputProps, isDragActive }) => (
                                                                <section class="drag-otr col-md-12" {...getRootProps()} style={{ cursor: "pointer" }}>
                                                                    {/* <div> */}
                                                                    <img src="/img/download-icon-upload.svg" alt="" />
                                                                    <div>
                                                                        <input {...getInputProps()} />
                                                                        <h3 class="drag-txt">{`Drag and drop file here or click to ${
                                                                            file_name_recommendation ? "change" : "select"
                                                                        } file`}</h3>
                                                                        <p class="doc-name">
                                                                            {newFile?.path || file_name_recommendation || "Supports Docx"}
                                                                        </p>
                                                                    </div>
                                                                    {/* </div> */}
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                    ) : null}

                                                    {isSmartChartView || isAssetExportSettingsview ? (
                                                        <div className="col-md-12 box-otr box-wide">
                                                            <div className="">
                                                                <label className="container-check pl-0 mb-0">Export Template</label>
                                                            </div>
                                                            <div className="export-property-select-box">
                                                                <ReactSelect
                                                                    options={exportTemplates}
                                                                    value={recom_template}
                                                                    isClearable
                                                                    classNamePrefix="react-select"
                                                                    onChange={value => this.setState({ recom_template: value })}
                                                                    isSearchable={false}
                                                                    className={
                                                                        (isSmartChartView || isAssetExportSettingsview) &&
                                                                        showErrorBorder &&
                                                                        !recom_template?.value?.trim().length
                                                                            ? "error-border "
                                                                            : ""
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : null}

                                                    <div className="col-md-12 box-otr box-wide">
                                                        <div className="">
                                                            <label className="container-check pl-0 mb-0">Export Property</label>
                                                        </div>
                                                        <div className="export-property-select-box">
                                                            {/* <select
                                                            autoComplete="off"
                                                            name=""
                                                            className={`custom-selecbox form-control`}
                                                            onChange={e => this.setState({ recom_property: e.target.value })}
                                                            value={recom_property}
                                                        >
                                                            <option value="">Select</option>
                                                            {exportProperties.map(item => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </select> */}

                                                            <ReactSelect
                                                                options={exportProperties}
                                                                value={recom_property}
                                                                isClearable
                                                                classNamePrefix="react-select"
                                                                onChange={value => this.handleSelectExportProperty(value)}
                                                                isSearchable={false}
                                                                className={
                                                                    (isSmartChartView || isBuildingView || isAssetExportSettingsview) &&
                                                                    showErrorBorder &&
                                                                    !recom_property?.value?.trim().length
                                                                        ? "error-border "
                                                                        : ""
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.props?.grouping && (
                                                        <div className="d-flex col-md-12 pl-0 pr-3">
                                                            <div className="otr-form p-0 d-flex mt-3 col-md-6 mr-3">
                                                                <div className="selct-otr col-12 p-0">
                                                                    <SelectBox
                                                                        label="First Level Grouping"
                                                                        value={this.state.selectedFirstLevel}
                                                                        handleChange={e => this.handleFirstLevelChange(e)}
                                                                        optionsList={RECOM_FIRST_LEVEL_GROUPING}
                                                                        isGrouping
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="otr-form p-0 d-flex mt-3 col-md-6 mr-3">
                                                                <div className="selct-otr col-12 p-0">
                                                                    <SelectBox
                                                                        label="Second Level Grouping"
                                                                        value={this.state.selectedSecondLevel}
                                                                        handleChange={e => {
                                                                            this.setState({ selectedSecondLevel: e });
                                                                        }}
                                                                        optionsList={this.state?.secondLevelGrouping}
                                                                        disabled={
                                                                            !this.state.selectedFirstLevel || !this.state?.secondLevelGrouping?.length
                                                                        }
                                                                        isGrouping
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {KeyLists?.filter(keyItem => {
                                                        let isTrue = true;
                                                        if (this.state.searchKey) {
                                                            isTrue = keyItem["heading"]
                                                                ?.toString()
                                                                ?.toLowerCase()
                                                                ?.includes(this.state.searchKey?.toLowerCase());
                                                        }
                                                        if (isTrue && this.state.viewFilter === "visible") {
                                                            isTrue = keyItem["is_active"] === true;
                                                        } else if (isTrue && this.state.viewFilter === "notVisible") {
                                                            isTrue = keyItem["is_active"] === false;
                                                        }
                                                        return isTrue;
                                                    }).map((keyItem, i) => (
                                                        <>
                                                            {keyItem["heading"] === "Sl No" ? (
                                                                <div className="col-md-12 box-otr box-wide ">
                                                                    <div className="rem-txt">
                                                                        <label className="container-check">
                                                                            {keyItem["heading"]}

                                                                            <input
                                                                                type="checkbox"
                                                                                checked={true}
                                                                                disabled={true}
                                                                                onChange={() => this.handleColumn(keyItem)}
                                                                            />
                                                                            <span className="checkmark cursor-diabled" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="right-box-content">
                                                                        <div class="input-box">
                                                                            <NumberFormat
                                                                                autoComplete={"nope"}
                                                                                className="custom-input form-control"
                                                                                value={keyItem["width"]}
                                                                                suffix={" inch"}
                                                                                thousandSeparator={true}
                                                                                onValueChange={values => {
                                                                                    const { value } = values;
                                                                                    this.handleWidth(value, keyItem);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="image-drag">
                                                                            <img src="/img/Group 2.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <Draggable key={i} draggableId={`draggable-${i}`} index={i}>
                                                                    {provided => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            key={`draggable-${i}`}
                                                                            className="col-md-12 box-otr box-wide "
                                                                        >
                                                                            <div className="rem-txt">
                                                                                <label className="container-check">
                                                                                    {keyItem["heading"]}

                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={keyItem["is_active"]}
                                                                                        onChange={() => this.handleColumn(keyItem)}
                                                                                    />
                                                                                    <span className="checkmark" />
                                                                                </label>
                                                                            </div>
                                                                            <div className="right-box-content">
                                                                                <div class="input-box">
                                                                                    <NumberFormat
                                                                                        autoComplete={"nope"}
                                                                                        className="custom-input form-control"
                                                                                        value={keyItem["width"]}
                                                                                        suffix={" inch"}
                                                                                        thousandSeparator={true}
                                                                                        onValueChange={values => {
                                                                                            const { value } = values;
                                                                                            this.handleWidth(value, keyItem);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="image-drag">
                                                                                    <img src="/img/Group 2.svg" alt="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )}
                                                        </>
                                                    ))}
                                                    {!KeyLists?.length ? <div className="col-md-12 text-center mt-5">No records found!</div> : ""}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <div class="col-md-12 p-0 mt-4 text-right btnOtr d-flex col-md-12 not-draggable">
                                        {download_file?.length ? (
                                            <div className="col-md-2 p-0 text-center">
                                                <button
                                                    onClick={this.handleDownloadWord}
                                                    className="btn-download"
                                                    data-place="top"
                                                    data-tip={"Download Uploaded Template"}
                                                    data-for="export"
                                                >
                                                    <img src="/img/file-download.svg" alt="" />
                                                    <ReactTooltip id="export" effect="solid" />
                                                </button>
                                            </div>
                                        ) : null}
                                        <div className="tot-btr">
                                            <button
                                                type="button"
                                                onClick={this.confirmCancel}
                                                className="btn btn-secondary btnClr col-md-3 mr-1"
                                                data-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button type="button" onClick={this.handleSave} className="btn btn-primary btnRgion btn-wid">
                                                Save
                                                {updateLoading ? (
                                                    <span className="spinner-border spinner-border-sm pl-2 ml-2" role="status"></span>
                                                ) : (
                                                    ""
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </OuterDraggable>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { recommendationsReducer, smartChartReducer, buildingReducer } = state;
    return { recommendationsReducer, smartChartReducer, buildingReducer };
};
const {
    getExportPropertyListForSmartChartRecom,
    getExportTemplateListForSmartChartRecom,
    getExportSettingsForSmartChartRecom,
    saveExportSettingsForSmartChartRecom,
    getSmartchartAssetExportSettings,
    getExportPropertyListForSmartChartAsset,
    getExportTemplateListForSmartChartAsset,
    saveExportSettingsForSmartChartAsset
} = smartChartActions;

const { getExportTemplateListForBuildings } = buildingActions;

export default withRouter(
    connect(mapStateToProps, {
        ...actions,
        getExportPropertyListForSmartChartRecom,
        getExportTemplateListForSmartChartRecom,
        getExportSettingsForSmartChartRecom,
        saveExportSettingsForSmartChartRecom,
        getExportTemplateListForBuildings,
        getSmartchartAssetExportSettings,
        getExportPropertyListForSmartChartAsset,
        getExportTemplateListForSmartChartAsset,
        saveExportSettingsForSmartChartAsset
    })(ViewExportModal)
);
