import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import actions from "../../dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

const BbiConfigModal = ({
    onCancel,
    heading,
    onSave,
    trade_ids,
    getListParams,
    btnName = "Save",
    isLoading = false,
    simpleNarrativeTextValue = true
}) => {
    const [tradeValues, setTradeValues] = useState([]);
    const [selectedTrades, setSelectedTrades] = useState([]);
    const [simpleNarrativeText, setSimpleNarrativeText] = useState(true);
    const dispatch = useDispatch();
    const { masterFilterList, filterFields } = useSelector(state => state.dashboardReducer);
    useEffect(() => {
        const filterDetail = filterFields?.find(({ paramKey }) => paramKey === "trade_ids");
        const { key, searchKey, getListKey, isBoolean } = filterDetail;
        let params = {
            searchKey,
            field: isBoolean ? ["Yes", "No"] : getListKey,
            project_ids: getListParams?.project_ids?.[0] || getListParams?.project_id,
            client_ids: getListParams?.client_ids?.[0] || getListParams?.client_id
        };
        dispatch(actions.getMasterFilter(params, key, true));
    }, []);
    useEffect(() => {
        if (trade_ids) {
            setSelectedTrades(_.uniq([...selectedTrades, ...trade_ids]));
            setSimpleNarrativeText(simpleNarrativeTextValue);
        }
    }, [trade_ids]);
    useEffect(() => {
        if (masterFilterList?.trades) {
            setTradeValues(masterFilterList?.trades);
            if (!trade_ids) {
                setSelectedTrades(_.uniq([...selectedTrades, ...masterFilterList?.trades?.map(e => e.id)]));
            }
        }
    }, [masterFilterList?.trades]);
    const handleCheckboxClick = id => {
        setSelectedTrades(prevSelectedTrades => {
            if (prevSelectedTrades.includes(id)) {
                return prevSelectedTrades.filter(tradeId => tradeId !== id);
            } else {
                return [...prevSelectedTrades, id];
            }
        });
    };
    const handleSave = () => {
        onSave(selectedTrades, simpleNarrativeText);
    };

    return (
        <>
            <div className={`modal modal-region smart-chart-popup bbi-pop`} id="modalId" tabindex="-1" style={{ display: "block" }}>
                <Draggable handle=".draggable" cancel=".not-draggable">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content outerModal_draggable">
                            <div className="modal-header draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    <div className="txt-hed d-flex align-items-center">{heading}</div>
                                </h5>
                                <button type="button" className="close" onClick={() => onCancel()}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body region-otr draggable">
                                <div className="upload-area not-draggable">
                                    <div className="category-check-wrap">
                                        <>
                                            <h4 style={{ textTransform: "capitalize" }}>Trades</h4>
                                            {tradeValues?.map((value, index) => (
                                                <div className="categ-wrap-trade">
                                                    <div className="d-flex" key={index}>
                                                        <div className="formInp">
                                                            <div className="col-md-12 checkbox-cover">
                                                                <span className="dropdown-item">
                                                                    <label className="container-check">
                                                                        {value?.name}
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedTrades.includes(value?.id)}
                                                                            onClick={() => handleCheckboxClick(value?.id)}
                                                                        />
                                                                        <span className={`checkmark `}></span>
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                        <>
                                            <h4 style={{ textTransform: "capitalize" }}>Executive Summary</h4>
                                            <div class="categ-wrap-trade ">
                                                <div className="formInp d-flex">
                                                    <div className="col-md-4 checkbox-cover">
                                                        <span className="dropdown-item">
                                                            <label className="container-check">
                                                                Simple Text
                                                                <input
                                                                    type="checkbox"
                                                                    checked={simpleNarrativeText}
                                                                    onChange={e => setSimpleNarrativeText(true)}
                                                                />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="col-md-4 checkbox-cover">
                                                        <span className="dropdown-item">
                                                            <label className="container-check">
                                                                Rich Text
                                                                <input
                                                                    type="checkbox"
                                                                    checked={!simpleNarrativeText}
                                                                    onChange={e => setSimpleNarrativeText(false)}
                                                                />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div>

                                <div className="btn-sec not-draggable">
                                    <div className="text-right btnOtr edit-cmn-btn mt-4">
                                        <button
                                            type="button"
                                            className="btn btn-secondary btnClr col-md-2 mr-3"
                                            data-dismiss="modal"
                                            onClick={() => onCancel()}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary btnRgion col-md-2"
                                            onClick={() => handleSave()}
                                            disabled={isLoading}
                                        >
                                            {btnName}
                                            {isLoading && (
                                                <span className="edit-icn-bx icon-btn-sec cursor-diabled">
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
        </>
    );
};

export default BbiConfigModal;
