// Get all sites
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

// Add site
export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

// Update site
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const UPDATE_USER_EMPTY = "UPDATE_USER_EMPTY";

// Update general reviewer
export const UPDATE_USER_GENERAL_REVIEWER_REQUEST = "UPDATE_USER_GENERAL_REVIEWER_REQUEST";
export const UPDATE_USER_GENERAL_REVIEWER_SUCCESS = "UPDATE_USER_GENERAL_REVIEWER_SUCCESS";
export const UPDATE_USER_GENERAL_REVIEWER_FAILURE = "UPDATE_USER_GENERAL_REVIEWER_FAILURE";

// Delete site
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

// Ger region based on USER
export const GET_REGIONS_BASED_ON_USER_REQUEST = "GET_REGIONS_BASED_ON_USER_REQUEST";
export const GET_REGIONS_BASED_ON_USER_SUCCESS = "GET_REGIONS_BASED_ON_USER_SUCCESS";
export const GET_REGIONS_BASED_ON_USER_FAILURE = "GET_REGIONS_BASED_ON_USER_FAILURE";

// Get all USERs
export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

// Get Site by Id
export const GET_USER_BY_ID_REQUEST = "GET_USER_BY_ID_REQUEST";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILURE = "GET_USER_TYPE_BY_ID_FAILURE";

export const UPDATE_USER_ENTITY_PARAMS_SUCCESS = "UPDATE_USER_ENTITY_PARAMS_SUCCESS";
export const UPDATE_USER_ENTITY_PARAMS_FAILURE = "UPDATE_USER_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_USER_LOG_REQUEST = "GET_ALL_USER_LOG_REQUEST";
export const GET_ALL_USER_LOG_SUCCESS = "GET_ALL_USER_LOG_SUCCESS";
export const GET_ALL_USER_LOG_FAILURE = "GET_ALL_USER_LOG_FAILURE";

export const RESTORE_USER_LOG_REQUEST = "RESTORE_USER_LOG_REQUEST";
export const RESTORE_USER_LOG_SUCCESS = "RESTORE_USER_LOG_SUCCESS";
export const RESTORE_USER_LOG_FAILURE = "RESTORE_USER_LOG_FAILURE";

export const DELETE_USER_LOG_REQUEST = "DELETE_USER_LOG_REQUEST";
export const DELETE_USER_LOG_SUCCESS = "DELETE_USER_LOG_SUCCESS";
export const DELETE_USER_LOG_FAILURE = "DELETE_USER_LOG_FAILURE";

export const GET_USER_EXPORT_REQUEST = "GET_USER_EXPORT_REQUEST";
export const GET_USER_EXPORT_SUCCESS = "GET_USER_EXPORT_SUCCESS";
export const GET_USER_EXPORT_FAILURE = "GET_USER_EXPORT_FAILURE";

export const GET_ALL_PROJECTS_DROP_DOWN_REQUEST = "GET_ALL_PROJECTS_DROP_DOWN_REQUEST";
export const GET_ALL_PROJECTS_DROP_DOWN_SUCCESS = "GET_ALL_PROJECTS_DROP_DOWN_SUCCESS";
export const GET_ALL_PROJECTS_DROP_DOWN_FAILURE = "GET_ALL_PROJECTS_DROP_DOWN_FAILURE";

export const GET_ALL_BUILDINGS_DROP_DOWN_REQUEST = "GET_ALL_BUILDINGS_DROP_DOWN_REQUEST";
export const GET_ALL_BUILDINGS_DROP_DOWN_SUCCESS = "GET_ALL_BUILDINGS_DROP_DOWN_SUCCESS";
export const GET_ALL_BUILDINGS_DROP_DOWN_FAILURE = "GET_ALL_BUILDINGS_DROP_DOWN_FAILURE";

export const GET_ALL_ROLES_DROP_DOWN_REQUEST = "GET_ALL_ROLES_DROP_DOWN_REQUEST";
export const GET_ALL_ROLES_DROP_DOWN_SUCCESS = "GET_ALL_ROLES_DROP_DOWN_SUCCESS";
export const GET_ALL_ROLES_DROP_DOWN_FAILURE = "GET_ALL_ROLES_DROP_DOWN_FAILURE";

export const GET_ALL_GROUPS_DROP_DOWN_REQUEST = "GET_ALL_GROUPS_DROP_DOWN_REQUEST";
export const GET_ALL_GROUPS_DROP_DOWN_SUCCESS = "GET_ALL_GROUPS_DROP_DOWN_SUCCESS";
export const GET_ALL_GROUPS_DROP_DOWN_FAILURE = "GET_ALL_GROUPS_DROP_DOWN_FAILURE";

export const GET_CONSULTANCIES_BASED_ON_ROLE_REQUEST = "GET_CONSULTANCIES_BASED_ON_ROLE_REQUEST";
export const GET_CONSULTANCIES_BASED_ON_ROLE_SUCCESS = "GET_CONSULTANCIES_BASED_ON_ROLE_SUCCESS";
export const GET_CONSULTANCIES_BASED_ON_ROLE_FAILURE = "GET_CONSULTANCIES_BASED_ON_ROLE_FAILURE";

export const GET_CLIENTS_BASED_ON_ROLE_REQUEST = "GET_CLIENTS_BASED_ON_ROLE_REQUEST";
export const GET_CLIENTS_BASED_ON_ROLE_SUCCESS = "GET_CLIENTS_BASED_ON_ROLE_SUCCESS";
export const GET_CLIENTS_BASED_ON_ROLE_FAILURE = "GET_CLIENTS_BASED_ON_ROLE_FAILURE";

export const USER_UPLOAD_EXCEL_REQUEST = "USER_UPLOAD_EXCEL_REQUEST";
export const USER_UPLOAD_EXCEL_SUCCESS = "USER_UPLOAD_EXCEL_SUCCESS";
export const USER_UPLOAD_EXCEL_FAILURE = "USER_UPLOAD_EXCEL_FAILURE";
export const CLEAR_EXCEL_DATA = "CLEAR_EXCEL_DATA";

export const GET_EXPORT_USER_TEMPLATE_REQUEST = "GET_EXPORT_USER_TEMPLATE_REQUEST";
export const GET_EXPORT_USER_TEMPLATE_FAILURE = "GET_EXPORT_USER_TEMPLATE_FAILURE";
export const GET_EXPORT_USER_TEMPLATE_SUCCESS = "GET_EXPORT_USER_TEMPLATE_SUCCESS";
