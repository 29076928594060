export const capitalRequestTableData = {
    keys: [
        "code",
        "requestor_first_name",
        "requestor_last_name",
        "funding_request_title",
        "request_estimate",
        "status",
        "status_note",
        "investment_priority",
        "request_status",
        "request_type",
        "department_name",
        "equipment_request_type",
        "age_and_condition_of_replacement",
        "request_description",
        "improving_service_inquiry",
        "problem_alleviation_inquiry",
        "compliance_resolution_inquiry",
        "investment_efficiency_inquiry",
        "operations_cost_reduction_inquiry",
        "safety_requirement_inquiry",
        "approval_delay_impact_inquiry",
        "future_plan_inquiry",
        "receiver_inquiry",
        "other_request_relationship_inquiry",
        "alternative_inquiry",
        "risk_inquiry",
        "vendor_name",
        "has_funding_source_identified",
        "funding_source_security_inquiry",
        "requestor_email",
        "comments",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Code",
            searchKey: "code",
            class: "reg-code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "code"
        },
        requestor_first_name: {
            isVisible: true,
            label: "First Name",
            searchKey: "requestor_first_name",
            class: "width-180px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "requestor_first_name",
            filterIdKey: "requestor_first_name"
        },
        requestor_last_name: {
            isVisible: true,
            label: "Last Name",
            searchKey: "requestor_last_name",
            class: "width-180px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "requestor_last_name",
            filterIdKey: "requestor_last_name"
        },
        requestor_email: {
            isVisible: true,
            label: "Email",
            searchKey: "requestor_email",
            class: "width-180px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "requestor_email"
        },
        status: {
            isVisible: true,
            label: "Status",
            searchKey: "status",
            class: "width-200px",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "status",
            filterIdKey: "status"
        },
        status_note: {
            isVisible: true,
            label: "Status Notes",
            searchKey: "status_note",
            class: "width-200px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "status_note"
        },
        department_name: {
            isVisible: true,
            label: "Department",
            searchKey: "department_name",
            class: "width-200px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "department_name"
        },
        funding_request_title: {
            isVisible: true,
            label: "CR Title",
            searchKey: "funding_request_title",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "funding_request_title"
        },
        request_estimate: {
            isVisible: true,
            label: "CR Cost Estimate",
            searchKey: "request_estimate",
            class: "width-220px",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "request_estimate"
        },

        investment_priority: {
            isVisible: true,
            label: "Priority",
            searchKey: "investment_priority",
            class: "",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "investment_priority",
            filterIdKey: "investment_priority"
        },
        request_status: {
            isVisible: true,
            label: "CR Requester Status",
            searchKey: "request_status",
            class: "",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "request_status",
            filterIdKey: "request_status"
        },
        request_type: {
            isVisible: true,
            label: "CR Type",
            searchKey: "request_type",
            class: "",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "request_type",
            filterIdKey: "request_type"
        },
        equipment_request_type: {
            isVisible: true,
            label: "EQ/Vehicle CR Type",
            searchKey: "equipment_request_type",
            class: "",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "equipment_request_type",
            filterIdKey: "equipment_request_type"
        },
        age_and_condition_of_replacement: {
            isVisible: true,
            label: "CR Replacement Condition",
            searchKey: "age_and_condition_of_replacement",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "age_and_condition_of_replacement"
        },
        request_description: {
            isVisible: true,
            label: "CR Description",
            searchKey: "request_description",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "request_description"
        },
        improving_service_inquiry: {
            isVisible: true,
            label: "CR Impact",
            searchKey: "improving_service_inquiry",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "improving_service_inquiry"
        },
        problem_alleviation_inquiry: {
            isVisible: true,
            label: "CR Alleviate",
            searchKey: "problem_alleviation_inquiry",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "problem_alleviation_inquiry"
        },
        compliance_resolution_inquiry: {
            isVisible: true,
            label: "Is CR Compliance",
            searchKey: "compliance_resolution_inquiry",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "compliance_resolution_inquiry",
            filterIdKey: "compliance_resolution_inquiry"
        },
        investment_efficiency_inquiry: {
            isVisible: true,
            label: "Is CR ROI",
            searchKey: "investment_efficiency_inquiry",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "investment_efficiency_inquiry",
            filterIdKey: "investment_efficiency_inquiry"
        },
        operations_cost_reduction_inquiry: {
            isVisible: true,
            label: "Is CR Savings",
            searchKey: "operations_cost_reduction_inquiry",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "operations_cost_reduction_inquiry",
            filterIdKey: "operations_cost_reduction_inquiry"
        },
        safety_requirement_inquiry: {
            isVisible: true,
            label: "Is CR Safety",
            searchKey: "safety_requirement_inquiry",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "safety_requirement_inquiry",
            filterIdKey: "safety_requirement_inquiry"
        },
        approval_delay_impact_inquiry: {
            isVisible: true,
            label: "CR Delay Impact",
            searchKey: "approval_delay_impact_inquiry",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "approval_delay_impact_inquiry"
        },
        future_plan_inquiry: {
            isVisible: true,
            label: "CR Future Impact",
            searchKey: "future_plan_inquiry",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "future_plan_inquiry"
        },
        receiver_inquiry: {
            isVisible: true,
            label: "Receiver Inquiry",
            searchKey: "receiver_inquiry",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "receiver_inquiry"
        },
        other_request_relationship_inquiry: {
            isVisible: true,
            label: "CR to CR Links",
            searchKey: "other_request_relationship_inquiry",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "other_request_relationship_inquiry"
        },
        alternative_inquiry: {
            isVisible: true,
            label: "CR Alternatives",
            searchKey: "alternative_inquiry",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "alternative_inquiry"
        },
        risk_inquiry: {
            isVisible: true,
            label: "CR No-Go Risks",
            searchKey: "request_status",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "risk_inquiry"
        },
        vendor_name: {
            isVisible: true,
            label: "CR Cost Supplier",
            searchKey: "vendor_name",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "vendor_name"
        },
        has_funding_source_identified: {
            isVisible: true,
            label: "Is CR Grant/Gift",
            searchKey: "has_funding_source_identified",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "has_funding_source_identified",
            filterIdKey: "has_funding_source_identified"
        },
        funding_source_security_inquiry: {
            isVisible: true,
            label: "Is CR Grant/Gift Secure",
            searchKey: "funding_source_security_inquiry",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "responsibility",
            commonSearchKey: "responsibility",
            commonSearchObjectKey: "funding_source_security_inquiry",
            filterIdKey: "funding_source_security_inquiry"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            searchKey: "comments",
            class: "",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "capital_request",
            commonSearchKey: "capital_request",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "capital_request",
            commonSearchKey: "capital_request",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "capital_request",
            commonSearchKey: "capital_request",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};
