export const GET_ALL_HEADING_REQUEST = "GET_ALL_HEADING_REQUEST";
export const GET_ALL_HEADING_SUCCESS = "GET_ALL_HEADING_SUCCESS";
export const GET_ALL_HEADING_FAILURE = "GET_ALL_HEADING_FAILURE";

export const UPDATE_HEADING_REQUEST = "UPDATE_HEADING_REQUEST";
export const UPDATE_HEADING_SUCCESS = "UPDATE_HEADING_SUCCESS";
export const UPDATE_HEADING_FAILURE = "UPDATE_HEADING_FAILURE";

export const UPDATE_HEADING_ENTITY_PARAMS_SUCCESS = "UPDATE_HEADING_ENTITY_PARAMS_SUCCESS";
export const UPDATE_HEADING_ENTITY_PARAMS_FAILURE = "UPDATE_HEADING_ENTITY_PARAMS_FAILURE";

export const GET_HEADING_DATA_BY_ID_REQUEST = "GET_HEADING_DATA_BY_ID_REQUEST";
export const GET_HEADING_DATA_BY_ID_SUCCESS = "GET_HEADING_DATA_BY_ID_SUCCESS";
export const GET_HEADING_DATA_BY_ID_FAILURE = "GET_HEADING_DATA_BY_ID_FAILURE";


export const GET_HEADING_EXPORT_REQUEST = "GET_HEADING_EXPORT_REQUEST ";
export const GET_HEADING_EXPORT_SUCCESS = "GET_HEADING_EXPORT_SUCCESS";
export const GET_HEADING_EXPORT_FAILURE = "GET_HEADING_EXPORT_FAILURE";
