import { removeUrlQueryString, showAlert } from "../../config/utils";
import * as actionTypes from "./constants";
import * as Service from "./services";

const getMenuItems = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_MENU_ITEMS_REQUEST });
            const res = await Service.getMenuItems(params);
            if (res && res.status === 200) {
                const regionData = res.data;
                if (regionData.success) {
                    dispatch({ type: actionTypes.GET_MENU_ITEMS_SUCCESS, response: regionData });
                } else {
                    dispatch({ type: actionTypes.GET_MENU_ITEMS_FAILURE, error: regionData });
                }
            } else {
                dispatch({
                    type: actionTypes.GET_MENU_ITEMS_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_MENU_ITEMS_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getSideMenuItems = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_PROJECT_MENU_ITEMS_REQUEST });
            const res = await Service.getSideMenuItems(params.entity, params.params);
            if (res && res.status === 200) {
                const regionData = res.data;
                if (regionData.success) {
                    dispatch({ type: actionTypes.GET_PROJECT_MENU_ITEMS_SUCCESS, response: regionData, key: params.response });
                } else {
                    dispatch({ type: actionTypes.GET_PROJECT_MENU_ITEMS_FAILURE, error: regionData });
                }
            } else {
                dispatch({
                    type: actionTypes.GET_PROJECT_MENU_ITEMS_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_PROJECT_MENU_ITEMS_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const setFormDirty = value => {
    return dispatch => {
        try {
            dispatch({
                type: actionTypes.SET_FORM_DIRTY_SUCCESS,
                response: value
            });
        } catch (e) {
            dispatch({
                type: actionTypes.SET_FORM_DIRTY_FAILURE,
                error: false
            });
        }
    };
};

const getLinkEmail = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_MENU_LINK_EMAIL_REQUEST });
            const res = await Service.getLinkEmail(params);
            if (res && res.status === 200) {
                const regionData = res.data;
                const { s3_url, message, status } = regionData;
                if (regionData.status) {
                    const baseUrl = removeUrlQueryString(s3_url);
                    const res = await Service.getPresignedUrl(baseUrl);
                    if (res && res.status === 200) {
                        const regionData = res.data;
                        const { signed_url } = regionData;
                        const link = document.createElement("a");
                        link.href = signed_url;
                        link.download = signed_url;
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                    dispatch({
                        type: actionTypes.GET_MENU_LINK_EMAIL_SUCCESS,
                        response: { message_status: "File Exported Successfully", message: message, status, s3_url }
                    });
                } else {
                    dispatch({
                        type: actionTypes.GET_MENU_LINK_EMAIL_SUCCESS,
                        response: { message_status: "File Exported Successfully", message: message, status }
                    });
                }
            } else {
                dispatch({
                    type: actionTypes.GET_MENU_LINK_EMAIL_FAILURE,
                    error: res.response && res.response.data,
                    message: "Something Went Wrong"
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_MENU_LINK_EMAIL_FAILURE,
                error: e.response && e.response.data,
                message: "Something Went Wrong"
            });
        }
    };
};

export const downloadPresignedUrlFile = async (publicUrl, contentDisposition = "", isLinkEmail) => {
    if (!publicUrl) showAlert("Oops. URL not found!");
    try {
        const baseUrl = removeUrlQueryString(publicUrl);
        const res = await Service.getPresignedUrl(baseUrl, contentDisposition);
        if (res && res.status === 200) {
            const regionData = res.data;
            const { signed_url } = regionData;
            const link = document.createElement("a");
            link.href = signed_url;
            link.download = signed_url;
            document.body.appendChild(link);
            link.click();
            link.remove();
        } else {
            showAlert("Something went wrong!");
        }
    } catch (e) {
        showAlert("Something went wrong!");
    }
};

export const getPresignedUrl = async publicUrl => {
    if (!publicUrl) return "";
    try {
        const baseUrl = removeUrlQueryString(publicUrl);
        const res = await Service.getPresignedUrl(baseUrl);
        if (res && res.status === 200) {
            const regionData = res.data;
            const { signed_url } = regionData;
            return signed_url;
        } else {
            return "";
        }
    } catch (e) {
        return "";
    }
};

/**
 * Saves the table configuration.
 *
 * @param {Object} config - The configuration object.
 * @param {string} config.user_id - The user ID (required).
 * @param {string} [config.project_id] - The project ID (optional).
 * @param {string} [config.client_id] - The client ID (optional).
 * @param {String} config.table_type - The table type (required).
 * @param {Object} config.table_settings - The table settings object (required).
 * @returns {void} This function returns nothing.
 * @throws {Error} Throws an error if neither client_id nor project_id is provided.
 */
export const saveTableConfig = async config => {
    try {
        const res = await Service.saveTableConfig(config);
        if (res && (res.status === 200 || res.status === 201)) {
        } else {
            showAlert("Failed to save table config!");
        }
    } catch (e) {
        showAlert("Failed to save table config!");
    }
};
/**
 * retrieve the table configuration.
 *
 * @param {string} config.user_id - The user ID (required).
 * @param {string} [config.project_id] - The project ID (optional).
 * @param {string} [config.client_id] - The client ID (optional).
 * @param {String} config.table_type - The table type (required).
 * @returns {void} This function returns nothing.
 * @throws {Error} Throws an error if neither client_id nor project_id is provided.
 */
export const getTableConfig = async params => {
    try {
        const res = await Service.getTableConfig(params);
        if (res && res.status === 200) {
            return res.data;
        } else {
            return null;
        }
    } catch (e) {
        showAlert("Failed to retrieve table config!");
        return null;
    }
};

const actions = {
    getMenuItems,
    getSideMenuItems,
    setFormDirty,
    getLinkEmail,
    saveTableConfig
};

export default actions;
