import React, { useEffect, useRef, useState } from "react";
import ReactSelect, { components } from "react-select";
import Loader from "../../common/components/Loader";
import CreatableSelect from "react-select/creatable";

export default function SelectBox({
    label,
    optionsList,
    handleChange,
    value,
    showErrorBorder,
    disabled,
    className,
    isBuilding,
    hasEmptySelect = true,
    isRecommendation = false,
    isCapitalType = false,
    isClearable = true,
    isSurveyor = false,
    isDynamicField = false,
    isAsset = false,
    isLoading = false,
    isGrouping = false,
    handleInputChange,
    isManufacturer = false,
    isLoadingDrop = false,
    manufacturerCount = null,
    isCreatable = false,
    createOption,
    isDropdownLineEdit = false,
    handleCellFocus,
    keyItem = "",
    isDropdownLoading = false,
    rowData,
    rowIndex,
    config
}) {
    let defaultOptions = [];
    optionsList?.length > 0 &&
        optionsList.forEach(option => {
            defaultOptions.push({
                label: `${!(isCapitalType || isSurveyor || isDynamicField) ? option.name : ""} ${isDynamicField ? option.value : ""} ${
                    isBuilding && (option.description || option.building_description) ? `(${option.description || option.building_description})` : ""
                } ${isCapitalType ? option.display_name : ""}${isSurveyor ? option.printed_name || option.name : ""}`,
                value: isCapitalType ? option.name : isDynamicField || isGrouping ? option.value : option.id
            });
        });
    const onChange = values => {
        if (values?.value !== value) {
            if (isManufacturer || isDropdownLineEdit) {
                handleChange(values?.value || "", values?.label || "");
            } else {
                handleChange(values?.value || "");
            }
        }
    };
    const [menuPosition, setMenuPosition] = useState("right");
    const selectRef = useRef(null);
    if (hasEmptySelect) {
        defaultOptions = [{ label: "Select", value: "" }, ...defaultOptions];
    }

    const [isCreating, setIsCreating] = useState(false);
    const [options, setOptions] = useState(defaultOptions);

    useEffect(() => {
        setOptions(defaultOptions);
    }, [optionsList]);

    const handleCreate = async inputValue => {
        setIsCreating(true);
        const newOption = await createOption(inputValue);
        setIsCreating(false);
        setOptions(prev => [...prev, newOption]);
    };
    const [isMenuOPen, setIsMenuOpen] = useState(false);
    const selectedValue = isDropdownLineEdit
        ? keyItem === "status"
            ? options?.find(option => typeof option?.value === "string" && option?.value?.trim() === value) ||
              ([{ value: value, label: value }] || [])?.find(option => typeof option?.value === "string" && option?.value?.trim() === value)
            : options?.find(option => typeof option?.label === "string" && option?.label?.trim() === value) ||
              ([{ value: value, label: value }] || [])?.find(option => typeof option?.label === "string" && option?.label?.trim() === value)
        : options.find(option => option.value === value);
    useEffect(() => {
        if (selectRef?.current && isRecommendation) {
            const parentDiv = document?.querySelector(".height-new-cp");
            const parentRect = parentDiv?.getBoundingClientRect();
            const selectRect = selectRef?.current?.getBoundingClientRect();
            const spaceOnLeft = selectRect?.left - parentRect?.left;
            const spaceOnRight = parentRect?.right - selectRect?.right;
            const dropdownMenu = document?.querySelector(".react-select__menu");
            if (dropdownMenu) {
                const dropdownMenuWidth = dropdownMenu?.getBoundingClientRect()?.width;
                if (spaceOnRight >= dropdownMenuWidth) {
                    setMenuPosition("right");
                } else if (spaceOnLeft >= dropdownMenuWidth) {
                    setMenuPosition("left");
                } else if (spaceOnRight > 0 || spaceOnRight > 0) {
                    setMenuPosition(spaceOnRight > spaceOnLeft ? "right" : "left");
                }
            }
        }
    }, [isMenuOPen]);

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            left: menuPosition === "left" ? "auto" : 0,
            right: menuPosition === "right" ? "auto" : 0
        })
    };
    return (
        <div className={`${isDropdownLineEdit ? "categ-drop-rc mb-0" : ""} form-group`}>
            {!isDropdownLineEdit && <label>{label}</label>}
            <div className={`selectOtr ${className} ${disabled ? " cursor-diabled " : ""}`} ref={selectRef}>
                {isCreatable ? (
                    <CreatableSelect
                        className={`${isRecommendation || isAsset || isGrouping ? "" : "form-control"} ${disabled ? " cursor-diabled " : ""}${
                            showErrorBorder ? "error-border" : ""
                        }`}
                        options={options}
                        value={selectedValue}
                        isClearable={isClearable && hasEmptySelect}
                        onChange={onChange}
                        classNamePrefix="react-select"
                        isDisabled={disabled || isCreating}
                        components={{
                            MenuList: props => <MenuList {...props} />
                        }}
                        isLoading={isLoading || isLoadingDrop || isCreating}
                        onCreateOption={handleCreate}
                    />
                ) : (
                    <ReactSelect
                        className={`${isRecommendation || isAsset || isGrouping || isDropdownLineEdit ? "" : "form-control"} ${
                            disabled ? " cursor-diabled " : ""
                        }${showErrorBorder ? "error-border" : ""}`}
                        options={options?.length !== 0 && !isDropdownLoading ? options : []}
                        value={selectedValue}
                        isClearable={isClearable && hasEmptySelect}
                        onChange={onChange}
                        classNamePrefix="react-select"
                        isDisabled={disabled}
                        components={{
                            MenuList: props => (
                                <MenuList
                                    {...props}
                                    count={manufacturerCount}
                                    isManufacturer={isManufacturer}
                                    isLoading={isLoading || isLoadingDrop}
                                    isDropdownLoading={isDropdownLoading}
                                />
                            )
                        }}
                        isLoading={isLoading || isDropdownLoading}
                        onInputChange={isManufacturer ? handleInputChange : ""}
                        onFocus={event => {
                            if (isManufacturer) {
                                handleInputChange(event.target.value, { action: "input-click" });
                            }
                            if (isDropdownLineEdit) {
                                handleCellFocus(keyItem, rowIndex, rowData?.id, config[keyItem]?.isDropdownLineEdit, rowData);
                            }
                        }}
                        styles={isRecommendation && !isAsset ? customStyles : {}}
                        onMenuOpen={() => {
                            setIsMenuOpen(!isMenuOPen);
                        }}
                    />
                )}
            </div>
        </div>
    );
}

const MenuList = props => {
    const menuListRef = useRef();

    useEffect(() => {
        if (menuListRef.current) {
            menuListRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, []);
    return (
        <components.MenuList {...props} innerRef={menuListRef}>
            {props?.isLoading && props?.isManufacturer ? (
                <div className="col-md-12 p-4">
                    <Loader />
                </div>
            ) : (
                <>
                    {props?.isManufacturer && <div className="option-count">Count: {props?.count}</div>}
                    {props.children}
                </>
            )}
        </components.MenuList>
    );
};
