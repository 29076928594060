import * as actionType from "./constants";

const initialState = {
    addRequestResponse: null,
    updateRequestResponse: null,
    getCapitalRequestDataResponse: {},
    getCapitalRequestDataByIdResponse: null,
    getListForCommonFilterResponse: {},
    getCapitalRequestDataForRecommendationResponse: {},
    entityParams: {
        entity: null,
        selectedEntity: null,
        selectedRowId: null,
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 1,
            search: "",
            filters: null,
            list: null,
            filterKeys: {},
            template_filter: "active",
            selectedDropdown: "active"
        },
        tableConfig: null
    },
    emailRegistrationResponse: null,
    lockCapitalRequestResponse: null,
    emailVerifyResponse: null,
    deleteCapitalRequestResponse: null,
    restoreCapitalRequestResponse: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_REQUEST_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_REQUEST_SUCCESS:
            return {
                ...state,
                addRequestResponse: { success: true, ...action.response }
            };
        case actionType.ADD_REQUEST_FAILURE:
            return {
                ...state,
                addRequestResponse: { success: false, ...action.error }
            };
        case actionType.UPDATE_REQUEST_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                updateRequestResponse: { success: true, ...action.response }
            };
        case actionType.UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                updateRequestResponse: { success: false, ...action.error }
            };
        case actionType.GET_CAPITAL_REQUEST_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CAPITAL_REQUEST_DATA_SUCCESS:
            return {
                ...state,
                getCapitalRequestDataResponse: { success: true, ...action.response }
            };
        case actionType.GET_CAPITAL_REQUEST_DATA_FAILURE:
            return {
                ...state,
                getCapitalRequestDataResponse: { success: false, ...action.error }
            };
        case actionType.GET_CAPITAL_REQUEST_DATA_BY_ID_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CAPITAL_REQUEST_DATA_BY_ID_SUCCESS:
            return {
                ...state,
                getCapitalRequestDataByIdResponse: { success: true, data: { ...action.response } }
            };
        case actionType.GET_CAPITAL_REQUEST_DATA_BY_ID_FAILURE:
            return {
                ...state,
                getCapitalRequestDataByIdResponse: { success: false, ...action.error }
            };
        case actionType.SUBMIT_EMAIL_REQUEST:
            return {
                ...state
            };
        case actionType.SUBMIT_EMAIL_SUCCESS:
            return {
                ...state,
                emailRegistrationResponse: { success: true, ...action.response }
            };
        case actionType.SUBMIT_EMAIL_FAILURE:
            return {
                ...state,
                emailRegistrationResponse: { success: false, ...action.error }
            };
        case actionType.GET_COMMON_FILTER_LIST_FOR_CR_REQUEST:
            return {
                ...state
            };
        case actionType.GET_COMMON_FILTER_LIST_FOR_CR_SUCCESS:
            return {
                ...state,
                getListForCommonFilterResponse: { success: true, list: action.response?.data || [], ...action.response }
            };
        case actionType.GET_COMMON_FILTER_LIST_FOR_CR_FAILURE:
            return {
                ...state,
                getListForCommonFilterResponse: { success: false, ...action.error }
            };
        case actionType.LOCK_CAPITAL_REQUEST_REQUEST:
            return {
                ...state
            };
        case actionType.LOCK_CAPITAL_REQUEST_SUCCESS:
            return {
                ...state,
                lockCapitalRequestResponse: { success: true, ...action.response }
            };
        case actionType.LOCK_CAPITAL_REQUEST_FAILURE:
            return {
                ...state,
                lockCapitalRequestResponse: { success: false, ...action.error }
            };
        case actionType.VERIFY_EMAIL_REQUEST:
            return {
                ...state
            };
        case actionType.VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                emailVerifyResponse: { success: true, ...action.response }
            };
        case actionType.VERIFY_EMAIL_FAILURE:
            return {
                ...state,
                emailVerifyResponse: { success: false, ...action.error }
            };
        case actionType.DELETE_CAPITAL_REQUEST_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_CAPITAL_REQUEST_SUCCESS:
            return {
                ...state,
                deleteCapitalRequestResponse: { success: true, ...action.response }
            };
        case actionType.DELETE_CAPITAL_REQUEST_FAILURE:
            return {
                ...state,
                deleteCapitalRequestResponse: { success: false, ...action.error }
            };
        case actionType.RESTORE_CR_REQUEST:
            return {
                ...state
            };
        case actionType.RESTORE_CR_SUCCESS:
            return {
                ...state,
                restoreCapitalRequestResponse: { success: true, ...action.response }
            };
        case actionType.RESTORE_CR_FAILURE:
            return {
                ...state,
                restoreCapitalRequestResponse: { success: false, ...action.error }
            };
        case actionType.EMPTY_VIEW_PAGE_DATA:
            return {
                ...state,
                getCapitalRequestDataByIdResponse: null
            };
        case actionType.CLEAR_RESPONSES:
            return {
                ...state,
                deleteCapitalRequestResponse: null,
                restoreCapitalRequestResponse: null
            };
        case actionType.GET_CAPITAL_REQUEST_DATA_FOR_RECOMMENDATION_VIEW_SUCCESS:
            return {
                ...state,
                getCapitalRequestDataForRecommendationResponse: { success: true, ...action.response }
            };
        case actionType.GET_CAPITAL_REQUEST_DATA_FOR_RECOMMENDATION_VIEW_FAILURE:
            return {
                ...state,
                getCapitalRequestDataForRecommendationResponse: { success: false, ...action.error }
            };
        case actionType.UPDATE_CR_ENTITY_PARAMS_SUCCESS:
            return {
                ...state,
                entityParams: { ...action.response }
            };
        case actionType.UPDATE_CR_ENTITY_PARAMS_FAILURE:
            return {
                ...state,
                entityParams: { ...action.error }
            };

        default:
            return state;
    }
};
