// Get all floors
export const GET_ADDITIONS_BASED_ON_BUILDING_REQUEST = "GET_ADDITIONS_BASED_ON_BUILDING_REQUEST";
export const GET_ADDITIONS_BASED_ON_BUILDING_SUCCESS = "GET_ADDITIONS_BASED_ON_BUILDING_SUCCESS";
export const GET_ADDITIONS_BASED_ON_BUILDING_FAILURE = "GET_ADDITIONS_BASED_ON_BUILDING_FAILURE";

// Add floor
export const ADD_ADDITION_REQUEST = "ADD_ADDITION_REQUEST";
export const ADD_ADDITION_SUCCESS = "ADD_ADDITION_SUCCESS";
export const ADD_ADDITION_FAILURE = "ADD_ADDITION_FAILURE";

// Update floor
export const UPDATE_ADDITION_REQUEST = "UPDATE_ADDITION_REQUEST";
export const UPDATE_ADDITION_SUCCESS = "UPDATE_ADDITION_SUCCESS";
export const UPDATE_ADDITION_FAILURE = "UPDATE_ADDITION_FAILURE";

// Delete floor
export const DELETE_ADDITION_REQUEST = "DELETE_ADDITION_REQUEST";
export const DELETE_ADDITION_SUCCESS = "DELETE_ADDITION_SUCCESS";
export const DELETE_ADDITION_FAILURE = "DELETE_ADDITION_FAILURE";

// Get Floor by Id
export const GET_ADDITION_BY_ID_REQUEST = "GET_ADDITION_BY_ID_REQUEST";
export const GET_ADDITION_BY_ID_SUCCESS = "GET_ADDITION_BY_ID_SUCCESS";
export const GET_ADDITION_BY_ID_FAILURE = "GET_ADDITION_BY_ID_FAILURE";

// Update Floor Entity Param
export const UPDATE_ADDITION_ENTITY_PARAMS_SUCCESS = "UPDATE_ADDITION_ENTITY_PARAMS_SUCCESS";
export const UPDATE_ADDITION_ENTITY_PARAMS_FAILURE = "UPDATE_ADDITION_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_ADDITION_LOG_REQUEST = "GET_ALL_ADDITION_LOG_REQUEST";
export const GET_ALL_ADDITION_LOG_SUCCESS = "GET_ALL_ADDITION_LOG_SUCCESS";
export const GET_ALL_ADDITION_LOG_FAILURE = "GET_ALL_ADDITION_LOG_FAILURE";

export const RESTORE_ADDITION_LOG_REQUEST = "RESTORE_ADDITION_LOG_REQUEST";
export const RESTORE_ADDITION_LOG_SUCCESS = "RESTORE_ADDITION_LOG_SUCCESS";
export const RESTORE_ADDITION_LOG_FAILURE = "RESTORE_ADDITION_LOG_FAILURE";

export const DELETE_ADDITION_LOG_REQUEST = "DELETE_ADDITION_LOG_REQUEST";
export const DELETE_ADDITION_LOG_SUCCESS = "DELETE_ADDITION_LOG_SUCCESS";
export const DELETE_ADDITION_LOG_FAILURE = "DELETE_ADDITION_LOG_FAILURE";

export const GET_ADDITION_EXPORT_REQUEST = "GET_ADDITION_EXPORT_REQUEST";
export const GET_ADDITION_EXPORT_SUCCESS = "GET_ADDITION_EXPORT_SUCCESS";
export const GET_ADDITION_EXPORT_FAILURE = "GET_ADDITION_EXPORT_FAILURE";

export const GET_ALL_CLIENT_USERS_REQUEST = "GET_ALL_CLIENT_USERS_REQUEST";
export const GET_ALL_CLIENT_USERS_SUCCESS = "GET_ALL_CLIENT_USERS_SUCCESS";
export const GET_ALL_CLIENT_USERS_FAILURE = "GET_ALL_CLIENT_USERS_FAILURE";


// Get all consultancy users
export const GET_ALL_CONSULTANCY_USERS_REQUEST = "GET_ALL_CONSULTANCY_USERS_REQUEST";
export const GET_ALL_CONSULTANCY_USERS_SUCCESS = "GET_ALL_CONSULTANCY_USERS_SUCCESS";
export const GET_ALL_CONSULTANCY_USERS_FAILURE = "GET_ALL_CONSULTANCY_USERS_FAILURE";


export const GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST = "GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST";
export const GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS = "GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS";
export const GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE = "GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE";


// Get all Clients
export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const GET_ALL_CLIENTS_FAILURE = "GET_ALL_CLIENTS_FAILURE";




export const GET_ALL_BUILDINGS_DROP_DOWN_REQUEST = "GET_ALL_BUILDINGS_DROP_DOWN_REQUEST";
export const GET_ALL_BUILDINGS_DROP_DOWN_SUCCESS = "GET_ALL_BUILDINGS_DROP_DOWN_SUCCESS";
export const GET_ALL_BUILDINGS_DROP_DOWN_FAILURE = "GET_ALL_BUILDINGS_DROP_DOWN_FAILURE";