export const GET_ASSET_CONDITION_SETTINGS_DATA_REQUEST = "GET_ASSET_CONDITION_SETTINGS_DATA_REQUEST";
export const GET_ASSET_CONDITION_SETTINGS_DATA_SUCCESS = "GET_ASSET_CONDITION_SETTINGS_DATA_SUCCESS";
export const GET_ASSET_CONDITION_SETTINGS_DATA_FAILURE = "GET_ASSET_CONDITION_SETTINGS_DATA_FAILURE";

export const ADD_ASSET_CONDITION_REQUEST = "ADD_ASSET_CONDITION_REQUEST";
export const ADD_ASSET_CONDITION_SUCCESS = "ADD_ASSET_CONDITION_SUCCESS";
export const ADD_ASSET_CONDITION_FAILURE = "ADD_ASSET_CONDITION_FAILURE";

export const GET_ASSET_CONDITION_BY_ID_REQUEST = "GET_ASSET_CONDITION_BY_ID_REQUEST";
export const GET_ASSET_CONDITION_BY_ID_SUCCESS = "GET_ASSET_CONDITION_BY_ID_SUCCESS";
export const GET_ASSET_CONDITION_BY_ID_FAILURE = "GET_ASSET_CONDITION_BY_ID_FAILURE";

export const UPDATE_ASSET_CONDITION_REQUEST = "UPDATE_ASSET_CONDITION_REQUEST";
export const UPDATE_ASSET_CONDITION_SUCCESS = "UPDATE_ASSET_CONDITION_SUCCESS";
export const UPDATE_ASSET_CONDITION_FAILURE = "UPDATE_ASSET_CONDITION_FAILURE";

export const DELETE_ASSET_CONDITION_REQUEST = "DELETE_ASSET_CONDITION_REQUEST";
export const DELETE_ASSET_CONDITION_SUCCESS = "DELETE_ASSET_CONDITION_SUCCESS";
export const DELETE_ASSET_CONDITION_FAILURE = "DELETE_ASSET_CONDITION_FAILURE";

// Update Floor Entity Param
export const UPDATE_ASSET_CONDITION_ENTITY_PARAMS_SUCCESS = "UPDATE_ASSET_CONDITION_ENTITY_PARAMS_SUCCESS";
export const UPDATE_ASSET_CONDITION_ENTITY_PARAMS_FAILURE = "UPDATE_ASSET_CONDITION_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_ASSET_CONDITION_LOG_REQUEST = "GET_ALL_ASSET_CONDITION_LOG_REQUEST";
export const GET_ALL_ASSET_CONDITION_LOG_SUCCESS = "GET_ALL_ASSET_CONDITION_LOG_SUCCESS";
export const GET_ALL_ASSET_CONDITION_LOG_FAILURE = "GET_ALL_ASSET_CONDITION_LOG_FAILURE";

export const RESTORE_SETTINGS_LOG_REQUEST = "RESTORE_SETTINGS_LOG_REQUEST";
export const RESTORE_SETTINGS_LOG_SUCCESS = "RESTORE_SETTINGS_LOG_SUCCESS";
export const RESTORE_SETTINGS_LOG_FAILURE = "RESTORE_SETTINGS_LOG_FAILURE";

export const DELETE_SETTINGS_LOG_REQUEST = "DELETE_SETTINGS_LOG_REQUEST";
export const DELETE_SETTINGS_LOG_SUCCESS = "DELETE_SETTINGS_LOG_SUCCESS";
export const DELETE_SETTINGS_LOG_FAILURE = "DELETE_SETTINGS_LOG_FAILURE";

export const GET_ASSET_CONDITION_EXPORT_REQUEST = "GET_ASSET_CONDITION_EXPORT_REQUEST";
export const GET_ASSET_CONDITION_EXPORT_SUCCESS = "GET_ASSET_CONDITION_EXPORT_SUCCESS";
export const GET_ASSET_CONDITION_EXPORT_FAILURE = "GET_ASSET_CONDITION_EXPORT_FAILURE";
