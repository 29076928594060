import React from "react";
import ReactTooltip from "react-tooltip";

export const AddRemoveButton = ({ onAdd }) => {
    return (
        <>
            <ReactTooltip
                id="add-remove-button"
                effect="solid"
                place="top"
                backgroundColor="#007bff"
                className="rc-tooltip-custom-class"
                html={true}
            />
            <button className="btn btn-add" data-tip={"Add Next Year"} data-for="add-remove-button" onClick={() => onAdd()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                    <g id="Group_55" data-name="Group 55" transform="translate(-1217 -1006)">
                        <rect
                            id="Rectangle_86"
                            data-name="Rectangle 86"
                            width="45"
                            height="45"
                            rx="22.5"
                            transform="translate(1217 1006)"
                            fill="#c3cad0"
                        />
                        <path
                            id="Path_8"
                            data-name="Path 8"
                            d="M15.844-.263H9.735V5.932H7.3V-.263H1.218V-2.471H7.3V-8.694H9.735v6.223h6.108Z"
                            transform="translate(1230.782 1029.694)"
                            fill="#848e97"
                        />
                    </g>
                </svg>
            </button>
        </>
    );
};
