import { isEmpty, uniqBy } from "lodash";
import React from "react";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import FilterIcon from "../../assets/img/dashboard/filter-butn.svg";
import moment from "moment";

export const AppliedFilters = ({ onCancel, myFilters, openFiltersPopup, showFiltersIcon, filterValues, tableConfig, source, MyfilterName }) => {
    const { filterContents = {}, masterFilterList, filterFields, dashboardExtraFilters: queries } = useSelector(state => state.dashboardReducer);
    const getAppliedFiltersList = filters => {
        let filterNames = [];
        filters &&
            Object.entries(filters).forEach(([key, value]) => {
                uniqBy(filterFields, "key").forEach(fi => {
                    if (key === "filters" && !isEmpty(filters[key])) {
                        Object.entries(value).forEach(([wKey, wValue]) => {
                            if (fi.paramKey === wKey) {
                                filterNames.push({
                                    name: fi.label,
                                    value:
                                        fi.paramKey === "buildings.redline_completed_date"
                                            ? [`${wValue?.filters?.[0]} ${wValue?.key?.from}-${wValue?.key?.to}`]
                                            : [`${wValue?.key || ""} [${wValue?.filters?.join(",")}]`]
                                });
                            }
                        });
                    } else if (key === "top_n" && !isEmpty(filters[key])) {
                        Object.entries(value).forEach(([wKey, wValue]) => {
                            if (fi.paramKey === wKey) {
                                filterNames.push({
                                    name: fi.label,
                                    value: [`${wValue || ""} [Top N]`]
                                });
                            }
                        });
                    } else if (fi.paramKey === key) {
                        const names = masterFilterList?.[fi.key]?.filter(ms => value.includes(ms.id))?.map(item => item.name) || [];
                        filterNames.push({
                            name: fi.label,
                            value: key === "building_types" ? value : names
                        });
                    }
                });
            });
        filters?.exclude_filters &&
            Object.entries(filters?.exclude_filters).forEach(([key, value]) => {
                uniqBy(filterFields, "key").forEach(fi => {
                    if (fi.paramKey === key) {
                        const names = masterFilterList?.[fi.key]?.filter(ms => value.includes(ms.id))?.map(item => item.name) || [];
                        filterNames.push({
                            name: `${fi.label}-Exclude`,
                            value: key === "building_types" ? value : names
                        });
                    }
                });
            });
        if (filters?.start_year && filters?.end_year) {
            filterNames.push({ name: "Years", value: { start: filters?.start_year, end: filters?.end_year } });
        }
        return filterNames;
    };
    const FilterNameFormat = data => {
        const formattedData = [];
        for (const key in data) {
            formattedData.push({ name: key, value: data[key] });
        }
        return formattedData;
    };

    const renderFilters = filters => {
        return (
            <>
                {filters &&
                    Object.values(filters).map(f => {
                        return f.name !== "Years" ? (
                            f?.value?.length > 0 && (
                                <>
                                    <span className="tag active">{f.name} </span>
                                    {f.value.map(item => {
                                        return (
                                            <span key={item} className="tag">
                                                {item}
                                            </span>
                                        );
                                    })}
                                    <br />
                                </>
                            )
                        ) : (
                            <>
                                <span className="tag active">{f.name} </span>
                                <span className="tag">Start : {f.value.start}</span>
                                <span className="tag">End : {f.value.end}</span>
                            </>
                        );
                    })}
            </>
        );
    };

    const renderListFilter = values => {
        return Object.keys(values).map(fi => {
            let label = "";
            if (tableConfig && Object.keys(tableConfig)?.length) {
                label = Object.values(tableConfig || {}).find(field => field.searchKey === fi)?.label || "";
            }
            label = label ? label : fi;
            if (values[fi] && values[fi].length) {
                return values[fi].map(r => {
                    return (
                        <span className="filter">
                            {label} : {r === true ? "Yes" : r === false ? "No" : r}
                        </span>
                    );
                });
            } else if (values[fi] && values[fi].name) {
                return values[fi].name.map(r => {
                    return (
                        <span className="filter">
                            {label} : {r === true ? "Yes" : r === false ? "No" : r}
                        </span>
                    );
                });
            } else if (values[fi] && values[fi].year) {
                return values[fi].year.map(r => {
                    return <span className="filter">year : {r}</span>;
                });
            } else if (values[fi] && values[fi].month) {
                return values[fi].month.map(r => {
                    return <span className="filter">month : {r}</span>;
                });
            } else if (values[fi] && values[fi].account_type) {
                return values[fi].account_type.map(r => {
                    return (
                        <span className="filter">
                            {fi} : {r}
                        </span>
                    );
                });
            } else if (values[fi] && values[fi].meter) {
                return values[fi].meter.map(r => {
                    return (
                        <span className="filter">
                            {fi} : {r}
                        </span>
                    );
                });
            } else if (values[fi] && values[fi].meter_type) {
                return values[fi].meter_type.map(r => {
                    return (
                        <span className="filter">
                            {fi} : {r}
                        </span>
                    );
                });
            } else if (values[fi] && values[fi].hospital_name) {
                return values[fi].hospital_name.map(r => {
                    return (
                        <span className="filter">
                            {fi} : {r}
                        </span>
                    );
                });
            } else if (values[fi] && values[fi].file_type) {
                return values[fi].file_type.map(r => {
                    return (
                        <span className="filter">
                            {fi} : {r}
                        </span>
                    );
                });
            } else if (values[fi] && values[fi].description) {
                return values[fi].description.map(r => {
                    return (
                        <span className="filter">
                            {fi} : {r}
                        </span>
                    );
                });
            } else if (values[fi] && values[fi].file_name) {
                return values[fi].file_name.map(r => {
                    return (
                        <span className="filter">
                            {fi} : {r}
                        </span>
                    );
                });
            } else if (values[fi] && values[fi].document_type) {
                return values[fi].document_type.map(r => {
                    return (
                        <span className="filter">
                            {fi} : {r}
                        </span>
                    );
                });
            }
        });
    };

    const renderWildCardFilter = values => {
        return Object.keys(values).map(fi => {
            let label = "";
            let type = "";
            if (tableConfig && Object.keys(tableConfig)?.length) {
                const config = Object.values(tableConfig || {}).find(field => field.searchKey === fi) || {};
                label = config?.label || "";
                type = config?.type || "";
            }
            label = label ? label : fi.replace(".", ".");
            if (type === "date" && values[fi].key) {
                return (
                    <span key={fi} className="filter">
                        {`${label} : ${moment(values[fi]?.key?.from).format("MM-DD-YYYY hh:mm A")}, ${moment(values[fi].key.to).format(
                            "MM-DD-YYYY hh:mm A"
                        )} [${values[fi].filters}]`}
                    </span>
                );
            } else {
                return values[fi] && (values[fi].key || values[fi].filters?.includes("null") || values[fi].filters?.includes("not_null")) ? (
                    <span key={fi} className="filter">
                        {`${label} : ${values[fi].key} [${values[fi].filters}]`}
                    </span>
                ) : null;
            }
        });
    };

    const uniqueDataMap = {};
    let uniqueData = [];
    if (MyfilterName) {
        [...FilterNameFormat(MyfilterName), ...getAppliedFiltersList(myFilters)].forEach(item => {
            if (
                !uniqueDataMap[item.name] &&
                (getAppliedFiltersList(myFilters)?.filter(e => e.name === item?.name)?.length > 0 || item?.name === "Projects")
            ) {
                uniqueDataMap[item.name] = item;
            } else {
                const names = filterFields?.find(e => e?.label === item?.name)?.paramKey || "";
                if (
                    (item?.value?.length > 0 || uniqueDataMap[item.name]?.value?.length === 0 || myFilters[names]?.length === 0) &&
                    getAppliedFiltersList(myFilters)?.filter(e => e.name === item?.name)?.length > 0
                ) {
                    uniqueDataMap[item.name] = item;
                }
            }
        });
        uniqueData = Object.values(uniqueDataMap);
    }
    const appliedFilters = MyfilterName ? uniqueData?.filter(item => item.name !== "FCA Projects") : getAppliedFiltersList(myFilters);

    const renderQueries = () => {
        let filterNames = [];
        if (queries?.length) {
            filterNames.push({ name: "Queries", value: queries?.map(query => query.name) });
            return filterNames;
        }
    };
    return (
        <React.Fragment>
            <div className="modal modal-region modal-view modal-applied-filter" id="modalId" style={{ display: "block" }} tabIndex="-1">
                <Draggable handle=".draggable" cancel=".not-draggable">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    <div className="txt-hed">Applied Filters </div>
                                </h5>
                                {showFiltersIcon && (
                                    <button
                                        className={`filter-button blue-common-btn mr-4 cursor-pointer not-draggable`}
                                        data-tip={`View Filters`}
                                        data-for="dashboard-icons-right"
                                        onClick={() => openFiltersPopup()}
                                    >
                                        <img src={FilterIcon} alt="" />
                                        <span> Filters</span>
                                    </button>
                                )}
                                <button type="button" className="close not-draggable" onClick={onCancel}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body region-otr filter-apply">
                                {source === "dashboard" ? (
                                    <div className="fil-badge-otr">
                                        {source === "recommendation" && <h6>From Dashboard</h6>}
                                        {(!isEmpty(appliedFilters) || !isEmpty(renderQueries())) && (
                                            <div className="tag-ara-filtr">
                                                <div className={"tag-scrol"}>
                                                    <>
                                                        {renderFilters(appliedFilters)}
                                                        {renderFilters(renderQueries())}
                                                    </>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : source === "recommendation" ? (
                                    <>
                                        <div className="fil-badge-otr">
                                            {source === "recommendation" && <h6>From Dashboard</h6>}
                                            {!isEmpty(filterContents) && (
                                                <div className="tag-ara">
                                                    <div className={"tag-scrol"}>{renderFilters(filterContents)}</div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : null}
                                {(source === "asset" || source === "recommendation") && (
                                    <div className="fil-badge-otr">
                                        {source === "recommendation" && <h6>From Local</h6>}
                                        {!isEmpty(appliedFilters && filterValues) && (
                                            <div className="tag-ara">
                                                <div className={"tag-scrol"}>
                                                    {
                                                        <>
                                                            {renderFilters(appliedFilters)}
                                                            {filterValues &&
                                                                Object.keys(filterValues).map((f, key) => {
                                                                    if (f === "filters" && filterValues[f] && Object.keys(filterValues[f])?.length)
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">Wild card filter </span>
                                                                                {renderWildCardFilter(filterValues[f])}
                                                                            </>
                                                                        );
                                                                    if (
                                                                        f === "list" &&
                                                                        filterValues[f] &&
                                                                        typeof filterValues[f] === "object" &&
                                                                        Object.entries(filterValues[f]).length !== 0
                                                                    )
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">Common filter </span>
                                                                                {renderListFilter(filterValues[f])}
                                                                            </>
                                                                        );
                                                                    if (f === "search" && filterValues[f])
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">Global search : {filterValues[f]} </span>
                                                                            </>
                                                                        );
                                                                    if (f === "surveyor" && filterValues[f])
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">Surveyor : {filterValues[f]} </span>
                                                                            </>
                                                                        );
                                                                    if (f === "image_or_not" && filterValues[f])
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">
                                                                                    Images Present : {filterValues[f] === "true" ? "Yes" : "No"}{" "}
                                                                                </span>
                                                                            </>
                                                                        );
                                                                    if (f === "infrastructure_request" && filterValues[f])
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">
                                                                                    Capital Request : {filterValues[f]}{" "}
                                                                                </span>
                                                                            </>
                                                                        );
                                                                    if (f === "water" && filterValues[f])
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">Water Present : {filterValues[f]} </span>
                                                                            </>
                                                                        );
                                                                    if (f === "energy" && filterValues[f])
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">Energy Present : {filterValues[f]} </span>
                                                                            </>
                                                                        );
                                                                    if (f === "fmp" && filterValues[f])
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">FMP Present : {filterValues[f]} </span>
                                                                            </>
                                                                        );
                                                                    if (f === "facility_master_plan" && filterValues[f])
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">FMP Present : {filterValues[f]} </span>
                                                                            </>
                                                                        );
                                                                    if (f === "recommendation_type" && filterValues[f])
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">
                                                                                    Recommendation Type : {filterValues[f]}{" "}
                                                                                </span>
                                                                            </>
                                                                        );
                                                                    if (f === "budget_priority" && filterValues[f])
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">
                                                                                    Budget Priority : {filterValues[f]}{" "}
                                                                                </span>
                                                                            </>
                                                                        );

                                                                    if (f === "recommendation_assigned_true" && filterValues[f])
                                                                        return (
                                                                            <>
                                                                                {" "}
                                                                                <span className="badge-otr">
                                                                                    Recommendation Assigned :{" "}
                                                                                    {filterValues[f] === "true" ? "Yes" : "No"}{" "}
                                                                                </span>
                                                                            </>
                                                                        );
                                                                })}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
        </React.Fragment>
    );
};
