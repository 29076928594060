// Get all sites
export const GET_ALL_BUILDING_TYPES_REQUEST = "GET_ALL_BUILDING_TYPES_REQUEST";
export const GET_ALL_BUILDING_TYPES_SUCCESS = "GET_ALL_BUILDING_TYPES_SUCCESS";
export const GET_ALL_BUILDING_TYPES_FAILURE = "GET_ALL_BUILDING_TYPES_FAILURE";

// Add site
export const ADD_BUILDING_TYPE_REQUEST = "ADD_BUILDING_TYPE_REQUEST";
export const ADD_BUILDING_TYPE_SUCCESS = "ADD_BUILDING_TYPE_SUCCESS";
export const ADD_BUILDING_TYPE_FAILURE = "ADD_BUILDING_TYPE_FAILURE";

// PARSE FCA
export const PARSE_FCA_REQUEST = "PARSE_FCA_REQUEST";
export const PARSE_FCA_SUCCESS = "PARSE_FCA_SUCCESS";
export const PARSE_FCA_FAILURE = "PARSE_FCA_FAILURE";

// Update site
export const UPDATE_BUILDING_TYPE_REQUEST = "UPDATE_BUILDING_TYPE_REQUEST";
export const UPDATE_BUILDING_TYPE_SUCCESS = "UPDATE_BUILDING_TYPE_SUCCESS";
export const UPDATE_BUILDING_TYPE_FAILURE = "UPDATE_BUILDING_TYPE_FAILURE";

// Delete site
export const DELETE_BUILDING_TYPE_REQUEST = "DELETE_BUILDING_TYPE_REQUEST";
export const DELETE_BUILDING_TYPE_SUCCESS = "DELETE_BUILDING_TYPE_SUCCESS";
export const DELETE_BUILDING_TYPE_FAILURE = "DELETE_BUILDING_TYPE_FAILURE";

// Ger region based on client
export const GET_REGIONS_BASED_ON_CLIENT_REQUEST = "GET_REGIONS_BASED_ON_CLIENT_REQUEST";
export const GET_REGIONS_BASED_ON_CLIENT_SUCCESS = "GET_REGIONS_BASED_ON_CLIENT_SUCCESS";
export const GET_REGIONS_BASED_ON_CLIENT_FAILURE = "GET_REGIONS_BASED_ON_CLIENT_FAILURE";

// Get all consultancy users
export const GET_ALL_CONSULTANCY_USERS_REQUEST = "GET_ALL_CONSULTANCY_USERS_REQUEST";
export const GET_ALL_CONSULTANCY_USERS_SUCCESS = "GET_ALL_CONSULTANCY_USERS_SUCCESS";
export const GET_ALL_CONSULTANCY_USERS_FAILURE = "GET_ALL_CONSULTANCY_USERS_FAILURE";

// Get all Clients
export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const GET_ALL_CLIENTS_FAILURE = "GET_ALL_CLIENTS_FAILURE";

// Get Site by Id
export const GET_BUILDING_TYPE_BY_ID_REQUEST = "GET_BUILDING_TYPE_BY_ID_REQUEST";
export const GET_BUILDING_TYPE_BY_ID_SUCCESS = "GET_BUILDING_TYPE_BY_ID_SUCCESS";
export const GET_BUILDING_TYPE_BY_ID_FAILURE = "GET_BUILDING_TYPE_BY_ID_FAILURE";

// Upload Image
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

// Get all Images
export const GET_ALL_IMAGES_REQUEST = "GET_ALL_IMAGES_REQUEST";
export const GET_ALL_IMAGES_SUCCESS = "GET_ALL_IMAGES_SUCCESS";
export const GET_ALL_IMAGES_FAILURE = "GET_ALL_IMAGES_FAILURE";

// Delete Images
export const DELETE_IMAGES_REQUEST = "DELETE_IMAGES_REQUEST";
export const DELETE_IMAGES_SUCCESS = "DELETE_IMAGES_SUCCESS";
export const DELETE_IMAGES_FAILURE = "DELETE_IMAGES_FAILURE";

// Delete Images
export const UPDATE_BUILDING_TYPE_ENTITY_PARAMS_SUCCESS =
    "UPDATE_BUILDING_TYPE_ENTITY_PARAMS_SUCCESS";
export const UPDATE_BUILDING_TYPE_ENTITY_PARAMS_FAILURE =
    "UPDATE_BUILDING_TYPE_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_BUILDING_TYPE_LOG_REQUEST = "GET_ALL_BUILDING_TYPE_LOG_REQUEST";
export const GET_ALL_BUILDING_TYPE_LOG_SUCCESS = "GET_ALL_BUILDING_TYPE_LOG_SUCCESS";
export const GET_ALL_BUILDING_TYPE_LOG_FAILURE = "GET_ALL_BUILDING_TYPE_LOG_FAILURE";

export const RESTORE_BUILDING_TYPE_LOG_REQUEST = "RESTORE_BUILDING_TYPE_LOG_REQUEST";
export const RESTORE_BUILDING_TYPE_LOG_SUCCESS = "RESTORE_BUILDING_TYPE_LOG_SUCCESS";
export const RESTORE_BUILDING_TYPE_LOG_FAILURE = "RESTORE_BUILDING_TYPE_LOG_FAILURE";

export const DELETE_BUILDING_TYPE_LOG_REQUEST = "DELETE_BUILDING_TYPE_LOG_REQUEST";
export const DELETE_BUILDING_TYPE_LOG_SUCCESS = "DELETE_BUILDING_TYPE_LOG_SUCCESS";
export const DELETE_BUILDING_TYPE_LOG_FAILURE = "DELETE_BUILDING_TYPE_LOG_FAILURE";

export const GET_BUILDING_TYPE_EXPORT_REQUEST = "GET_BUILDING_TYPE_EXPORT_REQUEST";
export const GET_BUILDING_TYPE_EXPORT_SUCCESS = "GET_BUILDING_TYPE_EXPORT_SUCCESS";
export const GET_BUILDING_TYPE_EXPORT_FAILURE = "GET_BUILDING_TYPE_EXPORT_FAILURE";


export const GET_BUILDING_TYPE_COLOR_CODE_REQUEST = "GET_BUILDING_TYPE_COLOR_CODE_REQUEST";
export const GET_BUILDING_TYPE_COLOR_CODE_SUCCESS = "GET_BUILDING_TYPE_COLOR_CODE_SUCCESS";
export const GET_BUILDING_TYPE_COLOR_CODE_FAILURE = "GET_BUILDING_TYPE_COLOR_CODE_FAILURE";

export const ADD_BUILDING_TYPE_COLOR_CODE_REQUEST = "ADD_BUILDING_TYPE_COLOR_CODE_REQUEST";
export const ADD_BUILDING_TYPE_COLOR_CODE_SUCCESS = "ADD_BUILDING_TYPE_COLOR_CODE_SUCCESS";
export const ADD_BUILDING_TYPE_COLOR_CODE_FAILURE = "ADD_BUILDING_TYPE_COLOR_CODE_FAILURE";

export const UPDATE_BUILDING_TYPE_COLOR_CODE_REQUEST = "UPDATE_BUILDING_TYPE_COLOR_CODE_REQUEST";
export const UPDATE_BUILDING_TYPE_COLOR_CODE_SUCCESS = "UPDATE_BUILDING_TYPE_COLOR_CODE_SUCCESS";
export const UPDATE_BUILDING_TYPE_COLOR_CODE_FAILURE = "UPDATE_BUILDING_TYPE_COLOR_CODE_FAILURE";

export const DELETE_BUILDING_TYPE_COLOR_CODE_REQUEST = "DELETE_BUILDING_TYPE_COLOR_CODE_REQUEST";
export const DELETE_BUILDING_TYPE_COLOR_CODE_SUCCESS = "DELETE_BUILDING_TYPE_COLOR_CODE_SUCCESS";
export const DELETE_BUILDING_TYPE_COLOR_CODE_FAILURE = "DELETE_BUILDING_TYPE_COLOR_CODE_FAILURE";