import React, { Fragment, Suspense } from "react";
import { Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { APP_MODE } from "../../../config/constants";
import ErrorBoundary from "./ErrorBoundary";
import SideMenu from "./SideMenu";
import TopBar from "./TopBar";
import { onMessageListener } from "../../../config/firebase";
import { ToastContainer } from "react-toastify";
import ToastMsg from "./ToastMessage";
import notificationActions from "../../notification/actions";
import PageNotFound from "./PageNotFound";

class PrivateRoute extends React.Component {
    state = {
        showNav: true
    };

    componentDidMount = () => {
        const redirectAfterLoginRoutes = [
            "/project-capital-request/:section/:id",
            "/assetmanagement/:section/:id/:tab",
            "/recommendations/:section/:id/:tab"
        ];
        if (!localStorage.getItem("fca-token") && redirectAfterLoginRoutes.includes(this.props.path)) {
            localStorage.setItem("prevRoute", this.props.location.pathname);
        }
    };

    componentDidUpdate = prevProps => {
        const windowWidth = window.innerWidth;
        const sidebarElement = document.getElementById("sidebar");
        if (prevProps.path !== this.props.path) {
            if (windowWidth < 991 && sidebarElement) {
                sidebarElement.classList.add("expandnavbar");
            }
        }
    };
    handleShowNa = () => {
        this.setState({
            showNav: !this.state.showNav
        });
    };

    render() {
        const { component: Component, ...rest } = this.props;
        onMessageListener()
            .then(payload => {
                this.props.getUnreadNotifications();
                if (payload?.notification?.title) {
                    ToastMsg({ title: payload.notification.title, body: payload.notification.body });
                }
            })
            .catch(err => console.log("failed: ", err));

        const isCrUser = localStorage.getItem("infrastructure_request_user") === "yes";

        return (
            <Route
                {...rest}
                render={props =>
                    localStorage.getItem("fca-token") ? (
                        <Fragment>
                            {isCrUser ? (
                                <>
                                    {props.match.params.tab === "cr-recommendations" ||
                                    props.match.path === "/recommendations/:section" ||
                                    props.match.path === "/recommendations/:section/:id" ||
                                    props.match.path === "/recommendations/:section/:id/:tab" ||
                                    props.match.path === "/documents/:section" ||
                                    props.match.path === "/documents/:section/:id" ||
                                    props.match.path === "/documents/:section/:id/:tab" ? (
                                        <div className="dashboard-outer">
                                            <div className={`outer-detail outer-height`}>
                                                <div className={`right-panel-section expandnavbarr`} id="main">
                                                    <TopBar
                                                        handleShowNa={this.handleShowNa}
                                                        showNav={true}
                                                        hasDefaultProjectMenu={false}
                                                        isCrHeader
                                                    />
                                                    <ErrorBoundary>
                                                        <Suspense fallback={<></>}>
                                                            <Component {...props} />
                                                        </Suspense>
                                                    </ErrorBoundary>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <PageNotFound isCR />
                                    )}
                                </>
                            ) : props.match.path === "/home" ? (
                                <ErrorBoundary>
                                    <Suspense fallback={<></>}>
                                        <Component {...props} />
                                    </Suspense>
                                </ErrorBoundary>
                            ) : (
                                <div className="dashboard-outer">
                                    <div className={`outer-detail drop-nav ${props.match.path === "/dashboard" ? "dash-side-nav" : ""}`}>
                                        <SideMenu handleShowNa={this.handleShowNa} showNav={this.state.showNav} />
                                        <div className={`right-panel-section ${APP_MODE === "training" ? "right-common-blue-outer" : ""}`} id="main">
                                            {props.match.path === "/dashboard" ? null : (
                                                <TopBar handleShowNa={this.handleShowNa} showNav={this.state.showNav} hasDefaultProjectMenu={true} />
                                            )}
                                            <ErrorBoundary>
                                                <Suspense fallback={<></>}>
                                                    <Component {...props} />
                                                </Suspense>
                                            </ErrorBoundary>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    ) : (
                        <Redirect to={{ pathname: "/" }} />
                    )
                }
            />
        );
    }
}
export default withRouter(connect(null, { ...notificationActions })(PrivateRoute));
