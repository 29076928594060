import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import Loader from "../../../common/components/Loader";
import { areEqual } from "react-window";
import { debounce } from "lodash";
import { WildCardFilterItem } from "./WildCardFilterItem";
import { EllipsisTooltipText } from "../../../common/components/EllipsisTextWithTooltip";
import StartIcon from "../../../../assets/img/star.svg";
import StartActiveIcon from "../../../../assets/img/star-active.svg";
export const FilterItem = memo(
    ({
        elem,
        showDropDown,
        handleFilterView,
        filterList = {},
        handleFilterUpdate,
        filterData: filterParams,
        yearValues,
        setShowDropdown,
        setWildCardFilter,
        isAssetFilter,
        showWildcard,
        setShowWildcard,
        favorites,
        toggleFavorite,
        showWildcardField,
        setWildcardField
    }) => {
        let { loading, list: options = [], count: filterCount } = filterList || {};

        const initialData =
            elem.key === "year"
                ? {
                      start_year: filterParams?.start_year || yearValues?.[0],
                      end_year: filterParams?.end_year || yearValues?.[yearValues?.length - 1],
                      update_fci: filterParams?.update_fci || true
                  }
                : filterParams?.[elem.paramKey] || [];
        const [filterData, setFilterData] = useState(initialData);
        const [search, setSearch] = useState("");
        const dropdownRef = useRef();

        useEffect(() => {
            setFilterData(initialData);
            setSearch("");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filterParams, showDropDown]);

        const handleChange = item => {
            let tempList = [];
            if (elem.fieldType === "select") {
                tempList = filterData.includes(item) ? [] : [item];
            } else {
                tempList = filterData.includes(item) ? filterData.filter(elem => elem !== item) : [...filterData, item];
            }
            setFilterData(tempList);
        };

        const handleChangeYear = e => {
            const { name, value, checked } = e.target;
            setFilterData({ ...filterData, [name]: name === "update_fci" ? checked : parseInt(value) });
        };

        const handleSelectAll = e => {
            setFilterData(e.target.checked ? searchList.map(opt => opt.id) : []);
        };

        const handleClearSelection = () => {
            setFilterData([]);
        };

        if ((elem.key === "building_types" || isAssetFilter) && options.length) {
            options = options?.map(item => ({
                ...item,
                id: item.name,
                name: item.name === true ? "Yes" : item.name === false ? "No" : item.name,
                count: item.count
            }));
        }

        const handleFilterSearch = async value => {
            setSearch(value);
            if (filterCount > 0 && filterCount !== options?.length) {
                debounceSearch(value);
            }
        };

        const debounceSearch = useCallback(
            debounce(search => {
                handleFilterView({ ...elem, search, fetchMore: true }, dropdownRef);
            }, 1000),
            []
        );

        let searchList = options;
        if (search?.trim()?.length && filterCount <= options.length) {
            searchList = options?.filter(
                item =>
                    item?.name?.toLowerCase()?.includes(search?.toLowerCase()) || item?.description?.toLowerCase()?.includes(search?.toLowerCase())
            );
        }

        const availableOptions = searchList.filter(opt => !filterData.includes(opt.id));
        const selectedOptions = options.filter(opt => filterData.includes(opt.id));
        const isAllSelected = selectedOptions?.length > 0 && searchList.every(elem => filterData.includes(elem.id));
        const isFiltered = elem.key === "year" ? filterParams?.start_year || filterParams?.end_year : filterParams?.[elem.paramKey]?.length > 0;
        const isFavorite = favorites.includes(elem.key);
        return (
            <div key={elem.key} ref={dropdownRef} className={`col list-prop-sec dshb`}>
                <>
                    <ReactTooltip
                        id="favorite"
                        className="rc-tooltip-custom-class"
                        backgroundColor="#007bff"
                        place="top"
                        effect="solid"
                        delayShow={500}
                    />
                    <div className={showDropDown === elem.key ? "dropdown-container dropdown-open" : "dropdown-container"}>
                        <div className="d-flex pr-tot-outr">
                            <i
                                data-tip={isFavorite ? "Remove from favorites" : "Add to favorites"}
                                data-for="favorite"
                                onClick={() => toggleFavorite(elem.key)}
                            >
                                {isFavorite ? <img src={StartActiveIcon} alt="" /> : <img src={StartIcon} alt="" />}
                            </i>
                            <EllipsisTooltipText className="wild-card-filter-label" text={elem?.label} asLabel />
                        </div>
                        <div
                            className={`dropdown-toggle click-dropdown ${isFiltered ? "bg-th-filtered" : ""}`}
                            onClick={() => handleFilterView(elem, dropdownRef)}
                        >
                            Select
                            <span className="close-reg">
                                <i className="fas fa-chevron-down"></i>
                            </span>
                        </div>

                        <div className={showDropDown === elem.key ? "dropdown-menu  dropdown-active " : "dropdown-menu "}>
                            {loading ? (
                                <div className="col-md-12 p-4">
                                    <Loader />
                                </div>
                            ) : options?.length || elem.key === "year" ? (
                                <>
                                    <div
                                        className={`dropdown-menu drop-filtr pos-abs ${elem.key !== "year" ? "dp-rcm-overflow" : ""} mt-0`}
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <>
                                            {elem.key === "year" ? (
                                                <YearFilter year={filterData} yearValues={yearValues} handleChangeYear={handleChangeYear} />
                                            ) : (
                                                <>
                                                    <div className="col-md-12 p-0 slt">
                                                        <span className="dropdown-item build_search_drp">
                                                            <input
                                                                type="search"
                                                                placeholder="Search..."
                                                                value={search}
                                                                onChange={e => handleFilterSearch(e.target.value)}
                                                            />
                                                            <i class="fas fa-times cursor-pointer cls-close" onClick={() => handleFilterSearch("")} />
                                                            {elem.fieldType !== "select" && (
                                                                <label className="container-check">
                                                                    {`Select All (${selectedOptions.length}/${
                                                                        selectedOptions?.length + availableOptions?.length
                                                                    }) ${filterCount > 100 ? `Total: ${filterCount}` : ""}`}

                                                                    <input type="checkbox" checked={isAllSelected} onChange={handleSelectAll} />
                                                                    <span className="checkmark"></span>
                                                                    <button className="clear-btn-selection" onClick={handleClearSelection}>
                                                                        Clear
                                                                    </button>
                                                                </label>
                                                            )}
                                                        </span>
                                                    </div>
                                                    {selectedOptions?.length > 0 && (
                                                        <div className="col-md-12 p-0 slt-ara border-bottom">
                                                            <ReactTooltip
                                                                id="master_filter_dropdown_selected"
                                                                className="rc-tooltip-custom-class"
                                                                effect="solid"
                                                                place="top"
                                                                backgroundColor="#007bff"
                                                                delayShow={500}
                                                            />
                                                            {selectedOptions.map(item => (
                                                                <span className="dropdown-item" key={item.id}>
                                                                    <label className="container-check d-flex align-items-center">
                                                                        <span
                                                                            className="text-short"
                                                                            data-tip={`${
                                                                                item.description ? `${item.name} (${item.description})` : ""
                                                                            }`}
                                                                            data-for="master_filter_dropdown_selected"
                                                                        >
                                                                            {item.name || "-"} {item.description ? `(${item.description})` : ""}
                                                                        </span>
                                                                        <span className="count-num"> ({item.count})</span>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={true}
                                                                            onChange={() => handleChange(item.id)}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </span>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {availableOptions?.length > 0 && (
                                                        <div className="col-md-12 p-0 slt-ara">
                                                            <ReactTooltip
                                                                id="master_filter_dropdown_available"
                                                                className="rc-tooltip-custom-class"
                                                                effect="solid"
                                                                place="top"
                                                                backgroundColor="#007bff"
                                                                delayShow={500}
                                                            />
                                                            {availableOptions.map(item => (
                                                                <span className="dropdown-item" key={item.id}>
                                                                    <label className="container-check d-flex align-items-center">
                                                                        <span
                                                                            className="text-short"
                                                                            data-tip={`${
                                                                                item.description ? `${item.name} (${item.description})` : ""
                                                                            }`}
                                                                            data-for="master_filter_dropdown_available"
                                                                        >
                                                                            {item.name || "-"} {item.description ? `(${item.description})` : ""}
                                                                        </span>
                                                                        <span className="count-num"> ({item.count})</span>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={false}
                                                                            onChange={() => handleChange(item.id)}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </span>
                                                            ))}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </>
                                        {/* <div className="col-md-6 no-wrap">NO MORE VALUES TO DISPLAY</div> */}
                                        <div className="col-md-12 mt-3 drp-btn">
                                            <button
                                                type="button"
                                                className="btn btn-primary btnRgion mr-2"
                                                onClick={() => handleFilterUpdate(elem, filterData)}
                                            >
                                                OK
                                            </button>
                                            <button type="button" className="btn btn-primary btnClr" onClick={() => handleFilterView(elem)}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="col-md-12 p-2"> No Data Found</div>
                            )}
                        </div>
                    </div>
                    {showWildcardField && (
                        <WildCardFilterItem
                            elem={elem}
                            showDropDown={showWildcard}
                            setShowDropdown={setShowWildcard}
                            filter={filterParams.filters?.[elem.paramKey] || {}}
                            setWildCardFilter={setWildCardFilter}
                            dropdownRef={dropdownRef}
                            handleFilterView={handleFilterView}
                            isFavorite={isFavorite}
                            toggleFavorite={toggleFavorite}
                            isDropdownFiltered={isFiltered}
                        />
                    )}
                </>
            </div>
        );
    },
    areEqual
);

const YearFilter = ({ yearValues, year, updateFCI, handleChangeYear }) => {
    return (
        <div className="drop-filtr pb-3">
            <div className="col-md-12 p-0 slt-ara">
                <div className="year-outer ">
                    <div className="col-xl-12 selecbox-otr p-0">
                        <span className="dropdown-item p-0">
                            <label>Start Year</label>
                            <div className="custom-selecbox w-100">
                                <select
                                    value={year?.start_year}
                                    name={"start_year"}
                                    onChange={e => {
                                        handleChangeYear(e);
                                    }}
                                >
                                    {yearValues?.length > 0 &&
                                        yearValues.map(year => {
                                            return (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </span>
                    </div>
                    <div className="col-xl-12 p-0 selecbox-otr p-0">
                        <span className="dropdown-item  p-0">
                            <label>End Year</label>
                            <div className="custom-selecbox w-100">
                                <select
                                    value={year?.end_year}
                                    name={"end_year"}
                                    onChange={e => {
                                        handleChangeYear(e);
                                    }}
                                >
                                    {yearValues?.length > 0 &&
                                        yearValues.map(year => {
                                            return (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </span>
                    </div>
                </div>
            </div>

            <div className="col-md-12 p-0  pt-1">
                <span className="dropdown-item">
                    <label className="container-check">
                        Static FCI
                        <input
                            type="checkbox"
                            checked={year?.update_fci}
                            name="update_fci"
                            onChange={e => {
                                handleChangeYear(e);
                            }}
                        />
                        <span className="checkmark"></span>
                    </label>
                </span>
            </div>
        </div>
    );
};
