import React from "react";
import ReactTooltip from "react-tooltip";

import { getDisplayNameFromKey, isRecomSectionBreak } from "../utils";

const ChartItem = ({
    item,
    setSmartChartData,
    chartItemData = null,
    currentBand,
    openChartItemConfigModal,
    provided,
    index,
    entity,
    toggleImageListForBandModal,
    toggleImageBandDeleteConfirmationModal,
    editImageBand,
    isEdit = false,
    updatePageBreakConfig,
    isExcelExport = false,
    isSkeletonView,
    buildingMfilter = [],
    siteMfilter = []
}) => {
    return (
        <div className="drag-item" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={`draggable-${index}`}>
            <div className="top-sec">
                <div className="icon">
                    <img src="/img/icon-squre.svg" />
                </div>
                {item.isImageBand ||
                item.isCAband ||
                item.isRecommendation ||
                item.isMultipleRecommendation ||
                item.isAssetOnePager ||
                item?.isMemo ||
                item?.isUserDoc ||
                (item?.isFilterReport && item?.isSelected) ? (
                    <ReactTooltip id={`sm-charts-chart-item${entity}`} effect="solid" place="bottom" backgroundColor="#007bff" />
                ) : null}
                <div className="icon-rght-b">
                    {item.isIndividualBuilding ? (
                        <>
                            <ReactTooltip id={`sm-charts-main-indv${entity}`} effect="solid" place="bottom" backgroundColor="#007bff" />
                            <div
                                className="icon-b"
                                data-tip="This chart/table will repeat for every building in scope"
                                data-for={`sm-charts-main-indv${entity}`}
                            >
                                <img src={`/img/sm-charts-all-buildings.svg`} />
                            </div>
                        </>
                    ) : null}

                    <div class="wrap-icon d-flex">
                        {item?.isMemo ? (
                            <div
                                class="edt-icn cursor-hand"
                                data-tip={item.isMemo ? "Edit Text" : ""}
                                data-for={`sm-charts-chart-item${entity}`}
                                onClick={() =>
                                    (entity === "project" || entity === "assets") &&
                                    item.isSelected &&
                                    openChartItemConfigModal({
                                        bandName: currentBand,
                                        chartKey: item?.isMemo ? item.docKey : item.name,
                                        entity: entity,
                                        itemConfig: item.config,
                                        isImageBand: item.isImageBand,
                                        isMemo: item?.isMemo,
                                        recomType: item.isMultipleRecommendation
                                            ? "multiple"
                                            : item.isOnePager
                                            ? "onepager"
                                            : item.isRecommendation
                                            ? "normal"
                                            : ""
                                    })
                                }
                            >
                                <i class="fa fa-edit text-edit"></i>
                            </div>
                        ) : null}
                        {(item.isRecommendation ||
                            item.isCAband ||
                            item?.isMultipleRecommendation ||
                            item?.isMemo ||
                            item?.isUserDoc ||
                            (item?.isFilterReport && item?.isSelected)) &&
                        !isExcelExport ? (
                            <div
                                class="edt-icn ey-icn cursor-hand"
                                data-tip={isRecomSectionBreak(item?.tileData) ? "add page break" : "remove page break"}
                                data-for={`sm-charts-chart-item${entity}`}
                                onClick={() => {
                                    updatePageBreakConfig(
                                        {
                                            bandName: currentBand,
                                            chartKey: item.docKey,
                                            entity: entity,
                                            name: item.name,
                                            selectedTile: item,
                                            remove_section_break: !isRecomSectionBreak(item?.tileData)
                                        },
                                        "remove_section_break"
                                    );
                                }}
                            >
                                <img
                                    src={`/img/${isRecomSectionBreak(item?.tileData) ? "section-break-recom-dis.svg" : "section-break-recom.svg"}`}
                                />
                            </div>
                        ) : null}
                        {item.isImageBand ||
                        item.isCAband ||
                        item.isMultipleRecommendation ||
                        item.isRecommendation ||
                        item.isOnePager ||
                        item?.isMemo ||
                        (item?.isFilterReport && item?.isSelected) ||
                        (item.isBsi && item?.isSelected) ? (
                            <div
                                class="edt-icn cursor-hand"
                                data-tip={
                                    item.isImageBand
                                        ? "Edit Image Band Name"
                                        : item.isMultipleRecommendation
                                        ? "Edit Recommendation Summary Band Name"
                                        : item.isRecommendation
                                        ? "Edit Recommendation Table Band Name"
                                        : item.isOnePager
                                        ? "Edit One Pager Band Name"
                                        : item.isMemo
                                        ? "Edit Text Band Name"
                                        : item?.isFilterReport
                                        ? "Edit Master Filter"
                                        : "Edit Comparative Analysis Band"
                                }
                                data-for={`sm-charts-chart-item${entity}`}
                                onClick={() =>
                                    editImageBand(
                                        {
                                            bandName: currentBand,
                                            chartKey: item.docKey,
                                            entity: entity,
                                            name: item.name || "Image Band",
                                            selectedTile: item
                                        },
                                        item.isCAband ? "CA" : "image"
                                    )
                                }
                            >
                                <img src="/img/sm-charts-img-edit.svg" />
                            </div>
                        ) : null}

                        {item.isImageBand ||
                        item.isCAband ||
                        item.isMultipleRecommendation ||
                        item.isRecommendation ||
                        item.isOnePager ||
                        (item.isUserDoc && !item.isImage) ||
                        item?.isMemo ? (
                            <div
                                class="edt-icn de-icn cursor-hand"
                                data-tip={
                                    item.isImageBand
                                        ? "Delete Image Band"
                                        : item.isCAband
                                        ? "Delete Comparative Analysis Band"
                                        : item.isMultipleRecommendation
                                        ? "Delete Recommendation Summary Band"
                                        : item.isRecommendation
                                        ? "Delete Recommendation Table Band"
                                        : item.isOnePager
                                        ? "Delete One Pager Band"
                                        : item.isMemo
                                        ? "Delete Text Band"
                                        : "Delete User Document"
                                }
                                data-for={`sm-charts-chart-item${entity}`}
                                onClick={() =>
                                    toggleImageBandDeleteConfirmationModal({
                                        bandName: currentBand,
                                        chartKey: item.docKey,
                                        entity: entity,
                                        type: item.isImageBand
                                            ? "Image Band"
                                            : item.isCAband
                                            ? "Comparative Analysis Band"
                                            : item.isMultipleRecommendation
                                            ? "Recommendation Summary Band"
                                            : item.isRecommendation
                                            ? "Recommendation Table Band"
                                            : item.isOnePager
                                            ? "One Pager Band"
                                            : item.isMemo
                                            ? "Memo"
                                            : "Document"
                                    })
                                }
                            >
                                <img src="/img/sm-charts-img-delete.svg" />
                            </div>
                        ) : null}
                        {item.isImageBand ? (
                            <div
                                class="edt-icn ey-icn cursor-hand"
                                data-tip="View Selected Images"
                                data-for={`sm-charts-chart-item${entity}`}
                                onClick={() =>
                                    toggleImageListForBandModal(
                                        {
                                            bandName: currentBand,
                                            chartKey: item.isImageBand ? item.docKey : item.name,
                                            entity: entity,
                                            itemConfig: item.config,
                                            isImageBand: item.isImageBand
                                        },
                                        true
                                    )
                                }
                            >
                                <img src="/img/sm-charts-img-eye.svg" />
                            </div>
                        ) : null}
                        <div className="checkbox">
                            <label
                                className={`container-check green-check ${
                                    isSkeletonView || (item.isBbi && !buildingMfilter.length) || (item.isBsi && !siteMfilter.length)
                                        ? "disabled-btn"
                                        : ""
                                }`}
                            >
                                <input
                                    type="checkbox"
                                    name="is_bold"
                                    onClick={e => {
                                        setSmartChartData("customize_chart", {
                                            bandName: currentBand,
                                            chartKey:
                                                item.isUserDoc ||
                                                item.isImageBand ||
                                                item.isBbi ||
                                                item.isBsi ||
                                                item.name === "Recommendation Table" ||
                                                item.name === "Recommendation Summary" ||
                                                item.name === "Recommendation One Pager" ||
                                                item?.isMemo ||
                                                item?.isFilterReport ||
                                                item.name.startsWith("Comparative Analysis Band")
                                                    ? item.docKey
                                                    : item.name,
                                            is_remove: !e.target.checked,
                                            defaultValue: item.defaultValue,
                                            entity: entity
                                        });
                                    }}
                                    checked={item.isSelected}
                                    disabled={isSkeletonView || (item.isBbi && !buildingMfilter.length) || (item.isBsi && !siteMfilter.length)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cont-sec">
                <div className="icons-sec">
                    <img src={`/img/${item.entityIcon}`} />
                </div>
                <h3>{getDisplayNameFromKey(item.name, item.isUserDoc)}</h3>
            </div>
            {item.hasConfig && ((entity === "project" && !item?.isAssetOnePager) || entity === "assets") ? (
                <div className="fot-sec top-border">
                    <div className="fot-select">
                        {item.isRecommendation ||
                        (item.name !== "sorted_recom" && !isExcelExport && !item?.isMemo && item?.name !== "asset_band" && item.name !== "BBI") ? (
                            <>
                                {!(
                                    (item?.tileData?.band1?.config_filter && Object.keys(item.tileData.band1.config_filter).length > 0) ||
                                    (item?.tileData?.config_filter && Object.keys(item.tileData.config_filter).length > 0) ||
                                    item?.tileData?.band1?.mfilter?.recommendations?.length === 0 ||
                                    item?.tileData?.recommendations?.length === 0
                                ) && (
                                    <span
                                        className={`badge ${
                                            (item.isImageBand || item.isMultipleRecommendation || item.isRecommendation || item.isOnePager) &&
                                            !item.totalChartCount &&
                                            item.isSelected
                                                ? "sm-chart-chart-count-indicator"
                                                : ""
                                        }`}
                                    >
                                        {item?.totalChartCount}
                                    </span>
                                )}
                                <span className="nme">
                                    {item.isMultipleRecommendation || item.isRecommendation || item.isOnePager
                                        ? item?.isAssetOnePager
                                            ? (item?.tileData?.band1?.config_filter && Object.keys(item.tileData.band1.config_filter).length > 0) ||
                                              (item?.tileData?.config_filter && Object.keys(item.tileData.config_filter).length > 0)
                                                ? "All Assets"
                                                : "Assets"
                                            : (item?.tileData?.band1?.config_filter && Object.keys(item.tileData.band1.config_filter).length > 0) ||
                                              (item?.tileData?.config_filter && Object.keys(item.tileData.config_filter).length > 0) ||
                                              item?.tileData?.band1?.mfilter?.recommendations?.length === 0 ||
                                              item?.tileData?.recommendations?.length === 0
                                            ? "All Recommendations"
                                            : "Recommendations"
                                        : `Selected ${item.isImageBand ? "Images" : "Charts"}`}
                                </span>
                            </>
                        ) : null}
                    </div>
                    <div className="btn-area">
                        <div className="icon"></div>
                        {item.isImageBand ? (
                            <button
                                class="btn butn-img-select"
                                data-tip="Select Images"
                                data-for={`sm-charts-chart-item${entity}`}
                                onClick={() =>
                                    toggleImageListForBandModal({
                                        bandName: currentBand,
                                        chartKey: item.isImageBand ? item.docKey : item.name,
                                        entity: entity,
                                        itemConfig: item.config,
                                        isImageBand: item.isImageBand
                                    })
                                }
                            >
                                <img src="/img/sm-charts-img-select.svg" />
                            </button>
                        ) : null}
                        {!(isEdit && item.name.includes("multi_recommendation")) ? (
                            <button
                                className="btn btn-conf"
                                onClick={() =>
                                    (entity === "project" || entity === "assets") &&
                                    item.isSelected &&
                                    openChartItemConfigModal({
                                        bandName: currentBand,
                                        chartKey:
                                            item.isImageBand || item.isMultipleRecommendation || item.isRecommendation || item.isOnePager
                                                ? item.docKey
                                                : item.name,
                                        entity: entity,
                                        itemConfig: item.config,
                                        isImageBand: item.isImageBand,
                                        recomType: item.isMultipleRecommendation
                                            ? "multiple"
                                            : item.isOnePager
                                            ? "onepager"
                                            : item.isRecommendation
                                            ? "normal"
                                            : ""
                                    })
                                }
                            >
                                Configure <img src="/img/blue_arrow_right.svg" />
                            </button>
                        ) : null}
                    </div>
                </div>
            ) : null}
            {provided.placeholder}
        </div>
    );
};
export default ChartItem;
