import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Draggable from "react-draggable";
import moment from "moment";

import Recommendations from "../../recommendations";
import { recommendationsTableData } from "../../../config/tableData";
import { energy_fields } from "../../recommendations/components/Bands/EnergyBand";
import { water_fields } from "../../recommendations/components/Bands/WaterBand";
import Assets from "../../assets";

const MultipleRecommendationModal = ({
    onCancel,
    selectedProject,
    setSmartChartData,
    configData = {},
    selectedRecomIds = [],
    recomType = "",
    clientId = "",
    smartChartFilters,
    smartChartTileConfig,
    getExportSettingsForSmartChartRecom,
    entity,
    configParams
}) => {
    const {
        entityParams,
        priorityElementsDropDownResponse = {},
        getAllRecommendationsResponse = {}
    } = useSelector(state => state.recommendationsReducer);
    const { entityParams: assetEntityParams, getWholeAssetIdsResponse } = useSelector(state => state.assetReducer);
    const { exportSettingsData = {} } = useSelector(state => state.smartChartReducer);
    const { section } = useParams();
    const isAssets = entity === "assets";

    const selectedEntityParams = isAssets ? assetEntityParams : entityParams;
    const [draggableState, setDraggableState] = useState(false);

    useEffect(() => {
        if (recomType === "normal" || recomType === "onepager" || recomType === "multiple") {
            let selectedSettingsId = smartChartTileConfig?.band1?.settings_id || null;
            getExportSettingsForSmartChartRecom({
                project_id: selectedProject,
                settings_id: selectedSettingsId,
                client_id: clientId
            });
        }
    }, []);

    const handleAddRecommendations = () => {
        let selectedRecomIds = isAssets ? assetEntityParams?.[section].selectedAssetsId || [] : entityParams?.[section]?.selectedRecomIds || [];
        let wildCardFilters = isAssets ? assetEntityParams?.[section].params?.filters || {} : entityParams?.[section]?.params?.filters || {};
        let commonFilters = isAssets
            ? assetEntityParams?.[section].tableDataExportFilters || {}
            : entityParams?.[section]?.tableDataExportFilters || {};
        let sortedRecommendationIds = selectedRecomIds.sort((a, b) => a.index - b.index).map(elem => elem.id);
        let isSelectAll = isAssets
            ? getWholeAssetIdsResponse?.recommendation_ids && getWholeAssetIdsResponse?.recommendation_ids?.length === selectedRecomIds?.length
            : getAllRecommendationsResponse?.count && getAllRecommendationsResponse?.count === selectedRecomIds?.length
            ? true
            : false;
        let currentBandData = smartChartTileConfig?.band1 || {};
        let normalRecomConfig = {};
        let listFilters = isAssets ? assetEntityParams?.[section].params?.list || {} : entityParams?.[section]?.params?.list || {};
        const { config, keys } = recommendationsTableData;
        let key_name = {};
        let multiRecommendationConfig = {};
        let globalSearchValue = isAssets ? assetEntityParams?.[section].params?.search : entityParams?.[section]?.params?.search;
        switch (recomType) {
            case "normal":
                if (isSelectAll) {
                    const { config_filter, config_filter_names, ...restBandData } = currentBandData;
                    normalRecomConfig = {
                        band1: {
                            ...currentBandData,
                            settings_id: smartChartTileConfig?.band1?.settings_id || exportSettingsData?.data?.id || "",
                            config_filter: {
                                ...entityParams?.[section]?.params,
                                ...getUpdatedCommonFilters(commonFilters, entityParams?.[section]?.params?.list),
                                wildcard_filters: {
                                    ...getUpdatedWildCardFilters(wildCardFilters)
                                },
                                global_search: globalSearchValue || "",
                                sort_by_order: {
                                    ...getUpdatedSortFilters(
                                        isAssets ? assetEntityParams?.[section].params?.order ?? {} : entityParams?.[section]?.params?.order || {}
                                    )
                                }
                            },
                            config_filter_names: {
                                list: isAssets ? assetEntityParams?.[section].params?.list ?? null : entityParams?.[section]?.params?.list || null,
                                filters: wildCardFilters,
                                tableDataExportFilters: commonFilters,
                                order: isAssets ? assetEntityParams?.[section].params?.order ?? null : entityParams?.[section]?.params?.order || null,
                                maintenance_year: isAssets
                                    ? assetEntityParams?.[section].params?.maintenance_year ?? []
                                    : entityParams?.[section]?.params?.maintenance_year || []
                            },
                            number_of_recom: getAllRecommendationsResponse?.count,
                            mfilter: {
                                ...restBandData?.mfilter,
                                recommendations: []
                            }
                        }
                    };
                } else {
                    const { config_filter, config_filter_names, ...restBandData } = currentBandData;
                    normalRecomConfig = {
                        band1: {
                            ...restBandData,
                            mfilter: {
                                ...restBandData?.mfilter,
                                [entity === "assets" ? "asset_ids" : "recommendations"]: [...sortedRecommendationIds]
                            },
                            settings_id: smartChartTileConfig?.band1?.settings_id || exportSettingsData?.data?.id || ""
                        }
                    };
                }
                setSmartChartData("config_chart", {
                    ...configData,
                    chartConfigData: normalRecomConfig,
                    defaultValue: smartChartTileConfig
                });
                break;
            case "onepager":
                if (isSelectAll) {
                    const { config_filter, config_filter_names, ...restBandData } = currentBandData;
                    normalRecomConfig = {
                        band1: {
                            ...currentBandData,
                            config_filter: {
                                ...selectedEntityParams?.[section]?.params,
                                ...getUpdatedCommonFilters(commonFilters, entityParams?.[section]?.params?.list),
                                wildcard_filters: {
                                    ...getUpdatedWildCardFilters(wildCardFilters)
                                },
                                global_search: globalSearchValue || "",
                                sort_by_order: {
                                    ...getUpdatedSortFilters(selectedEntityParams?.[section]?.params?.order || {})
                                },
                                order: selectedEntityParams?.[section]?.params?.order
                            },
                            config_filter_names: {
                                list: selectedEntityParams?.[section]?.params?.list || null,
                                filters: wildCardFilters,
                                tableDataExportFilters: commonFilters,
                                order: selectedEntityParams?.[section]?.params?.order || null,
                                maintenance_year: selectedEntityParams?.[section]?.params?.maintenance_year || []
                            },
                            mfilter: {
                                ...restBandData?.mfilter,
                                recommendations: []
                                // ...(isAssets && { asset_ids: [...sortedRecommendationIds] })
                            },
                            ...(!isAssets && { number_of_recom: getAllRecommendationsResponse?.count })
                        }
                    };
                } else {
                    const { config_filter, config_filter_names, ...restBandData } = currentBandData;
                    normalRecomConfig = {
                        band1: {
                            ...restBandData,
                            mfilter: {
                                ...restBandData?.mfilter,
                                [entity === "assets" ? "asset_ids" : "recommendations"]: [...sortedRecommendationIds]
                            }
                        }
                    };
                }
                setSmartChartData("config_chart", {
                    ...configData,
                    chartConfigData: normalRecomConfig,
                    defaultValue: smartChartTileConfig
                });
                break;
            case "multiple":
                if (isSelectAll) {
                    if (listFilters && Object.keys(listFilters).length) {
                        keys.forEach(item => {
                            Object.entries(listFilters).forEach(([key, value]) => {
                                if (config[item].searchKey === key) {
                                    key_name[config[item].label] = value;
                                }
                            });
                        });
                    }
                    key_name = Object.keys(key_name)?.length ? key_name : null;
                    let selectedConfigValues = configData?.selectedChartConfig || {};
                    const { recommendations, ...restMultiRecomBandData } = selectedConfigValues;
                    multiRecommendationConfig = {
                        ...restMultiRecomBandData,
                        notes: "",
                        key_name: key_name,
                        config_filter: {
                            ...entityParams?.[section]?.params,
                            ...getUpdatedCommonFilters(commonFilters, entityParams?.[section]?.params?.list),
                            wildcard_filters: {
                                ...getUpdatedWildCardFilters(wildCardFilters)
                            },
                            global_search: globalSearchValue || "",
                            sort_by_order: {
                                ...getUpdatedSortFilters(selectedEntityParams?.[section]?.params?.order || {})
                            }
                        },
                        config_filter_names: {
                            list: selectedEntityParams?.[section]?.params?.list || null,
                            filters: wildCardFilters,
                            tableDataExportFilters: commonFilters,
                            order: selectedEntityParams?.[section]?.params?.order || null,
                            maintenance_year: selectedEntityParams?.[section]?.params?.maintenance_year || []
                        },
                        number_of_recom: getAllRecommendationsResponse?.count,
                        recommendations: []
                    };
                } else {
                    if (listFilters && Object.keys(listFilters).length) {
                        keys.forEach(item => {
                            Object.entries(listFilters).forEach(([key, value]) => {
                                if (config[item].searchKey === key) {
                                    key_name[config[item].label] = value;
                                }
                            });
                        });
                    }
                    key_name = Object.keys(key_name)?.length ? key_name : null;
                    let selectedConfigValues = configData?.selectedChartConfig || {};
                    const { config_filter, config_filter_names, ...restBandData } = selectedConfigValues;
                    multiRecommendationConfig = {
                        ...restBandData,
                        notes: "",
                        [entity === "assets" ? "asset_ids" : "recommendations"]: [...sortedRecommendationIds],
                        key_name: key_name
                    };
                }
                setSmartChartData("config_chart", {
                    ...configData,
                    chartConfigData: multiRecommendationConfig,
                    defaultValue: configData?.selectedChartConfig || null
                });
                break;
            default:
                if (listFilters && Object.keys(listFilters).length) {
                    keys.forEach(item => {
                        Object.entries(listFilters).forEach(([key, value]) => {
                            if (config[item].searchKey === key) {
                                key_name[config[item].label] = value;
                            }
                        });
                    });
                }
                key_name = Object.keys(key_name)?.length ? key_name : null;
                let selectedConfigValues = configData?.selectedChartConfig || {};
                multiRecommendationConfig = {
                    ...selectedConfigValues,
                    notes: "",
                    [entity === "assets" ? "asset_ids" : "recommendations"]: [...sortedRecommendationIds],
                    key_name: key_name
                };
                setSmartChartData("config_chart", {
                    ...configData,
                    chartConfigData: multiRecommendationConfig,
                    defaultValue: configData?.selectedChartConfig || null
                });
        }

        onCancel();
    };

    const getUpdatedWildCardFilters = (filters = {}) => {
        let tableConfig = exportSettingsData?.data?.properties || exportSettingsData?.properties || [];
        let oldTableConfigArray = Object.entries(selectedEntityParams?.[section]?.tableConfig || {});
        let result = {};
        if (tableConfig?.length) {
            let additionalKeys = ["is_dynamic_field", "is_static_field", "is_energy_band", "is_water_band"];
            result = Object.entries(filters).reduce((filtersData, [currentDataKey, currentDataValue], index) => {
                let updatedData = { ...currentDataValue } || {};
                let [selectedItemKey, selectedItemValue] = oldTableConfigArray.find(([key, value]) => value?.searchKey == currentDataKey) || [];
                let finalKey = "";
                let fieldType = "";
                if (selectedItemValue?.getListTable == "maintenance_year") {
                    finalKey = currentDataKey?.split(".")?.[1];
                    fieldType = "is_maintenance_year";
                } else if (selectedItemValue?.getListTable == "priority_element") {
                    let currentPriorityElement =
                        priorityElementsDropDownResponse?.priority_elements?.[parseInt(currentDataKey?.split(".")?.[1]) - 1] || {};
                    finalKey = currentPriorityElement?.id;
                    fieldType = "is_priority_element";
                } else if (selectedItemKey?.includes("energy_band_")) {
                    let energyBandIndex = selectedItemKey?.split("_")?.pop();
                    let currentSelected = energy_fields?.[energyBandIndex] || {};
                    finalKey = currentSelected?.key;
                    fieldType = "is_energy_band";
                } else if (selectedItemKey?.includes("water_band_")) {
                    let energyBandIndex = selectedItemKey?.split("_")?.pop();
                    let currentSelected = water_fields?.[energyBandIndex] || {};
                    finalKey = currentSelected?.key;
                    fieldType = "is_water_band";
                } else {
                    let currentSelected = tableConfig.find(k => k.key == selectedItemKey) || {};

                    finalKey = currentSelected?.key || "";
                    fieldType = additionalKeys.find(type => Object.keys(currentSelected).includes(type));
                    if (selectedItemValue?.type === "date" && currentDataValue?.key !== null) {
                        finalKey = currentSelected?.key || "";
                        fieldType = additionalKeys.find(type => Object.keys(currentSelected).includes(type));
                        updatedData = {
                            ...updatedData,
                            key: {
                                from: moment(currentDataValue?.key?.from).format("YYYY-MM-DD"),
                                to: moment(currentDataValue?.key?.to).format("YYYY-MM-DD")
                            }
                        };
                    }
                    if (selectedItemValue?.smartChartWildCardFilterKey) {
                        finalKey = selectedItemValue?.smartChartWildCardFilterKey;
                        fieldType = "is_static_field";
                    }
                }
                if (finalKey && currentDataValue?.key !== null) {
                    return {
                        ...filtersData,
                        [finalKey]: { ...updatedData, [fieldType]: true }
                    };
                }
                return {
                    ...filtersData
                };
            }, {});
        }
        return result;
    };

    const getUpdatedCommonFilters = (filters = {}, list = {}) => {
        let tableConfig = Object.entries(selectedEntityParams?.[section]?.tableConfig || {});
        let result = {};
        result = Object.entries(filters).reduce((filtersData, [currentDataKey, currentDataValue]) => {
            let [selectedItemKey, selectedItemValue] =
                tableConfig.find(([key, value]) => {
                    let searchKeyName = value?.filterIdKey || value?.searchKey;
                    if (searchKeyName === currentDataKey) return [key, value];
                }) || [];
            let currentFilterKey = selectedItemValue?.smartChartCommonSearchKey || "";
            let currentFilterValue = currentDataValue || [];
            if (currentFilterKey) {
                return {
                    ...filtersData,
                    [currentFilterKey]:
                        currentFilterKey === "buildings_descriptions" ? list && list["buildings.description"] : [...currentFilterValue]
                };
            }
            return {
                ...filtersData
            };
        }, {});
        return result;
    };

    const setNormalRecomData = (settingsId, groupingData, Isgrouping) => {
        let currentBandData = configData?.selectedChartConfig?.band1 || {};
        let normalRecomConfig = {
            band1: {
                ...currentBandData,
                settings_id: settingsId
            }
        };
        if (Isgrouping) {
            normalRecomConfig = {
                band1: {
                    ...normalRecomConfig?.band1,
                    grouping: groupingData
                }
            };
        }
        setSmartChartData("config_chart", {
            ...configData,
            chartConfigData: normalRecomConfig,
            defaultValue: configData?.selectedChartConfig || null
        });
    };

    const getUpdatedSortFilters = (filters = {}) => {
        let tableConfig = exportSettingsData?.data?.properties || exportSettingsData?.properties || [];
        let oldTableConfigArray = Object.entries(selectedEntityParams?.[section]?.tableConfig || {});
        let result = {};
        if (tableConfig?.length) {
            let additionalKeys = ["is_dynamic_field", "is_static_field", "is_energy_band", "is_water_band"];
            result = Object.entries(filters).reduce((filtersData, [currentDataKey, currentDataValue], index) => {
                let updatedData = { order: currentDataValue };
                let [selectedItemKey, selectedItemValue] = oldTableConfigArray.find(([key, value]) => value?.searchKey == currentDataKey) || [];
                let finalKey = "";
                let fieldType = "";
                if (currentDataKey == "maintenance_years.amount") {
                    finalKey = entityParams?.[section]?.params?.maintenance_year?.[0];
                    fieldType = "is_maintenance_year";
                } else if (currentDataKey?.includes("dynamic_fields")) {
                    finalKey = currentDataKey?.split(".")?.[1];
                    fieldType = "is_dynamic_field";
                } else {
                    let currentSelected = tableConfig.find(k => k.key == selectedItemKey) || {};
                    finalKey = currentSelected?.key || "";
                    fieldType = "is_static_field";
                    if (selectedItemValue?.smartChartWildCardFilterKey) {
                        finalKey = selectedItemValue?.smartChartWildCardFilterKey;
                        fieldType = "is_static_field";
                    }
                }
                if (finalKey) {
                    return {
                        ...filtersData,
                        [finalKey]: { ...updatedData, [fieldType]: true }
                    };
                }
                return {
                    ...filtersData
                };
            }, {});
        }
        return result;
    };

    let isAssignActive = isAssets ? assetEntityParams?.[section]?.selectedAssetsId?.length : entityParams?.[section]?.selectedRecomIds?.length;

    const smartChartFiltersForRecom = { ...smartChartFilters };
    // smartChartFiltersForRecom["sub_system_ids"] = smartChartFiltersForRecom["subsystem_ids"];
    // delete smartChartFiltersForRecom["subsystem_ids"];

    return (
        <div
            className="modal assign-init-modal image-pull-modal config-modal"
            style={{ display: "block" }}
            id="modalId"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <Draggable handle=".draggable" cancel=".non-draggable" disabled={draggableState}>
                <div className="modal-dialog assignModal" role="document">
                    <div className="modal-content">
                        <div className="modal-header draggable">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <div className="txt-hed">{`Select ${entity === "assets" ? "Assets" : "Recommendations"}`}</div>
                            </h5>
                            <button type="button" className="close non-draggable" onClick={onCancel}>
                                <span aria-hidden="true">
                                    <img src="/img/close.svg" alt="" />
                                </span>
                            </button>
                        </div>
                        <form autoComplete="nope">
                            <div className="modal-body ">
                                <div className="form-group">
                                    <div className="formInp">
                                        <div className="dashboard-outer">
                                            <div className="outer-detail">
                                                <div className="right-panel-section">
                                                    <div className="dtl-sec">
                                                        <div className="dtl-sec system-building col-md-12 ">
                                                            <div className="tab-dtl region-mng">
                                                                <div className="tab-active recomdn-table bg-grey-table modal-table-scroll">
                                                                    {entity === "assets" ? (
                                                                        <Assets
                                                                            isSmartChartView={true}
                                                                            clientId={clientId}
                                                                            smartChartFilters={smartChartFilters}
                                                                            selectedRecomIdList={selectedRecomIds}
                                                                            tableDataExportFilters={
                                                                                selectedEntityParams?.[section]?.tableDataExportFilters
                                                                            }
                                                                            configParams={configParams}
                                                                            isDraggable={e => {
                                                                                setDraggableState(e);
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Recommendations
                                                                            projectIdForSmartChart={selectedProject}
                                                                            isSmartChartView={true}
                                                                            selectedRecomIdList={selectedRecomIds}
                                                                            smartChartFilters={smartChartFiltersForRecom}
                                                                            recomType={recomType}
                                                                            clientIdForSmartChart={clientId}
                                                                            setNormalRecomData={setNormalRecomData}
                                                                            smartChartTileConfig={smartChartTileConfig}
                                                                            isDraggable={e => {
                                                                                setDraggableState(e);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 p-0 text-right btnOtr">
                                        <button
                                            type="button"
                                            onClick={() => isAssignActive && handleAddRecommendations()}
                                            className={`btn btn-primary btnRgion col-md-2 ${!isAssignActive ? "disabled-btn" : ""}`}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Draggable>
        </div>
    );
};

export default MultipleRecommendationModal;
