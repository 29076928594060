import React from "react";
import Draggable from "react-draggable";
import moment from "moment";

const ViewReportInfo = ({ onCancel, reportData = {}, currentTab, isQuerModal = false }) => {
    return (
        <div className="modal modal-region smart-chart-popup more-info-modal" id="modalId" tabindex="-1" style={{ display: "block" }}>
            <Draggable handle=".draggable" cancel=".non-draggable">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header draggable">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <div className="txt-hed">More Information</div>
                            </h5>
                            <button type="button" className="close close-icn-sec non-draggable" onClick={() => onCancel()}>
                                <span aria-hidden="true">
                                    <img src="/img/close-icn.svg" alt="" />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body region-otr core-rpt-dt-mod">
                            <div className="col-md-12 more-info-content">
                                <div className="more-info-dtl d-flex">
                                    <div className="img-dis active-dis">
                                        <img src="/img/grp-img.svg" />
                                    </div>
                                    <div className="img-dis-dtl">
                                        <div className="rpt-nm">
                                            {currentTab === "reporttemplates"
                                                ? "Report Template Name"
                                                : currentTab === "corereports"
                                                ? "Core Report Name"
                                                : isQuerModal
                                                ? "Query Name"
                                                : "Report name"}
                                        </div>
                                        <div className="rpt-ast rpt-active">{reportData?.name || "-"}</div>
                                    </div>
                                </div>
                                {(currentTab === "reporttemplates" || currentTab === "corereports") && reportData?.skeleton_template && (
                                    <div className="more-info-dtl d-flex">
                                        <div className="img-dis active-dis">
                                            <img src="/img/sm-chart-skel.svg" />
                                        </div>
                                        <div className="img-dis-dtl">
                                            <div className="rpt-nm">Core Report Name</div>
                                            <div className="rpt-ast rpt-active">{reportData.skeleton_template || "-"}</div>
                                        </div>
                                    </div>
                                )}
                                {currentTab !== "corereports" && (
                                    <div className="more-info-dtl d-flex">
                                        <div className="img-dis">
                                            <img src="/img/user-info-icon.svg" />
                                        </div>
                                        <div className="img-dis-dtl">
                                            <div className="rpt-nm">Client</div>
                                            <div className="rpt-ast">{reportData?.client_name || "-"}</div>
                                        </div>
                                    </div>
                                )}
                                {currentTab === "reports" && (
                                    <>
                                        <div className="more-info-dtl d-flex">
                                            <div className="img-dis">
                                                <img src="/img/clipboard-text.svg" />
                                            </div>
                                            <div className="img-dis-dtl">
                                                <div className="rpt-nm">Project</div>
                                                <div className="rpt-ast">{reportData?.project_name || "-"}</div>
                                            </div>
                                        </div>
                                        {reportData?.skeleton_template && (
                                            <div className="more-info-dtl d-flex">
                                                <div className="img-dis">
                                                    <img src="/img/clipboard-text.svg" />
                                                </div>
                                                <div className="img-dis-dtl">
                                                    <div className="rpt-nm">Core Report Name</div>
                                                    <div className="rpt-ast">{reportData.skeleton_template}</div>
                                                </div>
                                            </div>
                                        )}
                                        {reportData?.export_type ? (
                                            <div className="more-info-dtl d-flex">
                                                <div className="img-dis">
                                                    <img src="/img/clipboard-text.svg" />
                                                </div>
                                                <div className="img-dis-dtl">
                                                    <div className="rpt-nm">Export Type</div>
                                                    <div className="rpt-ast">
                                                        {reportData.export_type === 3
                                                            ? "Multipe Data Set (Merged)"
                                                            : reportData.export_type === 2
                                                            ? "Multiple Data Set (Separated)"
                                                            : "Single Data Set"}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </>
                                )}
                                <div className="more-info-dtl d-flex">
                                    <div className="img-dis">
                                        <img src="/img/admin-ic.svg" />
                                    </div>
                                    <div className="img-dis-dtl">
                                        <div className="rpt-nm">
                                            {currentTab === "reporttemplates" || currentTab === "corereports" || isQuerModal ? "Created" : "Exported"}{" "}
                                            By
                                        </div>
                                        <div className="rpt-ast">{reportData?.user || reportData?.created_by_user || "-"}</div>
                                    </div>
                                </div>
                                <div className="more-info-dtl d-flex">
                                    <div className="img-dis">
                                        <img src="/img/calender-ic.svg" />
                                    </div>
                                    <div className="img-dis-dtl">
                                        <div className="rpt-nm">
                                            {currentTab === "reporttemplates" || currentTab === "corereports" || isQuerModal ? "Created" : "Export"}{" "}
                                            date & time
                                        </div>
                                        <div className="rpt-ast">
                                            {reportData?.created_date ? moment(reportData.created_date).format("MM-DD-YYYY h:mm A") : "-"}
                                        </div>
                                    </div>
                                </div>
                                {(currentTab === "reporttemplates" || currentTab === "corereports") && (
                                    <div className="more-info-dtl d-flex">
                                        <div className="img-dis">
                                            <img src="/img/calender-ic.svg" />
                                        </div>
                                        <div className="img-dis-dtl">
                                            <div className="rpt-nm">Modified date & time</div>
                                            <div className="rpt-ast">
                                                {reportData?.modified_date ? moment(reportData.modified_date).format("MM-DD-YYYY h:mm A") : "-"}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="more-info-dtl d-flex">
                                    <div className="img-dis">
                                        <img src="/img/notes-ic.svg" />
                                    </div>
                                    <div className="img-dis-dtl">
                                        <div className="rpt-nm">Notes</div>
                                        <div className="rpt-ast">{reportData?.notes || "-"}</div>
                                    </div>
                                </div>
                                {!isQuerModal && (
                                    <div className="more-info-dtl d-flex">
                                        <div className="img-dis">
                                            <img src="/img/vuesax-bold-status-up_01.svg" />
                                        </div>
                                        <div className="img-dis-dtl">
                                            <div className="rpt-nm">Report Type</div>
                                            <div className="rpt-ast">{reportData?.property_type === "SKEL" ? "Global" : "Local" || "-"}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    );
};

export default ViewReportInfo;
