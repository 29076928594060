import { useEffect, useRef, useState } from "react";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useParams, useLocation } from "react-router-dom";
import qs from "query-string";
import { bandPositions, bands, tabs } from "./constants";
import recommendationActions from "../../../recommendations/actions";
import buildingActions from "../../../building/actions";
import projectActions from "../../../project/actions";
import { DYNAMIC_FIELD_TYPES } from "../../../common/constants";
import { getAppliedFiltersCount } from "../../../../utils/filtersUtils";
import { AppliedQueryFilters } from "../../../../config/utils";
const useMyFilters = ({
    dashboardFilterParams,
    submitMasterFilter,
    onCancel,
    getListParams,
    isRecommendationTable,
    queries,
    saveFilterSubmit,
    isDashboard = false
}) => {
    const dispatch = useDispatch();
    const { masterFilterList, dropdownLoader, getDashboard = {}, filterFields, updateFilterName } = useSelector(state => state.dashboardReducer);
    const { recommendation_custom_fields = {} } = useSelector(state => state.recommendationsReducer?.dynamicFields || {});
    const { building_custom_field = {} } = useSelector(state => state.buildingReducer?.buildingDynamicFields || {});
    const { priorityElementsDropDownResponse = {} } = useSelector(state => state.recommendationsReducer || {});
    const [showBands, setShowBands] = useState({});
    const clientId = useSelector(state => state.projectReducer.getProjectByIdResponse?.client?.id);
    const [showDropdown, setShowDropdown] = useState("");
    const [filterData, setFilterData] = useState({
        ...dashboardFilterParams,
        exclude_filters: dashboardFilterParams.exclude_filters || {}
    });
    const [filterName, setFilterName] = useState({ ...updateFilterName });

    const [initialFilterData, setInitialFilterData] = useState({
        ...dashboardFilterParams,
        exclude_filters: dashboardFilterParams.exclude_filters || {}
    });

    const [activeTab, setActiveTab] = useState(Object.keys(tabs)[0]);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [yearFields, setYearFields] = useState([]);
    const [dynamicFields, setDynamicFields] = useState([]);
    const [buildingDynamicFields, setBuildingDynamicFields] = useState([]);
    const [priorityFields, setPriorityFields] = useState([]);
    const [fieldSearch, setFieldSearch] = useState("");
    const [showAppliedFilters, setShowAppliedFilters] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const hasPageBeenRendered = useRef({});
    const emptyDivRef = useRef(null);
    const sectionRef = useRef(null);
    const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);
    const path = useParams();
    const location = useLocation();
    useEffect(() => {
        const timer1 = setTimeout(() => {
            getDynamicFields();
        }, 100);
        return () => {
            clearTimeout(timer1);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData.project_ids, getDashboard]);

    useEffect(() => {
        if (hasPageBeenRendered.current["effect1"]) {
            if (recommendation_custom_fields && Object.keys(recommendation_custom_fields)?.length) {
                let dynamicMasterFilters = [];
                Object.entries(recommendation_custom_fields).forEach(([band, item]) => {
                    item.filter(field => field.field_type === DYNAMIC_FIELD_TYPES.SELECT).forEach((field, index) => {
                        let paramKey =
                            field.field_type === "select"
                                ? `recommendation_dynamic_field__${field?.field_name}`
                                : `recommendation_dynamic_fields.${field?.field_name}`;
                        dynamicMasterFilters.push({
                            label: field?.label,
                            key: field?.field_name,
                            paramKey: paramKey,
                            exportFilterKey: paramKey,
                            band: band,
                            bandPosition: [0, 1, 2].includes(index) ? bandPositions.header : bandPositions.body,
                            isRecommendationField: true,
                            getListKey: field?.field_options.map(item => item.value) || [],
                            searchKey: `recommendation__${field?.field_name}`,
                            isDynamicField: true,
                            isWildCardFilter: field.field_type !== "select",
                            fieldType: field?.field_type === "amount" ? "number" : field.field_type === "memo" ? "text" : field?.field_type
                        });
                    });
                });
                setDynamicFields(dynamicMasterFilters);
            }
        }
        hasPageBeenRendered.current["effect1"] = true;
    }, [recommendation_custom_fields]);

    useEffect(() => {
        if (hasPageBeenRendered.current["effect1"]) {
            if (building_custom_field && Object.keys(building_custom_field)?.length) {
                let dynamicMasterFilters = [];
                Object.entries(building_custom_field).forEach(([band, item]) => {
                    item.filter(field => [DYNAMIC_FIELD_TYPES.SELECT, DYNAMIC_FIELD_TYPES.BOOLEAN].includes(field.field_type)).forEach(
                        (field, index) => {
                            let paramKey =
                                field.field_type === "select" || field.field_type === "boolean"
                                    ? `building_dynamic_field__${field?.field_name}`
                                    : `building_dynamic_fields.${field?.field_name}`;
                            dynamicMasterFilters.push({
                                label: field?.label,
                                key: field?.field_name,
                                paramKey: paramKey,
                                exportFilterKey: paramKey,
                                band: band,
                                bandPosition: [0, 1, 2].includes(index) ? bandPositions.header : bandPositions.body,
                                isRecommendationField: true,
                                getListKey: field?.field_options.map(item => item.value) || [],
                                searchKey: `building__${field?.field_name}`,
                                isDynamicField: true,
                                isWildCardFilter: field.field_type !== "select",
                                isBoolean: field.field_type === DYNAMIC_FIELD_TYPES.BOOLEAN,
                                fieldType: field?.field_type === "amount" ? "number" : field.field_type === "memo" ? "text" : field?.field_type
                            });
                        }
                    );
                });
                setBuildingDynamicFields(dynamicMasterFilters);
            }
        }
        hasPageBeenRendered.current["effect1"] = true;
    }, [building_custom_field]);
    // useEffect(() => {
    //     if (priorityElementsDropDownResponse !== prevPriorityElements.current && priorityElementsDropDownResponse?.priority_elements?.length) {
    //         prevRecommendationCustomFields.current = priorityElementsDropDownResponse;
    //         let priorityElements = [];
    //         priorityElementsDropDownResponse?.priority_elements.forEach((field, i) => {
    //             priorityElements.push({
    //                 label: field?.display_name || `Priority Element ${i + 1}`,
    //                 key: `priority_elements.${i + 1}`,
    //                 paramKey: `priority_elements.${i + 1}`,
    //                 exportFilterKey: `priority_elements.${i + 1}`,
    //                 band: bands.priority,
    //                 bandPosition: [0, 1, 2].includes(i) ? bandPositions.header : bandPositions.body,
    //                 isRecommendationField: true,
    //                 getListKey: [`priority_elements.${i + 1}`],
    //                 searchKey: `priority_elements.${i + 1}`
    //             });
    //         });
    //         setPriorityFields(priorityElements);
    //     }
    // }, [priorityElementsDropDownResponse]);

    useEffect(() => {
        const timer2 = setTimeout(() => {
            const { start, end } = getDashboard?.years || {};
            if (start && end) {
                let yearFilters = [];
                const yearsArray = Array.from({ length: end - start + 1 }, (_, index) => start + index);
                yearFilters = yearsArray.map(year => ({
                    label: year,
                    key: year,
                    paramKey: `maintenance_years.${year}`,
                    band: bands.capital_spending_plan,
                    bandPosition: bandPositions.body,
                    isWildCardFilter: true,
                    fieldType: "number"
                }));
                setYearFields(yearFilters);
            }
        }, 100);

        return () => {
            clearTimeout(timer2);
        };
    }, [getDashboard]);

    useEffect(() => {
        if (hasPageBeenRendered.current["effect3"]) {
            dispatch(actions.updateMasterFilter([...yearFields, ...dynamicFields, ...buildingDynamicFields, ...priorityFields]));
        }
        hasPageBeenRendered.current["effect3"] = true;
    }, [dispatch, yearFields, dynamicFields, buildingDynamicFields, priorityFields]);

    useEffect(() => {
        if (hasPageBeenRendered.current["effect4"]) {
            if (emptyDivRef.current && emptyDivRef.current.classList?.contains("d-block")) {
                emptyDivRef.current.classList.remove("d-block");
                emptyDivRef.current.classList.add("d-none");
                if (sectionRef.current) {
                    sectionRef.current.scrollIntoView({ behavior: "smooth" });
                }
            }
        }
        hasPageBeenRendered.current["effect4"] = true;
    }, [activeTab]);

    useEffect(() => {
        if (clientId) dispatch(buildingActions.getBuildingDynamicFields({ client_id: clientId }));
    }, [clientId]);

    const handleFilterView = async (item, dropdownRef) => {
        const { key, paramKey, options = [], searchKey, getListKey, isRecommendationField, isDynamicField, isWildCardFilter, isBoolean } = item;
        if (showDropdown !== key && dropdownRef?.current) {
            if (emptyDivRef.current) {
                emptyDivRef.current.classList.remove("d-none");
                emptyDivRef.current.classList.add("d-block");
            }
            dropdownRef.current?.scrollIntoView({ behavior: "smooth" });
        }
        setShowDropdown(showDropdown === key ? "" : key);
        if ((!options?.length && key !== showDropdown && !isWildCardFilter) || isBoolean) {
            let { [paramKey]: omit, ...rest } = { ...filterData };
            let remainingFilter = { ...rest };

            if (remainingFilter.exclude_filters) {
                const { [paramKey]: omit, ...restExcludeFilters } = remainingFilter.exclude_filters;
                remainingFilter = { ...rest, exclude_filters: restExcludeFilters };
            }
            let resultValue = {};
            if (isDashboard) {
                const result = await AppliedQueryFilters(remainingFilter, queries);
                const {
                    [paramKey]: omittedValue,
                    exclude_filters: { [paramKey]: excludedValue, ...remainingExcludeFilters },
                    ...remainingResult
                } = result;

                resultValue = {
                    ...remainingResult,
                    exclude_filters: remainingExcludeFilters
                };
            }

            if (key === "year") {
                const { start_year = "", end_year = "", ...restQuery } = rest;
                rest = restQuery;
            }
            let params = {
                searchKey,
                field: isBoolean ? ["Yes", "No"] : getListKey,
                ...getListParams,
                ...(isDashboard ? resultValue : rest)
            };

            if (["sub_system", "additions"].includes(key) && isRecommendationTable) params.limit = 500;
            else if (["sites", "buildings", "floors"].includes(key) && isRecommendationTable) params.limit = 1000;

            if (isDynamicField) {
                params.is_dynamic = isDynamicField;
            }
            if (isRecommendationTable) {
                dispatch(actions.getMasterFilter(params, key, isRecommendationField, isRecommendationTable));
            } else if (isRecommendationField) {
                dispatch(actions.getMasterFilter(params, key, isRecommendationField));
            } else {
                dispatch(actions.getMasterFilter(isDashboard ? resultValue : rest, key));
            }
        }
    };

    const handleFilterUpdate = (item, data, exclude) => {
        setShowDropdown("");
        let updatedData = data;
        if (item.key !== "year" && data?.length) {
            let options = masterFilterList[item.key];
            if (item.key === "building_types") {
                updatedData = data.filter(item => options.findIndex(opt => opt.name === item) !== -1);
            } else {
                updatedData = data.filter(item => options.findIndex(opt => opt.id === item) !== -1);
            }
        }
        if (item.key === "year") {
            setFilterData({ ...filterData, ...data });
            setFilterName({ ...filterName, ...data });
        } else if (item.key === "projects") {
            let newFilterData = { ...filterData };
            filterFields
                .filter(field => field.isDynamicField)
                .forEach(field => {
                    if (newFilterData.hasOwnProperty(field.paramKey)) {
                        delete newFilterData[field.paramKey];
                    }
                });
            setFilterData({
                ...newFilterData,
                ...(exclude
                    ? { exclude_filters: { ...newFilterData.exclude_filters, [item.paramKey]: updatedData } }
                    : { [item.paramKey]: updatedData })
            });
            setFilterName(prevFilterData => ({
                ...prevFilterData,
                ...(exclude
                    ? {
                          exclude_filters: {
                              ...prevFilterData.exclude_filters,
                              [item.label]:
                                  item.key === "building_types"
                                      ? masterFilterList[item.key].filter(entry => new Set(data).has(entry.name)).map(entry => entry.name)
                                      : masterFilterList[item.key].filter(entry => new Set(data).has(entry.id)).map(entry => entry.name)
                          }
                      }
                    : {
                          [item.key === "building_types" ? "Building Types" : item.label]:
                              item.key === "building_types"
                                  ? masterFilterList[item.key].filter(entry => new Set(data).has(entry.name)).map(entry => entry.name)
                                  : masterFilterList[item.key].filter(entry => new Set(data).has(entry.id)).map(entry => entry.name)
                      })
            }));
        } else {
            if (exclude) {
                if (updatedData?.length > 0) {
                    setFilterData(prevFilterData => ({
                        ...prevFilterData,
                        [item.paramKey]: [],
                        exclude_filters: {
                            ...prevFilterData?.exclude_filters,
                            [item.paramKey]: updatedData
                        }
                    }));
                    setFilterName(prevFilterData => ({
                        ...prevFilterData,
                        [item.key === "building_types" ? "Building Types" : item.label]: [],
                        exclude_filters: {
                            ...prevFilterData?.exclude_filters,
                            [item.key === "building_types" ? "Building Types" : item.label]:
                                item.key === "building_types"
                                    ? masterFilterList[item.key].filter(entry => new Set(data).has(entry.name)).map(entry => entry.name)
                                    : masterFilterList[item.key].filter(entry => new Set(data).has(entry.id)).map(entry => entry.name)
                        }
                    }));
                } else {
                    setFilterData(prevFilterData => {
                        const newFilterData = { ...prevFilterData };
                        delete newFilterData[item.paramKey];
                        if (newFilterData.exclude_filters) {
                            delete newFilterData.exclude_filters[item.paramKey];
                        }
                        return newFilterData;
                    });
                    setFilterName(prevFilterData => {
                        const newFilterData = { ...prevFilterData };
                        delete newFilterData[item.key === "building_types" ? "Building Types" : item.label];
                        if (newFilterData.exclude_filters) {
                            delete newFilterData.exclude_filters[item.key === "building_types" ? "Building Types" : item.label];
                        }
                        return newFilterData;
                    });
                }
            } else {
                if (updatedData?.length > 0) {
                    setFilterData({
                        ...filterData,
                        [item.paramKey]: updatedData,
                        exclude_filters: { ...filterData?.exclude_filters, [item.paramKey]: [] }
                    });
                    setFilterName({
                        ...filterName,
                        [item.key === "building_types" ? "Building Types" : item.label]:
                            item.key === "building_types"
                                ? masterFilterList[item.key].filter(entry => new Set(data).has(entry.name)).map(entry => entry.name)
                                : masterFilterList[item.key].filter(entry => new Set(data).has(entry.id)).map(entry => entry.name),
                        exclude_filters: { ...filterName?.exclude_filters, [item.key === "building_types" ? "Building Types" : item.label]: [] }
                    });
                } else {
                    setFilterData(prevFilterData => ({
                        ...prevFilterData,
                        [item.paramKey]: [],
                        exclude_filters: {
                            ...prevFilterData?.exclude_filters,
                            [item.paramKey]: updatedData
                        }
                    }));
                    setFilterName({
                        ...filterName,
                        [item.key === "building_types" ? "Building Types" : item.label]: [],
                        exclude_filters: { ...filterName?.exclude_filters, [item.key === "building_types" ? "Building Types" : item.label]: [] }
                    });
                }
            }
        }
    };
    const handleBandClick = (key, value) => {
        setShowBands({ ...showBands, [key]: value });
    };
    const handleSubmit = isSmartChart => {
        let updatedFilterData = { ...filterData };
        const filteredWildCardData = Object.entries(updatedFilterData?.filters || {}).reduce((result, [key, elem]) => {
            if (
                (elem.filters[0] !== "in_between" && elem.key?.trim().length) ||
                (elem.filters[0] === "in_between" && elem.key.from && elem.key.to) ||
                elem.filters[0] === "null" ||
                elem.filters[0] === "not_null"
            ) {
                result[key] = elem;
            }
            return result;
        }, {});
        const filteredTopNData = Object.entries(updatedFilterData?.top_n || {}).reduce((result, [key, elem]) => {
            if (elem?.trim().length) {
                result[key] = elem;
            }
            return result;
        }, {});
        if (Object.keys(filteredWildCardData).length > 0) {
            updatedFilterData.filters = filteredWildCardData;
        } else {
            delete updatedFilterData.filters;
        }

        if (Object.keys(filteredTopNData).length > 0) {
            updatedFilterData.top_n = filteredTopNData;
        }

        setInitialFilterData(updatedFilterData);
        onCancel();
        if (isSmartChart) {
            Object?.keys(updatedFilterData)?.map(async paramKeys => {
                if (paramKeys !== "filters" && paramKeys !== "project_ids" && paramKeys !== "client_ids") {
                    const filterDetail = filterFields?.find(({ paramKey }) => paramKey === paramKeys);
                    await handleFilterView(filterDetail);
                }
            });
        }
        submitMasterFilter(updatedFilterData, filterName);
    };
    const handleSaveFilerModal = () => {
        setShowSaveFilterModal(true);
    };
    const handleSaveFilter = filterSaveData => {
        let updatedFilterData = { ...filterData };
        const filteredWildCardData = Object.entries(updatedFilterData?.filters || {}).reduce((result, [key, elem]) => {
            if (
                (elem.filters[0] !== "in_between" && elem.key?.trim().length) ||
                (elem.filters[0] === "in_between" && elem.key.from && elem.key.to) ||
                elem.filters[0] === "null" ||
                elem.filters[0] === "not_null"
            ) {
                result[key] = elem;
            }
            return result;
        }, {});
        const filteredTopNData = Object.entries(updatedFilterData?.top_n || {}).reduce((result, [key, elem]) => {
            if (elem?.trim().length) {
                result[key] = elem;
            }
            return result;
        }, {});
        if (Object.keys(filteredWildCardData).length) {
            updatedFilterData.filters = filteredWildCardData;
        }
        if (Object.keys(filteredTopNData).length) {
            updatedFilterData.top_n = filteredTopNData;
        }
        setShowSaveFilterModal(false);
        saveFilterSubmit({ ...filterSaveData, query: updatedFilterData, query_label: filterName });
        onCancel();
    };

    const isDirty = !isEqual(filterData, initialFilterData);

    const handleCancel = () => {
        isDirty ? setShowCancelConfirmation(true) : onCancel();
    };

    const onCancelConfirm = () => {
        onCancel();
    };

    const getDynamicFields = async () => {
        setLoading(true);
        let project_id = null;
        if (!isRecommendationTable) {
            if (filterData?.project_ids?.length) {
                project_id = filterData?.project_ids?.[0];
            } else {
                project_id = getDashboard?.chart?.[0]?.project_id;
            }
        } else {
            const search = location?.search;
            const query = qs.parse(search);

            project_id = query.pid || path.id || "";
        }
        await Promise.all([
            dispatch(recommendationActions.getDynamicFields({ project_id })),
            project_id && dispatch(projectActions.getProjectById(project_id))

            // dispatch(recommendationActions.getPriorityElementDropDownData(project_id))
        ]);

        setLoading(false);
    };

    let dynamicBands = [];
    if (recommendation_custom_fields && Object.keys(recommendation_custom_fields)?.length) {
        Object.entries(recommendation_custom_fields).forEach(([band, item]) => {
            if (item.some(field => field.field_type === DYNAMIC_FIELD_TYPES.SELECT)) {
                dynamicBands.push(band);
            }
        });
    }

    let buildingDynamicBands = [];
    if (building_custom_field && Object.keys(building_custom_field)?.length) {
        Object.entries(building_custom_field).forEach(([band, item]) => {
            if (item.some(field => [DYNAMIC_FIELD_TYPES.SELECT, DYNAMIC_FIELD_TYPES.BOOLEAN].includes(field.field_type))) {
                buildingDynamicBands.push(band);
            }
        });
    }

    const clearAllFilters = isSmartChartFilter => {
        if (isSmartChartFilter) {
            const filteredObject = Object.keys(filterData).reduce((acc, key) => {
                if (key === "project_ids") {
                    acc[key] = filterData[key];
                }
                return acc;
            }, {});
            setFilterData(filteredObject);
            submitMasterFilter(filteredObject);
        } else {
            setFilterData({ exclude_filters: {} });
            setFilterName({ exclude_filters: {} });
        }
    };

    const getFilterCountByBand = (band, isSmartChartFilter = false) => {
        if (!(filterData && Object.keys(filterData)?.length)) {
            return 0;
        }
        let bandFilterCount = 0;
        let bandParamKeys = [];
        if (isSmartChartFilter) {
            bandParamKeys = filterFields.filter(elem => elem.band === band && elem.paramKey !== "client_ids").map(field => field?.paramKey);
        } else {
            bandParamKeys = filterFields.filter(elem => elem.band === band).map(field => field.paramKey) || [];
        }
        bandFilterCount = Object.keys(filterData).filter(key => bandParamKeys.includes(key) && filterData[key]?.length).length;

        if (bandParamKeys.includes("year") && (filterData.start_year || filterData.end_year)) {
            bandFilterCount += 1;
        }
        if (filterData.filters && Object.keys(filterData.filters).length) {
            Object.entries(filterData.filters).forEach(([key, value]) => {
                if (bandParamKeys.includes(key) && (value.key || value.filters.includes("null") || value.filters.includes("not_null"))) {
                    bandFilterCount += 1;
                }
            });
        }
        if (filterData.top_n && Object.keys(filterData.top_n).length) {
            Object.entries(filterData.top_n).forEach(([key, value]) => {
                if (bandParamKeys.includes(key) && value) {
                    bandFilterCount += 1;
                }
            });
        }

        return bandFilterCount;
    };

    const setWildCardFilter = (elem, data, checked) => {
        if (data.filters[0] === "top_n") {
            setTopNFilter(elem, data);
        } else {
            let currentFilter = { ...filterData?.filters } || {};
            let currentFilterName = { ...filterName?.filters } || {};
            if (data.filters[0] === "in_between" && !(data.key?.from && data.key?.to)) {
                return;
            } else if (Object.keys(data?.key ?? {}).length && (!data?.filters?.length || data.filters[0] === "")) {
                if (currentFilter?.hasOwnProperty(elem.paramKey)) {
                    delete currentFilter[elem.paramKey];
                    delete currentFilterName[elem.label];
                }
            } else if (data.filters[0] !== "in_between" && !data?.key?.trim()?.length && data?.filters[0] === "") {
                if (currentFilter?.hasOwnProperty(elem.paramKey)) {
                    delete currentFilter[elem.paramKey];
                    delete currentFilterName[elem.label];
                }
            } else {
                currentFilter = {
                    ...currentFilter,
                    [elem.paramKey]: data
                };
                currentFilterName = {
                    ...currentFilterName,
                    [elem.label]: data
                };
            }
            setFilterData({ ...filterData, filters: currentFilter });
            setFilterName({ ...filterName, filters: currentFilterName });
        }
    };

    const setTopNFilter = (elem, data) => {
        let currentFilter = filterData?.top_n || {};
        let currentFilterName = filterData?.top_n || {};
        if (data?.key) {
            currentFilter = {
                ...currentFilter,
                [elem.paramKey]: data.key
            };
            currentFilterName = {
                ...currentFilterName,
                [elem.label]: data.key
            };
        } else {
            if (currentFilter?.hasOwnProperty(elem.paramKey)) {
                delete currentFilter[elem.paramKey];
                delete currentFilterName[elem.label];
            }
        }
        setFilterData({ ...filterData, top_n: currentFilter });
        setFilterName({ ...filterName, top_n: currentFilterName });
    };

    const getTotalFilterCount = () => {
        let filterCount = 0;
        filterCount = getAppliedFiltersCount(filterData);
        filterCount += queries?.length;
        return filterCount;
    };

    return {
        showBands,
        showDropdown,
        handleFilterView,
        dashboardFilterParams,
        masterFilterList,
        handleBandClick,
        dropdownLoader,
        filterData,
        handleFilterUpdate,
        isDirty,
        handleSubmit,
        activeTab,
        setActiveTab,
        showCancelConfirmation,
        onCancelConfirm,
        setShowCancelConfirmation,
        handleCancel,
        dynamicBands,
        buildingDynamicBands,
        filterFields,
        clearAllFilters,
        getFilterCountByBand,
        setShowDropdown,
        setWildCardFilter,
        isLoading,
        fieldSearch,
        setFieldSearch,
        showAppliedFilters,
        setShowAppliedFilters,
        totalFilterCount: getTotalFilterCount(),
        emptyDivRef,
        sectionRef,
        showSaveFilterModal,
        setShowSaveFilterModal,
        handleSaveFilerModal,
        handleSaveFilter
    };
};
export default useMyFilters;
