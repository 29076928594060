import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import ReactTooltip from "react-tooltip";

function SingleQaIssuePreview({
    onSubmit,
    selectedRow,
    onStatusChange,
    onCommentSubmit,
    onSendNotification,
    isQaQcReviewerUser,
    setShowConfirmation,
    onEdit,
    onClose
}) {
    const [isErr, setIsErr] = useState(false);
    const [titleValue, setTitleValue] = useState("");
    const [isEditMode, setIsEditMode] = useState(false);
    const [comment, setComment] = useState();

    const {
        getRecommendationReviewByIdResponse: {
            title = "",
            id,
            status,
            resolved_at: resolvedAt,
            created_at: createdAt,
            recommendation_review_comments: comments = [],
            surveyor_id: surveyorId,
            requestor = {}
        }
    } = useSelector(state => state.recommendationsReducer) || {};

    useEffect(() => {
        setIsEditMode(false);
        setTitleValue(title);
    }, [title]);
    const statusEnum = {
        pending: 0,
        resolved: 1,
        approved: 2
    };

    const userId = localStorage.getItem("userId");
    const userRole = localStorage.getItem("role");

    const handleStatusChange = ({ value }) => {
        onStatusChange({ id, status: statusEnum[value] });
    };

    const handleSubmitComment = () => {
        if (comment?.trim()) {
            onCommentSubmit(id, comment?.trim());
        }
        setComment("");
    };
    const handleClose = () => {
        setIsEditMode(false);
        setTitleValue(title);
    };

    const handleSave = () => {
        if (!titleValue?.trim()) return;
        if (titleValue?.trim() === title) return handleClose();
        onSubmit({ id: id, title: titleValue });
    };

    const statusClassEnum = {
        pending: "pending-orange",
        resolved: "res-yellow ",
        approved: "res-green"
    };

    const statusIndicatorIcon = {
        approved: "/img/approved.svg",
        pending: "/img/pending.svg",
        resolved: "/img/resolved.svg"
    };

    const renderStatusDropDown = () => {
        const statusOptions = isQaQcReviewerUser
            ? [
                  { label: "Pending", value: "pending" },
                  { label: "Resolved", value: "resolved" },
                  { label: "Approved", value: "approved" }
              ]
            : [
                  { label: "Pending", value: "pending" },
                  { label: "Resolved", value: "resolved" }
              ];
        const shouldHideDropDown = () => {
            if (status === "approved")
                return {
                    status: "Approved",
                    cls: "green"
                };
            if (!isQaQcReviewerUser && status === "resolved")
                return {
                    status: "Resolved",
                    cls: "yellow"
                };
            return null;
        };
        return (
            <div className="btn-area">
                {shouldHideDropDown() ? (
                    <button className={`btn btn-status ${shouldHideDropDown()?.cls}`}>{shouldHideDropDown()?.status}</button>
                ) : (
                    <>
                        <ReactTooltip id="status" className="rc-tooltip-custom-class" globalEventOff="click" />
                        <div
                            className="categ-select-box wid-sel-bx-110 pr-1"
                            data-delay-show="500"
                            data-tip="Change Status"
                            data-effect="solid"
                            data-for="status"
                            data-place="left"
                            data-background-color="#007bff"
                        >
                            <ReactSelect
                                className={`react-select-container ${statusClassEnum[status]}`}
                                classNamePrefix="react-select"
                                value={statusOptions.find(option => option.value === status)}
                                onChange={handleStatusChange}
                                options={statusOptions}
                                isSearchable={false}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    };

    const isCommentEnabled = () => {
        const userId = localStorage.getItem("userId");
        const role = localStorage.getItem("role");
        if (role === "super_admin") return true;
        if (userId === surveyorId) return true;
        if (isQaQcReviewerUser) return true;
        // return false;
        return true;
    };

    const RenderCommment = ({ comment }) => {
        return (
            <div className="comment-detail">
                <div className="chat-prof">
                    <div className="img-sec">
                        <img alt="" src="/img/user-icon.png" />
                    </div>
                    <div className="content-sec-out">
                        <h4>{comment?.creator?.name}</h4>
                        <h5>{comment?.created_at}</h5>
                    </div>
                </div>
                <div className="chat-coment">{comment?.comment}</div>
                {/* <div className="comment-action">
            <div className="edit-sec">
                <img alt="" src="/img/edit.png" className="img-edit" />
                <span>Edit</span>
            </div>
            <div className="del-sec">
                <img alt="" src="/img/delete.png" className="img-del" />
                <span>Delete</span>
            </div>
        </div> */}
            </div>
        );
    };
    if (id !== selectedRow) {
        return <div className="preview"></div>;
    }

    return (
        <div className="preview">
            <div class="close-coment imgCrsr" onClick={onClose}>
                <img src="/img/close.svg" alt="" />
            </div>
            <div className="heading-sec">
                {isEditMode ? (
                    <textarea
                        className={`form-control ${isErr ? "error-border" : ""}`}
                        value={titleValue}
                        onKeyDown={event => {
                            if (event.key === "Enter") {
                                setIsEditMode(false);
                                return handleSave();
                            }
                            if (event.key === "Escape") {
                                return handleClose();
                            }
                        }}
                        onChange={({ target: { value } }) => setTitleValue(value)}
                    ></textarea>
                ) : (
                    <h3>
                        <img alt="" src={statusIndicatorIcon[status]} />

                        <span> {titleValue || title}</span>
                    </h3>
                )}

                <ReactTooltip id="edit-title-preview" className="rc-tooltip-custom-class" />
                <ReactTooltip id="delete-issue-preview" className="rc-tooltip-custom-class" />
                {isQaQcReviewerUser && (
                    <div className="edit-send-sec">
                        {(userId === requestor?.id || userRole === "super_admin") && (
                            <>
                                <button
                                    className="edit-btn"
                                    onClick={() => onEdit({ title, id })}
                                    // data-for="edit-title-preview" data-tip="Edit title"
                                >
                                    <img src="/img/edit.png" className="/img-edit" alt="" />
                                    {isEditMode ? "Cancel" : "Edit Title"}
                                </button>

                                <button
                                    className="edit-btn delete-btn"
                                    onClick={() => setShowConfirmation(id)}
                                    // data-for="delete-issue-preview"
                                    // data-tip="Delete issue"
                                >
                                    <img src="/img/delete-icon-qa.svg" className="/img-edit" alt="" />
                                    Delete Issue
                                </button>
                            </>
                        )}
                        <ReactTooltip id="delete-issue-preview" className="rc-tooltip-custom-class" />

                        {status === "pending" && (
                            <button className="edit-btn send-btn" onClick={() => onSendNotification(id)}>
                                <img src="/img/send-view.svg" className="/img-send" alt="" />
                                Send
                            </button>
                        )}
                    </div>
                )}
            </div>

            <div className="element-sec">
                {/* reviewer name  */}
                <div className="resolve-date added-date">
                    <img alt="" src="/img/user-icon.png" />

                    <span>Reviewer : {requestor?.name || "-"}</span>
                </div>

                <div className="status-sec resolve-date">{renderStatusDropDown()}</div>
                {resolvedAt && (
                    <div className="resolve-date">
                        <img alt="" src="/img/stickynote.svg" />
                        <span>Resolved Date & Time : {resolvedAt}</span>
                    </div>
                )}

                <div className="resolve-date added-date">
                    <img alt="" src="/img/stickynote.svg" />
                    <span>Added Date & Time : {createdAt}</span>
                </div>
                <div className="comment-section-outer">
                    <div className="comment-person">
                        {comments?.length ? (
                            comments?.map(comment => <RenderCommment key={comment?.id} comment={comment} />)
                        ) : (
                            <h4 className="text-center">No Comments</h4>
                        )}
                    </div>
                    {isCommentEnabled() && (
                        <div className="comment-txtbox">
                            <div className="img-sec">
                                <img alt="" src="/img/user-icon.png" />
                            </div>
                            <input
                                type="text"
                                className={`form-control ${isErr ? "error-border" : ""}`}
                                placeholder="Add a Comment"
                                value={comment}
                                // onKeyPress={event => {
                                //     if (event.key === "Enter") {
                                //         handleSubmitComment();
                                //     }
                                // }}
                                onChange={({ target: { value } }) => setComment(value)}
                            />
                            <button className="btn-send-cmnt" onClick={handleSubmitComment}>
                                <img src="/img/send-btn.svg" alt="" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SingleQaIssuePreview;
