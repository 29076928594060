import React, { useCallback, useState } from "react";
import { useRef } from "react";
import { ProgressBar } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import actions from "../actions";

import folderIcon from "../../../assets/img/folder-open.svg";
import { connect } from "react-redux";
import moment from "moment";
import { UPLOAD_TYPES } from "../constants";

export const UploadContainer = props => {
    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        props.handleFileInput(acceptedFiles, fileRejections);
    }, []);

    const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);

    const imageExtensions = ["jpg", "jpeg", "png", "heic"];

    const imageValidator = file => {
        const extension = file?.name?.split(".")?.pop()?.toLowerCase();
        if (props.uploadType === UPLOAD_TYPES.THREE_SIXTY) {
            if (extension !== "zip") {
                return {
                    message: "non_zip_file"
                };
            }
            return null;
        } else {
            if (!imageExtensions.includes(extension)) {
                return {
                    message: `Non-image file`
                };
            }
            if (props.uploadType === UPLOAD_TYPES.ASSET && !isValidAssetImageName(file?.name)) {
                return {
                    message: `invalid_file_name`
                };
            }
            return null;
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        // noDrag: isFileDialogOpen,
        onDrop,
        validator: imageValidator,
        maxFiles: props.uploadType === UPLOAD_TYPES.THREE_SIXTY ? 1 : props.isAssignView ? 10 : 0,
        onFileDialogCancel: () => {
            setIsFileDialogOpen(false);
        },
        onFileDialogOpen: () => {
            setIsFileDialogOpen(true);
        }
    });

    const isValidAssetImageName = imageName => {
        const regex = /^\{[^}]*\}[^{]*\.[A-Za-z0-9]+$/;
        return regex.test(imageName);
    };

    const { filesCount, selectedImages, uploadedFiles, uploadProgress, setRotate, rotate } = props;

    return (
        <>
            {props.isEdit ? (
                <>
                    {selectedImages?.length === 1 ? (
                        <>
                            <img
                                src={selectedImages[0]?.is_edited ? `${selectedImages[0]?.s3_eimage_key}` : `${selectedImages[0]?.s3_image_key}`}
                                alt=""
                                style={{ transform: `rotate(${rotate}deg)` }}
                            />
                            {!selectedImages[0]?.is_edited ? (
                                <button className="btn btn-rotate-blue" onClick={setRotate}>
                                    <i class="fas fa-sync-alt"></i> Rotate
                                </button>
                            ) : null}
                        </>
                    ) : (
                        <div className="upload-sec">
                            <div class="imge-pre">
                                <img src={folderIcon} alt="" />
                                {props.filesCount ? <h4>Selected Images : {props.filesCount}</h4> : null}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div className="upload-sec cursor-hand" {...getRootProps()}>
                    {props.isUploading ? (
                        <div className="imge-loading">
                            <ProgressBar animated now={uploadProgress} label={`${uploadProgress}%`} />
                            <div className="count-progress"> {`${props.uploadedFiles.imagesCount}/${filesCount} `}</div>
                        </div>
                    ) : (
                        <>
                            <input type="file" {...getInputProps()} />
                            <div class="imge-pre">
                                <img src={folderIcon} />
                                {!props.isEdit && (
                                    <>
                                        <h4>
                                            Click here to select Files or Folders from your machine
                                            <br /> OR <br /> Drag & Drop your Files or Folders here
                                            <br />
                                            {props.uploadType !== UPLOAD_TYPES.THREE_SIXTY && "(Accepted image formats: JPG, JPEG, PNG, HEIC)"}
                                        </h4>
                                    </>
                                )}
                                {props.filesCount ? <h4>Selected Images : {props.filesCount}</h4> : null}
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

const mapStateToProps = state => {
    const { imageReducer } = state;
    return { imageReducer };
};
let { rotateImages } = actions;
export default React.memo(connect(mapStateToProps, { rotateImages })(UploadContainer));
