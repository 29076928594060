import * as actionType from "./constants";

const initialState = {
    getMedianListResponse: [],
    medianPostResponse: {},
    medianDeletedResponse: {},
    getMedianIdResponse: {},
    medianPatchResponse: {},
    entityParams: {
        entity: null,
        selectedEntity: null,
        selectedRowId: null,
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 0,
            search: "",
            filters: null,
            list: null,
            filterKeys: {}
        },
        wildCardFilterParams: {},
        filterParams: {},
        tableConfig: null,
        historyPaginationParams: {
            totalPages: 0,
            perPage: 40,
            currentPage: 0,
            totalCount: 0
        },
        historyParams: {
            limit: 40,
            offset: 0,
            search: ""
        }
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_MEDIAN_DATA_ENTITY_PARAMS_SUCCESS:
            return {
                ...state,
                entityParams: { ...action.response }
            };
        case actionType.UPDATE_MEDIAN_DATA_ENTITY_PARAMS_FAILURE:
            return {
                ...state,
                entityParams: { ...action.error }
            };
        case actionType.GET_MEDIAN_REQUEST:
            return {
                ...state
            };
        case actionType.GET_MEDIAN_SUCCESS:
            return {
                ...state,
                getMedianListResponse: { success: true, ...action.response }
            };
        case actionType.GET_MEDIAN_FAILURE:
            return {
                ...state,
                getMedianListResponse: { success: false, ...action.error }
            };
        case actionType.POST_MEDIAN_REQUEST:
            return {
                ...state
            };
        case actionType.POST_MEDIAN_SUCCESS:
            return {
                ...state,
                medianPostResponse: { success: true, ...action.response }
            };
        case actionType.POST_MEDIAN_FAILURE:
            return {
                ...state,
                medianPostResponse: { success: false, ...action.error }
            };
        case actionType.GET_MEDIAN_ID_REQUEST:
            return {
                ...state
            };
        case actionType.GET_MEDIAN_ID_SUCCESS:
            return {
                ...state,
                getMedianIdResponse: { success: true, ...action.response }
            };
        case actionType.GET_MEDIAN_ID_FAILURE:
            return {
                ...state,
                getMedianIdResponse: { success: false, ...action.error }
            };
        case actionType.PATCH_MEDIAN_REQUEST:
            return {
                ...state
            };
        case actionType.PATCH_MEDIAN_SUCCESS:
            return {
                ...state,
                medianPatchResponse: { success: true, ...action.response }
            };
        case actionType.PATCH_MEDIAN_FAILURE:
            return {
                ...state,
                medianPatchResponse: { success: false, ...action.error }
            };
        case actionType.DELETE_MEDIAN_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_MEDIAN_SUCCESS:
            return {
                ...state,
                medianDeletedResponse: { success: true, ...action.response }
            };
        case actionType.DELETE_MEDIAN_FAILURE:
            return {
                ...state,
                medianDeletedResponse: { success: false, ...action.error }
            };
        case actionType.CLEAR_REDUCER:
            return {
                ...state,
                medianPostResponse: {},
                medianPatchResponse: {},
                medianDeletedResponse: {},
                getMedianIdResponse: {}
            };
        default:
            return state;
    }
};
