import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { dateFilterKeys, numberFilterKeys, stringFilterKeys } from "../../../../utils/wildCardFilterUtils";
import { dateTimeFinder } from "../../../../config/utils";
import moment from "moment";
export const WildCardFilterItem = ({
    elem,
    showDropDown,
    setShowDropdown,
    filter,
    topNFilter,
    setWildCardFilter,
    handleFilterView,
    dropdownRef,
    isFavorite,
    toggleFavorite,
    isDropdownFiltered
}) => {
    const { label, fieldType, key, options = [] } = elem;
    const defaultFilterKey = fieldType === "number" ? ["equals"] : ["contains"];
    const [filterDate, setFilterDate] = useState({ from: "", to: "" });

    useEffect(() => {
        setFilterDate({ from: filter?.key?.from ?? "", to: filter?.key?.to ?? "" });
    }, []);

    const handleChangeFilter = (value, filterKey = null) => {
        const data = {
            key: value,
            filters: filterKey
                ? filterKey
                : filter?.filters?.includes("null") || filter.filters?.includes("not_null") || filter.filters?.includes("count_gt_1")
                ? defaultFilterKey
                : filter?.filters || defaultFilterKey
        };
        setWildCardFilter(elem, data);
    };

    const setFilterKey = (value, checked) => {
        let data = { key: filter?.key || null, filters: checked ? [value] : [] };
        if (fieldType === "date") {
            data = {
                ...data,
                key: checked && value !== "in_between" ? dateTimeFinder(value) : filterDate,
                filters: checked ? ["in_between"] : [],
                filterKey: checked ? [value] : []
            };

            setFilterDate(checked && value !== "in_between" ? { ...(dateTimeFinder(value) ?? {}) } : { ...filterDate });
        }

        if (value === "not_null" || value === "null" || value === "count_gt_1") {
            data.key = checked ? "" : null;
            data.filters = checked ? data?.filters : ["contains"];
            setShowDropdown("");
        } else if (fieldType === "date") {
            setShowDropdown("");
        } else if (data?.key?.trim()?.length && checked) {
            setShowDropdown("");
        }
        setWildCardFilter(elem, data, checked);
    };

    const handleClearFilter = () => {
        setShowDropdown("");
        setWildCardFilter(elem, { key: "", filters: [""] });
    };

    const renderWildCardOptions = () => {
        const filterKeys = fieldType === "number" || fieldType === "year" ? numberFilterKeys(elem) : stringFilterKeys;
        if (fieldType === "date") {
            return (
                <div className="dropdown-menu drop-filtr sub-drop">
                    <div className="slct-date-otr">
                        <div className="place-holder">
                            <div className="col-md-2 p-0 labl-txt">From</div>
                            <div className="col-md-10 pr-0">
                                <input
                                    value={filterDate?.from ? moment(filterDate?.from).format("yyyy-MM-DD") : null}
                                    type="date"
                                    onChange={e =>
                                        setFilterDate({
                                            ...filterDate,
                                            from: e.target.value
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="place-holder">
                            <div className="col-md-2 p-0 labl-txt">To</div>
                            <div className="col-md-10 pr-0">
                                <input
                                    value={filterDate?.to ? moment(filterDate?.to).format("yyyy-MM-DD") : null}
                                    type="date"
                                    onChange={e => setFilterDate({ ...filterDate, to: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="check-box-otr-dte d-flex col-md-12">
                        {dateFilterKeys
                            .filter(e => !["next_week", "next_year", "tomorrow", "next_month"].includes(e.value))
                            .map((item, i) => (
                                <>
                                    <div key={i} className="col-md-6 p-0">
                                        <span className="dropdown-item">
                                            <label className="container-check">
                                                {item.label}

                                                {item.value ? (
                                                    <>
                                                        <input
                                                            type="checkbox"
                                                            checked={filter?.filterKey?.includes(item.value)}
                                                            onChange={e => setFilterKey(item.value, e.target.checked)}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </>
                                                ) : null}
                                            </label>
                                        </span>
                                    </div>
                                    {i % 6 === 1 ? <div className="col-md-12 dropdown-line"></div> : null}
                                </>
                            ))}
                    </div>
                </div>
            );
        }

        return (
            <div className="dropdown-menu drop-filtr" aria-labelledby="dropdownMenuButton">
                {filterKeys.map(filterKey => (
                    <span key={filterKey.value} className="dropdown-item">
                        <label className="container-check">
                            {filterKey.label}
                            <input
                                type="checkbox"
                                checked={filter?.filters?.includes(filterKey.value)}
                                onChange={e => setFilterKey(filterKey.value, e.target.checked)}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </span>
                ))}
            </div>
        );
    };

    const isFiltered =
        filter.key ||
        filter.filters?.includes("null") ||
        filter.filters?.includes("not_null") ||
        filter.filters?.includes("count_gt_1") ||
        filter.filters?.includes("in_between");

    return (
        <div className={`form-item asset-form-filtr wd-filter ${fieldType === "date" ? "calen-filter-outr" : ""}`}>
            <div className="d-flex justify-content-between">
                {elem.hasTopNFilterField && (
                    <div className="tp-box">
                        <NumberFormat
                            type="text"
                            className={`w-50 mb-1 band-top-outer ${topNFilter > 0 ? "bg-th-filtered" : ""}`}
                            value={topNFilter?.toString() || ""}
                            onValueChange={(values, sourceInfo) => {
                                if (sourceInfo?.source === "event") {
                                    const { value } = values;
                                    handleChangeFilter(value, ["top_n"]);
                                }
                            }}
                            thousandSeparator
                            isAllowed={values => {
                                if (!values.value) return true;
                                const { value } = values;
                                return value > 0;
                            }}
                            decimalScale={0}
                            allowNegative={false}
                            placeholder={"Top N"}
                            prefix="Top "
                        />
                        <span aria-hidden="true" class="band-top-icon-close" onClick={() => handleChangeFilter("", ["top_n"])}>
                            <i class="fa fa-times"></i>
                        </span>
                    </div>
                )}
            </div>
            {fieldType === "date" ? (
                <div className={`caln-div cursor-pointer ${isFiltered ? "bg-th-filtered" : ""}`} onClick={() => handleFilterView(elem, dropdownRef)}>
                    <div className="caln-txt">Select Date</div>
                </div>
            ) : fieldType === "select" ? (
                <div className={`selectOtr`}>
                    <select
                        autoComplete={"nope"}
                        className={`form-control custom-select ${isFiltered ? "bg-th-filtered" : ""}`}
                        value={filter?.key || ""}
                        onChange={e => {
                            handleChangeFilter(e.target.value, ["equals"]);
                        }}
                    >
                        {options.length > 0 && options.map(item => <option value={item.value}>{item.label}</option>)}
                    </select>
                </div>
            ) : fieldType === "number" || fieldType === "year" ? (
                <NumberFormat
                    type="text"
                    className={`form-control ${isFiltered ? "bg-th-filtered" : ""}`}
                    value={filter?.key || ""}
                    thousandSeparator={fieldType === "year" ? false : true}
                    onValueChange={(values, sourceInfo) => {
                        if (sourceInfo?.source === "event") {
                            const { value } = values;
                            handleChangeFilter(value);
                        }
                    }}
                    placeholder={"Type Here..."}
                    onKeyDown={e => (e.key === "Enter" ? setShowDropdown("") : undefined)}
                />
            ) : (
                <input
                    type="text"
                    className={`form-control ${isFiltered ? "bg-th-filtered" : ""}`}
                    value={filter?.key || ""}
                    onChange={e => handleChangeFilter(e.target.value)}
                    placeholder={"Type Here..."}
                    onKeyDown={e => (e.key === "Enter" ? setShowDropdown("") : undefined)}
                />
            )}

            <i className="fas fa-times cursor-pointer cls" onClick={handleClearFilter}></i>
            <i
                className={`fas ${elem?.fieldType === "date" ? "fa-calendar" : "fa-filter"} cursor-pointer filter-icn`}
                onClick={() => handleFilterView(elem, dropdownRef, true)}
            ></i>

            {showDropDown === key && renderWildCardOptions()}
        </div>
    );
};
