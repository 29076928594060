import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import SmartChartExportsList from "./SmartChartExportsList";
import Properties from "../properties/index";
import { SMART_CHART_TAB_VIEWS, SMART_CHART_FAVORITE_TAB_VIEWS, SMART_CHART_REPORT_VIEW } from "../constants";
import Documents from "./Documents";
import SmartChartDataEditForm from "./SmartChartDataEditForm";
import { useSelector } from "react-redux";
import Portal from "../../common/components/Portal";
import ReactTooltip from "react-tooltip";

const SmartChartMain = ({
    history,
    smartChartTableData,
    showWildCardFilter,
    paginationParams,
    currentViewAllUsers,
    showViewModal,
    tableData,
    handleGlobalSearch,
    toggleWildCardFilter,
    updateCurrentViewAllUsers,
    handleDeleteSite,
    showEditPage,
    handlePerPageChange,
    handlePageClick,
    showAddForm,
    updateSelectedRow,
    selectedRowId,
    globalSearchKey,
    match: {
        params: { section }
    },
    showInfoPage,
    updateWildCardFilter,
    wildCardFilter,
    handleHideColumn,
    getListForCommonFilterSite,
    updateCommonFilter,
    commonFilter,
    resetAllFilters,
    isColunmVisibleChanged,
    resetAll,
    updateTableSortFilters,
    resetSort,
    tableParams,
    exportSiteTable,
    permissions,
    showAddButton,
    hasExport,
    hasEdit,
    hasDelete,
    hasInfoPage,
    entity,
    hasActionColumn,
    handleDownloadItem,
    refreshTableData,
    deleteSmartChartReport,
    regenerateSmartChart,
    showSmartChartDataEditModal,
    getSmartChartMasterFilterDropDown,
    masterFilterList,
    mFilters,
    updateMfilterForSmartChartList,
    handleClickTab,
    viewSmartChartProperty,
    handleEditSmartChartProperty,
    handleExportSmartReport,
    updateFiltersForMasterFilter,
    isModalView = false,
    viewReports,
    toggleSelectDownloadTypeModal,
    addToFavorite,
    isFavoriteView,
    handleSortData,
    handleSelectViewType,
    sortParams,
    viewType,
    toggleAssignUsersToFavoriteModal,
    toggleCreateReportFromCoreReportModal,
    selectedTabForFavoriteView,
    path,
    tableConfig,
    setUpdateCoreReport,
    setUpdateState,
    handleExportSmartReports,
    setShowDashboardSmartChartExport,
    isReportView = false,
    ...props
}) => {
    useEffect(() => {
        let previousProperty = sessionStorage.getItem("selectedProperty") || null;
        if (section === "reports" && previousProperty && !isModalView) {
            viewSmartChartProperty(previousProperty);
            sessionStorage.removeItem("selectedProperty");
        }
    }, [section]);

    let tabViews = isFavoriteView ? SMART_CHART_FAVORITE_TAB_VIEWS : isReportView ? SMART_CHART_REPORT_VIEW : SMART_CHART_TAB_VIEWS;
    let currentSection = isFavoriteView || isReportView ? selectedTabForFavoriteView : section;
    const { filterValues } = useSelector(state => state?.dashboardReducer);

    return (
        <React.Fragment>
            <div
                className={`dtl-sec col-md-12 ${isFavoriteView || isModalView ? "height-full" : ""} padd-report ${
                    !currentSection || currentSection === "corereports" ? "view-pad" : ""
                }`}
            >
                <div className="tab-dtl region-mng smchart-exp top-smrt-exp">
                    <div className="d-flex justify-content-between align-items-center">
                        {isFavoriteView || !isModalView || isReportView ? (
                            <>
                                <ul className={`tab-data`}>
                                    {tabViews
                                        ?.filter(item => !item?.isButtonTab)
                                        ?.map((tabView, index) => (
                                            <li
                                                key={index}
                                                className={`cursor-pointer ${currentSection === tabView.key ? "active" : ""}`}
                                                onClick={() => handleClickTab(tabView, isFavoriteView || isReportView)}
                                            >
                                                {tabView.label}
                                            </li>
                                        ))}
                                </ul>

                                <div class="sm-chart-btn">
                                    {tabViews
                                        ?.filter(item => item?.isButtonTab)
                                        ?.map((tabView, index) => (
                                            <button
                                                key={tabView.key}
                                                onClick={() => handleClickTab(tabView, isFavoriteView || isReportView)}
                                                class={`btn ${tabView.class} ${currentSection === tabView.key ? "active-smrt-btn" : ""} `}
                                            >
                                                <span class="icon mr-1">
                                                    <img src={tabView.icon} alt="" />
                                                </span>
                                                <span class="text">{tabView.label}</span>
                                            </button>
                                        ))}
                                </div>
                            </>
                        ) : null}
                        {path === "/dashboard" && (
                            <button
                                className="icon-bg-outr btnText exp-butn"
                                data-tip={`Generate Report For Current Dashboard`}
                                data-for="smart-chart"
                                onClick={e => {
                                    e.preventDefault();
                                    setShowDashboardSmartChartExport(true);
                                }}
                                disabled={filterValues?.client_ids?.length > 1 || filterValues?.project_ids?.length > 1}
                            >
                                <img src="/img/export.svg" /> Dashboard Report
                            </button>
                        )}
                        <ReactTooltip id="smart-chart" place="left" effect="solid" backgroundColor="#007bff" />
                    </div>
                    {(currentSection === "reporttemplates" && !isModalView) || (currentSection === "reporttemplates" && path === "/dashboard") ? (
                        <Properties
                            // viewSmartChartProperty={viewSmartChartProperty}
                            handleEditSmartChartProperty={handleEditSmartChartProperty}
                            handleExportSmartReport={handleExportSmartReport}
                            updateFiltersForMasterFilter={updateFiltersForMasterFilter}
                            savedParams={mFilters}
                            showSmartChartDataEditModal={showSmartChartDataEditModal}
                            viewReports={viewReports}
                        />
                    ) : currentSection === "documents" && !isModalView ? (
                        <>
                            <Documents
                                getSmartChartMasterFilterDropDown={getSmartChartMasterFilterDropDown}
                                updateMfilterForSmartChartList={updateMfilterForSmartChartList}
                                updateFiltersForMasterFilter={updateFiltersForMasterFilter}
                                savedParams={mFilters}
                            />
                        </>
                    ) : currentSection === "images" && !isModalView ? (
                        <Documents
                            isImage
                            getSmartChartMasterFilterDropDown={getSmartChartMasterFilterDropDown}
                            updateMfilterForSmartChartList={updateMfilterForSmartChartList}
                            updateFiltersForMasterFilter={updateFiltersForMasterFilter}
                            savedParams={mFilters}
                        />
                    ) : (currentSection === "corereports" && !isModalView) ||
                      (currentSection === "corereports" && path === "/dashboard") ||
                      (currentSection === "corereports" && isReportView) ? (
                        <>
                            <Properties
                                // viewSmartChartProperty={viewSmartChartProperty}
                                handleEditSmartChartProperty={handleEditSmartChartProperty}
                                handleExportSmartReport={handleExportSmartReport}
                                updateFiltersForMasterFilter={updateFiltersForMasterFilter}
                                savedParams={mFilters}
                                showSmartChartDataEditModal={showSmartChartDataEditModal}
                                viewReports={viewReports}
                                isSkeletonView
                                toggleCreateReportFromCoreReportModal={toggleCreateReportFromCoreReportModal}
                                isModalView={isModalView}
                                setUpdateCoreReport={setUpdateCoreReport}
                                currentSection={currentSection}
                                setUpdateState={setUpdateState}
                                isReportView={isReportView}
                            />
                        </>
                    ) : (
                        <SmartChartExportsList
                            getSmartChartMasterFilterDropDown={getSmartChartMasterFilterDropDown}
                            masterFilterList={masterFilterList}
                            mFilters={mFilters}
                            updateMfilterForSmartChartList={updateMfilterForSmartChartList}
                            paginationParams={paginationParams}
                            handlePageClick={handlePageClick}
                            handlePerPageChange={handlePerPageChange}
                            tableData={tableData}
                            hasEdit={hasEdit}
                            showAddButton={showAddButton}
                            refreshTableData={refreshTableData}
                            handleGlobalSearch={handleGlobalSearch}
                            globalSearchKey={globalSearchKey}
                            hasExport={hasExport}
                            hasDelete={hasDelete}
                            resetAllFilters={resetAllFilters}
                            showSmartChartDataEditModal={showSmartChartDataEditModal}
                            regenerateSmartChart={regenerateSmartChart}
                            deleteSmartChartReport={deleteSmartChartReport}
                            handleDownloadItem={handleDownloadItem}
                            showWildCardFilter={showWildCardFilter}
                            showEditPage={showEditPage}
                            showInfoPage={showInfoPage}
                            updateSelectedRow={updateSelectedRow}
                            selectedRowId={selectedRowId}
                            updateWildCardFilter={updateWildCardFilter}
                            wildCardFilter={wildCardFilter}
                            handleHideColumn={handleHideColumn}
                            getListForCommonFilter={getListForCommonFilterSite}
                            updateCommonFilter={updateCommonFilter}
                            commonFilter={commonFilter}
                            updateTableSortFilters={updateTableSortFilters}
                            tableParams={tableParams}
                            permissions={permissions}
                            hasInfoPage={hasInfoPage}
                            hasActionColumn={hasActionColumn}
                            viewSmartChartProperty={viewSmartChartProperty}
                            handleEditSmartChartProperty={handleEditSmartChartProperty}
                            isModalView={isModalView}
                            toggleSelectDownloadTypeModal={toggleSelectDownloadTypeModal}
                            addToFavorite={addToFavorite}
                            isFavoriteView={isFavoriteView}
                            handleSortData={handleSortData}
                            handleSelectViewType={handleSelectViewType}
                            sortParams={sortParams}
                            viewType={viewType}
                            toggleAssignUsersToFavoriteModal={toggleAssignUsersToFavoriteModal}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(SmartChartMain);
