import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import Portal from "../../common/components/Portal";
import { useDispatch, useSelector } from "react-redux";
import actions from "../actions";
import userActions from "../../users/actions";
import { showAlert } from "../../../config/utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Draggable from "react-draggable";
import ReactTooltip from "react-tooltip";
import { downloadPresignedUrlFile } from "../../common/actions";

export const BulkUploadForm = ({ clientDetails, ...props }) => {
    const dispatch = useDispatch();
    const { section, id } = useParams();
    const { excelUploadResponse } = useSelector(state => state.assetReducer);
    const { excelUserUploadResponse, userExcelTemplate } = useSelector(state => state.userReducer);
    const [file, setFile] = useState(null);
    const [clientId] = useState(section === "assetinfo" ? id : clientDetails?.id);
    const [showConfirmModal, setConfirmModal] = useState(false);
    const [uploadError, setUploadError] = useState("");
    const [attachmentChanged, setAttachmentChanged] = useState(false);
    const [showErrorBorder, setErrorBorder] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        if (alertMessage) {
            showAlert(alertMessage, "isLong");
            setAlertMessage("");
        }
    }, [alertMessage]);

    const validate = () => {
        if (!file) {
            setErrorBorder(true);
            setUploadError("Please choose file");
            return false;
        }
        if (props.importType !== "users" && !clientId) {
            setErrorBorder(true);
            setUploadError("Please choose a client");
            return false;
        }
        return true;
    };

    const handleUploadData = () => {
        if (validate()) {
            setIsUploading(true);
            uploadData();
        }
    };

    useEffect(() => {
        if (props.importType === "users") dispatch(userActions.getUserExcelTemplate({ upload_type: "user_upload" }));
    }, []);

    useEffect(() => {
        if (props.importType === "users") {
            if (excelUserUploadResponse) {
                const { success, message } = excelUserUploadResponse;
                if (success) {
                    setIsUploading(false);
                    setAlertMessage(message);
                    setTimeout(() => {
                        props.onCancel();
                    }, 1000);
                } else {
                    setIsUploading(false);
                    setAlertMessage("Something went wrong. Please check the import history for more details");
                }
            }
        } else {
            if (excelUploadResponse) {
                const { success, message } = excelUploadResponse;
                if (success) {
                    setIsUploading(false);
                    setAlertMessage(message);
                    setTimeout(() => {
                        props.onCancel();
                        props.onSuccess()
                    }, 1000);
                } else {
                    setIsUploading(false);
                    setAlertMessage("Something went wrong. Please check the import history for more details");
                }
            }
        }
        return () => {
            dispatch(actions.clearExcelDataResponse());
            dispatch(userActions.clearExcelDataResponse());
        };
    }, [excelUploadResponse, excelUserUploadResponse]);

    const uploadData = () => {
        let params = { file };
        if (clientId) {
            params.client_id = clientId;
            dispatch(actions.uploadExcelData(params, props.importType));
        } else if (props?.importType === "users") {
            dispatch(userActions.uploadExcelData(params));
        }
    };

    const renderConfirmationModal = () => {
        if (!showConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to close and lose all changes?"}
                        type="cancel"
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => setConfirmModal(false)}
                        onYes={clearForm}
                    />
                }
                onCancel={() => setConfirmModal(false)}
            />
        );
    };

    const cancelForm = () => {
        if (!attachmentChanged) {
            clearForm();
        } else {
            setConfirmModal(true);
        }
    };

    const clearForm = async () => {
        props.onCancel();
    };

    const handleAddAttachment = e => {
        setUploadError("");
        const attachment = e.target.files?.[0];
        let ext = attachment?.name?.split(".").pop();
        const acceptableExt = ["xls", "xlsx", "xlsm"];
        if (acceptableExt.includes(ext)) {
            if (attachment.size < 100000000) {
                setAttachmentChanged(true);
                setFile(attachment);
            } else {
                setUploadError("File is too big. Files with size greater than 100MB is not allowed.");
            }
        } else {
            setAttachmentChanged(false);
            setUploadError("* Upload xls or xlsm or xlsx Files !!!");
        }
    };

    const handleDownloadWord = () => {
        const { s3_link } = userExcelTemplate;
        if (s3_link) {
           downloadPresignedUrlFile(s3_link)
        }
    };

    return (
        <React.Fragment>
            <div
                className="modal modal-region"
                id="modalId"
                style={{ display: "block" }}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <Draggable handle=".draggable" cancel=".not-draggable">
                        <div className="modal-content">
                            <div className="modal-header draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {props.importType === "special" ? "Upload File (New format)" : "Upload Data"}
                                </h5>
                                <div className="not-draggable">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={cancelForm}>
                                        <span aria-hidden="true">
                                            <img src="/img/close.svg" alt="" />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="add-project">
                                <div className="modal-body region-otr">
                                    <div className="building-form">
                                        {clientDetails && (
                                            <div className="form-group">
                                                <div className="formInp">
                                                    <label>Client *</label>
                                                    <input
                                                        readOnly
                                                        className="cursor-diabled custom-input form-control"
                                                        defaultValue={clientDetails?.name || ""}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-md-12 upldFile btnAddCam p-0">
                                            <input
                                                type="file"
                                                className="form-control"
                                                id="attachmentFiles"
                                                name="projectFile"
                                                accept=".xls, .xlsx, .xlsm"
                                                onChange={handleAddAttachment}
                                            />
                                            <div className="upld-otr d-flex">
                                                <label
                                                    htmlFor="attachmentFiles"
                                                    className={`${showErrorBorder && !file ? "error-border" : ""} custom-file-uploadd cursor-pointer`}
                                                    title={file?.name || null}
                                                >
                                                    {file ? (
                                                        file?.name?.length > 25 ? (
                                                            file.name.substring(0, 25) + "..."
                                                        ) : (
                                                            file.name
                                                        )
                                                    ) : (
                                                        <>
                                                            <img src="/img/upload.png" alt="" />
                                                            Upload File *
                                                        </>
                                                    )}
                                                </label>
                                            </div>
                                            <div className="text-center">
                                                <span className="text-danger">{uploadError}</span>
                                            </div>
                                            <p className="upld">Upload xls or xlsm or xlsx Files</p>
                                        </div>
                                        <div className="text-center btnOtr upload-general-data">
                                            {props?.importType === "users" && (
                                                <div className="col-md-2 p-0 text-center dwd-btn-left">
                                                    <button
                                                        className="btn-download"
                                                        data-place="top"
                                                        data-tip={"Download Template"}
                                                        data-for="export"
                                                        onClick={() => userExcelTemplate?.s3_link && handleDownloadWord()}
                                                    >
                                                        <img src="/img/file-download.svg" alt="" />
                                                        <ReactTooltip id="export" effect="solid" />
                                                    </button>
                                                </div>
                                            )}
                                            <div className="btn-wrapper">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btnClr col-md-6"
                                                    data-dismiss="modal"
                                                    onClick={() => cancelForm()}
                                                >
                                                    Cancel
                                                </button>
                                                {isUploading ? (
                                                    <button type="button" className="btn btn-primary btnRgion col-md-6">
                                                        <div className="button-loader d-flex justify-content-center align-items-center">
                                                            <div className="spinner-border text-white" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleUploadData()}
                                                        className="btn btn-primary btnRgion col-md-6"
                                                    >
                                                        Upload
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
            </div>
            {renderConfirmationModal()}
        </React.Fragment>
    );
};
