import React, { useState, useEffect } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

import ChartItem from "./ChartItem";
import MasterFilter from "./MasterFilter";
import { CHART_ITEMS, MASTER_FILTER_ROWS } from "../constants";
import { getEnityNameForCAband } from "../utils";
import { MyFilters } from "../../dashboard/components/FilterModal/MyFilters";
import Portal from "../../common/components/Portal";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../dashboard/actions";

const ChartSection = ({
    getSmartChartMasterFilterDropDown,
    projectsDropdownData = [],
    projectId,
    setSmartChartData,
    currentBand,
    currentBandIndex,
    masterFilterList,
    openChartItemConfigModal,
    chartSectionData,
    chartKeys = [],
    copyChartSectionData,
    entity,
    selectedClient,
    toggleImageListForBandModal,
    toggleImageBandDeleteConfirmationModal,
    editImageBand,
    isEdit,
    updatePageBreakConfig,
    isExcelExport,
    isSkeletonView,
    requiredFields,
    filterOpen = false,
    setFilterOpen
}) => {
    const [chartData, setChartData] = useState([]);
    const [rowConstructArray, setRowConstructArray] = useState([]);
    const [year, setYear] = useState("");
    const dispatch = useDispatch();
    const [smartChartFilterName, setSmartChartFilterName] = useState(chartSectionData?.filter_label || {});
    const {
        filterFields,
        masterFilterList: dashboardMasterFilterList,
        getDashboard,
        getBuildingTypeFilter
    } = useSelector(state => state.dashboardReducer);
    const [smartChartSectionData, setSmartChartSectionData] = useState(chartSectionData?.mfilter || {});
    const [isMasterFilterSubmit, setMasterFilterSubmit] = useState(false);
    useEffect(() => {
        setSmartChartFilterName(chartSectionData?.filter_label);
    }, [chartSectionData]);
    useEffect(() => {
        setSmartChartSectionData(chartSectionData?.mfilter);
        setChartItemData(entity);
        if (Object?.keys(chartSectionData?.mfilter)?.length > 0) {
            Object.entries(chartSectionData?.mfilter)?.forEach(([key, value]) => {
                if (key === "building_types" && isEdit && !isMasterFilterSubmit) {
                    const { [key]: omit, ...rest } = { ...chartSectionData?.mfilter };
                    dispatch(actions.getMasterFilter(rest, key));
                    setMasterFilterSubmit(null);
                } else {
                    if (isMasterFilterSubmit) {
                        const names =
                            Array.isArray(value) && value.length > 0
                                ? dashboardMasterFilterList?.building_types?.filter(ms => value.includes(ms.id))?.map(item => item.name) || []
                                : [];
                        setSmartChartSectionData({ ...chartSectionData?.mfilter, building_types: names });
                    }
                }
            });
        }
    }, [chartSectionData, isExcelExport]);
    useEffect(() => {
        if (filterOpen) {
            setMasterFilterSubmit(false);
        }
    }, [filterOpen]);
    useEffect(() => {
        if (Object?.keys(chartSectionData?.mfilter)?.length > 0) {
            Object.entries(chartSectionData?.mfilter)?.forEach(([key, value]) => {
                if (key === "building_types") {
                    const names = dashboardMasterFilterList?.building_types?.filter(ms => value.includes(ms.id))?.map(item => item.name) || [];
                    setSmartChartSectionData({ ...chartSectionData?.mfilter, building_types: names });
                }
            });
        }
    }, [dashboardMasterFilterList]);
    useEffect(() => {
        if (getDashboard && getDashboard.years) {
            let graphData = getDashboard.years;

            let yearValues = [];
            for (let i = graphData.start; i <= graphData.end; i++) yearValues.push(i);

            setYear(yearValues);
        }
    }, [getDashboard]);
    useEffect(() => {
        if (isMasterFilterSubmit) {
            MasterFilterSubmit(isMasterFilterSubmit);
        }
    }, [isMasterFilterSubmit, dashboardMasterFilterList]);

    const setChartItemData = (entity = "project") => {
        let chartDataArray = [];
        let chartItemList = { ...chartSectionData.type };
        let copyChartItemList = copyChartSectionData?.type || {};
        let entityKeys = Object.keys(CHART_ITEMS[entity]);
        chartKeys.forEach(chartItem => {
            chartDataArray.push({
                name:
                    chartItem.includes("user_doc") || chartItem.includes("image_doc")
                        ? chartItemList[chartItem]?.name || copyChartItemList?.[chartItem]?.name || chartItem
                        : chartItem.includes("list_image")
                        ? chartItemList[chartItem]?.name || copyChartItemList?.[chartItem]?.name || "Image Band"
                        : chartItem.includes("multi_recommendation")
                        ? chartItemList[chartItem]?.name || copyChartItemList?.[chartItem]?.name || "Recommendation Summary"
                        : chartItem.includes("normal_recom")
                        ? chartItemList[chartItem]?.band1?.name || copyChartItemList?.[chartItem]?.band1?.name || "Recommendation Table"
                        : chartItem.includes("recom_onepager")
                        ? chartItemList[chartItem]?.band1?.name || copyChartItemList?.[chartItem]?.band1?.name || "Recommendation One Pager"
                        : chartItem.includes("asset-onepager")
                        ? chartItemList[chartItem]?.band1?.name || copyChartItemList?.[chartItem]?.band1?.name || "Asset One Pager"
                        : chartItem.includes("bbi_report")
                        ? chartItemList[chartItem]?.band1?.name || copyChartItemList?.[chartItem]?.band1?.name || "BBI"
                        : chartItem.includes("bsi_report")
                        ? chartItemList[chartItem]?.band1?.name || copyChartItemList?.[chartItem]?.band1?.name || "BSI"
                        : chartItem.includes("memo_field")
                        ? chartItemList[chartItem]?.name || copyChartItemList?.[chartItem]?.name || "Text Band"
                        : chartItem.includes("filter_report1")
                        ? chartItemList[chartItem]?.name || copyChartItemList?.[chartItem]?.name || "Master Filters"
                        : !entityKeys.includes(chartItem)
                        ? `Comparative Analysis Band (${
                              chartItem?.includes("fci_benchmarking")
                                  ? getEnityNameForCAband(chartItem.split("_")?.slice(0, 2)?.join("_")) +
                                    " & " +
                                    getEnityNameForCAband(chartItem.split("_")?.[2])
                                  : getEnityNameForCAband(chartItem.split("_")?.[0]) + " & " + getEnityNameForCAband(chartItem.split("_")?.[1])
                          })` || chartItem
                        : chartItem,
                isSelected: chartItemList.hasOwnProperty(chartItem),
                defaultValue: CHART_ITEMS[entity][chartItem]?.defaultValue || "",
                // isVisible: CHART_ITEMS[entity][chartItem]?.isVisible,
                totalChartCount: chartItem.includes("list_image")
                    ? chartItemList?.[chartItem]?.data?.length || 0
                    : chartItem.includes("multi_recommendation")
                    ? chartItemList?.[chartItem]?.recommendations?.length || chartItemList?.[chartItem]?.number_of_recom || 0
                    : chartItem.includes("normal_recom") || chartItem.includes("recom_onepager") || chartItem.includes("asset-onepager")
                    ? chartItem.includes("asset-onepager")
                        ? chartItemList?.[chartItem]?.band1?.mfilter?.asset_ids?.length || 0
                        : chartItemList?.[chartItem]?.band1?.mfilter?.recommendations?.length ||
                          chartItemList?.[chartItem]?.band1?.number_of_recom ||
                          0
                    : (chartItemList?.[chartItem]?.summary_view?.chart_type?.length || 0) +
                      (chartItemList?.[chartItem]?.detailed_view?.chart_type?.length || 0),
                isUserDoc: chartItem.includes("user_doc") || chartItem.includes("image_doc") ? true : false,
                isImage: chartItem.includes("image_doc") ? true : false,
                isImageBand: chartItem.includes("list_image") ? true : false,
                isFilterReport: chartItem.includes("filter_report1") ? true : false,
                docKey: chartItem,
                hasConfig:
                    CHART_ITEMS[entity]?.[chartItem]?.hasConfig ||
                    chartItem.includes("list_image") ||
                    chartItem.includes("multi_recommendation") ||
                    chartItem.includes("normal_recom") ||
                    chartItem.includes("recom_onepager") ||
                    chartItem.includes("asset-onepager")
                        ? true
                        : false,
                config: CHART_ITEMS[entity]?.[chartItem]?.config || null,
                entityIcon: getEntityIcon(chartItem, entityKeys),
                isIndividualBuilding: chartItemList?.[chartItem]?.hasOwnProperty("is_indv_bld"),
                isCAband:
                    !chartItem.includes("list_image") &&
                    !chartItem.includes("image_doc") &&
                    !chartItem.includes("user_doc") &&
                    !chartItem.includes("multi_recommendation") &&
                    !chartItem.includes("normal_recom") &&
                    !chartItem.includes("recom_onepager") &&
                    !chartItem.includes("asset-onepager") &&
                    !chartItem.includes("memo_field") &&
                    !entityKeys.includes(chartItem),
                isMultipleRecommendation: chartItem.includes("multi_recommendation") ? true : false,
                isRecommendation: chartItem.includes("normal_recom") ? true : false,
                tileData: chartItemList?.[chartItem] || copyChartItemList?.[chartItem] || {},
                isOnePager: chartItem.includes("recom_onepager") || chartItem.includes("asset-onepager") ? true : false,
                isAssetOnePager: chartItem?.includes("asset-onepager"),
                isBbi: chartItem.includes("bbi_report"),
                isBsi: chartItem.includes("bsi_report"),
                isMemo: chartItem.includes("memo_field")
            });
        });
        if (chartDataArray.length) {
            let totalRows = chartDataArray.length / 4 + (chartDataArray.length % 4 ? 1 : 0);
            let tempRowConstructArray = [];
            for (let i = 1; i <= totalRows; i++) {
                tempRowConstructArray.push(i);
            }
            setRowConstructArray([...tempRowConstructArray]);
        }
        setChartData([...chartDataArray]);
    };
    const getEntityIcon = (chartItem, entityKeys = []) => {
        if (chartItem.includes("user_doc")) {
            return "file-type-standard.svg";
        }
        if (chartItem.includes("image_doc") || chartItem.includes("list_image")) {
            return "smart-chart-image-item.svg";
        }
        if (chartItem?.includes("recom_onepager") || chartItem?.includes("asset-onepager")) {
            return "sm-charts-one-pager.svg";
        }
        if (
            chartItem == "sorted_recom" ||
            chartItem == "energy_band" ||
            chartItem == "water_band" ||
            chartItem === "multi_recommendation" ||
            chartItem == "normal_recom" ||
            chartItem == "asset_band" ||
            chartItem.includes("bbi_report") ||
            chartItem.includes("bsi_report") ||
            chartItem.includes("filter_report1") ||
            !entityKeys.includes(chartItem) ||
            isExcelExport
        ) {
            return "sm-charts-table-blue-icon.svg";
        }
        return "smart-chart-chart-icon-item.svg";
    };
    const filteredData = Object.keys(MASTER_FILTER_ROWS)
        .filter(key => key !== "project")
        .reduce((obj, key) => {
            obj[key] = MASTER_FILTER_ROWS[key];
            return obj;
        }, {});
    const getSystemChartParams = dashboardFilterParams => {
        let params = {};
        let buildingTypeData = getBuildingTypeFilter?.building_types || dashboardMasterFilterList?.building_types;
        const building_type_ids =
            buildingTypeData?.filter(item => dashboardFilterParams?.building_types?.some(elm => elm === item.name)).map(item => item.id) || [];
        if (building_type_ids?.length) {
            params = building_type_ids;
        }
        return params;
    };
    const MasterFilterSubmit = filterObjects => {
        if (filterObjects) {
            const { filters, top_n, ...rest } = filterObjects;
            filterObjects = {
                ...rest,
                ...(filters && { filters }),
                ...(top_n && { top_n })
            };
        }
        if (!filterObjects?.filters || !filterObjects?.strat_year || !filterObjects?.end_year) {
            setSmartChartData("mfilter", {
                bandName: currentBand,
                entity: entity,
                removeWildcardFilter: !filterObjects?.filters,
                removeYearFilter: !filterObjects?.start_year || !filterObjects?.end_year
            });
        }
        if (Object?.keys(filterObjects)?.length > 0 && !Object?.keys(filterObjects).every(item => item === "project_ids")) {
            Object?.keys(filterObjects)?.map(paramKeys => {
                const filterDetail = filterFields?.find(({ paramKey }) => paramKey === paramKeys);
                if (Object?.keys(filterObjects)?.length > 0) {
                    if (paramKeys === "exclude_filters") return;
                    let building_ids = [];
                    if (paramKeys === "building_types") {
                        building_ids = getSystemChartParams(filterObjects);
                    }
                    setSmartChartData("mfilter", {
                        mfilterKey: paramKeys === "start_year" || paramKeys === "end_year" ? "years" : paramKeys,
                        bandName: currentBand,
                        filterValues:
                            filterObjects[paramKeys]?.length > 0
                                ? paramKeys === "building_types"
                                    ? building_ids
                                    : [...filterObjects[paramKeys]]
                                : paramKeys === "start_year"
                                ? { start: filterObjects[paramKeys] }
                                : paramKeys === "end_year"
                                ? { end: filterObjects[paramKeys] }
                                : filterObjects[paramKeys],
                        entity: entity,
                        filterKey: paramKeys === "filters" ? "filters" : paramKeys === "top_n" ? "top_n" : filterDetail?.key,
                        dropDownData: dashboardMasterFilterList?.[filterDetail?.key] || [],
                        filterLabel:
                            paramKeys === "start_year"
                                ? "Start Year"
                                : paramKeys === "end_year"
                                ? "End Year"
                                : paramKeys === "update_fci"
                                ? "update_fci"
                                : paramKeys === "filters"
                                ? "filters"
                                : paramKeys === "top_n"
                                ? "top_n"
                                : filterDetail?.label,
                        isYearFilter: paramKeys === "start_year" || paramKeys === "end_year" || false,
                        paramKey: paramKeys === "start_year" || paramKeys === "end_year" ? paramKeys : ""
                    });
                }
            });
        } else {
            setSmartChartData("mfilter", {
                bandName: currentBand,
                entity: entity,
                isFilterValue: true
            });
        }
    };
    return (
        <>
            <div class="drag-main mt-2">
                <div class="row m-0">
                    {filteredData[entity]?.map((row, rowIndex) => (
                        <MasterFilter
                            getSmartChartMasterFilterDropDown={getSmartChartMasterFilterDropDown}
                            masterFilterList={masterFilterList[entity]}
                            setSmartChartData={setSmartChartData}
                            currentBand={currentBand}
                            selectedFiltersList={chartSectionData?.mfilter}
                            selectedClient={selectedClient}
                            entity={entity}
                            rowIndex={rowIndex}
                            requiredFields={requiredFields}
                        />
                    ))}
                </div>
                <div class="row m-0">
                    <div class="col-md-12 p-0">
                        {rowConstructArray.map((dropRow, index) => (
                            <Droppable droppableId={`CHARTITEMS-${entity}-${index}`} direction="horizontal">
                                {(provided, snapshot) => (
                                    <div
                                        class="dragble-area"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps} //new prop
                                        // isDraggingOver={snapshot.isDraggingOver}
                                    >
                                        {chartData
                                            // .filter(chartItem => chartItem.isVisible)
                                            .slice(index * 4, (index + 1) * 4)
                                            .map((item, i) => {
                                                return (
                                                    <Draggable
                                                        key={`draggable-${entity}-${i}${index}`}
                                                        draggableId={`draggable-${entity}-${i}${index}`}
                                                        index={index * 4 + i}
                                                        // index={i}
                                                    >
                                                        {provided => {
                                                            return (
                                                                <ChartItem
                                                                    index={i}
                                                                    provided={provided}
                                                                    item={item}
                                                                    setSmartChartData={setSmartChartData}
                                                                    currentBand={currentBand}
                                                                    openChartItemConfigModal={openChartItemConfigModal}
                                                                    droppableIndex={index}
                                                                    draggableIndex={i}
                                                                    entity={entity}
                                                                    toggleImageListForBandModal={toggleImageListForBandModal}
                                                                    toggleImageBandDeleteConfirmationModal={toggleImageBandDeleteConfirmationModal}
                                                                    editImageBand={editImageBand}
                                                                    isEdit={isEdit}
                                                                    updatePageBreakConfig={updatePageBreakConfig}
                                                                    isExcelExport={isExcelExport}
                                                                    isSkeletonView={isSkeletonView}
                                                                    buildingMfilter={chartSectionData?.mfilter?.building_ids}
                                                                    siteMfilter={chartSectionData?.mfilter?.site_ids}
                                                                />
                                                            );
                                                        }}
                                                    </Draggable>
                                                );
                                            })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        ))}
                    </div>
                </div>
                {/* </DragDropContext> */}
            </div>
            {filterOpen ? (
                <Portal
                    body={
                        <MyFilters
                            dashboardFilterParams={smartChartSectionData}
                            onCancel={() => setFilterOpen(false)}
                            submitMasterFilter={filterObjects => {
                                setMasterFilterSubmit(filterObjects);
                            }}
                            yearValues={year}
                            isSmartChartFilter
                            filterName={smartChartFilterName}
                            filterCount={false}
                        />
                    }
                    onCancel={() => setFilterOpen(false)}
                />
            ) : null}
        </>
    );
};
export default ChartSection;
