export const siteTableData = {
    keys: [
        "code",
        "name",
        "region",
        "consultancy",
        "client",
        "client_site_id",
        "status",
        "sf",
        "city",
        "county",
        "country",
        "state",
        "street",
        "zip_code",
        "comments",
        "color_code",
        "sort_order",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Site Code",
            class: "reg-code",
            searchKey: "sites.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Site Name",
            class: "reg-name",
            searchKey: "sites.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "name",
            hasLineEdit: true
        },
        color_code: {
            isVisible: true,
            label: "Color",
            class: "width-220px",
            searchKey: "",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            hasLineEdit: true
        },
        status: {
            isVisible: true,
            label: "Status",
            class: "reg-name",
            searchKey: "sites.status",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "status"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "width-220px",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-220px",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        client_site_id: {
            isVisible: true,
            label: "Client Site ID",
            class: "width-220px",
            searchKey: "sites.client_site_id",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "client_site_id",
            commonSearchKey: "sites",
            commonSearchObjectKey: "client_site_id"
        },
        sf: {
            isVisible: true,
            label: "SF",
            class: "width-140px",
            searchKey: "sites.total_area",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "total_area"
        },
        region: {
            isVisible: true,
            label: "Region",
            class: "width-220px",
            searchKey: "regions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "region",
            commonSearchKey: "regions",
            commonSearchObjectKey: "name"
        },
        street: {
            isVisible: true,
            label: "Street",
            class: "width-180px",
            searchKey: "sites.street",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "street"
        },
        city: {
            isVisible: true,
            label: "City",
            class: "width-180px",
            searchKey: "sites.city",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "city"
        },
        county: {
            isVisible: true,
            label: "County",
            class: "width-180px",
            searchKey: "sites.county",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "county"
        },
        state: {
            isVisible: true,
            label: "State",
            class: "width-180px",
            searchKey: "sites.state",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "state"
        },
        zip_code: {
            isVisible: true,
            label: "ZipCode",
            class: "width-180px",
            searchKey: "sites.zip_code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "zip_code"
        },
        country: {
            isVisible: true,
            label: "Country",
            class: "width-180px",
            searchKey: "sites.country",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "country"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "sites.comments",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "comments",
            hasLineEdit: true
        },
        sort_order: {
            isVisible: true,
            label: "Export Sort Order",
            class: "width-180px",
            searchKey: "sites.sort_order",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "sort_order",
            hasLineEdit: true
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "sites.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "sites.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};
export const documentTableData = {
    keys: [
        "code",
        "file_name",
        "description",
        "file_type",
        "document_type",
        "identifier",
        "consultancy",
        "client",
        "project",
        "reportRegion",
        "reportSite",
        "reportBuilding",
        "recommendation",
        "initiative",
        "floor",
        "notes",
        "status",
        "version_no",
        "user",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Document Code",
            class: "width-140px",
            searchKey: "documents.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "documents",
            commonSearchKey: "documents",
            commonSearchObjectKey: "code"
        },
        file_name: {
            isVisible: true,
            label: "File Name",
            class: "name",
            searchKey: "documents.file_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "documents",
            commonSearchKey: "documents",
            commonSearchObjectKey: "file_name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "documents.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "documents",
            commonSearchKey: "documents",
            commonSearchObjectKey: "description"
        },
        file_type: {
            isVisible: true,
            label: "File Type",
            class: "width-140px",
            searchKey: "documents.file_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "documents",
            commonSearchKey: "documents",
            commonSearchObjectKey: "file_type"
        },
        document_type: {
            isVisible: true,
            label: "Document Type",
            class: "width-180px",
            searchKey: "documents.document_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "documents",
            commonSearchKey: "documents",
            commonSearchObjectKey: "document_type"
        },

        project: {
            isVisible: true,
            label: "Project",
            class: "",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        reportBuilding: {
            isVisible: true,
            label: "Building",
            class: "",
            searchKey: "buildings.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "building",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        reportRegion: {
            isVisible: true,
            label: "Region",
            class: "",
            searchKey: "regions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "region",
            commonSearchKey: "regions",
            commonSearchObjectKey: "name"
        },
        reportSite: {
            isVisible: true,
            label: "Site",
            class: "",
            searchKey: "sites.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "site",
            commonSearchKey: "sites",
            commonSearchObjectKey: "name"
        },
        recommendation: {
            isVisible: true,
            label: "Recommendation",
            class: "",
            searchKey: "recommendations.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "recommendation",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "description"
        },
        initiative: {
            isVisible: true,
            label: "Initiative",
            class: "",
            searchKey: "initiatives.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "initiative",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "name"
        },
        floor: {
            isVisible: true,
            label: "Floor",
            class: "",
            searchKey: "floors.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "floor",
            commonSearchKey: "floors",
            commonSearchObjectKey: "name"
        },

        notes: {
            isVisible: true,
            label: "Notes",
            class: "",
            searchKey: "documents.notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "documents",
            commonSearchKey: "documents",
            commonSearchObjectKey: "notes"
        },
        status: {
            isVisible: true,
            label: "Status",
            class: "",
            searchKey: "documents.status",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "documents",
            commonSearchKey: "documents",
            commonSearchObjectKey: "status"
        },
        version_no: {
            isVisible: true,
            label: "Version",
            class: "",
            searchKey: "documents.version_no",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "documents",
            commonSearchKey: "documents",
            commonSearchObjectKey: "version_no"
        },
        identifier: {
            isVisible: false,
            label: "Identifier",
            class: "width-140px",
            searchKey: "documents.identifier",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "documents",
            commonSearchKey: "documents",
            commonSearchObjectKey: "identifier"
        },
        user: {
            isVisible: true,
            label: "Uploaded By",
            searchKey: "users.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "users.name",
            commonSearchKey: "documents",
            commonSearchObjectKey: "user"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "documents.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "documents",
            commonSearchKey: "documents",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "documents.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "documents",
            commonSearchKey: "documents",
            commonSearchObjectKey: "updated_at"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        }
    },
    data: []
};

export const regionTableData = {
    keys: ["code", "name", "consultancy", "client", "users", "client_users", "comments", "color_code", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Region Code",
            class: "reg-code",
            searchKey: "regions.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "regions",
            commonSearchKey: "regions",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Region Name",
            class: "reg-name",
            searchKey: "regions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "regions",
            commonSearchKey: "regions",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "width-220px",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-220px",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        users: {
            isVisible: false,
            label: "Consultancy Users",
            class: "cons-user",
            searchKey: "users.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "assigned_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        client_users: {
            isVisible: false,
            label: "Client Users",
            class: "",
            searchKey: "client_users.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "client_users",
            commonSearchKey: "client_users",
            commonSearchObjectKey: "name"
        },
        color_code: {
            isVisible: true,
            label: "Color",
            class: "",
            searchKey: "",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "regions.comments",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "regions",
            commonSearchKey: "regions",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "regions.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "regions",
            commonSearchKey: "regions",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "regions.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "regions",
            commonSearchKey: "regions",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const projectTableData = {
    keys: ["code", "name", "consultancy", "client", "users", "client_users", "comments", "color_code", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Project Code",
            class: "reg-code",
            searchKey: "projects.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Project Name",
            class: "reg-name",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "width-220px",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-220px",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        users: {
            isVisible: false,
            label: "Consultancy Users",
            class: "cons-user",
            searchKey: "users.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "assigned_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        client_users: {
            isVisible: false,
            label: "Client Users",
            class: "",
            searchKey: "client_users.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "client_users",
            commonSearchKey: "client_users",
            commonSearchObjectKey: "name"
        },
        color_code: {
            isVisible: true,
            label: "Color",
            class: "",
            searchKey: "",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: ""
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "projects.comments",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "projects.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "projects.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const buildingTypeTableData = {
    keys: ["name", "consultancy", "client", "median_eui", "description", "display_in_stats", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            class: "reg-name",
            searchKey: "main_building_types.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "main_building_types",
            commonSearchKey: "main_building_types",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        median_eui: {
            isVisible: true,
            label: "Property Type Median EUIs (US)",
            class: "",
            searchKey: "median_eui.property_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "median_eui",
            commonSearchKey: "median_eui",
            commonSearchObjectKey: "property_type"
        },
        display_in_stats: {
            isVisible: true,
            label: "Display in Stats",
            class: "",
            searchKey: "main_building_types.display_in_stats",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "main_building_types",
            commonSearchKey: "main_building_types",
            commonSearchObjectKey: "display_in_stats"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "main_building_types.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "main_building_types",
            commonSearchKey: "main_building_types",
            commonSearchObjectKey: "description"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "main_building_types.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "main_building_types",
            commonSearchKey: "main_building_types",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "main_building_types.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "main_building_types",
            commonSearchKey: "main_building_types",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const projectSettingsBuildingTypeTableData = {
    keys: [
        "name",
        "site_fc_dm_efci",
        "listed_in_fc_site",
        "listed_in_dm_site",
        "give_in_fc_site",
        "receive_in_fc_site",
        "give_in_dm_site",
        "receive_in_dm_site",
        "listed_in_site_efci",
        "has_own_efci"
    ],
    config: {
        name: { isVisible: true, label: "Building Type", class: "build-add" },
        site_fc_dm_efci: {
            isVisible: true,
            label: "Is Part of Site FC/DM/EFCI",
            class: "fy-dtl"
        },
        listed_in_fc_site: { isVisible: true, label: "Listed in FC Site Table", class: "fy-dtl" },
        listed_in_dm_site: { isVisible: true, label: "Listed in DM Site Table", class: "fy-dtl" },
        give_in_fc_site: {
            isVisible: true,
            label: "Give to other Buildings in FC Site Table",
            class: "fy-dtl"
        },
        receive_in_fc_site: {
            isVisible: true,
            label: "Receive from other Buildings in FC Site Table",
            class: "fy-dtl"
        },
        give_in_dm_site: {
            isVisible: true,
            label: "Give to other Buildings in DM Site Table",
            class: "fy-dtl"
        },
        receive_in_dm_site: {
            isVisible: true,
            label: "Receive from other Buildings in DM Site Table",
            class: "fy-dtl"
        },
        has_own_efci: { isVisible: true, label: "Has own EFCI View", class: "fy-dtl" },
        listed_in_site_efci: {
            isVisible: true,
            label: "Listed/Rolled-Up to Site EFCI View",
            class: "fy-dtl"
        }
    },
    data: []
};

export const recommendationsTableData = {
    keys: [
        "code",
        "description",
        "project_total",
        "budget_priority",
        "building",
        "buildingDescription",
        "addition",
        "department",
        "capital_type_display_name",
        "category",
        "trade",
        "system",
        "sub_system",
        "buildingType",
        "recommendation_type",
        "recommendatonProject",
        "region",
        "site",
        "criticality",
        "priority",
        "initiative",
        "hospital_name_recomentation",
        "client",
        "consultancy",
        "ministry",
        "floor",
        "room",
        "funding_source",
        "responsibility",
        "asset_name",
        "condition",
        "installed_year",
        "service_life",
        "usefull_life_remaining",
        "crv",
        "essential",
        "surveyor",
        "inspection_date",
        "source",
        "status",
        "status_notes",
        "notes",
        "fmp",
        "fmp_id",
        "fmp_track",
        "fmp_project",
        "infrastructure_request",
        "red_line",
        "birth_year",
        "created_at",
        "updated_at"
    ],
    config: {
        id: {
            isVisible: true,
            label: "Id",
            class: "",
            searchKey: null
        },
        code: {
            isVisible: true,
            label: "Code",
            class: "reg-code",
            searchKey: "recommendations.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "code"
        },
        budget_priority: {
            isVisible: true,
            label: "Budget Priority",
            class: "width-200px",
            searchKey: "budget_priority",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.budget_priority"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "budget_priority",
            smartChartCommonSearchKey: "budget_priority"
        },
        infrastructure_request: {
            isVisible: true,
            label: "Capital Request",
            class: "width-220px",
            searchKey: "infrastructure_request",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.infrastructure_request"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "infrastructure_request",
            smartChartCommonSearchKey: "infrastructure_request"
        },
        red_line: {
            isVisible: true,
            label: "Redlining",
            class: "width-220px",
            searchKey: "red_line",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.red_line"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "red_line",
            smartChartCommonSearchKey: "red_line"
        },
        description: {
            isVisible: true,
            label: "Recommendation",
            class: "recom-dash-main",
            searchKey: "recommendations.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "description"
        },
        trade: {
            isVisible: true,
            label: "Trade",
            class: "width-160px",
            searchKey: "recommendations.trade_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["trades.id", "trades.name"],
            commonSearchKey: "trades",
            commonSearchObjectKey: "name",
            filterIdKey: "trade_id",
            dynamicField: true,
            smartChartCommonSearchKey: "trade_ids"
        },
        recommendatonProject: {
            isVisible: true,
            label: "Project Name",
            class: "",
            searchKey: "recommendations.project_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["projects.id", "projects.name"],
            commonSearchKey: "projects",
            commonSearchObjectKey: "name",
            filterIdKey: "project_id",
            dynamicField: true,
            dynamicFieldKey: "project",
            smartChartWildCardFilterKey: "project",
            smartChartCommonSearchKey: ""
        },
        system: {
            isVisible: true,
            label: "System",
            class: "width-220px",
            searchKey: "recommendations.system_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["systems.id", "systems.name"],
            commonSearchKey: "systems",
            commonSearchObjectKey: "name",
            filterIdKey: "system_id",
            dynamicField: true,
            smartChartCommonSearchKey: "system_ids"
        },
        sub_system: {
            isVisible: true,
            label: "Sub System",
            class: "width-250px",
            searchKey: "recommendations.sub_system_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["sub_systems.id", "sub_systems.name"],
            commonSearchKey: "sub_systems",
            commonSearchObjectKey: "name",
            filterIdKey: "sub_system_id",
            dynamicField: true,
            smartChartCommonSearchKey: "sub_system_ids"
        },
        source: {
            isVisible: true,
            label: "Source",
            class: "width-160px",
            searchKey: "source",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.source"],
            commonSearchKey: "source",
            commonSearchObjectKey: "source",
            smartChartCommonSearchKey: "source"
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "width-170px",
            searchKey: "buildings.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["buildings.id", "buildings.name"],
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name",
            filterIdKey: "building_id",
            dynamicField: true,
            smartChartCommonSearchKey: "building_ids"
        },
        buildingDescription: {
            isVisible: true,
            label: "Building Description",
            class: "width-240px",
            searchKey: "buildings.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["buildings.id", "buildings.description"],
            commonSearchKey: "buildings",
            commonSearchObjectKey: "description",
            filterIdKey: "buildings_descriptions",
            dynamicField: true,
            dynamicFieldKey: "building",
            smartChartWildCardFilterKey: "building_description",
            smartChartCommonSearchKey: "buildings_descriptions"
        },
        buildingType: {
            isVisible: true,
            label: "Building Type",
            class: "width-220px",
            searchKey: "main_building_types.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["main_building_types.id", "main_building_types.name"],
            commonSearchKey: "main_building_types",
            commonSearchObjectKey: "name",
            filterIdKey: "building_type_id",
            dynamicField: true,
            dynamicFieldKey: "main_building_type",
            smartChartWildCardFilterKey: "building_type",
            smartChartCommonSearchKey: "building_types"
        },
        recommendation_type: {
            isVisible: true,
            label: "Recommendation Type",
            class: "",
            searchKey: "recommendation_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.recommendation_type"],
            commonSearchKey: "recommendation_types",
            commonSearchObjectKey: "name",
            smartChartCommonSearchKey: "recommendation_type"
        },
        client: {
            isVisible: false,
            label: "Client",
            class: "width-160px",
            searchKey: "recommendations.client_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["clients.id", "clients.name"],
            commonSearchKey: "clients",
            commonSearchObjectKey: "name",
            filterIdKey: "client_id",
            dynamicField: true,
            smartChartCommonSearchKey: ""
        },
        consultancy: {
            isVisible: false,
            label: "Consultancy",
            class: "width-200px",
            searchKey: "recommendations.consultancy_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["consultancies.id", "consultancies.name"],
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name",
            filterIdKey: "consultancy_id",
            dynamicField: true,
            smartChartCommonSearchKey: ""
        },
        ministry: {
            isVisible: false,
            label: "Ministry",
            class: "",
            searchKey: "recommendations.ministry",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "ministry"
        },
        site: {
            isVisible: true,
            label: "Site",
            class: "width-160px",
            searchKey: "recommendations.site_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["sites.id", "sites.name"],
            commonSearchKey: "sites",
            commonSearchObjectKey: "name",
            filterIdKey: "site_id",
            dynamicField: true,
            smartChartCommonSearchKey: "site_ids"
        },
        region: {
            isVisible: true,
            label: "Region",
            class: "width-160px",
            searchKey: "recommendations.region_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["regions.id", "regions.name"],
            commonSearchKey: "regions",
            commonSearchObjectKey: "name",
            filterIdKey: "region_id",
            dynamicField: true,
            smartChartCommonSearchKey: "region_ids"
        },
        addition: {
            isVisible: true,
            label: "Addition",
            class: "width-160px ",
            searchKey: "recommendations.addition_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["additions.id", "additions.name"],
            commonSearchKey: "additions",
            commonSearchObjectKey: "name",
            filterIdKey: "addition_id",
            dynamicField: true,
            smartChartWildCardFilterKey: "addition_name",
            smartChartCommonSearchKey: "addition_ids",
            hasLineEdit: true,
            isDropdownLineEdit: true
        },
        floor: {
            isVisible: false,
            label: "Floor",
            class: " ",
            searchKey: "recommendations.floor_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["floors.id", "floors.name"],
            commonSearchKey: "floors",
            commonSearchObjectKey: "name",
            filterIdKey: "floor_id",
            dynamicField: true,
            smartChartCommonSearchKey: "floor_ids",
            hasLineEdit: true,
            isDropdownLineEdit: true
        },
        initiative: {
            isVisible: true,
            label: "Initiative",
            class: "width-160px ",
            searchKey: "recommendations.initiative_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["initiatives.id", "initiatives.name"],
            commonSearchKey: "initiative",
            commonSearchObjectKey: "initiative",
            dynamicField: true,
            smartChartWildCardFilterKey: "initiative_name",
            hasLineEdit: true,
            isDropdownLineEdit: true
        },
        hospital_name_recomentation: {
            isVisible: false,
            label: "Parent Building",
            class: "",
            searchKey: "hospital_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.hospital_name"],
            commonSearchKey: "hospital_name",
            commonSearchObjectKey: "hospital_name",
            smartChartWildCardFilterKey: "hospital_name",
            smartChartCommonSearchKey: ""
        },
        department: {
            isVisible: false,
            label: "Department",
            class: "width-140px ",
            searchKey: "recommendations.department_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["departments.id", "departments.name"],
            commonSearchKey: "departments",
            commonSearchObjectKey: "name",
            filterIdKey: "department_id",
            dynamicField: true,
            smartChartCommonSearchKey: "department_ids",
            hasLineEdit: true,
            isDropdownLineEdit: true
        },
        category: {
            isVisible: true,
            label: "Category",
            class: "width-170px ",
            searchKey: "recommendations.category_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["categories.id", "categories.name"],
            commonSearchKey: "categories",
            commonSearchObjectKey: "name",
            filterIdKey: "category_id",
            dynamicField: true,
            smartChartCommonSearchKey: "category_ids",
            hasLineEdit: true,
            isDropdownLineEdit: true
        },
        room: {
            isVisible: true,
            label: "Room",
            class: "width-160px",
            searchKey: "recommendations.room",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "room"
        },
        asset_name: {
            isVisible: true,
            label: "Asset Name",
            class: "width-220px",
            searchKey: "assets.asset_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "asset_name",
            dynamicField: true,
            dynamicFieldKey: "asset"
        },
        installed_year: {
            isVisible: true,
            label: "Installed Year",
            class: "width-180px",
            searchKey: "recommendations.installed_year",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "installed_year"
        },
        notes: {
            isVisible: true,
            label: "Recommendation Narrative",
            class: "",
            searchKey: "recommendations.notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "notes",
            smartChartWildCardFilterKey: "notes"
        },
        condition: {
            isVisible: true,
            label: "Condition",
            class: "",
            searchKey: "condition",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.condition"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "condition",
            smartChartCommonSearchKey: "condition"
            // hasLineEdit: true,
            // isDropdownLineEdit: true
        },
        priority: {
            isVisible: true,
            label: "Priority",
            class: "width-130px",
            searchKey: "recommendations.priority",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["recommendations.priority"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "priority"
        },
        criticality: {
            isVisible: true,
            label: "Criticality",
            class: "width-180px ",
            searchKey: "recommendations.criticality_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["criticalities.id", "criticalities.name"],
            commonSearchKey: "criticalities",
            commonSearchObjectKey: "name",
            filterIdKey: "criticality_id",
            dynamicField: true,
            smartChartCommonSearchKey: "criticality_ids",
            hasLineEdit: true,
            isDropdownLineEdit: true
        },
        funding_source: {
            isVisible: true,
            label: "Funding Source",
            class: "width-220px ",
            searchKey: "recommendations.funding_source_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["funding_sources.id", "funding_sources.name"],
            commonSearchKey: "funding_sources",
            commonSearchObjectKey: "name",
            filterIdKey: "funding_source_id",
            dynamicField: true,
            smartChartCommonSearchKey: "funding_source_ids",
            hasLineEdit: true,
            isDropdownLineEdit: true
        },
        responsibility: {
            isVisible: true,
            label: "Responsibility",
            class: "width-220px ",
            searchKey: "recommendations.responsibility_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.responsibility_id", "recommendations.responsibility_name"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "responsibility_name",
            filterIdKey: "responsibility_id",
            dynamicField: true,
            smartChartWildCardFilterKey: "responsibility_name",
            smartChartCommonSearchKey: "responsibility_ids",
            hasLineEdit: true,
            isDropdownLineEdit: true
        },
        deffered_maintenance: {
            isVisible: true,
            label: "Deffered Maintenance",
            class: "",
            searchKey: "recommendations.deffered_maintenance",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["recommendations.deffered_maintenance"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "deffered_maintenance"
        },
        future_capital: {
            isVisible: true,
            label: "Future Capital",
            class: "",
            searchKey: "recommendations.future_capital",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["recommendations.future_capital"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "future_capital"
        },
        inspection_date: {
            isVisible: true,
            label: "Inspection Date",
            class: "width-180px",
            searchKey: "recommendations.inspection_date",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "inspection_date"
        },
        requested_date: {
            isVisible: true,
            label: "Requested Date",
            class: "width-180px",
            searchKey: "recommendations.requested_date",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "requested_date"
        },
        project_total: {
            isVisible: true,
            label: "Project Total",
            class: "width-180px",
            searchKey: "recommendations.project_total",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "project_total",
            hasTopNFilter: true
        },
        surveyor: {
            isVisible: true,
            label: "Surveyor",
            class: "width-160px",
            searchKey: "recommendations.surveyor",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.surveyor"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "surveyor"
        },
        requestor: {
            isVisible: true,
            label: "Requester",
            class: "width-160px",
            searchKey: "recommendations.requestor_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.requestor_id", "recommendations.requestor_name"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "requestor_name"
        },
        status: {
            isVisible: true,
            label: "Status",
            class: "width-140px ",
            searchKey: "status",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.status"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "status",
            smartChartCommonSearchKey: "status",
            hasLineEdit: true,
            isDropdownLineEdit: true
        },
        status_notes: {
            isVisible: true,
            label: "Status Notes",
            class: "width-140px",
            searchKey: "status_notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["recommendations.status_notes"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "status_notes",
            smartChartCommonSearchKey: "status_notes"
        },
        cr_status: {
            isVisible: true,
            label: "CR Status",
            class: "width-140px",
            searchKey: "cr_status",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.cr_status"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "cr_status",
            smartChartCommonSearchKey: "cr_status"
        },
        cr_status_notes: {
            isVisible: true,
            label: "CR Status Notes",
            class: "width-140px",
            searchKey: "cr_status_notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["recommendations.cr_status_notes"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "cr_status_notes",
            smartChartCommonSearchKey: "cr_status_notes"
        },
        capital_type_display_name: {
            isVisible: true,
            label: "Capital Type",
            class: "width-200px ",
            searchKey: "recommendations.capital_type_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendation_capital_types.id", "recommendation_capital_types.display_name"],
            commonSearchKey: "recommendation_capital_types",
            commonSearchObjectKey: "display_name",
            filterIdKey: "recommendation_capital_type_id",
            dynamicField: true,
            dynamicFieldKey: "recommendation_capital_type",
            smartChartWildCardFilterKey: "recommendation_capital_type",
            smartChartCommonSearchKey: "recommendation_capital_type_ids",
            hasLineEdit: true,
            isDropdownLineEdit: true
        },
        usefull_life_remaining: {
            isVisible: true,
            label: "Useful Life Remaining",
            class: "width-200px",
            searchKey: "recommendations.usefull_life_remaining",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "usefull_life_remaining"
        },
        service_life: {
            isVisible: true,
            label: "Service Life",
            class: "width-140px",
            searchKey: "recommendations.service_life",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "service_life"
        },
        crv: {
            isVisible: false,
            label: "CRV",
            class: "width-160px",
            searchKey: "recommendations.crv",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "crv"
        },
        essential: {
            isVisible: false,
            label: "Essential",
            class: "width-160px",
            searchKey: "recommendations.essential",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "essential"
        },
        fmp: {
            isVisible: true,
            label: "Facility Master Plan",
            class: "width-220px",
            searchKey: "fmp",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.fmp"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "fmp",
            smartChartCommonSearchKey: "fmp"
        },
        fmp_id: {
            isVisible: false,
            label: "FMP ID",
            class: "width-220px",
            searchKey: "recommendations.fmp_id",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "fmp_id"
        },
        energy_band_show: {
            isVisible: true,
            label: "Energy",
            class: "width-220px",
            searchKey: "energy_band_show",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.energy_band_show"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "energy_band_show",
            smartChartCommonSearchKey: "energy_band_show"
        },
        water_band_show: {
            isVisible: true,
            label: "Water",
            class: "width-220px",
            searchKey: "water_band_show",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["recommendations.water_band_show"],
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "water_band_show",
            smartChartCommonSearchKey: "water_band_show"
        },
        fmp_project: {
            isVisible: false,
            label: "FMP Project",
            class: "width-220px",
            searchKey: "recommendations.fmp_project",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "fmp_project"
        },
        fmp_track: {
            isVisible: false,
            label: "FMP Track",
            class: "width-220px",
            searchKey: "recommendations.fmp_track",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "fmp_track"
        },
        unit: {
            isVisible: undefined,
            label: "Unit",
            class: "width-180px",
            searchKey: "unit",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "unit",
            hasLineEdit: true
        },
        cost_per_unit: {
            isVisible: undefined,
            label: "Cost Per Unit",
            class: "width-180px",
            searchKey: "cost_per_unit",
            type: "number",
            decimalScale: 4,
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "cost_per_unit",
            hasLineEdit: true
        },
        quantity: {
            isVisible: undefined,
            label: "Quantity",
            class: "width-180px",
            searchKey: "quantity",
            type: "number",
            decimalScale: 4,
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "quantity",
            hasLineEdit: true
        },
        options_cost: {
            isVisible: undefined,
            label: "Opinions of Cost",
            class: "width-200px",
            searchKey: "options_cost",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "options_cost"
        },
        birth_year: {
            isVisible: true,
            label: "Birth Year",
            class: "width-180px",
            searchKey: "recommendations.birth_year",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "birth_year"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "width-200px",
            searchKey: "recommendations.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "width-200px",
            searchKey: "recommendations.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const buildingTableData = {
    keys: [
        "code",
        "name",
        "description",
        "hospital_name",
        "building_type",
        "status",
        "criticality",
        "site",
        "region",
        "consultancy",
        "client",
        "number",
        "area",
        "cost",
        "crv",
        "year",
        "original_age",
        "major_renovation_year",
        "major_renovation_age",
        "color_code",
        "sort_order",
        "enterprise_index",
        "ownership",
        "ownership_type",
        "lease_end_date",
        "manager",
        "senior_property_manager",
        "ministry",
        "use",
        "secondary_use",
        "sector",
        "internal_group",
        "division",
        "assessment_date",
        "redline_completed",
        "redline_completed_date",
        "window_sf",
        "climate_zone",
        "sf_by_ton_standard",
        "sf_by_ton_actual",
        "street",
        "street_address_2",
        "city",
        "state",
        "zip_code",
        "country",
        "comments",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Building Code",
            class: "width-190px",
            searchKey: "buildings.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Building Name",
            class: "width-200px",
            searchKey: "buildings.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name",
            hasLineEdit: true
        },
        hospital_name: {
            isVisible: false,
            label: "Parent Building",
            class: "width-220px",
            searchKey: "buildings.hospital_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "hospital_name",
            hasLineEdit: true
        },
        building_type: {
            isVisible: true,
            label: "Type",
            class: "width-160px",
            searchKey: "buildings.main_building_type_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "main_building_type",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "main_building_type_name"
        },
        status: {
            isVisible: true,
            label: "Status",
            class: "width-130px",
            searchKey: "buildings.status",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "status"
        },
        criticality: {
            isVisible: true,
            label: "Criticality",
            class: "width-160px",
            searchKey: "buildings.criticality",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "criticality"
        },
        color_code: {
            isVisible: true,
            label: "Color",
            class: "width-220px",
            searchKey: "",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "",
            hasLineEdit: true
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "width-220px",
            searchKey: "consultancy_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "consultancy_name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-220px",
            searchKey: "client_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "client_name"
        },
        region: {
            isVisible: true,
            label: "Region",
            class: "width-220px",
            searchKey: "region_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "regions",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "region_name"
        },
        site: {
            isVisible: true,
            label: "Site",
            class: "width-220px",
            searchKey: "site_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "sites",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "site_name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "buildings.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "description",
            hasLineEdit: true
        },
        number: {
            isVisible: true,
            label: "Building Number",
            class: "width-220px",
            searchKey: "buildings.number",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "number",
            hasLineEdit: true
        },
        area: {
            isVisible: true,
            label: "Area (Sq Ft)",
            class: "width-150px",
            searchKey: "buildings.area",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "area",
            hasLineEdit: true
        },
        cost: {
            isVisible: true,
            label: "Cost",
            class: "width-130px",
            searchKey: "buildings.cost",
            type: "number",
            hasWildCardSearch: true,
            decimalScale: 2,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "cost",
            hasLineEdit: true,
            decimalScale: 4
        },
        crv: {
            isVisible: true,
            label: "CRV",
            class: "width-160px",
            searchKey: "buildings.crv",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "crv"
        },
        enterprise_index: {
            isVisible: true,
            label: "Enterprise Index",
            class: "width-180px",
            searchKey: "buildings.enterprise_index",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "enterprise_index",
            hasLineEdit: true
        },
        ownership: {
            isVisible: true,
            label: "Ownership",
            class: "clent",
            searchKey: "buildings.ownership",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "ownership",
            hasLineEdit: true
        },
        ownership_type: {
            isVisible: true,
            label: "Ownership Type",
            class: "width-180px",
            searchKey: "buildings.ownership_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "ownership_type"
        },
        lease_end_date: {
            isVisible: true,
            label: "Lease End Date",
            class: "width-200px",
            searchKey: "buildings.lease_end_date",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "lease_end_date"
        },
        use: {
            isVisible: true,
            label: "Primary Use",
            class: "width-180px",
            searchKey: "buildings.use",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "use"
        },
        secondary_use: {
            isVisible: true,
            label: "Secondary Use",
            class: "width-180px",
            searchKey: "buildings.secondary_use",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "secondary_use"
        },
        sector: {
            isVisible: true,
            label: "Sector",
            class: "width-180px",
            searchKey: "buildings.sector",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "sector"
        },
        internal_group: {
            isVisible: true,
            label: "Internal Group",
            class: "width-180px",
            searchKey: "buildings.internal_group",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "internal_group"
        },
        division: {
            isVisible: true,
            label: "Division",
            class: "width-180px",
            searchKey: "buildings.division",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "division"
        },
        assessment_date: {
            isVisible: true,
            label: "Assessment Date",
            class: "width-200px",
            searchKey: "buildings.assessment_date",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "assessment_date"
        },
        redline_completed: {
            isVisible: true,
            label: "Redline Completed",
            class: "width-180px",
            type: "boolean",
            searchKey: "buildings.redline_completed",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "redline_completed"
        },
        redline_completed_date: {
            isVisible: true,
            label: "Redline Completed Date",
            class: "width-200px",
            searchKey: "buildings.redline_completed_date",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "redline_completed_date"
        },
        window_sf: {
            isVisible: true,
            label: "Window SF",
            class: "width-160px",
            searchKey: "buildings.window_sf",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "window_sf",
            hasLineEdit: true,
            decimalScale: 6
        },
        climate_zone: {
            isVisible: true,
            label: "Climate Zone",
            class: "width-160px",
            searchKey: "buildings.climate_zone",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "climate_zone",
            hasLineEdit: true,
            decimalScale: 6
        },
        sf_by_ton_standard: {
            isVisible: true,
            label: "Ton Standard/SF",
            class: "width-160px",
            searchKey: "buildings.sf_by_ton_standard",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "sf_by_ton_standard",
            hasLineEdit: true,
            decimalScale: 6
        },
        sf_by_ton_actual: {
            isVisible: true,
            label: "Ton Actual/SF",
            class: "width-160px",
            searchKey: "buildings.sf_by_ton_actual",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "sf_by_ton_actual",
            hasLineEdit: true,
            decimalScale: 6
        },
        manager: {
            isVisible: true,
            label: "Manager",
            class: "width-180px",
            searchKey: "buildings.manager",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "manager",
            hasLineEdit: true
        },
        senior_property_manager: {
            isVisible: true,
            label: "Senior Property Manager",
            class: "width-180px",
            searchKey: "buildings.senior_property_manager",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "senior_property_manager"
        },
        year: {
            isVisible: true,
            label: "Year Built",
            class: "width-130px",
            searchKey: "buildings.year",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "year",
            hasLineEdit: true
        },
        original_age: {
            isVisible: true,
            label: "Original Age",
            class: "width-130px",
            searchKey: "buildings.original_age",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "original_age",
            hasLineEdit: false
        },
        major_renovation_year: {
            isVisible: true,
            label: "Year of Major Renovation",
            class: "width-150px",
            searchKey: "buildings.major_renovation_year",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "major_renovation_year",
            hasLineEdit: true
        },
        major_renovation_age: {
            isVisible: true,
            label: "Major Renovation Age",
            class: "width-150px",
            searchKey: "buildings.major_renovation_age",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "major_renovation_age",
            hasLineEdit: false
        },
        ministry: {
            isVisible: true,
            label: "Ministry",
            class: "width-180px",
            searchKey: "buildings.ministry",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "ministry",
            hasLineEdit: true
        },
        street: {
            isVisible: true,
            label: "Street",
            class: "width-180px",
            searchKey: "buildings.street",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "street"
        },
        street_address_2: {
            isVisible: true,
            label: "Street Address 2",
            class: "width-180px",
            searchKey: "buildings.street_address_2",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "street_address_2"
        },
        city: {
            isVisible: true,
            label: "City",
            class: "width-180px",
            searchKey: "buildings.city",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "city"
        },
        state: {
            isVisible: true,
            label: "State",
            class: "width-180px",
            searchKey: "buildings.state",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "state"
        },
        zip_code: {
            isVisible: true,
            label: "ZipCode",
            class: "width-180px",
            searchKey: "buildings.zip_code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "zip_code"
        },
        country: {
            isVisible: true,
            label: "Country",
            class: "width-180px",
            searchKey: "buildings.country",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "country"
        },
        sort_order: {
            isVisible: true,
            label: "Export Sort Order",
            class: "width-180px",
            searchKey: "buildings.sort_order",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "sort_order",
            hasLineEdit: true
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "buildings.comments",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "comments",
            hasLineEdit: true
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "width-200px",
            searchKey: "buildings.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "width-200px",
            searchKey: "buildings.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "buildings",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const initiativeTableData = {
    keys: [
        "code",
        "name",
        "client",
        "consultancy",
        "project",
        "identifier",
        "performed_by",
        "initiative_type",
        "recommendations_cost",
        "recommendations_count",
        "actual_cost",
        "funding",
        "total_sf",
        "status",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Initiative Code",
            class: "reg-code",
            searchKey: "initiatives.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Initiative Name",
            class: "",
            searchKey: "initiatives.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "name"
        },
        performed_by: {
            isVisible: true,
            label: "Performed By",
            class: "",
            searchKey: "performed_by",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "performed_by"
        },
        identifier: {
            isVisible: true,
            label: "Identifier Name",
            class: "",
            searchKey: "initiatives.identifier",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "identifier"
        },
        status: {
            isVisible: true,
            label: "Status",
            class: "",
            searchKey: "initiatives.status",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "status"
        },
        project: {
            isVisible: true,
            label: "Project Name",
            class: "",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        actual_cost: {
            isVisible: true,
            label: "Actual Cost",
            class: "",
            searchKey: "initiatives.actual_cost",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "actual_cost"
        },
        recommendations_count: {
            isVisible: true,
            label: "Recommendations Count",
            class: "",
            searchKey: "initiatives.recommendations_count",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "recommendations_count"
        },
        recommendations_cost: {
            isVisible: true,
            label: "Recommendations Cost",
            class: "",
            searchKey: "initiatives.recommendations_cost",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "recommendations_cost"
        },
        funding: {
            isVisible: true,
            label: "Funding",
            class: "",
            searchKey: "initiatives.funding",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "funding"
        },
        total_sf: {
            isVisible: true,
            label: "Total SF",
            class: "",
            searchKey: "initiatives.total_sf",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "total_sf"
        },
        initiative_type: {
            isVisible: true,
            label: "Initiative Type",
            class: "",
            searchKey: "initiative_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "initiative_type"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "initiatives.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "initiatives.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "initiatives",
            commonSearchKey: "initiatives",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const floorTableData = {
    keys: [
        "code",
        "name",
        "description",
        "building",
        "client",
        "consultancy",
        "region",
        "site",
        "comments",
        "sort_order",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Floor Code",
            class: "width-160px",
            searchKey: "floors.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Floor Name",
            class: "width-160px",
            searchKey: "floors.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "name",
            hasLineEdit: true
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "width-160px",
            searchKey: "floors.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "description",
            hasLineEdit: true
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "width-160px",
            searchKey: "buildings.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "building",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },

        client: {
            isVisible: true,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "width-180px",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        region: {
            isVisible: true,
            label: "Region",
            class: "width-220px",
            searchKey: "regions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "region",
            commonSearchKey: "regions",
            commonSearchObjectKey: "name"
        },
        site: {
            isVisible: true,
            label: "Site",
            class: "width-220px",
            searchKey: "sites.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "site",
            commonSearchKey: "sites",
            commonSearchObjectKey: "name"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "width-220px",
            searchKey: "comment",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "comments",
            hasLineEdit: true
        },
        sort_order: {
            isVisible: true,
            label: "Sort Order",
            class: "width-180px",
            searchKey: "floors.sort_order",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "sort_order",
            hasLineEdit: true
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "floor-create",
            searchKey: "floors.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "width-180px",
            searchKey: "floors.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "floors",
            commonSearchKey: "floors",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const buildingAdditionTableData = {
    keys: [
        "code",
        "name",
        "description",
        "building",
        "area",
        "cost",
        "crv",
        "year",
        "construction_age",
        "renovation_year",
        "last_major_construction_age",
        "color_code",
        "sort_order",
        "client",
        "consultancy",
        "region",
        "site",
        "comment",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Addition Code",
            class: "width-160px",
            searchKey: "additions.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Addition Name",
            class: "width-180px",
            searchKey: "additions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "name",
            hasLineEdit: true
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "width-160px",
            searchKey: "buildings.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "building",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "width-160px",
            searchKey: "description.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "description",
            commonSearchKey: "description",
            commonSearchObjectKey: "name",
            hasLineEdit: true
        },
        area: {
            isVisible: true,
            label: "Area (Sq Ft)",
            class: "width-150px",
            searchKey: "additions.area",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "area",
            hasLineEdit: true
        },
        cost: {
            isVisible: true,
            label: "Cost",
            class: "width-140px",
            searchKey: "additions.cost",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "cost",
            hasLineEdit: true
        },
        crv: {
            isVisible: true,
            label: "CRV",
            class: "width-160px",
            searchKey: "additions.crv",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "crv"
        },
        year: {
            isVisible: true,
            label: "Year Built",
            class: "width-140px",
            searchKey: "additions.year",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "year",
            hasLineEdit: true
        },
        construction_age: {
            isVisible: false,
            label: "Original Age",
            class: "width-140px",
            type: "number",
            hasLineEdit: false,
            hasOnlyInfoView: true
        },
        renovation_year: {
            isVisible: true,
            label: "Year Of Major Renovation",
            class: "width-200px",
            searchKey: "additions.renovation_year",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "renovation_year",
            hasLineEdit: true
        },
        last_major_construction_age: {
            isVisible: false,
            label: "Major Renovation Age",
            class: "width-140px",
            type: "number",
            hasLineEdit: false,
            hasOnlyInfoView: true
        },
        color_code: {
            isVisible: true,
            label: "Color",
            class: "width-220px",
            searchKey: "additions.color_code",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "color_code",
            hasLineEdit: true
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-140px",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "width-180px",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        region: {
            isVisible: true,
            label: "Region",
            class: "width-180px",
            searchKey: "regions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "region",
            commonSearchKey: "regions",
            commonSearchObjectKey: "name"
        },
        site: {
            isVisible: true,
            label: "Site",
            class: "width-180px",
            searchKey: "sites.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "site",
            commonSearchKey: "sites",
            commonSearchObjectKey: "name"
        },
        comment: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "additions.comment",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "comment",
            hasLineEdit: true
        },
        sort_order: {
            isVisible: true,
            label: "Export Sort Order",
            class: "width-200px",
            searchKey: "additions.sort_order",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "sort_order",
            hasLineEdit: true
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "additions.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "additions.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const tradesettingsTableData = {
    keys: [
        "name",
        "display_name",
        "table_header",
        "project",
        "client",
        "description",
        "color_code",
        // "narrative_required",
        "created_at",
        "updated_at"
    ],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            searchKey: "trades.name",
            type: "string",
            class: "width-120px",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "trades",
            commonSearchKey: "trades",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "width-180px",
            searchKey: "trades.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "trades",
            commonSearchKey: "trades",
            commonSearchObjectKey: "display_name"
        },
        table_header: {
            isVisible: true,
            label: "BBI Heading",
            class: "width-180px",
            searchKey: "trades.table_header",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "trades",
            commonSearchKey: "trades",
            commonSearchObjectKey: "table_header"
        },
        project: {
            isVisible: true,
            label: "Project",
            class: "width-160px",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-160px",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "trades.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "trades",
            commonSearchKey: "trades",
            commonSearchObjectKey: "description"
        },
        color_code: {
            isVisible: true,
            label: "Color",
            class: "width-140px",
            searchKey: "trades.color_code",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "trades",
            commonSearchKey: "trades",
            commonSearchObjectKey: "color_code"
        },
        // narrative_required: {
        //     isVisible: true,
        //     label: "Narrative Required",
        //     class: "width-160px",
        //     searchKey: "trades.narrative_required",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "trades",
        //     commonSearchKey: "trades",
        //     commonSearchObjectKey: "narrative_required"
        // },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "trades.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "trades",
            commonSearchKey: "trades",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "trades.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "trades",
            commonSearchKey: "trades",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const futureCapitalTableData = {
    keys: ["name", "2022", "FY2", "FY3", "FY4", "FY5", "FY6", "Total"],
    config: {
        name: { isVisible: true, label: "Building/Addition", class: "" },
        2022: { isVisible: true, label: "2022", class: "" },
        FY2: { isVisible: true, label: "FY2", class: "" },
        FY3: { isVisible: true, label: "FY3", class: "" },
        FY4: { isVisible: true, label: "FY4", class: "" },
        FY5: { isVisible: true, label: "FY5", class: "" },
        FY6: { isVisible: true, label: "FY6", class: "" },
        Total: { isVisible: true, label: "Total", class: "" }
    },
    data: []
};

export const categorysettingsTableData = {
    keys: ["name", "project", "client", "description", "color_code", "assigned_bands", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            class: "width-160px",
            searchKey: "categories.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "categories",
            commonSearchKey: "categories",
            commonSearchObjectKey: "name"
        },
        project: {
            isVisible: true,
            label: "Project",
            class: "width-160px",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-160px",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "categories.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "categories",
            commonSearchKey: "categories",
            commonSearchObjectKey: "description"
        },
        color_code: {
            isVisible: true,
            label: "Color",
            class: "width-140px",
            searchKey: "categories.color_code",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "categories",
            commonSearchKey: "categories",
            commonSearchObjectKey: "color_code"
        },
        assigned_bands: {
            isVisible: true,
            label: "Assigned Bands",
            class: "",
            searchKey: "categories.assigned_bands",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "categories",
            commonSearchKey: "categories",
            commonSearchObjectKey: "assigned_bands"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "categories.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "categories",
            commonSearchKey: "categories",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "categories.updated_at",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "categories",
            commonSearchKey: "categories",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const differedMaintenanceTableData = {
    keys: ["name", "2022", "FY2", "FY3", "FY4", "FY5", "FY6", "Total"],
    config: {
        name: { isVisible: true, label: "Building/Addition", class: "" },
        2022: { isVisible: true, label: "2022", class: "" },
        FY2: { isVisible: true, label: "FY2", class: "" },
        FY3: { isVisible: true, label: "FY3", class: "" },
        FY4: { isVisible: true, label: "FY4", class: "" },
        FY5: { isVisible: true, label: "FY5", class: "" },
        FY6: { isVisible: true, label: "FY6", class: "" },
        Total: { isVisible: true, label: "Total", class: "" }
    },
    data: []
};

export const systemsettingsTableData = {
    keys: ["name", "project", "client", "trades", "description", "color_code", "narrative_required", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            class: "width-160px",
            searchKey: "systems.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "systems",
            commonSearchKey: "systems",
            commonSearchObjectKey: "name"
        },
        project: {
            isVisible: true,
            label: "Project",
            class: "width-160px",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-160px",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        trades: {
            isVisible: true,
            label: "Trade",
            class: "width-140px",
            searchKey: "trades.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "trade",
            commonSearchKey: "trades",
            commonSearchObjectKey: "name"
        },
        color_code: {
            isVisible: true,
            label: "Color",
            class: "width-140px",
            searchKey: "systems.color_code",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "systems",
            commonSearchKey: "systems",
            commonSearchObjectKey: "color_code"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "systems.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "systems",
            commonSearchKey: "systems",
            commonSearchObjectKey: "description"
        },
        narrative_required: {
            isVisible: true,
            label: "Narrative Required",
            class: "width-160px",
            searchKey: "systems.narrative_required",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "systems",
            commonSearchKey: "systems",
            commonSearchObjectKey: "narrative_required"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "systems.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "systems",
            commonSearchKey: "systems",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "systems.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "systems",
            commonSearchKey: "systems",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const subsystemsettingsTableData = {
    keys: [
        "name",
        "project",
        "client",
        "trades",
        "systems",
        "description",
        "benchmark",
        "narrative_required",
        "service_life",
        "created_at",
        "updated_at"
    ],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            class: "width-180px",
            searchKey: "sub_systems.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sub_systems",
            commonSearchKey: "sub_systems",
            commonSearchObjectKey: "name"
        },
        project: {
            isVisible: true,
            label: "Project",
            class: "width-160px",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-160px",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        trades: {
            isVisible: true,
            label: "Trade",
            searchKey: "trades.name",
            class: "width-120px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "trade",
            commonSearchKey: "trades",
            commonSearchObjectKey: "name"
        },
        systems: {
            isVisible: true,
            label: "System",
            searchKey: "systems.name",
            type: "string",
            class: "width-220px",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "system",
            commonSearchKey: "systems",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "sub_systems.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sub_systems",
            commonSearchKey: "sub_systems",
            commonSearchObjectKey: "description"
        },
        benchmark: {
            isVisible: true,
            label: "Benchmark",
            searchKey: "sub_systems.benchmark",
            class: "width-140px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "sub_systems",
            commonSearchKey: "sub_systems",
            commonSearchObjectKey: "benchmark"
        },
        narrative_required: {
            isVisible: true,
            label: "Narrative Required",
            class: "width-160px",
            searchKey: "sub_systems.narrative_required",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sub_systems",
            commonSearchKey: "sub_systems",
            commonSearchObjectKey: "narrative_required"
        },
        service_life: {
            isVisible: true,
            label: "Service Life",
            searchKey: "sub_systems.service_life",
            class: "width-120px",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sub_systems",
            commonSearchKey: "sub_systems",
            commonSearchObjectKey: "service_life"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "sub_systems.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sub_systems",
            commonSearchKey: "sub_systems",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "sub_systems.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sub_systems",
            commonSearchKey: "sub_systems",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const departmentsettingsTableData = {
    keys: ["name", "project", "client", "description", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            class: "width-160px",
            searchKey: "departments.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "departments",
            commonSearchKey: "departments",
            commonSearchObjectKey: "name"
        },
        project: {
            isVisible: true,
            label: "Project",
            class: "width-160px",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-160px",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "departments.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "departments",
            commonSearchKey: "departments",
            commonSearchObjectKey: "description"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "departments.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "departments",
            commonSearchKey: "departments",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "departments.updated_at",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "departments",
            commonSearchKey: "departments",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const generalsettingsTableData = {
    keys: ["start", "end", "region", "site", "number", "created_at", "updated_at"],
    config: {
        start: {
            isVisible: true,
            label: "Start Year",
            searchKey: "year_limits.start",
            type: "string",
            hasWildCardSearch: true,
            class: "width-120px",
            hasCommonSearch: false,
            getListTable: "year_limits",
            commonSearchKey: "year_limits",
            commonSearchObjectKey: "start"
        },
        end: {
            isVisible: true,
            label: "End Year",
            searchKey: "year_limits.end",
            type: "string",
            class: "width-120px",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "year_limits",
            commonSearchKey: "year_limits",
            commonSearchObjectKey: "end"
        },
        region: {
            isVisible: true,
            label: "Region",
            searchKey: "regions.name",
            class: "width-180px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "region",
            commonSearchKey: "regions",
            commonSearchObjectKey: "name"
        },
        site: {
            isVisible: true,
            label: "Site",
            searchKey: "sites.name",
            type: "string",
            class: "width-180px",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "site",
            commonSearchKey: "sites",
            commonSearchObjectKey: "name"
        },
        number: {
            isVisible: true,
            label: "Number of Years",
            searchKey: "year_limits.number",
            type: "string",
            class: "width-160px",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "year_limits",
            commonSearchKey: "year_limits",
            commonSearchObjectKey: "number"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "year_limits.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "year_limits",
            commonSearchKey: "year_limits",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "year_limits.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "year_limits",
            commonSearchKey: "year_limits",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const fundingsourcesettingsTableData = {
    keys: ["name", "project", "client", "description", "color_code", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            searchKey: "funding_sources.name",
            class: "width-140px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "funding_sources",
            commonSearchKey: "funding_sources",
            commonSearchObjectKey: "name"
        },
        project: {
            isVisible: true,
            label: "Project",
            class: "width-160px",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-160px",
            searchKey: "clients.6name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "funding_sources.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "funding_sources",
            commonSearchKey: "funding_sources",
            commonSearchObjectKey: "description"
        },
        color_code: {
            isVisible: true,
            label: "Color",
            class: "width-140px",
            searchKey: "funding_sources.color_code",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "funding_sources",
            commonSearchKey: "funding_sources",
            commonSearchObjectKey: "color_code"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "funding_sources.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "funding_sources",
            commonSearchKey: "funding_sources",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "funding_sources.updated_at",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "funding_sources",
            commonSearchKey: "funding_sources",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const prioritysettingsTableData = {
    keys: ["name", "start", "end", "color_code", "region", "site", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            searchKey: "priorities.name",
            class: "width-160px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "priorities",
            commonSearchKey: "priorities",
            commonSearchObjectKey: "name"
        },
        start: {
            isVisible: true,
            label: "Start Year",
            searchKey: "priorities.start",
            class: "width-120px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "priorities",
            commonSearchKey: "priorities",
            commonSearchObjectKey: "start"
        },
        end: {
            isVisible: true,
            label: "End Year",
            searchKey: "priorities.end",
            class: "width-120px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "priorities",
            commonSearchKey: "priorities",
            commonSearchObjectKey: "end"
        },
        region: {
            isVisible: true,
            label: "Region",
            class: "width-160px",
            searchKey: "regions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "region",
            commonSearchKey: "regions",
            commonSearchObjectKey: "name"
        },
        color_code: {
            isVisible: true,
            label: "Color",
            class: "width-140px",
            searchKey: "priorities.color_code",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "priorities",
            commonSearchKey: "priorities",
            commonSearchObjectKey: "color_code"
        },
        site: {
            isVisible: true,
            label: "Site",
            searchKey: "sites.name",
            class: "width-160px",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "site",
            commonSearchKey: "sites",
            commonSearchObjectKey: "name"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "priorities.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "priorities",
            commonSearchKey: "priorities",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "priorities.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "priorities",
            commonSearchKey: "priorities",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const clientTableData = {
    keys: ["code", "name", "consultancy", "comments", "is_active", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Client Code",
            class: "reg-code",
            searchKey: "clients.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Client Name",
            class: "reg-name",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "width-220px",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "consultancy",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "clients.comments",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "comments"
        },
        is_active: {
            isVisible: true,
            label: "Is Active",
            class: "width-120px",
            searchKey: "clients.is_active",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "is_active"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "clients.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "clients.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const manageLandingclientTableData = {
    keys: ["code", "name", "consultancy"],
    config: {
        code: {
            isVisible: true,
            label: "Client Code",
            class: "",
            searchKey: "clients.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Client Name",
            class: "reg-name",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "consultancy",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        }
    },
    data: []
};

export const userTableData = {
    keys: [
        "name",
        "first_name",
        "last_name",
        "printed_name",
        "email",
        "consultancy",
        "client",
        "role",
        "group",
        "default_project",
        "landing_page_lock",
        "assetmanagement_client",
        "energymanagement_client",
        "infrastructure_request",
        "cr_approver",
        "fmp",
        // "buildings",
        // "projects",
        "title",
        "credentials",
        "department",
        "work_phone",
        "cell_phone",
        "building_name",
        "floor",
        "room_number",
        "room_name",
        "city",
        "location",
        "state",
        "zip_code",
        "address",
        "emergency_contact_no",
        "emergency_contact_name",
        "notes",
        "is_active",
        "general_reviewer",
        "last_seen_at",
        "last_sign_in_at",
        "last_sign_out_at",
        "created_at",
        "updated_at"
    ],
    config: {
        name: {
            isVisible: true,
            label: "User Name",
            class: "reg-name",
            searchKey: "users.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        first_name: {
            isVisible: true,
            label: "First Name",
            class: "reg-name",
            searchKey: "users.first_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "first_name"
        },
        last_name: {
            isVisible: true,
            label: "Last Name",
            class: "reg-name",
            searchKey: "users.last_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_name"
        },
        printed_name: {
            isVisible: true,
            label: "Printed Name",
            class: "reg-name",
            searchKey: "users.printed_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "printed_name"
        },
        email: {
            isVisible: true,
            label: "Email",
            class: "",
            searchKey: "users.email",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "email"
        },
        last_seen_at: {
            isVisible: true,
            label: "Last Seen",
            class: "",
            searchKey: "users.last_seen_at",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_seen_at"
        },
        role: {
            isVisible: true,
            label: "Role",
            class: "",
            searchKey: "roles.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "role",
            commonSearchKey: "roles",
            commonSearchObjectKey: "name"
        },
        // buildings: {
        //     isVisible: true,
        //     label: "Buildings",
        //     class: "",
        //     searchKey: "buildings.name",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "buildings",
        //     commonSearchKey: "buildings",
        //     commonSearchObjectKey: "name"
        // },
        group: {
            isVisible: true,
            label: "User Permission",
            class: "",
            searchKey: "groups.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "groups",
            commonSearchKey: "groups",
            commonSearchObjectKey: "name"
        },
        default_project: {
            isVisible: true,
            label: "Default Project",
            class: "",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        landing_page_lock: {
            isVisible: true,
            label: "Has Landing Page",
            class: "",
            searchKey: "users.landing_page_lock",
            type: "boolean",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "landing_page_lock"
        },
        assetmanagement_client: {
            isVisible: true,
            label: "Asset Management Client",
            class: "width-300px",
            searchKey: "users.assetmanagement_client_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "assetmanagement_client_name"
        },
        energymanagement_client: {
            isVisible: true,
            label: "Energy Management Client",
            class: "width-300px",
            searchKey: "users.energymanagement_client_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "energymanagement_client_name"
        },
        infrastructure_request: {
            isVisible: true,
            label: "Capital Request User",
            class: "",
            searchKey: "users.infrastructure_request",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "infrastructure_request"
        },
        cr_approver: {
            isVisible: true,
            label: "CR Approver",
            class: "",
            searchKey: "users.cr_approver",
            type: "boolean",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "cr_approver"
        },
        general_reviewer: {
            isVisible: true,
            label: "Project Reviewer User",
            class: "",
            searchKey: "users.general_reviewer",
            type: "boolean",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "general_reviewer"
        },
        fmp: {
            isVisible: true,
            label: "FMP User",
            class: "",
            searchKey: "users.fmp",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "fmp"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "clients",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        // projects: {
        //     isVisible: true,
        //     label: "Associated Projects",
        //     class: "",
        //     searchKey: "projects.name",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "projects",
        //     commonSearchKey: "projects",
        //     commonSearchObjectKey: "name"
        // },
        title: {
            isVisible: true,
            label: "Title",
            class: "",
            searchKey: "users.title",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "title"
        },
        work_phone: {
            isVisible: true,
            label: "Work Phone",
            class: "reg-name",
            searchKey: "users.work_phone",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "work_phone"
        },
        cell_phone: {
            isVisible: true,
            label: "Cell Phone",
            class: "reg-name",
            searchKey: "users.cell_phone",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "cell_phone"
        },
        building_name: {
            isVisible: true,
            label: "Building Name",
            class: "reg-name",
            searchKey: "users.building_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "building_name"
        },
        floor: {
            isVisible: true,
            label: "Floor",
            class: "reg-name",
            searchKey: "users.floor",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "floor"
        },
        room_number: {
            isVisible: true,
            label: "Room Number",
            class: "reg-name",
            searchKey: "users.room_number",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "room_number"
        },
        room_name: {
            isVisible: true,
            label: "Room Name",
            class: "reg-name",
            searchKey: "users.room_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "room_name"
        },
        emergency_contact_no: {
            isVisible: true,
            label: "Emergency Contact Number",
            class: "reg-name",
            searchKey: "users.emergency_contact_no",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "emergency_contact_no"
        },
        emergency_contact_name: {
            isVisible: true,
            label: "Emergency Contact Name",
            class: "reg-name",
            searchKey: "users.emergency_contact_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "emergency_contact_name"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "reg-name",
            searchKey: "users.notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "notes"
        },
        department: {
            isVisible: true,
            label: "Department",
            class: "reg-name",
            searchKey: "users.department",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "department"
        },
        credentials: {
            isVisible: true,
            label: "Credentials",
            class: "reg-name",
            searchKey: "users.credentials",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "credentials"
        },

        city: {
            isVisible: true,
            label: "City",
            class: "reg-name",
            searchKey: "users.city",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "city"
        },
        last_sign_in_at: {
            isVisible: true,
            label: "Last Sign in At",
            class: "reg-name",
            searchKey: "users.last_sign_in_at",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_sign_in_at"
        },
        last_sign_out_at: {
            isVisible: true,
            label: "Last Sign out At",
            class: "reg-name",
            searchKey: "users.last_sign_out_at",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "last_sign_out_at"
        },
        location: {
            isVisible: true,
            label: "Location",
            class: "reg-name",
            searchKey: "users.location",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "location"
        },
        state: {
            isVisible: true,
            label: "State",
            class: "reg-name",
            searchKey: "users.state",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "state"
        },
        zip_code: {
            isVisible: true,
            label: "Zip Code",
            class: "reg-name",
            searchKey: "users.zip_code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "zip_code"
        },
        address: {
            isVisible: true,
            label: "Address",
            class: "reg-name",
            searchKey: "users.address",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "address"
        },
        is_active: {
            isVisible: true,
            label: "Is Active",
            class: "width-120px",
            searchKey: "users.is_active",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: true,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "is_active"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "users.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "users.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "users",
            commonSearchKey: "users",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const importHistoryTableData = {
    keys: ["name", "import_project", "user", "import_site", "created_at"],
    config: {
        name: {
            isVisible: true,
            label: "File Name",
            class: "reg-name",
            searchKey: "fca_sheets.sheet",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "fca_sheets",
            commonSearchKey: "fca_sheets",
            commonSearchObjectKey: "sheet"
        },
        import_project: {
            isVisible: true,
            label: "Project",
            class: "",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "project",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        user: {
            isVisible: true,
            label: "User",
            class: "",
            searchKey: "users.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "user",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        import_site: {
            isVisible: true,
            label: "Site",
            class: "",
            searchKey: "sites.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "site",
            commonSearchKey: "sites",
            commonSearchObjectKey: "name"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "fca_sheets.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "fca_sheets",
            commonSearchKey: "fca_sheets",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "fca_sheets.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "fca_sheets",
            commonSearchKey: "fca_sheets",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const assetconditionsettingsTableData = {
    keys: ["name", "project", "client", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            class: "width-140px",
            searchKey: "asset_conditions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "asset_conditions",
            commonSearchKey: "asset_conditions",
            commonSearchObjectKey: "name"
        },
        project: {
            isVisible: true,
            label: "Project",
            class: "width-160px",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-160px",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "asset_conditions.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "asset_conditions",
            commonSearchKey: "asset_conditions",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "asset_conditions.updated_at",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "asset_conditions",
            commonSearchKey: "asset_conditions",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const capitalTypeTableData = {
    keys: ["name", "display_name", "project", "client", "description", "color_code"],
    config: {
        name: {
            isVisible: true,
            label: "Capital Type",
            class: "width-140px",
            searchKey: "capital_types.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "capital_types",
            commonSearchKey: "capital_types",
            commonSearchObjectKey: "name"
        },
        project: {
            isVisible: true,
            label: "Project",
            class: "width-160px",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        display_name: {
            isVisible: true,
            label: "Display Name",
            class: "width-160px",
            searchKey: "capital_types.display_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "display_name",
            commonSearchKey: "display_name",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-160px",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "capital_types.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "capital_types",
            commonSearchKey: "capital_types",
            commonSearchObjectKey: "description"
        },
        color_code: {
            isVisible: true,
            label: "Color",
            class: "width-140px",
            searchKey: "capital_types.color_code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "capital_types",
            commonSearchKey: "capital_types",
            commonSearchObjectKey: "color_code"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "capital_types.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "capital_types",
            commonSearchKey: "capital_types",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "capital_types.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "capital_types",
            commonSearchKey: "capital_types",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const userPermssionTableData = {
    keys: ["name", "users", "consultancy", "client", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            class: "",
            searchKey: "groups.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "fca_sheets",
            commonSearchKey: "fca_sheets",
            commonSearchObjectKey: "sheet"
        },
        users: {
            isVisible: true,
            label: "Consultancy Users",
            class: "",
            searchKey: "users.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "assigned_users",
            commonSearchKey: "users",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: true,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "fca_sheets",
            commonSearchKey: "fca_sheets",
            commonSearchObjectKey: "sheet"
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "fca_sheets",
            commonSearchKey: "fca_sheets",
            commonSearchObjectKey: "sheet"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "groups.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "groups",
            commonSearchKey: "groups",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "groups.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "groups",
            commonSearchKey: "groups",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const templateTableData = {
    keys: ["name", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            class: "",
            searchKey: "groups.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "fca_sheets",
            commonSearchKey: "fca_sheets",
            commonSearchObjectKey: "sheet"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "groups.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "groups",
            commonSearchKey: "groups",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "groups.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "groups",
            commonSearchKey: "groups",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const consultancyTableData = {
    keys: ["code", "name", "comments", "created_at", "updated_at"],
    config: {
        code: {
            isVisible: true,
            label: "Consultancy Code",
            class: "reg-code",
            searchKey: "consultancies.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "code"
        },
        name: {
            isVisible: true,
            label: "Consultancy Name",
            class: "reg-name",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        comments: {
            isVisible: true,
            label: "Comments",
            class: "",
            searchKey: "consultancies.comments",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "consultancy",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "comments"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "consultancies.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "consultancies.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const narrativeRecommendationsTableData = {
    keys: [
        "code",
        "description",
        "project_total",
        "budget_priority",
        "building",
        "addition",
        "department",
        "capital_type",
        "category",
        "trade",
        "system",
        "sub_system",
        "buildingType",
        "recommendatonProject",
        "region",
        "site",
        "priority",
        "initiative",
        "hospital_name_recomentation",
        "client",
        "consultancy",
        "ministry",
        "floor",
        "room",
        "funding_source",
        "asset_name",
        "installed_year",
        "condition",
        "usefull_life_remaining",
        "service_life",
        "crv",
        "essential",
        "surveyor",
        "inspection_date",
        "status",
        "notes",
        "fmp",
        "fmp_id",
        "fmp_track",
        "fmp_project",
        "created_at",
        "updated_at"
    ],
    config: {
        id: {
            isVisible: true,
            label: "Id",
            class: "",
            searchKey: null
        },
        code: {
            isVisible: true,
            label: "Code",
            class: "reg-code",
            searchKey: "recommendations.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "code"
        },
        description: {
            isVisible: true,
            label: "Recommendation",
            class: "",
            searchKey: "recommendations.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "description"
        },
        budget_priority: {
            isVisible: true,
            label: "Budget Priority",
            class: "width-180px",
            searchKey: "recommendations.budget_priority",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "budget_priority"
        },
        trade: {
            isVisible: true,
            label: "Trade",
            class: "width-100px",
            searchKey: "trades.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "trade",
            commonSearchKey: "trades",
            commonSearchObjectKey: "name"
        },
        recommendatonProject: {
            isVisible: true,
            label: "Project Name",
            class: "",
            searchKey: "projects.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "projects",
            commonSearchKey: "projects",
            commonSearchObjectKey: "name"
        },
        system: {
            isVisible: true,
            label: "System",
            class: "width-220px",
            searchKey: "systems.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "systems.name",
            commonSearchKey: "systems",
            commonSearchObjectKey: "name"
        },
        sub_system: {
            isVisible: true,
            label: "Sub System",
            class: "width-250px",
            searchKey: "sub_systems.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "sub_systems.name",
            commonSearchKey: "sub_systems",
            commonSearchObjectKey: "name"
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "width-140px",
            searchKey: "buildings.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "buildings.name",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name"
        },
        addition: {
            isVisible: true,
            label: "Addition",
            class: "width-140px",
            searchKey: "additions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "additions",
            commonSearchKey: "additions",
            commonSearchObjectKey: "name"
        },
        buildingType: {
            isVisible: true,
            label: "Building Type",
            class: "width-220px",
            searchKey: "main_building_types.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "main_building_types.name",
            commonSearchKey: "main_building_types",
            commonSearchObjectKey: "name"
        },
        client: {
            isVisible: false,
            label: "Client",
            class: "client-wid",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "client",
            commonSearchKey: "clients",
            commonSearchObjectKey: "name"
        },
        consultancy: {
            isVisible: false,
            label: "Consultancy",
            class: "",
            searchKey: "consultancies.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "consultancies",
            commonSearchKey: "consultancies",
            commonSearchObjectKey: "name"
        },
        ministry: {
            isVisible: false,
            label: "Ministry",
            class: "",
            searchKey: "recommendations.ministry",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "ministry"
        },
        site: {
            isVisible: true,
            label: "Site",
            class: "width-160px",
            searchKey: "sites.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "sites",
            commonSearchKey: "sites",
            commonSearchObjectKey: "name"
        },
        region: {
            isVisible: true,
            label: "Region",
            class: "width-160px",
            searchKey: "regions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "regions",
            commonSearchKey: "regions",
            commonSearchObjectKey: "name"
        },
        floor: {
            isVisible: false,
            label: "Floor",
            class: "",
            searchKey: "floors.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "floor",
            commonSearchKey: "floors",
            commonSearchObjectKey: "name"
        },
        initiative: {
            isVisible: true,
            label: "Initiative",
            class: "width-160px",
            searchKey: "initiatives.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "initiatives",
            commonSearchKey: "initiative",
            commonSearchObjectKey: "initiative"
        },
        hospital_name_recomentation: {
            isVisible: false,
            label: "Parent Building",
            class: "",
            searchKey: "recommendations.hospital_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "hospital_name",
            commonSearchKey: "hospital_name",
            commonSearchObjectKey: "hospital_name"
        },
        department: {
            isVisible: false,
            label: "Department",
            class: "width-140px",
            searchKey: "departments.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "departments.name",
            commonSearchKey: "departments",
            commonSearchObjectKey: "name"
        },
        category: {
            isVisible: true,
            label: "Category",
            class: "width-170px",
            searchKey: "categories.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "categories",
            commonSearchKey: "categories",
            commonSearchObjectKey: "name"
        },
        room: {
            isVisible: false,
            label: "Room",
            class: "",
            searchKey: "recommendations.room",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "room"
        },
        asset_name: {
            isVisible: true,
            label: "Asset Name",
            class: "width-220px",
            searchKey: "recommendations.asset_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "asset_name"
        },
        installed_year: {
            isVisible: true,
            label: "Installed Year",
            class: "width-150px",
            searchKey: "recommendations.installed_year",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "installed_year"
        },
        notes: {
            isVisible: true,
            label: "Recommendation Narrative",
            class: "",
            searchKey: "recommendations.notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "notes"
        },
        condition: {
            isVisible: true,
            label: "Asset Condition",
            class: "",
            searchKey: "recommendations.condition",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "condition",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "condition"
        },
        priority: {
            isVisible: true,
            label: "Priority",
            class: "width-100px",
            searchKey: "recommendations.priority",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "priority"
        },
        funding_source: {
            isVisible: true,
            label: "Funding Source",
            class: "width-170px",
            searchKey: "funding_sources.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "funding_sources",
            commonSearchKey: "funding_sources",
            commonSearchObjectKey: "name"
        },
        deffered_maintenance: {
            isVisible: true,
            label: "Deffered Maintenance",
            class: "",
            searchKey: "recommendations.deffered_maintenance",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "deffered_maintenance"
        },
        future_capital: {
            isVisible: true,
            label: "Future Capital",
            class: "",
            searchKey: "recommendations.future_capital",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "future_capital"
        },
        inspection_date: {
            isVisible: true,
            label: "Inspection Date",
            class: "width-160px",
            searchKey: "recommendations.inspection_date",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "inspection_date"
        },
        project_total: {
            isVisible: true,
            label: "Project Total",
            class: "width-140px",
            searchKey: "recommendations.project_total",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "project_total"
        },
        surveyor: {
            isVisible: true,
            label: "Surveyor",
            class: "width-140px",
            searchKey: "recommendations.surveyor",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "surveyor"
        },
        status: {
            isVisible: true,
            label: "Status",
            class: "width-140px",
            searchKey: "recommendations.status",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "status"
        },
        capital_type: {
            isVisible: true,
            label: "Capital Type",
            class: "width-200px",
            searchKey: "recommendations.capital_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "capital_type"
        },
        usefull_life_remaining: {
            isVisible: true,
            label: "Useful Life Remaining",
            class: "width-200px",
            searchKey: "recommendations.usefull_life_remaining",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "usefull_life_remaining"
        },
        service_life: {
            isVisible: true,
            label: "Service Life",
            class: "width-140px",
            searchKey: "recommendations.service_life",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "service_life"
        },
        crv: {
            isVisible: false,
            label: "CRV",
            class: "",
            searchKey: "recommendations.crv",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "crv"
        },
        // manufacturer: {
        //     isVisible: true,
        //     label: "Manufacturer",
        //     class: "width-150px",
        //     searchKey: "recommendations.manufacturer",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "manufacturer"
        // },
        // model_number: {
        //     isVisible: true,
        //     label: "Model Number",
        //     class: "width-150px",
        //     searchKey: "recommendations.model_number",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "model_number"
        // },
        // serial_number: {
        //     isVisible: true,
        //     label: "Serial Number",
        //     class: "width-150px",
        //     searchKey: "recommendations.serial_number",
        //     type: "number",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "serial_number"
        // },
        // capacity: {
        //     isVisible: false,
        //     label: "Capacity",
        //     class: "",
        //     searchKey: "recommendations.capacity",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "capacity"
        // },
        // asset_notes: {
        //     isVisible: false,
        //     label: "Asset Notes",
        //     class: "",
        //     searchKey: "recommendations.asset_notes",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "asset_notes"
        // },
        // priority_element1: {
        //     isVisible: true,
        //     label: "Priority Element 1",
        //     class: "",
        //     searchKey: "priority_elements.1",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "priority_element1"
        // },
        // priority_element2: {
        //     isVisible: true,
        //     label: "Priority Element 2",
        //     class: "",
        //     searchKey: "priority_elements.2",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "priority_element2"
        // },
        // priority_element3: {
        //     isVisible: true,
        //     label: "Priority Element 3",
        //     class: "",
        //     searchKey: "priority_elements.3",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "priority_element3"
        // },
        // priority_element4: {
        //     isVisible: true,
        //     label: "Priority Element 4",
        //     class: "",
        //     searchKey: "priority_elements.4",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "priority_element4"
        // },
        // priority_element5: {
        //     isVisible: true,
        //     label: "Priority Element 5",
        //     class: "",
        //     searchKey: "priority_elements.5",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "priority_element5"
        // },
        // priority_element6: {
        //     isVisible: true,
        //     label: "Priority Element 6",
        //     class: "",
        //     searchKey: "priority_elements.6",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "priority_element6"
        // },
        // priority_element7: {
        //     isVisible: true,
        //     label: "Priority Element 7",
        //     class: "",
        //     searchKey: "priority_elements.7",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "priority_element7"
        // },
        // priority_element8: {
        //     isVisible: true,
        //     label: "Priority Element 8",
        //     class: "",
        //     searchKey: "priority_elements.8",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "recommendations",
        //     commonSearchKey: "recommendations",
        //     commonSearchObjectKey: "priority_element8"
        // },
        fmp: {
            isVisible: true,
            label: "Facility Master Plan",
            class: "width-220px",
            searchKey: "recommendations.fmp",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "fmp"
        },
        fmp_id: {
            isVisible: false,
            label: "FMP ID",
            class: "width-220px",
            searchKey: "recommendations.fmp_id",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "fmp_id"
        },
        fmp_project: {
            isVisible: false,
            label: "FMP Project",
            class: "width-220px",
            searchKey: "recommendations.fmp_project",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "fmp_project"
        },
        fmp_track: {
            isVisible: false,
            label: "FMP Track",
            class: "width-220px",
            searchKey: "recommendations.fmp_track",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "fmp_track"
        },
        essential: {
            isVisible: false,
            label: "Essential",
            class: "",
            searchKey: "recommendations.essential",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "essential"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "recommendations.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "recommendations.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "recommendations",
            commonSearchKey: "recommendations",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const reportPropertiesTableData = {
    keys: ["name", "description", "notes", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Property Name",
            class: "",
            searchKey: "excel_settings.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "excel_settings",
            commonSearchKey: "excel_settings",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "excel_settings.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "excel_settings",
            commonSearchKey: "excel_settings",
            commonSearchObjectKey: "description"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "",
            searchKey: "excel_settings.notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "excel_settings",
            commonSearchKey: "excel_settings",
            commonSearchObjectKey: "notes"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "excel_settings.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "excel_settings",
            commonSearchKey: "excel_settings",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "excel_settings.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "excel_settings",
            commonSearchKey: "excel_settings",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

// report template table configuration (python backend)
export const reportTemplateTableData = {
    keys: ["template_name", "setting_name", "uploaded_by", "description", "notes", "uploaded_at", "updated_at"],
    config: {
        template_name: {
            isVisible: true,
            label: "Template Name",
            class: "width-160px",
            searchKey: "template_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "template_name"
        },
        setting_name: {
            isVisible: true,
            label: "Report Property",
            class: "width-160px",
            searchKey: "setting_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "setting_name"
        },
        uploaded_by: {
            isVisible: true,
            label: "Uploaded By",
            class: "width-140px",
            searchKey: "uploaded_by",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "uploaded_by"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "description"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "",
            searchKey: "notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "notes"
        },
        uploaded_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "uploaded_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "uploaded_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};
export const imageLogsTableData = {
    keys: [
        "image_name",
        "username",
        "overwrite",
        "status",
        "file_size",
        "trade_name",
        "system_name",
        "sub_system_name",
        "building_name",
        "site_name",
        "region_name",
        "project_name",
        "client_name",
        "is_asset",
        "upload_date"
    ],
    config: {
        image_name: {
            isVisible: true,
            label: "Image Name",
            class: "width-200px",
            searchKey: "image_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "image_name"
        },
        username: {
            isVisible: true,
            label: "Uploaded By",
            class: "width-160px",
            searchKey: "username",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "username"
        },
        overwrite: {
            isVisible: true,
            label: "Overwrite",
            class: "width-160px",
            searchKey: "overwrite",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "overwrite"
        },
        file_size: {
            isVisible: true,
            label: "Size",
            class: "width-160px",
            searchKey: "file_size",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "file_size"
        },
        status: {
            isVisible: true,
            label: "Upload Status",
            class: "width-160px",
            searchKey: "status",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "status"
        },
        trade_name: {
            isVisible: true,
            label: "Trade",
            class: "width-200px",
            searchKey: "trade_name",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "trade_name"
        },
        system_name: {
            isVisible: true,
            label: "System",
            class: "width-200px",
            searchKey: "system_name",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "system_name"
        },
        sub_system_name: {
            isVisible: true,
            label: "Sub-System",
            class: "width-200px",
            searchKey: "sub_system_name",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "sub_system_name"
        },
        building_name: {
            isVisible: true,
            label: "Building",
            class: "width-200px",
            searchKey: "building_name",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "building_name"
        },
        site_name: {
            isVisible: true,
            label: "Site",
            class: "width-200px",
            searchKey: "site_name",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "site_name"
        },
        region_name: {
            isVisible: true,
            label: "Region",
            class: "width-200px",
            searchKey: "region_name",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "region_name"
        },
        project_name: {
            isVisible: true,
            label: "Project",
            class: "width-200px",
            searchKey: "project_name",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "project_name"
        },
        client_name: {
            isVisible: true,
            label: "Client",
            class: "width-200px",
            searchKey: "client_name",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "client_name"
        },
        is_asset: {
            isVisible: true,
            label: "Is Asset Image",
            class: "width-160px",
            searchKey: "is_asset",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "is_asset"
        },
        upload_date: {
            isVisible: true,
            label: "Uploaded Date",
            class: "",
            searchKey: "upload_date",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "upload_date"
        }
    },
    data: []
};

export const importModalTableData = {
    keys: ["doc_name", "user", "status", "sort_type", "export_from", "filter_fields", "export_notes", "created_at", "updated_at"],
    config: {
        doc_name: {
            isVisible: true,
            label: "File Name",
            class: "reg-name",
            searchKey: "doc_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "doc_name",
            commonSearchKey: "doc_name",
            commonSearchObjectKey: "doc_name"
        },
        user: {
            isVisible: true,
            label: "User",
            class: "width-120px",
            searchKey: "user__name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "user_name",
            commonSearchKey: "user_name",
            commonSearchObjectKey: "user_name"
        },
        status: {
            isVisible: true,
            label: "Status",
            class: "width-120px",
            searchKey: "status",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "status",
            commonSearchKey: "status",
            commonSearchObjectKey: "status"
        },
        // export_name: {
        //     isVisible: true,
        //     label: "Export Name",
        //     class: "",
        //     searchKey: "export_name",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "user",
        //     commonSearchKey: "users",
        //     commonSearchObjectKey: "name"
        // },
        export_notes: {
            isVisible: true,
            label: "Notes",
            class: "width-170px",
            searchKey: "export_notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "export_notes",
            commonSearchKey: "export_notes",
            commonSearchObjectKey: "export_notes"
        },
        sort_type: {
            isVisible: true,
            label: "Sort Type",
            class: "width-120px",
            searchKey: "sort_type",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "sort_type",
            commonSearchKey: "sort_type",
            commonSearchObjectKey: "sort_type"
        },
        filter_fields: {
            isVisible: true,
            label: "Filter",
            class: "",
            searchKey: "filter_fields",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "filter_fields",
            commonSearchKey: "filter_fields",
            commonSearchObjectKey: "filter_fields"
        },
        export_from: {
            isVisible: true,
            label: "Exporting From",
            class: "width-150px",
            searchKey: "export_from",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "export_from",
            commonSearchKey: "export_from",
            commonSearchObjectKey: "export_from"
        },

        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "created_at",
            commonSearchKey: "created_at",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "updated_at",
            commonSearchKey: "updated_at",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const chartTemplateTableData = {
    keys: [
        "name",
        "chart_properties",
        "template_type",
        "active_single_chart",
        "active_smart_chart",
        "one_pager_active",
        "uploaded_by",
        "description",
        "notes",
        "created_at",
        "updated_at"
    ],
    config: {
        name: {
            isVisible: true,
            label: "Template Name",
            class: "",
            searchKey: "name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "name"
        },
        chart_properties: {
            isVisible: true,
            label: "Export Property",
            class: "",
            searchKey: "property_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "chart_properties"
        },
        template_type: {
            isVisible: true,
            label: "Template Type",
            class: "width-150px",
            searchKey: "template_type",
            type: "string",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "template_type"
        },
        uploaded_by: {
            isVisible: true,
            label: "Uploaded By",
            class: "width-140px",
            searchKey: "uploaded_by",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "uploaded_by"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "description"
        },
        active_single_chart: {
            isVisible: true,
            label: "Regular (Single) Chart Active",
            class: "chart-singular",
            type: "boolean",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: ""
        },
        active_smart_chart: {
            isVisible: true,
            label: "Smart Chart Active",
            class: "width-180px smart-chrt-act",
            type: "boolean",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: ""
        },
        one_pager_active: {
            isVisible: true,
            label: "One Pager Active",
            class: "width-180px",
            type: "boolean",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: ""
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "width-140px",
            searchKey: "notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "notes"
        },
        uploaded_at: {
            isVisible: true,
            label: "Created At",
            class: "width-140px",
            searchKey: "uploaded_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "uploaded_at"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "width-160px",
            searchKey: "created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "width-180px",
            searchKey: "updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const chartPropertiesTableData = {
    keys: ["name", "description", "notes", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Property Name",
            class: "",
            searchKey: "name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "excel_settings",
            commonSearchKey: "excel_settings",
            commonSearchObjectKey: "name"
        },
        description: {
            isVisible: true,
            label: "Description",
            class: "",
            searchKey: "description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "excel_settings",
            commonSearchKey: "excel_settings",
            commonSearchObjectKey: "description"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "",
            searchKey: "notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "excel_settings",
            commonSearchKey: "excel_settings",
            commonSearchObjectKey: "notes"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "excel_settings",
            commonSearchKey: "excel_settings",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "excel_settings",
            commonSearchKey: "excel_settings",
            commonSearchObjectKey: "updated_at"
        }
    },

    data: []
};
export const manageHeadingsTableData = {
    keys: ["name", "notes", "created_at", "updated_at"],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            class: "",
            searchKey: "name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "name"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "",
            searchKey: "notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "notes"
        },

        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "",
            commonSearchKey: "",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};

export const notificationTableData = {
    keys: ["notification_title", "notification_body", "user", "read_at", "seen_at", "created_at"],
    config: {
        notification_title: {
            isVisible: true,
            label: "Title",
            class: "",
            searchKey: "title",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "code"
        },
        notification_body: {
            isVisible: true,
            label: "Message",
            class: "recom-dash-main",
            searchKey: "body",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "code"
        },
        user: {
            isVisible: true,
            label: "Recipient",
            class: "",
            searchKey: "user_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "code"
        },
        read_at: {
            isVisible: true,
            label: "Read At",
            class: "",
            searchKey: "read_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "read_at"
        },
        seen_at: {
            isVisible: true,
            label: "Seen At",
            class: "",
            searchKey: "seen_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "seen_at"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "created_at"
        }
    },
    data: []
};

export const emailTableData = {
    keys: ["from_user", "to_user", "description", "files", "created_at"],
    config: {
        from_user: {
            isVisible: true,
            label: "From",
            class: "",
            searchKey: "from_user",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "from_mail",
            commonSearchKey: "from_mail",
            commonSearchObjectKey: "from_mail"
        },
        to_user: {
            isVisible: true,
            label: "To",
            class: "",
            searchKey: "to_user",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "to_user",
            commonSearchKey: "to_user",
            commonSearchObjectKey: "to_user"
        },
        description: {
            isVisible: true,
            label: "Content",
            class: "",
            searchKey: "content",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "description",
            commonSearchKey: "description",
            commonSearchObjectKey: "description"
        },

        files: {
            isVisible: true,
            label: "Attachments",
            class: "width-120px",
            searchKey: "files",
            type: "files",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "code"
        },

        // updated_at: {
        //     isVisible: true,
        //     label: "Updated At",
        //     class: "",
        //     searchKey: "seen_at",
        //     type: "date",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "notifications",
        //     commonSearchKey: "notifications",
        //     commonSearchObjectKey: "seen_at"
        // },
        created_at: {
            isVisible: true,
            label: "Time",
            class: "",
            searchKey: "created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "email",
            commonSearchKey: "email",
            commonSearchObjectKey: "created_at"
        }
    },
    data: []
};
export const inboxTableData = {
    keys: ["from_user", "description", "files", "created_at"],
    config: {
        from_user: {
            isVisible: true,
            label: "From",
            class: "",
            searchKey: "from_user",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "from_mail",
            commonSearchKey: "from_mail",
            commonSearchObjectKey: "from_mail"
        },

        description: {
            isVisible: true,
            label: "Content",
            class: "",
            searchKey: "content",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "description",
            commonSearchKey: "description",
            commonSearchObjectKey: "description"
        },

        // time: {
        //     isVisible: true,
        //     label: "Time",
        //     class: "",
        //     searchKey: "time",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "notifications",
        //     commonSearchKey: "notifications",
        //     commonSearchObjectKey: "code"
        // },

        // updated_at: {
        //     isVisible: true,
        //     label: "Updated At",
        //     class: "",
        //     searchKey: "seen_at",
        //     type: "date",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "notifications",
        //     commonSearchKey: "notifications",
        //     commonSearchObjectKey: "seen_at"
        // },
        files: {
            isVisible: true,
            label: "Attachments",
            class: "width-120px",
            searchKey: "files",
            type: "files",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "code"
        },
        created_at: {
            isVisible: true,
            label: "Time",
            class: "",
            searchKey: "created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "email",
            commonSearchKey: "email",
            commonSearchObjectKey: "created_at"
        }
    },
    data: []
};

export const sentTableData = {
    keys: ["to_user", "description", "files", "created_at"],
    config: {
        to_user: {
            isVisible: true,
            label: "To",
            class: "",
            searchKey: "to_user",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "to_user",
            commonSearchKey: "to_user",
            commonSearchObjectKey: "to_user"
        },
        description: {
            isVisible: true,
            label: "Content",
            class: "",
            searchKey: "content",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "description",
            commonSearchKey: "description",
            commonSearchObjectKey: "description"
        },
        // time: {
        //     isVisible: true,
        //     label: "Time",
        //     class: "",
        //     searchKey: "time",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "notifications",
        //     commonSearchKey: "notifications",
        //     commonSearchObjectKey: "code"
        // },

        // updated_at: {
        //     isVisible: true,
        //     label: "Updated At",
        //     class: "",
        //     searchKey: "seen_at",
        //     type: "date",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "notifications",
        //     commonSearchKey: "notifications",
        //     commonSearchObjectKey: "seen_at"
        // },
        files: {
            isVisible: true,
            label: "Attachments",
            class: "width-120px",
            searchKey: "files",
            type: "files",
            hasWildCardSearch: false,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "code"
        },
        created_at: {
            isVisible: true,
            label: "Time",
            class: "",
            searchKey: "created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "email",
            commonSearchKey: "email",
            commonSearchObjectKey: "created_at"
        }
    },
    data: []
};

export const smartChartTableData = {
    keys: ["name", "client_name", "project_name", "user", "notes", "created_at"],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            class: "width-180px",
            searchKey: "to_user",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "to_user",
            commonSearchKey: "to_user",
            commonSearchObjectKey: "to_user"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "",
            searchKey: "content",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "description",
            commonSearchKey: "description",
            commonSearchObjectKey: "description"
        },
        client_name: {
            isVisible: true,
            label: "Client",
            class: "width-160px",
            searchKey: "to_user",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "to_user",
            commonSearchKey: "to_user",
            commonSearchObjectKey: "to_user"
        },
        project_name: {
            isVisible: true,
            label: "Project",
            class: "width-160px",
            searchKey: "to_user",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "to_user",
            commonSearchKey: "to_user",
            commonSearchObjectKey: "to_user"
        },
        user: {
            isVisible: true,
            label: "Exported By",
            class: "width-160px",
            searchKey: "content",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "description",
            commonSearchKey: "description",
            commonSearchObjectKey: "description"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "width-160px",
            searchKey: "created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "created_at"
        }
    },
    data: []
};

export const smartChartPropertiesTableData = {
    keys: ["name", "client_name", "notes", "user", "created_at"],
    config: {
        name: {
            isVisible: true,
            label: "Name",
            class: "width-180px",
            searchKey: "to_user",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "to_user",
            commonSearchKey: "to_user",
            commonSearchObjectKey: "to_user"
        },
        notes: {
            isVisible: true,
            label: "Notes",
            class: "",
            searchKey: "content",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "description",
            commonSearchKey: "description",
            commonSearchObjectKey: "description"
        },
        client_name: {
            isVisible: true,
            label: "Client",
            class: "width-160px",
            searchKey: "to_user",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "to_user",
            commonSearchKey: "to_user",
            commonSearchObjectKey: "to_user"
        },
        user: {
            isVisible: true,
            label: "Created By",
            class: "width-160px",
            searchKey: "content",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "description",
            commonSearchKey: "description",
            commonSearchObjectKey: "description"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "notifications",
            commonSearchKey: "notifications",
            commonSearchObjectKey: "created_at"
        }
    },
    data: []
};
export const MedianTableData = {
    keys: ["market_sector", "property_type", "source_eui_kbtu_ft2", "site_eui_kbtu_ft2", "year", "created_at", "updated_at"],
    config: {
        market_sector: {
            isVisible: true,
            label: "Market Sector",
            class: "",
            searchKey: "market_sector",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "median",
            commonSearchKey: "median",
            commonSearchObjectKey: "market_sector"
        },
        property_type: {
            isVisible: true,
            label: "Property Type",
            class: "recom-dash-main",
            searchKey: "property_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "median",
            commonSearchKey: "median",
            commonSearchObjectKey: "property_type"
        },
        source_eui_kbtu_ft2: {
            isVisible: true,
            label: "Source EUI (kbtu/ft2)",
            class: "",
            searchKey: "source_eui_kbtu_ft2",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "median",
            commonSearchKey: "median",
            commonSearchObjectKey: "source_eui_kbtu_ft2"
        },
        site_eui_kbtu_ft2: {
            isVisible: true,
            label: "Site EUI(kbtu_ft2)",
            class: "",
            searchKey: "site_eui_kbtu_ft2",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "median",
            commonSearchKey: "median",
            commonSearchObjectKey: "site_eui_kbtu_ft2"
        },
        year: {
            isVisible: true,
            label: "Year",
            class: "",
            searchKey: "year",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "median",
            commonSearchKey: "median",
            commonSearchObjectKey: "year"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "median",
            commonSearchKey: "median",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "median",
            commonSearchKey: "median",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};
