import React from "react";
import ReactTooltip from "react-tooltip";

export const AdditionalFilters = ({ tableParams, ...props }) => {
    return (
        <div class={`collapse-outer col-md-12 p-0 tog-cls`}>
            <ReactTooltip id="toggle-div" effect="solid" place="top" backgroundColor="#007bff" />
            <ul>
                {/* <li
                    className={`col-3 ${tableParams.recommendation_assigned_true ? "active" : ""}`}
                    onClick={() => props.filterByRecommendationAssigned()}
                    data-for="toggle-div"
                    data-tip={
                        tableParams.recommendation_assigned_true === "true"
                            ? `View Assets with NO Recommendations`
                            : tableParams.recommendation_assigned_true === "false"
                            ? "Reset Recommendation Filter"
                            : `View Assets with Recommendations`
                    }
                >
                    <img src="/img/recom-assigned.svg" alt="" /> Recommendations
                </li> */}
                <li
                    className={`col-3 ${tableParams.guid_assigned ? "active" : ""}`}
                    onClick={() => props.filterByGuid()}
                    data-for="toggle-div"
                    data-tip={
                        tableParams.guid_assigned === "true"
                            ? `View Assets with NO GUID`
                            : tableParams.guid_assigned === "false"
                            ? "Reset GUID Filter"
                            : `View Assets with GUID`
                    }
                >
                    <img src="/img/plan-icon.svg" alt="" /> GUID
                </li>
                <li
                    className={`col-3 ${tableParams.has_image ? "active" : ""}`}
                    onClick={() => props.filterByImages()}
                    data-for="toggle-div"
                    data-tip={
                        tableParams.has_image === "true"
                            ? `View Assets with NO images `
                            : tableParams.has_image === "false"
                            ? "View All Assets"
                            : `View Assets with Images`
                    }
                >
                    <img src="/img/img-icon.svg" alt="" /> Image
                </li>
            </ul>
        </div>
    );
};
