import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import Portal from "../../common/components/Portal";
import { useDispatch, useSelector } from "react-redux";
import actions from "../actions";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../common/components/Loader";
import Draggable from "react-draggable";
export const ShiftCspModal = props => {
    const dispatch = useDispatch();
    const { getSourceYearsResponse } = useSelector(state => state.recommendationsReducer);
    const [data, setData] = useState({ source_year: "", target_year: "" });
    const [showGroupUpdateConfirmation, setGroupUpdateConfirmation] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [showErrorBorder, setErrorBorder] = useState(false);
    const [errorParams, setErrorParams] = useState({});
    const { maintenance_years } = props;

    useEffect(() => {
        dispatch(actions.getSourceYears({ recommendation_ids: props.recommendationIds }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validate = () => {
        let showErrorBorder = false;
        let errorParams = {};
        if (!data?.source_year) {
            showErrorBorder = true;
            errorParams.source_year = "Source Year is Required";
        } else if (!data?.target_year) {
            showErrorBorder = true;
            errorParams.target_year = "Target Year is Required";
        }
        setErrorBorder(showErrorBorder);
        setErrorParams(errorParams);
        if (showErrorBorder) return false;
        return true;
    };

    const onShiftCspYear = async () => {
        setLoading(true);
        await props.onShiftCspYear(data);
        setLoading(false);
    };
    const renderGroupUpdateConfirmationModal = () => {
        if (!showGroupUpdateConfirmation) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`You are about to modify ${props.recommendationIds?.length} Recommendations. This action cannot be undone! `}
                        type="cancel"
                        message={"Are you sure you want to continue?"}
                        onNo={() => setGroupUpdateConfirmation(false)}
                        onYes={() => {
                            setGroupUpdateConfirmation(false);
                            onShiftCspYear();
                        }}
                    />
                }
                onCancel={() => setGroupUpdateConfirmation(false)}
            />
        );
    };
    const source_years = [...new Set(getSourceYearsResponse?.years)];
    return (
        <>
            <div
                className="modal modal-region year-filter"
                style={{ display: "block" }}
                id="modalId"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <Draggable cancel=".not-draggable">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Shift CSP
                                </h5>
                                <button
                                    type="button"
                                    className="close not-draggable"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => props.onCancel()}
                                >
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body not-draggable region-otr build-type-mod">
                                <div className="form-group">
                                    <label>From *</label>
                                    <div className="selectOtr">
                                        <LoadingOverlay active={getSourceYearsResponse?.isLoading} spinner={<Loader />} fadeSpeed={10}>
                                            <select
                                                onChange={e => setData({ ...data, source_year: parseInt(e.target.value) })}
                                                value={data.source_year}
                                                className={`form-control ${showErrorBorder && errorParams?.source_year ? "error-border" : ""}`}
                                            >
                                                <option value="">Select</option>
                                                {source_years?.length
                                                    ? source_years
                                                          ?.filter(item => parseInt(item) !== data.target_year)
                                                          .map((item, i) => (
                                                              <option value={item} key={i}>
                                                                  {item}
                                                              </option>
                                                          ))
                                                    : null}
                                            </select>
                                        </LoadingOverlay>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>To *</label>
                                    <div className="selectOtr">
                                        <select
                                            onChange={e => setData({ ...data, target_year: parseInt(e.target.value) })}
                                            value={data.target_year}
                                            className={`form-control ${showErrorBorder && errorParams?.target_year ? "error-border" : ""}`}
                                        >
                                            <option value="">Select</option>
                                            {maintenance_years?.length
                                                ? maintenance_years
                                                      .filter(item => parseInt(item) !== data.source_year)
                                                      .map((item, i) => (
                                                          <option value={item} key={i}>
                                                              {item}
                                                          </option>
                                                      ))
                                                : null}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12 p-0 text-right btnOtr">
                                    <button type="button" onClick={() => props.onCancel()} className="btn btn-primary btnClr">
                                        Cancel
                                    </button>
                                    {isLoading ? (
                                        <button type="button" className="btn btn-primary btnRgion ml-2">
                                            <div className="button-loader d-flex justify-content-center align-items-center">
                                                <div className="spinner-border text-white" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            onClick={() => validate() && setGroupUpdateConfirmation(true)}
                                            className="btn btn-primary btnRgion col-md-2"
                                        >
                                            Shift
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
            {renderGroupUpdateConfirmationModal()}
        </>
    );
};
