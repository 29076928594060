import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";

import "../../../assets/css/qaband.css";

import QaQcRow from "./QaQcRow";
import Portal from "../../common/components/Portal";
import ConfirmationModal from "../../common/components/ConfirmationModal";

import actions from "../actions";
import SingleQaIssuePreview from "./SingleQaIssuePreview";
import history from "../../../config/history";
import { bulkResetBreadCrumpData, findPrevPathFromBreadCrumpData, popBreadCrumpOnPageClose } from "../../../config/utils";
import AddIssueModal from "../../common/components/AddIssueModal";

function QaQcBand({ isQaQcReviewerUser, recommendationId, refreshinfoDetails, showData, basicDetails }) {
    const [filterBy, setFilterBy] = useState("");
    const [sortby, setSortby] = useState("created_at");
    const [sortOrder, setSortOrder] = useState("desc");
    const [showConfirmation, setShowConfirmation] = useState(null);
    const [clearAllConfirmation, setClearAllConfirmation] = useState(false);
    const [passQaQcConfirm, setPassQaQcConfirm] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editIssue, setEditIssue] = useState(null);

    const {
        getAllRecommendationReviewsResponse = {},
        updateRecommendationReviewResponse = {},
        addQaQcIssueResponse,
        getRecommendationByIdResponse = {},
        updateQaQcPassedResponse = {}
    } = useSelector(state => state.recommendationsReducer) || {};
    const filterOptions = [
        { label: "Pending", value: "pending" },
        { label: "Approved", value: "approved" },
        { label: "Resolved", value: "resolved" },
        { label: "Default ", value: "" }
    ];
    const sortOptions = [
        { label: "Status", value: "status" },
        { label: "Updted At", value: "updated_at" },
        { label: "Added Date", value: "created_at" }
        // { label: "Default ", value: "" }
    ];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            actions.getAllRecommendationReviews({
                recommendation_id: recommendationId,
                order: {
                    [`recommendation_reviews.${sortby}`]: sortOrder
                }
            })
        );
    }, [sortby, sortOrder]);

    useEffect(() => {
        if (selectedRow) {
            dispatch(actions.getRecommendationReviewById(selectedRow));
        }
    }, [selectedRow]);

    useEffect(() => {
        if (getRecommendationByIdResponse) {
            const { success, id, code, project } = getRecommendationByIdResponse;
            if (success) {
                handleBreadCrumbs({ id, project_id: project?.id, code, project: project?.name });
            }
        }
    }, [getRecommendationByIdResponse]);

    useEffect(() => {
        if (updateRecommendationReviewResponse?.message) {
            if (updateRecommendationReviewResponse?.success) {
                if (showConfirmation === selectedRow) {
                    setSelectedRow(null);
                }
                setShowConfirmation(null);
                setEditIssue(null);
            }
            var x = document.getElementById("sucess-alert");
            if (x) {
                x.className = "show";
                x.innerText = updateRecommendationReviewResponse.message;
                setTimeout(function () {
                    x.className = x.className.replace("show", "");
                }, 3000);
            }
            dispatch(
                actions.getAllRecommendationReviews({
                    recommendation_id: recommendationId,
                    order: {
                        [`recommendation_reviews.${sortby}`]: sortOrder
                    }
                })
            );
            if (selectedRow) {
                dispatch(actions.getRecommendationReviewById(selectedRow));
            }
        }
    }, [updateRecommendationReviewResponse]);

    useEffect(() => {
        if (addQaQcIssueResponse?.message) {
            if (getRecommendationByIdResponse?.qa_passed) refreshinfoDetails();
        }
    }, [addQaQcIssueResponse]);

    useEffect(() => {
        if (updateQaQcPassedResponse?.success) {
            refreshinfoDetails();
            setPassQaQcConfirm(false);
        }
    }, [updateQaQcPassedResponse]);

    useEffect(() => {
        setSelectedRow(null);
    }, [recommendationId]);

    const handleEditIssueTitle = title => {
        dispatch(actions.updateRecommendationReview(editIssue?.id, { recommendation_review: { title } }));
    };

    const handleAddIssueComment = (id, comment) => {
        dispatch(
            actions.addReviewComment({
                recommendation_review_comment: {
                    comment,
                    recommendation_review_id: id,
                    creator_id: localStorage.getItem("userId")
                }
            })
        );
    };

    const handleEditIssueStatus = ({ id, status }) => {
        if (isQaQcReviewerUser) {
            dispatch(
                actions.updateRecommendationReviewStatusReviewer(id, {
                    status_action: status
                })
            );
        } else {
            dispatch(
                actions.updateRecommendationReviewStatusUser(id, {
                    status_action: status
                })
            );
        }
    };

    const handleSendAllNotification = () => {
        dispatch(actions.sendQaQcAllNotification({ recommendation_id: recommendationId }));
    };

    const handleSendReviewNotification = id => {
        dispatch(actions.sendReviewNotification(id));
    };

    const handleRowClicked = id => {
        setSelectedRow(prev => (prev === id ? null : id));
    };

    const submitQaPassed = () => {
        dispatch(
            actions.qaQcPassedUpdate(recommendationId, {
                qa_passed: !getRecommendationByIdResponse?.qa_passed
            })
        );
    };

    const handleBreadCrumbs = data => {
        const currentBC = JSON.parse(sessionStorage.getItem("bc-data") || "[]");
        if (currentBC?.length < 2) {
            const { id, project_id, code, project } = data || {};
            let bc = [
                { index: 0, key: "main", name: "FCA Projects", path: "/project" },
                { index: 1, key: "buildingName", name: project, path: `/project/projectinfo/${project_id}/basicdetails` },
                { index: 2, key: "info", name: "Recommendations", path: `/project/projectinfo/${project_id}/recommendations` },
                { index: 3, key: "Name", name: code, path: `/recommendations/recommendationsinfo/${id}/maindetails` },
                { index: 4, key: "info", name: "Qa/Qc", path: `/recommendations/recommendationsinfo/${id}/qaqc` }
            ];
            bulkResetBreadCrumpData(bc);
        }
    };

    const renderPassConfirmationModal = () => {
        if (!passQaQcConfirm) return null;
        const isPassed = getRecommendationByIdResponse?.qa_passed;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Do you want to mark this as ${isPassed ? "not " : ""}QA Passed?`}
                        message={`Once marked this recommendation will be ${isPassed ? "un" : ""}locked`}
                        onNo={() => setPassQaQcConfirm(false)}
                        onYes={submitQaPassed}
                        isUnAssign
                        type="unassign"
                    />
                }
                onCancel={() => setPassQaQcConfirm(null)}
            />
        );
    };
    const renderClearAllConfirmationModal = () => {
        if (!clearAllConfirmation) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to clear all issues?"}
                        message={"This action cannot be reverted, are you sure that you need to clear all items?"}
                        onNo={() => setClearAllConfirmation(null)}
                        onYes={() => {}}
                        type="unassign"
                    />
                }
                onCancel={() => setClearAllConfirmation(null)}
            />
        );
    };

    const renderEditIssueModal = () => {
        if (!editIssue) return null;
        return <Portal body={<AddIssueModal title={editIssue?.title} onSubmit={handleEditIssueTitle} onCancel={() => setEditIssue(null)} />} />;
    };
    const getData = () =>
        !!filterBy
            ? getAllRecommendationReviewsResponse?.recommendation_reviews?.filter(({ status }) => status === filterBy)
            : getAllRecommendationReviewsResponse?.recommendation_reviews || [];
    const renderConfirmationModal = () => {
        if (!showConfirmation) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to delete this issue ?"}
                        message={"This action cannot be reverted, are you sure that you need to delete this item?"}
                        onNo={() => setShowConfirmation(null)}
                        onYes={() => dispatch(actions.deleteRecommendationReview(showConfirmation))}
                    />
                }
                onCancel={() => setShowConfirmation(null)}
            />
        );
    };

    const menuPlacement = inx => (getData()?.length > 9 && inx > getData()?.length - 3 ? "top" : "bottom");
    if (!showData) return <div className="tab-active qa-band" />;
    return (
        <div className="tab-active qa-band">
            <div className="dtl-sec col-md-12">
                {/* <div className="top-bar-search">
                    <input type="text" className="form-control" placeholder="Issue title" />
                    <button className="btn btn-add">
                        <i className="fas fa-plus"></i>
                    </button>
                </div> */}
                <div class="table-top-menu mb-2">
                    <div class="lft">
                        <div class="btn-edit-otr btn-main-outr">
                            <div class="text-label">
                                <label>Recommendation: {basicDetails?.description || "-"}</label>
                            </div>
                        </div>
                    </div>
                    <div class="rgt m-0 p-0 right-sort-sec">
                        <button
                            class="btn-qa"
                            onClick={() => {
                                popBreadCrumpOnPageClose();
                                history.push(findPrevPathFromBreadCrumpData());
                            }}
                        >
                            <i class="fas fa-window-close mr-1"></i>Close
                        </button>
                        {isQaQcReviewerUser && (
                            <>
                                <div className="rem-txt qa-passed">
                                    <ReactTooltip id="qa-pass-select" className="rc-tooltip-custom-class" />
                                    <label
                                        className="container-check"
                                        data-delay-show="500"
                                        data-tip={
                                            getAllRecommendationReviewsResponse?.recommendation_reviews?.some(({ status }) => status !== "approved")
                                                ? "Approve all issues to mark as QA Passed"
                                                : `Mark as ${getRecommendationByIdResponse?.qa_passed ? "not " : ""}QA Passed`
                                        }
                                        data-effect="solid"
                                        data-for="qa-pass-select"
                                        data-place="left"
                                        data-background-color="#007bff"
                                    >
                                        QA/QC Passed
                                        <input
                                            type="checkbox"
                                            disabled={getAllRecommendationReviewsResponse?.recommendation_reviews?.some(
                                                ({ status }) => status !== "approved"
                                            )}
                                            checked={getRecommendationByIdResponse?.qa_passed}
                                            onChange={() => setPassQaQcConfirm(true)}
                                        />
                                        <span className="checkmark" />
                                    </label>
                                </div>
                            </>
                        )}
                        <ReactTooltip id="filter-select" className="rc-tooltip-custom-class" globalEventOff="click" />
                        <div
                            className="categ-select-box wid-sel-bx-110 pr-1 sel-def-bx"
                            data-delay-show="500"
                            data-tip="Filter"
                            data-effect="solid"
                            data-for="filter-select"
                            data-place="left"
                            data-background-color="#007bff"
                        >
                            <ReactSelect
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={filterOptions.find(option => option.value === filterBy)}
                                onChange={({ value }) => {
                                    setFilterBy(value);
                                }}
                                options={filterOptions}
                                isSearchable={false}
                            />
                        </div>
                        <ReactTooltip id="send-all" className="rc-tooltip-custom-class" />
                        {isQaQcReviewerUser && (
                            <button
                                className="btn-qa active"
                                data-delay-show="500"
                                data-tip="Send reminder notification about all issues"
                                data-effect="solid"
                                data-for="send-all"
                                data-place="left"
                                data-background-color="#007bff"
                                onClick={handleSendAllNotification}
                            >
                                Send All
                                <img src="/img/send-view.svg" className="img-send" alt="" />
                            </button>
                        )}

                        <ReactTooltip id="filter-select-sort" className="rc-tooltip-custom-class" globalEventOff="click" />
                        <div className="sort-cls">
                            <i
                                className={`fas fa-long-arrow-alt-${sortOrder === "asc" ? "up" : "down"} text-danger topfilter-cursor`}
                                onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
                            />
                        </div>
                        <div
                            className="categ-select-box wid-sel-bx-110 pr-1 sel-def-bx"
                            data-delay-show="500"
                            data-tip="Sort By"
                            data-effect="solid"
                            data-for="filter-select-sort"
                            data-place="left"
                            data-background-color="#007bff"
                        >
                            <ReactSelect
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={sortOptions.find(option => option.value === sortby)}
                                onChange={({ value }) => {
                                    setSortby(value);
                                }}
                                options={sortOptions}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                </div>

                <div class="qa-band-outer-sec qa-table">
                    <div className="table-areas">
                        <div className="table-responsive">
                            {!getData()?.length && getAllRecommendationReviewsResponse?.success && (
                                <div className="table-topper efc-topr no-data-efci">
                                    <div class="col-md-12 otr-topr">
                                        <h3>No data found</h3>
                                    </div>
                                </div>
                            )}
                            <table className="table">
                                {getData()?.length ? (
                                    <thead>
                                        <tr>
                                            <th className="icon-th"> </th>
                                            <th className="query-txt">Name </th>
                                            <th className="text-center">Reviewer </th>

                                            {!selectedRow && <th className="text-center">Resolved Date & Time</th>}
                                            <th className="text-center">Added Date & Time</th>
                                            <th className="text-center">Status</th>
                                            <th className="query-cmt">Comments</th>
                                            {!selectedRow && isQaQcReviewerUser && <th className="text-center">Actions</th>}
                                        </tr>
                                    </thead>
                                ) : null}

                                <tbody>
                                    {getData()?.map((rowData, inx) => {
                                        const userId = localStorage.getItem("userId");
                                        const userRole = localStorage.getItem("role");

                                        const isReviewer = rowData?.requestor?.id === userId;
                                        const hasReviewerPermission = isReviewer || userRole === "super_admin";

                                        return (
                                            <QaQcRow
                                                rowData={rowData}
                                                key={rowData?.id}
                                                selectedRow={selectedRow}
                                                setSelectedRow={setSelectedRow}
                                                hasReviewerPermission={hasReviewerPermission}
                                                // onSubmit={handleEditIssueTitle} //line edit no longer needed
                                                onEdit={setEditIssue}
                                                handleRowClick={handleRowClicked}
                                                menuPlacement={menuPlacement(inx)}
                                                onStatusChange={handleEditIssueStatus}
                                                isQaQcReviewerUser={isQaQcReviewerUser}
                                                setShowConfirmation={setShowConfirmation}
                                                onSendNotification={handleSendReviewNotification}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {selectedRow && (
                        <SingleQaIssuePreview
                            selectedRow={selectedRow}
                            // onSubmit={handleEditIssueTitle} //line edit no longer needed
                            onEdit={setEditIssue}
                            onStatusChange={handleEditIssueStatus}
                            isQaQcReviewerUser={isQaQcReviewerUser}
                            onCommentSubmit={handleAddIssueComment}
                            setShowConfirmation={setShowConfirmation}
                            onSendNotification={handleSendReviewNotification}
                            onClose={() => setSelectedRow(null)}
                        />
                    )}
                </div>
            </div>
            {renderClearAllConfirmationModal()}
            {renderConfirmationModal()}
            {renderPassConfirmationModal()}
            {renderEditIssueModal()}
        </div>
    );
}

export default QaQcBand;
