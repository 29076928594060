import React, { useState } from "react";
import Draggable from "react-draggable";

import { RECOMMENDATION_BANDS } from "../constants";

const OnePagerExportModal = ({
    onCancel,
    exportRecommendation,
    currentRecomData,
    showDynamicOption = true,
    exportButton = false,
    heading = null,
    NarrativeText = false
}) => {
    const [selectedBands, setSelectedBands] = useState([1]);
    const [simpleNarrativeText, setSimpleNarrativeText] = useState(true);

    const handleSelectBand = key => {
        let tempSelectedBands = [...selectedBands];
        if (tempSelectedBands.includes(key)) {
            tempSelectedBands = tempSelectedBands.filter(b => b !== key);
        } else {
            tempSelectedBands.push(key);
        }
        setSelectedBands([...tempSelectedBands]);
    };

    const handleExport = type => {
        exportRecommendation(
            { ...currentRecomData, dynamic_field_check_box: selectedBands.length ? true : false, simple_text: simpleNarrativeText },
            type
        );
    };

    return (
        <React.Fragment>
            <div className="modal modal-region modal-view inbox-modal" id="modalId" tabIndex="-1" style={{ display: "block" }}>
                <Draggable handle=".modal-header">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    <div className="txt-hed">{heading ? heading : "Recommendation One Pager Export"}</div>
                                </h5>
                                <button type="button" className="close" onClick={onCancel}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body region-otr sm-chart-upload">
                                <div className="upload-area not-draggable">
                                    <div className="upload-sec cursor-hand" role="button" tabIndex="0">
                                        <input type="file" multiple="" autocomplete="off" tabIndex="-1" style={{ display: "none" }} />
                                    </div>
                                </div>

                                <div className="col-md-12 main-sec">
                                    <>
                                        {showDynamicOption &&
                                            RECOMMENDATION_BANDS.map((band, index) => (
                                                <div className="form-row" key={index}>
                                                    <div className="form-group col-12">
                                                        <label className="container-check re-check-container">
                                                            {band.label}
                                                            <input
                                                                type="radio"
                                                                onClick={e => handleSelectBand(band.key)}
                                                                checked={selectedBands.includes(band.key)}
                                                            />
                                                            <span className="checkmark white" />
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        <div className="form-row">
                                            {!NarrativeText && <h4 style={{ textTransform: "capitalize" }}> Executive Summary</h4>}
                                            <div class="form-group check-container-outer col-12 mb-1 mt-1 d-flex ">
                                                <label class="container-check re-check-container mr-4">
                                                    {NarrativeText ? "Simple Narrative Text" : "Simple Text"}
                                                    <input
                                                        type="checkbox"
                                                        checked={simpleNarrativeText}
                                                        onChange={e => setSimpleNarrativeText(true)}
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="container-check re-check-container mr-4">
                                                    {NarrativeText ? "Rich Narrative Text" : "Rich Text"}
                                                    <input
                                                        type="checkbox"
                                                        checked={!simpleNarrativeText}
                                                        onChange={e => setSimpleNarrativeText(false)}
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </>
                                </div>

                                <div className="btn-sec">
                                    <div className="text-right btnOtr edit-cmn-btn mt-3">
                                        <button
                                            type="button"
                                            className="btn btn-secondary btnClr btn-cancel-one-pager"
                                            data-dismiss="modal"
                                            onClick={() => onCancel()}
                                        >
                                            Cancel
                                        </button>
                                        {exportButton ? (
                                            <button type="button" className="btn btn-create save" onClick={() => handleExport("word")}>
                                                Export
                                            </button>
                                        ) : (
                                            <>
                                                <button type="button" className="btn btn-create save" onClick={() => handleExport("word")}>
                                                    Export to Word
                                                </button>
                                                <button type="button" className="btn btn-create save mr-1" onClick={() => handleExport("pdf")}>
                                                    Export to PDF
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
        </React.Fragment>
    );
};

export default OnePagerExportModal;
