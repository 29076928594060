import { fcaReportGateway } from "../../services/authorizationService";

class MyUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }
    upload() {
        return new Promise((resolve, reject) => {
            const data = new FormData();
            this.loader.file.then(file => {
                data.append("file", file);
                fcaReportGateway
                    .post("/smart-chart/property/image_upload/", data)
                    .then(result => {
                        resolve({
                            default: result?.data.file_name
                        });
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        });
    }
    abort() {}
}

export function MyUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
        return new MyUploadAdapter(loader);
    };
}
