export const MASTER_FILTER_KEYS = [
    { label: "Clients", key: "clients", paramKey: "client_ids" },
];

export const GET_REPORTS_REQUEST = "GET_REPORTS_REQUEST";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAILURE = "GET_REPORTS_FAILURE";

export const UPDATE_REPORT_ENTITY_PARAMS_SUCCESS="UPDATE_REPORT_ENTITY_PARAMS_SUCCESS"
export const UPDATE_REPORT_ENTITY_PARAMS_FAILURE="UPDATE_REPORT_ENTITY_PARAMS_FAILURE"

// Add floor
export const ADD_REPORTS_REQUEST = "ADD_REPORTS_REQUEST";
export const ADD_REPORTS_SUCCESS = "ADD_REPORTS_SUCCESS";
export const ADD_REPORTS_FAILURE = "ADD_REPORTS_FAILURE";

// Update floor
export const UPDATE_REPORTS_REQUEST = "UPDATE_REPORTS_REQUEST";
export const UPDATE_REPORTS_SUCCESS = "UPDATE_REPORTS_SUCCESS";
export const UPDATE_REPORTS_FAILURE = "UPDATE_REPORTS_FAILURE";

// Delete floor
export const DELETE_REPORTS_REQUEST = "DELETE_REPORTS_REQUEST";
export const DELETE_REPORTS_SUCCESS = "DELETE_REPORTS_SUCCESS";
export const DELETE_REPORTS_FAILURE = "DELETE_REPORTS_FAILURE";

// Get Document by Id
export const GET_REPORTS_BY_ID_REQUEST = "GET_REPORTS_BY_ID_REQUEST";
export const GET_REPORTS_BY_ID_SUCCESS = "GET_REPORTS_BY_ID_SUCCESS";
export const GET_REPORTS_BY_ID_FAILURE = "GET_REPORTS_BY_ID_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_REPORTS_LOG_REQUEST = "GET_ALL_REPORTS_LOG_REQUEST";
export const GET_ALL_REPORTS_LOG_SUCCESS = "GET_ALL_REPORTS_LOG_SUCCESS";
export const GET_ALL_REPORTS_LOG_FAILURE = "GET_ALL_REPORTS_LOG_FAILURE";

export const RESTORE_REPORTS_LOG_REQUEST = "RESTORE_REPORTS_LOG_REQUEST";
export const RESTORE_REPORTS_LOG_SUCCESS = "RESTORE_REPORTS_LOG_SUCCESS";
export const RESTORE_REPORTS_LOG_FAILURE = "RESTORE_REPORTS_LOG_FAILURE";

export const DELETE_REPORTS_LOG_REQUEST = "DELETE_REPORTS_LOG_REQUEST";
export const DELETE_REPORTS_LOG_SUCCESS = "DELETE_REPORTS_LOG_SUCCESS";
export const DELETE_REPORTS_LOG_FAILURE = "DELETE_REPORTS_LOG_FAILURE";

export const GET_REPORTS_EXPORT_REQUEST = "GET_REPORTS_EXPORT_REQUEST";
export const GET_REPORTS_EXPORT_SUCCESS = "GET_REPORTS_EXPORT_SUCCESS";
export const GET_REPORTS_EXPORT_FAILURE = "GET_REPORTS_EXPORT_FAILURE";


export const GET_INITIATIVE_DROP_DOWN_REQUEST = "GET_INITIATIVE_DROP_DOWN_REQUEST";
export const GET_INITIATIVE_DROP_DOWN_SUCCESS = "GET_INITIATIVE_DROP_DOWN_SUCCESS";
export const GET_INITIATIVE_DROP_DOWN_FAILURE = "GET_INITIATIVE_DROP_DOWN_FAILURE";

export const GET_RECOMMENDATION_DROP_DOWN_REQUEST = "GET_RECOMMENDATION_DROP_DOWN_REQUEST";
export const GET_RECOMMENDATION_DROP_DOWN_SUCCESS = "GET_RECOMMENDATION_DROP_DOWN_SUCCESS";
export const GET_RECOMMENDATION_DROP_DOWN_FAILURE = "GET_RECOMMENDATION_DROP_DOWN_FAILURE";

export const GET_ALL_REGION_DROPDOWN_REQUEST = "GET_ALL_REGION_DROPDOWN_REQUEST";
export const GET_ALL_REGION_DROPDOWN_SUCCESS = "GET_ALL_REGION_DROPDOWN_SUCCESS";
export const GET_ALL_REGION_DROPDOWN_FAILURE = "GET_ALL_REGION_DROPDOWN_FAILURE";

export const GET_ALL_DOCUMENTS_TYPE_REQUEST = "GET_ALL_DOCUMENTS_TYPE_REQUEST";
export const GET_ALL_DOCUMENTS_TYPE_SUCCESS = "GET_ALL_DOCUMENTS_TYPE_SUCCESS";
export const GET_ALL_DOCUMENTS_TYPE_FAILURE= "GET_ALL_DOCUMENTS_TYPE_FAILURE";

export const GET_SITES_BY_REGION_IN_DOCUMENTS_REQUEST = "GET_SITES_BY_REGION_IN_DOCUMENTS_REQUEST";
export const GET_SITES_BY_REGION_IN_DOCUMENTS_SUCCESS = "GET_SITES_BY_REGION_IN_DOCUMENTS_SUCCESS";
export const GET_SITES_BY_REGION_IN_DOCUMENTS_FAILURE = "GET_SITES_BY_REGION_IN_DOCUMENTS_FAILURE";

export const GET_DOCUMENT_MASTER_FILTER_LISTS_REQUEST = "GET_DOCUMENT_MASTER_FILTER_LISTS_REQUEST";
export const GET_DOCUMENT_MASTER_FILTER_LISTS_SUCCESS = "GET_DOCUMENT_MASTER_FILTER_LISTS_SUCCESS";
export const GET_DOCUMENT_MASTER_FILTER_LISTS_FAILURE = "GET_DOCUMENT_MASTER_FILTER_LISTS_FAILURE";