import React, { useState } from "react";
import Draggable from "react-draggable";

import { IMAGE_BAND_CONFIG_TYPES } from "../constants";

const ImageBandConfigModal = ({ setSmartChartData, configData = {}, onCancel, isView = false }) => {
    const [selectedImageBandConfig, setSelectedImageBandConfig] = useState(configData?.selectedChartConfig?.config?.type || null);

    const updateImageBandConfig = () => {
        setSmartChartData("config_image_band", {
            ...configData,
            imageBandConfig: { config: { type: selectedImageBandConfig } }
        });
        onCancel();
    };

    return (
        <div
            className="modal modal-region smart-chart-popup smart-dtl-pop add-image-modal img-pop-sing"
            id="modalId"
            tabindex="-1"
            style={{ display: "block" }}
        >
            <Draggable handle=".draggable" cancel=".non-draggable">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header draggable">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <div className="txt-hed">Image Band Configuration</div>
                            </h5>
                            <button type="button" className="close non-draggable" onClick={() => onCancel()}>
                                <span aria-hidden="true">
                                    <img src="/img/close.svg" alt="" />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body region-otr ">
                            <div className="img-repr col-12 d-flex">
                                {IMAGE_BAND_CONFIG_TYPES.map((configType, i) => (
                                    <div className={`img-rep-otr d-flex ${configType.key === 3 ? "col-12" : "col-6"}`} key={i}>
                                        <div className={`img-rep-item col-md-12 ${configType.key === 3 ? "mt-3" : ""}`}>
                                            <div className="top-sec justify-content-end">
                                                <div className="checkbox">
                                                    <label className={`container-check ${isView ? "cursor-diabled" : ""}`}>
                                                        <input
                                                            type="checkbox"
                                                            name="is_bold"
                                                            checked={selectedImageBandConfig === configType.key}
                                                            onClick={e =>
                                                                !isView && setSelectedImageBandConfig(!e.target.checked ? null : configType.key)
                                                            }
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="cont-sec pl-0">
                                                <div className="img-sec">
                                                    <img src={configType.icon} />
                                                </div>
                                                <h3>{configType.label}</h3>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="footer-otr col-12">
                                <div className="btn-otrr">
                                    {!isView ? (
                                        <button className="btn-img-rpt mr-2" onClick={() => updateImageBandConfig()}>
                                            Save
                                        </button>
                                    ) : null}
                                    <button className="btn-cancel" onClick={() => onCancel()}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    );
};

export default ImageBandConfigModal;
