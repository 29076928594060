export const GET_ASSET_DATA_REQUEST = "GET_ASSET_DATA_REQUEST";
export const GET_ASSET_DATA_SUCCESS = "GET_ASSET_DATA_SUCCESS";
export const GET_ASSET_DATA_FAILURE = "GET_ASSET_DATA_FAILURE";

export const ADD_DATA_REQUEST = "ADD_DATA_REQUEST";
export const ADD_DATA_SUCCESS = "ADD_DATA_SUCCESS";
export const ADD_DATA_FAILURE = "ADD_DATA_FAILURE";

export const GET_DATA_BY_ID_REQUEST = "GET_DATA_BY_ID_REQUEST";
export const GET_DATA_BY_ID_SUCCESS = "GET_DATA_BY_ID_SUCCESS";
export const GET_DATA_BY_ID_FAILURE = "GET_DATA_BY_ID_FAILURE";

export const UPDATE_DATA_REQUEST = "UPDATE_DATA_REQUEST";
export const UPDATE_DATA_SUCCESS = "UPDATE_DATA_SUCCESS";
export const UPDATE_DATA_FAILURE = "UPDATE_DATA_FAILURE";

export const UPDATE_ASSET_ENTITY_PARAMS_SUCCESS = "UPDATE_ASSET_ENTITY_PARAMS_SUCCESS";
export const UPDATE_ASSET_ENTITY_PARAMS_FAILURE = "UPDATE_ASSET_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_RECOMMENDATION_ASSIGNED_COUNT_REQUEST = "GET_RECOMMENDATION_ASSIGNED_COUNT_REQUEST";
export const GET_RECOMMENDATION_ASSIGNED_COUNT_SUCCESS = "GET_RECOMMENDATION_ASSIGNED_COUNT_SUCCESS";
export const GET_RECOMMENDATION_ASSIGNED_COUNT_FAILURE = "GET_RECOMMENDATION_ASSIGNED_COUNT_FAILURE";

export const GET_ALL_DATA_LOG_REQUEST = "GET_ALL_DATA_LOG_REQUEST";
export const GET_ALL_DATA_LOG_SUCCESS = "GET_ALL_DATA_LOG_SUCCESS";
export const GET_ALL_DATA_LOG_FAILURE = "GET_ALL_DATA_LOG_FAILURE";

export const RESTORE_DATA_LOG_REQUEST = "RESTORE_DATA_LOG_REQUEST";
export const RESTORE_DATA_LOG_SUCCESS = "RESTORE_DATA_LOG_SUCCESS";
export const RESTORE_DATA_LOG_FAILURE = "RESTORE_DATA_LOG_FAILURE";

export const DELETE_DATA_LOG_REQUEST = "DELETE_DATA_LOG_REQUEST";
export const DELETE_DATA_LOG_SUCCESS = "DELETE_DATA_LOG_SUCCESS";
export const DELETE_DATA_LOG_FAILURE = "DELETE_DATA_LOG_FAILURE";

export const GET_DATA_EXPORT_REQUEST = "GET_DATA_EXPORT_REQUEST";
export const GET_DATA_EXPORT_SUCCESS = "GET_DATA_EXPORT_SUCCESS";
export const GET_DATA_EXPORT_FAILURE = "GET_DATA_EXPORT_FAILURE";

export const DELETE_DATA_REQUEST = "DELETE_DATA_REQUEST";
export const DELETE_DATA_SUCCESS = "DELETE_DATA_SUCCESS";
export const DELETE_DATA_FAILURE = "DELETE_DATA_FAILURE";

export const GET_DROPDOWN_REQUEST = "GET_DROPDOWN_REQUEST";
export const GET_DROPDOWN_SUCCESS = "GET_DROPDOWN_SUCCESS";
export const GET_DROPDOWN_FAILURE = "GET_DROPDOWN_FAILURE";

export const GET_ALL_IMAGES = "GET_ALL_IMAGES";
export const GET_ALL_IMAGES_SUCCESS = " GET_ALL_IMAGES_SUCCESS";
export const GET_ALL_IMAGES_FAILURE = "GET_ALL_IMAGES_FAILURE";

// Upload Image
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

export const UPDATE_ASSET_IMAGE_REQUEST = "UPDATE_ASSET_IMAGE_REQUEST";
export const UPDATE_ASSET_IMAGE_SUCCESS = "UPDATE_ASSET_IMAGE_SUCCESS";
export const UPDATE_ASSET_IMAGE_FAILURE = "UPDATE_ASSET_IMAGE_FAILURE";

export const DELETE_IMAGES_REQUEST = "DELETE_IMAGES_REQUEST";
export const DELETE_IMAGES_SUCCESS = "DELETE_IMAGES_SUCCESS";
export const DELETE_IMAGES_FAILURE = "DELETE_IMAGES_FAILURE";

export const UPDATE_SELECTED_ASSET_SUCCESS = "UPDATE_SELECTED_ASSET_SUCCESS";
export const UPDATE_SELECTED_ASSET_FAILURE = "UPDATE_SELECTED_ASSET_FAILURE";

export const UPDATE_ASSET_SCROLL_POSITION_SUCCESS = "UPDATE_ASSET_SCROLL_POSITION_SUCCESS";
export const UPDATE_ASSET_SCROLL_POSITION_FAILURE = "UPDATE_ASSET_SCROLL_POSITION_FAILURE";

export const EXPORT_ASSET_REPORT_PDF_REQUEST = "EXPORT_ASSET_REPORT_PDF_REQUEST";

export const ASSET_BANDS = [
    { key: 1, label: "Basic Asset Information" },
    { key: 2, label: "Geo Hierarchy" },
    { key: 3, label: "Extended Asset Information" },
    { key: 4, label: "Asset QA/QC Status" },
    { key: 5, label: "Asset Parent/Child Hierarchy" },
    { key: 6, label: "Asset Additional" },
    { key: 7, label: "Boiler Fields" },
    { key: 8, label: "Auxiliary Equipment Fields" },
    { key: 9, label: "Asset Images" }
];

export const UPLOAD_EXCEL_REQUEST = "UPLOAD_EXCEL_REQUEST";
export const UPLOAD_EXCEL_SUCCESS = "UPLOAD_EXCEL_SUCCESS";
export const UPLOAD_EXCEL_FAILURE = "UPLOAD_EXCEL_FAILURE";
export const CLEAR_EXCEL_DATA = "CLEAR_EXCEL_DATA";

export const GET_ASSET_FILTER_REQUEST = "GET_ASSET_FILTER_REQUEST";
export const GET_ASSET_FILTER_SUCCESS = "GET_ASSET_FILTER_SUCCESS";
export const GET_ASSET_FILTER_FAILURE = "GET_ASSET_FILTER_FAILURE";

export const EXPORT_FETCH_PROPERTIES_REQUEST = "EXPORT_FETCH_PROPERTIES_REQUEST";
export const EXPORT_FETCH_PROPERTIES_SUCCESS = "EXPORT_FETCH_PROPERTIES_SUCCESS";
export const EXPORT_FETCH_PROPERTIES_FAILURE = "EXPORT_FETCH_PROPERTIES_FAILURE";

export const EXPORT_POST_PROPERTIES_REQUEST = "EXPORT_POST_PROPERTIES_REQUEST";
export const EXPORT_POST_PROPERTIES_SUCCESS = "EXPORT_POST_PROPERTIES_SUCCESS";
export const EXPORT_POST_PROPERTIES_FAILURE = "EXPORT_POST_PROPERTIES_FAILURE";

export const GET_WHOLE_ASSETS_IDS_REQUEST = "GET_WHOLE_ASSETS_IDS_REQUEST";
export const GET_WHOLE_ASSETS_IDS_SUCCESS = "GET_WHOLE_ASSETS_IDS_SUCCESS";
export const GET_WHOLE_ASSETS_IDS_FAILURE = "GET_WHOLE_ASSETS_IDS_FAILURE";

export const GET_ASSET_COMMON_DATA_BY_IDS_REQUEST = "GET_ASSET_COMMON_DATA_BY_IDS_REQUEST";
export const GET_ASSET_COMMON_DATA_BY_IDS_SUCCESS = "GET_ASSET_COMMON_DATA_BY_IDS_SUCCESS";
export const GET_ASSET_COMMON_DATA_BY_IDS_FAILURE = "GET_ASSET_COMMON_DATA_BY_IDS_FAILURE";
export const RESET_ASSET_COMMON_DATA = "RESET_ASSET_COMMON_DATA";

export const UPDATE_MULTI_ASSET_REQUEST = "UPDATE_MULTI_ASSET_REQUEST";
export const UPDATE_MULTI_ASSET_SUCCESS = "UPDATE_MULTI_ASSET_SUCCESS";
export const UPDATE_MULTI_ASSET_FAILURE = "UPDATE_MULTI_ASSET_FAILURE";

export const GET_ASSET_DYNAMIC_FIELDS_REQUEST = "GET_ASSET_DYNAMIC_FIELDS_REQUEST";
export const GET_ASSET_DYNAMIC_FIELDS_SUCCESS = "GET_ASSET_DYNAMIC_FIELDS_SUCCESS";
export const GET_ASSET_DYNAMIC_FIELDS_FAILURE = "GET_ASSET_DYNAMIC_FIELDS_FAILURE";

export const SAVE_FAVORITES_REQUEST = "SAVE_FAVORITES_REQUEST";
export const SAVE_FAVORITES_SUCCESS = "SAVE_FAVORITES_SUCCESS";
export const SAVE_FAVORITES_FAILURE = "SAVE_FAVORITES_FAILURE";

export const GET_FAVORITES_REQUEST = "GET_FAVORITES_REQUEST";
export const GET_FAVORITES_SUCCESS = "GET_FAVORITES_SUCCESS";
export const GET_FAVORITES_FAILURE = "GET_FAVORITES_FAILURE";
