// Send login email
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// login python
export const LOGIN_REQUEST_PYTHON = "LOGIN_REQUEST_PYTHON";
export const LOGIN_SUCCESS_PYTHON = "LOGIN_SUCCESS_PYTHON";
export const LOGIN_FAILURE_PYTHON = "LOGIN_FAILURE_PYTHON";

// Logout user
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
// Logout user
export const LOGOUT_REVOKE_REQUEST = "LOGOUT_REVOKE_REQUEST";
export const LOGOUT_REVOKE_SUCCESS = "LOGOUT_REVOKE_SUCCESS";
export const LOGOUT_REVOKE_FAILURE = "LOGOUT_REVOKE_FAILURE";

// forgot password
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

// Logout user
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const COGNITO_LOGIN_OTP_REQUEST = "COGNITO_LOGIN_OTP_REQUEST";
export const COGNITO_LOGIN_OTP_SUCCESS = "COGNITO_LOGIN_OTP_SUCCESS";
export const COGNITO_LOGIN_OTP_FAILURE = "COGNITO_LOGIN_OTP_FAILURE";

// validate token
export const VALIDATE_TOKEN_REQUEST = "VALIDATE_TOKEN_REQUEST";
export const VALIDATE_TOKEN_SUCCESS = "VALIDATE_TOKEN_SUCCESS";
export const VALIDATE_TOKEN_FAILURE = "VALIDATE_TOKEN_FAILURE";

export const LOGIN_PASSWORD_RESET = "LOGIN_PASSWORD_RESET";
