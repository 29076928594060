import React from "react";
import { InputBox } from "../../common/components/formFields/InputBox";
import { SelectBox } from "../../common/components/formFields/SelectBox";
import { dropdownValues, fields } from "../constants";
import moment from "moment";
import { NumberBox } from "../../common/components/formFields/NumberBox";
import useCapitalRequestForm from "./useCapitalRequestForm";
import Portal from "../../common/components/Portal";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import { RadioBox } from "../../common/components/formFields/RadioBox";
import { TextArea } from "../../common/components/formFields/TextArea";
import { RichEditorPopup } from "../../common/components/formFields/RichEditorPopup";

export const CapitalRequestForm = ({ isProjectView }) => {
    const {
        data,
        handleSubmit,
        handleCancel,
        handleChange,
        setData,
        showConfirmModal,
        setShowConfirmModal,
        cancelForm,
        isSubmitting,
        editMode,
        richTextPopup,
        setShowRichTextPopup,
        errorParams,
        showErrorBorder
    } = useCapitalRequestForm({ isProjectView });

    const renderConfirmationModal = () => {
        if (!showConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading="Do you want to clear and lose all changes?"
                        message="This action cannot be reverted, are you sure that you need to cancel?"
                        onNo={() => setShowConfirmModal(false)}
                        onYes={cancelForm}
                    />
                }
                onCancel={() => setShowConfirmModal(false)}
            />
        );
    };
    const renderRichTextPopup = () => {
        const { show, value, label, key, onSaveData } = richTextPopup;
        if (!show) return null;
        return (
            <Portal
                body={<RichEditorPopup value={value} label={label} key={key} onCancel={() => setShowRichTextPopup({})} saveData={onSaveData} />}
                onCancel={() => setShowRichTextPopup({})}
            />
        );
    };
    return (
        <div className="dtl-sec col-md-12 mb-2">
            {renderConfirmationModal()}
            {renderRichTextPopup()}
            <div className="tab-dtl region-mng">
                <form autoComplete={"nope"}>
                    <div className="tab-active build-dtl">
                        <div className="otr-common-edit custom-col  border-0 p-0">
                            <div className="basic-otr">
                                <div className="basic-dtl-otr cr-outer basic-sec">
                                    <div className="otr-asset-grp">
                                        {Object.keys(fields)
                                            // filtering edit only fields
                                            .filter(fieldKey => (editMode ? fieldKey : !fields[fieldKey]?.inEditOnly))
                                            //filtering admin only fields
                                            .filter(fieldKey => (isProjectView ? fieldKey : !fields[fieldKey]?.adminOnly))
                                            .map(key => (
                                                <>
                                                    {fields[key].type === "text" ? (
                                                        <InputBox
                                                            label={`${fields[key].label} ${fields[key]?.isRequired ? "*" : ""}`}
                                                            placeholder="Type Here..."
                                                            name={key}
                                                            value={data[key]}
                                                            type="text"
                                                            handleChange={handleChange}
                                                            isDisabled={fields[key]?.readOnly}
                                                            showErrorBorder={showErrorBorder && errorParams[key]}
                                                        />
                                                    ) : fields[key].type === "textArea" ? (
                                                        <TextArea
                                                            label={`${fields[key].label} ${fields[key]?.isRequired ? "*" : ""}`}
                                                            placeholder="Type Here..."
                                                            name={key}
                                                            value={data[key]}
                                                            type="text"
                                                            isRichText={fields[key]?.isRichText}
                                                            handleChange={value => setData({ ...data, [key]: value })}
                                                            handleClickExpandView={e => {
                                                                e.preventDefault();
                                                                setShowRichTextPopup({
                                                                    show: true,
                                                                    label: fields[key].label,
                                                                    value: data[key],
                                                                    key,
                                                                    onSaveData: value => {
                                                                        setShowRichTextPopup({});
                                                                        setData({ ...data, [key]: value });
                                                                    }
                                                                });
                                                            }}
                                                            showErrorBorder={showErrorBorder && errorParams[key]}
                                                        />
                                                    ) : fields[key].type === "amount" ? (
                                                        <NumberBox
                                                            label={`${fields[key].label} ${fields[key]?.isRequired ? "*" : ""}`}
                                                            placeholder="Type Here..."
                                                            name={key}
                                                            value={data[key]}
                                                            type="text"
                                                            prefix="$ "
                                                            hasThousandSeparator={true}
                                                            handleChange={(key, value) => setData({ ...data, [key]: value })}
                                                            showErrorBorder={showErrorBorder && errorParams[key]}
                                                        />
                                                    ) : fields[key].type === "dropdown" ? (
                                                        <SelectBox
                                                            label={`${fields[key].label} ${fields[key]?.isRequired ? "*" : ""}`}
                                                            name={key}
                                                            value={data[key]}
                                                            handleChange={e => setData({ ...data, [e.target.name]: parseInt(e.target.value) })}
                                                            optionsList={dropdownValues[key]}
                                                            showErrorBorder={showErrorBorder && errorParams[key]}
                                                        />
                                                    ) : fields[key].type === "radio" ? (
                                                        <RadioBox
                                                            label={`${fields[key].label} ${fields[key]?.isRequired ? "*" : ""}`}
                                                            name={key}
                                                            value={data[key]}
                                                            handleChange={(key, value) => setData({ ...data, [key]: value })}
                                                            optionsList={dropdownValues.radio_button}
                                                            showErrorBorder={showErrorBorder && errorParams[key]}
                                                        />
                                                    ) : fields[key].type === "date" ? (
                                                        <InputBox
                                                            label={`${fields[key].label} ${fields[key]?.isRequired ? "*" : ""}`}
                                                            name={key}
                                                            value={moment(data[key]).format("MM/DD/YYYY")}
                                                            type="text"
                                                            isDisabled
                                                        />
                                                    ) : null}
                                                </>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-right btnOtr edit-cmn-btn">
                            <span className="errorMessage">{showErrorBorder ? Object.values(errorParams)[0] : ""}</span>
                            <button type="button" className="btn btn-secondary btnClr col-md-2 mr-1" data-dismiss="modal" onClick={handleCancel}>
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary btnRgion col-md-2" disabled={isSubmitting} onClick={handleSubmit}>
                                {editMode ? "Update" : "Submit"}
                                {isSubmitting ? <span className="spinner-border spinner-border-sm pl-2 ml-1" role="status"></span> : null}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
