import React from "react";
import ReactTooltip from "react-tooltip";
export const InputBox = ({ placeholder, label, name, value, handleChange, type, showErrorBorder, isDisabled = false, col = 3, dataTip }) => {
    return (
        <div className={`col-md-${col} basic-box`}>
            <ReactTooltip id="input_field" effect="solid" backgroundColor="#007bff" place="top" />
            <div className="codeOtr" data-tip={dataTip} data-for="input_field">
                <h4>{label}</h4>
                <input
                    autoComplete={"off"}
                    type={type}
                    className={`${showErrorBorder ? "error-border " : ""} ${isDisabled ? "cursor-diabled" : ""} custom-input form-control`}
                    value={value}
                    name={name}
                    onChange={handleChange}
                    placeholder={placeholder || label.replace("*", "")}
                    disabled={isDisabled}
                />
            </div>
        </div>
    );
};
