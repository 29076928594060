export const ADD_LIMIT_REQUEST = "ADD_LIMIT_REQUEST";
export const ADD_LIMIT_SUCCESS = "ADD_LIMIT_SUCCESS";
export const ADD_LIMIT_FAILURE = "ADD_LIMIT_FAILURE";

export const GET_ADD_LIMIT_REQUEST = "GET_ADD_LIMIT_REQUEST";
export const GET_ADD_LIMIT_SUCCESS = "GET_ADD_LIMIT_SUCCESS";
export const GET_ADD_LIMIT_FAILURE = "GET_ADD_LIMIT_FAILURE";

export const GET_GENERAL_BY_ID_REQUEST = "GET_GENERAL_BY_ID_REQUEST";
export const GET_GENERAL_BY_ID_SUCCESS = "GET_GENERAL_BY_ID_SUCCESS";
export const GET_GENERAL_BY_ID_FAILURE = "GET_GENERAL_BY_ID_FAILURE";

export const UPDATE_GENERAL_REQUEST = "UPDATE_GENERAL_REQUEST";
export const UPDATE_GENERAL_SUCCESS = "UPDATE_GENERAL_SUCCESS";
export const UPDATE_GENERAL_FAILURE = "UPDATE_GENERAL_FAILURE";

export const DELETE_GENERAL_REQUEST = "DELETE_GENERAL_REQUEST";
export const DELETE_GENERAL_SUCCESS = "DELETE_GENERAL_SUCCESS";
export const DELETE_GENERAL_FAILURE = "DELETE_GENERAL_FAILURE";

// Update Floor Entity Param
export const UPDATE_GENERAL_ENTITY_PARAMS_SUCCESS = "UPDATE_GENERAL_ENTITY_PARAMS_SUCCESS";
export const UPDATE_GENERAL_ENTITY_PARAMS_FAILURE = "UPDATE_GENERAL_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_GENERAL_LOG_REQUEST = "GET_ALL_GENERAL_LOG_REQUEST";
export const GET_ALL_GENERAL_LOG_SUCCESS = "GET_ALL_GENERAL_LOG_SUCCESS";
export const GET_ALL_GENERAL_LOG_FAILURE = "GET_ALL_GENERAL_LOG_FAILURE";

export const RESTORE_SETTINGS_LOG_REQUEST = "RESTORE_SETTINGS_LOG_REQUEST";
export const RESTORE_SETTINGS_LOG_SUCCESS = "RESTORE_SETTINGS_LOG_SUCCESS";
export const RESTORE_SETTINGS_LOG_FAILURE = "RESTORE_SETTINGS_LOG_FAILURE";

export const DELETE_SETTINGS_LOG_REQUEST = "DELETE_SETTINGS_LOG_REQUEST";
export const DELETE_SETTINGS_LOG_SUCCESS = "DELETE_SETTINGS_LOG_SUCCESS";
export const DELETE_SETTINGS_LOG_FAILURE = "DELETE_SETTINGS_LOG_FAILURE";

export const GET_GENERAL_EXPORT_REQUEST = "GET_GENERAL_EXPORT_REQUEST";
export const GET_GENERAL_EXPORT_SUCCESS = "GET_GENERAL_EXPORT_SUCCESS";
export const GET_GENERAL_EXPORT_FAILURE = "GET_GENERAL_EXPORT_FAILURE";

export const GET_REGION_LIST_REQUEST = "GET_REGION_LIST_REQUEST";
export const GET_REGION_LIST_SUCCESS = "GET_REGION_LIST_SUCCESS";
export const GET_REGION_LIST_FAILURE = "GET_REGION_LIST_FAILURE";

export const GET_SITE_LIST_REQUEST = "GET_SITE_LIST_REQUEST";
export const GET_SITE_LIST_SUCCESS = "GET_SITE_LIST_SUCCESS";
export const GET_SITE_LIST_FAILURE = "GET_SITE_LIST_FAILURE";
