import React, { useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import action from "../actions";

const SaveFilterModal = ({ onCancel, templateName = "", onFilterSave }) => {
    const [showErrorBorder, setShowErrorBorder] = useState(false);
    const [FilterData, setFilterData] = useState({
        name: templateName,
        notes: "",
        project: null,
        user: null,
        created_by: null
    });
    const { getProjectByIdResponse } = useSelector(state => state.projectReducer);

    const validate = () => {
        setShowErrorBorder(false);
        if (!FilterData.name && !FilterData.name.trim().length) {
            setShowErrorBorder(true);
            return false;
        }
        return true;
    };
    const submitSaveFilter = () => {
        if (validate()) {
            let filterValue = { ...FilterData };
            filterValue = {
                ...FilterData,
                project: getProjectByIdResponse?.id,
                client: getProjectByIdResponse?.client?.id,
                created_by: localStorage.getItem("userId")
                // user: localStorage.getItem("userId")
            };
            setFilterData(filterValue);
            onFilterSave(filterValue);
        }
    };
    return (
        <>
            <div className="modal modal-region modal-view inbox-modal" id="modalId" tabIndex="-1" style={{ display: "block" }}>
                <Draggable handle=".draggable" cancel=".non-draggable">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    <div className="txt-hed">Save Filter</div>
                                </h5>
                                <button type="button" className="close non-draggable" onClick={onCancel}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className={`modal-body region-otr sm-chart-upload`}>
                                <div className="col-md-12 main-sec">
                                    <div className="form-row">
                                        <div className="form-group col-12 mb-4">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                value={FilterData.name}
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    setFilterData(prevParams => {
                                                        return {
                                                            ...prevParams,
                                                            name: value
                                                        };
                                                    });
                                                }}
                                                className={`form-control ${
                                                    showErrorBorder && !FilterData.name && !FilterData.name.trim().length ? "error-border" : ""
                                                }`}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-12 mb-4">
                                            <label>Notes</label>
                                            <textarea
                                                className="form-control textarea"
                                                value={FilterData.notes}
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    setFilterData(prevParams => {
                                                        return {
                                                            ...prevParams,
                                                            notes: value
                                                        };
                                                    });
                                                }}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-sec">
                                    <div className="text-right btnOtr edit-cmn-btn">
                                        <button
                                            type="button"
                                            className="btn btn-primary btnRgion "
                                            class="btn btn-create save"
                                            onClick={() => submitSaveFilter()}
                                        >
                                            Create
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btnClr mr-1"
                                            data-dismiss="modal"
                                            onClick={() => onCancel()}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
        </>
    );
};

export default SaveFilterModal;
