import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import { useParams } from "react-router-dom";
import actions from "../../recommendations/actions";

function AddIssueModal({ onCancel, onSubmit, title }) {
    const [issueTitle, setIssueTitle] = useState(title || "");
    const [isErr, setIsErr] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const { id, tab } = useParams();
    const { addQaQcIssueResponse } = useSelector(state => state.recommendationsReducer) || {};

    useEffect(() => {
        if (addQaQcIssueResponse?.message) {
            var x = document.getElementById("sucess-alert");
            const message = addQaQcIssueResponse?.errors?.title ? "The issue title already exists" : addQaQcIssueResponse?.message;
            if (x) {
                x.className = "show";
                x.innerText = message;
                setTimeout(function () {
                    x.className = x.className.replace("show", "");
                }, 3000);
            }
            dispatch(
                actions.getAllRecommendationReviews({
                    recommendation_id: id,
                    order: {
                        [`recommendation_reviews.created_at`]: "desc"
                    }
                })
            );
            dispatch(actions.getRecommendationById(id));
            if (addQaQcIssueResponse?.success) {
                onCancel();
            }
            setIsLoading(false);
        }
    }, [addQaQcIssueResponse]);

    const handleSave = () => {
        if (!issueTitle?.trim()) return setIsErr(true);
        if (onSubmit) {
            onSubmit(issueTitle);
        } else {
            setIsLoading(true);
            dispatch(actions.createQaQcIssue({ recommendation_review: { recommendation_id: id, title: issueTitle } }));
        }
    };

    const handleChange = ({ target: { value } }) => {
        setIssueTitle(value);
        setIsErr(false);
    };

    return (
        <div className="modal modal-region qa-band-modl" id="modalId" tabIndex="-1" style={{ display: "block" }} role="dialog">
            <Draggable handle=".draggable" cancel=".close">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header draggable">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <div className="txt-hed">{onSubmit ? "Edit title" : "Add New QA/QC Issue"}</div>
                            </h5>
                            <button type="button" className="close close-icn-sec" onClick={onCancel}>
                                <span aria-hidden="true">
                                    <img src="/img/close.svg" alt="" />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body region-otr">
                            <div className="col-md-12 more-info-content p-0">
                                <div className="form-group">
                                    <label>Issue title</label>
                                    <textarea
                                        placeholder="Notes"
                                        autoFocus
                                        className={`form-control ${isErr ? "error-border" : ""}`}
                                        value={issueTitle}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-sec">
                                <div className="text-right btnOtr edit-cmn-btn">
                                    <button type="button" className="btn btn-secondary btnClr col-md-2 mr-1" data-dismiss="modal" onClick={onCancel}>
                                        Cancel
                                    </button>
                                    <button disabled={isLoading} type="button" className="btn btn-primary btnRgion col-md-2" onClick={handleSave}>
                                        {isLoading ? (
                                            <div className="button-loader d-flex justify-content-center align-items-center">
                                                <div className="spinner-border text-white" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            "Save"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    );
}

export default AddIssueModal;
