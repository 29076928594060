import React, { useState } from "react";
import { SketchPicker } from "react-color";
import NumberFormat from "react-number-format";
import reactCSS from "reactcss";

const BbiForm = ({ bbiProperty = {}, handleBbiChange, FONT_FAMILY = [] }) => {
    const styles = reactCSS({
        default: {
            color: {
                width: "40px",
                height: "15px",
                borderRadius: "3px",
                zoom: "107%"
            },
            popover: {
                position: "absolute",
                zIndex: "3",
                left: "111px",
                top: "-76px"
            },
            cover: {
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px"
            },
            swatch: {
                padding: "6px",
                background: "#ffffff",
                borderRadius: "2px",
                cursor: "pointer",
                display: "inline-block",
                boxShadow: "0 0 0 1px rgba(0,0,0,.2)"
            }
        }
    });
    const [colorModalState, setColorModalState] = useState(null);
    return (
        <>
            <div className="styles-box">
                <h2>Heading</h2>

                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Heading Font Name </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.bbi_heading?.font_id || "-"}
                                    onChange={e => {
                                        handleBbiChange("bbi_heading", "font_id", e.target.value);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {FONT_FAMILY.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Heading Font Size</label>

                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.bbi_heading?.font_size || 0}
                                    onValueChange={values => {
                                        handleBbiChange("bbi_heading", "font_size", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Heading Font Color </label>
                            </div>
                            <div className="dropdown">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => handleBbiChange("bbi_heading", "color", "")}
                                >
                                    <i class="fas fa-times "></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() =>
                                        setColorModalState(prevColorModalState =>
                                            prevColorModalState === "custom_heading_font_color" ? null : "custom_heading_font_color"
                                        )
                                    }
                                >
                                    <span>{bbiProperty?.bbi_heading?.color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: bbiProperty?.bbi_heading?.color ? `#${bbiProperty?.bbi_heading?.color}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "custom_heading_font_color" && (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => setColorModalState(null)} />
                                        <SketchPicker
                                            position="right"
                                            color={bbiProperty?.bbi_heading?.color === "#000000" ? "" : bbiProperty?.bbi_heading?.color}
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color => handleBbiChange("bbi_heading", "color", color.hex?.replace("#", ""))}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Heading Font Bold </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.bbi_heading?.bold || "-"}
                                    onChange={e => {
                                        handleBbiChange("bbi_heading", "bold", e.target.value === "true");
                                    }}
                                >
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Heading Alignment</label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    name="tag"
                                    value={bbiProperty?.bbi_heading?.alignment}
                                    onChange={e => handleBbiChange("bbi_heading", "alignment", e.target?.value)}
                                >
                                    <option value="">Select</option>
                                    <option value={"left"}>Left</option>
                                    <option value={"center"}>Center</option>
                                    <option value={"right"}>Right</option>
                                    <option value={"justify"}>Justify</option>
                                </select>
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Heading Space Before </label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.bbi_heading?.space_before || 0}
                                    onValueChange={values => {
                                        handleBbiChange("bbi_heading", "space_before", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Heading Space After </label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.bbi_heading?.space_after || 0}
                                    onValueChange={values => {
                                        handleBbiChange("bbi_heading", "space_after", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="styles-box">
                <h2>Sub Heading</h2>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Sub Heading Font Name </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.bbi_sub_heading?.font_id || "-"}
                                    onChange={e => {
                                        handleBbiChange("bbi_sub_heading", "font_id", e.target.value);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {FONT_FAMILY.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Sub Heading Font Size</label>

                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.bbi_sub_heading?.font_size || 0}
                                    onValueChange={values => {
                                        handleBbiChange("bbi_sub_heading", "font_size", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Sub Heading Font Color </label>
                            </div>
                            <div className="dropdown">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => handleBbiChange("bbi_sub_heading", "color", "")}
                                >
                                    <i class="fas fa-times "></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() =>
                                        setColorModalState(prevColorModalState =>
                                            prevColorModalState === "sub_heading_font_color" ? null : "sub_heading_font_color"
                                        )
                                    }
                                >
                                    <span>{bbiProperty?.bbi_sub_heading?.color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: bbiProperty?.bbi_sub_heading?.color ? `#${bbiProperty?.bbi_sub_heading?.color}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "sub_heading_font_color" && (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => setColorModalState(null)} />
                                        <SketchPicker
                                            position="right"
                                            color={bbiProperty?.bbi_sub_heading?.color === "#000000" ? "" : bbiProperty?.bbi_sub_heading?.color}
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color => handleBbiChange("bbi_sub_heading", "color", color.hex?.replace("#", ""))}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Sub Heading Font Bold </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.bbi_sub_heading?.bold || "-"}
                                    onChange={e => {
                                        handleBbiChange("bbi_sub_heading", "bold", e.target.value === "true");
                                    }}
                                >
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Sub Heading Alignment</label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    name="tag"
                                    value={bbiProperty?.bbi_sub_heading?.alignment}
                                    onChange={e => handleBbiChange("bbi_sub_heading", "alignment", e.target?.value)}
                                >
                                    <option value="">Select</option>
                                    <option value={"left"}>Left</option>
                                    <option value={"center"}>Center</option>
                                    <option value={"right"}>Right</option>
                                    <option value={"justify"}>Justify</option>
                                </select>
                            </div>
                        </div>

                        <div className="font-family font-st col-md-3">
                            <label>Sub Heading Space Before </label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.bbi_sub_heading?.space_before || 0}
                                    onValueChange={values => {
                                        handleBbiChange("bbi_sub_heading", "space_before", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Sub Heading Space After </label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.bbi_sub_heading?.space_after || 0}
                                    onValueChange={values => {
                                        handleBbiChange("bbi_sub_heading", "space_after", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="styles-box">
                <h2>Additionals (Executive Summary)</h2>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Additionals Font Name </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.executive_summary?.additional?.font_id || "-"}
                                    onChange={e => {
                                        handleBbiChange("executive_summary", "font_id", e.target.value, "additional");
                                    }}
                                >
                                    <option value="">Select</option>
                                    {FONT_FAMILY.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Additionals Font Size</label>

                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.executive_summary?.additional?.font_size || 0}
                                    onValueChange={values => {
                                        handleBbiChange("executive_summary", "font_size", values.floatValue, "additional");
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Additionals Font Color </label>
                            </div>
                            <div className="dropdown">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => handleBbiChange("executive_summary", "color", "", "additional")}
                                >
                                    <i class="fas fa-times "></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() =>
                                        setColorModalState(prevColorModalState =>
                                            prevColorModalState === "additional_font_color" ? null : "additional_font_color"
                                        )
                                    }
                                >
                                    <span>{bbiProperty?.executive_summary?.additional?.color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: bbiProperty?.executive_summary?.additional?.color
                                                ? `#${bbiProperty?.executive_summary?.additional?.color}`
                                                : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "additional_font_color" && (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => setColorModalState(null)} />
                                        <SketchPicker
                                            position="right"
                                            color={
                                                bbiProperty?.executive_summary?.additional?.color === "#000000"
                                                    ? ""
                                                    : bbiProperty?.executive_summary?.additional?.color
                                            }
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color =>
                                                handleBbiChange("executive_summary", "color", color.hex?.replace("#", ""), "additional")
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Additionals Font Bold </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.executive_summary?.additional?.bold || "-"}
                                    onChange={e => {
                                        handleBbiChange("executive_summary", "bold", e.target.value === "true", "additional");
                                    }}
                                >
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Additionals Alignment</label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    name="tag"
                                    value={bbiProperty?.executive_summary?.additional?.alignment}
                                    onChange={e => handleBbiChange("executive_summary", "alignment", e.target?.value, "additional")}
                                >
                                    <option value="">Select</option>
                                    <option value={"left"}>Left</option>
                                    <option value={"center"}>Center</option>
                                    <option value={"right"}>Right</option>
                                    <option value={"justify"}>Justify</option>
                                </select>
                            </div>
                        </div>

                        <div className="font-family font-st col-md-3">
                            <label>Additionals Space Before </label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.executive_summary?.additional?.space_before || 0}
                                    onValueChange={values => {
                                        handleBbiChange("executive_summary", "space_before", values.floatValue, "additional");
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Additionals Space After </label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.executive_summary?.additional?.space_after || 0}
                                    onValueChange={values => {
                                        handleBbiChange("executive_summary", "space_after", values.floatValue, "additional");
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="styles-box">
                <h2>Trade (Executive Summary)</h2>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Trade Font Name </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.executive_summary?.trade?.font_id || "-"}
                                    onChange={e => {
                                        handleBbiChange("executive_summary", "font_id", e.target.value, "trade");
                                    }}
                                >
                                    <option value="">Select</option>
                                    {FONT_FAMILY.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Trade Font Size</label>

                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.executive_summary?.trade?.font_size || 0}
                                    onValueChange={values => {
                                        handleBbiChange("executive_summary", "font_size", values.floatValue, "trade");
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Trade Font Color </label>
                            </div>
                            <div className="dropdown">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => handleBbiChange("executive_summary", "color", "", "trade")}
                                >
                                    <i class="fas fa-times "></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() =>
                                        setColorModalState(prevColorModalState =>
                                            prevColorModalState === "trade_font_color" ? null : "trade_font_color"
                                        )
                                    }
                                >
                                    <span>{bbiProperty?.executive_summary?.trade?.color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: bbiProperty?.executive_summary?.trade?.color
                                                ? `#${bbiProperty?.executive_summary?.trade?.color}`
                                                : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "trade_font_color" && (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => setColorModalState(null)} />
                                        <SketchPicker
                                            position="right"
                                            color={
                                                bbiProperty?.executive_summary?.trade?.color === "#000000"
                                                    ? ""
                                                    : bbiProperty?.executive_summary?.trade?.color
                                            }
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color => handleBbiChange("executive_summary", "color", color.hex?.replace("#", ""), "trade")}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Trade Font Bold </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.executive_summary?.trade?.bold || "-"}
                                    onChange={e => {
                                        handleBbiChange("executive_summary", "bold", e.target.value === "true", "trade");
                                    }}
                                >
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Trade Alignment</label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    name="tag"
                                    value={bbiProperty?.executive_summary?.trade?.alignment}
                                    onChange={e => handleBbiChange("executive_summary", "alignment", e.target?.value, "trade")}
                                >
                                    <option value="">Select</option>
                                    <option value={"left"}>Left</option>
                                    <option value={"center"}>Center</option>
                                    <option value={"right"}>Right</option>
                                    <option value={"justify"}>Justify</option>
                                </select>
                            </div>
                        </div>

                        <div className="font-family font-st col-md-3">
                            <label>Trade Space Before </label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.executive_summary?.trade?.space_before || 0}
                                    onValueChange={values => {
                                        handleBbiChange("executive_summary", "space_before", values.floatValue, "trade");
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Trade Space After </label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.executive_summary?.trade?.space_after || 0}
                                    onValueChange={values => {
                                        handleBbiChange("executive_summary", "space_after", values.floatValue, "trade");
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="styles-box">
                <h2>Memo Properties</h2>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Apply Memo Field Property</label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.memo_properties?.memo_props_active}
                                    onChange={e => {
                                        handleBbiChange("memo_properties", "memo_props_active", e.target.value === "true");
                                    }}
                                >
                                    <option value={true}>Active</option>
                                    <option value={false}>Inactive</option>
                                </select>
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Heading Font Name </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.memo_properties?.heading?.font_id || "-"}
                                    onChange={e => {
                                        handleBbiChange("memo_properties", "font_id", e.target.value, "heading");
                                    }}
                                >
                                    <option value="">Select</option>
                                    {FONT_FAMILY.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Heading Font Size</label>

                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.memo_properties?.heading?.font_size || 0}
                                    onValueChange={values => {
                                        handleBbiChange("memo_properties", "font_size", values.floatValue, "heading");
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Heading Font Color </label>
                            </div>

                            <div className="dropdown sketch-wide">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        handleBbiChange("memo_properties", "font_color", "", "heading");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "bbiProperty_memo_properties_heading_color"
                                                ? null
                                                : "bbiProperty_memo_properties_heading_color"
                                        );
                                    }}
                                >
                                    <span>{bbiProperty?.memo_properties?.heading?.font_color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: bbiProperty?.memo_properties?.heading?.font_color
                                                ? `#${bbiProperty?.memo_properties?.heading?.font_color}`
                                                : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "bbiProperty_memo_properties_heading_color" && (
                                    <div style={styles.popover}>
                                        <div
                                            style={styles.cover}
                                            onClick={() => {
                                                setColorModalState(null);
                                            }}
                                        />
                                        <SketchPicker
                                            position="right"
                                            color={bbiProperty?.memo_properties?.heading?.font_color}
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color => {
                                                handleBbiChange("memo_properties", "font_color", color.hex?.replace("#", ""), "heading");
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Heading Font Bold </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.memo_properties?.heading?.font_bold || "-"}
                                    onChange={e => {
                                        handleBbiChange("memo_properties", "font_bold", e.target.value === "true", "heading");
                                    }}
                                >
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Body Font Name </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.memo_properties?.body?.font_id || "-"}
                                    onChange={e => {
                                        handleBbiChange("memo_properties", "font_id", e.target.value, "body");
                                    }}
                                >
                                    <option value="">Select</option>
                                    {FONT_FAMILY.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Font Size</label>

                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.memo_properties?.body?.font_size || 0}
                                    onValueChange={values => {
                                        handleBbiChange("memo_properties", "font_size", values?.floatValue, "body");
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Body Font Color </label>
                            </div>

                            <div className="dropdown sketch-wide">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        handleBbiChange("memo_properties", "font_color", "", "body");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        handleBbiChange("memo_properties", "font_color", "", "heading");
                                        setColorModalState(
                                            colorModalState === "bbiProperty_memo_properties_body_color"
                                                ? null
                                                : "bbiProperty_memo_properties_body_color"
                                        );
                                    }}
                                >
                                    <span>{bbiProperty?.memo_properties?.body?.font_color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: bbiProperty?.memo_properties?.body?.font_color
                                                ? `#${bbiProperty?.memo_properties?.body?.font_color}`
                                                : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "bbiProperty_memo_properties_body_color" && (
                                    <div style={styles.popover}>
                                        <div
                                            style={styles.cover}
                                            onClick={() => {
                                                setColorModalState(null);
                                            }}
                                        />
                                        <SketchPicker
                                            position="right"
                                            color={bbiProperty?.memo_properties?.body?.font_color}
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color => {
                                                handleBbiChange("memo_properties", "font_color", color.hex?.replace("#", ""), "body");
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Body Font Bold </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={bbiProperty?.memo_properties?.body?.font_bold || "-"}
                                    onChange={e => {
                                        handleBbiChange("memo_properties", "font_bold", e.target.value === "true", "body");
                                    }}
                                >
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Paragraph Space Before </label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.memo_properties?.paragraph_space_before || 0}
                                    onValueChange={values => {
                                        handleBbiChange("memo_properties", "paragraph_space_before", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Paragraph Space After </label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.memo_properties?.paragraph_space_after || 0}
                                    onValueChange={values => {
                                        handleBbiChange("memo_properties", "paragraph_space_after", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Paragraph Alignment </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    name="tag"
                                    value={bbiProperty?.memo_properties?.alignment}
                                    onChange={e => handleBbiChange("memo_properties", "alignment", e.target.value)}
                                >
                                    <option value="">Select</option>
                                    <option value={"left"}>Left</option>
                                    <option value={"center"}>Center</option>
                                    <option value={"right"}>Right</option>
                                    <option value={"justify"}>Justify</option>
                                </select>
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>Paragraph Line Space </label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={bbiProperty?.memo_properties?.line_space || 0}
                                    onValueChange={values => {
                                        handleBbiChange("memo_properties", "line_space", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BbiForm;
