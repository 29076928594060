import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import NumberFormat from "react-number-format";
import reactCSS from "reactcss";
import ConfirmationModal from "../ConfirmationModal";
import Portal from "../Portal";

export const InputCell = ({ keyItem, rowIndex, config, handleCellFocus, handleCellValueChange, setCellValueChange, rowData, inputValue }) => {
    const hasThousandsSeparator = !["year", "major_renovation_year"].includes(keyItem);
    const [inputChange, setInputChange] = useState("");
    useEffect(() => {
        if (config[keyItem]?.type === "string") {
            setInputChange(inputValue[keyItem]);
        }
    }, []);
    return (
        <>
            {keyItem === "color_code" ? (
                <ColorPicker
                    colorCode={inputValue[keyItem]}
                    keyItem={keyItem}
                    rowIndex={rowIndex}
                    setCellValueChange={setCellValueChange}
                    rowData={rowData}
                    handleCellValueChange={handleCellValueChange}
                    handleCellFocus={handleCellFocus}
                />
            ) : config[keyItem]?.type === "string" ? (
                <input
                    value={inputChange}
                    className="custom-input form-control"
                    onChange={e => {
                        setInputChange(e.target.value);
                        handleCellValueChange(e.target?.value, keyItem);
                    }}
                    onBlur={() => setCellValueChange(keyItem, rowIndex)}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            setCellValueChange(keyItem, rowIndex);
                        }
                        if (e.key === "Escape") {
                            handleCellValueChange(rowData[keyItem], keyItem);
                            handleCellFocus("", "");
                        }
                    }}
                    autoFocus
                />
            ) : (
                <NumberFormat
                    value={parseFloat(inputValue[keyItem])}
                    className="custom-input form-control"
                    thousandSeparator={hasThousandsSeparator}
                    decimalScale={config[keyItem]?.decimalScale || 0}
                    onValueChange={values => {
                        const { value, sourceInfo } = values;
                        if (sourceInfo?.source !== "prop") {
                            handleCellValueChange(parseFloat(value), keyItem);
                        }
                    }}
                    allowNegative={false}
                    onKeyPress={e => e.key === "Enter" && setCellValueChange(keyItem, rowIndex)}
                    onBlur={() => setCellValueChange(keyItem, rowIndex)}
                    onKeyDown={e => {
                        if (e.key === "Escape" || e.keyCode === 27) {
                            handleCellValueChange(rowData[keyItem], keyItem);
                            handleCellFocus("", "");
                        }
                    }}
                    autoFocus
                />
            )}
        </>
    );
};

const ColorPicker = ({ colorCode, setCellValueChange, rowData, handleCellFocus, handleCellValueChange, keyItem, rowIndex }) => {
    const [showPicker, setPicker] = useState(true);
    const [selectedColor, setColor] = useState(colorCode);
    const [showClearConfirm, setClearConfirm] = useState(false);
    const styles = reactCSS({
        default: {
            color: {
                width: "40px",
                height: "15px",
                borderRadius: "3px",
                background: selectedColor
            },
            popover: {
                position: "absolute",
                zIndex: "3"
            },
            cover: {
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px"
            },
            swatch: {
                padding: "6px",
                background: "#ffffff",
                borderRadius: "2px",
                cursor: "pointer",
                display: "inline-block",
                boxShadow: "0 0 0 1px rgba(0,0,0,.2)"
            }
        }
    });
    const handleSave = async () => {
        setPicker(false);
        await handleCellValueChange(selectedColor, keyItem);
        setCellValueChange(keyItem, rowIndex);
    };
    const handleClear = async () => {
        setClearConfirm(false);
        setPicker(false);
        setColor("");
        await handleCellValueChange("", keyItem);
        setCellValueChange(keyItem, rowIndex);
    };
    const handleCancel = () => {
        setColor(colorCode);
        setPicker(false);
        handleCellFocus("", "");
    };
    const renderConfirmationModal = () => {
        if (!showClearConfirm) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={"Do you want to remove this color?"}
                        message={"This action cannot be reverted, are you sure?"}
                        onNo={() => setClearConfirm(false)}
                        onYes={handleClear}
                    />
                }
                onCancel={() => setClearConfirm(false)}
            />
        );
    };

    return (
        <div>
            {renderConfirmationModal()}
            <div className="color-palette-flx">
                <div className={"cursor-hand"}>
                    {selectedColor ? (
                        <>
                            {selectedColor}
                            <span
                                className="color-box-common"
                                style={{
                                    background: selectedColor
                                }}
                            ></span>
                        </>
                    ) : (
                        "-"
                    )}
                </div>
            </div>
            {showPicker && (
                <>
                    <div className="btn-out-table">
                        <button type="button" className="btn-out-cancel" onClick={handleCancel}>
                            Cancel
                        </button>
                        <button type="button" className="btn-out-cancel" onClick={() => setClearConfirm(true)}>
                            Clear
                        </button>
                        <button type="button" className="btn-out-save" onClick={handleSave}>
                            Save
                        </button>
                    </div>
                    <div style={styles.popover}>
                        <SketchPicker
                            color={selectedColor || ""}
                            className="sketch-picker-zoom"
                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                            onChange={color => setColor(color?.hex || "")}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
