import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import moment from "moment";
import NumberFormat from "react-number-format";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

import actions from "../actions";
import { FILE_TYPE } from "../../common/constants";
import reportPropertyActions from "../../reportProperties/actions";
import smartChartAction from "../../smartCharts/actions";
import projectAction from "../../project/actions";

const SmartChartDataEditForm = ({
    selectedData,
    updateData,
    actionType = "",
    regenerateSmartChart,
    heading,
    buttonText,
    hasExportType = false,
    exportSmartChart,
    hasNotes = true,
    isFavoriteView = false,
    reportPropertyReducer: {
        dropDownList: { fonts: FONT_FAMILY = [] }
    },
    getDropdownList,
    isRecom = false,
    selectedTileData = {},
    getSmartChartPropertyById,
    smartChartPropertyByIdData = {},
    actionTypeForModal,
    smartchartConfigData,
    isExcelExport = false,
    isMemo = false,
    isBsi = false,
    isNarrativeText = false,
    btnText,
    isNormalRecom = false,
    isQuery = false,
    ...props
}) => {
    const [formParams, setFormParams] = useState({
        name: "",
        notes: ""
    });
    const [showErrorBorder, setShowErrorBorder] = useState(false);
    const [exportType, setExportType] = useState(FILE_TYPE.WORD);
    const [orientation, setOrientation] = useState(false);
    const [exportHeading, setExportHeading] = useState({
        bold: false,
        color: "ffffff",
        font_size: 10,
        font_id: "",
        bg_color: "003f2d",
        alignment: "left",
        cell_margin: { top: 0.04, left: 0.04, right: 0.04, bottom: 0.04 }
    });
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showBgColorPicker, setShowBgColorPicker] = useState(false);
    const [withNotes, setWithNotes] = useState(true);
    const [withExport, setWithExport] = useState(true);
    const [simpleNarrativeText, setSimpleNarrativeText] = useState(true);
    const [excelExport, setExcelExport] = useState(isExcelExport);
    const { filterValues } = useSelector(state => state?.dashboardReducer);
    const { getProjectByIdResponse } = useSelector(state => state?.projectReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        if (actionType === "DashboardExport" && (getProjectByIdResponse?.success || filterValues?.client_ids)) {
            dispatch(
                getTemplatePropertiesList({
                    client_id: filterValues?.client_ids?.[0] || getProjectByIdResponse?.client?.id
                })
            );
            dispatch(
                getTemplateList({
                    client_id: filterValues?.client_ids?.[0] || getProjectByIdResponse?.client?.id
                })
            );
        }
    }, [getProjectByIdResponse, filterValues?.client_ids]);
    useEffect(() => {
        if (hasExportType && actionTypeForModal && actionTypeForModal !== "DashboardExport") {
            getSmartChartPropertyById(selectedData?.smart_export_props);
        }
        if (isRecom || isMemo || isBsi || isNarrativeText) {
            getDropdownList("fonts");
            setExportHeading(
                selectedTileData?.custom_heading_props ||
                    selectedTileData?.band1?.custom_heading_props || {
                        bold: false,
                        color: "ffffff",
                        font_size: 10,
                        font_id: "",
                        bg_color: "003f2d",
                        alignment: "left",
                        cell_margin: { top: 0.04, left: 0.04, right: 0.04, bottom: 0.04 }
                    }
            );
            setWithNotes(selectedTileData?.hasOwnProperty("with_notes") ? selectedTileData?.with_notes : true);
            setWithExport(
                selectedTileData?.hasOwnProperty("with_export")
                    ? selectedTileData?.with_export
                    : selectedTileData?.band1?.hasOwnProperty("with_export")
                    ? selectedTileData?.band1?.with_export
                    : true
            );
            setSimpleNarrativeText(
                isBsi || isNarrativeText || isMemo
                    ? selectedTileData?.hasOwnProperty("simple_text")
                        ? selectedTileData?.simple_text
                        : selectedTileData?.band1?.hasOwnProperty("simple_text")
                        ? selectedTileData?.band1?.simple_text
                        : true
                    : selectedTileData?.hasOwnProperty("notes_in_cell")
                    ? selectedTileData?.notes_in_cell
                    : true
            );
        }
        setFormParams({
            name:
                actionType === "Regenerate"
                    ? `${selectedData.name} ${moment().format("MM_DD_YYYY_HH_mm_ss")}`
                    : actionType === "DashboardExport"
                    ? `${selectedData?.name ? selectedData?.name : getProjectByIdResponse?.client?.name} Dashboard Export ${moment().format(
                          "MM_DD_YYYY_HH_mm_ss"
                      )}`
                    : selectedData?.name || "",
            notes: selectedData?.notes || ""
        });
        if (selectedData?.doc_url && selectedData.doc_url?.split(/[#?]/)[0]?.split(".")?.pop()?.trim() === "xlsx") {
            setExcelExport(true);
        }
    }, []);
    useEffect(() => {
        if (!(selectedTileData?.custom_heading_props?.font_id || selectedTileData?.band1?.custom_heading_props?.font_id)) {
            const initialFont = FONT_FAMILY?.find(e => e.name === "Calibre");
            setExportHeading(prev => {
                return { ...prev, font_id: initialFont?.id };
            });
        }
    }, [FONT_FAMILY]);

    useEffect(() => {
        if ((smartChartPropertyByIdData?.success && actionTypeForModal) || smartchartConfigData?.project) {
            let excelExportCheck = isExcelExport;
            let isOrientationChecked = checkIsOrientation();
            if (isOrientationChecked) setOrientation(isOrientationChecked);
            if (smartChartPropertyByIdData?.data || smartchartConfigData?.project) {
                let propertyData = smartchartConfigData?.project
                    ? smartchartConfigData
                    : JSON.parse(smartChartPropertyByIdData?.data?.properties_text);
                if (propertyData?.project?.band1?.export_type === "excel") {
                    excelExportCheck = true;
                }
                setExcelExport(excelExportCheck);
            }
        }
    }, [smartChartPropertyByIdData]);

    const checkIsOrientation = () => {
        let isOrientationChecked = false;
        if (smartChartPropertyByIdData?.data || smartchartConfigData?.project) {
            let propertyData = smartChartPropertyByIdData?.data
                ? JSON.parse(smartChartPropertyByIdData?.data?.properties_text)
                : smartchartConfigData;
            let tileKeys = Object.keys(propertyData?.project?.band1?.type || {});
            isOrientationChecked = tileKeys.some(
                key =>
                    key.includes("user_doc") ||
                    key.includes("multi_recommendation") ||
                    key.includes("normal_recom") ||
                    key.includes("sorted_recom") ||
                    key.includes("recom_onepager") ||
                    key.includes("bbi_report") ||
                    key.includes("bsi_report") ||
                    key.includes("asset-onepager") ||
                    key?.startsWith("recommendations_site") ||
                    key?.startsWith("recommendations_building") ||
                    key?.startsWith("recommendations_region") ||
                    key?.startsWith("recommendations_project") ||
                    key?.startsWith("recommendations_addition") ||
                    key?.startsWith("asset_site") ||
                    key?.startsWith("asset_building") ||
                    key?.startsWith("asset_region") ||
                    key?.startsWith("asset_project") ||
                    key?.startsWith("asset_addition")
            );
        }
        return isOrientationChecked;
    };

    const validate = () => {
        setShowErrorBorder(false);
        if (!formParams.name && !formParams.name.trim().length) {
            setShowErrorBorder(true);
            return false;
        }
        if (actionType === "Regenerate" && formParams?.name.trim()?.length > 100) {
            setShowErrorBorder(true);
            return false;
        }
        return true;
    };

    const updateSmartChartData = async isExport => {
        let exportParams = {
            property_id: selectedData?.smart_export_props,
            orientation,
            ...formParams
        };
        let updateParams = {
            ...formParams,
            orientation
        };
        if (actionType === "Regenerate" && selectedData?.latest_favourite_export_id) {
            exportParams.prev_export_id = selectedData.latest_favourite_export_id;
        }
        if (localStorage.getItem("role") === "super_admin" && selectedData?.sm_report_id) {
            exportParams.admin_regenerate_export = selectedData.sm_report_id;
        }
        switch (exportType) {
            case FILE_TYPE.PDF:
                exportParams.is_pdf = true;
                updateParams.is_pdf = true;
                break;
            case FILE_TYPE.PPT:
                exportParams.is_ppt = true;
                updateParams.is_ppt = true;
                break;
            case FILE_TYPE.WORD:
                break;
            default:
                break;
        }
        if (excelExport) {
            exportParams.is_excel = true;
            updateParams.is_excel = true;
        }

        if (validate()) {
            actionType === "Regenerate"
                ? regenerateSmartChart(exportParams)
                : actionType === "Export" || actionType === "DashboardExport"
                ? exportSmartChart(exportParams, isExport)
                : isRecom
                ? updateData({
                      name: formParams?.name,
                      custom_heading_props: {
                          ...exportHeading
                          //   ...Object.fromEntries(Object.entries(exportHeading).filter(([key, value]) => key !== "cell_margin" && key !== "alignment"))
                      },
                      with_notes: withNotes,
                      notes_in_cell: simpleNarrativeText
                  })
                : isMemo
                ? updateData({
                      name: formParams?.name,
                      custom_heading_props: {
                          ...exportHeading
                      },
                      with_export: withExport,
                      simple_text: simpleNarrativeText
                  })
                : isBsi || isNarrativeText
                ? updateData({
                      simple_text: simpleNarrativeText
                  })
                : updateData({ ...updateParams }, selectedData?.id, selectedData?.properties_text);
            if (!isQuery) {
                props.onCancel();
            }
        }
    };

    const handleExportHeadingSelect = ({ name, value }) => {
        setExportHeading(prevValue => {
            return {
                ...prevValue,
                [name]: value
            };
        });
    };

    const styles = reactCSS({
        default: {
            color: {
                width: "40px",
                height: "15px",
                borderRadius: "3px",
                background: exportHeading?.color,
                zoom: "107%"
            },
            popover: {
                position: "absolute",
                zIndex: "3",
                left: "111px",
                top: "-76px"
            },
            cover: {
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px"
            },
            swatch: {
                padding: "6px",
                background: "#ffffff",
                borderRadius: "2px",
                cursor: "pointer",
                display: "inline-block",
                boxShadow: "0 0 0 1px rgba(0,0,0,.2)"
            }
        }
    });
    return (
        <React.Fragment>
            <div
                className={`modal modal-region modal-view inbox-modal ${isMemo || isRecom || isBsi || isNarrativeText ? "editmemo-modal" : ""}`}
                id="modalId"
                tabIndex="-1"
                style={{ display: "block" }}
            >
                <Draggable handle=".draggable" cancel=".non-draggable">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    <div className="txt-hed">{heading}</div>
                                </h5>
                                <button type="button" className="close non-draggable" onClick={props.onCancel}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div
                                className={`modal-body region-otr core-rpt-dt-mod sm-chart-upload ${
                                    isRecom || isMemo || isBsi || isNarrativeText ? "edit-multi-recom-sm" : ""
                                }`}
                            >
                                <div className="upload-area not-draggable">
                                    <div className="upload-sec cursor-hand" role="button" tabIndex="0">
                                        <input type="file" multiple="" autocomplete="off" tabIndex="-1" style={{ display: "none" }} />
                                    </div>
                                </div>
                                <div className="col-md-12 main-sec">
                                    {!isRecom && !isMemo && !isBsi ? (
                                        <div className="form-row">
                                            <div className="form-group col-12 mb-4">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    placeholder="Name"
                                                    value={formParams.name}
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        setFormParams(prevParams => {
                                                            return {
                                                                ...prevParams,
                                                                name: value
                                                            };
                                                        });
                                                    }}
                                                    className={`form-control ${
                                                        (showErrorBorder && !formParams.name && !formParams.name.trim().length) ||
                                                        (actionType === "Regenerate" && showErrorBorder && formParams.name.trim().length > 100)
                                                            ? "error-border"
                                                            : ""
                                                    }`}
                                                />
                                                {actionType === "Regenerate" && showErrorBorder && formParams.name.trim().length > 100 && (
                                                    <small className="text-danger pl-2 er-danger">Name should not exceed 100 characters.</small>
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                    {isRecom || isMemo || isBsi || isNarrativeText ? (
                                        <div className="form-row outer-font-section">
                                            {!isBsi && !isNarrativeText && (
                                                <div className="form-row col-12">
                                                    <div className="form-group col-12 mb-4">
                                                        <label>Name</label>
                                                        <input
                                                            type="text"
                                                            placeholder="Name"
                                                            value={formParams.name}
                                                            onChange={e => {
                                                                let value = e.target.value;
                                                                setFormParams(prevParams => {
                                                                    return {
                                                                        ...prevParams,
                                                                        name: value
                                                                    };
                                                                });
                                                            }}
                                                            className={`form-control ${
                                                                showErrorBorder && !formParams.name && !formParams.name.trim().length
                                                                    ? "error-border"
                                                                    : ""
                                                            }`}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {(isRecom || isBsi || isNarrativeText || isMemo) && (
                                                <>
                                                    {!isBsi && !isNarrativeText && !isMemo && (
                                                        <div class="rem-txt check-container-outer col-12 mb-3 d-flex">
                                                            <label class="container-check">
                                                                With Narrative
                                                                <input type="checkbox" checked={withNotes} onChange={e => setWithNotes(true)} />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                            <label class="container-check">
                                                                Without Narrative
                                                                <input type="checkbox" checked={!withNotes} onChange={e => setWithNotes(false)} />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    )}
                                                    {isMemo && (
                                                        <div class="rem-txt check-container-outer col-12 mb-3 d-flex">
                                                            <label class="container-check">
                                                                Include Heading
                                                                <input type="checkbox" checked={withExport} onChange={e => setWithExport(true)} />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                            <label class="container-check">
                                                                Exclude Heading
                                                                <input type="checkbox" checked={!withExport} onChange={e => setWithExport(false)} />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    )}
                                                    {(withNotes || isBsi || isNarrativeText || isMemo) && !isNormalRecom && (
                                                        <>
                                                            {isBsi || isNarrativeText ? (
                                                                <h4 style={{ textTransform: "capitalize" }}>
                                                                    {isBsi ? "Executive Summary" : "Narrative Text"}
                                                                </h4>
                                                            ) : null}
                                                            <div class="rem-txt check-container-outer col-12 mb-3 d-flex">
                                                                <label class="container-check mb-0">
                                                                    {isRecom ? "Simple Narrative Text in table" : "Simple Text"}
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={simpleNarrativeText}
                                                                        onChange={e => setSimpleNarrativeText(true)}
                                                                    />
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                                <label class="container-check mb-0">
                                                                    {isRecom ? "Rich Narrative Text" : "Rich Text"}
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={!simpleNarrativeText}
                                                                        onChange={e => setSimpleNarrativeText(false)}
                                                                    />
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}

                                            {!isBsi && !isNarrativeText && (
                                                <div className="style-outer">
                                                    <div className="styles-box">
                                                        <h2>Heading</h2>
                                                        <div className="styles-sec">
                                                            <div className={`d-flex f-outer ${isMemo || isRecom ? "overflow-edit-memo" : ""}`}>
                                                                <div className="font-family font-st col-md-6">
                                                                    <label>Font Name *</label>
                                                                    <div className="font-select">
                                                                        <select
                                                                            className={`dropdown`}
                                                                            value={exportHeading?.font_id}
                                                                            name="font_id"
                                                                            onChange={e => handleExportHeadingSelect(e.target)}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            {FONT_FAMILY.map(f => (
                                                                                <option key={f.id} value={f.id}>
                                                                                    {f.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="font-size font-st col-md-6">
                                                                    <label>Font Size *</label>
                                                                    <div className={`input-ft-size`}>
                                                                        <NumberFormat
                                                                            value={exportHeading?.font_size}
                                                                            onValueChange={values =>
                                                                                handleExportHeadingSelect({
                                                                                    name: "font_size",
                                                                                    value: values.floatValue
                                                                                })
                                                                            }
                                                                            allowNegative={false}
                                                                            suffix=" Pt"
                                                                            // className={`${showErrorBorder && !heading?.font_size ? "error-border" : ""}`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="font-color font-st col-md-6">
                                                                    <label>Font Color *</label>
                                                                    <div className={`dropdown`}>
                                                                        <div
                                                                            className="dropdown-toggle"
                                                                            type="button"
                                                                            onClick={() => setShowColorPicker(prevValue => !prevValue)}
                                                                        >
                                                                            <span>{exportHeading?.color || "Select"}</span>
                                                                            <span
                                                                                className="color-bx"
                                                                                style={{
                                                                                    background: exportHeading?.color
                                                                                        ? `#${exportHeading?.color}`
                                                                                        : "#fff"
                                                                                }}
                                                                            ></span>
                                                                            <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                        </div>
                                                                        {showColorPicker && (
                                                                            <div style={styles.popover}>
                                                                                <div style={styles.cover} onClick={() => setShowColorPicker(false)} />
                                                                                <SketchPicker
                                                                                    position="right"
                                                                                    color={exportHeading?.color}
                                                                                    presetColors={[
                                                                                        // "#95cd50",
                                                                                        "#ffe242",
                                                                                        "#ffa105",
                                                                                        "#ff0305",
                                                                                        "#0018A8",
                                                                                        "#800080",
                                                                                        "#3E8EDE",
                                                                                        "#417505",
                                                                                        "#ffffff"
                                                                                    ]}
                                                                                    onChange={color =>
                                                                                        handleExportHeadingSelect({
                                                                                            name: "color",
                                                                                            value: color.hex?.replace("#", "")
                                                                                        })
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="font-family font-st col-md-6">
                                                                    <label>Font Bold</label>
                                                                    <div className="font-select">
                                                                        <select
                                                                            className={`dropdown`}
                                                                            name="tag"
                                                                            value={exportHeading?.bold}
                                                                            onChange={e =>
                                                                                handleExportHeadingSelect({
                                                                                    name: "bold",
                                                                                    value: e.target.value === "true"
                                                                                })
                                                                            }
                                                                        >
                                                                            <option value={false}>No</option>
                                                                            <option value={true}>Yes</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="font-color font-st col-md-6">
                                                                    <label>Background Color *</label>
                                                                    <div className={`dropdown`}>
                                                                        <div
                                                                            className="dropdown-toggle"
                                                                            type="button"
                                                                            onClick={() => setShowBgColorPicker(prevValue => !prevValue)}
                                                                        >
                                                                            <span>{exportHeading?.bg_color || "Select"}</span>
                                                                            <span
                                                                                className="color-bx"
                                                                                style={{
                                                                                    background: exportHeading?.bg_color
                                                                                        ? `#${exportHeading?.bg_color}`
                                                                                        : "#fff"
                                                                                }}
                                                                            ></span>
                                                                            <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                        </div>
                                                                        {showBgColorPicker && (
                                                                            <div style={styles.popover}>
                                                                                <div
                                                                                    style={styles.cover}
                                                                                    onClick={() => setShowBgColorPicker(false)}
                                                                                />
                                                                                <SketchPicker
                                                                                    position="right"
                                                                                    color={exportHeading?.bg_color}
                                                                                    presetColors={[
                                                                                        "#95cd50",
                                                                                        "#ffe242",
                                                                                        "#ffa105",
                                                                                        "#ff0305",
                                                                                        "#0018A8",
                                                                                        "#003f2d",
                                                                                        "#3E8EDE",
                                                                                        "#417505"
                                                                                    ]}
                                                                                    onChange={bg_color =>
                                                                                        handleExportHeadingSelect({
                                                                                            name: "bg_color",
                                                                                            value: bg_color.hex?.replace("#", "")
                                                                                        })
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {(isMemo || isRecom) && (
                                                                    <>
                                                                        <div className="font-family font-st col-md-6">
                                                                            <label>Alignment</label>
                                                                            <div className="font-select">
                                                                                <select
                                                                                    className={`dropdown`}
                                                                                    value={exportHeading?.alignment}
                                                                                    name="alignment"
                                                                                    onChange={e => handleExportHeadingSelect(e.target)}
                                                                                >
                                                                                    <option value="">Select</option>
                                                                                    <option value="left">Left</option>
                                                                                    <option value="center">Center</option>
                                                                                    <option value="right">Right</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="font-size font-st col-md-6">
                                                                            <label>Margin Top</label>
                                                                            <div className={`input-ft-size`}>
                                                                                <NumberFormat
                                                                                    value={exportHeading?.cell_margin?.top}
                                                                                    onValueChange={values =>
                                                                                        setExportHeading(prevValue => {
                                                                                            return {
                                                                                                ...prevValue,
                                                                                                cell_margin: {
                                                                                                    ...prevValue.cell_margin,
                                                                                                    top: values.floatValue
                                                                                                }
                                                                                            };
                                                                                        })
                                                                                    }
                                                                                    allowNegative={false}
                                                                                    suffix=" inch"
                                                                                    // className={`${showErrorBorder && !heading?.font_size ? "error-border" : ""}`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="font-size font-st col-md-6">
                                                                            <label>Margin Left</label>
                                                                            <div className={`input-ft-size`}>
                                                                                <NumberFormat
                                                                                    value={exportHeading?.cell_margin?.left}
                                                                                    onValueChange={values =>
                                                                                        setExportHeading(prevValue => {
                                                                                            return {
                                                                                                ...prevValue,
                                                                                                cell_margin: {
                                                                                                    ...prevValue.cell_margin,
                                                                                                    left: values.floatValue
                                                                                                }
                                                                                            };
                                                                                        })
                                                                                    }
                                                                                    allowNegative={false}
                                                                                    suffix=" inch"
                                                                                    // className={`${showErrorBorder && !heading?.font_size ? "error-border" : ""}`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="font-size font-st col-md-6">
                                                                            <label>Margin Right</label>
                                                                            <div className={`input-ft-size`}>
                                                                                <NumberFormat
                                                                                    value={exportHeading?.cell_margin?.right}
                                                                                    onValueChange={values =>
                                                                                        setExportHeading(prevValue => {
                                                                                            return {
                                                                                                ...prevValue,
                                                                                                cell_margin: {
                                                                                                    ...prevValue.cell_margin,
                                                                                                    right: values.floatValue
                                                                                                }
                                                                                            };
                                                                                        })
                                                                                    }
                                                                                    allowNegative={false}
                                                                                    suffix=" inch"
                                                                                    // className={`${showErrorBorder && !heading?.font_size ? "error-border" : ""}`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="font-size font-st col-md-6">
                                                                            <label>Margin Bottom</label>
                                                                            <div className={`input-ft-size`}>
                                                                                <NumberFormat
                                                                                    value={exportHeading?.cell_margin?.bottom}
                                                                                    onValueChange={values =>
                                                                                        setExportHeading(prevValue => {
                                                                                            return {
                                                                                                ...prevValue,
                                                                                                cell_margin: {
                                                                                                    ...prevValue.cell_margin,
                                                                                                    bottom: values.floatValue
                                                                                                }
                                                                                            };
                                                                                        })
                                                                                    }
                                                                                    allowNegative={false}
                                                                                    suffix=" inch"
                                                                                    // className={`${showErrorBorder && !heading?.font_size ? "error-border" : ""}`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) : null}
                                    <div className="form-row">
                                        {hasNotes ? (
                                            <div className="form-group col-12 mb-4">
                                                <label>Notes</label>
                                                <textarea
                                                    className="form-control textarea"
                                                    value={formParams.notes}
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        setFormParams(prevParams => {
                                                            return {
                                                                ...prevParams,
                                                                notes: value
                                                            };
                                                        });
                                                    }}
                                                ></textarea>
                                            </div>
                                        ) : null}
                                    </div>
                                    {hasExportType ? (
                                        <>
                                            <div className="form-row">
                                                {!excelExport && (
                                                    <div className="form-group col-3">
                                                        <label className="container-check re-check-container">
                                                            Word
                                                            <input
                                                                type="radio"
                                                                name="export_type"
                                                                onClick={e => setExportType(FILE_TYPE.WORD)}
                                                                checked={exportType === FILE_TYPE.WORD}
                                                            />
                                                            <span className="checkmark white" />
                                                        </label>
                                                    </div>
                                                )}
                                                {!excelExport && (
                                                    <div className="form-group col-3">
                                                        <label className={`container-check re-check-container`}>
                                                            PDF
                                                            <input
                                                                type="radio"
                                                                name="export_type"
                                                                onClick={e => setExportType(FILE_TYPE.PDF)}
                                                                checked={exportType === FILE_TYPE.PDF}
                                                            />
                                                            <span className="checkmark white" />
                                                        </label>
                                                    </div>
                                                )}
                                                {!excelExport && (
                                                    <div className="form-group col-3">
                                                        <label className="container-check re-check-container">
                                                            PPT
                                                            <input
                                                                type="radio"
                                                                name="export_type"
                                                                onClick={e => setExportType(FILE_TYPE.PPT)}
                                                                checked={exportType === FILE_TYPE.PPT}
                                                            />
                                                            <span className="checkmark white" />
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                            {excelExport && (
                                                <div className="form-row">
                                                    <div className="form-group col-3">
                                                        <label className={`container-check re-check-container ${"cursor-diabled"}`}>
                                                            Excel
                                                            <input type="radio" name="excelexport" checked={excelExport} disabled={true} />
                                                            <span className="checkmark white" />
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                            {!excelExport && (
                                                <div className="form-row">
                                                    <div className="form-group col-3">
                                                        <label
                                                            className={`container-check re-check-container ${
                                                                checkIsOrientation() ? "cursor-diabled" : ""
                                                            }`}
                                                        >
                                                            Orientation
                                                            <input
                                                                type="radio"
                                                                name="orientation"
                                                                onClick={e => setOrientation(!orientation)}
                                                                checked={orientation}
                                                                disabled={checkIsOrientation()}
                                                            />
                                                            <span className="checkmark white" />
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : null}
                                </div>
                                <div className="btn-sec">
                                    <div className={`text-right btnOtr edit-cmn-btn ${btnText ? "butn-sm-reprt" : ""}`}>
                                        {btnText && (
                                            <button
                                                type="button"
                                                className="btn btn-primary btnRgion"
                                                class="btn btn-create save"
                                                onClick={() => {
                                                    updateSmartChartData(actionType === "DashboardExport" && true);
                                                }}
                                            >
                                                {btnText}
                                            </button>
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-primary btnRgion "
                                            class="btn btn-create save"
                                            onClick={() => {
                                                updateSmartChartData(actionType === "DashboardExport" && false);
                                            }}
                                        >
                                            {buttonText}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btnClr mr-1"
                                            data-dismiss="modal"
                                            onClick={() => props.onCancel()}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { smartChartReducer, reportPropertyReducer, projectReducer, dashboardReducer } = state;
    return { smartChartReducer, reportPropertyReducer, projectReducer, dashboardReducer };
};

const { uploadDocsForSmartReport, clearPropertyByIdData, getSmartChartPropertyById } = actions;
const { getDropdownList } = reportPropertyActions;
const { getTemplatePropertiesList, getTemplateList } = smartChartAction;

export default connect(mapStateToProps, {
    uploadDocsForSmartReport,
    getDropdownList,
    clearPropertyByIdData,
    getSmartChartPropertyById,
    getTemplatePropertiesList,
    getTemplateList
})(SmartChartDataEditForm);
