import { CompactPicker } from "react-color";
import NumberFormat from "react-number-format";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorderArray } from "../../../config/utils";
import { useState } from "react";

const SortedRecommendationForm = ({
    fontFamilyData = [],
    fontColor = [],
    sortedRecomProperty = {},
    onSortedRecommendationColumnOrderChange,
    TABLE_STYLE,
    setSortedRecommendationProperty,
    setSortedRecommendationTableColumnConfig,
    showErrorBorder
}) => {
    const [colorModalState, setColorModalState] = useState(null);
    const onColumnDnd = result => {
        if (!result.destination) {
            return;
        }
        const keyList = reorderArray(sortedRecomProperty?.properties, result.source.index, result.destination.index);
        onSortedRecommendationColumnOrderChange(keyList);
    };
    let totalRowsForColumnDnd = sortedRecomProperty?.properties?.length
        ? Math.trunc(sortedRecomProperty.properties.length / 4) + (sortedRecomProperty.properties.length % 4 ? 1 : 0)
        : 0;
    const { custom_heading, addition, site, body, building, grand_total, table_heading, table_style } = sortedRecomProperty;
    return (
        <>
            <div className="styles-box">
                <h2>Table Properties</h2>

                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Table Style </label>
                            <div className="font-select">
                                <select
                                    // className={`dropdown ${
                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                    //         ? "error-border"
                                    //         : ""
                                    // }`}
                                    className="dropdown"
                                    value={table_style?.style_id || "-"}
                                    // // name="table_style"
                                    onChange={e => {
                                        let value = e.target.value;
                                        setSortedRecommendationProperty("table_style", "style_id", value);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                    {TABLE_STYLE.map(f => (
                                        <option value={f.id} key={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Custom Heading Font Name</label>
                            <div className="font-select">
                                <select
                                    // className={`dropdown ${
                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                    //         ? "error-border"
                                    //         : ""
                                    // }`}
                                    className="dropdown"
                                    value={custom_heading?.font_id || "-"}
                                    // // name="table_style"
                                    onChange={e => {
                                        let value = e.target.value;
                                        setSortedRecommendationProperty("custom_heading", "font_id", value);
                                    }}
                                >
                                    {/* <option value="">Select</option> */}
                                    {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                    <option value="">Select</option>
                                    {fontFamilyData.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Custom Heading Font Size</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={custom_heading?.font_size}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("custom_heading", "font_size", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                    // className={`${
                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_size
                                    //         ? "error-border"
                                    //         : ""
                                    // }`}
                                />
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Custom Heading Font Color </label>
                            </div>
                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("custom_heading", "font_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times "></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_custom_heading_font_color" ? null : "table_sort_custom_heading_font_color"
                                        );
                                    }}
                                >
                                    <span>{custom_heading?.font_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: custom_heading?.font_colour ? `#${custom_heading?.font_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_custom_heading_font_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={custom_heading?.font_colour === "#000000" ? "" : custom_heading?.font_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("custom_heading", "font_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Custom Heading Background Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("custom_heading", "background_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_custom_heading_BG_color" ? null : "table_sort_custom_heading_BG_color"
                                        );
                                    }}
                                >
                                    <span>{custom_heading?.background_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: custom_heading?.background_colour ? `#${custom_heading?.background_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_custom_heading_BG_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={custom_heading?.background_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("custom_heading", "background_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Custom Heading Cell Margin Top</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={custom_heading?.cell_margin?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("custom_heading", "top", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Custom Heading Cell Margin Left</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={custom_heading?.cell_margin?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("custom_heading", "left", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Custom Heading Cell Margin Right</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={custom_heading?.cell_margin?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("custom_heading", "right", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Custom Heading Cell Margin Bottom</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={custom_heading?.cell_margin?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("custom_heading", "bottom", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Heading Font Name </label>
                            <div className="font-select">
                                <select
                                    // className={`dropdown ${
                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                    //         ? "error-border"
                                    //         : ""
                                    // }`}
                                    className="dropdown"
                                    value={table_heading?.font_id || "-"}
                                    // // name="table_style"
                                    onChange={e => {
                                        setSortedRecommendationProperty("table_heading", "font_id", e.target.value);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                    {/* <option value="">Select</option> */}
                                    {fontFamilyData.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Heading Font Size</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={table_heading?.font_size}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("table_heading", "font_size", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Heading Font Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("table_heading", "font_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_table_heading_font_color" ? null : "table_sort_table_heading_font_color"
                                        );
                                    }}
                                >
                                    <span>{table_heading?.font_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: table_heading?.font_colour ? `#${table_heading?.font_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_table_heading_font_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={table_heading?.font_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("table_heading", "font_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Heading Background Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("table_heading", "background_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_table_heading_BG_color" ? null : "table_sort_table_heading_BG_color"
                                        );
                                    }}
                                >
                                    <span>{table_heading?.background_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: table_heading?.background_colour ? `#${table_heading?.background_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_table_heading_BG_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={table_heading?.background_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("table_heading", "background_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Heading Border Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("table_heading", "border_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_table_heading_border_colour"
                                                ? null
                                                : "table_sort_table_heading_border_colour"
                                        );
                                    }}
                                >
                                    <span>{table_heading?.border_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: table_heading?.border_colour ? `#${table_heading?.border_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_table_heading_border_colour" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={table_heading?.border_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("table_heading", "border_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Heading Border</label>
                            <div className={`input-ft-size`}>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={table_heading?.border?.left}
                                        onChange={e => {
                                            setSortedRecommendationProperty("table_heading", "left", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Left</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={table_heading?.border?.right}
                                        onChange={e => {
                                            setSortedRecommendationProperty("table_heading", "right", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Right</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={table_heading?.border?.top}
                                        onChange={e => {
                                            setSortedRecommendationProperty("table_heading", "top", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Top</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={table_heading?.border?.bottom}
                                        onChange={e => {
                                            setSortedRecommendationProperty("table_heading", "bottom", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Bottom</label>
                                </span>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Heading Left Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!table_heading?.border?.left ? "cursor-diabled" : ""}`}
                                    value={table_heading?.border_width?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("table_heading", "left", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!table_heading?.border?.left}
                                />
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Heading Right Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!table_heading?.border?.right ? "cursor-diabled" : ""}`}
                                    value={table_heading?.border_width?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("table_heading", "right", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!table_heading?.border?.right}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Heading Top Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!table_heading?.border?.top ? "cursor-diabled" : ""}`}
                                    value={table_heading?.border_width?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("table_heading", "top", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!table_heading?.border?.top}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Heading Bottom Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!table_heading?.border?.bottom ? "cursor-diabled" : ""}`}
                                    value={table_heading?.border_width?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("table_heading", "bottom", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!table_heading?.border?.bottom}
                                />
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Heading Cell Margin Top</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={table_heading?.cell_margin?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("table_heading", "top", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Heading Cell Margin Left</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={table_heading?.cell_margin?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("table_heading", "left", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Heading Cell Margin Right</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={table_heading?.cell_margin?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("table_heading", "right", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Heading Cell Margin Bottom</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={table_heading?.cell_margin?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("table_heading", "bottom", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Body Font Name </label>
                            <div className="font-select">
                                <select
                                    // className={`dropdown ${
                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                    //         ? "error-border"
                                    //         : ""
                                    // }`}tex
                                    className="dropdown"
                                    value={body?.font_id || "-"}
                                    // // name="table_style"
                                    onChange={e => {
                                        setSortedRecommendationProperty("body", "font_id", e.target.value);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                    {fontFamilyData.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Font Size</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={body?.font_size}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("body", "font_size", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                    // className={`${
                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_size
                                    //         ? "error-border"
                                    //         : ""
                                    // }`}
                                />
                            </div>
                        </div>
                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Body Font Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("body", "font_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(colorModalState === "table_sort_body_font_color" ? null : "table_sort_body_font_color");
                                    }}
                                >
                                    <span>{body?.font_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: body?.font_colour ? `#${body?.font_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_body_font_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={body?.font_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("body", "font_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Body Border Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("body", "border_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_body_border_colour" ? null : "table_sort_body_border_colour"
                                        );
                                    }}
                                >
                                    <span>{body?.border_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: body?.border_colour ? `#${body?.border_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_body_border_colour" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={body?.border_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("body", "border_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Body Border</label>
                            <div className={`input-ft-size`}>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={body?.border?.left}
                                        onChange={e => {
                                            setSortedRecommendationProperty("body", "left", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Left</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={body?.border?.right}
                                        onChange={e => {
                                            setSortedRecommendationProperty("body", "right", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Right</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={body?.border?.top}
                                        onChange={e => {
                                            setSortedRecommendationProperty("body", "top", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Top</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={body?.border?.bottom}
                                        onChange={e => {
                                            setSortedRecommendationProperty("body", "bottom", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Bottom</label>
                                </span>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Left Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!body?.border?.left ? "cursor-diabled" : ""}`}
                                    value={body?.border_width?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("body", "left", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!body?.border?.left}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Right Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!body?.border?.right ? "cursor-diabled" : ""}`}
                                    value={body?.border_width?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("body", "right", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!body?.border?.right}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Top Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!body?.border?.top ? "cursor-diabled" : ""}`}
                                    value={body?.border_width?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("body", "top", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!body?.border?.top}
                                />
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Body Bottom Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!body?.border?.bottom ? "cursor-diabled" : ""}`}
                                    value={site?.border_width?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("body", "bottom", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!body?.border?.bottom}
                                />
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Body Cell Margin Top</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={body?.cell_margin?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("body", "top", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Cell Margin Left</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={body?.cell_margin?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("body", "left", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Cell Margin Right</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={body?.cell_margin?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("body", "right", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Cell Margin Bottom</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={body?.cell_margin?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("body", "bottom", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Site Font Name </label>
                            <div className="font-select">
                                <select
                                    // className={`dropdown ${
                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                    //         ? "error-border"
                                    //         : ""
                                    // }`}
                                    className="dropdown"
                                    value={site?.font_id || "-"}
                                    // // name="table_style"
                                    onChange={e => {
                                        setSortedRecommendationProperty("site", "font_id", e.target.value);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                    {/* <option value="">Select</option> */}
                                    {fontFamilyData.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Site Font Size</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={site?.font_size}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("site", "font_size", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Site Font Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("site", "font_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(colorModalState === "table_sort_site_font_color" ? null : "table_sort_site_font_color");
                                    }}
                                >
                                    <span>{site?.font_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: site?.font_colour ? `#${site?.font_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_site_font_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={site?.font_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("site", "font_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Site Background Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("site", "background_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(colorModalState === "table_sort_site_BG_color" ? null : "table_sort_site_BG_color");
                                    }}
                                >
                                    <span>{site?.background_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: site?.background_colour ? `#${site?.background_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_site_BG_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={site?.background_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("site", "background_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Site Border Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("site", "border_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_site_border_colour" ? null : "table_sort_site_border_colour"
                                        );
                                    }}
                                >
                                    <span>{site?.border_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: site?.border_colour ? `#${site?.border_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_site_border_colour" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={site?.border_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("site", "border_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Site Border</label>
                            <div className={`input-ft-size`}>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={site?.border?.left}
                                        onChange={e => {
                                            setSortedRecommendationProperty("site", "left", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Left</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={site?.border?.right}
                                        onChange={e => {
                                            setSortedRecommendationProperty("site", "right", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Right</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={site?.border?.top}
                                        onChange={e => {
                                            setSortedRecommendationProperty("site", "top", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Top</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={site?.border?.bottom}
                                        onChange={e => {
                                            setSortedRecommendationProperty("site", "bottom", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Bottom</label>
                                </span>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label> Site Left Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!site?.border?.left ? "cursor-diabled" : ""}`}
                                    value={site?.border_width?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("site", "left", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!site?.border?.left}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Site Right Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!site?.border?.right ? "cursor-diabled" : ""}`}
                                    value={site?.border_width?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("site", "right", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!site?.border?.right}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Site Top Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!site?.border?.top ? "cursor-diabled" : ""}`}
                                    value={site?.border_width?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("site", "top", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!site?.border?.top}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Site Bottom Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!site?.border?.bottom ? "cursor-diabled" : ""}`}
                                    value={site?.border_width?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("site", "bottom", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!site?.border?.bottom}
                                />
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Site Cell Margin Top</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={site?.cell_margin?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("site", "top", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Site Cell Margin Left</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={site?.cell_margin?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("site", "left", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Site Cell Margin Right</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={site?.cell_margin?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("site", "right", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Site Cell Margin Bottom</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={site?.cell_margin?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("site", "bottom", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Building Font Name </label>
                            <div className="font-select">
                                <select
                                    // className={`dropdown ${
                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                    //         ? "error-border"
                                    //         : ""
                                    // }`}
                                    className="dropdown"
                                    value={building?.font_id || "-"}
                                    // // name="table_style"
                                    onChange={e => {
                                        setSortedRecommendationProperty("building", "font_id", e.target.value);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                    {/* <option value="">Select</option> */}
                                    {fontFamilyData.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Building Font Size</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={building?.font_size}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("building", "font_size", values.floatValuee);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Building Font Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("building", "font_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_building_font_color" ? null : "table_sort_building_font_color"
                                        );
                                    }}
                                >
                                    <span>{building?.font_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: building?.font_colour ? `#${building?.font_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_building_font_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={building?.font_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("building", "font_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Building Background Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("building", "background_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_building_BG_color" ? null : "table_sort_building_BG_color"
                                        );
                                    }}
                                >
                                    <span>{building?.background_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: building?.background_colour ? `#${building?.background_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_building_BG_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={building?.background_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("building", "background_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Building Border Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("building", "border_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_building_border_colour" ? null : "table_sort_building_border_colour"
                                        );
                                    }}
                                >
                                    <span>{building?.border_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: building?.border_colour ? `#${building?.border_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_building_border_colour" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={building?.border_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("building", "border_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Building Border</label>
                            <div className={`input-ft-size`}>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={building?.border?.left}
                                        onChange={e => {
                                            setSortedRecommendationProperty("building", "left", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Left</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={building?.border?.right}
                                        onChange={e => {
                                            setSortedRecommendationProperty("building", "right", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Right</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={building?.border?.top}
                                        onChange={e => {
                                            setSortedRecommendationProperty("building", "top", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Top</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={building?.border?.bottom}
                                        onChange={e => {
                                            setSortedRecommendationProperty("building", "bottom", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Bottom</label>
                                </span>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label> Building Left Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!building?.border?.left ? "cursor-diabled" : ""}`}
                                    value={building?.border_width?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("building", "left", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!building?.border?.left}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Building Right Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!building?.border?.right ? "cursor-diabled" : ""}`}
                                    value={building?.border_width?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("building", "right", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!building?.border?.right}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Building Top Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!building?.border?.top ? "cursor-diabled" : ""}`}
                                    value={building?.border_width?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("building", "top", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!building?.border?.top}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Building Bottom Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!building?.border?.bottom ? "cursor-diabled" : ""}`}
                                    value={building?.border_width?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("building", "bottom", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!building?.border?.bottom}
                                />
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Building Cell Margin Top</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={building?.cell_margin?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("building", "top", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Building Cell Margin Left</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={building?.cell_margin?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("building", "left", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Building Cell Margin Right</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={building?.cell_margin?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("building", "right", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Building Cell Margin Bottom</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={building?.cell_margin?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("building", "bottom", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Addition Font Name </label>
                            <div className="font-select">
                                <select
                                    // className={`dropdown ${
                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                    //         ? "error-border"
                                    //         : ""
                                    // }`}
                                    className="dropdown"
                                    value={addition?.font_id || "-"}
                                    // // name="table_style"
                                    onChange={e => {
                                        setSortedRecommendationProperty("addition", "font_id", e.target.value);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                    {/* <option value="">Select</option> */}
                                    {fontFamilyData.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Addition Font Size</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={addition?.font_size}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("addition", "font_size", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Addition Font Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("addition", "font_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_addition_font_color" ? null : "table_sort_addition_font_color"
                                        );
                                    }}
                                >
                                    <span>{addition?.font_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: addition?.font_colour ? `#${addition?.font_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_addition_font_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={addition?.font_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("addition", "font_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Addition Background Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("addition", "background_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_addition_BG_color" ? null : "table_sort_addition_BG_color"
                                        );
                                    }}
                                >
                                    <span>{addition?.background_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: addition?.background_colour ? `#${addition?.background_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_addition_BG_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={addition?.background_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("addition", "background_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Addition Border Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("addition", "border_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_addition_border_colour" ? null : "table_sort_addition_border_colour"
                                        );
                                    }}
                                >
                                    <span>{addition?.border_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: addition?.border_colour ? `#${addition?.border_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_addition_border_colour" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={addition?.border_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("addition", "border_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Addition Border</label>
                            <div className={`input-ft-size`}>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={addition?.border?.left}
                                        onChange={e => {
                                            setSortedRecommendationProperty("addition", "left", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Left</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={addition?.border?.right}
                                        onChange={e => {
                                            setSortedRecommendationProperty("addition", "right", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Right</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={addition?.border?.top}
                                        onChange={e => {
                                            setSortedRecommendationProperty("addition", "top", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Top</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={addition?.border?.bottom}
                                        onChange={e => {
                                            setSortedRecommendationProperty("addition", "bottom", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Bottom</label>
                                </span>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label> Addition Left Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!addition?.border?.left ? "cursor-diabled" : ""}`}
                                    value={addition?.border_width?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("addition", "left", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!addition?.border?.left}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Addition Right Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!addition?.border?.right ? "cursor-diabled" : ""}`}
                                    value={addition?.border_width?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("addition", "right", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!addition?.border?.right}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Addition Top Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!addition?.border?.top ? "cursor-diabled" : ""}`}
                                    value={addition?.border_width?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("addition", "top", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!addition?.border?.top}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Addition Bottom Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!addition?.border?.bottom ? "cursor-diabled" : ""}`}
                                    value={addition?.border_width?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("addition", "bottom", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!addition?.border?.bottom}
                                />
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Addition Cell Margin Top</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={addition?.cell_margin?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("addition", "top", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Addition Cell Margin Left</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={addition?.cell_margin?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("addition", "left", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Addition Cell Margin Right</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={addition?.cell_margin?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("addition", "right", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Addition Cell Margin Bottom</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={addition?.cell_margin?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("addition", "bottom", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Grand Total Font Name </label>
                            <div className="font-select">
                                <select
                                    // className={`dropdown ${
                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                    //         ? "error-border"
                                    //         : ""
                                    // }`}
                                    className="dropdown"
                                    value={grand_total?.font_id || "-"}
                                    // // name="table_style"
                                    onChange={e => {
                                        setSortedRecommendationProperty("grand_total", "font_id", e.target.value);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                    {/* <option value="">Select</option> */}
                                    {fontFamilyData.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Grand Total Font Size</label>

                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={grand_total?.font_size}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("grand_total", "font_size", values.floatValue);
                                    }}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Grand Total Font Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("grand_total", "font_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_grand_total_font_color" ? null : "table_sort_grand_total_font_color"
                                        );
                                    }}
                                >
                                    <span>{grand_total?.font_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: grand_total?.font_colour ? `#${grand_total?.font_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_grand_total_font_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={grand_total?.font_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("grand_total", "font_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Grand Total Background Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("grand_total", "background_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_grand_total_BG_color" ? null : "table_sort_grand_total_BG_color"
                                        );
                                    }}
                                >
                                    <span>{grand_total?.background_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: grand_total?.background_colour ? `#${grand_total?.background_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_grand_total_BG_color" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={grand_total?.background_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("grand_total", "background_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Grand Total Border Color </label>
                            </div>

                            <div
                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                className="dropdown"
                            >
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => {
                                        setSortedRecommendationProperty("grand_total", "border_colour", "");
                                    }}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(
                                            colorModalState === "table_sort_grand_total_border_colour" ? null : "table_sort_grand_total_border_colour"
                                        );
                                    }}
                                >
                                    <span>{grand_total?.border_colour || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: grand_total?.border_colour ? `#${grand_total?.border_colour}` : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_sort_grand_total_border_colour" && (
                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                        <CompactPicker
                                            className="picker"
                                            colors={fontColor}
                                            color={grand_total?.border_colour}
                                            onChangeComplete={color => {
                                                setSortedRecommendationProperty("grand_total", "border_colour", color.hex?.replace("#", ""));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Grand Total Border</label>
                            <div className={`input-ft-size`}>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={grand_total?.border?.left}
                                        onChange={e => {
                                            setSortedRecommendationProperty("grand_total", "left", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Left</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={grand_total?.border?.right}
                                        onChange={e => {
                                            setSortedRecommendationProperty("grand_total", "right", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Right</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={grand_total?.border?.top}
                                        onChange={e => {
                                            setSortedRecommendationProperty("grand_total", "top", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Top</label>
                                </span>
                                <span className="check-align">
                                    <input
                                        type="checkbox"
                                        checked={grand_total?.border?.bottom}
                                        onChange={e => {
                                            setSortedRecommendationProperty("grand_total", "bottom", e.target.checked, "border", "border_width");
                                        }}
                                    ></input>
                                    <label>Bottom</label>
                                </span>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label> Grand Total Left Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!grand_total?.border?.left ? "cursor-diabled" : ""}`}
                                    value={grand_total?.border_width?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("grand_total", "left", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!grand_total?.border?.left}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Grand Total Right Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!grand_total?.border?.right ? "cursor-diabled" : ""}`}
                                    value={grand_total?.border_width?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("grand_total", "right", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!grand_total?.border?.right}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Grand Total Top Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!grand_total?.border?.top ? "cursor-diabled" : ""}`}
                                    value={grand_total?.border_width?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("grand_total", "top", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!grand_total?.border?.top}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Grand Total Bottom Border Width</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    className={` ${!grand_total?.border?.bottom ? "cursor-diabled" : ""}`}
                                    value={grand_total?.border_width?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("grand_total", "bottom", values.floatValue, "border_width");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                    disabled={!grand_total?.border?.bottom}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Grand Total Cell Margin Top</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={grand_total?.cell_margin?.top}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("grand_total", "top", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Grand Total Cell Margin Left</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={grand_total?.cell_margin?.left}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("grand_total", "left", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Grand Total Cell Margin Right</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={grand_total?.cell_margin?.right}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("grand_total", "right", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Grand Total Cell Margin Bottom</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={grand_total?.cell_margin?.bottom}
                                    onValueChange={values => {
                                        setSortedRecommendationProperty("grand_total", "bottom", values.floatValue, "cell_margin");
                                    }}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="styles-box">
                <h2>Table Column Configuration</h2>
                <DragDropContext onDragEnd={onColumnDnd}>
                    {Array.from({
                        length: totalRowsForColumnDnd
                    }).map((rowData, i) => (
                        <Droppable droppableId={`droppable-${i}`} direction="horizontal">
                            {provided => (
                                <div ref={provided.innerRef} className="styles-sec">
                                    <div className="d-flex f-outer">
                                        {sortedRecomProperty?.properties?.slice(i * 4, (i + 1) * 4)?.map((columnData, index) => (
                                            <Draggable
                                                key={`draggable-${i}${index}`}
                                                draggableId={`draggable-${i}${index}`}
                                                index={i * 4 + index}
                                                isDragDisabled={columnData.key === "sl_no"}
                                            >
                                                {provided => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        key={`draggable-${index}`}
                                                        className="font-family font-st col-md-3"
                                                    >
                                                        <div className="col-md-12 box-otr box-wide tabl-clm">
                                                            <div className="rem-txt">
                                                                <label className="container-check">
                                                                    {columnData?.heading || "-"}
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={columnData?.is_active}
                                                                        disabled={columnData.key === "sl_no"}
                                                                        onChange={() => {
                                                                            setSortedRecommendationTableColumnConfig(
                                                                                columnData?.key,
                                                                                "is_active",
                                                                                !columnData.is_active
                                                                            );
                                                                        }}
                                                                    />
                                                                    <span
                                                                        className={`checkmark ${columnData?.key === "sl_no" ? "cursor-diabled" : ""}`}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className="right-box-content">
                                                                <div class="input-box">
                                                                    <NumberFormat
                                                                        autoComplete={"nope"}
                                                                        className="custom-input form-control"
                                                                        value={columnData?.width}
                                                                        suffix={" inch"}
                                                                        thousandSeparator={true}
                                                                        onValueChange={values => {
                                                                            const { value } = values;
                                                                            setSortedRecommendationTableColumnConfig(
                                                                                columnData?.key,
                                                                                "width",
                                                                                Number(value)
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="image-drag">
                                                                    <img src="/img/Group 2.svg" alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    ))}
                </DragDropContext>
            </div>
            <div className="styles-box">
                {/* <h2>Table Column Configuration</h2> */}
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-size font-st col-md-3">
                            <label>Starting Year Count</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={sortedRecomProperty?.year?.start_count}
                                    onValueChange={values => setSortedRecommendationProperty("yearsValue", "start_count", values.value)}
                                    allowNegative={false}
                                    decimalScale={0}
                                    className={`${
                                        showErrorBorder &&
                                        (!sortedRecomProperty?.year?.start_count ||
                                            sortedRecomProperty?.year?.start_count > sortedRecomProperty?.year?.end_count)
                                            ? "error-border"
                                            : ""
                                    }`}
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Ending Year Count</label>
                            <div className={`input-ft-size `}>
                                <NumberFormat
                                    value={sortedRecomProperty?.year?.end_count}
                                    onValueChange={values => setSortedRecommendationProperty("yearsValue", "end_count", values.value)}
                                    allowNegative={false}
                                    decimalScale={0}
                                    className={`${
                                        showErrorBorder &&
                                        (!sortedRecomProperty?.year?.end_count ||
                                            sortedRecomProperty?.year?.start_count > sortedRecomProperty?.year?.end_count)
                                            ? "error-border "
                                            : ""
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SortedRecommendationForm;
