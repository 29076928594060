import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { reorderArray } from "../../../config/utils";
import ReactTooltip from "react-tooltip";

const RecommendationPropertyForm = ({
    fontFamilyData = [],
    tableStyleData = [],
    setNormalRecomProperty,
    normalRecomProperty = {},
    setNormalRecommendationTableColumnConfig,
    onRecommendationColumnOrderChange,
    showErrorBorder,
    isAsset
}) => {
    const [colorModalState, setColorModalState] = useState(null);

    const styles = reactCSS({
        default: {
            color: {
                width: "40px",
                height: "15px",
                borderRadius: "3px",
                zoom: "107%"
            },
            popover: {
                position: "absolute",
                zIndex: "3",
                left: "111px",
                top: "-76px"
            },
            cover: {
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px"
            },
            swatch: {
                padding: "6px",
                background: "#ffffff",
                borderRadius: "2px",
                cursor: "pointer",
                display: "inline-block",
                boxShadow: "0 0 0 1px rgba(0,0,0,.2)"
            }
        }
    });

    const onColumnDnd = result => {
        if (!result.destination) {
            return;
        }
        const keyList = reorderArray(normalRecomProperty?.properties, result.source.index, result.destination.index);
        onRecommendationColumnOrderChange(keyList, isAsset);
    };

    let totalRowsForColumnDnd = normalRecomProperty?.properties?.length
        ? Math.trunc(normalRecomProperty.properties.length / 4) + (normalRecomProperty.properties.length % 4 ? 1 : 0)
        : 0;
    return (
        <>
            <div className="styles-box">
                <h2>Table Properties</h2>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Table Style </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={normalRecomProperty?.table?.table_style_id || "-"}
                                    onChange={e => {
                                        let value = e.target.value;
                                        setNormalRecomProperty(null, "table_style_id", value, isAsset);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {tableStyleData.map(f => (
                                        <option value={f.id} key={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-family font-st col-md-3">
                            <label>
                                Table Page Fit{" "}
                                <i
                                    className="fa fa-info info-ft-st"
                                    data-for="table_page_fit"
                                    data-tip="True- Fit table to page layout</br> False- Auto Adjust columns based on content"
                                />
                            </label>

                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    name="tag"
                                    value={normalRecomProperty?.table?.table_page_fit}
                                    onChange={e => {
                                        const value = e.target.value === "true";
                                        setNormalRecomProperty(null, "table_page_fit", value, isAsset);
                                    }}
                                >
                                    {" "}
                                    <option value="">Select</option>
                                    <option value={"true"}>True</option>
                                    <option value={"false"}>False</option>
                                </select>
                            </div>
                            <ReactTooltip
                                className="rc-tooltip-custom-class tool-format-bx"
                                id="table_page_fit"
                                backgroundColor="#007bff"
                                place="top"
                                effect="solid"
                                html={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Custom Heading Font Name</label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={normalRecomProperty?.table?.custom_head?.font_id || "-"}
                                    onChange={e => setNormalRecomProperty("custom_head", "font_id", e.target.value, isAsset)}
                                >
                                    <option value="">Select</option>
                                    {fontFamilyData.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Custom Heading Font Size</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.custom_head?.font_size}
                                    onValueChange={values => setNormalRecomProperty("custom_head", "font_size", values.floatValue, isAsset)}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Custom Heading Font Color </label>
                            </div>
                            <div className="dropdown">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => setNormalRecomProperty("custom_head", "font_color", "", isAsset)}
                                >
                                    <i class="fas fa-times "></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() =>
                                        setColorModalState(prevColorModalState =>
                                            prevColorModalState === "custom_heading_font_color" ? null : "custom_heading_font_color"
                                        )
                                    }
                                >
                                    <span>{normalRecomProperty?.table?.custom_head?.font_color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: normalRecomProperty?.table?.custom_head?.font_color
                                                ? `#${normalRecomProperty?.table?.custom_head?.font_color}`
                                                : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "custom_heading_font_color" && (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => setColorModalState(null)} />
                                        <SketchPicker
                                            position="right"
                                            color={
                                                normalRecomProperty?.table?.custom_head?.font_color === "#000000"
                                                    ? ""
                                                    : normalRecomProperty?.table?.custom_head?.font_color
                                            }
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color =>
                                                setNormalRecomProperty("custom_head", "font_color", color.hex?.replace("#", ""), isAsset)
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Custom Heading Background Color </label>
                            </div>

                            <div className="dropdown sketch-wide">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => setNormalRecomProperty("custom_head", "bg_color", "", isAsset)}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() =>
                                        setColorModalState(prevColorModalState =>
                                            prevColorModalState === "custom_heading_BG_color" ? null : "custom_heading_BG_color"
                                        )
                                    }
                                >
                                    <span>{normalRecomProperty?.table?.custom_head?.bg_color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: normalRecomProperty?.table?.custom_head?.bg_color
                                                ? `#${normalRecomProperty?.table?.custom_head?.bg_color}`
                                                : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "custom_heading_BG_color" && (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => setColorModalState(null)} />
                                        <SketchPicker
                                            position="right"
                                            color={normalRecomProperty?.table?.custom_head?.bg_color}
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color =>
                                                setNormalRecomProperty("custom_head", "bg_color", color.hex?.replace("#", ""), isAsset)
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Custom Heading Font Alignment</label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    name="tag"
                                    value={normalRecomProperty?.table?.custom_head?.alignment}
                                    onChange={e => setNormalRecomProperty("custom_head", "alignment", e.target.value, isAsset)}
                                >
                                    <option value="">Select</option>
                                    <option value={"left"}>Left</option>
                                    <option value={"center"}>Center</option>
                                    <option value={"right"}>Right</option>
                                </select>
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Custom Heading Cell Margin Top</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.custom_head?.cell_margin?.top}
                                    onValueChange={values => setNormalRecomProperty("custom_head", "top", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Custom Heading Cell Margin Left</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.custom_head?.cell_margin?.left}
                                    onValueChange={values => setNormalRecomProperty("custom_head", "left", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Custom Heading Cell Margin Right</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.custom_head?.cell_margin?.right}
                                    onValueChange={values =>
                                        setNormalRecomProperty("custom_head", "right", values.floatValue, isAsset, "cell_margin")
                                    }
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Custom Heading Cell Margin Bottom</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.custom_head?.cell_margin?.bottom}
                                    onValueChange={values =>
                                        setNormalRecomProperty("custom_head", "bottom", values.floatValue, isAsset, "cell_margin")
                                    }
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Custom Blank Line Heading</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.custom_head?.blank_line_height}
                                    onValueChange={values => setNormalRecomProperty("custom_head", "blank_line_height", values?.floatValue, isAsset)}
                                    allowNegative={false}
                                    suffix=" pt"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Heading Font Name </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={normalRecomProperty?.table?.heading?.font_id || "-"}
                                    onChange={e => setNormalRecomProperty("heading", "font_id", e.target.value, isAsset)}
                                >
                                    <option value="">Select</option>
                                    {fontFamilyData.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Heading Font Size</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.heading?.font_size}
                                    onValueChange={values => setNormalRecomProperty("heading", "font_size", values.floatValue, isAsset)}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Heading Font Color </label>
                            </div>

                            <div className="dropdown">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => setNormalRecomProperty("heading", "font_color", "", isAsset)}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(prevColorModalState =>
                                            prevColorModalState === "table_heading_font_color" ? null : "table_heading_font_color"
                                        );
                                    }}
                                >
                                    <span>{normalRecomProperty?.table?.heading?.font_color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: normalRecomProperty?.table?.heading?.font_color
                                                ? `#${normalRecomProperty?.table?.heading?.font_color}`
                                                : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_heading_font_color" && (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => setColorModalState(null)} />
                                        <SketchPicker
                                            position="right"
                                            color={normalRecomProperty?.table?.heading?.font_color}
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color => setNormalRecomProperty("heading", "font_color", color.hex?.replace("#", ""), isAsset)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Heading Background Color </label>
                            </div>

                            <div className="dropdown sketch-wide">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => setNormalRecomProperty("heading", "bg_color", "", isAsset)}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(prevColorModalState =>
                                            prevColorModalState === "table_heading_BG_color" ? null : "table_heading_BG_color"
                                        );
                                    }}
                                >
                                    <span>{normalRecomProperty?.table?.heading?.bg_color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: normalRecomProperty?.table?.heading?.bg_color
                                                ? `#${normalRecomProperty?.table?.heading?.bg_color}`
                                                : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "table_heading_BG_color" && (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => setColorModalState(null)} />
                                        <SketchPicker
                                            position="right"
                                            color={normalRecomProperty?.table?.heading?.bg_color}
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color => setNormalRecomProperty("heading", "bg_color", color.hex?.replace("#", ""), isAsset)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Heading Font Alignment</label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    name="tag"
                                    value={normalRecomProperty?.table?.heading?.alignment}
                                    onChange={e => setNormalRecomProperty("heading", "alignment", e.target.value, isAsset)}
                                >
                                    <option value="">Select</option>
                                    <option value={"left"}>Left</option>
                                    <option value={"center"}>Center</option>
                                    <option value={"right"}>Right</option>
                                </select>
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Heading Cell Margin Top</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.heading?.cell_margin?.top}
                                    onValueChange={values => setNormalRecomProperty("heading", "top", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Heading Cell Margin Left</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.heading?.cell_margin?.left}
                                    onValueChange={values => setNormalRecomProperty("heading", "left", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Heading Cell Margin Right</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.heading?.cell_margin?.right}
                                    onValueChange={values => setNormalRecomProperty("heading", "right", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Heading Cell Margin Bottom</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.heading?.cell_margin?.bottom}
                                    onValueChange={values => setNormalRecomProperty("heading", "bottom", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Body Font Name </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={normalRecomProperty?.table?.data?.font_id || "-"}
                                    onChange={e => setNormalRecomProperty("data", "font_id", e.target.value, isAsset)}
                                >
                                    <option value="">Select</option>
                                    {fontFamilyData.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Font Size</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.data?.font_size}
                                    onValueChange={values => setNormalRecomProperty("data", "font_size", values.floatValue, isAsset)}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Body Font Color </label>
                            </div>

                            <div className="dropdown">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => setNormalRecomProperty("data", "font_color", "", isAsset)}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(prevColorModalState =>
                                            prevColorModalState === "body_font_color" ? null : "body_font_color"
                                        );
                                    }}
                                >
                                    <span>{normalRecomProperty?.table?.data?.font_color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: normalRecomProperty?.table?.data?.font_color
                                                ? `#${normalRecomProperty?.table?.data?.font_color}`
                                                : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "body_font_color" && (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => setColorModalState(null)} />
                                        <SketchPicker
                                            position="right"
                                            color={normalRecomProperty?.table?.data?.font_color}
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color => setNormalRecomProperty("data", "font_color", color.hex?.replace("#", ""), isAsset)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Font Alignment</label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    name="tag"
                                    value={normalRecomProperty?.table?.data?.alignment}
                                    onChange={e => setNormalRecomProperty("data", "alignment", e.target.value, isAsset)}
                                >
                                    <option value="">Select</option>
                                    <option value={"left"}>Left</option>
                                    <option value={"center"}>Center</option>
                                    <option value={"right"}>Right</option>
                                </select>
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Body Cell Margin Top</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.data?.cell_margin?.top}
                                    onValueChange={values => setNormalRecomProperty("data", "top", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Cell Margin Left</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.data?.cell_margin?.left}
                                    onValueChange={values => setNormalRecomProperty("data", "left", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Cell Margin Right</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.data?.cell_margin?.right}
                                    onValueChange={values => setNormalRecomProperty("data", "right", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Body Cell Margin Bottom</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.data?.cell_margin?.bottom}
                                    onValueChange={values => setNormalRecomProperty("data", "bottom", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="styles-sec">
                    <div className="d-flex f-outer">
                        <div className="font-family font-st col-md-3">
                            <label>Sub Total Font Name </label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    value={normalRecomProperty?.table?.sub_total?.font_id || "-"}
                                    onChange={e => setNormalRecomProperty("sub_total", "font_id", e.target.value, isAsset)}
                                >
                                    <option value="">Select</option>
                                    {fontFamilyData.map(f => (
                                        <option key={f.id} value={f.id}>
                                            {f.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Sub Total Font Size</label>

                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.sub_total?.font_size}
                                    onValueChange={values => setNormalRecomProperty("sub_total", "font_size", values.floatValue, isAsset)}
                                    allowNegative={false}
                                    suffix=" Pt"
                                />
                            </div>
                        </div>
                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Sub Total Font Color </label>
                            </div>

                            <div className="dropdown">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => setNormalRecomProperty("sub_total", "font_color", "", isAsset)}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(prevColorModalState =>
                                            prevColorModalState === "grand_total_font_color" ? null : "grand_total_font_color"
                                        );
                                    }}
                                >
                                    <span>{normalRecomProperty?.table?.sub_total?.font_color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: normalRecomProperty?.table?.sub_total?.font_color
                                                ? `#${normalRecomProperty?.table?.sub_total?.font_color}`
                                                : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "grand_total_font_color" && (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => setColorModalState(null)} />
                                        <SketchPicker
                                            position="right"
                                            color={normalRecomProperty?.table?.sub_total?.font_color}
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color =>
                                                setNormalRecomProperty("sub_total", "font_color", color.hex?.replace("#", ""), isAsset)
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="font-color font-st col-md-3">
                            <div className="head_sub">
                                <label>Sub Total Background Color </label>
                            </div>

                            <div className="dropdown sketch-wide">
                                <span
                                    className="arrow_close"
                                    style={{ cursor: "pointer" }}
                                    onClick={e => setNormalRecomProperty("sub_total", "bg_color", "", isAsset)}
                                >
                                    <i class="fas fa-times"></i>
                                </span>
                                <div
                                    className="dropdown-toggle"
                                    type="button"
                                    onClick={() => {
                                        setColorModalState(prevColorModalState =>
                                            prevColorModalState === "grand_total_BG_color" ? null : "grand_total_BG_color"
                                        );
                                    }}
                                >
                                    <span>{normalRecomProperty?.table?.sub_total?.bg_color || "Select"}</span>
                                    <span
                                        className="color-bx"
                                        style={{
                                            background: normalRecomProperty?.table?.sub_total?.bg_color
                                                ? `#${normalRecomProperty?.table?.sub_total?.bg_color}`
                                                : "#fff"
                                        }}
                                    ></span>
                                    <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                </div>
                                {colorModalState === "grand_total_BG_color" && (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => setColorModalState(null)} />
                                        <SketchPicker
                                            position="right"
                                            color={normalRecomProperty?.table?.sub_total?.bg_color}
                                            presetColors={["#95cd50", "#ffe242", "#ffa105", "#ff0305", "#0018A8", "#800080", "#3E8EDE", "#417505"]}
                                            onChange={color => setNormalRecomProperty("sub_total", "bg_color", color.hex?.replace("#", ""), isAsset)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Sub Total Font Alignment</label>
                            <div className="font-select">
                                <select
                                    className="dropdown"
                                    name="tag"
                                    value={normalRecomProperty?.table?.sub_total?.alignment}
                                    onChange={e => setNormalRecomProperty("sub_total", "alignment", e.target.value, isAsset)}
                                >
                                    <option value="">Select</option>
                                    <option value={"left"}>Left</option>
                                    <option value={"center"}>Center</option>
                                    <option value={"right"}>Right</option>
                                </select>
                            </div>
                        </div>

                        <div className="font-size font-st col-md-3">
                            <label>Sub Total Cell Margin Top</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.sub_total?.cell_margin?.top}
                                    onValueChange={values => setNormalRecomProperty("sub_total", "top", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Sub Total Cell Margin Left</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.sub_total?.cell_margin?.left}
                                    onValueChange={values => setNormalRecomProperty("sub_total", "left", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Sub Total Cell Margin Right</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.sub_total?.cell_margin?.right}
                                    onValueChange={values => setNormalRecomProperty("sub_total", "right", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                        <div className="font-size font-st col-md-3">
                            <label>Sub Total Cell Margin Bottom</label>
                            <div className={`input-ft-size`}>
                                <NumberFormat
                                    value={normalRecomProperty?.table?.sub_total?.cell_margin?.bottom}
                                    onValueChange={values => setNormalRecomProperty("sub_total", "bottom", values.floatValue, isAsset, "cell_margin")}
                                    allowNegative={false}
                                    suffix=" inch"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="styles-box">
                <h2>Table Column Configuration</h2>
                <DragDropContext onDragEnd={onColumnDnd}>
                    {Array.from({ length: totalRowsForColumnDnd }).map((rowData, i) => (
                        <Droppable droppableId={`droppable-${i}`} direction="horizontal">
                            {provided => (
                                <div ref={provided.innerRef} className="styles-sec">
                                    <div className="d-flex f-outer">
                                        {normalRecomProperty?.properties?.slice(i * 4, (i + 1) * 4)?.map((columnData, index) => (
                                            <Draggable key={`draggable-${i}${index}`} draggableId={`draggable-${i}${index}`} index={i * 4 + index}>
                                                {provided => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        key={`draggable-${index}`}
                                                        className="font-family font-st col-md-3"
                                                    >
                                                        <div className="col-md-12 box-otr box-wide tabl-clm">
                                                            <div className="rem-txt">
                                                                <label className="container-check">
                                                                    {columnData?.heading || "-"}
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={columnData?.is_active}
                                                                        onChange={() =>
                                                                            setNormalRecommendationTableColumnConfig(
                                                                                columnData?.key,
                                                                                "is_active",
                                                                                !columnData.is_active,
                                                                                isAsset
                                                                            )
                                                                        }
                                                                    />
                                                                    <span className="checkmark" />
                                                                </label>
                                                            </div>
                                                            <div className="right-box-content">
                                                                <div class="input-box">
                                                                    <NumberFormat
                                                                        autoComplete={"nope"}
                                                                        className="custom-input form-control"
                                                                        value={columnData?.width}
                                                                        suffix={" inch"}
                                                                        thousandSeparator={true}
                                                                        onValueChange={values => {
                                                                            const { value } = values;
                                                                            setNormalRecommendationTableColumnConfig(
                                                                                columnData?.key,
                                                                                "width",
                                                                                Number(value),
                                                                                isAsset
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="image-drag">
                                                                    <img src="/img/Group 2.svg" alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    ))}
                </DragDropContext>
            </div>
            {!isAsset && (
                <div className="styles-box">
                    {/* <h2>Table Column Configuration</h2> */}
                    <div className="styles-sec">
                        <div className="d-flex f-outer">
                            <div className="font-size font-st col-md-3">
                                <label>Starting Year Count</label>
                                <div className={`input-ft-size`}>
                                    <NumberFormat
                                        value={normalRecomProperty?.year?.start_count}
                                        onValueChange={values => setNormalRecomProperty("yearsValue", "start_count", values.value, isAsset)}
                                        allowNegative={false}
                                        decimalScale={0}
                                        className={`${
                                            showErrorBorder &&
                                            (!normalRecomProperty?.year?.start_count ||
                                                normalRecomProperty?.year?.start_count > normalRecomProperty?.year?.end_count)
                                                ? "error-border "
                                                : ""
                                        }`}
                                    />
                                </div>
                            </div>
                            <div className="font-size font-st col-md-3">
                                <label>Ending Year Count</label>
                                <div className={`input-ft-size `}>
                                    <NumberFormat
                                        value={normalRecomProperty?.year?.end_count}
                                        onValueChange={values => setNormalRecomProperty("yearsValue", "end_count", values.value, isAsset)}
                                        allowNegative={false}
                                        decimalScale={0}
                                        className={`${
                                            showErrorBorder &&
                                            (!normalRecomProperty?.year?.end_count ||
                                                normalRecomProperty?.year?.start_count > normalRecomProperty?.year?.end_count)
                                                ? "error-border "
                                                : ""
                                        }`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RecommendationPropertyForm;
