import React, { useEffect } from "react";
import Iframe from "react-iframe";
import ReactTooltip from "react-tooltip";
import { getFileExtension } from "../../../config/utils";
import { VideoPlayer } from "./VideoPlayer";

const excelExtensions = ["xlsx", "xlsm", "xlsb", "xls"];
const wordExtensions = ["docx", "docm", "doc", "dotx", "dotm"];
const imageExtensions = ["jpg", "jpeg", "png", "gif", "tif", "tiff", "bmp", "webp"];

export const ReportItem = ({ doc, viewSingleReport }) => {
    const extension = getFileExtension(doc?.file_name);
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [doc.id]);

    return (
        <>
            <ReactTooltip id={`${doc.id}description`} effect="solid" className="rc-tooltip-custom-class" backgroundColor="#007bff" />
            <div
                className="pdf-box position-relative"
                key={doc?.id}
                onClick={() => (extension === "pdf" || extension === "mp4" ? viewSingleReport(doc) : null)}
            >
                <h3 className="cursor-hand" data-tip={doc.description} data-place="top" data-effect="solid" data-for={`${doc.id}description`}>
                    {doc?.description}
                </h3>
                {extension === "pdf" ? (
                    <Iframe
                        url={doc?.url || ""}
                        width="95%"
                        height="95%"
                        display="initial"
                        position="relative"
                        className="pdf-view"
                        overflow="hidden"
                    />
                ) : extension === "mp4" ? (
                    <VideoPlayer poster={doc?.poster} publicUrl={doc?.url} />
                ) : (
                    <>
                        <div className="dwnd-outer">
                            {imageExtensions.includes(extension) ? (
                                <img src={doc?.url} alt="" className="dwd-icon" />
                            ) : excelExtensions.includes(extension) ? (
                                <img src="/img/xl-icon.svg" alt="" className="dwd-icon" />
                            ) : (
                                <img src="/img/dwd-grey.svg" alt="" className="dwd-icon" />
                            )}
                        </div>
                        <div
                            className="download-icon-deliv"
                            data-tip="Download File"
                            data-place="top"
                            data-effect="solid"
                            data-for={`${doc.id}description`}
                        >
                            <a href={doc?.url} target="_blank" download>
                                <i className="fas fa-download"></i>
                            </a>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
