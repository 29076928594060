import React from "react";
import qs from "query-string";
import { useLocation } from "react-router-dom";

import BuildModalHeader from "../../common/components/BuildModalHeader";
import { CapitalRequestMain } from "./CapitalRequestMain";

const LinkCRtoRecommendationModal = ({
    onCancel,
    linkSelectedCRs,
    linkCRloader = false,
    selectedCRIds = [],
    handleSelectCR,
    handleSelectAllCR,
    projectIdForSelection = ""
}) => {
    const { search } = useLocation();
    const query = qs.parse(search);
    let projectId = query?.p_id || projectIdForSelection || "";
    return (
        <div
            className="modal assign-init-modal image-pull-modal"
            style={{ display: "block" }}
            id="modalId"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog assignModal" role="document">
                <div className="modal-content">
                    <BuildModalHeader title="Select Capital Request" onCancel={onCancel} modalClass="assignModal" />

                    <form autoComplete="nope">
                        <div className="modal-body ">
                            <div className="form-group">
                                <div className="formInp">
                                    <div className="dashboard-outer">
                                        <div className="outer-detail">
                                            <div className="right-panel-section">
                                                <div className="dtl-sec">
                                                    <div className="dtl-sec system-building col-md-12 ">
                                                        <div className="tab-dtl region-mng">
                                                            <div className="tab-active recomdn-table bg-grey-table modal-table-scroll">
                                                                <CapitalRequestMain
                                                                    isAssignView
                                                                    projectId={projectId}
                                                                    isProjectView
                                                                    isCRSelection
                                                                    selectedCRIds={selectedCRIds}
                                                                    handleSelectCR={handleSelectCR}
                                                                    handleSelectAllCR={handleSelectAllCR}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 p-0 text-right btnOtr">
                                    <button
                                        disabled={!selectedCRIds.length || linkCRloader}
                                        type="button"
                                        onClick={() => linkSelectedCRs()}
                                        className="btn btn-primary btnRgion col-md-2"
                                    >
                                        Assign {linkCRloader && <span className="spinner-border spinner-border-sm pl-2" role="status"></span>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default LinkCRtoRecommendationModal;
