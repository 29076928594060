/* eslint-disable import/no-anonymous-default-export */
import { energy_fields } from "../recommendations/components/Bands/EnergyBand";
import { water_fields } from "../recommendations/components/Bands/WaterBand";
import { bandPositions, bands, qaPassedOptions, qaStatusOptions } from "./components/FilterModal/constants";
import * as actionType from "./constants";
const role = localStorage.getItem("role");
let initialMaterFilters = [
    {
        label: "Trade",
        key: "trades",
        paramKey: "trade_ids",
        exportFilterKey: "trade_id",
        band: bands.recommendation,
        bandPosition: bandPositions.header,
        isRecommendationField: true,
        getListKey: ["trades.id", "trades.name"],
        searchKey: "recommendations.trade_name"
    },
    {
        label: "System",
        key: "system",
        paramKey: "system_ids",
        exportFilterKey: "system_id",
        band: bands.recommendation,
        bandPosition: bandPositions.header,
        isRecommendationField: true,
        getListKey: ["systems.id", "systems.name"],
        searchKey: "recommendations.system_name"
    },
    {
        label: "Sub System",
        key: "sub_system",
        paramKey: "sub_system_ids",
        exportFilterKey: "sub_system_id",
        band: bands.recommendation,
        bandPosition: bandPositions.header,
        isRecommendationField: true,
        getListKey: ["sub_systems.id", "sub_systems.name"],
        searchKey: "recommendations.sub_system_name"
    },
    {
        label: "Recommendation Type",
        key: "recommendation_type",
        paramKey: "recommendation_type",
        exportFilterKey: "recommendation_type",
        band: bands.recommendation,
        bandPosition: bandPositions.body,
        searchKey: "recommendation_type",
        isRecommendationField: true,
        getListKey: ["recommendations.recommendation_type"]
        // options: [
        //     { id: "asset", name: "Asset" },
        //     { id: "building", name: "Building" }
        // ]
    },
    {
        label: "Recommendation",
        key: "description",
        paramKey: "recommendations.description",
        exportFilterKey: "recommendations.description",
        band: bands.recommendation,
        bandPosition: bandPositions.body,
        isWildCardFilter: true,
        fieldType: "text"
    },
    {
        label: "Recommendation Narrative",
        key: "notes",
        paramKey: "recommendations.notes",
        exportFilterKey: "recommendations.notes",
        band: bands.recommendation,
        bandPosition: bandPositions.body,
        isWildCardFilter: true,
        fieldType: "text"
    },
    {
        label: "Regions",
        key: "regions",
        paramKey: "region_ids",
        exportFilterKey: "region_id",
        band: bands.geo_hierarchy,
        bandPosition: bandPositions.header,
        getListKey: ["regions.id", "regions.name"],
        searchKey: "recommendations.region_name"
    },
    {
        label: "Sites",
        key: "sites",
        paramKey: "site_ids",
        exportFilterKey: "site_id",
        band: bands.geo_hierarchy,
        bandPosition: bandPositions.header,
        getListKey: ["sites.id", "sites.name"],
        searchKey: "recommendations.site_name"
    },
    {
        label: "Buildings",
        key: "buildings",
        paramKey: "building_ids",
        exportFilterKey: "building_id",
        band: bands.geo_hierarchy,
        bandPosition: bandPositions.header,
        getListKey: ["buildings.id", "buildings.name"],
        searchKey: "buildings.name"
    },
    {
        label: "B.Types",
        key: "building_types",
        paramKey: "building_types",
        exportFilterKey: "building_type_id",
        band: bands.geo_hierarchy,
        bandPosition: bandPositions.body,
        getListKey: ["main_building_types.id", "main_building_types.name"],
        searchKey: "main_building_types.name"
    },
    {
        label: "Additions",
        key: "additions",
        paramKey: "addition_ids",
        exportFilterKey: "addition_id",
        band: bands.geo_hierarchy,
        bandPosition: bandPositions.body,
        searchKey: "recommendations.addition_name",
        getListKey: ["additions.id", "additions.name"]
    },
    {
        label: "Floors",
        key: "floors",
        paramKey: "floor_ids",
        exportFilterKey: "floor_id",
        band: bands.geo_hierarchy,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "recommendations.floor_name",
        getListKey: ["floors.id", "floors.name"]
    },
    //csp fields
    {
        label: "Project Total",
        key: "project_total",
        paramKey: "recommendations.project_total",
        exportFilterKey: "recommendations.project_total",
        band: bands.capital_spending_plan,
        bandPosition: bandPositions.header,
        isWildCardFilter: true,
        fieldType: "number",
        hasTopNFilterField: true
    },
    {
        label: "Years",
        key: "year",
        paramKey: "year",
        exportFilterKey: "year",
        band: bands.capital_spending_plan,
        bandPosition: bandPositions.header,
        searchKey: "",
        isRecommendationField: true,
        getListKey: ["maintenance_years.year"]
    },
    {
        label: "Unit",
        key: "unit",
        paramKey: "recommendations.unit",
        exportFilterKey: "recommendations.unit",
        band: bands.capital_spending_plan,
        bandPosition: bandPositions.header,
        isWildCardFilter: true,
        fieldType: "text"
    },
    {
        label: "Cost Per Unit",
        key: "cost_per_unit",
        paramKey: "recommendations.cost_per_unit",
        exportFilterKey: "recommendations.cost_per_unit",
        band: bands.capital_spending_plan,
        bandPosition: bandPositions.body,
        isWildCardFilter: true,
        fieldType: "number"
    },
    {
        label: "Quantity",
        key: "quantity",
        paramKey: "recommendations.quantity",
        exportFilterKey: "recommendations.quantity",
        band: bands.capital_spending_plan,
        bandPosition: bandPositions.body,
        isWildCardFilter: true,
        fieldType: "number"
    },
    {
        label: "Opinions of Cost",
        key: "options_cost",
        paramKey: "recommendations.options_cost",
        exportFilterKey: "recommendations.options_cost",
        band: bands.capital_spending_plan,
        bandPosition: bandPositions.body,
        isWildCardFilter: true,
        fieldType: "number"
    },
    //asset fields
    // {
    //     label: "Asset Name",
    //     key: "asset_name",
    //     paramKey: "assets.asset_name",
    //     exportFilterKey: "assets.asset_name",
    //     band: bands.asset,
    //     bandPosition: bandPositions.header,
    //     isWildCardFilter: true,
    //     fieldType: "text"
    // },
    {
        label: "Asset Condition",
        key: "asset_condition",
        paramKey: "asset_condition_ids",
        exportFilterKey: "asset_condition",
        band: bands.asset,
        bandPosition: bandPositions.header,
        isRecommendationField: true,
        searchKey: "condition",
        getListKey: ["asset.condition"]
    },
    {
        label: "Serial Number",
        key: "serial_number",
        paramKey: "assets.serial_number",
        exportFilterKey: "assets.serial_number",
        band: bands.asset,
        bandPosition: bandPositions.header,
        isWildCardFilter: true,
        fieldType: "text"
    },
    {
        label: "Uniformat Level 1",
        key: "uniformat_level_1",
        paramKey: "uniformat_level_1s_ids",
        exportFilterKey: "uniformat_level_1s_ids",
        band: bands.asset,
        bandPosition: bandPositions.header,
        isRecommendationField: true,
        searchKey: "assets.uniformat_level_1s.name",
        getListKey: ["assets.uniformat_level_1s.id", "assets.uniformat_level_1s.name"]
    },
    {
        label: "Uniformat Level 2",
        key: "uniformat_level_2.name",
        paramKey: "uniformat_level_2s_ids",
        exportFilterKey: "uniformat_level_2s_ids",
        band: bands.asset,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "assets.uniformat_level_2s.name",
        getListKey: ["assets.uniformat_level_2s.id", "assets.uniformat_level_2s.name"]
    },
    {
        label: "Uniformat Level 3",
        key: "uniformat_level_3.name",
        paramKey: "uniformat_level_3s_ids",
        exportFilterKey: "uniformat_level_3s_ids",
        band: bands.asset,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "assets.uniformat_level_3s.name",
        getListKey: ["assets.uniformat_level_3s.id", "assets.uniformat_level_3s.name"]
    },
    {
        label: "Uniformat Level 4",
        key: "uniformat_level_4.name",
        paramKey: "uniformat_level_4s_ids",
        exportFilterKey: "uniformat_level_4s_ids",
        band: bands.asset,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "assets.uniformat_level_4s.name",
        getListKey: ["assets.uniformat_level_4s.id", "assets.uniformat_level_4s.name"]
    },
    {
        label: "Uniformat Level 5",
        key: "uniformat_level_5.name",
        paramKey: "uniformat_level_5s_ids",
        exportFilterKey: "uniformat_level_5s_ids",
        band: bands.asset,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "assets.uniformat_level_5s.name",
        getListKey: ["assets.uniformat_level_5s.id", "assets.uniformat_level_5s.name"]
    },
    {
        label: "Uniformat Level 6",
        key: "uniformat_level_6.name",
        paramKey: "uniformat_level_6s_ids",
        exportFilterKey: "uniformat_level_6s_ids",
        band: bands.asset,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "assets.uniformat_level_6s.name",
        getListKey: ["assets.uniformat_level_6s.id", "assets.uniformat_level_6s.name"]
    },
    // {
    //     label: "Asset Tag",
    //     key: "asset_tag",
    //     paramKey: "assets.asset_tag",
    //     exportFilterKey: "assets.asset_tag",
    //     band: bands.asset,
    //     bandPosition: bandPositions.header,
    //     isWildCardFilter: true,
    //     fieldType: "text"
    // },
    // {
    //     label: "Serial Number",
    //     key: "serial_number",
    //     paramKey: "assets.serial_number",
    //     exportFilterKey: "assets.serial_number",
    //     band: bands.asset,
    //     bandPosition: bandPositions.body,
    //     isWildCardFilter: true,
    //     fieldType: "text"
    // },
    // {
    //     label: "Installed Year",
    //     key: "installed_year",
    //     paramKey: "assets.installed_year",
    //     exportFilterKey: "assets.installed_year",
    //     band: bands.asset,
    //     bandPosition: bandPositions.body,
    //     isWildCardFilter: true,
    //     fieldType: "year"
    // },
    // {
    //     label: "Service Life",
    //     key: "service_life",
    //     paramKey: "assets.service_life",
    //     exportFilterKey: "assets.service_life",
    //     band: bands.asset,
    //     bandPosition: bandPositions.body,
    //     isWildCardFilter: true,
    //     fieldType: "number"
    // },
    // {
    //     label: "Useful Life Remaining",
    //     key: "useful_life_remaining",
    //     paramKey: "assets.usefull_life_remaining",
    //     exportFilterKey: "assets.usefull_life_remaining",
    //     band: bands.asset,
    //     bandPosition: bandPositions.body,
    //     isWildCardFilter: true,
    //     fieldType: "number"
    // },
    // {
    //     label: "Model Number",
    //     key: "model_number",
    //     paramKey: "assets.model_number",
    //     exportFilterKey: "assets.model_number",
    //     band: bands.asset,
    //     bandPosition: bandPositions.body,
    //     isWildCardFilter: true,
    //     fieldType: "text"
    // },
    // {
    //     label: "Manufacturer",
    //     key: "manufacturer",
    //     paramKey: "assets.manufacturer",
    //     exportFilterKey: "assets.manufacturer",
    //     band: bands.asset,
    //     bandPosition: bandPositions.body,
    //     isWildCardFilter: true,
    //     fieldType: "text"
    // },
    // {
    //     label: "Capacity",
    //     key: "capacity",
    //     paramKey: "assets.capacity",
    //     exportFilterKey: "assets.capacity",
    //     band: bands.asset,
    //     bandPosition: bandPositions.body,
    //     isWildCardFilter: true,
    //     fieldType: "text"
    // },
    // {
    //     label: "Capacity Unit",
    //     key: "capacity_unit",
    //     paramKey: "assets.capacity_unit",
    //     exportFilterKey: "assets.capacity_unit",
    //     band: bands.asset,
    //     bandPosition: bandPositions.body,
    //     isWildCardFilter: true,
    //     fieldType: "text"
    // },
    // {
    //     label: "Refrigerant",
    //     key: "refrigerant",
    //     paramKey: "assets.refrigerant",
    //     exportFilterKey: "assets.refrigerant",
    //     band: bands.asset,
    //     bandPosition: bandPositions.body,
    //     isWildCardFilter: true,
    //     fieldType: "text"
    // },
    {
        label: "Condition",
        key: "condition",
        paramKey: "condition_ids",
        exportFilterKey: "condition",
        band: bands.additional_details,
        bandPosition: bandPositions.header,
        isRecommendationField: true,
        searchKey: "condition",
        getListKey: ["recommendations.condition"]
    },
    {
        label: "Category",
        key: "category",
        paramKey: "category_ids",
        exportFilterKey: "category_id",
        band: bands.additional_details,
        bandPosition: bandPositions.header,
        searchKey: "recommendations.category_name",
        isRecommendationField: true,
        getListKey: ["categories.id", "categories.name"]
    },
    {
        label: "Capital Type",
        key: "capital_type",
        paramKey: "capital_type_ids",
        exportFilterKey: "capital_type_id",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        searchKey: "recommendations.capital_type_name",
        isRecommendationField: true,
        getListKey: ["recommendation_capital_types.id", "recommendation_capital_types.display_name"]
    },
    {
        label: "Status",
        key: "status",
        paramKey: "status_ids",
        exportFilterKey: "status",
        band: bands.additional_details,
        bandPosition: bandPositions.header,
        searchKey: "status",
        isRecommendationField: true,
        getListKey: ["recommendations.status"]
    },
    {
        label: "CR Status",
        key: "cr_status",
        paramKey: "cr_status_ids",
        exportFilterKey: "cr_status",
        band: bands.additional_details,
        bandPosition: bandPositions.header,
        searchKey: "status",
        isRecommendationField: true,
        getListKey: ["recommendations.cr_status"]
    },
    {
        label: "Criticality",
        key: "criticality",
        paramKey: "criticality_ids",
        exportFilterKey: "criticality_id",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        searchKey: "recommendations.criticality_name",
        isRecommendationField: true,
        getListKey: ["criticalities.id", "criticalities.name"]
    },
    {
        label: "Installed Year",
        key: "installed_year",
        paramKey: "recommendations.installed_year",
        exportFilterKey: "recommendations.installed_year",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        isWildCardFilter: true,
        fieldType: "year"
    },
    {
        label: "Service Life",
        key: "service_life",
        paramKey: "recommendations.service_life",
        exportFilterKey: "recommendations.service_life",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        isWildCardFilter: true,
        fieldType: "number"
    },
    {
        label: "Useful Life Remaining",
        key: "useful_life_remaining",
        paramKey: "recommendations.usefull_life_remaining",
        exportFilterKey: "recommendations.usefull_life_remaining",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        isWildCardFilter: true,
        fieldType: "number"
    },
    {
        label: "CRV",
        key: "crv",
        paramKey: "recommendations.crv",
        exportFilterKey: "recommendations.crv",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        isWildCardFilter: true,
        fieldType: "number"
    },

    {
        label: "Initiative",
        key: "initiative",
        paramKey: "initiative_ids",
        exportFilterKey: "initiative_id",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        searchKey: "recommendations.initiative_name",
        isRecommendationField: true,
        getListKey: ["initiatives.id", "initiatives.name"]
    },
    {
        label: "Funding",
        key: "funding_source",
        paramKey: "funding_source_ids",
        exportFilterKey: "funding_source_id",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        searchKey: "recommendations.funding_source_name",
        isRecommendationField: true,
        getListKey: ["funding_sources.id", "funding_sources.name"]
    },
    {
        label: "Responsibility",
        key: "responsibility",
        paramKey: "responsibility_ids",
        exportFilterKey: "responsibility_id",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        searchKey: "recommendations.responsibility_name",
        isRecommendationField: true,
        getListKey: ["recommendations.responsibility_id", "recommendations.responsibility_name"]
    },
    {
        label: "Source",
        key: "source",
        paramKey: "source_ids",
        exportFilterKey: "source",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        searchKey: "source",
        isRecommendationField: true,
        getListKey: ["recommendations.source"]
    },
    {
        label: "Birth Year",
        key: "birth_year",
        paramKey: "recommendations.birth_year",
        exportFilterKey: "recommendations.birth_year",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        isWildCardFilter: true,
        fieldType: "number"
    },
    {
        label: "Surveyor",
        key: "surveyor",
        paramKey: "surveyor_ids",
        exportFilterKey: "surveyor",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        searchKey: "recommendations.surveyor",
        isRecommendationField: true,
        getListKey: ["recommendations.surveyor"]
    },
    {
        label: "Requester",
        key: "requestor",
        paramKey: "requestor_ids",
        exportFilterKey: "requestor",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        searchKey: "recommendations.requestor",
        isRecommendationField: true,
        getListKey: ["recommendations.requestor_id", "recommendations.requestor_name"]
    },
    {
        label: "IR",
        key: "infrastructure_requests",
        paramKey: "infrastructure_requests",
        exportFilterKey: "infrastructure_request",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        searchKey: "infrastructure_request",
        isRecommendationField: true,
        getListKey: ["recommendations.infrastructure_request"]
    },
    {
        label: "FMP",
        key: "facility_master_plan",
        paramKey: "facility_master_plan",
        exportFilterKey: "fmp",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        searchKey: "fmp",
        isRecommendationField: true,
        getListKey: ["recommendations.fmp"]
    },
    {
        label: "Redlining",
        key: "redlining",
        paramKey: "redlining",
        exportFilterKey: "redlining",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        searchKey: "red_line",
        isRecommendationField: true,
        getListKey: ["recommendations.red_line"]
    },
    {
        label: "Budget Priority",
        key: "budget_priority",
        paramKey: "budget_priority",
        exportFilterKey: "budget_priority",
        band: bands.additional_details,
        bandPosition: bandPositions.body,
        searchKey: "budget_priority",
        isRecommendationField: true,
        getListKey: ["recommendations.budget_priority"]
    },
    // {
    //     label: "Images",
    //     key: "image_assigned",
    //     paramKey: "image_assigned",
    //     exportFilterKey: "image_assigned",
    //     band: bands.additional_details,
    //     bandPosition: bandPositions.body,
    //     searchKey: "image_assigned",
    //     isRecommendationField: true,
    //     getListKey: ["recommendations.image_assigned"]
    // },
    {
        label: "FCI",
        key: "color_codes",
        paramKey: "fci_color",
        exportFilterKey: "fci",
        band: bands.other,
        bandPosition: bandPositions.header
    },
    {
        label: "Clients",
        key: "clients",
        paramKey: "client_ids",
        hide: role === "client_user",
        exportFilterKey: "client_id",
        band: bands.other,
        bandPosition: bandPositions.header,
        searchKey: "recommendations.client_name",
        getListKey: ["clients.id", "clients.name"]
    },
    {
        label: "FCA Projects",
        key: "projects",
        paramKey: "project_ids",
        exportFilterKey: "project_id",
        band: bands.other,
        bandPosition: bandPositions.header,
        searchKey: "recommendations.project_name",
        getListKey: ["projects.id", "projects.name"]
    },
    {
        label: "My QA",
        key: "review_status",
        paramKey: "review_status",
        exportFilterKey: "review_status",
        band: bands.qa_qc,
        bandPosition: bandPositions.header,
        isWildCardFilter: true,
        fieldType: "select",
        hide: role === "client_user",
        options: qaStatusOptions
    },

    {
        label: "QA Passed",
        key: "qa_passed",
        paramKey: "qa_passed",
        exportFilterKey: "qa_passed",
        band: bands.qa_qc,
        bandPosition: bandPositions.header,
        isWildCardFilter: true,
        fieldType: "select",
        hide: role === "client_user",
        options: qaPassedOptions
    },
    {
        label: "Buildings",
        key: "buildings",
        paramKey: "building_ids",
        exportFilterKey: "building_id",
        band: bands.building,
        bandPosition: bandPositions.header,
        getListKey: ["buildings.id", "buildings.name"],
        searchKey: "buildings.name"
    },
    {
        label: "Primary Uses",
        key: "primary_use",
        paramKey: "primary_use_ids",
        exportFilterKey: "primary_use_ids",
        band: bands.building,
        bandPosition: bandPositions.header,
        getListKey: ["buildings.primary_use"]
    },
    {
        label: "Secondary Uses",
        key: "secondary_use",
        paramKey: "secondary_use_ids",
        exportFilterKey: "secondary_use_ids",
        band: bands.building,
        bandPosition: bandPositions.header,
        getListKey: ["buildings.secondary_use"]
    },
    {
        label: "Sectors",
        key: "sectors",
        paramKey: "sector_ids",
        exportFilterKey: "sector_ids",
        band: bands.building,
        bandPosition: bandPositions.body,
        getListKey: ["buildings.sectors"]
    },
    {
        label: "Internal Groups",
        key: "internal_groups",
        paramKey: "internal_group_ids",
        exportFilterKey: "internal_group_ids",
        band: bands.building,
        bandPosition: bandPositions.body,
        getListKey: ["buildings.internal_groups"]
    },
    {
        label: "Divisions",
        key: "divisions",
        paramKey: "division_ids",
        exportFilterKey: "division_ids",
        band: bands.building,
        bandPosition: bandPositions.body,
        getListKey: ["buildings.divisions"]
    },
    {
        label: "Manager",
        key: "building_manager",
        paramKey: "manager_ids",
        exportFilterKey: "manager_ids",
        band: bands.building,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "building_manager",
        getListKey: ["buildings.manager"]
    },
    {
        label: "Senior Property Manager",
        key: "building_senior_property_manager",
        paramKey: "senior_property_manager_ids",
        exportFilterKey: "senior_property_manager_ids",
        band: bands.building,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "building_senior_property_manager",
        getListKey: ["buildings.senior_property_manager"]
    },
    {
        label: "Status",
        key: "building_status",
        paramKey: "building_status_ids",
        exportFilterKey: "building_status_ids",
        band: bands.building,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "building_status",
        getListKey: ["buildings.status"]
    },
    {
        label: "Criticality",
        key: "building_criticality",
        paramKey: "building_criticality_ids",
        exportFilterKey: "building_criticality_ids",
        band: bands.building,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "building_criticality",
        getListKey: ["buildings.criticality"]
    },
    {
        label: "Building Number",
        key: "building_number",
        paramKey: "buildings.number",
        exportFilterKey: "buildings.number",
        band: bands.building,
        bandPosition: bandPositions.body,
        isWildCardFilter: true,
        fieldType: "text"
    },
    {
        label: "Country",
        key: "building_country",
        paramKey: "building_country_ids",
        exportFilterKey: "building_country_ids",
        band: bands.building,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "building_country",
        getListKey: ["buildings.country"]
    },
    {
        label: "State",
        key: "building_state",
        paramKey: "building_state_ids",
        exportFilterKey: "building_state_ids",
        band: bands.building,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "building_state",
        getListKey: ["buildings.state"]
    },
    {
        label: "Redline Completed",
        key: "building_redline_completed",
        paramKey: "building_redline_completed",
        exportFilterKey: "building_redline_completed",
        band: bands.building,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "building_redline_completed",
        getListKey: ["buildings.redline_completed"]
    },

    {
        label: "Redline Completed Date",
        key: "building_redline_completed_date",
        paramKey: "buildings.redline_completed_date",
        exportFilterKey: "building_redline_completed_date",
        band: bands.building,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "building_redline_completed_date",
        getListKey: ["buildings.redline_completed_date"],
        fieldType: "date",
        isWildCardFilter: true
    },
    {
        label: "Sites",
        key: "sites",
        paramKey: "site_ids",
        exportFilterKey: "site_id",
        band: bands.site,
        bandPosition: bandPositions.header,
        getListKey: ["sites.id", "sites.name"],
        searchKey: "recommendations.site_name"
    },
    {
        label: "Site Status",
        key: "site_status",
        paramKey: "site_status_ids",
        exportFilterKey: "site_status_ids",
        band: bands.site,
        bandPosition: bandPositions.header,
        isRecommendationField: true,
        searchKey: "site_status",
        getListKey: ["sites.status"]
    },
    {
        label: "Country",
        key: "site_country",
        paramKey: "site_country_ids",
        exportFilterKey: "site_country_ids",
        band: bands.site,
        bandPosition: bandPositions.header,
        isRecommendationField: true,
        searchKey: "site_country",
        getListKey: ["sites.country"]
    },
    {
        label: "State",
        key: "site_state",
        paramKey: "site_state_ids",
        exportFilterKey: "site_state_ids",
        band: bands.site,
        bandPosition: bandPositions.body,
        isRecommendationField: true,
        searchKey: "site_state",
        getListKey: ["sites.state"]
    }
    // {
    //     label: "Water",
    //     key: "water_band_show",
    //     paramKey: "water_band_show",
    //     exportFilterKey: "water_band_show",
    //     band: bands.water,
    //     bandPosition: bandPositions.header,
    //     searchKey: "water_band_show",
    //     isRecommendationField: true,
    //     getListKey: ["recommendations.water_band_show"]
    // },
    // {
    //     label: "Energy",
    //     key: "energy_band_show",
    //     paramKey: "energy_band_show",
    //     exportFilterKey: "energy_band_show",
    //     band: bands.energy,
    //     bandPosition: bandPositions.header,
    //     searchKey: "energy_band_show",
    //     isRecommendationField: true,
    //     getListKey: ["recommendations.energy_band_show"]
    // }
];
// let waterFields = water_fields.map((item, i) => ({
//     label: item.label,
//     key: item.key,
//     paramKey: `water_band.${item.key}`,
//     exportFilterKey: `water_band.${item.key}`,
//     band: bands.water,
//     bandPosition: [0, 1].includes(i) ? bandPositions.header : bandPositions.body,
//     isWildCardFilter: true,
//     fieldType: item.type
// }));
// let energyFields = energy_fields.map((item, i) => ({
//     label: item.label,
//     key: item.key,
//     paramKey: `energy_band.${item.key}`,
//     exportFilterKey: `energy_band.${item.key}`,
//     band: bands.energy,
//     bandPosition: [0, 1].includes(i) ? bandPositions.header : bandPositions.body,
//     isWildCardFilter: true,
//     fieldType: item.type
// }));

initialMaterFilters = [
    ...initialMaterFilters
    // ...waterFields,
    // ...energyFields
];
const initialState = {
    getDashboard: {},
    getDashboardChart: {},
    getFciChart: {},
    getHorizontalChart: {},
    getMap: {},
    getAllLegents: {},
    getFilterColors: {},
    filterValues: {},
    dashboardExtraFilters: [],
    backUpNames: {},
    backUpValues: {},
    popUpData: {},
    filterContents: [],
    landingPageData: {},
    widgetData: {},
    landingPageReport: {},
    isFullScreen: "",
    secondChartView: "",
    accordianOpen: null,
    activeLandingPageMenu: "",
    masterFilterList: {},
    defaultProject: localStorage.getItem("default_project"),
    dashboardResetFlag: false,
    dropdownLoader: {},
    filterFields: initialMaterFilters,
    tempChartFilters: {},
    tempQueryChartFilters: {},
    saveFilterQuery: {},
    getQueryData: { isQueryLoading: false },
    queryEntityParams: {
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 0,
            search: "",
            filters: null,
            list: null,
            order: null
        }
    },
    deleteQueryResponse: {},
    saveQueryResponse: { success: false },
    updateQueryResponse: { success: false },
    updateFilterName: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_DASHBOARD_REQUEST:
            return {
                ...state
            };
        case actionType.GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                getDashboard: { success: true, ...action.response }
            };
        case actionType.GET_DASHBOARD_FAILURE:
            return {
                ...state,
                getDashboard: { success: false, ...action.error }
            };
        case actionType.CLEAR_DASHBOARD_DATA:
            return {
                ...state,
                getDashboard: {}
            };
        case actionType.GET_DASHBOARD_CHART_REQUEST:
            return {
                ...state
            };
        case actionType.GET_DASHBOARD_CHART_SUCCESS:
            return {
                ...state,
                getDashboardChart: { ...state.getDashboardChart, ...action.response }
            };
        case actionType.GET_DASHBOARD_CHART_FAILURE:
            return {
                ...state,
                getDashboardChart: { chart: [], success: false }
            };
        case actionType.GET_DASHBOARD_FCI_CHART_REQUEST:
            return {
                ...state
            };
        case actionType.GET_DASHBOARD_FCI_CHART_SUCCESS:
            return {
                ...state,
                getFciChart: { success: true, ...action.response }
            };
        case actionType.GET_DASHBOARD_FCI_CHART_FAILURE:
            return {
                ...state,
                getFciChart: { success: false, ...action.error }
            };
        case actionType.GET_DASHBOARD_MAP_CHART_REQUEST:
            return {
                ...state
            };
        case actionType.GET_DASHBOARD_MAP_CHART_SUCCESS:
            return {
                ...state,
                getMap: { success: true, ...action.response }
            };
        case actionType.GET_DASHBOARD_MAP_CHART_FAILURE:
            return {
                ...state,
                getMap: { success: false, ...action.error }
            };
        case actionType.GET_DASHBOARD_HORIZONTAL_CHART_REQUEST:
            return {
                ...state
            };
        case actionType.GET_DASHBOARD_HORIZONTAL_CHART_SUCCESS:
            return {
                ...state,
                getHorizontalChart: { success: true, ...action.response }
            };
        case actionType.GET_DASHBOARD_HORIZONTAL_CHART_FAILURE:
            return {
                ...state,
                getHorizontalChart: { success: false, ...action.error }
            };
        //filter values
        case actionType.GET_FILTER_PROJECT_REQUEST:
            return {
                ...state,
                dropdownLoader: { ...state.dropdownLoader, [action.key]: true }
            };
        case actionType.GET_FILTER_PROJECT_SUCCESS:
            return {
                ...state,
                masterFilterList: {
                    ...state.masterFilterList,
                    [action.key]: action.isRecommendationField ? [...action.response?.list] : [...action.response?.[action.key]]
                },
                dropdownLoader: { ...state.dropdownLoader, [action.key]: false }
            };
        case actionType.GET_FILTER_PROJECT_FAILURE:
            return {
                ...state,
                masterFilterList: {},
                dropdownLoader: { ...state.dropdownLoader, [action.key]: false }
            };

        case actionType.GET_LEGENTS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_LEGENTS_SUCCESS:
            return {
                ...state,
                getAllLegents: { success: true, ...action.response }
            };
        case actionType.GET_LEGENTS_FAILURE:
            return {
                ...state,
                getAllLegents: { success: false, ...action.error }
            };

        case actionType.MODIFY_FILTER_REQUEST:
            return {
                ...state,
                filterContents: action.response.param || [],
                filterValues: { ...action.response.values },
                dashboardExtraFilters: action.response.dashboardExtraFilters || [],
                backUpNames: action.response.backUpNames,
                backUpValues: action.response.backUpValues
            };
        case actionType.SAVE_INDIVIDUAL_CHART_PARAMS:
            return {
                ...state,
                individualFilters: { ...action.response }
            };
        case actionType.SAVE_TEMP_CHART_FILTERS:
            return {
                ...state,
                tempChartFilters: { ...action.response }
            };
        case actionType.SAVE_QUERY_TEMP_CHART_FILTERS: {
            return {
                ...state,
                tempQueryChartFilters: { ...action.response }
            };
        }
        case actionType.MODIFY_POP_UP_REQUEST:
            return {
                ...state,
                popUpData: action.response.data
            };

        case actionType.GET_LANDING_PAGE_REQUEST:
            return {
                ...state
            };
        case actionType.GET_LANDING_PAGE_SUCCESS:
            return {
                ...state,
                landingPageData: { success: true, ...action.response }
            };
        case actionType.GET_LANDING_PAGE_FAILURE:
            return {
                ...state,
                landingPageData: { success: false, ...action.error }
            };
        case actionType.GET_LANDING_PAGE_REPORT_REQUEST:
            return {
                ...state
            };
        case actionType.GET_LANDING_PAGE_REPORT_SUCCESS:
            return {
                ...state,
                landingPageReport: { success: true, ...action.response }
            };
        case actionType.GET_LANDING_PAGE_REPORT_FAILURE:
            return {
                ...state,
                landingPageReport: { success: false, ...action.error }
            };
        case actionType.GET_WIDGET_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.GET_WIDGET_DATA_SUCCESS:
            return {
                ...state,
                widgetData: { success: true, ...action.response }
            };
        case actionType.GET_WIDGET_DATA_FAILURE:
            return {
                ...state,
                widgetData: { success: false, ...action.error }
            };
        case actionType.UPDATE_FULL_SCREEN_SUCCESS:
            return {
                ...state,
                isFullScreen: action.response
            };
        case actionType.UPDATE_FULL_SCREEN_FAILURE:
            return {
                ...state,
                isFullScreen: false
            };
        case actionType.UPDATE_IS_BUDGET_PRIORITY_VIEW_SUCCESS:
            return {
                ...state,
                secondChartView: action.response
            };
        case actionType.UPDATE_IS_BUDGET_PRIORITY_VIEW_FAILURE:
            return {
                ...state,
                secondChartView: ""
            };
        case actionType.UPDATE_TRACKER_MENU_SUCCESS:
            return {
                ...state,
                accordianOpen: action.response
            };
        case actionType.SET_ACTIVE_MENU_SUCCESS:
            return {
                ...state,
                activeLandingPageMenu: action.response
            };
        case actionType.UPDATE_DEFAULT_PROJECT:
            return {
                ...state,
                defaultProject: action.response
            };
        case actionType.TOGGLE_DASHBOARD_RESET_FLAG:
            return {
                ...state,
                dashboardResetFlag: !state.dashboardResetFlag
            };
        case actionType.UPDATE_MASTER_FILTER:
            return {
                ...state,
                filterFields: [...initialMaterFilters, ...action.response]
            };
        case actionType.GET_QUERY_REQUEST:
            return {
                ...state,
                getQueryData: { ...action.response, isQueryLoading: true }
            };
        case actionType.GET_QUERY_SUCCESS:
            return {
                ...state,
                getQueryData: { success: true, ...action.response, isQueryLoading: false }
            };
        case actionType.GET_QUERY_FAILURE:
            return {
                ...state,
                getQueryData: { success: false, ...action.response, isQueryLoading: false }
            };
        case actionType.DELETE_QUERY_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_QUERY_SUCCESS:
            return {
                ...state,
                deleteQueryResponse: { success: true, ...action.response }
            };
        case actionType.DELETE_QUERY_FAILURE:
            return {
                ...state,
                deleteQueryResponse: { success: false, ...action.error }
            };
        case actionType.SAVE_FILTER_REQUEST:
            return {
                ...state
            };
        case actionType.SAVE_FILTER_SUCCESS:
            return {
                ...state,
                saveQueryResponse: { success: true, ...action.response }
            };
        case actionType.SAVE_FILTER_FAILURE:
            return {
                ...state,
                saveQueryResponse: { success: false, ...action.error }
            };
        case actionType.UPDATE_FILTER_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_FILTER_SUCCESS:
            return {
                ...state,
                updateQueryResponse: { success: true, updateLoading: false, ...action.response }
            };
        case actionType.UPDATE_FILTER_FAILURE:
            return {
                ...state,
                updateQueryResponse: { success: false, updateLoading: false, ...action.error }
            };
        case actionType.UPDATE_FILTER_NAME:
            return {
                ...state,
                updateFilterName: { ...action.response }
            };
        default:
            return state;
    }
};
