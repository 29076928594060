import * as actionTypes from "./constants";
import * as Service from "./services";
const addRequest = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.ADD_REQUEST_REQUEST });
            const res = await Service.addRequest(params);
            if (res && res.status === 200) {
                const regionData = res.data;
                if (regionData.success) {
                    dispatch({ type: actionTypes.ADD_REQUEST_SUCCESS, response: regionData });
                } else {
                    dispatch({ type: actionTypes.ADD_REQUEST_FAILURE, error: regionData });
                }
            } else {
                dispatch({
                    type: actionTypes.ADD_REQUEST_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.ADD_REQUEST_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};
const updateRequest = (id, params) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.UPDATE_REQUEST_REQUEST });
            const res = await Service.updateRequest(id, params);
            if (res && res.status === 200) {
                const regionData = res.data;
                if (regionData.success) {
                    dispatch({ type: actionTypes.UPDATE_REQUEST_SUCCESS, response: regionData });
                } else {
                    dispatch({ type: actionTypes.UPDATE_REQUEST_FAILURE, error: regionData });
                }
            } else {
                dispatch({
                    type: actionTypes.UPDATE_REQUEST_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.UPDATE_REQUEST_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getCapitalRequestData = (params, isRecommendationView = false, isProjectView) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_CAPITAL_REQUEST_DATA_REQUEST });
            const res = isProjectView ? await Service.getCapitalRequestData(params) : await Service.getAnonCapitalRequestData(params);
            if (res && res.status === 200) {
                const capitalRequestData = res.data;

                dispatch({
                    type: isRecommendationView
                        ? actionTypes.GET_CAPITAL_REQUEST_DATA_FOR_RECOMMENDATION_VIEW_SUCCESS
                        : actionTypes.GET_CAPITAL_REQUEST_DATA_SUCCESS,
                    response: capitalRequestData
                });
            } else {
                dispatch({
                    type: isRecommendationView
                        ? actionTypes.GET_CAPITAL_REQUEST_DATA_FOR_RECOMMENDATION_VIEW_FAILURE
                        : actionTypes.GET_CAPITAL_REQUEST_DATA_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: isRecommendationView
                    ? actionTypes.GET_CAPITAL_REQUEST_DATA_FOR_RECOMMENDATION_VIEW_FAILURE
                    : actionTypes.GET_CAPITAL_REQUEST_DATA_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getCapitalRequestDataById = (id, params, isProjectView) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_CAPITAL_REQUEST_DATA_BY_ID_REQUEST });
            const res = isProjectView ? await Service.getCapitalRequestDataById(id, params) : await Service.getAnonCapitalRequestDataById(id, params);
            if (res && res.status === 200) {
                const capitalRequestData = res.data;

                dispatch({ type: actionTypes.GET_CAPITAL_REQUEST_DATA_BY_ID_SUCCESS, response: capitalRequestData });
            } else {
                dispatch({
                    type: actionTypes.GET_CAPITAL_REQUEST_DATA_BY_ID_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_CAPITAL_REQUEST_DATA_BY_ID_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getListForCapitalRequestFilter = (key, params, isProjectView) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_COMMON_FILTER_LIST_FOR_CR_REQUEST });
            const res = isProjectView
                ? await Service.getListForCapitalRequestFilter(key, params)
                : await Service.getListForAnonCapitalRequestFilter(key, params);
            if (res && res.status === 200) {
                const filterData = res.data;
                dispatch({
                    type: actionTypes.GET_COMMON_FILTER_LIST_FOR_CR_SUCCESS,
                    response: filterData
                });
            } else {
                dispatch({
                    type: actionTypes.GET_COMMON_FILTER_LIST_FOR_CR_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_COMMON_FILTER_LIST_FOR_CR_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const exportCapitalRequest = (params, isProjectView) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_CAPITAL_REQUEST_DATA_EXPORT_REQUEST });
            const response = isProjectView ? await Service.exportCapitalRequest(params) : await Service.exportAnonCapitalRequest(params);
            if (response && response.data) {
                const text = await new Response(response.data).text();
                if (text && text.split('"')[1] === "error") {
                    dispatch({ type: actionTypes.GET_CAPITAL_REQUEST_DATA_EXPORT_FAILURE, response: { error: text.split('"')[3] } });
                    return true;
                } else {
                    dispatch({ type: actionTypes.GET_CAPITAL_REQUEST_DATA_EXPORT_SUCCESS, response: {} });
                }
            }
            const { data } = response;
            const name = response.headers["content-disposition"].split("filename=");
            const fileName = name[1];
            let blob = new Blob([data]);
            let url = window.URL || window.webkitURL;
            let downloadUrl = url.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", `${fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            dispatch({
                type: actionTypes.GET_CAPITAL_REQUEST_DATA_EXPORT_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const submitEmail = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.SUBMIT_EMAIL_REQUEST });
            const res = await Service.submitEmail(params);
            if (res && res.status === 200) {
                const capitalRequestData = res.data;

                dispatch({ type: actionTypes.SUBMIT_EMAIL_SUCCESS, response: capitalRequestData });
            } else {
                dispatch({
                    type: actionTypes.SUBMIT_EMAIL_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.SUBMIT_EMAIL_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const lockCapitalRequest = (id, params) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.LOCK_CAPITAL_REQUEST_REQUEST });
            const res = await Service.lockCapitalRequest(id, params);
            if (res && res.status === 200) {
                const regionData = res.data;
                if (regionData.success) {
                    dispatch({ type: actionTypes.LOCK_CAPITAL_REQUEST_SUCCESS, response: regionData });
                } else {
                    dispatch({ type: actionTypes.LOCK_CAPITAL_REQUEST_FAILURE, error: regionData });
                }
            } else {
                dispatch({
                    type: actionTypes.LOCK_CAPITAL_REQUEST_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.LOCK_CAPITAL_REQUEST_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const verifyEmail = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.VERIFY_EMAIL_REQUEST });
            const res = await Service.verifyEmail(params);
            if (res && res.status === 200) {
                const regionData = res.data;
                if (regionData.success) {
                    dispatch({ type: actionTypes.VERIFY_EMAIL_SUCCESS, response: regionData });
                } else {
                    dispatch({ type: actionTypes.VERIFY_EMAIL_FAILURE, error: regionData });
                }
            } else {
                dispatch({
                    type: actionTypes.VERIFY_EMAIL_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.VERIFY_EMAIL_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const deleteCapitalRequest = (id, params) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.DELETE_CAPITAL_REQUEST_REQUEST });
            const res = await Service.deleteCapitalRequest(id, params);
            if (res && res.status === 200) {
                const regionData = res.data;
                if (regionData.success) {
                    dispatch({ type: actionTypes.DELETE_CAPITAL_REQUEST_SUCCESS, response: regionData });
                } else {
                    dispatch({ type: actionTypes.DELETE_CAPITAL_REQUEST_FAILURE, error: regionData });
                }
            } else {
                dispatch({
                    type: actionTypes.DELETE_CAPITAL_REQUEST_FAILURE,
                    error: res.response?.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.DELETE_CAPITAL_REQUEST_FAILURE,
                error: e.response?.data
            });
        }
    };
};

const clearViewPageData = () => {
    return async dispatch => {
        dispatch({
            type: actionTypes.EMPTY_VIEW_PAGE_DATA
        });
    };
};

const clearResponseData = () => {
    return async dispatch => {
        dispatch({
            type: actionTypes.CLEAR_RESPONSES
        });
    };
};

const restoreCapitalRequest = id => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.RESTORE_CR_REQUEST });
            const res = await Service.restoreCapitalRequest(id);
            if (res && res.status === 200) {
                const regionData = res.data;
                if (regionData.success) {
                    dispatch({ type: actionTypes.RESTORE_CR_SUCCESS, response: regionData });
                } else {
                    dispatch({ type: actionTypes.RESTORE_CR_FAILURE, error: regionData });
                }
            } else {
                dispatch({
                    type: actionTypes.RESTORE_CR_FAILURE,
                    error: res.response?.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.RESTORE_CR_FAILURE,
                error: e.response?.data
            });
        }
    };
};

const exportCapitalRequestReportPdf = (params, isProjectView) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.EXPORT_CAPITAL_REQUEST_REPORT_PDF_REQUEST });
            const res = isProjectView ? await Service.exportCapitalRequestReportPdf(params) : await Service.exportAnonCapitalRequestReportPdf(params);
            if (res && res.data) {
                const { data } = res;
                const name = res.headers["content-disposition"].split("filename=");
                const fileName = name[1].split('"')[1];
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", `${fileName}`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (e) {}
    };
};
const exportCapitalRequestReportDocx = (params, isProjectView) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.EXPORT_CAPITAL_REQUEST_REPORT_PDF_REQUEST });
            const res = isProjectView
                ? await Service.exportCapitalRequestReportDocx(params)
                : await Service.exportAnonCapitalRequestReportDocx(params);

            if (res && res.data) {
                const { data } = res;
                const name = res.headers["content-disposition"].split("filename=");
                const fileName = name[1].split('"')[1];
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", `${fileName}`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (e) {}
    };
};

const linkCapitalRequestToRecommendation = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.LINK_CAPITAL_REQUEST_TO_RECOMMENDATION_REQUEST });
            const res = await Service.linkCapitalRequestToRecommendation(params);
            if (res && res.status === 200) {
                const resData = res.data;
                if (resData.success) {
                    dispatch({ type: actionTypes.LINK_CAPITAL_REQUEST_TO_RECOMMENDATION_SUCCESS, response: resData });
                } else {
                    dispatch({ type: actionTypes.LINK_CAPITAL_REQUEST_TO_RECOMMENDATION_FAILURE, error: resData });
                }
            } else {
                dispatch({
                    type: actionTypes.LINK_CAPITAL_REQUEST_TO_RECOMMENDATION_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.LINK_CAPITAL_REQUEST_TO_RECOMMENDATION_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const removeCapitalRequestLinkToRecommendation = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.REMOVE_CAPITAL_REQUEST_LINK_TO_RECOMMENDATION_REQUEST });
            const res = await Service.removeCapitalRequestLinkToRecommendation(params);
            if (res && res.status === 200) {
                const resData = res.data;
                if (resData.success) {
                    dispatch({ type: actionTypes.REMOVE_CAPITAL_REQUEST_LINK_TO_RECOMMENDATION_SUCCESS, response: resData });
                } else {
                    dispatch({ type: actionTypes.REMOVE_CAPITAL_REQUEST_LINK_TO_RECOMMENDATION_FAILURE, error: resData });
                }
            } else {
                dispatch({
                    type: actionTypes.REMOVE_CAPITAL_REQUEST_LINK_TO_RECOMMENDATION_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.REMOVE_CAPITAL_REQUEST_LINK_TO_RECOMMENDATION_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};
const updateCapitalRequestEntityParam = entityParams => {
    return async dispatch => {
        try {
            if (entityParams) {
                dispatch({
                    type: actionTypes.UPDATE_CR_ENTITY_PARAMS_SUCCESS,
                    response: entityParams
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.UPDATE_CR_ENTITY_PARAMS_FAILURE,
                error: entityParams
            });
        }
    };
};

const logoutCRUser = () => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.CR_USER_LOGOUT_REQUEST });
            const res = await Service.deleteCRToken();
            if (res && res.status === 200) {
                const regionData = res.data;
                dispatch({ type: actionTypes.CR_USER_LOGOUT_SUCCESS, response: regionData });
                localStorage.removeItem("crRequestorToken");
                localStorage.removeItem("crRequestorMail");
                window.location.href = "/";
            } else {
                dispatch({
                    type: actionTypes.CR_USER_LOGOUT_FAILURE,
                    error: res.response?.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.CR_USER_LOGOUT_FAILURE,
                error: e.response?.data
            });
        }
    };
};

export default {
    addRequest,
    updateRequest,
    getCapitalRequestData,
    getCapitalRequestDataById,
    getListForCapitalRequestFilter,
    exportCapitalRequest,
    submitEmail,
    lockCapitalRequest,
    verifyEmail,
    clearViewPageData,
    deleteCapitalRequest,
    clearResponseData,
    restoreCapitalRequest,
    exportCapitalRequestReportPdf,
    linkCapitalRequestToRecommendation,
    removeCapitalRequestLinkToRecommendation,
    exportCapitalRequestReportDocx,
    updateCapitalRequestEntityParam,
    logoutCRUser
};
