import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment-timezone";
import LoadingOverlay from "react-loading-overlay";

import ConfirmationModal from "../../common/components/ConfirmationModal";
import Portal from "../../common/components/Portal";
import ViewModal from "../../common/components/ViewModal";
import actions from "../actions";
import Loader from "../../common/components/Loader";
import { capitalRequestTableData } from "../tableConfig";
import { addToBreadCrumpData, checkPermission } from "../../../config/utils";
import { permissions } from "../../../config/permissions";
import { CapitalRequestTable } from "./CapitalRequestTable";
import LinkCRtoRecommendationModal from "./LinkCRtoRecommendationModal";

export const CapitalRequestMain = props => {
    const {
        entityParams,
        getCapitalRequestDataResponse,
        getListForCommonFilterResponse,
        deleteCapitalRequestResponse,
        restoreCapitalRequestResponse,
        getCapitalRequestDataForRecommendationResponse
    } = useSelector(state => state.capitalRequestReducer) || {};

    const history = useHistory();
    const { code, id: recommendationId } = useParams();
    const email = localStorage.getItem("crRequestorMail");
    const [isLoading, setIsLoading] = useState(false);
    const [paginationParams, setPaginationParams] = useState(entityParams?.paginationParams);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showWildCardFilter, setShowWildCardFilter] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(entityParams?.selectedRowId);
    const [isDeleted, setDeleted] = useState(false);
    const [showHardDeleteConfirmationModal, setHardDeleteConfirmationModal] = useState(false);
    const [params, setParams] = useState(
        entityParams?.params || {
            limit: 100,
            offset: 1,
            search: "",
            filters: null,
            list: null,
            filterKeys: {},
            selectedDropdown: "active"
        }
    );
    const [selectedCapitalRequest, setSelectedCapitalRequest] = useState(entityParams?.selectedEntity);
    const [tableData, setTableData] = useState({
        keys: capitalRequestTableData.keys,
        data: [],
        config: entityParams?.tableConfig || _.cloneDeep(capitalRequestTableData.config)
    });
    const [alertMessage, setAlertMessage] = useState("");
    const [wildCardFilterParams, setWildCardFilterParams] = useState(entityParams?.wildCardFilterParams);
    const [filterParams, setFilterParams] = useState(entityParams?.filterParams);
    const [historyPaginationParams, setHistoryPaginationParams] = useState(entityParams?.historyPaginationParams);
    const [historyParams, setHistoryParams] = useState(entityParams?.historyParams);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showRestoreConfirmModal, setRestoreConfirmModal] = useState(false);
    const [summaryRowData, setSummaryRowData] = useState({ request_estimate: "" });
    const [commonFilters, setCommonFilters] = useState(entityParams?.params?.common ?? {});
    const [showLinkCRtoRecommendationModal, setShowLinkCRtoRecommendationModal] = useState(false);
    const [selectedCRIds, setSelectedCRIds] = useState([]);
    const [linkCRloader, setLinkCRloader] = useState(false);
    const [showCRunlinkModal, setShowCRunlinkModal] = useState(false);

    const refreshList = useRef(false);

    const dispatch = useDispatch();

    useEffect(() => {
        refreshCapitalRequestData();

        return () => {
            dispatch(actions.clearResponseData());
        };
    }, [recommendationId]);

    useEffect(() => {
        if (refreshList.current) {
            refreshList.current = false;
            refreshCapitalRequestData();
        }
    }, [
        params.limit,
        params.offset,
        params.search,
        paginationParams.currentPage,
        paginationParams.perPage,
        params?.order,
        params.filterKeys,
        commonFilters,
        params.selectedDropdown
    ]);

    useEffect(() => {
        if (deleteCapitalRequestResponse) {
            const { success, message, errors = "Delete failed" } = deleteCapitalRequestResponse;
            setIsLoading(false);
            if (success) {
                setAlertMessage(message);
                refreshCapitalRequestData();
            } else {
                setAlertMessage(errors);
            }
        }
    }, [deleteCapitalRequestResponse]);

    useEffect(() => {
        if (alertMessage) {
            showAlert();
            setAlertMessage("");
        }
    }, [alertMessage]);

    useEffect(() => {
        if (getCapitalRequestDataResponse?.data && !props.isRecommendationView) {
            let capitalRequestList = getCapitalRequestDataResponse.data || [];
            let totalCount = getCapitalRequestDataResponse?.count || 0;
            let miscellaneous = getCapitalRequestDataResponse?.miscellaneous || {};
            if (capitalRequestList && !capitalRequestList.length && paginationParams.currentPage) {
                setParams({
                    params: {
                        ...params,
                        offset: (paginationParams.currentPage - 1) * paginationParams.perPage
                    }
                });
                refreshCapitalRequestData();
            }
            if (capitalRequestList && !capitalRequestList.length && getCapitalRequestDataResponse?.error) {
                setAlertMessage(getCapitalRequestDataResponse.error);
            }
            capitalRequestList.map(capItem => {
                capItem.created_at = moment(capItem.created_at).tz("America/New_York").format("MM-DD-YYYY h:mm A");
                capItem.updated_at = moment(capItem.updated_at).tz("America/New_York").format("MM-DD-YYYY h:mm A");
            });
            setTableData(prevTableData => ({
                ...prevTableData,
                data: capitalRequestList
            }));
            setShowWildCardFilter(!!params.filters);
            setPaginationParams(prevPaginationParams => ({
                ...prevPaginationParams,
                totalCount: totalCount,
                totalPages: Math.ceil(totalCount / prevPaginationParams.perPage)
            }));
            props.setFreezeActions && props.setFreezeActions(miscellaneous?.freeze_capital_requests);
            setSummaryRowData(prevSummaryData => {
                return {
                    ...prevSummaryData,
                    request_estimate: getCapitalRequestDataResponse?.request_estimate_total || 0
                };
            });
        }
    }, [getCapitalRequestDataResponse]);

    useEffect(() => {
        if (getCapitalRequestDataForRecommendationResponse?.data && props.isRecommendationView) {
            let capitalRequestList = getCapitalRequestDataForRecommendationResponse.data || [];
            let totalCount = getCapitalRequestDataForRecommendationResponse?.count || 0;
            if (capitalRequestList && !capitalRequestList.length && paginationParams.currentPage) {
                setParams({
                    params: {
                        ...params,
                        offset: (paginationParams.currentPage - 1) * paginationParams.perPage
                    }
                });
                refreshCapitalRequestData();
            }
            if (capitalRequestList && !capitalRequestList.length && getCapitalRequestDataForRecommendationResponse?.error) {
                setAlertMessage(getCapitalRequestDataForRecommendationResponse.error);
            }
            capitalRequestList.map(capItem => {
                capItem.created_at = moment(capItem.created_at).tz("America/New_York").format("MM-DD-YYYY h:mm A");
                capItem.updated_at = moment(capItem.updated_at).tz("America/New_York").format("MM-DD-YYYY h:mm A");
            });
            setTableData(prevTableData => ({
                ...prevTableData,
                data: capitalRequestList
            }));
            setShowWildCardFilter(!!params.filters);
            setPaginationParams(prevPaginationParams => ({
                ...prevPaginationParams,
                totalCount: totalCount,
                totalPages: Math.ceil(totalCount / prevPaginationParams.perPage)
            }));
            setSummaryRowData(prevSummaryData => {
                return {
                    ...prevSummaryData,
                    request_estimate: getCapitalRequestDataForRecommendationResponse?.request_estimate_total || 0
                };
            });
        }
    }, [getCapitalRequestDataForRecommendationResponse]);

    const refreshCapitalRequestData = async () => {
        const { limit, offset, search, order, filterKeys, filters, common } = params;
        let ordering = [];
        ordering = Object.entries(order || [])
            .map(([key, value]) => (value === "asc" ? key : `-${key}`))
            .join(",");
        let capitalRequestParams = {
            per_page_count: limit,
            page_number: offset,
            search_query: search,
            ordering,
            ...filters,
            ...common,
            ...filterKeys,
            ...commonFilters
        };
        setDropdownParam(capitalRequestParams);
        if (props?.projectId) {
            capitalRequestParams.project_id = props.projectId;
        } else {
            capitalRequestParams.email = email;
            capitalRequestParams.project_code = code;
        }
        if (props?.isRecommendationView) {
            capitalRequestParams.recommendation_id = recommendationId;
        }
        if (props?.isCRSelection) {
            capitalRequestParams.recommendation_id = recommendationId;
            capitalRequestParams.recom_not_linked = true;
        }
        setIsLoading(true);
        await dispatch(actions.getCapitalRequestData(capitalRequestParams, props?.isRecommendationView, props.isProjectView));
        setIsLoading(false);
        updateEntityParams();
        return true;
    };

    const setDropdownParam = paramObj => {
        const { selectedDropdown } = params;
        if (selectedDropdown) {
            if (selectedDropdown === "active") {
                paramObj.active = true;
            } else if (selectedDropdown === "deleted") {
                paramObj.deleted = true;
            } else if (selectedDropdown === "archived") {
                paramObj.archived = true;
            }
        }
    };

    const updateWildCardFilter = async (newFilter, filterKeys) => {
        let newFilterKeys = { ...filterKeys };
        refreshList.current = true;
        setParams(prevParams => ({
            ...prevParams,
            offset: 1,
            filters: newFilter,
            filterKeys: newFilterKeys
        }));
        setPaginationParams(prevPaginationParams => ({
            ...prevPaginationParams,
            currentPage: 0
        }));
        updateEntityParams();
    };

    const updateEntityParams = async () => {
        let entityParams = {
            entity: "capitalRequest",
            selectedEntity: selectedCapitalRequest,
            paginationParams: paginationParams,
            params: params,
            wildCardFilterParams: wildCardFilterParams,
            filterParams: filterParams,
            tableConfig: tableData.config,
            selectedRowId: selectedRowId,
            historyPaginationParams: historyPaginationParams,
            historyParams: historyParams
        };
        await dispatch(actions.updateCapitalRequestEntityParam(entityParams));
    };

    const resetAllFilters = async () => {
        refreshList.current = true;
        setParams(prevParams => ({
            ...prevParams,
            limit: 100,
            offset: 1,
            search: "",
            filters: null,
            list: null,
            filterKeys: {},
            common: null,
            selectedDropdown: "active"
        }));
        setPaginationParams(prevPaginationParams => ({
            ...prevPaginationParams,
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        }));
        setWildCardFilterParams({});
        setFilterParams({});
        setSelectedRowId(null);
        setCommonFilters({});
        updateEntityParams();
    };

    const resetSort = async () => {
        refreshList.current = true;
        setParams(prevParams => ({
            ...prevParams,
            order: null
        }));
        updateEntityParams();
    };

    const resetAll = async () => {
        refreshList.current = true;
        setParams(prevParams => ({
            ...prevParams,
            limit: 100,
            offset: 1,
            search: "",
            filters: null,
            order: null,
            list: null,
            filterKeys: {},
            common: null,
            selectedDropdown: "active"
        }));

        setPaginationParams(prevPaginationParams => ({
            ...prevPaginationParams,
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        }));

        setTableData(prevTableData => ({
            ...prevTableData,
            config: _.cloneDeep(capitalRequestTableData.config)
        }));

        setWildCardFilterParams({});
        setFilterParams({});
        setSelectedRowId(null);
        setCommonFilters({});

        updateEntityParams();
    };

    const getListForCommonFilter = async fieldParams => {
        const { search, filterKeys, filters, common } = params;
        const { [fieldParams?.field]: omit, ...restCommonFilters } = commonFilters;
        let listParams = { search_query: search, ...filters, ...common, ...filterKeys, ...restCommonFilters };
        if (props?.projectId) {
            listParams.project_id = props.projectId;
        } else {
            listParams.email = email;
        }
        if (props?.isRecommendationView) {
            listParams.recommendation_id = recommendationId;
        }
        setDropdownParam(listParams);
        await dispatch(actions.getListForCapitalRequestFilter(fieldParams?.field, { ...listParams }, props?.isProjectView));
        return [];
    };

    const updateCommonFilter = async (commonFilters, exportFilters) => {
        refreshList.current = true;
        setParams(prevParams => ({
            ...prevParams,
            offset: 1,
            list: commonFilters,
            common: exportFilters
        }));
        setPaginationParams(prevPaginationParams => ({
            ...prevPaginationParams,
            currentPage: 0
        }));
        setCommonFilters(prevCommonFilters => ({ ...prevCommonFilters, ...exportFilters }));
        updateEntityParams();
    };

    const updateTableSortFilters = async searchKey => {
        refreshList.current = true;
        if (params.order) {
            setParams(prevParams => ({
                ...prevParams,
                order: {
                    ...prevParams.order,
                    [searchKey]: prevParams.order[searchKey] === "desc" ? "asc" : "desc"
                }
            }));
        } else {
            setParams(prevParams => ({
                ...prevParams,
                order: { [searchKey]: "asc" }
            }));
        }
        updateEntityParams();
    };

    const handleHideColumn = async keyItem => {
        if (keyItem !== "selectAll" && keyItem !== "deselectAll") {
            setTableData(prevTableData => ({
                ...prevTableData,
                config: {
                    ...prevTableData.config,
                    [keyItem]: {
                        ...prevTableData.config[keyItem],
                        isVisible: !prevTableData.config[keyItem].isVisible
                    }
                }
            }));
        } else {
            let tempConfig = tableData.config;
            tableData.keys.forEach(item => {
                if (keyItem === "selectAll") {
                    tempConfig[item].isVisible = true;
                } else {
                    tempConfig[item].isVisible = false;
                }
            });

            setTableData(prevTableData => ({
                ...prevTableData,
                config: tempConfig
            }));
        }

        await updateEntityParams();
        return true;
    };

    const updateSelectedRow = async rowId => {
        setSelectedRowId(rowId);
        await updateEntityParams();
    };

    const handlePerPageChange = async e => {
        refreshList.current = true;
        const newPerPage = e.target.value;

        setPaginationParams(prevPaginationParams => ({
            ...prevPaginationParams,
            perPage: newPerPage,
            currentPage: 0
        }));

        setParams(prevParams => ({
            ...prevParams,
            offset: 1,
            limit: newPerPage
        }));
    };

    const handlePageClick = async page => {
        refreshList.current = true;
        setPaginationParams(prevPaginationParams => ({
            ...prevPaginationParams,
            currentPage: page.selected
        }));

        setParams(prevParams => ({
            ...prevParams,
            offset: page.selected + 1
        }));
    };

    const showEditPage = id => {
        if (props.isProjectView) {
            addToBreadCrumpData({
                key: "edit",
                name: "Edit CR",
                path: `/project-capital-request/edit/${id}`
            });
            history.push(`/project-capital-request/edit/${id}`);
        } else {
            history.push(`/capital-request/${code}/edit/${id}`);
        }
    };

    const showAddForm = () => {
        history.push(`/capital-request/${code}/add`);
    };

    const toggleWildCardFilter = () => {
        setShowWildCardFilter(!showWildCardFilter);
    };

    const showAlert = () => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show";
            x.innerText = alertMessage;
            setTimeout(function () {
                x.className = x.className.replace("show", "");
            }, 3000);
        }
    };

    const handleDeleteFloor = async (id, isDeleted) => {
        if ((!props.isProjectView || (props.isProjectView && !checkPermission("forms", permissions.CAPITAL_REQUEST, "hard_delete"))) && isDeleted) {
            setAlertMessage("You are not authorized to perform the Hard Delete !");
        } else {
            setShowConfirmModal(true);
            setSelectedCapitalRequest(id);
            setDeleted(isDeleted);
        }
    };

    const renderConfirmationModal = () => {
        if (!showConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to delete this Capital Request ?"}
                        message={""}
                        onNo={() => setShowConfirmModal(false)}
                        onYes={isHardDelete => {
                            isHardDelete ? setHardDeleteConfirmationModal(true) : deleteCrOnConfirm(isHardDelete);
                        }}
                        onHardDelete={deleteCrOnConfirm}
                        isHard={props.isProjectView ? checkPermission("forms", permissions.CAPITAL_REQUEST, "hard_delete") : false}
                        isDeleted={isDeleted}
                    />
                }
                onCancel={() => setShowConfirmModal(false)}
            />
        );
    };

    const renderHardDeleteConfirmationModal = () => {
        if (!showHardDeleteConfirmationModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Are you sure you want to permanently (Hard) delete this Capital Request?"}
                        type="cancel"
                        onNo={() => setHardDeleteConfirmationModal(false)}
                        onYes={() => {
                            setHardDeleteConfirmationModal(false);
                            deleteCrOnConfirm(true);
                        }}
                    />
                }
                onCancel={() => setHardDeleteConfirmationModal(false)}
            />
        );
    };

    const deleteCrOnConfirm = async (isHardDelete = false) => {
        setIsLoading(true);
        setShowConfirmModal(false);
        dispatch(actions.deleteCapitalRequest(selectedCapitalRequest, isHardDelete ? { hard_delete: 1 } : {}));
    };

    const handleGlobalSearch = async search => {
        refreshList.current = true;
        setParams(prevParams => ({
            ...prevParams,
            offset: 1,
            search
        }));
        setPaginationParams(prevPaginationParams => ({
            ...prevPaginationParams,
            currentPage: 0
        }));
    };

    const showInfoPage = (id, projectId, rowData) => {
        setSelectedCapitalRequest(id);
        if (props.isProjectView) {
            history.push(`/project-capital-request/info/${id}${props?.isRecommendationView ? `?from_rec=${true}` : ""}`);
            addToBreadCrumpData({
                key: "Name",
                name: rowData.code,
                path: `/project-capital-request/info/${rowData.id}${props?.isRecommendationView ? `?from_rec=${true}` : ""}`
            });
            addToBreadCrumpData({
                key: "info",
                name: "Basic Details",
                path: `/project-capital-request/info/${rowData.id}${props?.isRecommendationView ? `?from_rec=${true}` : ""}`
            });
        } else {
            history.push(`/capital-request/${code}/info/${id}`);
        }
    };

    const exportTableXl = async () => {
        const { search, order, filterKeys } = params;
        let currentUserName = localStorage.getItem("user") || "";
        let ordering = [];
        ordering = Object.entries(order || [])
            .map(([key, value]) => (value === "asc" ? key : `-${key}`))
            .join(",");
        let hiddenColumns = tableData.keys.filter(tableKey => !tableData.config[tableKey].isVisible);
        let capitalRequestParams = {
            search_query: search,
            ordering,
            ...filterKeys,
            hide_columns: hiddenColumns,
            ...commonFilters
        };
        setDropdownParam(capitalRequestParams);
        if (props?.projectId) {
            capitalRequestParams.project_id = props.projectId;
            capitalRequestParams.username = currentUserName;
        } else {
            capitalRequestParams.email = email;
        }
        if (props?.isRecommendationView) {
            capitalRequestParams.recommendation_id = recommendationId;
        }
        setTableLoading(true);
        await dispatch(actions.exportCapitalRequest(capitalRequestParams, props?.isProjectView));
        setTableLoading(false);
    };

    const isColunmVisibleChanged = () => {
        let flag = false;
        const { keys, config } = tableData;
        keys.forEach(key => {
            if (!_.isEqual(config[key]?.isVisible, capitalRequestTableData.config[key]?.isVisible)) {
                flag = true;
            }
        });
        return flag;
    };

    const selectFilterHandler = e => {
        refreshList.current = true;
        setParams(prevParams => ({
            ...prevParams,
            offset: 1,
            selectedDropdown: e.target.value
        }));
        setPaginationParams(prevPaginationParams => ({
            ...prevPaginationParams,
            currentPage: 0
        }));

        updateEntityParams();
    };

    const toggleRestoreConfirmModal = async id => {
        setRestoreConfirmModal(true);
        setSelectedCapitalRequest(id);
    };

    const renderRestoreConfirmationModal = () => {
        if (!showRestoreConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to restore this Capital Request?"}
                        message={""}
                        onNo={() => setRestoreConfirmModal(false)}
                        onYes={restoreRecommendationOnConfirm}
                        type={"restore"}
                        isRestore={true}
                    />
                }
                onCancel={() => setRestoreConfirmModal(false)}
            />
        );
    };

    const restoreRecommendationOnConfirm = async () => {
        setIsLoading(true);
        setRestoreConfirmModal(false);
        dispatch(actions.restoreCapitalRequest(selectedCapitalRequest));
    };

    useEffect(() => {
        if (restoreCapitalRequestResponse) {
            const { success, message, errors = "Restore failed" } = restoreCapitalRequestResponse;
            setIsLoading(false);
            if (success) {
                setAlertMessage(message);
                refreshCapitalRequestData();
            } else {
                setAlertMessage(errors);
            }
        }
    }, [restoreCapitalRequestResponse]);

    const toggleLinkCRtoRecommendationModal = () => {
        setShowLinkCRtoRecommendationModal(true);
    };

    const renderLinkCRtoRecommendationModal = () => {
        if (!showLinkCRtoRecommendationModal) return null;
        return (
            <Portal
                body={
                    <LinkCRtoRecommendationModal
                        onCancel={() => setShowLinkCRtoRecommendationModal(false)}
                        linkSelectedCRs={linkSelectedCRs}
                        linkCRloader={linkCRloader}
                        selectedCRIds={selectedCRIds}
                        isCRSelection={props?.isCRSelection}
                        handleSelectCR={handleSelectCR}
                        handleSelectAllCR={handleSelectAllCR}
                        projectIdForSelection={props?.projectIdForSelection}
                    />
                }
                onCancel={() => setShowLinkCRtoRecommendationModal(false)}
            />
        );
    };

    const handleSelectCR = async (data, isChecked) => {
        const { id } = data;
        setSelectedCRIds(prevSelectedCRIds => (isChecked ? [...prevSelectedCRIds, id] : prevSelectedCRIds.filter(crId => crId !== id)));
    };

    const handleSelectAllCR = isChecked => {
        let tempSelected = [];
        if (isChecked) {
            tableData.data.forEach(item => {
                tempSelected.push(item.id);
            });
        } else {
            tempSelected = selectedCRIds;
            tableData.data.forEach(item => {
                tempSelected = tempSelected.filter(crId => crId !== item.id);
            });
        }
        setSelectedCRIds(tempSelected);
    };

    const linkSelectedCRs = async () => {
        setLinkCRloader(true);
        await dispatch(
            actions.linkCapitalRequestToRecommendation({
                recommendations_capital_requests: { recommendation_id: recommendationId, capital_request_ids: selectedCRIds }
            })
        );
        setLinkCRloader(false);
        setShowLinkCRtoRecommendationModal(false);
        setSelectedCRIds([]);
        refreshCapitalRequestData();
    };

    const unlinkCRs = () => {
        setShowCRunlinkModal(true);
    };

    const renderCRunlinkConfirmModal = () => {
        if (!showCRunlinkModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={
                            <>
                                Are you sure you want to unlink {`${selectedCRIds?.length === 1 ? "this" : "these"}`}
                                <span className="badge-red-circled">{selectedCRIds?.length}</span>{" "}
                                {`Capital Request${selectedCRIds?.length > 1 ? "s" : ""}?`}
                            </>
                        }
                        type="cancel"
                        onNo={() => setShowCRunlinkModal(false)}
                        onYes={() => {
                            setShowCRunlinkModal(false);
                            crUnlinkConfirm();
                        }}
                    />
                }
                onCancel={() => setShowCRunlinkModal(false)}
            />
        );
    };

    const crUnlinkConfirm = async () => {
        await dispatch(
            actions.removeCapitalRequestLinkToRecommendation({
                recommendations_capital_requests: { recommendation_id: recommendationId, capital_request_ids: selectedCRIds }
            })
        );
        setShowCRunlinkModal(false);
        setSelectedCRIds([]);
        refreshCapitalRequestData();
    };
    const { freezeActions } = props;
    return (
        <LoadingOverlay active={isLoading} spinner={<Loader />} fadeSpeed={10}>
            <CapitalRequestTable
                showWildCardFilter={showWildCardFilter}
                paginationParams={paginationParams}
                showViewModal={() => setShowViewModal(true)}
                tableData={tableData}
                handleGlobalSearch={handleGlobalSearch}
                globalSearchKey={params.search}
                updateSelectedRow={updateSelectedRow}
                selectedRowId={selectedRowId}
                isColunmVisibleChanged={isColunmVisibleChanged}
                toggleWildCardFilter={toggleWildCardFilter}
                handleDeleteFloor={handleDeleteFloor}
                showEditPage={showEditPage}
                handlePerPageChange={handlePerPageChange}
                handlePageClick={handlePageClick}
                showAddForm={showAddForm}
                showInfoPage={showInfoPage}
                updateWildCardFilter={updateWildCardFilter}
                wildCardFilter={params}
                handleHideColumn={handleHideColumn}
                getListForCommonFilterFloor={getListForCommonFilter}
                updateCommonFilter={updateCommonFilter}
                commonFilter={params.list}
                resetAllFilters={resetAllFilters}
                resetAll={resetAll}
                updateTableSortFilters={updateTableSortFilters}
                resetSort={resetSort}
                tableParams={params}
                exportTableXl={exportTableXl}
                tableLoading={tableLoading}
                hasExport={props.isProjectView ? checkPermission("forms", permissions.CAPITAL_REQUEST, "export") : true}
                showAddButton={!freezeActions && !props?.isProjectView && !props?.isRecommendationView}
                hasDelete={
                    props.isProjectView
                        ? props?.isRecommendationView
                            ? false
                            : checkPermission("forms", permissions.CAPITAL_REQUEST, "delete")
                        : !freezeActions
                }
                hasInfoPage={
                    props?.isCRSelection ? false : props.isProjectView ? checkPermission("forms", permissions.CAPITAL_REQUEST, "view") : true
                }
                hasEdit={
                    props.isProjectView
                        ? props?.isRecommendationView
                            ? false
                            : checkPermission("forms", permissions.CAPITAL_REQUEST, "edit")
                        : !freezeActions
                }
                entity={permissions.CAPITAL_REQUEST}
                summaryRowData={summaryRowData}
                commonFilterData={getListForCommonFilterResponse || {}}
                commonFilters={commonFilters}
                selectFilterHandler={selectFilterHandler}
                showRestoreModal={toggleRestoreConfirmModal}
                isRecommendationView={props?.isRecommendationView}
                toggleLinkCRtoRecommendationModal={toggleLinkCRtoRecommendationModal}
                isCRSelection={props?.isCRSelection || props?.isRecommendationView}
                handleSelectCR={props?.isCRSelection ? props?.handleSelectCR : handleSelectCR}
                selectedCRIds={props?.isCRSelection ? props?.selectedCRIds : selectedCRIds}
                handleSelectAllCR={props?.isCRSelection ? props?.handleSelectAllCR : handleSelectAllCR}
                hasActionColumn={!props?.isCRSelection}
                everyItemCheckedPerPage={tableData?.data?.every(item =>
                    (props?.isCRSelection ? props?.selectedCRIds : selectedCRIds).includes(item.id)
                )}
                unlinkCRs={unlinkCRs}
            />
            {renderConfirmationModal()}
            {renderHardDeleteConfirmationModal()}
            {renderRestoreConfirmationModal()}
            {renderLinkCRtoRecommendationModal()}
            {renderCRunlinkConfirmModal()}
            {showViewModal ? (
                <Portal
                    body={
                        <ViewModal
                            keys={tableData.keys}
                            config={tableData.config}
                            handleHideColumn={handleHideColumn}
                            onCancel={() => setShowViewModal(false)}
                        />
                    }
                    onCancel={() => setShowViewModal(false)}
                />
            ) : null}
        </LoadingOverlay>
    );
};
