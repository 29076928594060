/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import actions from "../actions";
import {
    addToBreadCrumpData,
    bulkResetBreadCrumpData,
    findPrevPathFromBreadCrumpData,
    popBreadCrumpOnPageClose,
    showAlert
} from "../../../config/utils";

const useCapitalRequestView = ({ isProjectView }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { getCapitalRequestDataByIdResponse } = useSelector(state => state.capitalRequestReducer);
    const { code, id } = useParams();
    const [data, setData] = useState({});
    const [isLoading, setLoading] = useState(false);
    const getDataRef = useRef(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [exportLoader, setExportLoader] = useState(false);
    const [showOnePagerExportModal, setShowOnePagerExportModal] = useState(false);
    const [CRData, setCRdata] = useState({});

    useEffect(() => {
        setData({});
        refreshCapitalRequestData();
        return () => {
            dispatch(actions.clearViewPageData());
        };
    }, [id]);

    useEffect(() => {
        if (alertMessage) {
            showAlert(alertMessage);
            setAlertMessage("");
        }
    }, [alertMessage]);

    useEffect(() => {
        if (getCapitalRequestDataByIdResponse) {
            setLoading(false);
            const { success, message, data } = getCapitalRequestDataByIdResponse;
            if (success && data) {
                setData({
                    ...initialState,
                    ...getCapitalRequestDataByIdResponse.data,
                    requested_at: moment(getCapitalRequestDataByIdResponse.data?.requested_at).tz("America/New_York").format("MM-DD-YYYY h:mm A")
                });
                handleBreadCrumbs(getCapitalRequestDataByIdResponse.data);
            } else {
                setAlertMessage(message);
            }
        }
    }, [getCapitalRequestDataByIdResponse]);

    const refreshCapitalRequestData = async () => {
        setLoading(true);
        let params = {};
        if (isProjectView) {
            params.user_id = localStorage.getItem("userId");
        }
        await dispatch(actions.getCapitalRequestDataById(id, params, isProjectView));
    };

    const handleBreadCrumbs = data => {
        const currentBC = JSON.parse(sessionStorage.getItem("bc-data") || "[]");
        if (currentBC?.length < 2) {
            const { id, project_id, code, project } = data || {};
            let bc = [
                { index: 0, key: "main", name: "FCA Projects", path: "/project" },
                { index: 1, key: "buildingName", name: project, path: `/project/projectinfo/${project_id}/basicdetails` },
                { index: 2, key: "info", name: "CR", path: `/project/projectinfo/${project_id}/capitalrequest` },
                { index: 3, key: "Name", name: code, path: `/project-capital-request/info/${id}` }
            ];
            bulkResetBreadCrumpData(bc);
        }
    };

    const cancelViewPage = () => {
        if (isProjectView) {
            popBreadCrumpOnPageClose();
            history.push(findPrevPathFromBreadCrumpData());
        } else {
            history.push(`/capital-request/${code}`);
        }
    };
    const showEditPage = id => {
        if (isProjectView) {
            addToBreadCrumpData({
                key: "edit",
                name: "Edit CR",
                path: `/project-capital-request/edit/${id}`
            });
            history.push(`/project-capital-request/edit/${id}`);
        } else {
            history.push(`/capital-request/${code}/edit/${id}`, { from: "viewPage" });
        }
    };
    const lockCR = lock => {
        setData({ ...data, locked: lock });
        dispatch(actions.lockCapitalRequest(id, { locked: lock }));
    };

    const createNewOrDuplicateRequest = (data, type) => {
        if (type === "Duplicate") {
            history.push(`/capital-request/${code}/add`, { crData: data, from: "viewPage", fromId: id });
        } else {
            history.push(`/capital-request/${code}/add`, { from: "viewPage", fromId: id });
        }
    };

    const exportOnePager = async (CRData, type) => {
        setExportLoader(true);
        setShowOnePagerExportModal(false);
        let params = CRData;
        if (isProjectView) {
            params.username = localStorage.getItem("user");
        }
        if (type === "Docx") {
            await dispatch(actions.exportCapitalRequestReportDocx(params, isProjectView));
        } else {
            await dispatch(actions.exportCapitalRequestReportPdf(params, isProjectView));
        }
        setExportLoader(false);
    };
    const onePagerExport = (cr_id, project_id, client_id) => {
        setShowOnePagerExportModal(true);
        setCRdata({ cr_id, project_id, client_id });
    };
    return {
        isLoading,
        data,
        cancelViewPage,
        showEditPage,
        lockCR,
        createNewOrDuplicateRequest,
        exportOnePager,
        exportLoader,
        onePagerExport,
        showOnePagerExportModal,
        setShowOnePagerExportModal,
        CRData
    };
};
export default useCapitalRequestView;

const initialState = {
    locked: false,
    requestor_first_name: "",
    requestor_last_name: "",
    department_name: "",
    funding_request_title: "",
    request_estimate: "",
    investment_priority: "",
    request_status: "",
    request_type: "",
    equipment_request_type: "",
    request_description: "",
    age_and_condition_of_replacement: "",
    improving_service_inquiry: "",
    problem_alleviation_inquiry: "",
    compliance_resolution_inquiry: "",
    investment_efficiency_inquiry: "",
    operations_cost_reduction_inquiry: "",
    safety_requirement_inquiry: "",
    approval_delay_impact_inquiry: "",
    future_plan_inquiry: "",
    receiver_inquiry: "",
    other_request_relationship_inquiry: "",
    alternative_inquiry: "",
    risk_inquiry: "",
    approver_name: "",
    has_funding_source_identified: "",
    funding_source_security_inquiry: "",
    comments: ""
};
