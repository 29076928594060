import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Draggable from "react-draggable";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { connect } from "react-redux";
import actions from "../actions";
import ViewChartMainForModal from "./ViewChartMainForModal";
import Portal from "../../common/components/Portal";
import ImageBandConfigModal from "./ImageBandConfigModal";
import ChartItemConfigModal from "./ChartItemConfigModal";
import ImageListForBandModal from "./ImageListForBandModal";
import { RichEditorPopup } from "../../common/components/formFields/RichEditorPopup";

const SourceTemplate = ({ getSmartChartSourceTemplete, smartChartPropertyByIdData = {}, currentPropertyId = null, openFilterModal, ...props }) => {
    const [propertyData, setPropertyData] = useState({
        user: localStorage.getItem("userId"),
        name: "",
        notes: "",
        client_name: "",
        template_property_name: "",
        template_name: "",
        properties_text: {},
        is_mapped: false,
        created_by: "",
        modified_by: "",
        created_date: "",
        modified_date: "",
        is_locked: true,
        client_id: "",
        property_type: "",
        skeleton_template: ""
    });
    const [selectedChartConfigData, setSelectedChartConfigData] = useState({});
    const [showImageBandConfigModal, setShowImageBandConfigModal] = useState(false);
    const [showChartItemConfigModal, setShowChartItemConfigModal] = useState(false);
    const [showImageListForBandModal, setShowImageListForBandModal] = useState(false);
    let isExcelExport = propertyData?.properties_text?.project?.band1?.export_type === "excel" ? true : false;
    const [showRichTextPopup, setShowRichTextPopup] = useState({ label: "", key: "", show: false, value: "" });

    useEffect(() => {
        let propertyId = currentPropertyId || "";
        getSmartChartSourceTemplete(propertyId);
    }, []);
    useEffect(() => {
        if (smartChartPropertyByIdData?.success) {
            setViewPropertyData(smartChartPropertyByIdData.data);
        }
    }, [smartChartPropertyByIdData]);

    useEffect(() => {
        if (propertyData.name) {
            ReactTooltip.rebuild();
        }
    }, [propertyData]);

    const setViewPropertyData = property => {
        let tempProperties = JSON.parse(property?.properties_text);
        let selectedProperties = {};
        selectedProperties = Object.keys(tempProperties).reduce((resultData, currentProp, index) => {
            if (!tempProperties[currentProp].band1.hasOwnProperty("type") || _.isEmpty(tempProperties[currentProp].band1.type)) {
                return resultData;
            }
            return { ...resultData, [currentProp]: tempProperties[currentProp] };
        }, {});
        let updatedPropertyData = removeExtrakeysFromEnergyBand(selectedProperties);
        setPropertyData(prevPropertyData => {
            return {
                ...prevPropertyData,
                name: property.name,
                notes: property.notes,
                client_name: property.client_name,
                template_property_name: property.template_property_name,
                template_name: property.template_name,
                properties_text: { ...updatedPropertyData },
                is_mapped: property.is_mapped,
                created_by: property.user,
                modified_by: property.modified_by,
                created_date: property.created_date,
                modified_date: property.modified_date,
                is_locked: property.is_locked,
                client_id: property.client_id,
                property_type: property.property_type,
                skeleton_template: property?.skeleton_template || ""
            };
        });
    };
    const openChartItemConfigModal = params => {
        const { entity, chartKey, bandName, isImageBand = false, isMemo = false } = params;
        let selectedChartConfig = propertyData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey]
            ? { ...propertyData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey] }
            : {};
        setSelectedChartConfigData({ ...params, selectedChartConfig });
        if (isImageBand) {
            setShowImageBandConfigModal(true);
        } else if (isMemo) {
            setShowRichTextPopup({ label: "Text Band", value: selectedChartConfig?.text, show: true, key: "Text Band" });
        } else {
            setShowChartItemConfigModal(true);
        }
    };

    const removeExtrakeysFromEnergyBand = tempPropertyData => {
        if (tempPropertyData?.project?.band1?.type?.hasOwnProperty("energy_band")) {
            delete tempPropertyData?.project?.band1?.type["em_eci_site_cost"];
            delete tempPropertyData?.project?.band1?.type["em_eci_energy_cost"];
            delete tempPropertyData?.project?.band1?.type["bld_brkdown_energy_cost"];
        }
        if (tempPropertyData?.energy_mng?.band1?.type?.hasOwnProperty("energy_band")) {
            delete tempPropertyData?.energy_mng?.band1?.type["em_eci_site_cost"];
            delete tempPropertyData?.energy_mng?.band1?.type["em_eci_energy_cost"];
            delete tempPropertyData?.energy_mng?.band1?.type["bld_brkdown_energy_cost"];
        }
        return tempPropertyData;
    };

    const toggleImageListForBandModal = params => {
        const { entity, chartKey, bandName } = params;
        let selectedChartConfig = propertyData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey]
            ? { ...propertyData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey] }
            : {};
        setSelectedChartConfigData({ ...params, selectedChartConfig });
        setShowImageListForBandModal(true);
    };
    const renderImageListForBandModal = () => {
        if (!showImageListForBandModal) return null;
        return (
            <Portal
                body={
                    <ImageListForBandModal
                        onCancel={() => setShowImageListForBandModal(false)}
                        selectedClient={propertyData.client_id}
                        configData={selectedChartConfigData}
                        isView={true}
                        isTemplateView={true}
                    />
                }
                onCancel={() => setShowImageListForBandModal(false)}
            />
        );
    };

    const renderImageBandConfigModal = () => {
        if (!showImageBandConfigModal) return null;
        return (
            <Portal
                body={<ImageBandConfigModal onCancel={() => setShowImageBandConfigModal(false)} configData={selectedChartConfigData} isView={true} />}
                onCancel={() => setShowImageBandConfigModal(false)}
            />
        );
    };

    const renderChartItemConfigModal = () => {
        if (!showChartItemConfigModal) return null;
        return (
            <Portal
                body={
                    <ChartItemConfigModal
                        configData={selectedChartConfigData}
                        onCancel={() => setShowChartItemConfigModal(false)}
                        isView={true}
                        isExcelExport={isExcelExport}
                    />
                }
                onCancel={() => setShowChartItemConfigModal(false)}
            />
        );
    };
    const renderMemoEditor = () => {
        const { label, key, show, value } = showRichTextPopup;
        if (!show) return null;
        return (
            <Portal
                body={<RichEditorPopup value={value} label={label} key={key} isReadOnly onCancel={() => setShowRichTextPopup({})} />}
                onCancel={() => setShowRichTextPopup(null)}
            />
        );
    };
    return (
        <div class="modal modal-region smart-chart-popup smart-dtl-pop" id="modalId" tabIndex="-1" style={{ display: "block" }}>
            <ReactTooltip id="edit-report-template-modal" effect="solid" place="bottom" backgroundColor="#007bff" />
            <Draggable handle=".modal-header">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div class="modal-header draggable">
                            <h5 class="modal-title" id="exampleModalLabel">
                                <div class="txt-hed">Smart Chart Report Template Details</div>
                                <div class="btn-mod-headotr">
                                    <button class="btn-cancel" onClick={() => props.onCancel()}>
                                        Close
                                    </button>
                                </div>
                            </h5>
                        </div>
                        <div class="modal-body region-otr core-rpt-dt-mod">
                            <div class="tab-dtl region-mng chart-drage-area">
                                <div class="col-md-12 view-content-bx-outer">
                                    <div class="view-dtl-inner pt-0">
                                        <div class="txt-det-lbl">
                                            <div class="text-label">Template Name</div>
                                            <div class="text-label-dtl">{propertyData.name || "-"}</div>
                                        </div>
                                        <>
                                            <div class="txt-det-lbl">
                                                <div class="text-label">Client</div>
                                                <div class="text-label-dtl">{propertyData.client_name || "-"}</div>
                                            </div>
                                            <div class="txt-det-lbl">
                                                <div class="text-label">Export Property</div>
                                                <div class="text-label-dtl">{propertyData.template_property_name || ""}</div>
                                            </div>
                                            <div class="txt-det-lbl">
                                                <div class="text-label">Export Template</div>
                                                <div class="text-label-dtl">{propertyData.template_name || "-"}</div>
                                            </div>
                                            <div class="txt-det-lbl">
                                                <div class="text-label">Created By</div>
                                                <div class="text-label-dtl">{propertyData.created_by || "-"}</div>
                                            </div>
                                            <div class="txt-det-lbl">
                                                <div class="text-label">Last Modified By</div>
                                                <div class="text-label-dtl">{propertyData.modified_by || "-"}</div>
                                            </div>
                                        </>
                                        <div class="txt-det-lbl">
                                            <div class="text-label">Created Date & Time</div>
                                            <div class="text-label-dtl">
                                                {propertyData.created_date ? moment(propertyData.created_date).format("MM-DD-YYYY h:mm A") : "-"}
                                            </div>
                                        </div>
                                        <div class="txt-det-lbl">
                                            <div class="text-label">Modified Date & Time</div>
                                            <div class="text-label-dtl">
                                                {propertyData.modified_date ? moment(propertyData.modified_date).format("MM-DD-YYYY h:mm A") : "-"}
                                            </div>
                                        </div>
                                        {propertyData?.skeleton_template && (
                                            <div class="txt-det-lbl">
                                                <div class="text-label">Core Report Name</div>
                                                <div class="text-label-dtl">{propertyData.skeleton_template || "-"}</div>
                                            </div>
                                        )}
                                        <div className={`txt-det-lbl notes-wid ${propertyData?.skeleton_template ? "template-note-shrink" : ""}`}>
                                            <div className="text-label"> Template Notes</div>
                                            <div className="text-label-desc">{propertyData.notes || "-"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-active location-sec">
                                    <div class="row m-0">
                                        <div class="col-md-12">
                                            <div id="accordion">
                                                {Object.keys(propertyData.properties_text).map((entity, index) => (
                                                    <ViewChartMainForModal
                                                        chartData={propertyData?.properties_text}
                                                        entity={entity}
                                                        openChartItemConfigModal={openChartItemConfigModal}
                                                        toggleImageListForBandModal={toggleImageListForBandModal}
                                                        isExcelExport={isExcelExport}
                                                        openFilterModal={openFilterModal}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
            {renderChartItemConfigModal()}
            {renderImageBandConfigModal()}
            {renderImageListForBandModal()}
            {renderMemoEditor()}
        </div>
    );
};
const mapStateToProps = state => {
    const { smartChartReducer } = state;
    return { smartChartReducer };
};

export default withRouter(connect(mapStateToProps, { ...actions })(SourceTemplate));
