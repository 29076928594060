import React, { useState, useEffect } from "react";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import NumberFormat from "react-number-format";
import ReactTooltip from "react-tooltip";

import { getDisplayNameFromKey, shiftObjectProps, isRecomSectionBreak, getUpdatedChartConfigData } from "../utils";
import {
    CHART_DATA_VIEWS,
    CHART_ITEMS,
    INDV_BUILDING_EXPORT_ENTITIES,
    CHART_ITEMS_FOR_EXCEL,
    EXPORTING_CHART_SECTIONS,
    DEFAULT_PROPERTY_VALUE
} from "../constants";
import { reorderArray } from "../../../config/utils";
import viewIcon from "../../../assets/img/Group 138.svg";
import ViewExportModal from "../../recommendations/components/ExportSettingsModal";
import Portal from "../../common/components/Portal";
import { recommendationsTableData } from "../../../config/tableData";
import OuterDraggable from "react-draggable";

const ChartItemConfigModal = ({
    setSmartChartData,
    configData = {},
    onCancel,
    defaultData = {},
    configOrder = {},
    isView = false,
    isExcelExport = false,
    isSkeletonView,
    chartData,
    selectedClient
}) => {
    const [chartConfigData, setChartConfigData] = useState(getUpdatedChartConfigData({ ...configData?.selectedChartConfig }));
    const [chartViews, setChartViews] = useState([]);
    const [isIndividualExport, setIsIndividualExport] = useState(
        (configData?.chartKey === "sorted_recom"
            ? configData?.selectedChartConfig?.band1?.is_indv_bld
            : configData?.selectedChartConfig?.is_indv_bld) || false
    );
    const [sortType, setSortType] = useState(configData?.selectedChartConfig?.band1?.type?.[0] || "building");
    const [systemTopCombination, setSystemTopCombination] = useState(configData?.selectedChartConfig?.nslice || null);
    const [isIndividualTradeExport, setIsIndividualTradeExport] = useState(
        configData?.selectedChartConfig?.hasOwnProperty("combination") ? !configData?.selectedChartConfig?.combination : true
    );
    const [defChartView, setDefChartView] = useState([]);
    const [tablePageBreak, setTablePageBreak] = useState(configData?.selectedChartConfig?.remove_tbl_section_break || false);
    const [summaryPageBreak, setSummaryPageBreak] = useState(configData?.selectedChartConfig?.summary_view?.remove_section_break || false);
    const [detailPageBreak, setDetailPageBreak] = useState(configData?.selectedChartConfig?.detailed_view?.remove_section_break || false);
    const [recomendationPageBreak, setRecomendationPageBreak] = useState(
        configData?.selectedChartConfig?.normal_recom?.band1?.remove_section_break || false
    );
    const [showMore, setShowMore] = useState("");
    const [modalDraggable, setModalDraggable] = useState(false);
    const [tiles, setTiles] = useState({
        summary_view: { width: "", height: "", autoCalculate: true },
        detailed_view: { width: "", height: "", autoCalculate: true }
    });
    const [focusedInput, setFocusedInput] = useState(null);
    const [errorMessage, setErrorMessage] = useState({
        summary_view: { message: "", width: null, height: null },
        detailed_view: { message: "", width: null, height: null }
    });
    const [showViewModalExport, setShowViewModalExport] = useState(false);
    const tableData = {
        keys: _.cloneDeep(recommendationsTableData.keys),
        config: _.cloneDeep(recommendationsTableData.config)
    };
    const [transform, setTransform] = useState({ left: 0, top: 0 });
    useEffect(() => {
        let currentChartItems = isExcelExport ? CHART_ITEMS_FOR_EXCEL : CHART_ITEMS;
        let defaultChartView = currentChartItems?.[configData.entity]?.[configData.chartKey]?.config
            ? Object.keys(currentChartItems[configData.entity][configData.chartKey].config)
            : [];
        const constantConfigKeys =
            currentChartItems?.project[configData?.chartKey]?.config && Object.keys(currentChartItems?.project[configData?.chartKey]?.config);
        const providedConfigKeysInOrder = [];

        Object.keys(configData?.selectedChartConfig).forEach(key => {
            if (constantConfigKeys?.includes(key)) {
                providedConfigKeysInOrder?.push(key);
            }
        });
        constantConfigKeys?.forEach(key => {
            if (!providedConfigKeysInOrder?.includes(key)) {
                providedConfigKeysInOrder?.push(key);
            }
        });
        let currentChartView = configOrder?.[configData.chartKey]
            ? [...configOrder?.[configData.chartKey]]
            : providedConfigKeysInOrder?.length > 0
            ? providedConfigKeysInOrder
            : [];

        setChartViews(currentChartView?.length ? [...currentChartView] : defaultChartView);
        setShowMore(currentChartView?.length ? currentChartView && currentChartView[0] : defaultChartView[0]);
        setDefChartView(defaultChartView);
        if (configData?.chartKey === "system" && chartConfigData?.normal_recom) {
            setChartConfigData(prevConfig => {
                return {
                    ...prevConfig,
                    ["normal_recom"]: {
                        ...prevConfig["normal_recom"],
                        band1: {
                            ...prevConfig["normal_recom"]?.band1,
                            mfilter: {
                                ...prevConfig["normal_recom"]?.band1?.mfilter,
                                ...chartData?.properties_text?.project?.band1?.mfilter
                            }
                        }
                    }
                };
            });
        }
    }, []);

    useEffect(() => {
        if (chartConfigData) {
            const { summary_view, detailed_view } = chartConfigData;
            if (summary_view?.config || detailed_view?.config) {
                setTiles(prevTiles => ({
                    summary_view: {
                        ...prevTiles.summary_view,
                        width: summary_view?.config?.width,
                        height: summary_view?.config?.height,
                        autoCalculate: summary_view?.config?.autoCalculate || true
                    },
                    detailed_view: {
                        ...prevTiles.detailed_view,
                        width: detailed_view?.config?.width,
                        height: detailed_view?.config?.height,
                        autoCalculate: detailed_view?.config?.autoCalculate || true
                    }
                }));
            }
        }
    }, []);
    const showAlert = view => {
        const x = document.getElementById("sucess-alert");
        if (x) {
            x.innerText = `${errorMessage[view]?.message}`;
            x.style.display = "block";
            x.style.visibility = "visible";
            setTimeout(function () {
                x.style.display = "none";
            }, 3000);
        }
    };

    const handleChartConfigData = (action, chartView, params) => {
        const { is_remove = false, chartType, exportChartSectionType = "" } = params;
        switch (action) {
            case "view":
                if (is_remove) {
                    setChartConfigData(prevConfig => {
                        return {
                            ..._.omit(prevConfig, [chartView])
                        };
                    });
                } else {
                    if (chartView === "table_view") {
                        setChartConfigData(prevConfig => {
                            return {
                                ...prevConfig,
                                [chartView]: ""
                            };
                        });
                    } else if (chartView === "normal_recom") {
                        setChartConfigData(prevConfig => {
                            return {
                                ...prevConfig,
                                [chartView]: {
                                    band1: {
                                        ...configData?.selectedChartConfig["normal_recom"]?.band1,
                                        ...chartData?.properties_text?.project?.band1
                                    }
                                }
                            };
                        });
                    } else {
                        const { entity, chartKey } = configData;
                        let previousSelectedValue =
                            defaultData?.[entity]?.band1?.type?.[chartKey]?.[chartView]?.chart_type ||
                            DEFAULT_PROPERTY_VALUE?.[entity]?.band1?.type?.[chartKey]?.[chartView]?.chart_type ||
                            [];
                        setChartConfigData(prevConfig => {
                            return {
                                ...prevConfig,
                                [chartView]: {
                                    config: prevConfig[chartView]?.config || { legend: true, header: true, total: true },
                                    chart_type: [...previousSelectedValue],
                                    remove_section_break: prevConfig[chartView]?.remove_section_break || false
                                }
                            };
                        });
                    }
                }

                break;
            case "chartType":
                let currentTypes = chartConfigData[chartView]?.chart_type ? [...chartConfigData[chartView].chart_type] : [];
                let updatedChartTypes = [];

                if (is_remove) {
                    updatedChartTypes = currentTypes.filter(type => type !== chartType);
                } else {
                    updatedChartTypes = [...currentTypes, chartType];
                }
                setChartConfigData(prevConfig => {
                    return {
                        ...prevConfig,
                        [chartView]: {
                            ...prevConfig[chartView],
                            chart_type: [...updatedChartTypes]
                        }
                    };
                });
                break;
            case "allSelect":
                if (is_remove) {
                    setChartConfigData({
                        summary_view: {
                            config: chartConfigData?.summary_view?.config || { legend: true, header: true, total: true }
                        },
                        detailed_view: {
                            config: chartConfigData?.detailed_view?.config || { legend: true, header: true, total: true }
                        }
                    });
                } else {
                    let allChartTypes = {};
                    if (configData?.chartKey === "EFCI") {
                        allChartTypes = {
                            detailed_view: {
                                chart_type: ["line_column_2d"]
                            }
                        };
                    } else {
                        allChartTypes = {
                            summary_view: {
                                chart_type: ["pie_2d", "pie_3d", "donut_2d", "donut_3d"],
                                config: chartConfigData?.summary_view?.config || { legend: true, header: true, total: true }
                            },
                            detailed_view: {
                                chart_type: ["stacked_column_2d", "stacked_column_3d"],
                                config: chartConfigData?.detailed_view?.config || { legend: true, header: true, total: true }
                            },
                            table_view: ""
                        };
                    }
                    setChartConfigData(prevConfig => {
                        return {
                            ...allChartTypes
                        };
                    });
                }
                break;
            case "exportChartSection":
                let currentExportChartSectionTypes = chartConfigData[chartView]?.config || { legend: true, header: true, total: true };
                setChartConfigData(prevConfig => {
                    return {
                        ...prevConfig,
                        [chartView]: {
                            ...prevConfig?.[chartView],
                            config: {
                                ...currentExportChartSectionTypes,
                                [exportChartSectionType]: !is_remove
                            }
                        }
                    };
                });
                break;
            case "pageBreak":
                const { pageBreak } = params;
                if (chartView === "summary_view") {
                    setChartConfigData(prevConfig => {
                        return {
                            ...prevConfig,
                            [chartView]: {
                                ...prevConfig?.[chartView],
                                remove_section_break: pageBreak
                            }
                        };
                    });
                }
                if (chartView === "detailed_view") {
                    setChartConfigData(prevConfig => {
                        return {
                            ...prevConfig,
                            [chartView]: {
                                ...prevConfig?.[chartView],
                                remove_section_break: pageBreak
                            }
                        };
                    });
                }
                if (chartView === "normal_recom") {
                    setChartConfigData(prevConfig => {
                        return {
                            ...prevConfig,
                            [chartView]: {
                                band1: {
                                    ...prevConfig?.[chartView]?.band1,
                                    remove_section_break: pageBreak
                                }
                            }
                        };
                    });
                }
                break;
            default:
        }
    };
    const handleClickCancel = () => {
        setChartConfigData({});
        onCancel();
    };
    useEffect(() => {
        if (errorMessage?.detailed_view?.message || errorMessage?.summary_view?.message) {
            if (errorMessage?.summary_view?.message) {
                showAlert("summary_view");
            } else if (errorMessage?.detailed_view?.message) {
                showAlert("detailed_view");
            }
        }
    }, [errorMessage]);

    const handleClickSave = () => {
        setErrorMessage({ summary_view: { message: "", width: null, height: null }, detailed_view: { message: "", width: null, height: null } });
        let additionalConfigData = INDV_BUILDING_EXPORT_ENTITIES.includes(configData?.chartKey) && isIndividualExport ? { is_indv_bld: "T" } : {};
        let chartData = CHART_DATA_VIEWS;
        let chartViewVlaue = chartViews;
        if (configData?.chartKey === "sorted_recom") {
            additionalConfigData = { ...additionalConfigData, type: [sortType] };
        }
        if (configData?.chartKey === "system") {
            additionalConfigData = { ...additionalConfigData, combination: !isIndividualTradeExport };
            if (systemTopCombination) {
                additionalConfigData = { ...additionalConfigData, nslice: systemTopCombination };
            }
            chartData = [...chartData, "system_ids", "trade_ids"];
            chartViewVlaue = [...chartViews, "system_ids", "trade_ids"];
        }
        if (configData?.chartKey === "subsystem") {
            chartData = [...chartData, "system_ids", "trade_ids", "subsystem_ids"];
            chartViewVlaue = [...chartViews, "system_ids", "trade_ids", "subsystem_ids"];
        }
        const summaryValid = isValidWidthHeight(chartConfigData?.summary_view?.config, "summary_view");
        let detailValid = false;
        if (summaryValid) {
            detailValid = isValidWidthHeight(chartConfigData?.detailed_view?.config, "detailed_view");
        }
        if (summaryValid && detailValid) {
            setChartConfigData({});
            setSmartChartData("config_chart", {
                ...configData,
                chartConfigData: { ...shiftObjectProps(chartConfigData, chartData) },
                configOrder: chartViewVlaue,
                defaultValue: configData?.selectedChartConfig || null,
                additionalKeysForConfig: { ...additionalConfigData, remove_tbl_section_break: tablePageBreak }
            });
            onCancel();
        }
    };
    const isValidWidthHeight = (config, view) => {
        if (!config?.width && config?.height) {
            setErrorMessage(prevState => ({ ...prevState, [view]: { message: "Width is required", width: true, height: false } }));
            return false;
        } else if (!config?.height && config?.width) {
            setErrorMessage(prevState => ({ ...prevState, [view]: { message: "Height is required", width: false, height: true } }));
            return false;
        } else if (config?.height || config?.width) {
            if (config.height < 30 && config.width < 30) {
                setErrorMessage(prevState => ({
                    ...prevState,
                    [view]: { message: "Both width and height must be at least 30", width: true, height: true }
                }));
            } else if (config?.height < 30) {
                setErrorMessage(prevState => ({ ...prevState, [view]: { message: "Height must be at least 30", width: false, height: true } }));
            } else if (config?.width < 30) {
                setErrorMessage(prevState => ({ ...prevState, [view]: { message: "Width must be at least 30", width: true, height: false } }));
            } else {
                return true;
            }
        } else {
            setErrorMessage(prevState => ({ ...prevState, [view]: null }));
            return true;
        }
    };

    const isAllSelected = () => {
        if (configData?.chartKey === "EFCI") {
            if (chartConfigData?.detailed_view?.chart_type?.length === 1) {
                return true;
            }
        } else if (
            chartConfigData?.summary_view?.chart_type?.length === 4 &&
            chartConfigData?.detailed_view?.chart_type?.length === 2 &&
            chartConfigData.hasOwnProperty("table_view")
        ) {
            return true;
        }
        return false;
    };

    const renderChartTypes = (view, chartKey) => {
        switch (view) {
            case "summary_view":
                return ["pie_2d", "pie_3d", "donut_2d", "donut_3d"];
            case "detailed_view":
                if (chartKey === "EFCI") {
                    return ["line_column_2d"];
                }
                return ["stacked_column_2d", "stacked_column_3d"];
            default:
                return [];
        }
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const keyList = reorderArray(chartViews, result.source.index, result.destination.index);
        setChartViews(keyList);
    };

    const getIconsForView = view => {
        switch (view) {
            case "summary_view":
                return "sm-charts-pie-chart-icon.svg";
            case "detailed_view":
                return "smart-chart-chart-icon-item.svg";
            case "table_view":
                return "sm-charts-table-blue-icon.svg";
            case "normal_recom":
                return "sm-charts-table-blue-icon.svg";
        }
    };
    const aspectRatio = 1.6667;
    function getHeightFromWidth(width) {
        return width / aspectRatio;
    }

    function getWidthFromHeight(height) {
        return height * aspectRatio;
    }
    function handleCheckboxChange(view) {
        setTiles(prevTiles => ({
            ...prevTiles,
            [view]: {
                width: "",
                height: "",
                autoCalculate: !prevTiles[view]?.autoCalculate
            }
        }));
    }

    const handleWidthChange = (value, chartView) => {
        if (focusedInput === "width") {
            setTiles(prevTiles => {
                const updatedTiles = { ...prevTiles };
                // const widthValue = value !== "0" ? value : "";
                const widthValue = value;
                let newHeight = "";
                if (tiles[chartView]?.autoCalculate) {
                    const calculatedHeight = widthValue ? getHeightFromWidth(widthValue) : "";
                    newHeight = calculatedHeight || calculatedHeight === 0 ? calculatedHeight.toFixed(2) : "";
                    updatedTiles[chartView].width = widthValue;
                    updatedTiles[chartView].height = newHeight;
                } else {
                    updatedTiles[chartView].width = value !== "" ? value : "";
                }
                updateChartConfig(chartView, updatedTiles[chartView].width, updatedTiles[chartView].height, updatedTiles[chartView].autoCalculate);
                return updatedTiles;
            });
        }
    };

    const handleHeightChange = (value, chartView) => {
        if (focusedInput === "height") {
            setTiles(prevTiles => {
                const updatedTiles = { ...prevTiles };
                const heightValue = value;
                let newWidth = "";
                if (tiles[chartView]?.autoCalculate) {
                    const calculatedWidth = heightValue ? getWidthFromHeight(heightValue) : "";
                    newWidth = calculatedWidth ? calculatedWidth?.toFixed(2) : "";
                    updatedTiles[chartView].height = heightValue;
                    updatedTiles[chartView].width = newWidth;
                } else {
                    updatedTiles[chartView].height = heightValue;
                }
                updateChartConfig(chartView, updatedTiles[chartView]?.width, updatedTiles[chartView].height, updatedTiles[chartView]?.autoCalculate);
                return updatedTiles;
            });
        }
    };

    const updateChartConfig = (chartView, width, height, autoCalculate) => {
        const numericWidth = !width ? null : parseFloat(width);
        const numericHeight = !height ? null : parseFloat(height);
        if (!isNaN(numericWidth) || !isNaN(numericHeight)) {
            setChartConfigData(prevConfig => ({
                ...prevConfig,
                [chartView]: {
                    ...prevConfig?.[chartView],
                    config: {
                        ...prevConfig?.[chartView]?.config,
                        width: numericWidth,
                        height: numericHeight,
                        autoCalculate
                    }
                }
            }));
        }
    };
    const handleDrag = (e, ui) => {
        const { x, y } = ui;
        setTransform({ left: x, top: y });
    };

    return (
        <>
            <div
                className={`modal modal-region smart-chart-popup ${configData?.chartKey === "sorted_recom" ? "sm-sorted-recom" : ""}`}
                id="modalId"
                tabindex="-1"
                style={{ display: "block" }}
            >
                <div className="modal-dialog" role="document">
                    <OuterDraggable handle=".draggable" cancel=".not-draggable" onDrag={handleDrag}>
                        <div className="modal-content outerModal_draggable" style={transform}>
                            <div className="modal-header draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    <div className="txt-hed d-flex align-items-center">{getDisplayNameFromKey(configData?.chartKey)}</div>
                                    <div className="sys-wrap">
                                        {INDV_BUILDING_EXPORT_ENTITIES.includes(configData?.chartKey) ? (
                                            <div className="check-box-area">
                                                <label className={`container-check ${isView || isSkeletonView ? "cursor-diabled" : ""} green-check`}>
                                                    Repeat for EVERY building in scope
                                                    <input
                                                        type="checkbox"
                                                        name="is_bold"
                                                        checked={isIndividualExport}
                                                        onClick={e =>
                                                            !isView &&
                                                            !isSkeletonView &&
                                                            setIsIndividualExport(prevValue => {
                                                                return !prevValue;
                                                            })
                                                        }
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        ) : null}
                                        {configData?.chartKey === "system" && (
                                            <div className="check-box-area">
                                                <label className={`container-check ${isView || isSkeletonView ? "cursor-diabled" : ""} green-check`}>
                                                    Repeat for EVERY trades in scope
                                                    <input
                                                        type="checkbox"
                                                        name="is_bold"
                                                        checked={isIndividualTradeExport}
                                                        onClick={e =>
                                                            !isView && !isSkeletonView && setIsIndividualTradeExport(prevValue => !prevValue)
                                                        }
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        )}
                                        <div className="config-top-rgt-cont">
                                            {!isExcelExport && (
                                                <div className="check-box-area">
                                                    {configData?.chartKey !== "sorted_recom" ? (
                                                        <label
                                                            className={`container-check ${
                                                                isView || isSkeletonView ? "cursor-diabled" : ""
                                                            } green-check`}
                                                        >
                                                            {" "}
                                                            Select All Charts{" "}
                                                            <input
                                                                type="checkbox"
                                                                name="is_bold"
                                                                checked={isAllSelected()}
                                                                onClick={e =>
                                                                    !isView &&
                                                                    !isSkeletonView &&
                                                                    handleChartConfigData("allSelect", "", { is_remove: !e.target.checked })
                                                                }
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    ) : null}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </h5>
                                <button type="button" className="close" onClick={() => onCancel()}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body region-otr draggable">
                                <div className="upload-area not-draggable">
                                    <div className="upload-sec cursor-hand" role="button" tabindex="0">
                                        <input type="file" multiple="" autocomplete="off" tabindex="-1" style={{ display: "none" }} />
                                    </div>
                                </div>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    {configData?.chartKey !== "sorted_recom" ? (
                                        <>
                                            {configData?.chartKey === "system" && (
                                                <div className="mr-2 pr-0 number-text-block sm-charts-top-n-inp not-draggable">
                                                    <NumberFormat
                                                        className="custom-input form-control"
                                                        value={systemTopCombination}
                                                        prefix="Top "
                                                        suffix=" +1"
                                                        placeholder="Top n +1"
                                                        displayType="input"
                                                        decimalScale={0}
                                                        allowNegative={false}
                                                        onValueChange={values => setSystemTopCombination(parseInt(values.value))}
                                                    />
                                                </div>
                                            )}
                                            <div className="col-md-12 sm-chart-views pl-1 not-draggable">
                                                <div className="left-box-sm">
                                                    {Array(Math.round(defChartView.length / 2))
                                                        .fill()
                                                        .map((dropRow, rowIndex) => (
                                                            <Droppable
                                                                droppableId={`CHART-VIEW-${rowIndex}`}
                                                                direction="vertical"
                                                                isDropDisabled={isView || isSkeletonView || chartViews.length < 2 ? true : false}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        className="dragble-area"
                                                                        ref={provided.innerRef}
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {chartViews.slice(rowIndex * 2, (rowIndex + 1) * 2).map((view, index) => (
                                                                            <>
                                                                                {configData?.itemConfig?.[view] ? (
                                                                                    <Draggable
                                                                                        key={`draggable-chart-view-${rowIndex}${index}`}
                                                                                        draggableId={`draggable-chart-view-${rowIndex}${index}`}
                                                                                        index={rowIndex * 2 + index}
                                                                                        isDragDisabled={
                                                                                            isView || isSkeletonView || chartViews.length < 2
                                                                                                ? true
                                                                                                : false || modalDraggable
                                                                                        }
                                                                                    >
                                                                                        {provided => (
                                                                                            <div
                                                                                                className="box-drg-outr"
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                key={`draggable-${index}`}
                                                                                            >
                                                                                                <div
                                                                                                    className={`drag-item height-item ${
                                                                                                        showMore === view &&
                                                                                                        !(
                                                                                                            (errorMessage?.detailed_view?.message &&
                                                                                                                view === "detailed_view") ||
                                                                                                            (errorMessage?.summary_view?.message &&
                                                                                                                view === "summary_view")
                                                                                                        )
                                                                                                            ? "current-active "
                                                                                                            : ""
                                                                                                    }${
                                                                                                        (errorMessage?.detailed_view?.message &&
                                                                                                            view === "detailed_view") ||
                                                                                                        (errorMessage?.summary_view?.message &&
                                                                                                            view === "summary_view")
                                                                                                            ? "error-border"
                                                                                                            : ""
                                                                                                    }`}
                                                                                                    onClick={() => {
                                                                                                        setShowMore(view);
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="top-sec sq-ic-out">
                                                                                                        <div className="icon">
                                                                                                            <img src="/img/icon-squre.svg" />
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="cont-sec">
                                                                                                        <div className="icons-sec">
                                                                                                            <img
                                                                                                                src={`/img/${getIconsForView(view)}`}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <h3>{getDisplayNameFromKey(view)}</h3>
                                                                                                    </div>
                                                                                                    <div className="top-sec">
                                                                                                        <div className="icon-pop-flex">
                                                                                                            <div className="checkbox">
                                                                                                                <label
                                                                                                                    className={`container-check ${
                                                                                                                        isView ||
                                                                                                                        isSkeletonView ||
                                                                                                                        isExcelExport
                                                                                                                            ? "cursor-diabled"
                                                                                                                            : ""
                                                                                                                    } green-check`}
                                                                                                                >
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="is_bold"
                                                                                                                        checked={
                                                                                                                            view === "table_view"
                                                                                                                                ? chartConfigData?.hasOwnProperty(
                                                                                                                                      "table_view"
                                                                                                                                  )
                                                                                                                                : view ==
                                                                                                                                  "normal_recom"
                                                                                                                                ? chartConfigData[
                                                                                                                                      view
                                                                                                                                  ]?.hasOwnProperty(
                                                                                                                                      "band1"
                                                                                                                                  )
                                                                                                                                : chartConfigData &&
                                                                                                                                  chartConfigData[
                                                                                                                                      view
                                                                                                                                  ]?.hasOwnProperty(
                                                                                                                                      "chart_type"
                                                                                                                                  )
                                                                                                                        }
                                                                                                                        onClick={e =>
                                                                                                                            !isView &&
                                                                                                                            !isSkeletonView &&
                                                                                                                            handleChartConfigData(
                                                                                                                                "view",
                                                                                                                                view,
                                                                                                                                {
                                                                                                                                    is_remove:
                                                                                                                                        !e.target
                                                                                                                                            .checked
                                                                                                                                }
                                                                                                                            )
                                                                                                                        }
                                                                                                                        disabled={isExcelExport}
                                                                                                                    />
                                                                                                                    <span className="checkmark"></span>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                {provided.placeholder}
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ) : null}
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        ))}
                                                </div>
                                                {showMore !== "table_view" && showMore?.length > 0 ? (
                                                    <div className="fot-sec top-border types-otr-chart-sec overflw-scrl">
                                                        <div className="header-top-txt">
                                                            <img src={viewIcon} alt="icon" />
                                                            <h4>{getDisplayNameFromKey(showMore)} Settings</h4>
                                                        </div>
                                                        <div className="wrapper-fot-select">
                                                            <div className="fot-select">
                                                                <label>Page Break</label>
                                                                <div className="check-box-area">
                                                                    <label
                                                                        className={`container-check ${
                                                                            isView || isSkeletonView ? "cursor-diabled" : ""
                                                                        } green-check`}
                                                                    >
                                                                        Yes
                                                                        <input
                                                                            type="checkbox"
                                                                            name="is_bold"
                                                                            checked={
                                                                                showMore === "table_view"
                                                                                    ? !tablePageBreak
                                                                                    : showMore === "summary_view"
                                                                                    ? !summaryPageBreak
                                                                                    : showMore === "normal_recom"
                                                                                    ? !recomendationPageBreak
                                                                                    : !detailPageBreak
                                                                            }
                                                                            onClick={e => {
                                                                                if (showMore === "summary_view") {
                                                                                    setSummaryPageBreak(prevValue => !prevValue);
                                                                                    handleChartConfigData("pageBreak", showMore, {
                                                                                        is_remove: !e.target.checked,
                                                                                        pageBreak: !summaryPageBreak
                                                                                    });
                                                                                } else if (showMore === "detailed_view") {
                                                                                    setDetailPageBreak(prevValue => !prevValue);
                                                                                    handleChartConfigData("pageBreak", showMore, {
                                                                                        is_remove: !e.target.checked,
                                                                                        pageBreak: !detailPageBreak
                                                                                    });
                                                                                } else if (showMore === "normal_recom") {
                                                                                    setRecomendationPageBreak(prevValue => !prevValue);
                                                                                    handleChartConfigData("pageBreak", showMore, {
                                                                                        is_remove: !e.target.checked,
                                                                                        pageBreak: !recomendationPageBreak
                                                                                    });
                                                                                }
                                                                            }}
                                                                            disabled={isSkeletonView || isView}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="check-box-area">
                                                                    <label
                                                                        className={`container-check ${
                                                                            isView || isSkeletonView ? "cursor-diabled" : ""
                                                                        } green-check`}
                                                                    >
                                                                        No
                                                                        <input
                                                                            type="checkbox"
                                                                            name="is_bold"
                                                                            checked={
                                                                                showMore === "table_view"
                                                                                    ? tablePageBreak
                                                                                    : showMore === "summary_view"
                                                                                    ? summaryPageBreak
                                                                                    : showMore === "normal_recom"
                                                                                    ? recomendationPageBreak
                                                                                    : detailPageBreak
                                                                            }
                                                                            onClick={e => {
                                                                                if (showMore === "summary_view") {
                                                                                    setSummaryPageBreak(prevValue => !prevValue);
                                                                                    handleChartConfigData("pageBreak", showMore, {
                                                                                        is_remove: !e.target.checked,
                                                                                        pageBreak: !summaryPageBreak
                                                                                    });
                                                                                } else if (showMore === "detailed_view") {
                                                                                    setDetailPageBreak(prevValue => !prevValue);
                                                                                    handleChartConfigData("pageBreak", showMore, {
                                                                                        is_remove: !e.target.checked,
                                                                                        pageBreak: !detailPageBreak
                                                                                    });
                                                                                } else if (showMore === "normal_recom") {
                                                                                    setRecomendationPageBreak(prevValue => !prevValue);
                                                                                    handleChartConfigData("pageBreak", showMore, {
                                                                                        is_remove: !e.target.checked,
                                                                                        pageBreak: !recomendationPageBreak
                                                                                    });
                                                                                }
                                                                            }}
                                                                            disabled={isSkeletonView || isView}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {showMore !== "normal_recom" && (
                                                                <>
                                                                    <div className="fot-select">
                                                                        <label>Chart Types</label>
                                                                        {renderChartTypes(showMore, configData.chartKey).map((chartType, i) => (
                                                                            <div className="check-box-area" key={i}>
                                                                                <label
                                                                                    className={`container-check ${
                                                                                        isView || isSkeletonView ? "cursor-diabled" : ""
                                                                                    } green-check`}
                                                                                >
                                                                                    {getDisplayNameFromKey(chartType)}
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name="is_bold"
                                                                                        checked={
                                                                                            chartConfigData?.[showMore]?.chart_type?.includes(
                                                                                                chartType
                                                                                            )
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        onClick={e =>
                                                                                            !isView &&
                                                                                            !isSkeletonView &&
                                                                                            handleChartConfigData("chartType", showMore, {
                                                                                                is_remove: !e.target.checked,
                                                                                                chartType: chartType
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    <div className="fot-select">
                                                                        <label>Options</label>
                                                                        {EXPORTING_CHART_SECTIONS.map((exportChartSection, i) => (
                                                                            <div className="check-box-area" key={i}>
                                                                                <label
                                                                                    className={`container-check ${
                                                                                        isView || isSkeletonView ? "cursor-diabled" : ""
                                                                                    } green-check`}
                                                                                >
                                                                                    {exportChartSection.label}
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name="is_bold"
                                                                                        checked={
                                                                                            chartConfigData?.[showMore]?.config?.[
                                                                                                exportChartSection.key
                                                                                            ] || false
                                                                                        }
                                                                                        onClick={e =>
                                                                                            !isView &&
                                                                                            !isSkeletonView &&
                                                                                            handleChartConfigData("exportChartSection", showMore, {
                                                                                                is_remove: !e.target.checked,
                                                                                                exportChartSectionType: exportChartSection.key
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                    <div className="fot-select chart-size">
                                                                        <label>Chart size</label>
                                                                        <div className="chart-box-out">
                                                                            <div className="check-box-area">
                                                                                <label
                                                                                    className={`container-check ${
                                                                                        isView || isSkeletonView ? "cursor-diabled" : ""
                                                                                    } green-check`}
                                                                                >
                                                                                    W
                                                                                </label>
                                                                                <NumberFormat
                                                                                    className={`custom-input form-control ${
                                                                                        (errorMessage?.detailed_view?.message &&
                                                                                            errorMessage?.detailed_view?.width &&
                                                                                            showMore === "detailed_view") ||
                                                                                        (errorMessage?.summary_view?.message &&
                                                                                            errorMessage?.summary_view.width &&
                                                                                            showMore === "summary_view")
                                                                                            ? "error-border"
                                                                                            : ""
                                                                                    }`}
                                                                                    placeholder="eg:450"
                                                                                    value={parseFloat(tiles[showMore]?.width)}
                                                                                    thousandSeparator={false}
                                                                                    decimalScale={2}
                                                                                    onValueChange={values => {
                                                                                        const { value } = values;
                                                                                        handleWidthChange(value, showMore);
                                                                                    }}
                                                                                    allowNegative={false}
                                                                                    allowLeadingZeros={false}
                                                                                    isAllowed={({ value }) => {
                                                                                        return (
                                                                                            value === "" ||
                                                                                            (!isNaN(value) && parseFloat(value) <= 1000)
                                                                                        );
                                                                                    }}
                                                                                    onFocus={() => setFocusedInput("width")}
                                                                                    onBlur={() => setFocusedInput(null)}
                                                                                    disabled={isSkeletonView || isView}
                                                                                />
                                                                                <span class="pt-txt">pt</span>
                                                                            </div>
                                                                            <div
                                                                                className={`img-wd ${
                                                                                    tiles[showMore]?.autoCalculate ? "filled-color" : ""
                                                                                }`}
                                                                                onClick={() => handleCheckboxChange(showMore)}
                                                                            >
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="63.614"
                                                                                    height="17.878"
                                                                                    viewBox="0 0 63.614 17.878"
                                                                                >
                                                                                    <g
                                                                                        id="Group_140"
                                                                                        data-name="Group 140"
                                                                                        transform="translate(-1121.636 -679.061)"
                                                                                    >
                                                                                        <path
                                                                                            id="hyperlink-icon"
                                                                                            d="M8.807,4.957A1.113,1.113,0,0,1,7.233,3.383L9.125,1.5a5.148,5.148,0,0,1,7.262,0l.01.012a5.146,5.146,0,0,1-.01,7.25l-1.891,1.884A1.113,1.113,0,1,1,12.921,9.07l1.891-1.891a2.922,2.922,0,0,0,0-4.107L14.8,3.055a2.922,2.922,0,0,0-4.1.01L8.809,4.957Zm2.037.46a1.113,1.113,0,0,1,1.567,1.574L6.99,12.412a1.113,1.113,0,0,1-1.574-1.573l5.423-5.423Zm-1.81,7.54a1.113,1.113,0,0,1,1.573,1.574L8.759,16.38a5.146,5.146,0,0,1-7.24.02l-.041-.039A5.148,5.148,0,0,1,1.5,9.118L3.346,7.274A1.113,1.113,0,1,1,4.921,8.843L3.072,10.692a2.923,2.923,0,0,0,0,4.11h0a2.92,2.92,0,0,0,4.113,0Z"
                                                                                            transform="translate(1144.501 679.062)"
                                                                                            fill="#bdc8dd"
                                                                                        />
                                                                                        <path
                                                                                            id="Line_12"
                                                                                            data-name="Line 12"
                                                                                            d="M12,.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H12a.75.75,0,0,1,.75.75A.75.75,0,0,1,12,.75Z"
                                                                                            transform="translate(1122.386 688.5)"
                                                                                            fill="#bdc8dd"
                                                                                        />
                                                                                        <path
                                                                                            id="Line_13"
                                                                                            data-name="Line 13"
                                                                                            d="M12,.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H12a.75.75,0,0,1,.75.75A.75.75,0,0,1,12,.75Z"
                                                                                            transform="translate(1172.5 688.5)"
                                                                                            fill="#bdc8dd"
                                                                                        />
                                                                                    </g>
                                                                                </svg>
                                                                            </div>
                                                                            <div className="check-box-area">
                                                                                <label
                                                                                    className={`container-check ${
                                                                                        isView || isSkeletonView ? "cursor-diabled" : ""
                                                                                    } green-check`}
                                                                                >
                                                                                    H
                                                                                </label>
                                                                                <NumberFormat
                                                                                    className={`custom-input form-control ${
                                                                                        (errorMessage?.detailed_view?.message &&
                                                                                            errorMessage?.detailed_view?.height &&
                                                                                            showMore === "detailed_view") ||
                                                                                        (errorMessage?.summary_view?.message &&
                                                                                            errorMessage?.summary_view?.height &&
                                                                                            showMore === "summary_view")
                                                                                            ? "error-border"
                                                                                            : ""
                                                                                    }`}
                                                                                    placeholder="eg:270"
                                                                                    value={parseFloat(tiles[showMore]?.height)}
                                                                                    thousandSeparator={false}
                                                                                    decimalScale={2}
                                                                                    onValueChange={values => {
                                                                                        const { value } = values;
                                                                                        handleHeightChange(value, showMore);
                                                                                    }}
                                                                                    allowNegative={false}
                                                                                    isAllowed={({ value }) => {
                                                                                        return (
                                                                                            value === "" ||
                                                                                            (!isNaN(value) && parseFloat(value) <= 1000)
                                                                                        );
                                                                                    }}
                                                                                    onFocus={() => setFocusedInput("height")}
                                                                                    onBlur={() => setFocusedInput(null)}
                                                                                    disabled={isSkeletonView || isView}
                                                                                />
                                                                                <span class="pt-txt">pt</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {showMore === "normal_recom" && (
                                                                <div className="btn-area fot-select">
                                                                    <label>Column Configuration</label>
                                                                    <button
                                                                        className="btn btn-conf"
                                                                        onClick={() => {
                                                                            setShowViewModalExport(true);
                                                                        }}
                                                                        disabled={isSkeletonView || isView}
                                                                    >
                                                                        Config <img src="/img/blue_arrow_right.svg" />
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    showMore === "table_view" && (
                                                        <div className="fot-sec top-border types-otr-chart-sec overflw-scrl">
                                                            <div className="header-top-txt">
                                                                <img src={viewIcon} alt="icon" />
                                                                <h4>{getDisplayNameFromKey(showMore)} Settings</h4>
                                                            </div>
                                                            <div className="fot-select">
                                                                <label>Page Break</label>
                                                                <div className="check-box-area">
                                                                    <label
                                                                        className={`container-check ${
                                                                            isView || isSkeletonView ? "cursor-diabled" : ""
                                                                        } green-check`}
                                                                    >
                                                                        Yes
                                                                        <input
                                                                            type="checkbox"
                                                                            name="is_bold"
                                                                            checked={
                                                                                showMore === "table_view"
                                                                                    ? !tablePageBreak
                                                                                    : showMore === "summary_view"
                                                                                    ? !summaryPageBreak
                                                                                    : !detailPageBreak
                                                                            }
                                                                            onClick={e => {
                                                                                if (showMore === "table_view") {
                                                                                    setTablePageBreak(prevValue => !prevValue);
                                                                                } else if (showMore === "summary_view") {
                                                                                    setSummaryPageBreak(prevValue => !prevValue);
                                                                                    handleChartConfigData("pageBreak", showMore, {
                                                                                        is_remove: !e.target.checked,
                                                                                        pageBreak: !summaryPageBreak
                                                                                    });
                                                                                } else if (showMore === "detailed_view") {
                                                                                    setDetailPageBreak(prevValue => !prevValue);
                                                                                    handleChartConfigData("pageBreak", showMore, {
                                                                                        is_remove: !e.target.checked,
                                                                                        pageBreak: !detailPageBreak
                                                                                    });
                                                                                }
                                                                            }}
                                                                            disabled={isSkeletonView || isView}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="check-box-area">
                                                                    <label
                                                                        className={`container-check ${
                                                                            isView || isSkeletonView ? "cursor-diabled" : ""
                                                                        } green-check`}
                                                                    >
                                                                        No
                                                                        <input
                                                                            type="checkbox"
                                                                            name="is_bold"
                                                                            checked={
                                                                                showMore === "table_view"
                                                                                    ? tablePageBreak
                                                                                    : showMore === "summary_view"
                                                                                    ? summaryPageBreak
                                                                                    : detailPageBreak
                                                                            }
                                                                            onClick={() => {
                                                                                setTablePageBreak(prevValue => !prevValue);
                                                                            }}
                                                                            disabled={isSkeletonView || isView}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="radio-area not-draggable">
                                            <div className="heading">
                                                <h3>Please Select Breakdown Type.</h3>
                                            </div>
                                            <div className="radio-sec">
                                                <div className="button-group">
                                                    <div className="button">
                                                        <label
                                                            className={`container cursor-hand ${isView || isSkeletonView ? "cursor-diabled" : ""}`}
                                                        >
                                                            <input
                                                                onChange={() => !isView && !isSkeletonView && setSortType("building")}
                                                                checked={sortType === "building"}
                                                                type="radio"
                                                            />
                                                            Building
                                                        </label>
                                                    </div>
                                                    <div className="button">
                                                        <label
                                                            className={`container cursor-hand ${isView || isSkeletonView ? "cursor-diabled" : ""}`}
                                                        >
                                                            <input
                                                                onChange={() => !isView && !isSkeletonView && setSortType("addition")}
                                                                checked={sortType === "addition"}
                                                                type="radio"
                                                            />
                                                            Addition
                                                        </label>
                                                    </div>
                                                    <div className="button">
                                                        <label
                                                            className={`container cursor-hand ${isView || isSkeletonView ? "cursor-diabled" : ""}`}
                                                        >
                                                            <input
                                                                onChange={() => !isView && !isSkeletonView && setSortType("responsibility")}
                                                                checked={sortType === "responsibility"}
                                                                type="radio"
                                                            />
                                                            Responsibility
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </DragDropContext>
                                <div className="btn-sec not-draggable">
                                    <div className="text-right btnOtr edit-cmn-btn mt-4">
                                        <button
                                            type="button"
                                            className="btn btn-secondary btnClr col-md-2 mr-3"
                                            data-dismiss="modal"
                                            onClick={() => handleClickCancel()}
                                        >
                                            Cancel
                                        </button>
                                        {!isView && !isSkeletonView ? (
                                            <button type="button" className="btn btn-primary btnRgion col-md-2" onClick={() => handleClickSave()}>
                                                Save
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OuterDraggable>
                </div>
            </div>
            {showViewModalExport ? (
                <Portal
                    body={
                        <ViewExportModal
                            keys={tableData.keys}
                            config={tableData.config}
                            tableData={tableData}
                            onCancel={() => setShowViewModalExport(false)}
                            projectIdForSmartChart={
                                chartData?.properties_text?.project?.band1?.mfilter?.project_ids[0] ||
                                configData?.selectedChartConfig?.normal_recom?.band1?.mfilter?.project_ids[0]
                            }
                            isSmartChartView={true}
                            clientIdForSmartChart={selectedClient}
                            setNormalRecomData={(e, groupingData, Isgrouping) => {
                                setChartConfigData(prevConfig => {
                                    return {
                                        ...prevConfig,
                                        ["normal_recom"]: {
                                            ...prevConfig["normal_recom"],
                                            band1: {
                                                ...prevConfig["normal_recom"]?.band1,
                                                settings_id: e,
                                                ...(Isgrouping && { grouping: groupingData })
                                            }
                                        }
                                    };
                                });
                            }}
                            smartChartTileConfig={chartConfigData?.normal_recom}
                            settingsId={chartConfigData?.normal_recom?.band1?.settings_id}
                            grouping={true}
                        />
                    }
                    onCancel={() => setShowViewModalExport(false)}
                />
            ) : null}
        </>
    );
};

export default ChartItemConfigModal;
