import React, { Component } from "react";
import { CompactPicker, SketchPicker } from "react-color";
import { connect } from "react-redux";
import qs from "query-string";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import _, { property } from "lodash";
import reactCSS from "reactcss";

import { ALIGNMENTS, FONT_COLOR, HEADINGS, LIST_STYLES, SPACING_RULE, FRAME_STYLES } from "../constants";
import Portal from "../../common/components/Portal";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import actions from "../actions";
import reportPropertyActions from "../../reportProperties/actions";
import ReactTooltip from "react-tooltip";
import SortedRecommendation from "./SortedRecommendation";
import NormalRecommendationForm from "./NormalRecommendationForm";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SortedRecommendationForm from "./SortedRecommendationForm";
import { reorderArray } from "../../../config/utils";
import BbiForm from "./BbiForm";

let initial_state = {
    id: "",
    name: "",
    client_id: "",
    description: "",
    notes: "",
    asset_props: {
        table: {
            table_page_fit: false,
            data: {
                font_id: "",
                alignment: "left",
                font_size: 6,
                font_color: "435254",
                cell_margin: {
                    top: 0.1,
                    left: 0.3,
                    right: 0.09,
                    bottom: 0.04
                }
            },
            sub_total: {
                font_id: "",
                bg_color: "003f2d",
                alignment: "left",
                font_size: 6,
                font_color: "ffffff",
                cell_margin: {
                    top: 0.1,
                    left: 0.3,
                    right: 0.09,
                    bottom: 0.04
                }
            },
            heading: {
                font_id: "",
                bg_color: "003f2d",
                alignment: "left",
                font_size: 6,
                font_color: "ffffff",
                cell_margin: {
                    top: 0.1,
                    left: 0.3,
                    right: 0.09,
                    bottom: 0.04
                },
                blank_line_height: 4
            },
            custom_head: {
                font_size: 14,
                font_id: "",
                font_color: "",
                bg_color: "",
                alignment: "left",
                cell_margin: {
                    top: 0.1,
                    left: 0.3,
                    right: 0.09,
                    bottom: 0.04
                },
                blank_line_height: 4
            },
            table_style_id: ""
        },
        properties: [
            {
                width: 1,
                is_active: true,
                heading: "Code",
                is_static_field: true,
                key: "assets.code"
            },
            {
                width: 1,
                is_active: true,
                heading: "Asset Name",
                is_static_field: true,
                key: "assets.asset_name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Asset Description",
                is_static_field: true,
                key: "assets.asset_description"
            },
            {
                width: 1,
                is_active: false,
                heading: "Asset Condition Description",
                is_static_field: true,
                key: "assets.asset_condition"
            },
            {
                width: 1,
                is_active: false,
                heading: "Asset Note",
                is_static_field: true,
                key: "assets.asset_note"
            },
            {
                width: 1,
                is_active: false,
                heading: "CSP Unit",
                is_static_field: true,
                key: "assets.csp_unit"
            },
            {
                width: 1,
                is_active: false,
                heading: "CRV",
                is_static_field: true,
                key: "assets.crv"
            },
            {
                width: 1,
                is_active: false,
                heading: "FCA Cost",
                is_static_field: true,
                key: "assets.fca_cost"
            },
            {
                width: 1,
                is_active: false,
                heading: "Asset Status",
                is_static_field: true,
                key: "assets.asset_status_id"
            },
            {
                width: 1,
                is_active: false,
                heading: "Asset Tag",
                is_static_field: true,
                key: "assets.asset_tag"
            },
            {
                width: 1,
                is_active: false,
                heading: "Model Number",
                is_static_field: true,
                key: "assets.model_number"
            },
            {
                width: 1,
                is_active: false,
                heading: "Core Model Number",
                is_static_field: true,
                key: "assets.core_model_number"
            },
            {
                width: 1,
                is_active: false,
                heading: "Serial Number",
                is_static_field: true,
                key: "assets.serial_number"
            },
            {
                width: 1,
                is_active: false,
                heading: "Region",
                is_static_field: true,
                key: "regions.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Site",
                is_static_field: true,
                key: "sites.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Building",
                is_static_field: true,
                key: "buildings.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Building Description",
                is_static_field: true,
                key: "buildings.description"
            },
            {
                width: 1,
                is_active: false,
                heading: "Building Type",
                is_static_field: true,
                key: "buildings.main_building_type_name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Addition",
                is_static_field: true,
                key: "additions.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Floor",
                is_static_field: true,
                key: "floors.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Room Number",
                is_static_field: true,
                key: "assets.room_number"
            },
            {
                width: 1,
                is_active: false,
                heading: "Architectural Room Number",
                is_static_field: true,
                key: "assets.architectural_room_number"
            },
            {
                width: 1,
                is_active: false,
                heading: "Room Name",
                is_static_field: true,
                key: "assets.room_name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Location",
                is_static_field: true,
                key: "assets.location"
            },
            {
                width: 1,
                is_active: false,
                heading: "Additional Room Description",
                is_static_field: true,
                key: "assets.additional_room_description"
            },
            {
                width: 1,
                is_active: false,
                heading: "Year Manufactured",
                is_static_field: true,
                key: "assets.year_manufactured"
            },
            {
                width: 1,
                is_active: false,
                heading: "Manufacturer",
                is_static_field: true,
                key: "assets.manufacturer"
            },
            {
                width: 1,
                is_active: false,
                heading: "Installed Year",
                is_static_field: true,
                key: "assets.installed_year"
            },
            {
                width: 1,
                is_active: false,
                heading: "Installed Year Status",
                is_static_field: true,
                key: "assets.installed_year_status"
            },
            {
                width: 1,
                is_active: false,
                heading: "Current Age",
                is_static_field: true,
                key: "assets.current_age"
            },
            {
                width: 1,
                is_active: false,
                heading: "Service Life",
                is_static_field: true,
                key: "assets.service_life"
            },
            {
                width: 1,
                is_active: false,
                heading: "End of Service Life",
                is_static_field: true,
                key: "assets.end_of_service_life"
            },
            {
                width: 1,
                is_active: false,
                heading: "Useful Life Remaining",
                is_static_field: true,
                key: "assets.usefull_life_remaining"
            },
            {
                width: 1,
                is_active: false,
                heading: "Refrigerant",
                is_static_field: true,
                key: "assets.refrigerant"
            },
            {
                width: 1,
                is_active: false,
                heading: "Warranty Start",
                is_static_field: true,
                key: "assets.warranty_start"
            },
            {
                width: 1,
                is_active: false,
                heading: "Warranty End",
                is_static_field: true,
                key: "assets.warranty_end"
            },
            {
                width: 1,
                is_active: false,
                heading: "Install Date",
                is_static_field: true,
                key: "assets.install_date"
            },
            {
                width: 1,
                is_active: false,
                heading: "Startup Date",
                is_static_field: true,
                key: "assets.startup_date"
            },
            {
                width: 1,
                is_active: false,
                heading: "Capacity",
                is_static_field: true,
                key: "assets.capacity"
            },
            {
                width: 1,
                is_active: false,
                heading: "Area Served",
                is_static_field: true,
                key: "assets.area_served"
            },
            {
                width: 1,
                is_active: false,
                heading: "Asset Type",
                is_static_field: true,
                key: "asset_types.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Asset Barcode",
                is_static_field: true,
                key: "assets.asset_barcode"
            },
            {
                width: 1,
                is_active: false,
                heading: "Asset CMMS ID",
                is_static_field: true,
                key: "assets.asset_cmms_id"
            },
            {
                width: 1,
                is_active: false,
                heading: "Upstream Asset Barcode Number",
                is_static_field: true,
                key: "assets.upstream_asset_barcode_number"
            },
            {
                width: 1,
                is_active: false,
                heading: "Linked Asset Barcode Number",
                is_static_field: true,
                key: "assets.linked_asset_barcode_number"
            },
            {
                width: 1,
                is_active: false,
                heading: "Source Panel Barcode Number",
                is_static_field: true,
                key: "assets.source_panel_barcode_number"
            },
            {
                width: 1,
                is_active: false,
                heading: "Source Panel",
                is_static_field: true,
                key: "assets.source_panel"
            },
            {
                width: 1,
                is_active: false,
                heading: "Notes",
                is_static_field: true,
                key: "assets.notes"
            },
            {
                width: 1,
                is_active: false,
                heading: "Uniformat Level 1 ID",
                is_static_field: true,
                key: "uniformat_level_1s.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Uniformat Level 2 ID",
                is_static_field: true,
                key: "uniformat_level_2s.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Uniformat Level 3 ID",
                is_static_field: true,
                key: "uniformat_level_3s.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Uniformat Level 4 ID",
                is_static_field: true,
                key: "uniformat_level_4s.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Uniformat Level 5 ID",
                is_static_field: true,
                key: "uniformat_level_5s.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Uniformat Level 6 ID",
                is_static_field: true,
                key: "uniformat_level_6s.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Created At",
                is_static_field: true,
                key: "assets.created_at"
            },
            {
                width: 1,
                is_active: false,
                heading: "Updated At",
                is_static_field: true,
                key: "assets.updated_at"
            },
            {
                width: 1,
                is_active: false,
                heading: "Criticality",
                is_static_field: true,
                key: "assets.criticality"
            },
            {
                width: 1,
                is_active: false,
                heading: "Client",
                is_static_field: true,
                key: "clients.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Main Category",
                is_static_field: true,
                key: "main_categories.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Sub Category 1",
                is_static_field: true,
                key: "sub_category_1.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Sub Category 2",
                is_static_field: true,
                key: "sub_category_2.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Sub Category 3",
                is_static_field: true,
                key: "sub_category_3.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Client Asset Condition",
                is_static_field: true,
                key: "client_asset_conditions.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Trade",
                is_static_field: true,
                key: "trades.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Sub System",
                is_static_field: true,
                key: "sub_systems.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "System",
                is_static_field: true,
                key: "systems.name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Parent Global ID",
                is_static_field: true,
                key: "assets.parent_global_id"
            },
            {
                width: 1,
                is_active: false,
                heading: "Survey Global ID",
                is_static_field: true,
                key: "assets.survey_global_id"
            },
            {
                width: 1,
                is_active: false,
                heading: "Survey ID",
                is_static_field: true,
                key: "assets.survey_id"
            },
            {
                width: 1,
                is_active: false,
                heading: "GUID",
                is_static_field: true,
                key: "assets.guid"
            },
            {
                width: 1,
                is_active: false,
                heading: "Estimated Replacement Cost",
                is_static_field: true,
                key: "assets.est_replacement_cost"
            },
            {
                width: 1,
                is_active: false,
                heading: "Source Panel Name",
                is_static_field: true,
                key: "assets.source_panel_name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Skysite Hyperlink",
                is_static_field: true,
                key: "assets.skysite_hyperlink"
            },
            {
                width: 1,
                is_active: false,
                heading: "Capacity Unit",
                is_static_field: true,
                key: "assets.capacity_unit"
            },
            {
                width: 1,
                is_active: false,
                heading: "Quantity",
                is_static_field: true,
                key: "assets.quantity"
            },
            {
                width: 1,
                is_active: false,
                heading: "RTLS Tag",
                is_static_field: true,
                key: "assets.rtls_tag"
            },
            {
                width: 1,
                is_active: false,
                heading: "Latitude",
                is_static_field: true,
                key: "assets.latitude"
            },
            {
                width: 1,
                is_active: false,
                heading: "Longitude",
                is_static_field: true,
                key: "assets.longitude"
            },
            {
                width: 1,
                is_active: false,
                heading: "Age",
                is_static_field: true,
                key: "assets.age"
            },
            {
                width: 1,
                is_active: false,
                heading: "New Asset",
                is_static_field: true,
                key: "assets.new_asset"
            },
            {
                width: 1,
                is_active: false,
                heading: "Survey Property Note",
                is_static_field: true,
                key: "assets.survey_property_note"
            },
            {
                width: 1,
                is_active: false,
                heading: "Capacity Status",
                is_static_field: true,
                key: "assets.capacity_status"
            },
            {
                width: 1,
                is_active: false,
                heading: "Name Plate Status",
                is_static_field: true,
                key: "assets.name_plate_status"
            },
            {
                width: 1,
                is_active: false,
                heading: "QA Notes",
                is_static_field: true,
                key: "assets.qa_notes"
            },
            {
                width: 1,
                is_active: false,
                heading: "Unit Cost",
                is_static_field: true,
                key: "assets.unit_cost"
            },
            {
                width: 1,
                is_active: false,
                heading: "Additional QA Notes",
                is_static_field: true,
                key: "assets.additional_qa_notes"
            },
            {
                width: 1,
                is_active: false,
                heading: "Surveyor",
                is_static_field: true,
                key: "assets.surveyor"
            },
            {
                width: 1,
                is_active: false,
                heading: "Editor",
                is_static_field: true,
                key: "assets.editor"
            },
            {
                width: 1,
                is_active: false,
                heading: "Survey Date Created",
                is_static_field: true,
                key: "assets.survey_date_created"
            },
            {
                width: 1,
                is_active: false,
                heading: "Survey Date Edited",
                is_static_field: true,
                key: "assets.survey_date_edited"
            },
            {
                width: 1,
                is_active: false,
                heading: "Asset Condition Notes",
                is_static_field: true,
                key: "assets.asset_condition_notes"
            },
            {
                width: 1,
                is_active: false,
                heading: "Category Description",
                is_static_field: true,
                key: "assets.category_description"
            },
            {
                width: 1,
                is_active: false,
                heading: "Recommendation Count",
                is_static_field: true,
                key: "assets.recommendation_count"
            },
            {
                width: 1,
                is_active: false,
                heading: "Has Recommendation",
                is_static_field: true,
                key: "assets.has_recommendation"
            },
            {
                width: 1,
                is_active: false,
                heading: "Image Count",
                is_static_field: true,
                key: "assets.image_count"
            },
            {
                width: 1,
                is_active: false,
                heading: "Uniformat Level 3 Custom Description",
                is_static_field: true,
                key: "assets.uniformat_level_3_custom_description"
            },
            {
                width: 1,
                is_active: false,
                heading: "Asset Ownership",
                is_static_field: true,
                key: "assets.asset_ownership"
            },
            {
                width: 1,
                is_active: false,
                heading: "Asset Name Unique",
                is_static_field: true,
                key: "assets.asset_name_unique"
            },
            {
                width: 1,
                is_active: false,
                heading: "Energy Use Type",
                is_static_field: true,
                key: "assets.energy_use_type"
            }
        ]
    },
    properties: {
        chart: {
            width: 500,
            height: 300,
            alignment: "center",
            frame: false,
            frame_props: {
                size: "6",
                space: "1",
                color: "bfbfbf",
                val: "single"
            }
        },
        header: {
            project: {
                font_id: "",
                font_size: 8.5,
                color: "4b90fa",
                bold: false,
                font_id: ""
            },
            logo: {
                width: 75,
                height: 25,
                client: false
            },
            client: {
                font_id: "",
                font_size: 8.5,
                color: "4b90fa",
                bold: false
            }
        },
        heading: {
            bold: true,
            color: "101010",
            alignment: "center",
            font_size: 13,
            font_id: ""
        },
        x_axis: {
            color: "666666",
            font_size: 11
        },
        y_axis: {
            color: "666666",
            font_size: 11
        },
        data_labels: {
            color: "000000",
            font_size: 11
        },
        chart_font: {
            font_id: ""
        },
        legend: {
            show_legend: true,
            backgroundColor: "",
            borderColor: "",
            borderRadius: 0,
            borderWidth: 0,
            font_size: 12,
            bold: true,
            font_color: "333333"
        },
        custom_legend: {
            show_legend: true,
            legend_heading: {
                bold: false,
                color: "101010",
                alignment: "left",
                font_size: 10,
                font_id: "",
                bg_color: "ffffff"
            },
            legend_value: {
                bold: false,
                color: "101010",
                alignment: "left",
                font_size: 10,
                font_id: "",
                bg_color: "ffffff"
            }
        },
        total: {
            show_total: true,
            bold: false,
            color: "101010",
            alignment: "left",
            font_size: 12,
            font_id: "",
            bg_color: "ffffff"
        },
        table: {
            table_style_id: "",
            col_width: 10,
            table_page_fit: false,
            sub_total: {
                font_id: "",
                alignment: "left",
                font_size: 12,
                font_color: "ffffff",
                bg_color: "4117bf",
                cell_margin: {
                    top: 0.1,
                    left: 0.3,
                    right: 0.09,
                    bottom: 0.04
                }
            },
            data: {
                font_id: "",
                alignment: "",
                font_size: 13,
                font_color: "",
                cell_margin: {
                    top: 0.1,
                    left: 0.3,
                    right: 0.09,
                    bottom: 0.04
                }
            },
            heading: {
                font_id: "",
                font_size: 3,
                bg_color: "4117bf",
                alignment: "left",
                // font_size: 14,
                font_color: "fff700",
                cell_margin: {
                    top: 0.1,
                    left: 0.3,
                    right: 0.09,
                    bottom: 0.04
                }
            },
            custom_head: {
                font_id: "",
                bg_color: "4117bf",
                alignment: "center",
                font_size: 15,
                font_color: "fff700",
                cell_margin: {
                    top: 0.1,
                    left: 0.3,
                    right: 0.09,
                    bottom: 0.04
                },
                blank_line_height: 4
            }

            // table_style: ""
        },
        memo_properties: {
            memo_props_active: false,
            heading: {
                font_id: "",
                font_size: 9,
                font_color: "003F2D",
                font_bold: false,
                font_family: ""
            },
            body: {
                font_id: "",
                font_size: 9,
                font_color: "000000",
                font_bold: false,
                font_family: ""
            }
        },
        comp_analysis: {
            table: {
                table_page_fit: false,
                table_style_id: "",
                col_width: 10,
                sub_total: {
                    font_id: "",
                    alignment: "left",
                    font_size: 12,
                    font_color: "ffffff",
                    bg_color: "4117bf",
                    cell_margin: {
                        top: 0.1,
                        left: 0.3,
                        right: 0.09,
                        bottom: 0.04
                    }
                },
                data: {
                    font_id: "",
                    alignment: "",
                    font_size: 13,
                    font_color: "",
                    cell_margin: {
                        top: 0.1,
                        left: 0.3,
                        right: 0.09,
                        bottom: 0.04
                    },
                    blank_line_height: 4
                },
                heading: {
                    font_id: "",
                    font_size: 3,
                    bg_color: "4117bf",
                    alignment: "left",
                    // font_size: 14,
                    font_color: "fff700",
                    cell_margin: {
                        top: 0.1,
                        left: 0.3,
                        right: 0.09,
                        bottom: 0.04
                    }
                },
                custom_head: {
                    font_id: "",
                    bg_color: "4117bf",
                    alignment: "center",
                    font_size: 15,
                    font_color: "fff700",
                    cell_margin: {
                        top: 0.1,
                        left: 0.3,
                        right: 0.09,
                        bottom: 0.04
                    },
                    blank_line_height: 4
                }
            },
            ca_recommendation: {
                code: {
                    label: "Code",
                    show_total: false,
                    data_type: "str",
                    selected: true,
                    width: 1
                },
                name: {
                    label: "Name",
                    show_total: false,
                    data_type: "str",
                    selected: true,
                    width: 1
                },
                description: {
                    label: "Description",
                    show_total: false,
                    data_type: "str",
                    selected: true,
                    width: 1
                },
                building_type: {
                    label: "Type",
                    show_total: false,
                    data_type: "str",
                    selected: true,
                    width: 1
                },
                place: {
                    label: "Place",
                    show_total: false,
                    data_type: "str",
                    selected: false,
                    width: 1
                },
                total_area: {
                    label: "Area",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    width: 1
                },
                recommendations_count: {
                    label: "Recommendation Count",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    width: 1
                },
                project_total: {
                    label: "Sum Project Total",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    show_dollar: true,
                    width: 1
                },
                cost: {
                    label: "Cost",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    show_dollar: true,
                    width: 1
                },
                differed_maintenance: {
                    label: "Deferred Maintenance",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    show_dollar: true,
                    width: 1
                },
                future_capital: {
                    label: "Future Capital",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    show_dollar: true,
                    width: 1
                },
                non_infrastructure: {
                    label: "Non-Infrastructure",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    show_dollar: true,
                    width: 1
                },
                dm_count: {
                    label: "Deferred Maintenance Count",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    width: 1
                },
                fc_count: {
                    label: "Future Capital Count",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    width: 1
                },
                ni_count: {
                    label: "Non-Infrastructure Count",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    width: 1
                },
                dollar_per_square_foot: {
                    label: "Dollar per square foot",
                    show_total: false,
                    data_type: "int",
                    selected: true,
                    width: 1
                },
                city: {
                    label: "City",
                    show_total: false,
                    data_type: "str",
                    selected: false,
                    width: 1
                },
                state: {
                    label: "State",
                    show_total: false,
                    data_type: "str",
                    selected: false,
                    width: 1
                },
                country: {
                    label: "Country",
                    show_total: false,
                    data_type: "str",
                    selected: false,
                    width: 1
                }
            },
            ca_asset: {
                code: {
                    label: "Code",
                    show_total: false,
                    data_type: "str",
                    selected: true,
                    width: 1
                },
                name: {
                    label: "Name",
                    show_total: false,
                    data_type: "str",
                    selected: true,
                    width: 1
                },
                description: {
                    label: "Description",
                    show_total: false,
                    data_type: "str",
                    selected: true,
                    width: 1
                },
                building_type: {
                    label: "Type",
                    show_total: false,
                    data_type: "str",
                    selected: true,
                    width: 1
                },
                place: {
                    label: "Place",
                    show_total: false,
                    data_type: "str",
                    selected: false,
                    width: 1
                },
                area: {
                    label: "Area",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    width: 1
                },
                asset_count: {
                    label: "Asset Count",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    width: 1
                },
                cost: {
                    label: "Cost",
                    show_total: false,
                    data_type: "str",
                    selected: true,
                    width: 1
                },
                total_crv: {
                    label: "Total CRV",
                    show_total: true,
                    data_type: "int",
                    selected: true,
                    show_dollar: true,
                    width: 1
                },
                asset_condition: {
                    label: "Asset Condition",
                    show_total: false,
                    data_type: "",
                    selected: true,
                    width: 1
                },
                asset_condition_count: {
                    label: "Asset Condition Count",
                    show_total: false,
                    data_type: "",
                    selected: true,
                    width: 1
                },
                city: {
                    label: "City",
                    show_total: false,
                    data_type: "str",
                    selected: false,
                    width: 1
                },
                state: {
                    label: "State",
                    show_total: false,
                    data_type: "str",
                    selected: false,
                    width: 1
                },
                country: {
                    label: "Country",
                    show_total: false,
                    data_type: "str",
                    selected: false,
                    width: 1
                }
            }
        }
    },
    recommendation_props: {
        body: {
            font_size: 9,
            font_id: "",
            font_colour: "",
            background_colour: "",
            border_colour: "",
            border_width: {
                top: 6,
                left: 6,
                right: 6,
                bottom: 6
            },
            cell_margin: {
                top: 0.04,
                left: 0.04,
                right: 0.04,
                bottom: 0.04
            }
        },
        site: {
            font_size: 12,
            font_id: "",
            font_colour: "",
            background_colour: "",
            border_colour: "",
            border_width: {
                top: 6,
                left: 6,
                right: 6,
                bottom: 6
            },
            cell_margin: {
                top: 0.04,
                left: 0.04,
                right: 0.04,
                bottom: 0.04
            }
        },
        addition: {
            font_size: 10,
            font_id: "",
            font_colour: "",
            background_colour: "",
            border_colour: "",
            border_width: {
                top: 6,
                left: 6,
                right: 6,
                bottom: 6
            },
            cell_margin: {
                top: 0.04,
                left: 0.04,
                right: 0.04,
                bottom: 0.04
            }
        },
        building: {
            font_size: 11,
            font_id: "",
            font_colour: "",
            background_colour: "",
            border_colour: "",
            border_width: {
                top: 6,
                left: 6,
                right: 6,
                bottom: 6
            },
            cell_margin: {
                top: 0.04,
                left: 0.04,
                right: 0.04,
                bottom: 0.04
            }
        },
        grand_total: {
            font_size: 13,
            font_id: "",
            font_colour: "",
            background_colour: "",
            border_colour: "",
            border_width: {
                top: 6,
                left: 6,
                right: 6,
                bottom: 6
            },
            cell_margin: {
                top: 0.04,
                left: 0.04,
                right: 0.04,
                bottom: 0.04
            }
        },
        table_style: {
            style_id: ""
        },
        table_heading: {
            font_size: 13,
            font_id: "",
            font_colour: "",
            background_colour: "",
            border_colour: "",

            border: {
                left: false,
                right: false,
                bottom: false,
                top: false
            },
            border_width: {
                top: 6,
                left: 6,
                right: 6,
                bottom: 6
            },
            cell_margin: {
                top: 0.04,
                left: 0.04,
                right: 0.04,
                bottom: 0.04
            }
        },
        custom_heading: {
            font_size: 14,
            font_id: "",
            font_colour: "",
            background_colour: "",
            cell_margin: {
                top: 0.04,
                left: 0.04,
                right: 0.04,
                bottom: 0.04
            }
        },
        year: {
            end_count: 10,
            start_count: 1
        },
        properties: [
            {
                key: "sl_no",
                width: 0.3,
                heading: "Sl No",
                is_active: true,
                is_static_field: true
            },
            {
                key: "code",
                width: 1,
                heading: "Code",
                is_active: true,
                is_static_field: true
            },
            {
                key: "recommendation",
                width: 3,
                heading: "Recommendation",
                is_active: true,
                is_static_field: true
            },

            {
                key: "trade",
                width: 1,
                heading: "Trade",
                is_active: true,
                is_static_field: true
            },

            {
                key: "category",
                width: 2,
                heading: "Category",
                is_active: true,
                is_static_field: true
            },
            {
                key: "addition",
                width: 1,
                heading: "Addition",
                is_active: true,
                is_static_field: true
            },
            {
                key: "project_total",
                width: 1.3,
                heading: "Project Total",
                is_active: true,
                is_static_field: true
            },
            {
                key: "priority",
                width: 1,
                heading: "Priority",
                is_active: false,
                is_static_field: true
            },
            {
                key: "criticality",
                width: 1,
                heading: "Criticality",
                is_active: false,
                is_static_field: true
            },
            {
                key: "funding_source",
                width: 1,
                heading: "Funding Source",
                is_active: false,
                is_static_field: true
            },
            {
                key: "capital_type",
                width: 1,
                heading: "Capital Type",
                is_active: false,
                is_static_field: true
            },

            {
                key: "responsibility",
                width: 1,
                heading: "Responsibility",
                is_active: false,
                is_static_field: true
            },
            {
                key: "years_with_value",
                width: 1,
                heading: "Year(s)",
                is_active: false,
                is_static_field: true
            }
        ]
    },
    multi_recommendation_props: {
        heading: {
            font_id: "",
            font_size: 12,
            font_color: "",
            font_family: ""
        },
        caption: {
            font_id: "",
            font_size: 12,
            font_color: "",
            font_family: ""
        },
        body: {
            font_id: "",
            font_size: 12,
            font_color: "",
            font_family: ""
        },
        notes: {
            font_id: "",
            font_size: 12,
            font_color: "",
            font_family: ""
        },
        seperator_line: {
            space_after: 0,
            size: 0,
            space_before: 0,
            style: "single",
            color: "#4b90fa"
        },
        sub_total: {
            is_sub_total: true,
            font_id: "",
            font_size: 8,
            font_colour: "",
            border_colour: "",
            background_colour: "",
            border: {
                left: false,
                right: false,
                top: false,
                bottom: false
            },
            cell_margin: {
                top: 0.04,
                left: 0.04,
                right: 0.04,
                bottom: 0.04
            }
        },
        memo_properties: {
            memo_props_active: false,
            heading: {
                font_id: "",
                font_size: 9,
                font_color: "003F2D",
                font_bold: false,
                font_family: ""
            },
            body: {
                font_id: "",
                font_size: 9,
                font_color: "000000",
                font_bold: false,
                font_family: ""
            }
        }
    },
    normal_recommendation_props: {
        table: {
            table_page_fit: false,
            data: {
                font_id: "",
                alignment: "left",
                font_size: 6,
                font_color: "435254",
                cell_margin: {
                    top: 0.1,
                    left: 0.3,
                    right: 0.09,
                    bottom: 0.04
                }
            },
            sub_total: {
                font_id: "",
                bg_color: "003f2d",
                alignment: "left",
                font_size: 6,
                font_color: "ffffff",
                cell_margin: {
                    top: 0.1,
                    left: 0.3,
                    right: 0.09,
                    bottom: 0.04
                }
            },
            heading: {
                font_id: "",
                bg_color: "003f2d",
                alignment: "left",
                font_size: 6,
                font_color: "ffffff",
                cell_margin: {
                    top: 0.1,
                    left: 0.3,
                    right: 0.09,
                    bottom: 0.04
                }
            },
            custom_head: {
                font_size: 14,
                font_id: "",
                font_color: "",
                bg_color: "",
                alignment: "left",
                cell_margin: {
                    top: 0.1,
                    left: 0.3,
                    right: 0.09,
                    bottom: 0.04
                },
                blank_line_height: 4
            },
            table_style_id: ""
        },
        year: {
            end_count: 10,
            start_count: 1
        },
        properties: [
            {
                key: "code",
                width: 1,
                heading: "Id",
                is_active: true,
                is_static_field: true
            },
            {
                key: "project_total",
                width: 1.3,
                heading: "Project Total",
                is_active: true,
                is_static_field: true
            },
            {
                key: "description",
                width: 4,
                heading: "Recommendation",
                is_active: true,
                is_static_field: true
            },
            {
                key: "budget_priority",
                width: 1,
                heading: "Budget Priority",
                is_active: true,
                is_static_field: true
            },
            {
                key: "building",
                width: 1,
                heading: "Building",
                is_active: true,
                is_static_field: true
            },
            {
                key: "building_description",
                width: 2,
                heading: "Building Description",
                is_active: true,
                is_static_field: true
            },
            {
                key: "addition_name",
                width: 1,
                heading: "Addition",
                is_active: false,
                is_static_field: true
            },
            {
                key: "department",
                width: 1,
                heading: "Department",
                is_active: false,
                is_static_field: true
            },
            {
                key: "recommendation_capital_type",
                width: 1,
                heading: "Recommendation Capital Type",
                is_active: false,
                is_static_field: true
            },
            {
                key: "category",
                width: 1,
                heading: "Category",
                is_active: false,
                is_static_field: true
            },
            {
                key: "trade",
                width: 1,
                heading: "Trade",
                is_active: false,
                is_static_field: true
            },
            {
                key: "system",
                width: 1,
                heading: "System",
                is_active: false,
                is_static_field: true
            },
            {
                key: "sub_system",
                width: 1,
                heading: "Sub System",
                is_active: false,
                is_static_field: true
            },
            {
                key: "building_type",
                width: 1,
                heading: "Building Type",
                is_active: false,
                is_static_field: true
            },
            {
                key: "recommendation_type",
                width: 1,
                heading: "Recommendation Type",
                is_active: false,
                is_static_field: true
            },
            {
                key: "project",
                width: 1,
                heading: "Project",
                is_active: false,
                is_static_field: true
            },
            {
                key: "region",
                width: 1,
                heading: "Region",
                is_active: false,
                is_static_field: true
            },
            {
                key: "site",
                width: 1,
                heading: "Site",
                is_active: false,
                is_static_field: true
            },
            {
                key: "criticality",
                width: 1,
                heading: "Criticality",
                is_active: false,
                is_static_field: true
            },
            {
                key: "priority",
                width: 1,
                heading: "Priority",
                is_active: false,
                is_static_field: true
            },
            {
                width: 1,
                is_active: false,
                heading: "Initiative",
                is_static_field: true,
                key: "initiative_name"
            },
            {
                width: 1,
                is_active: false,
                heading: "Parent Building",
                is_static_field: true,
                key: "hospital_name"
            },
            {
                key: "client",
                width: 1,
                heading: "Client",
                is_active: false,
                is_static_field: true
            },
            {
                key: "consultancy",
                width: 1,
                heading: "Consultancy",
                is_active: false,
                is_static_field: true
            },
            {
                key: "ministry",
                width: 1,
                heading: "Ministry",
                is_active: false,
                is_static_field: true
            },
            {
                key: "floor",
                width: 1,
                heading: "Floor",
                is_active: false,
                is_static_field: true
            },
            {
                key: "room",
                width: 1,
                heading: "Room",
                is_active: false,
                is_static_field: true
            },
            {
                key: "funding_source",
                width: 1,
                heading: "Funding Source",
                is_active: false,
                is_static_field: true
            },
            {
                key: "responsibility_name",
                width: 1,
                heading: "Responsibility Name",
                is_active: false,
                is_static_field: true
            },
            {
                key: "asset_name",
                width: 1,
                heading: "Asset Name",
                is_active: false,
                is_static_field: true
            },
            {
                key: "condition",
                width: 1,
                heading: "Condition",
                is_active: false,
                is_static_field: true
            },
            {
                key: "installed_year",
                width: 1,
                heading: "Installed Year",
                is_active: false,
                is_static_field: true
            },
            {
                key: "service_life",
                width: 1,
                heading: "Service Life",
                is_active: false,
                is_static_field: true
            },
            {
                key: "usefull_life_remaining",
                width: 1,
                heading: "Usefull Life Remaining",
                is_active: false,
                is_static_field: true
            },
            {
                key: "crv",
                width: 1,
                heading: "Crv",
                is_active: false,
                is_static_field: true
            },
            {
                key: "essential",
                width: 1,
                heading: "Essential",
                is_active: false,
                is_static_field: true
            },
            {
                key: "surveyor",
                width: 1,
                heading: "Surveyor",
                is_active: false,
                is_static_field: true
            },
            {
                key: "inspection_date",
                width: 1,
                heading: "Inspection Date",
                is_active: false,
                is_static_field: true
            },
            {
                key: "source",
                width: 1,
                heading: "Source",
                is_active: false,
                is_static_field: true
            },
            {
                key: "status",
                width: 1,
                heading: "Status",
                is_active: false,
                is_static_field: true
            },
            {
                key: "recommendation_narrative",
                width: 1,
                heading: "Recommendation Narrative",
                is_active: false,
                is_static_field: true
            },
            {
                key: "fmp",
                width: 1,
                heading: "Fmp",
                is_active: false,
                is_static_field: true
            },
            {
                key: "fmp_id",
                width: 1,
                heading: "Fmp Id",
                is_active: false,
                is_static_field: true
            },
            {
                key: "fmp_track",
                width: 1,
                heading: "Fmp Track",
                is_active: false,
                is_static_field: true
            },
            {
                key: "fmp_project",
                width: 1,
                heading: "Fmp Project",
                is_active: false,
                is_static_field: true
            },
            {
                key: "infrastructure_request",
                width: 1,
                heading: "Capital Request",
                is_active: false,
                is_static_field: true
            },
            {
                key: "red_line",
                width: 1,
                heading: "Red Line",
                is_active: false,
                is_static_field: true
            },
            {
                key: "energy_band_show",
                width: 1,
                heading: "Energy Band Show",
                is_active: false,
                is_static_field: true
            },
            {
                key: "water_band_show",
                width: 1,
                heading: "Water Band Show",
                is_active: false,
                is_static_field: true
            },
            {
                key: "unit",
                width: 1,
                heading: "Unit",
                is_active: false,
                is_static_field: true
            },
            {
                key: "cost_per_unit",
                width: 1,
                heading: "Cost Per Unit",
                is_active: false,
                is_static_field: true
            },
            {
                key: "quantity",
                width: 1,
                heading: "Quantity",
                is_active: false,
                is_static_field: true
            },
            {
                key: "options_cost",
                width: 1,
                heading: "Opinions of Cost",
                is_active: false,
                is_static_field: true
            },
            {
                key: "created_at",
                width: 1,
                heading: "Created At",
                is_active: false,
                is_static_field: true
            },
            {
                key: "updated_at",
                width: 1,
                heading: "Updated At",
                is_active: false,
                is_static_field: true
            },
            {
                key: "years_with_value",
                width: 1,
                heading: "Year(s)",
                is_active: false,
                is_custom_field: true
            }
        ]
    },
    bbi_bsi_props: {
        bbi_heading: {
            alignment: "left",
            bold: false,
            color: "003F2D",
            font_family: "",
            font_size: 32,
            space_after: 9,
            space_before: 0,
            font_id: ""
        },

        bbi_sub_heading: {
            alignment: "left",
            bold: false,
            color: "538184",
            font_family: "",
            font_size: 18,
            space_after: 9,
            space_before: 0,
            font_id: ""
        },

        executive_summary: {
            additional: {
                alignment: "left",
                bold: false,
                color: "435254",
                font_family: "",
                font_size: 18,
                space_after: 9,
                space_before: 0,
                font_id: ""
            },

            trade: { alignment: "left", bold: false, color: "435254", font_family: "", font_size: 18, space_after: 9, space_before: 0, font_id: "" }
        },
        memo_properties: {
            memo_props_active: false,
            heading: {
                font_id: "",
                font_size: 9,
                font_color: "003F2D",
                font_bold: false,
                font_family: ""
            },
            body: {
                font_id: "",
                font_size: 9,
                font_color: "000000",
                font_bold: false,
                font_family: ""
            },
            paragraph_space_before: 0,
            paragraph_space_after: 8,
            line_space: 1,
            alignment: "left"
        }
    }
};
class Form extends Component {
    state = {
        showErrorBorder: false,
        property: initial_state,
        colorModalState: null,
        activeDetail: "chart",
        initialValues: {},
        selectedTab: "chart",
        isYearValid: true,
        totalRowsForColumnDnd:
            initial_state?.properties?.comp_analysis?.ca_recommendation &&
            Object?.keys(initial_state?.properties?.comp_analysis?.ca_recommendation)?.length
                ? Math.trunc(Object?.keys(initial_state?.properties?.comp_analysis?.ca_recommendation)?.length / 4) +
                  (Object?.keys(initial_state?.properties?.comp_analysis?.ca_recommendation)?.length % 4 ? 1 : 0)
                : 0
    };

    componentDidMount = async () => {
        const { isSmartchartView } = this.props;
        this.props.getDropdownList("fonts");
        this.props.getDropdownList("table_styles");
        if (this.props.selectedProperty) {
            let propertyData = {};
            if (isSmartchartView) {
                await this.props.getPropertyById(this.props.selectedProperty);
                propertyData = this.props.chartPropertyReducer.getPropertyByIdResponse;
            } else {
                propertyData = await this.props.getPropertyDataById();
            }
            await this.setState({
                property: {
                    ...this.state.property,
                    id: propertyData.id,
                    name: propertyData.name,
                    client_id: propertyData?.client?.id,
                    description: propertyData.description,
                    notes: propertyData.notes,
                    // properties: propertyData.properties,
                    properties: {
                        ...propertyData.properties,
                        chart: {
                            ...propertyData.properties?.chart,
                            frame_props: {
                                ...this.state.property.properties.chart.frame_props,
                                ...propertyData.properties.chart.frame_props
                            }
                        },
                        custom_legend: {
                            show_legend: true,
                            ...propertyData.properties.custom_legend
                        },
                        total: {
                            show_total: true,
                            ...propertyData.properties.total
                        },
                        legend: {
                            ...this.state.property.properties.legend,
                            ...propertyData.properties.legend
                        },
                        table: {
                            ...this.state.property.properties.table,
                            ...propertyData.properties.table,
                            data: {
                                ...this.state.property.properties.table.data,
                                ...propertyData?.properties?.table?.data
                            },
                            sub_total: {
                                ...this.state.property.properties.table.sub_total,
                                ...propertyData?.properties?.table?.sub_total
                            },
                            heading: {
                                ...this.state.property.properties.table.heading,
                                ...propertyData?.properties?.table?.heading
                            },
                            custom_head: {
                                ...this.state.property.properties.table.custom_head,
                                ...propertyData?.properties?.table?.custom_head
                            }
                        },
                        comp_analysis: {
                            table: {
                                ...this.state.property.properties?.comp_analysis?.table,
                                ...propertyData.properties?.comp_analysis?.table,
                                data: {
                                    ...this.state.property.properties?.comp_analysis?.table?.data,
                                    ...propertyData?.properties?.comp_analysis?.table?.data
                                },
                                sub_total: {
                                    ...this.state.property.properties?.comp_analysis?.table?.sub_total,
                                    ...propertyData?.properties?.comp_analysis?.table?.sub_total
                                },
                                heading: {
                                    ...this.state.property.properties?.comp_analysis?.table?.heading,
                                    ...propertyData?.properties?.comp_analysis?.table?.heading
                                },
                                custom_head: {
                                    ...this.state.property.properties?.comp_analysis?.table?.custom_head,
                                    ...propertyData?.properties?.comp_analysis?.table?.custom_head
                                }
                            },

                            ca_recommendation:
                                propertyData?.properties?.comp_analysis?.ca_recommendation &&
                                Object.keys(propertyData?.properties?.comp_analysis?.ca_recommendation)?.length
                                    ? typeof propertyData?.properties?.comp_analysis?.ca_recommendation === "string"
                                        ? JSON.parse(propertyData?.properties?.comp_analysis?.ca_recommendation)
                                        : propertyData?.properties?.comp_analysis?.ca_recommendation
                                    : { ...this.state.property.properties?.comp_analysis?.ca_recommendation },
                            ca_asset:
                                propertyData?.properties?.comp_analysis?.ca_asset &&
                                Object.keys(propertyData?.properties?.comp_analysis?.ca_asset)?.length
                                    ? typeof propertyData?.properties?.comp_analysis?.ca_asset === "string"
                                        ? JSON.parse(propertyData?.properties?.comp_analysis?.ca_asset)
                                        : propertyData?.properties?.comp_analysis?.ca_asset
                                    : { ...this.state.property.properties?.comp_analysis?.ca_asset }
                        },
                        memo_properties: {
                            ...this.state.property.properties.memo_properties,
                            ...propertyData?.properties?.memo_properties,
                            body: {
                                ...this.state.property.properties.memo_properties?.body,
                                ...propertyData?.properties?.memo_properties?.body
                            },
                            heading: {
                                ...this.state.property.properties.memo_properties?.heading,
                                ...propertyData?.properties?.memo_properties?.heading
                            }
                        }
                    },
                    recommendation_props: {
                        ...propertyData.recommendation_props,
                        year: {
                            ...this.state.property.recommendation_props?.year,
                            ...propertyData?.recommendation_props?.year
                        },
                        body: {
                            ...this.state.property.recommendation_props?.body,
                            ...propertyData?.recommendation_props?.body
                        },
                        site: {
                            ...this.state.property.recommendation_props?.site,
                            ...propertyData?.recommendation_props?.site
                        },
                        addition: {
                            ...this.recommendation_props?.addition,
                            ...propertyData?.recommendation_props?.addition
                        },
                        building: {
                            ...this.state.property.recommendation_props?.building,
                            ...propertyData?.recommendation_props?.building
                        },
                        grand_total: {
                            ...this.state.property.recommendation_props?.grand_total,
                            ...propertyData?.recommendation_props?.grand_total
                        },
                        table_style: {
                            ...this.state.property.recommendation_props?.table_style,
                            ...propertyData?.recommendation_props?.table_style
                        },
                        table_heading: {
                            ...this.state.property.recommendation_props?.table_heading,
                            ...propertyData?.recommendation_props?.table_heading
                        },
                        custom_heading: {
                            ...this.state.property.recommendation_props?.custom_heading,
                            ...propertyData?.recommendation_props?.custom_heading
                        },
                        properties: propertyData?.recommendation_props?.properties?.length
                            ? propertyData?.recommendation_props?.properties.find(e => e?.key === "years_with_value")
                                ? propertyData?.recommendation_props?.properties
                                : [
                                      ...propertyData?.recommendation_props?.properties,
                                      {
                                          key: "years_with_value",
                                          width: 1,
                                          heading: "Year(s)",
                                          is_active: false,
                                          is_custom_field: true
                                      }
                                  ]
                            : [...this.state.property?.recommendation_props?.properties]
                    },
                    multi_recommendation_props: {
                        ...propertyData.multi_recommendation_props,
                        body: {
                            ...this.multi_recommendation_props?.body,
                            ...propertyData?.multi_recommendation_props?.body
                        },
                        heading: {
                            ...this.multi_recommendation_props?.heading,
                            ...propertyData?.multi_recommendation_props?.heading
                        },
                        caption: {
                            ...this.multi_recommendation_props?.caption,
                            ...propertyData?.multi_recommendation_props?.caption
                        },
                        notes: {
                            ...this.multi_recommendation_props?.notes,
                            ...propertyData?.multi_recommendation_props?.notes
                        },
                        sub_total: {
                            ...this.state.property.multi_recommendation_props.sub_total,
                            ...propertyData?.multi_recommendation_props?.sub_total
                        },
                        memo_properties: {
                            ...this.state.property.multi_recommendation_props.memo_properties,
                            ...propertyData?.multi_recommendation_props?.memo_properties,
                            body: {
                                ...this.state.property.multi_recommendation_props.memo_properties?.body,
                                ...propertyData?.multi_recommendation_props?.memo_properties?.body
                            },
                            heading: {
                                ...this.state.property.multi_recommendation_props.memo_properties?.heading,
                                ...propertyData?.multi_recommendation_props?.memo_properties?.heading
                            }
                        }
                    },
                    normal_recommendation_props: {
                        ...this.state.property.normal_recommendation_props,
                        table: {
                            ...this.state.property.normal_recommendation_props.table,
                            ...propertyData?.normal_recommendation_props?.table,
                            data: {
                                ...this.state.property.normal_recommendation_props.table.data,
                                ...propertyData?.normal_recommendation_props?.table?.data
                            },
                            sub_total: {
                                ...this.state.property.normal_recommendation_props.table.sub_total,
                                ...propertyData?.normal_recommendation_props?.table?.sub_total
                            },
                            heading: {
                                ...this.state.property.normal_recommendation_props.table.heading,
                                ...propertyData?.normal_recommendation_props?.table?.heading
                            },
                            custom_head: {
                                ...this.state.property.normal_recommendation_props.table.custom_head,
                                ...propertyData?.normal_recommendation_props?.table?.custom_head
                            }
                        },
                        year: {
                            ...this.state.property.normal_recommendation_props.year,
                            ...propertyData?.normal_recommendation_props?.year
                        },
                        properties: propertyData?.normal_recommendation_props?.properties?.length
                            ? propertyData?.normal_recommendation_props?.properties.find(e => e?.key === "years_with_value")
                                ? propertyData?.normal_recommendation_props?.properties
                                : [
                                      ...propertyData?.normal_recommendation_props?.properties,
                                      {
                                          key: "years_with_value",
                                          width: 1,
                                          heading: "Year(s)",
                                          is_active: false,
                                          is_custom_field: true
                                      }
                                  ]
                            : [...this.state.property?.normal_recommendation_props?.properties]
                    },
                    asset_props: {
                        ...this.state.property.asset_props,
                        table: {
                            ...this.state.property.asset_props.table,
                            ...propertyData?.asset_props?.table,
                            data: {
                                ...this.state.property.asset_props.table.data,
                                ...propertyData?.asset_props?.table?.data
                            },
                            sub_total: {
                                ...this.state.property.asset_props.table.sub_total,
                                ...propertyData?.asset_props?.table?.sub_total
                            },
                            heading: {
                                ...this.state.property.asset_props.table.heading,
                                ...propertyData?.asset_props?.table?.heading
                            },
                            custom_head: {
                                ...this.state.property.asset_props.table.custom_head,
                                ...propertyData?.asset_props?.table?.custom_head
                            }
                        },
                        properties: propertyData?.asset_props?.properties?.length
                            ? propertyData?.asset_props?.properties.find(e => e?.key === "years_with_value")
                                ? propertyData?.asset_props?.properties
                                : [...propertyData?.asset_props?.properties]
                            : [...this.state.property?.asset_props?.properties]
                    },
                    bbi_bsi_props: {
                        ...this.state.property?.bbi_bsi_props,
                        ...propertyData.bbi_bsi_props,
                        bbi_heading: {
                            ...this.state.property?.bbi_bsi_props?.bbi_heading,
                            ...propertyData?.bbi_bsi_props?.bbi_heading
                        },
                        bbi_sub_heading: {
                            ...this.state.property?.bbi_bsi_props?.bbi_sub_heading,
                            ...propertyData?.bbi_bsi_props?.bbi_sub_heading
                        },
                        executive_summary: {
                            ...this.bbi_bsi_props?.executive_summary,
                            additional: {
                                ...this.state.property?.bbi_bsi_props?.executive_summary?.additional,
                                ...propertyData?.bbi_bsi_props?.executive_summary?.additional
                            },
                            trade: {
                                ...this.state.property?.bbi_bsi_props?.executive_summary?.trade,
                                ...propertyData?.bbi_bsi_props?.executive_summary?.trade
                            }
                        },
                        memo_properties: {
                            ...this.state.property?.bbi_bsi_props?.memo_properties,
                            ...propertyData?.bbi_bsi_props?.memo_properties,
                            body: {
                                ...this.state.property?.bbi_bsi_props?.memo_properties?.body,
                                ...propertyData?.bbi_bsi_props?.memo_properties?.body
                            },
                            heading: {
                                ...this.state.property?.bbi_bsi_props?.memo_properties?.heading,
                                ...propertyData?.bbi_bsi_props?.memo_properties?.heading
                            }
                        }
                    }
                }
            });
        } else {
            const {
                location: { search }
            } = this.props;
            const query = qs.parse(search);
            await this.setState({ property: { ...this.state.property, client_id: query?.client_id || "" } });
        }
        const totalRows =
            this.state.property?.properties?.comp_analysis?.ca_recommendation &&
            Object?.keys(this.state.property?.properties?.comp_analysis?.ca_recommendation)?.length
                ? Math.trunc(Object?.keys(this.state.property?.properties?.comp_analysis?.ca_recommendation)?.length / 4) +
                  (Object?.keys(this.state.property?.properties?.comp_analysis?.ca_recommendation)?.length % 4 ? 1 : 0)
                : 0;
        this.setState({ totalRowsForColumnDnd: totalRows });

        this.setState({ initialValues: _.cloneDeep(this.state.property) });
    };

    validate = () => {
        const {
            properties: { header, heading, chart, custom_legend, total },
            name,
            description,
            normal_recommendation_props: { year }
        } = this.state.property;

        if (
            !name?.trim()?.length ||
            !description?.trim()?.length ||
            !header?.project?.font_id ||
            !header?.project?.font_size ||
            !header?.project?.color ||
            // !header?.project?.bold ||
            !header?.client?.font_id ||
            !header?.client?.font_size ||
            !header?.client?.color ||
            // !header?.client?.bold ||
            !heading?.font_id ||
            !heading?.font_size ||
            !heading?.color ||
            !heading?.alignment ||
            // !heading?.bold ||
            (custom_legend.show_legend && !custom_legend?.legend_heading?.font_id) ||
            (custom_legend.show_legend && !custom_legend?.legend_heading?.font_size) ||
            !custom_legend?.legend_heading?.color ||
            !custom_legend?.legend_heading?.alignment ||
            // !custom_legend?.legend_heading?.bold ||
            (custom_legend.show_legend && !custom_legend?.legend_value?.font_id) ||
            (custom_legend.show_legend && !custom_legend?.legend_value?.font_size) ||
            !custom_legend?.legend_value?.color ||
            !custom_legend?.legend_value?.alignment ||
            // !custom_legend?.legend_value?.bold ||
            (total.show_total && !total?.font_id) ||
            (total.show_total && !total?.font_size) ||
            !total?.color
            // ||!total?.alignment
            // !total?.bold
        ) {
            this.setState({ showErrorBorder: true, isYearValid: true });
            return false;
        }
        if (!year.end_count || !year.start_count) {
            this.setState({ showErrorBorder: true, isYearValid: true });
            return false;
        }
        if (!this.state.property?.recommendation_props?.year?.end_count || !this.state.property?.recommendation_props?.year?.start_count) {
            this.setState({ showErrorBorder: true, isYearValid: true });
            return false;
        }
        if (
            year.start_count > year.end_count ||
            this.state.property.recommendation_props.year.start_count > this.state.property.recommendation_props.year.end_count
        ) {
            this.setState({ showErrorBorder: true, isYearValid: false });
            return false;
        }
        return true;
    };

    handleSubmit = () => {
        if (this.validate()) {
            let updatedProperty = { ...this.state.property };
            if (updatedProperty?.properties?.comp_analysis?.ca_recommendation) {
                updatedProperty.properties.comp_analysis.ca_recommendation = JSON.stringify(
                    updatedProperty.properties.comp_analysis.ca_recommendation
                );
            }
            if (updatedProperty?.properties?.comp_analysis?.ca_asset) {
                updatedProperty.properties.comp_analysis.ca_asset = JSON.stringify(updatedProperty.properties.comp_analysis.ca_asset);
            }
            if (this.props.selectedProperty) {
                this.props.handleUpdateProperty(updatedProperty);
            } else {
                this.props.handleAddProperty(updatedProperty);
            }
        }
    };

    cancelForm = () => {
        if (_.isEqual(this.state.initialValues, this.state.property)) {
            this.clearForm();
        } else {
            this.setState({
                showConfirmModal: true
            });
        }
    };

    clearForm = async () => {
        this.setState({
            property: initial_state,
            initialValues: initial_state
        });
        this.props.cancelForm();
    };

    renderConfirmationModal = () => {
        const { showConfirmModal } = this.state;
        if (!showConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={"Do you want to clear and lose all changes?"}
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => this.setState({ showConfirmModal: false })}
                        onYes={this.clearForm}
                    />
                }
                onCancel={() => this.setState({ showConfirmModal: false })}
            />
        );
    };

    handleChangeBasicDetails = e => {
        const { name, value } = e.target;
        const { property } = this.state;
        this.setState({
            property: { ...property, [name]: value }
        });
    };
    handleChangeHeader = (prop, key, value) => {
        const { property } = this.state;
        const { properties } = property;
        const { header } = properties;
        this.setState({
            property: { ...property, properties: { ...properties, header: { ...header, [prop]: { ...header[prop], [key]: value } } } }
        });
    };

    handleChangeCustomLegend = (prop, key, value) => {
        const { property } = this.state;
        const { properties } = property;
        const { custom_legend } = properties;
        this.setState({
            property: {
                ...property,
                properties: { ...properties, custom_legend: { ...custom_legend, [prop]: { ...custom_legend[prop], [key]: value } } }
            }
        });
    };
    handleChangeChart = (masterKey, key, value) => {
        const { property } = this.state;
        const { properties } = property;
        this.setState({
            property: { ...property, properties: { ...properties, [masterKey]: { ...properties[masterKey], [key]: value } } }
        });
    };
    handleBbiChange = (masterKey, key, value, subKey) => {
        const { property } = this.state;
        const { bbi_bsi_props } = property;
        if (subKey) {
            this.setState({
                property: {
                    ...property,
                    bbi_bsi_props: {
                        ...bbi_bsi_props,
                        [masterKey]: {
                            ...bbi_bsi_props?.[masterKey],
                            [subKey]: {
                                ...bbi_bsi_props?.[masterKey]?.[subKey],
                                [key]: value
                            }
                        }
                    }
                }
            });
        } else {
            this.setState({
                property: { ...property, bbi_bsi_props: { ...bbi_bsi_props, [masterKey]: { ...bbi_bsi_props?.[masterKey], [key]: value } } }
            });
        }
    };

    setActiveTab = activeTab => {
        const { isSmartchartView } = this.props;
        if (isSmartchartView) {
            this.setState({ selectedTab: activeTab });
        } else {
            let tabKeyList = ["CA", "sortedRecommendations", "multiRecommendation", "normalRecommendation", "asset", "bbi"];
            if (activeTab !== "chart") {
                this.props.history.push(`/chartProperties/edit/${this.props.match.params.id}/${tabKeyList.includes(activeTab) ? activeTab : null}`);
            } else {
                this.props.history.push(`/chartProperties/edit/${this.props.match.params.id}`);
            }
        }
    };

    setNormalRecomProperty = (parentKey = null, key, value, isAsset, customKey) => {
        if (customKey) {
            if (isAsset) {
                this.setState({
                    property: {
                        ...this.state.property,
                        asset_props: {
                            ...this.state.property.asset_props,
                            table: {
                                ...this.state.property.asset_props.table,
                                [parentKey]: {
                                    ...this.state.property.asset_props?.table?.[parentKey],
                                    [customKey]: {
                                        ...this.state.property.asset_props?.table?.[parentKey]?.[customKey],
                                        [key]: value
                                    }
                                }
                            }
                        }
                    }
                });
            } else {
                this.setState({
                    property: {
                        ...this.state.property,
                        normal_recommendation_props: {
                            ...this.state.property.normal_recommendation_props,
                            table: {
                                ...this.state.property.normal_recommendation_props.table,
                                [parentKey]: {
                                    ...this.state.property.normal_recommendation_props?.table?.[parentKey],
                                    [customKey]: {
                                        ...this.state.property.normal_recommendation_props?.table?.[parentKey]?.[customKey],
                                        [key]: value
                                    }
                                }
                            }
                        }
                    }
                });
            }
        } else {
            if (!parentKey) {
                if (isAsset) {
                    this.setState({
                        property: {
                            ...this.state.property,
                            asset_props: {
                                ...this.state.property.asset_props,
                                table: {
                                    ...this.state.property.asset_props?.table,
                                    [key]: value
                                }
                            }
                        }
                    });
                } else {
                    this.setState({
                        property: {
                            ...this.state.property,
                            normal_recommendation_props: {
                                ...this.state.property.normal_recommendation_props,
                                table: {
                                    ...this.state.property.normal_recommendation_props?.table,
                                    [key]: value
                                }
                            }
                        }
                    });
                }
                return;
            } else if (parentKey === "yearsValue") {
                this.setState({
                    property: {
                        ...this.state.property,
                        normal_recommendation_props: {
                            ...this.state.property.normal_recommendation_props,
                            year: {
                                ...this.state.property.normal_recommendation_props?.year,

                                [key]: Number(value) || ""
                            }
                        }
                    }
                });
                return;
            }
            if (isAsset) {
                this.setState({
                    property: {
                        ...this.state.property,
                        asset_props: {
                            ...this.state.property.asset_props,
                            table: {
                                ...this.state.property.asset_props?.table,
                                [parentKey]: {
                                    ...this.state.property.asset_props?.table?.[parentKey],
                                    [key]: value
                                }
                            }
                        }
                    }
                });
            } else {
                this.setState({
                    property: {
                        ...this.state.property,
                        normal_recommendation_props: {
                            ...this.state.property.normal_recommendation_props,
                            table: {
                                ...this.state.property.normal_recommendation_props?.table,
                                [parentKey]: {
                                    ...this.state.property.normal_recommendation_props?.table?.[parentKey],
                                    [key]: value
                                }
                            }
                        }
                    }
                });
            }
        }
    };
    setSortedRecommendationProperty = (parentValue = null, key, value, customKey, parentCostomKey = null) => {
        if (customKey) {
            if (!parentCostomKey) {
                this.setState({
                    property: {
                        ...this.state.property,
                        recommendation_props: {
                            ...this.state.property.recommendation_props,
                            [parentValue]: {
                                ...this.state.property.recommendation_props?.[parentValue],
                                [customKey]: {
                                    ...this.state.property.recommendation_props?.[parentValue]?.[customKey],
                                    [key]: value
                                }
                            }
                        }
                    }
                });
            } else {
                this.setState({
                    property: {
                        ...this.state.property,
                        recommendation_props: {
                            ...this.state.property.recommendation_props,
                            [parentValue]: {
                                ...this.state.property.recommendation_props?.[parentValue],
                                [customKey]: {
                                    ...this.state.property.recommendation_props?.[parentValue]?.[customKey],
                                    [key]: value
                                },
                                [parentCostomKey]: {
                                    ...this.state.property.recommendation_props?.[parentValue]?.[customKey],
                                    [key]: 6
                                }
                            }
                        }
                    }
                });
            }
        } else {
            if (!parentValue) {
                this.setState({
                    property: {
                        ...this.state.property,
                        recommendation_props: {
                            ...this.state.property.recommendation_props,

                            [key]: value
                        }
                    }
                });
                return;
            } else if (parentValue === "yearsValue") {
                this.setState({
                    property: {
                        ...this.state.property,
                        recommendation_props: {
                            ...this.state.property.recommendation_props,
                            year: {
                                ...this.state.property.recommendation_props?.year,
                                [key]: Number(value) || ""
                            }
                        }
                    }
                });
                return;
            }
            this.setState({
                property: {
                    ...this.state.property,
                    recommendation_props: {
                        ...this.state.property.recommendation_props,
                        [parentValue]: {
                            ...this.state.property.recommendation_props?.[parentValue],
                            [key]: value
                        }
                    }
                }
            });
        }
    };
    setSortedRecommendationTableColumnConfig = (columnKey, key, value) => {
        this.setState({
            property: {
                ...this.state.property,
                recommendation_props: {
                    ...this.state.property.recommendation_props,
                    properties: this.getUpdatedColumnCofigValues(this.state.property.recommendation_props?.properties, columnKey, key, value)
                }
            }
        });
    };

    setNormalRecommendationTableColumnConfig = (columnKey, key, value, isAsset) => {
        if (isAsset) {
            this.setState({
                property: {
                    ...this.state.property,
                    asset_props: {
                        ...this.state.property.asset_props,
                        properties: this.getUpdatedColumnCofigValues(this.state.property.asset_props?.properties, columnKey, key, value)
                    }
                }
            });
        } else {
            this.setState({
                property: {
                    ...this.state.property,
                    normal_recommendation_props: {
                        ...this.state.property.normal_recommendation_props,
                        properties: this.getUpdatedColumnCofigValues(
                            this.state.property.normal_recommendation_props?.properties,
                            columnKey,
                            key,
                            value
                        )
                    }
                }
            });
        }
    };
    setCaColumnConfig = (columnKey, key, value, tableName) => {
        const updatedProperties = {
            ...this.state.property?.properties?.comp_analysis[tableName],

            [columnKey]: {
                ...this.state.property?.properties?.comp_analysis?.[tableName]?.[columnKey],
                [key]: value
            }
        };
        this.setState(prevState => ({
            property: {
                ...prevState.property,
                properties: {
                    ...prevState.property?.properties,
                    comp_analysis: {
                        ...prevState.property?.properties?.comp_analysis,
                        [tableName]: updatedProperties
                    }
                }
            }
        }));
    };
    getUpdatedColumnCofigValues = (configData = [], columnKey, key, value) => {
        let updatedValues = [];
        if (configData.length) {
            updatedValues = configData.map(data => {
                if (data?.key === columnKey) {
                    data = {
                        ...data,
                        [key]: value
                    };
                }
                return data;
            });
        }
        return updatedValues || [];
    };

    onRecommendationColumnOrderChange = (updatedColumns, isAsset) => {
        if (isAsset) {
            this.setState({
                property: {
                    ...this.state.property,
                    asset_props: {
                        ...this.state.property.asset_props,
                        properties: [...updatedColumns]
                    }
                }
            });
        } else {
            this.setState({
                property: {
                    ...this.state.property,
                    normal_recommendation_props: {
                        ...this.state.property.normal_recommendation_props,
                        properties: [...updatedColumns]
                    }
                }
            });
        }
    };

    onSortedRecommendationColumnOrderChange = updatedColumns => {
        this.setState({
            property: {
                ...this.state.property,
                recommendation_props: {
                    ...this.state.property.recommendation_props,
                    properties: [...updatedColumns]
                }
            }
        });
    };
    onColumnDnd = additionalString => result => {
        if (!result.destination) {
            return;
        }
        const arrayData = Object.keys(this.state.property?.properties?.comp_analysis?.[additionalString]).map(key => ({
            [key]: this.state.property?.properties?.comp_analysis?.[additionalString][key]
        }));

        const keyList = reorderArray(arrayData, result.source.index, result.destination.index);
        const objectData = keyList.reduce((acc, obj) => {
            const [key, value] = Object.entries(obj)[0];
            return { ...acc, [key]: value };
        }, {});

        this.setState({
            property: {
                ...this.state.property,
                properties: {
                    ...this.state.property?.properties,
                    comp_analysis: {
                        ...this.state.property.properties?.comp_analysis,
                        [additionalString]: objectData
                    }
                }
            }
        });
    };

    render() {
        const { property, showErrorBorder, colorModalState, selectedTab } = this.state;
        const {
            name,
            description,
            notes,
            properties,
            recommendation_props,
            multi_recommendation_props,
            normal_recommendation_props,
            asset_props,
            bbi_bsi_props
        } = property;
        const {
            header,
            heading,
            chart,
            legend,
            custom_legend,
            total,
            x_axis,
            y_axis,
            data_labels,
            chart_font,
            table,
            comp_analysis,
            memo_properties
        } = properties;
        const {
            dropDownList: { fonts: FONT_FAMILY, table_styles: TABLE_STYLE }
        } = this.props.reportPropertyReducer;
        const { custom_heading, addition, site, body, building, grand_total, table_heading, table_style } = recommendation_props;
        const { isSmartchartView } = this.props;
        const styles = reactCSS({
            default: {
                color: {
                    width: "40px",
                    height: "15px",
                    borderRadius: "3px",
                    zoom: "107%"
                },
                popover: {
                    position: "absolute",
                    zIndex: "3",
                    left: "10px",
                    top: "-310px"
                },
                cover: {
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px"
                },
                swatch: {
                    padding: "6px",
                    background: "#ffffff",
                    borderRadius: "2px",
                    cursor: "pointer",
                    display: "inline-block",
                    boxShadow: "0 0 0 1px rgba(0,0,0,.2)"
                }
            }
        });
        return (
            <>
                <div className="dtl-sec col-md-12 system-building">
                    <div className="custom-col">
                        <div className="tab-dtl region-mng">
                            <ul>
                                {/* <div className="recom-notify-img">
                                <img src={exclmIcon} alt="" />
                            </div> */}
                                <li
                                    className={
                                        isSmartchartView
                                            ? selectedTab === "chart"
                                                ? " cursor-pointer active pl-4"
                                                : "cursor-pointer"
                                            : !this.props.match.params.tab
                                            ? " cursor-pointer active pl-4"
                                            : "cursor-pointer"
                                    }
                                    onClick={() => this.setActiveTab("chart")}
                                >
                                    {isSmartchartView ? "Chart, Chart Table" : "Chart"}
                                </li>
                                <li
                                    className={
                                        this.props.match.params.tab === "CA" || (isSmartchartView && selectedTab === "CA")
                                            ? " cursor-pointer active pl-4"
                                            : "cursor-pointer"
                                    }
                                    onClick={() => this.setActiveTab("CA")}
                                >
                                    Comparative Analysis
                                </li>
                                {/* {!isSmartchartView && ( */}
                                <>
                                    <li
                                        className={
                                            this.props.match.params.tab === "sortedRecommendations" ||
                                            (isSmartchartView && selectedTab === "sortedRecommendations")
                                                ? " cursor-pointer active pl-4"
                                                : "cursor-pointer"
                                        }
                                        onClick={() => this.setActiveTab("sortedRecommendations")}
                                    >
                                        Sorted Recommendations
                                    </li>
                                    <li
                                        className={
                                            this.props.match.params.tab === "multiRecommendation" ||
                                            (isSmartchartView && selectedTab === "multiRecommendation")
                                                ? " cursor-pointer active pl-4"
                                                : "cursor-pointer"
                                        }
                                        onClick={() => this.setActiveTab("multiRecommendation")}
                                    >
                                        Recommendation Summary
                                    </li>
                                    <li
                                        className={
                                            this.props.match.params.tab === "normalRecommendation" ||
                                            (isSmartchartView && selectedTab === "normalRecommendation")
                                                ? " cursor-pointer active pl-4"
                                                : "cursor-pointer"
                                        }
                                        onClick={() => this.setActiveTab("normalRecommendation")}
                                    >
                                        Recommendation Table
                                    </li>
                                    <li
                                        className={
                                            this.props.match.params.tab === "asset" || (isSmartchartView && selectedTab === "asset")
                                                ? " cursor-pointer active pl-4"
                                                : "cursor-pointer"
                                        }
                                        onClick={() => this.setActiveTab("asset")}
                                    >
                                        Asset Table
                                    </li>
                                    <li
                                        className={
                                            this.props.match.params.tab === "bbi" || (isSmartchartView && selectedTab === "bbi")
                                                ? " cursor-pointer active pl-4"
                                                : "cursor-pointer"
                                        }
                                        onClick={() => this.setActiveTab("bbi")}
                                    >
                                        BBI/BSI
                                    </li>
                                    {/* <li
                                    className={activeDetail === "ChartDataTable" ? " cursor-pointer active pl-4" : "cursor-pointer"}
                                    onClick={() => this.setActiveTab("ChartDataTable")}
                                >
                                    Image Gallery
                                </li> */}
                                </>
                                {/* )} */}
                            </ul>

                            <div class="otr-common-edit custom-col new-area">
                                <div className="row m-0 back-show p-2">
                                    <div className="col-md-12 p-0">
                                        <div className="basic-otr">
                                            <div className="basic-dtl-otr m-0 basic-sec p-0 w-100 row">
                                                <div className="col-md-4 basic-box">
                                                    <div className="codeOtr">
                                                        <h4>Property Name *</h4>
                                                        <input
                                                            autoComplete={"off"}
                                                            autoFill={"off"}
                                                            type="text"
                                                            className={`${
                                                                showErrorBorder && !name?.trim()?.length ? "error-border " : ""
                                                            }custom-input form-control ${isSmartchartView ? "cursor-diabled" : ""}`}
                                                            name="name"
                                                            onChange={e => this.handleChangeBasicDetails(e)}
                                                            value={name}
                                                            placeholder="Enter Property Name"
                                                            disabled={isSmartchartView}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 basic-box">
                                                    <div className="codeOtr">
                                                        <h4>Description *</h4>
                                                        <input
                                                            autoComplete={"off"}
                                                            autoFill={"off"}
                                                            type="text"
                                                            className={`${
                                                                showErrorBorder && !description?.trim()?.length ? "error-border " : ""
                                                            }custom-input form-control ${isSmartchartView ? "cursor-diabled" : ""}`}
                                                            name="description"
                                                            onChange={e => this.handleChangeBasicDetails(e)}
                                                            value={description}
                                                            placeholder="Enter Description"
                                                            disabled={isSmartchartView}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 basic-box">
                                                    <div className="codeOtr">
                                                        <h4>Notes</h4>
                                                        <input
                                                            autoComplete={"off"}
                                                            autoFill={"off"}
                                                            type="text"
                                                            className={`custom-input form-control ${isSmartchartView ? "cursor-diabled" : ""}`}
                                                            name="notes"
                                                            onChange={e => this.handleChangeBasicDetails(e)}
                                                            value={notes}
                                                            placeholder="Enter Notes"
                                                            disabled={isSmartchartView}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="outer-font-section">
                            <div className="style-outer">
                                {/* sorted */}
                                {this.props.match.params.tab === "sortedRecommendations" ||
                                (isSmartchartView && selectedTab === "sortedRecommendations") ? (
                                    // <SortedRecommendation handleSetRecommendationProps={this.handleSetRecommendationProps}/>
                                    <SortedRecommendationForm
                                        fontFamilyData={FONT_FAMILY}
                                        fontColor={FONT_COLOR}
                                        setSortedRecommendationProperty={this.setSortedRecommendationProperty}
                                        sortedRecomProperty={recommendation_props}
                                        setSortedRecommendationTableColumnConfig={this.setSortedRecommendationTableColumnConfig}
                                        onSortedRecommendationColumnOrderChange={this.onSortedRecommendationColumnOrderChange}
                                        showErrorBorder={showErrorBorder}
                                        TABLE_STYLE={TABLE_STYLE}
                                    />
                                ) : this.props.match.params.tab === "bbi" || (isSmartchartView && selectedTab === "bbi") ? (
                                    <BbiForm
                                        bbiProperty={bbi_bsi_props}
                                        handleBbiChange={this?.handleBbiChange}
                                        FONT_FAMILY={FONT_FAMILY}
                                        styles={styles}
                                    />
                                ) : this.props.match.params.tab === "multiRecommendation" ||
                                  (isSmartchartView && selectedTab === "multiRecommendation") ? (
                                    <>
                                        <div className="styles-box">
                                            <h2>Multi Recommendation Properties</h2>

                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Heading Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${
                                                                //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                                                //         ? "error-border"
                                                                //         : ""
                                                                // }`}
                                                                className="dropdown"
                                                                value={multi_recommendation_props?.heading?.font_id || "-"}
                                                                // // name="table_style"
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                heading: {
                                                                                    ...this.state.property.multi_recommendation_props.heading,
                                                                                    font_id: e.target.value
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <option value="">Select</option>
                                                                {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="font-size font-st col-md-3">
                                                        <label>Heading Font Size</label>

                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={multi_recommendation_props?.heading?.font_size || "-"}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                heading: {
                                                                                    ...this.state.property.multi_recommendation_props.heading,

                                                                                    font_size: values.floatValue
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <div className="head_sub">
                                                            <label>Heading Font Color </label>
                                                        </div>

                                                        <div
                                                            // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                            className="dropdown"
                                                        >
                                                            <span
                                                                style={{ cursor: "pointer" }}
                                                                className="arrow_close"
                                                                onClick={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                heading: {
                                                                                    ...this.state.property.multi_recommendation_props.heading,
                                                                                    font_color: ""
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <i class="fas fa-times"></i>
                                                            </span>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "multi_recommendation_heading_font_color"
                                                                                ? null
                                                                                : "multi_recommendation_heading_font_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{multi_recommendation_props?.heading?.font_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: multi_recommendation_props?.heading?.font_color
                                                                            ? `#${multi_recommendation_props?.heading?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                            </div>
                                                            {colorModalState === "multi_recommendation_heading_font_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={multi_recommendation_props?.heading?.font_color}
                                                                        onChangeComplete={color =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        heading: {
                                                                                            ...this.state.property.multi_recommendation_props.heading,

                                                                                            font_color: color.hex?.replace("#", "")
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Body Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${
                                                                //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                                                //         ? "error-border"
                                                                //         : ""
                                                                // }`}
                                                                className="dropdown"
                                                                value={multi_recommendation_props?.body?.font_id || "-"}
                                                                // // name="table_style"
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                body: {
                                                                                    ...this.state.property.multi_recommendation_props.body,
                                                                                    font_id: e.target.value
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <option value="">Select</option>
                                                                {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="font-size font-st col-md-3">
                                                        <label>Body Font Size</label>

                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={multi_recommendation_props?.body?.font_size || "-"}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                body: {
                                                                                    ...this.state.property.multi_recommendation_props.body,

                                                                                    font_size: values.floatValue
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <div className="head_sub">
                                                            <label>Body Font Color </label>
                                                        </div>
                                                        <div
                                                            // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                            className="dropdown"
                                                        >
                                                            <span
                                                                className="arrow_close"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                body: {
                                                                                    ...this.state.property.multi_recommendation_props.body,
                                                                                    font_color: e.target.value
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <i class="fas fa-times"></i>
                                                            </span>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "multi_recommendation_body_font_color"
                                                                                ? null
                                                                                : "multi_recommendation_body_font_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{multi_recommendation_props?.body?.font_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: multi_recommendation_props?.body?.font_color
                                                                            ? `#${multi_recommendation_props?.body?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                            </div>
                                                            {colorModalState === "multi_recommendation_body_font_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={multi_recommendation_props?.body?.font_color}
                                                                        onChangeComplete={color =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        body: {
                                                                                            ...this.state.property.multi_recommendation_props.body,

                                                                                            font_color: color.hex?.replace("#", "")
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Caption Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${
                                                                //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                                                //         ? "error-border"
                                                                //         : ""
                                                                // }`}
                                                                className="dropdown"
                                                                value={multi_recommendation_props?.caption?.font_id || "-"}
                                                                // // name="table_style"
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                caption: {
                                                                                    ...this.state.property.multi_recommendation_props.caption,
                                                                                    font_id: e.target.value
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <option value="">Select</option>
                                                                {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="font-size font-st col-md-3">
                                                        <label>Caption Font Size</label>

                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={multi_recommendation_props?.caption?.font_size || "-"}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                caption: {
                                                                                    ...this.state.property.multi_recommendation_props.caption,

                                                                                    font_size: values.floatValue
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <div className="head_sub">
                                                            <label>Caption Font Color </label>
                                                        </div>
                                                        <div
                                                            // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                            className="dropdown"
                                                        >
                                                            <span
                                                                className="arrow_close"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                caption: {
                                                                                    ...this.state.property.multi_recommendation_props.caption,
                                                                                    font_color: ""
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <i class="fas fa-times"></i>
                                                            </span>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "multi_recommendation_caption_font_color"
                                                                                ? null
                                                                                : "multi_recommendation_caption_font_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{multi_recommendation_props?.caption?.font_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: multi_recommendation_props?.caption?.font_color
                                                                            ? `#${multi_recommendation_props?.caption?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                            </div>
                                                            {colorModalState === "multi_recommendation_caption_font_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={multi_recommendation_props?.caption?.font_color}
                                                                        onChangeComplete={color =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        caption: {
                                                                                            ...this.state.property.multi_recommendation_props.caption,

                                                                                            font_color: color.hex?.replace("#", "")
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Notes Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${
                                                                //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                                                //         ? "error-border"
                                                                //         : ""
                                                                // }`}
                                                                className="dropdown"
                                                                value={multi_recommendation_props?.notes?.font_id || "-"}
                                                                // // name="table_style"
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                notes: {
                                                                                    ...this.state.property.multi_recommendation_props.notes,
                                                                                    font_id: e.target.value
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <option value="">Select</option>
                                                                {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="font-size font-st col-md-3">
                                                        <label>Notes Font Size</label>

                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={multi_recommendation_props?.notes?.font_size || "-"}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                notes: {
                                                                                    ...this.state.property.multi_recommendation_props.notes,

                                                                                    font_size: values.floatValue
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <div className="head_sub">
                                                            <label>Notes Font Color </label>
                                                        </div>
                                                        <div
                                                            // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                            className="dropdown"
                                                        >
                                                            <span
                                                                className="arrow_close"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                notes: {
                                                                                    ...this.state.property.multi_recommendation_props.notes,
                                                                                    font_color: ""
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <i class="fas fa-times"></i>
                                                            </span>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "multi_recommendation_notes_font_color"
                                                                                ? null
                                                                                : "multi_recommendation_notes_font_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{multi_recommendation_props?.notes?.font_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: multi_recommendation_props?.notes?.font_color
                                                                            ? `#${multi_recommendation_props?.notes?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                            </div>
                                                            {colorModalState === "multi_recommendation_notes_font_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={multi_recommendation_props?.notes?.font_color}
                                                                        onChangeComplete={color =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        notes: {
                                                                                            ...this.state.property.multi_recommendation_props.notes,

                                                                                            font_color: color.hex?.replace("#", "")
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Sub Total Visible</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                name="frame"
                                                                value={multi_recommendation_props?.sub_total?.is_sub_total}
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                sub_total: {
                                                                                    ...this.state.property.multi_recommendation_props.sub_total,

                                                                                    is_sub_total: e.target.value == "true"
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {multi_recommendation_props?.sub_total?.is_sub_total && (
                                                        <>
                                                            <div className="font-family font-st col-md-3">
                                                                <label>Sub Total Font Name </label>
                                                                <div className="font-select">
                                                                    <select
                                                                        className="dropdown"
                                                                        value={multi_recommendation_props?.sub_total?.font_id || "-"}
                                                                        onChange={e =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        sub_total: {
                                                                                            ...this.state.property.multi_recommendation_props
                                                                                                .sub_total,

                                                                                            font_id: e.target.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    >
                                                                        <option value="">Select</option>

                                                                        {FONT_FAMILY.map(f => (
                                                                            <option key={f.id} value={f.id}>
                                                                                {f.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="font-size font-st col-md-3">
                                                                <label>Sub Total Font Size</label>

                                                                <div className={`input-ft-size`}>
                                                                    <NumberFormat
                                                                        value={multi_recommendation_props?.sub_total?.font_size}
                                                                        onValueChange={values =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        sub_total: {
                                                                                            ...this.state.property.multi_recommendation_props
                                                                                                .sub_total,

                                                                                            font_size: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                        allowNegative={false}
                                                                        suffix=" Pt"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="font-color font-st col-md-3">
                                                                <div className="head_sub">
                                                                    <label>Sub Total Font Color </label>
                                                                </div>

                                                                <div className="dropdown">
                                                                    <span
                                                                        className="arrow_close"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={e =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        sub_total: {
                                                                                            ...this.state.property.multi_recommendation_props
                                                                                                .sub_total,

                                                                                            font_colour: ""
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    >
                                                                        <i class="fas fa-times"></i>
                                                                    </span>
                                                                    <div
                                                                        className="dropdown-toggle"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState:
                                                                                    colorModalState === "multi_recom_sub_total_font_color"
                                                                                        ? null
                                                                                        : "multi_recom_sub_total_font_color"
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span>{multi_recommendation_props?.sub_total?.font_colour || "Select"}</span>
                                                                        <span
                                                                            className="color-bx"
                                                                            style={{
                                                                                background: multi_recommendation_props?.sub_total?.font_colour
                                                                                    ? `#${multi_recommendation_props?.sub_total?.font_colour}`
                                                                                    : "#fff"
                                                                            }}
                                                                        ></span>
                                                                        <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                                    </div>
                                                                    {colorModalState === "multi_recom_sub_total_font_color" && (
                                                                        <div className="z-index-10" style={{ position: "absolute" }}>
                                                                            <CompactPicker
                                                                                className="picker"
                                                                                colors={FONT_COLOR}
                                                                                color={multi_recommendation_props?.sub_total?.font_colour}
                                                                                onChangeComplete={color =>
                                                                                    this.setState({
                                                                                        property: {
                                                                                            ...this.state.property,
                                                                                            multi_recommendation_props: {
                                                                                                ...this.state.property.multi_recommendation_props,
                                                                                                sub_total: {
                                                                                                    ...this.state.property.multi_recommendation_props
                                                                                                        .sub_total,

                                                                                                    font_colour: color.hex?.replace("#", "")
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="font-color font-st col-md-3">
                                                                <div className="head_sub">
                                                                    <label>Sub Total Background Color </label>
                                                                </div>

                                                                <div className="dropdown">
                                                                    <span
                                                                        className="arrow_close"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={e =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        sub_total: {
                                                                                            ...this.state.property.multi_recommendation_props
                                                                                                .sub_total,

                                                                                            background_colour: ""
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    >
                                                                        <i class="fas fa-times"></i>
                                                                    </span>
                                                                    <div
                                                                        className="dropdown-toggle"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState:
                                                                                    colorModalState === "multi_recom_sub_total_BG_color"
                                                                                        ? null
                                                                                        : "multi_recom_sub_total_BG_color"
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            {multi_recommendation_props?.sub_total?.background_colour || "Select"}
                                                                        </span>
                                                                        <span
                                                                            className="color-bx"
                                                                            style={{
                                                                                background: multi_recommendation_props?.sub_total?.background_colour
                                                                                    ? `#${multi_recommendation_props?.sub_total?.background_colour}`
                                                                                    : "#fff"
                                                                            }}
                                                                        ></span>
                                                                        <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                                    </div>
                                                                    {colorModalState === "multi_recom_sub_total_BG_color" && (
                                                                        <div className="z-index-10" style={{ position: "absolute" }}>
                                                                            <CompactPicker
                                                                                className="picker"
                                                                                colors={FONT_COLOR}
                                                                                color={multi_recommendation_props?.sub_total?.background_colour}
                                                                                onChangeComplete={color =>
                                                                                    this.setState({
                                                                                        property: {
                                                                                            ...this.state.property,
                                                                                            multi_recommendation_props: {
                                                                                                ...this.state.property.multi_recommendation_props,
                                                                                                sub_total: {
                                                                                                    ...this.state.property.multi_recommendation_props
                                                                                                        .sub_total,

                                                                                                    background_colour: color.hex?.replace("#", "")
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="font-color font-st col-md-3">
                                                                <div className="head_sub">
                                                                    <label>Sub Total Border Color </label>
                                                                </div>

                                                                <div className="dropdown">
                                                                    <span
                                                                        className="arrow_close"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={e =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        sub_total: {
                                                                                            ...this.state.property.multi_recommendation_props
                                                                                                .sub_total,

                                                                                            border_colour: ""
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    >
                                                                        <i class="fas fa-times"></i>
                                                                    </span>
                                                                    <div
                                                                        className="dropdown-toggle"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState:
                                                                                    colorModalState === "mulit_recom_sub_total_border_colour"
                                                                                        ? null
                                                                                        : "mulit_recom_sub_total_border_colour"
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            {multi_recommendation_props?.sub_total?.border_colour || "Select"}
                                                                        </span>
                                                                        <span
                                                                            className="color-bx"
                                                                            style={{
                                                                                background: multi_recommendation_props?.sub_total?.border_colour
                                                                                    ? `#${multi_recommendation_props?.sub_total?.border_colour}`
                                                                                    : "#fff"
                                                                            }}
                                                                        ></span>
                                                                        <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                                    </div>
                                                                    {colorModalState === "mulit_recom_sub_total_border_colour" && (
                                                                        <div className="z-index-10" style={{ position: "absolute" }}>
                                                                            <CompactPicker
                                                                                className="picker"
                                                                                colors={FONT_COLOR}
                                                                                color={multi_recommendation_props?.sub_total?.border_colour}
                                                                                onChangeComplete={color =>
                                                                                    this.setState({
                                                                                        property: {
                                                                                            ...this.state.property,
                                                                                            multi_recommendation_props: {
                                                                                                ...this.state.property.multi_recommendation_props,
                                                                                                sub_total: {
                                                                                                    ...this.state.property.multi_recommendation_props
                                                                                                        .sub_total,

                                                                                                    border_colour: color.hex?.replace("#", "")
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="font-size font-st col-md-3">
                                                                <label>Sub Total Border</label>
                                                                <div className={`input-ft-size`}>
                                                                    <span className="check-align">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={multi_recommendation_props?.sub_total?.border?.left}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        multi_recommendation_props: {
                                                                                            ...this.state.property.multi_recommendation_props,
                                                                                            sub_total: {
                                                                                                ...this.state.property.multi_recommendation_props
                                                                                                    .sub_total,
                                                                                                border: {
                                                                                                    ...this.state.property.multi_recommendation_props
                                                                                                        .sub_total.border,
                                                                                                    left: e.target.checked
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        ></input>
                                                                        <label>Left</label>
                                                                    </span>
                                                                    <span className="check-align">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={multi_recommendation_props?.sub_total?.border?.right}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        multi_recommendation_props: {
                                                                                            ...this.state.property.multi_recommendation_props,
                                                                                            sub_total: {
                                                                                                ...this.state.property.multi_recommendation_props
                                                                                                    .sub_total,
                                                                                                border: {
                                                                                                    ...this.state.property.multi_recommendation_props
                                                                                                        .sub_total.border,
                                                                                                    right: e.target.checked
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        ></input>
                                                                        <label>Right</label>
                                                                    </span>
                                                                    <span className="check-align">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={multi_recommendation_props?.sub_total?.border?.top}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        multi_recommendation_props: {
                                                                                            ...this.state.property.multi_recommendation_props,
                                                                                            sub_total: {
                                                                                                ...this.state.property.multi_recommendation_props
                                                                                                    .sub_total,
                                                                                                border: {
                                                                                                    ...this.state.property.multi_recommendation_props
                                                                                                        .sub_total.border,
                                                                                                    top: e.target.checked
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        ></input>
                                                                        <label>Top</label>
                                                                    </span>
                                                                    <span className="check-align">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={multi_recommendation_props?.sub_total?.border?.bottom}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        multi_recommendation_props: {
                                                                                            ...this.state.property.multi_recommendation_props,
                                                                                            sub_total: {
                                                                                                ...this.state.property.multi_recommendation_props
                                                                                                    .sub_total,
                                                                                                border: {
                                                                                                    ...this.state.property.multi_recommendation_props
                                                                                                        .sub_total.border,
                                                                                                    bottom: e.target.checked
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        ></input>
                                                                        <label>Bottom</label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="font-size font-st col-md-3">
                                                                <label>Sub Total Cell Margin Top</label>
                                                                <div className={`input-ft-size`}>
                                                                    <NumberFormat
                                                                        value={multi_recommendation_props?.sub_total?.cell_margin?.top}
                                                                        onValueChange={values =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        sub_total: {
                                                                                            ...this.state.property.multi_recommendation_props
                                                                                                .sub_total,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.multi_recommendation_props
                                                                                                    .sub_total.cell_margin,
                                                                                                top: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                        allowNegative={false}
                                                                        suffix=" inch"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="font-size font-st col-md-3">
                                                                <label>Sub Total Cell Margin Left</label>
                                                                <div className={`input-ft-size`}>
                                                                    <NumberFormat
                                                                        value={multi_recommendation_props?.sub_total?.cell_margin?.left}
                                                                        onValueChange={values =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        sub_total: {
                                                                                            ...this.state.property.multi_recommendation_props
                                                                                                .sub_total,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.multi_recommendation_props
                                                                                                    .sub_total.cell_margin,
                                                                                                left: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                        allowNegative={false}
                                                                        suffix=" inch"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="font-size font-st col-md-3">
                                                                <label>Sub Total Cell Margin Right</label>
                                                                <div className={`input-ft-size`}>
                                                                    <NumberFormat
                                                                        value={multi_recommendation_props?.sub_total?.cell_margin?.right}
                                                                        onValueChange={values =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        sub_total: {
                                                                                            ...this.state.property.multi_recommendation_props
                                                                                                .sub_total,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.multi_recommendation_props
                                                                                                    .sub_total.cell_margin,
                                                                                                right: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                        allowNegative={false}
                                                                        suffix=" inch"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="font-size font-st col-md-3">
                                                                <label>Sub Total Cell Margin Bottom</label>
                                                                <div className={`input-ft-size`}>
                                                                    <NumberFormat
                                                                        value={multi_recommendation_props?.sub_total?.cell_margin?.bottom}
                                                                        onValueChange={values =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        sub_total: {
                                                                                            ...this.state.property.multi_recommendation_props
                                                                                                .sub_total,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.multi_recommendation_props
                                                                                                    .sub_total.cell_margin,
                                                                                                bottom: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                        allowNegative={false}
                                                                        suffix=" inch"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="styles-box">
                                            <h2>Seperator Properties</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Border Size</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={multi_recommendation_props?.seperator_line?.size}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                seperator_line: {
                                                                                    ...this.state.property.multi_recommendation_props.seperator_line,
                                                                                    size: values.floatValue
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <div className="head_sub">
                                                            <label>Border Color </label>
                                                        </div>

                                                        <div className="dropdown">
                                                            <span
                                                                className="arrow_close"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                seperator_line: {
                                                                                    ...this.state.property.multi_recommendation_props.seperator_line,
                                                                                    color: ""
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <i class="fas fa-times"></i>
                                                            </span>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "multi_recommendation_seperator_color"
                                                                                ? null
                                                                                : "multi_recommendation_seperator_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{multi_recommendation_props?.seperator_line?.color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: multi_recommendation_props?.seperator_line?.color
                                                                            ? `#${multi_recommendation_props?.seperator_line?.color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                            </div>
                                                            {colorModalState === "multi_recommendation_seperator_color" && (
                                                                <div style={styles.popover}>
                                                                    <div
                                                                        style={styles.cover}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState: null
                                                                            });
                                                                        }}
                                                                    />
                                                                    <SketchPicker
                                                                        position="right"
                                                                        color={multi_recommendation_props?.seperator_line?.color}
                                                                        presetColors={[
                                                                            "#95cd50",
                                                                            "#ffe242",
                                                                            "#ffa105",
                                                                            "#ff0305",
                                                                            "#0018A8",
                                                                            "#800080",
                                                                            "#3E8EDE",
                                                                            "#417505"
                                                                        ]}
                                                                        onChange={color => {
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        seperator_line: {
                                                                                            ...this.state.property.multi_recommendation_props
                                                                                                .seperator_line,
                                                                                            color: color.hex?.replace("#", "")
                                                                                        }
                                                                                    }
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Border Style</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                value={multi_recommendation_props?.seperator_line?.style}
                                                                name="font_id"
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                seperator_line: {
                                                                                    ...this.state.property.multi_recommendation_props.seperator_line,
                                                                                    style: e.target.value
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                {/* <option value="">Select</option> */}
                                                                {FRAME_STYLES.map((fStyle, i) => (
                                                                    <option key={i} value={fStyle.name}>
                                                                        {fStyle.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Space After</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={multi_recommendation_props?.seperator_line?.space_after}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                seperator_line: {
                                                                                    ...this.state.property.multi_recommendation_props.seperator_line,
                                                                                    space_after: values.floatValue
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Space Before</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={multi_recommendation_props?.seperator_line?.space_before}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                seperator_line: {
                                                                                    ...this.state.property.multi_recommendation_props.seperator_line,
                                                                                    space_before: values.floatValue
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="styles-box">
                                            <h2>Memo Properties</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Apply Memo Field Property</label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={multi_recommendation_props?.memo_properties?.memo_props_active}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                memo_properties: {
                                                                                    ...this.state.property.multi_recommendation_props.memo_properties,
                                                                                    memo_props_active: e.target.value === "true"
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value={true}>Active</option>
                                                                <option value={false}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Heading Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={multi_recommendation_props?.memo_properties?.heading?.font_id || "-"}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                memo_properties: {
                                                                                    ...this.state.property.multi_recommendation_props.memo_properties,
                                                                                    heading: {
                                                                                        ...this.state.property.multi_recommendation_props
                                                                                            .memo_properties?.heading,
                                                                                        font_id: e.target.value
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="font-size font-st col-md-3">
                                                        <label>Heading Font Size</label>

                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={multi_recommendation_props?.memo_properties?.heading?.font_size || "-"}
                                                                onValueChange={values => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                memo_properties: {
                                                                                    ...this.state.property.multi_recommendation_props.memo_properties,
                                                                                    heading: {
                                                                                        ...this.state.property.multi_recommendation_props
                                                                                            .memo_properties?.heading,
                                                                                        font_size: values.floatValue
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <div className="head_sub">
                                                            <label>Heading Font Color </label>
                                                        </div>

                                                        <div className="dropdown">
                                                            <span
                                                                className="arrow_close"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                memo_properties: {
                                                                                    ...this.state.property.multi_recommendation_props.memo_properties,
                                                                                    heading: {
                                                                                        ...this.state.property.multi_recommendation_props
                                                                                            .memo_properties?.heading,
                                                                                        font_color: ""
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <i class="fas fa-times"></i>
                                                            </span>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState ===
                                                                            "multi_recommendation_props_memo_properties_heading_color"
                                                                                ? null
                                                                                : "multi_recommendation_props_memo_properties_heading_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>
                                                                    {multi_recommendation_props?.memo_properties?.heading?.font_color || "Select"}
                                                                </span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: multi_recommendation_props?.memo_properties?.heading?.font_color
                                                                            ? `#${multi_recommendation_props?.memo_properties?.heading?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                            </div>
                                                            {colorModalState === "multi_recommendation_props_memo_properties_heading_color" && (
                                                                <div style={styles.popover}>
                                                                    <div
                                                                        style={styles.cover}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState: null
                                                                            });
                                                                        }}
                                                                    />
                                                                    <SketchPicker
                                                                        position="right"
                                                                        color={multi_recommendation_props?.memo_properties?.heading?.font_color}
                                                                        presetColors={[
                                                                            "#95cd50",
                                                                            "#ffe242",
                                                                            "#ffa105",
                                                                            "#ff0305",
                                                                            "#0018A8",
                                                                            "#800080",
                                                                            "#3E8EDE",
                                                                            "#417505"
                                                                        ]}
                                                                        onChange={color => {
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        memo_properties: {
                                                                                            ...this.state.property.multi_recommendation_props
                                                                                                .memo_properties,
                                                                                            heading: {
                                                                                                ...this.state.property.multi_recommendation_props
                                                                                                    .memo_properties?.heading,
                                                                                                font_color: color.hex?.replace("#", "")
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Heading Font Bold </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={multi_recommendation_props?.memo_properties?.heading?.font_bold || "-"}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                memo_properties: {
                                                                                    ...this.state.property.multi_recommendation_props.memo_properties,
                                                                                    heading: {
                                                                                        ...this.state.property.multi_recommendation_props
                                                                                            .memo_properties?.heading,
                                                                                        font_bold: e.target.value === "true"
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Body Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={multi_recommendation_props?.memo_properties?.body?.font_id || "-"}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                memo_properties: {
                                                                                    ...this.state.property.multi_recommendation_props.memo_properties,
                                                                                    body: {
                                                                                        ...this.state.property.multi_recommendation_props
                                                                                            .memo_properties?.body,
                                                                                        font_id: e.target.value
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Body Font Size</label>

                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={multi_recommendation_props?.memo_properties?.body?.font_size || "-"}
                                                                onValueChange={values => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                memo_properties: {
                                                                                    ...this.state.property.multi_recommendation_props.memo_properties,
                                                                                    body: {
                                                                                        ...this.state.property.multi_recommendation_props
                                                                                            .memo_properties?.body,
                                                                                        font_size: values?.floatValue
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <div className="head_sub">
                                                            <label>Body Font Color </label>
                                                        </div>

                                                        <div className="dropdown">
                                                            <span
                                                                className="arrow_close"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                memo_properties: {
                                                                                    ...this.state.property.multi_recommendation_props.memo_properties,
                                                                                    body: {
                                                                                        ...this.state.property.multi_recommendation_props
                                                                                            .memo_properties?.body,
                                                                                        font_color: ""
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <i class="fas fa-times"></i>
                                                            </span>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState ===
                                                                            "multi_recommendation_props_memo_properties_body_color"
                                                                                ? null
                                                                                : "multi_recommendation_props_memo_properties_body_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>
                                                                    {multi_recommendation_props?.memo_properties?.body?.font_color || "Select"}
                                                                </span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: multi_recommendation_props?.memo_properties?.body?.font_color
                                                                            ? `#${multi_recommendation_props?.memo_properties?.body?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                            </div>
                                                            {colorModalState === "multi_recommendation_props_memo_properties_body_color" && (
                                                                <div style={styles.popover}>
                                                                    <div
                                                                        style={styles.cover}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState: null
                                                                            });
                                                                        }}
                                                                    />
                                                                    <SketchPicker
                                                                        position="right"
                                                                        color={multi_recommendation_props?.memo_properties?.body?.font_color}
                                                                        presetColors={[
                                                                            "#95cd50",
                                                                            "#ffe242",
                                                                            "#ffa105",
                                                                            "#ff0305",
                                                                            "#0018A8",
                                                                            "#800080",
                                                                            "#3E8EDE",
                                                                            "#417505"
                                                                        ]}
                                                                        onChange={color => {
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    multi_recommendation_props: {
                                                                                        ...this.state.property.multi_recommendation_props,
                                                                                        memo_properties: {
                                                                                            ...this.state.property.multi_recommendation_props
                                                                                                .memo_properties,
                                                                                            body: {
                                                                                                ...this.state.property.multi_recommendation_props
                                                                                                    .memo_properties?.body,
                                                                                                font_color: color.hex?.replace("#", "")
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Body Font Bold </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={multi_recommendation_props?.memo_properties?.body?.font_bold || "-"}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            multi_recommendation_props: {
                                                                                ...this.state.property.multi_recommendation_props,
                                                                                memo_properties: {
                                                                                    ...this.state.property.multi_recommendation_props.memo_properties,
                                                                                    body: {
                                                                                        ...this.state.property.multi_recommendation_props
                                                                                            .memo_properties?.body,
                                                                                        font_bold: e.target.value === "true"
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : this.props.match.params.tab === "normalRecommendation" ||
                                  this.props.match.params.tab === "asset" ||
                                  (isSmartchartView && selectedTab === "normalRecommendation") ||
                                  (isSmartchartView && selectedTab === "asset") ? (
                                    <NormalRecommendationForm
                                        fontFamilyData={FONT_FAMILY}
                                        tableStyleData={TABLE_STYLE}
                                        setNormalRecomProperty={this.setNormalRecomProperty}
                                        normalRecomProperty={
                                            this.props.match.params.tab === "asset" || (isSmartchartView && selectedTab === "asset")
                                                ? asset_props
                                                : normal_recommendation_props
                                        }
                                        setNormalRecommendationTableColumnConfig={this.setNormalRecommendationTableColumnConfig}
                                        onRecommendationColumnOrderChange={this.onRecommendationColumnOrderChange}
                                        showErrorBorder={showErrorBorder}
                                        isAsset={this.props.match.params.tab === "asset" || (isSmartchartView && selectedTab === "asset")}
                                    />
                                ) : this.props.match.params.tab === "CA" || (isSmartchartView && selectedTab === "CA") ? (
                                    <>
                                        <div className="styles-box">
                                            <h2>Comparative Analysis Data Table Properties</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Table Style </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={comp_analysis?.table?.table_style_id || "-"}
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        table_style_id: e.target.value
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <option value="">Select</option>
                                                                {TABLE_STYLE.map(f => (
                                                                    <option value={f.id} key={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Table Column Width (Only for excel export)</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={comp_analysis?.table?.col_width}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        col_width: Number(values.value)
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>
                                                            Table Page Fit{" "}
                                                            <i
                                                                className="fa fa-info info-ft-st"
                                                                data-for="table_page_fit"
                                                                data-tip="True- Fit table to page layout</br> False- Auto Adjust columns based on content"
                                                            />
                                                        </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                name="tag"
                                                                value={comp_analysis?.table?.table_page_fit}
                                                                onChange={e => {
                                                                    const value = e.target.value === "true";
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        table_page_fit: value
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                {" "}
                                                                <option value="">Select</option>
                                                                <option value={"true"}>True</option>
                                                                <option value={"false"}>False</option>
                                                            </select>
                                                        </div>
                                                        <ReactTooltip
                                                            className="rc-tooltip-custom-class tool-format-bx"
                                                            id="table_page_fit"
                                                            backgroundColor="#007bff"
                                                            place="top"
                                                            effect="solid"
                                                            html={true}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="styles-sec">
                                                    <div className="d-flex f-outer">
                                                        <div className="font-family font-st col-md-3">
                                                            <label>Custom Heading Font Name </label>
                                                            <div className="font-select">
                                                                <select
                                                                    className="dropdown"
                                                                    value={comp_analysis?.table?.custom_head?.font_id}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,

                                                                                            custom_head: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .custom_head,
                                                                                                font_id: e.target.value
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                >
                                                                    <option value="">Select</option>
                                                                    {FONT_FAMILY.map(f => (
                                                                        <option key={f.id} value={f.id}>
                                                                            {f.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Custom Heading Font Size</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={comp_analysis?.table?.custom_head?.font_size}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            custom_head: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    ?.custom_head,
                                                                                                font_size: Number(values.value)
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" Pt"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-color font-st col-md-3">
                                                            <label>Custom Heading Font Color </label>
                                                            <div className="dropdown">
                                                                <div
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            colorModalState:
                                                                                colorModalState === "table_custom_heading_color"
                                                                                    ? null
                                                                                    : "table_custom_heading_color"
                                                                        });
                                                                    }}
                                                                >
                                                                    <span>{comp_analysis?.table?.custom_head?.font_color || "Select"}</span>
                                                                    <span
                                                                        className="color-bx"
                                                                        style={{
                                                                            background: comp_analysis?.table?.custom_head?.font_color
                                                                                ? `#${comp_analysis?.table?.custom_head?.font_color}`
                                                                                : "#fff"
                                                                        }}
                                                                    ></span>
                                                                    <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                </div>
                                                                {colorModalState === "table_custom_heading_color" && (
                                                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                                                        <CompactPicker
                                                                            className="picker"
                                                                            colors={FONT_COLOR}
                                                                            color={comp_analysis?.table?.custom_head?.font_color}
                                                                            onChangeComplete={color =>
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        properties: {
                                                                                            ...this.state.property.properties,
                                                                                            comp_analysis: {
                                                                                                ...this.state.property.properties.comp_analysis,
                                                                                                table: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table,
                                                                                                    custom_head: {
                                                                                                        ...this.state.property.properties
                                                                                                            .comp_analysis?.table.custom_head,
                                                                                                        font_color: color.hex?.replace("#", "")
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="font-color font-st col-md-3">
                                                            <label>Custom Heading Background Color </label>
                                                            <div className="dropdown">
                                                                <div
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            colorModalState:
                                                                                colorModalState === "table_custom_heading_bg_color"
                                                                                    ? null
                                                                                    : "table_custom_heading_bg_color"
                                                                        });
                                                                    }}
                                                                >
                                                                    <span>{comp_analysis?.table?.custom_head?.bg_color || "Select"}</span>
                                                                    <span
                                                                        className="color-bx"
                                                                        style={{
                                                                            background: comp_analysis?.table?.custom_head?.bg_color
                                                                                ? `#${comp_analysis?.table?.custom_head?.bg_color}`
                                                                                : "#fff"
                                                                        }}
                                                                    ></span>
                                                                    <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                </div>
                                                                {colorModalState === "table_custom_heading_bg_color" && (
                                                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                                                        <CompactPicker
                                                                            className="picker"
                                                                            colors={FONT_COLOR}
                                                                            color={comp_analysis?.table?.custom_head?.bg_color}
                                                                            onChangeComplete={color =>
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        properties: {
                                                                                            ...this.state.property.properties,
                                                                                            comp_analysis: {
                                                                                                ...this.state.property.properties.comp_analysis,
                                                                                                table: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table,
                                                                                                    custom_head: {
                                                                                                        ...this.state.property.properties
                                                                                                            .comp_analysis?.table?.custom_head,
                                                                                                        bg_color: color.hex?.replace("#", "")
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="font-family font-st col-md-3">
                                                            <label>Custom Heading Alignment</label>
                                                            <div className="font-select">
                                                                <select
                                                                    className="dropdown"
                                                                    name="tag"
                                                                    value={comp_analysis?.table?.custom_head?.alignment}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            custom_head: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    ?.custom_head,
                                                                                                alignment: e.target.value
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                >
                                                                    {" "}
                                                                    <option value="">Select</option>
                                                                    <option value={"left"}>Left</option>
                                                                    <option value={"center"}>Center</option>
                                                                    <option value={"right"}>Right</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="font-size font-st col-md-3">
                                                            <label>Custom Heading Cell Margin Top</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={comp_analysis?.table?.custom_head?.cell_margin?.top}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            custom_head: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    ?.custom_head,
                                                                                                cell_margin: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table?.custom_head.cell_margin,
                                                                                                    top: values.floatValue
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Custom Heading Cell Margin Left</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={comp_analysis?.table?.custom_head?.cell_margin?.left}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            custom_head: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .custom_head,
                                                                                                cell_margin: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table.custom_head.cell_margin,
                                                                                                    left: values.floatValue
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Custom Heading Cell Margin Right</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={comp_analysis?.table?.custom_head?.cell_margin?.right}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            custom_head: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .custom_head,
                                                                                                cell_margin: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table.custom_head.cell_margin,
                                                                                                    right: values.floatValue
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Custom Heading Cell Margin Bottom</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={comp_analysis?.table?.custom_head?.cell_margin?.bottom}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            custom_head: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .custom_head,
                                                                                                cell_margin: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table.custom_head.cell_margin,
                                                                                                    bottom: values.floatValue
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Custom Heading Blank Line Height</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={comp_analysis?.table?.custom_head?.blank_line_height}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,

                                                                                            custom_head: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .custom_head,
                                                                                                blank_line_height: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" pt"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="styles-sec">
                                                    <div className="d-flex f-outer">
                                                        <div className="font-family font-st col-md-3">
                                                            <label>Heading Font Name </label>
                                                            <div className="font-select">
                                                                <select
                                                                    // className={`dropdown ${showErrorBorder && !header.client.font_id ? "error-border" : ""}`}
                                                                    className="dropdown"
                                                                    value={comp_analysis?.table?.heading?.font_id}
                                                                    // name="font_id"
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            heading: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .heading,
                                                                                                font_id: e.target.value
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                >
                                                                    <option value="">Select</option>
                                                                    {FONT_FAMILY.map(f => (
                                                                        <option key={f.id} value={f.id}>
                                                                            {f.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Heading Font Size</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={comp_analysis?.table?.heading?.font_size}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            heading: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .heading,
                                                                                                font_size: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" Pt"
                                                                    // className={`${
                                                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_size
                                                                    //         ? "error-border"
                                                                    //         : ""
                                                                    // }`}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="font-color font-st col-md-3">
                                                            <label>Heading Font Color </label>
                                                            <div
                                                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                                className="dropdown"
                                                            >
                                                                <div
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            colorModalState:
                                                                                colorModalState === "table_heading_color"
                                                                                    ? null
                                                                                    : "table_heading_color"
                                                                        });
                                                                    }}
                                                                >
                                                                    <span>{comp_analysis?.table?.heading?.font_color || "Select"}</span>
                                                                    <span
                                                                        className="color-bx"
                                                                        style={{
                                                                            background: comp_analysis?.table?.heading?.font_color
                                                                                ? `#${comp_analysis?.table?.heading?.font_color}`
                                                                                : "#fff"
                                                                        }}
                                                                    ></span>
                                                                    <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                </div>
                                                                {colorModalState === "table_heading_color" && (
                                                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                                                        <CompactPicker
                                                                            className="picker"
                                                                            colors={FONT_COLOR}
                                                                            color={comp_analysis?.table?.heading?.font_color}
                                                                            onChangeComplete={color =>
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        properties: {
                                                                                            ...this.state.property.properties,
                                                                                            comp_analysis: {
                                                                                                table: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table,
                                                                                                    heading: {
                                                                                                        ...this.state.property.properties
                                                                                                            .comp_analysis?.table.heading,
                                                                                                        font_color: color.hex?.replace("#", "")
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="font-color font-st col-md-3">
                                                            <label>Heading Background Color </label>
                                                            <div
                                                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                                className="dropdown"
                                                            >
                                                                <div
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            colorModalState:
                                                                                colorModalState === "table_heading_bg_color"
                                                                                    ? null
                                                                                    : "table_heading_bg_color"
                                                                        });
                                                                    }}
                                                                >
                                                                    <span>{comp_analysis?.table?.heading?.bg_color || "Select"}</span>
                                                                    <span
                                                                        className="color-bx"
                                                                        style={{
                                                                            background: comp_analysis?.table?.heading?.bg_color
                                                                                ? `#${comp_analysis?.table?.heading?.bg_color}`
                                                                                : "#fff"
                                                                        }}
                                                                    ></span>
                                                                    <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                </div>
                                                                {colorModalState === "table_heading_bg_color" && (
                                                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                                                        <CompactPicker
                                                                            className="picker"
                                                                            colors={FONT_COLOR}
                                                                            color={comp_analysis?.table?.heading?.bg_color}
                                                                            onChangeComplete={color =>
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        properties: {
                                                                                            ...this.state.property.properties,
                                                                                            comp_analysis: {
                                                                                                ...this.state.property.properties.comp_analysis,
                                                                                                table: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table,
                                                                                                    heading: {
                                                                                                        ...this.state.property.properties
                                                                                                            .comp_analysis?.table.heading,
                                                                                                        bg_color: color.hex?.replace("#", "")
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="font-family font-st col-md-3">
                                                            <label>Heading Alignment</label>
                                                            <div className="font-select">
                                                                <select
                                                                    // className={`dropdown ${showErrorBorder && !total?.alignment ? "error-border" : ""}`}
                                                                    className="dropdown"
                                                                    name="tag"
                                                                    value={comp_analysis?.table?.heading?.alignment}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            heading: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .heading,
                                                                                                alignment: e.target.value
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                >
                                                                    {" "}
                                                                    <option value="">Select</option>
                                                                    <option value={"left"}>Left</option>
                                                                    <option value={"center"}>Center</option>
                                                                    <option value={"right"}>Right</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="font-size font-st col-md-3">
                                                            <label>Heading Cell Margin Top</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={comp_analysis?.table?.heading?.cell_margin?.top}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            heading: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .heading,
                                                                                                cell_margin: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table.heading.cell_margin,
                                                                                                    top: values.floatValue
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Heading Cell Margin Left</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={comp_analysis?.table?.heading?.cell_margin?.left}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            heading: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .heading,
                                                                                                cell_margin: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table.heading.cell_margin,
                                                                                                    left: values.floatValue
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Heading Cell Margin Right</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={comp_analysis?.table?.heading?.cell_margin?.right}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            heading: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .heading,
                                                                                                cell_margin: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table.heading.cell_margin,
                                                                                                    right: values.floatValue
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Heading Cell Margin Bottom</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={comp_analysis?.table?.heading?.cell_margin?.bottom}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    comp_analysis: {
                                                                                        ...this.state.property.properties.comp_analysis,
                                                                                        table: {
                                                                                            ...this.state.property.properties.comp_analysis?.table,
                                                                                            heading: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .heading,
                                                                                                cell_margin: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table.heading.cell_margin,
                                                                                                    bottom: values.floatValue
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Data Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${showErrorBorder && !header.client.font_id ? "error-border" : ""}`}
                                                                className="dropdown"
                                                                value={comp_analysis?.table?.data?.font_id}
                                                                // name="font_id"
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        data: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .data,
                                                                                            font_id: e.target.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Data Font Size</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={comp_analysis?.table?.data?.font_size}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        data: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .data,
                                                                                            font_size: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                                // className={`${
                                                                //     showErrorBorder && custom_legend?.show_legend && !total?.font_size
                                                                //         ? "error-border"
                                                                //         : ""
                                                                // }`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-color font-st col-md-3">
                                                        <label>Data Font Color </label>
                                                        <div
                                                            // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                            className="dropdown"
                                                        >
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "table_custom_font_color"
                                                                                ? null
                                                                                : "table_custom_font_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{comp_analysis?.table?.data?.font_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: comp_analysis?.table?.data?.font_color
                                                                            ? `#${comp_analysis?.table?.data?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon"></i>
                                                            </div>
                                                            {colorModalState === "table_custom_font_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={comp_analysis?.table?.data?.font_color}
                                                                        onChangeComplete={color =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    properties: {
                                                                                        ...this.state.property.properties,
                                                                                        comp_analysis: {
                                                                                            ...this.state.property.properties.comp_analysis,
                                                                                            table: {
                                                                                                ...this.state.property.properties.comp_analysis
                                                                                                    ?.table,
                                                                                                data: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table.data,
                                                                                                    font_color: color.hex?.replace("#", "")
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Data Alignment</label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${showErrorBorder && !total?.alignment ? "error-border" : ""}`}
                                                                className="dropdown"
                                                                name="tag"
                                                                value={comp_analysis?.table?.data?.alignment}
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        data: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .data,
                                                                                            alignment: e.target.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                {" "}
                                                                <option value="">Select</option>
                                                                <option value={"left"}>Left</option>
                                                                <option value={"center"}>Center</option>
                                                                <option value={"right"}>Right</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="font-size font-st col-md-3">
                                                        <label>Data Cell Margin Top</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={comp_analysis?.table?.data?.cell_margin?.top}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        data: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .data,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .data.cell_margin,
                                                                                                top: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Data Cell Margin Left</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={comp_analysis?.table?.data?.cell_margin?.left}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        data: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .data,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .data.cell_margin,
                                                                                                left: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Data Cell Margin Right</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={comp_analysis?.table?.data?.cell_margin?.right}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        data: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .data,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .data.cell_margin,
                                                                                                right: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Data Cell Margin Bottom</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={comp_analysis?.table?.data?.cell_margin?.bottom}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        data: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .data,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .data.cell_margin,
                                                                                                bottom: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Data Blank Line Height</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={comp_analysis?.table?.data?.blank_line_height}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        data: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .data,
                                                                                            blank_line_height: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" pt"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Sub Total Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${showErrorBorder && !header.client.font_id ? "error-border" : ""}`}
                                                                className="dropdown"
                                                                value={comp_analysis?.table?.sub_total?.font_id}
                                                                // name="font_id"
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        sub_total: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .sub_total,
                                                                                            font_id: e.target.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Sub Total Font Size</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={comp_analysis?.table?.sub_total?.font_size}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        sub_total: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .sub_total,
                                                                                            font_size: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                                // className={`${
                                                                //     showErrorBorder && custom_legend?.show_legend && !total?.font_size
                                                                //         ? "error-border"
                                                                //         : ""
                                                                // }`}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <label>Sub Total Font Color </label>
                                                        <div
                                                            // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                            className="dropdown"
                                                        >
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "table_subtotal_color" ? null : "table_subtotal_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{comp_analysis?.table?.sub_total?.font_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: comp_analysis?.table?.sub_total?.font_color
                                                                            ? `#${comp_analysis?.table?.sub_total?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon"></i>
                                                            </div>
                                                            {colorModalState === "table_subtotal_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={comp_analysis?.table?.sub_total?.font_color}
                                                                        onChangeComplete={color =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    properties: {
                                                                                        ...this.state.property.properties,
                                                                                        comp_analysis: {
                                                                                            ...this.state.property.properties.comp_analysis,
                                                                                            table: {
                                                                                                ...this.state.property.properties.comp_analysis
                                                                                                    ?.table,
                                                                                                sub_total: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table.sub_total,
                                                                                                    font_color: color.hex?.replace("#", "")
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <label>Sub Total Background Color </label>
                                                        <div
                                                            // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                            className="dropdown"
                                                        >
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "table_sub_total_bg_color"
                                                                                ? null
                                                                                : "table_sub_total_bg_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{comp_analysis?.table?.sub_total?.bg_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: comp_analysis?.table?.sub_total?.bg_color
                                                                            ? `#${comp_analysis?.table?.sub_total?.bg_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon"></i>
                                                            </div>
                                                            {colorModalState === "table_sub_total_bg_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={comp_analysis?.table?.sub_total?.bg_color}
                                                                        onChangeComplete={color =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    properties: {
                                                                                        ...this.state.property.properties,
                                                                                        comp_analysis: {
                                                                                            ...this.state.property.properties.comp_analysis,
                                                                                            table: {
                                                                                                ...this.state.property.properties.comp_analysis
                                                                                                    ?.table,
                                                                                                sub_total: {
                                                                                                    ...this.state.property.properties.comp_analysis
                                                                                                        ?.table.sub_total,
                                                                                                    bg_color: color.hex?.replace("#", "")
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="font-family font-st col-md-3">
                                                        <label>Sub Total Alignment</label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${showErrorBorder && !total?.alignment ? "error-border" : ""}`}
                                                                className="dropdown"
                                                                name="tag"
                                                                value={comp_analysis?.table?.sub_total?.alignment}
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        sub_total: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .sub_total,
                                                                                            alignment: e.target.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                {" "}
                                                                <option value="">Select</option>
                                                                <option value={"left"}>Left</option>
                                                                <option value={"center"}>Center</option>
                                                                <option value={"right"}>Right</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="font-size font-st col-md-3">
                                                        <label>Sub Total Cell Margin Top</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={comp_analysis?.table?.sub_total?.cell_margin?.top}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        sub_total: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .sub_total,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .sub_total.cell_margin,
                                                                                                top: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Sub Total Cell Margin Left</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={comp_analysis?.table?.sub_total?.cell_margin?.left}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        sub_total: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .sub_total,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .sub_total.cell_margin,
                                                                                                left: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Sub Total Cell Margin Right</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={comp_analysis?.table?.sub_total?.cell_margin?.right}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        sub_total: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .sub_total,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .sub_total.cell_margin,
                                                                                                right: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Sub Total Cell Margin Bottom</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={comp_analysis?.table?.sub_total?.cell_margin?.bottom}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                comp_analysis: {
                                                                                    ...this.state.property.properties.comp_analysis,
                                                                                    table: {
                                                                                        ...this.state.property.properties.comp_analysis?.table,
                                                                                        sub_total: {
                                                                                            ...this.state.property.properties.comp_analysis?.table
                                                                                                .sub_total,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.comp_analysis?.table
                                                                                                    .sub_total.cell_margin,
                                                                                                bottom: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="styles-box">
                                            <h2>Table Column Configuration-Recommendation</h2>

                                            <DragDropContext onDragEnd={this.onColumnDnd("ca_recommendation")}>
                                                {Array.from({
                                                    length: this.state?.totalRowsForColumnDnd
                                                }).map((rowData, i) => (
                                                    <Droppable droppableId={`droppable-${i}`} direction="horizontal">
                                                        {provided => (
                                                            <div ref={provided.innerRef} className="styles-sec">
                                                                <div className="d-flex f-outer">
                                                                    {comp_analysis?.ca_recommendation &&
                                                                        Object.entries(
                                                                            typeof comp_analysis?.ca_recommendation === "string"
                                                                                ? JSON.parse(comp_analysis?.ca_recommendation)
                                                                                : comp_analysis?.ca_recommendation
                                                                        )
                                                                            ?.slice(i * 4, (i + 1) * 4)
                                                                            ?.map(([columnKey, columnData], index) =>
                                                                                columnData?.label === "Code" ? (
                                                                                    <div className="font-family font-st col-md-3">
                                                                                        <div className="col-md-12 box-otr box-wide tabl-clm">
                                                                                            <div className="rem-txt ">
                                                                                                <label className="container-check">
                                                                                                    {columnData?.label || "-"}
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={columnData?.selected}
                                                                                                        disabled={columnData?.label === "Code"}
                                                                                                        onChange={() => {
                                                                                                            this.setCaColumnConfig(
                                                                                                                columnKey,
                                                                                                                "selected",
                                                                                                                !columnData.selected,
                                                                                                                "ca_recommendation"
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                    <span
                                                                                                        className={`checkmark ${
                                                                                                            columnData?.label === "Code"
                                                                                                                ? "cursor-diabled"
                                                                                                                : ""
                                                                                                        }`}
                                                                                                    />
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="right-box-content">
                                                                                                <div class="input-box">
                                                                                                    <NumberFormat
                                                                                                        autoComplete={"nope"}
                                                                                                        className="custom-input form-control"
                                                                                                        value={columnData?.width}
                                                                                                        suffix={" inch"}
                                                                                                        thousandSeparator={true}
                                                                                                        onValueChange={values => {
                                                                                                            const { value } = values;
                                                                                                            this.setCaColumnConfig(
                                                                                                                columnKey,
                                                                                                                "width",
                                                                                                                Number(value),
                                                                                                                "ca_recommendation"
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="image-drag">
                                                                                                    <img src="/img/Group 2.svg" alt="" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <Draggable
                                                                                        key={`draggable-${i}${index}`}
                                                                                        draggableId={`draggable-${i}${index}`}
                                                                                        index={i * 4 + index}
                                                                                        isDragDisabled={columnData?.label === "Code"}
                                                                                    >
                                                                                        {provided => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                key={`draggable-${index}`}
                                                                                                className="font-family font-st col-md-3"
                                                                                            >
                                                                                                <div className="col-md-12 box-otr box-wide tabl-clm">
                                                                                                    <div className="rem-txt">
                                                                                                        <label className="container-check">
                                                                                                            {columnData?.label || "-"}
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                checked={columnData?.selected}
                                                                                                                disabled={
                                                                                                                    columnData?.label === "Code"
                                                                                                                }
                                                                                                                onChange={() => {
                                                                                                                    this.setCaColumnConfig(
                                                                                                                        columnKey,
                                                                                                                        "selected",
                                                                                                                        !columnData.selected,
                                                                                                                        "ca_recommendation"
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                            <span
                                                                                                                className={`checkmark ${
                                                                                                                    columnData?.label === "Code"
                                                                                                                        ? "cursor-diabled"
                                                                                                                        : ""
                                                                                                                }`}
                                                                                                            />
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className="right-box-content">
                                                                                                        <div class="input-box">
                                                                                                            <NumberFormat
                                                                                                                autoComplete={"nope"}
                                                                                                                className="custom-input form-control"
                                                                                                                value={columnData?.width}
                                                                                                                suffix={" inch"}
                                                                                                                thousandSeparator={true}
                                                                                                                onValueChange={values => {
                                                                                                                    const { value } = values;
                                                                                                                    this.setCaColumnConfig(
                                                                                                                        columnKey,
                                                                                                                        "width",
                                                                                                                        Number(value),
                                                                                                                        "ca_recommendation"
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="image-drag">
                                                                                                            <img src="/img/Group 2.svg" alt="" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {provided.placeholder}
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                )
                                                                            )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                ))}
                                            </DragDropContext>
                                        </div>
                                        <div className="styles-box">
                                            <h2>Table Column Configuration-Asset</h2>

                                            <DragDropContext onDragEnd={this.onColumnDnd("ca_asset")}>
                                                {Array.from({
                                                    length: this.state?.totalRowsForColumnDnd
                                                }).map((rowData, i) => (
                                                    <Droppable droppableId={`droppable-${i}`} direction="horizontal">
                                                        {provided => (
                                                            <div ref={provided.innerRef} className="styles-sec">
                                                                <div className="d-flex f-outer">
                                                                    {comp_analysis?.ca_asset &&
                                                                        Object.entries(
                                                                            typeof comp_analysis?.ca_asset === "string"
                                                                                ? JSON.parse(comp_analysis?.ca_asset)
                                                                                : comp_analysis?.ca_asset
                                                                        )
                                                                            ?.slice(i * 4, (i + 1) * 4)
                                                                            ?.map(([columnKey, columnData], index) =>
                                                                                columnData?.label === "Code" ? (
                                                                                    <div className="font-family font-st col-md-3">
                                                                                        <div className="col-md-12 box-otr box-wide tabl-clm">
                                                                                            <div className="rem-txt ">
                                                                                                <label className="container-check">
                                                                                                    {columnData?.label || "-"}
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={columnData?.selected}
                                                                                                        disabled={columnData?.label === "Code"}
                                                                                                        onChange={() => {
                                                                                                            this.setCaColumnConfig(
                                                                                                                columnKey,
                                                                                                                "selected",
                                                                                                                !columnData.selected,
                                                                                                                "ca_asset"
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                    <span
                                                                                                        className={`checkmark ${
                                                                                                            columnData?.label === "Code"
                                                                                                                ? "cursor-diabled"
                                                                                                                : ""
                                                                                                        }`}
                                                                                                    />
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="right-box-content">
                                                                                                <div class="input-box">
                                                                                                    <NumberFormat
                                                                                                        autoComplete={"nope"}
                                                                                                        className="custom-input form-control"
                                                                                                        value={columnData?.width}
                                                                                                        suffix={" inch"}
                                                                                                        thousandSeparator={true}
                                                                                                        onValueChange={values => {
                                                                                                            const { value } = values;
                                                                                                            this.setCaColumnConfig(
                                                                                                                columnKey,
                                                                                                                "width",
                                                                                                                Number(value),
                                                                                                                "ca_asset"
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="image-drag">
                                                                                                    <img src="/img/Group 2.svg" alt="" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <Draggable
                                                                                        key={`draggable-${i}${index}`}
                                                                                        draggableId={`draggable-${i}${index}`}
                                                                                        index={i * 4 + index}
                                                                                        isDragDisabled={columnData?.label === "Code"}
                                                                                    >
                                                                                        {provided => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                key={`draggable-${index}`}
                                                                                                className="font-family font-st col-md-3"
                                                                                            >
                                                                                                <div className="col-md-12 box-otr box-wide tabl-clm">
                                                                                                    <div className="rem-txt">
                                                                                                        <label className="container-check">
                                                                                                            {columnData?.label || "-"}
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                checked={columnData?.selected}
                                                                                                                disabled={
                                                                                                                    columnData?.label === "Code"
                                                                                                                }
                                                                                                                onChange={() => {
                                                                                                                    this.setCaColumnConfig(
                                                                                                                        columnKey,
                                                                                                                        "selected",
                                                                                                                        !columnData.selected,
                                                                                                                        "ca_asset"
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                            <span
                                                                                                                className={`checkmark ${
                                                                                                                    columnData?.label === "Code"
                                                                                                                        ? "cursor-diabled"
                                                                                                                        : ""
                                                                                                                }`}
                                                                                                            />
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className="right-box-content">
                                                                                                        <div class="input-box">
                                                                                                            <NumberFormat
                                                                                                                autoComplete={"nope"}
                                                                                                                className="custom-input form-control"
                                                                                                                value={columnData?.width}
                                                                                                                suffix={" inch"}
                                                                                                                thousandSeparator={true}
                                                                                                                onValueChange={values => {
                                                                                                                    const { value } = values;
                                                                                                                    this.setCaColumnConfig(
                                                                                                                        columnKey,
                                                                                                                        "width",
                                                                                                                        Number(value),
                                                                                                                        "ca_asset"
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="image-drag">
                                                                                                            <img src="/img/Group 2.svg" alt="" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {provided.placeholder}
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                )
                                                                            )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                ))}
                                            </DragDropContext>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="styles-box">
                                            <h2>Page Header (Client Template Only)</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Client Font Name *</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown ${
                                                                    showErrorBorder && !header.client.font_id ? "error-border" : ""
                                                                }`}
                                                                value={header?.client?.font_id}
                                                                name="font_id"
                                                                onChange={e => this.handleChangeHeader("client", "font_id", e.target.value)}
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Client Font Size *</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={header?.client?.font_size}
                                                                onValueChange={values =>
                                                                    this.handleChangeHeader("client", "font_size", values.floatValue)
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                                className={`${showErrorBorder && !header?.client?.font_size ? "error-border" : ""}`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-color font-st col-md-3">
                                                        <label>Client Font Color *</label>
                                                        <div
                                                            className={`dropdown ${showErrorBorder && !header?.client?.color ? "error-border" : ""}`}
                                                        >
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "header_client_color" ? null : "header_client_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{header?.client?.color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: header?.client?.color ? `#${header?.client?.color}` : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon"></i>
                                                            </div>
                                                            {colorModalState === "header_client_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={header?.client?.color}
                                                                        onChangeComplete={color =>
                                                                            this.handleChangeHeader("client", "color", color.hex?.replace("#", ""))
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Client Font Bold</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                name="tag"
                                                                value={header?.client?.bold}
                                                                onChange={e => this.handleChangeHeader("client", "bold", e.target.value === "true")}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Project Font Name *</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown ${
                                                                    showErrorBorder && !header.project.font_id ? "error-border" : ""
                                                                }`}
                                                                value={header?.project?.font_id}
                                                                name="font_id"
                                                                onChange={e => this.handleChangeHeader("project", "font_id", e.target.value)}
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Project Font Size *</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={header?.project?.font_size}
                                                                onValueChange={values =>
                                                                    this.handleChangeHeader("project", "font_size", values.floatValue)
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                                className={`${showErrorBorder && !header?.project?.font_size ? "error-border" : ""}`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-color font-st col-md-3">
                                                        <label>Project Font Color *</label>
                                                        <div
                                                            className={`dropdown ${showErrorBorder && !header?.project?.color ? "error-border" : ""}`}
                                                        >
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "header_project_color" ? null : "header_project_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{header?.project?.color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: header?.project?.color ? `#${header?.project?.color}` : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon"></i>
                                                            </div>
                                                            {colorModalState === "header_project_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={header?.project?.color}
                                                                        onChangeComplete={color =>
                                                                            this.handleChangeHeader("project", "color", color.hex?.replace("#", ""))
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Project Font Bold</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                name="tag"
                                                                value={header?.project?.bold}
                                                                onChange={e => this.handleChangeHeader("project", "bold", e.target.value === "true")}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="font-size font-st col-md-3">
                                                        <label>Logo Width</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={header?.logo?.width}
                                                                onValueChange={values => this.handleChangeHeader("logo", "width", values.floatValue)}
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-color font-st col-md-3">
                                                        <label>Logo Height</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={header?.logo?.height}
                                                                onValueChange={values => this.handleChangeHeader("logo", "height", values.floatValue)}
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Logo Client</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                name="tag"
                                                                value={header?.logo?.client}
                                                                onChange={e => this.handleChangeHeader("logo", "client", e.target.value === "true")}
                                                            >
                                                                <option value={true}>True</option>
                                                                <option value={false}>False</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="styles-box">
                                            <h2>Chart Heading</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Font Name *</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown ${showErrorBorder && !heading?.font_id ? "error-border" : ""}`}
                                                                value={heading?.font_id}
                                                                name="font_id"
                                                                onChange={e => this.handleChangeChart("heading", "font_id", e.target.value)}
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Font Size *</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={heading?.font_size}
                                                                onValueChange={values =>
                                                                    this.handleChangeChart("heading", "font_size", values.floatValue)
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                                className={`${showErrorBorder && !heading?.font_size ? "error-border" : ""}`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-color font-st col-md-3">
                                                        <label>Font Color *</label>
                                                        <div className={`dropdown ${showErrorBorder && !heading?.color ? "error-border" : ""}`}>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState: colorModalState === "heading_color" ? null : "heading_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{heading?.color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{ background: heading?.color ? `#${heading?.color}` : "#fff" }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon"></i>
                                                            </div>
                                                            {colorModalState === "heading_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={heading?.color}
                                                                        onChangeComplete={color =>
                                                                            this.handleChangeChart("heading", "color", color.hex?.replace("#", ""))
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Font Bold</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                name="tag"
                                                                value={heading?.bold}
                                                                onChange={e => this.handleChangeChart("heading", "bold", e.target.value === "true")}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Font Alignment</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown ${showErrorBorder && !heading?.alignment ? "error-border" : ""}`}
                                                                name="tag"
                                                                value={heading?.alignment}
                                                                onChange={e => this.handleChangeChart("heading", "alignment", e.target.value)}
                                                            >
                                                                <option value={"left"}>Left</option>
                                                                <option value={"center"}>Center</option>
                                                                <option value={"right"}>Right</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="styles-box">
                                            <h2>Chart Outer Frame (Border)</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Visible</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                name="frame"
                                                                value={chart?.frame}
                                                                onChange={e => this.handleChangeChart("chart", "frame", e.target.value === "true")}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {chart?.frame ? (
                                                <div className="styles-sec">
                                                    <div className="d-flex f-outer">
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Frame Width</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={chart?.frame_props?.size}
                                                                    onValueChange={values => {
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    chart: {
                                                                                        ...this.state.property.properties.chart,
                                                                                        frame_props: {
                                                                                            ...this.state.property.properties.chart.frame_props,
                                                                                            size: values.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        });
                                                                    }}
                                                                    allowNegative={false}
                                                                    suffix=" Pt"
                                                                    // className={`${showErrorBorder && !header?.client?.font_size ? "error-border" : ""}`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Frame Padding</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={chart?.frame_props?.space}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    chart: {
                                                                                        ...this.state.property.properties.chart,
                                                                                        frame_props: {
                                                                                            ...this.state.property.properties.chart.frame_props,
                                                                                            space: values.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" Pt"
                                                                    // className={`${showErrorBorder && !header?.client?.font_size ? "error-border" : ""}`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-color font-st col-md-3">
                                                            <label>Frame Color</label>
                                                            <div className={`dropdown`}>
                                                                <div
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            colorModalState: colorModalState === "frame_color" ? null : "frame_color"
                                                                        });
                                                                    }}
                                                                >
                                                                    <span>{chart?.frame_props?.color || "Select"}</span>
                                                                    <span
                                                                        className="color-bx"
                                                                        style={{
                                                                            background: chart?.frame_props?.color
                                                                                ? `#${chart?.frame_props?.color}`
                                                                                : "#fff"
                                                                        }}
                                                                    ></span>
                                                                    <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                </div>
                                                                {colorModalState === "frame_color" && (
                                                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                                                        <CompactPicker
                                                                            className="picker"
                                                                            colors={FONT_COLOR}
                                                                            color={chart?.frame_props?.color}
                                                                            onChangeComplete={color => {
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        properties: {
                                                                                            ...this.state.property.properties,
                                                                                            chart: {
                                                                                                ...this.state.property.properties.chart,
                                                                                                frame_props: {
                                                                                                    ...this.state.property.properties.chart
                                                                                                        .frame_props,
                                                                                                    color: color.hex?.replace("#", "")
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="font-family font-st col-md-3">
                                                            <label>Frame Style</label>
                                                            <div className="font-select">
                                                                <select
                                                                    className={`dropdown`}
                                                                    value={chart?.frame_props?.val}
                                                                    name="font_id"
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    chart: {
                                                                                        ...this.state.property.properties.chart,
                                                                                        frame_props: {
                                                                                            ...this.state.property.properties.chart.frame_props,
                                                                                            val: e.target.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                >
                                                                    {/* <option value="">Select</option> */}
                                                                    {FRAME_STYLES.map((fStyle, i) => (
                                                                        <option key={i} value={fStyle.name}>
                                                                            {fStyle.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="styles-box">
                                            <h2>Chart X-Axis</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Font Name</label>
                                                        <i
                                                            data-tip="Font Name of X-Axis, Y-Axis, Data Labels and Legends must be the same"
                                                            data-for="chart_property"
                                                            className="fas fa-info-circle ml-2"
                                                        />
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                value={chart_font?.font_id}
                                                                name="font_id"
                                                                onChange={e => this.handleChangeChart("chart_font", "font_id", e.target.value)}
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Font Size</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={x_axis?.font_size}
                                                                onValueChange={values =>
                                                                    this.handleChangeChart("x_axis", "font_size", values.floatValue)
                                                                }
                                                                allowNegative={false}
                                                                suffix=" px"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-color font-st col-md-3">
                                                        <label>Font Color</label>
                                                        <div className={`dropdown`}>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState: colorModalState === "x_axis_color" ? null : "x_axis_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{x_axis?.color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{ background: x_axis?.color ? `#${x_axis?.color}` : "#fff" }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon"></i>
                                                            </div>
                                                            {colorModalState === "x_axis_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={x_axis?.color}
                                                                        onChangeComplete={color =>
                                                                            this.handleChangeChart("x_axis", "color", color.hex?.replace("#", ""))
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="styles-box">
                                            <h2>Chart Y-Axis</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Font Name</label>
                                                        <i
                                                            data-tip="Font Name of X-Axis, Y-Axis, Data Labels and Legends must be the same"
                                                            data-for="chart_property"
                                                            className="fas fa-info-circle ml-2"
                                                        />
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                value={chart_font?.font_id}
                                                                name="font_id"
                                                                onChange={e => this.handleChangeChart("chart_font", "font_id", e.target.value)}
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Font Size</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={y_axis?.font_size}
                                                                onValueChange={values =>
                                                                    this.handleChangeChart("y_axis", "font_size", values.floatValue)
                                                                }
                                                                allowNegative={false}
                                                                suffix=" px"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-color font-st col-md-3">
                                                        <label>Font Color</label>
                                                        <div className={`dropdown`}>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState: colorModalState === "y_axis_color" ? null : "y_axis_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{y_axis?.color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{ background: y_axis?.color ? `#${y_axis?.color}` : "#fff" }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon"></i>
                                                            </div>
                                                            {colorModalState === "y_axis_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={y_axis?.color}
                                                                        onChangeComplete={color =>
                                                                            this.handleChangeChart("y_axis", "color", color.hex?.replace("#", ""))
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="styles-box">
                                            <h2>Chart Data Labels</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Font Name</label>
                                                        <i
                                                            data-tip="Font Name of X-Axis, Y-Axis, Data Labels and Legends must be the same"
                                                            data-for="chart_property"
                                                            className="fas fa-info-circle ml-2"
                                                        />
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                value={chart_font?.font_id}
                                                                name="font_id"
                                                                onChange={e => this.handleChangeChart("chart_font", "font_id", e.target.value)}
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Font Size</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={data_labels?.font_size}
                                                                onValueChange={values =>
                                                                    this.handleChangeChart("data_labels", "font_size", values.floatValue)
                                                                }
                                                                allowNegative={false}
                                                                suffix=" px"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-color font-st col-md-3">
                                                        <label>Font Color</label>
                                                        <div className={`dropdown`}>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "data_labels_color" ? null : "data_labels_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{data_labels?.color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{ background: data_labels?.color ? `#${data_labels?.color}` : "#fff" }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon"></i>
                                                            </div>
                                                            {colorModalState === "data_labels_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={data_labels?.color}
                                                                        onChangeComplete={color =>
                                                                            this.handleChangeChart(
                                                                                "data_labels",
                                                                                "color",
                                                                                color.hex?.replace("#", "")
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="styles-box">
                                            <h2>Chart Legend (Color Legend as part of the Chart)</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Visible</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                name="show_legend"
                                                                value={legend?.show_legend}
                                                                onChange={e =>
                                                                    this.handleChangeChart("legend", "show_legend", e.target.value === "true")
                                                                }
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {legend?.show_legend && (
                                                <>
                                                    <div className="styles-sec">
                                                        <div className="d-flex f-outer">
                                                            <div className="font-family font-st col-md-3">
                                                                <label>Font Name</label>
                                                                <i
                                                                    data-tip="Font Name of X-Axis, Y-Axis, Data Labels and Legends must be the same"
                                                                    data-for="chart_property"
                                                                    className="fas fa-info-circle ml-2"
                                                                />
                                                                <div className="font-select">
                                                                    <select
                                                                        className={`dropdown`}
                                                                        value={chart_font?.font_id}
                                                                        name="font_id"
                                                                        onChange={e =>
                                                                            this.handleChangeChart("chart_font", "font_id", e.target.value)
                                                                        }
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {FONT_FAMILY.map(f => (
                                                                            <option key={f.id} value={f.id}>
                                                                                {f.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="font-size font-st col-md-3">
                                                                <label>Font Size</label>
                                                                <div className={`input-ft-size`}>
                                                                    <NumberFormat
                                                                        value={legend?.font_size}
                                                                        onValueChange={values =>
                                                                            this.handleChangeChart("legend", "font_size", values.floatValue)
                                                                        }
                                                                        allowNegative={false}
                                                                        suffix=" px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="font-color font-st col-md-3">
                                                                <label>Font Color</label>
                                                                <div className={`dropdown`}>
                                                                    <div
                                                                        className="dropdown-toggle"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState:
                                                                                    colorModalState === "legend_font_color"
                                                                                        ? null
                                                                                        : "legend_font_color"
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span>{legend?.font_color || "Select"}</span>
                                                                        <span
                                                                            className="color-bx"
                                                                            style={{
                                                                                background: legend?.font_color ? `#${legend?.font_color}` : "#fff"
                                                                            }}
                                                                        ></span>
                                                                        <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                    </div>
                                                                    {colorModalState === "legend_font_color" && (
                                                                        <div className="z-index-10" style={{ position: "absolute" }}>
                                                                            <CompactPicker
                                                                                className="picker"
                                                                                colors={FONT_COLOR}
                                                                                color={legend?.font_color}
                                                                                onChangeComplete={color =>
                                                                                    this.handleChangeChart(
                                                                                        "legend",
                                                                                        "font_color",
                                                                                        color.hex?.replace("#", "")
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="font-family font-st col-md-3">
                                                                <label>Font Bold</label>
                                                                <div className="font-select">
                                                                    <select
                                                                        className={`dropdown`}
                                                                        name="legend"
                                                                        value={legend?.bold}
                                                                        onChange={e =>
                                                                            this.handleChangeChart("legend", "bold", e.target.value === "true")
                                                                        }
                                                                    >
                                                                        <option value={false}>No</option>
                                                                        <option value={true}>Yes</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="styles-sec">
                                                        <div className="d-flex f-outer">
                                                            <div className="font-color font-st col-md-3">
                                                                <label>Background Color</label>
                                                                <div
                                                                    className={`dropdown ${
                                                                        showErrorBorder && !legend?.backgroundColor ? "error-border" : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className="dropdown-toggle"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState:
                                                                                    colorModalState === "legend_bg_color" ? null : "legend_bg_color"
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span>{legend?.backgroundColor || "Select"}</span>
                                                                        <span
                                                                            className="color-bx"
                                                                            style={{
                                                                                background: legend?.backgroundColor
                                                                                    ? `#${legend?.backgroundColor}`
                                                                                    : "#fff"
                                                                            }}
                                                                        ></span>
                                                                        <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                    </div>
                                                                    {colorModalState === "legend_bg_color" && (
                                                                        <div className="z-index-10" style={{ position: "absolute" }}>
                                                                            <CompactPicker
                                                                                className="picker"
                                                                                colors={FONT_COLOR}
                                                                                color={legend?.backgroundColor}
                                                                                onChangeComplete={color =>
                                                                                    this.handleChangeChart(
                                                                                        "legend",
                                                                                        "backgroundColor",
                                                                                        color.hex?.replace("#", "")
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="font-color font-st col-md-3">
                                                                <label>Border Color</label>
                                                                <div
                                                                    className={`dropdown ${
                                                                        showErrorBorder && !legend?.borderColor ? "error-border" : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className="dropdown-toggle"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState:
                                                                                    colorModalState === "legend_border_color"
                                                                                        ? null
                                                                                        : "legend_border_color"
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span>{legend?.borderColor || "Select"}</span>
                                                                        <span
                                                                            className="color-bx"
                                                                            style={{
                                                                                background: legend?.borderColor ? `#${legend?.borderColor}` : "#fff"
                                                                            }}
                                                                        ></span>
                                                                        <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                    </div>
                                                                    {colorModalState === "legend_border_color" && (
                                                                        <div className="z-index-10" style={{ position: "absolute" }}>
                                                                            <CompactPicker
                                                                                className="picker"
                                                                                colors={FONT_COLOR}
                                                                                color={legend?.borderColor}
                                                                                onChangeComplete={color =>
                                                                                    this.handleChangeChart(
                                                                                        "legend",
                                                                                        "borderColor",
                                                                                        color.hex?.replace("#", "")
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="font-size font-st col-md-3">
                                                                <label>Border Width</label>
                                                                <div className={`input-ft-size`}>
                                                                    <NumberFormat
                                                                        value={legend?.borderWidth}
                                                                        onValueChange={values =>
                                                                            this.handleChangeChart("legend", "borderWidth", values.floatValue)
                                                                        }
                                                                        allowNegative={false}
                                                                        suffix=" px"
                                                                        className={`${showErrorBorder && !legend?.borderWidth ? "error-border" : ""}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="font-size font-st col-md-3">
                                                                <label>Border Radius</label>
                                                                <div className={`input-ft-size`}>
                                                                    <NumberFormat
                                                                        value={legend?.borderRadius}
                                                                        onValueChange={values =>
                                                                            this.handleChangeChart("legend", "borderRadius", values.floatValue)
                                                                        }
                                                                        allowNegative={false}
                                                                        suffix=" px"
                                                                        className={`${
                                                                            showErrorBorder && !legend?.borderRadius ? "error-border" : ""
                                                                        }`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="styles-box">
                                            <h2>Chart Value and % Breakdown (Below the Chart)</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Visible</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                name="tag"
                                                                value={custom_legend?.show_legend}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                custom_legend: {
                                                                                    ...this.state.property.properties.custom_legend,
                                                                                    show_legend: e.target.value === "true"
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {custom_legend?.show_legend ? (
                                                <>
                                                    <div className="styles-sec">
                                                        <div className="d-flex f-outer">
                                                            <div className="font-family font-st col-md-3">
                                                                <label>Header Font Name *</label>
                                                                <div className="font-select">
                                                                    <select
                                                                        className={`dropdown ${
                                                                            showErrorBorder &&
                                                                            custom_legend.show_legend &&
                                                                            !custom_legend?.legend_heading?.font_id
                                                                                ? "error-border"
                                                                                : ""
                                                                        }`}
                                                                        value={custom_legend?.legend_heading?.font_id}
                                                                        name="font_id"
                                                                        onChange={e =>
                                                                            this.handleChangeCustomLegend("legend_heading", "font_id", e.target.value)
                                                                        }
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {FONT_FAMILY.map(f => (
                                                                            <option key={f.id} value={f.id}>
                                                                                {f.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="font-size font-st col-md-3">
                                                                <label>Header Font Size *</label>
                                                                <div className={`input-ft-size`}>
                                                                    <NumberFormat
                                                                        value={custom_legend?.legend_heading?.font_size}
                                                                        onValueChange={values =>
                                                                            this.handleChangeCustomLegend(
                                                                                "legend_heading",
                                                                                "font_size",
                                                                                values.floatValue
                                                                            )
                                                                        }
                                                                        allowNegative={false}
                                                                        suffix=" Pt"
                                                                        className={`${
                                                                            showErrorBorder &&
                                                                            custom_legend.show_legend &&
                                                                            !custom_legend?.legend_heading?.font_size
                                                                                ? "error-border"
                                                                                : ""
                                                                        }`}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="font-color font-st col-md-3">
                                                                <label>Header Font Color *</label>
                                                                <div
                                                                    className={`dropdown ${
                                                                        showErrorBorder && !custom_legend?.legend_heading?.color ? "error-border" : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className="dropdown-toggle"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState:
                                                                                    colorModalState === "custom_legend_heading_color"
                                                                                        ? null
                                                                                        : "custom_legend_heading_color"
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span>{custom_legend?.legend_heading?.color || "Select"}</span>
                                                                        <span
                                                                            className="color-bx"
                                                                            style={{
                                                                                background: custom_legend?.legend_heading?.color
                                                                                    ? `#${custom_legend?.legend_heading?.color}`
                                                                                    : "#fff"
                                                                            }}
                                                                        ></span>
                                                                        <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                    </div>
                                                                    {colorModalState === "custom_legend_heading_color" && (
                                                                        <div className="z-index-10" style={{ position: "absolute" }}>
                                                                            <CompactPicker
                                                                                className="picker"
                                                                                colors={FONT_COLOR}
                                                                                color={custom_legend?.legend_heading?.color}
                                                                                onChangeComplete={color =>
                                                                                    this.handleChangeCustomLegend(
                                                                                        "legend_heading",
                                                                                        "color",
                                                                                        color.hex?.replace("#", "")
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="font-family font-st col-md-3">
                                                                <label>Header Font Bold</label>
                                                                <div className="font-select">
                                                                    <select
                                                                        className={`dropdown`}
                                                                        name="tag"
                                                                        value={custom_legend?.legend_heading?.bold}
                                                                        onChange={e =>
                                                                            this.handleChangeCustomLegend(
                                                                                "legend_heading",
                                                                                "bold",
                                                                                e.target.value === "true"
                                                                            )
                                                                        }
                                                                    >
                                                                        <option value={false}>No</option>
                                                                        <option value={true}>Yes</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex f-outer">
                                                            <div className="font-family font-st col-md-3">
                                                                <label>Header Font Alignment</label>
                                                                <div className="font-select">
                                                                    <select
                                                                        className={`dropdown ${
                                                                            showErrorBorder && !custom_legend?.legend_heading?.alignment
                                                                                ? "error-border"
                                                                                : ""
                                                                        }`}
                                                                        name="tag"
                                                                        value={custom_legend?.legend_heading?.alignment}
                                                                        onChange={e =>
                                                                            this.handleChangeCustomLegend(
                                                                                "legend_heading",
                                                                                "alignment",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    >
                                                                        <option value={"left"}>Left</option>
                                                                        <option value={"center"}>Center</option>
                                                                        <option value={"right"}>Right</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="font-color font-st col-md-3">
                                                                <label>Header Background Color *</label>
                                                                <div
                                                                    className={`dropdown ${
                                                                        showErrorBorder && !custom_legend?.legend_heading?.bg_color
                                                                            ? "error-border"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className="dropdown-toggle"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState:
                                                                                    colorModalState === "custom_legend_heading_bg_color"
                                                                                        ? null
                                                                                        : "custom_legend_heading_bg_color"
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span>{custom_legend?.legend_heading?.bg_color || "Select"}</span>
                                                                        <span
                                                                            className="color-bx"
                                                                            style={{
                                                                                background: custom_legend?.legend_heading?.bg_color
                                                                                    ? `#${custom_legend?.legend_heading?.bg_color}`
                                                                                    : "#fff"
                                                                            }}
                                                                        ></span>
                                                                        <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                    </div>
                                                                    {colorModalState === "custom_legend_heading_bg_color" && (
                                                                        <div className="z-index-10" style={{ position: "absolute" }}>
                                                                            <CompactPicker
                                                                                className="picker"
                                                                                colors={FONT_COLOR}
                                                                                color={custom_legend?.legend_heading?.bg_color}
                                                                                onChangeComplete={color =>
                                                                                    this.handleChangeCustomLegend(
                                                                                        "legend_heading",
                                                                                        "bg_color",
                                                                                        color.hex?.replace("#", "")
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="styles-sec">
                                                        <div className="d-flex f-outer">
                                                            <div className="font-family font-st col-md-3">
                                                                <label>Value Font Name *</label>
                                                                <div className="font-select">
                                                                    <select
                                                                        className={`dropdown ${
                                                                            showErrorBorder &&
                                                                            custom_legend.show_legend &&
                                                                            !custom_legend?.legend_value?.font_id
                                                                                ? "error-border"
                                                                                : ""
                                                                        }`}
                                                                        value={custom_legend?.legend_value?.font_id}
                                                                        name="font_id"
                                                                        onChange={e =>
                                                                            this.handleChangeCustomLegend("legend_value", "font_id", e.target.value)
                                                                        }
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {FONT_FAMILY.map(f => (
                                                                            <option key={f.id} value={f.id}>
                                                                                {f.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="font-size font-st col-md-3">
                                                                <label>Value Font Size *</label>
                                                                <div className={`input-ft-size`}>
                                                                    <NumberFormat
                                                                        value={custom_legend?.legend_value?.font_size}
                                                                        onValueChange={values =>
                                                                            this.handleChangeCustomLegend(
                                                                                "legend_value",
                                                                                "font_size",
                                                                                values.floatValue
                                                                            )
                                                                        }
                                                                        allowNegative={false}
                                                                        suffix=" Pt"
                                                                        className={`${
                                                                            showErrorBorder &&
                                                                            custom_legend.show_legend &&
                                                                            !custom_legend?.legend_value?.font_size
                                                                                ? "error-border"
                                                                                : ""
                                                                        }`}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="font-color font-st col-md-3">
                                                                <label>Value Font Color *</label>
                                                                <div
                                                                    className={`dropdown ${
                                                                        showErrorBorder && !custom_legend?.legend_value?.color ? "error-border" : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className="dropdown-toggle"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState:
                                                                                    colorModalState === "custom_legend_value_color"
                                                                                        ? null
                                                                                        : "custom_legend_value_color"
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span>{custom_legend?.legend_value?.color || "Select"}</span>
                                                                        <span
                                                                            className="color-bx"
                                                                            style={{
                                                                                background: custom_legend?.legend_value?.color
                                                                                    ? `#${custom_legend?.legend_value?.color}`
                                                                                    : "#fff"
                                                                            }}
                                                                        ></span>
                                                                        <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                    </div>
                                                                    {colorModalState === "custom_legend_value_color" && (
                                                                        <div className="z-index-10" style={{ position: "absolute" }}>
                                                                            <CompactPicker
                                                                                className="picker"
                                                                                colors={FONT_COLOR}
                                                                                color={custom_legend?.legend_value?.color}
                                                                                onChangeComplete={color =>
                                                                                    this.handleChangeCustomLegend(
                                                                                        "legend_value",
                                                                                        "color",
                                                                                        color.hex?.replace("#", "")
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="font-family font-st col-md-3">
                                                                <label>Value Font Bold</label>
                                                                <div className="font-select">
                                                                    <select
                                                                        className={`dropdown`}
                                                                        name="tag"
                                                                        value={custom_legend?.legend_value?.bold}
                                                                        onChange={e =>
                                                                            this.handleChangeCustomLegend(
                                                                                "legend_value",
                                                                                "bold",
                                                                                e.target.value === "true"
                                                                            )
                                                                        }
                                                                    >
                                                                        <option value={false}>No</option>
                                                                        <option value={true}>Yes</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex f-outer">
                                                            <div className="font-family font-st col-md-3">
                                                                <label>Value Font Alignment</label>
                                                                <div className="font-select">
                                                                    <select
                                                                        className={`dropdown ${
                                                                            showErrorBorder && !custom_legend?.legend_value?.alignment
                                                                                ? "error-border"
                                                                                : ""
                                                                        }`}
                                                                        name="tag"
                                                                        value={custom_legend?.legend_value?.alignment}
                                                                        onChange={e =>
                                                                            this.handleChangeCustomLegend("legend_value", "alignment", e.target.value)
                                                                        }
                                                                    >
                                                                        <option value={"left"}>Left</option>
                                                                        <option value={"center"}>Center</option>
                                                                        <option value={"right"}>Right</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="font-color font-st col-md-3">
                                                                <label>Value Background Color *</label>
                                                                <div
                                                                    className={`dropdown ${
                                                                        showErrorBorder && !custom_legend?.legend_value?.bg_color
                                                                            ? "error-border"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className="dropdown-toggle"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState:
                                                                                    colorModalState === "custom_legend_value_bg_color"
                                                                                        ? null
                                                                                        : "custom_legend_value_bg_color"
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span>{custom_legend?.legend_value?.bg_color || "Select"}</span>
                                                                        <span
                                                                            className="color-bx"
                                                                            style={{
                                                                                background: custom_legend?.legend_value?.bg_color
                                                                                    ? `#${custom_legend?.legend_value?.bg_color}`
                                                                                    : "#fff"
                                                                            }}
                                                                        ></span>
                                                                        <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                    </div>
                                                                    {colorModalState === "custom_legend_value_bg_color" && (
                                                                        <div className="z-index-10" style={{ position: "absolute" }}>
                                                                            <CompactPicker
                                                                                className="picker"
                                                                                colors={FONT_COLOR}
                                                                                color={custom_legend?.legend_value?.bg_color}
                                                                                onChangeComplete={color =>
                                                                                    this.handleChangeCustomLegend(
                                                                                        "legend_value",
                                                                                        "bg_color",
                                                                                        color.hex?.replace("#", "")
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                        <div className="styles-box">
                                            <h2>Chart Total (Below the Chart)</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Visible</label>
                                                        <div className="font-select">
                                                            <select
                                                                className={`dropdown`}
                                                                name="tag"
                                                                value={total?.show_total}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                total: {
                                                                                    ...this.state.property.properties.total,
                                                                                    show_total: e.target.value === "true"
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {total?.show_total ? (
                                                <div className="styles-sec">
                                                    <div className="d-flex f-outer">
                                                        <div className="font-family font-st col-md-3">
                                                            <label>Font Name *</label>
                                                            <div className="font-select">
                                                                <select
                                                                    className={`dropdown ${
                                                                        showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                                                            ? "error-border"
                                                                            : ""
                                                                    }`}
                                                                    value={total?.font_id}
                                                                    name="font_id"
                                                                    onChange={e => this.handleChangeChart("total", "font_id", e.target.value)}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {FONT_FAMILY.map(f => (
                                                                        <option key={f.id} value={f.id}>
                                                                            {f.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Font Size *</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={total?.font_size}
                                                                    onValueChange={values =>
                                                                        this.handleChangeChart("total", "font_size", values.floatValue)
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" Pt"
                                                                    className={`${
                                                                        showErrorBorder && custom_legend?.show_legend && !total?.font_size
                                                                            ? "error-border"
                                                                            : ""
                                                                    }`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-color font-st col-md-3">
                                                            <label>Font Color *</label>
                                                            <div className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}>
                                                                <div
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            colorModalState: colorModalState === "total_color" ? null : "total_color"
                                                                        });
                                                                    }}
                                                                >
                                                                    <span>{total?.color || "Select"}</span>
                                                                    <span
                                                                        className="color-bx"
                                                                        style={{ background: total?.color ? `#${total?.color}` : "#fff" }}
                                                                    ></span>
                                                                    <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                </div>
                                                                {colorModalState === "total_color" && (
                                                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                                                        <CompactPicker
                                                                            className="picker"
                                                                            colors={FONT_COLOR}
                                                                            color={total?.color}
                                                                            onChangeComplete={color =>
                                                                                this.handleChangeChart("total", "color", color.hex?.replace("#", ""))
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="font-family font-st col-md-3">
                                                            <label>Font Bold</label>
                                                            <div className="font-select">
                                                                <select
                                                                    className={`dropdown`}
                                                                    name="tag"
                                                                    value={total?.bold}
                                                                    onChange={e => this.handleChangeChart("total", "bold", e.target.value === "true")}
                                                                >
                                                                    <option value={false}>No</option>
                                                                    <option value={true}>Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex f-outer">
                                                        <div className="font-family font-st col-md-3">
                                                            <label>Font Alignment</label>
                                                            <div className="font-select">
                                                                <select
                                                                    className={`dropdown ${
                                                                        showErrorBorder && !total?.alignment ? "error-border" : ""
                                                                    }`}
                                                                    name="tag"
                                                                    value={total?.alignment}
                                                                    onChange={e => this.handleChangeChart("total", "alignment", e.target.value)}
                                                                >
                                                                    <option value={"left"}>Left</option>
                                                                    <option value={"center"}>Center</option>
                                                                    <option value={"right"}>Right</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="font-color font-st col-md-3">
                                                            <label>Background Color *</label>
                                                            <div className={`dropdown ${showErrorBorder && !total?.bg_color ? "error-border" : ""}`}>
                                                                <div
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            colorModalState:
                                                                                colorModalState === "total_bg_color" ? null : "total_bg_color"
                                                                        });
                                                                    }}
                                                                >
                                                                    <span>{total?.bg_color || "Select"}</span>
                                                                    <span
                                                                        className="color-bx"
                                                                        style={{ background: total?.bg_color ? `#${total?.bg_color}` : "#fff" }}
                                                                    ></span>
                                                                    <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                </div>
                                                                {colorModalState === "total_bg_color" && (
                                                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                                                        <CompactPicker
                                                                            className="picker"
                                                                            colors={FONT_COLOR}
                                                                            color={total?.bg_color}
                                                                            onChangeComplete={color =>
                                                                                this.handleChangeChart(
                                                                                    "total",
                                                                                    "bg_color",
                                                                                    color.hex?.replace("#", "")
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>

                                        {/* table properties */}
                                        <div className="styles-box">
                                            <h2>Chart Data Table Properties</h2>

                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Table Style </label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${
                                                                //     showErrorBorder && custom_legend?.show_legend && !total?.font_id
                                                                //         ? "error-border"
                                                                //         : ""
                                                                // }`}
                                                                className="dropdown"
                                                                value={table?.table_style_id || "-"}
                                                                // name="table_style"
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    table_style_id: e.target.value
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value="">Select</option>
                                                                {/* <option value="CBRE Table - Emerald Option 2">CBRE Table - Emerald Option 2</option> */}
                                                                {TABLE_STYLE.map(f => (
                                                                    <option value={f.id} key={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Table Column Width (Only for excel export)</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={table?.col_width}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    col_width: Number(values.value)
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>
                                                            Table Page Fit{" "}
                                                            <i
                                                                className="fa fa-info info-ft-st"
                                                                data-for="table_page_fit"
                                                                data-tip="True- Fit table to page layout</br> False- Auto Adjust columns based on content"
                                                            />
                                                        </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                name="tag"
                                                                value={table?.table_page_fit}
                                                                onChange={e => {
                                                                    const value = e.target.value === "true";
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties?.table,
                                                                                    table_page_fit: value
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                {" "}
                                                                <option value="">Select</option>
                                                                <option value={"true"}>True</option>
                                                                <option value={"false"}>False</option>
                                                            </select>
                                                        </div>
                                                        <ReactTooltip
                                                            className="rc-tooltip-custom-class tool-format-bx"
                                                            id="table_page_fit"
                                                            backgroundColor="#007bff"
                                                            place="top"
                                                            effect="solid"
                                                            html={true}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="styles-sec">
                                                    <div className="d-flex f-outer">
                                                        <div className="font-family font-st col-md-3">
                                                            <label>Custom Heading Font Name </label>
                                                            <div className="font-select">
                                                                <select
                                                                    // className={`dropdown ${showErrorBorder && !header.client.font_id ? "error-border" : ""}`}
                                                                    className="dropdown"
                                                                    value={table?.custom_head?.font_id}
                                                                    // name="font_id"
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        custom_head: {
                                                                                            ...this.state.property.properties.table.custom_head,
                                                                                            font_id: e.target.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                >
                                                                    <option value="">Select</option>
                                                                    {FONT_FAMILY.map(f => (
                                                                        <option key={f.id} value={f.id}>
                                                                            {f.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Custom Heading Font Size</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={table?.custom_head?.font_size}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        custom_head: {
                                                                                            ...this.state.property.properties.table.custom_head,
                                                                                            font_size: Number(values.value)
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" Pt"
                                                                    // className={`${
                                                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_size
                                                                    //         ? "error-border"
                                                                    //         : ""
                                                                    // }`}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="font-color font-st col-md-3">
                                                            <label>Custom Heading Font Color </label>
                                                            <div
                                                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                                className="dropdown"
                                                            >
                                                                <div
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            colorModalState:
                                                                                colorModalState === "table_custom_heading_color"
                                                                                    ? null
                                                                                    : "table_custom_heading_color"
                                                                        });
                                                                    }}
                                                                >
                                                                    <span>{table?.custom_head?.font_color || "Select"}</span>
                                                                    <span
                                                                        className="color-bx"
                                                                        style={{
                                                                            background: table?.custom_head?.font_color
                                                                                ? `#${table?.custom_head?.font_color}`
                                                                                : "#fff"
                                                                        }}
                                                                    ></span>
                                                                    <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                </div>
                                                                {colorModalState === "table_custom_heading_color" && (
                                                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                                                        <CompactPicker
                                                                            className="picker"
                                                                            colors={FONT_COLOR}
                                                                            color={table?.custom_head?.font_color}
                                                                            onChangeComplete={color =>
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        properties: {
                                                                                            ...this.state.property.properties,
                                                                                            table: {
                                                                                                ...this.state.property.properties.table,
                                                                                                custom_head: {
                                                                                                    ...this.state.property.properties.table
                                                                                                        .custom_head,
                                                                                                    font_color: color.hex?.replace("#", "")
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="font-color font-st col-md-3">
                                                            <label>Custom Heading Background Color </label>
                                                            <div
                                                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                                className="dropdown"
                                                            >
                                                                <div
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            colorModalState:
                                                                                colorModalState === "table_custom_heading_bg_color"
                                                                                    ? null
                                                                                    : "table_custom_heading_bg_color"
                                                                        });
                                                                    }}
                                                                >
                                                                    <span>{table?.custom_head?.bg_color || "Select"}</span>
                                                                    <span
                                                                        className="color-bx"
                                                                        style={{
                                                                            background: table?.custom_head?.bg_color
                                                                                ? `#${table?.custom_head?.bg_color}`
                                                                                : "#fff"
                                                                        }}
                                                                    ></span>
                                                                    <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                </div>
                                                                {colorModalState === "table_custom_heading_bg_color" && (
                                                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                                                        <CompactPicker
                                                                            className="picker"
                                                                            colors={FONT_COLOR}
                                                                            color={table?.custom_head?.bg_color}
                                                                            onChangeComplete={color =>
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        properties: {
                                                                                            ...this.state.property.properties,
                                                                                            table: {
                                                                                                ...this.state.property.properties.table,
                                                                                                custom_head: {
                                                                                                    ...this.state.property.properties.table
                                                                                                        .custom_head,
                                                                                                    bg_color: color.hex?.replace("#", "")
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="font-family font-st col-md-3">
                                                            <label>Custom Heading Alignment</label>
                                                            <div className="font-select">
                                                                <select
                                                                    // className={`dropdown ${showErrorBorder && !total?.alignment ? "error-border" : ""}`}
                                                                    className="dropdown"
                                                                    name="tag"
                                                                    value={table?.custom_head?.alignment}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        custom_head: {
                                                                                            ...this.state.property.properties.table.custom_head,
                                                                                            alignment: e.target.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                >
                                                                    {" "}
                                                                    <option value="">Select</option>
                                                                    <option value={"left"}>Left</option>
                                                                    <option value={"center"}>Center</option>
                                                                    <option value={"right"}>Right</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="font-size font-st col-md-3">
                                                            <label>Custom Heading Cell Margin Top</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={table?.custom_head?.cell_margin?.top}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        custom_head: {
                                                                                            ...this.state.property.properties.table.custom_head,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.table.custom_head
                                                                                                    .cell_margin,
                                                                                                top: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Custom Heading Cell Margin Left</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={table?.custom_head?.cell_margin?.left}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        custom_head: {
                                                                                            ...this.state.property.properties.table.custom_head,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.table.custom_head
                                                                                                    .cell_margin,
                                                                                                left: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Custom Heading Cell Margin Right</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={table?.custom_head?.cell_margin?.right}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        custom_head: {
                                                                                            ...this.state.property.properties.table.custom_head,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.table.custom_head
                                                                                                    .cell_margin,
                                                                                                right: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Custom Heading Cell Margin Bottom</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={table?.custom_head?.cell_margin?.bottom}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        custom_head: {
                                                                                            ...this.state.property.properties.table.custom_head,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.table.custom_head
                                                                                                    .cell_margin,
                                                                                                bottom: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Custom Heading Blank Line Height</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={table?.custom_head?.blank_line_height}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        custom_head: {
                                                                                            ...this.state.property.properties.table.custom_head,
                                                                                            blank_line_height: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" pt"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="styles-sec">
                                                    <div className="d-flex f-outer">
                                                        <div className="font-family font-st col-md-3">
                                                            <label>Heading Font Name </label>
                                                            <div className="font-select">
                                                                <select
                                                                    // className={`dropdown ${showErrorBorder && !header.client.font_id ? "error-border" : ""}`}
                                                                    className="dropdown"
                                                                    value={table?.heading?.font_id}
                                                                    // name="font_id"
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        heading: {
                                                                                            ...this.state.property.properties.table.heading,
                                                                                            font_id: e.target.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                >
                                                                    <option value="">Select</option>
                                                                    {FONT_FAMILY.map(f => (
                                                                        <option key={f.id} value={f.id}>
                                                                            {f.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Heading Font Size</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={table?.heading?.font_size}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        heading: {
                                                                                            ...this.state.property.properties.table.heading,
                                                                                            font_size: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" Pt"
                                                                    // className={`${
                                                                    //     showErrorBorder && custom_legend?.show_legend && !total?.font_size
                                                                    //         ? "error-border"
                                                                    //         : ""
                                                                    // }`}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="font-color font-st col-md-3">
                                                            <label>Heading Font Color </label>
                                                            <div
                                                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                                className="dropdown"
                                                            >
                                                                <div
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            colorModalState:
                                                                                colorModalState === "table_heading_color"
                                                                                    ? null
                                                                                    : "table_heading_color"
                                                                        });
                                                                    }}
                                                                >
                                                                    <span>{table?.heading?.font_color || "Select"}</span>
                                                                    <span
                                                                        className="color-bx"
                                                                        style={{
                                                                            background: table?.heading?.font_color
                                                                                ? `#${table?.heading?.font_color}`
                                                                                : "#fff"
                                                                        }}
                                                                    ></span>
                                                                    <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                </div>
                                                                {colorModalState === "table_heading_color" && (
                                                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                                                        <CompactPicker
                                                                            className="picker"
                                                                            colors={FONT_COLOR}
                                                                            color={table?.heading?.font_color}
                                                                            onChangeComplete={color =>
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        properties: {
                                                                                            ...this.state.property.properties,
                                                                                            table: {
                                                                                                ...this.state.property.properties.table,
                                                                                                heading: {
                                                                                                    ...this.state.property.properties.table.heading,
                                                                                                    font_color: color.hex?.replace("#", "")
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="font-color font-st col-md-3">
                                                            <label>Heading Background Color </label>
                                                            <div
                                                                // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                                className="dropdown"
                                                            >
                                                                <div
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            colorModalState:
                                                                                colorModalState === "table_heading_bg_color"
                                                                                    ? null
                                                                                    : "table_heading_bg_color"
                                                                        });
                                                                    }}
                                                                >
                                                                    <span>{table?.heading?.bg_color || "Select"}</span>
                                                                    <span
                                                                        className="color-bx"
                                                                        style={{
                                                                            background: table?.heading?.bg_color
                                                                                ? `#${table?.heading?.bg_color}`
                                                                                : "#fff"
                                                                        }}
                                                                    ></span>
                                                                    <i aria-hidden="true" className="grey chevron down icon"></i>
                                                                </div>
                                                                {colorModalState === "table_heading_bg_color" && (
                                                                    <div className="z-index-10" style={{ position: "absolute" }}>
                                                                        <CompactPicker
                                                                            className="picker"
                                                                            colors={FONT_COLOR}
                                                                            color={table?.heading?.bg_color}
                                                                            onChangeComplete={color =>
                                                                                this.setState({
                                                                                    property: {
                                                                                        ...this.state.property,
                                                                                        properties: {
                                                                                            ...this.state.property.properties,
                                                                                            table: {
                                                                                                ...this.state.property.properties.table,
                                                                                                heading: {
                                                                                                    ...this.state.property.properties.table.heading,
                                                                                                    bg_color: color.hex?.replace("#", "")
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="font-family font-st col-md-3">
                                                            <label>Heading Alignment</label>
                                                            <div className="font-select">
                                                                <select
                                                                    // className={`dropdown ${showErrorBorder && !total?.alignment ? "error-border" : ""}`}
                                                                    className="dropdown"
                                                                    name="tag"
                                                                    value={table?.heading?.alignment}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        heading: {
                                                                                            ...this.state.property.properties.table.heading,
                                                                                            alignment: e.target.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                >
                                                                    {" "}
                                                                    <option value="">Select</option>
                                                                    <option value={"left"}>Left</option>
                                                                    <option value={"center"}>Center</option>
                                                                    <option value={"right"}>Right</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="font-size font-st col-md-3">
                                                            <label>Heading Cell Margin Top</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={table?.heading?.cell_margin?.top}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        heading: {
                                                                                            ...this.state.property.properties.table.heading,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.table.heading
                                                                                                    .cell_margin,
                                                                                                top: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Heading Cell Margin Left</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={table?.heading?.cell_margin?.left}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        heading: {
                                                                                            ...this.state.property.properties.table.heading,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.table.heading
                                                                                                    .cell_margin,
                                                                                                left: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Heading Cell Margin Right</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={table?.heading?.cell_margin?.right}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        heading: {
                                                                                            ...this.state.property.properties.table.heading,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.table.heading
                                                                                                    .cell_margin,
                                                                                                right: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="font-size font-st col-md-3">
                                                            <label>Heading Cell Margin Bottom</label>
                                                            <div className={`input-ft-size`}>
                                                                <NumberFormat
                                                                    value={table?.heading?.cell_margin?.bottom}
                                                                    onValueChange={values =>
                                                                        this.setState({
                                                                            property: {
                                                                                ...this.state.property,
                                                                                properties: {
                                                                                    ...this.state.property.properties,
                                                                                    table: {
                                                                                        ...this.state.property.properties.table,
                                                                                        heading: {
                                                                                            ...this.state.property.properties.table.heading,
                                                                                            cell_margin: {
                                                                                                ...this.state.property.properties.table.heading
                                                                                                    .cell_margin,
                                                                                                bottom: values.floatValue
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                    allowNegative={false}
                                                                    suffix=" inch"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Data Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${showErrorBorder && !header.client.font_id ? "error-border" : ""}`}
                                                                className="dropdown"
                                                                value={table?.data?.font_id}
                                                                // name="font_id"
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    data: {
                                                                                        ...this.state.property.properties.table.data,
                                                                                        font_id: e.target.value
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Data Font Size</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={table?.data?.font_size}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    data: {
                                                                                        ...this.state.property.properties.table.data,
                                                                                        font_size: values.floatValue
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                                // className={`${
                                                                //     showErrorBorder && custom_legend?.show_legend && !total?.font_size
                                                                //         ? "error-border"
                                                                //         : ""
                                                                // }`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-color font-st col-md-3">
                                                        <label>Data Font Color </label>
                                                        <div
                                                            // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                            className="dropdown"
                                                        >
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "table_custom_font_color"
                                                                                ? null
                                                                                : "table_custom_font_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{table?.data?.font_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: table?.data?.font_color ? `#${table?.data?.font_color}` : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon"></i>
                                                            </div>
                                                            {colorModalState === "table_custom_font_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={table?.data?.font_color}
                                                                        onChangeComplete={color =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    properties: {
                                                                                        ...this.state.property.properties,
                                                                                        table: {
                                                                                            ...this.state.property.properties.table,
                                                                                            data: {
                                                                                                ...this.state.property.properties.table.data,
                                                                                                font_color: color.hex?.replace("#", "")
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Data Alignment</label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${showErrorBorder && !total?.alignment ? "error-border" : ""}`}
                                                                className="dropdown"
                                                                name="tag"
                                                                value={table?.data?.alignment}
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    data: {
                                                                                        ...this.state.property.properties.table.data,
                                                                                        alignment: e.target.value
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                {" "}
                                                                <option value="">Select</option>
                                                                <option value={"left"}>Left</option>
                                                                <option value={"center"}>Center</option>
                                                                <option value={"right"}>Right</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="font-size font-st col-md-3">
                                                        <label>Data Cell Margin Top</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={table?.data?.cell_margin?.top}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    data: {
                                                                                        ...this.state.property.properties.table.data,
                                                                                        cell_margin: {
                                                                                            ...this.state.property.properties.table.data.cell_margin,
                                                                                            top: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Data Cell Margin Left</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={table?.data?.cell_margin?.left}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    data: {
                                                                                        ...this.state.property.properties.table.data,
                                                                                        cell_margin: {
                                                                                            ...this.state.property.properties.table.data.cell_margin,
                                                                                            left: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Data Cell Margin Right</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={table?.data?.cell_margin?.right}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    data: {
                                                                                        ...this.state.property.properties.table.data,
                                                                                        cell_margin: {
                                                                                            ...this.state.property.properties.table.data.cell_margin,
                                                                                            right: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Data Cell Margin Bottom</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={table?.data?.cell_margin?.bottom}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    data: {
                                                                                        ...this.state.property.properties.table.data,
                                                                                        cell_margin: {
                                                                                            ...this.state.property.properties.table.data.cell_margin,
                                                                                            bottom: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Sub Total Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${showErrorBorder && !header.client.font_id ? "error-border" : ""}`}
                                                                className="dropdown"
                                                                value={table?.sub_total?.font_id}
                                                                // name="font_id"
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    sub_total: {
                                                                                        ...this.state.property.properties.table.sub_total,
                                                                                        font_id: e.target.value
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Sub Total Font Size</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={table?.sub_total?.font_size}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    sub_total: {
                                                                                        ...this.state.property.properties.table.sub_total,
                                                                                        font_size: values.floatValue
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                                // className={`${
                                                                //     showErrorBorder && custom_legend?.show_legend && !total?.font_size
                                                                //         ? "error-border"
                                                                //         : ""
                                                                // }`}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <label>Sub Total Font Color </label>
                                                        <div
                                                            // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                            className="dropdown"
                                                        >
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "table_subtotal_color" ? null : "table_subtotal_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{table?.sub_total?.font_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: table?.sub_total?.font_color
                                                                            ? `#${table?.sub_total?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon"></i>
                                                            </div>
                                                            {colorModalState === "table_subtotal_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={table?.sub_total?.font_color}
                                                                        onChangeComplete={color =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    properties: {
                                                                                        ...this.state.property.properties,
                                                                                        table: {
                                                                                            ...this.state.property.properties.table,
                                                                                            sub_total: {
                                                                                                ...this.state.property.properties.table.sub_total,
                                                                                                font_color: color.hex?.replace("#", "")
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <label>Sub Total Background Color </label>
                                                        <div
                                                            // className={`dropdown ${showErrorBorder && !total?.color ? "error-border" : ""}`}
                                                            className="dropdown"
                                                        >
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "table_sub_total_bg_color"
                                                                                ? null
                                                                                : "table_sub_total_bg_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{table?.sub_total?.bg_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: table?.sub_total?.bg_color
                                                                            ? `#${table?.sub_total?.bg_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon"></i>
                                                            </div>
                                                            {colorModalState === "table_sub_total_bg_color" && (
                                                                <div className="z-index-10" style={{ position: "absolute" }}>
                                                                    <CompactPicker
                                                                        className="picker"
                                                                        colors={FONT_COLOR}
                                                                        color={table?.sub_total?.bg_color}
                                                                        onChangeComplete={color =>
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    properties: {
                                                                                        ...this.state.property.properties,
                                                                                        table: {
                                                                                            ...this.state.property.properties.table,
                                                                                            sub_total: {
                                                                                                ...this.state.property.properties.table.sub_total,
                                                                                                bg_color: color.hex?.replace("#", "")
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="font-family font-st col-md-3">
                                                        <label>Sub Total Alignment</label>
                                                        <div className="font-select">
                                                            <select
                                                                // className={`dropdown ${showErrorBorder && !total?.alignment ? "error-border" : ""}`}
                                                                className="dropdown"
                                                                name="tag"
                                                                value={table?.sub_total?.alignment}
                                                                onChange={e =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    sub_total: {
                                                                                        ...this.state.property.properties.table.sub_total,
                                                                                        alignment: e.target.value
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                {" "}
                                                                <option value="">Select</option>
                                                                <option value={"left"}>Left</option>
                                                                <option value={"center"}>Center</option>
                                                                <option value={"right"}>Right</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="font-size font-st col-md-3">
                                                        <label>Sub Total Cell Margin Top</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={table?.sub_total?.cell_margin?.top}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    sub_total: {
                                                                                        ...this.state.property.properties.table.sub_total,
                                                                                        cell_margin: {
                                                                                            ...this.state.property.properties.table.sub_total
                                                                                                .cell_margin,
                                                                                            top: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Sub Total Cell Margin Left</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={table?.sub_total?.cell_margin?.left}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    sub_total: {
                                                                                        ...this.state.property.properties.table.sub_total,
                                                                                        cell_margin: {
                                                                                            ...this.state.property.properties.table.sub_total
                                                                                                .cell_margin,
                                                                                            left: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Sub Total Cell Margin Right</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={table?.sub_total?.cell_margin?.right}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    sub_total: {
                                                                                        ...this.state.property.properties.table.sub_total,
                                                                                        cell_margin: {
                                                                                            ...this.state.property.properties.table.sub_total
                                                                                                .cell_margin,
                                                                                            right: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Sub Total Cell Margin Bottom</label>
                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={table?.sub_total?.cell_margin?.bottom}
                                                                onValueChange={values =>
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                table: {
                                                                                    ...this.state.property.properties.table,
                                                                                    sub_total: {
                                                                                        ...this.state.property.properties.table.sub_total,
                                                                                        cell_margin: {
                                                                                            ...this.state.property.properties.table.sub_total
                                                                                                .cell_margin,
                                                                                            bottom: values.floatValue
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                allowNegative={false}
                                                                suffix=" inch"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="styles-box">
                                            <h2>Memo Properties</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Apply Memo Field Property</label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={properties?.memo_properties?.memo_props_active}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                memo_properties: {
                                                                                    ...this.state.property.properties.memo_properties,
                                                                                    memo_props_active: e.target.value === "true"
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value={true}>Active</option>
                                                                <option value={false}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Heading Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={properties?.memo_properties?.heading?.font_id || "-"}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                memo_properties: {
                                                                                    ...this.state.property.properties.memo_properties,
                                                                                    heading: {
                                                                                        ...this.state.property.properties.memo_properties?.heading,
                                                                                        font_id: e.target.value
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="font-size font-st col-md-3">
                                                        <label>Heading Font Size</label>

                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={properties?.memo_properties?.heading?.font_size || "-"}
                                                                onValueChange={values => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                memo_properties: {
                                                                                    ...this.state.property.properties.memo_properties,
                                                                                    heading: {
                                                                                        ...this.state.property.properties.memo_properties?.heading,
                                                                                        font_size: values.floatValue
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <div className="head_sub">
                                                            <label>Heading Font Color </label>
                                                        </div>

                                                        <div className="dropdown">
                                                            <span
                                                                className="arrow_close"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                memo_properties: {
                                                                                    ...this.state.property.properties.memo_properties,
                                                                                    heading: {
                                                                                        ...this.state.property.properties.memo_properties?.heading,
                                                                                        font_color: ""
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <i class="fas fa-times"></i>
                                                            </span>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "properties_memo_properties_heading_color"
                                                                                ? null
                                                                                : "properties_memo_properties_heading_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{properties?.memo_properties?.heading?.font_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: properties?.memo_properties?.heading?.font_color
                                                                            ? `#${properties?.memo_properties?.heading?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                            </div>
                                                            {colorModalState === "properties_memo_properties_heading_color" && (
                                                                <div style={styles.popover}>
                                                                    <div
                                                                        style={styles.cover}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState: null
                                                                            });
                                                                        }}
                                                                    />
                                                                    <SketchPicker
                                                                        position="right"
                                                                        color={properties?.memo_properties?.heading?.font_color}
                                                                        presetColors={[
                                                                            "#95cd50",
                                                                            "#ffe242",
                                                                            "#ffa105",
                                                                            "#ff0305",
                                                                            "#0018A8",
                                                                            "#800080",
                                                                            "#3E8EDE",
                                                                            "#417505"
                                                                        ]}
                                                                        onChange={color => {
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    properties: {
                                                                                        ...this.state.property.properties,
                                                                                        memo_properties: {
                                                                                            ...this.state.property.properties.memo_properties,
                                                                                            heading: {
                                                                                                ...this.state.property.properties.memo_properties
                                                                                                    ?.heading,
                                                                                                font_color: color.hex?.replace("#", "")
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Heading Font Bold </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={properties?.memo_properties?.heading?.font_bold || "-"}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                memo_properties: {
                                                                                    ...this.state.property.properties.memo_properties,
                                                                                    heading: {
                                                                                        ...this.state.property.properties.memo_properties?.heading,
                                                                                        font_bold: e.target.value === "true"
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Body Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={properties?.memo_properties?.body?.font_id || "-"}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                memo_properties: {
                                                                                    ...this.state.property.properties.memo_properties,
                                                                                    body: {
                                                                                        ...this.state.property.properties.memo_properties?.body,
                                                                                        font_id: e.target.value
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Body Font Size</label>

                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={properties?.memo_properties?.body?.font_size || "-"}
                                                                onValueChange={values => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                memo_properties: {
                                                                                    ...this.state.property.properties.memo_properties,
                                                                                    body: {
                                                                                        ...this.state.property.properties.memo_properties?.body,
                                                                                        font_size: values?.floatValue
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <div className="head_sub">
                                                            <label>Body Font Color </label>
                                                        </div>

                                                        <div className="dropdown">
                                                            <span
                                                                className="arrow_close"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                memo_properties: {
                                                                                    ...this.state.property.properties.memo_properties,
                                                                                    body: {
                                                                                        ...this.state.property.properties.memo_properties?.body,
                                                                                        font_color: ""
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <i class="fas fa-times"></i>
                                                            </span>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "properties_memo_properties_body_color"
                                                                                ? null
                                                                                : "properties_memo_properties_body_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{properties?.memo_properties?.body?.font_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: properties?.memo_properties?.body?.font_color
                                                                            ? `#${properties?.memo_properties?.body?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                            </div>
                                                            {colorModalState === "properties_memo_properties_body_color" && (
                                                                <div style={styles.popover}>
                                                                    <div
                                                                        style={styles.cover}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState: null
                                                                            });
                                                                        }}
                                                                    />
                                                                    <SketchPicker
                                                                        position="right"
                                                                        color={properties?.memo_properties?.body?.font_color}
                                                                        presetColors={[
                                                                            "#95cd50",
                                                                            "#ffe242",
                                                                            "#ffa105",
                                                                            "#ff0305",
                                                                            "#0018A8",
                                                                            "#800080",
                                                                            "#3E8EDE",
                                                                            "#417505"
                                                                        ]}
                                                                        onChange={color => {
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    properties: {
                                                                                        ...this.state.property.properties,
                                                                                        memo_properties: {
                                                                                            ...this.state.property.properties.memo_properties,
                                                                                            body: {
                                                                                                ...this.state.property.properties.memo_properties
                                                                                                    ?.body,
                                                                                                font_color: color.hex?.replace("#", "")
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Body Font Bold </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={properties?.memo_properties?.body?.font_bold || "-"}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                memo_properties: {
                                                                                    ...this.state.property.properties.memo_properties,
                                                                                    body: {
                                                                                        ...this.state.property.properties.memo_properties?.body,
                                                                                        font_bold: e.target.value === "true"
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="styles-box">
                                            <h2>Memo Properties</h2>
                                            <div className="styles-sec">
                                                <div className="d-flex f-outer">
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Memo Field property Status </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={memo_properties?.memo_props_active || "-"}
                                                                onChange={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                memo_properties: {
                                                                                    ...this.state.property.properties.memo_properties,
                                                                                    memo_props_active: e.target.value === "true"
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Heading Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={memo_properties?.heading?.font_id || "-"}
                                                                onChange={e => this.handleMemo("heading", "font_id", e.target.value)}
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="font-size font-st col-md-3">
                                                        <label>Heading Font Size</label>

                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={memo_properties?.heading?.font_size || "-"}
                                                                onValueChange={values => this.handleMemo("heading", "font_size", values.floatValue)}
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <div className="head_sub">
                                                            <label>Heading Font Color </label>
                                                        </div>

                                                        <div className="dropdown">
                                                            <span
                                                                className="arrow_close"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                memo_properties: {
                                                                                    ...this.state.property.properties.memo_properties,
                                                                                    heading: {
                                                                                        ...this.state.property.properties.memo_properties?.heading,
                                                                                        font_color: ""
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <i class="fas fa-times"></i>
                                                            </span>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "memo_properties_heading_color"
                                                                                ? null
                                                                                : "memo_properties_heading_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{memo_properties?.heading?.font_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: memo_properties?.heading?.font_color
                                                                            ? `#${memo_properties?.heading?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                            </div>
                                                            {colorModalState === "memo_properties_heading_color" && (
                                                                <div style={styles.popover}>
                                                                    <div
                                                                        style={styles.cover}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState: null
                                                                            });
                                                                        }}
                                                                    />
                                                                    <SketchPicker
                                                                        position="right"
                                                                        color={memo_properties?.heading?.font_color}
                                                                        presetColors={[
                                                                            "#95cd50",
                                                                            "#ffe242",
                                                                            "#ffa105",
                                                                            "#ff0305",
                                                                            "#0018A8",
                                                                            "#800080",
                                                                            "#3E8EDE",
                                                                            "#417505"
                                                                        ]}
                                                                        onChange={color => {
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    properties: {
                                                                                        ...this.state.property.properties,
                                                                                        memo_properties: {
                                                                                            ...this.state.property.properties.memo_properties,
                                                                                            heading: {
                                                                                                ...this.state.property.properties.memo_properties
                                                                                                    ?.heading,
                                                                                                font_color: color.hex?.replace("#", "")
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Heading Font Bold </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={memo_properties?.heading?.font_bold || "-"}
                                                                onChange={e => {
                                                                    this.handleMemo("heading", "font_bold", e.target.value === "true");
                                                                }}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Body Font Name </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={memo_properties?.body?.font_id || "-"}
                                                                onChange={e => this.handleMemo("body", "font_id", e.target.value)}
                                                            >
                                                                <option value="">Select</option>
                                                                {FONT_FAMILY.map(f => (
                                                                    <option key={f.id} value={f.id}>
                                                                        {f.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="font-size font-st col-md-3">
                                                        <label>Body Font Size</label>

                                                        <div className={`input-ft-size`}>
                                                            <NumberFormat
                                                                value={memo_properties?.body?.font_size || "-"}
                                                                onValueChange={values => this.handleMemo("body", "font_size", values?.floatValue)}
                                                                allowNegative={false}
                                                                suffix=" Pt"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="font-color font-st col-md-3">
                                                        <div className="head_sub">
                                                            <label>Body Font Color </label>
                                                        </div>

                                                        <div className="dropdown">
                                                            <span
                                                                className="arrow_close"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={e => {
                                                                    this.setState({
                                                                        property: {
                                                                            ...this.state.property,
                                                                            properties: {
                                                                                ...this.state.property.properties,
                                                                                memo_properties: {
                                                                                    ...this.state.property.properties.memo_properties,
                                                                                    body: {
                                                                                        ...this.state.property.properties.memo_properties?.body,
                                                                                        font_color: ""
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                <i class="fas fa-times"></i>
                                                            </span>
                                                            <div
                                                                className="dropdown-toggle"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        colorModalState:
                                                                            colorModalState === "memo_properties_body_color"
                                                                                ? null
                                                                                : "memo_properties_body_color"
                                                                    });
                                                                }}
                                                            >
                                                                <span>{memo_properties?.body?.font_color || "Select"}</span>
                                                                <span
                                                                    className="color-bx"
                                                                    style={{
                                                                        background: memo_properties?.body?.font_color
                                                                            ? `#${memo_properties?.body?.font_color}`
                                                                            : "#fff"
                                                                    }}
                                                                ></span>
                                                                <i aria-hidden="true" className="grey chevron down icon arrow_right"></i>
                                                            </div>
                                                            {colorModalState === "memo_properties_body_color" && (
                                                                <div style={styles.popover}>
                                                                    <div
                                                                        style={styles.cover}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                colorModalState: null
                                                                            });
                                                                        }}
                                                                    />
                                                                    <SketchPicker
                                                                        position="right"
                                                                        color={memo_properties?.body?.font_color}
                                                                        presetColors={[
                                                                            "#95cd50",
                                                                            "#ffe242",
                                                                            "#ffa105",
                                                                            "#ff0305",
                                                                            "#0018A8",
                                                                            "#800080",
                                                                            "#3E8EDE",
                                                                            "#417505"
                                                                        ]}
                                                                        onChange={color => {
                                                                            this.setState({
                                                                                property: {
                                                                                    ...this.state.property,
                                                                                    properties: {
                                                                                        ...this.state.property.properties,
                                                                                        memo_properties: {
                                                                                            ...this.state.property.properties.memo_properties,
                                                                                            body: {
                                                                                                ...this.state.property.properties.memo_properties
                                                                                                    ?.body,
                                                                                                font_color: color.hex?.replace("#", "")
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="font-family font-st col-md-3">
                                                        <label>Body Font Bold </label>
                                                        <div className="font-select">
                                                            <select
                                                                className="dropdown"
                                                                value={memo_properties?.body?.font_bold || "-"}
                                                                onChange={e => {
                                                                    this.handleMemo("body", "font_bold", e.target.value === "true");
                                                                }}
                                                            >
                                                                <option value={false}>No</option>
                                                                <option value={true}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  */}
                                    </>
                                )}
                            </div>

                            <div className="add-btn-wrapper pt-0 mt-2">
                                <>
                                    {showErrorBorder && (
                                        <span className="errorMessage">
                                            {this.state?.isYearValid
                                                ? "* Required fields are missing"
                                                : "Starting year must be less than the ending year"}
                                        </span>
                                    )}
                                    {!isSmartchartView && (
                                        <button className="button ml-2 cursor-hand" onClick={() => this.cancelForm()}>
                                            Cancel
                                        </button>
                                    )}
                                    <button
                                        className={`button btn-save ml-2 cursor-hand`}
                                        onClick={e => {
                                            e.preventDefault();
                                            this.handleSubmit();
                                        }}
                                    >
                                        {this.props.selectedProperty ? "Update Property" : "Add Property"}
                                    </button>
                                </>
                            </div>
                        </div>
                        <ReactTooltip id={`chart_property`} backgroundColor="#007bff" place="top" effect="solid" />
                    </div>
                    <ReactTooltip id={`chart_property`} backgroundColor="#007bff" place="top" effect="solid" />
                </div>
                {this.renderConfirmationModal()}
            </>
        );
    }
}
const mapStateToProps = state => {
    const { chartPropertyReducer, reportPropertyReducer } = state;
    return { chartPropertyReducer, reportPropertyReducer };
};

const { getDropdownList } = reportPropertyActions;

export default withRouter(
    connect(mapStateToProps, {
        ...actions,
        getDropdownList
    })(Form)
);
