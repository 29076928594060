import React from "react";
import Draggable from "react-draggable";
import LoadingOverlay from "react-loading-overlay";
import ReactTooltip from "react-tooltip";
import Loader from "../../../common/components/Loader";
import { tabs } from "./constants";
import useMyFilters from "./useMyFilters";
import "../../../../assets/css/filter-dashboard-modal.css";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import Portal from "../../../common/components/Portal";
import { FilterSearch } from "./FilterSearch";
import { AppliedFilters } from "../../../common/AppliedFilters";
import { Band } from "./Band";
import { FilterItem } from "./FilterItem";
import WildcardIcon from "../../../../assets/img/wildcard-icon.svg";
export const MyFilters = ({ currentFilters, onCancel, submitMasterFilter, tableConfig }) => {
    const {
        showBands,
        showDropdown,
        handleFilterView,
        masterFilterList,
        handleBandClick,
        handleFilterUpdate,
        filterData,
        isDirty,
        handleSubmit,
        activeTab,
        setActiveTab,
        handleCancel,
        showCancelConfirmation,
        onCancelConfirm,
        setShowCancelConfirmation,
        filterFields: initialFields,
        clearAllFilters,
        getFilterCountByBand,
        setShowDropdown,
        setWildCardFilter,
        isLoading,
        fieldSearch,
        setFieldSearch,
        showAppliedFilters,
        setShowAppliedFilters,
        totalFilterCount,
        showWildcard,
        setShowWildcard,
        toggleFavorite,
        favorites,
        showWildcardField,
        setWildcardField,
        showFavoriteRemoveConfirmation,
        setFavoriteRemoveConfirmation,
        onFavoriteRemoveConfirmation
    } = useMyFilters({
        currentFilters,
        submitMasterFilter,
        onCancel
    });

    const renderConfirmationModal = () => {
        if (!showCancelConfirmation) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to cancel and lose all changes?"}
                        type="cancel"
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => setShowCancelConfirmation(false)}
                        onYes={onCancelConfirm}
                    />
                }
                onCancel={() => setShowCancelConfirmation(false)}
            />
        );
    };

    const renderFavoriteRemoveConfirmationModal = () => {
        if (!showFavoriteRemoveConfirmation) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to remove this from Favorites?"}
                        type="cancel"
                        message={"Are you sure?"}
                        onYes={() => onFavoriteRemoveConfirmation()}
                        onNo={() => setFavoriteRemoveConfirmation(false)}
                    />
                }
                onCancel={() => setFavoriteRemoveConfirmation(false)}
            />
        );
    };

    const renderAppliedFilters = () => {
        if (!showAppliedFilters) return null;
        const { filters = {}, ...rest } = filterData || {};
        return (
            <Portal
                body={
                    <AppliedFilters
                        myFilters={{}}
                        filterValues={{ ...currentFilters, filters: filters, list: rest }}
                        showFiltersIcon={false}
                        onCancel={() => setShowAppliedFilters(false)}
                        source="asset"
                        tableConfig={tableConfig}
                    />
                }
                onCancel={() => setShowAppliedFilters(false)}
            />
        );
    };

    let filterFields = initialFields;
    let favFilters = [];

    if (favorites.length) {
        favFilters = filterFields.filter(field => favorites.includes(field.key)).map(fav => ({ ...fav, isFavorite: true }));
    }

    if (fieldSearch && fieldSearch?.trim()?.length) {
        filterFields = filterFields.filter(field => field.label.toLowerCase().includes(fieldSearch.toLowerCase()));
        favFilters = favFilters.filter(field => field.label.toLowerCase().includes(fieldSearch.toLowerCase()));
    }

    return (
        <LoadingOverlay active={isLoading} spinner={<Loader />} fadeSpeed={10}>
            {renderConfirmationModal()}
            {renderAppliedFilters()}
            {renderFavoriteRemoveConfirmationModal()}
            <div className="dtl-sec modal-recom-tab-view col-md-12 mod-filtr-dshb">
                <Draggable handle=".draggable" cancel=".not-draggable">
                    <div className="tab-dtl filters-popup region-mng additional-dtl addition-edit">
                        <div className="close-btn-otr">
                            <button type="button" className="close not-draggable" data-dismiss="modal" aria-label="Close" onClick={handleCancel}>
                                <span aria-hidden="true">
                                    <img src="/img/close.svg" alt="" />
                                </span>
                            </button>
                        </div>
                        <div className="header-mod-top draggable">
                            <h3 className="filter-head">Filters</h3>
                        </div>
                        <div className="tab-active location-sec recom-sec main-dtl-add recommendation-form add-recommendation not-draggable filter-dashboard-popup">
                            <div className="col-md-12 detail-recom add-details-outer">
                                <div className="table-top-menu outer-flx-section">
                                    <ul className={``}>
                                        {Object.keys(tabs).map(tab => (
                                            <li className={`${activeTab === tab ? "active" : ""}`} key={tab} onClick={() => setActiveTab(tab)}>
                                                {tabs[tab].label}
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="clr-btn">
                                        <ReactTooltip
                                            id="recommandation_detils"
                                            effect="solid"
                                            className="rc-tooltip-custom-class"
                                            backgroundColor="#007bff"
                                            place="left"
                                        />
                                        <div className="search search-box-asset mr-2">
                                            <FilterSearch fieldSearch={fieldSearch} setFieldSearch={setFieldSearch} fields={initialFields} />
                                        </div>
                                        {totalFilterCount > 0 && (
                                            <div
                                                className="view view-filter-otr cursor-pointer"
                                                data-tip="Applied Filters"
                                                data-for="recommandation_detils"
                                                data-place="left"
                                                onClick={() => setShowAppliedFilters(true)}
                                            >
                                                <div className="view-inner filter-all">
                                                    <img src="/img/filter.svg" alt="" className={"filtrImg"} />
                                                    <div className="arrow-sec"></div>
                                                    <span className="notifyTxt">{totalFilterCount}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="search search-box-asset mr-2">
                                            <button className="wild-button" onClick={() => setWildcardField(!showWildcardField)}>
                                                <img src={WildcardIcon} alt="" /> Wildcard
                                            </button>
                                        </div>

                                        <button className="btn" onClick={clearAllFilters}>
                                            <i className="fas fa-times mr-2"></i>
                                            Clear All
                                        </button>
                                    </div>
                                </div>
                                <div className="outer-rcm recommendations">
                                    <div className="cnt-sec">
                                        <div className="row">
                                            <div className="col-md-12 pr-0">
                                                <div id="accordion">
                                                    {activeTab === "favorites" ? (
                                                        <div className={`card`}>
                                                            <div className={"collapse show"} aria-labelledby={"heading"}>
                                                                <div className="card-body">
                                                                    <div className="outer-rcm mt-1">
                                                                        {favFilters.map(field => (
                                                                            <FilterItem
                                                                                elem={field}
                                                                                showDropDown={showDropdown}
                                                                                handleFilterView={handleFilterView}
                                                                                filterList={masterFilterList?.[field.key]}
                                                                                key={field.key}
                                                                                filterData={filterData}
                                                                                handleFilterUpdate={handleFilterUpdate}
                                                                                setShowDropdown={setShowDropdown}
                                                                                setWildCardFilter={setWildCardFilter}
                                                                                isAssetFilter
                                                                                showWildcard={showWildcard}
                                                                                setShowWildcard={setShowWildcard}
                                                                                toggleFavorite={toggleFavorite}
                                                                                favorites={favorites}
                                                                                showWildcardField={showWildcardField}
                                                                                setWildcardField={setWildcardField}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {tabs[activeTab].bands
                                                                .filter(band => filterFields.some(field => field.band === band))
                                                                .map(band => (
                                                                    <Band
                                                                        bandId={band?.toLowerCase()?.replace("/", "_").split(" ").join("_")}
                                                                        label={band}
                                                                        showBand={showBands}
                                                                        handleBandClick={handleBandClick}
                                                                        key={band}
                                                                        filteredCount={getFilterCountByBand(band)}
                                                                        MainFields={
                                                                            <>
                                                                                {filterFields
                                                                                    .filter(elem => elem.band === band && !elem.hide)
                                                                                    .filter((elem, index) => index < 3)
                                                                                    .map(field => (
                                                                                        <FilterItem
                                                                                            elem={field}
                                                                                            showDropDown={showDropdown}
                                                                                            handleFilterView={handleFilterView}
                                                                                            filterList={masterFilterList?.[field.key]}
                                                                                            key={field.key}
                                                                                            filterData={filterData}
                                                                                            handleFilterUpdate={handleFilterUpdate}
                                                                                            setShowDropdown={setShowDropdown}
                                                                                            setWildCardFilter={setWildCardFilter}
                                                                                            isAssetFilter
                                                                                            showWildcard={showWildcard}
                                                                                            setShowWildcard={setShowWildcard}
                                                                                            toggleFavorite={toggleFavorite}
                                                                                            favorites={favorites}
                                                                                            showWildcardField={showWildcardField}
                                                                                            setWildcardField={setWildcardField}
                                                                                        />
                                                                                    ))}
                                                                            </>
                                                                        }
                                                                        RestFields={
                                                                            <>
                                                                                {filterFields
                                                                                    .filter(elem => elem.band === band && !elem.hide)
                                                                                    .filter((elem, index) => index >= 3)
                                                                                    .map(field => (
                                                                                        <FilterItem
                                                                                            elem={field}
                                                                                            showDropDown={showDropdown}
                                                                                            handleFilterView={handleFilterView}
                                                                                            filterList={masterFilterList?.[field.key]}
                                                                                            key={field.key}
                                                                                            filterData={filterData}
                                                                                            handleFilterUpdate={handleFilterUpdate}
                                                                                            setShowDropdown={setShowDropdown}
                                                                                            setWildCardFilter={setWildCardFilter}
                                                                                            isAssetFilter
                                                                                            showWildcard={showWildcard}
                                                                                            setShowWildcard={setShowWildcard}
                                                                                            toggleFavorite={toggleFavorite}
                                                                                            favorites={favorites}
                                                                                            showWildcardField={showWildcardField}
                                                                                            setWildcardField={setWildcardField}
                                                                                        />
                                                                                    ))}
                                                                            </>
                                                                        }
                                                                    />
                                                                ))}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ height: "250px", width: "100%" }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-right btnOtr mt-3 mb-2">
                                    <button type="button" className={`btn btn-secondary btnClr`} onClick={handleCancel}>
                                        Cancel
                                    </button>

                                    <button
                                        type="button"
                                        className={`btn btn-primary btnRgion ml-2 ${!isDirty ? "cursor-diabled" : ""}`}
                                        disabled={!isDirty}
                                        onClick={handleSubmit}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
        </LoadingOverlay>
    );
};
