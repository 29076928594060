import React from "react";
import BuildModalHeader from "../../common/components/BuildModalHeader";
import { getFileExtension } from "../../../config/utils";
import { VideoPlayer } from "./VideoPlayer";

function ReportsFullView({ selectedReport, onCancel, hasBBIPreview = false, exportPDF, exportDoc, isExporting = false }) {
    const extension = getFileExtension(selectedReport?.file_name);
    return (
        <React.Fragment>
            <div
                className="modal modal-region helper-modal"
                id="modalId"
                style={{ display: "block" }}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className={`modal-dialog`} role="document">
                    <div className="modal-content">
                        <BuildModalHeader
                            hasBBIPreview={hasBBIPreview}
                            title={selectedReport?.file_name}
                            onCancel={onCancel}
                            exportPDF={exportPDF}
                            exportDoc={exportDoc}
                            isExporting={isExporting}
                            modalClass="helper-modal"
                        />
                        <div className="modal-body region-otr">
                            {extension === "mp4" ? (
                                <VideoPlayer className="helper-iframe" poster={selectedReport?.poster || ""} publicUrl={selectedReport?.url || ""} />
                            ) : (
                                <iframe title="helper-iframe" className="helper-iframe" src={selectedReport?.url} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ReportsFullView;
