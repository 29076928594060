import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import _ from "lodash";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { editorConfiguration } from "../../../../utils/ckEditorUtils";
import ConfirmationModal from "../ConfirmationModal";
import Portal from "../Portal";
import { CkeditorConfiguration } from "../../../smartCharts/CkEditorConfig";
import { MyUploadAdapterPlugin } from "../../../smartCharts/CustomImageUploadPlugin";

export const RichEditorPopup = ({ label, value, onCancel, saveData, key, isReadOnly = false, isSmartchart = false }) => {
    const [data, setData] = useState(value);
    const [initialState] = useState(value);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    useEffect(() => {
        setData(value);
    }, [value]);

    const handleChange = data => {
        setData(data);
    };

    const handleSubmit = () => {
        saveData(data);
        onCancel();
    };

    const cancelForm = () => {
        if (_.isEqual(initialState, data) || isReadOnly) {
            onCancel();
        } else {
            setShowConfirmationModal(true);
        }
    };

    const renderConfirmationModal = () => {
        if (!showConfirmationModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={"Do you want to cancel and lose all changes?"}
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => setShowConfirmationModal(false)}
                        onYes={() => onCancel()}
                        cancel={() => setShowConfirmationModal(false)}
                    />
                }
                onCancel={() => setShowConfirmationModal(false)}
            />
        );
    };

    return (
        <>
            <div
                id="modalId"
                className={`modal modal-region modal-img-magamnt ${
                    isSmartchart ? "modal-text-band" : "modal-report-note"
                } modal-ck-edit modal-ck-editor-note`}
                style={{ display: "block", cursor: "move" }}
            >
                <Draggable cancel=".not-dragabble">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {renderConfirmationModal()}
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={cancelForm}>
                                <span aria-hidden="true">
                                    <img src="/img/Group4.svg" alt="" />
                                </span>
                            </button>
                            <div className="modal-header d-flex align-items-center">
                                <h5 className="mb-0">{label}</h5>
                            </div>

                            <div className="modal-body region-otr not-dragabble">
                                <div className="report-notes-txt  col-md-12 p-0 not-dragabble">
                                    <CKEditor
                                        editor={Editor}
                                        // disabled={isReadOnly}
                                        config={
                                            isReadOnly
                                                ? { ...CkeditorConfiguration, toolbar: [] }
                                                : { ...CkeditorConfiguration, extraPlugins: [MyUploadAdapterPlugin] }
                                        }
                                        id={`${key}-edit-popup`}
                                        data={data || ""}
                                        onReady={editor => {
                                            if (editor) {
                                                editor?.plugins?.get("Notification")?.on("show:warning", (event, data) => {
                                                    event.stop();
                                                });
                                                editor.setData(data || "");
                                            }
                                            if (isReadOnly) {
                                                editor?.enableReadOnlyMode("my-feature-id");
                                            }
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleChange(data);
                                        }}
                                        onBlur={(event, editor) => {}}
                                        onFocus={(event, editor) => {}}
                                    />
                                </div>
                            </div>
                            {!isReadOnly && (
                                <div class="modal-footer">
                                    <div class="btn-otr col-md-12 text-right p-0">
                                        <button type="button" onClick={cancelForm} className="btn btn-primary btnRgion btn-cancel mr-2">
                                            Cancel
                                        </button>
                                        <button type="button" onClick={handleSubmit} className="btn btn-primary btnRgion btn-save-round">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Draggable>
            </div>
        </>
    );
};
