export const GET_TEMPLATES_REQUEST = "GET_TEMPLATES_REQUEST";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAILURE = "GET_TEMPLATES_FAILURE";

export const ADD_TEMPLATE_REQUEST = "ADD_TEMPLATE_REQUEST";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAILURE = "ADD_TEMPLATE_FAILURE";

export const GET_TEMPLATE_BY_ID_REQUEST = "GET_TEMPLATE_BY_ID_REQUEST";
export const GET_TEMPLATE_BY_ID_SUCCESS = "GET_TEMPLATE_BY_ID_SUCCESS";
export const GET_TEMPLATE_BY_ID_FAILURE = "GET_TEMPLATE_BY_ID_FAILURE";

export const UPDATE_TEMPLATE_REQUEST = "UPDATE_TEMPLATE_REQUEST";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_FAILURE = "UPDATE_TEMPLATE_FAILURE";

export const DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILURE = "DELETE_TEMPLATE_FAILURE";

// Update Floor Entity Param
export const UPDATE_TRADE_ENTITY_PARAMS_SUCCESS = "UPDATE_TRADE_ENTITY_PARAMS_SUCCESS";
export const UPDATE_TRADE_ENTITY_PARAMS_FAILURE = "UPDATE_TRADE_ENTITY_PARAMS_FAILURE";

export const EXPORT_TEMPLATE_REQUEST = "EXPORT_TEMPLATE_REQUEST";
export const EXPORT_TEMPLATE_SUCCESS = "EXPORT_TEMPLATE_SUCCESS";
export const EXPORT_TEMPLATE_FAILURE = "EXPORT_TEMPLATE_FAILURE";

export const GET_PROPERTY_DROPDOWN_REQUEST = "GET_PROPERTY_DROPDOWN_REQUEST";
export const GET_PROPERTY_DROPDOWN_SUCCESS = "GET_PROPERTY_DROPDOWN_SUCCESS";
export const GET_PROPERTY_DROPDOWN_FAILURE = "GET_PROPERTY_DROPDOWN_FAILURE";

export const RESTORE_TEMPLATE_REQUEST = "RESTORE_TEMPLATE_REQUEST";
export const RESTORE_TEMPLATE_SUCCESS = "RESTORE_TEMPLATE_SUCCESS";
export const RESTORE_TEMPLATE_FAILURE = "RESTORE_TEMPLATE_FAILURE";

export const ADD_USER_ACTIVITY_LOG_REQUEST = "ADD_USER_ACTIVITY_LOG_REQUEST";
export const ADD_USER_ACTIVITY_LOG_SUCCESS = "ADD_USER_ACTIVITY_LOG_SUCCESS";
export const ADD_USER_ACTIVITY_LOG_FAILURE = "ADD_USER_ACTIVITY_LOG_FAILURE";
