import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { withRouter } from "react-router-dom";

import ReactTooltip from "react-tooltip";
import walletIconn from "../../../assets/img/dashboard/Group 954.svg";
import Table from "../../common/components/Table";
import recommendationsActions from "../actions";
import projectActions from "../../project/actions";
import TableTopIcons from "../../common/components/TableTopIcons";
import chartIcon from "../../../assets/img/dashboard/dsh-chart.svg";
import { Dropdown } from "react-bootstrap";
import { checkPermission } from "../../../config/utils";
import ReactSelect from "react-select";
class RecommendationsMain extends Component {
    state = {
        isCheckedWord: true,
        isCheckedPdf: true,
        show: false,
        showMoreFilter: false,
        isSimpleNarrativeTextWord: true,
        isSimpleNarrativeTextPdf: true
    };

    componentDidMount = () => {
        this.setState({
            showMoreFilter: this.isMoreFiltered()
        });
    };

    assignContent = async () => {
        await this.props.assignProjectModal();
    };
    toggleChangeWord = async (e, check) => {
        if (check === "isCheckedWord") {
            await this.setState({
                isCheckedWord: !e.target.checked
            });
        } else {
            await this.setState({
                isCheckedWord: true
            });
        }
    };

    toggleChangePdf = async (e, check) => {
        if (check === "isCheckedPdf") {
            await this.setState({
                isCheckedPdf: !e.target.checked
            });
        } else {
            await this.setState({
                isCheckedPdf: true
            });
        }
    };

    clearExport = () => {
        this.setState({
            isCheckedPdf: true,
            isCheckedWord: true,
            isSimpleNarrativeTextWord: true,
            isSimpleNarrativeTextPdf: true
        });
    };
    toggleMoreFilter = async data => {
        this.setState({ showMoreFilter: !this.state.showMoreFilter });
    };
    statusOptions = [
        {
            label: "All",
            value: null
        },
        {
            label: "Pending",
            value: "pending"
        },
        {
            label: "Resolved",
            value: "resolved"
        },
        {
            label: "Approved",
            value: "approved"
        },
        {
            label: "All Pending",
            value: "all_pending"
        },
        {
            label: "All Resolved",
            value: "all_resolved"
        },
        {
            label: "All Approved",
            value: "all_approved"
        },
        {
            label: "No QA/QC",
            value: "no_reviews"
        }
    ];
    qaPassedOptions = [
        {
            label: "All",
            value: null
        },
        {
            label: "Qa Passed",
            value: "yes"
        },
        {
            label: "Not Qa Passed",
            value: "no"
        }
    ];

    isMoreFiltered = () => {
        const { tableParams = {} } = this.props;
        if (
            tableParams.image_or_not ||
            tableParams.recommendation_assigned_true ||
            tableParams?.surveyor ||
            tableParams?.infrastructure_request ||
            tableParams?.water ||
            tableParams?.energy ||
            tableParams?.fmp ||
            tableParams?.facility_master_plan ||
            tableParams?.recommendation_type ||
            tableParams?.budget_priority ||
            tableParams?.qa_passed ||
            tableParams?.review_status
        ) {
            return true;
        }
        return false;
    };
    render() {
        const {
            showWildCardFilter,
            paginationParams,
            currentViewAllUsers,
            showViewModal,
            tableData,
            handleGlobalSearch,
            toggleWildCardFilter,
            updateCurrentViewAllUsers,
            handleDeleteRecommendations,
            showEditPage,
            handlePerPageChange,
            handlePageClick,
            showAddForm,
            match: {
                params: { section }
            },
            showInfoPage,
            updateSelectedRow,
            selectedRowId,
            globalSearchKey,
            updateWildCardFilter,
            wildCardFilter,
            handleHideColumn,
            getListForCommonFilterRecommendation,
            updateCommonFilter,
            commonFilter,
            resetAllFilters,
            updateTableSortFilters,
            updateLastSortFilter,
            resetSort,
            tableParams,
            isBuildingLocked,
            handleCutPaste,
            summaryRowData,
            showRestoreModal,
            selectFilterHandler,
            permissions,
            selectFilterHandlerInitiative,
            showAddButton,
            hasExport,
            hasNewlyCreated,
            hasNewlyEdited,
            hasEdit,
            hasDelete,
            hasInfoPage,
            hasFilterByWater,
            hasFilterByEnergy,
            hasFilterByBudgetPriority,
            hasFilterByFmp,
            filterByFmp,
            filterByRecomType,
            filterByBudgetPriority,
            filterByEnergy,
            filterByWater,
            entity,
            exportExcelAllTrades = null,
            exportAllTradesLoading = false,
            exportWordLoading = false,
            selectedRecomIds,
            handleSelectRecom,
            handleSelectAllRecom,
            isBudgetPriority,
            isFullscreen,
            tableRef,
            hasViewMyRecommendation,
            hasFilterByImages,
            hasFilterByMyQa,
            hasFilterByQaPassed,
            filterBySurveyor,
            filterByImages,
            filterByReviews,
            filterByQaPassed,
            filterByIR,
            resetAll,
            isColunmVisibleChanged,
            hasMultiAction,
            handleEditMultiSelectedData,
            showSelectedRecom,
            clearSelection,
            everyItemCheckedPerPage,
            selectWholeRecommendation,
            hasIrRecommendation,
            priorityElementsData = [],
            exportSelectedRecom,
            multiExportPdfLoader,
            multiExportWordLoader,
            exportToWordFile,
            showViewModalExport,
            showViewImport,
            exportToExcelFiles,
            tableDataExportFilters,
            hasRecomTypeFilter,
            isInputMode,
            handleCellFocus,
            handleCellValueChange,
            lineEditingEnabled,
            toggleLineEditing,
            handleColumnPin,
            updateColumnWidthInConfig,
            pinnedColumnsRef,
            isSmartChartView = false,
            createAssetRecommendation,
            openFiltersPopup,
            filteredCount,
            toggleShiftCspModal,
            dashboardFilterParams,
            myFilters,
            toggleAppliedFiltersPopup,
            isCapitalRequest,
            isCrLinkedRecommendations,
            createRecommendationFromCr,
            isTableResetting = false,
            isNormalRecomGroupingLoading,
            resetAllTableConfig
        } = this.props;
        const isEveryItemSelected = selectedRecomIds?.length === paginationParams.totalCount;
        let isConsultancyUser = localStorage.getItem("role") === "consultancy_user";
        let isSuperAdmin = localStorage.getItem("role") === "super_admin";
        let user_role = localStorage.getItem("role");
        let isCRApprover = user_role === "super_admin" || localStorage.getItem("crApprover") === "true";
        return (
            <React.Fragment>
                <div className={`dtl-sec ${isBudgetPriority ? "pad-dtl-sec" : ""} rcom-tot-dtl col-md-12 mb-4`}>
                    <div className="table-top-menu allign-right top-head-wid">
                        <div className="lft">
                            {isBudgetPriority ? null : section !== "initiativeInfo" ? (
                                <select
                                    className={
                                        this.props.selectedDropdown === "active" && section !== "initiativeInfo"
                                            ? `form-control cstm-active ${isSmartChartView ? "disabled-btn" : ""}`
                                            : `form-control ${isSmartChartView ? "disabled-btn" : ""}`
                                    }
                                    value={this.props.selectedDropdown}
                                    onChange={e => selectFilterHandler(e)}
                                    disabled={isSmartChartView}
                                >
                                    <option value="all" className={"cstm-option"}>
                                        All
                                    </option>

                                    <option className={"cstm-option"} value="active">
                                        Active
                                    </option>
                                    {!isCapitalRequest && (
                                        <>
                                            <option className={"cstm-option"} value="on_hold">
                                                On hold
                                            </option>
                                            <option className={"cstm-option"} value="completed">
                                                Completed
                                            </option>
                                            <option className={"cstm-option"} value="locked">
                                                Locked
                                            </option>
                                            <option className={"cstm-option"} value="unlocked">
                                                Unlocked
                                            </option>
                                        </>
                                    )}
                                    <option value="deleted" className={"cstm-option"}>
                                        Deleted
                                    </option>
                                </select>
                            ) : (
                                <select
                                    className={
                                        this.props.selectedDropdownInitiaive === "active" && section !== "initiativeInfo"
                                            ? "form-control cstm-active"
                                            : "form-control"
                                    }
                                    value={this.props.selectedDropdownInitiaive}
                                    onChange={e => selectFilterHandlerInitiative(e)}
                                >
                                    <option value="all" className={"cstm-option"}>
                                        All
                                    </option>
                                    <option className={"cstm-option"} value="assigned">
                                        Assigned
                                    </option>
                                    <option className={"cstm-option"} value="unassigned">
                                        Unassigned
                                    </option>
                                </select>
                            )}
                        </div>
                        {section === "initiativeInfo" ? (
                            <div className="lft pl-2">
                                <select
                                    className={this.props.selectedDropdown === "active" ? "form-control cstm-active" : "form-control"}
                                    value={this.props.selectedDropdown}
                                    onChange={e => selectFilterHandlerInitiative(e)}
                                >
                                    <option className={"cstm-option"} value="allInitiative">
                                        All
                                    </option>
                                    <option className={"cstm-option"} value="active">
                                        Active
                                    </option>
                                    <option className={"cstm-option"} value="on_hold">
                                        On hold
                                    </option>
                                    <option className={"cstm-option"} value="completed">
                                        Completed
                                    </option>
                                    <option value="deleted" className={"cstm-option"}>
                                        Deleted
                                    </option>
                                    <option className={"cstm-option"} value="locked">
                                        Locked
                                    </option>
                                    <option className={"cstm-option"} value="unlocked">
                                        Unlocked
                                    </option>
                                </select>
                            </div>
                        ) : null}
                        <div className="rgt justify-content-between">
                            {isBudgetPriority ? (
                                <div className={"hed-set budgt-head"}>
                                    <div class="tab-btn-dsh">
                                        <button class="btn-cmn btn-txt active">Budget Priorities</button>
                                        <button class="btn-cmn btn-icn">
                                            <img
                                                src={chartIcon}
                                                alt="wlt-icn"
                                                data-place="bottom"
                                                data-effect="solid"
                                                data-background-color="#007bff"
                                                onClick={this.props.toggleSecondChartView}
                                                data-tip="FCI Benchmarking View"
                                                data-for="chrt"
                                            />
                                            <ReactTooltip id="chrt" />
                                        </button>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex filter-dash-otr align-items-center">
                                            <TableTopIcons
                                                tableData={tableData}
                                                globalSearchKey={globalSearchKey}
                                                handleGlobalSearch={handleGlobalSearch}
                                                resetAllFilters={resetAllFilters}
                                                toggleWildCardFilter={toggleWildCardFilter}
                                                showViewModal={showViewModal}
                                                showViewModalExport={showViewModalExport}
                                                showViewImport={showViewImport}
                                                resetSort={resetSort}
                                                tableParams={tableParams}
                                                isExport={isFullscreen ? hasExport : false}
                                                exportTableXl={this.props.exportTableXl}
                                                exportExcelAllTrades={false}
                                                tableLoading={this.props.tableLoading}
                                                exportAllTradesLoading={exportAllTradesLoading}
                                                exportWordLoading={exportWordLoading}
                                                selectFilterHandler={selectFilterHandler}
                                                isSelectFilter={true}
                                                entity={entity}
                                                hasHelp={isFullscreen}
                                                hasView={isFullscreen}
                                                hasNewlyCreated={isFullscreen && hasNewlyCreated}
                                                hasNewlyEdited={isFullscreen && hasNewlyEdited}
                                                updateTableSortFilters={updateTableSortFilters}
                                                updateLastSortFilter={updateLastSortFilter}
                                                hasViewMyRecommendation={isFullscreen && hasViewMyRecommendation}
                                                hasFilterByImages={isFullscreen && hasFilterByImages}
                                                filterBySurveyor={filterBySurveyor}
                                                hasIrRecommendation={isFullscreen && hasIrRecommendation}
                                                filterByImages={filterByImages}
                                                filterByIR={isFullscreen && filterByIR}
                                                resetAll={resetAll}
                                                isColunmVisibleChanged={isColunmVisibleChanged}
                                                showWildCardFilter={showWildCardFilter}
                                                selectedRecomIds={selectedRecomIds}
                                                clearSelection={clearSelection}
                                                toggleMoreFilter={this.toggleMoreFilter}
                                                isMoreFiltered={this.isMoreFiltered}
                                                hasAdditionalFilters={isFullscreen}
                                                isBudgetPriority
                                                isSmartChartView={this.props?.isSmartChartView}
                                                unPinAllColumn={this.props.unPinAllColumn}
                                                isRecommendationTable
                                                handleNormalRecomGrouping={this.props?.handleNormalRecomGrouping}
                                                isNormalRecomGroupingLoading={isNormalRecomGroupingLoading}
                                            />
                                        </div>
                                        <div className="btn-grp remove-when-downloading">
                                            <div
                                                className="fl-srn"
                                                data-place="left"
                                                data-effect="solid"
                                                data-delay-show="500"
                                                data-background-color="#007bff"
                                                data-tip={isFullscreen ? "Back To Normal Screen" : "View Full Screen"}
                                                data-for="budgetView"
                                                onClick={() => this.props.handleFullView()}
                                            >
                                                <img src="/img/restore.svg" alt="" className="set-icon-width" />
                                            </div>
                                            <ReactTooltip id="budgetView" />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <TableTopIcons
                                    tableData={tableData}
                                    globalSearchKey={globalSearchKey}
                                    handleGlobalSearch={handleGlobalSearch}
                                    resetAllFilters={resetAllFilters}
                                    toggleWildCardFilter={toggleWildCardFilter}
                                    showViewModal={showViewModal}
                                    resetSort={resetSort}
                                    tableParams={tableParams}
                                    isExport={hasExport}
                                    exportTableXl={this.props.exportTableXl}
                                    exportExcelAllTrades={exportExcelAllTrades}
                                    tableLoading={this.props.tableLoading}
                                    exportAllTradesLoading={exportAllTradesLoading}
                                    exportWordLoading={exportWordLoading}
                                    selectFilterHandler={selectFilterHandler}
                                    isSelectFilter={true}
                                    entity={entity}
                                    hasNewlyCreated={hasNewlyCreated}
                                    hasNewlyEdited={hasNewlyEdited}
                                    updateTableSortFilters={updateTableSortFilters}
                                    updateLastSortFilter={updateLastSortFilter}
                                    hasViewMyRecommendation={hasViewMyRecommendation}
                                    hasViewExportModal={this.props.hasViewExportModal}
                                    hasFilterByImages={hasFilterByImages}
                                    hasIrRecommendation={hasIrRecommendation}
                                    filterBySurveyor={filterBySurveyor}
                                    filterByImages={filterByImages}
                                    filterByIR={filterByIR}
                                    resetAll={resetAll}
                                    isColunmVisibleChanged={isColunmVisibleChanged}
                                    showWildCardFilter={showWildCardFilter}
                                    selectedRecomIds={selectedRecomIds}
                                    clearSelection={clearSelection}
                                    exportToWord={exportToWordFile}
                                    exportToExcel={exportToExcelFiles}
                                    showViewModalExport={showViewModalExport}
                                    showViewImport={showViewImport}
                                    toggleMoreFilter={this.toggleMoreFilter}
                                    isMoreFiltered={this.isMoreFiltered}
                                    hasAdditionalFilters={true}
                                    hasTableCellEdit={checkPermission("forms", "recommendations", "line_edit")}
                                    toggleLineEditing={toggleLineEditing}
                                    lineEditingEnabled={lineEditingEnabled}
                                    isSmartChartView={this.props?.isSmartChartView}
                                    unPinAllColumn={this.props.unPinAllColumn}
                                    openFiltersPopup={openFiltersPopup}
                                    filteredCount={filteredCount}
                                    dashboardFilterParams={dashboardFilterParams}
                                    myFilters={myFilters}
                                    toggleAppliedFiltersPopup={toggleAppliedFiltersPopup}
                                    isRecommendationTable
                                    handleNormalRecomGrouping={this.props?.handleNormalRecomGrouping}
                                    isNormalRecomGroupingLoading={isNormalRecomGroupingLoading}
                                    resetAllTableConfig={resetAllTableConfig}
                                    children={
                                        <>
                                            {this.props.isImageView ? (
                                                <>
                                                    <button
                                                        className={!selectedRecomIds.length ? "add-btn disabled-btn mr-2" : "add-btn mr-2"}
                                                        disabled={!selectedRecomIds.length}
                                                        onClick={() => this.props.unAassignContent()}
                                                    >
                                                        <i className="fas fa-minus" /> Unassign Image
                                                    </button>
                                                </>
                                            ) : section === "recommendationsinfo" && isCapitalRequest ? null : isCapitalRequest && showAddButton ? (
                                                <button className="add-btn" onClick={() => showAddForm()}>
                                                    <i className="fas fa-plus" /> Add New Capital Request
                                                </button>
                                            ) : isCrLinkedRecommendations ? (
                                                <>
                                                    {/* <button className={"add-btn disabled-btn mr-2"} disabled>
                                                        <i className="fas fa-plus" /> Link Recommendation(s)
                                                    </button> */}
                                                    {isCRApprover && (
                                                        <button className={"add-btn mr-2"} onClick={() => createRecommendationFromCr()}>
                                                            <i className="fas fa-plus" /> Create CR based Recommendation
                                                        </button>
                                                    )}
                                                </>
                                            ) : !this.props.isChartView &&
                                              !isBuildingLocked &&
                                              !isBudgetPriority &&
                                              section !== "assetInfo" &&
                                              section !== "initiativeInfo" &&
                                              !this.props.isSmartChartView &&
                                              showAddButton ? (
                                                <button className="add-btn" onClick={() => showAddForm()}>
                                                    <i className="fas fa-plus" /> Add New Recommendation
                                                </button>
                                            ) : null}
                                            {section === "assetInfo" ? (
                                                <button className="add-btn" onClick={() => createAssetRecommendation()}>
                                                    <i className="fas fa-plus" /> Create Service Life Recommendation
                                                </button>
                                            ) : null}
                                            {section === "initiativeInfo" && this.props.match.params.tab === "recommendation" && (
                                                <>
                                                    {this.props.selectedDropdownInitiaive === "unassigned" ? (
                                                        <button
                                                            className={!this.props.enableButton ? "add-btn mr-2 disabled-btn" : "add-btn mr-2"}
                                                            disabled={!this.props.enableButton}
                                                            onClick={() => this.assignContent()}
                                                        >
                                                            <i className="fas fa-plus" /> Assign Initiative
                                                        </button>
                                                    ) : this.props.selectedDropdownInitiaive === "assigned" ? (
                                                        <button
                                                            className={!this.props.enableButton ? "add-btn disabled-btn" : "add-btn"}
                                                            disabled={!this.props.enableButton}
                                                            onClick={() => this.props.unAassignContent()}
                                                        >
                                                            <i className="fas fa-minus" /> Unassign Initiative
                                                        </button>
                                                    ) : null}
                                                </>
                                            )}
                                        </>
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div className="d-flex wrapper-icon-full">
                        {selectedRecomIds?.length > 0 && (
                            <>
                                <button className={"add-btn ml-2 btn-new-noti"} onClick={e => e.preventDefault()}>
                                    <i className="fas fa-check" />
                                    <span>{selectedRecomIds?.length}</span>
                                </button>
                                <button
                                    className={"add-btn ml-2 btn-new-noti"}
                                    onClick={e => {
                                        e.preventDefault();
                                        clearSelection();
                                    }}
                                >
                                    <i className="fas fa-times-circle mr-1" /> {`Clear`}
                                </button>
                                {!this.props.isSmartChartView && section !== "initiativeInfo" && (
                                    <>
                                        {hasEdit && (
                                            <button className={"add-btn ml-2 btn-new-noti"} onClick={() => handleEditMultiSelectedData()}>
                                                <i className="fas fa-pencil-alt mr-1" /> {`Edit`}
                                            </button>
                                        )}
                                        {isSuperAdmin && (
                                            <button className={"add-btn ml-2 btn-new-noti"} onClick={() => toggleShiftCspModal()}>
                                                <i class="fas fa-exchange-alt mr-1"></i>
                                                {`Shift CSP`}
                                            </button>
                                        )}
                                    </>
                                )}
                                <button
                                    className={"add-btn ml-2 btn-new-noti"}
                                    onClick={e => {
                                        e.preventDefault();
                                        showSelectedRecom();
                                    }}
                                >
                                    <i className="fas fa-eye mr-1" />
                                    {tableParams.recommendation_ids?.length ? " Show All" : " Show"}
                                </button>
                                {!this.props.isSmartChartView ? (
                                    <Dropdown className="export-btn-drop" show={this.state.show}>
                                        <Dropdown.Toggle
                                            id="dropdown-basic"
                                            onClick={() => this.setState({ show: !this.state.show })}
                                            className="add-btn ml-2 btn-new-noti export-btn"
                                        >
                                            {multiExportPdfLoader || multiExportWordLoader ? (
                                                <div className="edit-icn-bx icon-btn-sec export-loader">
                                                    <div className="spinner-border text-primary" role="status"></div>
                                                </div>
                                            ) : (
                                                <i className="fas fa-print mr-1"></i>
                                            )}
                                            Print
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <i className="fas fa-solid fa-file-word mr-1" />
                                                Word
                                            </Dropdown.Item>
                                            <div class="brief-desc flex-wrap print-recom-mode">
                                                <div class="seting-type checkbox-container print-mode">
                                                    <label class="container-checkbox cursor-hand m-0">
                                                        <input
                                                            type="checkbox"
                                                            checked={this.state.isCheckedWord}
                                                            onChange={e => this.toggleChangeWord(e, "isCheckedWordDefault")}
                                                        />
                                                        <span class="checkmark"></span>With Narrative
                                                    </label>
                                                    <label class="container-checkbox cursor-hand m-0">
                                                        <input
                                                            type="checkbox"
                                                            checked={!this.state.isCheckedWord}
                                                            onChange={e => this.toggleChangeWord(e, "isCheckedWord")}
                                                        />
                                                        <span class="checkmark"></span>Without Narrative
                                                    </label>
                                                </div>
                                                {this.state.isCheckedWord && (
                                                    <div class="seting-type checkbox-container print-mode">
                                                        <label class="container-checkbox cursor-hand m-0">
                                                            <input
                                                                type="checkbox"
                                                                checked={this.state.isSimpleNarrativeTextWord}
                                                                onChange={e => this.setState({ isSimpleNarrativeTextWord: true })}
                                                            />
                                                            <span class="checkmark"></span>Simple Narrative Text in table
                                                        </label>
                                                        <label class="container-checkbox cursor-hand m-0">
                                                            <input
                                                                type="checkbox"
                                                                checked={!this.state.isSimpleNarrativeTextWord}
                                                                onChange={e => this.setState({ isSimpleNarrativeTextWord: false })}
                                                            />
                                                            <span class="checkmark"></span>Rich Narrative Text
                                                        </label>
                                                    </div>
                                                )}
                                                <button
                                                    className="dwd-btn mt-3"
                                                    onClick={() => {
                                                        exportSelectedRecom("word", {
                                                            isChecked: this.state.isCheckedWord,
                                                            isSimpleNarrativeText: this.state.isSimpleNarrativeTextWord
                                                        });
                                                        this.clearExport();
                                                        this.setState({ show: false });
                                                    }}
                                                >
                                                    <i className="fas fa-file-download mr-2"></i>
                                                    Download
                                                </button>
                                            </div>

                                            <Dropdown.Item>
                                                <i className="fas fa-solid fa-file-pdf mr-1" />
                                                PDF
                                            </Dropdown.Item>
                                            <div class="brief-desc flex-wrap print-recom-mode">
                                                <div class="seting-type checkbox-container print-mode">
                                                    <label class="container-checkbox cursor-hand m-0">
                                                        <input
                                                            type="checkbox"
                                                            checked={this.state.isCheckedPdf}
                                                            onChange={e => this.toggleChangePdf(e, "isCheckedPdfDefault")}
                                                        />
                                                        <span class="checkmark"></span>With Narrative
                                                    </label>
                                                    <label class="container-checkbox cursor-hand m-0">
                                                        <input
                                                            type="checkbox"
                                                            checked={!this.state.isCheckedPdf}
                                                            onChange={e => this.toggleChangePdf(e, "isCheckedPdf")}
                                                        />
                                                        <span class="checkmark"></span>Without Narrative
                                                    </label>
                                                </div>
                                                {this.state.isCheckedPdf && (
                                                    <div class="seting-type checkbox-container print-mode">
                                                        <label class="container-checkbox cursor-hand m-0">
                                                            <input
                                                                type="checkbox"
                                                                checked={this.state.isSimpleNarrativeTextPdf}
                                                                onChange={e => this.setState({ isSimpleNarrativeTextPdf: true })}
                                                            />
                                                            <span class="checkmark"></span>Simple Narrative Text in table
                                                        </label>
                                                        <label class="container-checkbox cursor-hand m-0">
                                                            <input
                                                                type="checkbox"
                                                                checked={!this.state.isSimpleNarrativeTextPdf}
                                                                onChange={e => this.setState({ isSimpleNarrativeTextPdf: false })}
                                                            />
                                                            <span class="checkmark"></span>Rich Narrative Text
                                                        </label>
                                                    </div>
                                                )}
                                                <button
                                                    className="dwd-btn mt-3"
                                                    onClick={() => {
                                                        exportSelectedRecom("pdf", {
                                                            isChecked: this.state.isCheckedPdf,
                                                            isSimpleNarrativeText: this.state?.isSimpleNarrativeTextPdf
                                                        });
                                                        this.clearExport();
                                                        this.setState({ show: false });
                                                    }}
                                                >
                                                    <i className="fas fa-file-download mr-2"></i>
                                                    Download
                                                </button>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : null}
                                {paginationParams?.totalCount > paginationParams?.perPage && (
                                    <button
                                        className={`add-btn ml-2 btn-new-noti btn-sel-all ${isEveryItemSelected ? "active" : ""}`}
                                        onClick={e => {
                                            e.preventDefault();
                                            selectWholeRecommendation();
                                        }}
                                    >
                                        <i className="fas fa-check-double mr-1" /> {isEveryItemSelected ? "Selected All" : "Select All"}{" "}
                                        <span>{paginationParams?.totalCount}</span>
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                    <div class={`collapse-outer col-md-12 p-0  ${this.state.showMoreFilter ? "tog-cls" : ""} `}>
                        <ul>
                            {hasFilterByWater && this.props.projectReducer?.getProjectByIdResponse?.show_water_band && (
                                <li
                                    className={`${tableParams.water ? "active" : ""}`}
                                    onClick={() => filterByWater()}
                                    data-for="toggle-div"
                                    data-tip={
                                        tableParams.water === "yes"
                                            ? `View Recommendations with NO Water `
                                            : tableParams.water === "no"
                                            ? "Reset Water View Filter"
                                            : `View Recommendation with Water`
                                    }
                                >
                                    <img src="/img/water-icon.svg" alt="" /> Water
                                </li>
                            )}
                            {hasFilterByEnergy && this.props.projectReducer?.getProjectByIdResponse?.show_energy_band && (
                                <li
                                    className={`${tableParams.energy ? "active" : ""}`}
                                    onClick={() => filterByEnergy()}
                                    data-for="toggle-div"
                                    data-tip={
                                        tableParams.energy === "yes"
                                            ? `View Recommendations with NO Energy`
                                            : tableParams.energy === "no"
                                            ? "Reset Energy View Filter"
                                            : `View Recommendation with Energy`
                                    }
                                >
                                    <img src="/img/energy-icn.svg" alt="" /> Energy
                                </li>
                            )}
                            {hasIrRecommendation && (
                                <li
                                    className={`${tableParams.infrastructure_request ? "active" : ""}`}
                                    onClick={() => filterByIR()}
                                    data-for="toggle-div"
                                    data-tip={
                                        tableParams.infrastructure_request === "yes"
                                            ? `View Recommendations with NO Capital Request`
                                            : tableParams.infrastructure_request === "no"
                                            ? "Reset Capital Request View Filter"
                                            : `View Recommendation with Capital Request`
                                    }
                                >
                                    <img src="/img/ir-icon.svg" alt="" /> Capital Request
                                </li>
                            )}

                            {hasViewMyRecommendation && isConsultancyUser && (
                                <li
                                    className={`${tableParams.surveyor ? "active" : ""}`}
                                    onClick={() => filterBySurveyor()}
                                    data-for="toggle-div"
                                    data-tip={tableParams.surveyor ? `View All Recommendations` : `View my SURVEYED Recommendations`}
                                >
                                    <img src="/img/person-icon.svg" alt="" />
                                    Surveyor
                                </li>
                            )}

                            {hasFilterByBudgetPriority && (
                                <li
                                    className={`${tableParams.budget_priority ? "active" : ""}`}
                                    onClick={() => filterByBudgetPriority()}
                                    data-for="toggle-div"
                                    data-tip={
                                        tableParams.budget_priority === "yes"
                                            ? `View Recommendations with NO Budget Priority`
                                            : tableParams.budget_priority === "no"
                                            ? "Reset Budget priority View Filter"
                                            : `View Recommendation with Budget priority`
                                    }
                                >
                                    <img src={walletIconn} alt="" />
                                    Budget Priority
                                </li>
                            )}

                            {hasFilterByFmp && (
                                <li
                                    className={`${tableParams.facility_master_plan ? "active" : ""}`}
                                    onClick={() => filterByFmp()}
                                    data-for="toggle-div"
                                    data-tip={
                                        tableParams.facility_master_plan === "yes"
                                            ? `View Recommendations with NO FMP`
                                            : tableParams.facility_master_plan === "no"
                                            ? "Reset Facility master plan View Filter"
                                            : `View Recommendation with FMP`
                                    }
                                >
                                    <img src="/img/plan-icon.svg" alt="" /> Facility Master Plan
                                </li>
                            )}
                            {hasRecomTypeFilter && (
                                <li
                                    className={`${tableParams.recommendation_type ? "active" : ""}`}
                                    onClick={() => filterByRecomType()}
                                    data-for="toggle-div"
                                    data-tip={
                                        tableParams.recommendation_type === "asset"
                                            ? `View Recommendations with Building Types`
                                            : tableParams.recommendation_type === "building"
                                            ? "Reset Recommendation Type Filter"
                                            : `View Recommendation with Asset Types`
                                    }
                                >
                                    <img src="/img/plan-icon.svg" alt="" /> Recommendation Type
                                </li>
                            )}
                            {hasFilterByImages && (isConsultancyUser || isSuperAdmin) && (
                                <li
                                    className={`${tableParams.image_or_not ? "active" : ""}`}
                                    onClick={() => filterByImages()}
                                    data-for="toggle-div"
                                    data-tip={
                                        tableParams.image_or_not === "true"
                                            ? `View Recommendations with NO images `
                                            : tableParams.image_or_not === "false"
                                            ? "View All Recommendations"
                                            : `View Recommendation with Images`
                                    }
                                >
                                    <img src="/img/img-icon.svg" alt="" /> Image
                                </li>
                            )}
                            {hasFilterByMyQa && (isConsultancyUser || isSuperAdmin) && (
                                <li className={`more-drp-li ${tableParams.review_status ? "active" : ""}`}>
                                    <label class="label-txt" data-for="toggle-div" data-tip="Filter By QA/QC status" data-place="left">
                                        My QA
                                    </label>
                                    <ReactSelect
                                        options={this.statusOptions}
                                        value={this.statusOptions.find(opt => opt.value === tableParams.review_status)}
                                        // isClearable
                                        classNamePrefix="react-select"
                                        onChange={({ value }) => filterByReviews(value)}
                                    />
                                </li>
                            )}
                            {hasFilterByQaPassed && (isConsultancyUser || isSuperAdmin) && (
                                <li className={`more-drp-li ${tableParams.qa_passed ? "active" : ""}`}>
                                    {/* <img src="/img/qa-passed.svg" alt="" />  */}
                                    <label class="label-txt" data-for="toggle-div" data-tip="Filter By QA Passed" data-place="left">
                                        QA Passed
                                    </label>
                                    <ReactSelect
                                        options={this.qaPassedOptions}
                                        value={this.qaPassedOptions.find(opt => opt.value === tableParams.qa_passed)}
                                        // isClearable
                                        classNamePrefix="react-select"
                                        onChange={({ value }) => filterByQaPassed(value)}
                                    />
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className={`recommendations-table ${this.state.showMoreFilter ? "more-inf-added" : ""} `}>
                        {isTableResetting ? (
                            <div className="table-section table-scroll table-gen-overflow overflow-hght no-data-table">
                                <table className="table table-common table-min-height"></table>
                            </div>
                        ) : (
                            <Table
                                updateCurrentViewAllUsers={updateCurrentViewAllUsers}
                                tableData={tableData}
                                currentViewAllUsers={currentViewAllUsers}
                                handleDeleteItem={handleDeleteRecommendations}
                                showWildCardFilter={showWildCardFilter}
                                showEditPage={showEditPage}
                                showInfoPage={showInfoPage}
                                updateSelectedRow={updateSelectedRow}
                                selectedRowId={selectedRowId}
                                updateWildCardFilter={updateWildCardFilter}
                                wildCardFilter={wildCardFilter}
                                handleHideColumn={handleHideColumn}
                                getListForCommonFilter={getListForCommonFilterRecommendation}
                                updateCommonFilter={updateCommonFilter}
                                commonFilter={commonFilter}
                                hasActionColumn={
                                    !(
                                        this.props.isChartView ||
                                        this.props.isAssetView ||
                                        (isBudgetPriority && !isFullscreen) ||
                                        this.props.isSmartChartView
                                    )
                                }
                                updateTableSortFilters={updateTableSortFilters}
                                tableParams={tableParams}
                                isBuildingLocked={this.props.isBuildingLocked}
                                handleCutPaste={handleCutPaste}
                                summaryRowData={summaryRowData}
                                showRestoreModal={showRestoreModal}
                                permissions={permissions}
                                handleSelect={this.props.handleSelect}
                                handleSelectAll={this.props.handleSelectAll}
                                recomentationIds={this.props.recomentationIds}
                                currentPage={paginationParams.currentPage}
                                selectedAllClicked={this.state.selectedAll}
                                resetSelect={this.resetSelect}
                                hasEdit={hasEdit}
                                hasDelete={hasDelete}
                                hasInfoPage={hasInfoPage}
                                selectedRecomIds={selectedRecomIds}
                                handleSelectRecom={handleSelectRecom}
                                handleSelectAllRecom={handleSelectAllRecom}
                                isBudgetPriority={isBudgetPriority}
                                isFullscreen={isFullscreen}
                                tableRef={tableRef}
                                hasMultiAction={hasMultiAction}
                                everyItemCheckedPerPage={everyItemCheckedPerPage}
                                priorityElementsData={priorityElementsData}
                                exportFilters={tableDataExportFilters}
                                isInputMode={isInputMode}
                                handleCellFocus={handleCellFocus}
                                handleCellValueChange={handleCellValueChange}
                                handleColumnPin={handleColumnPin}
                                updateColumnWidthInConfig={updateColumnWidthInConfig}
                                pinnedColumnsRef={pinnedColumnsRef}
                                hasPin={true}
                                lineEditingEnabled={lineEditingEnabled}
                                isSmartChartView={this.props.isSmartChartView}
                                additionalTableClass={section === "imageInfo" ? "table-img-filheight" : ""}
                                lineEditDropdownValue={this.props?.lineEditDropdownValue}
                                isDropdownLoading={this.props?.isDropdownLoading}
                            />
                        )}
                        {tableData.data && tableData.data.length ? (
                            <div className="table-bottom d-flex">
                                <div className="count d-flex col-md-6">
                                    <div className="count-dtl">
                                        Total Count: <span>{paginationParams.totalCount}</span>
                                    </div>
                                    <div className="col-md-2 pr-2 selbx">
                                        <select className="form-control" value={paginationParams.perPage} onChange={e => handlePerPageChange(e)}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="40">40</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="150">150</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination-sec col-md-6">
                                    <ReactPaginate
                                        previousLabel={
                                            <span data-place="top" data-effect="solid" data-tip={`Previous`} data-background-color="#007bff">
                                                &lt;
                                            </span>
                                        }
                                        nextLabel={
                                            <span data-place="top" data-effect="solid" data-tip={`Next`} data-background-color="#007bff">
                                                &gt;
                                            </span>
                                        }
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={paginationParams.totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={isBudgetPriority ? 1 : 5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        activeLinkClassName={"active"}
                                        forcePage={paginationParams.currentPage}
                                    />
                                    <ReactTooltip />
                                </div>
                            </div>
                        ) : null}
                    </div>{" "}
                    <ReactTooltip id="toggle-div" effect="solid" className="rc-tooltip-custom-class" place="top" backgroundColor="#007bff" />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { siteReducer, projectReducer } = state;
    return { siteReducer, projectReducer };
};

export default withRouter(connect(mapStateToProps, { ...recommendationsActions, ...projectActions })(RecommendationsMain));
