import * as actionTypes from "./constants";
import * as Service from "./services";

const loginUser = () => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.LOGIN_REQUEST });
            const res = await Service.getUserDetails();
            if (res && res.status === 200) {
                const loginData = res.data;
                dispatch({ type: actionTypes.LOGIN_SUCCESS, response: loginData });
            } else {
                try {
                    const checkValidJson = JSON.parse(res.response?.data?.error);
                    dispatch({
                        type: actionTypes.LOGIN_PASSWORD_RESET,
                        reset: { reset: checkValidJson?.reset, reset_token: checkValidJson?.reset_token }
                    });
                    return;
                } catch (err) {
                    console.log(err);
                }
                dispatch({ type: actionTypes.LOGIN_FAILURE, error: res.response && res.response.data });
            }
        } catch (e) {
            try {
                const checkValidJson = JSON.parse(e.response?.data?.error);
                dispatch({
                    type: actionTypes.LOGIN_PASSWORD_RESET,
                    reset: { reset: checkValidJson?.reset, reset_token: checkValidJson?.reset_token }
                });
                return;
            } catch (err) {
                console.log(err);
            }
            dispatch({ type: actionTypes.LOGIN_FAILURE, error: e.response && e.response.data });
        }
    };
};
const loginUserPython = param => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.LOGIN_REQUEST_PYTHON });
            const res = await Service.loginUserPython(param);
            if (res && res.status === 200) {
                const loginData = res.data;
                if (loginData?.AuthenticationResult?.AccessToken || loginData) {
                    dispatch({ type: actionTypes?.LOGIN_SUCCESS_PYTHON, response: loginData });
                } else {
                    dispatch({ type: actionTypes.LOGIN_FAILURE_PYTHON, error: loginData });
                }
            } else {
                dispatch({ type: actionTypes.LOGIN_FAILURE_PYTHON, error: res?.response?.data?.error || res.message });
            }
        } catch (e) {
            console.log(e);
            dispatch({ type: actionTypes.LOGIN_FAILURE_PYTHON, error: e.response && e.response.data });
        }
    };
};

const logoutUser = () => {
    const refreshToken = { username: localStorage.getItem("userMail"), refresh_token: localStorage.getItem("refresh-token") };
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.LOGOUT_REQUEST });
            const revokeRes = await Service.revokeToken(refreshToken);
            if (revokeRes && revokeRes.status === 200) {
                const codeData = revokeRes.data;
                dispatch({ type: actionTypes.LOGOUT_SUCCESS, response: codeData });
            } else {
                dispatch({ type: actionTypes.LOGOUT_FAILURE, error: revokeRes.response.data });
            }
        } catch (e) {
            dispatch({ type: actionTypes.LOGOUT_FAILURE, error: e.response && e.response.data });
        }
    };
};

const forgotPassword = param => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.FORGOT_PASSWORD_REQUEST });
            const res = await Service.forgotPassword(param);
            if (res && res.status === 200) {
                const codeData = res.data;
                if (codeData.success) {
                    dispatch({ type: actionTypes.FORGOT_PASSWORD_SUCCESS, response: codeData });
                } else {
                    dispatch({ type: actionTypes.FORGOT_PASSWORD_FAILURE, error: codeData });
                }
            } else {
                dispatch({ type: actionTypes.FORGOT_PASSWORD_FAILURE, error: res.response.data });
            }
        } catch (e) {
            dispatch({ type: actionTypes.FORGOT_PASSWORD_FAILURE, error: e.response && e.response.data });
        }
    };
};

const resetPassword = param => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.RESET_PASSWORD_REQUEST });
            const res = await Service.resetPassword(param);
            if (res && res.status === 200) {
                const codeData = res.data;
                if (codeData.success) {
                    dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS, response: codeData });
                } else {
                    dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE, error: codeData });
                }
            } else {
                dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE, error: res.response.data });
            }
        } catch (e) {
            dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE, error: e.response && e.response.data });
        }
    };
};
const CognitoLoginOTP = param => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.COGNITO_LOGIN_OTP_REQUEST });
            const res = await Service.CognitoLoginOTP(param);
            if (res && res.status === 200) {
                const loginData = res.data;
                if (loginData?.AuthenticationResult?.AccessToken) {
                    dispatch({ type: actionTypes.COGNITO_LOGIN_OTP_SUCCESS, response: loginData });
                } else {
                    dispatch({ type: actionTypes.COGNITO_LOGIN_OTP_FAILURE, error: loginData });
                }
            } else {
                dispatch({ type: actionTypes.COGNITO_LOGIN_OTP_FAILURE, error: res.response.data });
            }
        } catch (e) {
            dispatch({ type: actionTypes.COGNITO_LOGIN_OTP_FAILURE, error: e.response && e.response.data });
        }
    };
};

const resetPasswordProfile = (id, param) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.RESET_PASSWORD_REQUEST });
            const res = await Service.resetPasswordProfile(id, param);
            if (res && res.status === 200) {
                const codeData = res.data;
                if (codeData.success) {
                    dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS, response: codeData });
                } else {
                    dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE, error: codeData });
                }
            } else {
                dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE, error: res.response.data });
            }
        } catch (e) {
            dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE, error: e.response && e.response.data });
        }
    };
};
const validateToken = token => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.VALIDATE_TOKEN_REQUEST });
            const res = await Service.validateToken(token);
            if (res && res.status === 200) {
                const codeData = res.data;
                if (codeData.success) {
                    dispatch({ type: actionTypes.VALIDATE_TOKEN_SUCCESS, response: codeData });
                } else {
                    dispatch({ type: actionTypes.VALIDATE_TOKEN_FAILURE, error: codeData });
                }
            } else {
                dispatch({ type: actionTypes.VALIDATE_TOKEN_FAILURE, error: res.response.data });
            }
        } catch (e) {
            dispatch({ type: actionTypes.VALIDATE_TOKEN_FAILURE, error: e.response && e.response.data });
        }
    };
};

const actions = {
    loginUser,
    logoutUser,
    forgotPassword,
    resetPassword,
    resetPasswordProfile,
    validateToken,
    loginUserPython,
    CognitoLoginOTP
};
export default actions;
