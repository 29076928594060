// Get all sites
export const GET_ALL_SITES_REQUEST = "GET_ALL_SITES_REQUEST";
export const GET_ALL_SITES_SUCCESS = "GET_ALL_SITES_SUCCESS";
export const GET_ALL_SITES_FAILURE = "GET_ALL_SITES_FAILURE";

// Add site
export const ADD_SITE_REQUEST = "ADD_SITE_REQUEST";
export const ADD_SITE_SUCCESS = "ADD_SITE_SUCCESS";
export const ADD_SITE_FAILURE = "ADD_SITE_FAILURE";

// Update site
export const UPDATE_SITE_REQUEST = "UPDATE_SITE_REQUEST";
export const UPDATE_SITE_SUCCESS = "UPDATE_SITE_SUCCESS";
export const UPDATE_SITE_FAILURE = "UPDATE_SITE_FAILURE";

// Delete site
export const DELETE_SITE_REQUEST = "DELETE_SITE_REQUEST";
export const DELETE_SITE_SUCCESS = "DELETE_SITE_SUCCESS";
export const DELETE_SITE_FAILURE = "DELETE_SITE_FAILURE";

// Ger region based on client
export const GET_REGIONS_BASED_ON_CLIENT_REQUEST = "GET_REGIONS_BASED_ON_CLIENT_REQUEST";
export const GET_REGIONS_BASED_ON_CLIENT_SUCCESS = "GET_REGIONS_BASED_ON_CLIENT_SUCCESS";
export const GET_REGIONS_BASED_ON_CLIENT_FAILURE = "GET_REGIONS_BASED_ON_CLIENT_FAILURE";

// Get all consultancy users
export const GET_ALL_CONSULTANCY_USERS_REQUEST = "GET_ALL_CONSULTANCY_USERS_REQUEST";
export const GET_ALL_CONSULTANCY_USERS_SUCCESS = "GET_ALL_CONSULTANCY_USERS_SUCCESS";
export const GET_ALL_CONSULTANCY_USERS_FAILURE = "GET_ALL_CONSULTANCY_USERS_FAILURE";

// Get all Clients
export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const GET_ALL_CLIENTS_FAILURE = "GET_ALL_CLIENTS_FAILURE";

// Get Site by Id
export const GET_SITE_BY_ID_REQUEST = "GET_SITE_BY_ID_REQUEST";
export const GET_SITE_BY_ID_SUCCESS = "GET_SITE_BY_ID_SUCCESS";
export const GET_SITE_BY_ID_FAILURE = "GET_SITE_BY_ID_FAILURE";

// Upload Image
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

// Get all Images
export const GET_ALL_IMAGES_REQUEST = "GET_ALL_IMAGES_REQUEST";
export const GET_ALL_IMAGES_SUCCESS = "GET_ALL_IMAGES_SUCCESS";
export const GET_ALL_IMAGES_FAILURE = "GET_ALL_IMAGES_FAILURE";

// Delete Images
export const DELETE_IMAGES_REQUEST = "DELETE_IMAGES_REQUEST";
export const DELETE_IMAGES_SUCCESS = "DELETE_IMAGES_SUCCESS";
export const DELETE_IMAGES_FAILURE = "DELETE_IMAGES_FAILURE";

// Update Comments Images
export const UPDATE_IMAGE_COMMENT_REQUEST = "UPDATE_IMAGE_COMMENT_REQUEST";
export const UPDATE_IMAGE_COMMENT_SUCCESS = "UPDATE_IMAGE_COMMENT_SUCCESS";
export const UPDATE_IMAGE_COMMENT_FAILURE = "UPDATE_IMAGE_COMMENT_FAILURE";

// Update Site Entity Params
export const UPDATE_SITE_ENTITY_PARAMS_SUCCESS = "UPDATE_SITE_ENTITY_PARAMS_SUCCESS";
export const UPDATE_SITE_ENTITY_PARAMS_FAILURE = "UPDATE_SITE_ENTITY_PARAMS_FAILURE";

// get chart information
export const GET_CHART_REQUEST = "GET_CHART_REQUEST";
export const GET_CHART_SUCCESS = "GET_CHART_SUCCESS";
export const GET_CHART_FAILURE = "GET_CHART_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

// Get EFCI based on Site under Project
export const GET_EFCI_BY_SITE_REQUEST = "GET_EFCI_BY_SITE_REQUEST";
export const GET_EFCI_BY_SITE_SUCCESS = "GET_EFCI_BY_SITE_SUCCESS";
export const GET_EFCI_BY_SITE_FAILURE = "GET_EFCI_BY_SITE_FAILURE";

export const UPDATE_CAPITAL_SPENDING_PLAN_REQUEST = "UPDATE_CAPITAL_SPENDING_PLAN_REQUEST";
export const UPDATE_CAPITAL_SPENDING_PLAN_SUCCESS = "UPDATE_CAPITAL_SPENDING_PLAN_SUCCESS";
export const UPDATE_CAPITAL_SPENDING_PLAN_FAILURE = "UPDATE_CAPITAL_SPENDING_PLAN_FAILURE";

export const UPDATE_FUNDING_OPTION_REQUEST = "UPDATE_FUNDING_OPTION_REQUEST";
export const UPDATE_FUNDING_OPTION_SUCCESS = "UPDATE_FUNDING_OPTION_SUCCESS";
export const UPDATE_FUNDING_OPTION_FAILURE = "UPDATE_FUNDING_OPTION_FAILURE";

export const UPDATE_ANNUAL_EFCI_REQUEST = "UPDATE_ANNUAL_EFCI_REQUEST";
export const UPDATE_ANNUAL_EFCI_SUCCESS = "UPDATE_ANNUAL_EFCI_SUCCESS";
export const UPDATE_ANNUAL_EFCI_FAILURE = "UPDATE_ANNUAL_EFCI_FAILURE";

export const UPDATE_ANNUAL_FUNDING_REQUEST = "UPDATE_ANNUAL_FUNDING_REQUEST";
export const UPDATE_ANNUAL_FUNDING_SUCCESS = "UPDATE_ANNUAL_FUNDING_SUCCESS";
export const UPDATE_ANNUAL_FUNDING_FAILURE = "UPDATE_ANNUAL_FUNDING_FAILURE";

export const UPDATE_FUNDING_EFCI_REQUEST = "UPDATE_FUNDING_EFCI_REQUEST";
export const UPDATE_FUNDING_EFCI_SUCCESS = "UPDATE_FUNDING_EFCI_SUCCESS";
export const UPDATE_FUNDING_EFCI_FAILURE = "UPDATE_FUNDING_EFCI_FAILURE";

export const HIDE_FUNDING_OPTION_REQUEST = "HIDE_FUNDING_OPTION_REQUEST";
export const HIDE_FUNDING_OPTION_SUCCESS = "HIDE_FUNDING_OPTION_SUCCESS";
export const HIDE_FUNDING_OPTION_FAILURE = "HIDE_FUNDING_OPTION_FAILURE";

export const RESET_EFCI_REQUEST = "RESET_EFCI_REQUEST";
export const RESET_EFCI_SUCCESS = "RESET_EFCI_SUCCESS";
export const RESET_EFCI_FAILURE = "RESET_EFCI_FAILURE";

export const SAVE_EFCI_REQUEST = "SAVE_EFCI_REQUEST";
export const SAVE_EFCI_SUCCESS = "SAVE_EFCI_SUCCESS";
export const SAVE_EFCI_FAILURE = "SAVE_EFCI_FAILURE";

export const LOAD_EFCI_REQUEST = "LOAD_EFCI_REQUEST";
export const LOAD_EFCI_SUCCESS = "LOAD_EFCI_SUCCESS";
export const LOAD_EFCI_FAILURE = "LOAD_EFCI_FAILURE";

// get chart efci information
export const GET_CHART_EFCI_REQUEST = "GET_CHART_EFCI_REQUEST";
export const GET_CHART_EFCI_SUCCESS = "GET_CHART_EFCI_SUCCESS";
export const GET_CHART_EFCI_FAILURE = "GET_CHART_EFCI_FAILURE";

export const UPDATE_CAPITAL_SPENDING_PLAN_CHART_REQUEST = "UPDATE_CAPITAL_SPENDING_PLAN_CHART_REQUEST";
export const UPDATE_CAPITAL_SPENDING_PLAN_CHART_SUCCESS = "UPDATE_CAPITAL_SPENDING_PLAN_CHART_SUCCESS";
export const UPDATE_CAPITAL_SPENDING_PLAN_CHART_FAILURE = "UPDATE_CAPITAL_SPENDING_PLAN_CHART_FAILURE";

export const UPDATE_FUNDING_OPTION_CHART_REQUEST = "UPDATE_FUNDING_OPTION_CHART_REQUEST";
export const UPDATE_FUNDING_OPTION_CHART_SUCCESS = "UPDATE_FUNDING_OPTION_CHART_SUCCESS";
export const UPDATE_FUNDING_OPTION_CHART_FAILURE = "UPDATE_FUNDING_OPTION_CHART_FAILURE";

export const UPDATE_ANNUAL_EFCI_CHART_REQUEST = "UPDATE_ANNUAL_EFCI_CHART_REQUEST";
export const UPDATE_ANNUAL_EFCI_CHART_SUCCESS = "UPDATE_ANNUAL_EFCI_CHART_SUCCESS";
export const UPDATE_ANNUAL_EFCI_CHART_FAILURE = "UPDATE_ANNUAL_EFCI_CHART_FAILURE";

export const UPDATE_ANNUAL_FUNDING_CHART_REQUEST = "UPDATE_ANNUAL_FUNDING_CHART_REQUEST";
export const UPDATE_ANNUAL_FUNDING_CHART_SUCCESS = "UPDATE_ANNUAL_FUNDING_CHART_SUCCESS";
export const UPDATE_ANNUAL_FUNDING_CHART_FAILURE = "UPDATE_ANNUAL_FUNDING_CHART_FAILURE";

export const UPDATE_FUNDING_EFCI_CHART_REQUEST = "UPDATE_FUNDING_EFCI_CHART_REQUEST";
export const UPDATE_FUNDING_EFCI_CHART_SUCCESS = "UPDATE_FUNDING_EFCI_CHART_SUCCESS";
export const UPDATE_FUNDING_EFCI_CHART_FAILURE = "UPDATE_FUNDING_EFCI_CHART_FAILURE";

export const HIDE_FUNDING_OPTION_CHART_REQUEST = "HIDE_FUNDING_OPTION_CHART_REQUEST";
export const HIDE_FUNDING_OPTION_CHART_SUCCESS = "HIDE_FUNDING_OPTION_CHART_SUCCESS";
export const HIDE_FUNDING_OPTION_CHART_FAILURE = "HIDE_FUNDING_OPTION_CHART_FAILURE";

export const GET_SITE_EXPORT_REQUEST = "GET_SITE_EXPORT_REQUEST";
export const GET_SITE_EXPORT_SUCCESS = "GET_SITE_EXPORT_SUCCESS";
export const GET_SITE_EXPORT_FAILURE = "GET_SITE_EXPORT_FAILURE";

export const ADD_EFCI_ACTIVE_TAB_REQUEST = "ADD_EFCI_ACTIVE_TAB_REQUEST";
export const ADD_EFCI_ACTIVE_TAB_SUCCESS = "ADD_EFCI_ACTIVE_TAB_SUCCESS";
export const ADD_EFCI_ACTIVE_TAB_FAILURE = "ADD_EFCI_ACTIVE_TAB_FAILURE";

export const GET_ALL_SITE_LOG_REQUEST = "GET_ALL_SITE_LOG_REQUEST";
export const GET_ALL_SITE_LOG_SUCCESS = "GET_ALL_SITE_LOG_SUCCESS";
export const GET_ALL_SITE_LOG_FAILURE = "GET_ALL_SITE_LOG_FAILURE";

export const RESTORE_SITE_LOG_REQUEST = "RESTORE_SITE_LOG_REQUEST";
export const RESTORE_SITE_LOG_SUCCESS = "RESTORE_SITE_LOG_SUCCESS";
export const RESTORE_SITE_LOG_FAILURE = "RESTORE_SITE_LOG_FAILURE";

export const DELETE_SITE_LOG_REQUEST = "DELETE_SITE_LOG_REQUEST";
export const DELETE_SITE_LOG_SUCCESS = "DELETE_SITE_LOG_SUCCESS";
export const DELETE_SITE_LOG_FAILURE = "DELETE_SITE_LOG_FAILURE";

export const GET_ANNUAL_EFCI_LOGS_REQUEST = "GET_ANNUAL_EFCI_LOGS_REQUEST";
export const GET_ANNUAL_EFCI_LOGS_SUCCESS = "GET_ANNUAL_EFCI_LOGS_SUCCESS";
export const GET_ANNUAL_EFCI_LOGS_FAILURE = "GET_ANNUAL_EFCI_LOGS_FAILURE";

export const GET_ANNUAL_FUNDING_OPTIONS_LOGS_REQUEST = "GET_ANNUAL_FUNDING_OPTIONS_LOGS_REQUEST";
export const GET_ANNUAL_FUNDING_OPTIONS_LOGS_SUCCESS = "GET_ANNUAL_FUNDING_OPTIONS_LOGS_SUCCESS";
export const GET_ANNUAL_FUNDING_OPTIONS_LOGS_FAILURE = "GET_ANNUAL_FUNDING_OPTIONS_LOGS_FAILURE";

export const RESTORE_ANNUAL_EFCI_LOGS_REQUEST = "RESTORE_ANNUAL_EFCI_LOGS_REQUEST";
export const RESTORE_ANNUAL_EFCI_LOGS_SUCCESS = "RESTORE_ANNUAL_EFCI_LOGS_SUCCESS";
export const RESTORE_ANNUAL_EFCI_LOGS_FAILURE = "RESTORE_ANNUAL_EFCI_LOGS_FAILURE";

export const RESTORE_ANNUAL_FUNDING_LOGS_REQUEST = "RESTORE_ANNUAL_FUNDING_LOGS_REQUEST";
export const RESTORE_ANNUAL_FUNDING_LOGS_SUCCESS = "RESTORE_ANNUAL_FUNDING_LOGS_SUCCESS";
export const RESTORE_ANNUAL_FUNDING_LOGS_FAILURE = "RESTORE_ANNUAL_FUNDING_LOGS_FAILURE";

export const GET_FUNDING_OPTIONS_LOGS_REQUEST = "GET_FUNDING_OPTIONS_LOGS_REQUEST";
export const GET_FUNDING_OPTIONS_LOGS_SUCCESS = "GET_FUNDING_OPTIONS_LOGS_SUCCESS";
export const GET_FUNDING_OPTIONS_LOGS_FAILURE = "GET_FUNDING_OPTIONS_LOGS_FAILURE";

export const RESTORE_FUNDING_LOGS_REQUEST = "RESTORE_FUNDING_LOGS_REQUEST";
export const RESTORE_FUNDING_LOGS_SUCCESS = "RESTORE_FUNDING_LOGS_SUCCESS";
export const RESTORE_FUNDING_LOGS_FAILURE = "RESTORE_FUNDING_LOGS_FAILURE";

export const GET_FUNDING_EFCI_LOGS_REQUEST = "GET_FUNDING_EFCI_LOGS_REQUEST";
export const GET_FUNDING_EFCI_LOGS_SUCCESS = "GET_FUNDING_EFCI_LOGS_SUCCESS";
export const GET_FUNDING_EFCI_LOGS_FAILURE = "GET_FUNDING_EFCI_LOGS_FAILURE";

export const RESTORE_FUNDING_EFCI_LOGS_REQUEST = "RESTORE_FUNDING_EFCI_LOGS_REQUEST";
export const RESTORE_FUNDING_EFCI_LOGS_SUCCESS = "RESTORE_FUNDING_EFCI_LOGS_SUCCESS";
export const RESTORE_FUNDING_EFCI_LOGS_FAILURE = "RESTORE_FUNDING_EFCI_LOGS_FAILURE";

export const GET_TOTAL_FUNDING_EFCI_LOGS_REQUEST = "GET_TOTAL_FUNDING_EFCI_LOGS_REQUEST";
export const GET_TOTAL_FUNDING_EFCI_LOGS_SUCCESS = "GET_TOTAL_FUNDING_EFCI_LOGS_SUCCESS";
export const GET_TOTAL_FUNDING_EFCI_LOGS_FAILURE = "GET_TOTAL_FUNDING_EFCI_LOGS_FAILURE";

export const RESTORE_TOTAL_FUNDING_EFCI_LOGS_REQUEST = "RESTORE_TOTAL_FUNDING_EFCI_LOGS_REQUEST";
export const RESTORE_TOTAL_FUNDING_EFCI_LOGS_SUCCESS = "RESTORE_TOTAL_FUNDING_EFCI_LOGS_SUCCESS";
export const RESTORE_TOTAL_FUNDING_EFCI_LOGS_FAILURE = "RESTORE_TOTAL_FUNDING_EFCI_LOGS_FAILURE";

export const GET_CSP_LOGS_REQUEST = "GET_CSP_LOGS_REQUEST";
export const GET_CSP_LOGS_SUCCESS = "GET_CSP_LOGS_SUCCESS";
export const GET_CSP_LOGS_FAILURE = "GET_CSP_LOGS_FAILURE";

export const RESTORE_CSP_LOGS_REQUEST = "RESTORE_CSP_LOGS_REQUEST";
export const RESTORE_CSP_LOGS_SUCCESS = "RESTORE_CSP_LOGS_SUCCESS";
export const RESTORE_CSP_LOGS_FAILURE = "RESTORE_CSP_LOGS_FAILURE";

export const DELETE_EFCI_LOG_REQUEST = "DELETE_EFCI_LOG_REQUEST";
export const DELETE_EFCI_LOGS_SUCCESS = "DELETE_EFCI_LOGS_SUCCESS";
export const DELETE_EFCI_LOGS_FAILURE = "DELETE_EFCI_LOGS_FAILURE";

//CHART LOGS
export const GET_ALL_SITE_CHART_LOG_REQUEST = "GET_ALL_SITE_CHART_LOG_REQUEST";
export const GET_ALL_SITE_CHART_LOG_SUCCESS = "GET_ALL_SITE_CHART_LOG_SUCCESS";
export const GET_ALL_SITE_CHART_LOG_FAILURE = "GET_ALL_SITE_CHART_LOG_FAILURE";

export const RESTORE_SITE_CHART_LOG_REQUEST = "RESTORE_SITE_CHART_LOG_REQUEST";
export const RESTORE_SITE_CHART_LOG_SUCCESS = "RESTORE_SITE_CHART_LOG_SUCCESS";
export const RESTORE_SITE_CHART_LOG_FAILURE = "RESTORE_SITE_CHART_LOG_FAILURE";

export const DELETE_SITE_CHART_LOG_REQUEST = "DELETE_SITE_CHART_LOG_REQUEST";
export const DELETE_SITE_CHART_LOG_SUCCESS = "DELETE_SITE_CHART_LOG_SUCCESS";
export const DELETE_SITE_CHART_LOG_FAILURE = "DELETE_SITE_CHART_LOG_FAILURE";

export const GET_ANNUAL_EFCI_CHART_LOGS_REQUEST = "GET_ANNUAL_EFCI_CHART_LOGS_REQUEST";
export const GET_ANNUAL_EFCI_CHART_LOGS_SUCCESS = "GET_ANNUAL_EFCI_CHART_LOGS_SUCCESS";
export const GET_ANNUAL_EFCI_CHART_LOGS_FAILURE = "GET_ANNUAL_EFCI_CHART_LOGS_FAILURE";

export const GET_ANNUAL_FUNDING_OPTIONS_CHART_LOGS_REQUEST = "GET_ANNUAL_FUNDING_OPTIONS_CHART_LOGS_REQUEST";
export const GET_ANNUAL_FUNDING_OPTIONS_CHART_LOGS_SUCCESS = "GET_ANNUAL_FUNDING_OPTIONS_CHART_LOGS_SUCCESS";
export const GET_ANNUAL_FUNDING_OPTIONS_CHART_LOGS_FAILURE = "GET_ANNUAL_FUNDING_OPTIONS_CHART_LOGS_FAILURE";

export const RESTORE_ANNUAL_EFCI_CHART_LOGS_REQUEST = "RESTORE_ANNUAL_EFCI_CHART_LOGS_REQUEST";
export const RESTORE_ANNUAL_EFCI_CHART_LOGS_SUCCESS = "RESTORE_ANNUAL_EFCI_CHART_LOGS_SUCCESS";
export const RESTORE_ANNUAL_EFCI_CHART_LOGS_FAILURE = "RESTORE_ANNUAL_EFCI_CHART_LOGS_FAILURE";

export const RESTORE_ANNUAL_FUNDING_CHART_LOGS_REQUEST = "RESTORE_ANNUAL_FUNDING_CHART_LOGS_REQUEST";
export const RESTORE_ANNUAL_FUNDING_CHART_LOGS_SUCCESS = "RESTORE_ANNUAL_FUNDING_CHART_LOGS_SUCCESS";
export const RESTORE_ANNUAL_FUNDING_CHART_LOGS_FAILURE = "RESTORE_ANNUAL_FUNDING_CHART_LOGS_FAILURE";

export const GET_FUNDING_OPTIONS_CHART_LOGS_REQUEST = "GET_FUNDING_OPTIONS_CHART_LOGS_REQUEST";
export const GET_FUNDING_OPTIONS_CHART_LOGS_SUCCESS = "GET_FUNDING_OPTIONS_CHART_LOGS_SUCCESS";
export const GET_FUNDING_OPTIONS_CHART_LOGS_FAILURE = "GET_FUNDING_OPTIONS_CHART_LOGS_FAILURE";

export const RESTORE_FUNDING_CHART_LOGS_REQUEST = "RESTORE_FUNDING_CHART_LOGS_REQUEST";
export const RESTORE_FUNDING_CHART_LOGS_SUCCESS = "RESTORE_FUNDING_CHART_LOGS_SUCCESS";
export const RESTORE_FUNDING_CHART_LOGS_FAILURE = "RESTORE_FUNDING_CHART_LOGS_FAILURE";

export const GET_FUNDING_EFCI_CHART_LOGS_REQUEST = "GET_FUNDING_EFCI_CHART_LOGS_REQUEST";
export const GET_FUNDING_EFCI_CHART_LOGS_SUCCESS = "GET_FUNDING_EFCI_CHART_LOGS_SUCCESS";
export const GET_FUNDING_EFCI_CHART_LOGS_FAILURE = "GET_FUNDING_EFCI_CHART_LOGS_FAILURE";

export const RESTORE_FUNDING_EFCI_CHART_LOGS_REQUEST = "RESTORE_FUNDING_EFCI_CHART_LOGS_REQUEST";
export const RESTORE_FUNDING_EFCI_CHART_LOGS_SUCCESS = "RESTORE_FUNDING_EFCI_CHART_LOGS_SUCCESS";
export const RESTORE_FUNDING_EFCI_CHART_LOGS_FAILURE = "RESTORE_FUNDING_EFCI_CHART_LOGS_FAILURE";

export const GET_TOTAL_FUNDING_EFCI_CHART_LOGS_REQUEST = "GET_TOTAL_FUNDING_EFCI_CHART_LOGS_REQUEST";
export const GET_TOTAL_FUNDING_EFCI_CHART_LOGS_SUCCESS = "GET_TOTAL_FUNDING_EFCI_CHART_LOGS_SUCCESS";
export const GET_TOTAL_FUNDING_EFCI_CHART_LOGS_FAILURE = "GET_TOTAL_FUNDING_EFCI_CHART_LOGS_FAILURE";

export const RESTORE_TOTAL_FUNDING_EFCI_CHART_LOGS_REQUEST = "RESTORE_TOTAL_FUNDING_EFCI_CHART_LOGS_REQUEST";
export const RESTORE_TOTAL_FUNDING_EFCI_CHART_LOGS_SUCCESS = "RESTORE_TOTAL_FUNDING_EFCI_CHART_LOGS_SUCCESS";
export const RESTORE_TOTAL_FUNDING_EFCI_CHART_LOGS_FAILURE = "RESTORE_TOTAL_FUNDING_EFCI_CHART_LOGS_FAILURE";

export const GET_CSP_CHART_LOGS_REQUEST = "GET_CSP_CHART_LOGS_REQUEST";
export const GET_CSP_CHART_LOGS_SUCCESS = "GET_CSP_CHART_LOGS_SUCCESS";
export const GET_CSP_CHART_LOGS_FAILURE = "GET_CSP_CHART_LOGS_FAILURE";

export const RESTORE_CSP_CHART_LOGS_REQUEST = "RESTORE_CSP_CHART_LOGS_REQUEST";
export const RESTORE_CSP_CHART_LOGS_SUCCESS = "RESTORE_CSP_CHART_LOGS_SUCCESS";
export const RESTORE_CSP_CHART_LOGS_FAILURE = "RESTORE_CSP_CHART_LOGS_FAILURE";

export const DELETE_EFCI_CHART_LOG_REQUEST = "DELETE_EFCI_CHART_LOG_REQUEST";
export const DELETE_EFCI_CHART_LOGS_SUCCESS = "DELETE_EFCI_CHART_LOGS_SUCCESS";
export const DELETE_EFCI_CHART_LOGS_FAILURE = "DELETE_EFCI_CHART_LOGS_FAILURE";

export const GET_CHART_EXPORT_REQUEST = "GET_CHART_EXPORT_REQUEST";
export const GET_CHART_EXPORT_SUCCESS = "GET_CHART_EXPORT_SUCCESS";
export const GET_CHART_EXPORT_FAILURE = "GET_CHART_EXPORT_FAILURE";

export const GET_PROJECTS_BASED_ON_CLIENT_REQUEST = "GET_PROJECTS_BASED_ON_CLIENT_REQUEST";
export const GET_PROJECTS_BASED_ON_CLIENT_SUCCESS = "GET_PROJECTS_BASED_ON_CLIENT_SUCCESS";
export const GET_PROJECTS_BASED_ON_CLIENT_FAILURE = "GET_PROJECTS_BASED_ON_CLIENT_FAILURE";

export const GET_ALL_CLIENT_USERS_REQUEST = "GET_ALL_CLIENT_USERS_REQUEST";
export const GET_ALL_CLIENT_USERS_SUCCESS = "GET_ALL_CLIENT_USERS_SUCCESS";
export const GET_ALL_CLIENT_USERS_FAILURE = "GET_ALL_CLIENT_USERS_FAILURE";

export const GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST = "GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST";
export const GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS = "GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS";
export const GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE = "GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE";

export const LOCK_SITE_REQUEST = "LOCK_SITE_REQUEST";
export const LOCK_SITE_SUCCESS = "LOCK_SITE_SUCCESS";
export const LOCK_SITE_FAILURE = "LOCK_SITE_FAILURE";

export const GET_BSI_PREVIEW_REQUEST = "GET_BSI_PREVIEW_REQUEST";
export const GET_BSI_PREVIEW_SUCCESS = "GET_BSI_PREVIEW_SUCCESS";
export const GET_BSI_PREVIEW_FAILURE = "GET_BSI_PREVIEW_FAILURE";

export const EXPORT_DOC_BSI_REQUEST = "EXPORT_DOC_BSI_REQUEST";
export const EXPORT_DOC_BSI_SUCCESS = "EXPORT_DOC_BSI_SUCCESS";
export const EXPORT_DOC_BSI_FAILURE = "EXPORT_DOC_BSI_FAILURE";
