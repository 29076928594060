// Get all clients
export const GET_DASHBOARD_DETAILS_REQUEST = "GET_DASHBOARD_DETAILS_REQUEST";
export const GET_DASHBOARD_DETAILS_SUCCESS = "GET_DASHBOARD_DETAILS_SUCCESS";
export const GET_DASHBOARD_DETAILS_FAILURE = "GET_DASHBOARD_DETAILS_FAILURE";

// Get client by id
export const GET_CHART_CLIENT_ID_REQUEST = "GET_CHART_CLIENT_ID_REQUEST";
export const GET_CHART_CLIENT_ID_SUCCESS = "GET_CHART_CLIENT_ID_SUCCESS";
export const GET_CHART_CLIENT_ID_FAILURE = "GET_CHART_CLIENT_ID_FAILURE";

// Get building by id
export const GET_CHART_BUILDING_BY_ID_REQUEST = "GET_CHART_BUILDING_BY_ID_REQUEST";
export const GET_CHART_BUILDING_BY_ID_SUCCESS = "GET_CHART_BUILDING_BY_ID_SUCCESS";
export const GET_CHART_BUILDING_BY_ID_FAILURE = "GET_CHART_BUILDING_BY_ID_FAILURE";

// Get region by id
export const GET_CHART_REGION_BY_ID_REQUEST = "GET_CHART_REGION_BY_ID_REQUEST";
export const GET_CHART_REGION_BY_ID_SUCCESS = "GET_CHART_REGION_BY_ID_SUCCESS";
export const GET_CHART_REGION_BY_ID_FAILURE = "GET_CHART_REGION_BY_ID_FAILURE";

// Get site by id
export const GET_CHART_SITE_BY_ID_REQUEST = "GET_CHART_SITE_BY_ID_REQUEST";
export const GET_CHART_SITE_BY_ID_SUCCESS = "GET_CHART_SITE_BY_ID_SUCCESS";
export const GET_CHART_SITE_BY_ID_FAILURE = "GET_CHART_SITE_BY_ID_FAILURE";

//get filter regions
export const GET_ENERGY_FILTER_REGIONS_REQUEST = "GET_ENERGY_FILTER_REGIONS_REQUEST";
export const GET_ENERGY_FILTER_REGIONS_SUCCESS = "GET_ENERGY_FILTER_REGIONS_SUCCESS";
export const GET_ENERGY_FILTER_REGIONS_FAILURE = "GET_FILTER_REGIONS_FAILURE";

//get filter sites
export const GET_ENERGY_FILTER_SITES_REQUEST = "GET_ENERGY_FILTER_SITES_REQUEST";
export const GET_ENERGY_FILTER_SITES_SUCCESS = "GET_ENERGY_FILTER_SITES_SUCCESS";
export const GET_ENERGY_FILTER_SITES_FAILURE = "GET_ENERGY_FILTER_SITES_FAILURE";

//get filter building_types
export const GET_ENERGY_FILTER_BUILDING_TYPE_REQUEST = "GET_ENERGY_FILTER_BUILDING_TYPE_REQUEST";
export const GET_ENERGY_FILTER_BUILDING_TYPE_SUCCESS = "GET_ENERGY_FILTER_BUILDING_TYPE_SUCCESS";
export const GET_ENERGY_FILTER_BUILDING_TYPE_FAILURE = "GET_ENERGY_FILTER_BUILDING_TYPE_FAILURE";

//get filter buildings
export const GET_ENERGY_FILTER_BUILDINGS_REQUEST = "GET_ENERGY_FILTER_BUILDINGS_REQUEST";
export const GET_ENERGY_FILTER_BUILDINGS_SUCCESS = "GET_ENERGY_FILTER_BUILDINGS_SUCCESS";
export const GET_ENERGY_FILTER_BUILDINGS_FAILURE = "GET_ENERGY_FILTER_BUILDINGS_FAILURE";

//get filter year
export const GET_ENERGY_FILTER_YEAR_REQUEST = "GET_ENERGY_FILTER_YEAR_REQUEST";
export const GET_ENERGY_FILTER_YEAR_SUCCESS = "GET_ENERGY_FILTER_YEAR_SUCCESS";
export const GET_ENERGY_FILTER_YEAR_FAILURE = "GET_ENERGY_FILTER_YEAR_FAILURE";

export const GET_ENERGY_MONTH_YEAR_REQUEST = "GET_ENERGY_MONTH_YEAR_REQUEST";
export const GET_ENERGY_MONTH_YEAR_SUCCESS = "GET_ENERGY_MONTH_YEAR_SUCCESS";
export const GET_ENERGY_MONTH_YEAR_FAILURE = "GET_ENERGY_MONTH_YEAR_FAILURE";
