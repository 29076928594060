export const bands = {
    geo_hierarchy: "Geo Hierarchy",
    recommendation: "Recommendation",
    capital_spending_plan: "Capital Spending Plan",
    // priority: "Priority",
    asset: "Asset",
    additional_details: "Additional Details",
    building: "Building",
    site: "Site",
    other: "Others"
    // qa_qc: "QA/Qc",
    // water: "Water",
    // energy: "Energy"
};

export const bandPositions = {
    header: 1,
    body: 2
};

export const tabs = {
    recommendation: {
        label: "Recommendation",
        bands: [
            bands.geo_hierarchy,
            bands.recommendation,
            bands.capital_spending_plan,
            // bands.priority,
            bands.asset,
            bands.additional_details
        ]
    },
    building: {
        label: "Geo Hierarchy",
        bands: [bands.building, bands.site]
    },
    other: {
        label: "Others",
        bands: [
            bands.other
            // bands.qa_qc,
            // bands.water,
            // bands.energy
        ]
    }
};

export const qaStatusOptions = [
    {
        label: "Select",
        value: ""
    },
    {
        label: "Pending",
        value: "pending"
    },
    {
        label: "Resolved",
        value: "resolved"
    },
    {
        label: "Approved",
        value: "approved"
    },
    {
        label: "All Pending",
        value: "all_pending"
    },
    {
        label: "All Resolved",
        value: "all_resolved"
    },
    {
        label: "All Approved",
        value: "all_approved"
    },
    {
        label: "No QA/QC",
        value: "no_reviews"
    }
];
export const qaPassedOptions = [
    {
        label: "Select",
        value: ""
    },
    {
        label: "Qa Passed",
        value: "yes"
    },
    {
        label: "Not Qa Passed",
        value: "no"
    }
];
