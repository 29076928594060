// Get all meter list
export const GET_METER_LIST_REQUEST = "GET_METER_LIST_REQUEST";
export const GET_METER_LIST_SUCCESS = "GET_METER_LIST_SUCCESS";
export const GET_METER_LIST_FAILURE = "GET_METER_LIST_FAILURE";

// Get all dropdowns

export const GET_METER_CLIENTS_REQUEST = "GET_METER_CLIENTS_REQUEST";
export const GET_METER_CLIENTS_SUCCESS = "GET_METER_CLIENTS_SUCCESS";
export const GET_METER_CLIENTS_FAILURE = "GET_METER_CLIENTS_FAILURE";

export const GET_METER_REGION_REQUEST = "GET_METER_REGION_REQUEST";
export const GET_METER_REGION_SUCCESS = "GET_METER_REGION_SUCCESS";
export const GET_METER_REGION_FAILURE = "GET_METER_REGION_FAILURE";

export const GET_METER_SITE_REQUEST = "GET_METER_SITE_REQUEST";
export const GET_METER_SITE_SUCCESS = "GET_METER_SITE_SUCCESS";
export const GET_METER_SITE_FAILURE = "GET_METER_SITE_FAILURE";

export const GET_METER_BUILDING_REQUEST = "GET_METER_BUILDING_REQUEST";
export const GET_METER_BUILDING_SUCCESS = "GET_METER_BUILDING_SUCCESS";
export const GET_METER_BUILDING_FAILURE = "GET_METER_BUILDING_FAILURE";

export const GET_METER_ACCOUNTS_REQUEST = "GET_METER_ACCOUNTS_REQUEST";
export const GET_METER_ACCOUNTS_SUCCESS = "GET_METER_ACCOUNTS_SUCCESS";
export const GET_METER_ACCOUNTS_FAILURE = "GET_METER_ACCOUNTS_FAILURE";

// Add trade
export const ADD_METER_TEMPLATE_REQUEST = "ADD_METER_TEMPLATE_REQUEST";
export const ADD_METER_TEMPLATE_SUCCESS = "ADD_METER_TEMPLATE_SUCCESS";
export const ADD_METER_TEMPLATE_FAILURE = "ADD_METER_TEMPLATE_FAILURE";

// // Get Trade by Id
export const GET_METER_TEMPLATE_BY_ID_REQUEST = "GET_METER_TEMPLATE_BY_ID_REQUEST";
export const GET_METER_TEMPLATE_BY_ID_SUCCESS = "GET_METER_TEMPLATE_BY_ID_SUCCESS";
export const GET_METER_TEMPLATE_BY_ID_FAILURE = "GET_METER_TEMPLATE_BY_ID_FAILURE";

// Get Site by Id
export const GET_SITE_BY_ID_REQUEST = "GET_SITE_BY_ID_REQUEST";
export const GET_SITE_BY_ID_SUCCESS = "GET_SITE_BY_ID_SUCCESS";
export const GET_SITE_BY_ID_FAILURE = "GET_SITE_BY_ID_FAILURE";

// Get Building by Id
export const GET_BUILDING_BY_ID_REQUEST = "GET_BUILDING_BY_ID_REQUEST";
export const GET_BUILDING_BY_ID_SUCCESS = "GET_BUILDING_BY_ID_SUCCESS";
export const GET_BUILDING_BY_ID_FAILURE = "GET_BUILDING_BY_ID_FAILURE";

// Update meter
export const UPDATE_METER_TEMPLATE_REQUEST = "UPDATE_METER_TEMPLATE_REQUEST";
export const UPDATE_METER_TEMPLATE_SUCCESS = "UPDATE_METER_TEMPLATE_SUCCESS";
export const UPDATE_METER_TEMPLATE_FAILURE = "UPDATE_METER_TEMPLATE_FAILURE";

// Delete meter
export const DELETE_METER_TEMPLATE_REQUEST = "DELETE_METER_TEMPLATE_REQUEST";
export const DELETE_METER_TEMPLATE_SUCCESS = "DELETE_METER_TEMPLATE_SUCCESS";
export const DELETE_METER_TEMPLATE_FAILURE = "DELETE_METER_TEMPLATE_FAILURE";

// Get all readings
export const GET_ALL_METER_READINGS_ELECTRIC_REQUEST = "GET_ALL_METER_READINGS_ELECTRIC_REQUEST";
export const GET_ALL_METER_READINGS_ELECTRIC_SUCCESS = "GET_ALL_METER_READINGS_ELECTRIC_SUCCESS";
export const GET_ALL_METER_READINGS_ELECTRIC_FAILURE = "GET_ALL_METER_READINGS_ELECTRIC_FAILURE";

// // Update Trade Entity Param
export const UPDATE_NARRATIVE_TEMPLATE_ENTITY_PARAMS_SUCCESS_GAS = "UPDATE_NARRATIVE_TEMPLATE_ENTITY_PARAMS_SUCCESS_GAS";
export const UPDATE_NARRATIVE_TEMPLATE_ENTITY_PARAMS_FAILURE_GAS = "UPDATE_NARRATIVE_TEMPLATE_ENTITY_PARAMS_FAILURE_GAS";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_NARRATIVE_TEMPLATE_LOG_REQUEST = "GET_ALL_NARRATIVE_TEMPLATE_LOG_REQUEST";
export const GET_ALL_NARRATIVE_TEMPLATE_LOG_SUCCESS = "GET_ALL_NARRATIVE_TEMPLATE_LOG_SUCCESS";
export const GET_ALL_NARRATIVE_TEMPLATE_LOG_FAILURE = "GET_ALL_NARRATIVE_TEMPLATE_LOG_FAILURE";

export const RESTORE_NARRATIVE_TEMPLATE_LOG_REQUEST = "RESTORE_NARRATIVE_TEMPLATE_LOG_REQUEST";
export const RESTORE_NARRATIVE_TEMPLATE_LOG_SUCCESS = "RESTORE_NARRATIVE_TEMPLATE_LOG_SUCCESS";
export const RESTORE_NARRATIVE_TEMPLATE_LOG_FAILURE = "RESTORE_NARRATIVE_TEMPLATE_LOG_FAILURE";

export const DELETE_NARRATIVE_TEMPLATE_LOG_REQUEST = "DELETE_NARRATIVE_TEMPLATE_LOG_REQUEST";
export const DELETE_NARRATIVE_TEMPLATE_LOG_SUCCESS = "DELETE_NARRATIVE_TEMPLATE_LOG_SUCCESS";
export const DELETE_NARRATIVE_TEMPLATE_LOG_FAILURE = "DELETE_NARRATIVE_TEMPLATE_LOG_FAILURE";

export const GET_NARRATIVE_TEMPLATE_EXPORT_REQUEST = "GET_NARRATIVE_TEMPLATE_EXPORT_REQUEST";
export const GET_NARRATIVE_TEMPLATE_EXPORT_SUCCESS = "GET_NARRATIVE_TEMPLATE_EXPORT_SUCCESS";
export const GET_NARRATIVE_TEMPLATE_EXPORT_FAILURE = "GET_NARRATIVE_TEMPLATE_EXPORT_FAILURE";

// export const GET_ALL_CLIENT_USERS_REQUEST = "GET_ALL_CLIENT_USERS_REQUEST";
// export const GET_ALL_CLIENT_USERS_SUCCESS = "GET_ALL_CLIENT_USERS_SUCCESS";
// export const GET_ALL_CLIENT_USERS_FAILURE = "GET_ALL_CLIENT_USERS_FAILURE";

// // Get all consultancy users
// export const GET_ALL_CONSULTANCY_USERS_REQUEST = "GET_ALL_CONSULTANCY_USERS_REQUEST";
// export const GET_ALL_CONSULTANCY_USERS_SUCCESS = "GET_ALL_CONSULTANCY_USERS_SUCCESS";
// export const GET_ALL_CONSULTANCY_USERS_FAILURE = "GET_ALL_CONSULTANCY_USERS_FAILURE";

// export const GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST = "GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST";
// export const GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS = "GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS";
// export const GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE = "GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE";

// // Get all Clients
// export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
// export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
// export const GET_ALL_CLIENTS_FAILURE = "GET_ALL_CLIENTS_FAILURE";

// export const GET_ALL_BUILDINGS_DROP_DOWN_REQUEST = "GET_ALL_BUILDINGS_DROP_DOWN_REQUEST";
// export const GET_ALL_BUILDINGS_DROP_DOWN_SUCCESS = "GET_ALL_BUILDINGS_DROP_DOWN_SUCCESS";
// export const GET_ALL_BUILDINGS_DROP_DOWN_FAILURE = "GET_ALL_BUILDINGS_DROP_DOWN_FAILURE";

// export const GET_ASSIGN_MODAL_DETAILS_REQUEST = "GET_ASSIGN_MODAL_DETAILS_REQUEST";
// export const GET_ASSIGN_MODAL_DETAILS_SUCCESS = "GET_ASSIGN_MODAL_DETAILS_SUCCESS";
// export const GET_ASSIGN_MODAL_DETAILS_FAILURE = "GET_ASSIGN_MODAL_DETAILS_FAILURE";

// export const ASSIGN_ITEMS_REQUEST = "ASSIGN_ITEMS_REQUEST";
// export const ASSIGN_ITEMS_SUCCESS = "ASSIGN_ITEMS_SUCCESS";
// export const ASSIGN_ITEMS_FAILURE = "ASSIGN_ITEMS_FAILURE";
