import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { MASTER_FILTER_KEYS } from "../constants";

const MasterFilter = ({
    entity,
    getSmartChartMasterFilterDropDown,
    masterFilterList = {},
    masterFilters = {},
    setSmartChartData,
    currentBand,
    selectedFiltersList = {},
    selectedClient,
    rowIndex,
    requiredFields,
    isCoreReportView = false,
    core_report_req_filters = {},
    ...props
}) => {
    const [selectedDropDown, setSelectedDropdown] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [search, setSearch] = useState({});
    const [yearsFilter, setYearsFilter] = useState({ start: "", end: "" });
    const [showErrorBorder, setShowErrorBorder] = useState(false);
    const [energyMngYearFilters, setEnergyMngYearFilters] = useState({
        start: {
            month: "",
            year: ""
        },
        end: {
            month: "",
            year: ""
        }
    });

    const handleClickDropdown = async (key, paramKey) => {
        setSelectedItems(selectedFiltersList?.[paramKey]?.length ? [...selectedFiltersList[paramKey]] : []);
        if (selectedFiltersList?.start) {
            setYearsFilter(prevValue => {
                return {
                    start: selectedFiltersList.start,
                    end: selectedFiltersList.end
                };
            });
        }
        setSelectedDropdown(prevValue => (prevValue === key ? "" : key));
        if (selectedDropDown !== key) await getSmartChartMasterFilterDropDown(key, entity, getParamsForMasterFilter(key));
    };

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [masterFilterList, selectedItems]);

    useEffect(() => {
        if (selectedDropDown === "energy_mng_custom_years") {
            setEnergyMngYearFilters({
                start: {
                    month: selectedFiltersList?.em_start_year_month?.[1] || "",
                    year: selectedFiltersList?.em_start_year_month?.[0] || ""
                },
                end: {
                    month: selectedFiltersList?.em_end_year_month?.[1] || "",
                    year: selectedFiltersList?.em_end_year_month?.[0] || ""
                }
            });
        }
    }, [selectedDropDown]);

    useEffect(() => {
        checkOrUpdateMonthValuesForEnergyFilter();
    }, [energyMngYearFilters.start.year, energyMngYearFilters.end.year]);

    const getParamsForMasterFilter = key => {
        let currentUser = localStorage.getItem("userId") || "";

        const sameKeyRemoval = (entity, paramKey) => MASTER_FILTER_KEYS?.[entity].find(item => item?.key === key)?.paramKey === paramKey;

        switch (entity) {
            case "project":
                return {
                    client_id: selectedClient || null,
                    user_id: currentUser,
                    project_id:
                        selectedFiltersList?.project_ids && !sameKeyRemoval("project", "project_ids") ? [...selectedFiltersList.project_ids] : null,
                    region_id:
                        selectedFiltersList?.region_ids && !sameKeyRemoval("project", "region_ids") ? [...selectedFiltersList.region_ids] : null,
                    site_id: selectedFiltersList?.site_ids && !sameKeyRemoval("project", "site_ids") ? [...selectedFiltersList.site_ids] : null,
                    building_id:
                        selectedFiltersList?.building_ids && !sameKeyRemoval("project", "building_ids")
                            ? [...selectedFiltersList.building_ids]
                            : null,
                    building_type_id:
                        selectedFiltersList?.building_types && !sameKeyRemoval("project", "building_types")
                            ? [...selectedFiltersList.building_types]
                            : null,
                    fmp: selectedFiltersList?.fmp && !sameKeyRemoval("project", "fmp") ? [...selectedFiltersList.fmp] : null,
                    ir:
                        selectedFiltersList?.infrastructure_requests && !sameKeyRemoval("project", "infrastructure_requests")
                            ? [...selectedFiltersList.infrastructure_requests]
                            : null,
                    addition_id:
                        selectedFiltersList?.addition_ids && !sameKeyRemoval("project", "addition_ids")
                            ? [...selectedFiltersList.addition_ids]
                            : null,
                    responsibility_id: null,
                    trade_id: selectedFiltersList?.trade_ids && !sameKeyRemoval("project", "trade_ids") ? [...selectedFiltersList.trade_ids] : null,
                    system_id:
                        selectedFiltersList?.system_ids && !sameKeyRemoval("project", "system_ids") ? [...selectedFiltersList.system_ids] : null,
                    sub_system_id:
                        selectedFiltersList?.subsystem_ids && !sameKeyRemoval("project", "subsystem_ids")
                            ? [...selectedFiltersList.subsystem_ids]
                            : null,
                    primary_use:
                        selectedFiltersList?.primary_use && !sameKeyRemoval("project", "primary_use") ? [...selectedFiltersList.primary_use] : null,
                    secondary_use:
                        selectedFiltersList?.secondary_use && !sameKeyRemoval("project", "secondary_use")
                            ? [...selectedFiltersList.secondary_use]
                            : null,
                    internal_groups:
                        selectedFiltersList?.internal_groups && !sameKeyRemoval("project", "internal_groups")
                            ? [...selectedFiltersList.internal_groups]
                            : null,
                    sectors: selectedFiltersList?.sectors && !sameKeyRemoval("project", "sectors") ? [...selectedFiltersList.sectors] : null,
                    divisions: selectedFiltersList?.division && !sameKeyRemoval("project", "division") ? [...selectedFiltersList.division] : null,
                    fci: selectedFiltersList?.color_scale && !sameKeyRemoval("project", "color_scale") ? [...selectedFiltersList.color_scale] : null,
                    start: selectedFiltersList?.start && !sameKeyRemoval("project", "start") ? selectedFiltersList.start : null,
                    end: selectedFiltersList?.end && !sameKeyRemoval("project", "end") ? selectedFiltersList.end : null
                };
            case "assets":
                return {
                    client_id: selectedClient || null,
                    user_id: currentUser,
                    site_ids: selectedFiltersList?.site_ids && !sameKeyRemoval("assets", "site_ids") ? [...selectedFiltersList.site_ids] : null,
                    region_ids:
                        selectedFiltersList?.region_ids && !sameKeyRemoval("assets", "region_ids") ? [...selectedFiltersList.region_ids] : null,
                    building_ids:
                        selectedFiltersList?.building_ids && !sameKeyRemoval("assets", "building_ids") ? [...selectedFiltersList.building_ids] : null
                };
            case "energy_mng":
                return {
                    user_id: currentUser,
                    client_id: selectedClient || null,
                    region_ids:
                        selectedFiltersList?.region_ids && !sameKeyRemoval("energy_mng", "region_ids") ? [...selectedFiltersList.region_ids] : null,
                    site_ids: selectedFiltersList?.site_ids && !sameKeyRemoval("energy_mng", "site_ids") ? [...selectedFiltersList.site_ids] : null,
                    building_ids:
                        selectedFiltersList?.building_ids && !sameKeyRemoval("energy_mng", "building_ids")
                            ? [...selectedFiltersList.building_ids]
                            : null,
                    building_type_ids:
                        selectedFiltersList?.building_types && !sameKeyRemoval("energy_mng", "building_types")
                            ? [...selectedFiltersList.building_types]
                            : null,
                    years: selectedFiltersList?.years && !sameKeyRemoval("energy_mng", "years") ? [...selectedFiltersList.years] : null,
                    start_yr:
                        selectedFiltersList?.em_start_year_month && !["energy_mng_custom_years", "energy_mng_custom_months"].includes(key)
                            ? selectedFiltersList.em_start_year_month[0]
                            : null,
                    start_month:
                        selectedFiltersList?.em_start_year_month && !["energy_mng_custom_years", "energy_mng_custom_months"].includes(key)
                            ? selectedFiltersList.em_start_year_month[1]
                            : null,
                    end_yr:
                        selectedFiltersList?.em_end_year_month && !["energy_mng_custom_years", "energy_mng_custom_months"].includes(key)
                            ? selectedFiltersList.em_end_year_month[0]
                            : null,
                    end_month:
                        selectedFiltersList?.em_end_year_month && !["energy_mng_custom_years", "energy_mng_custom_months"].includes(key)
                            ? selectedFiltersList.em_end_year_month[1]
                            : null
                };
            default:
                return {};
        }
    };

    const renderFilteredList = selectedItem => {
        const searchValue = search[selectedItem];
        let filteredList = [];
        if (masterFilterList[selectedItem]?.length) {
            filteredList = searchValue?.trim()?.length
                ? masterFilterList[selectedItem].filter(
                      item =>
                          item.name?.toString()?.toLowerCase()?.includes(searchValue?.toLocaleLowerCase()) ||
                          item.description?.toString()?.toLowerCase()?.includes(searchValue?.toLocaleLowerCase())
                  )
                : masterFilterList[selectedItem];
        }

        return filteredList;
    };

    const handleSelectDropdown = (selectedId, key = "") => {
        if (key == "projects" || key === "energy_mng_projects") {
            setSelectedItems([selectedId]);
        } else {
            let tempSelectedItems = [...selectedItems];
            if (selectedItems.includes(selectedId)) {
                tempSelectedItems = tempSelectedItems.filter(id => id !== selectedId);
            } else {
                tempSelectedItems.push(selectedId);
            }
            setSelectedItems([...tempSelectedItems]);
        }
    };

    const handleSelectAll = async (key, isChecked) => {
        let temp = [];
        if (renderFilteredList(key)?.length) {
            renderFilteredList(key).map(data => temp.push(data.id));
        }
        setSelectedItems(isChecked ? [...temp] : []);
    };

    const validate = key => {
        setShowErrorBorder(false);
        if (key === "energy_mng_custom_years") {
            let isAllFieldEmpty = checkAllEnergyFiltersAreEmpty();
            if (!isAllFieldEmpty && !energyMngYearFilters?.start?.year?.trim()?.length) {
                setShowErrorBorder(true);
                return false;
            }
            if (!isAllFieldEmpty && !energyMngYearFilters?.start?.month?.trim()?.length) {
                setShowErrorBorder(true);
                return false;
            }
            if (!isAllFieldEmpty && !energyMngYearFilters?.end?.year?.trim()?.length) {
                setShowErrorBorder(true);
                return false;
            }
            if (!isAllFieldEmpty && !energyMngYearFilters?.end?.month?.trim()?.length) {
                setShowErrorBorder(true);
                return false;
            }
            return true;
        }
        if (yearsFilter?.start && !yearsFilter?.end?.toString()?.trim()?.length) {
            setShowErrorBorder(true);
            return false;
        }
        if (yearsFilter?.end && !yearsFilter?.start?.toString()?.trim()?.length) {
            setShowErrorBorder(true);
            return false;
        }
        return true;
    };

    const checkAllEnergyFiltersAreEmpty = () => {
        return (
            !energyMngYearFilters.start.year && !energyMngYearFilters.start.month && !energyMngYearFilters.end.year && !energyMngYearFilters.end.month
        );
    };

    const updateFilter = (key, paramKey, label) => {
        let updatedData = selectedItems;
        if (key === "years" || key === "energy_mng_custom_years") {
            if (!validate(key)) {
                return false;
            }
            // return false;
        }
        if (key !== "year" && entity === "project") {
            let options = masterFilterList[key];
            updatedData = selectedItems.filter(item => options.findIndex(opt => opt.id === item) !== -1);
        }
        setSmartChartData("mfilter", {
            mfilterKey: paramKey,
            bandName: currentBand,
            filterValues:
                key === "years"
                    ? yearsFilter
                    : key === "energy_mng_custom_years"
                    ? checkAllEnergyFiltersAreEmpty()
                        ? { em_start_year_month: [], em_end_year_month: [] }
                        : {
                              em_start_year_month: [energyMngYearFilters.start.year, energyMngYearFilters.start.month],
                              em_end_year_month: [energyMngYearFilters.end.year, energyMngYearFilters.end.month]
                          }
                    : [...updatedData],
            entity: entity,
            filterKey: key,
            dropDownData: masterFilterList?.[key] || [],
            filterLabel: label
        });
        setSelectedDropdown(prevValue => (prevValue === key ? "" : key));
        setSelectedItems([]);
    };

    const handleCancelFilter = (key, paramKey) => {
        setSelectedDropdown("");
        setSelectedItems([]);
        setSearch({ ...search, [key]: "" });
    };

    const getYearValuesForFilter = type => {
        let yearValues = [];
        let dropDownData = masterFilterList?.years?.length ? [...masterFilterList?.years] : [];
        if (dropDownData.length) {
            const { start, end } = yearsFilter;
            switch (type) {
                case "start":
                    if (end) {
                        yearValues = dropDownData.filter(y => y <= end);
                    } else {
                        yearValues = [...dropDownData];
                    }
                    break;
                case "end":
                    if (start) {
                        yearValues = dropDownData.filter(y => y >= start);
                    } else {
                        yearValues = [...dropDownData];
                    }
                    break;
            }
        }
        return yearValues;
    };

    const getMasterFilterKeys = (keysList = []) => {
        let filterKeys = [];
        let updatedKeysList = [...keysList];
        if (isCoreReportView) {
            updatedKeysList = keysList.filter(k => requiredFields?.[entity].includes(k.paramKey));
        }
        if (entity === "project" || entity === "energy_mng") {
            if (rowIndex === 0) {
                filterKeys = updatedKeysList.slice(0, 5);
            } else if (rowIndex === 1) {
                filterKeys = updatedKeysList.slice(5, 9);
            } else if (rowIndex === 2) {
                filterKeys = updatedKeysList.slice(9, 14);
            } else {
                filterKeys = updatedKeysList.slice(14, 18);
            }
        } else {
            filterKeys = [...updatedKeysList];
        }
        return filterKeys;
    };

    const handleClickEnergyYearsDropdown = async (key, paramKey) => {
        setSelectedDropdown(prevValue => (prevValue === key ? "" : key));
        if (selectedDropDown !== key) {
            await getSmartChartMasterFilterDropDown(key, entity, getParamsForMasterFilter(key));
            await getSmartChartMasterFilterDropDown("energy_mng_custom_months", entity, getParamsForMasterFilter("energy_mng_custom_months"));
        }
    };

    const getYearsListForEnergyFilters = (type, key) => {
        if (type === "month") {
            if (energyMngYearFilters.start.year && energyMngYearFilters.start.year === energyMngYearFilters.end.year) {
                if (key === "from") {
                    if (energyMngYearFilters.end.month) {
                        let currentSelectedItemIndex = masterFilterList?.energy_mng_custom_months?.findIndex(
                            month => month === energyMngYearFilters.end.month
                        );
                        return masterFilterList?.energy_mng_custom_months?.filter((month, i) => i <= currentSelectedItemIndex);
                    }
                    return masterFilterList?.energy_mng_custom_months || [];
                }
                if (energyMngYearFilters.start.month) {
                    let currentSelectedItemIndex = masterFilterList?.energy_mng_custom_months?.findIndex(
                        month => month === energyMngYearFilters.start.month
                    );
                    return masterFilterList?.energy_mng_custom_months?.filter((month, i) => i >= currentSelectedItemIndex);
                }
            }
            return masterFilterList?.energy_mng_custom_months || [];
        }
        if (masterFilterList?.energy_mng_custom_years?.length) {
            if (key === "from") {
                if (energyMngYearFilters.end.year) {
                    return masterFilterList.energy_mng_custom_years.filter(
                        yearValue => parseInt(yearValue.id) <= parseInt(energyMngYearFilters.end.year)
                    );
                }
                return masterFilterList.energy_mng_custom_years;
            }
            if (energyMngYearFilters.start.year) {
                return masterFilterList.energy_mng_custom_years.filter(
                    yearValue => parseInt(yearValue.id) >= parseInt(energyMngYearFilters.start.year)
                );
            }
            return masterFilterList.energy_mng_custom_years;
        }
    };

    const checkOrUpdateMonthValuesForEnergyFilter = () => {
        if (
            energyMngYearFilters.start.year &&
            energyMngYearFilters.start.month &&
            energyMngYearFilters.end.month &&
            energyMngYearFilters.start.year === energyMngYearFilters.end.year
        ) {
            let fromMonthIndex = masterFilterList?.energy_mng_custom_months?.findIndex(month => month === energyMngYearFilters.start.month);
            let toMonthIndex = masterFilterList?.energy_mng_custom_months?.findIndex(month => month === energyMngYearFilters.end.month);
            if (fromMonthIndex > toMonthIndex) {
                setEnergyMngYearFilters(preValue => {
                    return {
                        start: {
                            ...preValue.start,
                            month: ""
                        },
                        end: {
                            ...preValue.end,
                            month: ""
                        }
                    };
                });
            }
        }
    };
    return (
        <>
            <div class="col-md-12 p-0 pad-50">
                <div class="d-flex itm-nmb">
                    {entity === "project" && rowIndex === 1 ? (
                        <div class={`col ${selectedFiltersList?.start ? "bg-th-filtered" : ""}`}>
                            <div class={`dropdown-container ${selectedDropDown === "years" ? "dropdown-open" : ""} `}>
                                <div class="dropdown-toggle click-dropdown" onClick={() => handleClickDropdown("years", "years")}>
                                    Years
                                    <span class="close-reg">
                                        <i class="fas fa-chevron-down"></i>
                                    </span>
                                </div>
                                <div className={selectedDropDown === "years" ? "dropdown-menu year-drop  dropdown-active p-0" : "dropdown-menu "}>
                                    <div
                                        className="drop-filtr pb-3"
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <div className="col-md-12 p-0 slt-ara">
                                            <div className="year-outer ">
                                                <div>
                                                    <button
                                                        className="clear-btn-selection sm-chart-year-fil-clr"
                                                        onClick={() => setYearsFilter({ start: "", end: "" })}
                                                    >
                                                        Clear
                                                    </button>
                                                </div>

                                                <div className="col-xl-12 selecbox-otr p-0 mb-4">
                                                    <span className="dropdown-item p-0">
                                                        <label>Start Year</label>
                                                        <div className="custom-selecbox w-100">
                                                            <select
                                                                className={`${
                                                                    showErrorBorder && !yearsFilter?.start?.toString()?.trim()?.length
                                                                        ? "error-border"
                                                                        : ""
                                                                }`}
                                                                value={yearsFilter.start}
                                                                name={"start"}
                                                                onChange={e => {
                                                                    e.stopPropagation();
                                                                    let yearValue = parseInt(e.target.value) || "";
                                                                    setYearsFilter(prevValue => {
                                                                        return {
                                                                            ...prevValue,
                                                                            start: yearValue
                                                                        };
                                                                    });
                                                                }}
                                                            >
                                                                <option value="">Select</option>
                                                                {getYearValuesForFilter("start").map(year => {
                                                                    return <option value={year}>{year}</option>;
                                                                })}
                                                            </select>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div className="col-xl-12 p-0 selecbox-otr p-0">
                                                    <span className="dropdown-item  p-0">
                                                        <label>End Year</label>
                                                        <div className="custom-selecbox w-100">
                                                            <select
                                                                className={`${
                                                                    showErrorBorder && !yearsFilter?.end?.toString()?.trim()?.length
                                                                        ? "error-border"
                                                                        : ""
                                                                }`}
                                                                value={yearsFilter.end}
                                                                name={"end"}
                                                                onChange={e => {
                                                                    e.stopPropagation();
                                                                    let yearValue = parseInt(e.target.value) || "";
                                                                    setYearsFilter(prevValue => {
                                                                        return {
                                                                            ...prevValue,
                                                                            end: yearValue
                                                                        };
                                                                    });
                                                                }}
                                                            >
                                                                <option value="">Select</option>
                                                                {getYearValuesForFilter("end").map(year => {
                                                                    return <option value={year}>{year}</option>;
                                                                })}
                                                            </select>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3 drp-btn">
                                            <button
                                                type="button"
                                                className="btn btn-primary btnRgion mr-2"
                                                onClick={() => updateFilter("years", "years", "Years")}
                                            >
                                                OK
                                            </button>
                                            <button type="button" className="btn btn-primary btnClr" onClick={() => handleCancelFilter()}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {getMasterFilterKeys(MASTER_FILTER_KEYS[entity]).map((item, i) => (
                        <div class={`col ${selectedFiltersList?.[item.paramKey]?.length ? "bg-th-filtered" : ""}`}>
                            <div class={`dropdown-container ${selectedDropDown === item.key ? "dropdown-open" : ""} `}>
                                <div
                                    className={`dropdown-toggle click-dropdown ${
                                        entity === "energy_mng" &&
                                        selectedFiltersList?.em_end_year_month?.length > 0 &&
                                        item.key === "energy_mng_years"
                                            ? "cursor-diabled"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        !(
                                            entity === "energy_mng" &&
                                            selectedFiltersList?.em_end_year_month?.length > 0 &&
                                            item.key === "energy_mng_years"
                                        ) && handleClickDropdown(item.key, item.paramKey)
                                    }
                                >
                                    {item.label + (isCoreReportView && core_report_req_filters[item.paramKey]?.required ? "*" : "")}
                                    {/* {requiredFields?.[entity]?.includes(item.paramKey) && "*"} */}
                                    <span class="close-reg">
                                        <i class="fas fa-chevron-down"></i>
                                    </span>
                                </div>
                                {selectedDropDown === item.key && (
                                    <div className={`dropdown-menu p-0 ${selectedDropDown === item.key ? "dropdown-active p-0" : ""}`}>
                                        <ReactTooltip id="smart-chart-master-filter" />
                                        <div className="drop-filtr dp-rcm-overflow" onClick={e => e.stopPropagation()}>
                                            <div className="col-md-12 p-0 border-bottom pt-1">
                                                <span className="dropdown-item build_search_drp">
                                                    <input
                                                        type="search"
                                                        placeholder="Search..."
                                                        value={search[item.key]}
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            setSearch(prevSearch => {
                                                                return { ...prevSearch, [item.key]: value };
                                                            });
                                                        }}
                                                    />
                                                    <i
                                                        class="fas fa-times cursor-pointer cls-close"
                                                        onClick={() =>
                                                            setSearch(prevSearch => {
                                                                return { ...prevSearch, [item.key]: "" };
                                                            })
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div className="col-md-12 p-0 border-bottom pt-1">
                                                <div className="dropdown-item select_section_outer">
                                                    <label className="container-check">
                                                        {`Select all (${
                                                            selectedItems?.filter(
                                                                selectedItem =>
                                                                    renderFilteredList(item.key)?.findIndex(fi => fi.id === selectedItem) !== -1
                                                            )?.length ?? 0
                                                        }/${
                                                            renderFilteredList(item.key)?.filter(fi => !masterFilters[item.paramKey]?.includes(fi.id))
                                                                ?.length ?? 0
                                                        })`}
                                                        {/* {`Select all (${selectedItems?.length ?? 0}/${
                                                            renderFilteredList(item.key)?.filter(fi => !masterFilters[item.paramKey]?.includes(fi.id))
                                                                ?.length ?? 0
                                                        })`} */}
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                selectedItems.length &&
                                                                renderFilteredList(item.key)?.length ==
                                                                    selectedItems?.filter(
                                                                        selectedItem =>
                                                                            renderFilteredList(item.key)?.findIndex(fi => fi.id === selectedItem) !==
                                                                            -1
                                                                    )?.length
                                                            }
                                                            onClick={e => item.key !== "projects" && handleSelectAll(item.key, e.target.checked)}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <button className="clear-btn-selection" onClick={() => setSelectedItems([])}>
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-12 p-0 slt-ara border-bottom">
                                                {masterFilterList[item.key]
                                                    ?.filter(fi => selectedItems?.includes(fi.id))
                                                    ?.map(data => (
                                                        <span className="dropdown-item" key={data.id}>
                                                            <label className="container-check">
                                                                <span
                                                                    className="text-short"
                                                                    data-tip={
                                                                        data.name?.length + data?.description?.length > 25
                                                                            ? `${data.name} ${data.description ? `(${data.description})` : ""}`
                                                                            : null
                                                                    }
                                                                    data-effect="solid"
                                                                    data-for="smart-chart-master-filter"
                                                                    data-place="left"
                                                                    data-background-color="#007bff"
                                                                    data-delay-show="500"
                                                                >
                                                                    {data.name} {data.description ? `(${data.description})` : ""}
                                                                </span>
                                                                <span className="float-right">({data.count})</span>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedItems.includes(data.id)}
                                                                    onChange={e => handleSelectDropdown(data.id, item.key)}
                                                                />
                                                                <span className="checkmark" />
                                                            </label>
                                                        </span>
                                                    ))}
                                            </div>
                                            {renderFilteredList(item.key)?.filter(fi => !masterFilters[item.paramKey]?.includes(fi.id))?.length ? (
                                                <div className="col-md-12 p-0 slt-ara">
                                                    {renderFilteredList(item.key)
                                                        ?.filter(fi => !selectedItems?.includes(fi.id))
                                                        ?.map(data => (
                                                            <span className="dropdown-item" key={data.id}>
                                                                <label className="container-check">
                                                                    <span
                                                                        className="text-short"
                                                                        data-tip={
                                                                            data.name?.length + data?.description?.length > 25
                                                                                ? `${data.name} ${data.description ? `(${data.description})` : ""}`
                                                                                : null
                                                                        }
                                                                        data-effect="solid"
                                                                        data-for="smart-chart-master-filter"
                                                                        data-place="left"
                                                                        data-background-color="#007bff"
                                                                        data-delay-show="500"
                                                                    >
                                                                        {data.name} {data.description ? `(${data.description})` : ""}
                                                                    </span>
                                                                    <span className="float-right">({data.count})</span>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={false}
                                                                        onChange={e => handleSelectDropdown(data.id, item.key)}
                                                                    />
                                                                    <span className="checkmark" />
                                                                </label>
                                                            </span>
                                                        ))}
                                                </div>
                                            ) : (
                                                <div className={`col-md-12`}>
                                                    {masterFilterList[item.key]?.filter(fi => masterFilters[item.paramKey]?.includes(fi.id))
                                                        ?.length === 0 &&
                                                    renderFilteredList(item.key)?.filter(fi => !masterFilters[item.paramKey]?.includes(fi.id))
                                                        ?.length === 0
                                                        ? "No data Found"
                                                        : "No more values to display"}
                                                </div>
                                            )}
                                            <div className="col-md-12 drp-btn">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => updateFilter(item.key, item.paramKey, item.label)}
                                                >
                                                    ok
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btnClr ml-2"
                                                    onClick={() => handleCancelFilter(item.key, item.paramKey)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    {((entity === "energy_mng" &&
                        (requiredFields?.energy_mng?.includes("em_start_year_month") || requiredFields?.energy_mng.includes("em_end_year_month"))) ||
                        (entity === "energy_mng" && requiredFields?.energy_mng?.length === 0)) &&
                        rowIndex === 0 && (
                            <div class={`col ${selectedFiltersList?.em_start_year_month?.length ? "bg-th-filtered" : ""}`}>
                                <div class={`dropdown-container ${selectedDropDown === "energy_mng_custom_years" ? "dropdown-open" : ""} `}>
                                    <div
                                        className={`dropdown-toggle click-dropdown ${selectedFiltersList?.years?.length ? "cursor-diabled" : ""}`}
                                        onClick={() =>
                                            !selectedFiltersList?.years?.length && handleClickEnergyYearsDropdown("energy_mng_custom_years", "years")
                                        }
                                    >
                                        Month & Year
                                        <span class="close-reg">
                                            <i class="fas fa-chevron-down"></i>
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            selectedDropDown === "energy_mng_custom_years"
                                                ? "dropdown-menu year-drop dropdown-active energy-mng-years-fil p-0"
                                                : "dropdown-menu"
                                        }
                                    >
                                        <div
                                            className="drop-filtr pb-3"
                                            onClick={e => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            <div className="col-md-12 p-0 slt-ara">
                                                <div className="year-outer ">
                                                    <div>
                                                        <button
                                                            className="clear-btn-selection sm-chart-year-fil-clr"
                                                            onClick={() =>
                                                                setEnergyMngYearFilters({
                                                                    start: {
                                                                        month: "",
                                                                        year: ""
                                                                    },
                                                                    end: {
                                                                        month: "",
                                                                        year: ""
                                                                    }
                                                                })
                                                            }
                                                        >
                                                            Clear
                                                        </button>
                                                    </div>

                                                    <div className="col-xl-12 selecbox-otr p-0 mb-4">
                                                        <span className="dropdown-item p-0">
                                                            <label>From</label>
                                                            <div className="energy-mng-years">
                                                                <div className="custom-selecbox">
                                                                    <select
                                                                        className={`${
                                                                            showErrorBorder && !energyMngYearFilters?.start?.year?.trim()?.length
                                                                                ? "error-border"
                                                                                : ""
                                                                        }`}
                                                                        value={energyMngYearFilters.start.year}
                                                                        name={"start"}
                                                                        onChange={e => {
                                                                            e.stopPropagation();
                                                                            let startYearValue = e.target.value;
                                                                            setEnergyMngYearFilters(prevValue => {
                                                                                return {
                                                                                    ...prevValue,
                                                                                    start: {
                                                                                        ...prevValue?.start,
                                                                                        year: startYearValue
                                                                                    }
                                                                                };
                                                                            });
                                                                        }}
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {getYearsListForEnergyFilters("year", "from")?.map(yearData => {
                                                                            return <option value={yearData.id}>{yearData.name}</option>;
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className="custom-selecbox">
                                                                    <select
                                                                        className={`${
                                                                            showErrorBorder && !energyMngYearFilters?.start?.month?.trim()?.length
                                                                                ? "error-border"
                                                                                : ""
                                                                        }`}
                                                                        value={energyMngYearFilters.start.month}
                                                                        name={"start"}
                                                                        onChange={e => {
                                                                            e.stopPropagation();
                                                                            let startMonthValue = e.target.value;
                                                                            setEnergyMngYearFilters(prevValue => {
                                                                                return {
                                                                                    ...prevValue,
                                                                                    start: {
                                                                                        ...prevValue?.start,
                                                                                        month: startMonthValue
                                                                                    }
                                                                                };
                                                                            });
                                                                        }}
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {getYearsListForEnergyFilters("month", "from")?.map(monthData => {
                                                                            return <option value={monthData}>{monthData}</option>;
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div className="col-xl-12 p-0 selecbox-otr p-0">
                                                        <span className="dropdown-item  p-0">
                                                            <label>To</label>
                                                            <div className="energy-mng-years">
                                                                <div className="custom-selecbox">
                                                                    <select
                                                                        className={`${
                                                                            showErrorBorder && !energyMngYearFilters?.end?.year?.trim()?.length
                                                                                ? "error-border"
                                                                                : ""
                                                                        }`}
                                                                        value={energyMngYearFilters.end.year}
                                                                        name={"end"}
                                                                        onChange={e => {
                                                                            e.stopPropagation();
                                                                            let endYearValue = e.target.value;
                                                                            setEnergyMngYearFilters(prevValue => {
                                                                                return {
                                                                                    ...prevValue,
                                                                                    end: {
                                                                                        ...prevValue?.end,
                                                                                        year: endYearValue
                                                                                    }
                                                                                };
                                                                            });
                                                                        }}
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {getYearsListForEnergyFilters("year", "to")?.map(yearData => {
                                                                            return <option value={yearData.id}>{yearData.name}</option>;
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className="custom-selecbox">
                                                                    <select
                                                                        className={`${
                                                                            showErrorBorder && !energyMngYearFilters?.end?.month?.trim()?.length
                                                                                ? "error-border"
                                                                                : ""
                                                                        }`}
                                                                        value={energyMngYearFilters.end.month}
                                                                        name={"end"}
                                                                        onChange={e => {
                                                                            e.stopPropagation();
                                                                            let endMonthValue = e.target.value;
                                                                            setEnergyMngYearFilters(prevValue => {
                                                                                return {
                                                                                    ...prevValue,
                                                                                    end: {
                                                                                        ...prevValue?.end,
                                                                                        month: endMonthValue
                                                                                    }
                                                                                };
                                                                            });
                                                                        }}
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {getYearsListForEnergyFilters("month", "to")?.map(monthData => {
                                                                            return <option value={monthData}>{monthData}</option>;
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3 drp-btn">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btnRgion mr-2"
                                                    onClick={() => updateFilter("energy_mng_custom_years", "energy_mng_custom_years", "Month & Year")}
                                                >
                                                    OK
                                                </button>
                                                <button type="button" className="btn btn-primary btnClr" onClick={() => handleCancelFilter()}>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    {entity === "project" && rowIndex === 3 ? <div class={`col`}></div> : null}
                </div>
            </div>
            {entity === "energy_mng" && rowIndex === 0 ? (
                <div class="col-md-4 p-0 pad-50">
                    <div class="d-flex itm-nmb">
                        <div class={`col ${selectedFiltersList?.project_ids?.length ? "bg-th-filtered" : ""}`}>
                            <div class={`dropdown-container ${selectedDropDown === "energy_mng_projects" ? "dropdown-open" : ""} `}>
                                <div class="dropdown-toggle click-dropdown" onClick={() => handleClickDropdown("energy_mng_projects", "project_ids")}>
                                    Projects (For Recommendation Data)
                                    <span class="close-reg">
                                        <i class="fas fa-chevron-down"></i>
                                    </span>
                                </div>
                                {selectedDropDown === "energy_mng_projects" && (
                                    <div className={`dropdown-menu p-0 ${selectedDropDown === "energy_mng_projects" ? "dropdown-active p-0" : ""}`}>
                                        <ReactTooltip id="smart-chart-master-filter" />
                                        <div className="drop-filtr dp-rcm-overflow" onClick={e => e.stopPropagation()}>
                                            <div className="col-md-12 p-0 border-bottom pt-1">
                                                <span className="dropdown-item build_search_drp">
                                                    <input
                                                        type="search"
                                                        placeholder="Search..."
                                                        value={search.energy_mng_projects}
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            setSearch(prevSearch => {
                                                                return { ...prevSearch, energy_mng_projects: value };
                                                            });
                                                        }}
                                                    />
                                                    <i
                                                        class="fas fa-times cursor-pointer cls-close"
                                                        onClick={() =>
                                                            setSearch(prevSearch => {
                                                                return { ...prevSearch, energy_mng_projects: "" };
                                                            })
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div className="col-md-12 p-0 border-bottom pt-1">
                                                <div className="dropdown-item select_section_outer">
                                                    <label className="container-check">
                                                        Select all
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                selectedItems.length &&
                                                                masterFilterList.energy_mng_projects?.length == selectedItems.length
                                                            }
                                                            onClick={e => ""}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <button className="clear-btn-selection" onClick={() => setSelectedItems([])}>
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-12 p-0 slt-ara border-bottom">
                                                {masterFilterList.energy_mng_projects
                                                    ?.filter(fi => selectedItems?.includes(fi.id))
                                                    ?.map(data => (
                                                        <span className="dropdown-item" key={data.id}>
                                                            <label className="container-check">
                                                                <span
                                                                    className="text-short"
                                                                    data-tip={
                                                                        data.name?.length + data?.description?.length > 25
                                                                            ? `${data.name} ${data.description ? `(${data.description})` : ""}`
                                                                            : null
                                                                    }
                                                                    data-effect="solid"
                                                                    data-for="smart-chart-master-filter"
                                                                    data-place="left"
                                                                    data-background-color="#007bff"
                                                                    data-delay-show="500"
                                                                >
                                                                    {data.name} {data.description ? `(${data.description})` : ""}
                                                                </span>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedItems.includes(data.id)}
                                                                    onChange={e => handleSelectDropdown(data.id, "energy_mng_projects")}
                                                                />
                                                                <span className="checkmark" />
                                                            </label>
                                                        </span>
                                                    ))}
                                            </div>
                                            {renderFilteredList("energy_mng_projects")?.filter(fi => !masterFilters.project_ids?.includes(fi.id))
                                                ?.length ? (
                                                <div className="col-md-12 p-0 slt-ara">
                                                    {renderFilteredList("energy_mng_projects")
                                                        ?.filter(fi => !selectedItems?.includes(fi.id))
                                                        ?.map(data => (
                                                            <span className="dropdown-item" key={data.id}>
                                                                <label className="container-check">
                                                                    <span
                                                                        className="text-short"
                                                                        data-tip={
                                                                            data.name?.length + data?.description?.length > 25
                                                                                ? `${data.name} ${data.description ? `(${data.description})` : ""}`
                                                                                : null
                                                                        }
                                                                        data-effect="solid"
                                                                        data-for="smart-chart-master-filter"
                                                                        data-place="left"
                                                                        data-background-color="#007bff"
                                                                        data-delay-show="500"
                                                                    >
                                                                        {data.name} {data.description ? `(${data.description})` : ""}
                                                                    </span>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={false}
                                                                        onChange={e => handleSelectDropdown(data.id, "energy_mng_projects")}
                                                                    />
                                                                    <span className="checkmark" />
                                                                </label>
                                                            </span>
                                                        ))}
                                                </div>
                                            ) : (
                                                <div className={`col-md-12`}>
                                                    {masterFilterList["energy_mng_projects"]?.filter(fi =>
                                                        masterFilters["project_ids"]?.includes(fi.id)
                                                    )?.length === 0 &&
                                                    renderFilteredList("energy_mng_projects")?.filter(
                                                        fi => !masterFilters["project_ids"]?.includes(fi.id)
                                                    )?.length === 0
                                                        ? "No data Found"
                                                        : "No more values to display"}
                                                </div>
                                            )}
                                            <div className="col-md-12 drp-btn">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => updateFilter("energy_mng_projects", "project_ids", "Projects")}
                                                >
                                                    ok
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btnClr ml-2"
                                                    onClick={() => handleCancelFilter("energy_mng_projects", "project_ids", "Projects")}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
export default MasterFilter;
