import _ from "lodash";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import ReactTooltip from "react-tooltip";
import actions from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { RecommendationBand as AssetBand } from "../../recommendations/components/Bands/RecommendationBand";
import { FormField } from "../../recommendations/components/Bands/FormField";
import { DYNAMIC_FIELD_TYPES } from "../../common/constants";
import Portal from "../../common/components/Portal";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../common/components/Loader";
import { getCurrentAge, getUsefullLifeRemaining } from "../../../config/utils";
import { assetBands, filterFields, loaderKeys, multiEditFields } from "./FilterModal/constants";
import { FilterSearch } from "./FilterModal/FilterSearch";

const MultiSelectEditForm = ({ selectedAssetsIds = [], onCancel, handleMultiAssetUpdate }) => {
    const initialAssetData = {
        asset_name: "",
        asset_tag: "",
        asset_note: "",
        client_asset_condition_id: "",
        description: "",
        installed_year: "",
        service_life: "",
        usefull_life_remaining: "",
        crv: "",
        csp_unit: "",
        manufacturer: "",
        year_manufactured: "",
        model_number: "",
        core_model_number: "",
        serial_number: "",
        capacity: "",
        capacity_unit: "",
        asset_ownership: "",
        criticality: "",
        area_served: "",

        client_id: "",
        system_id: "",
        sub_system_id: "",
        trade_id: "",
        region_id: "",
        site_id: "",
        building_id: "",
        addition_id: "",
        floor_id: "",
        room_number: "",
        room_name: "",
        location: "",
        architectural_room_number: "",
        additional_room_description: "",

        uniformat_level_1_id: "",
        uniformat_level_2_id: "",
        uniformat_level_3_id: "",
        uniformat_level_4_id: "",
        uniformat_level_5_id: "",
        uniformat_level_6_id: "",
        uniformat_level_6_description: "",
        uniformat_level_3_custom_description: "",

        asset_type_id: "",
        asset_description: "",
        asset_barcode: "",
        asset_client_id: "",
        asset_cmms_id: "",

        warranty_start: "",
        warranty_end: "",
        install_date: "",
        startup_date: "",
        upstream_asset_barcode_number: "",
        linked_asset_barcode_number: "",
        source_panel_barcode_number: "",
        source_panel_emergency: "",
        asset_status_id: "",
        notes: "",

        main_category_id: "",
        sub_category_1_id: "",
        sub_category_2_id: "",
        subcategory2_description: "",
        sub_category_3_id: "",
        guid: "",
        source_panel_name: "",
        skysite_hyperlink: "",
        quantity: "",

        rtls_tag: "",
        latitude: "",
        longitude: "",
        current_age: "",
        age: "",
        new_asset: "",
        parent_global_id: "",
        survey_global_id: "",
        survey_id: "",
        survey_property_note: "",
        capacity_status: "",
        unit_cost: "",
        installed_year_status: "",
        name_plate_status: "",
        qa_notes: "",
        additional_qa_notes: "",
        surveyor: "",
        editor: "",
        survey_date_created: "",
        survey_date_edited: "",
        refrigerant: "",

        discharge: "",
        return: "",
        unit_property_labeled: "",
        factory_mounted_disconnect: "",
        unit_voltage: "",
        ahu_voltage: "",
        wire_type: "",
        wire_size: "",
        estimated_wire_run_length: "",
        breaker_size: "",
        mocp: "",
        mca: "",
        liquid_line: "",
        suction_line: "",
        hot_gas_by_pass: "",
        number_of_circuits: "",
        length_of_line: "",
        damper_type: "",
        gas_heating: "",
        gas_output_btu: "",
        electric_heating: "",
        electric_heating_kw: "",
        pump_backup_heat_kw: "",
        cooling_only: "",
        controls: "",
        control_type: "",
        smoke_detector: "",
        smoke_detector_mounting: "",
        smoke_detector_supply: "",
        smoke_detector_return: "",
        curb: "",
        curb_description: "",
        vibration_isolation: "",
        burglar_bars: "",
        installed_on_adapter_curb: "",
        curb_outside_dimension_length: "",
        curb_outside_dimension_width: "",
        allow_curb_adaptor: "",
        ducted_or_concentric_system: "",
        unit_weight: "",
        codes_for_disconnects: "",
        codes_for_convenience_outlets: "",
        codes_for_roof_lighting: "",
        duct_work_attached_to_bottom_of_unit: "",
        aluminium_wire_used: "",
        code_or_safety_concerns: "",
        distance_from_salt_water_body: "",
        recommend_coil_coating: "",
        need_resealed: "",
        split_system_line_sets_good_condition: "",
        split_system_line_sets_damaged: "",
        split_system_line_sets_crimped: "",
        split_system_line_sets_insulated: "",
        split_system_line_sets_re_used: "",
        line_connection_type: "",
        boiler_type: "",
        boiler_burner_type: "",
        boiler_btu_input: "",
        boiler_btu_output: "",
        boiler_hp: "",
        boiler_fuel: "",
        boiler_gas_pressure: "",
        boiler_kw_input: "",
        boiler_power_burner_type: "",
        boiler_burner_model: "",
        boiler_burner_serial: "",
        boiler_pressure_type: "",
        confirm_boiler_pressure: "",
        auxiliary_equipment_name: "",
        auxiliary_model: "",
        auxiliary_serial: "",
        auxiliary_date_manufactured: "",
        auxiliary_manufacturer: "",
        auxiliary_condition: "",
        auxiliary_eul: "",
        auxiliary_crv: ""
    };
    const dispatch = useDispatch();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [initialValues, setInitialValues] = useState(initialAssetData);
    const [asset, setAsset] = useState({
        ...initialAssetData
    });
    const [showGroupUpdateConfirmation, setShowGroupUpdateConfirmation] = useState(false);
    const [fieldSearch, setFieldSearch] = useState("");

    const [showErrorBorder, setShowErrorBorder] = useState(false);
    const [errorParams, setErrorParams] = useState({
        name: ""
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [showBand, setShowBand] = useState({});
    const { dropDownList, getAssetCommonDataByIdsResponse, getAssetCommonDataLoading, updateMultipleAssetsLoading, dropDownLoading } = useSelector(
        state => state.assetReducer
    );
    useEffect(() => {
        dispatch(actions.getAssetCommonDataByIds({ asset_ids: (selectedAssetsIds ?? []).map(e => e.id) }));
        return () => {
            dispatch(actions.resetMultiAssetCommonData());
        };
    }, []);

    useEffect(() => {
        if (dropDownList?.sub_category_2s && asset?.uniformat_level_6_id) {
            let res =
                asset.uniformat_level_6_id &&
                asset.uniformat_level_6_id.length &&
                dropDownList?.sub_category_2s.filter(item => item?.id === asset.uniformat_level_6_id);
            setAsset({
                ...asset,
                subcategory2_description: res.length && res[0]?.subcategory2_description
            });
        }
    }, [dropDownList?.sub_category_2s, asset?.uniformat_level_6_id]);

    useEffect(() => {
        if (getAssetCommonDataByIdsResponse?.common_fields) {
            const { common_fields } = getAssetCommonDataByIdsResponse;
            const assetData = {
                asset_name: common_fields?.asset_name,
                asset_tag: common_fields?.asset_tag,
                asset_note: common_fields?.asset_note,
                client_asset_condition_id: common_fields?.client_asset_condition_id,
                description: common_fields?.description,
                installed_year: common_fields?.installed_year ? parseInt(common_fields.installed_year) : "",
                service_life: common_fields?.service_life ? parseInt(common_fields?.service_life) : "",
                usefull_life_remaining: common_fields?.usefull_life_remaining,
                crv: common_fields?.crv ? parseInt(common_fields?.crv) : "",
                csp_unit: common_fields?.csp_unit,
                manufacturer: common_fields?.manufacturer,
                year_manufactured: common_fields?.year_manufactured ? parseInt(common_fields.year_manufactured) : "",
                model_number: common_fields?.model_number,
                core_model_number: common_fields?.core_model_number,
                serial_number: common_fields?.serial_number,
                capacity: common_fields?.capacity,
                capacity_unit: common_fields?.capacity_unit,
                asset_ownership: common_fields?.asset_ownership,
                criticality: common_fields?.criticality,
                area_served: common_fields?.area_served,
                client_id: common_fields?.client_id,
                system_id: common_fields?.system_id,
                sub_system_id: common_fields?.sub_system_id,
                trade_id: common_fields?.trade_id,
                region_id: common_fields?.region_id,
                site_id: common_fields?.site_id,
                building_id: common_fields?.building_id,
                addition_id: common_fields?.addition_id,
                floor_id: common_fields?.floor_id,
                room_number: common_fields?.room_number,
                room_name: common_fields?.room_name,
                location: common_fields?.location,
                architectural_room_number: common_fields?.architectural_room_number,
                additional_room_description: common_fields?.additional_room_description,
                uniformat_level_1_id: common_fields?.uniformat_level_1_id,
                uniformat_level_2_id: common_fields?.uniformat_level_2_id,
                uniformat_level_3_id: common_fields?.uniformat_level_3_id,
                uniformat_level_4_id: common_fields?.uniformat_level_4_id,
                uniformat_level_5_id: common_fields?.uniformat_level_5_id,
                uniformat_level_6_id: common_fields?.uniformat_level_6_id,
                uniformat_level_6_description: common_fields?.uniformat_level_6_description,
                uniformat_level_3_custom_description: common_fields?.uniformat_level_3_custom_description,
                asset_type_id: common_fields?.asset_type_id,
                asset_description: common_fields?.asset_description,
                asset_barcode: common_fields?.asset_barcode,
                asset_client_id: common_fields?.asset_client_id,
                asset_cmms_id: common_fields?.asset_cmms_id,
                warranty_start: common_fields?.warranty_start,
                warranty_end: common_fields?.warranty_end,
                install_date: common_fields?.install_date,
                startup_date: common_fields?.startup_date,
                upstream_asset_barcode_number: common_fields?.upstream_asset_barcode_number,
                linked_asset_barcode_number: common_fields?.linked_asset_barcode_number,
                source_panel_barcode_number: common_fields?.source_panel_barcode_number,
                source_panel_emergency: common_fields?.source_panel_emergency,
                asset_status_id: common_fields?.asset_status_id,
                notes: common_fields?.notes,
                main_category_id: common_fields?.main_category_id,
                sub_category_1_id: common_fields?.sub_category_1_id,
                sub_category_2_id: common_fields?.sub_category_2_id,
                subcategory2_description: common_fields?.subcategory2_description,
                sub_category_3_id: common_fields?.sub_category_3_id,
                guid: common_fields?.guid,
                source_panel_name: common_fields?.source_panel_name,
                skysite_hyperlink: common_fields?.skysite_hyperlink,
                quantity: common_fields?.quantity ? parseInt(common_fields?.quantity) : "",
                rtls_tag: common_fields?.rtls_tag,
                latitude: common_fields?.latitude,
                longitude: common_fields?.longitude,
                current_age: common_fields?.current_age,
                age: common_fields?.age,
                new_asset: common_fields?.new_asset,
                parent_global_id: common_fields?.parent_global_id,
                survey_global_id: common_fields?.survey_global_id,
                survey_id: common_fields?.survey_id,
                survey_property_note: common_fields?.survey_property_note,
                capacity_status: common_fields?.capacity_status,
                unit_cost: common_fields?.unit_cost,
                installed_year_status: common_fields?.installed_year_status,
                name_plate_status: common_fields?.name_plate_status,
                qa_notes: common_fields?.qa_notes,
                additional_qa_notes: common_fields?.additional_qa_notes,
                surveyor: common_fields?.surveyor,
                editor: common_fields?.editor,
                survey_date_created: common_fields?.survey_date_created,
                survey_date_edited: common_fields?.survey_date_edited,
                refrigerant: common_fields?.refrigerant,
                discharge: common_fields?.discharge,
                return: common_fields?.return,
                unit_property_labeled: common_fields?.unit_property_labeled,
                factory_mounted_disconnect: common_fields?.factory_mounted_disconnect,
                unit_voltage: common_fields?.unit_voltage,
                ahu_voltage: common_fields?.ahu_voltage,
                wire_type: common_fields?.wire_type,
                wire_size: common_fields?.wire_size,
                estimated_wire_run_length: common_fields?.estimated_wire_run_length,
                breaker_size: common_fields?.breaker_size,
                mocp: common_fields?.mocp ? parseInt(common_fields?.mocp) : "",
                mca: common_fields?.mca,
                liquid_line: common_fields?.liquid_line,
                suction_line: common_fields?.suction_line,
                hot_gas_by_pass: common_fields?.hot_gas_by_pass,
                number_of_circuits: common_fields?.number_of_circuits ? parseInt(common_fields?.number_of_circuits) : "",
                length_of_line: common_fields?.length_of_line,
                damper_type: common_fields?.damper_type,
                gas_heating: common_fields?.gas_heating,
                gas_output_btu: common_fields?.gas_output_btu,
                electric_heating: common_fields?.electric_heating,
                electric_heating_kw: common_fields?.electric_heating_kw,
                pump_backup_heat_kw: common_fields?.pump_backup_heat_kw,
                cooling_only: common_fields?.cooling_only,
                controls: common_fields?.controls,
                control_type: common_fields?.control_type,
                smoke_detector: common_fields?.smoke_detector,
                smoke_detector_mounting: common_fields?.smoke_detector_mounting,
                smoke_detector_supply: common_fields?.smoke_detector_supply,
                smoke_detector_return: common_fields?.smoke_detector_return,
                curb: common_fields?.curb,
                curb_description: common_fields?.curb_description,
                vibration_isolation: common_fields?.vibration_isolation,
                burglar_bars: common_fields?.burglar_bars,
                installed_on_adapter_curb: common_fields?.installed_on_adapter_curb,
                curb_outside_dimension_length: common_fields?.curb_outside_dimension_length,
                curb_outside_dimension_width: common_fields?.curb_outside_dimension_width,
                allow_curb_adaptor: common_fields?.allow_curb_adaptor,
                ducted_or_concentric_system: common_fields?.ducted_or_concentric_system,
                unit_weight: common_fields?.unit_weight,
                codes_for_disconnects: common_fields?.codes_for_disconnects,
                codes_for_convenience_outlets: common_fields?.codes_for_convenience_outlets,
                codes_for_roof_lighting: common_fields?.codes_for_roof_lighting,
                duct_work_attached_to_bottom_of_unit: common_fields?.duct_work_attached_to_bottom_of_unit,
                aluminium_wire_used: common_fields?.aluminium_wire_used,
                code_or_safety_concerns: common_fields?.code_or_safety_concerns,
                distance_from_salt_water_body: common_fields?.distance_from_salt_water_body,
                recommend_coil_coating: common_fields?.recommend_coil_coating,
                need_resealed: common_fields?.need_resealed,
                split_system_line_sets_good_condition: common_fields?.split_system_line_sets_good_condition,
                split_system_line_sets_damaged: common_fields?.split_system_line_sets_damaged,
                split_system_line_sets_crimped: common_fields?.split_system_line_sets_crimped,
                split_system_line_sets_insulated: common_fields?.split_system_line_sets_insulated,
                split_system_line_sets_re_used: common_fields?.split_system_line_sets_re_used,
                line_connection_type: common_fields?.line_connection_type,
                boiler_type: common_fields?.boiler_type,
                boiler_burner_type: common_fields?.boiler_burner_type,
                boiler_btu_input: common_fields?.boiler_btu_input,
                boiler_btu_output: common_fields?.boiler_btu_output,
                boiler_hp: common_fields?.boiler_hp,
                boiler_fuel: common_fields?.boiler_fuel,
                boiler_gas_pressure: common_fields?.boiler_gas_pressure,
                boiler_kw_input: common_fields?.boiler_kw_input,
                boiler_power_burner_type: common_fields?.boiler_power_burner_type,
                boiler_burner_model: common_fields?.boiler_burner_model,
                boiler_burner_serial: common_fields?.boiler_burner_serial,
                boiler_pressure_type: common_fields?.boiler_pressure_type,
                confirm_boiler_pressure: common_fields?.confirm_boiler_pressure,
                auxiliary_equipment_name: common_fields?.auxiliary_equipment_name,
                auxiliary_model: common_fields?.auxiliary_model,
                auxiliary_serial: common_fields?.auxiliary_serial,
                auxiliary_date_manufactured: common_fields?.auxiliary_date_manufactured,
                auxiliary_manufacturer: common_fields?.auxiliary_manufacturer,
                auxiliary_condition: common_fields?.auxiliary_condition,
                auxiliary_eul: common_fields?.auxiliary_eul,
                auxiliary_crv: common_fields?.auxiliary_crv
            };
            setAsset(assetData);
            setInitialValues(assetData);
            getInitialDropdowns(assetData);
        }
    }, [getAssetCommonDataByIdsResponse]);

    const confirmCancel = () => {
        if (_.isEqual(initialValues, asset)) {
            onCancel();
        } else {
            setShowConfirmModal(true);
        }
    };

    const handleBandClick = (key, value) => {
        setShowBand({
            ...showBand,
            [key]: value
        });
    };

    const handleChange = async (e, name, fieldType) => {
        let value = "";
        if (fieldType === DYNAMIC_FIELD_TYPES.SELECT) {
            value = e;
        } else if (fieldType === DYNAMIC_FIELD_TYPES.DATE) {
            value = e ? moment(e).format("YYYY-MM-DD") : null;
        } else if (fieldType === DYNAMIC_FIELD_TYPES.NUMBER) {
            value = ["boiler_hp", "mca", "latitude", "longitude"].includes(name) ? e.value : parseInt(e.value);
        } else {
            value = e.target.value;
        }
        let tempAsset = {
            ...asset,
            [name]: value
        };
        if (name === "uniformat_level_1_id") {
            tempAsset = await handleLevel1Select(tempAsset);
        } else if (name === "uniformat_level_2_id") {
            tempAsset = await handleLevel2Select(tempAsset);
        } else if (name === "uniformat_level_3_id") {
            tempAsset = await handleLevel3Select(tempAsset);
        } else if (name === "uniformat_level_4_id") {
            tempAsset = await handleLevel4Select(tempAsset);
        } else if (name === "uniformat_level_5_id") {
            tempAsset = await handleLevel5Select(tempAsset);
        } else if (name === "uniformat_level_6_id") {
            await handleLevel6Select(tempAsset);
            let res = value && value.length && dropDownList?.uniformat_level_6s.filter(item => item.id === value);
            tempAsset = {
                ...tempAsset,
                uniformat_level_6_description: res.length && res[0]?.uniformat_level_6_description
            };
        } else if (name === "client_id") {
            tempAsset = await handleClientSelect(tempAsset);
        } else if (name === "region_id") {
            tempAsset = await handleRegionSelect(tempAsset);
        } else if (name === "site_id") {
            tempAsset = await handleSiteSelect(tempAsset);
        } else if (name === "building_id") {
            tempAsset = await handleBuildingSelect(tempAsset);
        } else if (name === "main_category_id") {
            tempAsset = await handleMainCategorySelect(tempAsset);
        } else if (name === "sub_category_1_id") {
            tempAsset = await handleSubCategory1Select(tempAsset);
        } else if (name === "sub_category_2_id") {
            tempAsset = await handleSubCategory2Select(tempAsset);
        } else if (name === "trade_id") {
            tempAsset = await handleTradeSelect(tempAsset);
        } else if (name === "system_id") {
            tempAsset = await handleSystemSelect(tempAsset);
        }
        setAsset(tempAsset);
    };

    const handleClientSelect = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            asset_type_id: "",
            asset_status_id: "",
            client_asset_condition_id: "",
            region_id: "",
            site_id: "",
            building_id: "",
            addition_id: "",
            floor_id: ""
        };
        dispatch(
            actions.getDropdownList("regions", {
                client_id: tempAsset.client_id
            })
        );
        dispatch(
            actions.getDropdownList("asset_statuses", {
                client_id: tempAsset.client_id
            })
        );
        dispatch(
            actions.getDropdownList("asset_types", {
                client_id: tempAsset.client_id
            })
        );
        dispatch(
            actions.getDropdownList("asset_conditions", {
                client_id: tempAsset.client_id
            })
        );
        dispatch(
            actions.getDropdownList("main_categories", {
                client_id: tempAsset.client_id
            })
        );
        return tempAsset;
    };

    const handleRegionSelect = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            site_id: "",
            building_id: "",
            addition_id: "",
            floor_id: ""
        };
        dispatch(
            actions.getDropdownList("sites", {
                region_id: tempAsset.region_id
            })
        );
        return tempAsset;
    };

    const handleSiteSelect = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            building_id: "",
            addition_id: "",
            floor_id: ""
        };

        dispatch(
            actions.getDropdownList("buildings", {
                site_id: tempAsset.site_id
            })
        );
        return tempAsset;
    };

    const handleBuildingSelect = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            addition_id: "",
            floor_id: "",
            building_type: dropDownList?.buildings?.find(item => item.id === tempAsset.building_id)?.building_type
        };
        dispatch(
            actions.getDropdownList("floors", {
                building_id: tempAsset.building_id
            })
        );
        dispatch(
            actions.getDropdownList("additions", {
                building_id: tempAsset.building_id
            })
        );
        return tempAsset;
    };

    const handleMainCategorySelect = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            sub_category_1_id: "",
            sub_category_2_id: "",
            sub_category_3_id: ""
        };

        dispatch(
            actions.getDropdownList("sub_category_1s", {
                client_id: tempAsset.client_id,
                main_category_id: tempAsset.main_category_id
            })
        );
        return tempAsset;
    };

    const handleSubCategory1Select = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            sub_category_2_id: "",
            sub_category_3_id: ""
        };
        dispatch(
            actions.getDropdownList("sub_category_2s", {
                client_id: tempAsset.client_id,
                main_category_id: tempAsset.main_category_id,
                sub_category_1_id: tempAsset.sub_category_1_id
            })
        );
        return tempAsset;
    };

    const handleSubCategory2Select = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            sub_category_3_id: ""
        };

        dispatch(
            actions.getDropdownList("sub_category_3s", {
                client_id: tempAsset.client_id,
                main_category_id: tempAsset.main_category_id,
                sub_category_1_id: tempAsset.sub_category_1_id,
                sub_category_2_id: tempAsset.sub_category_2_id
            })
        );
        return tempAsset;
    };

    const handleTradeSelect = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            system_id: "",
            sub_system_id: ""
        };

        dispatch(
            actions.getDropdownList("systems", {
                trade_id: tempAsset.trade_id,
                client_id: tempAsset.client_id
            })
        );
        return tempAsset;
    };
    const handleSystemSelect = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            sub_system_id: ""
        };

        dispatch(
            actions.getDropdownList("sub_systems", {
                client_id: tempAsset.client_id,
                system_id: tempAsset.system_id
            })
        );
        return tempAsset;
    };

    const renderGroupUpdateConfirmationModal = () => {
        if (!showGroupUpdateConfirmation) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={
                            <>
                                You are about to modify <span className="badge-red-circled">{selectedAssetsIds.length}</span> Assets. This action
                                cannot be undone!
                            </>
                        }
                        type="cancel"
                        message={"Are you sure you want to continue?"}
                        onNo={() => setShowGroupUpdateConfirmation(false)}
                        onYes={() => {
                            setShowGroupUpdateConfirmation(false);
                            updateSelectedRecommendations();
                        }}
                    />
                }
                onCancel={() => setShowGroupUpdateConfirmation(false)}
            />
        );
    };

    const updateSelectedRecommendations = () => {
        const additionalAssetFieldsArray = [
            "discharge",
            "return",
            "unit_property_labeled",
            "factory_mounted_disconnect",
            "unit_voltage",
            "ahu_voltage",
            "wire_type",
            "wire_size",
            "estimated_wire_run_length",
            "breaker_size",
            "mocp",
            "mca",
            "liquid_line",
            "suction_line",
            "hot_gas_by_pass",
            "number_of_circuits",
            "length_of_line",
            "damper_type",
            "gas_heating",
            "gas_output_btu",
            "electric_heating",
            "electric_heating_kw",
            "pump_backup_heat_kw",
            "cooling_only",
            "controls",
            "control_type",
            "smoke_detector",
            "smoke_detector_mounting",
            "smoke_detector_supply",
            "smoke_detector_return",
            "curb",
            "curb_description",
            "vibration_isolation",
            "burglar_bars",
            "installed_on_adapter_curb",
            "curb_outside_dimension_length",
            "curb_outside_dimension_width",
            "allow_curb_adaptor",
            "ducted_or_concentric_system",
            "unit_weight",
            "codes_for_disconnects",
            "codes_for_convenience_outlets",
            "codes_for_roof_lighting",
            "duct_work_attached_to_bottom_of_unit",
            "aluminium_wire_used",
            "code_or_safety_concerns",
            "distance_from_salt_water_body",
            "recommend_coil_coating",
            "need_resealed",
            "split_system_line_sets_good_condition",
            "split_system_line_sets_damaged",
            "split_system_line_sets_crimped",
            "split_system_line_sets_insulated",
            "split_system_line_sets_re_used",
            "line_connection_type",
            "boiler_type",
            "boiler_burner_type",
            "boiler_btu_input",
            "boiler_btu_output",
            "boiler_hp",
            "boiler_fuel",
            "boiler_gas_pressure",
            "boiler_kw_input",
            "boiler_power_burner_type",
            "boiler_burner_model",
            "boiler_burner_serial",
            "boiler_pressure_type",
            "confirm_boiler_pressure"
        ];

        let newData = {};
        Object.entries(asset).forEach(([key, value]) => {
            if (!_.isEqual(value, initialValues[key])) {
                if (additionalAssetFieldsArray.includes(key)) {
                    newData = { ...newData, asset_additional: { ...newData.asset_additional, [key]: value } };
                } else newData = { ...newData, asset: { ...newData.asset, [key]: value } };
            }
        });
        newData = { ...newData, asset_ids: (selectedAssetsIds ?? []).map(e => e.id) };
        handleMultiAssetUpdate(newData);
    };

    const getInitialDropdowns = assetData => {
        dispatch(actions.getDropdownList("clients"));
        dispatch(actions.getDropdownList("uniformat_level_1s"));
        const { client_id, region_id, site_id, building_id, trade_id, system_id } = assetData;
        if (client_id) {
            dispatch(
                actions.getDropdownList("trades", {
                    client_id: client_id
                })
            );
            dispatch(
                actions.getDropdownList("asset_statuses", {
                    client_id: client_id
                })
            );
            dispatch(
                actions.getDropdownList("asset_types", {
                    client_id: client_id
                })
            );
            dispatch(
                actions.getDropdownList("asset_conditions", {
                    client_id: client_id
                })
            );
            dispatch(
                actions.getDropdownList("regions", {
                    client_id: client_id
                })
            );
            dispatch(
                actions.getDropdownList("main_categories", {
                    client_id: client_id
                })
            );
        }
        if (region_id)
            dispatch(
                actions.getDropdownList("sites", {
                    region_id: region_id
                })
            );
        if (site_id)
            dispatch(
                actions.getDropdownList("buildings", {
                    site_id: site_id
                })
            );
        if (building_id) {
            dispatch(
                actions.getDropdownList("floors", {
                    building_id: building_id
                })
            );
            dispatch(
                actions.getDropdownList("additions", {
                    building_id: building_id
                })
            );
        }
        if (trade_id)
            dispatch(
                actions.getDropdownList("systems", {
                    client_id: client_id,
                    trade_id: trade_id
                })
            );
        if (system_id)
            dispatch(
                actions.getDropdownList("sub_systems", {
                    client_id: client_id,
                    system_id: system_id
                })
            );
    };

    const renderConfirmationModal = () => {
        if (!showConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Do you want to ${asset ? "cancel" : "clear"} and lose all changes?`}
                        type="cancel"
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => setShowConfirmModal(false)}
                        onYes={() => onCancel()}
                    />
                }
                onCancel={() => setShowConfirmModal(false)}
            />
        );
    };

    const handleLevel1Select = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            uniformat_level_2_id: "",
            uniformat_level_3_id: "",
            uniformat_level_4_id: "",
            uniformat_level_5_id: ""
        };
        dispatch(
            actions.getDropdownList("uniformat_level_2s", {
                uniformat_level_1_id: tempAsset.uniformat_level_1_id
            })
        );
        return tempAsset;
    };

    const handleLevel2Select = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            uniformat_level_3_id: "",
            uniformat_level_4_id: "",
            uniformat_level_5_id: ""
        };
        dispatch(
            actions.getDropdownList("uniformat_level_3s", {
                uniformat_level_1_id: tempAsset.uniformat_level_1_id,
                uniformat_level_2_id: tempAsset.uniformat_level_2_id
            })
        );
        return tempAsset;
    };

    const handleLevel3Select = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            uniformat_level_4_id: "",
            uniformat_level_5_id: ""
        };
        dispatch(
            actions.getDropdownList("uniformat_level_4s", {
                uniformat_level_1_id: tempAsset.uniformat_level_1_id,
                uniformat_level_2_id: tempAsset.uniformat_level_2_id,
                uniformat_level_3_id: tempAsset.uniformat_level_3_id
            })
        );
        return tempAsset;
    };
    const handleLevel4Select = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            uniformat_level_5_id: ""
        };
        dispatch(
            actions.getDropdownList("uniformat_level_5s", {
                uniformat_level_1_id: tempAsset.uniformat_level_1_id,
                uniformat_level_2_id: tempAsset.uniformat_level_2_id,
                uniformat_level_3_id: tempAsset.uniformat_level_3_id,
                uniformat_level_4_id: tempAsset.uniformat_level_4_id
            })
        );
        return tempAsset;
    };
    const handleLevel5Select = async tempAsset => {
        tempAsset = {
            ...tempAsset,
            uniformat_level_6_id: ""
        };
        dispatch(
            actions.getDropdownList("uniformat_level_6s", {
                uniformat_level_1_id: tempAsset.uniformat_level_1_id,
                uniformat_level_2_id: tempAsset.uniformat_level_2_id,
                uniformat_level_3_id: tempAsset.uniformat_level_3_id,
                uniformat_level_4_id: tempAsset.uniformat_level_4_id,
                uniformat_level_5_id: tempAsset.uniformat_level_5_id
            })
        );
        return tempAsset;
    };

    const handleLevel6Select = async tempAsset => {
        dispatch(
            actions.getDropdownList("uniformat_level_6s", {
                uniformat_level_1_id: tempAsset.uniformat_level_1_id,
                uniformat_level_2_id: tempAsset.uniformat_level_2_id,
                uniformat_level_3_id: tempAsset.uniformat_level_3_id,
                uniformat_level_4_id: tempAsset.uniformat_level_4_id,
                uniformat_level_5_id: tempAsset.uniformat_level_5_id,
                uniformat_level_6_id: tempAsset.uniformat_level_6_id
            })
        );
    };

    const multiselectClass = field => {
        let selectboxClass = "";
        // if all data common
        if ((asset[field] || initialValues[field]) && _.isEqual(asset[field], initialValues[field])) {
            selectboxClass = "common-data ";
        } // if field get updated
        else if ((asset[field] || initialValues[field]) && !_.isEqual(asset[field], initialValues[field])) {
            selectboxClass = "dirty-field ";
        }
        return selectboxClass;
    };

    const disabledFields = fieldParam => {
        const fields = [
            { key: "client_id", label: "Client" },
            { key: "region_id", label: "Region" },
            { key: "site_id", label: "Site" },
            { key: "building_id", label: "Building" }
        ];

        const selectedField = fields.find(e => e.key === fieldParam)?.label;

        if (!selectedField) return {};

        return {
            "data-tip": `<b>Cannot update ${selectedField} field for multiple Assets. To update ${selectedField} field, please select one Asset at a time.</b>`,
            "data-for": "asset_details",
            "data-delay-show": 500,
            "data-place": "top",
            "data-html": true
        };
    };

    let fieldsToBeRendered = filterFields.filter(e => e.multiEditKey && multiEditFields[e.multiEditKey]);

    if (fieldSearch && fieldSearch?.trim()?.length) {
        fieldsToBeRendered = fieldsToBeRendered.filter(field => field.label.toLowerCase().includes(fieldSearch.toLowerCase()));
    }

    const selectOptions = {
        asset_type_id: dropDownList?.asset_types ?? [],
        asset_status_id: dropDownList?.asset_statuses ?? [],
        client_asset_condition_id: dropDownList?.asset_conditions ?? [],
        asset_ownership: [
            { id: "Owned", name: "Owned" },
            { id: "Leased", name: "Leased" },
            { id: "Other", name: "Other" }
        ],

        client_id: dropDownList?.clients || [],

        region_id: asset?.client_id ? dropDownList?.regions : [],
        site_id: asset?.region_id ? dropDownList?.sites : [],
        building_id: asset?.site_id ? dropDownList?.buildings : [],
        addition_id: asset?.building_id ? dropDownList?.additions : [],
        floor_id: asset?.building_id ? dropDownList?.floors : [],
        uniformat_level_1_id: dropDownList?.uniformat_level_1s || [],
        uniformat_level_2_id: asset?.uniformat_level_1_id ? dropDownList?.uniformat_level_2s : [],
        uniformat_level_3_id: asset?.uniformat_level_2_id ? dropDownList?.uniformat_level_3s : [],
        uniformat_level_4_id: asset?.uniformat_level_3_id ? dropDownList?.uniformat_level_4s : [],
        uniformat_level_5_id: asset?.uniformat_level_4_id ? dropDownList?.uniformat_level_5s : [],
        uniformat_level_6_id: asset?.uniformat_level_5_id ? dropDownList?.uniformat_level_6s : [],
        main_category_id: asset?.client_id ? dropDownList?.main_categories : [],
        sub_category_1_id: asset?.main_category_id ? dropDownList?.sub_category_1s : [],
        sub_category_2_id: asset?.sub_category_1_id ? dropDownList?.sub_category_2s : [],
        sub_category_3_id: asset?.sub_category_2_id ? dropDownList?.sub_category_3s : [],
        trade_id: asset?.client_id ? dropDownList?.trades : [],
        system_id: asset?.trade_id ? dropDownList?.systems : [],
        sub_system_id: asset?.system_id ? dropDownList?.sub_systems : [],
        new_asset: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" }
        ],
        discharge: [
            { id: "Down", name: "Down" },
            { id: "Side", name: "Side" },
            { id: "NA", name: "NA" }
        ],
        return: [
            { id: "Down", name: "Down" },
            { id: "Side", name: "Side" },
            { id: "NA", name: "NA" }
        ],
        unit_property_labeled: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        factory_mounted_disconnect: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        unit_voltage: [
            { id: "480-3PH", name: "480-3PH" },
            { id: "230-3PH", name: "230-3PH" },
            { id: "230-1PH", name: "230-1PH" },
            { id: "208-1PH", name: "208-1PH" },
            { id: "110-1PH", name: "110-1PH" },
            { id: "NA", name: "NA" }
        ],
        ahu_voltage: [
            { id: "480-3PH", name: "480-3PH" },
            { id: "230-3PH", name: "230-3PH" },
            { id: "230-1PH", name: "230-1PH" },
            { id: "208-1PH", name: "208-1PH" },
            { id: "110-1PH", name: "110-1PH" },
            { id: "NA", name: "NA" }
        ],
        hot_gas_by_pass: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        damper_type: [
            { id: "Manual", name: "Manual" },
            { id: "Modulating", name: "Modulating" },
            { id: "Economizer", name: "Economizer" },
            { id: "Power Exhaust", name: "Power Exhaust" },
            { id: "NA", name: "NA" }
        ],
        gas_heating: [
            { id: "Natural Gas", name: "Natural Gas" },
            { id: "LP Gas", name: "LP Gas" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        electric_heating: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        pump_backup_heat_kw: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        cooling_only: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        controls: [
            { id: "Thermostat", name: "Thermostat" },
            { id: "Remote Sensor", name: "Remote Sensor" },
            { id: "DDC/EMS Controls", name: "DDC/EMS Controls" },
            { id: "NA", name: "NA" }
        ],
        smoke_detector: [
            { id: "Central Alarm Panel", name: "Central Alarm Panel" },
            { id: "Self Standing", name: "Self Standing" },
            { id: "Annunciator Required", name: "Annunciator Required" },
            { id: "NA", name: "NA" }
        ],
        smoke_detector_mounting: [
            { id: "Unit", name: "Unit" },
            { id: "Duct", name: "Duct" },
            { id: "NA", name: "NA" }
        ],
        smoke_detector_supply: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        smoke_detector_return: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        curb: [
            { id: "Standard", name: "Standard" },
            { id: "Seismic", name: "Seismic" },
            { id: "Pitched", name: "Pitched" },
            { id: "Other", name: "Other" },
            { id: "NA", name: "NA" }
        ],
        vibration_isolation: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        burglar_bars: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        installed_on_adapter_curb: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        allow_curb_adaptor: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        ducted_or_concentric_system: [
            { id: "Ducted", name: "Ducted" },
            { id: "Concentric", name: "Concentric" },
            { id: "NA", name: "NA" }
        ],
        codes_for_disconnects: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        codes_for_convenience_outlets: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        codes_for_roof_lighting: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        duct_work_attached_to_bottom_of_unit: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        aluminium_wire_used: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        code_or_safety_concerns: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        recommend_coil_coating: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        need_resealed: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        split_system_line_sets_good_condition: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        split_system_line_sets_damaged: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        split_system_line_sets_crimped: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        split_system_line_sets_insulated: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        split_system_line_sets_re_used: [
            { id: "Yes", name: "Yes" },
            { id: "No", name: "No" },
            { id: "NA", name: "NA" }
        ],
        boiler_pressure_type: ["Low", "Medium", "High", "NA"].map(option => ({
            id: option,
            name: option
        }))
    };

    const renderFormField = (eachField, i) => {
        let fieldItem = {};

        if (multiEditFields[eachField.multiEditKey] === DYNAMIC_FIELD_TYPES.SELECT) {
            fieldItem = {
                ...fieldItem,
                field_options: selectOptions[eachField.multiEditKey]
            };
        }

        if (
            [
                "asset_condition_description",
                "client_id",
                "region_id",
                "site_id",
                "building_id",
                "current_age",
                "end_of_service_life",
                "usefull_life_remaining",
                "uniformat_level_6_description",
                "subcategory2_description"
            ].includes(eachField.multiEditKey)
        ) {
            fieldItem = {
                ...fieldItem,
                rules: {
                    read_only: true
                }
            };
        }

        if (["boiler_hp", "mca", "latitude", "longitude"].includes(eachField.multiEditKey)) {
            fieldItem = {
                ...fieldItem,
                rules: { ...fieldItem?.rules, decimal_scale: 6 }
            };
        }

        const value = asset[eachField.multiEditKey]
            ? multiEditFields[eachField.multiEditKey] === DYNAMIC_FIELD_TYPES.DATE
                ? new Date(asset[eachField.multiEditKey])
                : multiEditFields[eachField.multiEditKey] === DYNAMIC_FIELD_TYPES.NUMBER
                ? ["boiler_hp", "mca", "latitude", "longitude"].includes(eachField.multiEditKey)
                    ? asset[eachField.multiEditKey]
                    : parseInt(asset[eachField.multiEditKey])
                : asset[eachField.multiEditKey]
            : "";

        return (
            <FormField
                label={eachField.label}
                fieldKey={eachField.label}
                fieldParam={eachField.multiEditKey}
                fieldType={multiEditFields[eachField.multiEditKey]}
                value={value}
                bandId={`band${i + 1}`}
                bandShown={showBand}
                handleBandClick={handleBandClick}
                onChange={e => handleChange(e, eachField.multiEditKey, multiEditFields[eachField.multiEditKey])}
                showErrorBorder={showErrorBorder}
                errorParams={errorParams}
                fieldItem={fieldItem}
                isAsset
                multiselectClass={multiselectClass}
                disabledMessage={disabledFields(eachField.multiEditKey)}
                isLoading={dropDownLoading[loaderKeys[eachField.multiEditKey]] ?? false}
            />
        );
    };

    return (
        <LoadingOverlay active={getAssetCommonDataLoading || updateMultipleAssetsLoading} spinner={<Loader />} fadeSpeed={10}>
            {renderGroupUpdateConfirmationModal()}
            {renderConfirmationModal()}
            <ReactTooltip id="asset_details" effect="solid" backgroundColor="#007bff" className="rc-tooltip-custom-class" />
            <div className="dtl-sec modal-recom-tab-view col-md-12">
                <Draggable cancel=".not-draggable" positionOffset={{ x: "0%", y: "0%" }}>
                    <div className="tab-dtl region-mng additional-dtl addition-edit" style={{ cursor: "move" }}>
                        <div class="close-btn-otr">
                            <button
                                type="button"
                                className="close not-draggable"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => confirmCancel()}
                            >
                                <span aria-hidden="true">
                                    <img src="/img/close.svg" alt="" />
                                </span>
                            </button>
                        </div>
                        <div class="header-mod-top d-flex">
                            <div>
                                <label for="" class="btn-new-noti btn-padding-noti">
                                    Selected Assets
                                    <span>{selectedAssetsIds.length}</span>
                                </label>
                            </div>
                            <div className="search search-box-asset mr-2 not-draggable">
                                <FilterSearch fieldSearch={fieldSearch} setFieldSearch={setFieldSearch} fields={fieldsToBeRendered} />
                            </div>
                        </div>
                        <div className="tab-active location-sec recom-sec main-dtl-add recommendation-form add-recommendation not-draggable">
                            <div className="col-md-12 detail-recom add-details-outer">
                                <div className="outer-rcm recommendations">
                                    <div className="cnt-sec">
                                        <div className="row">
                                            <div className="col-md-12 pr-0">
                                                <div id="accordion">
                                                    {assetBands
                                                        .filter(band => fieldsToBeRendered.some(field => field.band === band))
                                                        .map((band, i) => (
                                                            <AssetBand
                                                                label={band}
                                                                bandId={`band${i + 1}`}
                                                                key={i + 1}
                                                                handleBandClick={handleBandClick}
                                                                showBand={showBand}
                                                                MainFields={fieldsToBeRendered
                                                                    .filter(elem => elem.band === band)
                                                                    .filter((elem, index) => index < 3)
                                                                    .map(eachField => renderFormField(eachField, i))}
                                                                RestFields={fieldsToBeRendered
                                                                    .filter(elem => elem.band === band)
                                                                    .filter((elem, index) => index >= 3)
                                                                    .map(eachField => renderFormField(eachField, i))}
                                                            />
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-right btnOtr mt-3 mb-2">
                                    <span className="errorMessage">{showErrorBorder ? errorMessage : ""}</span>
                                    <button type="button" className="btn btn-secondary btnClr" onClick={() => confirmCancel()}>
                                        Cancel
                                    </button>
                                    {!updateMultipleAssetsLoading ? (
                                        <button
                                            type="button"
                                            className={`btn btn-primary btnRgion ml-2`}
                                            onClick={() => setShowGroupUpdateConfirmation(true)}
                                            disabled={_.isEqual(initialValues, asset)}
                                        >
                                            Update
                                        </button>
                                    ) : (
                                        <button type="button" className="btn btn-primary btnRgion ml-2">
                                            <div className="button-loader d-flex justify-content-center align-items-center">
                                                <div className="spinner-border text-white" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
        </LoadingOverlay>
    );
};

export default MultiSelectEditForm;
