// for calculating left position of each pinned column
export const getPinnedColumnLeftPositions = (pinnedColumns, pinnedColumnsRef, defaultLeft = 54) => {
    let columnLeftValues = {};
    pinnedColumns.forEach(columnKey => {
        const columnIndex = pinnedColumns.indexOf(columnKey);
        const leftColumns = pinnedColumns.slice(0, columnIndex);
        let leftColumnsWidth = 0;
        leftColumns.forEach(id => {
            const columnElement = pinnedColumnsRef?.[id];
            if (columnElement) {
                leftColumnsWidth += columnElement.offsetWidth;
            }
        });
        columnLeftValues[columnKey] = `${leftColumnsWidth + defaultLeft}px`;
    });
    return columnLeftValues;
};

/**
 * Inserts fields into an array before or after a specified reference field.
 * @param {Array} array - The array to insert fields into.
 * @param {Array} fields - The fields to insert.
 * @param {string} referenceField - The reference field to insert fields before or after.
 * @param {string} position - The position to insert fields ('before' or 'after'). Defaults to 'before'.
 */
export const insertFields = (array, fields, referenceField, position = 'before') => {
    const index = array.findIndex(item => item === referenceField);
    if (index !== -1) {
        array.splice(position === 'after' ? index + 1 : index, 0, ...fields);
    }
};

/**
 * Removes specified fields from an array.
 * @param {Array} array - The array to remove fields from.
 * @param {Array} fields - The fields to remove.
 */
export const removeFields = (array, fields) => {
    fields.forEach(field => {
        const index = array.indexOf(field);
        if (index !== -1) {
            array.splice(index, 1);
        }
    });
};