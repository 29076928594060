export const GET_PROPERTY_VALUE_DATA_REQUEST = "GET_PROPERTY_VALUE_DATA_REQUEST";
export const GET_PROPERTY_VALUE_DATA_SUCCESS = "GET_PROPERTY_VALUE_DATA_SUCCESS";
export const GET_PROPERTY_VALUE_DATA_FAILURE = "GET_PROPERTY_VALUE_DATA_FAILURE";

export const ADD_PROPERTY_VALUE_DATA_REQUEST = "ADD_PROPERTY_VALUE_DATA_REQUEST";
export const ADD_PROPERTY_VALUE_DATA_SUCCESS = "ADD_PROPERTY_VALUE_DATA_SUCCESS";
export const ADD_PROPERTY_VALUE_DATA_FAILURE = "ADD_PROPERTY_VALUE_DATA_FAILURE";

export const GET_PROPERTY_VALUE_DATA_BY_ID_REQUEST = "GET_PROPERTY_VALUE_DATA_BY_ID_REQUEST";
export const GET_PROPERTY_VALUE_DATA_BY_ID_SUCCESS = "GET_PROPERTY_VALUE_DATA_BY_ID_SUCCESS";
export const GET_PROPERTY_VALUE_DATA_BY_ID_FAILURE = "GET_PROPERTY_VALUE_DATA_BY_ID_FAILURE";

export const UPDATE_PROPERTY_VALUE_DATA_REQUEST = "UPDATE_PROPERTY_VALUE_DATA_REQUEST";
export const UPDATE_PROPERTY_VALUE_DATA_SUCCESS = "UPDATE_PROPERTY_VALUE_DATA_SUCCESS";
export const UPDATE_PROPERTY_VALUE_DATA_FAILURE = "UPDATE_PROPERTY_VALUE_DATA_FAILURE";

export const UPDATE_PROPERTY_VALUE_DATA_ENTITY_PARAMS_SUCCESS = "UPDATE_PROPERTY_VALUE_DATA_ENTITY_PARAMS_SUCCESS";
export const UPDATE_PROPERTY_VALUE_DATA_ENTITY_PARAMS_FAILURE = "UPDATE_PROPERTY_VALUE_DATA_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_PROPERTY_VALUE_DATA_LOG_REQUEST = "GET_ALL_PROPERTY_VALUE_DATA_LOG_REQUEST";
export const GET_ALL_PROPERTY_VALUE_DATA_LOG_SUCCESS = "GET_ALL_PROPERTY_VALUE_DATA_LOG_SUCCESS";
export const GET_ALL_PROPERTY_VALUE_DATA_LOG_FAILURE = "GET_ALL_PROPERTY_VALUE_DATA_LOG_FAILURE";

export const RESTORE_PROPERTY_VALUE_DATA_LOG_REQUEST = "RESTORE_PROPERTY_VALUE_DATA_LOG_REQUEST";
export const RESTORE_PROPERTY_VALUE_DATA_LOG_SUCCESS = "RESTORE_PROPERTY_VALUE_DATA_LOG_SUCCESS";
export const RESTORE_PROPERTY_VALUE_DATA_LOG_FAILURE = "RESTORE_PROPERTY_VALUE_DATA_LOG_FAILURE";

export const DELETE_PROPERTY_VALUE_DATA_LOG_REQUEST = "DELETE_PROPERTY_VALUE_DATA_LOG_REQUEST";
export const DELETE_PROPERTY_VALUE_DATA_LOG_SUCCESS = "DELETE_PROPERTY_VALUE_DATA_LOG_SUCCESS";
export const DELETE_PROPERTY_VALUE_DATA_LOG_FAILURE = "DELETE_PROPERTY_VALUE_DATA_LOG_FAILURE";

export const GET_PROPERTY_VALUE_DATA_EXPORT_REQUEST = "GET_PROPERTY_VALUE_DATA_EXPORT_REQUEST";
export const GET_PROPERTY_VALUE_DATA_EXPORT_SUCCESS = "GET_PROPERTY_VALUE_DATA_EXPORT_SUCCESS";
export const GET_PROPERTY_VALUE_DATA_EXPORT_FAILURE = "GET_PROPERTY_VALUE_DATA_EXPORT_FAILURE";

export const DELETE_PROPERTY_VALUE_DATA_REQUEST = "DELETE_PROPERTY_VALUE_DATA_REQUEST";
export const DELETE_PROPERTY_VALUE_DATA_SUCCESS = "DELETE_PROPERTY_VALUE_DATA_SUCCESS";
export const DELETE_PROPERTY_VALUE_DATA_FAILURE = "DELETE_PROPERTY_VALUE_DATA_FAILURE";

export const CHECK_PROPERTY_VALUE_REQUEST = "CHECK_PROPERTY_VALUE_REQUEST";
export const CHECK_PROPERTY_VALUE_SUCCESS = "CHECK_PROPERTY_VALUE_SUCCESS";
export const CHECK_PROPERTY_VALUE_FAILURE = "CHECK_PROPERTY_VALUE_FAILURE";