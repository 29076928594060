import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";

export const ExportColumnConfiguration = ({ onCancel, keys = [], config, onExport, isDraggable }) => {
    const [searchKey, setSearchKey] = useState("");
    const [selectedColumns, setSelectedColumns] = useState([]);

    useEffect(() => {
        setSelectedColumns(keys.filter(key => config[key].isVisible));
    }, []);

    const handleSearch = event => {
        setSearchKey(event.target.value);
    };

    const handleSelectColumn = item => {
        if (selectedColumns.includes(item)) {
            let newColumns = selectedColumns.filter(fil => fil !== item);
            setSelectedColumns(newColumns);
        } else {
            setSelectedColumns([...selectedColumns, item]);
        }
    };

    const handleSelectAll = e => {
        const { checked } = e.target;
        if (checked) {
            setSelectedColumns(keys);
        } else {
            setSelectedColumns([]);
        }
    };

    let tempKeys = keys;
    if (searchKey?.trim()?.length) {
        tempKeys = tempKeys.filter(key => config[key]?.label?.toString()?.toLowerCase()?.includes(searchKey.toLowerCase()));
    }

    const handleExport = () => {
        let hide_columns = [""];
        keys.forEach((keyItem, i) => {
            if (!selectedColumns.includes(keyItem)) {
                hide_columns.push(config[keyItem]?.label);
            }
        });
        onCancel();
        onExport(hide_columns);
    };

    const isAllSelected = keys.length === selectedColumns.length;
    return (
        <div className="modal modal-region modal-view" id="modalId" style={{ display: "block" }} tabIndex="-1">
            <Draggable
                handle=".draggable"
                cancel=".not-draggable"
                onDrag={() => isDraggable && isDraggable(false)}
                onStart={isDraggable && isDraggable(true)}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header draggable">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <div className="txt-hed">Select Columns</div>
                                {/* <div className="selct-otr not-draggable">
                                    <select className="form-control" onChange={e => this.handleDropdownChange(e)} value={this.state.viewFilter}>
                                        <option value="all">All</option>
                                        <option value="visible">Visible</option>
                                        <option value="inVisible">Not Visible</option>
                                    </select>
                                </div> */}
                            </h5>
                            <button type="button" className="close not-draggable" onClick={onCancel}>
                                <span aria-hidden="true">
                                    <img src="/img/close.svg" alt="" />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body region-otr">
                            <div className="col-md-12 formInp search">
                                <i className="fas fa-search" />
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={async e => {
                                        handleSearch(e);
                                    }}
                                    value={searchKey}
                                    placeholder="Search Now"
                                />
                            </div>

                            <div className="col-md-12 check-otr d-flex checkbox-sec">
                                <>
                                    <div className="col-md-6 box-otr">
                                        <div className="rem-txt">
                                            <label className="container-check">
                                                Select All
                                                <input type="checkbox" checked={isAllSelected} onChange={handleSelectAll} />
                                                <span className="checkmark" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                </>
                                {tempKeys.length ? (
                                    <>
                                        {tempKeys
                                            .filter(e => !config[e]?.hasOnlyInfoView)
                                            .map((keyItem, i) => (
                                                <div key={i} className="col-md-6 box-otr">
                                                    <div className="rem-txt">
                                                        <label className="container-check">
                                                            {config[keyItem]?.label}
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedColumns.includes(keyItem)}
                                                                onChange={() => handleSelectColumn(keyItem)}
                                                            />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                    </>
                                ) : (
                                    <div className="col-md-12 text-center mt-5">No records found!</div>
                                )}
                            </div>
                            <div class="col-md-12 p-0 mt-4 text-right btnOtr d-flex col-md-12">
                                <div class="tot-btr">
                                    <button type="button" onClick={onCancel} class="btn btn-secondary btnClr col-md-3 mr-1">
                                        Cancel
                                    </button>
                                    <button type="button" onClick={handleExport} class="btn btn-primary btnRgion btn-wid">
                                        Export
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    );
};
