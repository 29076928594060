export const GET_TRADE_SETTINGS_DATA_REQUEST = "GET_TRADE_SETTINGS_DATA_REQUEST";
export const GET_TRADE_SETTINGS_DATA_SUCCESS = "GET_TRADE_SETTINGS_DATA_SUCCESS";
export const GET_TRADE_SETTINGS_DATA_FAILURE = "GET_TRADE_SETTINGS_DATA_FAILURE";

export const ADD_TRADE_REQUEST = "ADD_TRADE_REQUEST";
export const ADD_TRADE_SUCCESS = "ADD_TRADE_SUCCESS";
export const ADD_TRADE_FAILURE = "ADD_TRADE_FAILURE";

export const GET_TRADE_BY_ID_REQUEST = "GET_TRADE_BY_ID_REQUEST";
export const GET_TRADE_BY_ID_SUCCESS = "GET_TRADE_BY_ID_SUCCESS";
export const GET_TRADE_BY_ID_FAILURE = "GET_TRADE_BY_ID_FAILURE";

export const UPDATE_TRADE_REQUEST = "UPDATE_TRADE_REQUEST";
export const UPDATE_TRADE_SUCCESS = "UPDATE_TRADE_SUCCESS";
export const UPDATE_TRADE_FAILURE = "UPDATE_TRADE_FAILURE";

export const DELETE_TRADE_REQUEST = "DELETE_TRADE_REQUEST";
export const DELETE_TRADE_SUCCESS = "DELETE_TRADE_SUCCESS";
export const DELETE_TRADE_FAILURE = "DELETE_TRADE_FAILURE";

// Update Floor Entity Param
export const UPDATE_TRADE_ENTITY_PARAMS_SUCCESS = "UPDATE_TRADE_ENTITY_PARAMS_SUCCESS";
export const UPDATE_TRADE_ENTITY_PARAMS_FAILURE = "UPDATE_TRADE_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_TRADE_LOG_REQUEST = "GET_ALL_TRADE_LOG_REQUEST";
export const GET_ALL_TRADE_LOG_SUCCESS = "GET_ALL_TRADE_LOG_SUCCESS";
export const GET_ALL_TRADE_LOG_FAILURE = "GET_ALL_TRADE_LOG_FAILURE";

export const RESTORE_SETTINGS_LOG_REQUEST = "RESTORE_SETTINGS_LOG_REQUEST";
export const RESTORE_SETTINGS_LOG_SUCCESS = "RESTORE_SETTINGS_LOG_SUCCESS";
export const RESTORE_SETTINGS_LOG_FAILURE = "RESTORE_SETTINGS_LOG_FAILURE";

export const DELETE_SETTINGS_LOG_REQUEST = "DELETE_SETTINGS_LOG_REQUEST";
export const DELETE_SETTINGS_LOG_SUCCESS = "DELETE_SETTINGS_LOG_SUCCESS";
export const DELETE_SETTINGS_LOG_FAILURE = "DELETE_SETTINGS_LOG_FAILURE";

export const GET_TRADE_EXPORT_REQUEST = "GET_TRADE_EXPORT_REQUEST";
export const GET_TRADE_EXPORT_SUCCESS = "GET_TRADE_EXPORT_SUCCESS";
export const GET_TRADE_EXPORT_FAILURE = "GET_TRADE_EXPORT_FAILURE";
export const FONT_COLOR = [
    "#4D4D4D",
    "#999999",
    "#FFFFFF",
    "#F44E3B",
    "#FE9200",
    "#FCDC00",
    "#DBDF00",
    "#A4DD00",
    "#68CCCA",
    "#73D8FF",
    "#AEA1FF",
    "#FDA1FF",
    "#333333",
    "#808080",
    "#cccccc",
    "#D33115",
    "#E27300",
    "#FCC400",
    "#B0BC00",
    "#68BC00",
    "#16A5A5",
    "#009CE0",
    "#7B64FF",
    "#FA28FF",
    "#000000",
    "#666666",
    "#B3B3B3",
    "#9F0500",
    "#C45100",
    "#FB9E00",
    "#808900",
    "#194D33",
    "#0C797D",
    "#006A4D",
    "#00A657",
    "#0062B1",
    "#653294",
    "#AB149E",
    "#404040",
    "#BFD857"
];
