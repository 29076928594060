import React, { useState, useEffect } from "react";

import ChartItem from "./ChartItem";
import MasterFilter from "../MasterFilter";
import { CHART_ITEMS } from "../../constants";
import { getEnityNameForCAband } from "../../utils";
import { MyFilters } from "../../../dashboard/components/FilterModal/MyFilters";
import Portal from "../../../common/components/Portal";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../dashboard/actions.js";

const ChartSection = ({
    masterFilterList,
    chartSectionData,
    entity,
    selectedClient,
    isExcelExport,
    requiredFields,
    getMasterFiltersData,
    setSelectedMasterFilters,
    setFilterOpen,
    projectSectionDatas,
    filterOpen = false
}) => {
    const [chartData, setChartData] = useState([]);
    const { filterFields, masterFilterList: dashboardMasterFilterList, getBuildingTypeFilter } = useSelector(state => state.dashboardReducer);
    const [smartChartSectionData, setSmartChartSectionData] = useState(projectSectionDatas?.mfilter || {});
    const dispatch = useDispatch();
    const [isMasterFilterSubmit, setMasterFilterSubmit] = useState(null);
    useEffect(() => {
        setSmartChartSectionData(projectSectionDatas?.mfilter);
        setChartItemData(entity);
        if (Object?.keys(projectSectionDatas?.mfilter)?.length > 0) {
            Object.entries(projectSectionDatas?.mfilter)?.forEach(([key, value]) => {
                if (key === "building_types" && !isMasterFilterSubmit) {
                    const { [key]: omit, ...rest } = { ...projectSectionDatas?.mfilter };
                    dispatch(actions.getMasterFilter(rest, key));
                    setMasterFilterSubmit(null);
                } else {
                    if (isMasterFilterSubmit) {
                        const names = dashboardMasterFilterList?.building_types?.filter(ms => value.includes(ms.id))?.map(item => item.name) || [];
                        setSmartChartSectionData({ ...projectSectionDatas?.mfilter, building_types: names });
                    }
                }
            });
        }
    }, [projectSectionDatas, isExcelExport]);
    useEffect(() => {
        if (Object?.keys(projectSectionDatas?.mfilter)?.length > 0) {
            Object.entries(projectSectionDatas?.mfilter)?.forEach(([key, value]) => {
                if (key === "building_types") {
                    const names = dashboardMasterFilterList?.building_types?.filter(ms => value.includes(ms.id))?.map(item => item.name) || [];
                    setSmartChartSectionData({ ...projectSectionDatas?.mfilter, building_types: names });
                }
            });
        }
    }, [dashboardMasterFilterList]);

    useEffect(() => {
        setChartItemData(entity);
    }, [chartSectionData]);
    useEffect(() => {
        if (filterOpen) {
            setMasterFilterSubmit(null);
        }
    }, [filterOpen]);
    useEffect(() => {
        if (isMasterFilterSubmit) {
            MasterFilterSubmit(isMasterFilterSubmit);
        }
    }, [isMasterFilterSubmit, dashboardMasterFilterList]);
    const MasterFilterSubmit = filterObjects => {
        if (Object?.keys(filterObjects)?.length > 0) {
            Object?.keys(filterObjects)?.map(paramKeys => {
                const filterDetail = filterFields?.find(({ paramKey }) => paramKey === paramKeys);
                if (Object?.keys(filterObjects)?.length > 0) {
                    if (paramKeys === "exclude_filters") return;
                    let building_ids = [];
                    if (paramKeys === "building_types") {
                        building_ids = getSystemChartParams(filterObjects);
                    }
                    setSelectedMasterFilters("mfilter", {
                        mfilterKey: paramKeys,
                        bandName: "band1",
                        filterValues:
                            filterObjects[paramKeys]?.length > 0
                                ? paramKeys === "building_types"
                                    ? building_ids
                                    : [...filterObjects[paramKeys]]
                                : paramKeys === "start_year"
                                ? { start: filterObjects[paramKeys] }
                                : paramKeys === "end_year"
                                ? { end: filterObjects[paramKeys] }
                                : filterObjects[paramKeys],
                        entity: "project",
                        filterKey: paramKeys === "filters" ? "filters" : filterDetail?.key,
                        dropDownData: dashboardMasterFilterList?.[filterDetail?.key] || [],
                        filterLabel: filterDetail?.label
                    });
                }
            });
        } else {
            setSelectedMasterFilters("mfilter", {
                bandName: "band1",
                filterValues: [],
                entity: entity,
                filterKey: [],
                isFilterValue: true
            });
        }
    };
    const getSystemChartParams = dashboardFilterParams => {
        let params = {};
        let buildingTypeData = getBuildingTypeFilter?.building_types || dashboardMasterFilterList?.building_types;
        const building_type_ids =
            buildingTypeData?.filter(item => dashboardFilterParams?.building_types?.some(elm => elm === item.name)).map(item => item.id) || [];
        if (building_type_ids?.length) {
            params = building_type_ids;
        }
        return params;
    };
    const setChartItemData = (entity = "project") => {
        let chartDataArray = [];
        let chartItemList = { ...chartSectionData.type };
        let chartKeys = Object.keys(chartItemList);
        let entityKeys = Object.keys(CHART_ITEMS[entity]);
        chartKeys.forEach(chartItem => {
            chartDataArray.push({
                name:
                    chartItem.includes("user_doc") || chartItem.includes("image_doc")
                        ? chartItemList[chartItem]?.name || chartItem
                        : chartItem.includes("list_image")
                        ? chartItemList[chartItem]?.name || "Image Band"
                        : chartItem.includes("multi_recommendation")
                        ? chartItemList[chartItem]?.name || "Recommendation Summary"
                        : chartItem.includes("normal_recom")
                        ? chartItemList[chartItem]?.band1?.name || "Recommendation Table"
                        : chartItem.includes("recom_onepager")
                        ? chartItemList[chartItem]?.band1?.name || "Recommendation One Pager"
                        : chartItem.includes("asset-onepager")
                        ? chartItemList[chartItem]?.band1?.name || "Asset One Pager"
                        : chartItem.includes("memo_field")
                        ? chartItemList[chartItem]?.band1?.name || "Text Band"
                        : chartItem.includes("filter_report1")
                        ? chartItemList[chartItem]?.name || "Master Filters"
                        : !entityKeys.includes(chartItem)
                        ? `Comparative Analysis Band (${
                              chartItem?.includes("fci_benchmarking")
                                  ? getEnityNameForCAband(chartItem.split("_")?.slice(0, 2)?.join("_")) +
                                    " & " +
                                    getEnityNameForCAband(chartItem.split("_")?.[2])
                                  : getEnityNameForCAband(chartItem.split("_")?.[0]) + " & " + getEnityNameForCAband(chartItem.split("_")?.[1])
                          })` || chartItem
                        : chartItem,
                entityIcon: getEntityIcon(chartItem, entityKeys)
            });
        });
        setChartData([...chartDataArray]);
    };

    const getEntityIcon = (chartItem, entityKeys = []) => {
        if (chartItem.includes("user_doc")) {
            return "file-type-standard.svg";
        }
        if (chartItem.includes("image_doc") || chartItem.includes("list_image")) {
            return "smart-chart-image-item.svg";
        }
        if (chartItem?.includes("recom_onepager")) {
            return "sm-charts-one-pager.svg";
        }
        if (
            chartItem == "sorted_recom" ||
            chartItem == "energy_band" ||
            chartItem == "water_band" ||
            chartItem === "multi_recommendation" ||
            chartItem == "normal_recom" ||
            chartItem == "asset_band" ||
            chartItem.includes("filter_report1") ||
            !entityKeys.includes(chartItem) ||
            isExcelExport
        ) {
            return "sm-charts-table-blue-icon.svg";
        }
        return "smart-chart-chart-icon-item.svg";
    };
    const { project, ...filteredObject } = requiredFields;
    return (
        <div class="drag-main mt-1">
            <div class="row m-0">
                {filteredObject?.[entity] &&
                    Array(Math.ceil(filteredObject?.[entity]?.length / 5))
                        .fill()
                        ?.map((row, rowIndex) => (
                            <MasterFilter
                                getSmartChartMasterFilterDropDown={getMasterFiltersData}
                                masterFilterList={masterFilterList?.[entity]}
                                setSmartChartData={setSelectedMasterFilters}
                                currentBand={"band1"}
                                selectedFiltersList={chartSectionData?.mfilter}
                                selectedClient={selectedClient}
                                entity={entity}
                                rowIndex={rowIndex}
                                isCoreReportView
                                requiredFields={requiredFields}
                                core_report_req_filters={chartSectionData?.core_report_req_filters}
                            />
                        ))}
            </div>
            <div class="row m-0">
                <div class="col-md-12 p-0">
                    {Array(Math.ceil(chartData.length / 4 + (chartData.length % 4 ? 1 : 0)))
                        .fill()
                        .map((dropRow, index) => (
                            <div className="dragble-area">
                                {chartData.slice(index * 4, (index + 1) * 4).map((item, i) => {
                                    return <ChartItem index={i} item={item} currentBand={"band1"} entity={entity} />;
                                })}
                            </div>
                        ))}
                </div>
            </div>
            {filterOpen ? (
                <Portal
                    body={
                        <MyFilters
                            dashboardFilterParams={smartChartSectionData}
                            onCancel={() => setFilterOpen(false)}
                            submitMasterFilter={filterObjects => {
                                setMasterFilterSubmit(filterObjects);
                            }}
                            requiredFields={requiredFields?.project}
                            core_report_req_filters={projectSectionDatas?.core_report_req_filters}
                            isCoreReportFilters
                        />
                    }
                    onCancel={() => this.setState({ showFiltersPopup: false })}
                />
            ) : null}
        </div>
    );
};
export default ChartSection;
