import React from "react";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

import Table from "../../common/components/Table";
import TableTopIcons from "../../common/components/TableTopIcons";
import refreshIcon from "../../../assets/img/img-refresh.svg";
export const CapitalRequestTable = ({
    showWildCardFilter,
    paginationParams,
    currentViewAllUsers,
    showViewModal,
    tableData,
    handleGlobalSearch,
    toggleWildCardFilter,
    updateCurrentViewAllUsers,
    handleDeleteFloor,
    showEditPage,
    handlePerPageChange,
    handlePageClick,
    showAddForm,
    showInfoPage,
    updateSelectedRow,
    selectedRowId,
    globalSearchKey,
    updateWildCardFilter,
    wildCardFilter,
    resetAll,
    handleHideColumn,
    getListForCommonFilterFloor,
    updateCommonFilter,
    isColunmVisibleChanged,
    commonFilter,
    resetAllFilters,
    updateTableSortFilters,
    resetSort,
    tableParams,
    permissions,
    showAddButton,
    hasExport,
    hasEdit,
    hasDelete,
    hasInfoPage,
    entity,
    exportTableXl,
    tableLoading,
    hasActionColumn,
    summaryRowData,
    commonFilterData,
    commonFilters,
    selectFilterHandler,
    showRestoreModal,
    isRecommendationView = false,
    toggleLinkCRtoRecommendationModal,
    isCRSelection,
    handleSelectCR,
    selectedCRIds = [],
    handleSelectAllCR,
    everyItemCheckedPerPage,
    unlinkCRs
}) => {
    return (
        <React.Fragment>
            <div className={`dtl-sec`}>
                <div className="table-top-menu allign-right">
                    <div className="lft">
                        <select
                            className={tableParams?.selectedDropdown === "active" ? "form-control cstm-active" : "form-control"}
                            value={tableParams?.selectedDropdown}
                            onChange={e => selectFilterHandler(e)}
                        >
                            <option value="all" className={"cstm-option"}>
                                All
                            </option>
                            <option value="active" className={"cstm-option"}>
                                Active
                            </option>
                            <option value="archived" className={"cstm-option"}>
                                Archived (2024)
                            </option>
                            <option value="deleted" className={"cstm-option"}>
                                Deleted
                            </option>
                        </select>
                    </div>
                    <div class="btn-rf ml-2">
                        <button class="btn-comn-rfh" onClick={() => resetAll()}>
                            <span className="icon mr-1">
                                <img src={refreshIcon} alt="" />
                            </span>
                            Refresh
                        </button>
                    </div>
                    <div className="rgt">
                        <TableTopIcons
                            tableData={tableData}
                            resetAll={resetAll}
                            isColunmVisibleChanged={isColunmVisibleChanged}
                            globalSearchKey={globalSearchKey}
                            handleGlobalSearch={handleGlobalSearch}
                            resetAllFilters={resetAllFilters}
                            toggleWildCardFilter={toggleWildCardFilter}
                            showViewModal={showViewModal}
                            resetSort={resetSort}
                            tableParams={tableParams}
                            exportTableXl={exportTableXl}
                            tableLoading={tableLoading}
                            isExport={hasExport}
                            entity={entity}
                            showWildCardFilter={showWildCardFilter}
                            hasWildCardFilter={true}
                            hasHelp={false}
                        />

                        {isRecommendationView ? (
                            <>
                                <button className="add-btn mr-2" onClick={() => toggleLinkCRtoRecommendationModal()}>
                                    <i className="fas fa-plus" /> Link Capital Request
                                </button>
                                <button
                                    className={`add-btn ${!selectedCRIds.length ? "disabled-btn" : ""}`}
                                    disabled={!selectedCRIds.length}
                                    onClick={() => unlinkCRs()}
                                >
                                    <i className="fas fa-plus" /> Unlink Capital Request
                                </button>
                            </>
                        ) : showAddButton ? (
                            <button className="add-btn" onClick={() => showAddForm()}>
                                <i className="fas fa-plus" /> Create New Capital Request
                            </button>
                        ) : null}
                    </div>
                </div>
                <Table
                    updateCurrentViewAllUsers={updateCurrentViewAllUsers}
                    tableData={tableData}
                    currentViewAllUsers={currentViewAllUsers}
                    handleDeleteItem={handleDeleteFloor}
                    showWildCardFilter={showWildCardFilter}
                    showEditPage={showEditPage}
                    showInfoPage={showInfoPage}
                    updateSelectedRow={updateSelectedRow}
                    selectedRowId={selectedRowId}
                    updateWildCardFilter={updateWildCardFilter}
                    wildCardFilter={wildCardFilter}
                    handleHideColumn={handleHideColumn}
                    getListForCommonFilter={getListForCommonFilterFloor}
                    updateCommonFilter={updateCommonFilter}
                    commonFilter={commonFilter}
                    updateTableSortFilters={updateTableSortFilters}
                    tableParams={tableParams}
                    permissions={permissions}
                    hasEdit={hasEdit}
                    hasDelete={hasDelete}
                    hasInfoPage={hasInfoPage}
                    entity={entity}
                    hasViewIcon={true}
                    hasActionColumn={hasActionColumn}
                    summaryRowData={summaryRowData}
                    isNewTableFilters={true}
                    commonFilterData={commonFilterData}
                    exportFilters={commonFilters}
                    showRestoreModal={showRestoreModal}
                    isCRSelection={isCRSelection}
                    handleSelectRecom={handleSelectCR}
                    selectedRecomIds={selectedCRIds}
                    handleSelectAllRecom={handleSelectAllCR}
                    everyItemCheckedPerPage={everyItemCheckedPerPage}
                    isNewWildCardFilter={true}
                />
                {tableData.data && tableData.data.length ? (
                    <div className="table-bottom d-flex">
                        <div className="count d-flex col-md-6">
                            <div className="count-dtl">
                                Total Count: <span>{paginationParams.totalCount}</span>
                            </div>
                            <div className="col-md-2 pr-2 selbx">
                                <select className="form-control" value={paginationParams.perPage} onChange={e => handlePerPageChange(e)}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="150">150</option>
                                </select>
                            </div>
                        </div>
                        <div className="pagination-sec col-md-6">
                            <ReactPaginate
                                previousLabel={
                                    <span data-place="top" data-effect="solid" data-tip={`Previous`} data-background-color="#007bff">
                                        &lt;
                                    </span>
                                }
                                nextLabel={
                                    <span data-place="top" data-effect="solid" data-tip={`Next`} data-background-color="#007bff">
                                        &gt;
                                    </span>
                                }
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={paginationParams.totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                activeLinkClassName={"active"}
                                forcePage={paginationParams.currentPage}
                            />
                            <ReactTooltip />
                        </div>
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    );
};
