import { DYNAMIC_FIELD_TYPES } from "../../../common/constants";

export const bands = {
    basic_asset_information: "Basic Asset Information",
    geo_hierarchy: "Geo Hierarchy",
    extended_asset_information: "Extended Asset Information",
    asset_qa_qc: "Asset QA/QC Status",
    asset_parent_hierarchy: "Asset Parent/Child Hierarchy",
    asset_additional: "Asset Additional",
    boiler_fields: "Boiler Fields"
    // auxiliary_equipment_fields: "Auxiliary Equipment Fields"
};

export const bandPositions = {
    header: 1,
    body: 2
};

export const tabs = {
    main: {
        label: "Main",
        bands: [
            bands.basic_asset_information,
            bands.geo_hierarchy,
            bands.extended_asset_information,
            bands.asset_qa_qc,
            bands.asset_parent_hierarchy,
            bands.asset_additional,
            bands.boiler_fields
            // bands.auxiliary_equipment_fields
        ]
    },
    favorites: {
        label: "Favorites",
        bands: []
    }
};

export const assetBands = [
    bands.basic_asset_information,
    bands.geo_hierarchy,
    bands.extended_asset_information,
    bands.asset_qa_qc,
    bands.asset_parent_hierarchy,
    bands.asset_additional,
    bands.boiler_fields
    // bands.auxiliary_equipment_fields
];

const multiEditFieldKeys = {
    asset_name: "asset_name",
    asset_description: "asset_description",
    asset_type_id: "asset_type_id",
    asset_status_id: "asset_status_id",
    client_asset_condition_id: "client_asset_condition_id",
    asset_condition_description: "asset_condition_description",
    asset_ownership: "asset_ownership",
    criticality: "criticality",
    asset_tag: "asset_tag",
    guid: "guid",
    model_number: "model_number",
    core_model_number: "core_model_number",
    serial_number: "serial_number",
    capacity: "capacity",
    capacity_unit: "capacity_unit",
    capacity_status: "capacity_status",
    unit_cost: "unit_cost",
    asset_barcode: "asset_barcode",
    asset_client_id: "asset_client_id",
    asset_cmms_id: "asset_cmms_id",
    rtls_tag: "rtls_tag",
    client_id: "client_id",
    region_id: "region_id",
    site_id: "site_id",
    building_id: "building_id",
    addition_id: "addition_id",
    floor_id: "floor_id",
    room_number: "room_number",
    room_name: "room_name",
    location: "location",
    architectural_room_number: "architectural_room_number",
    additional_room_description: "additional_room_description",
    longitude: "longitude",
    latitude: "latitude",
    manufacturer: "manufacturer",
    year_manufactured: "year_manufactured",
    installed_year: "installed_year",
    current_age: "current_age",
    service_life: "service_life",
    end_of_service_life: "end_of_service_life",
    usefull_life_remaining: "usefull_life_remaining",
    csp_unit: "csp_unit",
    crv: "crv",
    refrigerant: "refrigerant",
    warranty_start: "warranty_start",
    warranty_end: "warranty_end",
    install_date: "install_date",
    startup_date: "startup_date",
    uniformat_level_1_id: "uniformat_level_1_id",
    uniformat_level_2_id: "uniformat_level_2_id",
    uniformat_level_3_id: "uniformat_level_3_id",
    uniformat_level_4_id: "uniformat_level_4_id",
    uniformat_level_5_id: "uniformat_level_5_id",
    uniformat_level_6_id: "uniformat_level_6_id",
    uniformat_level_6_description: "uniformat_level_6_description",
    uniformat_level_3_custom_description: "uniformat_level_3_custom_description",
    main_category_id: "main_category_id",
    sub_category_1_id: "sub_category_1_id",
    sub_category_2_id: "sub_category_2_id",
    subcategory2_description: "subcategory2_description",
    sub_category_3_id: "sub_category_3_id",
    trade_id: "trade_id",
    system_id: "system_id",
    sub_system_id: "sub_system_id",
    asset_note: "asset_note",
    survey_id: "survey_id",
    survey_property_note: "survey_property_note",
    installed_year_status: "installed_year_status",
    name_plate_status: "name_plate_status",
    qa_notes: "qa_notes",
    additional_qa_notes: "additional_qa_notes",
    survey_date_created: "survey_date_created",
    surveyor: "surveyor",
    survey_date_edited: "survey_date_edited",
    editor: "editor",
    new_asset: "new_asset",
    area_served: "area_served",
    upstream_asset_barcode_number: "upstream_asset_barcode_number",
    linked_asset_barcode_number: "linked_asset_barcode_number",
    source_panel_name: "source_panel_name",
    source_panel_barcode_number: "source_panel_barcode_number",
    source_panel: "source_panel",
    skysite_hyperlink: "skysite_hyperlink",
    quantity: "quantity",
    parent_global_id: "parent_global_id",
    discharge: "discharge",
    return: "return",
    unit_property_labeled: "unit_property_labeled",
    factory_mounted_disconnect: "factory_mounted_disconnect",
    unit_voltage: "unit_voltage",
    ahu_voltage: "ahu_voltage",
    wire_type: "wire_type",
    wire_size: "wire_size",
    estimated_wire_run_length: "estimated_wire_run_length",
    breaker_size: "breaker_size",
    mocp: "mocp",
    mca: "mca",
    liquid_line: "liquid_line",
    suction_line: "suction_line",
    hot_gas_by_pass: "hot_gas_by_pass",
    number_of_circuits: "number_of_circuits",
    length_of_line: "length_of_line",
    damper_type: "damper_type",
    gas_heating: "gas_heating",
    gas_output_btu: "gas_output_btu",
    electric_heating: "electric_heating",
    electric_heating_kw: "electric_heating_kw",
    pump_backup_heat_kw: "pump_backup_heat_kw",
    cooling_only: "cooling_only",
    controls: "controls",
    control_type: "control_type",
    smoke_detector: "smoke_detector",
    smoke_detector_mounting: "smoke_detector_mounting",
    smoke_detector_supply: "smoke_detector_supply",
    smoke_detector_return: "smoke_detector_return",
    curb: "curb",
    curb_description: "curb_description",
    vibration_isolation: "vibration_isolation",
    burglar_bars: "burglar_bars",
    installed_on_adapter_curb: "installed_on_adapter_curb",
    curb_outside_dimension_length: "curb_outside_dimension_length",
    curb_outside_dimension_width: "curb_outside_dimension_width",
    allow_curb_adaptor: "allow_curb_adaptor",
    ducted_or_concentric_system: "ducted_or_concentric_system",
    unit_weight: "unit_weight",
    codes_for_disconnects: "codes_for_disconnects",
    codes_for_convenience_outlets: "codes_for_convenience_outlets",
    codes_for_roof_lighting: "codes_for_roof_lighting",
    duct_work_attached_to_bottom_of_unit: "duct_work_attached_to_bottom_of_unit",
    aluminium_wire_used: "aluminium_wire_used",
    code_or_safety_concerns: "code_or_safety_concerns",
    distance_from_salt_water_body: "distance_from_salt_water_body",
    recommend_coil_coating: "recommend_coil_coating",
    need_resealed: "need_resealed",
    split_system_line_sets_good_condition: "split_system_line_sets_good_condition",
    split_system_line_sets_damaged: "split_system_line_sets_damaged",
    split_system_line_sets_crimped: "split_system_line_sets_crimped",
    split_system_line_sets_insulated: "split_system_line_sets_insulated",
    split_system_line_sets_re_used: "split_system_line_sets_re_used",
    line_connection_type: "line_connection_type",
    boiler_type: "boiler_type",
    boiler_burner_type: "boiler_burner_type",
    boiler_btu_input: "boiler_btu_input",
    boiler_btu_output: "boiler_btu_output",
    boiler_hp: "boiler_hp",
    boiler_fuel: "boiler_fuel",
    boiler_gas_pressure: "boiler_gas_pressure",
    boiler_kw_input: "boiler_kw_input",
    boiler_power_burner_type: "boiler_power_burner_type",
    boiler_burner_model: "boiler_burner_model",
    boiler_burner_serial: "boiler_burner_serial",
    boiler_pressure_type: "boiler_pressure_type",
    confirm_boiler_pressure: "confirm_boiler_pressure"
};

export const loaderKeys = {
    asset_type_id: "asset_types",
    asset_status_id: "asset_statuses",
    client_asset_condition_id: "asset_conditions",
    client_id: "clients",
    region_id: "regions",
    site_id: "sites",
    building_id: "buildings",
    addition_id: "additions",
    floor_id: "floors",
    uniformat_level_1_id: "uniformat_level_1s",
    uniformat_level_2_id: "uniformat_level_2s",
    uniformat_level_3_id: "uniformat_level_3s",
    uniformat_level_4_id: "uniformat_level_4s",
    uniformat_level_5_id: "uniformat_level_5s",
    uniformat_level_6_id: "uniformat_level_6s",
    main_category_id: "main_categories",
    sub_category_1_id: "sub_category_1s",
    sub_category_2_id: "sub_category_2s",
    sub_category_3_id: "sub_category_3s",
    trade_id: "trades",
    system_id: "systems",
    sub_system_id: "sub_systems"
};

export const filterFields = [
    {
        label: "Asset Code",
        key: "code",
        paramKey: "assets.code",
        band: "Basic Asset Information",
        getListKey: "assets.code",
        searchKey: "assets.code",
        isWildCardFilter: true,
        fieldType: "text"
    },
    {
        label: "Asset Name",
        key: "asset_name",
        paramKey: "assets.asset_name",
        band: "Basic Asset Information",
        getListKey: "assets.asset_name",
        searchKey: "assets.asset_name",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.asset_name
    },
    {
        label: "Asset Description",
        key: "asset_description",
        paramKey: "asset_description",
        band: "Basic Asset Information",
        getListKey: "asset_description",
        searchKey: "asset_description",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.asset_description
    },
    {
        label: "Asset Type",
        key: "asset_type",
        paramKey: "asset_types.name",
        band: "Basic Asset Information",
        getListKey: "asset_types.name",
        searchKey: "asset_types.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.asset_type_id
    },
    {
        label: "Asset Status",
        key: "asset_status",
        paramKey: "asset_statuses.name",
        band: "Basic Asset Information",
        getListKey: "asset_statuses.name",
        searchKey: "asset_statuses.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.asset_status_id
    },
    {
        label: "Asset Condition",
        key: "client_asset_condition",
        paramKey: "client_asset_conditions.name",
        band: "Basic Asset Information",
        getListKey: "client_asset_conditions.name",
        searchKey: "client_asset_conditions.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.client_asset_condition_id
    },
    {
        label: "Asset Condition Description",
        key: "description",
        paramKey: "client_asset_conditions.description",
        band: "Basic Asset Information",
        getListKey: "client_asset_conditions.description",
        searchKey: "client_asset_conditions.description",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.asset_condition_description
    },
    {
        label: "Asset Ownership",
        key: "asset_ownership",
        paramKey: "asset_ownership",
        band: "Basic Asset Information",
        getListKey: "asset_ownership",
        searchKey: "asset_ownership",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.asset_ownership
    },
    {
        label: "Criticality",
        key: "criticality",
        paramKey: "assets.criticality",
        band: "Basic Asset Information",
        getListKey: "assets.criticality",
        searchKey: "assets.criticality",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.criticality
    },
    {
        label: "Asset Tag",
        key: "asset_tag",
        paramKey: "assets.asset_tag",
        band: "Basic Asset Information",
        getListKey: "assets.asset_tag",
        searchKey: "assets.asset_tag",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.asset_tag
    },
    {
        label: "GUID",
        key: "guid",
        paramKey: "guid",
        band: "Basic Asset Information",
        getListKey: "guid",
        searchKey: "guid",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.guid
    },
    {
        label: "Model Number",
        key: "model_number",
        paramKey: "assets.model_number",
        band: "Basic Asset Information",
        getListKey: "assets.model_number",
        searchKey: "assets.model_number",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.model_number
    },
    {
        label: "Core Model Number",
        key: "core_model_number",
        paramKey: "assets.core_model_number",
        band: "Basic Asset Information",
        getListKey: "assets.core_model_number",
        searchKey: "assets.core_model_number",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.core_model_number
    },
    {
        label: "Serial Number",
        key: "serial_number",
        paramKey: "assets.serial_number",
        band: "Basic Asset Information",
        getListKey: "assets.serial_number",
        searchKey: "assets.serial_number",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.serial_number
    },
    {
        label: "Capacity",
        key: "capacity",
        paramKey: "assets.capacity",
        band: "Basic Asset Information",
        getListKey: "assets.capacity",
        searchKey: "assets.capacity",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.capacity
    },
    {
        label: "Capacity Unit",
        key: "capacity_unit",
        paramKey: "assets.capacity_unit",
        band: "Basic Asset Information",
        getListKey: "assets.capacity_unit",
        searchKey: "assets.capacity_unit",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.capacity_unit
    },
    {
        label: "Capacity Status",
        key: "capacity_status",
        paramKey: "assets.capacity_status",
        band: "Basic Asset Information",
        getListKey: "assets.capacity_status",
        searchKey: "assets.capacity_status",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.capacity_status
    },
    {
        label: "Unit Cost",
        key: "unit_cost",
        paramKey: "assets.unit_cost",
        band: "Basic Asset Information",
        getListKey: "assets.unit_cost",
        searchKey: "assets.unit_cost",
        isWildCardFilter: true,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.unit_cost
    },
    {
        label: "Asset Barcode",
        key: "asset_barcode",
        paramKey: "asset_barcode",
        band: "Basic Asset Information",
        getListKey: "asset_barcode",
        searchKey: "asset_barcode",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.asset_barcode
    },
    {
        label: "Asset Client ID",
        key: "asset_client_id",
        paramKey: "asset_client_id",
        band: "Basic Asset Information",
        getListKey: "asset_client_id",
        searchKey: "asset_client_id",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.asset_client_id
    },
    {
        label: "Asset CMMS ID",
        key: "asset_cmms_id",
        paramKey: "asset_cmms_id",
        band: "Basic Asset Information",
        getListKey: "asset_cmms_id",
        searchKey: "asset_cmms_id",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.asset_cmms_id
    },
    {
        label: "RTLS Tag",
        key: "rtls_tag",
        paramKey: "assets.rtls_tag",
        band: "Basic Asset Information",
        getListKey: "assets.rtls_tag",
        searchKey: "assets.rtls_tag",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.rtls_tag
    },
    {
        label: "Manufacturer",
        key: "manufacturer",
        paramKey: "assets.manufacturer",
        band: "Extended Asset Information",
        getListKey: "assets.manufacturer",
        searchKey: "assets.manufacturer",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.manufacturer
    },
    {
        label: "Year Manufactured",
        key: "year_manufactured",
        paramKey: "year_manufactured",
        band: "Extended Asset Information",
        getListKey: "year_manufactured",
        searchKey: "year_manufactured",
        isWildCardFilter: true,
        fieldType: "year",
        multiEditKey: multiEditFieldKeys.year_manufactured
    },
    {
        label: "Installed Year",
        key: "installed_year",
        paramKey: "assets.installed_year",
        band: "Extended Asset Information",
        getListKey: "assets.installed_year",
        searchKey: "assets.installed_year",
        isWildCardFilter: true,
        fieldType: "year",
        multiEditKey: multiEditFieldKeys.installed_year
    },
    {
        label: "Current Age",
        key: "current_age",
        paramKey: "assets.current_age",
        band: "Extended Asset Information",
        getListKey: "assets.current_age",
        searchKey: "assets.current_age",
        isWildCardFilter: true,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.current_age
    },
    {
        label: "Service Life",
        key: "service_life",
        paramKey: "assets.service_life",
        band: "Extended Asset Information",
        getListKey: "assets.service_life",
        searchKey: "assets.service_life",
        isWildCardFilter: true,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.service_life
    },
    {
        label: "End of Service Life",
        key: "end_of_service_life",
        paramKey: "assets.end_of_service_life",
        band: "Extended Asset Information",
        getListKey: "assets.end_of_service_life",
        searchKey: "assets.end_of_service_life",
        isWildCardFilter: true,
        fieldType: "year",
        multiEditKey: multiEditFieldKeys.end_of_service_life
    },
    {
        label: "Useful Life Remaining",
        key: "usefull_life_remaining",
        paramKey: "assets.usefull_life_remaining",
        band: "Extended Asset Information",
        getListKey: "assets.usefull_life_remaining",
        searchKey: "assets.usefull_life_remaining",
        isWildCardFilter: true,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.usefull_life_remaining
    },
    {
        label: "CSP Unit",
        key: "csp_unit",
        paramKey: "assets.csp_unit",
        band: "Extended Asset Information",
        getListKey: "assets.csp_unit",
        searchKey: "assets.csp_unit",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.csp_unit
    },
    {
        label: "CRV",
        key: "crv",
        paramKey: "assets.crv",
        band: "Extended Asset Information",
        getListKey: "assets.crv",
        searchKey: "assets.crv",
        isWildCardFilter: true,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.crv
    },
    {
        label: "Refrigerant",
        key: "refrigerant",
        paramKey: "assets.refrigerant",
        band: "Extended Asset Information",
        getListKey: "assets.refrigerant",
        searchKey: "assets.refrigerant",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.refrigerant
    },
    {
        label: "Warranty Start",
        key: "warranty_start",
        paramKey: "warranty_start",
        band: "Extended Asset Information",
        getListKey: "warranty_start",
        searchKey: "warranty_start",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.warranty_start
    },
    {
        label: "Warranty End",
        key: "warranty_end",
        paramKey: "warranty_end",
        band: "Extended Asset Information",
        getListKey: "warranty_end",
        searchKey: "warranty_end",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.warranty_end
    },
    {
        label: "Install Date",
        key: "install_date",
        paramKey: "install_date",
        band: "Extended Asset Information",
        getListKey: "install_date",
        searchKey: "install_date",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.install_date
    },
    {
        label: "Startup Date",
        key: "startup_date",
        paramKey: "startup_date",
        band: "Extended Asset Information",
        getListKey: "startup_date",
        searchKey: "startup_date",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.startup_date
    },
    {
        label: "FCA Cost",
        key: "fca_cost",
        paramKey: "assets.fca_cost",
        band: "Extended Asset Information",
        getListKey: "assets.fca_cost",
        searchKey: "assets.fca_cost",
        isWildCardFilter: true,
        fieldType: "number"
    },
    {
        label: "Uniformat Level 1",
        key: "uniformat_level_1",
        paramKey: "uniformat_level_1s.name",
        band: "Extended Asset Information",
        getListKey: "uniformat_level_1s.name",
        searchKey: "uniformat_level_1s.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.uniformat_level_1_id
    },
    {
        label: "Uniformat Level 2",
        key: "uniformat_level_2",
        paramKey: "uniformat_level_2s.name",
        band: "Extended Asset Information",
        getListKey: "uniformat_level_2s.name",
        searchKey: "uniformat_level_2s.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.uniformat_level_2_id
    },
    {
        label: "Uniformat Level 3",
        key: "uniformat_level_3",
        paramKey: "uniformat_level_3s.name",
        band: "Extended Asset Information",
        getListKey: "uniformat_level_3s.name",
        searchKey: "uniformat_level_3s.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.uniformat_level_3_id
    },
    {
        label: "Uniformat Level 4",
        key: "uniformat_level_4",
        paramKey: "uniformat_level_4s.name",
        band: "Extended Asset Information",
        getListKey: "uniformat_level_4s.name",
        searchKey: "uniformat_level_4s.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.uniformat_level_4_id
    },
    {
        label: "Uniformat Level 5",
        key: "uniformat_level_5",
        paramKey: "uniformat_level_5s.name",
        band: "Extended Asset Information",
        getListKey: "uniformat_level_5s.name",
        searchKey: "uniformat_level_5s.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.uniformat_level_5_id
    },
    {
        label: "Uniformat Level 6",
        key: "uniformat_level_6",
        paramKey: "uniformat_level_6s.name",
        band: "Extended Asset Information",
        getListKey: "uniformat_level_6s.name",
        searchKey: "uniformat_level_6s.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.uniformat_level_6_id
    },
    {
        label: "Uniformat Level 6 Description",
        key: "uniformat_level_6_description",
        paramKey: "uniformat_level_6_description",
        band: "Extended Asset Information",
        getListKey: "uniformat_level_6_description",
        searchKey: "uniformat_level_6_description",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.uniformat_level_6_description
    },
    {
        label: "Uniformat Level 3 Custom Description",
        key: "uniformat_level_3_custom_description",
        paramKey: "uniformat_level_3_custom_description",
        band: "Extended Asset Information",
        getListKey: "uniformat_level_3_custom_description",
        searchKey: "uniformat_level_3_custom_description",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.uniformat_level_3_custom_description
    },
    {
        label: "Main Category",
        key: "main_category",
        paramKey: "main_categories.name",
        band: "Extended Asset Information",
        getListKey: "main_categories.name",
        searchKey: "main_categories.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.main_category_id
    },
    {
        label: "Sub Category 1",
        key: "sub_category_1",
        paramKey: "sub_category_1.name",
        band: "Extended Asset Information",
        getListKey: "sub_category_1.name",
        searchKey: "sub_category_1.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.sub_category_1_id
    },
    {
        label: "Sub Category 2",
        key: "sub_category_2",
        paramKey: "sub_category_2.name",
        band: "Extended Asset Information",
        getListKey: "sub_category_2.name",
        searchKey: "sub_category_2.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.sub_category_2_id
    },
    {
        label: "Sub Category 2 Description",
        key: "subcategory2_description",
        paramKey: "subcategory2_description",
        band: "Extended Asset Information",
        getListKey: "subcategory2_description",
        searchKey: "subcategory2_description",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.subcategory2_description
    },
    {
        label: "Sub Category 3",
        key: "sub_category_3",
        paramKey: "sub_category_3.name",
        band: "Extended Asset Information",
        getListKey: "sub_category_3.name",
        searchKey: "sub_category_3.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.sub_category_3_id
    },
    {
        label: "Trade",
        key: "trade",
        paramKey: "trades.name",
        band: "Extended Asset Information",
        getListKey: "trades.name",
        searchKey: "trades.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.trade_id
    },
    {
        label: "System",
        key: "system",
        paramKey: "systems.name",
        band: "Extended Asset Information",
        getListKey: "systems.name",
        searchKey: "systems.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.system_id
    },
    {
        label: "Sub-System",
        key: "sub_system",
        paramKey: "sub_systems.name",
        band: "Extended Asset Information",
        getListKey: "sub_systems.name",
        searchKey: "sub_systems.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.sub_system_id
    },
    {
        label: "Asset Note",
        key: "asset_note",
        paramKey: "asset_note",
        band: "Extended Asset Information",
        getListKey: "asset_note",
        searchKey: "asset_note",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.asset_note
    },
    {
        label: "Client",
        key: "client",
        paramKey: "clients.name",
        band: "Geo Hierarchy",
        getListKey: "clients.name",
        searchKey: "clients.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.client_id
    },
    {
        label: "Region",
        key: "region",
        paramKey: "regions.name",
        band: "Geo Hierarchy",
        getListKey: "regions.name",
        searchKey: "regions.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.region_id
    },
    {
        label: "Site",
        key: "site",
        paramKey: "sites.name",
        band: "Geo Hierarchy",
        getListKey: "sites.name",
        searchKey: "sites.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.site_id
    },
    {
        label: "Building",
        key: "building",
        paramKey: "buildings.name",
        band: "Geo Hierarchy",
        getListKey: "buildings.name",
        searchKey: "buildings.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.building_id
    },
    {
        label: "Building Type",
        key: "building_type",
        paramKey: "main_building_types.name",
        band: "Geo Hierarchy",
        getListKey: "main_building_types.name",
        searchKey: "main_building_types.name",
        isWildCardFilter: false,
        fieldType: "text"
    },
    {
        label: "Addition",
        key: "addition",
        paramKey: "additions.name",
        band: "Geo Hierarchy",
        getListKey: "additions.name",
        searchKey: "additions.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.addition_id
    },
    {
        label: "Floor",
        key: "floor",
        paramKey: "floors.name",
        band: "Geo Hierarchy",
        getListKey: "floors.name",
        searchKey: "floors.name",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.floor_id
    },
    {
        label: "Room Number",
        key: "room_number",
        paramKey: "room_number",
        band: "Geo Hierarchy",
        getListKey: "room_number",
        searchKey: "room_number",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.room_number
    },
    {
        label: "Room Name",
        key: "room_name",
        paramKey: "room_name",
        band: "Geo Hierarchy",
        getListKey: "room_name",
        searchKey: "room_name",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.room_name
    },
    {
        label: "Location",
        key: "location",
        paramKey: "location",
        band: "Geo Hierarchy",
        getListKey: "location",
        searchKey: "location",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.location
    },
    {
        label: "Architectural Room Number",
        key: "architectural_room_number",
        paramKey: "architectural_room_number",
        band: "Geo Hierarchy",
        getListKey: "architectural_room_number",
        searchKey: "architectural_room_number",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.architectural_room_number
    },
    {
        label: "Additional Room Description",
        key: "additional_room_description",
        paramKey: "additional_room_description",
        band: "Geo Hierarchy",
        getListKey: "additional_room_description",
        searchKey: "additional_room_description",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.additional_room_description
    },
    {
        label: "Latitude",
        key: "latitude",
        paramKey: "assets.latitude",
        band: "Geo Hierarchy",
        getListKey: "assets.latitude",
        searchKey: "assets.latitude",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.latitude
    },
    {
        label: "Longitude",
        key: "longitude",
        paramKey: "assets.longitude",
        band: "Geo Hierarchy",
        getListKey: "assets.longitude",
        searchKey: "assets.longitude",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.longitude
    },
    {
        label: "Area Served",
        key: "area_served",
        paramKey: "area_served",
        band: "Asset Parent/Child Hierarchy",
        getListKey: "area_served",
        searchKey: "area_served",
        isWildCardFilter: true,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.area_served
    },
    {
        label: "Upstream Asset Barcode Numbers",
        key: "upstream_asset_barcode_number",
        paramKey: "upstream_asset_barcode_number",
        band: "Asset Parent/Child Hierarchy",
        getListKey: "upstream_asset_barcode_number",
        searchKey: "upstream_asset_barcode_number",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.upstream_asset_barcode_number
    },
    {
        label: "Linked Asset Barcode Numbers",
        key: "linked_asset_barcode_number",
        paramKey: "linked_asset_barcode_number",
        band: "Asset Parent/Child Hierarchy",
        getListKey: "linked_asset_barcode_number",
        searchKey: "linked_asset_barcode_number",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.linked_asset_barcode_number
    },
    {
        label: "Source Panel Name",
        key: "source_panel_name",
        paramKey: "source_panel_name",
        band: "Asset Parent/Child Hierarchy",
        getListKey: "source_panel_name",
        searchKey: "source_panel_name",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.source_panel_name
    },
    {
        label: "Source Panel Barcode Number",
        key: "source_panel_barcode_number",
        paramKey: "source_panel_barcode_number",
        band: "Asset Parent/Child Hierarchy",
        getListKey: "source_panel_barcode_number",
        searchKey: "source_panel_barcode_number",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.source_panel_barcode_number
    },
    {
        label: "Source Panel Emergency/Normal",
        key: "source_panel",
        paramKey: "source_panel",
        band: "Asset Parent/Child Hierarchy",
        getListKey: "source_panel",
        searchKey: "source_panel",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.source_panel
    },
    {
        label: "Skysite Hyperlink",
        key: "skysite_hyperlink",
        paramKey: "skysite_hyperlink",
        band: "Asset Parent/Child Hierarchy",
        getListKey: "skysite_hyperlink",
        searchKey: "skysite_hyperlink",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.skysite_hyperlink
    },
    {
        label: "Quantity",
        key: "quantity",
        paramKey: "quantity",
        band: "Asset Parent/Child Hierarchy",
        getListKey: "quantity",
        searchKey: "quantity",
        isWildCardFilter: true,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.quantity
    },
    {
        label: "Parent Global ID",
        key: "parent_global_id",
        paramKey: "assets.parent_global_id",
        band: "Asset Parent/Child Hierarchy",
        getListKey: "assets.parent_global_id",
        searchKey: "assets.parent_global_id",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.parent_global_id
    },
    {
        label: "Has Recommendation",
        key: "has_recommendation",
        paramKey: "assets.has_recommendation",
        band: "Basic Asset Information",
        getListKey: "assets.has_recommendation",
        searchKey: "assets.has_recommendation",
        isWildCardFilter: false,
        fieldType: "boolean"
    },
    {
        label: "Has Image",
        key: "assets.has_image",
        paramKey: "has_image",
        band: "Basic Asset Information",
        getListKey: "assets.has_image",
        searchKey: "assets.has_image",
        isWildCardFilter: false,
        fieldType: "select",
        listEndPoint: "/image_list"
    },
    {
        label: "Asset Survey ID",
        key: "survey_id",
        paramKey: "assets.survey_id",
        band: "Asset QA/QC Status",
        getListKey: "assets.survey_id",
        searchKey: "assets.survey_id",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.survey_id
    },
    {
        label: "Asset Survey Property Notes",
        key: "survey_property_note",
        paramKey: "assets.survey_property_note",
        band: "Asset QA/QC Status",
        getListKey: "assets.survey_property_note",
        searchKey: "assets.survey_property_note",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.survey_property_note
    },
    {
        label: "Install Year Status",
        key: "installed_year_status",
        paramKey: "assets.installed_year_status",
        band: "Asset QA/QC Status",
        getListKey: "assets.installed_year_status",
        searchKey: "assets.installed_year_status",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.installed_year_status
    },
    {
        label: "Name Plate Status",
        key: "name_plate_status",
        paramKey: "assets.name_plate_status",
        band: "Asset QA/QC Status",
        getListKey: "assets.name_plate_status",
        searchKey: "assets.name_plate_status",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.name_plate_status
    },
    {
        label: "Asset Survey QA/QC Notes",
        key: "qa_notes",
        paramKey: "assets.qa_notes",
        band: "Asset QA/QC Status",
        getListKey: "assets.qa_notes",
        searchKey: "assets.qa_notes",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.qa_notes
    },
    {
        label: "Asset Survey Additional QA/QC Notes",
        key: "additional_qa_notes",
        paramKey: "assets.additional_qa_notes",
        band: "Asset QA/QC Status",
        getListKey: "assets.additional_qa_notes",
        searchKey: "assets.additional_qa_notes",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.additional_qa_notes
    },
    {
        label: "Asset Survey Date Created",
        key: "survey_date_created",
        paramKey: "assets.survey_date_created",
        getListKey: "assets.survey_date_created",
        searchKey: "assets.survey_date_created",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.survey_date_created
    },
    {
        label: "Asset Survey Surveyor",
        key: "surveyor",
        paramKey: "assets.surveyor",
        band: "Asset QA/QC Status",
        getListKey: "assets.surveyor",
        searchKey: "assets.surveyor",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.surveyor
    },
    {
        label: "Asset Survey Date Edited",
        key: "survey_date_edited",
        paramKey: "assets.survey_date_edited",
        getListKey: "assets.survey_date_edited",
        searchKey: "assets.survey_date_edited",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.survey_date_edited
    },
    {
        label: "Asset Survey Editor",
        key: "editor",
        paramKey: "assets.editor",
        band: "Asset QA/QC Status",
        getListKey: "assets.editor",
        searchKey: "assets.editor",
        isWildCardFilter: true,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.editor
    },
    {
        label: "Is This a New Asset",
        key: "new_asset",
        paramKey: "assets.new_asset",
        band: "Asset QA/QC Status",
        getListKey: "assets.new_asset",
        searchKey: "assets.new_asset",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.new_asset
    },
    {
        label: "Discharge",
        key: "discharge",
        paramKey: "asset_additionals.discharge",
        band: "Asset Additional",
        getListKey: "asset_additionals.discharge",
        searchKey: "asset_additionals.discharge",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.discharge
    },
    {
        label: "Return",
        key: "return",
        paramKey: "asset_additionals.return",
        band: "Asset Additional",
        getListKey: "asset_additionals.return",
        searchKey: "asset_additionals.return",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.return
    },
    {
        label: "Is Unit Properly Labeled",
        key: "unit_property_labeled",
        paramKey: "asset_additionals.unit_property_labeled",
        band: "Asset Additional",
        getListKey: "asset_additionals.unit_property_labeled",
        searchKey: "asset_additionals.unit_property_labeled",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.unit_property_labeled
    },
    {
        label: "Factory Mounted Disconnect",
        key: "factory_mounted_disconnect",
        paramKey: "asset_additionals.factory_mounted_disconnect",
        band: "Asset Additional",
        getListKey: "asset_additionals.factory_mounted_disconnect",
        searchKey: "asset_additionals.factory_mounted_disconnect",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.factory_mounted_disconnect
    },
    {
        label: "Unit Voltage",
        key: "unit_voltage",
        paramKey: "asset_additionals.unit_voltage",
        band: "Asset Additional",
        getListKey: "asset_additionals.unit_voltage",
        searchKey: "asset_additionals.unit_voltage",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.unit_voltage
    },
    {
        label: "Ahu Voltage",
        key: "ahu_voltage",
        paramKey: "asset_additionals.ahu_voltage",
        band: "Asset Additional",
        getListKey: "asset_additionals.ahu_voltage",
        searchKey: "asset_additionals.ahu_voltage",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.ahu_voltage
    },
    {
        label: "Wire Type",
        key: "wire_type",
        paramKey: "asset_additionals.wire_type",
        band: "Asset Additional",
        getListKey: "asset_additionals.wire_type",
        searchKey: "asset_additionals.wire_type",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.wire_type
    },
    {
        label: "Wire Size",
        key: "wire_size",
        paramKey: "asset_additionals.wire_size",
        band: "Asset Additional",
        getListKey: "asset_additionals.wire_size",
        searchKey: "asset_additionals.wire_size",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.wire_size
    },
    {
        label: "Estimated Wire Run Length",
        key: "estimated_wire_run_length",
        paramKey: "asset_additionals.estimated_wire_run_length",
        band: "Asset Additional",
        getListKey: "asset_additionals.estimated_wire_run_length",
        searchKey: "asset_additionals.estimated_wire_run_length",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.estimated_wire_run_length
    },
    {
        label: "Breaker Size",
        key: "breaker_size",
        paramKey: "asset_additionals.breaker_size",
        band: "Asset Additional",
        getListKey: "asset_additionals.breaker_size",
        searchKey: "asset_additionals.breaker_size",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.breaker_size
    },
    {
        label: "MOCP",
        key: "mocp",
        paramKey: "asset_additionals.mocp",
        band: "Asset Additional",
        getListKey: "asset_additionals.mocp",
        searchKey: "asset_additionals.mocp",
        isWildCardFilter: false,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.mocp
    },
    {
        label: "MCA",
        key: "mca",
        paramKey: "asset_additionals.mca",
        band: "Asset Additional",
        getListKey: "asset_additionals.mca",
        searchKey: "asset_additionals.mca",
        isWildCardFilter: false,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.mca
    },
    {
        label: "Liquid Line",
        key: "liquid_line",
        paramKey: "asset_additionals.liquid_line",
        band: "Asset Additional",
        getListKey: "asset_additionals.liquid_line",
        searchKey: "asset_additionals.liquid_line",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.liquid_line
    },
    {
        label: "Suction Line",
        key: "suction_line",
        paramKey: "asset_additionals.suction_line",
        band: "Asset Additional",
        getListKey: "asset_additionals.suction_line",
        searchKey: "asset_additionals.suction_line",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.suction_line
    },
    {
        label: "Hot Gas By-Pass",
        key: "hot_gas_by_pass",
        paramKey: "asset_additionals.hot_gas_by_pass",
        band: "Asset Additional",
        getListKey: "asset_additionals.hot_gas_by_pass",
        searchKey: "asset_additionals.hot_gas_by_pass",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.hot_gas_by_pass
    },
    {
        label: "Number Of Circuits",
        key: "number_of_circuits",
        paramKey: "asset_additionals.number_of_circuits",
        band: "Asset Additional",
        getListKey: "asset_additionals.number_of_circuits",
        searchKey: "asset_additionals.number_of_circuits",
        isWildCardFilter: false,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.number_of_circuits
    },
    {
        label: "Length of Line Run",
        key: "length_of_line",
        paramKey: "asset_additionals.length_of_line",
        band: "Asset Additional",
        getListKey: "asset_additionals.length_of_line",
        searchKey: "asset_additionals.length_of_line",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.length_of_line
    },
    {
        label: "Damper Type",
        key: "damper_type",
        paramKey: "asset_additionals.damper_type",
        band: "Asset Additional",
        getListKey: "asset_additionals.damper_type",
        searchKey: "asset_additionals.damper_type",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.damper_type
    },
    {
        label: "Gas Heating",
        key: "gas_heating",
        paramKey: "asset_additionals.gas_heating",
        band: "Asset Additional",
        getListKey: "asset_additionals.gas_heating",
        searchKey: "asset_additionals.gas_heating",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.gas_heating
    },
    {
        label: "Gas Output BTU",
        key: "gas_output_btu",
        paramKey: "asset_additionals.gas_output_btu",
        band: "Asset Additional",
        getListKey: "asset_additionals.gas_output_btu",
        searchKey: "asset_additionals.gas_output_btu",
        isWildCardFilter: false,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.gas_output_btu
    },
    {
        label: "Electric Heating",
        key: "electric_heating",
        paramKey: "asset_additionals.electric_heating",
        band: "Asset Additional",
        getListKey: "asset_additionals.electric_heating",
        searchKey: "asset_additionals.electric_heating",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.electric_heating
    },
    {
        label: "Electric Heating KW",
        key: "electric_heating_kw",
        paramKey: "asset_additionals.electric_heating_kw",
        band: "Asset Additional",
        getListKey: "asset_additionals.electric_heating_kw",
        searchKey: "asset_additionals.electric_heating_kw",
        isWildCardFilter: false,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.electric_heating_kw
    },
    {
        label: "Heat Pump - Backup Heat KW",
        key: "pump_backup_heat_kw",
        paramKey: "asset_additionals.pump_backup_heat_kw",
        band: "Asset Additional",
        getListKey: "asset_additionals.pump_backup_heat_kw",
        searchKey: "asset_additionals.pump_backup_heat_kw",
        isWildCardFilter: false,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.pump_backup_heat_kw
    },
    {
        label: "Cooling Only",
        key: "cooling_only",
        paramKey: "asset_additionals.cooling_only",
        band: "Asset Additional",
        getListKey: "asset_additionals.cooling_only",
        searchKey: "asset_additionals.cooling_only",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.cooling_only
    },
    {
        label: "Controls",
        key: "controls",
        paramKey: "asset_additionals.controls",
        band: "Asset Additional",
        getListKey: "asset_additionals.controls",
        searchKey: "asset_additionals.controls",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.controls
    },
    {
        label: "Controls Type",
        key: "control_type",
        paramKey: "asset_additionals.control_type",
        band: "Asset Additional",
        getListKey: "asset_additionals.control_type",
        searchKey: "asset_additionals.control_type",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.control_type
    },
    {
        label: "Smoke Detector",
        key: "smoke_detector",
        paramKey: "asset_additionals.smoke_detector",
        band: "Asset Additional",
        getListKey: "asset_additionals.smoke_detector",
        searchKey: "asset_additionals.smoke_detector",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.smoke_detector
    },
    {
        label: "Smoke Detector Mounting",
        key: "smoke_detector_mounting",
        paramKey: "asset_additionals.smoke_detector_mounting",
        band: "Asset Additional",
        getListKey: "asset_additionals.smoke_detector_mounting",
        searchKey: "asset_additionals.smoke_detector_mounting",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.smoke_detector_mounting
    },
    {
        label: "Smoke Detector-Supply",
        key: "smoke_detector_supply",
        paramKey: "asset_additionals.smoke_detector_supply",
        band: "Asset Additional",
        getListKey: "asset_additionals.smoke_detector_supply",
        searchKey: "asset_additionals.smoke_detector_supply",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.smoke_detector_supply
    },
    {
        label: "Smoke Detector-Return",
        key: "smoke_detector_return",
        paramKey: "asset_additionals.smoke_detector_return",
        band: "Asset Additional",
        getListKey: "asset_additionals.smoke_detector_return",
        searchKey: "asset_additionals.smoke_detector_return",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.smoke_detector_return
    },
    {
        label: "Curb",
        key: "curb",
        paramKey: "asset_additionals.curb",
        band: "Asset Additional",
        getListKey: "asset_additionals.curb",
        searchKey: "asset_additionals.curb",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.curb
    },
    {
        label: "Curb Other Description",
        key: "curb_description",
        paramKey: "asset_additionals.curb_description",
        band: "Asset Additional",
        getListKey: "asset_additionals.curb_description",
        searchKey: "asset_additionals.curb_description",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.curb_description
    },
    {
        label: "Vibration Isolation",
        key: "vibration_isolation",
        paramKey: "asset_additionals.vibration_isolation",
        band: "Asset Additional",
        getListKey: "asset_additionals.vibration_isolation",
        searchKey: "asset_additionals.vibration_isolation",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.vibration_isolation
    },
    {
        label: "Burglar Bars",
        key: "burglar_bars",
        paramKey: "asset_additionals.burglar_bars",
        band: "Asset Additional",
        getListKey: "asset_additionals.burglar_bars",
        searchKey: "asset_additionals.burglar_bars",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.burglar_bars
    },
    {
        label: "Existing Unit Installed on an Adapter Curb?",
        key: "installed_on_adapter_curb",
        paramKey: "asset_additionals.installed_on_adapter_curb",
        band: "Asset Additional",
        getListKey: "asset_additionals.installed_on_adapter_curb",
        searchKey: "asset_additionals.installed_on_adapter_curb",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.installed_on_adapter_curb
    },
    {
        label: "Existing Curb Outside Dimension Length",
        key: "curb_outside_dimension_length",
        paramKey: "asset_additionals.curb_outside_dimension_length",
        band: "Asset Additional",
        getListKey: "asset_additionals.curb_outside_dimension_length",
        searchKey: "asset_additionals.curb_outside_dimension_length",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.curb_outside_dimension_length
    },
    {
        label: "Existing Curb Outside Dimension Width",
        key: "curb_outside_dimension_width",
        paramKey: "asset_additionals.curb_outside_dimension_width",
        band: "Asset Additional",
        getListKey: "asset_additionals.curb_outside_dimension_width",
        searchKey: "asset_additionals.curb_outside_dimension_width",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.curb_outside_dimension_width
    },
    {
        label: "Will Mall, Landlord, Municipality Allow a Curb Adapter?",
        key: "allow_curb_adaptor",
        paramKey: "asset_additionals.allow_curb_adaptor",
        band: "Asset Additional",
        getListKey: "asset_additionals.allow_curb_adaptor",
        searchKey: "asset_additionals.allow_curb_adaptor",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.allow_curb_adaptor
    },
    {
        label: "Is this Ducted or Concentric System",
        key: "ducted_or_concentric_system",
        paramKey: "asset_additionals.ducted_or_concentric_system",
        band: "Asset Additional",
        getListKey: "asset_additionals.ducted_or_concentric_system",
        searchKey: "asset_additionals.ducted_or_concentric_system",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.ducted_or_concentric_system
    },
    {
        label: "Unit Weight",
        key: "unit_weight",
        paramKey: "asset_additionals.unit_weight",
        band: "Asset Additional",
        getListKey: "asset_additionals.unit_weight",
        searchKey: "asset_additionals.unit_weight",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.unit_weight
    },
    {
        label: "Any Special Codes for Disconnects",
        key: "codes_for_disconnects",
        paramKey: "asset_additionals.codes_for_disconnects",
        band: "Asset Additional",
        getListKey: "asset_additionals.codes_for_disconnects",
        searchKey: "asset_additionals.codes_for_disconnects",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.codes_for_disconnects
    },
    {
        label: "Any Special Codes Codes for Convenience Outlets",
        key: "codes_for_convenience_outlets",
        paramKey: "asset_additionals.codes_for_convenience_outlets",
        band: "Asset Additional",
        getListKey: "asset_additionals.codes_for_convenience_outlets",
        searchKey: "asset_additionals.codes_for_convenience_outlets",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.codes_for_convenience_outlets
    },
    {
        label: "Any Special Codes Codes for Roof Lighting",
        key: "codes_for_roof_lighting",
        paramKey: "asset_additionals.codes_for_roof_lighting",
        band: "Asset Additional",
        getListKey: "asset_additionals.codes_for_roof_lighting",
        searchKey: "asset_additionals.codes_for_roof_lighting",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.codes_for_roof_lighting
    },
    {
        label: "Current Duct Work Attached to Bottom of Unit",
        key: "duct_work_attached_to_bottom_of_unit",
        paramKey: "asset_additionals.duct_work_attached_to_bottom_of_unit",
        band: "Asset Additional",
        getListKey: "asset_additionals.duct_work_attached_to_bottom_of_unit",
        searchKey: "asset_additionals.duct_work_attached_to_bottom_of_unit",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.duct_work_attached_to_bottom_of_unit
    },
    {
        label: "Was Aluminium Wire Used",
        key: "aluminium_wire_used",
        paramKey: "asset_additionals.aluminium_wire_used",
        band: "Asset Additional",
        getListKey: "asset_additionals.aluminium_wire_used",
        searchKey: "asset_additionals.aluminium_wire_used",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.aluminium_wire_used
    },
    {
        label: "Are there any Code or Safety Concerns",
        key: "code_or_safety_concerns",
        paramKey: "asset_additionals.code_or_safety_concerns",
        band: "Asset Additional",
        getListKey: "asset_additionals.code_or_safety_concerns",
        searchKey: "asset_additionals.code_or_safety_concerns",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.code_or_safety_concerns
    },
    {
        label: "Distance from Body Of Salt Water",
        key: "distance_from_salt_water_body",
        paramKey: "asset_additionals.distance_from_salt_water_body",
        band: "Asset Additional",
        getListKey: "asset_additionals.distance_from_salt_water_body",
        searchKey: "asset_additionals.distance_from_salt_water_body",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.distance_from_salt_water_body
    },
    {
        label: "Recommend Coil Coating",
        key: "recommend_coil_coating",
        paramKey: "asset_additionals.recommend_coil_coating",
        band: "Asset Additional",
        getListKey: "asset_additionals.recommend_coil_coating",
        searchKey: "asset_additionals.recommend_coil_coating",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.recommend_coil_coating
    },
    {
        label: "Do Electrical/Piping Roof Penetrations Need To Be Resealed",
        key: "need_resealed",
        paramKey: "asset_additionals.need_resealed",
        band: "Asset Additional",
        getListKey: "asset_additionals.need_resealed",
        searchKey: "asset_additionals.need_resealed",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.need_resealed
    },
    {
        label: "Split System - Are Line Sets Good Condition",
        key: "split_system_line_sets_good_condition",
        paramKey: "asset_additionals.split_system_line_sets_good_condition",
        band: "Asset Additional",
        getListKey: "asset_additionals.split_system_line_sets_good_condition",
        searchKey: "asset_additionals.split_system_line_sets_good_condition",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.split_system_line_sets_good_condition
    },
    {
        label: "Split System- Are Line Sets Damaged",
        key: "split_system_line_sets_damaged",
        paramKey: "asset_additionals.split_system_line_sets_damaged",
        band: "Asset Additional",
        getListKey: "asset_additionals.split_system_line_sets_damaged",
        searchKey: "asset_additionals.split_system_line_sets_damaged",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.split_system_line_sets_damaged
    },
    {
        label: "Split System- Are Line Sets Crimped",
        key: "split_system_line_sets_crimped",
        paramKey: "asset_additionals.split_system_line_sets_crimped",
        band: "Asset Additional",
        getListKey: "asset_additionals.split_system_line_sets_crimped",
        searchKey: "asset_additionals.split_system_line_sets_crimped",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.split_system_line_sets_crimped
    },
    {
        label: "Split System- Are Line Sets Insulated",
        key: "split_system_line_sets_insulated",
        paramKey: "asset_additionals.split_system_line_sets_insulated",
        band: "Asset Additional",
        getListKey: "asset_additionals.split_system_line_sets_insulated",
        searchKey: "asset_additionals.split_system_line_sets_insulated",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.split_system_line_sets_insulated
    },
    {
        label: "Split System- Can Line Sets To Be Re-Used",
        key: "split_system_line_sets_re_used",
        paramKey: "asset_additionals.split_system_line_sets_re_used",
        band: "Asset Additional",
        getListKey: "asset_additionals.split_system_line_sets_re_used",
        searchKey: "asset_additionals.split_system_line_sets_re_used",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.split_system_line_sets_re_used
    },
    {
        label: "Line Connection Type",
        key: "line_connection_type",
        paramKey: "asset_additionals.line_connection_type",
        band: "Asset Additional",
        getListKey: "asset_additionals.line_connection_type",
        searchKey: "asset_additionals.line_connection_type",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.line_connection_type
    },
    {
        label: "Boiler Type",
        key: "boiler_type",
        paramKey: "asset_additionals.boiler_type",
        band: "Boiler Fields",
        getListKey: "asset_additionals.boiler_type",
        searchKey: "asset_additionals.boiler_type",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.boiler_type
    },
    {
        label: "Boiler Burner Type",
        key: "boiler_burner_type",
        paramKey: "asset_additionals.boiler_burner_type",
        band: "Boiler Fields",
        getListKey: "asset_additionals.boiler_burner_type",
        searchKey: "asset_additionals.boiler_burner_type",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.boiler_burner_type
    },
    {
        label: "Boiler BTU Input",
        key: "boiler_btu_input",
        paramKey: "asset_additionals.boiler_btu_input",
        band: "Boiler Fields",
        getListKey: "asset_additionals.boiler_btu_input",
        searchKey: "asset_additionals.boiler_btu_input",
        isWildCardFilter: false,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.boiler_btu_input
    },
    {
        label: "Boiler BTU Output",
        key: "boiler_btu_output",
        paramKey: "asset_additionals.boiler_btu_output",
        band: "Boiler Fields",
        getListKey: "asset_additionals.boiler_btu_output",
        searchKey: "asset_additionals.boiler_btu_output",
        isWildCardFilter: false,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.boiler_btu_output
    },
    {
        label: "Boiler HP",
        key: "boiler_hp",
        paramKey: "asset_additionals.boiler_hp",
        band: "Boiler Fields",
        getListKey: "asset_additionals.boiler_hp",
        searchKey: "asset_additionals.boiler_hp",
        isWildCardFilter: false,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.boiler_hp
    },
    {
        label: "Boiler Fuel",
        key: "boiler_fuel",
        paramKey: "asset_additionals.boiler_fuel",
        band: "Boiler Fields",
        getListKey: "asset_additionals.boiler_fuel",
        searchKey: "asset_additionals.boiler_fuel",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.boiler_fuel
    },
    {
        label: "Boiler Gas Pressure",
        key: "boiler_gas_pressure",
        paramKey: "asset_additionals.boiler_gas_pressure",
        band: "Boiler Fields",
        getListKey: "asset_additionals.boiler_gas_pressure",
        searchKey: "asset_additionals.boiler_gas_pressure",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.boiler_gas_pressure
    },
    {
        label: "Boiler KW Input",
        key: "boiler_kw_input",
        paramKey: "asset_additionals.boiler_kw_input",
        band: "Boiler Fields",
        getListKey: "asset_additionals.boiler_kw_input",
        searchKey: "asset_additionals.boiler_kw_input",
        isWildCardFilter: false,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.boiler_kw_input
    },
    {
        label: "Boiler Power Burner Type",
        key: "boiler_power_burner_type",
        paramKey: "asset_additionals.boiler_power_burner_type",
        band: "Boiler Fields",
        getListKey: "asset_additionals.boiler_power_burner_type",
        searchKey: "asset_additionals.boiler_power_burner_type",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.boiler_power_burner_type
    },
    {
        label: "Boiler Burner Model",
        key: "boiler_burner_model",
        paramKey: "asset_additionals.boiler_burner_model",
        band: "Boiler Fields",
        getListKey: "asset_additionals.boiler_burner_model",
        searchKey: "asset_additionals.boiler_burner_model",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.boiler_burner_model
    },
    {
        label: "Boiler Burner Serial",
        key: "boiler_burner_serial",
        paramKey: "asset_additionals.boiler_burner_serial",
        band: "Boiler Fields",
        getListKey: "asset_additionals.boiler_burner_serial",
        searchKey: "asset_additionals.boiler_burner_serial",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.boiler_burner_serial
    },
    {
        label: "Boiler Operating Pressure Type",
        key: "boiler_pressure_type",
        paramKey: "asset_additionals.boiler_pressure_type",
        band: "Boiler Fields",
        getListKey: "asset_additionals.boiler_pressure_type",
        searchKey: "asset_additionals.boiler_pressure_type",
        isWildCardFilter: false,
        fieldType: "text",
        multiEditKey: multiEditFieldKeys.boiler_pressure_type
    },
    {
        label: "Confirm Boiler Pressure",
        key: "confirm_boiler_pressure",
        paramKey: "asset_additionals.confirm_boiler_pressure",
        band: "Boiler Fields",
        getListKey: "asset_additionals.confirm_boiler_pressure",
        searchKey: "asset_additionals.confirm_boiler_pressure",
        isWildCardFilter: false,
        fieldType: "number",
        multiEditKey: multiEditFieldKeys.confirm_boiler_pressure
    },
    {
        label: "Created At",
        key: "created_at",
        paramKey: "assets.created_at",
        getListKey: "assets.created_at",
        searchKey: "assets.created_at",
        isWildCardFilter: true,
        fieldType: "text"
    },
    {
        label: "Updated At",
        key: "updated_at",
        paramKey: "assets.updated_at",
        getListKey: "assets.updated_at",
        searchKey: "assets.updated_at",
        isWildCardFilter: true,
        fieldType: "text"
    }
];

export const multiEditFields = {
    asset_name: DYNAMIC_FIELD_TYPES.TEXT,
    asset_description: DYNAMIC_FIELD_TYPES.TEXT,
    asset_type_id: DYNAMIC_FIELD_TYPES.SELECT,
    asset_status_id: DYNAMIC_FIELD_TYPES.SELECT,
    client_asset_condition_id: DYNAMIC_FIELD_TYPES.SELECT,
    asset_condition_description: DYNAMIC_FIELD_TYPES.TEXT,
    asset_ownership: DYNAMIC_FIELD_TYPES.SELECT,
    criticality: DYNAMIC_FIELD_TYPES.TEXT,
    asset_tag: DYNAMIC_FIELD_TYPES.TEXT,
    guid: DYNAMIC_FIELD_TYPES.TEXT,
    model_number: DYNAMIC_FIELD_TYPES.TEXT,
    core_model_number: DYNAMIC_FIELD_TYPES.TEXT,
    serial_number: DYNAMIC_FIELD_TYPES.TEXT,
    capacity: DYNAMIC_FIELD_TYPES.TEXT,
    capacity_unit: DYNAMIC_FIELD_TYPES.TEXT,
    capacity_status: DYNAMIC_FIELD_TYPES.TEXT,
    asset_barcode: DYNAMIC_FIELD_TYPES.TEXT,
    asset_client_id: DYNAMIC_FIELD_TYPES.TEXT,
    asset_cmms_id: DYNAMIC_FIELD_TYPES.TEXT,
    rtls_tag: DYNAMIC_FIELD_TYPES.TEXT,
    client_id: DYNAMIC_FIELD_TYPES.SELECT,
    region_id: DYNAMIC_FIELD_TYPES.SELECT,
    site_id: DYNAMIC_FIELD_TYPES.SELECT,
    building_id: DYNAMIC_FIELD_TYPES.SELECT,
    addition_id: DYNAMIC_FIELD_TYPES.SELECT,
    floor_id: DYNAMIC_FIELD_TYPES.SELECT,
    room_number: DYNAMIC_FIELD_TYPES.TEXT,
    room_name: DYNAMIC_FIELD_TYPES.TEXT,
    location: DYNAMIC_FIELD_TYPES.TEXT,
    architectural_room_number: DYNAMIC_FIELD_TYPES.TEXT,
    additional_room_description: DYNAMIC_FIELD_TYPES.TEXT,
    longitude: DYNAMIC_FIELD_TYPES.NUMBER,
    latitude: DYNAMIC_FIELD_TYPES.NUMBER,
    manufacturer: DYNAMIC_FIELD_TYPES.TEXT,
    year_manufactured: DYNAMIC_FIELD_TYPES.NUMBER,
    installed_year: DYNAMIC_FIELD_TYPES.NUMBER,
    current_age: DYNAMIC_FIELD_TYPES.TEXT,
    service_life: DYNAMIC_FIELD_TYPES.NUMBER,
    end_of_service_life: DYNAMIC_FIELD_TYPES.TEXT,
    usefull_life_remaining: DYNAMIC_FIELD_TYPES.TEXT,
    csp_unit: DYNAMIC_FIELD_TYPES.TEXT,
    crv: DYNAMIC_FIELD_TYPES.NUMBER,
    refrigerant: DYNAMIC_FIELD_TYPES.TEXT,
    warranty_start: DYNAMIC_FIELD_TYPES.DATE,
    warranty_end: DYNAMIC_FIELD_TYPES.DATE,
    install_date: DYNAMIC_FIELD_TYPES.DATE,
    startup_date: DYNAMIC_FIELD_TYPES.DATE,
    uniformat_level_1_id: DYNAMIC_FIELD_TYPES.SELECT,
    uniformat_level_2_id: DYNAMIC_FIELD_TYPES.SELECT,
    uniformat_level_3_id: DYNAMIC_FIELD_TYPES.SELECT,
    uniformat_level_4_id: DYNAMIC_FIELD_TYPES.SELECT,
    uniformat_level_5_id: DYNAMIC_FIELD_TYPES.SELECT,
    uniformat_level_6_id: DYNAMIC_FIELD_TYPES.SELECT,
    uniformat_level_6_description: DYNAMIC_FIELD_TYPES.TEXT,
    uniformat_level_3_custom_description: DYNAMIC_FIELD_TYPES.TEXT,
    main_category_id: DYNAMIC_FIELD_TYPES.SELECT,
    sub_category_1_id: DYNAMIC_FIELD_TYPES.SELECT,
    sub_category_2_id: DYNAMIC_FIELD_TYPES.SELECT,
    subcategory2_description: DYNAMIC_FIELD_TYPES.TEXT,
    sub_category_3_id: DYNAMIC_FIELD_TYPES.SELECT,
    trade_id: DYNAMIC_FIELD_TYPES.SELECT,
    system_id: DYNAMIC_FIELD_TYPES.SELECT,
    sub_system_id: DYNAMIC_FIELD_TYPES.SELECT,
    asset_note: DYNAMIC_FIELD_TYPES.TEXT,
    survey_id: DYNAMIC_FIELD_TYPES.TEXT,
    survey_property_note: DYNAMIC_FIELD_TYPES.TEXT,
    capacity_status: DYNAMIC_FIELD_TYPES.TEXT,
    installed_year_status: DYNAMIC_FIELD_TYPES.TEXT,
    name_plate_status: DYNAMIC_FIELD_TYPES.TEXT,
    qa_notes: DYNAMIC_FIELD_TYPES.TEXT,
    additional_qa_notes: DYNAMIC_FIELD_TYPES.TEXT,
    survey_date_created: DYNAMIC_FIELD_TYPES.DATE,
    surveyor: DYNAMIC_FIELD_TYPES.TEXT,
    survey_date_edited: DYNAMIC_FIELD_TYPES.DATE,
    editor: DYNAMIC_FIELD_TYPES.TEXT,
    new_asset: DYNAMIC_FIELD_TYPES.SELECT,
    area_served: DYNAMIC_FIELD_TYPES.TEXT,
    upstream_asset_barcode_number: DYNAMIC_FIELD_TYPES.TEXT,
    linked_asset_barcode_number: DYNAMIC_FIELD_TYPES.TEXT,
    source_panel_name: DYNAMIC_FIELD_TYPES.TEXT,
    source_panel_barcode_number: DYNAMIC_FIELD_TYPES.TEXT,
    source_panel: DYNAMIC_FIELD_TYPES.TEXT,
    skysite_hyperlink: DYNAMIC_FIELD_TYPES.TEXT,
    quantity: DYNAMIC_FIELD_TYPES.NUMBER,
    parent_global_id: DYNAMIC_FIELD_TYPES.TEXT,
    discharge: DYNAMIC_FIELD_TYPES.SELECT,
    return: DYNAMIC_FIELD_TYPES.SELECT,
    unit_property_labeled: DYNAMIC_FIELD_TYPES.SELECT,
    factory_mounted_disconnect: DYNAMIC_FIELD_TYPES.SELECT,
    unit_voltage: DYNAMIC_FIELD_TYPES.SELECT,
    ahu_voltage: DYNAMIC_FIELD_TYPES.SELECT,
    wire_type: DYNAMIC_FIELD_TYPES.TEXT,
    wire_size: DYNAMIC_FIELD_TYPES.TEXT,
    estimated_wire_run_length: DYNAMIC_FIELD_TYPES.TEXT,
    breaker_size: DYNAMIC_FIELD_TYPES.TEXT,
    mocp: DYNAMIC_FIELD_TYPES.NUMBER,
    mca: DYNAMIC_FIELD_TYPES.NUMBER,
    liquid_line: DYNAMIC_FIELD_TYPES.TEXT,
    suction_line: DYNAMIC_FIELD_TYPES.TEXT,
    hot_gas_by_pass: DYNAMIC_FIELD_TYPES.SELECT,
    number_of_circuits: DYNAMIC_FIELD_TYPES.NUMBER,
    length_of_line: DYNAMIC_FIELD_TYPES.TEXT,
    damper_type: DYNAMIC_FIELD_TYPES.SELECT,
    gas_heating: DYNAMIC_FIELD_TYPES.SELECT,
    gas_output_btu: DYNAMIC_FIELD_TYPES.TEXT,
    electric_heating: DYNAMIC_FIELD_TYPES.SELECT,
    electric_heating_kw: DYNAMIC_FIELD_TYPES.TEXT,
    pump_backup_heat_kw: DYNAMIC_FIELD_TYPES.SELECT,
    cooling_only: DYNAMIC_FIELD_TYPES.SELECT,
    controls: DYNAMIC_FIELD_TYPES.SELECT,
    control_type: DYNAMIC_FIELD_TYPES.TEXT,
    smoke_detector: DYNAMIC_FIELD_TYPES.SELECT,
    smoke_detector_mounting: DYNAMIC_FIELD_TYPES.SELECT,
    smoke_detector_supply: DYNAMIC_FIELD_TYPES.SELECT,
    smoke_detector_return: DYNAMIC_FIELD_TYPES.SELECT,
    curb: DYNAMIC_FIELD_TYPES.SELECT,
    curb_description: DYNAMIC_FIELD_TYPES.TEXT,
    vibration_isolation: DYNAMIC_FIELD_TYPES.SELECT,
    burglar_bars: DYNAMIC_FIELD_TYPES.SELECT,
    installed_on_adapter_curb: DYNAMIC_FIELD_TYPES.SELECT,
    curb_outside_dimension_length: DYNAMIC_FIELD_TYPES.TEXT,
    curb_outside_dimension_width: DYNAMIC_FIELD_TYPES.TEXT,
    allow_curb_adaptor: DYNAMIC_FIELD_TYPES.SELECT,
    ducted_or_concentric_system: DYNAMIC_FIELD_TYPES.SELECT,
    unit_weight: DYNAMIC_FIELD_TYPES.TEXT,
    codes_for_disconnects: DYNAMIC_FIELD_TYPES.SELECT,
    codes_for_convenience_outlets: DYNAMIC_FIELD_TYPES.SELECT,
    codes_for_roof_lighting: DYNAMIC_FIELD_TYPES.SELECT,
    duct_work_attached_to_bottom_of_unit: DYNAMIC_FIELD_TYPES.SELECT,
    aluminium_wire_used: DYNAMIC_FIELD_TYPES.SELECT,
    code_or_safety_concerns: DYNAMIC_FIELD_TYPES.SELECT,
    distance_from_salt_water_body: DYNAMIC_FIELD_TYPES.TEXT,
    recommend_coil_coating: DYNAMIC_FIELD_TYPES.SELECT,
    need_resealed: DYNAMIC_FIELD_TYPES.SELECT,
    split_system_line_sets_good_condition: DYNAMIC_FIELD_TYPES.SELECT,
    split_system_line_sets_damaged: DYNAMIC_FIELD_TYPES.SELECT,
    split_system_line_sets_crimped: DYNAMIC_FIELD_TYPES.SELECT,
    split_system_line_sets_insulated: DYNAMIC_FIELD_TYPES.SELECT,
    split_system_line_sets_re_used: DYNAMIC_FIELD_TYPES.SELECT,
    line_connection_type: DYNAMIC_FIELD_TYPES.TEXT,
    boiler_type: DYNAMIC_FIELD_TYPES.TEXT,
    boiler_burner_type: DYNAMIC_FIELD_TYPES.TEXT,
    boiler_btu_input: DYNAMIC_FIELD_TYPES.TEXT,
    boiler_btu_output: DYNAMIC_FIELD_TYPES.TEXT,
    boiler_hp: DYNAMIC_FIELD_TYPES.NUMBER,
    boiler_fuel: DYNAMIC_FIELD_TYPES.TEXT,
    boiler_gas_pressure: DYNAMIC_FIELD_TYPES.TEXT,
    boiler_kw_input: DYNAMIC_FIELD_TYPES.TEXT,
    boiler_power_burner_type: DYNAMIC_FIELD_TYPES.TEXT,
    boiler_burner_model: DYNAMIC_FIELD_TYPES.TEXT,
    boiler_burner_serial: DYNAMIC_FIELD_TYPES.TEXT,
    boiler_pressure_type: DYNAMIC_FIELD_TYPES.SELECT,
    confirm_boiler_pressure: DYNAMIC_FIELD_TYPES.TEXT,
    unit_cost: DYNAMIC_FIELD_TYPES.NUMBER
};
