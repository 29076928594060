import React from "react";
import Draggable from "react-draggable";
import PropertiesForm from "../../chartProperties/components/Form";

const ExportPropertiesModal = ({ onCancel, selectedExportProperty, handleUpdateExportProperty }) => {
    return (
        <div
            className="modal assign-init-modal image-pull-modal config-modal export-config-modal"
            style={{ display: "block" }}
            id="modalId"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <Draggable handle=".draggable" cancel=".non-draggable">
                <div className="modal-dialog assignModal" role="document">
                    <div className="modal-content">
                        <div className="modal-header draggable">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <div className="txt-hed">Edit Export Properties</div>
                            </h5>
                            <button type="button" className="close non-draggable" onClick={onCancel}>
                                <span aria-hidden="true">
                                    <img src="/img/close.svg" alt="" />
                                </span>
                            </button>
                        </div>
                        <form autoComplete="nope">
                            <div className="modal-body ">
                                <div className="form-group">
                                    <div className="formInp">
                                        <div className="dashboard-outer">
                                            <div className="outer-detail">
                                                <div className="right-panel-section">
                                                    <div>
                                                        <div className="dtl-sec system-building col-md-12 ">
                                                            <div className="tab-dtl region-mng">
                                                                <div className="tab-active recomdn-table bg-grey-table modal-table-scroll">
                                                                    <PropertiesForm
                                                                        isSmartchartView
                                                                        selectedProperty={selectedExportProperty}
                                                                        handleUpdateProperty={handleUpdateExportProperty}
                                                                        cancelForm={onCancel}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Draggable>
        </div>
    );
};
export default ExportPropertiesModal;
