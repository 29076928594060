export const GET_CHART_TEMPLATES_REQUEST = "GET_CHART_TEMPLATES_REQUEST";
export const GET_CHART_TEMPLATES_SUCCESS = "GET_CHART_TEMPLATES_SUCCESS";
export const GET_CHART_TEMPLATES_FAILURE = "GET_CHART_TEMPLATES_FAILURE";

export const ADD_CHART_TEMPLATE_REQUEST = "ADD_CHART_TEMPLATE_REQUEST";
export const ADD_CHART_TEMPLATE_SUCCESS = "ADD_CHART_TEMPLATE_SUCCESS";
export const ADD_CHART_TEMPLATE_FAILURE = "ADD_CHART_TEMPLATE_FAILURE";

export const GET_CHART_TEMPLATE_BY_ID_REQUEST = "GET_CHART_TEMPLATE_BY_ID_REQUEST";
export const GET_CHART_TEMPLATE_BY_ID_SUCCESS = "GET_CHART_TEMPLATE_BY_ID_SUCCESS";
export const GET_CHART_TEMPLATE_BY_ID_FAILURE = "GET_CHART_TEMPLATE_BY_ID_FAILURE";

export const UPDATE_CHART_TEMPLATE_REQUEST = "UPDATE_CHART_TEMPLATE_REQUEST";
export const UPDATE_CHART_TEMPLATE_SUCCESS = "UPDATE_CHART_TEMPLATE_SUCCESS";
export const UPDATE_CHART_TEMPLATE_FAILURE = "UPDATE_CHART_TEMPLATE_FAILURE";

export const DELETE_CHART_TEMPLATE_REQUEST = "DELETE_CHART_TEMPLATE_REQUEST";
export const DELETE_CHART_TEMPLATE_SUCCESS = "DELETE_CHART_TEMPLATE_SUCCESS";
export const DELETE_CHART_TEMPLATE_FAILURE = "DELETE_CHART_TEMPLATE_FAILURE";

// Update Floor Entity Param
export const UPDATE_CHART_TEMPLATE_ENTITY_PARAMS_SUCCESS = "UPDATE_CHART_TEMPLATE_ENTITY_PARAMS_SUCCESS";
export const UPDATE_CHART_TEMPLATE_ENTITY_PARAMS_FAILURE = "UPDATE_CHART_TEMPLATE_ENTITY_PARAMS_FAILURE";

export const EXPORT_CHART_TEMPLATE_REQUEST = "EXPORT_CHART_TEMPLATE_REQUEST";
export const EXPORT_CHART_TEMPLATE_SUCCESS = "EXPORT_CHART_TEMPLATE_SUCCESS";
export const EXPORT_CHART_TEMPLATE_FAILURE = "EXPORT_CHART_TEMPLATE_FAILURE";

export const GET_CHART_PROPERTY_DROPDOWN_REQUEST = "GET_CHART_PROPERTY_DROPDOWN_REQUEST";
export const GET_CHART_PROPERTY_DROPDOWN_SUCCESS = "GET_CHART_PROPERTY_DROPDOWN_SUCCESS";
export const GET_CHART_PROPERTY_DROPDOWN_FAILURE = "GET_CHART_PROPERTY_DROPDOWN_FAILURE";

export const RESTORE_CHART_TEMPLATE_REQUEST = "RESTORE_CHART_TEMPLATE_REQUEST";
export const RESTORE_CHART_TEMPLATE_SUCCESS = "RESTORE_CHART_TEMPLATE_SUCCESS";
export const RESTORE_CHART_TEMPLATE_FAILURE = "RESTORE_CHART_TEMPLATE_FAILURE";

export const ADD_USER_ACTIVITY_LOG_REQUEST = "ADD_USER_ACTIVITY_LOG_REQUEST";
export const ADD_USER_ACTIVITY_LOG_SUCCESS = "ADD_USER_ACTIVITY_LOG_SUCCESS";
export const ADD_USER_ACTIVITY_LOG_FAILURE = "ADD_USER_ACTIVITY_LOG_FAILURE";

export const UPDATE_CHART_TEMPLATE_STATUS_REQUEST = "UPDATE_CHART_TEMPLATE_STATUS_REQUEST";
export const UPDATE_CHART_TEMPLATE_STATUS_SUCCESS = "UPDATE_CHART_TEMPLATE_STATUS_SUCCESS";
export const UPDATE_CHART_TEMPLATE_STATUS_FAILURE = "UPDATE_CHART_TEMPLATE_STATUS_FAILURE";

export const DEFAULT_CHART_TEMPLATE_WITH_HEADER="/media/wordtemplates/charts/default/Client-Graph-Template.docx"
export const DEFAULT_CHART_TEMPLATE_WITHOUT_HEADER="/media/wordtemplates/charts/default/Consultant-Graph-Template.docx"

export const TEMPLATE_CHOICES = [
    {value:'CLI', name:'Client'},
    {value:'CON', name:'Consultant'}
]
