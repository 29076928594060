//get dashboard

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";

export const CLEAR_DASHBOARD_DATA = "CLEAR_DASHBOARD_DATA";

//get charts
export const GET_DASHBOARD_CHART_REQUEST = "GET_DASHBOARD_CHART_REQUEST";
export const GET_DASHBOARD_CHART_SUCCESS = "GET_DASHBOARD_CHART_SUCCESS";
export const GET_DASHBOARD_CHART_FAILURE = "GET_DASHBOARD_CHART_FAILURE";

//get fcicharts
export const GET_DASHBOARD_FCI_CHART_REQUEST = "GET_DASHBOARD_FCI_CHART_REQUEST";
export const GET_DASHBOARD_FCI_CHART_SUCCESS = "GET_DASHBOARD_FCI_CHART_SUCCESS";
export const GET_DASHBOARD_FCI_CHART_FAILURE = "GET_DASHBOARD_FCI_CHART_FAILURE";

//get map
export const GET_DASHBOARD_MAP_CHART_REQUEST = "GET_DASHBOARD_MAP_CHART_REQUEST";
export const GET_DASHBOARD_MAP_CHART_SUCCESS = "GET_DASHBOARD_MAP_CHART_SUCCESS";
export const GET_DASHBOARD_MAP_CHART_FAILURE = "GET_DASHBOARD_MAP_CHART_FAILURE";

//get horizontal charts
export const GET_DASHBOARD_HORIZONTAL_CHART_REQUEST = "GET_DASHBOARD_HORIZONTAL_CHART_REQUEST";
export const GET_DASHBOARD_HORIZONTAL_CHART_SUCCESS = "GET_DASHBOARD_HORIZONTAL_CHART_SUCCESS";
export const GET_DASHBOARD_HORIZONTAL_CHART_FAILURE = "GET_DASHBOARD_HORIZONTAL_CHART_FAILURE";

//get filter project
export const GET_FILTER_PROJECT_REQUEST = "GET_FILTER_PROJECT_REQUEST";
export const GET_FILTER_PROJECT_SUCCESS = "GET_FILTER_PROJECT_SUCCESS";
export const GET_FILTER_PROJECT_FAILURE = "GET_FILTER_PROJECT_FAILURE";

//get legents
export const GET_LEGENTS_REQUEST = "GET_LEGENTS_REQUEST";
export const GET_LEGENTS_SUCCESS = "GET_LEGENTS_SUCCESS";
export const GET_LEGENTS_FAILURE = "GET_LEGENTS_FAILURE";

export const MODIFY_FILTER_REQUEST = "MODIFY_FILTER_REQUEST";

export const MODIFY_POP_UP_REQUEST = "MODIFY_POP_UP_REQUEST";

//get landing page data
export const GET_LANDING_PAGE_REQUEST = "GET_LANDING_PAGE_REQUEST";
export const GET_LANDING_PAGE_SUCCESS = "GET_LANDING_PAGE_SUCCESS";
export const GET_LANDING_PAGE_FAILURE = "GET_LANDING_PAGE_FAILURE";

//get landing page reports
export const GET_LANDING_PAGE_REPORT_REQUEST = "GET_LANDING_PAGE_REPORT_REQUEST";
export const GET_LANDING_PAGE_REPORT_SUCCESS = "GET_LANDING_PAGE_REPORT_SUCCESS";
export const GET_LANDING_PAGE_REPORT_FAILURE = "GET_LANDING_PAGE_REPORT_FAILURE";

//get widget data
export const GET_WIDGET_DATA_REQUEST = "GET_WIDGET_DATA_REQUEST";
export const GET_WIDGET_DATA_SUCCESS = "GET_WIDGET_DATA_SUCCESS";
export const GET_WIDGET_DATA_FAILURE = "GET_WIDGET_DATA_FAILURE";

export const UPDATE_FULL_SCREEN_SUCCESS = "UPDATE_FULL_SCREEN_SUCCESS";
export const UPDATE_FULL_SCREEN_FAILURE = "UPDATE_FULL_SCREEN_FAILURE";

export const UPDATE_IS_BUDGET_PRIORITY_VIEW_SUCCESS = "UPDATE_IS_BUDGET_PRIORITY_VIEW_SUCCESS";
export const UPDATE_IS_BUDGET_PRIORITY_VIEW_FAILURE = "UPDATE_IS_BUDGET_PRIORITY_VIEW_FAILURE";

export const UPDATE_TRACKER_MENU_SUCCESS = "UPDATE_TRACKER_MENU_SUCCESS";
export const SET_ACTIVE_MENU_SUCCESS = "SET_ACTIVE_MENU_SUCCESS";

export const UPDATE_DEFAULT_PROJECT = "UPDATE_DEFAULT_PROJECT";

export const TOGGLE_DASHBOARD_RESET_FLAG = "TOGGLE_DASHBOARD_RESET_FLAG";

export const SAVE_INDIVIDUAL_CHART_PARAMS = "SAVE_INDIVIDUAL_CHART_PARAMS";
export const UPDATE_MASTER_FILTER = "UPDATE_MASTER_FILTER";
export const SAVE_TEMP_CHART_FILTERS = "SAVE_TEMP_CHART_FILTERS";
export const SAVE_QUERY_TEMP_CHART_FILTERS = "SAVE_QUERY_TEMP_CHART_FILTERS";

export const SAVE_FILTER_REQUEST = "SAVE_FILTER_REQUEST";
export const SAVE_FILTER_SUCCESS = "SAVE_FILTER_SUCCESS";
export const SAVE_FILTER_FAILURE = "SAVE_FILTER_FAILURE";

export const GET_QUERY_REQUEST = "GET_QUERY_REQUEST";
export const GET_QUERY_SUCCESS = "GET_QUERY_SUCCESS";
export const GET_QUERY_FAILURE = "GET_QUERY_FAILURE";

export const DELETE_QUERY_REQUEST = "DELETE_QUERY_REQUEST";
export const DELETE_QUERY_SUCCESS = "DELETE_QUERY_SUCCESS";
export const DELETE_QUERY_FAILURE = "DELETE_QUERY_FAILURE";

export const UPDATE_FILTER_REQUEST = "UPDATE_FILTER_REQUEST";
export const UPDATE_FILTER_SUCCESS = "UPDATE_FILTER_SUCCESS";
export const UPDATE_FILTER_FAILURE = "UPDATE_FILTER_FAILURE";

export const UPDATE_FILTER_NAME = "UPDATE_FILTER_NAME";
