import React from "react";

export const SelectBox = ({ label, name, value, handleChange, showErrorBorder, errorParams, optionsList, isDisabled = false, col = 3 }) => {
    return (
        <div className={`col-md-${col} basic-box`}>
            <div className="codeOtr">
                <h4>{label}</h4>
                <div className="custom-selecbox">
                    <select
                        value={value}
                        onChange={handleChange}
                        disabled={isDisabled}
                        name={name}
                        className={`custom-selecbox ${isDisabled ? "cursor-diabled" : ""} ${showErrorBorder ? "error-border" : ""}`}
                    >
                        <option value="">Select</option>
                        {optionsList?.length
                            ? optionsList.map((item, i) => (
                                  <option value={item.id} key={item.id}>
                                      {item.name}
                                  </option>
                              ))
                            : null}
                    </select>
                </div>
            </div>
        </div>
    );
};
