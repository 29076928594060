import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import actions from "../actions";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../common/components/Loader";

export const RequestorVerify = () => {
    const history = useHistory();
    const { code, token } = useParams();
    const [isLoading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { emailVerifyResponse } = useSelector(state => state.capitalRequestReducer);
    const [verified, setVerified] = useState(null);

    useEffect(() => {
        dispatch(actions.verifyEmail(token));
    }, []);

    useEffect(() => {
        if (emailVerifyResponse) {
            setLoading(false);
            const { success, requestor } = emailVerifyResponse;
            if (success) {
                setVerified(true);
                localStorage.setItem("crRequestorToken", requestor?.token);
                history.push(`/capital-request/${requestor?.code}/${requestor?.email?.toString().toLowerCase()}`);
            } else {
                setVerified(false);
            }
        }
    }, [emailVerifyResponse]);
    return (
        <LoadingOverlay active={isLoading} spinner={<Loader />} fadeSpeed={10}>
            <div className="error-sec">
                <div className="col-md-12 navbarOtr">
                    <nav className="navbar navbar-expand-lg col-md-12">
                        <a className="navbar-brand">
                            <img src="/img/fca-logo.svg" alt="" />
                        </a>
                    </nav>
                </div>
                {verified === false ? (
                    <div class="error-page expired-outer">
                        <div class="error-otr">
                            <div class="error-img">
                                <img src="/img/expired-smiely.svg" alt="" />
                            </div>
                            <h4>Your Verification Link Has Expired</h4>
                            <h5>Please try again to verify</h5>
                            <button onClick={() => history.push(`/capital-request/${code}`)} class="btn-blue-dwnlod">
                                Register Again
                            </button>
                        </div>
                    </div>
                ) : (
                    <div class="error-page expired-outer">
                        <div class="error-otr">
                            <div class="error-img"></div>
                        </div>
                    </div>
                )}
            </div>
        </LoadingOverlay>
    );
};
