export const ADD_PRIORITY_REQUEST = "ADD_LIMIT_REQUEST";
export const ADD_PRIORITY_SUCCESS = "ADD_LIMIT_SUCCESS";
export const ADD_PRIORITY_FAILURE = "ADD_LIMIT_FAILURE";

export const GET_PRIORITY_REQUEST = "GET_ADD_LIMIT_REQUEST";
export const GET_PRIORITY_SUCCESS = "GET_ADD_LIMIT_SUCCESS";
export const GET_PRIORITY_FAILURE = "GET_ADD_LIMIT_FAILURE";

export const GET_PRIORITY_BY_ID_REQUEST = "GET_PRIORITY_BY_ID_REQUEST";
export const GET_PRIORITY_BY_ID_SUCCESS = "GET_PRIORITY_BY_ID_SUCCESS";
export const GET_PRIORITY_BY_ID_FAILURE = "GET_PRIORITY_BY_ID_FAILURE";

export const UPDATE_PRIORITY_REQUEST = "UPDATE_PRIORITY_REQUEST";
export const UPDATE_PRIORITY_SUCCESS = "UPDATE_PRIORITY_SUCCESS";
export const UPDATE_PRIORITY_FAILURE = "UPDATE_PRIORITY_FAILURE";

export const DELETE_PRIORITY_REQUEST = "DELETE_PRIORITY_REQUEST";
export const DELETE_PRIORITY_SUCCESS = "DELETE_PRIORITY_SUCCESS";
export const DELETE_PRIORITY_FAILURE = "DELETE_PRIORITY_FAILURE";

// Update Floor Entity Param
export const UPDATE_PRIORITY_ENTITY_PARAMS_SUCCESS = "UPDATE_PRIORITY_ENTITY_PARAMS_SUCCESS";
export const UPDATE_PRIORITY_ENTITY_PARAMS_FAILURE = "UPDATE_PRIORITY_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_PRIORITY_LOG_REQUEST = "GET_ALL_PRIORITY_LOG_REQUEST";
export const GET_ALL_PRIORITY_LOG_SUCCESS = "GET_ALL_PRIORITY_LOG_SUCCESS";
export const GET_ALL_PRIORITY_LOG_FAILURE = "GET_ALL_PRIORITY_LOG_FAILURE";

export const RESTORE_SETTINGS_LOG_REQUEST = "RESTORE_SETTINGS_LOG_REQUEST";
export const RESTORE_SETTINGS_LOG_SUCCESS = "RESTORE_SETTINGS_LOG_SUCCESS";
export const RESTORE_SETTINGS_LOG_FAILURE = "RESTORE_SETTINGS_LOG_FAILURE";

export const DELETE_SETTINGS_LOG_REQUEST = "DELETE_SETTINGS_LOG_REQUEST";
export const DELETE_SETTINGS_LOG_SUCCESS = "DELETE_SETTINGS_LOG_SUCCESS";
export const DELETE_SETTINGS_LOG_FAILURE = "DELETE_SETTINGS_LOG_FAILURE";

export const GET_PRIORITY_EXPORT_REQUEST = "GET_PRIORITY_EXPORT_REQUEST";
export const GET_PRIORITY_EXPORT_SUCCESS = "GET_PRIORITY_EXPORT_SUCCESS";
export const GET_PRIORITY_EXPORT_FAILURE = "GET_PRIORITY_EXPORT_FAILURE";

export const GET_SITES_BY_REGION_IN_PRIORITY_REQUEST = "GET_SITES_BY_REGION_IN_PRIORITY_REQUEST";
export const GET_SITES_BY_REGION_IN_PRIORITY_SUCCESS = "GET_SITES_BY_REGION_IN_PRIORITY_SUCCESS";
export const GET_SITES_BY_REGION_IN_PRIORITY_FAILURE = "GET_SITES_BY_REGION_IN_PRIORITY_FAILURE";
