import React, { useMemo } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { GridItem } from "./GridItem";
import { VariableSizeList as List } from "react-window";
import Row from "./Row";
import InfiniteLoader from "react-window-infinite-loader";
import ReactTooltip from "react-tooltip";
import { RowList } from "./RowList";
import memoizeOne from "memoize-one";

const createItemData = memoizeOne(
    (
        columnCount,
        imageData,
        handleMultiSelectImage,
        selectedImages,
        handleEdit,
        handleFavClick,
        isAssignView,
        hasEdit,
        viewRecommendations,
        viewImageModal,
        showSelectBox,
        handleInputCaptionData,
        viewAssets,
        touchedImageId,
        isSmartChartView
    ) => ({
        columnCount,
        imageData,
        handleMultiSelectImage,
        selectedImages,
        handleEdit,
        handleFavClick,
        isAssignView,
        hasEdit,
        viewRecommendations,
        viewImageModal,
        showSelectBox,
        handleInputCaptionData,
        viewAssets,
        touchedImageId,
        isSmartChartView
    })
);

function ImageGrid({
    imageData,
    showSelectBox,
    selectedImages,
    handleMultiSelectImage,
    viewImageModal,
    handleEdit,
    handleDelete,
    hasMore,
    fetchMoreImages,
    isAssignView,
    hasEdit,
    hasDelete,
    handleFavClick,
    viewRecommendations,
    height,
    width,
    handleInputCaptionData,
    viewAssets,
    listRef,
    touchedImageId,
    isSmartChartView
}) {
    const cardHeight = 266;
    const columnCount = isAssignView ? 6 : 5;
    const rowCount = Math.ceil(imageData.length / columnCount);

    const getRowHeight = useMemo(() => {
        return () => {
            return cardHeight + 6;
        };
    }, [cardHeight]);

    const isItemLoaded = useMemo(() => {
        return index => {
            return !hasMore || index < rowCount;
        };
    }, [hasMore, rowCount]);

    const itemData = createItemData(
        columnCount,
        imageData,
        handleMultiSelectImage,
        selectedImages,
        handleEdit,
        handleFavClick,
        isAssignView,
        hasEdit,
        viewRecommendations,
        viewImageModal,
        showSelectBox,
        handleInputCaptionData,
        viewAssets,
        touchedImageId,
        isSmartChartView
    );
    return (
        <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={imageData.length} loadMoreItems={fetchMoreImages}>
            {({ onItemsRendered, ref }) => (
                <>
                    <ReactTooltip
                        id="image-grid"
                        effect="solid"
                        place="top"
                        backgroundColor="#1383D9"
                        getContent={dataTip => dataTip?.replace(/(?:\r\n|\r|\n)/g, "<br>")}
                        html={true}
                        multiline={true}
                    />
                    <List
                        className="list"
                        width={width}
                        height={height}
                        itemData={itemData}
                        ref={listRef}
                        itemCount={rowCount}
                        itemSize={getRowHeight}
                        estimatedItemSize={cardHeight}
                        onItemsRendered={onItemsRendered}
                    >
                        {RowList}
                    </List>
                </>
            )}
        </InfiniteLoader>
    );
}

export default React.memo(ImageGrid);
