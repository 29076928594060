import React, { Component } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import { dateTimeFinder } from "../../../config/utils";
import { dateFilterKeys, numberFilterKeys, stringFilterKeys } from "../../../utils/wildCardFilterUtils";

class WildCardFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedField: null,
            tempWildCardFilters: this.props.wildCardFilter,
            filterDate: {},
            selectedDateFilterKey: {}
        };
    }

    componentDidMount = async () => {
        document.addEventListener("keydown", this.handleKeyPress);
        document.addEventListener("mousedown", this.handleClickOutside);
        this.setState({ tempWildCardFilters: this.props.wildCardFilter });
    };

    componentDidUpdate = prevProps => {
        if (prevProps.wildCardFilter !== this.props.wildCardFilter) {
            this.setState({ tempWildCardFilters: this.props.wildCardFilter });
        }
    };

    componentWillUnmount = () => {
        document.removeEventListener("keydown", this.handleKeyPress);
        document.removeEventListener("mousedown", this.handleClickOutside);
    };

    setWrapperRef = node => {
        this.wrapperRef = node;
    };

    handleClickOutside = event => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                selectedField: ""
            });
        }
    };
    handleKeyPress = e => {
        if (e.keyCode === 27) {
            e.preventDefault();
            this.setState({ selectedField: null });
        }
    };

    showFilterDropDown = keyItem => {
        this.setState({
            selectedField: this.state.selectedField === keyItem ? null : keyItem
        });
    };

    setFilterKeys = (filterKey, searchKey, type, isChecked) => {
        let currentFilter = this.state.tempWildCardFilters;
        let item = currentFilter?.[searchKey] || {};
        item = {
            ...item,
            filters: isChecked ? [filterKey] : []
        };
        if (filterKey === "not_null" || filterKey === "null" || filterKey === "count_gt_1") {
            item.key = isChecked ? "" : null;
            item.filters = isChecked ? item.filters : [type === "number" ? "equals" : "contains"];
        }
        if (filterKey === "top_n" && item?.key && isChecked) {
            item = {
                ...item,
                key: parseInt(item.key)?.toString() || ""
            };
        }
        currentFilter = { ...currentFilter, [searchKey]: item };
        this.setState({
            tempWildCardFilters: currentFilter
        });
        if (filterKey === "not_null" || filterKey === "null" || filterKey === "count_gt_1" || (item?.key?.trim()?.length && isChecked)) {
            this.setState({ selectedField: null });
            this.updateWildCardFilter(currentFilter);
        }
    };

    setFilterKeyForDate = (filterKey, searchKey, checked) => {
        let currentFilter = this.state.tempWildCardFilters;
        let item = currentFilter?.[searchKey] || {};
        item = {
            ...item,
            key: checked && filterKey !== "in_between" ? dateTimeFinder(filterKey) : item.key,
            filters: checked ? ["in_between"] : [],
            filterKey: checked ? [filterKey] : []
        };

        currentFilter = { ...currentFilter, [searchKey]: item };
        this.setState({
            tempWildCardFilters: currentFilter
        });
        checked && this.updateWildCardFilter(currentFilter);
    };

    renderFilters = keyItem => {
        if (this.state.selectedField !== keyItem) {
            return null;
        }
        const { tempWildCardFilters } = this.state;
        const { config } = this.props;
        const { searchKey, type } = config[keyItem];
        const filterItem = tempWildCardFilters?.[searchKey] || {};

        if (type === "date") {
            return (
                <div className="dropdown-menu drop-filtr sub-drop">
                    <div className="slct-date-otr">
                        <div className="place-holder">
                            <div className="col-md-2 p-0 labl-txt">From</div>
                            <div className="col-md-10 pr-0">
                                <input
                                    value={filterItem?.key?.from ? moment(filterItem?.key?.from).format("yyyy-MM-DD") : null}
                                    type="date"
                                    onChange={e => this.onDateInputChange(e.target.value, searchKey, "from")}
                                />
                            </div>
                        </div>
                        <div className="place-holder">
                            <div className="col-md-2 p-0 labl-txt">To</div>
                            <div className="col-md-10 pr-0">
                                <input
                                    value={filterItem?.key?.to ? moment(filterItem?.key?.to).format("yyyy-MM-DD") : null}
                                    type="date"
                                    onChange={e => this.onDateInputChange(e.target.value, searchKey, "to")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="check-box-otr-dte d-flex col-md-12">
                        {dateFilterKeys.map((item, i) => (
                            <>
                                <div key={i} className="col-md-6 p-0">
                                    <span className="dropdown-item">
                                        <label className="container-check">
                                            {item.label}

                                            {item.value ? (
                                                <>
                                                    <input
                                                        type="checkbox"
                                                        checked={filterItem?.filterKey?.includes(item.value)}
                                                        onClick={e => this.setFilterKeyForDate(item.value, searchKey, e.target.checked)}
                                                    />
                                                    <span className="checkmark"></span>
                                                </>
                                            ) : null}
                                        </label>
                                    </span>
                                </div>
                                {i % 6 === 1 ? <div className="col-md-12 dropdown-line"></div> : null}
                            </>
                        ))}
                    </div>
                </div>
            );
        } else if (type === "number") {
            return (
                <div className="dropdown-menu drop-filtr" aria-labelledby="dropdownMenuButton">
                    {numberFilterKeys(config[keyItem]).map((item, i) => (
                        <span key={i} className="dropdown-item">
                            <label className="container-check">
                                {item.label}
                                <input
                                    type="checkbox"
                                    checked={filterItem?.filters?.includes(item.value)}
                                    onClick={e => this.setFilterKeys(item.value, config[keyItem].searchKey, config[keyItem].type, e.target.checked)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </span>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="dropdown-menu drop-filtr" aria-labelledby="dropdownMenuButton">
                    {stringFilterKeys.map((item, i) => (
                        <span key={i} className="dropdown-item">
                            <label className="container-check">
                                {item.label}
                                <input
                                    type="checkbox"
                                    checked={filterItem?.filters?.includes(item.value)}
                                    onClick={e => this.setFilterKeys(item.value, config[keyItem].searchKey, config[keyItem].type, e.target.checked)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </span>
                    ))}
                </div>
            );
        }
    };

    handleCloseClick = async (key, type) => {
        let currentFilter = this.state.tempWildCardFilters;
        const { wildCardFilter } = this.props;
        const item = wildCardFilter?.[key] || {};
        const filterPresent =
            item?.key || item?.filters?.includes("null") || item?.filters?.includes("not_null") || item?.filters?.includes("count_gt_1");
        let tempFilters = { ...currentFilter };
        if (tempFilters.hasOwnProperty(key)) {
            delete tempFilters[key];
            this.setState({ tempWildCardFilters: tempFilters });
        }
        if (filterPresent) {
            this.updateWildCardFilter(tempFilters);
        }
    };

    onDateInputChange = (value, key, subKey) => {
        let currentFilter = this.state.tempWildCardFilters || {};
        const item = currentFilter?.[key] || {};
        if (value) {
            currentFilter = {
                ...currentFilter,
                [key]: {
                    filters: [],
                    key: { ...item.key, [subKey]: value }
                }
            };
        }
        this.setState({ tempWildCardFilters: currentFilter });
    };

    onInputChange = (value, key, type) => {
        let currentFilter = this.state.tempWildCardFilters || {};
        const isNullable =
            currentFilter?.[key]?.filters?.includes("null") ||
            currentFilter?.[key]?.filters?.includes("not_null") ||
            currentFilter?.[key]?.filters?.includes("count_gt_1");
        const defaultFilter = type === "number" ? ["equals"] : ["contains"];
        currentFilter = {
            ...currentFilter,
            [key]: {
                filters: isNullable ? defaultFilter : currentFilter?.[key]?.filters?.length ? currentFilter?.[key]?.filters : defaultFilter,
                key: value.trim().length ? value : null
            }
        };

        this.setState({ tempWildCardFilters: currentFilter });
    };

    updateWildCardFilter = filter => {
        let updatedFilterData = { ...filter };
        const filteredWildCardData = Object.entries(updatedFilterData || {}).reduce((result, [key, elem]) => {
            if (
                elem?.key?.toString()?.trim().length ||
                elem?.filters?.[0] === "null" ||
                elem?.filters?.[0] === "not_null" ||
                elem?.filters?.[0] === "count_gt_1"
            ) {
                result[key] = elem;
            }
            return result;
        }, {});

        this.props.updateWildCardFilter(filteredWildCardData);
    };
    render() {
        const { isLoading, tempWildCardFilters } = this.state;
        if (isLoading) return null;
        const { keys, config, hasWildCardOptions = true, hasActionColumn = true, isAsset } = this.props;

        return (
            <React.Fragment>
                <tr
                    className={`viewImg filter-clp top-clamp ${isAsset ? "asset-filter" : ""} ${hasWildCardOptions ? "" : " has-no-wc-options"}`}
                    id="collapse-filter"
                    ref={this.setWrapperRef}
                >
                    <td />
                    {keys.length
                        ? keys.map((keyItem, i) =>
                              config[keyItem]?.isVisible ? (
                                  <td
                                      key={i}
                                      className={`filter-select ${config[keyItem].pinned ? "pinned" : ""}`}
                                      style={{ ...config[keyItem]?.style }}
                                  >
                                      {config[keyItem] && config[keyItem].hasWildCardSearch ? (
                                          <div className="formInp ">
                                              <i
                                                  className="fas fa-times cursor-pointer cls"
                                                  onClick={() => this.handleCloseClick(config[keyItem]?.searchKey, config[keyItem].type)}
                                              ></i>
                                              {config[keyItem] && config[keyItem].type !== "date" ? (
                                                  <>
                                                      {hasWildCardOptions ? (
                                                          <i
                                                              className="fas fa-filter cursor-pointer"
                                                              onClick={() => this.showFilterDropDown(keyItem)}
                                                          />
                                                      ) : null}

                                                      {config[keyItem].type === "number" ? (
                                                          <NumberFormat
                                                              value={tempWildCardFilters?.[config[keyItem]?.searchKey]?.key || ""}
                                                              placeholder={config[keyItem].label}
                                                              thousandSeparator={config[keyItem]?.isYear ? false : true}
                                                              className={"form-control"}
                                                              allowNegative={false}
                                                              decimalScale={
                                                                  tempWildCardFilters?.[config[keyItem]?.searchKey]?.filters?.[0] === "top_n"
                                                                      ? 0
                                                                      : undefined
                                                              }
                                                              onValueChange={values => {
                                                                  const { value } = values;
                                                                  this.onInputChange(value, config[keyItem]?.searchKey, "number");
                                                              }}
                                                              onKeyPress={event => {
                                                                  if (event.key === "Enter") {
                                                                      this.setState({ selectedField: null });
                                                                      this.updateWildCardFilter(tempWildCardFilters);
                                                                  }
                                                              }}
                                                          />
                                                      ) : (
                                                          <input
                                                              autoComplete="nope"
                                                              type="text"
                                                              className="form-control"
                                                              placeholder={config[keyItem].label}
                                                              value={tempWildCardFilters?.[config?.[keyItem]?.searchKey]?.key || ""}
                                                              onChange={e => this.onInputChange(e.target.value, config[keyItem]?.searchKey, "text")}
                                                              onKeyPress={event => {
                                                                  if (event.key === "Enter") {
                                                                      this.updateWildCardFilter(tempWildCardFilters);
                                                                      this.setState({ selectedField: null });
                                                                  }
                                                              }}
                                                          />
                                                      )}
                                                  </>
                                              ) : (
                                                  <>
                                                      <i
                                                          className="far fa-calendar cursor-pointer"
                                                          onClick={() => this.showFilterDropDown(keyItem)}
                                                      ></i>
                                                      <div className="caln-div cursor-pointer" onClick={() => this.showFilterDropDown(keyItem)}>
                                                          <div className="caln-txt">Select Date</div>
                                                      </div>
                                                  </>
                                              )}
                                              {/* {hasWildCardOptions ?  : null} */}
                                              {config[keyItem].searchKey && this.renderFilters(keyItem)}
                                          </div>
                                      ) : null}
                                  </td>
                              ) : null
                          )
                        : null}
                    {hasActionColumn ? <td /> : null}
                </tr>
            </React.Fragment>
        );
    }
}

export default WildCardFilter;
