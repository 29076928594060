import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _, { isEmpty } from "lodash";
import actions from "./actions";
import ImageActions from "../images/actions";
import Form from "./components/Form";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../common/components/Loader";
import Portal from "../common/components/Portal";
import InfoPage from "./components/Info";
import DataMain from "./components/DataMain";
import ConfirmationModal from "../common/components/ConfirmationModal";
import { addToBreadCrumpData, checkPermission, findPrevPathFromBreadCrumpData, popBreadCrumpOnPageClose } from "../../config/utils";
import ViewModal from "../common/components/ViewModal";
import history from "../../config/history";
import { assetTableData } from "./components/tableConfig";
import qs from "query-string";
import OnePagerModal from "./components/AssetOnePagerExportModal";
import { BulkUploadForm } from "./components/BulkUploadForm";
import { AppliedFilters } from "../common/AppliedFilters";
import { getAppliedFiltersCount } from "../../utils/filtersUtils";
import { MyFilters } from "./components/FilterModal/MyFilters";
import ExportModal from "./components/ExportModal";
import { ExportColumnConfiguration } from "../common/components/ExportColumnConfiguration";
import MultiSelectEditForm from "./components/MultiSelectEditForm";
class Assets extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        let prevSectionId = this.props.assetReducer.entityParams.parentSectionId;
        let isSameSession =
            prevSectionId === this.props.match.params.id &&
            this.props.location?.state?.from !== "charts" &&
            !this.props.isSmartChartView &&
            !this.props.isAssignView;

        const {
            match: {
                params: { section }
            }
        } = this.props;
        this.state = {
            isLoading: false,
            errorMessage: "",
            dataList: [],
            paginationParams: isSameSession
                ? this.props.assetReducer.entityParams.paginationParams
                : {
                      totalPages: 0,
                      perPage: 40,
                      currentPage: 0,
                      totalCount: 0
                  },
            showViewModal: false,
            showWildCardFilter: false,
            selectedRowId: isSameSession ? this.props.assetReducer.entityParams.selectedRowId : null,
            params:
                isSameSession || this.props.isSmartChartView
                    ? section && this.props.assetReducer.entityParams[section]
                        ? this.props.assetReducer.entityParams[section]?.params ?? {
                              limit: 40,
                              offset: 0,
                              search: ""
                          }
                        : this.props.assetReducer.entityParams.params ?? {
                              limit: 40,
                              offset: 0,
                              search: ""
                          }
                    : {
                          limit: 40,
                          offset: 0,
                          search: ""
                      },
            selectedData: this.props.match.params.id,
            tableData: {
                keys: assetTableData.keys,
                config:
                    isSameSession && !this.props.isSmartChartView
                        ? this.props.assetReducer.entityParams.tableConfig
                        : _.cloneDeep(assetTableData.config)
            },
            alertMessage: "",
            wildCardFilterParams: isSameSession ? this.props.assetReducer.entityParams.wildCardFilterParams : {},
            filterParams: isSameSession ? this.props.assetReducer.entityParams.filterParams : {},
            historyPaginationParams: isSameSession
                ? this.props.assetReducer.entityParams.historyPaginationParams
                : {
                      totalPages: 0,
                      perPage: 40,
                      currentPage: 0,
                      totalCount: 0
                  },
            historyParams: isSameSession
                ? this.props.assetReducer.entityParams.historyParams
                : {
                      limit: 40,
                      offset: 0,
                      search: ""
                  },
            logData: {
                count: "",
                data: []
            },
            showConfirmModalLog: false,
            selectedLog: "",
            isRestoreOrDelete: "",
            tableLoading: false,
            infoTabsData: [],
            showForm: false,
            showInfoPage: false,
            isHistory: false,
            showConfirmModal: false,
            imageResponse: [],
            customExcelExportLoading: false,
            summaryRowData: {
                crv_total: "",
                fca_cost_total: ""
            },
            locationState: this.props.location.state
                ? this.props.location.state
                : isSameSession
                ? this.props.assetReducer.entityParams.locationState
                : {},
            exportLoader: false,
            showOnePagerExportModal: false,
            selectedAssetData: {},
            parentSectionId: this.props.assetReducer.entityParams.parentSectionId,
            showFiltersPopup: false,
            showAppliedFilters: false,
            importType: "",
            showExportSettings: false,
            selectedAssetsId:
                this.props.assetReducer.entityParams[section]?.selectedAssetsId || this.props.assetReducer.entityParams?.selectedAssetsId || [],
            showExportConfigurationModal: false,
            showMultiSelectEditForm: false
        };
    }

    componentDidMount = async () => {
        const {
            location: { search },
            match: {
                params: { id, tab }
            }
        } = this.props;
        const query = qs.parse(search);
        if (this.props.isProjectAssetView) {
            await this.props.getAssetDynamicFields({ client_id: this.props.clientId });
        } else {
            await this.props.getAssetDynamicFields({
                client_id: this.props.clientId ? this.props.clientId : tab === "basicdetails" ? query.client_id : query?.c_id ?? id
            });
        }
        await this.refreshDynamicFields();

        this.handleScrollPosition();
        this.props.handleSelectAsset(null, false);
        if (!this.props.location.state || this.props.location?.state?.isInitialView || this.props.isAssignView) {
            this.setState({ locationState: false });
        }
        if (this.props.isSmartChartView) {
            if (!this.props.configParams) await this.resetAll();
            let tempParams = { ...this.state.params };
            if (!this.props?.selectedRecomIdList.length) {
                tempParams = { ...tempParams, asset_ids: null };
            }
            if (this.props.configParams) {
                tempParams = { ...tempParams, ...this.props.configParams };
            }
            let tempSelectedAssetIds = [];
            tempSelectedAssetIds = (this.props?.selectedRecomIdList ?? []).map(assetId => {
                return {
                    id: assetId
                };
            });
            await this.setState({
                selectedAssetsId: tempSelectedAssetIds,
                params: tempParams,
                paginationParams: { ...this.state.paginationParams, perPage: this.props.configParams?.limit ?? this.state.paginationParams.perPage },
                tableDataExportFilters: this.props?.tableDataExportFilters
            });
        }
        if (this.props.match.params.tab === "assets" || this.props.isAssignView || this.props.isSmartChartView) {
            this.setState({ parentSectionId: this.props.match.params.id });
            if (this.state.parentSectionId && this.state.parentSectionId !== this.props.match.params.id) {
                this.resetAll();
            } else {
                await this.refreshDataList();
            }
        }
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (
            this.props.isProjectAssetView &&
            this.props.clientId &&
            this.props.match.params.tab === "assets" &&
            prevProps.clientId !== this.props.clientId
        ) {
            await this.props.getAssetDynamicFields({ client_id: this.props.clientId });
            await this.refreshDataList();
        }
        if (prevProps.match.path !== this.props.match.path && this.props.match.path === "/assets") {
            this.handleScrollPosition();
        }
    };

    refreshDataList = async () => {
        this.setState({ isLoading: true });
        if (this.props.configParams) {
            await this.props.getAllAssetIds(this.props.configParams);
            const { recommendation_ids } = this.props.assetReducer.getWholeAssetIdsResponse;
            let tempSelected = [];
            recommendation_ids?.length &&
                recommendation_ids.forEach((id, index) => {
                    tempSelected.push({ id, index });
                });
            await this.setState({ selectedAssetsId: tempSelected });
        }
        const { params, paginationParams, tableData, locationState } = this.state;
        const { has_image, ...restList } = params.list || {};
        const {
            match: {
                params: { id, section }
            },
            location: { state }
        } = this.props;
        let dataList = [];
        let totalCount = 0;
        let crv_total = 0;
        let fca_cost_total = 0;
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        let subParams = { ...params, list: restList };

        if (this.props.isAssignView || this.props.isSmartChartView || (this.props.isProjectAssetView && this.props.clientId)) {
            subParams.client_id = this.props.clientId ?? subParams.client_id;
            params.client_id = this.props.clientId ?? subParams.client_id;
            const { smartChartFilters = {} } = this.props;
            if (smartChartFilters) subParams = { ...subParams, ...smartChartFilters };
        } else {
            switch (section) {
                case "assetinfo":
                    subParams.client_id = this.props.clientId || query?.client_id || id;
                    break;
                case "regioninfo":
                    subParams.region_id = id;
                    break;
                case "siteinfo":
                    subParams.site_id = id;
                    break;
                case "buildinginfo":
                    subParams.building_id = id;
                    break;
                case "imageInfo":
                    subParams.image_id = id;
                    break;

                default:
                    subParams.client_id = this.props.clientId || query?.client_id || id;
            }
        }
        if (
            !isEmpty(locationState) &&
            !locationState.isInitialView &&
            !this.props.isAssignView &&
            section !== "imageInfo" &&
            !this.props.isSmartChartView
        ) {
            await this.props.getChartAssetDataList({ ...subParams, ...locationState });
        } else {
            if ((this.props.isProjectAssetView && this.props.clientId) || !this.props.isProjectAssetView)
                await this.props.getDataList({ ...subParams });
        }
        const { getDataResponse } = this.props.assetReducer || {};
        dataList = getDataResponse?.assets || [];
        totalCount = getDataResponse?.count || 0;
        crv_total = getDataResponse?.crv || 0;
        fca_cost_total = getDataResponse?.fca_cost_total || 0;
        // go to previous page is the last record of the current page is deleted
        if (dataList && !dataList.length && paginationParams.currentPage) {
            this.setState({
                params: {
                    ...params,
                    offset: (paginationParams.currentPage - 1) * paginationParams.perPage
                }
            });
            await this.props.getDataList(this.state.params);
            dataList = getDataResponse?.assets || [];
            totalCount = getDataResponse?.count || 0;
            crv_total = getDataResponse?.crv || 0;
            fca_cost_total = getDataResponse?.fca_cost_total || 0;
        }
        if (!dataList?.length && getDataResponse?.error) {
            this.setState({ alertMessage: getDataResponse.error }, () => this.showAlert());
        }

        this.updateEntityParams();
        if ((this.props.isProjectAssetView && this.props.clientId) || !this.props.isProjectAssetView) {
            this.getListForCommonFilter({ field: "assets.has_recommendation" }, true);
            await this.setState({
                tableData: {
                    ...tableData,
                    data: dataList
                },
                dataList,
                summaryRowData: {
                    crv_total,
                    fca_cost_total
                },
                paginationParams: {
                    ...paginationParams,
                    totalCount: totalCount,
                    totalPages: Math.ceil(totalCount / paginationParams.perPage)
                },
                showWildCardFilter: this.state.params.filters ? true : false,
                isLoading: false
            });
        }
        return true;
    };

    handleScrollPosition = () => {
        const scrollPosition = this.props.assetReducer.scrollPosition;
        if (scrollPosition && this.tableRef?.current) {
            this.tableRef.current.scrollTo(0, parseInt(scrollPosition));
            this.props.setAssetScrollPosition(0);
        }
    };

    selectWholeAssets = async () => {
        this.setState({ isLoading: true });
        const { isSmartChartView = false, smartChartFilters = {} } = this.props;
        let params = this.state.params;
        const { myFilters, dashboardFilterParams } = this.state;
        const clientId = this.props.clientId;
        if (this.isDashboardFiltered) {
            params.dashboard = this.state.dashboard;
            params.capital_type = this.state.capital_type;
            params.building_ids = this.state.building_ids;
            params.year = this.state.year;
        }
        params.start_year = this.state.start_year;
        params.end_year = this.state.end_year;
        params.infrastructure_requests = this.state.infrastructure_requests;
        await this.props.getAllAssetIds({
            ...params,
            ...dashboardFilterParams,
            ...(isSmartChartView && { ...smartChartFilters }),
            ...myFilters,
            ...(clientId && { client_id: clientId })
        });
        const { recommendation_ids } = this.props.assetReducer.getWholeAssetIdsResponse;
        let tempSelected = [];
        recommendation_ids?.length &&
            recommendation_ids.forEach((id, index) => {
                tempSelected.push({ id, index });
            });
        await this.setState({ selectedAssetsId: [] });
        await this.setState({ selectedAssetsId: tempSelected, isLoading: false });
        this.updateEntityParams();
    };

    updateWildCardFilter = async newFilter => {
        const { params, paginationParams } = this.state;
        await this.setState({
            params: {
                ...params,
                offset: 0,
                filters: newFilter
            },
            paginationParams: {
                ...paginationParams,
                currentPage: 0
            }
        });
        this.updateEntityParams();
        await this.refreshDataList();
    };

    updateEntityParams = async () => {
        if (this.props.match.params.tab === "assets" || this.props.isSmartChartView) {
            const {
                filterParams,
                tableData,
                historyParams,
                historyPaginationParams,
                selectedRowId,
                wildCardFilterParams,
                paginationParams,
                params,
                locationState,
                parentSectionId,
                selectedAssetsId,
                tableDataExportFilters
            } = this.state;
            let entityParams = {
                entity: "assets",
                paginationParams,
                params,
                wildCardFilterParams,
                filterParams,
                tableConfig: tableData.config,
                selectedRowId,
                historyPaginationParams,
                historyParams,
                locationState,
                parentSectionId,
                selectedAssetsId,
                tableDataExportFilters
            };
            if (this.props.isSmartChartView) {
                const { section } = this.props.match.params;
                entityParams = { [section]: entityParams };
            }
            await this.props.updateDataEntityParams(entityParams);
        }
    };

    resetAllFilters = async () => {
        await this.setState({
            paginationParams: {
                totalPages: 0,
                perPage: 40,
                currentPage: 0,
                totalCount: 0
            },
            params: {
                ...this.state.params,
                limit: 40,
                offset: 0,
                search: "",
                project_id: null,
                filters: null,
                list: null,
                recommendation_assigned_true: null,
                guid_assigned: null,
                has_image: null
            },
            wildCardFilterParams: {},
            filterParams: {},
            selectedRowId: null,
            tableDataExportFilters: {}
        });
        this.updateEntityParams();
        await this.refreshDataList();
    };

    resetSort = async () => {
        await this.setState({
            params: {
                ...this.state.params,
                order: null
            }
        });
        this.updateEntityParams();
        await this.refreshDataList();
    };
    resetAll = async () => {
        await this.setState({
            selectedRegion: null,
            paginationParams: {
                totalPages: 0,
                perPage: 100,
                currentPage: 0,
                totalCount: 0
            },
            params: {
                ...this.state.params,
                limit: 100,
                offset: 0,
                search: "",
                project_id: null,
                filters: null,
                recommendation_assigned_true: null,
                guid_assigned: null,
                has_image: null,
                order: null,
                list: null,
                asset_ids: null
            },
            tableData: {
                ...this.state.tableData,
                config: _.cloneDeep(assetTableData.config)
            },

            wildCardFilterParams: {},
            filterParams: {},
            selectedRowId: null,
            tableDataExportFilters: {},
            selectedAssetsId: []
        });
        this.updateEntityParams();
        await this.refreshDataList();
    };
    isColunmVisibleChanged = () => {
        let flag = false;
        const {
            tableData: { keys, config }
        } = this.state;
        keys.map(key => {
            if (!_.isEqual(config[key]?.isVisible, assetTableData.config[key]?.isVisible)) {
                flag = true;
            }
        });
        return flag;
    };

    getListForCommonFilter = async (params, isRecommendationList = false) => {
        let { search, filters, list, recommendation_assigned_true, guid_assigned, has_image } = this.state.params;
        if (list?.has_image) {
            const { has_image, ...rest } = list;
            list = rest;
        }
        params.search = search;
        params.filters = filters;
        if (!isRecommendationList) {
            params.recommendation_assigned_true = recommendation_assigned_true;
        }
        params.guid_assigned = guid_assigned;
        params.has_image = has_image;
        if (list) {
            const { [params.field]: omit, ...restList } = list || {};
            params.list = Object.keys(restList)?.length ? restList : null;
        }
        if (this.props.isAssignView || this.props.isSmartChartView || this.props.isProjectAssetView) {
            params.client_id = this.props.clientId;
        }
        const { section, id } = this.props.match.params;
        let subParams = {};
        switch (section) {
            case "assetinfo":
                subParams.client_id = id;
                break;
            case "regioninfo":
                subParams.region_id = id;
                break;
            case "siteinfo":
                subParams.site_id = id;
                break;
            case "buildinginfo":
                subParams.building_id = id;
                break;

            default:
                break;
        }
        if (this.props?.isSmartChartView) {
            const { smartChartFilters = {} } = this.props;
            subParams = { ...params, ...smartChartFilters };
        }
        await this.props.getListForCommonFilter({ ...params, ...subParams, ...this.state.locationState }, isRecommendationList);
        return this.props.assetReducer.getListForCommonFilterResponse || {};
    };

    updateCommonFilter = async (commonFilters, exportFilters) => {
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                list: commonFilters
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            tableDataExportFilters: { ...this.state.tableDataExportFilters, ...exportFilters }
        });
        this.updateEntityParams();
        await this.refreshDataList();
    };

    updateTableSortFilters = async searchKey => {
        if (this.state.params.order) {
            await this.setState({
                params: {
                    ...this.state.params,
                    order: {
                        ...this.state.params.order,
                        [searchKey]: this.state.params.order[searchKey] === "desc" ? "asc" : "desc"
                    }
                }
            });
        } else {
            await this.setState({
                params: {
                    ...this.state.params,
                    order: { [searchKey]: "asc" }
                }
            });
        }
        this.updateEntityParams();
        await this.refreshDataList();
    };

    handleHideColumn = async keyItem => {
        if (keyItem !== "selectAll" && keyItem !== "deselectAll") {
            await this.setState({
                tableData: {
                    ...this.state.tableData,
                    config: {
                        ...this.state.tableData.config,
                        [keyItem]: {
                            ...this.state.tableData.config[keyItem],
                            isVisible: !this.state.tableData.config[keyItem].isVisible
                        }
                    }
                }
            });
        } else {
            let tempConfig = { ...this.state.tableData.config };
            this.state.tableData.keys.forEach(item => {
                if (keyItem === "selectAll") {
                    tempConfig[item] = { ...tempConfig[item], isVisible: true };
                } else {
                    tempConfig[item] = { ...tempConfig[item], isVisible: false };
                }
            });
            await this.setState({
                tableData: {
                    ...this.state.tableData,
                    config: tempConfig
                }
            });
        }
        await this.updateEntityParams();
        return true;
    };

    updateSelectedRow = async rowId => {
        await this.setState({
            selectedRowId: rowId
        });
        await this.updateEntityParams();
    };

    handlePerPageChange = async e => {
        const { paginationParams, params } = this.state;
        await this.setState({
            paginationParams: {
                ...paginationParams,
                perPage: e.target.value,
                currentPage: 0
            },
            params: {
                ...params,
                offset: 0,
                limit: e.target.value
            }
        });
        await this.refreshDataList();
    };

    handlePageClick = async page => {
        const { paginationParams, params } = this.state;
        await this.setState({
            paginationParams: {
                ...paginationParams,
                currentPage: page.selected
            },
            params: {
                ...params,
                // offset: page.selected * paginationParams.perPage
                offset: page.selected + 1
            }
        });
        await this.refreshDataList();
    };

    showEditPage = dataId => {
        const {
            basicDetails,
            clientId,
            history,
            location: { search },
            match: {
                params: { section, tab }
            }
        } = this.props;

        const query = qs.parse(search);
        let client_id = "";
        if (this.tableRef?.current?.scrollTop) {
            this.props.setAssetScrollPosition(this.tableRef.current.scrollTop);
        }
        this.setState({
            selectedData: dataId
        });
        if (section === "assetInfo" && tab === "basicdetails") {
            client_id = query.client_id;
        } else if (section === "assetinfo") {
            client_id = clientId;
        } else {
            client_id = basicDetails?.client?.id;
        }
        let path = `/assets/edit/${dataId}?c_id=${client_id}`;
        addToBreadCrumpData({
            key: "edit",
            name: `Edit Asset`,
            path
        });
        history.push(path);
    };

    showAddForm = () => {
        const { history } = this.props;
        this.setState({
            selectedData: null
        });
        let selectedClient = "";
        let selectedRegion = "";
        let selectedSite = "";
        let selectedBuilding = "";
        if (this.props.clientId) {
            selectedClient = this.props.clientId;
        }
        if (this.props.regionId) {
            selectedClient = this.props.basicDetails?.client?.id;
            selectedRegion = this.props.regionId;
        }
        if (this.props.siteId) {
            selectedClient = this.props.basicDetails?.client?.id;
            selectedRegion = this.props.basicDetails?.region?.id;
            selectedSite = this.props.siteId;
        }
        if (this.props.buildingId) {
            selectedClient = this.props.basicDetails?.client?.id;
            selectedRegion = this.props.basicDetails?.region?.id;
            selectedSite = this.props.basicDetails?.site?.id;
            selectedBuilding = this.props.buildingId;
        }
        addToBreadCrumpData({ key: "add", name: "Add Asset", path: "/assets/add" });
        history.push(`/assets/add?r_id=${selectedRegion}&c_id=${selectedClient}&b_id=${selectedBuilding}&s_id=${selectedSite}`);
    };

    showViewModal = () => {
        this.setState({
            showViewModal: true
        });
    };

    toggleWildCardFilter = () => {
        const { showWildCardFilter } = this.state;
        this.setState({
            showWildCardFilter: !showWildCardFilter
        });
    };

    handleAddData = async data => {
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        this.setState({
            isLoading: true
        });
        await this.props.addData(data);
        const { error, message, asset_id } = this.props.assetReducer.addDataResponse;
        if (error) {
            this.setState(
                {
                    alertMessage: error,
                    isLoading: false
                },
                () => this.showAlert()
            );
        } else {
            if (query?.isRecomAsset) {
                sessionStorage.setItem("lastCreatedAssetId", asset_id);
            }
            await this.setState({
                alertMessage: message,
                isLoading: false
            });
            await this.refreshDataList();
            this.showAlert();
            this.cancelForm();
        }
    };

    showAlert = () => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show";
            x.innerText = this.state.alertMessage;
            setTimeout(function () {
                x.className = x.className.replace("show", "");
            }, 3000);
        }
    };

    handleUpdateData = async data => {
        const {
            match: {
                params: { id }
            },
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        this.setState({
            isLoading: true
        });
        await this.props.updateData(id, data);
        const { updateDataResponse } = this.props.assetReducer;
        if (updateDataResponse.error) {
            this.setState({ alertMessage: updateDataResponse.error, isLoading: false }, () => this.showAlert());
        } else {
            if (query?.isRecomAsset) {
                sessionStorage.setItem("lastCreatedAssetId", id);
            }
            await this.setState({
                alertMessage: updateDataResponse && updateDataResponse.message
            });
            await this.refreshDataList();
            this.setState({
                isLoading: false
            });
            this.showAlert();
            this.cancelForm();
        }
    };

    handleGlobalSearch = async search => {
        const { params } = this.state;
        await this.setState({
            params: {
                ...params,
                offset: 0,
                search
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            }
        });
        await this.refreshDataList();
    };

    showInfoPage = (id, projectId, rowData, client_id) => {
        if (this.tableRef?.current?.scrollTop) {
            this.props.setAssetScrollPosition(this.tableRef.current.scrollTop);
        }
        const { history } = this.props;
        const { id: clientId } = this.props.match.params;
        let selectedClient = this.props.clientId || client_id || clientId;
        this.setState({
            selectedData: id,
            infoTabsData: [
                {
                    key: "basicdetails",
                    name: "Asset",
                    path: `/assets/assetInfo/${id}/basicdetails?client_id=${selectedClient}`
                },
                {
                    key: "recommendations",
                    name: "Recommendations",
                    path: `/assets/assetInfo/${id}/recommendations`
                },
                {
                    key: "infoimages",
                    name: "Images",
                    path: `/assets/assetInfo/${id}/infoimages`
                }
            ]
        });
        if (rowData) {
            addToBreadCrumpData({
                key: "Name",
                name: rowData?.asset_name,
                path: `/assets/assetInfo/${id}/basicdetails?client_id=${selectedClient}`
            });
            addToBreadCrumpData({
                key: "info",
                name: "Basic Details",
                path: `/assets/assetInfo/${id}/basicdetails?client_id=${selectedClient}`
            });
        }
        this.updateSelectedRow(id);
        let tabKeyList = ["basicdetails", "recommendations", "infoimages"];
        history.push(
            `/assets/assetInfo/${id}/${
                tabKeyList.includes(this.props.match.params.tab)
                    ? `${this.props.match.params.tab}?client_id=${selectedClient}`
                    : `basicdetails?client_id=${selectedClient}`
            }`
        );
    };

    getDataById = async () => {
        await this.props.getAssetDataById(this.state.selectedData);
        return this.props.assetReducer.getDataByIdResponse;
    };

    getLogData = async () => {
        const { historyParams, historyPaginationParams, logData, selectedData } = this.state;
        await this.props.getAllDataLogs(selectedData, historyParams);
        const {
            getDataLogsResponse: { logs, count, error }
        } = this.props.assetReducer;
        if (error) {
            this.setState({ alertMessage: error }, () => this.showAlert());
        } else {
            this.setState({
                logData: {
                    ...logData,
                    data: logs
                },
                historyPaginationParams: {
                    ...historyPaginationParams,
                    totalCount: count,
                    totalPages: Math.ceil(count / historyPaginationParams.perPage)
                }
            });
        }
    };

    handlePerPageChangeHistory = async e => {
        const { historyPaginationParams, historyParams } = this.state;
        await this.setState({
            historyPaginationParams: {
                ...historyPaginationParams,
                perPage: e.target.value,
                currentPage: 0
            },
            historyParams: {
                ...historyParams,
                offset: 0,
                limit: e.target.value
            }
        });
        await this.getLogData();
    };

    handlePageClickHistory = async page => {
        const { historyPaginationParams, historyParams } = this.state;
        await this.setState({
            historyPaginationParams: {
                ...historyPaginationParams,
                currentPage: page.selected
            },
            historyParams: {
                ...historyParams,
                offset: page.selected * historyPaginationParams.perPage
            }
        });
        await this.getLogData();
    };

    handleGlobalSearchHistory = async search => {
        const { historyParams, historyPaginationParams } = this.state;
        await this.setState({
            historyParams: {
                ...historyParams,
                offset: 0,
                search
            },
            historyPaginationParams: {
                ...historyPaginationParams,
                currentPage: 0
            }
        });
        await this.getLogData();
    };

    handleDeleteLog = async (id, choice) => {
        this.setState({
            showConfirmModalLog: true,
            selectedLog: id,
            isRestoreOrDelete: choice
        });
    };
    handleRestoreLog = async (id, choice) => {
        this.setState({
            showConfirmModalLog: true,
            selectedLog: id,
            isRestoreOrDelete: choice
        });
    };

    renderConfirmationModalLog = () => {
        const { showConfirmModalLog, isRestoreOrDelete } = this.state;
        if (!showConfirmModalLog) return null;
        if (isRestoreOrDelete === "delete") {
            return (
                <Portal
                    body={
                        <ConfirmationModal
                            heading={"Do you want to delete this log?"}
                            message={"This action cannot be reverted, are you sure that you need to delete this item?"}
                            onNo={() => this.setState({ showConfirmModalLog: false })}
                            onYes={this.deleteLogOnConfirm}
                        />
                    }
                    onCancel={() => this.setState({ showConfirmModalLog: false })}
                />
            );
        } else {
            return (
                <Portal
                    body={
                        <ConfirmationModal
                            heading={"Do you want to restore this log?"}
                            message={"This action cannot be reverted, are you sure that you need to restore this item?"}
                            onNo={() => this.setState({ showConfirmModalLog: false })}
                            onYes={this.restoreLogOnConfirm}
                            isRestore={true}
                            type={"restore"}
                        />
                    }
                    onCancel={() => this.setState({ showConfirmModalLog: false })}
                />
            );
        }
    };

    deleteLogOnConfirm = async () => {
        const { selectedLog } = this.state;
        await this.props.deleteDataLog(selectedLog);
        const { error, message, success } = this.props.assetReducer.deleteDataLogResponse;
        if (!success) {
            this.setState({ alertMessage: error || "Something went wrong !" }, () => this.showAlert());
        } else {
            this.setState({ alertMessage: message }, () => this.showAlert());
        }
        await this.getLogData();
        this.setState({
            showConfirmModalLog: false,
            selectedLog: null
        });
    };

    restoreLogOnConfirm = async selectedLog => {
        await this.props.restoreDataLog(selectedLog);
        const { error, message, success } = this.props.assetReducer.restoreDataLogResponse;
        if (!success) {
            this.setState({ alertMessage: error || "Something went wrong !" }, () => this.showAlert());
        } else {
            this.setState({ alertMessage: message }, () => this.showAlert());
        }
        this.setState({
            showConfirmModalLog: false,
            selectedLog: null,
            isHistory: false
        });
        this.refreshDataList();
    };

    exportTableXl = async hide_columns => {
        this.setState({ tableLoading: true });
        const {
            params: { search, filters, list, order, deleted, active, recommendation_assigned_true, guid_assigned, has_image, asset_ids }
        } = this.state;
        // const {
        //     tableData: { keys, config }
        // } = this.state;
        // let hide_columns = [""];
        // keys.map((keyItem, i) => {
        //     if (config && !config[keyItem]?.isVisible) {
        //         hide_columns.push(config[keyItem]?.label);
        //     }
        // });
        const { has_image: omit, ...restList } = list || {};

        let exportParams = {
            search,
            filters,
            list: restList,
            order,
            deleted,
            active,
            hide_columns,
            recommendation_assigned_true,
            guid_assigned,
            has_image,
            asset_ids
        };

        if (this.props.isAssignView || this.props.isProjectAssetView) {
            exportParams.client_id = this.props.clientId;
        }
        const { section, id } = this.props.match.params;
        let subParams = {};
        switch (section) {
            case "assetinfo":
                subParams.client_id = id;
                break;
            case "regioninfo":
                subParams.region_id = id;
                break;
            case "siteinfo":
                subParams.site_id = id;
                break;
            case "buildinginfo":
                subParams.building_id = id;
                break;

            default:
                break;
        }
        await this.props.exportData({ ...exportParams, ...subParams, ...this.state.locationState });
        this.setState({
            tableLoading: false
        });
        if (this.props.assetReducer.dataExportResponse && this.props.assetReducer.dataExportResponse.error) {
            await this.setState({ alertMessage: this.props.assetReducer.dataExportResponse.error });
            this.showAlert();
        }
    };

    exportCustomExcel = async () => {
        this.setState({ customExcelExportLoading: true });
        const {
            params: { search, filters, list, order, deleted, active, recommendation_assigned_true, guid_assigned, has_image }
        } = this.state;
        const {
            tableData: { keys, config }
        } = this.state;
        let hide_columns = [""];
        keys.map((keyItem, i) => {
            if (config && !config[keyItem]?.isVisible) {
                hide_columns.push(config[keyItem]?.label);
            }
        });
        let exportParams = { search, filters, list, order, deleted, active, hide_columns, recommendation_assigned_true, guid_assigned, has_image };
        const { section, id } = this.props.match.params;
        let subParams = {};
        if (this.props.isProjectAssetView) {
            const id = this.props.clientId;
        }
        switch (section) {
            case "assetinfo":
                subParams.client_id = id;
                break;
            case "regioninfo":
                subParams.region_id = id;
                break;
            case "siteinfo":
                subParams.site_id = id;
                break;
            case "buildinginfo":
                subParams.building_id = id;
                break;
            case "projectinfo":
                subParams.client_id = this.props.clientId;
                break;

            default:
                break;
        }
        await this.props.exportCustomExcel({ ...exportParams, ...subParams, ...this.state.locationState });
        this.setState({
            customExcelExportLoading: false
        });
        if (this.props.assetReducer.dataExportResponse && this.props.assetReducer.dataExportResponse.error) {
            await this.setState({ alertMessage: this.props.assetReducer.dataExportResponse.error });
            this.showAlert();
        }
    };

    updateLogSortFilters = async searchKey => {
        if (this.state.historyParams.order) {
            await this.setState({
                historyParams: {
                    ...this.state.historyParams,
                    order: {
                        ...this.state.historyParams.order,
                        [searchKey]: this.state.historyParams.order[searchKey] === "desc" ? "asc" : "desc"
                    }
                }
            });
        } else {
            await this.setState({
                historyParams: {
                    ...this.state.historyParams,
                    order: { [searchKey]: "asc" }
                }
            });
        }
        this.updateEntityParams();
        await this.getLogData(this.props.match.params.id);
    };

    cancelForm = () => {
        popBreadCrumpOnPageClose();
        history.push(findPrevPathFromBreadCrumpData());
    };

    cancelInfoPage = () => {
        popBreadCrumpOnPageClose();
        history.push(findPrevPathFromBreadCrumpData() || `/assets`);
    };

    toggleHistory = async () => {
        this.setState({ isHistory: !this.state.isHistory });
    };

    handleDeleteData = async id => {
        this.setState({
            showConfirmModal: true,
            selectedData: id
        });
    };

    renderConfirmationModal = () => {
        const { showConfirmModal } = this.state;
        if (!showConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Do you want to delete this Asset ?`}
                        message={"This action cannot be reverted, are you sure that you need to delete this item?"}
                        onNo={() => this.setState({ showConfirmModal: false })}
                        onYes={this.deleteDataOnConfirm}
                    />
                }
                onCancel={() => this.setState({ showConfirmModal: false })}
            />
        );
    };

    deleteDataOnConfirm = async () => {
        const { selectedData } = this.state;
        this.setState({
            showConfirmModal: false,
            isLoading: true
        });
        await this.props.deleteData(selectedData);

        const { success, message, error } = this.props.assetReducer.deleteDataResponse;
        if (!success) {
            await this.setState({
                alertMessage: message || error,
                showConfirmModal: false
            });
            this.showLongAlert();
        } else {
            await this.setState({ alertMessage: message });
            this.showLongAlert();
            if (this.props.match.path === "/assets/:section/:id/:tab") {
                this.cancelInfoPage();
            }
            this.refreshDataList();
        }
        this.setState({
            isLoading: false
        });
    };

    showLongAlert = () => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show-long-alert";
            x.innerText = this.state.alertMessage;
            setTimeout(function () {
                x.className = x.className.replace("show-long-alert", "");
            }, 6000);
        }
    };
    getAllImageList = async (assetId, params) => {
        await this.props.getAllImages(assetId, params);
        const {
            assetReducer: { getAllImagesResponse }
        } = this.props;
        await this.setState({
            imageResponse: getAllImagesResponse
        });
        return true;
    };

    uploadImages = async (imageData = {}) => {
        const { selectedData } = this.state;
        await this.props.uploadAssetImage(imageData, selectedData || this.props.match.params.id);
        return true;
    };
    updateAssetImages = async imageData => {
        await this.props.updateAssetImage(imageData);
        return true;
    };

    deleteImages = async imageId => {
        await this.props.deleteAssetImage(imageId);
        return true;
    };

    handleSelectAsset = (data, isChecked) => {
        this.props.handleSelectAsset(data, isChecked);
    };

    filterByRecommendationAssigned = async value => {
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                recommendation_assigned_true: value || null
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            }
        });
        this.updateEntityParams();
        await this.refreshDataList();
    };

    onePagerExport = data => {
        this.setState({
            showOnePagerExportModal: true,
            selectedAssetData: data
        });
    };

    renderAssetOnePagerExportModal = () => {
        const { showOnePagerExportModal, exportLoader } = this.state;
        if (!showOnePagerExportModal) return null;
        return (
            <Portal
                body={
                    <ExportModal
                        onCancel={() => this.setState({ showOnePagerExportModal: false })}
                        viewExportSettings={() => this.setState({ showExportSettings: true })}
                        exportData={this.postExport}
                        exportLoader={exportLoader}
                    />
                }
                onCancel={() => this.setState({ showOnePagerExportModal: false })}
            />
        );
    };

    onSettingsSave = async data => {
        await this.props.postExportProperties(data);
        const message = this.props.assetReducer.postSettingsResponse?.message ?? "";
        await this.setState({ alertMessage: message });
        this.showAlert();
        this.props.getExportProperties({ client_id: data.client_id });
        this.setState({ showExportSettings: false });
    };

    renderAssetExportSettings = () => {
        const { showExportSettings } = this.state;
        if (!showExportSettings) return null;
        return (
            <Portal
                body={
                    <OnePagerModal
                        onCancel={() => this.setState({ showExportSettings: false })}
                        onSettingsSave={this.onSettingsSave}
                        saveLoader={this.props.assetReducer?.postSettingsLoader}
                    />
                }
                onCancel={() => this.setState({ showExportSettings: false })}
            />
        );
    };

    postExport = async type => {
        const { selectedAssetData } = this.state;
        this.setState({ showOnePagerExportModal: false });
        this.setState({ exportLoader: true });
        let params = { ...selectedAssetData };
        if (this.props.assetReducer?.exportSettingsResponse?.data?.id)
            params = { ...params, property_id: this.props.assetReducer?.exportSettingsResponse?.data?.id };
        if (type === "pdf") {
            await this.props.exportAssetReportPdf({ ...params });
        } else {
            await this.props.exportAssetReportWord({ ...params });
        }
        this.setState({ exportLoader: false, showOnePagerExportModal: false });
    };

    showUploadDataModal = async (type = "normal") => {
        this.setState({
            showUploadDataModal: true,
            importType: type
        });
    };

    renderUploadDataModal = () => {
        const { showUploadDataModal, importType } = this.state;
        if (!showUploadDataModal) return null;
        const {
            match: {
                params: { section }
            }
        } = this.props;
        const clientDetails = section === "assetinfo" ? this.props.basicDetails : this.props.basicDetails?.client;
        return (
            <Portal
                body={
                    <BulkUploadForm
                        importType={importType}
                        clientDetails={clientDetails}
                        onCancel={() => this.setState({ showUploadDataModal: false })}
                        onSuccess={this.refreshDataList}
                    />
                }
                onCancel={() => this.setState({ showUploadDataModal: false })}
            />
        );
    };

    updateLastSortFilter = async (searchKey, val) => {
        if (this.state.params.order && this.state.params?.order[searchKey]) {
            await this.setState({
                params: {
                    ...this.state.params,
                    order: {
                        [searchKey]: this.state.params.order[searchKey] === "asc" ? "desc" : "asc"
                    }
                }
            });
        } else {
            await this.setState({
                params: {
                    ...this.state.params,
                    order: { [searchKey]: "desc" }
                }
            });
        }
        this.updateEntityParams();
        await this.refreshDataList();
    };

    filterByGuid = async () => {
        const { guid_assigned } = this.state.params;
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                guid_assigned: guid_assigned === "true" ? "false" : guid_assigned === "false" ? null : "true"
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            }
        });
        this.updateEntityParams();
        await this.refreshDataList();
    };

    filterByImages = async () => {
        const { has_image } = this.state.params;
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                has_image: has_image === "true" ? "false" : has_image === "false" ? null : "true"
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            }
        });
        this.updateEntityParams();
        await this.refreshDataList();
    };

    toggleFiltersPopup = () => {
        this.setState({ showFiltersPopup: !this.state.showFiltersPopup });
    };

    getCurrentFilters = () => {
        let { limit, offset, has_image, ...currentFilters } = { ...this.state.params, ...this.state.locationState };
        if (this.props.isAssignView) {
            currentFilters.client_id = this.props.clientId;
        }
        const { section, id } = this.props.match.params;
        switch (section) {
            case "assetinfo":
                currentFilters.client_id = id;
                break;
            case "regioninfo":
                currentFilters.region_id = id;
                break;
            case "siteinfo":
                currentFilters.site_id = id;
                break;
            case "buildinginfo":
                currentFilters.building_id = id;
                break;

            default:
                break;
        }
        return currentFilters;
    };

    renderFiltersPopup = () => {
        const { showFiltersPopup } = this.state;
        if (!showFiltersPopup) return null;
        return (
            <Portal
                body={
                    <MyFilters
                        currentFilters={this.getCurrentFilters()}
                        onCancel={() => this.toggleFiltersPopup()}
                        submitMasterFilter={this.submitMasterFilter}
                        tableConfig={this.state.tableData.config}
                    />
                }
                onCancel={() => this.toggleFiltersPopup()}
            />
        );
    };

    submitMasterFilter = filterData => {
        const { filters, ...rest } = filterData || {};
        let image_assigned_filter = null;
        if (rest?.has_image?.length) {
            image_assigned_filter = rest.has_image[0];
        }
        this.setState(
            {
                params: {
                    ...this.state.params,
                    filters: Object.keys(filters)?.length ? filters : null,
                    list: rest,
                    has_image: image_assigned_filter
                }
            },
            async () => this.refreshDataList()
        );
    };

    renderAppliedFilters = () => {
        const { showAppliedFilters, params } = this.state;
        if (!showAppliedFilters) return null;
        return (
            <Portal
                body={
                    <AppliedFilters
                        myFilters={{}}
                        filterValues={params}
                        openFiltersPopup={() => this.setState({ showFiltersPopup: true })}
                        showFiltersIcon={!this.state.showFiltersPopup}
                        onCancel={() => this.setState({ showAppliedFilters: false })}
                        source="asset"
                        tableConfig={this.state.tableData.config}
                    />
                }
                onCancel={() => this.setState({ showAppliedFilters: false })}
            />
        );
    };

    toggleAppliedFiltersPopup = () => {
        this.setState({ showAppliedFilters: !this.state.showAppliedFilters });
    };

    handleSelectMultiAsset = async ({ id }, isChecked) => {
        const {
            selectedAssetsId,
            paginationParams: { currentPage, perPage },
            dataList
        } = this.state;
        const page = currentPage * perPage;
        const index = page + (dataList.findIndex(elem => elem.id === id) + 1);
        await this.setState({
            selectedAssetsId: isChecked ? [...selectedAssetsId, { id, index }] : selectedAssetsId.filter(t => t.id !== id)
        });
        this.updateEntityParams();
    };

    handleSelectAllAssets = async isChecked => {
        const {
            dataList,
            paginationParams: { perPage, currentPage }
        } = this.state;
        const page = currentPage * perPage;

        await this.setState({ selectedAssetsId: isChecked ? dataList.map(({ id }, i) => ({ id, index: page * (i + 1) })) : [] });
        this.updateEntityParams();
    };

    clearSelection = async () => {
        const hadAssetIdParam = this.state.params?.asset_ids ? true : false;
        await this.setState({ selectedAssetsId: [], params: { ...this.state.params, asset_ids: null } });
        this.updateEntityParams();
        if (hadAssetIdParam) await this.refreshDataList();
    };

    showSelectedAssets = async () => {
        if (this.state.params.asset_ids?.length) {
            await this.setState({
                params: {
                    ...this.state.params,
                    offset: 0,
                    asset_ids: null
                },
                paginationParams: {
                    ...this.state.paginationParams,
                    currentPage: 0
                }
            });
        } else {
            await this.setState({
                params: {
                    ...this.state.params,
                    offset: 0,
                    asset_ids: this.state.selectedAssetsId.map(elem => elem.id)
                },
                paginationParams: {
                    ...this.state.paginationParams,
                    currentPage: 0
                }
            });
        }
        this.updateEntityParams();
        await this.refreshDataList();
    };

    renderExportExcelConfigurationModal = () => {
        const { showExportConfigurationModal, tableData } = this.state;
        if (!showExportConfigurationModal) return null;
        return (
            <Portal
                body={
                    <ExportColumnConfiguration
                        keys={tableData.keys}
                        config={tableData.config}
                        onExport={this.exportTableXl}
                        isDraggable={this.props?.isDraggable}
                        onCancel={() => {
                            this.setState({ showExportConfigurationModal: false });
                            this.props?.isDraggable && this.props?.isDraggable(false);
                        }}
                    />
                }
                onCancel={() => {
                    this.setState({ showExportConfigurationModal: false });
                    this.props?.isDraggable && this.props?.isDraggable(false);
                }}
            />
        );
    };

    toggleMultiSelectEditForm = async () => {
        this.setState({ showMultiSelectEditForm: !this.state.showMultiSelectEditForm });
    };

    handleMultiAssetUpdate = async values => {
        await this.props.updateMultiAssets(values);
        if (this.props.assetReducer.updateMultipleAssetsResponse && this.props.assetReducer.updateMultipleAssetsResponse.error) {
            await this.setState({
                alertMessage: this.props.assetReducer.updateMultipleAssetsResponse.error
            });
            this.showAlert();
        } else {
            this.toggleMultiSelectEditForm();
            await this.setState({
                params: {
                    ...this.state.params,
                    offset: 0
                },
                paginationParams: {
                    ...this.state.paginationParams,
                    currentPage: 0
                }
            });
            await this.refreshDataList();
            await this.setState({
                alertMessage: this.props.assetReducer.updateMultipleAssetsResponse?.message
            });
            this.showAlert();
        }
    };

    renderMultiSelectEditForm = () => {
        const { showMultiSelectEditForm } = this.state;
        if (!showMultiSelectEditForm) return null;
        const { basicDetails } = this.props;
        const clientId = basicDetails?.client?.id;
        return (
            <Portal
                body={
                    <MultiSelectEditForm
                        onCancel={this.toggleMultiSelectEditForm}
                        selectedAssetsIds={this.state.selectedAssetsId}
                        clientId={clientId}
                        handleMultiAssetUpdate={this.handleMultiAssetUpdate}
                    />
                }
                onCancel={this.toggleMultiSelectEditForm}
            />
        );
    };

    assignImagesToAsset = async imageData => {
        await this.props.assignImagesToSection(imageData, this.props.match.params.id, "assets");
        return this.props.imageReducer.assignImageResponse;
    };

    refreshDynamicFields = async () => {
        if (this.props.assetReducer?.assetDynamicFields?.asset_custom_field) {
            let tempKeys = [...this.state.tableData.keys];
            let tempConfig = { ...this.state.tableData.config };
            const { asset_custom_field: dynamicBands = {} } = this.props.assetReducer?.assetDynamicFields;
            Object.entries(dynamicBands).forEach(([band, fields], i) => {
                fields.forEach(field => {
                    let indexToInsertBefore = tempKeys.findIndex(item => item === "created_at");
                    tempKeys.splice(indexToInsertBefore, 0, field?.field_name || "");

                    tempConfig[field?.field_name] = {
                        isVisible:
                            this.props.assetReducer.entityParams[this.props.match.params.section]?.tableConfig?.[field?.field_name]?.isVisible ===
                            false
                                ? false
                                : field?.rules?.table_default_visible === false
                                ? false
                                : true,
                        label: field?.label,
                        class: "width-230px",
                        searchKey: `dynamic_fields.${field?.field_name}`,
                        type: field?.field_type,
                        hasWildCardSearch: true,
                        hasCommonSearch: false,
                        getListTable: "",
                        commonSearchKey: "dynamic_fields",
                        commonSearchObjectKey: field?.field_name,
                        isDynamicField: true,
                        rules: field?.rules,
                        pinned: this.props.assetReducer.entityParams[this.props.match.params.section]?.tableConfig?.[field?.field_name]?.pinned
                            ? true
                            : false,
                        style: this.props.assetReducer.entityParams[this.props.match.params.section]?.tableConfig?.[field?.field_name]?.style
                            ? this.props.assetReducer.entityParams[this.props.match.params.section]?.tableConfig?.[field?.field_name]?.style
                            : field?.style
                    };
                });
            });
            tempKeys = _.uniq(tempKeys);
            await this.setState({
                tableData: {
                    ...this.state.tableData,
                    keys: tempKeys,
                    config: tempConfig
                }
            });
        }
    };

    render() {
        const {
            showViewModal,
            showWildCardFilter,
            paginationParams,
            tableData,
            selectedRowId,
            infoTabsData,
            logData,
            historyPaginationParams,
            historyParams,
            selectedData,
            isHistory,
            imageResponse,
            summaryRowData,
            locationState,
            exportLoader,
            selectedAssetsId
        } = this.state;

        const {
            match: {
                params: { section }
            },
            isAssignView,
            isImageView,
            isSmartChartView
        } = this.props;

        return (
            <LoadingOverlay active={this.state.isLoading} spinner={<Loader />} fadeSpeed={10}>
                {!isAssignView && (section === "add" || section === "edit") ? (
                    <Form
                        selectedData={selectedData}
                        refreshDataList={this.refreshDataList}
                        handleAddData={this.handleAddData}
                        handleUpdateData={this.handleUpdateData}
                        getAssetById={this.getDataById}
                        cancelForm={this.cancelForm}
                    />
                ) : section === "assetInfo" ? (
                    <InfoPage
                        keys={tableData.keys}
                        config={tableData.config}
                        infoTabsData={infoTabsData}
                        getDataById={this.getDataById}
                        showInfoPage={this.showInfoPage}
                        showEditPage={this.showEditPage}
                        updateData={this.handleUpdateData}
                        handleDeleteItem={this.handleDeleteData}
                        getAllDataLogs={this.getLogData}
                        handlePerPageChangeHistory={this.handlePerPageChangeHistory}
                        handlePageClickHistory={this.handlePageClickHistory}
                        handleGlobalSearchHistory={this.handleGlobalSearchHistory}
                        globalSearchKeyHistory={this.state.historyParams && this.state.historyParams.search ? this.state.historyParams.search : ""}
                        logData={logData}
                        handleDeleteLog={this.handleDeleteLog}
                        restoreLog={this.restoreLogOnConfirm}
                        historyPaginationParams={historyPaginationParams}
                        historyParams={historyParams}
                        updateLogSortFilters={this.updateLogSortFilters}
                        selectedData={selectedData}
                        hasEdit={checkPermission("forms", "asset_management", "edit")}
                        hasDelete={checkPermission("forms", "asset_management", "delete")}
                        hasLogView={checkPermission("logs", "asset_management", "view")}
                        hasLogDelete={checkPermission("logs", "asset_management", "delete")}
                        hasLogRestore={checkPermission("logs", "asset_management", "restore")}
                        hasInfoPage={!isSmartChartView && checkPermission("forms", "asset_management", "view")}
                        // hasCreate={checkPermission("forms", "asset_management", "create")}
                        cancelInfoPage={this.cancelInfoPage}
                        cancelForm={this.cancelForm}
                        isHistory={isHistory}
                        toggleHistory={this.toggleHistory}
                        entity={"assets"}
                        getAllImageList={this.getAllImageList}
                        uploadImages={this.uploadImages}
                        imageResponse={imageResponse}
                        updateAssetImages={this.updateAssetImages}
                        deleteImages={this.deleteImages}
                        tableData={this.props.assetReducer.getDataResponse?.assets}
                        onePagerExport={this.onePagerExport}
                        exportLoader={exportLoader}
                        dynamicBands={this.props.assetReducer.assetDynamicFields?.asset_custom_field}
                        assignImagesToAsset={this.assignImagesToAsset}
                    />
                ) : (
                    <DataMain
                        showWildCardFilter={showWildCardFilter}
                        paginationParams={paginationParams}
                        tableData={tableData}
                        handleGlobalSearch={this.handleGlobalSearch}
                        globalSearchKey={this.state.params.search}
                        updateSelectedRow={this.updateSelectedRow}
                        selectedRowId={selectedRowId}
                        toggleWildCardFilter={this.toggleWildCardFilter}
                        showEditPage={this.showEditPage}
                        handlePerPageChange={this.handlePerPageChange}
                        handlePageClick={this.handlePageClick}
                        showAddForm={this.showAddForm}
                        showInfoPage={this.showInfoPage}
                        isColunmVisibleChanged={this.isColunmVisibleChanged}
                        updateWildCardFilter={this.updateWildCardFilter}
                        wildCardFilter={this.state.params.filters}
                        handleHideColumn={this.handleHideColumn}
                        getListForCommonFilterFloor={this.getListForCommonFilter}
                        updateCommonFilter={this.updateCommonFilter}
                        commonFilter={this.state.params.list}
                        resetAllFilters={this.resetAllFilters}
                        resetAll={this.resetAll}
                        updateTableSortFilters={this.updateTableSortFilters}
                        resetSort={this.resetSort}
                        tableParams={this.state.params}
                        exportTableXl={() => this.setState({ showExportConfigurationModal: true })}
                        exportCustomExcel={this.exportCustomExcel}
                        tableLoading={this.state.tableLoading}
                        customExcelExportLoading={this.state.customExcelExportLoading}
                        showViewModal={this.showViewModal}
                        summaryRowData={summaryRowData}
                        handleDelete={this.handleDeleteData}
                        hasExport={checkPermission("forms", "asset_management", "export")}
                        showAddButton={!isAssignView && !isImageView && !isSmartChartView && checkPermission("forms", "asset_management", "create")}
                        hasEdit={checkPermission("forms", "asset_management", "edit")}
                        hasDelete={checkPermission("forms", "asset_management", "delete")}
                        hasInfoPage={!isSmartChartView && !isAssignView && checkPermission("forms", "asset_management", "view")}
                        hasActionColumn={!(isAssignView || isSmartChartView)}
                        isAssignAsset={isAssignView || isSmartChartView}
                        entity={"assets"}
                        handleSelectAsset={this.handleSelectAsset}
                        selectedAsset={this.props.assetReducer.selectedAsset}
                        tableRef={this.tableRef}
                        filterByRecommendationAssigned={this.filterByRecommendationAssigned}
                        isAssetsChartsFiltered={Object.keys(locationState).length > 0}
                        hasViewIcon={!isAssignView && checkPermission("forms", "asset_management", "view")}
                        showUploadDataModal={this.showUploadDataModal}
                        updateLastSortFilter={this.updateLastSortFilter}
                        filterByGuid={this.filterByGuid}
                        filterByImages={this.filterByImages}
                        openFiltersPopup={this.toggleFiltersPopup}
                        toggleAppliedFiltersPopup={this.toggleAppliedFiltersPopup}
                        isSmartChartView={this.props.isSmartChartView}
                        handleSelectRecom={this.handleSelectMultiAsset}
                        selectedRecomIds={selectedAssetsId.map(e => e.id)}
                        handleSelectAllRecom={this.handleSelectAllAssets}
                        clearSelection={this.clearSelection}
                        showSelectedAssets={this.showSelectedAssets}
                        selectWholeAssets={this.selectWholeAssets}
                        everyItemCheckedPerPage={tableData?.data?.every(item => selectedAssetsId.find(e => e.id === item.id))}
                        hasMultiAction={!isAssignView}
                        isAssignView={isAssignView}
                        handleEditMultiSelectedData={this.toggleMultiSelectEditForm}
                        recommendationAssignedCount={this.props.assetReducer.recommendationAssignedCount}
                    />
                )}
                {showViewModal ? (
                    <Portal
                        body={
                            <ViewModal
                                keys={tableData.keys}
                                config={tableData.config}
                                handleHideColumn={this.handleHideColumn}
                                isDraggable={this.props?.isDraggable}
                                onCancel={() => {
                                    this.setState({ showViewModal: false });
                                    this.props?.isDraggable && this.props?.isDraggable(false);
                                }}
                            />
                        }
                        onCancel={() => {
                            this.setState({ showViewModal: false });
                            this.props?.isDraggable && this.props?.isDraggable(false);
                        }}
                    />
                ) : null}
                {this.renderConfirmationModalLog()}
                {this.renderConfirmationModal()}
                {this.renderAssetOnePagerExportModal()}
                {this.renderUploadDataModal()}
                {this.renderFiltersPopup()}
                {this.renderAppliedFilters()}
                {this.renderAssetExportSettings()}
                {this.renderExportExcelConfigurationModal()}
                {this.renderMultiSelectEditForm()}
            </LoadingOverlay>
        );
    }
}
const mapStateToProps = state => {
    const { assetReducer, imageReducer } = state;
    return { assetReducer, imageReducer };
};

export default withRouter(
    connect(mapStateToProps, {
        ...actions,
        assignImagesToSection: ImageActions.assignImagesToSection
    })(Assets)
);
