import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import ReactTooltip from "react-tooltip";
import LoadingOverlay from "react-loading-overlay";

import actions from "../actions";
import { reorderArray } from "../../../config/utils";
import DocumentFilters from "./DocumentFilters";
import GlobalSearch from "../../common/components/GlobalSearch";
import Loader from "../../common/components/Loader";
import { downloadPresignedUrlFile } from "../../common/actions";

const ImageListForBandModal = ({
    onCancel,
    selectedClient,
    getUploadedImageList,
    setSmartChartData,
    configData = {},
    isView = false,
    isTemplateView = false,
    entity = "Image",
    selectedBand,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [imageRowConstructArray, setImageRowConstructArray] = useState([]);
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [params, setParams] = useState({
        limit: 100,
        offset: 0,
        search: ""
    });
    const [sortParams, setSortParams] = useState({
        sort_by: "created_at",
        order: "desc"
    });
    const [viewType, setViewType] = useState("ALL");
    const refreshList = useRef(false);

    useEffect(() => {
        if (isView && isTemplateView) {
            getUploadedImageList({ client_id: selectedClient, is_image: true });
        } else {
            refreshDocumentList();
        }
        if (configData?.selectedChartConfig?.data?.length && !isView) {
            let selectedImageIds = configData.selectedChartConfig.data.map(img => img.id) || [];
            setSelectedImages([...selectedImageIds]);
        }
    }, []);

    useEffect(() => {
        let allImages = props.smartChartReducer.uploadedImageListResponse?.data || [];
        if (allImages?.length && entity === "Image") {
            if (isView) {
                let selectedImagList = configData?.selectedChartConfig?.data || [];
                let selectedImagIdList = selectedImagList.map(img => img.id);
                let filteredImages = allImages.filter(img => selectedImagIdList.includes(img.id));
                allImages = filteredImages.slice().sort((a, b) => selectedImagIdList.indexOf(a.id) - selectedImagIdList.indexOf(b.id));
            }
            let tempImageRowConstructArray = [];
            let totalRows = allImages.length / 4 + (allImages.length % 4 ? 1 : 0);
            for (let i = 1; i <= totalRows; i++) {
                tempImageRowConstructArray.push(i);
            }
            setImageRowConstructArray([...tempImageRowConstructArray]);
            setImageList([...allImages]);
        }
    }, [props.smartChartReducer.uploadedImageListResponse]);

    useEffect(() => {
        let allDocuments = props.smartChartReducer.uploadedDocListResponse?.data || [];
        if (allDocuments?.length) {
            if (entity === "Document") {
                let tempImageRowConstructArray = [];
                let totalRows = allDocuments.length / 4 + (allDocuments.length % 4 ? 1 : 0);
                for (let i = 1; i <= totalRows; i++) {
                    tempImageRowConstructArray.push(i);
                }
                setImageRowConstructArray([...tempImageRowConstructArray]);
                setImageList([...allDocuments]);
            } else if (entity === "Image") {
                if (isView) {
                    let selectedImagList = configData?.selectedChartConfig?.data || [];
                    let selectedImagIdList = selectedImagList.map(img => img.id);
                    let filteredImages = allDocuments.filter(img => selectedImagIdList.includes(img.id));
                    allDocuments = filteredImages.slice().sort((a, b) => selectedImagIdList.indexOf(a.id) - selectedImagIdList.indexOf(b.id));
                }
                let tempImageRowConstructArray = [];
                let totalRows = allDocuments.length / 4 + (allDocuments.length % 4 ? 1 : 0);
                for (let i = 1; i <= totalRows; i++) {
                    tempImageRowConstructArray.push(i);
                }
                setImageRowConstructArray([...tempImageRowConstructArray]);
                setImageList([...allDocuments]);
            }
        }
    }, [props.smartChartReducer.uploadedDocListResponse]);

    useEffect(() => {
        if (refreshList.current) {
            refreshList.current = false;
            refreshDocumentList();
        }
    }, [params.limit, params.offset, params.search, sortParams.order, sortParams.sort_by, viewType]);

    const refreshDocumentList = async () => {
        const { limit, offset, search } = params;
        const { sort_by, order } = sortParams;
        let docParams = { limit, offset, search, sort_by, order };
        if (selectedClient) {
            docParams.client_id = selectedClient || "";
        }
        if (entity === "Image") {
            docParams.is_image = true;
        }
        if (entity !== "Image") {
            docParams.filter = viewType;
        }
        setIsLoading(true);
        await props.getUploadedDocList({ ...docParams });
        setIsLoading(false);
    };

    const handleSelectItem = item => {
        if (entity === "Document") {
            let tempSelectedDocIds = [...selectedImages];
            let tempSelectedDocs = [...selectedDocs];
            if (tempSelectedDocIds.includes(item.id)) {
                tempSelectedDocIds = tempSelectedDocIds.filter(img => img !== item.id);
                tempSelectedDocs = tempSelectedDocs.filter(doc => doc.id !== item.id);
            } else {
                tempSelectedDocIds.push(item.id);
                tempSelectedDocs.push(item);
            }
            setSelectedImages(prevSelectedImages => {
                return [...tempSelectedDocIds];
            });
            setSelectedDocs(prevSelectedDocs => {
                return [...tempSelectedDocs];
            });
        } else {
            let tempSelectedImages = [...selectedImages];
            if (tempSelectedImages.includes(item.id)) {
                tempSelectedImages = tempSelectedImages.filter(img => img !== item.id);
            } else {
                tempSelectedImages.push(item.id);
            }
            setSelectedImages(prevSelectedImages => {
                return [...tempSelectedImages];
            });
        }
    };

    const saveItemsForBand = () => {
        if (entity === "Document") {
            let docKeys = [];
            let selectedDocForBand = selectedDocs.reduce((resultData, currentProp) => {
                let currentDocKey = "user_doc" + uuid();
                docKeys.push(currentDocKey);
                return {
                    ...resultData,
                    [currentDocKey]: {
                        id: currentProp?.id,
                        name: currentProp?.name || "",
                        remove_section_break: false
                    }
                };
            }, {});
            setSmartChartData("add_document_tile", {
                bandName: "band1",
                entity: selectedBand,
                documentTileData: { ...selectedDocForBand },
                addedDocKeys: [...docKeys]
            });
        } else {
            let updatesImageIdsForBand = selectedImages.map(img => {
                return { id: img };
            });
            setSmartChartData("config_image_band", {
                ...configData,
                imageBandConfig: { data: [...updatesImageIdsForBand] }
            });
        }
        onCancel();
    };

    const handleSelectAll = (isUnselect = false) => {
        if (isUnselect) {
            setSelectedImages([]);
            setSelectedDocs([]);
        } else {
            if (entity === "Document") {
                let allDocs = props.smartChartReducer.uploadedDocListResponse?.data || [];
                let allDocIds = allDocs.map(doc => doc.id);
                setSelectedImages([...allDocs]);
                setSelectedImages([...allDocIds]);
                setSelectedDocs([...allDocs]);
            } else {
                let allImages = props.smartChartReducer.uploadedDocListResponse?.data || [];
                let allImagIds = allImages.map(img => img.id);
                setSelectedImages([...allImagIds]);
            }
        }
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        let reOrderedImages = reorderArray([...imageList], result.source.index, result.destination.index);
        setImageList([...reOrderedImages]);
        let updatedImageData = reOrderedImages.map(img => {
            return { id: img.id };
        });
        setSmartChartData("config_image_band", {
            ...configData,
            imageBandConfig: { data: [...updatedImageData] }
        });
    };

    const downloadDocs = url => {
        downloadPresignedUrlFile(url);
    };

    const getItemListForView = viewType => {
        let returnData = [];
        switch (viewType) {
            case "global_doc":
                returnData = imageList.filter(doc => doc.doc_type === "GLB");
                break;
            case "client_doc":
                returnData = imageList.filter(doc => doc.doc_type !== "GLB");
                break;
            case "image":
                returnData = [...imageList];
                break;
        }
        return returnData;
    };

    const handleSortData = (key, value) => {
        setSortParams(prevSortParams => {
            return {
                ...prevSortParams,
                [key]: value
            };
        });
        refreshList.current = true;
    };

    const handleSelectViewType = type => {
        setViewType(type);
        refreshList.current = true;
    };

    const handleGlobalSearch = async search => {
        setParams(prevParams => {
            return {
                ...prevParams,
                search
            };
        });
        refreshList.current = true;
    };

    const resetAllFilters = async () => {
        setParams(prevParams => {
            return {
                ...prevParams,
                search: ""
            };
        });
        setViewType("ALL");
        setSortParams({
            sort_by: "created_at",
            order: "desc"
        });
        refreshList.current = true;
    };

    return (
        <div class="modal modal-region smart-chart-popup smart-dtl-pop add-image-modal" id="modalId" tabindex="-1" style={{ display: "block" }}>
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <div class="txt-hed">{isView ? "Selected Images" : `Select ${entity === "Document" ? "User Document" : entity}s`}</div>
                            <div class="btn-mod-headotr">
                                {!isView ? (
                                    <div class="checkbox">
                                        <label class="container-check green-check">
                                            <input
                                                type="checkbox"
                                                name="is_bold"
                                                checked={selectedImages.length === imageList.length}
                                                onClick={e => handleSelectAll(!e.target.checked)}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                        {`Select All ${entity === "Document" ? "User Document" : entity}s`}
                                    </div>
                                ) : null}
                            </div>
                        </h5>
                        <button type="button" class="close" onClick={() => onCancel()}>
                            <span aria-hidden="true">
                                <img src="/img/close.svg" alt="" />
                            </span>
                        </button>
                    </div>
                    <LoadingOverlay active={isLoading} spinner={<Loader />} fadeSpeed={10}>
                        <div class="modal-body region-otr position-relative pad-modal-bdy">
                            {!isTemplateView && !isView && (
                                <div className="doc-filter">
                                    <DocumentFilters
                                        sortParams={sortParams}
                                        handleSortData={handleSortData}
                                        handleSelectViewType={handleSelectViewType}
                                        viewType={viewType}
                                        isImage={entity === "Image"}
                                        entityKey={entity === "Image" ? "images" : "documents"}
                                    />
                                    <div className="btn-outer-sec">
                                        <GlobalSearch
                                            handleGlobalSearch={handleGlobalSearch}
                                            globalSearchKey={params.search}
                                            customClass={"search-sm-chrt"}
                                        />
                                        <button
                                            data-for="table-top-icons"
                                            data-tip={`Reset Filters`}
                                            className="btn btn-grid filtr-grid"
                                            onClick={() => resetAllFilters()}
                                        >
                                            <img src="/img/refresh-dsh.svg" alt="" className="fil-ico" />
                                        </button>
                                    </div>
                                </div>
                            )}
                            {entity === "Document" ? (
                                <div class="images-list imge-grid col-12 mb-3">
                                    {imageList.map((docItem, index) => (
                                        <div class="images-bx-out col-md-3" key={`global-doc-${index}`}>
                                            <div class="img-out-section">
                                                <>
                                                    <ReactTooltip
                                                        id={`smart-chart-image-list-modal-global`}
                                                        effect="solid"
                                                        place="bottom"
                                                        backgroundColor="#007bff"
                                                    />
                                                    <div
                                                        className="download-img-icn cursor-pointer"
                                                        data-tip="Download Document"
                                                        data-for="smart-chart-image-list-modal-global"
                                                        onClick={() => downloadDocs(docItem.s3_file)}
                                                    >
                                                        <img src="/img/download.svg" alt="" />
                                                    </div>
                                                </>

                                                <div class="img-list-out">
                                                    <div className={`checkbox ${entity === "Image" ? "img-check-bx" : ""}`}>
                                                        <label class="container-check">
                                                            <input
                                                                type="checkbox"
                                                                name="is_bold"
                                                                checked={selectedImages.includes(docItem.id)}
                                                                onClick={() => handleSelectItem(docItem)}
                                                            />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="dtl-out-txt">
                                                    <div className="d-flex align-items-center">
                                                        <div class="icons-sec">
                                                            <img src="/img/file-type-standard.svg" alt="" />
                                                        </div>
                                                        <h3 className="mt-1 ml-2">{docItem.name || "-"}</h3>
                                                    </div>

                                                    <div class={`notes-otr ${entity === "Document" ? "mt-3" : ""}`}>
                                                        <label>Notes</label>
                                                        <p class="notes-txt">{docItem.notes || "-"}</p>
                                                    </div>
                                                    <div class={`notes-otr ${entity === "Document" ? "mt-3" : ""}`}>
                                                        <label>Type</label>
                                                        <p class="notes-txt">{`${docItem.doc_type === "GLB" ? "Global" : "Client"} Document`}</p>
                                                    </div>
                                                </div>
                                                <div class="footer-txt">
                                                    <div class="ftr-dt">
                                                        <label>Uploaded By</label>
                                                        <p class="txt-ftr">{docItem.user || "-"}</p>
                                                    </div>
                                                    <div class="ftr-dt">
                                                        <label>Uploaded On</label>
                                                        <p class="txt-ftr">
                                                            {docItem.created_at ? moment(docItem.created_at).format("MM-DD-YYYY h:mm A") : "-"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                            {entity === "Image" ? (
                                <DragDropContext onDragEnd={onDragEnd}>
                                    {imageRowConstructArray.map((dropRow, index) => (
                                        <Droppable
                                            droppableId={`selected-images-document-${index}`}
                                            direction="horizontal"
                                            isDropDisabled={isTemplateView || !isView}
                                        >
                                            {(provided, snapshot) => (
                                                <div class="images-list col-12" ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
                                                    {getItemListForView(entity === "Document" ? "client_doc" : "image")
                                                        .slice(index * 4, (index + 1) * 4)
                                                        .map((imageItem, i) => (
                                                            <Draggable
                                                                key={`draggable-selected-img${imageItem.id}`}
                                                                draggableId={`draggable-selected-img${imageItem.id}`}
                                                                index={index * 4 + i}
                                                                isDragDisabled={isTemplateView || !isView}
                                                            >
                                                                {(provided, snapshot) => {
                                                                    return (
                                                                        <div
                                                                            class="images-bx-out col-md-3"
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            key={`draggable-${i}`}
                                                                        >
                                                                            <div class="img-out-section">
                                                                                {isView && !isTemplateView ? (
                                                                                    <div class="top-sec">
                                                                                        <div class="icon">
                                                                                            <img src="/img/icon-squre.svg" />
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                                {entity === "Document" ? (
                                                                                    <>
                                                                                        <ReactTooltip
                                                                                            id={`smart-chart-image-list-modal`}
                                                                                            effect="solid"
                                                                                            place="bottom"
                                                                                            backgroundColor="#007bff"
                                                                                        />
                                                                                        <div
                                                                                            className="download-img-icn cursor-pointer"
                                                                                            data-tip="Download Document"
                                                                                            data-for="smart-chart-image-list-modal"
                                                                                            onClick={() => downloadDocs(imageItem.s3_file)}
                                                                                        >
                                                                                            <img src="/img/download.svg" alt="" />
                                                                                        </div>
                                                                                    </>
                                                                                ) : null}
                                                                                <div class="img-list-out">
                                                                                    {!isView ? (
                                                                                        <div
                                                                                            className={`checkbox ${
                                                                                                entity === "Image" ? "img-check-bx" : ""
                                                                                            }`}
                                                                                        >
                                                                                            <label class="container-check">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    name="is_bold"
                                                                                                    checked={selectedImages.includes(imageItem.id)}
                                                                                                    onClick={() => handleSelectItem(imageItem)}
                                                                                                />
                                                                                                <span class="checkmark"></span>
                                                                                            </label>
                                                                                        </div>
                                                                                    ) : null}
                                                                                    {entity === "Image" ? (
                                                                                        <img src={imageItem.gallery_image?.image_file} />
                                                                                    ) : null}
                                                                                </div>
                                                                                <div class="dtl-out-txt">
                                                                                    {entity === "Document" ? (
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div class="icons-sec">
                                                                                                <img src="/img/file-type-standard.svg" alt="" />
                                                                                            </div>
                                                                                            <h3 className="mt-1 ml-2">{imageItem.name || "-"}</h3>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <h3>{imageItem.name || "-"}</h3>
                                                                                    )}

                                                                                    <div class={`notes-otr ${entity === "Document" ? "mt-3" : ""}`}>
                                                                                        <label>Notes</label>
                                                                                        <p class="notes-txt">{imageItem.notes || "-"}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="footer-txt">
                                                                                    <div class="ftr-dt">
                                                                                        <label>Uploaded By</label>
                                                                                        <p class="txt-ftr">{imageItem.user || "-"}</p>
                                                                                    </div>
                                                                                    <div class="ftr-dt">
                                                                                        <label>Uploaded On</label>
                                                                                        <p class="txt-ftr">
                                                                                            {imageItem.created_at
                                                                                                ? moment(imageItem.created_at).format(
                                                                                                      "MM-DD-YYYY h:mm A"
                                                                                                  )
                                                                                                : "-"}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Draggable>
                                                        ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    ))}
                                </DragDropContext>
                            ) : null}
                        </div>
                    </LoadingOverlay>
                    <div class="modal-footer">
                        <div class="footer-otr">
                            <div class="count-tr">
                                <span class="drk-bl">{isView ? `Total Selected ${entity === "Document" ? "User Document" : entity}s` : "Count"}</span>
                                <span class="bl-txt">
                                    {isView
                                        ? imageList.length
                                        : `${selectedImages?.filter(e => imageList?.some(obj => obj?.id === e))?.length} / ${imageList?.length}`}
                                </span>
                            </div>
                            {!isView ? (
                                <div class="btn-otrr">
                                    <button class="btn-img-rpt" onClick={() => saveItemsForBand()}>
                                        {`Select ${entity === "Document" ? "User Document" : entity}s for Report`}
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { smartChartReducer } = state;
    return { smartChartReducer };
};

export default connect(mapStateToProps, { ...actions })(ImageListForBandModal);
