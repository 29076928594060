// Get all clients
export const GET_ALL_CLIENT_DETAILS_REQUEST = "GET_ALL_CLIENT_DETAILS_REQUEST";
export const GET_ALL_CLIENT_DETAILS_SUCCESS = "GET_ALL_CLIENT_DETAILS_SUCCESS";
export const GET_ALL_CLIENT_DETAILS_FAILURE = "GET_ALL_CLIENT_DETAILS_FAILURE";

// Get client by id
export const GET_ALL_CLIENT_ID_REQUEST = "GET_ALL_CLIENT_ID_REQUEST";
export const GET_ALL_CLIENT_ID_SUCCESS = "GET_ALL_CLIENT_ID_SUCCESS";
export const GET_ALL_CLIENT_ID_FAILURE = "GET_ALL_CLIENT_ID_FAILURE";

// Get all sites
export const GET_ALL_METER_READINGS_REQUEST = "GET_ALL_METER_READINGS_REQUEST";
export const GET_ALL_METER_READINGS_SUCCESS = "GET_ALL_METER_READINGS_SUCCESS";
export const GET_ALL_METER_READINGS_FAILURE = "GET_ALL_METER_READINGS_FAILURE";

// //Update  Entity Param
export const UPDATE_ENERGY_ENTITY_PARAMS_SUCCESS = "UPDATE_ENERGY_ENTITY_PARAMS_SUCCESS";
export const UPDATE_ENERGY_ENTITY_PARAMS_FAILURE = "UPDATE_ENERGY_ENTITY_PARAMS_FAILURE";

// //Update Electric Entity Param
export const UPDATE_ELECTRIC_ENTITY_PARAMS_SUCCESS = "UPDATE_ELECTRIC_ENTITY_PARAMS_SUCCESS";
export const UPDATE_ELECTRIC_ENTITY_PARAMS_FAILURE = "UPDATE_ELECTRIC_ENTITY_PARAMS_FAILURE";

// Get all regions
export const GET_ALL_REGIONS_ENERGY_REQUEST = "GET_ALL_REGIONS_ENERGY_REQUEST";
export const GET_ALL_REGIONS_ENERGY_SUCCESS = "GET_ALL_REGIONS_ENERGY_SUCCESS";
export const GET_ALL_REGIONS_ENERGY_FAILURE = "GET_ALL_REGIONS_ENERGY_FAILURE";

// Get Region by Id
export const GET_REGION_BY_ID_ENERGY_REQUEST = "GET_REGION_BY_ID_ENERGY_REQUEST";
export const GET_REGION_BY_ID_ENERGY_SUCCESS = "GET_REGION_BY_ID_ENERGY_SUCCESS";
export const GET_REGION_BY_ID_ENERGY_FAILURE = "GET_REGION_BY_ID_ENERGY_FAILURE";

// Update entity Params
export const UPDATE_REGION_ENTITY_PARAMS_ENERGY_SUCCESS = "UPDATE_REGION_ENTITY_PARAMS_ENERGY_SUCCESS";
export const UPDATE_REGION_ENTITY_PARAMS_ENERGY_FAILURE = "UPDATE_REGION_ENTITY_PARAMS_ENERGY_FAILURE";


export const UPLOAD_EXCEL_REQUEST = "UPLOAD_EXCEL_REQUEST";
export const UPLOAD_EXCEL_SUCCESS = "UPLOAD_EXCEL_SUCCESS";
export const UPLOAD_EXCEL_FAILURE = "UPLOAD_EXCEL_FAILURE";
export const CLEAR_EXCEL_DATA = "CLEAR_EXCEL_DATA";

// // Add site
// export const ADD_PROJECT_REQUEST = "ADD_PROJECT_REQUEST";
// export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
// export const ADD_PROJECT_FAILURE = "ADD_PROJECT_FAILURE";

// // PARSE FCA
// export const PARSE_FCA_REQUEST = "PARSE_FCA_REQUEST";
// export const PARSE_FCA_SUCCESS = "PARSE_FCA_SUCCESS";
// export const PARSE_FCA_FAILURE = "PARSE_FCA_FAILURE";

// // Update site
// export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
// export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
// export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";

// // Delete site
// export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
// export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
// export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";

// // Ger region based on client
// export const GET_REGIONS_BASED_ON_CLIENT_REQUEST = "GET_REGIONS_BASED_ON_CLIENT_REQUEST";
// export const GET_REGIONS_BASED_ON_CLIENT_SUCCESS = "GET_REGIONS_BASED_ON_CLIENT_SUCCESS";
// export const GET_REGIONS_BASED_ON_CLIENT_FAILURE = "GET_REGIONS_BASED_ON_CLIENT_FAILURE";

// // Get all consultancy users
// export const GET_ALL_CONSULTANCY_USERS_REQUEST = "GET_ALL_CONSULTANCY_USERS_REQUEST";
// export const GET_ALL_CONSULTANCY_USERS_SUCCESS = "GET_ALL_CONSULTANCY_USERS_SUCCESS";
// export const GET_ALL_CONSULTANCY_USERS_FAILURE = "GET_ALL_CONSULTANCY_USERS_FAILURE";

// // Get all Clients
// export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
// export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
// export const GET_ALL_CLIENTS_FAILURE = "GET_ALL_CLIENTS_FAILURE";

// // Get Site by Id
// export const GET_PROJECT_BY_ID_REQUEST = "GET_PROJECT_BY_ID_REQUEST";
// export const GET_PROJECT_BY_ID_SUCCESS = "GET_PROJECT_BY_ID_SUCCESS";
// export const GET_PROJECT_BY_ID_FAILURE = "GET_PROJECT_BY_ID_FAILURE";

// // Upload Image
// export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
// export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
// export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

// // Get all Images
// export const GET_ALL_IMAGES_REQUEST = "GET_ALL_IMAGES_REQUEST";
// export const GET_ALL_IMAGES_SUCCESS = "GET_ALL_IMAGES_SUCCESS";
// export const GET_ALL_IMAGES_FAILURE = "GET_ALL_IMAGES_FAILURE";

// // Delete Images
// export const DELETE_IMAGES_REQUEST = "DELETE_IMAGES_REQUEST";
// export const DELETE_IMAGES_SUCCESS = "DELETE_IMAGES_SUCCESS";
// export const DELETE_IMAGES_FAILURE = "DELETE_IMAGES_FAILURE";

// //Get Future Capital based on Site
// export const GET_FUTURE_CAPITAL_BY_SITE_REQUEST = "GET_FUTURE_CAPITAL_BY_SITE_REQUEST";
// export const GET_FUTURE_CAPITAL_BY_SITE_SUCCESS = "GET_FUTURE_CAPITAL_BY_SITE_SUCCESS";
// export const GET_FUTURE_CAPITAL_BY_SITE_FAILURE = "GET_FUTURE_CAPITAL_BY_SITE_FAILURE";

// // Get project type building settings
// export const GET_BUILDING_TYPE_SETTINGS_DATA_REQUEST = "GET_BUILDING_TYPE_SETTINGS_DATA_REQUEST";
// export const GET_BUILDING_TYPE_SETTINGS_DATA_SUCCESS = "GET_BUILDING_TYPE_SETTINGS_DATA_SUCCESS";
// export const GET_BUILDING_TYPE_SETTINGS_DATA_FAILURE = "GET_BUILDING_TYPE_SETTINGS_DATA_FAILURE";

// // Update project type building settings
// export const UPDATE_BUILDING_TYPE_SETTINGS_REQUEST = "UPDATE_BUILDING_TYPE_SETTINGS_REQUEST";
// export const UPDATE_BUILDING_TYPE_SETTINGS_SUCCESS = "UPDATE_BUILDING_TYPE_SETTINGS_SUCCESS";
// export const UPDATE_BUILDING_TYPE_SETTINGS_FAILURE = "UPDATE_BUILDING_TYPE_SETTINGS_FAILURE";

// // Get project type trade settings
// export const GET_TRADE_SETTINGS_DATA_REQUEST = "GET_TRADE_SETTINGS_DATA_REQUEST";
// export const GET_TRADE_SETTINGS_DATA_SUCCESS = "GET_TRADE_SETTINGS_DATA_SUCCESS";
// export const GET_TRADE_SETTINGS_DATA_FAILURE = "GET_TRADE_SETTINGS_DATA_FAILURE";

// export const ADD_TRADE_REQUEST = "ADD_TRADE_REQUEST";
// export const ADD_TRADE_SUCCESS = "ADD_TRADE_SUCCESS";
// export const ADD_TRADE_FAILURE = "ADD_TRADE_FAILURE";

// export const GET_TRADE_BY_ID_REQUEST = "GET_TRADE_BY_ID_REQUEST";
// export const GET_TRADE_BY_ID_SUCCESS = "GET_TRADE_BY_ID_SUCCESS";
// export const GET_TRADE_BY_ID_FAILURE = "GET_TRADE_BY_ID_FAILURE";

// export const UPDATE_TRADE_REQUEST = "UPDATE_TRADE_REQUEST";
// export const UPDATE_TRADE_SUCCESS = "UPDATE_TRADE_SUCCESS";
// export const UPDATE_TRADE_FAILURE = "UPDATE_TRADE_FAILURE";

// export const DELETE_TRADE_REQUEST = "DELETE_TRADE_REQUEST";
// export const DELETE_TRADE_SUCCESS = "DELETE_TRADE_SUCCESS";
// export const DELETE_TRADE_FAILURE = "DELETE_TRADE_FAILURE";

// export const GET_CATEGORY_SETTINGS_DATA_REQUEST = "GET_CATEGORY_SETTINGS_DATA_REQUEST";
// export const GET_CATEGORY_SETTINGS_DATA_SUCCESS = "GET_CATEGORY_SETTINGS_DATA_SUCCESS";
// export const GET_CATEGORY_SETTINGS_DATA_FAILURE = "GET_CATEGORY_SETTINGS_DATA_FAILURE";

// export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
// export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
// export const ADD_CATEGORY_FAILURE = "ADD_CATEGORY_FAILURE";

// export const GET_CATEGORY_BY_ID_REQUEST = "GET_CATEGORY_BY_ID_REQUEST";
// export const GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS";
// export const GET_CATEGORY_BY_ID_FAILURE = "GET_CATEGORY_BY_ID_FAILURE";

// export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
// export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
// export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

// export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
// export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
// export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

// //Get Differed Maintenance based on Site
// export const GET_DIFFERED_MAINTENANCE_BY_SITE_REQUEST = "GET_DIFFERED_MAINTENANCE_BY_SITE_REQUEST";
// export const GET_DIFFERED_MAINTENANCE_BY_SITE_SUCCESS = "GET_DIFFERED_MAINTENANCE_BY_SITE_SUCCESS";
// export const GET_DIFFERED_MAINTENANCE_BY_SITE_FAILURE = "GET_DIFFERED_MAINTENANCE_BY_SITE_FAILURE";

// export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
// export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
// export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

// export const GET_SYSTEM_SETTINGS_DATA_REQUEST = "GET_SYSTEM_SETTINGS_DATA_REQUEST";
// export const GET_SYSTEM_SETTINGS_DATA_SUCCESS = "GET_SYSTEM_SETTINGS_DATA_SUCCESS";
// export const GET_SYSTEM_SETTINGS_DATA_FAILURE = "GET_SYSTEM_SETTINGS_DATA_FAILURE";

// export const ADD_SYSTEM_REQUEST = "ADD_SYSTEM_REQUEST";
// export const ADD_SYSTEM_SUCCESS = "ADD_SYSTEM_SUCCESS";
// export const ADD_SYSTEM_FAILURE = "ADD_SYSTEM_FAILURE";

// export const GET_SYSTEM_BY_ID_REQUEST = "GET_SYSTEM_BY_ID_REQUEST";
// export const GET_SYSTEM_BY_ID_SUCCESS = "GET_SYSTEM_BY_ID_SUCCESS";
// export const GET_SYSTEM_BY_ID_FAILURE = "GET_SYSTEM_BY_ID_FAILURE";

// export const UPDATE_SYSTEM_REQUEST = "UPDATE_SYSTEM_REQUEST";
// export const UPDATE_SYSTEM_SUCCESS = "UPDATE_SYSTEM_SUCCESS";
// export const UPDATE_SYSTEM_FAILURE = "UPDATE_SYSTEM_FAILURE";

// export const DELETE_SYSTEM_REQUEST = "DELETE_SYSTEM_REQUEST";
// export const DELETE_SYSTEM_SUCCESS = "DELETE_SYSTEM_SUCCESS";
// export const DELETE_SYSTEM_FAILURE = "DELETE_SYSTEM_FAILURE";

// export const GET_SUB_SYSTEM_SETTINGS_DATA_REQUEST = "GET_SUB_SYSTEM_SETTINGS_DATA_REQUEST";
// export const GET_SUB_SYSTEM_SETTINGS_DATA_SUCCESS = "GET_SUB_SYSTEM_SETTINGS_DATA_SUCCESS";
// export const GET_SUB_SYSTEM_SETTINGS_DATA_FAILURE = "GET_SUB_SYSTEM_SETTINGS_DATA_FAILURE";

// export const ADD_SUB_SYSTEM_REQUEST = "ADD_SUB_SYSTEM_REQUEST";
// export const ADD_SUB_SYSTEM_SUCCESS = "ADD_SUB_SYSTEM_SUCCESS";
// export const ADD_SUB_SYSTEM_FAILURE = "ADD_SUB_SYSTEM_FAILURE";

// export const GET_SUB_SYSTEM_BY_ID_REQUEST = "GET_SUB_SYSTEM_BY_ID_REQUEST";
// export const GET_SUB_SYSTEM_BY_ID_SUCCESS = "GET_SUB_SYSTEM_BY_ID_SUCCESS";
// export const GET_SUB_SYSTEM_BY_ID_FAILURE = "GET_SUB_SYSTEM_BY_ID_FAILURE";

// export const UPDATE_SUB_SYSTEM_REQUEST = "UPDATE_SUB_SYSTEM_REQUEST";
// export const UPDATE_SUB_SYSTEM_SUCCESS = "UPDATE_SUB_SYSTEM_SUCCESS";
// export const UPDATE_SUB_SYSTEM_FAILURE = "UPDATE_SUB_SYSTEM_FAILURE";

// export const DELETE_SUB_SYSTEM_REQUEST = "DELETE_SUB_SYSTEM_REQUEST";
// export const DELETE_SUB_SYSTEM_SUCCESS = "DELETE_SUB_SYSTEM_SUCCESS";
// export const DELETE_SUB_SYSTEM_FAILURE = "DELETE_SUB_SYSTEM_FAILURE";

// export const GET_DEPARTMENT_SETTINGS_DATA_REQUEST = "GET_DEPARTMENT_SETTINGS_DATA_REQUEST";
// export const GET_DEPARTMENT_SETTINGS_DATA_SUCCESS = "GET_DEPARTMENT_SETTINGS_DATA_SUCCESS";
// export const GET_DEPARTMENT_SETTINGS_DATA_FAILURE = "GET_DEPARTMENT_SETTINGS_DATA_FAILURE";

// export const ADD_DEPARTMENT_REQUEST = "ADD_DEPARTMENT_REQUEST";
// export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
// export const ADD_DEPARTMENT_FAILURE = "ADD_DEPARTMENT_FAILURE";

// export const GET_DEPARTMENT_BY_ID_REQUEST = "GET_DEPARTMENT_BY_ID_REQUEST";
// export const GET_DEPARTMENT_BY_ID_SUCCESS = "GET_DEPARTMENT_BY_ID_SUCCESS";
// export const GET_DEPARTMENT_BY_ID_FAILURE = "GET_DEPARTMENT_BY_ID_FAILURE";

// export const UPDATE_DEPARTMENT_REQUEST = "UPDATE_DEPARTMENT_REQUEST";
// export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
// export const UPDATE_DEPARTMENT_FAILURE = "UPDATE_DEPARTMENT_FAILURE";

// export const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST";
// export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
// export const DELETE_DEPARTMENT_FAILURE = "DELETE_DEPARTMENT_FAILURE";

// export const ADD_LIMIT_REQUEST = "ADD_LIMIT_REQUEST";
// export const ADD_LIMIT_SUCCESS = "ADD_LIMIT_SUCCESS";
// export const ADD_LIMIT_FAILURE = "ADD_LIMIT_FAILURE";

// export const GET_ADD_LIMIT_REQUEST = "GET_ADD_LIMIT_REQUEST";
// export const GET_ADD_LIMIT_SUCCESS = "GET_ADD_LIMIT_SUCCESS";
// export const GET_ADD_LIMIT_FAILURE = "GET_ADD_LIMIT_FAILURE";

// export const GET_GENERAL_BY_ID_REQUEST = "GET_GENERAL_BY_ID_REQUEST";
// export const GET_GENERAL_BY_ID_SUCCESS = "GET_GENERAL_BY_ID_SUCCESS";
// export const GET_GENERAL_BY_ID_FAILURE = "GET_GENERAL_BY_ID_FAILURE";

// export const UPDATE_GENERAL_REQUEST = "UPDATE_GENERAL_REQUEST";
// export const UPDATE_GENERAL_SUCCESS = "UPDATE_GENERAL_SUCCESS";
// export const UPDATE_GENERAL_FAILURE = "UPDATE_GENERAL_FAILURE";

// export const DELETE_GENERAL_REQUEST = "DELETE_GENERAL_REQUEST";
// export const DELETE_GENERAL_SUCCESS = "DELETE_GENERAL_SUCCESS";
// export const DELETE_GENERAL_FAILURE = "DELETE_GENERAL_FAILURE";

// export const GET_TRADE_SETTINGS_DROPDOWN_REQUEST = "GET_TRADE_SETTINGS_DROPDOWN_REQUEST";
// export const GET_TRADE_SETTINGS_DROPDOWN_SUCCESS = "GET_TRADE_SETTINGS_DROPDOWN_SUCCESS";
// export const GET_TRADE_SETTINGS_DROPDOWN_FAILURE = "GET_TRADE_SETTINGS_DROPDOWN_FAILURE";

// export const GET_SYSTEM_SETTINGS_BY_TRADE_REQUEST = "GET_SYSTEM_SETTINGS_BY_TRADE_REQUEST";
// export const GET_SYSTEM_SETTINGS_BY_TRADE_SUCCESS = "GET_SYSTEM_SETTINGS_BY_TRADE_SUCCESS";
// export const GET_SYSTEM_SETTINGS_BY_TRADE_FAILURE = "GET_SYSTEM_SETTINGS_BY_TRADE_FAILURE";

// export const GET_PROJECTS_EXPORT_REQUEST = "GET_PROJECTS_EXPORT_REQUEST";
// export const GET_PROJECTS_EXPORT_SUCCESS = "GET_PROJECTS_EXPORT_SUCCESS";
// export const GET_PROJECTS_EXPORT_FAILURE = "GET_PROJECTS_EXPORT_FAILURE";

// export const GET_EFCI_COLOR_CODE_REQUEST = "GET_EFCI_COLOR_CODE_REQUEST";
// export const GET_EFCI_COLOR_CODE_SUCCESS = "GET_EFCI_COLOR_CODE_SUCCESS";
// export const GET_EFCI_COLOR_CODE_FAILURE = "GET_EFCI_COLOR_CODE_FAILURE";

// export const ADD_EFCI_COLOR_CODE_REQUEST = "ADD_EFCI_COLOR_CODE_REQUEST";
// export const ADD_EFCI_COLOR_CODE_SUCCESS = "ADD_EFCI_COLOR_CODE_SUCCESS";
// export const ADD_EFCI_COLOR_CODE_FAILURE = "ADD_EFCI_COLOR_CODE_FAILURE";

// export const UPDATE_EFCI_COLOR_CODE_REQUEST = "UPDATE_EFCI_COLOR_CODE_REQUEST";
// export const UPDATE_EFCI_COLOR_CODE_SUCCESS = "UPDATE_EFCI_COLOR_CODE_SUCCESS";
// export const UPDATE_EFCI_COLOR_CODE_FAILURE = "UPDATE_EFCI_COLOR_CODE_FAILURE";

// export const DELETE_EFCI_COLOR_CODE_REQUEST = "DELETE_EFCI_COLOR_CODE_REQUEST";
// export const DELETE_EFCI_COLOR_CODE_SUCCESS = "DELETE_EFCI_COLOR_CODE_SUCCESS";
// export const DELETE_EFCI_COLOR_CODE_FAILURE = "DELETE_EFCI_COLOR_CODE_FAILURE";

// export const GET_ALL_PROJECT_LOG_REQUEST = "GET_ALL_PROJECT_LOG_REQUEST";
// export const GET_ALL_PROJECT_LOG_SUCCESS = "GET_ALL_PROJECT_LOG_SUCCESS";
// export const GET_ALL_PROJECT_LOG_FAILURE = "GET_ALL_PROJECT_LOG_FAILURE";

// export const RESTORE_PROJECT_LOG_REQUEST = "RESTORE_PROJECT_LOG_REQUEST";
// export const RESTORE_PROJECT_LOG_SUCCESS = "RESTORE_PROJECT_LOG_SUCCESS";
// export const RESTORE_PROJECT_LOG_FAILURE = "RESTORE_PROJECT_LOG_FAILURE";

// export const DELETE_PROJECT_LOG_REQUEST = "DELETE_PROJECT_LOG_REQUEST";
// export const DELETE_PROJECT_LOG_SUCCESS = "DELETE_PROJECT_LOG_SUCCESS";
// export const DELETE_PROJECT_LOG_FAILURE = "DELETE_PROJECT_LOG_FAILURE";

// export const GET_CHARTS_PROJECT_REQUEST = "GET_CHARTS_PROJECT_REQUEST";
// export const GET_CHARTS_PROJECT_SUCCESS = "GET_CHARTS_PROJECT_SUCCESS";
// export const GET_CHARTS_PROJECT_FAILURE = "GET_CHARTS_PROJECT_FAILURE";

// export const GET_ALL_PROJECT_IMPORT_LOG_REQUEST = "GET_ALL_PROJECT_IMPORT_LOG_REQUEST";
// export const GET_ALL_PROJECT_IMPORT_LOG_SUCCESS = "GET_ALL_PROJECT_IMPORT_LOG_SUCCESS";
// export const GET_ALL_PROJECT_IMPORT_LOG_FAILURE = "GET_ALL_PROJECT_IMPORT_LOG_FAILURE";

// export const DELETE_PROJECT_IMPORT_LOG_REQUEST = "DELETE_PROJECT_IMPORT_LOG_REQUEST";
// export const DELETE_PROJECT_IMPORT_LOG_SUCCESS = "DELETE_PROJECT_IMPORT_LOG_SUCCESS";
// export const DELETE_PROJECT_IMPORT_LOG_FAILURE = "DELETE_PROJECT_IMPORT_LOG_FAILURE";

// export const GET_IMPORT_PROJECTS_EXPORT_REQUEST = "GET_IMPORT_PROJECTS_EXPORT_REQUEST";
// export const GET_IMPORT_PROJECTS_EXPORT_SUCCESS = "GET_IMPORT_PROJECTS_EXPORT_SUCCESS";
// export const GET_IMPORT_PROJECTS_EXPORT_FAILURE = "GET_IMPORT_PROJECTS_EXPORT_FAILURE";

// export const GET_EFCI_BY_PROJECT_REQUEST = "GET_EFCI_BY_PROJECT_REQUEST";
// export const GET_EFCI_BY_PROJECT_SUCCESS = "GET_EFCI_BY_PROJECT_SUCCESS";
// export const GET_EFCI_BY_PROJECT_FAILURE = "GET_EFCI_BY_PROJECT_FAILURE";

// //CHART LOGS
// export const GET_ALL_SITE_CHART_LOG_REQUEST = "GET_ALL_SITE_CHART_LOG_REQUEST";
// export const GET_ALL_SITE_CHART_LOG_SUCCESS = "GET_ALL_SITE_CHART_LOG_SUCCESS";
// export const GET_ALL_SITE_CHART_LOG_FAILURE = "GET_ALL_SITE_CHART_LOG_FAILURE";

// export const RESTORE_SITE_CHART_LOG_REQUEST = "RESTORE_SITE_CHART_LOG_REQUEST";
// export const RESTORE_SITE_CHART_LOG_SUCCESS = "RESTORE_SITE_CHART_LOG_SUCCESS";
// export const RESTORE_SITE_CHART_LOG_FAILURE = "RESTORE_SITE_CHART_LOG_FAILURE";

// export const DELETE_SITE_CHART_LOG_REQUEST = "DELETE_SITE_CHART_LOG_REQUEST";
// export const DELETE_SITE_CHART_LOG_SUCCESS = "DELETE_SITE_CHART_LOG_SUCCESS";
// export const DELETE_SITE_CHART_LOG_FAILURE = "DELETE_SITE_CHART_LOG_FAILURE";

// export const GET_ANNUAL_EFCI_CHART_LOGS_PROJECT_REQUEST = "GET_ANNUAL_EFCI_CHART_LOGS_PROJECT_REQUEST";
// export const GET_ANNUAL_EFCI_CHART_LOGS_PROJECT_SUCCESS = "GET_ANNUAL_EFCI_CHART_LOGS_PROJECT_SUCCESS";
// export const GET_ANNUAL_EFCI_CHART_LOGS_PROJECT_FAILURE = "GET_ANNUAL_EFCI_CHART_LOGS_PROJECT_FAILURE";

// export const GET_ANNUAL_FUNDING_OPTIONS_CHART_LOGS_PROJECT_REQUEST = "GET_ANNUAL_FUNDING_OPTIONS_CHART_LOGS_PROJECT_REQUEST";
// export const GET_ANNUAL_FUNDING_OPTIONS_CHART_LOGS_PROJECT_SUCCESS = "GET_ANNUAL_FUNDING_OPTIONS_CHART_LOGS_PROJECT_SUCCESS";
// export const GET_ANNUAL_FUNDING_OPTIONS_CHART_LOGS_PROJECT_FAILURE = "GET_ANNUAL_FUNDING_OPTIONS_CHART_LOGS_PROJECT_FAILURE";

// export const RESTORE_ANNUAL_EFCI_CHART_LOGS_REQUEST = "RESTORE_ANNUAL_EFCI_CHART_LOGS_REQUEST";
// export const RESTORE_ANNUAL_EFCI_CHART_LOGS_SUCCESS = "RESTORE_ANNUAL_EFCI_CHART_LOGS_SUCCESS";
// export const RESTORE_ANNUAL_EFCI_CHART_LOGS_FAILURE = "RESTORE_ANNUAL_EFCI_CHART_LOGS_FAILURE";

// export const RESTORE_ANNUAL_FUNDING_CHART_LOGS_REQUEST = "RESTORE_ANNUAL_FUNDING_CHART_LOGS_REQUEST";
// export const RESTORE_ANNUAL_FUNDING_CHART_LOGS_SUCCESS = "RESTORE_ANNUAL_FUNDING_CHART_LOGS_SUCCESS";
// export const RESTORE_ANNUAL_FUNDING_CHART_LOGS_FAILURE = "RESTORE_ANNUAL_FUNDING_CHART_LOGS_FAILURE";

// export const GET_FUNDING_OPTIONS_CHART_LOGS_PROJECT_REQUEST = "GET_FUNDING_OPTIONS_CHART_LOGS_PROJECT_REQUEST";
// export const GET_FUNDING_OPTIONS_CHART_LOGS_PROJECT_SUCCESS = "GET_FUNDING_OPTIONS_CHART_LOGS_PROJECT_SUCCESS";
// export const GET_FUNDING_OPTIONS_CHART_LOGS_PROJECT_FAILURE = "GET_FUNDING_OPTIONS_CHART_LOGS_PROJECT_FAILURE";

// export const RESTORE_FUNDING_CHART_LOGS_REQUEST = "RESTORE_FUNDING_CHART_LOGS_REQUEST";
// export const RESTORE_FUNDING_CHART_LOGS_SUCCESS = "RESTORE_FUNDING_CHART_LOGS_SUCCESS";
// export const RESTORE_FUNDING_CHART_LOGS_FAILURE = "RESTORE_FUNDING_CHART_LOGS_FAILURE";

// export const GET_FUNDING_EFCI_CHART_LOGS_PROJECT_REQUEST = "GET_FUNDING_EFCI_CHART_LOGS_PROJECT_REQUEST";
// export const GET_FUNDING_EFCI_CHART_LOGS_PROJECT_SUCCESS = "GET_FUNDING_EFCI_CHART_LOGS_PROJECT_SUCCESS";
// export const GET_FUNDING_EFCI_CHART_LOGS_PROJECT_FAILURE = "GET_FUNDING_EFCI_CHART_LOGS_PROJECT_FAILURE";

// export const RESTORE_FUNDING_EFCI_CHART_LOGS_REQUEST = "RESTORE_FUNDING_EFCI_CHART_LOGS_REQUEST";
// export const RESTORE_FUNDING_EFCI_CHART_LOGS_SUCCESS = "RESTORE_FUNDING_EFCI_CHART_LOGS_SUCCESS";
// export const RESTORE_FUNDING_EFCI_CHART_LOGS_FAILURE = "RESTORE_FUNDING_EFCI_CHART_LOGS_FAILURE";

// export const GET_TOTAL_FUNDING_EFCI_CHART_LOGS_PROJECT_REQUEST = "GET_TOTAL_FUNDING_EFCI_CHART_LOGS_PROJECT_REQUEST";
// export const GET_TOTAL_FUNDING_EFCI_CHART_LOGS_PROJECT_SUCCESS = "GET_TOTAL_FUNDING_EFCI_CHART_LOGS_PROJECT_SUCCESS";
// export const GET_TOTAL_FUNDING_EFCI_CHART_LOGS_PROJECT_FAILURE = "GET_TOTAL_FUNDING_EFCI_CHART_LOGS_PROJECT_FAILURE";

// export const RESTORE_TOTAL_FUNDING_EFCI_CHART_LOGS_REQUEST = "RESTORE_TOTAL_FUNDING_EFCI_CHART_LOGS_REQUEST";
// export const RESTORE_TOTAL_FUNDING_EFCI_CHART_LOGS_SUCCESS = "RESTORE_TOTAL_FUNDING_EFCI_CHART_LOGS_SUCCESS";
// export const RESTORE_TOTAL_FUNDING_EFCI_CHART_LOGS_FAILURE = "RESTORE_TOTAL_FUNDING_EFCI_CHART_LOGS_FAILURE";

// export const RESTORE_CSP_CHART_LOGS_REQUEST = "RESTORE_CSP_CHART_LOGS_REQUEST";
// export const RESTORE_CSP_CHART_LOGS_SUCCESS = "RESTORE_CSP_CHART_LOGS_SUCCESS";
// export const RESTORE_CSP_CHART_LOGS_FAILURE = "RESTORE_CSP_CHART_LOGS_FAILURE";

// export const GET_CSP_CHART_LOGS_PROJECT_REQUEST = "GET_CSP_CHART_LOGS_PROJECT_REQUEST";
// export const GET_CSP_CHART_LOGS_PROJECT_SUCCESS = "GET_CSP_CHART_LOGS_PROJECT_SUCCESS";
// export const GET_CSP_CHART_LOGS_PROJECT_FAILURE = "GET_CSP_CHART_LOGS_PROJECT_FAILURE";

// export const DELETE_EFCI_CHART_LOG_REQUEST = "DELETE_EFCI_CHART_LOG_REQUEST";
// export const DELETE_EFCI_CHART_LOGS_SUCCESS = "DELETE_EFCI_CHART_LOGS_SUCCESS";
// export const DELETE_EFCI_CHART_LOGS_FAILURE = "DELETE_EFCI_CHART_LOGS_FAILURE";

// export const GET_CHART_EXPORT_REQUEST = "GET_CHART_EXPORT_REQUEST";
// export const GET_CHART_EXPORT_SUCCESS = "GET_CHART_EXPORT_SUCCESS";
// export const GET_CHART_EXPORT_FAILURE = "GET_CHART_EXPORT_FAILURE";

// // get chart efci information
// export const GET_CHART_EFCI_PROJECT_REQUEST = "GET_CHART_EFCI_PROJECT_REQUEST";
// export const GET_CHART_EFCI_PROJECT_SUCCESS = "GET_CHART_EFCI_PROJECT_SUCCESS";
// export const GET_CHART_EFCI_PROJECT_FAILURE = "GET_CHART_EFCI_PROJECT_FAILURE";

// export const UPDATE_CAPITAL_SPENDING_PLAN_CHART_PROJECT_REQUEST = "UPDATE_CAPITAL_SPENDING_PLAN_CHART_PROJECT_REQUEST";
// export const UPDATE_CAPITAL_SPENDING_PLAN_CHART_PROJECT_SUCCESS = "UPDATE_CAPITAL_SPENDING_PLAN_CHART_PROJECT_SUCCESS";
// export const UPDATE_CAPITAL_SPENDING_PLAN_CHART_PROJECT_FAILURE = "UPDATE_CAPITAL_SPENDING_PLAN_CHART_PROJECT_FAILURE";

// export const UPDATE_FUNDING_OPTION_CHART_PROJECT_REQUEST = "UPDATE_FUNDING_OPTION_CHART_PROJECT_REQUEST";
// export const UPDATE_FUNDING_OPTION_CHART_PROJECT_SUCCESS = "UPDATE_FUNDING_OPTION_CHART_PROJECT_SUCCESS";
// export const UPDATE_FUNDING_OPTION_CHART_PROJECT_FAILURE = "UPDATE_FUNDING_OPTION_CHART_PROJECT_FAILURE";

// export const UPDATE_ANNUAL_EFCI_CHART_PROJECT_REQUEST = "UPDATE_ANNUAL_EFCI_CHART_PROJECT_REQUEST";
// export const UPDATE_ANNUAL_EFCI_CHART_PROJECT_SUCCESS = "UPDATE_ANNUAL_EFCI_CHART_PROJECT_SUCCESS";
// export const UPDATE_ANNUAL_EFCI_CHART_PROJECT_FAILURE = "UPDATE_ANNUAL_EFCI_CHART_PROJECT_FAILURE";

// export const UPDATE_ANNUAL_FUNDING_CHART_PROJECT_REQUEST = "UPDATE_ANNUAL_FUNDING_CHART_PROJECT_REQUEST";
// export const UPDATE_ANNUAL_FUNDING_CHART_PROJECT_SUCCESS = "UPDATE_ANNUAL_FUNDING_CHART_PROJECT_SUCCESS";
// export const UPDATE_ANNUAL_FUNDING_CHART_PROJECT_FAILURE = "UPDATE_ANNUAL_FUNDING_CHART_PROJECT_FAILURE";

// export const UPDATE_FUNDING_EFCI_CHART_PROJECT_REQUEST = "UPDATE_FUNDING_EFCI_CHART_PROJECT_REQUEST";
// export const UPDATE_FUNDING_EFCI_CHART_PROJECT_SUCCESS = "UPDATE_FUNDING_EFCI_CHART_PROJECT_SUCCESS";
// export const UPDATE_FUNDING_EFCI_CHART_PROJECT_FAILURE = "UPDATE_FUNDING_EFCI_CHAR_PROJECTT_FAILURE";

// export const HIDE_FUNDING_OPTION_CHART_PROJECT_REQUEST = "HIDE_FUNDING_OPTION_CHART_PROJECT_REQUEST";
// export const HIDE_FUNDING_OPTION_CHART_PROJECT_SUCCESS = "HIDE_FUNDING_OPTION_CHART_PROJECT_SUCCESS";
// export const HIDE_FUNDING_OPTION_CHART_PROJECT_FAILURE = "HIDE_FUNDING_OPTION_CHART_PROJECT_FAILURE";

// export const GET_SITE_EXPORT_REQUEST = "GET_SITE_EXPORT_REQUEST";
// export const GET_SITE_EXPORT_SUCCESS = "GET_SITE_EXPORT_SUCCESS";
// export const GET_SITE_EXPORT_FAILURE = "GET_SITE_EXPORT_FAILURE";

// export const ADD_EFCI_ACTIVE_TAB_PROJECT_REQUEST = "ADD_EFCI_ACTIVE_TAB_PROJECT_REQUEST";
// export const ADD_EFCI_ACTIVE_TAB_PROJECT_SUCCESS = "ADD_EFCI_ACTIVE_TAB_PROJECT_SUCCESS";
// export const ADD_EFCI_ACTIVE_TAB_PROJECT_FAILURE = "ADD_EFCI_ACTIVE_TAB_PROJECT_FAILURE";

// export const SAVE_EFCI_PROJECT_REQUEST = "SAVE_EFCI_PROJECT_REQUEST";
// export const SAVE_EFCI_PROJECT_SUCCESS = "SAVE_EFCI_PROJECT_SUCCESS";
// export const SAVE_EFCI_PROJECT_FAILURE = "SAVE_EFCI_PROJECT_FAILURE";

// export const LOAD_EFCI_PROJECT_REQUEST = "LOAD_EFCI_PROJECT_REQUEST";
// export const LOAD_EFCI_PROJECT_SUCCESS = "LOAD_EFCI_PROJECT_SUCCESS";
// export const LOAD_EFCI_PROJECT_FAILURE = "LOAD_EFCI_PROJECT_FAILURE";

// export const UPDATE_PROJECT_CSP_SUMMARY_REQUEST = "UPDATE_PROJECT_CSP_SUMMARY_REQUEST";
// export const UPDATE_PROJECT_CSP_SUMMARY_SUCCESS = "UPDATE_PROJECT_CSP_SUMMARY_SUCCESS";
// export const UPDATE_PROJECT_CSP_SUMMARY_FAILURE = "UPDATE_PROJECT_CSP_SUMMARY_FAILURE";

// export const UPDATE_PROJECT_ANNUAL_EFCI_REQUEST = "UPDATE_PROJECT_ANNUAL_EFCI_REQUEST";
// export const UPDATE_PROJECT_ANNUAL_EFCI_SUCCESS = "UPDATE_PROJECT_ANNUAL_EFCI_SUCCESS";
// export const UPDATE_PROJECT_ANNUAL_EFCI_FAILURE = "UPDATE_PROJECT_ANNUAL_EFCI_FAILURE";

// export const UPDATE_PROJECT_ANNUAL_FUNDING_REQUEST = "UPDATE_PROJECT_ANNUAL_FUNDING_REQUEST";
// export const UPDATE_PROJECT_ANNUAL_FUNDING_SUCCESS = "UPDATE_PROJECT_ANNUAL_FUNDING_SUCCESS";
// export const UPDATE_PROJECT_ANNUAL_FUNDING_FAILURE = "UPDATE_PROJECT_ANNUAL_FUNDING_FAILURE";

// export const UPDATE_PROJECT_FUNDING_EFCI_REQUEST = "UPDATE_PROJECT_FUNDING_EFCI_REQUEST";
// export const UPDATE_PROJECT_FUNDING_EFCI_SUCCESS = "UPDATE_PROJECT_ANNUAL_FUNDING_SUCCESS";
// export const UPDATE_PROJECT_FUNDING_EFCI_FAILURE = "UPDATE_PROJECT_ANNUAL_FUNDING_FAILURE";

// export const UPDATE_PROJECT_FUNDING_COST_REQUEST = "UPDATE_PROJECT_FUNDING_COST_REQUEST";
// export const UPDATE_PROJECT_FUNDING_COST_SUCCESS = "UPDATE_PROJECT_FUNDING_COST_SUCCESS";
// export const UPDATE_PROJECT_FUNDING_COST_FAILURE = "UPDATE_PROJECT_FUNDING_COST_FAILURE";

// export const SAVE_CHART_PROJECT_REQUEST = "SAVE_CHART_PROJECT_REQUEST";
// export const SAVE_CHART_PROJECT_SUCCESS = "SAVE_CHART_PROJECT_SUCCESS";
// export const SAVE_CHART_PROJECT_FAILURE = "SAVE_CHART_PROJECT_FAILURE";

// //efci log
// export const GET_PROJECT_CSP_SUMMARY_LOG_REQUEST = "GET_PROJECT_CSP_SUMMARY_LOG_REQUEST";
// export const GET_PROJECT_CSP_SUMMARY_LOG_SUCCESS = "GET_PROJECT_CSP_SUMMARY_LOG_SUCCESS";
// export const GET_PROJECT_CSP_SUMMARY_LOG_FAILURE = "GET_PROJECT_CSP_SUMMARY_LOG_FAILURE";

// export const GET_PROJECT_ANNUAL_EFCI_LOG_REQUEST = "GET_PROJECT_ANNUAL_EFCI_LOG_REQUEST";
// export const GET_PROJECT_ANNUAL_EFCI_LOG_SUCCESS = "GET_PROJECT_ANNUAL_EFCI_LOG_SUCCESS";
// export const GET_PROJECT_ANNUAL_EFCI_LOG_FAILURE = "GET_PROJECT_ANNUAL_EFCI_LOG_FAILURE";

// export const GET_PROJECT_ANNUAL_FUNDING_LOG_REQUEST = "GET_PROJECT_ANNUAL_FUNDING_LOG_REQUEST";
// export const GET_PROJECT_ANNUAL_FUNDING_LOG_SUCCESS = "GET_PROJECT_ANNUAL_FUNDING_LOG_SUCCESS";
// export const GET_PROJECT_ANNUAL_FUNDING_LOG_FAILURE = "GET_PROJECT_ANNUAL_FUNDING_LOG_FAILURE";

// export const GET_PROJECT_FUNDING_EFCI_LOG_REQUEST = "GET_PROJECT_FUNDING_EFCI_LOG_REQUEST";
// export const GET_PROJECT_FUNDING_EFCI_LOG_SUCCESS = "GET_PROJECT_ANNUAL_FUNDING_LOG_SUCCESS";
// export const GET_PROJECT_FUNDING_EFCI_LOG_FAILURE = "GET_PROJECT_ANNUAL_FUNDING_LOG_FAIURE";

// export const GET_PROJECT_FUNDING_COST_LOG_REQUEST = "GET_PROJECT_FUNDING_COST_LOG_REQUEST";
// export const GET_PROJECT_FUNDING_COST_LOG_SUCCESS = "GET_PROJECT_FUNDING_COST_LOG_SUCCESS";
// export const GET_PROJECT_FUNDING_COST_LOG_FAILURE = "GET_PROJECT_FUNDING_COST_LOG_FAILURE";

// export const GET_ALL_CLIENT_USERS_REQUEST = "GET_ALL_CLIENT_USERS_REQUEST";
// export const GET_ALL_CLIENT_USERS_SUCCESS = "GET_ALL_CLIENT_USERS_SUCCESS";
// export const GET_ALL_CLIENT_USERS_FAILURE = "GET_ALL_CLIENT_USERS_FAILURE";

// export const LOCK_PROJECT_REQUEST = "LOCK_PROJECT_REQUEST";
// export const LOCK_PROJECT_SUCCESS = "LOCK_PROJECT_SUCCESS";
// export const LOCK_PROJECT_FAILURE = "LOCK_PROJECT_FAILURE";

// export const GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST = "GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST";
// export const GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS = "GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS";
// export const GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE = "GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE";

// export const HIDE_FUNDING_OPTION_CHART_REQUEST = "HIDE_FUNDING_OPTION_CHART_REQUEST";
// export const HIDE_FUNDING_OPTION_CHART_SUCCESS = "HIDE_FUNDING_OPTION_CHART_SUCCESS";
// export const HIDE_FUNDING_OPTION_CHART_FAILURE = "HIDE_FUNDING_OPTION_CHART_FAILURE";

// export const HIDE_FUNDING_OPTION_REQUEST = "HIDE_FUNDING_OPTION_REQUEST";
// export const HIDE_FUNDING_OPTION_SUCCESS = "HIDE_FUNDING_OPTION_SUCCESS";
// export const HIDE_FUNDING_OPTION_FAILURE = "HIDE_FUNDING_OPTION_FAILURE";

// export const GET_COLOR_CODE_LOG_REQUEST = "GET_COLOR_CODE_LOG_REQUEST";
// export const GET_COLOR_CODE_LOG_SUCCESS = "GET_COLOR_CODE_LOG_SUCCESS";
// export const GET_COLOR_CODE_LOG_FAILURE = "GET_COLOR_CODE_LOG_FAILURE";

// export const ADD_USER_ACTIVITY_LOG_REQUEST = "ADD_USER_ACTIVITY_LOG_REQUEST";
// export const ADD_USER_ACTIVITY_LOG_SUCCESS = "ADD_USER_ACTIVITY_LOG_SUCCESS";
// export const ADD_USER_ACTIVITY_LOG_FAILURE = "ADD_USER_ACTIVITY_LOG_FAILURE";

// // copying report template to project (python)
// export const COPY_REPORT_TEMPLATE_REQUEST = "COPY_REPORT_TEMPLATE_REQUEST";
// export const COPY_REPORT_TEMPLATE_SUCCESS = "COPY_REPORT_TEMPLATE_SUCCESS";
// export const COPY_REPORT_TEMPLATE_FAILURE = "COPY_REPORT_TEMPLATE_FAILURE";

// export const GET_MISC_SETTINGS_REQUEST = "GET_MISC_SETTINGS_REQUEST";
// export const GET_MISC_SETTINGS_SUCCESS = "GET_MISC_SETTINGS_SUCCESS";
// export const GET_MISC_SETTINGS_FAILURE = "GET_MISC_SETTINGS_FAILURE";

// export const UPDATE_MISC_SETTINGS_REQUEST = "UPDATE_MISC_SETTINGS_REQUEST";
// export const UPDATE_MISC_SETTINGS_SUCCESS = "UPDATE_MISC_SETTINGS_SUCCESS";
// export const UPDATE_MISC_SETTINGS_FAILURE = "UPDATE_MISC_SETTINGS_FAILURE";

// export const UPDATE_DISPLAY_ORDER_REQUEST = "UPDATE_DISPLAY_ORDER_REQUEST";
// export const UPDATE_DISPLAY_ORDER_SUCCESS = "UPDATE_DISPLAY_ORDER_SUCCESS";
// export const UPDATE_DISPLAY_ORDER_FAILURE = "UPDATE_DISPLAY_ORDER_FAILURE";

// export const INITIALIZE_SPECIAL_REPORT_REQUEST = "INITIALIZE_SPECIAL_REPORT_REQUEST";
// export const INITIALIZE_SPECIAL_REPORT_SUCCESS = "INITIALIZE_SPECIAL_REPORT_SUCCESS";
// export const INITIALIZE_SPECIAL_REPORT_FAILURE = "INITIALIZE_SPECIAL_REPORT_FAILURE";
