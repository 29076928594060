export const GET_DEPARTMENT_SETTINGS_DATA_REQUEST = "GET_DEPARTMENT_SETTINGS_DATA_REQUEST";
export const GET_DEPARTMENT_SETTINGS_DATA_SUCCESS = "GET_DEPARTMENT_SETTINGS_DATA_SUCCESS";
export const GET_DEPARTMENT_SETTINGS_DATA_FAILURE = "GET_DEPARTMENT_SETTINGS_DATA_FAILURE";

export const ADD_DEPARTMENT_REQUEST = "ADD_DEPARTMENT_REQUEST";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_FAILURE = "ADD_DEPARTMENT_FAILURE";

export const GET_DEPARTMENT_BY_ID_REQUEST = "GET_DEPARTMENT_BY_ID_REQUEST";
export const GET_DEPARTMENT_BY_ID_SUCCESS = "GET_DEPARTMENT_BY_ID_SUCCESS";
export const GET_DEPARTMENT_BY_ID_FAILURE = "GET_DEPARTMENT_BY_ID_FAILURE";

export const UPDATE_DEPARTMENT_REQUEST = "UPDATE_DEPARTMENT_REQUEST";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILURE = "UPDATE_DEPARTMENT_FAILURE";

export const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILURE = "DELETE_DEPARTMENT_FAILURE";

// Update Floor Entity Param
export const UPDATE_DEPARTMENT_ENTITY_PARAMS_SUCCESS = "UPDATE_DEPARTMENT_ENTITY_PARAMS_SUCCESS";
export const UPDATE_DEPARTMENT_ENTITY_PARAMS_FAILURE = "UPDATE_DEPARTMENT_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_DEPARTMENT_LOG_REQUEST = "GET_ALL_DEPARTMENT_LOG_REQUEST";
export const GET_ALL_DEPARTMENT_LOG_SUCCESS = "GET_ALL_DEPARTMENT_LOG_SUCCESS";
export const GET_ALL_DEPARTMENT_LOG_FAILURE = "GET_ALL_DEPARTMENT_LOG_FAILURE";

export const RESTORE_SETTINGS_LOG_REQUEST = "RESTORE_SETTINGS_LOG_REQUEST";
export const RESTORE_SETTINGS_LOG_SUCCESS = "RESTORE_SETTINGS_LOG_SUCCESS";
export const RESTORE_SETTINGS_LOG_FAILURE = "RESTORE_SETTINGS_LOG_FAILURE";

export const DELETE_SETTINGS_LOG_REQUEST = "DELETE_SETTINGS_LOG_REQUEST";
export const DELETE_SETTINGS_LOG_SUCCESS = "DELETE_SETTINGS_LOG_SUCCESS";
export const DELETE_SETTINGS_LOG_FAILURE = "DELETE_SETTINGS_LOG_FAILURE";

export const GET_DEPARTMENT_EXPORT_REQUEST = "GET_DEPARTMENT_EXPORT_REQUEST";
export const GET_DEPARTMENT_EXPORT_SUCCESS = "GET_DEPARTMENT_EXPORT_SUCCESS";
export const GET_DEPARTMENT_EXPORT_FAILURE = "GET_DEPARTMENT_EXPORT_FAILURE";
