export const AssetSettingsEntities = {
    status: {
        name: "Asset Status",
        key: "asset_statuses",
        responseKey: "asset_statuses",
        apiBodyParam: "asset_status",
        permissionKey: "asset_status",
        tableConfig: {
            keys: ["name", "client", "description", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "asset_statuses.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_statuses",
                    commonSearchKey: "asset_statuses",
                    commonSearchObjectKey: "name"
                },
                client: {
                    isVisible: true,
                    label: "Client",
                    searchKey: "clients.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "client",
                    commonSearchKey: "clients",
                    commonSearchObjectKey: "name"
                },
                description: {
                    isVisible: true,
                    label: "Description",
                    class: "",
                    searchKey: "description",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_statuses",
                    commonSearchKey: "asset_statuses",
                    commonSearchObjectKey: "description"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "asset_statuses.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_statuses",
                    commonSearchKey: "asset_statuses",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "asset_statuses.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_statuses",
                    commonSearchKey: "asset_statuses",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    type: {
        name: "Asset Type",
        key: "asset_types",
        responseKey: "asset_types",
        apiBodyParam: "asset_type",
        permissionKey: "asset_types",
        tableConfig: {
            keys: ["name", "client", "description", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "asset_types.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_types",
                    commonSearchKey: "asset_types",
                    commonSearchObjectKey: "name"
                },
                client: {
                    isVisible: true,
                    label: "Client",
                    searchKey: "clients.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "client",
                    commonSearchKey: "clients",
                    commonSearchObjectKey: "name"
                },
                description: {
                    isVisible: true,
                    label: "Description",
                    class: "",
                    searchKey: "description",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_types",
                    commonSearchKey: "asset_types",
                    commonSearchObjectKey: "description"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "asset_types.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_types",
                    commonSearchKey: "asset_types",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "asset_types.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_types",
                    commonSearchKey: "asset_types",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    condition: {
        name: "Asset Condition",
        key: "client_asset_conditions",
        responseKey: "asset_conditions",
        apiBodyParam: "client_asset_condition",
        permissionKey: "asset_conditions",
        tableConfig: {
            keys: ["name", "client", "description", "color_code", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "client_asset_conditions.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "client_asset_conditions",
                    commonSearchKey: "client_asset_conditions",
                    commonSearchObjectKey: "name"
                },
                client: {
                    isVisible: true,
                    label: "Client",
                    searchKey: "clients.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "client",
                    commonSearchKey: "clients",
                    commonSearchObjectKey: "name"
                },
                description: {
                    isVisible: true,
                    label: "Description",
                    class: "",
                    searchKey: "description",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "",
                    commonSearchKey: "",
                    commonSearchObjectKey: "",
                    isTextArea: true
                },
                color_code: {
                    isVisible: false,
                    label: "Color",
                    class: "",
                    searchKey: "",
                    type: "string",
                    hasWildCardSearch: false,
                    hasCommonSearch: false,
                    getListTable: "",
                    commonSearchKey: "",
                    commonSearchObjectKey: ""
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "client_asset_conditions.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "client_asset_conditions",
                    commonSearchKey: "client_asset_conditions",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "client_asset_conditions.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "client_asset_conditions",
                    commonSearchKey: "client_asset_conditions",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    manufacturer: {
        name: "Asset Manufacturer",
        key: "manufacturers",
        responseKey: "asset_manufactures",
        apiBodyParam: "manufacturers",
        permissionKey: "manufacturers",
        tableConfig: {
            keys: ["name", "description", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "manufacturers",
                    commonSearchKey: "manufacturers",
                    commonSearchObjectKey: "name"
                },
                description: {
                    isVisible: true,
                    label: "Description",
                    class: "",
                    searchKey: "description",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "manufacturers",
                    commonSearchKey: "manufacturers",
                    commonSearchObjectKey: "description"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "manufacturers",
                    commonSearchKey: "manufacturers",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "manufacturers",
                    commonSearchKey: "manufacturers",
                    commonSearchObjectKey: "asset_updated_at"
                }
            }
        }
    },
    pie_chart: {
        name: "Asset Pie Chart",
        key: "asset_chart_colors",
        responseKey: "chart_colors",
        apiBodyParam: "asset_chart_colors",
        permissionKey: "asset_chart_colors",
        tableConfig: {
            keys: ["client", "expired", "next_year", "two_years", "three_to_five", "six_to_ten", "ten_plus", "unknown"],
            config: {
                client: {
                    isVisible: true,
                    label: "Client",
                    class: "width-200px",
                    searchKey: "asset_chart_colors.client",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_chart_colors",
                    commonSearchKey: "asset_chart_colors",
                    commonSearchObjectKey: "name"
                },
                next_year: {
                    isVisible: true,
                    label: "Next Year",
                    class: "width-100px",
                    searchKey: "asset_chart_colors.next_year",
                    type: "string",
                    hasWildCardSearch: false,
                    hasCommonSearch: false,
                    getListTable: "asset_chart_colors",
                    commonSearchKey: "asset_chart_colors",
                    commonSearchObjectKey: "next_year"
                },
                two_years: {
                    isVisible: true,
                    label: "2 Years",
                    class: "width-100px",
                    searchKey: "asset_chart_colors.two_years",
                    type: "string",
                    hasWildCardSearch: false,
                    hasCommonSearch: false,
                    getListTable: "asset_chart_colors",
                    commonSearchKey: "asset_chart_colors",
                    commonSearchObjectKey: "two_years"
                },
                three_to_five: {
                    isVisible: true,
                    label: "3-5 Years",
                    class: "width-100px",
                    searchKey: "asset_chart_colors.three_to_five",
                    type: "string",
                    hasWildCardSearch: false,
                    hasCommonSearch: false,
                    getListTable: "asset_chart_colors",
                    commonSearchKey: "asset_chart_colors",
                    commonSearchObjectKey: "three_to_five"
                },
                six_to_ten: {
                    isVisible: true,
                    label: "6-10 Years",
                    class: "width-100px",
                    searchKey: "asset_chart_colors.six_to_ten",
                    type: "string",
                    hasWildCardSearch: false,
                    hasCommonSearch: false,
                    getListTable: "asset_chart_colors",
                    commonSearchKey: "asset_chart_colors",
                    commonSearchObjectKey: "six_to_ten"
                },
                ten_plus: {
                    isVisible: true,
                    label: "10+ Years",
                    class: "width-100px",
                    searchKey: "asset_chart_colors.ten_plus",
                    type: "string",
                    hasWildCardSearch: false,
                    hasCommonSearch: false,
                    getListTable: "asset_chart_colors",
                    commonSearchKey: "asset_chart_colors",
                    commonSearchObjectKey: "ten_plus"
                },
                unknown: {
                    isVisible: true,
                    label: "Unknown",
                    class: "width-100px",
                    searchKey: "asset_chart_colors.unknown",
                    type: "string",
                    hasWildCardSearch: false,
                    hasCommonSearch: false,
                    getListTable: "asset_chart_colors",
                    commonSearchKey: "asset_chart_colors",
                    commonSearchObjectKey: "unknown"
                },

                expired: {
                    isVisible: true,
                    label: "Expired",
                    class: "width-100px",
                    searchKey: "asset_chart_colors.expired",
                    type: "string",
                    hasWildCardSearch: false,
                    hasCommonSearch: false,
                    getListTable: "asset_chart_colors",
                    commonSearchKey: "asset_chart_colors",
                    commonSearchObjectKey: "expired"
                }
                // color_code: {
                //     isVisible: true,
                //     label: "Color",
                //     class: "",
                //     searchKey: "",
                //     type: "string",
                //     hasWildCardSearch: false,
                //     hasCommonSearch: false,
                //     getListTable: "",
                //     commonSearchKey: "",
                //     commonSearchObjectKey: ""
                // }

                // description: {
                //     isVisible: true,
                //     label: "Description",
                //     class: "",
                //     searchKey: "description",
                //     type: "string",
                //     hasWildCardSearch: true,
                //     hasCommonSearch: false,
                //     getListTable: "client_asset_conditions",
                //     commonSearchKey: "client_asset_conditions",
                //     commonSearchObjectKey: "description"
                // },
                // color_code: {
                //     isVisible: false,
                //     label: "Color",
                //     class: "",
                //     searchKey: "",
                //     type: "string",
                //     hasWildCardSearch: false,
                //     hasCommonSearch: false,
                //     getListTable: "",
                //     commonSearchKey: "",
                //     commonSearchObjectKey: ""
                // },
            }
        }
    },
    mainCategory: {
        name: "Main Category",
        key: "main_categories",
        responseKey: "main_categories",
        apiBodyParam: "main_category",
        permissionKey: "main_categories",
        tableConfig: {
            keys: ["name", "client", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "main_categories.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "main_categories",
                    commonSearchKey: "main_categories",
                    commonSearchObjectKey: "name"
                },
                client: {
                    isVisible: true,
                    label: "Client",
                    searchKey: "clients.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "client",
                    commonSearchKey: "clients",
                    commonSearchObjectKey: "name"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "main_categories.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "main_categories",
                    commonSearchKey: "main_categories",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "main_categories.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "main_categories",
                    commonSearchKey: "main_categories",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    subCategory1: {
        name: "Sub Category 1",
        key: "sub_category_1",
        responseKey: "sub_category_1s",
        apiBodyParam: "sub_category_1",
        permissionKey: "sub_category_1",
        tableConfig: {
            keys: ["name", "client", "main_category", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "sub_category_1.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "sub_category_1",
                    commonSearchKey: "sub_category_1",
                    commonSearchObjectKey: "name"
                },
                client: {
                    isVisible: true,
                    label: "Client",
                    searchKey: "clients.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "client",
                    commonSearchKey: "clients",
                    commonSearchObjectKey: "name"
                },
                main_category: {
                    isVisible: true,
                    label: "Main Category",
                    class: "",
                    searchKey: "main_categories.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "main_categories",
                    commonSearchKey: "main_categories",
                    commonSearchObjectKey: "name"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "sub_category_1.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "sub_category_1",
                    commonSearchKey: "sub_category_1",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "sub_category_1.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "sub_category_1",
                    commonSearchKey: "sub_category_1",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    subCategory2: {
        name: "Sub Category 2",
        key: "sub_category_2",
        responseKey: "sub_category_2s",
        apiBodyParam: "sub_category_2",
        permissionKey: "sub_category_2",
        tableConfig: {
            keys: ["name", "client", "main_category", "sub_category_1", "subcategory2_description", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "sub_category_2.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "sub_category_2",
                    commonSearchKey: "sub_category_2",
                    commonSearchObjectKey: "name"
                },
                client: {
                    isVisible: true,
                    label: "Client",
                    searchKey: "clients.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "client",
                    commonSearchKey: "clients",
                    commonSearchObjectKey: "name"
                },
                main_category: {
                    isVisible: true,
                    label: "Main Category",
                    class: "",
                    searchKey: "main_categories.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "main_categories",
                    commonSearchKey: "main_categories",
                    commonSearchObjectKey: "name"
                },
                sub_category_1: {
                    isVisible: true,
                    label: "Sub Category 1",
                    class: "",
                    searchKey: "sub_category_1.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "sub_category_1",
                    commonSearchKey: "sub_category_1",
                    commonSearchObjectKey: "name"
                },
                subcategory2_description: {
                    isVisible: true,
                    label: "Sub Category 2 Description",
                    class: "",
                    searchKey: "subcategory2_description",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "",
                    commonSearchKey: "",
                    commonSearchObjectKey: ""
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "sub_category_2.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "sub_category_2",
                    commonSearchKey: "sub_category_2",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "sub_category_2.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "sub_category_2",
                    commonSearchKey: "sub_category_2",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    subCategory3: {
        name: "Sub Category 3",
        key: "sub_category_3",
        responseKey: "sub_category_3s",
        apiBodyParam: "sub_category_3",
        permissionKey: "sub_category_3",
        tableConfig: {
            keys: ["name", "client", "main_category", "sub_category_1", "sub_category_2", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "sub_category_3.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "sub_category_3",
                    commonSearchKey: "sub_category_3",
                    commonSearchObjectKey: "name"
                },
                client: {
                    isVisible: true,
                    label: "Client",
                    searchKey: "clients.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "client",
                    commonSearchKey: "clients",
                    commonSearchObjectKey: "name"
                },
                main_category: {
                    isVisible: true,
                    label: "Main Category",
                    class: "",
                    searchKey: "main_categories.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "main_categories",
                    commonSearchKey: "main_categories",
                    commonSearchObjectKey: "name"
                },
                sub_category_1: {
                    isVisible: true,
                    label: "Sub Category 1",
                    class: "",
                    searchKey: "sub_category_1.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "sub_category_1",
                    commonSearchKey: "sub_category_1",
                    commonSearchObjectKey: "name"
                },
                sub_category_2: {
                    isVisible: true,
                    label: "Sub Category 2",
                    class: "",
                    searchKey: "sub_category_2.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "sub_category_2",
                    commonSearchKey: "sub_category_2",
                    commonSearchObjectKey: "name"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "sub_category_3.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "sub_category_3",
                    commonSearchKey: "sub_category_3",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "sub_category_3.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "sub_category_3",
                    commonSearchKey: "sub_category_3",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    level1: {
        name: "Uniformat Level 1",
        key: "uniformat_level_1s",
        responseKey: "uniformat_level_1s",
        apiBodyParam: "uniformat_level_1",
        permissionKey: "uniformat_level_1",
        tableConfig: {
            keys: ["name", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "uniformat_level_1s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_1s",
                    commonSearchKey: "uniformat_level_1s",
                    commonSearchObjectKey: "name"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "uniformat_level_1s.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_1s",
                    commonSearchKey: "uniformat_level_1s",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "uniformat_level_1s.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_1s",
                    commonSearchKey: "uniformat_level_1s",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    level2: {
        name: "Uniformat level 2",
        key: "uniformat_level_2s",
        responseKey: "uniformat_level_2s",
        apiBodyParam: "uniformat_level_2",
        permissionKey: "uniformat_level_2",
        tableConfig: {
            keys: ["name", "uniformat_level_1", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "uniformat_level_2s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_2s",
                    commonSearchKey: "uniformat_level_2s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_1: {
                    isVisible: true,
                    label: "Uniformat level 1",
                    class: "",
                    searchKey: "uniformat_level_1s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_1s",
                    commonSearchKey: "uniformat_level_1s",
                    commonSearchObjectKey: "name"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "uniformat_level_2s.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_2s",
                    commonSearchKey: "uniformat_level_2s",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "uniformat_level_2s.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_2s",
                    commonSearchKey: "uniformat_level_2s",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    level3: {
        name: "Uniformat level 3",
        key: "uniformat_level_3s",
        responseKey: "uniformat_level_3s",
        apiBodyParam: "uniformat_level_3",
        permissionKey: "uniformat_level_3",
        tableConfig: {
            keys: ["name", "uniformat_level_1", "uniformat_level_2", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "uniformat_level_3s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_3s",
                    commonSearchKey: "uniformat_level_3s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_1: {
                    isVisible: true,
                    label: "Uniformat level 1",
                    class: "",
                    searchKey: "uniformat_level_1s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_1s",
                    commonSearchKey: "uniformat_level_1s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_2: {
                    isVisible: true,
                    label: "Uniformat level 2",
                    class: "",
                    searchKey: "uniformat_level_2s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_2s",
                    commonSearchKey: "uniformat_level_2s",
                    commonSearchObjectKey: "name"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "uniformat_level_3s.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_3s",
                    commonSearchKey: "uniformat_level_3s",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "uniformat_level_3s.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_3s",
                    commonSearchKey: "uniformat_level_3s",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    level4: {
        name: "Uniformat level 4",
        key: "uniformat_level_4s",
        responseKey: "uniformat_level_4s",
        apiBodyParam: "uniformat_level_4",
        permissionKey: "uniformat_level_4",
        tableConfig: {
            keys: ["name", "uniformat_level_1", "uniformat_level_2", "uniformat_level_3", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "uniformat_level_4s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_4s",
                    commonSearchKey: "uniformat_level_4s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_1: {
                    isVisible: true,
                    label: "Uniformat level 1",
                    class: "",
                    searchKey: "uniformat_level_1s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_1s",
                    commonSearchKey: "uniformat_level_1s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_2: {
                    isVisible: true,
                    label: "Uniformat level 2",
                    class: "",
                    searchKey: "uniformat_level_2s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_2s",
                    commonSearchKey: "uniformat_level_2s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_3: {
                    isVisible: true,
                    label: "Uniformat level 3",
                    class: "",
                    searchKey: "uniformat_level_3s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_3s",
                    commonSearchKey: "uniformat_level_3s",
                    commonSearchObjectKey: "name"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "uniformat_level_4s.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_4s",
                    commonSearchKey: "uniformat_level_4s",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "uniformat_level_4s.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_4s",
                    commonSearchKey: "uniformat_level_4s",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    level5: {
        name: "Uniformat level 5",
        key: "uniformat_level_5s",
        responseKey: "uniformat_level_5s",
        apiBodyParam: "uniformat_level_5",
        permissionKey: "uniformat_level_5",
        tableConfig: {
            keys: ["name", "uniformat_level_1", "uniformat_level_2", "uniformat_level_3", "uniformat_level_4", "created_at", "updated_at"],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "uniformat_level_5s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_5s",
                    commonSearchKey: "uniformat_level_5s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_1: {
                    isVisible: true,
                    label: "Uniformat level 1",
                    class: "",
                    searchKey: "uniformat_level_1s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_1s",
                    commonSearchKey: "uniformat_level_1s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_2: {
                    isVisible: true,
                    label: "Uniformat level 2",
                    class: "",
                    searchKey: "uniformat_level_2s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_2s",
                    commonSearchKey: "uniformat_level_2s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_3: {
                    isVisible: true,
                    label: "Uniformat level 3",
                    class: "",
                    searchKey: "uniformat_level_3s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_3s",
                    commonSearchKey: "uniformat_level_3s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_4: {
                    isVisible: true,
                    label: "Uniformat level 4",
                    class: "",
                    searchKey: "uniformat_level_4s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_4s",
                    commonSearchKey: "uniformat_level_4s",
                    commonSearchObjectKey: "name"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "uniformat_level_5s.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_5s",
                    commonSearchKey: "uniformat_level_5s",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "uniformat_level_5s.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_5s",
                    commonSearchKey: "uniformat_level_5s",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    level6: {
        name: "Uniformat level 6",
        key: "uniformat_level_6s",
        responseKey: "uniformat_level_6s",
        apiBodyParam: "uniformat_level_6",
        permissionKey: "uniformat_level_6",
        tableConfig: {
            keys: [
                "name",
                "uniformat_level_6_description",
                "uniformat_level_1",
                "uniformat_level_2",
                "uniformat_level_3",
                "uniformat_level_4",
                "uniformat_level_5",
                "in_scope",
                "asset_eul",
                "asset_unit",
                "asset_unit_additional",
                "average_criticality",
                "average_business_criticality",
                "average_safety_criticality",
                "average_environment_criticality",
                "in_adc_worksheet",
                "created_at",
                "updated_at"
            ],
            config: {
                name: {
                    isVisible: true,
                    label: "Name",
                    class: "",
                    searchKey: "uniformat_level_6s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_1: {
                    isVisible: true,
                    label: "Uniformat level 1",
                    class: "",
                    searchKey: "uniformat_level_1s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_1s",
                    commonSearchKey: "uniformat_level_1s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_2: {
                    isVisible: true,
                    label: "Uniformat level 2",
                    class: "",
                    searchKey: "uniformat_level_2s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_2s",
                    commonSearchKey: "uniformat_level_2s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_3: {
                    isVisible: true,
                    label: "Uniformat level 3",
                    class: "",
                    searchKey: "uniformat_level_3s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_3s",
                    commonSearchKey: "uniformat_level_3s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_4: {
                    isVisible: true,
                    label: "Uniformat level 4",
                    class: "",
                    searchKey: "uniformat_level_4s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_4s",
                    commonSearchKey: "uniformat_level_4s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_5: {
                    isVisible: true,
                    label: "Uniformat level 5",
                    class: "",
                    searchKey: "uniformat_level_5s.name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_5s",
                    commonSearchKey: "uniformat_level_5s",
                    commonSearchObjectKey: "name"
                },
                uniformat_level_6_description: {
                    isVisible: true,
                    label: "Uniformat level 6 Description",
                    class: "",
                    searchKey: "uniformat_level_6_description",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "uniformat_level_6_description"
                },
                in_scope: {
                    isVisible: true,
                    label: "In Scope",
                    class: "",
                    searchKey: "uniformat_level_6s.in_scope",
                    type: "boolean",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "in_scope"
                },
                asset_eul: {
                    isVisible: true,
                    label: "Asset EUL",
                    class: "",
                    searchKey: "uniformat_level_6s.asset_eul",
                    type: "number",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "asset_eul"
                },
                asset_unit: {
                    isVisible: true,
                    label: "Asset Unit",
                    class: "",
                    searchKey: "uniformat_level_6s.asset_unit",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "asset_unit"
                },
                asset_unit_additional: {
                    isVisible: true,
                    label: "Asset Unit Additional",
                    class: "",
                    searchKey: "uniformat_level_6s.asset_unit_additional",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "asset_unit_additional"
                },
                average_criticality: {
                    isVisible: true,
                    label: "Average Criticality",
                    class: "",
                    searchKey: "uniformat_level_6s.average_criticality",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "average_criticality"
                },
                average_business_criticality: {
                    isVisible: true,
                    label: "Average Business Criticality",
                    class: "",
                    searchKey: "uniformat_level_6s.average_business_criticality",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "average_business_criticality"
                },
                average_safety_criticality: {
                    isVisible: true,
                    label: "Average Safety Criticality",
                    class: "",
                    searchKey: "uniformat_level_6s.average_safety_criticality",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "average_safety_criticality"
                },
                average_environment_criticality: {
                    isVisible: true,
                    label: "Average Environmental Criticality",
                    class: "",
                    searchKey: "uniformat_level_6s.average_environment_criticality",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "average_environment_criticality"
                },
                in_adc_worksheet: {
                    isVisible: true,
                    label: "In ADC Worksheet",
                    class: "",
                    searchKey: "uniformat_level_6s.in_adc_worksheet",
                    type: "boolean",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "in_adc_worksheet"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "",
                    searchKey: "uniformat_level_6s.created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "created_at"
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "",
                    searchKey: "uniformat_level_6s.updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "uniformat_level_6s",
                    commonSearchKey: "uniformat_level_6s",
                    commonSearchObjectKey: "updated_at"
                }
            }
        }
    },
    asset_scope_worksheets: {
        name: "Asset Scope Worksheet",
        key: "asset_scope_worksheets",
        responseKey: "asset_scope_worksheet",
        apiBodyParam: "asset_scope_worksheets",
        permissionKey: "asset_scope_worksheets",
        tableConfig: {
            keys: [
                "list_name",
                "client_input",
                "in_scope",
                "uniformat_code",
                "uniformat_name",
                "classification",
                "uni_l2",
                "uni_l3",
                "uni_l4",
                "uni_l5",
                "asset_desc",
                "in_master_template",
                "asset_eul",
                "asset_unit",
                "asset_unit_additional",
                "avg_crit",
                "avg_bus_crit",
                "avg_safe_crit",
                "avg_env_crit",
                "created_at",
                "updated_at"
            ],
            config: {
                list_name: {
                    isVisible: true,
                    label: "List Name",
                    class: "width-180px",
                    searchKey: "list_name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "list_name",
                    isRequired: true
                },
                client_input: {
                    isVisible: true,
                    label: "Client Input",
                    class: "width-180px",
                    searchKey: "client_input",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "client_input"
                },
                in_scope: {
                    isVisible: true,
                    label: "In Scope",
                    class: "width-180px",
                    searchKey: "in_scope",
                    type: "boolean",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "in_scope"
                },
                uniformat_code: {
                    isVisible: true,
                    label: "Uniformat Code",
                    class: "width-180px",
                    searchKey: "uniformat_code",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "uniformat_code",
                    isRequired: true
                },
                uniformat_name: {
                    isVisible: true,
                    label: "Uniformat Name",
                    class: "width-180px",
                    searchKey: "uniformat_name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "uniformat_name"
                },
                classification: {
                    isVisible: true,
                    label: "Classification",
                    class: "width-180px",
                    searchKey: "classification",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "classification"
                },
                uni_l2: {
                    isVisible: true,
                    label: "Uni L2",
                    class: "width-180px",
                    searchKey: "uni_l2",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "uni_l2"
                },
                uni_l3: {
                    isVisible: true,
                    label: "Uni L3",
                    class: "width-180px",
                    searchKey: "uni_l3",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "uni_l3"
                },
                uni_l4: {
                    isVisible: true,
                    label: "Uni L4",
                    class: "width-180px",
                    searchKey: "uni_l4",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "uni_l4"
                },
                uni_l5: {
                    isVisible: true,
                    label: "Uni L5",
                    class: "width-180px",
                    searchKey: "uni_l5",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "uni_l5"
                },
                asset_desc: {
                    isVisible: true,
                    label: "Asset Description",
                    class: "width-180px",
                    searchKey: "asset_desc",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "asset_desc"
                },
                in_master_template: {
                    isVisible: true,
                    label: "In Master Template",
                    class: "width-180px",
                    searchKey: "in_master_template",
                    type: "boolean",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "in_master_template"
                },
                asset_eul: {
                    isVisible: true,
                    label: "Asset EUL",
                    class: "width-180px",
                    searchKey: "asset_eul",
                    type: "number",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "asset_eul"
                },
                asset_unit: {
                    isVisible: true,
                    label: "Asset Unit",
                    class: "width-180px",
                    searchKey: "asset_unit",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "asset_unit"
                },
                asset_unit_additional: {
                    isVisible: true,
                    label: "Asset Unit Additional",
                    class: "width-180px",
                    searchKey: "asset_unit_additional",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "asset_unit_additional"
                },
                avg_crit: {
                    isVisible: true,
                    label: "Average Criticality",
                    class: "width-180px",
                    searchKey: "avg_crit",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "avg_crit"
                },
                avg_bus_crit: {
                    isVisible: true,
                    label: "Average Business Criticality",
                    class: "width-180px",
                    searchKey: "avg_bus_crit",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "avg_bus_crit"
                },
                avg_safe_crit: {
                    isVisible: true,
                    label: "Average Safety Criticality",
                    class: "width-180px",
                    searchKey: "avg_safe_crit",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "avg_safe_crit"
                },
                avg_env_crit: {
                    isVisible: true,
                    label: "Average Environmental Criticality",
                    class: "width-180px",
                    searchKey: "avg_env_crit",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "avg_env_crit"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "width-180px",
                    searchKey: "created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "created_at",
                    hideInForm: true
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "width-180px",
                    searchKey: "updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "asset_scope_worksheets",
                    commonSearchKey: "asset_scope_worksheets",
                    commonSearchObjectKey: "updated_at",
                    hideInForm: true
                }
            }
        }
    },
    recommendation_assets_costing_templates: {
        name: "Recommendation and Asset Costing Template",
        key: "recommendation_assets_costing_templates",
        responseKey: "recommendation_assets_costing_templates",
        apiBodyParam: "recommendation_assets_costing_templates",
        permissionKey: "recommendation_assets_costing_templates",
        tableConfig: {
            keys: [
                "line_id",
                "type_fca",
                "type_adc",
                "trade_1",
                "trade_2",
                "l2_code",
                "system",
                "system_l2",
                "average_useful_life_years",
                "uniformat_code_l4",
                "sub_system_l4",
                "uniformat_code_l5",
                "description",
                "unit",
                "unit_cost",
                "regional_adj",
                "regionally_adjusted",
                "action",
                "recommendation_name",
                "recommendation_narrative",
                "created_at",
                "updated_at"
            ],
            config: {
                line_id: {
                    isVisible: true,
                    label: "Line ID",
                    class: "width-180px",
                    searchKey: "line_id",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "line_id",
                    isRequired: true
                },
                type_fca: {
                    isVisible: true,
                    label: "Type FCA",
                    class: "width-180px",
                    searchKey: "type_fca",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "type_fca"
                },
                type_adc: {
                    isVisible: true,
                    label: "Type ADC",
                    class: "width-180px",
                    searchKey: "type_adc",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "type_adc"
                },
                trade_1: {
                    isVisible: true,
                    label: "Trade 1",
                    class: "width-180px",
                    searchKey: "trade_1",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "trade_1"
                },
                trade_2: {
                    isVisible: true,
                    label: "Trade 2",
                    class: "width-180px",
                    searchKey: "trade_2",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "trade_2"
                },
                l2_code: {
                    isVisible: true,
                    label: "L2 Code",
                    class: "width-180px",
                    searchKey: "l2_code",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "l2_code"
                },
                system: {
                    isVisible: true,
                    label: "System",
                    class: "width-180px",
                    searchKey: "system",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "system"
                },
                system_l2: {
                    isVisible: true,
                    label: "System L2",
                    class: "width-180px",
                    searchKey: "system_l2",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "system_l2"
                },
                average_useful_life_years: {
                    isVisible: true,
                    label: "Average Useful Life (Years)",
                    class: "width-180px",
                    searchKey: "average_useful_life_years",
                    type: "number",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "average_useful_life_years"
                },
                uniformat_code_l4: {
                    isVisible: true,
                    label: "Uniformat Code L4",
                    class: "width-180px",
                    searchKey: "uniformat_code_l4",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: true,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "uniformat_code_l4"
                },
                sub_system_l4: {
                    isVisible: true,
                    label: "Sub System L4",
                    class: "width-180px",
                    searchKey: "sub_system_l4",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "sub_system_l4"
                },
                uniformat_code_l5: {
                    isVisible: true,
                    label: "Uniformat Code L5",
                    class: "width-180px",
                    searchKey: "uniformat_code_l5",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "uniformat_code_l5",
                    isRequired: true
                },
                description: {
                    isVisible: true,
                    label: "Description",
                    class: "width-180px",
                    searchKey: "description",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "description"
                },
                unit: {
                    isVisible: true,
                    label: "Unit",
                    class: "width-180px",
                    searchKey: "unit",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "unit"
                },
                unit_cost: {
                    isVisible: true,
                    label: "Unit Cost",
                    class: "width-180px",
                    searchKey: "unit_cost",
                    type: "number",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "unit_cost"
                },
                regional_adj: {
                    isVisible: true,
                    label: "Regional Adjustment",
                    class: "width-180px",
                    searchKey: "regional_adj",
                    type: "number",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "regional_adj"
                },
                regionally_adjusted: {
                    isVisible: true,
                    label: "Regionally Adjusted",
                    class: "width-180px",
                    searchKey: "regionally_adjusted",
                    type: "number",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "regionally_adjusted"
                },
                action: {
                    isVisible: true,
                    label: "Action",
                    class: "width-180px",
                    searchKey: "action",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "action"
                },
                recommendation_name: {
                    isVisible: true,
                    label: "Recommendation Name",
                    class: "width-180px",
                    searchKey: "recommendation_name",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "recommendation_name"
                },
                recommendation_narrative: {
                    isVisible: true,
                    label: "Recommendation Narrative",
                    class: "width-180px",
                    searchKey: "recommendation_narrative",
                    type: "string",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "recommendation_narrative"
                },
                created_at: {
                    isVisible: true,
                    label: "Created At",
                    class: "width-180px",
                    searchKey: "created_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "created_at",
                    hideInForm: true
                },
                updated_at: {
                    isVisible: true,
                    label: "Updated At",
                    class: "width-180px",
                    searchKey: "updated_at",
                    type: "date",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "recommendation_assets_costing_templates",
                    commonSearchKey: "recommendation_assets_costing_templates",
                    commonSearchObjectKey: "updated_at",
                    hideInForm: true
                }
            }
        }
    }
};
