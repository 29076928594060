import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import NumberFormat from "react-number-format";
import "rc-tooltip/assets/bootstrap_white.css";

import { addToBreadCrumpData, popBreadCrumpOnPageClose, findPrevPathFromBreadCrumpData } from "../../../config/utils";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import Portal from "../../common/components/Portal";
import qs from "query-string";
import ShowHelperModal from "../../helper/components/ShowHelperModal";
import { ImageBand } from "../../common/components/ImageComponents/ImageBand";
import { EllipsisTooltipText } from "../../common/components/EllipsisTextWithTooltip";
import { DYNAMIC_FIELD_TYPES } from "../../common/constants";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { editorConfiguration } from "../../../utils/ckEditorUtils";
import { RichEditorPopup } from "../../common/components/formFields/RichEditorPopup";
// import helpIcon from "../../../assets/img/question-mark-icon.png";

class BasicDetails extends Component {
    state = {
        showConfirmModalLog: false,
        selectedLog: "",
        logChanges: {},
        showHelperModal: false,
        selectedHelperItem: {},
        showConfirmModal: false,
        showImageModal: false,
        showBand: false,
        showRichTextPopup: { label: "", key: "", show: false, value: "" }
    };

    setToolTip(basicDetails, name) {
        return (
            <div
                className={`txt-rcm ${name === "Installed Year" ? "wid-tw-rcm" : ""}`}
                data-tip={basicDetails && basicDetails.length > 20 ? basicDetails || "" : ""}
                data-multiline={true}
                data-place="left"
                data-effect="solid"
                data-background-color="#4991ff"
                data-for="Asset_details"
            >
                <div className="txt-dtl">
                    <h4>{name}</h4>
                    <h3>{basicDetails && basicDetails.length > 20 ? basicDetails.substring(0, 20) + "..." : basicDetails || "-"}</h3>
                </div>
            </div>
        );
    }

    getBandId = band => {
        return band.split(" ").join("_");
    };

    setToolTip1(basicDetails, name) {
        return (
            <div
                className="content-block-card"
                data-tip={basicDetails && basicDetails.length > 20 ? basicDetails || "" : ""}
                data-multiline={true}
                data-place="left"
                data-effect="solid"
                data-background-color="#4991ff"
                data-for="Asset_details"
            >
                <p className="m-label">{name}</p>
                <p className="m-details">{basicDetails && basicDetails.length > 20 ? basicDetails.substring(0, 20) + "..." : basicDetails || "-"}</p>
            </div>
        );
    }

    handleRestoreLog = async (id, choice, changes) => {
        await this.setState({
            showConfirmModalLog: true,
            selectedLog: id,
            logChanges: changes
        });
    };

    renderConfirmationModalLog = () => {
        const { showConfirmModalLog, logChanges } = this.state;
        if (!showConfirmModalLog) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to restore this log?"}
                        message={logChanges}
                        onNo={() => this.setState({ showConfirmModalLog: false })}
                        onYes={this.restoreLogOnConfirm}
                        isRestore={true}
                        type={"restore"}
                        isLogChange={true}
                    />
                }
                onCancel={() => this.setState({ showConfirmModalLog: false })}
            />
        );
    };

    restoreLogOnConfirm = async () => {
        const { selectedLog } = this.state;
        await this.props.HandleRestoreSettingsLog(selectedLog);
        await this.props.refreshinfoDetails();
        this.setState({
            showConfirmModalLog: false,
            selectedLog: null,
            isHistoryView: !this.state.isHistoryView
        });
    };

    showHelperModal = async (item, subItem) => {
        await this.setState({
            showHelperModal: true,
            selectedHelperItem: {
                item,
                subItem
            }
        });
    };

    findPriorityText = (element, options) => {
        let text = "-";
        let priorityObj = options.find(option => parseInt(option.value) === element);
        if (priorityObj) {
            text = priorityObj.name;
        }
        return text;
    };

    openImageModal = () => {
        this.setState({
            showImageModal: true
        });
    };

    showHelperModal = async (item, subItem) => {
        await this.setState({
            showHelperModal: true,
            selectedHelperItem: {
                item,
                subItem
            }
        });
    };
    renderUploadHelperModal = () => {
        const { showHelperModal, selectedHelperItem } = this.state;
        if (!showHelperModal) return null;
        return (
            <Portal
                body={<ShowHelperModal selectedHelperItem={selectedHelperItem} onCancel={() => this.setState({ showHelperModal: false })} />}
                onCancel={() => this.setState({ showHelperModal: false })}
            />
        );
    };

    exportAsset = basicDetails => {
        let params = {};
        params.client_id = basicDetails?.client?.id || "";
        params.asset_id = basicDetails?.id || "";
        params.username = localStorage.getItem("user");
        this.props.onePagerExport(params);
    };
    renderRichTextPopup = () => {
        const { label, key, show, value } = this.state.showRichTextPopup;
        if (!show) return null;
        return (
            <Portal
                body={<RichEditorPopup value={value} label={label} key={key} onCancel={() => this.setState({ showRichTextPopup: {} })} isReadOnly />}
                onCancel={() => this.setState({ showRichTextPopup: null })}
            />
        );
    };
    render() {
        const {
            basicDetails,
            handleDeleteItem,
            hasEdit,
            hasDelete,
            hasLogView,
            showEditPage,
            history,
            isHistoryView,
            hasClose = true,
            imageResponse,
            dynamicBands = {}
        } = this.props;
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        return (
            <React.Fragment>
                <ReactTooltip id="Asset_details" effect="solid" className="rc-tooltip-custom-class" backgroundColor="#007bff" />
                <div className="tab-active location-sec recom-sec add-recommendation asset-viewer-outer">
                    {this.renderUploadHelperModal()}
                    {this.renderRichTextPopup()}
                    <div className="otr-edit-delte col-md-12 text-right ed-dl view-narrative-report">
                        {this.props.exportLoader ? (
                            <span className="edit-icn-bx icon-btn-sec">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </span>
                        ) : (
                            <span
                                onClick={() => {
                                    this.exportAsset(basicDetails);
                                }}
                                className="edit-icn-bx icon-btn-sec"
                                data-tip="Export"
                                data-for="Asset_details"
                                data-place="bottom"
                            >
                                <img src="/img/export-rec.svg" alt="" />
                            </span>
                        )}

                        {isHistoryView
                            ? hasLogView && (
                                  <span
                                      onClick={() => {
                                          this.props.changeToHistory();
                                      }}
                                      className="edit-icn-bx"
                                  >
                                      <i className="fas fa-history"></i> View History
                                  </span>
                              )
                            : null}
                        {hasClose && !query.dashboardView ? (
                            <span
                                onClick={() => {
                                    popBreadCrumpOnPageClose();
                                    history.push(findPrevPathFromBreadCrumpData());
                                }}
                                className="edit-icn-bx"
                            >
                                <i className="fas fa-window-close"></i> Close
                            </span>
                        ) : null}
                        {hasEdit && (
                            <span
                                onClick={() => {
                                    if (showEditPage) {
                                        showEditPage(this.props.match.params.id);
                                    } else {
                                        addToBreadCrumpData({
                                            key: "edit",
                                            name: `Edit ${this.props.location.pathname.split("/")[1]}`,
                                            path: `/${this.props.location.pathname.split("/")[1]}/edit/${this.props.match.params.id}`
                                        });
                                        history.push(`/${this.props.location.pathname.split("/")[1]}/edit/${this.props.match.params.id}`);
                                    }
                                }}
                                className="edit-icn-bx"
                            >
                                <i className="fas fa-pencil-alt"></i> Edit
                            </span>
                        )}
                        {hasDelete && (
                            <span onClick={() => handleDeleteItem(this.props.match.params.id)} className="edit-icn-bx">
                                <i className="fas fa-trash-alt"></i> Delete
                            </span>
                        )}
                        <span
                            className="view-inner help-icon"
                            onClick={() => {
                                this.showHelperModal("forms", "asset_management");
                            }}
                            data-for="Asset_details"
                            data-tip="Help"
                            // data-effect="solid"
                            data-place="bottom"
                            data-background-color="#007bff"
                        >
                            <img src="/img/question-mark-icon.png" alt="" className="fil-ico" />
                        </span>
                    </div>
                    <div className="col-md-12 detail-recom scorll-flow">
                        <div className="m-details-img-sec cnt-sec">
                            <div class="card">
                                <div className={`card-header ${this.state.showBand && "asset-info"}`} id="headingOne">
                                    <div className="d-flex content-block-2 otr-recom-div no-bottom-bord">
                                        <button
                                            class="btn btn-link btn-right-pos"
                                            data-toggle="collapse"
                                            onClick={() => this.setState({ showBand: !this.state.showBand })}
                                            data-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        ></button>
                                        <div className="row align-items-stretch cnt-sec">
                                            <div className="col-md-8 m-details-content-outer">
                                                <div className="m-details-content-block ">
                                                    <div class="card">
                                                        <div class="card-header" id="headingOne">
                                                            <div className="d-flex content-block-2 otr-recom-div no-bottom-bord">
                                                                <button
                                                                    class="btn btn-link"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                    onClick={() => this.setState({ showBand: !this.state.showBand })}
                                                                >
                                                                    <span>Basic Asset Information</span>
                                                                </button>

                                                                <div className="col-md-3 basic-box">
                                                                    <div className="content-block-card ">
                                                                        <p className="m-label">Asset Code</p>
                                                                        <p className="m-details">{basicDetails.code || "-"}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 basic-box">
                                                                    <div className="content-block-card ">
                                                                        <p className="m-label">Asset Name</p>
                                                                        <EllipsisTooltipText
                                                                            className="m-details trim-txt"
                                                                            text={basicDetails.asset_name || "-"}
                                                                            isInfoField={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne">
                                                            <div class="card-body">
                                                                <div class="outer-rcm mt-1 basic-dtl-otr p-0">
                                                                    <div class="col-md-12 p-0">
                                                                        <div className="d-flex content-block-2">
                                                                            <div className="col-md-6 basic-box">
                                                                                <div className="content-block-card ">
                                                                                    <p className="m-label">Asset Description</p>
                                                                                    <EllipsisTooltipText
                                                                                        className="m-details trim-txt"
                                                                                        text={basicDetails.asset_description || "-"}
                                                                                        isInfoField={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">Asset Type</p>
                                                                                    <p className="m-details">
                                                                                        {basicDetails.asset_type?.name || "-"}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">Asset Status</p>
                                                                                    <p className="m-details">
                                                                                        {basicDetails.asset_status?.name || "-"}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex content-block-2">
                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">Asset Condition</p>
                                                                                    <p className="m-details">
                                                                                        {basicDetails.client_asset_condition?.name || "-"}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                {this.setToolTip1(
                                                                                    basicDetails?.client_asset_condition?.description,
                                                                                    "Asset Condition Description"
                                                                                )}
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                {this.setToolTip1(basicDetails?.asset_ownership, "Asset Ownership")}
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">Criticality</p>
                                                                                    <p className="m-details">{basicDetails.criticality || "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex content-block-2">
                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">Asset Tag</p>
                                                                                    <p className="m-details">{basicDetails.asset_tag || "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                {this.setToolTip1(basicDetails.guid, "GUID")}
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                {this.setToolTip1(basicDetails.model_number, "Model Number")}
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                {this.setToolTip1(
                                                                                    basicDetails.core_model_number,
                                                                                    "Core Model Number"
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex content-block-2">
                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">Serial Number</p>
                                                                                    <p className="m-details">{basicDetails.serial_number || "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">Capacity</p>
                                                                                    <p className="m-details">{basicDetails.capacity || "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">Capacity Unit</p>
                                                                                    <p className="m-details">{basicDetails.capacity_unit || "-"}</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">Unit Cost</p>
                                                                                    <p className="m-details">
                                                                                        <NumberFormat
                                                                                            value={
                                                                                                basicDetails.unit_cost
                                                                                                    ? parseFloat(basicDetails.unit_cost)
                                                                                                    : "-"
                                                                                            }
                                                                                            thousandSeparator={true}
                                                                                            displayType={"text"}
                                                                                            prefix={"$ "}
                                                                                        />
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex content-block-2">
                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">Asset Barcode</p>
                                                                                    <p className="m-details">{basicDetails.asset_barcode || "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">Asset Client ID</p>
                                                                                    <p className="m-details">{basicDetails.asset_client_id || "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">Asset CMMS ID</p>
                                                                                    <p className="m-details">{basicDetails.asset_cmms_id || "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 basic-box">
                                                                                <div className="content-block-card">
                                                                                    <p className="m-label">RTLS Tag</p>
                                                                                    <p className="m-details">{basicDetails.rtls_tag || "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`col-md-4 m-details-img-outer main-image-outer2`}>
                                                <ImageBand basicDetails={basicDetails} hasEdit={false} getAllImagesResponse={imageResponse} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cnt-sec">
                            <div className="card ml-0">
                                <div class="card-header" id="headingTwo">
                                    <div className="outer-rcm recommendations otr-recom-div">
                                        <button
                                            class="btn btn-link"
                                            data-toggle="collapse"
                                            data-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            Geo Hierarchy
                                        </button>
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Client</h4>
                                                <h3>{basicDetails?.client?.name || "-"}</h3>
                                            </div>
                                        </div>
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Region</h4>
                                                <h3>{basicDetails.region?.name || "-"}</h3>
                                            </div>
                                        </div>
                                        {this.setToolTip(basicDetails.site?.name, "Site")}
                                    </div>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo">
                                    <div class="card-body">
                                        <div className="outer-rcm recommendations ">
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Building</h4>
                                                    <h3>{basicDetails.building?.name || "-"}</h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails?.building_type?.name?.toString().length > 20
                                                        ? basicDetails.building_type?.name?.toString().substring(0, 20) + "..."
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-for="Asset_details"
                                                data-effect="solid"
                                                data-background-color="#4991ff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Building Type</h4>
                                                    <h3>
                                                        {basicDetails.building_type?.name?.toString().length > 20
                                                            ? basicDetails.building_type?.name?.toString().substring(0, 20) + "..."
                                                            : basicDetails.building_type?.name || "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.addition?.name?.toString().length > 20 ? basicDetails?.addition?.name || "" : ""
                                                }
                                                data-multiline={true}
                                                data-for="Asset_details"
                                                data-place="left"
                                                data-effect="solid"
                                                data-background-color="#4991ff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Addition</h4>
                                                    <h3>{basicDetails?.addition?.name || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Floor</h4>
                                                    <h3>{basicDetails.floor?.name || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="outer-rcm recommendations ">
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Room Number</h4>
                                                <h3>{basicDetails.room_number || "-"}</h3>
                                            </div>
                                        </div>
                                        <div
                                            className="txt-rcm"
                                            data-tip={basicDetails.room_name?.toString().length > 20 ? basicDetails.room_name || "" : ""}
                                            data-multiline={true}
                                            data-place="left"
                                            data-effect="solid"
                                            data-for="Asset_details"
                                            data-background-color="#4991ff"
                                        >
                                            <div className="txt-dtl">
                                                <h4>Room Name</h4>
                                                <h3>
                                                    {basicDetails.room_name?.toString().length > 20
                                                        ? basicDetails.room_name.toString().substring(0, 20) + "..."
                                                        : basicDetails.room_name || "-"}
                                                </h3>
                                            </div>
                                        </div>
                                        <div
                                            className="txt-rcm"
                                            data-tip={
                                                basicDetails.location && basicDetails.location.toString().length > 20
                                                    ? basicDetails.location || ""
                                                    : ""
                                            }
                                            data-multiline={true}
                                            data-place="left"
                                            data-effect="solid"
                                            data-for="Asset_details"
                                            data-background-color="#4991ff"
                                        >
                                            <div className="txt-dtl">
                                                <h4>Location</h4>
                                                <h3>
                                                    {basicDetails.location && basicDetails.location.toString().length > 20
                                                        ? basicDetails.location.toString().substring(0, 20) + "..."
                                                        : basicDetails.location || "-"}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Architectural Room Number</h4>
                                                <h3>{(basicDetails && basicDetails.architectural_room_number) || "-"}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="outer-rcm recommendations">
                                        {this.setToolTip(basicDetails && basicDetails.additional_room_description, "Additional Room Description")}
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Longitude</h4>
                                                <h3>
                                                    {basicDetails.longitude && basicDetails.longitude.toString().length > 20
                                                        ? basicDetails.longitude.toString().substring(0, 20) + "..."
                                                        : basicDetails.longitude || "-"}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Latitude</h4>
                                                <h3>
                                                    {basicDetails.latitude && basicDetails.latitude.toString().length > 20
                                                        ? basicDetails.latitude.toString().substring(0, 20) + "..."
                                                        : basicDetails.latitude || "-"}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="txt-rcm"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cnt-sec">
                            <div className="card ml-0">
                                <div class="card-header" id="headingThree">
                                    <div className="outer-rcm recommendations otr-recom-div">
                                        <button
                                            class="btn btn-link"
                                            data-toggle="collapse"
                                            data-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            Extended Asset Information
                                        </button>
                                        <div
                                            className="txt-rcm"
                                            data-tip={
                                                basicDetails.manufacturer && basicDetails.manufacturer.toString().length > 20
                                                    ? basicDetails.manufacturer || ""
                                                    : ""
                                            }
                                            data-multiline={true}
                                            data-place="left"
                                            data-effect="solid"
                                            data-for="Asset_details"
                                            data-background-color="#4991ff"
                                        >
                                            <div className="txt-dtl">
                                                <h4>Manufacturer</h4>
                                                <h3>{(basicDetails && basicDetails.manufacturer) || "-"}</h3>
                                            </div>
                                        </div>
                                        <div
                                            className="txt-rcm"
                                            data-tip={
                                                basicDetails.year_manufactured && basicDetails.year_manufactured.toString().length > 20
                                                    ? basicDetails.year_manufactured || ""
                                                    : ""
                                            }
                                            data-multiline={true}
                                            data-place="left"
                                            data-for="Asset_details"
                                            data-effect="solid"
                                            data-background-color="#4991ff"
                                        >
                                            <div className="txt-dtl">
                                                <h4>Year Manufactured</h4>
                                                <h3>{(basicDetails && basicDetails.year_manufactured) ?? "-"}</h3>
                                            </div>
                                        </div>
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Installed Year</h4>
                                                <h3>{(basicDetails && basicDetails.installed_year) ?? "-"}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree">
                                    <div class="card-body">
                                        <div className="outer-rcm recommendations ">
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Current Age</h4>
                                                    <h3>{basicDetails.current_age || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Service Life</h4>
                                                    <h3>{(basicDetails && basicDetails.service_life) ?? "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>End of Service Life</h4>
                                                    <h3>
                                                        {basicDetails?.usefull_life_remaining
                                                            ? `${new Date().getFullYear() + basicDetails.usefull_life_remaining}`
                                                            : "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Useful Life Remaining</h4>
                                                    <h3>{(basicDetails && basicDetails.usefull_life_remaining) ?? "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="outer-rcm recommendations ">
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>CSP Unit</h4>
                                                    <h3>{basicDetails?.csp_unit ?? "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>CRV</h4>
                                                    <h3>
                                                        <NumberFormat
                                                            value={parseInt(basicDetails.crv || 0)}
                                                            thousandSeparator={true}
                                                            displayType={"text"}
                                                            prefix={"$ "}
                                                        />
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>FCA Cost</h4>
                                                    <h3>
                                                        <NumberFormat
                                                            value={parseInt(basicDetails.fca_cost || 0)}
                                                            thousandSeparator={true}
                                                            displayType={"text"}
                                                            prefix={"$ "}
                                                        />
                                                    </h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={basicDetails?.refrigerant?.toString()?.length > 20 ? basicDetails.refrigerant || "" : ""}
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-for="Asset_details"
                                                data-background-color="#4991ff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Refrigerant</h4>
                                                    <h3>{basicDetails?.refrigerant || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="outer-rcm recommendations">
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Warranty Start</h4>
                                                    <h3>
                                                        {basicDetails?.warranty_start
                                                            ? moment(basicDetails?.warranty_start).format("MM-DD-YYYY") || "-"
                                                            : "-"}{" "}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Warranty End</h4>
                                                    <h3>
                                                        {basicDetails?.warranty_end
                                                            ? moment(basicDetails?.warranty_end).format("MM-DD-YYYY") || "-"
                                                            : "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.install_date && basicDetails.install_date.toString().length > 20
                                                        ? basicDetails.install_date || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-for="Asset_details"
                                                data-background-color="#4991ff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Install Date</h4>
                                                    <h3>
                                                        {basicDetails?.install_date
                                                            ? moment(basicDetails?.install_date).format("MM-DD-YYYY") || "-"
                                                            : "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.startup_date && basicDetails.startup_date.toString().length > 20
                                                        ? basicDetails.startup_date || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-for="Asset_details"
                                                data-background-color="#4991ff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Startup Date</h4>
                                                    <h3>
                                                        {basicDetails?.startup_date
                                                            ? moment(basicDetails?.startup_date).format("MM-DD-YYYY") || "-"
                                                            : "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="outer-rcm recommendations">
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.uniformat_level_1 && basicDetails?.uniformat_level_1?.name?.toString().length > 20
                                                        ? basicDetails.uniformat_level_1.name || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-for="Asset_details"
                                                data-background-color="#4991ff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Uniformat Level 1</h4>
                                                    <h3>
                                                        {(basicDetails && basicDetails.uniformat_level_1 && basicDetails.uniformat_level_1.name) ||
                                                            "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.uniformat_level_2 && basicDetails.uniformat_level_2?.name?.toString().length > 20
                                                        ? basicDetails.uniformat_level_2.name || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-for="Asset_details"
                                                data-background-color="#4991ff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Uniformat Level 2</h4>
                                                    <h3>{basicDetails.uniformat_level_2?.name || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Uniformat Level 3</h4>
                                                    <h3>{basicDetails.uniformat_level_3?.name || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Uniformat Level 4</h4>
                                                    <h3>{basicDetails.uniformat_level_4?.name || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="outer-rcm recommendations">
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Uniformat Level 5</h4>
                                                    <h3>{basicDetails.uniformat_level_5?.name || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Uniformat Level 6</h4>
                                                    <h3>{basicDetails?.uniformat_level_6?.name || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Uniformat Level 6 Description</h4>
                                                    <h3>{basicDetails?.uniformat_level_6?.uniformat_level_6_description || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Uniformat Level 3 Custom Description</h4>
                                                    <h3>{basicDetails?.uniformat_level_3_custom_description || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="outer-rcm recommendations">
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Main Category</h4>
                                                    <h3>{basicDetails?.main_category?.name || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Sub Category 1</h4>
                                                    <h3>{basicDetails?.sub_category_1?.name || "-"}</h3>{" "}
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Sub Category 2</h4>
                                                    <h3>{basicDetails?.sub_category_2?.name || "-"}</h3>{" "}
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails?.sub_category_2?.subcategory2_description?.toString().length > 38
                                                        ? basicDetails?.sub_category_2?.subcategory2_description || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-background-color="#4991ff"
                                                data-for="Asset_details"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Sub Category 2 Description</h4>
                                                    <h3>
                                                        {basicDetails?.sub_category_2?.subcategory2_description?.toString().length > 38
                                                            ? basicDetails?.sub_category_2?.subcategory2_description?.toString()?.substring(0, 38) +
                                                              "..."
                                                            : basicDetails?.sub_category_2?.subcategory2_description || "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="outer-rcm recommendations">
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Sub Category 3</h4>
                                                    <h3>{basicDetails?.sub_category_3?.name || "-"}</h3>{" "}
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Trade</h4>
                                                    <h3>{basicDetails.trade?.name || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>System</h4>
                                                    <h3>{basicDetails.system?.name || "-"}</h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails?.system?.name?.toString().length > 20
                                                        ? basicDetails.system?.name?.toString().substring(0, 20) + "..."
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-for="Asset_details"
                                                data-effect="solid"
                                                data-background-color="#4991ff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Sub-System </h4>
                                                    <h3>
                                                        {basicDetails.sub_system?.name?.toString().length > 20
                                                            ? basicDetails.sub_system?.name?.toString().substring(0, 20) + "..."
                                                            : basicDetails.sub_system?.name || "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="outer-rcm recommendations">
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.asset_note && basicDetails.asset_note.toString().length > 20
                                                        ? basicDetails.asset_note || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-background-color="#4991ff"
                                                data-for="Asset_details"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Asset Notes</h4>
                                                    <h3>
                                                        {basicDetails.asset_note && basicDetails.asset_note.toString().length > 20
                                                            ? basicDetails.asset_note.toString().substring(0, 20) + "..."
                                                            : basicDetails.asset_note || "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.energy_use_type && basicDetails.energy_use_type.toString().length > 20
                                                        ? basicDetails.energy_use_type || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-background-color="#4991ff"
                                                data-for="Asset_details"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Energy Use Type</h4>
                                                    <h3>
                                                        {basicDetails.energy_use_type && basicDetails.energy_use_type.toString().length > 20
                                                            ? basicDetails.energy_use_type.toString().substring(0, 20) + "..."
                                                            : basicDetails.energy_use_type || "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cnt-sec">
                            <div className="card ml-0">
                                <div class="card-header" id="headingFour">
                                    <div className="outer-rcm recommendations otr-recom-div">
                                        <button
                                            class="btn btn-link"
                                            data-toggle="collapse"
                                            data-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            Asset QA/QC Status
                                        </button>
                                        <div
                                            className="txt-rcm"
                                            data-tip={
                                                basicDetails.survey_id && basicDetails.survey_id.toString().length > 20
                                                    ? basicDetails.survey_id || ""
                                                    : ""
                                            }
                                            data-multiline={true}
                                            data-place="left"
                                            data-effect="solid"
                                            data-for="Asset_details"
                                            data-background-color="#4991ff"
                                        >
                                            <div className="txt-dtl">
                                                <h4>Asset Survey Id</h4>
                                                <h3>{(basicDetails && basicDetails.survey_id) || "-"}</h3>
                                            </div>
                                        </div>
                                        <div
                                            className="txt-rcm"
                                            data-tip={
                                                basicDetails.survey_property_note && basicDetails.survey_property_note.toString().length > 20
                                                    ? basicDetails.survey_property_note || ""
                                                    : ""
                                            }
                                            data-multiline={true}
                                            data-place="left"
                                            data-effect="solid"
                                            data-for="Asset_details"
                                            data-background-color="#4991ff"
                                        >
                                            <div className="txt-dtl">
                                                <h4>Asset Survey Property Notes</h4>
                                                <h3>{(basicDetails && basicDetails.survey_property_note) || "-"}</h3>
                                            </div>
                                        </div>
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Asset Survey QA/QC Notes</h4>
                                                <h3>{(basicDetails && basicDetails.qa_notes) || "-"}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour">
                                    <div class="card-body">
                                        <div className="outer-rcm recommendations ">
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Capacity Status</h4>
                                                    <h3>{(basicDetails && basicDetails.capacity_status) || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Install Year Status</h4>
                                                    <h3>{(basicDetails && basicDetails.installed_year_status) || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Name Plate Status</h4>
                                                    <h3>{(basicDetails && basicDetails.name_plate_status) || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Asset Survey Additional QA/QC Notes</h4>
                                                    <h3>{(basicDetails && basicDetails.additional_qa_notes) || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="outer-rcm recommendations">
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Asset Survey Date Created</h4>
                                                    <h3>{(basicDetails && basicDetails.survey_date_created) || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Asset Survey Surveyor</h4>
                                                    <h3>{(basicDetails && basicDetails.surveyor) || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Asset Survey Date Edited</h4>
                                                    <h3>{(basicDetails && basicDetails.survey_date_edited) || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Asset Survey Editor</h4>
                                                    <h3>{(basicDetails && basicDetails.editor) || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="outer-rcm recommendations">
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Is This a New Asset</h4>
                                                    <h3>{(basicDetails && basicDetails.new_asset) || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Created At</h4>
                                                    <h3>
                                                        {basicDetails?.created_at ? moment(basicDetails.created_at).format("MM-DD-YYYY h:mm A") : "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Updated At</h4>
                                                    <h3>
                                                        {basicDetails?.updated_at ? moment(basicDetails.updated_at).format("MM-DD-YYYY h:mm A") : "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ----------- */}
                        <div className="cnt-sec">
                            <div className="card ml-0">
                                <div class="card-header" id="headingFive">
                                    <div className="outer-rcm recommendations otr-recom-div">
                                        <button
                                            class="btn btn-link"
                                            data-toggle="collapse"
                                            data-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive"
                                        >
                                            Asset Parent/Child Hierarchy
                                        </button>
                                        <div
                                            className="txt-rcm"
                                            data-tip={
                                                basicDetails.area_served && basicDetails.area_served.toString().length > 20
                                                    ? basicDetails.area_served || ""
                                                    : ""
                                            }
                                            data-multiline={true}
                                            data-place="left"
                                            data-effect="solid"
                                            data-for="Asset_details"
                                            data-background-color="#4991ff"
                                        >
                                            <div className="txt-dtl">
                                                <h4>Area Served</h4>
                                                <h3>{(basicDetails && basicDetails.area_served) || "-"}</h3>
                                            </div>
                                        </div>
                                        <div
                                            className="txt-rcm"
                                            data-tip={
                                                basicDetails.upstream_asset_barcode_number &&
                                                basicDetails.upstream_asset_barcode_number.toString().length > 20
                                                    ? basicDetails.upstream_asset_barcode_number || ""
                                                    : ""
                                            }
                                            data-multiline={true}
                                            data-place="left"
                                            data-effect="solid"
                                            data-for="Asset_details"
                                            data-background-color="#4991ff"
                                        >
                                            <div className="txt-dtl">
                                                <h4>Upstream Asset Barcode Numbers</h4>
                                                <h3>{(basicDetails && basicDetails.upstream_asset_barcode_number) || "-"}</h3>
                                            </div>
                                        </div>
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Linked Asset Barcode Numbers</h4>
                                                <h3>{(basicDetails && basicDetails.linked_asset_barcode_number) || "-"}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive">
                                        <div class="card-body">
                                            <div className="outer-rcm recommendations">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Source Panel Name</h4>
                                                        <h3>{(basicDetails && basicDetails.source_panel_name) || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Source Panel Barcode Number</h4>
                                                        <h3>{(basicDetails && basicDetails.source_panel_barcode_number) || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Source Panel Emergency/Normal</h4>
                                                        <h3>{(basicDetails && basicDetails.source_panel) || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Skysite Hyperlink</h4>
                                                        <h3>{(basicDetails && basicDetails.skysite_hyperlink) || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* ---------------------- */}

                                            <div className="outer-rcm recommendations ">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Quantity</h4>
                                                        <h3>{(basicDetails && basicDetails.quantity) ?? "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Parent Global ID</h4>
                                                        <h3>{(basicDetails && basicDetails.parent_global_id) || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cnt-sec">
                            <div className="card ml-0">
                                <div class="card-header" id="headingSix">
                                    <div className="outer-rcm recommendations otr-recom-div">
                                        <button
                                            class="btn btn-link"
                                            data-toggle="collapse"
                                            data-target="#collapseSix"
                                            aria-expanded="false"
                                            aria-controls="collapseSix"
                                        >
                                            Asset Additional
                                        </button>
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Discharge</h4>
                                                <h3>{basicDetails?.discharge || "-"}</h3>
                                            </div>
                                        </div>
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Return</h4>
                                                <h3>{basicDetails?.return || "-"}</h3>
                                            </div>
                                        </div>
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Is Unit Properly Labeled</h4>
                                                <h3>{basicDetails?.unit_property_labeled || "-"}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapseSix" class="collapse" aria-labelledby="headingSix">
                                    <div class="card-body">
                                        <div className="outer-rcm recommendations ">
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Factory Mounted Disconnect</h4>
                                                    <h3>{basicDetails?.factory_mounted_disconnect || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Unit Voltage</h4>
                                                    <h3>{basicDetails?.unit_voltage || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>AHU Voltage</h4>
                                                    <h3>{basicDetails?.ahu_voltage || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Wire Type</h4>
                                                    <h3>{basicDetails?.wire_type || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Wire Size</h4>
                                                    <h3>{basicDetails?.wire_size || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Estimated Wire Run Length</h4>
                                                    <h3>{basicDetails?.estimated_wire_run_length || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Breaker Size</h4>
                                                    <h3>{basicDetails?.breaker_size || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>MOCP</h4>
                                                    <h3>{basicDetails?.mocp || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>MCA</h4>
                                                    <h3>{basicDetails?.mca || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Liquid Line</h4>
                                                    <h3>{basicDetails?.liquid_line || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Suction Line</h4>
                                                    <h3>{basicDetails?.suction_line || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Hot Gas By-Pass</h4>
                                                    <h3>{basicDetails?.hot_gas_by_pass || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Number of Circuits</h4>
                                                    <h3>{basicDetails?.number_of_circuits || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Length of Line Run</h4>
                                                    <h3>{basicDetails?.length_of_line || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Damper Type</h4>
                                                    <h3>{basicDetails?.damper_type || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Gas Heating</h4>
                                                    <h3>{basicDetails?.gas_heating || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Gas Output BTU</h4>
                                                    <h3>{basicDetails?.gas_output_btu || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Electric Heating</h4>
                                                    <h3>{basicDetails?.electric_heating || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Electric Heating KW</h4>
                                                    <h3>{basicDetails?.electric_heating_kw || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Heat Pump - Backup Heat KW</h4>
                                                    <h3>{basicDetails?.pump_backup_heat_kw || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Cooling Only</h4>
                                                    <h3>{basicDetails?.cooling_only || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Controls</h4>
                                                    <h3>{basicDetails?.controls || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Controls Type</h4>
                                                    <h3>{basicDetails?.control_type || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Smoke Detector</h4>
                                                    <h3>{basicDetails?.smoke_detector || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Smoke Detector Mounting</h4>
                                                    <h3>{basicDetails?.smoke_detector_mounting || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Smoke Detector - Supply</h4>
                                                    <h3>{basicDetails?.smoke_detector_supply || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Smoke Detector - Return</h4>
                                                    <h3>{basicDetails?.smoke_detector_return || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Curb</h4>
                                                    <h3>{basicDetails?.curb || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Curb Other Description</h4>
                                                    <h3>{basicDetails?.curb_description || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Vibration Isolation</h4>
                                                    <h3>{basicDetails?.vibration_isolation || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Burglar Bars</h4>
                                                    <h3>{basicDetails?.burglar_bars || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Existing Unit Installed on an Adapter Curb?</h4>
                                                    <h3>{basicDetails?.installed_on_adapter_curb || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Existing Curb Outside Dimensions - Length</h4>
                                                    <h3>{basicDetails?.curb_outside_dimension_length || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Existing Curb Outside Dimensions - Width</h4>
                                                    <h3>{basicDetails?.curb_outside_dimension_width || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Will Mall, Landlord, Municipality Allow a Curb Adapter?</h4>
                                                    <h3>{basicDetails?.allow_curb_adaptor || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Is This a Ducted or Concentric System</h4>
                                                    <h3>{basicDetails?.ducted_or_concentric_system || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Unit Weight</h4>
                                                    <h3>{basicDetails?.unit_weight || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Any Special Codes for Disconnects</h4>
                                                    <h3>{basicDetails?.codes_for_disconnects || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Any Special Codes for Convenience Outlets</h4>
                                                    <h3>{basicDetails?.codes_for_convenience_outlets || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Any Special Codes for Roof Lighting</h4>
                                                    <h3>{basicDetails?.codes_for_roof_lighting || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Current Duct Work Attached to Bottom of Unit</h4>
                                                    <h3>{basicDetails?.duct_work_attached_to_bottom_of_unit || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Was Aluminum Wire Used</h4>
                                                    <h3>{basicDetails?.aluminium_wire_used || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Are There Any Code or Safety Concerns</h4>
                                                    <h3>{basicDetails?.code_or_safety_concerns || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Distance (in Miles) from Body of Salt Water</h4>
                                                    <h3>{basicDetails?.distance_from_salt_water_body || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Recommend Coil Coating</h4>
                                                    <h3>{basicDetails?.recommend_coil_coating || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Do Electrical/Piping Roof Penetrations Need to be Resealed</h4>
                                                    <h3>{basicDetails?.need_resealed || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Split System - Are Line Sets in Good Condition</h4>
                                                    <h3>{basicDetails?.split_system_line_sets_good_condition || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Split System - Are Line Sets Damaged in Any Way</h4>
                                                    <h3>{basicDetails?.split_system_line_sets_damaged || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Split System - Are Line Sets Crimped</h4>
                                                    <h3>{basicDetails?.split_system_line_sets_crimped || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Split System - Are Line Sets Insulated</h4>
                                                    <h3>{basicDetails?.split_system_line_sets_insulated || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Split System - Can Line Sets Be Re-Used</h4>
                                                    <h3>{basicDetails?.split_system_line_sets_re_used || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Line Connection Type</h4>
                                                    <h3>{basicDetails?.line_connection_type || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cnt-sec">
                            <div className="card ml-0">
                                <div class="card-header" id="headingSeven">
                                    <div className="outer-rcm recommendations otr-recom-div">
                                        <button
                                            class="btn btn-link"
                                            data-toggle="collapse"
                                            data-target="#collapseSeven"
                                            aria-expanded="false"
                                            aria-controls="collapseSeven"
                                        >
                                            Boiler Fields
                                        </button>
                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Boiler Type</h4>
                                                <h3>{basicDetails?.boiler_type || "-"}</h3>
                                            </div>
                                        </div>

                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Boiler Burner Type</h4>
                                                <h3>{basicDetails?.boiler_burner_type || "-"}</h3>
                                            </div>
                                        </div>

                                        <div className="txt-rcm">
                                            <div className="txt-dtl">
                                                <h4>Boiler BTU Input</h4>
                                                <h3>{basicDetails?.boiler_btu_input || "-"}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven">
                                    <div class="card-body">
                                        <div className="outer-rcm recommendations ">
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Boiler BTU Output</h4>
                                                    <h3>{basicDetails?.boiler_btu_output || "-"}</h3>
                                                </div>
                                            </div>

                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Boiler HP</h4>
                                                    <h3>{basicDetails?.boiler_hp || "-"}</h3>
                                                </div>
                                            </div>

                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Boiler Fuel</h4>
                                                    <h3>{basicDetails?.boiler_fuel || "-"}</h3>
                                                </div>
                                            </div>

                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Boiler Gas Pressure</h4>
                                                    <h3>{basicDetails?.boiler_gas_pressure || "-"}</h3>
                                                </div>
                                            </div>

                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Boiler KW Input</h4>
                                                    <h3>{basicDetails?.boiler_kw_input || "-"}</h3>
                                                </div>
                                            </div>

                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Boiler Power Burner Type</h4>
                                                    <h3>{basicDetails?.boiler_power_burner_type || "-"}</h3>
                                                </div>
                                            </div>

                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Boiler Burner Model</h4>
                                                    <h3>{basicDetails?.boiler_burner_model || "-"}</h3>
                                                </div>
                                            </div>

                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Boiler Burner Serial</h4>
                                                    <h3>{basicDetails?.boiler_burner_serial || "-"}</h3>
                                                </div>
                                            </div>

                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Boiler Operating Pressure Type</h4>
                                                    <h3>{basicDetails?.boiler_pressure_type || "-"}</h3>
                                                </div>
                                            </div>

                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Confirm Boiler Pressure</h4>
                                                    <h3>{basicDetails?.confirm_boiler_pressure || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cnt-sec">
                            <div className="card ml-0">
                                {(basicDetails?.auxiliary?.length ? basicDetails?.auxiliary : [{}])?.map(e => (
                                    <>
                                        <div class="card-header" id="headingEight">
                                            <div className="outer-rcm recommendations otr-recom-div">
                                                <button
                                                    class="btn btn-link"
                                                    data-toggle="collapse"
                                                    data-target="#collapseEight"
                                                    aria-expanded="false"
                                                    aria-controls="collapseEight"
                                                >
                                                    Auxiliary Equipment Fields
                                                </button>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Equipment Name</h4>
                                                        <h3>{e?.auxiliary_equipment_name || "-"}</h3>
                                                    </div>
                                                </div>

                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Model</h4>
                                                        <h3>{e?.auxiliary_model || "-"}</h3>
                                                    </div>
                                                </div>

                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Serial</h4>
                                                        <h3>{e?.auxiliary_serial || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseEight" class="collapse" aria-labelledby="headingEight">
                                            <div class="card-body">
                                                <div className="outer-rcm recommendations ">
                                                    <div className="txt-rcm">
                                                        <div className="txt-dtl">
                                                            <h4>Date Manufactured</h4>
                                                            <h3>{e?.auxiliary_date_manufactured || "-"}</h3>
                                                        </div>
                                                    </div>

                                                    <div className="txt-rcm">
                                                        <div className="txt-dtl">
                                                            <h4>Manufacturer</h4>
                                                            <h3>{e?.auxiliary_manufacturer || "-"}</h3>
                                                        </div>
                                                    </div>

                                                    <div className="txt-rcm">
                                                        <div className="txt-dtl">
                                                            <h4>Condition</h4>
                                                            <h3>{e?.auxiliary_condition || "-"}</h3>
                                                        </div>
                                                    </div>

                                                    <div className="txt-rcm">
                                                        <div className="txt-dtl">
                                                            <h4>EUL</h4>
                                                            <h3>{e?.auxiliary_eul || "-"}</h3>
                                                        </div>
                                                    </div>

                                                    <div className="txt-rcm">
                                                        <div className="txt-dtl">
                                                            <h4>CRV</h4>
                                                            <NumberFormat
                                                                value={parseInt(e?.auxiliary_crv || 0)}
                                                                thousandSeparator={true}
                                                                displayType={"text"}
                                                                prefix={"$ "}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                        {dynamicBands &&
                            typeof dynamicBands === "object" &&
                            Object.entries(dynamicBands)?.map(([band, fields]) => (
                                <div className="cnt-sec">
                                    <div className="card ml-0">
                                        <div class="card-header" id={`heading-${this.getBandId(band)}`}>
                                            <div className="outer-rcm recommendations otr-recom-div">
                                                <button
                                                    class="btn btn-link"
                                                    data-toggle="collapse"
                                                    data-target={`#${this.getBandId(band)}`}
                                                    aria-expanded="false"
                                                    aria-controls={this.getBandId(band)}
                                                >
                                                    {band}
                                                </button>
                                                {fields
                                                    .filter((e, i) => i < 3)
                                                    .map(e => (
                                                        <div className="txt-rcm memo-new">
                                                            <div className="txt-dtl more-sec exe-view-sum">
                                                                {e.field_type === DYNAMIC_FIELD_TYPES?.MEMO ? (
                                                                    <div className="codeOtr memo-out ">
                                                                        <div className="head-view-form">
                                                                            <h4>{e.label}</h4>
                                                                            <button
                                                                                type="button"
                                                                                className="butn-expansion"
                                                                                fdprocessedid="xk6s85"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        showRichTextPopup: {
                                                                                            label: e.label,
                                                                                            value: basicDetails?.dynamic_fields?.[e.field_name]
                                                                                                ?.value,
                                                                                            show: true,
                                                                                            key: e.name
                                                                                        }
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <img src="/img/expand1.svg" alt="" />
                                                                            </button>
                                                                        </div>
                                                                        <CKEditor
                                                                            editor={Editor}
                                                                            config={{ ...editorConfiguration, toolbar: [] }}
                                                                            id="report-note-template"
                                                                            data={
                                                                                basicDetails?.dynamic_fields?.[e.field_name]?.value
                                                                                    ? basicDetails?.dynamic_fields?.[e.field_name]?.value
                                                                                    : "-"
                                                                            }
                                                                            onReady={editor => {
                                                                                editor?.setData(
                                                                                    basicDetails?.dynamic_fields?.[e.field_name]?.value
                                                                                        ? basicDetails?.dynamic_fields?.[e.field_name]?.value
                                                                                        : "-"
                                                                                );
                                                                                editor?.enableReadOnlyMode("my-feature-id");
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : e?.field_type === DYNAMIC_FIELD_TYPES.CURRENCY ||
                                                                  e.field_type === DYNAMIC_FIELD_TYPES.NUMBER ? (
                                                                    <>
                                                                        <h4>{e?.label}</h4>
                                                                        <NumberFormat
                                                                            value={basicDetails?.dynamic_fields?.[e.field_name]?.value || 0}
                                                                            decimalScale={e?.rules?.decimal_scale ?? 0}
                                                                            thousandSeparator={e?.rules?.thousand_separator || true}
                                                                            displayType={"text"}
                                                                            prefix={e?.rules?.prefix || "$"}
                                                                            suffix={e?.rules?.suffix}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <h4>{e?.label}</h4>
                                                                        <h3>{basicDetails?.dynamic_fields?.[e.field_name]?.value || "-"}</h3>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div id={this.getBandId(band)} class="collapse" aria-labelledby={`heading-${this.getBandId(band)}`}>
                                            <div class="card-body">
                                                <div className="outer-rcm recommendations ">
                                                    {fields
                                                        .filter((e, i) => i > 2)
                                                        .map(e => (
                                                            <div className="txt-rcm">
                                                                <div className="txt-dtl">
                                                                    <h4>{e?.label}</h4>
                                                                    <h3>
                                                                        {e?.field_type === DYNAMIC_FIELD_TYPES.CURRENCY ? (
                                                                            <NumberFormat
                                                                                value={basicDetails?.dynamic_fields?.[e.field_name]?.value || 0}
                                                                                decimalScale={e?.rules?.decimal_scale ?? 0}
                                                                                thousandSeparator={true}
                                                                                displayType={"text"}
                                                                                prefix={"$ "}
                                                                            />
                                                                        ) : (
                                                                            basicDetails?.dynamic_fields?.[e.field_name]?.value || "-"
                                                                        )}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(BasicDetails);
