import { isArray, isEmpty } from "lodash";
import React from "react";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import FilterIcon from "../../../assets/img/dashboard/filter-butn.svg";
import moment from "moment";

export const AppliedFilters = ({ onCancel, myFilters, openFiltersPopup, showFiltersIcon, tableConfig }) => {
    const renderCABandFilter = filters => {
        if (filters) {
            return (
                <div>
                    <span className="badge-otr">Common filter</span>
                    {Object.entries(filters).map(([key, value]) => {
                        let label;
                        if (tableConfig && Object.keys(tableConfig)?.length) {
                            label = Object.values(tableConfig || {})?.find(field => field.searchKey === key)?.label || "";
                        }
                        label = label ? label : key;
                        if (value?.length > 0) {
                            return (
                                <React.Fragment key={key}>
                                    <span className="filter">
                                        {label}: {value?.map(e => e)?.join(", ")}
                                    </span>
                                </React.Fragment>
                            );
                        }
                    })}
                </div>
            );
        }
        return null;
    };
    const renderWildCardFilters = values => {
        return Object.keys(values).map(fi => {
            let label;
            if (tableConfig && Object.keys(tableConfig)?.length) {
                label = Object.values(tableConfig || {})?.find(field => field.searchKey === fi)?.label || "";
            }
            label = label ? label : fi;
            return values[fi] && (values[fi].key || values[fi].filters?.includes("null") || values[fi].filters?.includes("not_null")) ? (
                <span key={fi} className="filter">
                    {`${label} : ${values[fi].key} [${values[fi].filters}]`}
                </span>
            ) : null;
        });
    };
    const configFilter = myFilters?.band1?.config_filter || myFilters?.config_filter;
    const renderFilterMore = () => {
        if (configFilter) {
            return Object.keys(configFilter).map(key => {
                const value = configFilter[key];
                if (key === "global_search" && value) {
                    return (
                        <span className="badge-otr" key={key}>
                            Global search: {value}
                        </span>
                    );
                }
                if (key === "Water" && value) {
                    return (
                        <span className="badge-otr" key={key}>
                            Water: {value}
                        </span>
                    );
                }
                if (key === "surveyor" && value) {
                    return (
                        <span className="badge-otr" key={key}>
                            Surveyor: {value}
                        </span>
                    );
                }

                if (key === "infrastructure_request" && value) {
                    return (
                        <span className="badge-otr" key={key}>
                            Capital Request: {value}
                        </span>
                    );
                }
                if (key === "energy" && value) {
                    return (
                        <span className="badge-otr" key={key}>
                            Energy: {value}
                        </span>
                    );
                }
                if (key === "recommendation_type" && value) {
                    return (
                        <span className="badge-otr" key={key}>
                            Recommendation Type: {value}
                        </span>
                    );
                }
                if (key === "facility_master_plan" && value) {
                    return (
                        <span className="badge-otr" key={key}>
                            Facility Master Plan: {value}
                        </span>
                    );
                }
                if (key === "image_or_not" && value) {
                    return (
                        <span className="badge-otr" key={key}>
                            Image: {value}
                        </span>
                    );
                }

                return null;
            });
        }

        return null;
    };
    return (
        <div className="modal modal-region modal-view modal-applied-filter" id="modalId" style={{ display: "block" }} tabIndex="-1">
            <Draggable handle=".draggable" cancel=".not-draggable">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header draggable">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <div className="txt-hed">Applied Filters </div>
                            </h5>
                            {showFiltersIcon && (
                                <button
                                    className={`filter-button blue-common-btn mr-4 cursor-pointer not-draggable`}
                                    data-tip={`View Filters`}
                                    data-for="dashboard-icons-right"
                                    onClick={() => openFiltersPopup()}
                                >
                                    <img src={FilterIcon} alt="" />
                                    <span> Filters</span>
                                </button>
                            )}
                            <button type="button" className="close not-draggable" onClick={onCancel}>
                                <span aria-hidden="true">
                                    <img src="/img/close.svg" alt="" />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body region-otr filter-apply core-fil-outer">
                            <div className="fil-badge-otr">
                                <div className="tag-ara">
                                    <div className={"tag-scrol"}>
                                        {(myFilters?.band1?.config_filter_names?.list || myFilters?.config_filter_names?.list) &&
                                            renderCABandFilter(myFilters?.band1?.config_filter_names?.list || myFilters?.config_filter_names?.list)}
                                        {(myFilters?.band1?.config_filter_names?.CA_BandFilter || myFilters?.config_filter_names?.CA_BandFilter) &&
                                            renderCABandFilter(
                                                myFilters?.band1?.config_filter_names?.CA_BandFilter || myFilters?.config_filter_names?.CA_BandFilter
                                            )}
                                        {myFilters?.band1?.config_filter_names || myFilters?.config_filter_names
                                            ? Object.keys(myFilters?.band1?.config_filter_names || myFilters?.config_filter_names).map((f, key) => {
                                                  if (
                                                      f === "filters" &&
                                                      ((myFilters?.band1?.config_filter_names &&
                                                          Object.keys(myFilters?.band1?.config_filter_names[f])?.length) ||
                                                          (myFilters?.config_filter_names && Object.keys(myFilters?.config_filter_names[f])?.length))
                                                  ) {
                                                      return (
                                                          <React.Fragment key={key}>
                                                              <span className="badge-otr">Wild card filter </span>
                                                              {renderWildCardFilters(
                                                                  myFilters?.band1?.config_filter_names[f] || myFilters?.config_filter_names[f]
                                                              )}
                                                          </React.Fragment>
                                                      );
                                                  }
                                              })
                                            : null}
                                        {renderFilterMore()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    );
};
