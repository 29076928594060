import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { DragDropContext, Droppable, Draggable as TileDrag } from "react-beautiful-dnd";
import { reorderArray } from "../../../config/utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import qs from "query-string";
import { useSelector } from "react-redux";

const AssetOnePagerExportModal = ({ onCancel, onSettingsSave, saveLoader }) => {
    const { search } = useLocation();
    const query = qs.parse(search);
    const client_id = query?.client_id ?? "";
    const [selectedBands, setSelectedBands] = useState([]);
    const { exportSettingsResponse } = useSelector(state => state.assetReducer);

    useEffect(() => {
        if (exportSettingsResponse?.success) {
            setSelectedBands(exportSettingsResponse?.data?.asset_properties);
        }
    }, [exportSettingsResponse]);

    const handleSelectBand = key => {
        let tempSelectedBands = [...selectedBands];
        tempSelectedBands = tempSelectedBands.map(e => (key === e.band_id ? { ...e, is_active: !e.is_active } : e));
        setSelectedBands([...tempSelectedBands]);
    };

    const onEnd = result => {
        if (!result.destination || result?.destination?.index === 8) {
            return;
        }
        const keyList = reorderArray(selectedBands, result.source.index, result.destination.index);
        setSelectedBands(keyList);
    };

    return (
        <React.Fragment>
            <div className="modal modal-region modal-view inbox-modal asset-pager" id="modalId" tabIndex="-1" style={{ display: "block" }}>
                {/* <Draggable handle=".modal-header"> */}
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header draggable">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <div className="txt-hed">Asset One Pager Settings</div>
                            </h5>
                            <button type="button" className="close" onClick={onCancel}>
                                <span aria-hidden="true">
                                    <img src="/img/close.svg" alt="" />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body region-otr sm-chart-upload">
                            <div className="upload-area not-draggable">
                                <div className="upload-sec cursor-hand" role="button" tabIndex="0">
                                    <input type="file" multiple="" autocomplete="off" tabIndex="-1" style={{ display: "none" }} />
                                </div>
                            </div>
                            <div className="col-md-12 main-sec">
                                <>
                                    <DragDropContext onDragEnd={onEnd}>
                                        <Droppable droppableId="droppable">
                                            {provided => (
                                                <div ref={provided.innerRef} className="col-md-12 check-otr d-flex checkbox-sec">
                                                    {selectedBands.map((keyItem, i) => (
                                                        <TileDrag
                                                            isDragDisabled={keyItem?.band_id === 9}
                                                            key={i}
                                                            draggableId={`draggable-${i}`}
                                                            index={i}
                                                        >
                                                            {provided => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    key={`draggable-${i}`}
                                                                    className="col-md-12 box-otr box-wide "
                                                                >
                                                                    <div className="rem-txt">
                                                                        <label className="container-check">
                                                                            {keyItem["label"]}

                                                                            <input
                                                                                type="checkbox"
                                                                                checked={keyItem["is_active"]}
                                                                                onChange={() => handleSelectBand(keyItem?.band_id)}
                                                                            />
                                                                            <span className="checkmark" />
                                                                        </label>
                                                                    </div>
                                                                    <div className="right-box-content">
                                                                        <div className="image-drag">
                                                                            <img src="/img/Group 2.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </TileDrag>
                                                    ))}
                                                    {!selectedBands?.length ? (
                                                        <div className="col-md-12 text-center mt-5">No records found!</div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </>
                            </div>
                            <div className="btn-sec">
                                <div className="text-right btnOtr edit-cmn-btn">
                                    <button type="button" className="btn btn-secondary btnClr mr-3" data-dismiss="modal" onClick={() => onCancel()}>
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-create save export-btn-asset"
                                        onClick={() => !saveLoader && onSettingsSave({ asset_properties: selectedBands, client_id })}
                                    >
                                        Save
                                        {saveLoader ? <span className="spinner-border spinner-border-sm pl-2 ml-2" role="status"></span> : ""}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </Draggable> */}
            </div>
        </React.Fragment>
    );
};

export default AssetOnePagerExportModal;
