/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ProfilePopup } from "../../dashboard/components/ProfilePopup";
import { useDispatch } from "react-redux";
import actions from "../actions";

export const Header = () => {
    const { code } = useParams();
    const dispatch = useDispatch();
    const email = localStorage.getItem("crRequestorMail");
    const history = useHistory();
    const [showSettingsDropDown, setSettingsDropDown] = useState();
    const handleLogout = () => {
        dispatch(actions.logoutCRUser());
    };
    return (
        <div className="col-md-12 navbarOtr">
            <nav className="navbar navbar-expand-lg col-md-12">
                <a className="logo-small navbar-brand cursor-pointer" onClick={() => history.push(`/capital-request/${code}`)}>
                    <img src={"/img/fca-logo.svg"} alt="" />
                </a>

                <div className="navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown profileImg" id="profileButton">
                            <a
                                className="nav-link dropdown-toggle d-flex flex-row align-items-center"
                                onClick={() => setSettingsDropDown(!showSettingsDropDown)}
                            >
                                <div className="profileImgSec">
                                    <img src={"/img/user-icon.png"} alt="" />
                                </div>
                                <span className="profName">{email} </span>
                            </a>
                            {showSettingsDropDown && (
                                <ProfilePopup
                                    handleLogout={handleLogout}
                                    hasUsername={false}
                                    hasRoleName={false}
                                    hasProfileMenu={false}
                                    hasResetMenu={false}
                                />
                            )}
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
};
