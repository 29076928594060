//narrative band types
export const BandTypes = {
    textBand: 1,
    singleImageBand: 2,
    doubleImageBand: 3,
    insertBand: 4,
    recommendationBand: 5,
    reportNoteBand: 6,
    chartBand: 7
};

export const GET_BUILDING_MENU_REQUEST = "GET_BUILDING_MENU_REQUEST";
export const GET_BUILDING_MENU_SUCCESS = "GET_BUILDING_MENU_SUCCESS";
export const GET_BUILDING_MENU_FAILURE = "GET_BUILDING_MENU_FAILURE";

export const GET_TRADE_MENU_REQUEST = "GET_TRADE_MENU_REQUEST";
export const GET_TRADE_MENU_SUCCESS = "GET_TRADE_MENU_SUCCESS";
export const GET_TRADE_MENU_FAILURE = "GET_TRADE_MENU_FAILURE";

export const GET_SYSTEM_MENU_REQUEST = "GET_SYSTEM_MENU_REQUEST";
export const GET_SYSTEM_MENU_SUCCESS = "GET_SYSTEM_MENU_SUCCESS";
export const GET_SYSTEM_MENU_FAILURE = "GET_SYSTEM_MENU_FAILURE";

export const GET_SUB_SYSTEM_MENU_REQUEST = "GET_SUB_SYSTEM_MENU_REQUEST";
export const GET_SUB_SYSTEM_MENU_SUCCESS = "GET_SUB_SYSTEM_MENU_SUCCESS";
export const GET_SUB_SYSTEM_MENU_FAILURE = "GET_SUB_SYSTEM_MENU_FAILURE";

export const GET_SITE_MENU_REQUEST = "GET_SITE_MENU_REQUEST";
export const GET_SITE_MENU_SUCCESS = "GET_SITE_MENU_SUCCESS";
export const GET_SITE_MENU_FAILURE = "GET_SITE_MENU_FAILURE";

export const GET_REGION_MENU_REQUEST = "GET_REGION_MENU_REQUEST";
export const GET_REGION_MENU_SUCCESS = "GET_REGION_MENU_SUCCESS";
export const GET_REGION_MENU_FAILURE = "GET_REGION_MENU_FAILURE";

export const GET_PROJECT_MENU_REQUEST = "GET_PROJECT_MENU_REQUEST";
export const GET_PROJECT_MENU_SUCCESS = "GET_PROJECT_MENU_SUCCESS";
export const GET_PROJECT_MENU_FAILURE = "GET_PROJECT_MENU_FAILURE";

export const GET_SITE_BUILDING_MENU_REQUEST = "GET_SITE_BUILDING_MENU_REQUEST";
export const GET_SITE_BUILDING_MENU_SUCCESS = "GET_SITE_BUILDING_MENU_SUCCESS";
export const GET_SITE_BUILDING_MENU_FAILURE = "GET_SITE_BUILDING_MENU_FAILURE";

// Upload Image
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

// Get all Images
export const GET_ALL_IMAGES_REQUEST = "GET_ALL_IMAGES_REQUEST";
export const GET_ALL_IMAGES_SUCCESS = "GET_ALL_IMAGES_SUCCESS";
export const GET_ALL_IMAGES_FAILURE = "GET_ALL_IMAGES_FAILURE";

// Delete Images
export const DELETE_IMAGES_REQUEST = "DELETE_IMAGES_REQUEST";
export const DELETE_IMAGES_SUCCESS = "DELETE_IMAGES_SUCCESS";
export const DELETE_IMAGES_FAILURE = "DELETE_IMAGES_FAILURE";

// Update Image Description
export const UPDATE_IMAGE_COMMENT_REQUEST = "UPDATE_IMAGE_COMMENT_REQUEST";
export const UPDATE_IMAGE_COMMENT_SUCCESS = "UPDATE_IMAGE_COMMENT_SUCCESS";
export const UPDATE_IMAGE_COMMENT_FAILURE = "UPDATE_IMAGE_COMMENT_FAILURE";

export const ADD_NARRATIVE_REQUEST = "ADD_NARRATIVE_REQUEST";
export const ADD_NARRATIVE_SUCCESS = "ADD_NARRATIVE_SUCCESS";
export const ADD_NARRATIVE_FAILURE = "ADD_NARRATIVE_FAILURE";

export const GET_NARRATIVE_REQUEST = "GET_NARRATIVE_REQUEST";
export const GET_NARRATIVE_SUCCESS = "GET_NARRATIVE_SUCCESS";
export const GET_NARRATIVE_FAILURE = "GET_NARRATIVE_FAILURE";

// Get RECOMMENDATION Images
export const GET_RECOMMENDATION_IMAGES_REQUEST = "GET_RECOMMENDATION_IMAGES_REQUEST";
export const GET_RECOMMENDATION_IMAGES_SUCCESS = "GET_RECOMMENDATION_IMAGES_SUCCESS";
export const GET_RECOMMENDATION_IMAGES_FAILURE = "GET_RECOMMENDATION_IMAGES_FAILURE";

// Get RECOMMENDATION Notes
export const GET_RECOMMENDATION_NOTES_REQUEST = "GET_RECOMMENDATION_NOTES_REQUEST";
export const GET_RECOMMENDATION_NOTES_SUCCESS = "GET_RECOMMENDATION_NOTES_SUCCESS";
export const GET_RECOMMENDATION_NOTES_FAILURE = "GET_RECOMMENDATION_NOTES_FAILURE";

// UPDATE RECOMMENDATION Images
export const UPDATE_RECOM_IMAGE_REQUEST = "UPDATE_RECOM_IMAGE_REQUEST";
export const UPDATE_RECOM_IMAGE_SUCCESS = "UPDATE_RECOM_IMAGE_SUCCESS";
export const UPDATE_RECOM_IMAGE_FAILURE = "UPDATE_RECOM_IMAGE_FAILURE";

// Get RECOMMENDATION
export const GET_NARRATIVE_RECOMMENDATION_REQUEST = "GET_NARRATIVE_RECOMMENDATION_REQUEST";
export const GET_NARRATIVE_RECOMMENDATION_SUCCESS = "GET_NARRATIVE_RECOMMENDATION_SUCCESS";
export const GET_NARRATIVE_RECOMMENDATION_FAILURE = "GET_NARRATIVE_RECOMMENDATION_FAILURE";

export const GET_RECOMMENDATION_BY_ID_REQUEST = "GET_RECOMMENDATION_BY_ID_REQUEST";
export const GET_RECOMMENDATION_BY_ID_SUCCESS = "GET_RECOMMENDATION_BY_ID_SUCCESS";
export const GET_RECOMMENDATION_BY_ID_FAILURE = "GET_RECOMMENDATION_BY_ID_FAILURE";

// Update Recommendation Entity Params
export const UPDATE_NARRATIVE_RECOMMENDATION_ENTITY_PARAMS_SUCCESS = "UPDATE_NARRATIVE_RECOMMENDATION_ENTITY_PARAMS_SUCCESS";
export const UPDATE_NARRATIVE_RECOMMENDATION_ENTITY_PARAMS_FAILURE = "UPDATE_NARRATIVE_RECOMMENDATION_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_RECOMMENDATION_EXPORT_REQUEST = "GET_RECOMMENDATION_EXPORT_REQUEST";
export const GET_RECOMMENDATION_EXPORT_SUCCESS = "GET_RECOMMENDATION_EXPORT_SUCCESS";
export const GET_RECOMMENDATION_EXPORT_FAILURE = "GET_RECOMMENDATION_EXPORT_FAILURE";

export const GET_REPORT_EXPORT_REQUEST = "GET_REPORT_EXPORT_REQUEST";
export const GET_REPORT_EXPORT_SUCCESS = "GET_REPORT_EXPORT_SUCCESS";
export const GET_REPORT_EXPORT_FAILURE = "GET_REPORT_EXPORT_FAILURE";

export const ADD_INSERT_REQUEST = "ADD_INSERT_REQUEST";
export const ADD_INSERT_SUCCESS = "ADD_INSERT_SUCCESS";
export const ADD_INSERT_FAILURE = "ADD_INSERT_FAILURE";

export const GET_INSERT_REQUEST = "GET_INSERT_REQUEST";
export const GET_INSERT_SUCCESS = "GET_INSERT_SUCCESS";
export const GET_INSERT_FAILURE = "GET_INSERT_FAILURE";

export const UPDATE_INSERT_REQUEST = "UPDATE_INSERT_REQUEST";
export const UPDATE_INSERT_SUCCESS = "UPDATE_INSERT_SUCCESS";
export const UPDATE_INSERT_FAILURE = "UPDATE_INSERT_FAILURE";

export const DELETE_INSERT_REQUEST = "DELETE_INSERT_REQUEST";
export const DELETE_INSERT_SUCCESS = "DELETE_INSERT_SUCCESS";
export const DELETE_INSERT_FAILURE = "DELETE_INSERT_FAILURE";

export const MARK_AS_COMPLETE_PYTHON_REQUEST = "MARK_AS_COMPLETE_PYTHON_REQUEST";
export const MARK_AS_COMPLETE_PYTHON_SUCCESS = "MARK_AS_COMPLETE_PYTHON_SUCCESS";
export const MARK_AS_COMPLETE_PYTHON_FAILURE = "MARK_AS_COMPLETE_PYTHON_FAILURE";

export const MARK_AS_COMPLETE_RUBY_REQUEST = "MARK_AS_COMPLETE_RUBY_REQUEST";
export const MARK_AS_COMPLETE_RUBY_SUCCESS = "MARK_AS_COMPLETE_RUBY_SUCCESS";
export const MARK_AS_COMPLETE_RUBY_FAILURE = "MARK_AS_COMPLETE_RUBY_FAILURE";

export const GET_SELECTED_RECOM_IMAGES_REQUEST = "GET_SELECTED_RECOM_IMAGES_REQUEST";
export const GET_SELECTED_RECOM_IMAGES_SUCCESS = "GET_SELECTED_RECOM_IMAGES_SUCCESS";
export const GET_SELECTED_RECOM_IMAGES_FAILURE = "GET_SELECTED_RECOM_IMAGES_FAILURE";

export const DELETE_NARRATIVE_REQUEST = "DELETE_NARRATIVE_REQUEST";
export const DELETE_NARRATIVE_SUCCESS = "DELETE_NARRATIVE_SUCCESS";
export const DELETE_NARRATIVE_FAILURE = "DELETE_NARRATIVE_FAILURE";

export const PDF_REPORT_REQUEST = "PDF_REPORT_REQUEST";
export const PDF_REPORT_SUCCESS = "PDF_REPORT_SUCCESS";
export const PDF_REPORT_FAILURE = "PDF_REPORT_FAILURE";

export const GET_EXPORT_HISTORY_REQUEST = "GET_EXPORT_HISTORY_REQUEST";
export const GET_EXPORT_HISTORY_SUCCESS = "GET_EXPORT_HISTORY_SUCCESS";
export const GET_EXPORT_HISTORY_FAILURE = "GET_EXPORT_HISTORY_FAILURE";

export const GET_LOGS_REQUEST = "GET_LOGS_REQUEST";
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS";
export const GET_LOGS_FAILURE = "GET_LOGS_FAILURE";

export const UPDATE_LOG_REQUEST = "UPDATE_LOG_REQUEST";
export const UPDATE_LOG_SUCCESS = "UPDATE_LOG_SUCCESS";
export const UPDATE_LOG_FAILURE = "UPDATE_LOG_FAILURE";

export const UPDATE_EXPORT_HISTORY_REQUEST = "UPDATE_EXPORT_HISTORY_REQUEST";
export const UPDATE_EXPORT_HISTORY_SUCCESS = "UPDATE_EXPORT_HISTORY_SUCCESS";
export const UPDATE_EXPORT_HISTORY_FAILURE = "UPDATE_EXPORT_HISTORY_FAILURE";

export const GET_NARRATIVE_TEMPLATES_REQUEST = "GET_NARRATIVE_TEMPLATES_REQUEST";
export const GET_NARRATIVE_TEMPLATES_SUCCESS = "GET_NARRATIVE_TEMPLATES_SUCCESS";
export const GET_NARRATIVE_TEMPLATES_FAILURE = "GET_NARRATIVE_TEMPLATES_FAILURE";

export const AUTO_POPULATE_TABLE_TEMPLATES_REQUEST = "AUTO_POPULATE_TABLE_TEMPLATES_REQUEST";
export const AUTO_POPULATE_TABLE_TEMPLATES_SUCCESS = "AUTO_POPULATE_TABLE_TEMPLATES_SUCCESS";
export const AUTO_POPULATE_TABLE_TEMPLATES_FAILURE = "AUTO_POPULATE_TABLE_TEMPLATES_FAILURE";

export const ADD_USER_ACTIVITY_LOG_REQUEST = "ADD_USER_ACTIVITY_LOG_REQUEST";
export const ADD_USER_ACTIVITY_LOG_SUCCESS = "ADD_USER_ACTIVITY_LOG_SUCCESS";
export const ADD_USER_ACTIVITY_LOG_FAILURE = "ADD_USER_ACTIVITY_LOG_FAILURE";

export const ASSIGN_IMAGES_FROM_MASTER_REQUEST = "ASSIGN_IMAGES_FROM_MASTER_REQUEST";
export const ASSIGN_IMAGES_FROM_MASTER_SUCCESS = "ASSIGN_IMAGES_FROM_MASTER_SUCCESS";
export const ASSIGN_IMAGES_FROM_MASTER_FAILURE = "ASSIGN_IMAGES_FROM_MASTER_FAILURE";

export const GET_BUILDING_REPORT_PRARGRAPHS_MENU_REQUEST = "GET_BUILDING_REPORT_PRARGRAPHS_MENU_REQUEST";
export const GET_BUILDING_REPORT_PRARGRAPHS_MENU_SUCCESS = "GET_BUILDING_REPORT_PRARGRAPHS_MENU_SUCCESS";
export const GET_BUILDING_REPORT_PRARGRAPHS_MENU_FAILURE = "GET_BUILDING_REPORT_PRARGRAPHS_MENU_FAILURE";

export const GET_BUILDING_CHILD_PRARGRAPHS_MENU_REQUEST = "GET_BUILDING_CHILD_PRARGRAPHS_MENU_REQUEST";
export const GET_BUILDING_CHILD_PRARGRAPHS_MENU_SUCCESS = "GET_BUILDING_CHILD_PRARGRAPHS_MENU_SUCCESS";
export const GET_BUILDING_CHILD_PRARGRAPHS_MENU_FAILURE = "GET_BUILDING_CHILD_PRARGRAPHS_MENU_FAILURE";

export const GET_SITE_REPORT_PRARGRAPHS_MENU_REQUEST = "GET_SITE_REPORT_PRARGRAPHS_MENU_REQUEST";
export const GET_SITE_REPORT_PRARGRAPHS_MENU_SUCCESS = "GET_SITE_REPORT_PRARGRAPHS_MENU_SUCCESS";
export const GET_SITE_REPORT_PRARGRAPHS_MENU_FAILURE = "GET_SITE_REPORT_PRARGRAPHS_MENU_FAILURE";

export const GET_SITE_CHILD_PRARGRAPHS_MENU_REQUEST = "GET_SITE_CHILD_PRARGRAPHS_MENU_REQUEST";
export const GET_SITE_CHILD_PRARGRAPHS_MENU_SUCCESS = "GET_SITE_CHILD_PRARGRAPHS_MENU_SUCCESS";
export const GET_SITE_CHILD_PRARGRAPHS_MENU_FAILURE = "GET_SITE_CHILD_PRARGRAPHS_MENU_FAILURE";

export const GET_REGION_REPORT_PRARGRAPHS_MENU_REQUEST = "GET_REGION_REPORT_PRARGRAPHS_MENU_REQUEST";
export const GET_REGION_REPORT_PRARGRAPHS_MENU_SUCCESS = "GET_REGION_REPORT_PRARGRAPHS_MENU_SUCCESS";
export const GET_REGION_REPORT_PRARGRAPHS_MENU_FAILURE = "GET_REGION_REPORT_PRARGRAPHS_MENU_FAILURE";

export const GET_REGION_CHILD_PRARGRAPHS_MENU_REQUEST = "GET_REGION_CHILD_PRARGRAPHS_MENU_REQUEST";
export const GET_REGION_CHILD_PRARGRAPHS_MENU_SUCCESS = "GET_REGION_CHILD_PRARGRAPHS_MENU_SUCCESS";
export const GET_REGION_CHILD_PRARGRAPHS_MENU_FAILURE = "GET_REGION_CHILD_PRARGRAPHS_MENU_FAILURE";

export const GET_PROJECT_REPORT_PRARGRAPHS_MENU_REQUEST = "GET_PROJECT_REPORT_PRARGRAPHS_MENU_REQUEST";
export const GET_PROJECT_REPORT_PRARGRAPHS_MENU_SUCCESS = "GET_PROJECT_REPORT_PRARGRAPHS_MENU_SUCCESS";
export const GET_PROJECT_REPORT_PRARGRAPHS_MENU_FAILURE = "GET_PROJECT_REPORT_PRARGRAPHS_MENU_FAILURE";

export const GET_PROJECT_CHILD_PRARGRAPHS_MENU_REQUEST = "GET_PROJECT_CHILD_PRARGRAPHS_MENU_REQUEST";
export const GET_PROJECT_CHILD_PRARGRAPHS_MENU_SUCCESS = "GET_PROJECT_CHILD_PRARGRAPHS_MENU_SUCCESS";
export const GET_PROJECT_CHILD_PRARGRAPHS_MENU_FAILURE = "GET_PROJECT_CHILD_PRARGRAPHS_MENU_FAILURE";

export const GET_NARRATIVE_CHART_REQUEST = "GET_NARRATIVE_CHART_REQUEST";
export const GET_NARRATIVE_CHART_SUCCESS = "GET_NARRATIVE_CHART_SUCCESS";
export const GET_NARRATIVE_CHART_FAILURE = "GET_NARRATIVE_CHART_FAILURE";

export const GET_CHART_DETAILS_REQUEST = "GET_CHART_DETAILS_REQUEST";
export const GET_CHART_DETAILS_SUCCESS = "GET_CHART_DETAILS_SUCCESS";
export const GET_CHART_DETAILS_FAILURE = "GET_CHART_DETAILS_FAILURE";

export const GET_PROJECTS_MENU_REQUEST = "GET_PROJECTS_MENU_REQUEST";
export const GET_PROJECTS_MENU_SUCCESS = "GET_PROJECTS_MENU_SUCCESS";
export const GET_PROJECTS_MENU_FAILURE = "GET_PROJECTS_MENU_FAILURE";

export const GET_REGIONS_MENU_REQUEST = "GET_REGIONS_MENU_REQUEST";
export const GET_REGIONS_MENU_SUCCESS = "GET_REGIONS_MENU_SUCCESS";
export const GET_REGIONS_MENU_FAILURE = "GET_REGIONS_MENU_FAILURE";

export const DISABLE_COMMANDS = [
    "setTableColumnHeader",
    "setTableRowHeader",
    "mergeTableCellUp",
    "mergeTableCellDown",
    "splitTableCellVertically",
    "splitTableCellHorizontally",
    "tableBorderColor",
    "tableBorderStyle",
    "tableBorderWidth",
    "tableAlignment",
    "tableWidth",
    "tableHeight",
    "tableBackgroundColor",
    "tableCellBorderStyle",
    "tableCellBorderColor",
    "tableCellBorderWidth",
    "tableCellHorizontalAlignment",
    // "tableCellWidth",
    // "tableCellHeight",
    "tableCellPadding",
    // "tableCellBackgroundColor",
    "tableCellVerticalAlignment"
];

export const SPECIAL_REPORT_NARRATABLES = [
    "BuildingSpecialReport",
    "BuildingReportParagraph",
    "BuildingChildParagraph",
    "SiteSpecialReport",
    "SiteReportParagraph",
    "SiteChildParagraph",
    "RegionSpecialReport",
    "RegionReportParagraph",
    "RegionChildParagraph",
    "ProjectSpecialReport",
    "ProjectReportParagraph",
    "ProjectChildParagraph"
];
