import React from "react";

const ViewPropertyFilters = ({ chartData = {}, entity, ...props }) => {
    const getSelectedFilters = () => {
        let filterList = [];
        if (chartData?.[entity]?.band1?.filter_label) {
            Object.keys(chartData[entity].band1.filter_label)
                ?.filter(e => e !== "FCA Projects")
                ?.map(filterItem => {
                    const filterValues = chartData[entity].band1.filter_label[filterItem];
                    if (Array.isArray(filterValues)) {
                        filterList.push({ name: filterItem, values: [...filterValues] });
                    } else if (typeof filterValues === "object" && filterValues !== null) {
                        filterList.push({ name: filterItem, values: Object.entries(filterValues) });
                    }
                });
        }
        return filterList || [];
    };
    return (
        <div class="list-badge-outer">
            {getSelectedFilters().map((filterItem, index) => (
                <div class="list-inner col-md-6" key={index}>
                    <h3>{filterItem.name}</h3>
                    <ul class="badge-ul">
                        {filterItem.values?.length !== 0 &&
                            filterItem.values.map((filterValue, i) => (
                                <li class="badge-list">
                                    <span>{typeof filterValue === "object" ? filterValue && Object.values(filterValue) : filterValue}</span>
                                </li>
                            ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};
export default ViewPropertyFilters;
