export const GET_USER_EMAIL_REQUEST = "GET_USER_EMAIL_REQUEST";
export const GET_USER_EMAIL_SUCCESS = "GET_USER_EMAIL_SUCCESS";
export const GET_USER_EMAIL_FAILURE = "GET_USER_EMAIL_FAILURE";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const GET_ALL_MAIL_DATA_REQUEST = "GET_ALL_MAIL_DATA_REQUEST";
export const GET_ALL_MAIL_DATA_SUCCESS = "GET_ALL_MAIL_DATA_SUCCESS";
export const GET_ALL_MAIL_DATA_FAILURE = "GET_ALL_MAIL_DATA_FAILURE";

export const GET_INBOX_MAIL_DATA_REQUEST = "GET_INBOX_MAIL_DATA_REQUEST";
export const GET_INBOX_MAIL_DATA_SUCCESS = "GET_INBOX_MAIL_DATA_SUCCESS";
export const GET_INBOX_MAIL_DATA_FAILURE = "GET_INBOX_MAIL_DATA_FAILURE";

export const UPDATE_EMAIL_DATA_ENTITY_PARAMS_SUCCESS = "UPDATE_EMAIL_DATA_ENTITY_PARAMS_SUCCESS";
export const UPDATE_EMAIL_DATA_ENTITY_PARAMS_FAILURE = "UPDATE_EMAIL_DATA_ENTITY_PARAMS_FAILURE";
