export const GET_CATEGORY_SETTINGS_DATA_REQUEST = "GET_CATEGORY_SETTINGS_DATA_REQUEST";
export const GET_CATEGORY_SETTINGS_DATA_SUCCESS = "GET_CATEGORY_SETTINGS_DATA_SUCCESS";
export const GET_CATEGORY_SETTINGS_DATA_FAILURE = "GET_CATEGORY_SETTINGS_DATA_FAILURE";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILURE = "ADD_CATEGORY_FAILURE";

export const GET_CATEGORY_BY_ID_REQUEST = "GET_CATEGORY_BY_ID_REQUEST";
export const GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS";
export const GET_CATEGORY_BY_ID_FAILURE = "GET_CATEGORY_BY_ID_FAILURE";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

export const UPDATE_CATEGORY_ENTITY_PARAMS_SUCCESS = "UPDATE_CATEGORY_ENTITY_PARAMS_SUCCESS";
export const UPDATE_CATEGORY_ENTITY_PARAMS_FAILURE = "UPDATE_CATEGORY_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_CATEGORY_LOG_REQUEST = "GET_ALL_CATEGORY_LOG_REQUEST";
export const GET_ALL_CATEGORY_LOG_SUCCESS = "GET_ALL_CATEGORY_LOG_SUCCESS";
export const GET_ALL_CATEGORY_LOG_FAILURE = "GET_ALL_CATEGORY_LOG_FAILURE";

export const RESTORE_SETTINGS_LOG_REQUEST = "RESTORE_SETTINGS_LOG_REQUEST";
export const RESTORE_SETTINGS_LOG_SUCCESS = "RESTORE_SETTINGS_LOG_SUCCESS";
export const RESTORE_SETTINGS_LOG_FAILURE = "RESTORE_SETTINGS_LOG_FAILURE";

export const DELETE_SETTINGS_LOG_REQUEST = "DELETE_SETTINGS_LOG_REQUEST";
export const DELETE_SETTINGS_LOG_SUCCESS = "DELETE_SETTINGS_LOG_SUCCESS";
export const DELETE_SETTINGS_LOG_FAILURE = "DELETE_SETTINGS_LOG_FAILURE";

export const GET_CATEGORY_EXPORT_REQUEST = "GET_CATEGORY_EXPORT_REQUEST";
export const GET_CATEGORY_EXPORT_SUCCESS = "GET_CATEGORY_EXPORT_SUCCESS";
export const GET_CATEGORY_EXPORT_FAILURE = "GET_CATEGORY_EXPORT_FAILURE";
