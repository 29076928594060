export const GET_SHIFT_CSP_HISTORY_REQUEST = "GET_SHIFT_CSP_HISTORY_REQUEST";
export const GET_SHIFT_CSP_HISTORY_SUCCESS = "GET_SHIFT_CSP_HISTORY_SUCCESS";
export const GET_SHIFT_CSP_HISTORY_FAILURE = "GET_SHIFT_CSP_HISTORY_FAILURE";

export const DELETE_SHIFT_HISTORY_REQUEST = "DELETE_SHIFT_HISTORY_REQUEST";
export const DELETE_SHIFT_HISTORY_SUCCESS = "DELETE_SHIFT_HISTORY_SUCCESS";
export const DELETE_SHIFT_HISTORY_FAILURE = "DELETE_SHIFT_HISTORY_FAILURE";

export const GET_LIST_FOR_SHIFT_HISTORY_FILTER_REQUEST = "GET_LIST_FOR_SHIFT_HISTORY_FILTER_REQUEST";
export const GET_LIST_FOR_SHIFT_HISTORY_FILTER_SUCCESS = "GET_LIST_FOR_SHIFT_HISTORY_FILTER_SUCCESS";
export const GET_LIST_FOR_SHIFT_HISTORY_FILTER_FAILURE = "GET_LIST_FOR_SHIFT_HISTORY_FILTER_FAILURE";
