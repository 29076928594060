import React, { useEffect, useState } from "react";

import BasicDetails from "../../common/components/BasicDetails";
import { fields } from "../constants";
import useCapitalRequestView from "./useCapitalRequestView";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../common/components/Loader";
import { checkPermission } from "../../../config/utils";
import { permissions } from "../../../config/permissions";
import { Dropdown } from "react-bootstrap";
import ProjectOnePagerExport from "../../project/components/ProjectOnePagerExport";
import Portal from "../../common/components/Portal";
import { useSelector } from "react-redux";

const CapitalRequestView = ({ isProjectView, isRecommendationView = false, freezeActions = false }) => {
    const {
        data,
        cancelViewPage,
        showEditPage,
        isLoading,
        lockCR,
        createNewOrDuplicateRequest,
        exportOnePager,
        exportLoader,
        onePagerExport,
        showOnePagerExportModal,
        setShowOnePagerExportModal,
        CRData
    } = useCapitalRequestView({
        isProjectView
    });
    const { getProjectByIdResponse } = useSelector(state => state.projectReducer);

    return (
        <LoadingOverlay active={isLoading} spinner={<Loader />} fadeSpeed={10}>
            <div className="dtl-sec system-building col-md-12">
                <div className="tab-dtl region-mng">
                    {!isProjectView && data.id && !freezeActions && (
                        <div class="outer-flx-section cr-dup">
                            <ul></ul>
                            <div className="mr-0 mt-0 mb-2">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <span className="edit-icn-bx">
                                            <i className="fas fa-plus"></i> Add New/Duplicate Request
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="w-100">
                                        <Dropdown.Item onClick={() => createNewOrDuplicateRequest(data, "Regular")}>Add New Request</Dropdown.Item>
                                        <Dropdown.Item onClick={() => createNewOrDuplicateRequest(data, "Duplicate")}>
                                            Add Duplicate Request
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    )}
                    <BasicDetails
                        keys={Object.keys(fields)}
                        config={fields}
                        basicDetails={data}
                        hasEdit={
                            !data?.id || data?.deleted || isRecommendationView
                                ? false
                                : isProjectView
                                ? checkPermission("forms", permissions.CAPITAL_REQUEST, "edit")
                                : !freezeActions && !data?.archived
                        }
                        hasDelete={false}
                        hasLock={isProjectView && checkPermission("forms", permissions.CAPITAL_REQUEST, "lock")}
                        hasLockedStatus={!isProjectView}
                        entity={"Capital Request"}
                        columnDivision={3}
                        cancelViewPage={cancelViewPage}
                        showEditPage={showEditPage}
                        className={"basic-form-outer"}
                        lockRegion={lockCR}
                        hasOnePagerExport
                        exportOnePager={exportOnePager}
                        exportLoader={exportLoader}
                        onePagerExport={onePagerExport}
                        clientId={getProjectByIdResponse?.client?.id}
                    />
                </div>
                {showOnePagerExportModal && (
                    <Portal
                        body={
                            <ProjectOnePagerExport
                                onCancel={() => setShowOnePagerExportModal(false)}
                                exportRecommendation={type => exportOnePager(CRData, type)}
                                exportLoader={exportLoader}
                            />
                        }
                        onCancel={() => setShowOnePagerExportModal(false)}
                    />
                )}
            </div>
        </LoadingOverlay>
    );
};
export default CapitalRequestView;
