import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";

function QaQcRow({
    rowData: {
        title = "",
        resolved_at: resolvedDate,
        created_at: addedDate,
        status = "pending",
        id,
        recommendation_review_comments: comments = [],
        recommendation_review_comment_count: commentCount,
        requestor = {}
    },
    setShowConfirmation,
    onSubmit,
    onEdit,
    onStatusChange,
    isQaQcReviewerUser,
    menuPlacement,
    onSendNotification,
    handleRowClick,
    selectedRow,
    hasReviewerPermission
}) {
    const [titleValue, setTitleValue] = useState(title);
    const [isEditMode, setIsEditMode] = useState(false);

    const { updateRecommendationReviewResponse = {} } = useSelector(state => state.recommendationsReducer) || {};

    useEffect(() => {
        if (updateRecommendationReviewResponse?.success && id === updateRecommendationReviewResponse?.updatedId) {
            setIsEditMode(false);
        }
    }, [updateRecommendationReviewResponse]);

    useEffect(() => {
        setTitleValue(title);
    }, [title]);

    const handleChange = ({ target: { value } }) => setTitleValue(value);
    const handleClose = () => {
        setIsEditMode(false);
        setTitleValue(title);
    };

    const handleSave = () => {
        if (!titleValue?.trim()) return;
        if (titleValue?.trim() === title) return handleClose();
        onSubmit({ id, title: titleValue });
    };
    const statusEnum = {
        pending: 0,
        resolved: 1,
        approved: 2
    };
    const handleStatusChange = ({ value }) => {
        onStatusChange({ id, status: statusEnum[value] });
    };

    const stopEventPropagation = e => {
        e.stopPropagation();
    };
    const statusClassEnum = {
        pending: "pending-orange",
        resolved: "res-yellow ",
        approved: "res-green"
    };

    const statusIndicatorIcon = {
        approved: "/img/approved.svg",
        pending: "/img/pending.svg",
        resolved: "/img/resolved.svg"
    };

    const renderStatusDropDown = () => {
        const statusOptions = isQaQcReviewerUser
            ? [
                  { label: "Pending", value: "pending" },
                  { label: "Resolved", value: "resolved" },
                  { label: "Approved", value: "approved" }
              ]
            : [
                  { label: "Pending", value: "pending" },
                  { label: "Resolved", value: "resolved" }
              ];
        const shouldHideDropDown = () => {
            if (status === "approved")
                return {
                    status: "Approved",
                    cls: "green"
                };
            if (!isQaQcReviewerUser && status === "resolved")
                return {
                    status: "Resolved",
                    cls: "yellow"
                };
            return null;
        };
        return (
            <div className="btn-area">
                {shouldHideDropDown() ? (
                    <button class={`btn btn-status ${shouldHideDropDown()?.cls}`}>{shouldHideDropDown()?.status}</button>
                ) : (
                    <>
                        <ReactTooltip id="status" className="rc-tooltip-custom-class" globalEventOff="click" />
                        <div
                            className="categ-select-box wid-sel-bx-110 pr-1"
                            data-delay-show="500"
                            data-tip="Change Status"
                            data-effect="solid"
                            data-for="status"
                            data-place="left"
                            data-background-color="#007bff"
                        >
                            <ReactSelect
                                className={`react-select-container ${statusClassEnum[status]}`}
                                classNamePrefix="react-select"
                                value={statusOptions.find(option => option.value === status)}
                                onChange={handleStatusChange}
                                options={statusOptions}
                                isSearchable={false}
                                menuPlacement={menuPlacement}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    };

    return (
        <>
            <tr
                onDoubleClick={() => {
                    setIsEditMode(false);
                    handleRowClick(id);
                }}
                className={selectedRow === id ? "active" : ""}
            >
                <td className="icon">
                    <div className="status-check">
                        <img src={statusIndicatorIcon[status] || "/img/pending.svg"} className="status-icons" alt="" />
                    </div>
                </td>
                {isEditMode ? (
                    <td className="dtl-txt-outr" onDoubleClick={stopEventPropagation}>
                        <div className="txt-content-sec">
                            <input
                                type="text"
                                className={`form-control ${!titleValue?.trim() ? "error-border" : ""}`}
                                value={titleValue}
                                onChange={handleChange}
                                autoFocus
                                onKeyDown={event => {
                                    if (event.key === "Enter") {
                                        return handleSave();
                                    }
                                    if (event.key === "Escape") {
                                        return handleClose();
                                    }
                                }}
                            />
                            <ReactTooltip id="cancel-edit" className="rc-tooltip-custom-class" />
                            <ReactTooltip id="save-edit" className="rc-tooltip-custom-class" />
                            <div className="btn-view-action">
                                <button
                                    className="btn btn-actions"
                                    data-delay-show="500"
                                    data-tip="Cancel"
                                    data-effect="solid"
                                    data-for="cancel-edit"
                                    data-place="left"
                                    data-background-color="#007bff"
                                    onClick={handleClose}
                                >
                                    <i className="fas fa-times" data-tip="Edit" data-effect="solid" data-place="bottom"></i>
                                </button>
                                <button
                                    className="btn btn-actions"
                                    data-delay-show="500"
                                    data-tip="Save"
                                    data-effect="solid"
                                    data-for="save-edit"
                                    data-place="left"
                                    data-background-color="#007bff"
                                    onClick={handleSave}
                                >
                                    <i className="fas fa-check" data-tip="Edit" data-effect="solid" data-place="bottom"></i>
                                </button>
                            </div>
                        </div>
                    </td>
                ) : (
                    <td className="dtl-txt-outr">
                        <div className="txt-content-sec">
                            <ReactTooltip id="title" className="rc-tooltip-custom-class" />
                            <div
                                className="text-view"
                                data-delay-show="500"
                                data-tip={title}
                                data-effect="solid"
                                data-for="title"
                                data-background-color="#007bff"
                            >
                                {title}
                            </div>
                            {isQaQcReviewerUser && !selectedRow && hasReviewerPermission && (
                                <>
                                    <ReactTooltip id="edit-title" className="rc-tooltip-custom-class" />
                                    <ReactTooltip id="delete-issue" className="rc-tooltip-custom-class" />
                                    <div className="btn-view-action" onDoubleClick={stopEventPropagation}>
                                        <button
                                            className="btn btn-actions"
                                            data-delay-show="500"
                                            data-tip="Edit Title"
                                            data-effect="solid"
                                            data-for="edit-title"
                                            data-place="left"
                                            data-background-color="#007bff"
                                            onClick={() => onEdit({ title, id })}
                                        >
                                            <i className="fas fa-pencil-alt" data-tip="Edit" data-effect="solid" data-place="bottom"></i>
                                        </button>
                                        <button
                                            className="btn btn-actions"
                                            data-delay-show="500"
                                            data-tip="Delete Issue"
                                            data-effect="solid"
                                            data-for="delete-issue"
                                            data-place="left"
                                            data-background-color="#007bff"
                                            onClick={() => setShowConfirmation(id)}
                                        >
                                            <i className="far fa-trash-alt" data-tip="Edit" data-effect="solid" data-place="bottom"></i>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </td>
                )}

                {/* reviewer Name  */}
                <td className="txt-com text-center">
                    <div className="txt-info">{requestor?.name || "-"}</div>
                </td>

                {!selectedRow && (
                    <td className="txt-com text-center">
                        <div className="txt-info">{` ${resolvedDate ? resolvedDate : "-"}`}</div>
                    </td>
                )}

                <td className="txt-com text-center">
                    <div className="txt-info">{addedDate}</div>
                </td>
                <td className="btn-td" onDoubleClick={stopEventPropagation}>
                    <div className="btn-area align-items-center justify-content-center">{renderStatusDropDown()}</div>
                </td>
                <td class="btn-td comnt-td">
                    <div class="btn-area">
                        <button class="btn btn-snt" onClick={() => handleRowClick(id)}>
                            <ReactTooltip id="comments" className="rc-tooltip-custom-class" />
                            <img
                                src="/img/comment-dots.svg"
                                className="mr-2"
                                alt=""
                                data-tip={selectedRow === id ? "Close Preview" : "View Comments"}
                                data-delay-show="500"
                                data-effect="solid"
                                data-background-color="#007bff"
                                data-for="comments"
                            />
                            {comments?.length || commentCount || ""}
                        </button>
                    </div>
                </td>
                {isQaQcReviewerUser && !selectedRow && (
                    <td class="btn-td send-td" onDoubleClick={stopEventPropagation}>
                        <div class="btn-area">
                            {/* <button className={`btn btn-status ${status === "resolved" ? "green" : "pen"}`}>{status}</button> */}
                            <ReactTooltip id="send-mail" className="rc-tooltip-custom-class" />
                            {status === "pending" && (
                                <button
                                    className="btn btn-snt"
                                    data-delay-show="500"
                                    data-tip="Send reminder notification"
                                    data-effect="solid"
                                    data-for="send-mail"
                                    data-place="left"
                                    data-background-color="#007bff"
                                    onClick={() => onSendNotification(id)}
                                >
                                    <img src="/img/send-view.svg" className="mr-2" alt="" />
                                    Send
                                </button>
                            )}
                        </div>
                    </td>
                )}
            </tr>
        </>
    );
}

export default QaQcRow;
