import React, { useState, useEffect } from "react";

import ViewChartItemForModal from "./ViewChartItemForModal";
import { CHART_ITEMS } from "../constants";
import { getEnityNameForCAband } from "../utils";

const ViewChartSectionForModal = ({
    getSmartChartMasterFilterDropDown,
    projectsDropdownData = [],
    projectId,
    currentBand,
    currentBandIndex,
    masterFilterList,
    openChartItemConfigModal,
    chartSectionData,
    entity,
    toggleImageListForBandModal,
    isExcelExport,
    openFilterModal,
    ...props
}) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        setChartItemData(entity);
    }, [chartSectionData]);

    const setChartItemData = (entity = "project") => {
        let chartDataArray = [];
        let chartItemList = { ...chartSectionData.type };
        let chartKeys = Object.keys(chartItemList);
        let entityKeys = Object.keys(CHART_ITEMS[entity]);
        chartKeys.forEach(chartItem => {
            let filter = false;
            const band1ConfigFilter = chartItemList[chartItem]?.band1;
            const ConfigFilterNames = chartItemList[chartItem];

            if (
                (band1ConfigFilter?.config_filter && Object.keys(band1ConfigFilter?.config_filter).length > 0) ||
                (ConfigFilterNames?.config_filter && Object.keys(ConfigFilterNames?.config_filter).length > 0)
            ) {
                filter = Object.entries(band1ConfigFilter?.config_filter || ConfigFilterNames?.config_filter)
                    .map(([key, value]) =>
                        (key === "global_search" && value) ||
                        (key === "wildcard_filters" && Object.keys(value).length > 0) ||
                        (key === "surveyor" && value) ||
                        (key === "image_or_not" && value) ||
                        (key === "infrastructure_request" && value) ||
                        (key === "water" && value) ||
                        (key === "energy" && value) ||
                        (key === "fmp" && value) ||
                        (key === "facility_master_plan" && value) ||
                        (key === "recommendation_type" && value) ||
                        (key === "budget_priority" && value)
                            ? true
                            : false
                    )
                    .reduce((acc, count) => acc + count, 0);
                if (ConfigFilterNames?.config_filter_names?.list || band1ConfigFilter?.config_filter_names?.list) {
                    filter = true;
                }
            }
            if (
                (ConfigFilterNames?.config_filter_names?.CA_BandFilter &&
                    Object.values(ConfigFilterNames?.config_filter_names?.CA_BandFilter)?.some(arr => Array.isArray(arr) && arr.length > 0)) ||
                (band1ConfigFilter?.config_filter_names?.CA_BandFilter &&
                    Object.values(band1ConfigFilter?.config_filter_names?.CA_BandFilter)?.some(arr => Array.isArray(arr) && arr.length > 0))
            ) {
                filter = true;
            }

            chartDataArray.push({
                name:
                    chartItem.includes("user_doc") || chartItem.includes("image_doc")
                        ? chartItemList[chartItem]?.name || chartItem
                        : chartItem.includes("list_image")
                        ? chartItemList[chartItem]?.name || "Image Band"
                        : chartItem.includes("normal_recom")
                        ? chartItemList[chartItem]?.band1?.name || "Recommendation Table"
                        : chartItem.includes("recom_onepager")
                        ? chartItemList[chartItem]?.band1?.name || "Recommendation One Pager"
                        : chartItem.includes("asset-onepager")
                        ? chartItemList[chartItem]?.band1?.name || "Asset One Pager"
                        : chartItem.includes("multi_recommendation")
                        ? chartItemList[chartItem]?.name || "Recommendation Summary"
                        : chartItem.includes("bbi_report")
                        ? chartItemList[chartItem]?.band1?.name || "BBI"
                        : chartItem.includes("bsi_report")
                        ? chartItemList[chartItem]?.band1?.name || "BSI"
                        : chartItem.includes("memo_field")
                        ? chartItemList[chartItem]?.band1?.name || "Text Band"
                        : chartItem.includes("filter_report1")
                        ? chartItemList[chartItem]?.name || "Master Filters"
                        : !entityKeys.includes(chartItem)
                        ? `Comparative Analysis Band (${
                              chartItem?.includes("fci_benchmarking")
                                  ? getEnityNameForCAband(chartItem.split("_")?.slice(0, 2)?.join("_")) +
                                    " & " +
                                    getEnityNameForCAband(chartItem.split("_")?.[2])
                                  : getEnityNameForCAband(chartItem.split("_")?.[0]) + " & " + getEnityNameForCAband(chartItem.split("_")?.[1])
                          })` || chartItem
                        : chartItem,
                totalChartCount: chartItem.includes("list_image")
                    ? chartItemList?.[chartItem]?.data?.length || 0
                    : chartItem === "multi_recommendation"
                    ? chartItemList?.[chartItem]?.recommendations?.length || 0
                    : (chartItemList?.[chartItem]?.summary_view?.chart_type?.length || 0) +
                      (chartItemList?.[chartItem]?.detailed_view?.chart_type?.length || 0),
                isUserDoc: chartItem.includes("user_doc") || chartItem.includes("image_doc") ? true : false,
                docKey: chartItem,
                isImage: chartItem.includes("image_doc") ? true : false,
                hasConfig: CHART_ITEMS[entity]?.[chartItem]?.hasConfig || chartItem.includes("list_image") ? true : false,
                config: CHART_ITEMS[entity]?.[chartItem]?.config || null,
                entityIcon: getEntityIcon(chartItem, entityKeys),
                isIndividualBuilding: chartItemList?.[chartItem]?.hasOwnProperty("is_indv_bld"),
                isFilterReport: chartItem.includes("filter_report1") ? true : false,
                isImageBand: chartItem.includes("list_image") ? true : false,
                isMemo: chartItem.includes("memo_field") ? true : false,
                filter: filter
            });
        });
        setChartData([...chartDataArray]);
    };

    const getEntityIcon = (chartItem, entityKeys = []) => {
        if (chartItem.includes("user_doc")) {
            return "file-type-standard.svg";
        }
        if (chartItem.includes("image_doc") || chartItem.includes("list_image")) {
            return "smart-chart-image-item.svg";
        }
        if (chartItem?.includes("recom_onepager")) {
            return "sm-charts-one-pager.svg";
        }
        if (
            chartItem == "sorted_recom" ||
            chartItem == "energy_band" ||
            chartItem == "water_band" ||
            chartItem === "multi_recommendation" ||
            chartItem == "normal_recom" ||
            chartItem == "asset_band" ||
            chartItem.includes("bbi_report") ||
            chartItem.includes("bsi_report") ||
            chartItem.includes("filter_report1") ||
            !entityKeys.includes(chartItem) ||
            isExcelExport
        ) {
            return "sm-charts-table-blue-icon.svg";
        }
        return "smart-chart-chart-icon-item.svg";
    };

    return (
        <div class="drag-main">
            <div class="row m-0">
                <div class="col-md-12 p-0">
                    <div class="dragble-area">
                        {chartData.map((item, i) => (
                            <ViewChartItemForModal
                                index={i}
                                item={item}
                                currentBand={currentBand}
                                entity={entity}
                                openChartItemConfigModal={openChartItemConfigModal}
                                openFilterModal={openFilterModal}
                                toggleImageListForBandModal={toggleImageListForBandModal}
                                isExcelExport={isExcelExport}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ViewChartSectionForModal;
