// Get All User Permissions
export const GET_ALL_PERMISSION_REQUEST = "GET_ALL_PERMISSION_REQUEST";
export const GET_ALL_PERMISSION_SUCCESS = "GET_ALL_PERMISSION_SUCCESS";
export const GET_ALL_PERMISSION_FAILURE = "GET_ALL_PERMISSION_FAILURE";

// Get  User Permissions
export const GET_PERMISSION_REQUEST = "GET_PERMISSION_REQUEST";
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS";
export const GET_PERMISSION_FAILURE = "GET_PERMISSION_FAILURE";

// Get  User Permissions by id
export const GET_PERMISSION_BY_ID_REQUEST = "GET_PERMISSION_BY_ID_REQUEST";
export const GET_PERMISSION_BY_ID_SUCCESS = "GET_PERMISSION_BY_ID_SUCCESS";
export const GET_PERMISSION_BY_ID_FAILURE = "GET_PERMISSION_BY_ID_FAILURE";

// Delete  User Permissions
export const DELETE_PERMISSION_REQUEST = "DELETE_PERMISSION_REQUEST";
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS";
export const DELETE_PERMISSION_FAILURE = "DELETE_PERMISSION_FAILURE";

// Create  User Permissions
export const CREATE_PERMISSION_REQUEST = "CREATE_PERMISSION_REQUEST";
export const CREATE_PERMISSION_SUCCESS = "CREATE_PERMISSION_SUCCESS";
export const CREATE_PERMISSION_FAILURE = "CREATE_PERMISSION_FAILURE";

// update  User Permissions
export const UPDATE_PERMISSION_REQUEST = "UPDATE_PERMISSION_REQUEST";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAILURE = "UPDATE_PERMISSION_FAILURE";

// update params
export const UPDATE_USER_PERMISSION_ENTITY_PARAMS_SUCCESS = "UPDATE_USER_PERMISSION_ENTITY_PARAMS_SUCCESS";
export const UPDATE_USER_PERMISSION_ENTITY_PARAMS_FAILURE = "UPDATE_USER_PERMISSION_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

// Get  Template
export const GET_TEMPLATE_REQUEST = "GET_TEMPLATE_REQUEST";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILURE = "GET_TEMPLATE_FAILURE";

// Get  consultancy user
export const GET_CONSULTANCY_USER_REQUEST = "GET_CONSULTANCY_USER_REQUEST";
export const GET_CONSULTANCY_USER_SUCCESS = "GET_CONSULTANCY_USER_SUCCESS";
export const GET_CONSULTANCY_USER_FAILURE = "GET_CONSULTANCY_USER_FAILURE";
// Get  clients
export const GET_CLIENTS_REQUEST = "GET_CLIENTS_REQUEST";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";

// Get  Details by template id
export const GET_BY_TEMPLATE_REQUEST = "GET_BY_TEMPLATE_REQUEST";
export const GET_BY_TEMPLATE_SUCCESS = "GET_BY_TEMPLATE_SUCCESS";
export const GET_BY_TEMPLATE_FAILURE = "GET_BY_TEMPLATE_FAILURE";

// export permissions
export const GET_PERMISSION_EXPORT_REQUEST = "GET_PERMISSION_EXPORT_REQUEST";
export const GET_PERMISSION_EXPORT_SUCCESS = "GET_PERMISSION_EXPORT_SUCCESS";
export const GET_PERMISSION_EXPORT_FAILURE = "GET_PERMISSION_EXPORT_FAILURE";

export const GET_TEMPLATE_INITIAL_VALUES_REQUEST = "GET_TEMPLATE_INITIAL_VALUES_REQUEST";
export const GET_TEMPLATE_INITIAL_VALUES_SUCCESS = "GET_TEMPLATE_INITIAL_VALUES_SUCCESS";
export const GET_TEMPLATE_INITIAL_VALUES_FAILURE = "GET_TEMPLATE_INITIAL_VALUES_FAILURE";

export const GET_USER_LIST_FOR_PERMISSIONS_REQUEST = "GET_USER_LIST_FOR_PERMISSIONS_REQUEST";
export const GET_USER_LIST_FOR_PERMISSIONS_SUCCESS = "GET_USER_LIST_FOR_PERMISSIONS_SUCCESS";
export const GET_USER_LIST_FOR_PERMISSIONS_FAILURE = "GET_USER_LIST_FOR_PERMISSIONS_FAILURE";

export const GET_USER_PERMISSIONS_BYID_REQUEST = "GETUSER_PERMISSIONS_BYID_REQUEST";
export const GET_USER_PERMISSIONS_BYID_SUCCESS = "GETUSER_PERMISSIONS_BYID_SUCCESS";
export const GET_USER_PERMISSIONS_BYID_FAILURE = "GETUSER_PERMISSIONS_BYID_FAILURE";

export const ADD_USER_PERMISSIONS_REQUEST = "ADD_USER_PERMISSIONS_REQUEST";
export const ADD_USER_PERMISSIONS_SUCCESS = "ADD_USER_PERMISSIONS_SUCCESS";
export const ADD_USER_PERMISSIONS_FAILURE = "ADD_USER_PERMISSIONS_FAILURE";

export const EDIT_USER_PERMISSIONS_BYID_REQUEST = "EDIT_USER_PERMISSIONS_BYID_REQUEST";
export const EDIT_USER_PERMISSIONS_BYID_SUCCESS = "EDIT_USER_PERMISSIONS_BYID_SUCCESS";
export const EDIT_USER_PERMISSIONS_BYID_FAILURE = "EDIT_USER_PERMISSIONS_BYID_FAILURE";
