import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Draggable from "react-draggable";
import { useHistory } from "react-router-dom";

import actions from "../actions";
import { bulkResetBreadCrumpData } from "../../../config/utils";
import { MASTER_FILTER_KEYS } from "../constants";
import { values } from "lodash";

const CreateSkeleton = ({ onCancel, templateId, closeViewPropertyModal, selectedClient, templateName = "", chartData }) => {
    const [showErrorBorder, setShowErrorBorder] = useState(false);
    const [skeletonData, setSkeletonData] = useState({ name: templateName, notes: "" });
    const [globalChecked, setGlobalChecked] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const [filterLists, setFilterLists] = useState({});

    useEffect(() => {
        const filteredDatas = {};
        const filterValue = {};
        const GeoHierarchyFields = ["Projects", "Region", "Site", "B.Types", "Buildings", "Years", "start", "end"];
        const entityFilters = MASTER_FILTER_KEYS["project"]?.filter(e => GeoHierarchyFields.includes(e.label));
        if (entityFilters && chartData["project"]?.band1?.mfilter) {
            entityFilters.forEach(filter => {
                const paramKey = filter.paramKey;
                if (Object.keys(chartData["project"].band1.mfilter).includes(paramKey)) {
                    if (paramKey === "project_ids") {
                        filterValue[paramKey] = {
                            required: true,
                            keep_values: true
                        };
                    } else if (Object.keys(chartData["project"].band1.mfilter).includes(paramKey)) {
                        filterValue[paramKey] = {
                            required: true,
                            keep_values: false
                        };
                    }
                }
            });
            filteredDatas["project"] = filterValue;
        }
        setFilterLists(filteredDatas);
    }, [chartData]);
    const validate = () => {
        setShowErrorBorder(false);
        if (!skeletonData.name && !skeletonData.name.trim().length) {
            setShowErrorBorder(true);
            return false;
        }
        return true;
    };

    const createNewSkeleton = () => {
        if (validate()) {
            const { name, notes } = skeletonData;
            const payload = {
                name,
                notes,
                property_id: templateId,
                core_report_req_filters: filterLists
            };

            if (!globalChecked) {
                payload.client_id = selectedClient;
            }
            dispatch(actions.createSkeletonFromTemplate(payload));
            bulkResetBreadCrumpData([
                { key: "main", name: "Smart Reports & Charts", path: `/smartcharts/reports` },
                {
                    key: "corereports",
                    name: "Core Reports",
                    path: `/smartcharts/corereports`
                }
            ]);
            closeViewPropertyModal();
            history.push(`/smartcharts/corereports`);
        }
    };

    const getSelectedFilters = () => {
        const filteredDatas = {};
        const GeoHierarchyFields = ["Projects", "Region", "Site", "B.Types", "Buildings", "Years", "start", "end"];
        const entityFilters = MASTER_FILTER_KEYS["project"]?.filter(e => GeoHierarchyFields.includes(e.label));
        if (entityFilters && chartData["project"]?.band1?.mfilter) {
            const filteredData = {};
            entityFilters.forEach(filter => {
                const paramKey = filter.paramKey;
                if (Object.keys(chartData["project"].band1.mfilter).includes(paramKey)) {
                    if (chartData["project"]?.band1?.mfilter?.[paramKey]?.length > 0) {
                        filteredData[paramKey] = filter;
                    }
                }
            });
            filteredDatas["project"] = filteredData;
        }
        return filteredDatas;
    };

    const handleFilterClick = (entityName, label, keyLabel, checked) => {
        setFilterLists(prevState => {
            const newState = { ...prevState };
            if (newState[entityName] && newState[entityName][label]) {
                newState[entityName][label][keyLabel] = checked;
                if (checked && keyLabel === "keep_values") {
                    newState[entityName][label].required = true;
                }
            }
            return newState;
        });
    };

    return (
        <>
            <div className="modal modal-region modal-view inbox-modal" id="modalId" tabIndex="-1" style={{ display: "block" }}>
                <Draggable handle=".draggable" cancel=".non-draggable">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    <div className="txt-hed">Create Core Report</div>
                                </h5>
                                <button type="button" className="close non-draggable" onClick={onCancel}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className={`modal-body region-otr sm-chart-upload`}>
                                <div className="col-md-12 main-sec">
                                    <div className="form-row">
                                        <div className="form-group col-12 mb-4">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                value={skeletonData.name}
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    setSkeletonData(prevParams => {
                                                        return {
                                                            ...prevParams,
                                                            name: value
                                                        };
                                                    });
                                                }}
                                                className={`form-control ${
                                                    showErrorBorder && !skeletonData.name && !skeletonData.name.trim().length ? "error-border" : ""
                                                }`}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-12 mb-4">
                                            <label>Notes</label>
                                            <textarea
                                                className="form-control textarea"
                                                value={skeletonData.notes}
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    setSkeletonData(prevParams => {
                                                        return {
                                                            ...prevParams,
                                                            notes: value
                                                        };
                                                    });
                                                }}
                                            ></textarea>
                                        </div>
                                    </div>

                                    {Object.entries(getSelectedFilters())?.map(([entityName, filterLabels], index) => (
                                        <div className="category-check-wrap">
                                            <h4 style={{ textTransform: "capitalize" }}>{entityName}</h4>
                                            {Object.entries(filterLabels).map(([key, Values], index) => (
                                                <div className="d-flex">
                                                    <div className="formInp">
                                                        <div className="col-md-12 checkbox-cover">
                                                            <label className="container-check pl-0">{Values?.label}</label>
                                                        </div>
                                                    </div>
                                                    {!globalChecked && (
                                                        <div className="formInp">
                                                            <div className="col-md-12 checkbox-cover">
                                                                <span className="dropdown-item">
                                                                    <label className="container-check">
                                                                        Keep values
                                                                        <input
                                                                            type="checkbox"
                                                                            onClick={e => {
                                                                                handleFilterClick(entityName, key, "keep_values", e.target.checked);
                                                                            }}
                                                                            checked={filterLists?.[entityName]?.[key]?.keep_values}
                                                                            disabled={Values?.label === "Projects"}
                                                                        />
                                                                        <span
                                                                            className={`checkmark ${
                                                                                Values?.label === "Projects" ? "cursor-notallowed" : ""
                                                                            }`}
                                                                        ></span>
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="formInp">
                                                        <div className="col-md-12 checkbox-cover">
                                                            <span className="dropdown-item">
                                                                <label className="container-check">
                                                                    Required
                                                                    <input
                                                                        type="checkbox"
                                                                        onClick={e => {
                                                                            handleFilterClick(entityName, key, "required", e.target.checked);
                                                                        }}
                                                                        checked={filterLists?.[entityName]?.[key]?.required}
                                                                        disabled={
                                                                            Values?.label === "Projects" ||
                                                                            filterLists?.[entityName]?.[key]?.keep_values
                                                                        }
                                                                    />
                                                                    <span
                                                                        className={`checkmark ${
                                                                            Values?.label === "Projects" ||
                                                                            filterLists?.[entityName]?.[key]?.keep_values
                                                                                ? "cursor-notallowed"
                                                                                : ""
                                                                        }`}
                                                                    ></span>
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    <div className="formInp checkbox-outr-wrap">
                                        <div className="col-md-12 checkbox-cover">
                                            <span className="dropdown-item">
                                                <label className="container-check">
                                                    Global Core Report
                                                    <input
                                                        type="checkbox"
                                                        onClick={() => {
                                                            setGlobalChecked(prevGlobalChecked => !prevGlobalChecked);
                                                            if (!globalChecked) {
                                                                setFilterLists(prevState => {
                                                                    const newState = { ...prevState };
                                                                    Object.keys(newState)?.forEach(entityName => {
                                                                        Object.keys(newState[entityName])?.forEach(label => {
                                                                            Object.keys(newState[entityName][label])?.forEach(keyLabel => {
                                                                                if (keyLabel === "keep_values") {
                                                                                    newState[entityName][label][keyLabel] = false;
                                                                                }
                                                                            });
                                                                        });
                                                                    });
                                                                    return newState;
                                                                });
                                                            } else {
                                                                setFilterLists(prevState => {
                                                                    const newState = { ...prevState };
                                                                    Object.keys(newState)?.forEach(entityName => {
                                                                        Object.keys(newState[entityName])?.forEach(label => {
                                                                            console.log(label);
                                                                            if (label === "project_ids") {
                                                                                Object.keys(newState[entityName][label])?.forEach(keyLabel => {
                                                                                    if (keyLabel === "keep_values") {
                                                                                        newState[entityName][label][keyLabel] = true;
                                                                                    }
                                                                                });
                                                                            }
                                                                        });
                                                                    });
                                                                    return newState;
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-sec">
                                    <div className="text-right btnOtr edit-cmn-btn">
                                        <button
                                            type="button"
                                            className="btn btn-primary btnRgion "
                                            class="btn btn-create save"
                                            onClick={() => createNewSkeleton()}
                                        >
                                            Create
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btnClr mr-1"
                                            data-dismiss="modal"
                                            onClick={() => onCancel()}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
        </>
    );
};

export default CreateSkeleton;
