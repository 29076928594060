import React, { useEffect, useRef, useState } from "react";

export const Band = ({ label, bandId, handleBandClick, MainFields, RestFields, showBand, isDefaultOpen, ExtraFields, filteredCount }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const collapseRef = useRef(null);

    useEffect(() => {
        const observer = new MutationObserver(mutationsList => {
            for (let mutation of mutationsList) {
                if (mutation.attributeName === "class") {
                    const isShowClass = mutation.target.classList.contains("show");
                    setIsCollapsed(!isShowClass);
                }
            }
        });

        const currentCollapseRef = collapseRef.current;

        if (currentCollapseRef) {
            observer.observe(currentCollapseRef, { attributes: true });
        }

        return () => {
            if (currentCollapseRef) {
                observer.disconnect();
            }
        };
    }, [bandId]);

    return (
        <div className={`card`}>
            <div className="card-header" id={"heading" + bandId}>
                <div className="otr-recom-div">
                    <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target={`#${bandId}`}
                        aria-expanded={isDefaultOpen ? "true" : "false"}
                        aria-controls={bandId}
                        onClick={event => {
                            event.preventDefault();
                            handleBandClick(bandId, false);
                        }}
                    >
                        {label}
                        {filteredCount > 0 && <span className="badge-outer">{filteredCount}</span>}
                    </button>
                    {MainFields}
                </div>
            </div>
            <div id={bandId} ref={collapseRef} className={"collapse"} aria-labelledby={"heading" + bandId}>
                <div className="card-body">
                    {!isCollapsed && <div className="outer-rcm mt-1">{RestFields}</div>}
                    {ExtraFields}
                </div>
            </div>
        </div>
    );
};
