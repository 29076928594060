import { useEffect, useRef, useState } from "react";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { filterFields, tabs } from "./constants";
import { getAppliedListFilterCount, getAppliedWildCardFilterCount } from "../../../../utils/filtersUtils";
const useMyFilters = ({ currentFilters, submitMasterFilter, onCancel }) => {
    const dispatch = useDispatch();
    const { masterFilterList, favoriteFilters } = useSelector(state => state.assetReducer);
    const [showBands, setShowBands] = useState({});
    const [showDropdown, setShowDropdown] = useState("");
    const [showWildcard, setShowWildcard] = useState("");
    const [filterData, setFilterData] = useState({ filters: { ...(currentFilters.filters || {}) }, ...(currentFilters.list || {}) });
    const [initialFilterData, setInitialFilterData] = useState({ filters: { ...(currentFilters.filters || {}) }, ...(currentFilters.list || {}) });
    const [activeTab, setActiveTab] = useState(Object.keys(tabs)[0]);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [fieldSearch, setFieldSearch] = useState("");
    const [showAppliedFilters, setShowAppliedFilters] = useState(false);
    const [favorites, setFavorites] = useState([]);
    const wrapperRef = useRef();
    const favFilterRef = useRef();
    const [showWildcardField, setWildcardField] = useState(false);
    const [showFavoriteRemoveConfirmation, setFavoriteRemoveConfirmation] = useState(false);
    const [selectedFavoriteItem, setSelectFavoriteItem] = useState("");
    const handleFilterView = async (item, dropdownRef, isWildCardFilter) => {
        const { key, options = [], searchKey, getListKey, paramKey, search = "", fetchMore = false, listEndPoint = "" } = item;
        if ((isWildCardFilter && showWildcard !== key) || (!isWildCardFilter && showDropdown !== key && dropdownRef?.current)) {
            dropdownRef.current?.scrollIntoView({ behavior: "smooth" });
            wrapperRef.current = dropdownRef.current;
        }
        if (isWildCardFilter) {
            setShowWildcard(showWildcard === key ? "" : key);
            setShowDropdown("");
        } else if (!fetchMore) {
            setShowDropdown(showDropdown === key ? "" : key);
            setShowWildcard("");
        }
        if (!options?.length && (key !== showDropdown || fetchMore) && !isWildCardFilter) {
            const { filters = {}, ...rest } = { ...filterData };
            const { [paramKey]: omit, has_image, ...omittedList } = rest;
            const filteredWildCardData = Object.entries(filters || {}).reduce((result, [key, elem]) => {
                if (elem.key?.trim().length || elem.filters[0] === "null" || elem.filters[0] === "not_null" || elem.filters[0] === "count_gt_1") {
                    result[key] = elem;
                }
                return result;
            }, {});
            let params = {
                ...currentFilters,
                search: currentFilters.search,
                searchKey,
                field: getListKey,
                filters: filteredWildCardData,
                list: omittedList
            };
            if (has_image?.length && paramKey !== "has_image") {
                params.has_image = has_image[0];
            }
            if (search?.trim()?.length) {
                params.search_filters = { [searchKey]: { key: search } };
            }
            dispatch(actions.getMasterFilter(params, key, listEndPoint));
        }
    };

    const handleFilterUpdate = (item, data) => {
        setShowDropdown("");
        let updatedData = data;
        if (data?.length) {
            let options = masterFilterList[item.key];
            updatedData = data.filter(item => options?.list?.findIndex(opt => opt.name === item) !== -1);
            setFilterData({ ...filterData, [item.paramKey]: updatedData });
        } else if (filterData.hasOwnProperty(item.paramKey)) {
            const updatedFilterData = { ...filterData };
            delete updatedFilterData[item.paramKey];
            setFilterData(updatedFilterData);
        }
    };

    const handleBandClick = (key, value) => {
        setShowBands({ ...showBands, [key]: value });
    };

    const handleSubmit = () => {
        let updatedFilterData = { ...filterData };
        const filteredWildCardData = Object.entries(updatedFilterData?.filters || {}).reduce((result, [key, elem]) => {
            if (elem.key?.trim().length || elem.filters[0] === "null" || elem.filters[0] === "not_null") {
                result[key] = elem;
            }
            return result;
        }, {});

        updatedFilterData = { ...updatedFilterData, filters: filteredWildCardData };
        setInitialFilterData(updatedFilterData);
        onCancel();
        submitMasterFilter(updatedFilterData);
    };

    const isDirty = !isEqual(filterData, initialFilterData);

    const handleCancel = () => {
        isDirty ? setShowCancelConfirmation(true) : onCancel();
    };

    const onCancelConfirm = () => {
        onCancel();
    };

    const clearAllFilters = () => {
        setFilterData({});
    };

    const getFilterCountByBand = band => {
        if (!(filterData && Object.keys(filterData)?.length)) {
            return 0;
        }
        let bandFilterCount = 0;
        const bandParamKeys = filterFields.filter(elem => elem.band === band).map(field => field.paramKey) || [];
        bandFilterCount = Object.keys(filterData).filter(key => bandParamKeys.includes(key) && filterData[key]?.length).length;

        if (filterData.filters && Object.keys(filterData.filters).length) {
            Object.entries(filterData.filters).forEach(([key, value]) => {
                if (bandParamKeys.includes(key) && (value.key || value.filters.includes("null") || value.filters.includes("not_null"))) {
                    bandFilterCount += 1;
                }
            });
        }

        return bandFilterCount;
    };

    const setWildCardFilter = (elem, data, checked) => {
        let currentFilter = filterData?.filters || {};
        if (data) {
            currentFilter = {
                ...currentFilter,
                [elem.paramKey]: data
            };
        } else {
            if (currentFilter?.hasOwnProperty(elem.paramKey)) {
                delete currentFilter[elem.paramKey];
            }
        }
        setFilterData({ ...filterData, filters: currentFilter });
    };

    const getTotalFilterCount = () => {
        let filterCount = 0;
        const { filters = {}, ...rest } = filterData;
        filterCount = getAppliedWildCardFilterCount(filters) + getAppliedListFilterCount(rest);
        return filterCount;
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = event => {
        if (wrapperRef?.current && !wrapperRef.current.contains(event.target)) {
            setShowDropdown("");
        }
    };

    const toggleFavorite = field => {
        if (favorites.includes(field)) {
            if (activeTab === "favorites") {
                setFavoriteRemoveConfirmation(true);
                setSelectFavoriteItem(field);
            } else {
                setFavorites(favorites.filter(item => item !== field));
            }
        } else {
            setFavorites([...favorites, field]);
        }
        favFilterRef.current = true;
    };

    useEffect(() => {
        if (favFilterRef.current) {
            saveFavorites();
        }
    }, [favorites]);

    useEffect(() => {
        const params = {
            user_id: localStorage.getItem("userId"),
            filter_key: "0"
        };
        dispatch(actions.getFavorites(params));
    }, []);

    useEffect(() => {
        setFavorites(favoriteFilters?.data?.properties || []);
        setActiveTab(favoriteFilters?.data?.properties?.length ? "favorites" : Object.keys(tabs)[0]);
    }, [favoriteFilters]);

    const saveFavorites = () => {
        let params = {
            user_id: localStorage.getItem("userId"),
            properties: JSON.stringify(favorites),
            filter_key: "0"
        };
        actions.saveFavorites(params);
    };

    const onFavoriteRemoveConfirmation = () => {
        setFavoriteRemoveConfirmation(false);
        if (favorites.includes(selectedFavoriteItem)) {
            setFavorites(favorites.filter(item => item !== selectedFavoriteItem));
            favFilterRef.current = true;
        }
    };

    return {
        showBands,
        showDropdown,
        showWildcard,
        setShowWildcard,
        handleFilterView,
        masterFilterList,
        handleBandClick,
        filterData,
        handleFilterUpdate,
        isDirty,
        handleSubmit,
        activeTab,
        setActiveTab,
        showCancelConfirmation,
        onCancelConfirm,
        setShowCancelConfirmation,
        handleCancel,
        filterFields,
        clearAllFilters,
        getFilterCountByBand,
        setShowDropdown,
        setWildCardFilter,
        isLoading,
        fieldSearch,
        setFieldSearch,
        showAppliedFilters,
        setShowAppliedFilters,
        totalFilterCount: getTotalFilterCount(),
        favorites,
        toggleFavorite,
        showWildcardField,
        setWildcardField,
        showFavoriteRemoveConfirmation,
        setFavoriteRemoveConfirmation,
        onFavoriteRemoveConfirmation
    };
};
export default useMyFilters;
