import * as actionType from "./constants";

const initialState = {
    addDynamicFieldResponse: null,
    updateDynamicFieldResponse: null,
    getDynamicFieldResponse: null,
    getDynamicFieldByIdResponse: null,
    getListForCommonFilterResponse: null,
    entityParams: {},
    deleteDynamicFieldResponse: null,
    restoreDynamicFieldResponse: null,
    bandsList: [],
    assetBandsList: [],
    buildingBandsList: [],
    getAssetDynamicFieldResponse: null,
    getAssetDynamicFieldByIdResponse: null,
    getBuildingDynamicFieldByIdResponse: null,
    updateAssetDynamicFieldResponse: null,
    updateBuildingDynamicFieldResponse: null,
    addAssetDynamicFieldResponse: null,
    addBuildingDynamicFieldResponse: null,
    getBuildingDynamicFieldResponse: null,
    getDynamicFieldBandsResponse: null,
    addDynamicFieldBandResponse: null,
    updateDynamicFieldBandsResponse: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_DYNAMIC_FIELD_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_DYNAMIC_FIELD_SUCCESS:
            return {
                ...state,
                addDynamicFieldResponse: { success: true, ...action.response }
            };
        case actionType.ADD_DYNAMIC_FIELD_FAILURE:
            return {
                ...state,
                addDynamicFieldResponse: { success: false, ...action.error }
            };

        case actionType.ADD_ASSET_DYNAMIC_FIELD_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_ASSET_DYNAMIC_FIELD_SUCCESS:
            return {
                ...state,
                addAssetDynamicFieldResponse: { success: true, ...action.response }
            };
        case actionType.ADD_ASSET_DYNAMIC_FIELD_FAILURE:
            return {
                ...state,
                addAssetDynamicFieldResponse: { success: false, ...action.error }
            };

        case actionType.ADD_BUILDING_DYNAMIC_FIELD_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_BUILDING_DYNAMIC_FIELD_SUCCESS:
            return {
                ...state,
                addBuildingDynamicFieldResponse: { success: true, ...action.response }
            };
        case actionType.ADD_BUILDING_DYNAMIC_FIELD_FAILURE:
            return {
                ...state,
                addBuildingDynamicFieldResponse: { success: false, ...action.error }
            };

        case actionType.ADD_DYNAMIC_FIELD_BANDS_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_DYNAMIC_FIELD_BANDS_SUCCESS:
            return {
                ...state,
                addDynamicFieldBandResponse: { success: true, ...action.response }
            };
        case actionType.ADD_DYNAMIC_FIELD_BANDS_FAILURE:
            return {
                ...state,
                addDynamicFieldBandResponse: { success: false, ...action.error }
            };

        case actionType.UPDATE_DYNAMIC_FIELD_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_DYNAMIC_FIELD_SUCCESS:
            return {
                ...state,
                updateDynamicFieldResponse: { success: true, ...action.response }
            };
        case actionType.UPDATE_DYNAMIC_FIELD_FAILURE:
            return {
                ...state,
                updateDynamicFieldResponse: { success: false, ...action.error }
            };

        case actionType.UPDATE_ASSET_DYNAMIC_FIELD_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_ASSET_DYNAMIC_FIELD_SUCCESS:
            return {
                ...state,
                updateAssetDynamicFieldResponse: { success: true, ...action.response }
            };
        case actionType.UPDATE_ASSET_DYNAMIC_FIELD_FAILURE:
            return {
                ...state,
                updateAssetDynamicFieldResponse: { success: false, ...action.error }
            };

        case actionType.UPDATE_BUILDING_DYNAMIC_FIELD_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_BUILDING_DYNAMIC_FIELD_SUCCESS:
            return {
                ...state,
                updateBuildingDynamicFieldResponse: { success: true, ...action.response }
            };
        case actionType.UPDATE_BUILDING_DYNAMIC_FIELD_FAILURE:
            return {
                ...state,
                updateBuildingDynamicFieldResponse: { success: false, ...action.error }
            };

        case actionType.UPDATE_DYNAMIC_FIELD_BANDS_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_DYNAMIC_FIELD_BANDS_SUCCESS:
            return {
                ...state,
                updateDynamicFieldBandsResponse: { success: true, ...action.response }
            };
        case actionType.UPDATE_DYNAMIC_FIELD_BANDS_FAILURE:
            return {
                ...state,
                updateDynamicFieldBandsResponse: { success: false, ...action.error }
            };

        case actionType.GET_DYNAMIC_FIELD_REQUEST:
            return {
                ...state
            };
        case actionType.GET_DYNAMIC_FIELD_SUCCESS:
            return {
                ...state,
                getDynamicFieldResponse: { success: true, ...action.response }
            };
        case actionType.GET_DYNAMIC_FIELD_FAILURE:
            return {
                ...state,
                getDynamicFieldResponse: { success: false, ...action.error }
            };

        case actionType.GET_ASSET_DYNAMIC_FIELD_REQUEST:
            return {
                ...state
            };
        case actionType.GET_ASSET_DYNAMIC_FIELD_SUCCESS:
            return {
                ...state,
                getAssetDynamicFieldResponse: { success: true, ...action.response }
            };
        case actionType.GET_ASSET_DYNAMIC_FIELD_FAILURE:
            return {
                ...state,
                getAssetDynamicFieldResponse: { success: false, ...action.error }
            };

        case actionType.GET_BUILDING_DYNAMIC_FIELD_REQUEST:
            return {
                ...state
            };
        case actionType.GET_BUILDING_DYNAMIC_FIELD_SUCCESS:
            return {
                ...state,
                getBuildingDynamicFieldResponse: { success: true, ...action.response }
            };
        case actionType.GET_BUILDING_DYNAMIC_FIELD_FAILURE:
            return {
                ...state,
                getBuildingDynamicFieldResponse: { success: false, ...action.error }
            };

        case actionType.GET_DYNAMIC_FIELD_BANDS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_DYNAMIC_FIELD_BANDS_SUCCESS:
            return {
                ...state,
                getDynamicFieldBandsResponse: { success: true, ...action.response }
            };
        case actionType.GET_DYNAMIC_FIELD_BANDS_FAILURE:
            return {
                ...state,
                getDynamicFieldBandsResponse: { success: false, ...action.error }
            };

        case actionType.GET_DYNAMIC_FIELD_BY_ID_REQUEST:
            return {
                ...state
            };
        case actionType.GET_DYNAMIC_FIELD_BY_ID_SUCCESS:
            return {
                ...state,
                getDynamicFieldByIdResponse: { success: true, data: { ...action.response } }
            };
        case actionType.GET_DYNAMIC_FIELD_BY_ID_FAILURE:
            return {
                ...state,
                getDynamicFieldByIdResponse: { success: false, ...action.error }
            };

        case actionType.GET_ASSET_DYNAMIC_FIELD_BY_ID_REQUEST:
            return {
                ...state
            };
        case actionType.GET_ASSET_DYNAMIC_FIELD_BY_ID_SUCCESS:
            return {
                ...state,
                getAssetDynamicFieldByIdResponse: { success: true, data: { ...action.response } }
            };
        case actionType.GET_ASSET_DYNAMIC_FIELD_BY_ID_FAILURE:
            return {
                ...state,
                getAssetDynamicFieldByIdResponse: { success: false, ...action.error }
            };

        case actionType.GET_BUILDING_DYNAMIC_FIELD_BY_ID_REQUEST:
            return {
                ...state
            };
        case actionType.GET_BUILDING_DYNAMIC_FIELD_BY_ID_SUCCESS:
            return {
                ...state,
                getBuildingDynamicFieldByIdResponse: { success: true, data: { ...action.response } }
            };
        case actionType.GET_BUILDING_DYNAMIC_FIELD_BY_ID_FAILURE:
            return {
                ...state,
                getBuildingDynamicFieldByIdResponse: { success: false, ...action.error }
            };

        case actionType.GET_COMMON_FILTER_LIST_FOR_DYNAMIC_FIELD_REQUEST:
            return {
                ...state
            };
        case actionType.GET_COMMON_FILTER_LIST_FOR_DYNAMIC_FIELD_SUCCESS:
            return {
                ...state,
                getListForCommonFilterResponse: { success: true, ...action.response }
            };
        case actionType.GET_COMMON_FILTER_LIST_FOR_DYNAMIC_FIELD_FAILURE:
            return {
                ...state,
                getListForCommonFilterResponse: { success: false, ...action.error }
            };
        case actionType.DELETE_DYNAMIC_FIELD_REQUEST:
            return {
                ...state,
                deleteLoading: true
            };
        case actionType.DELETE_DYNAMIC_FIELD_SUCCESS:
            return {
                ...state,
                deleteDynamicFieldResponse: { success: true, deleteLoading: false, ...action.response }
            };
        case actionType.DELETE_DYNAMIC_FIELD_FAILURE:
            return {
                ...state,
                deleteDynamicFieldResponse: { success: false, deleteLoading: false, ...action.error }
            };
        case actionType.RESTORE_DYNAMIC_FIELD_REQUEST:
            return {
                ...state
            };
        case actionType.RESTORE_DYNAMIC_FIELD_SUCCESS:
            return {
                ...state,
                restoreDynamicFieldResponse: { success: true, ...action.response }
            };
        case actionType.RESTORE_DYNAMIC_FIELD_FAILURE:
            return {
                ...state,
                restoreDynamicFieldResponse: { success: false, ...action.error }
            };
        case actionType.EMPTY_VIEW_PAGE_DATA:
            return {
                ...state,
                getDynamicFieldByIdResponse: null
            };
        case actionType.CLEAR_RESPONSES:
            return {
                ...state,
                deleteDynamicFieldResponse: null,
                restoreDynamicFieldResponse: null
            };
        case actionType.GET_BANDS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_BANDS_SUCCESS:
            return {
                ...state,
                bandsList: { success: true, data: { ...action.response } }
            };
        case actionType.GET_BANDS_FAILURE:
            return {
                ...state,
                bandsList: { success: false, ...action.error }
            };

        case actionType.GET_ASSET_BANDS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_ASSET_BANDS_SUCCESS:
            return {
                ...state,
                assetBandsList: { success: true, data: { ...action.response } }
            };
        case actionType.GET_ASSET_BANDS_FAILURE:
            return {
                ...state,
                assetBandsList: { success: false, ...action.error }
            };

        case actionType.GET_BUILDING_BANDS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_BUILDING_BANDS_SUCCESS:
            return {
                ...state,
                buildingBandsList: { success: true, data: { ...action.response } }
            };
        case actionType.GET_BUILDING_BANDS_FAILURE:
            return {
                ...state,
                buildingBandsList: { success: false, ...action.error }
            };

        case actionType.UPDATE_DYNAMIC_FIELD_ENTITY_PARAMS_SUCCESS:
            return {
                ...state,
                entityParams: { ...state.entityParams, [action.section]: { ...action.response } }
            };
        case actionType.UPDATE_DYNAMIC_FIELD_ENTITY_PARAMS_FAILURE:
            return {
                ...state,
                entityParams: { ...state.entityParams, [action.section]: { ...action.error } }
            };
        default:
            return state;
    }
};
