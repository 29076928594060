import React, { Component } from "react";
import { connect } from "react-redux";
import _, { debounce } from "lodash";
import qs from "query-string";
import "rc-time-picker/assets/index.css";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import "react-datepicker/dist/react-datepicker.css";
import recommendationsActions from "../actions";
import projectActions from "../../project/actions";
import NumberFormat from "react-number-format";
import Loader from "../../common/components/Loader";
import Portal from "../../common/components/Portal";
import buildingActions from "../../building/actions";
import userActions from "../../users/actions";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import {
    popBreadCrumpData,
    addToBreadCrumpData,
    findInfoPathFromBreadCrump,
    getUsefullLifeRemaining,
    convertToXML,
    removeAllTags,
    toArrayOfObjects,
    getCurrentAge,
    showAlert
} from "../../../config/utils";
import ReactTooltip from "react-tooltip";
import ImageUploadModal from "../../common/components/ImagesModal";
import RepotNoteTemplateModal from "./RepotNoteTemplateModal";
import AssignAssetModal from "./AssignAssetModal";
import RecommendationTemplateModal from "./RecommendationTemplateModal";
import history from "../../../config/history";
import assetActions from "../../assets/actions";
import Autosuggest from "react-autosuggest";
import AssetForm from "./AssetForm";
import { checkPermission } from "../../../config/utils";
import recomIcon from "../../../assets/img/recom-denoter.svg";
import recomIcon1 from "../../../assets/img/recom-icon.svg";
import RecommendationNoteEdit from "../../common/components/RecommendationNoteEdit";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { EnergyBand } from "./Bands/EnergyBand";
import { WaterBand } from "./Bands/WaterBand";
import { energy_fields } from "./Bands/EnergyBand";
import { water_fields } from "./Bands/WaterBand";
import { DynamicBand } from "./Bands/DynamicBand";
import { DYNAMIC_FIELD_TYPES, LOCK_STATUS, RECOMMENDATION_SOURCE_TYPES } from "../../common/constants";
import { RecommendationBand } from "./Bands/RecommendationBand";
import { FormField } from "./Bands/FormField";
import { AddRemoveButton } from "./AddRemoveButton";
import SelectBox from "../../images/components/SelectBox";
import moment from "moment";
import { CkeditorConfiguration } from "../../smartCharts/CkEditorConfig";
import { MyUploadAdapterPlugin } from "../../smartCharts/CustomImageUploadPlugin";
import { CAPITAL_REQUEST_STATUS } from "../constants";

const editorConfiguration = {
    toolbar: [
        // "fontFamily",
        // "fontSize",
        // "|",
        "bold",
        "italic",
        "underLine",
        "|",
        "alignment:left",
        "alignment:right",
        "alignment:center",
        "alignment:justify",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        // "outdent",
        // "indent",
        "highlight",
        "|",
        "undo",
        "redo"
        // "heading"
    ],
    removePlugins: ["Title", "ListStyle"],
    alignment: {
        options: ["left", "right", "center", "justify"]
    },
    highlight: {
        options: [
            { model: "yellowMarker", class: "marker-yellow", title: "Yellow marker", color: "var(--ck-highlight-marker-yellow)", type: "marker" },
            { model: "greenMarker", class: "marker-green", title: "Green marker", color: "#32CD32", type: "marker" },
            { model: "pinkMarker", class: "marker-pink", title: "Pink marker", color: "#FF00FF", type: "marker" },
            { model: "blueMarker", class: "marker-blue", title: "Blue marker", color: "#0000FF", type: "marker" }
        ]
    },
    placeholder: "Type Here..."
};
class RecommendationForm extends Component {
    constructor(props) {
        super(props);
        const {
            location: { search }
        } = this.props;
        this.templateRef = React.createRef();
        this.suggestionInputRef = React.createRef();
        const query = qs.parse(search);
        this.state = {
            toggleInput: false,
            activeDetail: "Main Details",
            loading: false,
            systemLoading: false,
            subSystemLoading: false,
            regionLoading: false,
            siteLoading: false,
            buildingLoading: false,
            floorLoading: false,
            additionLoading: false,
            clients: "",
            activeBuilding: "",
            showConfirmModal: false,
            building_type: "",
            priorityTotal: 0,
            systemArray: [],
            subSystemArray: [],
            initialPriorityTotal: "",
            costTotal: 0,
            initialCostYear: 0,
            imageUploadModal: false,
            project: {
                code: "",
                client_id: "",
                region_id: "",
                room: "",
                site_id: "",
                notes: "",
                note_html: "",
                asset_condition_id: "",
                project_id: query.p_id || "",
                comments: "",
                image: "",
                building_id: "",
                floor_id: "",
                addition_id: "",
                trade_id: "",
                system_id: "",
                sub_system_id: "",
                img_desc: "",
                category_id: "",
                asset_name: "",
                asset_tag: "",
                recommendationIdentifier: "",
                description: "",
                department_id: "",
                project: "",

                service_life: "",
                usefull_life_remaining: "",
                model_number: "",
                serial_number: "",
                area_served: "",
                installed_year: "",
                image_name: "",
                priority_elements: [
                    { index: "1", element: "" },
                    { index: "2", element: "" },
                    { index: "3", element: "" },
                    { index: "4", element: "" },
                    { index: "5", element: "" },
                    { index: "6", element: "" },
                    { index: "7", element: "" },
                    { index: "8", element: "" }
                ],
                capacity: "",
                capital_type: "",
                funding_source_id: "",
                responsibility_id: "",
                status: "pending",
                status_notes: "",
                cr_status: CAPITAL_REQUEST_STATUS[0]?.id,
                cr_status_notes: "",
                surveyor_id:
                    query?.is_cr !== "true" && localStorage.getItem("userId") && localStorage.getItem("userId") !== "null"
                        ? localStorage.getItem("userId")
                        : "",
                surveyor:
                    query?.is_cr !== "true" && localStorage.getItem("printed_name") && localStorage.getItem("printed_name") !== "null"
                        ? localStorage.getItem("printed_name")
                        : "",
                priority: 0,
                asset_notes: "",
                crv: "",
                inspection_date: "",
                maintenance_years: [],
                manufacturer_id: "",
                quantity: "",
                unit: "",
                cost_per_unit: "",
                options_cost: "",
                asset: {},
                recommendation_type: "building",
                fmp_id: "",
                fmp_project: "",
                fmp_track: "",
                infrastructure_request: localStorage.getItem("infrastructure_request_user") === "yes" || query?.is_cr === "true" ? "yes" : "no",
                fmp: localStorage.getItem("fmp_user") === "yes" ? "yes" : "no",
                red_line: "no",
                budget_priority: "no",
                criticality_id: "",
                source: query.type === RECOMMENDATION_SOURCE_TYPES.GEO_HIERARCHY ? "Same Geo Hierarchy" : query.type ?? "Regular",
                requestor_id:
                    query?.is_cr === "true" && localStorage.getItem("userId") && localStorage.getItem("userId") !== "null"
                        ? localStorage.getItem("userId")
                        : "",
                requested_date: query?.is_cr === "true" ? new Date() : "",
                energy_band: {
                    ecm_description: "",
                    annual_savings: "",
                    implementation_cost: "",
                    simple_payback: "",
                    annual_nat_gas_savings: "",
                    annual_elec_savings: "",
                    total_annual_savings: ""
                },
                water_band: {
                    recommendation_for_improvement: "",
                    annual_savings: "",
                    safety_concerns: "",
                    return_on_investment: ""
                },
                energy_band_show: "no",
                water_band_show: "no",
                dynamic_fields: {}
            },
            isLoading: true,
            errorMessage: "",
            showErrorBorder: false,
            isNewProject: true,
            selectedClient: {},
            regionList: [],
            siteList: [],
            initialValues: {},
            projectList: [],
            selectedImage: "",
            selectedConsultancyUsers: [],
            selectedProject: props.match.params.id,
            uploadError: "",
            attachmentChanged: false,
            locked: "",
            showAssetModal: false,
            assetFormData: assetFormInitialState,
            initialAssetFormData: {},
            showRecommendationTemplateModal: false,
            recommendationTemplateList: [],
            recommendationSuggestions: [],
            captionLength: 1000,
            showBand: {
                band1: true,
                band2: !query?.is_cr,
                band3: false,
                band4: false,
                band5: false,
                band6: false,
                energyBand: false,
                waterBand: false
            },
            priorityElementsData: [],
            criticalityData: [],
            capitalTypeData: [],
            showNoteModal: false,
            note_html: "",
            note_xml: "",
            editorHeight: "310",
            showYearRemoveConfirmation: false,
            selectedRemoveYear: "",
            siteCspYears: { start: "", end: "" },
            birth_year: "",
            cr_narrative: "",
            isLoadingDrop: false
        };
    }

    componentDidMount = async () => {
        this.setState({ initialValues: this.state.project });
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);

        this.setState({
            activeDetail: sessionStorage.getItem("activeDetail")
                ? sessionStorage.getItem("activeDetail")
                : query.active_tab === "additional"
                ? "Additional Details"
                : query.active_tab === "asset"
                ? "Asset Details"
                : "Main Details"
        });
        await this.props.getPriorityElementDropDownData(query.p_id);
        this.props.getRecommendationFieldConfigs({ project_id: query.p_id });
        this.props.getDynamicFields({ project_id: query.p_id, client_id: query.c_id });
        let updatedPriorityElements = this.props.recommendationsReducer.priorityElementsDropDownResponse?.priority_elements || [];
        await this.props.getCriticalityData({ project_id: query.p_id });
        let criticalityData = this.props.projectReducer.criticalityData?.criticalities || [];
        await this.props.getCapitalTypeDropDownData(query.p_id);
        let capitalTypeData = this.props.recommendationsReducer.capitalTypeDropDownResponse?.capital_types || [];
        this.props.getDepartmentByProject(query.p_id);
        this.props.getConditionBasedOnProject(query.p_id);
        this.props.getTradeBasedOnProject(query.p_id);
        this.props.getCategoryBasedOnProject(query.p_id);
        this.props.getCapitalTypeBasedOnProject(query.p_id);
        this.props.getFundingSourceByProject(query.p_id);
        this.props.getResponsibilityByProject(query.p_id);
        this.props.getUsersByProject(query.p_id, { is_surveyor_list: true });
        this.getRecommendationTemplates(undefined, query.p_id);
        await this.props.getProjectById(query.p_id);
        const { getProjectByIdResponse } = this.props.projectReducer;
        await this.setState({
            captionLength: getProjectByIdResponse?.miscellaneous?.reco_length
        });
        this.props.getInitiativeDropdown({ project_id: query.p_id, client_id: query.c_id });
        await this.props.getAllClientsRecomentation();
        this.props.getDropdownList("uniformat_level_1s");
        if (query.c_id) {
            // get asset dropdowns
            this.props.getDropdownList("asset_conditions", { client_id: query.c_id });
            this.props.getDropdownList("asset_statuses", { client_id: query.c_id });
            this.props.getDropdownList("asset_types", { client_id: query.c_id });
            let regionList = await this.props.getRegionListBasedOnClient(query.c_id);
            this.setState({
                regionList,
                project: {
                    ...this.state.project,
                    client_id: query.c_id
                },
                assetFormData: {
                    ...this.state.assetFormData,
                    client_id: query.c_id
                }
            });
        }

        const {
            projectReducer: {
                getAllClientsResponse: { clients }
            }
        } = this.props;

        const { selectedProject } = this.state;
        let projectList = [];
        if (query.inp || selectedProject) {
            projectList = this.props.projectReducer.getAllProjectsResponse.projects;
        }
        if (selectedProject) {
            await this.props.getDataById(selectedProject);
            const {
                recommendationsReducer: {
                    getRecommendationByIdResponse: {
                        code,
                        comments,
                        name,
                        success,
                        description,
                        trade,
                        system,
                        sub_system,
                        floor,
                        addition,
                        department,
                        category,
                        room,
                        asset_name,
                        asset_tag,
                        installed_year,
                        condition,
                        priority,
                        inspection_date,
                        future_capital,
                        project_total,
                        surveyor_id,
                        surveyor,
                        status,
                        status_notes,
                        cr_status,
                        cr_status_notes,
                        initiative,
                        capital_type,
                        service_life,
                        usefull_life_remaining,
                        crv,
                        model_number,
                        serial_number,
                        capacity,
                        asset_notes,
                        area_served,
                        notes,
                        note_html,
                        funding_source,
                        responsibility,
                        lock_status,
                        client,
                        region,
                        site,
                        building,
                        image,
                        maintenance_years,
                        priority_elements,
                        created_at,
                        updated_at,
                        manufacturer,
                        project,
                        quantity,
                        unit,
                        cost_per_unit,
                        options_cost,
                        asset,
                        recommendation_type,
                        fmp,
                        fmp_id,
                        fmp_project,
                        fmp_track,
                        criticality,
                        source,
                        energy_band,
                        water_band,
                        energy_band_show,
                        water_band_show,
                        red_line,
                        budget_priority,
                        infrastructure_request,
                        dynamic_fields,
                        birth_year,
                        requestor,
                        requested_date
                    }
                }
            } = this.props;
            if (success) {
                this.props.getDropdownList("manufacturers", { current_manufacturer: manufacturer?.id });

                updatedPriorityElements = this.getUpdatedPriorityElementsData(
                    priority_elements,
                    this.props.recommendationsReducer.priorityElementsDropDownResponse.priority_elements
                );
                let latestPriorityElements = this.getLatestPriorityElements(
                    priority_elements,
                    this.props.recommendationsReducer.priorityElementsDropDownResponse.priority_elements
                );

                let selectedCriticality = null;
                if (priority_elements?.length && !criticality?.id) {
                    let priorityTotal = 0;
                    let pelement = priority_elements;
                    pelement.map(item => (priorityTotal += Number(item.element)));
                    if (priorityTotal && criticalityData.length) {
                        selectedCriticality = criticalityData.find(
                            c => priorityTotal >= parseFloat(c.start_range) && priorityTotal <= parseFloat(c.end_range)
                        );
                    }
                }
                await this.props.getSystemBasedOnProject(query.p_id, trade.id);
                const systemData = this.props.recommendationsReducer?.getSystemByProject?.systems || [];
                await this.props.getSubSystemBasedOnProject(query.p_id, system.id);
                const subSystemData = this.props.recommendationsReducer?.getSubSystemByProject?.sub_systems || [];
                let regionList = await this.props.getRegionListBasedOnClient(client.id);
                let siteList = await this.props.getSiteListBasedOnRegion(region.id);
                this.props.getFloorBasedOnBuilding(building.id);
                this.props.getAdditionBasedOnBuilding(building.id);
                const dynamicFieldValue = {};
                if (dynamic_fields && Object.keys(dynamic_fields)?.length) {
                    Object.keys(dynamic_fields).forEach(field => {
                        dynamicFieldValue[field] = dynamic_fields[field]?.value;
                    });
                }
                await this.setState({
                    systemArray: systemData,
                    subSystemArray: subSystemData,
                    project: {
                        name,
                        code,
                        comments,
                        description,
                        trade_id: trade.id,
                        system_id: system.id || "",
                        sub_system_id: sub_system.id,
                        floor_id: floor.id,
                        addition_id: addition?.id,
                        department_id: department.id,
                        category_id: category.id,
                        room,
                        asset_name,
                        asset_tag,
                        installed_year,
                        asset_condition_id: condition?.id,
                        priority,
                        inspection_date,
                        future_capital,
                        project_total,
                        surveyor_id:
                            surveyor_id ||
                            this.props.projectReducer.getUserByProjectData?.users?.find(({ printed_name }) => printed_name === surveyor)?.id ||
                            "",
                        surveyor,
                        status,
                        status_notes,
                        cr_status,
                        cr_status_notes,
                        initiative_id: initiative ? initiative.id : "",
                        capital_type: capital_type || "",
                        service_life,
                        usefull_life_remaining,
                        crv,
                        model_number,
                        serial_number,
                        capacity,
                        asset_notes,
                        client_id: client.id || "",
                        region_id: region.id || "",
                        site_id: site.id || "",
                        building_id: building.id || "",
                        building_type: building.building_type,
                        image: image.id ? image : [],
                        maintenance_years,
                        priority_elements: latestPriorityElements,
                        img_desc: image.description || "",
                        area_served,
                        notes: notes || "",
                        note_html: note_html || notes?.replace(/\n/g, "<br />"),
                        image_id: image ? image.id : "",
                        funding_source_id: funding_source.id,
                        responsibility_id: responsibility?.id,
                        manufacturer_id: manufacturer?.id,
                        project,
                        quantity,
                        unit,
                        cost_per_unit,
                        options_cost,
                        asset,
                        asset_id: asset?.id || "",
                        recommendation_type: recommendation_type || "building",
                        fmp,
                        fmp_id,
                        fmp_project,
                        fmp_track,
                        criticality_id: criticality.id || selectedCriticality?.id || "",
                        source: source || "-",
                        energy_band: { ...this.state.project.energy_band, ...energy_band },
                        water_band: { ...this.state.project.water_band, ...water_band },
                        energy_band_show,
                        water_band_show,
                        infrastructure_request,
                        red_line,
                        budget_priority,
                        dynamic_fields: dynamicFieldValue,
                        requestor_id: requestor?.id,
                        requested_date
                    },
                    birth_year,
                    regionList,
                    siteList,
                    selectedImage: image,
                    createdAt: created_at,
                    updatedAt: updated_at,
                    locked: lock_status === LOCK_STATUS.LOCKED,
                    editorHeight: recommendation_type === "asset" ? "400" : "310"
                });
                if (recommendation_type === "asset") {
                    await this.handleAssignAsset(asset);
                }
                this.getCostYear(site?.id);
                this.getRecommendationTemplates(this.state.project.sub_system_id);
                this.props.getAllBuildingsDropdown({ site_id: this.state.project.site_id, project_id: query.p_id });
                let costTotal = 0;
                maintenance_years && maintenance_years.length && maintenance_years.map(item => (costTotal += item.amount));
                this.setState({
                    costTotal: costTotal,
                    initialCostYear: costTotal,
                    initialPriorityTotal: priority,
                    priorityTotal: priority
                });
                this.setState({ initialValues: _.cloneDeep(this.state.project) });
            }
        } else if (
            query.type === RECOMMENDATION_SOURCE_TYPES.SIMILAR ||
            query.type === RECOMMENDATION_SOURCE_TYPES.DUPPLICATE ||
            query.type === RECOMMENDATION_SOURCE_TYPES.GEO_HIERARCHY ||
            query.type === RECOMMENDATION_SOURCE_TYPES.CAPITAL_REQUEST
        ) {
            // set the initial recommendation form values based on the create type
            await this.populateValuesBasedOnType();
        } else {
            await this.props.getUserDefaultTrade(query.p_id);
            const { getUserDefaultTradeResponse } = this.props.recommendationsReducer;
            if (getUserDefaultTradeResponse?.id) {
                await this.setState({
                    project: { ...this.state.project, trade_id: getUserDefaultTradeResponse?.id },
                    assetFormData: { ...this.state.assetFormData, trade_id: getUserDefaultTradeResponse?.id }
                });
                this.handleTrade(getUserDefaultTradeResponse?.id);
            }
        }
        if (!selectedProject) {
            let initialPriorityElementData = this.setPriorirtyElementData(updatedPriorityElements);
            await Promise.all([
                this.props.getSystemBasedOnProject(query.p_id, this.props?.recommendationsReducer?.getRecommendationByIdResponse?.trade?.id),
                this.props.getSubSystemBasedOnProject(query.p_id, this.props?.recommendationsReducer?.getRecommendationByIdResponse?.system?.id)
            ]);
            const systemData = this.props.recommendationsReducer?.getSystemByProject?.systems || [];
            const subSystemData = this.props.recommendationsReducer?.getSubSystemByProject?.sub_systems || [];
            await this.setState({
                project: {
                    ...this.state.project,
                    priority_elements: initialPriorityElementData
                },
                systemArray: systemData,
                subSystemArray: subSystemData
            });
        }

        await this.setState({
            clients,
            projectList,
            isNewProject: query.inp,
            isLoading: false,
            priorityElementsData: updatedPriorityElements,
            criticalityData: criticalityData,
            capitalTypeData: capitalTypeData
        });
        ReactTooltip.rebuild();
        // to set the previous recommendation data
        await this.setPrevRecomData();
        this.setState({ initialAssetFormData: _.cloneDeep(this.state.assetFormData) });
    };

    getUpdatedPriorityElementsData = (currentData = [], dropDownData = []) => {
        let updatedData = [];
        if (currentData.length > dropDownData.length) {
            updatedData = currentData.map((pData, i) => {
                if (dropDownData[i]) {
                    return {
                        ...dropDownData[i]
                    };
                }
                return {
                    ...pData
                };
            });
        } else {
            updatedData = [...dropDownData];
        }
        return updatedData;
    };
    getLatestPriorityElements = (currentData = [], dropDownData = []) => {
        let newElements = [];
        newElements = dropDownData.filter(item => !currentData.map(item => item.recommendation_priority_id).includes(item.id));
        if (newElements.length) {
            newElements.forEach(elem => {
                currentData.push({ index: currentData?.length + 1, element: "", option_id: "", recommendation_priority_id: "" });
            });
        }
        return currentData;
    };

    setPriorirtyElementData = priority_elements => {
        let priorityElementData = [];
        priority_elements.map((element, i) => {
            priorityElementData.push({ index: i + 1, element: "", option_id: "", recommendation_priority_id: "" });
        });
        return priorityElementData;
    };

    setPrevRecomData = async () => {
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        if (JSON.parse(sessionStorage.getItem("currentFormData"))) {
            const project = JSON.parse(sessionStorage.getItem("currentFormData"));
            let systemData = [];
            let subSystemData = [];
            let regionList = [];
            let siteList = [];
            if (!this.state.selectedProject) {
                if (project?.trade_id) {
                    await this.props.getSystemBasedOnProject(query.p_id, project?.trade_id);
                    systemData = this.props.recommendationsReducer?.getSystemByProject?.systems || [];
                }
                if (project?.system_id) {
                    await this.props.getSubSystemBasedOnProject(query.p_id, project?.system_id);
                    subSystemData = this.props.recommendationsReducer?.getSubSystemByProject?.sub_systems || [];
                }
                if (project?.sub_system_id) {
                    this.getRecommendationTemplates(project?.sub_system_id);
                }
                if (project?.client_id) {
                    regionList = await this.props.getRegionListBasedOnClient(project?.client_id);
                }
                if (project?.region_id) {
                    siteList = await this.props.getSiteListBasedOnRegion(project?.region_id);
                }
                if (project?.site_id) {
                    this.props.getAllBuildingsDropdown({ site_id: project?.site_id, project_id: query.p_id });
                }
                if (project?.building_id) {
                    this.props.getFloorBasedOnBuilding(project?.building_id);
                    this.props.getAdditionBasedOnBuilding(project?.building_id);
                }
            }
            await this.setState({
                project,
                systemArray: systemData,
                subSystemArray: subSystemData,
                regionList,
                siteList
            });
            this.assignLastCreatedAsset();
            sessionStorage.removeItem("currentFormData");
            sessionStorage.removeItem("activeDetail");
        }
    };

    assignLastCreatedAsset = async () => {
        let lastCreatedAssetId = sessionStorage.getItem("lastCreatedAssetId");
        if (lastCreatedAssetId) {
            await this.props.getAssetDataById(lastCreatedAssetId);
            const resData = this.props.assetReducer.getDataByIdResponse;
            this.handleAssignAsset(resData);
            sessionStorage.removeItem("lastCreatedAssetId");
        }
    };

    populateValuesBasedOnType = async () => {
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        let recomData = JSON.parse(sessionStorage.getItem("currentRecommendationData"));
        if (recomData) {
            let systemData = [];
            let subSystemData = [];
            let regionList = [];
            let siteList = [];
            if (query.type !== "Building") {
                if (recomData?.trade?.id) {
                    await this.props.getSystemBasedOnProject(query.p_id, recomData?.trade?.id);
                    systemData = this.props.recommendationsReducer?.getSystemByProject?.systems || [];
                }
                if (recomData?.system?.id) {
                    await this.props.getSubSystemBasedOnProject(query.p_id, recomData?.system?.id);
                    subSystemData = this.props.recommendationsReducer?.getSubSystemByProject?.sub_systems || [];
                }
                if (recomData?.sub_system?.id) {
                    this.getRecommendationTemplates(recomData?.sub_system?.id);
                }
            }
            if (recomData?.client?.id) {
                regionList = await this.props.getRegionListBasedOnClient(recomData?.client?.id);
            }
            if (recomData?.region?.id) {
                siteList = await this.props.getSiteListBasedOnRegion(recomData?.region?.id);
            }
            if (recomData?.site?.id) {
                this.props.getAllBuildingsDropdown({ site_id: recomData?.site?.id, project_id: query.p_id });
                this.getCostYear(recomData?.site?.id);
            }
            if (recomData?.building?.id) {
                this.props.getFloorBasedOnBuilding(recomData?.building?.id);
                this.props.getAdditionBasedOnBuilding(recomData?.building?.id);
            }
            let list = { regionList, siteList };
            if (query.type !== "Building") {
                list = { ...list, systemArray: systemData, subSystemArray: subSystemData };
            }
            await this.setState({ ...list });
            const {
                addition,
                area_served,
                asset,
                asset_name,
                asset_notes,
                asset_tag,
                building,
                capacity,
                capital_type,
                category,
                client,
                condition,
                cost_per_unit,
                criticality,
                crv,
                department,
                description,
                energy_band,
                floor,
                funding_source,
                responsibility,
                initiative,
                inspection_date,
                installed_year,
                maintenance_years,
                manufacturer,
                model_number,
                notes,
                note_html,
                options_cost,
                priority,
                priority_elements,
                project,
                project_total,
                quantity,
                recommendation_type,
                region,
                room,
                serial_number,
                service_life,
                site,
                status,
                status_notes,
                cr_status,
                cr_status_notes,
                sub_system,
                surveyor_id,
                surveyor,
                system,
                trade,
                unit,
                usefull_life_remaining,
                water_band,
                energy_band_show,
                water_band_show,
                dynamic_fields,
                requestor,
                requested_date
            } = recomData;
            if (query.type === RECOMMENDATION_SOURCE_TYPES.SIMILAR) {
                await this.setState({
                    project: {
                        ...this.state.project,
                        addition_id: addition?.id,
                        building_id: building?.id,
                        building_type: building?.building_type,
                        floor_id: floor?.id,
                        inspection_date,
                        recommendation_type,
                        region_id: region?.id,
                        room,
                        site_id: site?.id,
                        status,
                        sub_system_id: sub_system?.id,
                        system_id: system?.id,
                        trade_id: trade?.id
                    }
                });
                this.handleRecommendationTypeSelect();
            } else if (query.type === RECOMMENDATION_SOURCE_TYPES.DUPPLICATE || query.type === RECOMMENDATION_SOURCE_TYPES.CAPITAL_REQUEST) {
                // ----> not copying surveyor field
                // ----> not copying inspection date if diff user is duplicating
                // ----> not copying dynamic field which have bbi and bsi link
                const dynamicFieldValue = {};
                if (dynamic_fields && Object.keys(dynamic_fields)?.length) {
                    Object.keys(dynamic_fields).forEach(field => {
                        dynamicFieldValue[field] =
                            dynamic_fields[field]?.link_bbi || dynamic_fields[field]?.link_bsi ? "" : dynamic_fields[field]?.value;
                    });
                }
                const currentUser = localStorage.getItem("userId");
                const isSameOwner = currentUser === surveyor_id;
                await this.setState({
                    project: {
                        ...this.state.project,
                        addition_id: addition?.id,
                        area_served,
                        asset_name,
                        asset_notes,
                        asset_tag,
                        building_id: building.id,
                        building_type: building.building_type,
                        capacity,
                        capital_type: capital_type || "",
                        category_id: category?.id,
                        client_id: client?.id,
                        asset_condition_id: condition?.id,
                        cost_per_unit,
                        crv,
                        department_id: department.id,
                        description,
                        floor_id: floor.id,
                        funding_source_id: funding_source.id,
                        responsibility_id: responsibility.id,
                        initiative_id: initiative?.id,
                        inspection_date: isSameOwner ? inspection_date : "",
                        requestor_id: query.type === RECOMMENDATION_SOURCE_TYPES.CAPITAL_REQUEST ? requestor?.id : this.state.project.requestor_id,
                        requested_date,
                        installed_year,
                        maintenance_years,
                        manufacturer_id: manufacturer?.id,
                        model_number,
                        notes: query.type === RECOMMENDATION_SOURCE_TYPES.CAPITAL_REQUEST ? "" : notes || "",
                        note_html: query.type === RECOMMENDATION_SOURCE_TYPES.CAPITAL_REQUEST ? "" : note_html || notes,
                        options_cost,
                        priority,
                        priority_elements,
                        project,
                        project_total,
                        quantity,
                        recommendation_type: recommendation_type || "asset",
                        region_id: region.id,
                        room,
                        serial_number,
                        service_life,
                        site_id: site.id,
                        status,
                        status_notes,
                        cr_status,
                        cr_status_notes,
                        sub_system_id: sub_system.id,
                        system_id: system.id,
                        trade_id: trade.id,
                        unit,
                        usefull_life_remaining,
                        criticality_id: criticality?.id,
                        energy_band: {
                            annual_savings: energy_band?.annual_savings || "",
                            total_captial_spending_plan_kbtu_savings: energy_band?.total_captial_spending_plan_kbtu_savings || "",
                            implementation_cost: energy_band?.implementation_cost || "",
                            simple_payback: energy_band?.simple_payback || "",
                            annual_nat_gas_savings: energy_band?.annual_nat_gas_savings || "",
                            annual_elec_savings: energy_band?.annual_elec_savings || "",
                            total_annual_savings: energy_band?.total_annual_savings || "",
                            annual_energy_savings: energy_band?.annual_energy_savings || "",
                            annual_cost_to_implement_ecm: energy_band?.annual_cost_to_implement_ecm || ""
                        },
                        water_band: {
                            annual_savings_water: water_band?.annual_savings_water || "",
                            safety_concerns: water_band?.safety_concerns || "",
                            simple_payback_water: water_band?.simple_payback_water || ""
                        },
                        energy_band_show,
                        water_band_show,
                        dynamic_fields: dynamicFieldValue
                    },
                    costTotal: project_total,
                    initialPriorityTotal: priority,
                    priorityTotal: priority,
                    cr_narrative: query.type === RECOMMENDATION_SOURCE_TYPES.CAPITAL_REQUEST ? note_html : ""
                });
                if (query.type === RECOMMENDATION_SOURCE_TYPES.DUPPLICATE) {
                    await this.duplicateAsset(asset);
                }
            } else if (query.type === RECOMMENDATION_SOURCE_TYPES.GEO_HIERARCHY) {
                await this.setState({
                    project: {
                        ...this.state.project,
                        building_id: building?.id,
                        building_type: building?.building_type,
                        region_id: region?.id,
                        site_id: site?.id,
                        floor_id: floor.id,
                        addition_id: addition?.id,
                        room
                    }
                });
            }
        }
    };

    duplicateAsset = async asset => {
        await this.setState({
            assetFormData: {
                code: "",
                asset_name: asset.asset_name,
                asset_tag: "",
                asset_note: asset.asset_note,
                client_asset_condition_id: asset.asset_condition?.id,
                installed_year: asset.installed_year,
                service_life: asset.service_life,
                usefull_life_remaining: asset.usefull_life_remaining,
                crv: asset.crv,
                manufacturer_id: asset.manufacturer?.id,
                year_manufactured: asset.year_manufactured,
                model_number: asset.model_number,
                serial_number: "",
                capacity: asset.capacity,
                criticality: asset.criticality,
                area_served: asset.area_served,
                client_id: asset.client?.id,
                region_id: asset.region?.id,
                site_id: asset.site?.id,
                building_id: asset.building?.id,
                addition_id: asset.addition?.id,
                floor_id: asset.floor?.id,
                room_number: asset.room_number,
                room_name: asset.room_name,
                location: asset.location,
                architectural_room_number: asset.architectural_room_number,
                additional_room_description: asset.additional_room_description,
                uniformat_level_1_id: asset.uniformat_level_1?.id,
                uniformat_level_2_id: asset.uniformat_level_2?.id,
                uniformat_level_3_id: asset.uniformat_level_3?.id,
                uniformat_level_4_id: asset.uniformat_level_4?.id,
                uniformat_level_5_id: asset.uniformat_level_5?.id,
                asset_type_id: asset.asset_type?.id,
                asset_description: asset.asset_description,
                asset_barcode: "",
                asset_client_id: asset.asset_client_id,
                asset_cmms_id: asset.asset_cmms_id,
                warranty_start: asset.warranty_start,
                warranty_end: asset.warranty_end,
                install_date: asset.install_date,
                startup_date: asset.startup_date,
                upstream_asset_barcode_number: asset.upstream_asset_barcode_number,
                linked_asset_barcode_number: asset.linked_asset_barcode_number,
                source_panel_barcode_number: asset.source_panel_barcode_number,
                source_panel: asset.source_panel,
                asset_status_id: asset.asset_status?.id,
                notes: asset.notes,
                building_type: asset?.building_type?.name,
                main_category_id: asset?.main_category?.id,
                sub_category_1_id: asset?.sub_category_1?.id,
                sub_category_2_id: asset?.sub_category_2?.id,
                sub_category_3_id: asset?.sub_category_3?.id,
                guid: "",
                source_panel_name: asset?.source_panel_name,
                skysite_hyperlink: asset?.skysite_hyperlink,
                trade_id: asset.trade?.id,
                system_id: asset.system?.id,
                sub_system_id: asset.sub_system?.id,

                quantity: asset?.quantity,
                rtls_tag: asset?.rtls_tag,
                latitude: asset?.latitude,
                longitude: asset?.longitude,
                current_age: asset?.current_age,
                age: asset?.age,
                new_asset: asset?.new_asset,
                parent_global_id: asset?.parent_global_id,
                survey_global_id: asset?.survey_global_id,
                survey_id: asset?.survey_id,
                survey_property_note: asset?.survey_property_note,
                capacity_status: asset?.capacity_status,
                installed_year_status: asset?.installed_year_status,
                name_plate_status: asset?.name_plate_status,
                qa_notes: asset?.qa_notes,
                additional_qa_notes: asset?.additional_qa_notes,
                surveyor: asset?.surveyor,
                editor: asset?.editor,
                survey_date_created: asset?.survey_date_created,
                survey_date_edited: asset?.survey_date_edited,
                description: asset?.asset_condition?.description,
                uniformat_level_6_id: asset?.uniformat_level_6?.id,
                uniformat_level_6_description: asset?.uniformat_level_6?.uniformat_level_6_description,
                subcategory2_description: asset?.sub_category_2?.subcategory2_description,
                capacity_unit: asset?.capacity_unit,
                refrigerant: asset?.refrigerant
            }
        });
        this.getSelectedAssetDropdowns();
        this.setState({ initialAssetFormData: _.cloneDeep(this.state.assetFormData) });
    };

    onSelectConsultancyUsers = async selectedConsultancyUsers => {
        const { project } = this.state;
        let tempUsers = [];
        if (selectedConsultancyUsers.length) {
            selectedConsultancyUsers.map(item => tempUsers.push(item.id));
        }

        await this.setState({
            project: {
                ...project,
                consultancy_user_ids: tempUsers
            },
            selectedConsultancyUsers
        });
    };

    scrollRight = event => {
        let conent = document.querySelector("#yearList");
        conent.scrollLeft += 203;
        event.preventDefault();
    };

    scrollLeft = event => {
        let conent = document.querySelector("#yearList");
        conent.scrollLeft -= 203;
        event.preventDefault();
    };

    confirmCancel = () => {
        const { costTotal, initialCostYear, priorityTotal, project } = this.state;
        if (
            _.isEqual(this.state.initialValues, this.state.project) &&
            _.isEqual(this.state.assetFormData, this.state.initialAssetFormData) &&
            costTotal === initialCostYear &&
            priorityTotal === project.priority
        ) {
            this.cancelForm();
        } else {
            this.setState({
                showConfirmModal: true
            });
        }
    };

    handleClientSelect = async e => {
        this.setState({
            regionLoading: true
        });
        const { project } = this.state;
        let regionList = await this.props.getRegionListBasedOnClient(project.client_id);
        this.setState({
            regionList,
            project: {
                ...project,
                region_id: "",
                site_id: ""
            },
            siteList: [],
            regionLoading: false
        });
    };

    setMaintenanceYear = (e, name) => {
        const { value } = e;
        let costTotal = 0;
        let myear = this.state.project.maintenance_years;
        const oldData = myear.filter(i => i.year == name);
        if (oldData.length) {
            myear.map(i => (i.amount = i.year == name ? value : i.amount));
        } else {
            const newObject = { year: name, amount: value };
            myear.push(newObject);
        }
        this.setState({
            project: {
                ...this.state.project,
                maintenance_years: myear
            }
        });
        myear.map(item => (costTotal += Number(item.amount)));
        this.setState({
            costTotal: costTotal
        });

        // if criticality score is based on the years
        if (this.props.projectReducer.getProjectByIdResponse?.miscellaneous?.criticality_score === "year") {
            this.handleCriticalityByYear(myear);
        }
    };

    handleCriticalityByYear = years => {
        const {
            project: { criticality_id },
            criticalityData
        } = this.state;
        let selectedCriticality = criticality_id;
        const firstCostYear = years.find(year => parseInt(year.amount) > 0)?.year;
        if (firstCostYear) {
            selectedCriticality =
                criticalityData.find(c => firstCostYear >= parseFloat(c.start_range) && firstCostYear <= parseFloat(c.end_range))?.id || "";
            this.setState({ project: { ...this.state.project, criticality_id: selectedCriticality } });
        }
    };

    renderConfirmationModal = () => {
        const { showConfirmModal, selectedProject } = this.state;
        if (!showConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Do you want to ${selectedProject ? "cancel" : "clear"} and lose all changes?`}
                        type="cancel"
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => this.setState({ showConfirmModal: false })}
                        onYes={() => {
                            this.cancelForm();
                        }}
                    />
                }
                onCancel={() => this.setState({ showConfirmModal: false })}
            />
        );
    };

    setPriorityelement = (e, priorityId) => {
        const {
            priorityElementsData,
            criticalityData,
            project: { criticality_id }
        } = this.state;
        const { name, value } = e.target;
        let selectedPriority = priorityElementsData.find(p => p.id === priorityId);
        let selectedOption = selectedPriority.options.find(o => o.id === value);
        let priorityTotal = 0;
        let pelement = this.state.project.priority_elements;
        pelement.map(i => {
            if (i.index == name) {
                if (!selectedOption) {
                    i.recommendation_priority_id = priorityId;
                    i.element = value ? parseInt(value) : "";
                    i.option_id = "";
                } else {
                    i.recommendation_priority_id = priorityId;
                    i.option_id = value;
                    i.element = parseInt(selectedOption.value);
                }
            }
            // i.element = i.index == name ? parseInt(value) : i.element
        });
        pelement.map(item => (priorityTotal += Number(item.element)));
        // if criticality score is based on the priority
        let selectedCriticality = criticality_id;
        if (this.props.projectReducer.getProjectByIdResponse?.miscellaneous?.criticality_score !== "year") {
            if (priorityTotal && criticalityData.length) {
                selectedCriticality =
                    criticalityData.find(c => priorityTotal >= parseFloat(c.start_range) && priorityTotal <= parseFloat(c.end_range))?.id || "";
            }
        }
        this.setState({
            project: {
                ...this.state.project,
                priority_elements: pelement,
                criticality_id: selectedCriticality
            },
            priorityTotal: priorityTotal
        });
    };

    addMaintenanceYear = () => {
        let currentState = this.state.project.maintenance_years;
        const nextYear = this.state.project.maintenance_years.length
            ? parseInt(this.state.project.maintenance_years[this.state.project.maintenance_years.length - 1].year) + 1
            : new Date().getFullYear();

        currentState.push({ year: nextYear, amount: 0.0 });
        this.setState({
            project: {
                ...this.state.project,
                maintenance_years: currentState
            }
        });
        this.handleBandClick("band3", true);
    };

    removeMaintenanceYear = item => {
        const amount = parseInt(item?.amount || 0);
        if (amount > 0) {
            this.setState({ showYearRemoveConfirmation: true, selectedRemoveYear: item });
        } else {
            this.onRemoveMaintenanceYearConfirm(item);
        }
    };

    onRemoveMaintenanceYearConfirm = item => {
        let currentState = this.state.project.maintenance_years.filter(items => items.year !== item.year);
        let currentCostTotal = this.state.costTotal;
        this.setState({
            project: {
                ...this.state.project,
                maintenance_years: currentState
            },
            costTotal: currentCostTotal - parseInt(item.amount || 0)
        });
    };

    handleRegionSelect = async e => {
        const {
            location: { search }
        } = this.props;

        const query = qs.parse(search);
        this.setState({
            siteLoading: true
        });
        const { project } = this.state;
        let siteList = await this.props.getSiteListBasedOnRegion(project.region_id, { project_id: query.p_id });
        this.setState({
            siteList,
            project: {
                ...project,
                site_id: "",
                building_id: "",
                building_type: "",
                addition_id: "",
                floor_id: ""
            },
            siteLoading: false
        });
    };

    handleFloorSelect = async value => {
        this.setState({
            floorLoading: true
        });
        let floorList = await this.props.getFloorBasedOnBuilding(value);
        this.setState({
            floorList,
            floorLoading: false
        });
    };
    handleAdditionSelect = async value => {
        this.setState({
            additionLoading: true
        });
        let additionList = await this.props.getAdditionBasedOnBuilding(value);
        this.setState({
            additionList,
            additionLoading: false
        });
    };

    handleSiteSelect = async site_id => {
        const {
            location: { search }
        } = this.props;

        const query = qs.parse(search);
        const { project, assetFormData } = this.state;
        this.setState({
            project: {
                ...project,
                site_id,
                building_id: "",
                building_type: "",
                addition_id: "",
                floor_id: ""
            },
            assetFormData: {
                ...assetFormData,
                site_id,
                building_id: "",
                building_type: "",
                addition_id: "",
                floor_id: ""
            }
        });
        this.props.getAllBuildingsDropdown({ site_id, project_id: query.p_id });
        this.getCostYear(site_id);
    };

    validate = () => {
        const { project, selectedProject, assetFormData, costTotal } = this.state;
        let showErrorBorder = false;
        let errorMessage = "";
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        if (query?.is_cr === "true") {
            if (!project.description?.trim()?.length) {
                errorMessage = "* Capital request is required";
                showErrorBorder = true;
            } else if (!project.note_html?.trim()?.length) {
                errorMessage = "* Capital request narrative is required";
                showErrorBorder = true;
            }
        } else {
            let role = localStorage.getItem("role");
            let validatePriorityElementResult = this.validatePriorityElements();
            let validateDynamicFields = this.validateDynamicFields();
            const { getProjectByIdResponse } = this.props.projectReducer || {};
            const { miscellaneous } = getProjectByIdResponse || {};
            const isAsset = project.recommendation_type === "asset";
            if (!project.client_id?.trim()?.length) {
                errorMessage = "* Client is required";
                showErrorBorder = true;
            } else if (!project.trade_id?.trim()?.length) {
                errorMessage = "* Trade is required";
                showErrorBorder = true;
            } else if (!project.system_id?.trim()?.length) {
                showErrorBorder = true;
                errorMessage = "* System is required";
            } else if (!project.sub_system_id?.trim()?.length) {
                showErrorBorder = true;
                errorMessage = "* SubSystem is required";
            } else if (!project.description?.trim()?.length) {
                errorMessage = "* Recommendation is required";
                showErrorBorder = true;
            } else if (!project.region_id?.trim()?.length) {
                errorMessage = "* Region is required";
                showErrorBorder = true;
            } else if (!project.site_id?.trim()?.length) {
                errorMessage = "* Site is required";
                showErrorBorder = true;
            } else if (!project.building_id?.trim()?.length) {
                errorMessage = "* Building is required";
                showErrorBorder = true;
            } else if (miscellaneous?.addition_required && !project.addition_id?.trim()?.length) {
                showErrorBorder = true;
                errorMessage = "* Addition  is required";
            } else if (miscellaneous?.floor_required && !project.floor_id?.trim()?.length) {
                showErrorBorder = true;
                errorMessage = "* Floor  is required";
            } else if (miscellaneous?.room_required && !project?.room?.trim()?.length) {
                showErrorBorder = true;
                errorMessage = "* Room  is required";
            } else if (
                !selectedProject &&
                miscellaneous?.project_total_required &&
                !this.props.recommendationsReducer.getCostYearByProject.year_limits
            ) {
                showErrorBorder = true;
                errorMessage = "* No maintenance years defined for the site";
            } else if (miscellaneous?.display_unit && !project.unit?.trim()?.length) {
                showErrorBorder = true;
                errorMessage = "* Unit is required";
            } else if (miscellaneous?.display_unit && (isNaN(project.cost_per_unit) || project.cost_per_unit <= 0)) {
                showErrorBorder = true;
                errorMessage = "* Cost per unit is required";
            } else if (miscellaneous?.display_unit && (isNaN(project.quantity) || project.quantity <= 0)) {
                showErrorBorder = true;
                errorMessage = "* Quantity is required";
            } else if (miscellaneous?.project_total_required && costTotal === 0) {
                showErrorBorder = true;
                errorMessage = "* Project Total cannot be zero";
            } else if (isAsset && !assetFormData.asset_name) {
                showErrorBorder = true;
                errorMessage = "* Asset name is required";
            } else if (isAsset && !assetFormData.client_asset_condition_id) {
                showErrorBorder = true;
                errorMessage = "* Asset condition is required";
            } else if (isAsset && miscellaneous?.asset_tag_required && !assetFormData.asset_tag) {
                showErrorBorder = true;
                errorMessage = "* Asset Tag is required";
            } else if (isAsset && miscellaneous?.serial_number_required && !assetFormData.serial_number) {
                showErrorBorder = true;
                errorMessage = "* Serial Number is required";
            } else if (isAsset && miscellaneous?.model_number_required && !assetFormData.model_number) {
                showErrorBorder = true;
                errorMessage = "* Model Number is required";
            } else if (isAsset && miscellaneous?.manufacturer_required && !assetFormData.manufacturer_id) {
                showErrorBorder = true;
                errorMessage = "* Manufacturer is required";
            } else if (isAsset && miscellaneous?.capacity_required && !assetFormData.capacity) {
                showErrorBorder = true;
                errorMessage = "* Capacity is required";
            } else if (isAsset && miscellaneous?.capacity_unit_required && !assetFormData.capacity_unit) {
                showErrorBorder = true;
                errorMessage = "* Capacity unit is required";
            } else if (isAsset && miscellaneous?.refrigerant_required && !assetFormData.refrigerant) {
                showErrorBorder = true;
                errorMessage = "* Refrigerant is required";
            } else if (project.recommendation_type === "building" && !project.asset_condition_id) {
                showErrorBorder = true;
                errorMessage = "* Condition is required";
            } else if (!project.category_id?.trim()?.length) {
                showErrorBorder = true;
                errorMessage = "* Category is required";
            } else if (!project.capital_type?.trim()?.length) {
                showErrorBorder = true;
                errorMessage = "* Capital is required";
            } else if (miscellaneous?.funding_required && !project.funding_source_id?.trim()?.length) {
                showErrorBorder = true;
                errorMessage = "* Funding is required";
            } else if (miscellaneous?.responsibility_required && !project.responsibility_id?.trim()?.length) {
                showErrorBorder = true;
                errorMessage = "* Responsibility is required";
            } else if (miscellaneous?.recommendation_narrative_required && !project?.note_html?.trim()?.length) {
                showErrorBorder = true;
                errorMessage = "* Recommendation narrative is required";
            } else if (!project.inspection_date) {
                showErrorBorder = true;
                errorMessage = "* Inspection date is required";
            } else if (project.inspection_date && !moment(project.inspection_date).isValid()) {
                showErrorBorder = true;
                errorMessage = "* Invalid inspection date";
            } else if (!project.status) {
                showErrorBorder = true;
                errorMessage = "* Status is required";
            } else if (!project.code && role === "super_admin" && selectedProject) {
                showErrorBorder = true;
                errorMessage = "* Code is required";
            } else if (project.code && role === "super_admin" && !project.code?.split("-")[2]) {
                showErrorBorder = true;
                errorMessage = "* Invalid code format";
            } else if (!validatePriorityElementResult.isValidate) {
                showErrorBorder = true;
                errorMessage = validatePriorityElementResult.errorMessage;
            } else if (!validateDynamicFields.isValidate) {
                showErrorBorder = true;
                errorMessage = validateDynamicFields.errorMessage;
            }
        }

        this.setState({ showErrorBorder, errorMessage });
        if (showErrorBorder) return false;
        return true;
    };
    // handleChangeInput = async description => {

    //   };

    validatePriorityElements = () => {
        const { priorityElementsData, project } = this.state;
        let isValidate = true;
        let errorMessage = "";
        priorityElementsData &&
            priorityElementsData.map((pElement, index) => {
                if (
                    pElement.recommendation_required &&
                    (!project.priority_elements[index]?.element || !project.priority_elements[index]?.element.toString()?.trim()?.length) &&
                    project.priority_elements[index]?.element !== 0
                ) {
                    isValidate = false;
                    if (errorMessage === "") {
                        errorMessage = `* ${pElement.display_name} is required`;
                    }
                }
            });
        return { isValidate, errorMessage };
    };

    addProject = async () => {
        try {
            let {
                project: { asset, ...rest },
                selectedImage,
                initialValues,
                capitalTypeData
            } = this.state;
            const {
                location: { search }
            } = this.props;
            const query = qs.parse(search);
            let selectedCapitalType = capitalTypeData.find(cType => cType.name === this.state.project.capital_type);
            const { handleAddRecommendations } = this.props;
            if (this.validate()) {
                this.setState({ loading: true });
                if (rest.recommendation_type === "asset") {
                    rest = await this.manageAssetData(asset, rest);
                } else {
                    rest.asset_id = "";
                }
                rest.dynamic_fields = toArrayOfObjects(rest.dynamic_fields || {});
                if (this.state.selectedProject) {
                    let redirectUrl = findInfoPathFromBreadCrump();
                    if (redirectUrl?.length === 3) {
                        addToBreadCrumpData({
                            key: "Name",
                            name: this.state.project.code,
                            path: `/recommendations/recommendationsinfo/${this.state.selectedProject}/${
                                this.state.activeDetail === "Main Details" ? "maindetails" : "additionaldetails"
                            }`
                        });
                        addToBreadCrumpData({
                            key: "info",
                            name: `${this.state.activeDetail === "Main Details" ? "Main Details" : "Additional Details"}`,
                            path: `/recommendations/recommendationsinfo/${this.state.selectedProject}/${
                                this.state.activeDetail === "Main Details" ? "maindetails" : "additionaldetails"
                            }`
                        });
                    }
                    let newData = {};
                    // newData.code = rest.code;
                    newData.building_id = rest.building_id;
                    newData.recommendation_type = rest.recommendation_type;
                    Object.entries(rest).forEach(([key, value]) => {
                        if (!_.isEqual(value, initialValues[key])) {
                            if (key === "manufacturer_id") {
                                newData[key] = value;
                            } else {
                                newData[key] = value;
                            }
                        }
                    });
                    if (newData.hasOwnProperty("capital_type")) {
                        newData.recommendation_capital_type_id = selectedCapitalType?.id || "";
                    }
                    if (query?.is_cr === "true") {
                        newData.is_cr = true;
                    }
                    await this.props.handleUpdateRecommendations(newData, selectedImage, this.state.selectedProject);
                    this.setState({ loading: false });
                } else {
                    popBreadCrumpData();
                    await handleAddRecommendations({
                        ...rest,
                        recommendation_capital_type_id: selectedCapitalType?.id || "",
                        ...(query?.is_cr === "true" ? { is_cr: true, cr_status: CAPITAL_REQUEST_STATUS[0]?.id } : {}),
                        ...(query?.type === RECOMMENDATION_SOURCE_TYPES.CAPITAL_REQUEST ? { capital_request_id: query?.capital_request_id } : {})
                    });
                    this.setState({ loading: false });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    manageAssetData = async (asset, rest) => {
        let assetFormData = this.state.assetFormData;
        const { initialAssetFormData } = this.state;
        if (!asset.id) {
            if (assetFormData.auxiliary && Object.values(assetFormData.auxiliary).filter(e => e).length) {
                assetFormData = {
                    ...assetFormData,
                    asset_auxiliary: {
                        auxiliary: [
                            {
                                ...assetFormData.auxiliary
                            }
                        ]
                    }
                };
                delete assetFormData.auxiliary;
            }
            await this.props.addData(assetFormData);
            const { asset_id } = this.props.assetReducer.addDataResponse;
            rest.asset_id = asset_id;
        } else if (!_.isEqual(this.state.assetFormData, this.state.initialAssetFormData)) {
            let newData = {};
            newData.asset_name = assetFormData.asset_name;
            Object.entries(assetFormData).map(([key, value]) => {
                if (value !== initialAssetFormData[key]) {
                    newData[key] = value;
                }
            });
            if (newData.auxiliary && Object.values(newData.auxiliary).filter(e => e).length) {
                newData = {
                    ...newData,
                    asset_auxiliary: {
                        auxiliary: [
                            {
                                ...newData.auxiliary
                            }
                        ]
                    }
                };
                delete newData.auxiliary;
            }
            await this.props.updateData(asset?.id, newData);
            rest.asset_id = asset?.id;
        } else {
            rest.asset_id = asset?.id;
        }
        return rest;
    };

    updateProject = async () => {
        const { project } = this.state;
        const { handleUpdateRecommendations } = this.props;
        if (this.validate()) {
            await handleUpdateRecommendations(project);
        }
    };

    cancelForm = () => {
        const { history } = this.props;
        popBreadCrumpData();
        let redirectUrl = findInfoPathFromBreadCrump();
        if (!this.props.isChartView) {
            history.push(redirectUrl[redirectUrl.length - 1]?.path || "/recommendations");
        } else {
            history.push(redirectUrl[6]?.path || "/recommendations");
        }
    };

    getSubSystem = systemId => {
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        this.props.getSubSystemBasedOnProject(query.p_id, systemId);
    };

    clearForm = async () => {
        await this.setState({
            project: {
                consultancy_user_ids: [],
                client_id: "",
                name: "",
                comments: "",
                region_id: ""
            },
            selectedClient: {},
            selectedConsultancyUsers: []
        });
    };

    isIterable = obj => {
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === "function";
    };

    handleAddAttachment = e => {
        this.setState({
            imageUploadModal: true
        });
    };

    setActiveTab = activeTab => {
        this.setState({ activeDetail: activeTab }, () => ReactTooltip.rebuild());
    };

    handleIdentifier = value => {
        this.setState({ project: { recommendationIdentifier: value } });
    };

    handleRecomentation = value => {
        this.setState({ project: { recommendation: value } });
    };

    getCostYear = async (site_id = "") => {
        if (!site_id) {
            this.setState({
                siteCspYears: { start: "", end: "" }
            });
            return;
        }
        const {
            location: { search }
        } = this.props;

        let costPerYear = {};
        const query = qs.parse(search);
        await this.props.getCostYearByProject(query.p_id, site_id || this.state.project.site_id);
        costPerYear = this.props.recommendationsReducer?.getCostYearByProject?.year_limits;
        if (costPerYear?.start && costPerYear?.end) {
            let currentState = this.state.project.maintenance_years;
            for (let i = costPerYear.start; i <= costPerYear.end; i++) {
                const index = currentState.findIndex(item => item.year === i);
                if (index === -1) {
                    let insertIndex = 0;
                    while (insertIndex < currentState.length && currentState[insertIndex].year < i) {
                        insertIndex++;
                    }
                    currentState.splice(insertIndex, 0, { year: i, amount: "" });
                }
            }
            await this.setState({
                project: {
                    ...this.state.project,
                    maintenance_years: currentState
                },
                siteCspYears: { start: costPerYear?.start, end: costPerYear?.end }
            });
        } else {
            await this.setState({
                alertMessage: "No maintenance years defined for this site.",
                siteCspYears: { start: "", end: "" }
            });
            this.showLongAlert();
        }
    };

    showLongAlert = () => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show-long-alert";
            x.innerText = this.state.alertMessage;
            setTimeout(function () {
                x.className = x.className.replace("show-long-alert", "");
            }, 6000);
        }
    };

    handleImage = e => {};

    deleteImage = () => {
        this.setState({
            attachmentChanged: true,
            project: {
                ...this.state.project,
                image: null,
                img_desc: null,
                image_id: null,
                image_name: ""
            },
            selectedImage: null
        });
    };

    handleAddImage = imageData => {
        this.setState({
            uploadError: "",
            imageUploadModal: false
        });
        if (!imageData.id) {
            this.setState({
                attachmentChanged: true,
                project: {
                    ...this.state.project,
                    image: imageData.file,
                    img_desc: imageData.comments
                },
                previewImage: URL.createObjectURL(imageData.file)
            });
        } else if (imageData.comments) {
            this.setState({
                project: {
                    ...this.state.project,
                    img_desc: imageData.comments
                }
            });
        }
    };

    handleTrade = async tradeId => {
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        this.setState({
            systemLoading: true,
            subSystemLoading: true
        });
        await Promise.all([
            this.props.getSystemBasedOnProject(query.p_id, tradeId),
            this.props.getSubSystemBasedOnProject(query.p_id, null, tradeId)
        ]);
        const systemData = this.props.recommendationsReducer?.getSystemByProject?.systems || [];
        const subSystemData = this.props.recommendationsReducer?.getSubSystemByProject?.sub_systems || [];

        this.setState({
            project: {
                ...this.state.project,
                system_id: "",
                sub_system_id: ""
            },
            systemArray: [],
            subSystemArray: []
        });
        setTimeout(() => {
            this.setState({
                systemArray: systemData,
                subSystemArray: subSystemData,
                systemLoading: false,
                subSystemLoading: false
            });
        }, 100);
    };

    handleSystem = async systemId => {
        this.setState({
            subSystemLoading: true
        });
        let data = [];
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        await this.props.getSubSystemBasedOnProject(query.p_id, systemId, this.state.project.trade_id);
        data = this.props.recommendationsReducer?.getSubSystemByProject?.sub_systems || [];
        this.setState({
            project: {
                ...this.state.project,
                sub_system_id: ""
            },
            subSystemArray: []
        });
        setTimeout(() => {
            this.setState({
                subSystemArray: data,
                subSystemLoading: false
            });
        }, 100);
    };

    toggleShowReportNoteTemplateModal = () => {
        const { showReportNoteTemplateModal } = this.state;
        this.setState({
            showReportNoteTemplateModal: !showReportNoteTemplateModal
        });
    };

    toggleShowRecommendationTemplateModal = () => {
        const { showRecommendationTemplateModal } = this.state;
        this.setState({
            showRecommendationTemplateModal: !showRecommendationTemplateModal
        });
    };

    renderRecommendationTemplateModal = () => {
        const { showRecommendationTemplateModal, project } = this.state;
        if (!showRecommendationTemplateModal || !project.sub_system_id) return null;
        return (
            <Portal
                body={
                    <RecommendationTemplateModal
                        heading={"Recommendation Templates"}
                        sub_system_id={project.sub_system_id || null}
                        onOk={this.onSuggestionSelected}
                        onCancel={() => {
                            this.setState({ showRecommendationTemplateModal: false });
                        }}
                    />
                }
                onCancel={() => this.setState({ showRecommendationTemplateModal: false })}
            />
        );
    };

    updateTextBandContent = async newBandContent => {
        const { project } = this.state;
        this.setState({
            project: {
                ...project,
                note_html: project.note_html + newBandContent
            }
        });
    };

    onSuggestionSelected = newBandContent => {
        const { project, captionLength, assetFormData } = this.state;
        const {
            text_format = "",
            cost_per_unit = "",
            unit = "",
            description = "",
            avg_useful_life: service_life,
            recommendation_type,
            trade,
            system,
            sub_system
        } = newBandContent;
        let newContent = text_format?.substr(0, captionLength) || "";
        this.setState({
            project: {
                ...project,
                description: newContent,
                cost_per_unit: cost_per_unit || project.cost_per_unit,
                unit: unit || project.unit,
                options_cost: project.quantity ? cost_per_unit * project.quantity : project.options_cost,
                notes: project.notes ? `${project.notes} ${description}` : description,
                note_html: project.note_html
                    ? `${project.note_html} ${description?.replace(/\n/g, "<br />") || ""}`
                    : description?.replace(/\n/g, "<br />"),
                service_life: service_life || project.service_life,
                usefull_life_remaining: getUsefullLifeRemaining(project.installed_year, service_life || project.service_life),
                recommendation_type: recommendation_type || project.recommendation_type,
                criticality_id: newBandContent?.criticality?.id || project.criticality_id,
                capital_type: newBandContent?.capital_type?.name || project.capital_type,
                funding_source_id: newBandContent?.funding_source?.id || project.funding_source_id,
                responsibility_id: newBandContent?.responsibility?.id || project.responsibility_id,
                category_id: newBandContent?.category?.id || project.category_id,
                asset_condition_id:
                    recommendation_type === "building" && newBandContent?.condition?.id ? newBandContent?.condition?.id : project.asset_condition_id,
                trade_id: trade?.id,
                system_id: system?.id,
                sub_system_id: sub_system?.id
            },
            assetFormData: {
                ...assetFormData,
                client_asset_condition_id:
                    recommendation_type === "asset" && newBandContent?.condition?.id
                        ? newBandContent?.condition?.id
                        : assetFormData?.client_asset_condition_id
            }
        });
    };

    renderReportNoteTemplateModal = () => {
        const { showReportNoteTemplateModal, project } = this.state;
        if (!showReportNoteTemplateModal || !project.sub_system_id) return null;
        return (
            <Portal
                body={
                    <RepotNoteTemplateModal
                        heading={"Narrative Templates"}
                        sub_system_id={project.sub_system_id || null}
                        onOk={this.updateTextBandContent}
                        onCancel={() => {
                            this.setState({ showReportNoteTemplateModal: false });
                        }}
                    />
                }
                onCancel={() => this.setState({ showReportNoteTemplateModal: false })}
            />
        );
    };

    renderAssetModal = () => {
        const { showAssetModal, project } = this.state;
        if (!showAssetModal) return null;
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        return (
            <Portal
                body={
                    <AssignAssetModal
                        handleAssignAsset={this.handleAssignAsset}
                        selectedData={project}
                        clientId={project.client_id || query.c_id}
                        onCancel={() => this.setState({ showAssetModal: false })}
                    />
                }
                onCancel={() => this.setState({ showAssetModal: false })}
            />
        );
    };

    handleAssignAsset = async assetData => {
        if (assetData?.client_asset_condition) {
            assetData.asset_condition = assetData.client_asset_condition;
        }
        await this.setState({
            project: {
                ...this.state.project,
                asset: { id: assetData?.id }
            },
            assetFormData: {
                code: assetData.code,
                asset_name: assetData.asset_name,
                asset_tag: assetData.asset_tag,
                asset_note: assetData.asset_note,
                client_asset_condition_id: assetData.asset_condition?.id,
                installed_year: assetData.installed_year,
                service_life: assetData.service_life,
                usefull_life_remaining: assetData.usefull_life_remaining,
                crv: assetData.crv,
                manufacturer_id: assetData.manufacturer?.id,
                year_manufactured: assetData.year_manufactured,
                model_number: assetData.model_number,
                serial_number: assetData.serial_number,
                capacity: assetData.capacity,
                criticality: assetData.criticality,
                area_served: assetData.area_served,
                client_id: assetData.client?.id,
                region_id: assetData.region?.id,
                site_id: assetData.site?.id,
                building_id: assetData.building?.id,
                addition_id: assetData.addition?.id,
                floor_id: assetData.floor?.id,
                room_number: assetData.room_number,
                room_name: assetData.room_name,
                location: assetData.location,
                architectural_room_number: assetData.architectural_room_number,
                additional_room_description: assetData.additional_room_description,
                uniformat_level_1_id: assetData.uniformat_level_1?.id,
                uniformat_level_2_id: assetData.uniformat_level_2?.id,
                uniformat_level_3_id: assetData.uniformat_level_3?.id,
                uniformat_level_4_id: assetData.uniformat_level_4?.id,
                uniformat_level_5_id: assetData.uniformat_level_5?.id,
                asset_type_id: assetData.asset_type?.id,
                asset_description: assetData.asset_description,
                asset_barcode: assetData.asset_barcode,
                asset_client_id: assetData.asset_client_id,
                asset_cmms_id: assetData.asset_cmms_id,
                warranty_start: assetData.warranty_start,
                warranty_end: assetData.warranty_end,
                install_date: assetData.install_date,
                startup_date: assetData.startup_date,
                upstream_asset_barcode_number: assetData.upstream_asset_barcode_number,
                linked_asset_barcode_number: assetData.linked_asset_barcode_number,
                source_panel_barcode_number: assetData.source_panel_barcode_number,
                source_panel: assetData.source_panel,
                asset_status_id: assetData.asset_status?.id,
                notes: assetData.notes,
                building_type: assetData?.building_type?.name,
                main_category_id: assetData?.main_category?.id,
                sub_category_1_id: assetData?.sub_category_1?.id,
                sub_category_2_id: assetData?.sub_category_2?.id,
                sub_category_3_id: assetData?.sub_category_3?.id,
                guid: assetData?.guid,
                source_panel_name: assetData?.source_panel_name,
                skysite_hyperlink: assetData?.skysite_hyperlink,
                trade_id: assetData.trade?.id,
                system_id: assetData.system?.id,
                sub_system_id: assetData?.sub_system?.id,

                quantity: assetData?.quantity,
                rtls_tag: assetData?.rtls_tag,
                latitude: assetData?.latitude,
                longitude: assetData?.longitude,
                current_age: assetData?.current_age,
                age: assetData?.age,
                new_asset: assetData?.new_asset,
                parent_global_id: assetData?.parent_global_id,
                survey_global_id: assetData?.survey_global_id,
                survey_id: assetData?.survey_id,
                survey_property_note: assetData?.survey_property_note,
                capacity_status: assetData?.capacity_status,
                installed_year_status: assetData?.installed_year_status,
                name_plate_status: assetData?.name_plate_status,
                qa_notes: assetData?.qa_notes,
                additional_qa_notes: assetData?.additional_qa_notes,
                surveyor: assetData?.surveyor,
                editor: assetData?.editor,
                survey_date_created: assetData?.survey_date_created,
                survey_date_edited: assetData?.survey_date_edited,
                description: assetData?.asset_condition?.description,
                uniformat_level_6_id: assetData?.uniformat_level_6?.id,
                uniformat_level_6_description: assetData?.uniformat_level_6?.uniformat_level_6_description,
                subcategory2_description: assetData?.sub_category_2?.subcategory2_description,
                capacity_unit: assetData?.capacity_unit,
                refrigerant: assetData?.refrigerant,
                asset_additional: assetData?.asset_additional,
                auxiliary: assetData?.auxiliary[0]
            }
        });
        this.getSelectedAssetDropdowns();
        this.setState({ initialAssetFormData: _.cloneDeep(this.state.assetFormData) });
    };

    handleRecommendationTypeSelect = async () => {
        const {
            recommendation_type,
            trade_id,
            system_id,
            sub_system_id,
            client_id,
            region_id,
            site_id,
            building_id,
            building_type,
            addition_id,
            floor_id,
            room,
            asset_condition_id
        } = this.state.project;
        const {
            assetReducer: { dropDownList }
        } = this.props;
        if (recommendation_type === "asset") {
            let client_asset_condition_id = "";
            if (asset_condition_id) {
                let condition_name = this.props.recommendationsReducer?.getConditionBasedOnProject?.asset_conditions?.find(
                    item => item.id === asset_condition_id
                )?.name;
                client_asset_condition_id = dropDownList?.asset_conditions?.find(item => item.name === condition_name)?.id;
            }
            await this.setState({
                assetFormData: {
                    ...this.state.assetFormData,
                    client_id,
                    region_id,
                    site_id,
                    building_id,
                    building_type,
                    addition_id,
                    floor_id,
                    room_number: room,
                    trade_id,
                    system_id,
                    sub_system_id,
                    client_asset_condition_id
                }
            });
            this.setState({ initialAssetFormData: _.cloneDeep(this.state.assetFormData) });
        } else {
            const { client_asset_condition_id } = this.state.assetFormData;
            let asset_condition_id = "";
            if (client_asset_condition_id) {
                let condition_name = dropDownList?.asset_conditions?.find(item => item.id === client_asset_condition_id)?.name;
                asset_condition_id = this.props.recommendationsReducer?.getConditionBasedOnProject?.asset_conditions?.find(
                    item => item.name === condition_name
                )?.id;
            }
            this.setState({ project: { ...this.state.project, asset_condition_id } });
        }
        ReactTooltip.rebuild();
    };

    getRecommendationTemplates = async (sub_system_id, project_id) => {
        let params = {};
        let dynamicUrl = sub_system_id
            ? `/sub_systems/${sub_system_id}/recommendation_templates`
            : `/projects/${project_id}/recommendation_templates?limit=1000`;
        let recommendationTemplateList = [];

        await this.props.getRecommendationTemplates(params, dynamicUrl);
        recommendationTemplateList =
            this.props.recommendationsReducer.getRecommendationTemplatesResponse?.project_sub_system_recommendation_templates || [];

        await this.setState({
            recommendationTemplateList,
            recommendationSuggestions: recommendationTemplateList
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            recommendationSuggestions: this.getSuggestions(value)
        });
        ReactTooltip.rebuild();
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            recommendationSuggestions: []
        });
    };

    getSuggestions = value => {
        let recommendationTemplateList = this.state.recommendationTemplateList;
        const inputValue = value?.trim()?.toLowerCase() || "";
        return recommendationTemplateList
            .filter(
                item =>
                    item.text_format?.toString()?.toLowerCase().includes(inputValue) ||
                    item.description?.toString()?.toLowerCase().includes(inputValue)
            )
            .filter((e, i) => i <= 39);
    };

    getSuggestionValue = suggestion => suggestion.text_format;

    renderSuggestion = suggestion => (
        <div>
            <ReactTooltip id="suggestions" effect="solid" html={true} multiline={true} backgroundColor="#007bff" place="bottom" />
            <div data-for="suggestions" data-tip={suggestion.description || ""}>
                {suggestion.text_format}
            </div>
        </div>
    );

    handleChangeAssetForm = async e => {
        const { name, value } = e.target;
        const additional_asset = [
            "discharge",
            "unit_property_labeled",
            "factory_mounted_disconnect",
            "unit_voltage",
            "ahu_voltage",
            "wire_type",
            "wire_size",
            "estimated_wire_run_length",
            "breaker_size",
            "mocp",
            "mca",
            "liquid_line",
            "suction_line",
            "hot_gas_by_pass",
            "number_of_circuits",
            "length_of_line",
            "damper_type",
            "gas_heating",
            "gas_output_btu",
            "electric_heating",
            "electric_heating_kw",
            "pump_backup_heat_kw",
            "cooling_only",
            "controls",
            "control_type",
            "smoke_detector",
            "smoke_detector_mounting",
            "smoke_detector_supply",
            "smoke_detector_return",
            "curb",
            "curb_description",
            "vibration_isolation",
            "burglar_bars",
            "installed_on_adapter_curb",
            "curb_outside_dimension_length",
            "curb_outside_dimension_width",
            "allow_curb_adaptor",
            "ducted_or_concentric_system",
            "unit_weight",
            "codes_for_disconnects",
            "codes_for_convenience_outlets",
            "codes_for_roof_lighting",
            "duct_work_attached_to_bottom_of_unit",
            "aluminium_wire_used",
            "code_or_safety_concerns",
            "distance_from_salt_water_body",
            "recommend_coil_coating",
            "need_resealed",
            "split_system_line_sets_good_condition",
            "split_system_line_sets_damaged",
            "split_system_line_sets_crimped",
            "split_system_line_sets_insulated",
            "split_system_line_sets_re_used",
            "line_connection_type",
            "boiler_type",
            "boiler_burner_type",
            "boiler_btu_input",
            "boiler_btu_output",
            "boiler_hp",
            "boiler_fuel",
            "boiler_gas_pressure",
            "boiler_kw_input",
            "boiler_power_burner_type",
            "boiler_burner_model",
            "boiler_burner_serial",
            "boiler_pressure_type",
            "confirm_boiler_pressure",
            "return"
        ];

        const auxiliaryFields = [
            "id",
            "auxiliary_equipment_name",
            "auxiliary_model",
            "auxiliary_serial",
            "auxiliary_date_manufactured",
            "auxiliary_manufacturer",
            "auxiliary_condition",
            "auxiliary_eul",
            "auxiliary_crv"
        ];
        let assetValue = {};
        if (name === "manufacturer") {
            assetValue = {
                ...this.state.assetFormData,
                manufacturer_id: e.target?.value
            };
        } else {
            assetValue = {
                ...this.state.assetFormData,
                [name]: value
            };
        }

        if (auxiliaryFields.includes(name))
            assetValue = { ...this.state.assetFormData, auxiliary: { ...this.state.assetFormData.auxiliary, [name]: value } };
        if (additional_asset.includes(name))
            assetValue = { ...this.state.assetFormData, asset_additional: { ...this.state.assetFormData.asset_additional, [name]: value } };
        await this.setState({
            assetFormData: assetValue
        });
    };

    setAssetFormData = async assetFormData => {
        await this.setState({ assetFormData });
    };

    getSelectedAssetDropdowns = () => {
        this.props.getDropdownList("manufacturers", {
            current_manufacturer: this.state.assetFormData?.manufacturer_id && this.state.assetFormData?.manufacturer_id
        });
        const { assetFormData } = this.state;
        this.props.getDropdownList("uniformat_level_2s", {
            uniformat_level_1_id: assetFormData.uniformat_level_1_id
        });
        this.props.getDropdownList("uniformat_level_3s", {
            uniformat_level_1_id: assetFormData.uniformat_level_1_id,
            uniformat_level_2_id: assetFormData.uniformat_level_2_id
        });
        this.props.getDropdownList("uniformat_level_4s", {
            uniformat_level_1_id: assetFormData.uniformat_level_1_id,
            uniformat_level_2_id: assetFormData.uniformat_level_2_id,
            uniformat_level_3_id: assetFormData.uniformat_level_3_id
        });
        this.props.getDropdownList("uniformat_level_5s", {
            uniformat_level_1_id: assetFormData.uniformat_level_1_id,
            uniformat_level_2_id: assetFormData.uniformat_level_2_id,
            uniformat_level_3_id: assetFormData.uniformat_level_3_id,
            uniformat_level_4_id: assetFormData.uniformat_level_4_id
        });
        this.props.getDropdownList("uniformat_level_6s", {
            uniformat_level_1_id: assetFormData.uniformat_level_1_id,
            uniformat_level_2_id: assetFormData.uniformat_level_2_id,
            uniformat_level_3_id: assetFormData.uniformat_level_3_id,
            uniformat_level_4_id: assetFormData.uniformat_level_4_id,
            uniformat_level_5_id: assetFormData.uniformat_level_5_id
        });
        this.props.getDropdownList("main_categories", {
            client_id: assetFormData.client_id
        });
        this.props.getDropdownList("sub_category_1s", {
            client_id: assetFormData.client_id,
            main_category_id: assetFormData.main_category_id
        });
        this.props.getDropdownList("sub_category_2s", {
            client_id: assetFormData.client_id,
            main_category_id: assetFormData.main_category_id,
            sub_category_1_id: assetFormData.sub_category_1_id
        });
        this.props.getDropdownList("sub_category_3s", {
            client_id: assetFormData.client_id,
            main_category_id: assetFormData.main_category_id,
            sub_category_1_id: assetFormData.sub_category_1_id,
            sub_category_2_id: assetFormData.sub_category_2_id
        });
    };

    showErrorBand = item => {
        // const { project, assetFormData, costTotal } = this.state;
        // switch (item) {
        //     case "b1":
        //         return !(project.trade_id && project.system_id && project.sub_system_id && project.description?.trim()?.length);
        //     case "b2":
        //         return !(project.region_id && project.site_id && project.building_id);
        //     case "b3":
        //         return costTotal === 0;
        //     case "b4":
        //         return false;
        //     case "b5":
        //         return project.recommendation_type === "asset" && !(assetFormData.asset_name && assetFormData.client_asset_condition_id);
        //     case "b6":
        //         return (
        //             (project.recommendation_type === "building" && !project.asset_condition_id) ||
        //             !(project.capital_type && project.category_id && project.status)
        //         );
        //     default:
        //         return false;
        // }
        return false;
    };
    // showBand = item => {
    //     const { showBand } = this.state;

    //     return false;
    // };

    handleClick = () => {
        this.setState(prevState => ({
            toggleInput: !prevState.toggleInput
        }));
    };

    renderCode = () => {
        const { project } = this.state;
        let val =
            (this.props.recommendationsReducer.getTradeByProject &&
                this.props.recommendationsReducer.getTradeByProject.trades &&
                this.props.recommendationsReducer.getTradeByProject.trades.find(item => item.id === project.trade_id)?.name) ||
            "";
        let bui =
            (this.props.userReducer.getAllBuildingsDropdownResponse &&
                this.props.userReducer.getAllBuildingsDropdownResponse.buildings &&
                this.props.userReducer.getAllBuildingsDropdownResponse.buildings.find(item => item.id === project.building_id)?.name) ||
            "";
        return `${bui}-${val.substring(0, 1)}-`;
    };

    renderPriorityElementToolTip = (name, options = [], notes, index = "") => {
        let tootTipData = "";
        if (options.length) {
            tootTipData = `<h4>${name ? name : `Priority Element ${index + 1}`}</h4>`;
            if (notes) {
                tootTipData = tootTipData + `<p>${notes}</p>`;
            }
            options.map((option, i) => {
                tootTipData = tootTipData + `<p>${option.name}</p>`;
            });
        }
        return tootTipData || null;
    };

    openReportNote = () => {
        this.setState({
            showNoteModal: true
        });
    };

    handleChangeNote = data => {
        let note_xml = convertToXML([data], 1);
        let removedAllTags = removeAllTags(data);
        this.setState({
            project: {
                ...this.state.project,
                notes: removedAllTags,
                note_xml: note_xml,
                note_html: data
            }
        });
    };

    componentDidUpdate = (prevProps, prevState) => {
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);

        if (prevState.showBand !== this.state.showBand || prevState.project !== this.state.project) {
            setTimeout(() => {
                let height = this.templateRef?.current?.clientHeight - 340;
                this.setState({ editorHeight: height });
            }, 500);
        }
        if (prevState.project.sub_system_id !== this.state.project.sub_system_id && !this.state.project.sub_system_id)
            this.getRecommendationTemplates(undefined, query.p_id);
    };

    handleBandClick = (key, value) => {
        this.setState({
            showBand: {
                ...this.state.showBand,
                [key]: value
            }
        });
    };
    handleChangeBandData = bandData => {
        this.setState({ project: { ...this.state.project, ...bandData } });
    };

    handleCheckBoxClick = (key, checked, field = "") => {
        const { project } = this.state;
        const valueFound = this.checkAnyActiveData(field);
        if (!checked && valueFound) {
            this.setState(
                {
                    alertMessage: "You can't uncheck when there is active data in the band"
                },
                () => this.showAlert()
            );
        } else {
            this.setState(
                {
                    project: { ...project, [key]: checked ? "yes" : "no" }
                },
                () => checked && ReactTooltip.rebuild()
            );
        }
    };

    checkAnyActiveData = field => {
        const { project } = this.state;
        let fields = [];
        let data = project[field];
        let valueFound = false;
        switch (field) {
            case "energy_band":
                fields = energy_fields;
                break;
            case "water_band":
                fields = water_fields;
                break;

            default:
                break;
        }
        valueFound = fields.some(f => data[f.key]);
        return valueFound;
    };
    showAlert = () => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show";
            x.innerText = this.state.alertMessage;
            setTimeout(function () {
                x.className = x.className.replace("show", "");
            }, 3000);
        }
    };

    validateDynamicFields = () => {
        const {
            project: { dynamic_fields }
        } = this.state;
        const { recommendation_custom_fields: bands = [] } = this.props.recommendationsReducer.dynamicFields;
        let isValidate = true;
        let errorMessage = "";

        for (const [band, fields] of Object.entries(bands)) {
            for (const field of fields) {
                const fieldValue = dynamic_fields?.[field?.field_name];
                const isRequired = field?.rules?.mandatory && !fieldValue?.toString()?.trim()?.length;
                const regex = new RegExp(field?.rules?.regex);
                const invalid =
                    (field?.field_type === DYNAMIC_FIELD_TYPES.TEXT || field?.field_type === DYNAMIC_FIELD_TYPES.MEMO) &&
                    fieldValue?.toString()?.trim()?.length &&
                    regex &&
                    !regex?.test(fieldValue);
                if (isRequired) {
                    isValidate = false;
                    errorMessage = `* ${field.label} is required`;
                    return { isValidate, errorMessage };
                } else if (invalid) {
                    isValidate = false;
                    errorMessage = `* Please enter a valid ${field.label}`;
                    return { isValidate, errorMessage };
                }
            }
        }

        return { isValidate, errorMessage };
    };

    isFieldLocked = field_name => {
        const fieldConfig = this.props.projectReducer?.getRecommendationFieldConfigsResponse?.recommendation_field_configs || [];
        return fieldConfig.find(field => field.field_name === field_name)?.is_locked || false;
    };

    isYearRemovable = year => {
        const { start, end } = this.state.siteCspYears;
        const { maintenance_years } = this.state.project;
        if (!maintenance_years?.length) {
            return false;
        }
        let isYearOutsideCsp = true;
        if (start && end) {
            if (year >= start && year <= end) {
                isYearOutsideCsp = false;
            } else {
                isYearOutsideCsp = true;
            }
        }
        const firstYear = maintenance_years[0].year;
        const lastYear = maintenance_years[maintenance_years.length - 1].year;
        const isFirstYear = year === firstYear;
        const isLastYear = year === lastYear;
        return isYearOutsideCsp && (isFirstYear || isLastYear);
    };

    renderYearRemoveConfirmationModal = () => {
        const { showYearRemoveConfirmation, selectedRemoveYear } = this.state;
        if (!showYearRemoveConfirmation) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Are you sure you want to remove the last year, including the associated amount?`}
                        type="cancel"
                        message={""}
                        onNo={() => this.setState({ showYearRemoveConfirmation: false })}
                        onYes={() => {
                            this.setState({ showYearRemoveConfirmation: false });
                            this.onRemoveMaintenanceYearConfirm(selectedRemoveYear);
                        }}
                    />
                }
                onCancel={() => this.setState({ showYearRemoveConfirmation: false })}
            />
        );
    };

    fetchOptions = async inputValue => {
        this.setState({ isLoadingDrop: true, inputValue });
        const response = await this.props.getDropdownList("manufacturers", {
            search: inputValue,
            current_manufacturer: this.state.assetFormData?.manufacturer_id && this.state.assetFormData?.manufacturer_id
        });
        this.setState({ isLoadingDrop: false });
    };

    handleInputChange = (newValue, { action }) => {
        if (action === "input-change" || action === "input-click" || action === "input-blur") {
            this.fetchOptions(newValue);
            return newValue;
        }
    };

    render() {
        const {
            project,
            regionList,
            showBand,
            siteList,
            recommendationSuggestions,
            assetFormData,
            clients,
            isLoading,
            loading,
            showErrorBorder,
            priorityElementsData,
            criticalityData,
            costTotal,
            selectedProject,
            birth_year,
            selectedImage
        } = this.state;
        const {
            assetReducer: { dropDownList }
        } = this.props;
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        const isFMPUser = localStorage.getItem("fmp_user") === "yes" ? true : false;
        const isIRUser = localStorage.getItem("infrastructure_request_user") === "yes" ? true : false;
        const { getProjectByIdResponse } = this.props.projectReducer;
        const { miscellaneous } = getProjectByIdResponse || {};
        const userRole = localStorage.getItem("role");
        const show_opinions_cost_diff_indicator =
            (userRole === "super_admin" || userRole === "consultancy_user") &&
            miscellaneous?.display_unit &&
            Math.round(costTotal) !== Math.round(project.options_cost);
        const birthYear = selectedProject ? birth_year : project.maintenance_years?.find(year => parseInt(year.amount) > 0)?.year || "";
        const { is_edited, s3_eimage_key, s3_image_key } = selectedImage?.master_image || {};
        const imageUrl = is_edited ? `${s3_eimage_key}` : `${s3_image_key}`;
        const isCR = query.is_cr === "true";
        let isCRApprover = userRole === "super_admin" || localStorage.getItem("crApprover") === "true";
        return (
            <LoadingOverlay active={loading || isLoading} spinner={<Loader />} fadeSpeed={10}>
                {this.state.showNoteModal && (
                    <Portal
                        body={
                            <RecommendationNoteEdit
                                notes={this.state.project.note_html}
                                onCancel={() => this.setState({ showNoteModal: false })}
                                isRecommendationView={true}
                                handleChangeNote={this.handleChangeNote}
                                subSystemId={this.state.project.sub_system_id}
                                toggleShowReportNoteTemplateModal={this.toggleShowReportNoteTemplateModal}
                            />
                        }
                        onCancel={() => this.setState({ showNoteModal: false })}
                    />
                )}

                <div className="dtl-sec col-md-12">
                    <ReactTooltip
                        id="recommendation_details"
                        effect="solid"
                        place="top"
                        backgroundColor="#007bff"
                        className="rc-tooltip-custom-class"
                        html={true}
                    />
                    <div className="tab-dtl region-mng additional-dtl addition-edit">
                        <div className="topper-rec-nav">
                            <ul>
                                <div className="recom-notify-img">
                                    <img
                                        src={recomIcon}
                                        alt=""
                                        data-tip={`${isCR ? "Capital Request" : "Recommendation"} in Edit or New mode.\nChanges not saved yet!`}
                                        data-for="recommendation_details"
                                        data-place="top"
                                    />
                                </div>
                                <li
                                    className={this.state.activeDetail === "Main Details" ? "active cursor-hand pl-4" : "cursor-hand pl-4"}
                                    onClick={() => this.setActiveTab("Main Details")}
                                >
                                    {this.state.selectedProject
                                        ? `Edit ${isCR ? "Capital Request" : "Recommendation"}`
                                        : query.type === RECOMMENDATION_SOURCE_TYPES.REGULAR
                                        ? `Add New ${isCR ? "Capital Request" : "Recommendation"}`
                                        : query.type === RECOMMENDATION_SOURCE_TYPES.SIMILAR
                                        ? `Add Similar ${isCR ? "Capital Request" : "Recommendation"}`
                                        : query.type === RECOMMENDATION_SOURCE_TYPES.DUPPLICATE
                                        ? `Add Duplicate ${isCR ? "Capital Request" : "Recommendation"}`
                                        : query.type === RECOMMENDATION_SOURCE_TYPES.GEO_HIERARCHY
                                        ? `Add Same Geo Hierarchy ${isCR ? "Capital Request" : "Recommendation"}`
                                        : `Add New ${isCR ? "Capital Request" : "Recommendation"}`}
                                </li>
                                {project.recommendation_type === "asset" && (
                                    <li
                                        className={this.state.activeDetail === "Asset Details" ? "active cursor-hand" : "cursor-hand"}
                                        onClick={() => this.setActiveTab("Asset Details")}
                                    >
                                        Asset
                                    </li>
                                )}
                            </ul>
                            <div className="mr-2 text-right btnOtr mt-2 mb-2">
                                <span className="errorMessage">{this.state.showErrorBorder ? this.state.errorMessage : ""}</span>
                                <button type="button" className="btn btn-secondary btnClr" onClick={() => this.confirmCancel()}>
                                    Cancel
                                </button>
                                {!this.state.loading ? (
                                    <button
                                        type="button"
                                        title={this.state.locked === true ? "You cannot update this item as this recommendation is locked!!!" : ""}
                                        className={`btn btn-primary btnRgion ml-2
                                                 ${this.state.locked === true ? "cursor-notallowed" : ""}`}
                                        disabled={this.state.locked === true ? true : false}
                                        onClick={() => this.addProject()}
                                    >
                                        {this.state.selectedProject ? "Update" : "Save"}
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-primary btnRgion ml-2">
                                        <div className="button-loader d-flex justify-content-center align-items-center">
                                            <div className="spinner-border text-white" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </button>
                                )}
                            </div>
                        </div>
                        {this.state.activeDetail === "Main Details" ? (
                            <div
                                className={`tab-active location-sec recom-sec main-dtl-add recommendation-form add-recommendation ${
                                    project?.recommendation_type === "asset"
                                        ? ""
                                        : query.type === "Regular"
                                        ? "building-type edit-ck-recom"
                                        : "building-type"
                                }`}
                            >
                                <div class="wrapper-id-check">
                                    {this.state.selectedProject && (
                                        <label htmlFor="" className="label-box-top txt-editable">
                                            {this.state.toggleInput ? (
                                                <>
                                                    {checkPermission("forms", "recommendation_code", "edit") && this.state.selectedProject && (
                                                        <div className="label-detl">
                                                            <NumberFormat
                                                                autoComplete={"nope"}
                                                                className="custom-input form-control"
                                                                placeholder="Quantity"
                                                                value={this.state.project.code}
                                                                prefix={this.renderCode()}
                                                                allowEmptyFormatting={true}
                                                                decimalSeparator={false}
                                                                displayType={"input"}
                                                                allowLeadingZeros
                                                                onValueChange={values => {
                                                                    const { formattedValue } = values;
                                                                    this.setState({
                                                                        project: {
                                                                            ...project,
                                                                            code: formattedValue
                                                                        }
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="p-2">
                                                    <span className="label-txt"> ID: </span> <span className="label-detl">{` ${project?.code}`}</span>{" "}
                                                </div>
                                            )}
                                            {checkPermission("forms", "recommendation_code", "edit") && this.state.selectedProject && (
                                                <button
                                                    data-tip={"Edit Code"}
                                                    data-for="recommendation_details"
                                                    data-place="top"
                                                    onClick={this.handleClick}
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </button>
                                            )}
                                        </label>
                                    )}
                                    <div class="wrapper-check-box">
                                        <div class="wrapper-water-check">
                                            <label class="container-check">
                                                <input
                                                    checked={project.red_line === "yes"}
                                                    type="checkbox"
                                                    name="red_line"
                                                    onChange={e => this.handleCheckBoxClick(e.target.name, e.target.checked)}
                                                />
                                                <span class="checkmark"></span>Redlining
                                            </label>
                                        </div>
                                        {isCR && (
                                            <div class="wrapper-water-check">
                                                <label class={`container-check ${query.is_cr === "true" || isIRUser ? "cursor-diabled" : ""}`}>
                                                    <input
                                                        checked={project.infrastructure_request === "yes"}
                                                        type="checkbox"
                                                        disabled={query.is_cr === "true" || isIRUser}
                                                        name="infrastructure_request"
                                                        onChange={e => this.handleCheckBoxClick(e.target.name, e.target.checked)}
                                                    />
                                                    <span class="checkmark"></span>Capital Request
                                                </label>
                                            </div>
                                        )}
                                        <div class="wrapper-water-check">
                                            <label class="container-check">
                                                <input
                                                    checked={project.budget_priority === "yes"}
                                                    type="checkbox"
                                                    name="budget_priority"
                                                    onChange={e => this.handleCheckBoxClick(e.target.name, e.target.checked)}
                                                />
                                                <span class="checkmark"></span>Budget Priority
                                            </label>
                                        </div>
                                        <div class="wrapper-water-check">
                                            <label class={`container-check ${isFMPUser ? "cursor-diabled" : ""}`}>
                                                <input
                                                    checked={project.fmp === "yes"}
                                                    type="checkbox"
                                                    disabled={isFMPUser}
                                                    name="fmp"
                                                    onChange={e => this.handleCheckBoxClick(e.target.name, e.target.checked)}
                                                />
                                                <span class="checkmark"></span>Facility Master Plan
                                            </label>
                                        </div>
                                        {miscellaneous?.show_energy_band && (
                                            <div class="wrapper-water-check">
                                                <label class="container-check">
                                                    <input
                                                        checked={project.energy_band_show === "yes"}
                                                        type="checkbox"
                                                        name="energy_band_show"
                                                        onChange={e => this.handleCheckBoxClick(e.target.name, e.target.checked, "energy_band")}
                                                    />
                                                    <span class="checkmark"></span>Energy
                                                </label>
                                            </div>
                                        )}
                                        {miscellaneous?.show_water_band && (
                                            <div class="wrapper-energy-check">
                                                <label class="container-check">
                                                    <input
                                                        checked={project.water_band_show === "yes"}
                                                        type="checkbox"
                                                        name="water_band_show"
                                                        onChange={e => this.handleCheckBoxClick(e.target.name, e.target.checked, "water_band")}
                                                    />
                                                    <span class="checkmark"></span>Water
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className={`col-md-12 detail-recom ${this.state.selectedProject ? "add-details-outer height-over-newrc" : ""}`}>
                                    <div className="outer-rcm recommendations">
                                        <div className="cnt-sec">
                                            <div className="row">
                                                <div className={`col-md-8 ${this.state.selectedProject ? "" : "height-new-cp"}`}>
                                                    <div id="accordion" ref={this.templateRef}>
                                                        <RecommendationBand
                                                            label="Geo Hierarchy"
                                                            bandId="band2"
                                                            handleBandClick={this.handleBandClick}
                                                            isDefaultOpen
                                                            showBand={showBand}
                                                            MainFields={
                                                                <>
                                                                    <FormField
                                                                        label="Region"
                                                                        fieldKey="region"
                                                                        fieldParam="region_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            field_options: regionList,
                                                                            rules: { mandatory: !isCR }
                                                                        }}
                                                                        bandId="band2"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={async value => {
                                                                            await this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    region_id: value
                                                                                },
                                                                                assetFormData: {
                                                                                    ...assetFormData,
                                                                                    region_id: value,
                                                                                    site_id: "",
                                                                                    building_id: "",
                                                                                    building_type: "",
                                                                                    addition_id: "",
                                                                                    floor_id: ""
                                                                                }
                                                                            });
                                                                            this.handleRegionSelect();
                                                                        }}
                                                                    />
                                                                    <FormField
                                                                        label="Site"
                                                                        fieldKey="site"
                                                                        fieldParam="site_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            field_options: project.region_id && siteList,
                                                                            rules: { mandatory: !isCR }
                                                                        }}
                                                                        bandId="band2"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={value => this.handleSiteSelect(value)}
                                                                    />
                                                                    <FormField
                                                                        label="Building"
                                                                        fieldKey="building"
                                                                        fieldParam="building_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            field_options:
                                                                                project.site_id &&
                                                                                this.props.userReducer?.getAllBuildingsDropdownResponse?.buildings,
                                                                            rules: { mandatory: !isCR }
                                                                        }}
                                                                        bandId="band1"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={async value => {
                                                                            let buildingType = value
                                                                                ? this.props.userReducer.getAllBuildingsDropdownResponse.buildings &&
                                                                                  this.props.userReducer.getAllBuildingsDropdownResponse.buildings
                                                                                      .length
                                                                                    ? this.props.userReducer.getAllBuildingsDropdownResponse.buildings.find(
                                                                                          b => b.id == value
                                                                                      )
                                                                                        ? this.props.userReducer.getAllBuildingsDropdownResponse.buildings.find(
                                                                                              b => b.id == value
                                                                                          )
                                                                                        : null
                                                                                    : null
                                                                                : "";
                                                                            this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    building_id: value,
                                                                                    building_type: buildingType ? buildingType.description : "",
                                                                                    addition_id: "",
                                                                                    floor_id: ""
                                                                                },
                                                                                assetFormData: {
                                                                                    ...assetFormData,
                                                                                    building_id: value,
                                                                                    building_type: buildingType ? buildingType.description : "",
                                                                                    addition_id: "",
                                                                                    floor_id: ""
                                                                                },
                                                                                activeBuilding: value
                                                                            });
                                                                            this.handleAdditionSelect(value);
                                                                            this.handleFloorSelect(value);
                                                                        }}
                                                                    />
                                                                </>
                                                            }
                                                            RestFields={
                                                                <>
                                                                    <FormField
                                                                        label="Building Type"
                                                                        fieldKey="building_type"
                                                                        fieldParam="building_type"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            rules: { mandatory: !isCR, read_only: true }
                                                                        }}
                                                                        bandId="band2"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={async e => {
                                                                            await this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    recommendation_type: e.target.value
                                                                                }
                                                                            });
                                                                            this.handleRecommendationTypeSelect();
                                                                        }}
                                                                    />
                                                                    <FormField
                                                                        label="Addition"
                                                                        fieldKey="addition"
                                                                        fieldParam="addition_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            field_options:
                                                                                project.building_id &&
                                                                                this.props.recommendationsReducer.getAdditionByBuilding?.additions,
                                                                            rules: { mandatory: miscellaneous?.addition_required && !isCR }
                                                                        }}
                                                                        bandId="band2"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={async value => {
                                                                            await this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    addition_id: value
                                                                                },
                                                                                assetFormData: {
                                                                                    ...assetFormData,
                                                                                    addition_id: value
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                    <FormField
                                                                        label="Floor"
                                                                        fieldKey="floor"
                                                                        fieldParam="floor_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            field_options:
                                                                                project.building_id &&
                                                                                this.props.recommendationsReducer?.getFloorByBuilding?.floors,
                                                                            rules: { mandatory: miscellaneous?.floor_required && !isCR }
                                                                        }}
                                                                        bandId="band2"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={async value => {
                                                                            await this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    floor_id: value
                                                                                },
                                                                                assetFormData: {
                                                                                    ...assetFormData,
                                                                                    floor_id: value
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                    <FormField
                                                                        label="Room"
                                                                        fieldKey="room"
                                                                        fieldParam="room"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            rules: { mandatory: miscellaneous?.room_required && !isCR }
                                                                        }}
                                                                        bandId="band2"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={e =>
                                                                            this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    room: e.target.value
                                                                                },
                                                                                assetFormData: {
                                                                                    ...assetFormData,
                                                                                    room_number: e.target.value
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </>
                                                            }
                                                        />
                                                        <RecommendationBand
                                                            label={isCR ? "Capital Request" : "Recommendation"}
                                                            bandId="band1"
                                                            handleBandClick={this.handleBandClick}
                                                            isDefaultOpen
                                                            showBand={showBand}
                                                            MainFields={
                                                                <>
                                                                    <FormField
                                                                        label="Trade"
                                                                        fieldKey="trade"
                                                                        fieldParam="trade_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            field_options:
                                                                                this.props.recommendationsReducer?.getTradeByProject?.trades,
                                                                            rules: { mandatory: !isCR }
                                                                        }}
                                                                        bandId="band1"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={value => {
                                                                            this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    trade_id: value
                                                                                },
                                                                                assetFormData: {
                                                                                    ...assetFormData,
                                                                                    trade_id: value,
                                                                                    system_id: "",
                                                                                    sub_system_id: ""
                                                                                }
                                                                            });
                                                                            this.handleTrade(value);
                                                                        }}
                                                                    />
                                                                    <FormField
                                                                        label="System"
                                                                        fieldKey="system"
                                                                        fieldParam="system_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        isLoading={this.state.systemLoading}
                                                                        fieldItem={{
                                                                            field_options: this.state?.systemArray,
                                                                            rules: { mandatory: !isCR }
                                                                        }}
                                                                        bandId="band1"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={async value => {
                                                                            const tradeId = this.state.systemArray.find(
                                                                                r => r.id === value
                                                                            )?.trade_id;
                                                                            this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    system_id: value,
                                                                                    trade_id: tradeId ? tradeId : project.trade_id
                                                                                },
                                                                                assetFormData: {
                                                                                    ...assetFormData,
                                                                                    system_id: value,
                                                                                    trade_id: tradeId ? tradeId : assetFormData.trade_id,
                                                                                    sub_system_id: ""
                                                                                }
                                                                            });
                                                                            await this.handleSystem(value);
                                                                        }}
                                                                    />
                                                                    <FormField
                                                                        label="Sub-System"
                                                                        fieldKey="sub_system"
                                                                        fieldParam="sub_system_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        isLoading={this.state.subSystemLoading}
                                                                        fieldItem={{
                                                                            field_options: this.state.subSystemArray,
                                                                            rules: { mandatory: !isCR }
                                                                        }}
                                                                        bandId="band1"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={async value => {
                                                                            const data = this.state?.subSystemArray.find(
                                                                                item => item.id === value
                                                                            )?.service_life;
                                                                            const tradeId = this.state.subSystemArray.find(
                                                                                r => r.id === value
                                                                            )?.trade_id;
                                                                            const systemId = this.state.subSystemArray.find(
                                                                                r => r.id === value
                                                                            )?.system_id;
                                                                            const query = qs.parse(search);

                                                                            this.setState(
                                                                                {
                                                                                    project: {
                                                                                        ...project,
                                                                                        sub_system_id: value,
                                                                                        trade_id: tradeId ? tradeId : project.trade_id,
                                                                                        system_id: systemId ? systemId : project.system_id,
                                                                                        service_life: this.state.project.service_life
                                                                                            ? this.state.project?.service_life
                                                                                            : data || 0
                                                                                    },
                                                                                    assetFormData: {
                                                                                        ...assetFormData,
                                                                                        sub_system_id: value,
                                                                                        trade_id: tradeId ? tradeId : assetFormData.trade_id,
                                                                                        system_id: systemId ? systemId : assetFormData.system_id,
                                                                                        service_life: this.state.assetFormData.service_life
                                                                                            ? this.state.assetFormData.service_life
                                                                                            : data || 0
                                                                                    }
                                                                                },
                                                                                () =>
                                                                                    value
                                                                                        ? this.getRecommendationTemplates(
                                                                                              this.state.project.sub_system_id
                                                                                          )
                                                                                        : this.getRecommendationTemplates(undefined, query.p_id)
                                                                            );
                                                                            await this.props.getSubSystemBasedOnProject(
                                                                                query.p_id,
                                                                                systemId,
                                                                                this.state.project.trade_id
                                                                            );
                                                                            const datas =
                                                                                this.props.recommendationsReducer?.getSubSystemByProject
                                                                                    ?.sub_systems || [];
                                                                            this.setState({
                                                                                subSystemArray: datas
                                                                            });
                                                                        }}
                                                                    />
                                                                </>
                                                            }
                                                            RestFields={
                                                                <>
                                                                    <FormField
                                                                        label="Type"
                                                                        fieldKey="type"
                                                                        fieldParam="recommendation_type"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        hasEmptyOption={false}
                                                                        fieldItem={{
                                                                            field_options: [
                                                                                { id: "asset", name: "Asset" },
                                                                                { id: "building", name: "Building" }
                                                                            ],
                                                                            rules: { mandatory: !isCR, read_only: query.is_cr === "true" }
                                                                        }}
                                                                        bandId="band1"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={async value => {
                                                                            await this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    recommendation_type: value
                                                                                }
                                                                            });
                                                                            this.handleRecommendationTypeSelect();
                                                                        }}
                                                                    />
                                                                    <div className="txt-rcm pt-0 txt-rcm-full-w" ref={this.suggestionInputRef}>
                                                                        <div className="heading">
                                                                            <h3>{isCR ? "Capital Request" : "Recommendation"} *</h3>
                                                                            <div>
                                                                                {this.state.project.sub_system_id && (
                                                                                    <button
                                                                                        className="btn btn-clear"
                                                                                        onClick={() => this.toggleShowRecommendationTemplateModal()}
                                                                                    >
                                                                                        <i className="fas fa-plus" /> Add Template
                                                                                    </button>
                                                                                )}
                                                                                <span className="ml-2">
                                                                                    {project.description?.length}/{this.state.captionLength}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="content-inp-card"
                                                                            data-tip={
                                                                                this.isFieldLocked("description")
                                                                                    ? "This field is locked and cannot be edited"
                                                                                    : ""
                                                                            }
                                                                            data-for="recommendation_details"
                                                                        >
                                                                            <div className="form-group">
                                                                                <Autosuggest
                                                                                    suggestions={recommendationSuggestions.filter((e, i) => i <= 39)}
                                                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                                                    getSuggestionValue={this.getSuggestionValue}
                                                                                    renderSuggestion={this.renderSuggestion}
                                                                                    shouldRenderSuggestions={() => true}
                                                                                    onSuggestionSelected={(e, { suggestion }) =>
                                                                                        this.onSuggestionSelected(suggestion)
                                                                                    }
                                                                                    inputProps={{
                                                                                        type: "text",
                                                                                        maxLength: this.state.captionLength,
                                                                                        placeholder: `Enter ${
                                                                                            isCR ? "Capital Request" : "Recommendation"
                                                                                        }`,
                                                                                        value: this.state.project.description,

                                                                                        onChange: (e, { newValue }) => {
                                                                                            this.setState({
                                                                                                project: {
                                                                                                    ...project,
                                                                                                    description: newValue
                                                                                                }
                                                                                            });
                                                                                        },
                                                                                        onFocus: () => {
                                                                                            if (
                                                                                                this.suggestionInputRef?.current &&
                                                                                                this.state.recommendationTemplateList?.length > 4
                                                                                            ) {
                                                                                                this.suggestionInputRef.current.scrollIntoView({
                                                                                                    behavior: "smooth",
                                                                                                    block: "start"
                                                                                                });
                                                                                            }
                                                                                        },

                                                                                        className: `${
                                                                                            this.state.showErrorBorder &&
                                                                                            !this.state.project.description?.trim().length
                                                                                                ? "error-border "
                                                                                                : ""
                                                                                        } custom-input form-control pr-5 ${
                                                                                            this.isFieldLocked("description") ? "grayed-out" : ""
                                                                                        }`,
                                                                                        disabled: this.isFieldLocked("description")
                                                                                    }}
                                                                                />
                                                                                <i
                                                                                    className="fas fa-times cursor-pointer recom-close-icon"
                                                                                    onClick={() =>
                                                                                        this.setState({
                                                                                            project: { ...project, description: "" }
                                                                                        })
                                                                                    }
                                                                                ></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        />

                                                        <RecommendationBand
                                                            label="Capital Spending Plan"
                                                            bandId="band3"
                                                            handleBandClick={this.handleBandClick}
                                                            showBand={showBand}
                                                            MainFields={
                                                                <>
                                                                    <div className="txt-rcm">
                                                                        <div
                                                                            class={`content-inp-card blue-sec ${
                                                                                show_opinions_cost_diff_indicator ? "triangle-highlight" : ""
                                                                            }`}
                                                                            data-tip={
                                                                                show_opinions_cost_diff_indicator
                                                                                    ? "The project total doesn't match the opinions of cost."
                                                                                    : ""
                                                                            }
                                                                            data-for="recommendation_details"
                                                                            data-place="top"
                                                                            data-target="#collapseTre"
                                                                            aria-expanded="false"
                                                                            aria-controls="collapseOne"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    showBand: {
                                                                                        ...showBand,
                                                                                        band3: true
                                                                                    }
                                                                                })
                                                                            }
                                                                        >
                                                                            <h3 className="p-name">Project Total</h3>
                                                                            <h3>
                                                                                <NumberFormat
                                                                                    autoComplete={"nope"}
                                                                                    className="color-white"
                                                                                    value={parseInt(this.state.costTotal || 0)}
                                                                                    thousandSeparator={true}
                                                                                    displayType={"text"}
                                                                                    prefix={"$ "}
                                                                                />
                                                                            </h3>
                                                                        </div>
                                                                    </div>

                                                                    {miscellaneous?.display_unit ? (
                                                                        <>
                                                                            <FormField
                                                                                label="Unit"
                                                                                fieldKey="unit"
                                                                                fieldParam="unit"
                                                                                fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                                value={project}
                                                                                fieldItem={{
                                                                                    rules: { mandatory: !isCR },
                                                                                    className: "txt-categ year-item main_year"
                                                                                }}
                                                                                bandId="band3"
                                                                                bandShown={showBand}
                                                                                handleBandClick={this.handleBandClick}
                                                                                showErrorBorder={showErrorBorder}
                                                                                isFieldLocked={this.isFieldLocked}
                                                                                onChange={e => {
                                                                                    this.setState({
                                                                                        project: {
                                                                                            ...project,
                                                                                            unit: e.target.value
                                                                                        }
                                                                                    });
                                                                                }}
                                                                            />
                                                                            <FormField
                                                                                label="Cost per Unit"
                                                                                fieldKey="cost_per_unit"
                                                                                fieldParam="cost_per_unit"
                                                                                fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                                value={project}
                                                                                fieldItem={{
                                                                                    rules: {
                                                                                        mandatory: !isCR,
                                                                                        prefix: "$ ",
                                                                                        thousand_separator: true,
                                                                                        allow_negative: false,
                                                                                        decimal_scale: 4
                                                                                    },
                                                                                    className: "txt-categ year-item main_year"
                                                                                }}
                                                                                bandId="band3"
                                                                                bandShown={showBand}
                                                                                handleBandClick={this.handleBandClick}
                                                                                showErrorBorder={showErrorBorder}
                                                                                isFieldLocked={this.isFieldLocked}
                                                                                onChange={values => {
                                                                                    const { value } = values;
                                                                                    this.setState({
                                                                                        project: {
                                                                                            ...project,
                                                                                            cost_per_unit: value,
                                                                                            options_cost: project.quantity
                                                                                                ? value * project.quantity
                                                                                                : project.options_cost
                                                                                        }
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {this.state.project.maintenance_years &&
                                                                                this.state.project.maintenance_years.map((item, i) => {
                                                                                    return (
                                                                                        <>
                                                                                            {(i === 0 || i === 1) && (
                                                                                                <FormField
                                                                                                    label={item.year}
                                                                                                    fieldKey={"maintenance_years"}
                                                                                                    fieldParam=""
                                                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                                                    value={(item && parseInt(item.amount)) || ""}
                                                                                                    isMaintenanceYear
                                                                                                    fieldItem={{
                                                                                                        rules: {
                                                                                                            mandatory: false,
                                                                                                            prefix: "$ ",
                                                                                                            thousand_separator: true,
                                                                                                            allow_negative: false
                                                                                                        },
                                                                                                        className: "txt-categ year-item main_year"
                                                                                                    }}
                                                                                                    placeholder="$ 0"
                                                                                                    bandId="band3"
                                                                                                    bandShown={showBand}
                                                                                                    handleBandClick={this.handleBandClick}
                                                                                                    showErrorBorder={showErrorBorder}
                                                                                                    isFieldLocked={this.isFieldLocked}
                                                                                                    onChange={values =>
                                                                                                        this.setMaintenanceYear(values, item.year)
                                                                                                    }
                                                                                                    hasRemove={this.isYearRemovable(item.year)}
                                                                                                    onRemove={() => this.removeMaintenanceYear(item)}
                                                                                                />
                                                                                            )}
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            {this.state.project.maintenance_years?.length < 2 && (
                                                                                <div className={`txt-rcm `}>
                                                                                    <div className="content-inp-card icn">
                                                                                        <AddRemoveButton onAdd={this.addMaintenanceYear} />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            }
                                                            RestFields={
                                                                <>
                                                                    {miscellaneous?.display_unit ? (
                                                                        <>
                                                                            <FormField
                                                                                label="Quantity"
                                                                                fieldKey="quantity"
                                                                                fieldParam="quantity"
                                                                                fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                                value={project}
                                                                                fieldItem={{
                                                                                    rules: {
                                                                                        mandatory: !isCR,
                                                                                        thousand_separator: true,
                                                                                        allow_negative: false,
                                                                                        decimal_scale: 4
                                                                                    },
                                                                                    className: "txt-categ year-item main_year"
                                                                                }}
                                                                                bandId="band3"
                                                                                bandShown={showBand}
                                                                                handleBandClick={this.handleBandClick}
                                                                                showErrorBorder={showErrorBorder}
                                                                                isFieldLocked={this.isFieldLocked}
                                                                                onChange={values => {
                                                                                    const { value } = values;
                                                                                    this.setState({
                                                                                        project: {
                                                                                            ...project,
                                                                                            quantity: value,
                                                                                            options_cost: project.cost_per_unit
                                                                                                ? value * project.cost_per_unit
                                                                                                : project.options_cost
                                                                                        }
                                                                                    });
                                                                                }}
                                                                            />
                                                                            <FormField
                                                                                label="Opinions of Cost"
                                                                                fieldKey="options_cost"
                                                                                fieldParam="options_cost"
                                                                                fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                                value={project}
                                                                                fieldItem={{
                                                                                    rules: {
                                                                                        read_only: true,
                                                                                        mandatory: false,
                                                                                        prefix: "$ ",
                                                                                        thousand_separator: true,
                                                                                        allow_negative: false
                                                                                    },
                                                                                    className: "txt-categ year-item main_year"
                                                                                }}
                                                                                bandId="band3"
                                                                                bandShown={showBand}
                                                                                handleBandClick={this.handleBandClick}
                                                                                showErrorBorder={showErrorBorder}
                                                                                isFieldLocked={this.isFieldLocked}
                                                                                onChange={values => {
                                                                                    const { value } = values;
                                                                                    this.setState({
                                                                                        project: {
                                                                                            ...project,
                                                                                            options_cost: value
                                                                                        }
                                                                                    });
                                                                                }}
                                                                            />

                                                                            {this.state.project.maintenance_years &&
                                                                                this.state.project.maintenance_years.map((item, i) => {
                                                                                    return (
                                                                                        <>
                                                                                            <FormField
                                                                                                label={item.year}
                                                                                                fieldKey="maintenance_years"
                                                                                                fieldParam=""
                                                                                                fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                                                value={(item && parseInt(item.amount)) || ""}
                                                                                                isMaintenanceYear
                                                                                                fieldItem={{
                                                                                                    rules: {
                                                                                                        mandatory: false,
                                                                                                        prefix: "$ ",
                                                                                                        thousand_separator: true,
                                                                                                        allow_negative: false
                                                                                                    },
                                                                                                    className: "txt-categ year-item main_year"
                                                                                                }}
                                                                                                placeholder="$ 0"
                                                                                                bandId="band3"
                                                                                                bandShown={showBand}
                                                                                                handleBandClick={this.handleBandClick}
                                                                                                showErrorBorder={showErrorBorder}
                                                                                                isFieldLocked={this.isFieldLocked}
                                                                                                onChange={values => {
                                                                                                    this.setMaintenanceYear(values, item.year);
                                                                                                }}
                                                                                                hasRemove={this.isYearRemovable(item.year)}
                                                                                                onRemove={() => this.removeMaintenanceYear(item)}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            <div className={`txt-rcm`}>
                                                                                <div className="content-inp-card icn">
                                                                                    <AddRemoveButton onAdd={this.addMaintenanceYear} />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {this.state.project.maintenance_years &&
                                                                                this.state.project.maintenance_years.map((item, i) => {
                                                                                    return (
                                                                                        <>
                                                                                            {i !== 0 && i !== 1 && (
                                                                                                <FormField
                                                                                                    label={item.year}
                                                                                                    fieldKey="maintenance_years"
                                                                                                    fieldParam=""
                                                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                                                    value={(item && parseInt(item.amount)) || ""}
                                                                                                    isMaintenanceYear
                                                                                                    fieldItem={{
                                                                                                        rules: {
                                                                                                            mandatory: false,
                                                                                                            prefix: "$ ",
                                                                                                            thousand_separator: true,
                                                                                                            allow_negative: false
                                                                                                        },
                                                                                                        className: "txt-categ year-item main_year"
                                                                                                    }}
                                                                                                    placeholder="$ 0"
                                                                                                    bandId="band3"
                                                                                                    bandShown={showBand}
                                                                                                    handleBandClick={this.handleBandClick}
                                                                                                    showErrorBorder={showErrorBorder}
                                                                                                    isFieldLocked={this.isFieldLocked}
                                                                                                    onChange={values => {
                                                                                                        this.setMaintenanceYear(values, item.year);
                                                                                                    }}
                                                                                                    hasRemove={this.isYearRemovable(item.year)}
                                                                                                    onRemove={() => this.removeMaintenanceYear(item)}
                                                                                                />
                                                                                            )}
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            {this.state.project.maintenance_years?.length >= 2 && (
                                                                                <div className={` txt-rcm `}>
                                                                                    <div className="content-inp-card icn">
                                                                                        <AddRemoveButton onAdd={this.addMaintenanceYear} />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            }
                                                        />

                                                        <RecommendationBand
                                                            label="Priority"
                                                            bandId="band4"
                                                            handleBandClick={this.handleBandClick}
                                                            showBand={showBand}
                                                            MainFields={
                                                                <>
                                                                    <div className="txt-rcm">
                                                                        <div className="content-inp-card blue-sec">
                                                                            <h3 className="p-name">Priority Total</h3>
                                                                            <h3 className="color-white">{this.state.priorityTotal || 0}</h3>
                                                                        </div>
                                                                    </div>
                                                                    {priorityElementsData.map((item, i) => (
                                                                        <>
                                                                            {(i === 0 || i === 1) && (
                                                                                <>
                                                                                    {item.options?.length ? (
                                                                                        <FormField
                                                                                            label={item.display_name || `Priority Element ${i + 1}`}
                                                                                            fieldKey={
                                                                                                item.display_name || `Priority Element ${i + 1}`
                                                                                            }
                                                                                            fieldParam=""
                                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                                            value={project?.priority_elements?.[i]?.option_id || ""}
                                                                                            name={i + 1}
                                                                                            fieldItem={{
                                                                                                field_options: item.options,
                                                                                                rules: {
                                                                                                    mandatory: item.recommendation_required && !isCR
                                                                                                }
                                                                                            }}
                                                                                            bandId="band4"
                                                                                            bandShown={showBand}
                                                                                            handleBandClick={this.handleBandClick}
                                                                                            showErrorBorder={showErrorBorder}
                                                                                            isFieldLocked={this.isFieldLocked}
                                                                                            onChange={value =>
                                                                                                this.setPriorityelement(
                                                                                                    { target: { name: i + 1, value } },
                                                                                                    item.id
                                                                                                )
                                                                                            }
                                                                                            isPriority
                                                                                        />
                                                                                    ) : (
                                                                                        <FormField
                                                                                            label={item.display_name || `Priority Element ${i + 1}`}
                                                                                            fieldKey={
                                                                                                item.display_name || `Priority Element ${i + 1}`
                                                                                            }
                                                                                            fieldParam=""
                                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                                            value={
                                                                                                parseInt(project?.priority_elements?.[i]?.element) ||
                                                                                                ""
                                                                                            }
                                                                                            name={i + 1}
                                                                                            fieldItem={{
                                                                                                rules: {
                                                                                                    mandatory: item.recommendation_required && !isCR
                                                                                                }
                                                                                            }}
                                                                                            bandId="band4"
                                                                                            bandShown={showBand}
                                                                                            handleBandClick={this.handleBandClick}
                                                                                            showErrorBorder={showErrorBorder}
                                                                                            isFieldLocked={this.isFieldLocked}
                                                                                            onChange={e => this.setPriorityelement(e, item.id)}
                                                                                            isPriority
                                                                                        />
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    ))}
                                                                    {!priorityElementsData.length ? (
                                                                        <FormField
                                                                            label="Criticality"
                                                                            fieldKey="criticality"
                                                                            fieldParam="criticality_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={project}
                                                                            fieldItem={{
                                                                                field_options: criticalityData,
                                                                                rules: { mandatory: false },
                                                                                className: "border-right-last"
                                                                            }}
                                                                            bandId="band4"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={value => {
                                                                                this.setState({
                                                                                    project: {
                                                                                        ...project,
                                                                                        criticality_id: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                </>
                                                            }
                                                            RestFields={
                                                                <>
                                                                    {priorityElementsData.map((item, i) => (
                                                                        <>
                                                                            {i !== 0 && i !== 1 && (
                                                                                <>
                                                                                    {item.options?.length ? (
                                                                                        <FormField
                                                                                            label={item.display_name || `Priority Element ${i + 1}`}
                                                                                            fieldKey={
                                                                                                item.display_name || `Priority Element ${i + 1}`
                                                                                            }
                                                                                            fieldParam=""
                                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                                            value={project?.priority_elements?.[i]?.option_id || ""}
                                                                                            name={i + 1}
                                                                                            fieldItem={{
                                                                                                field_options: item.options,
                                                                                                rules: {
                                                                                                    mandatory: item.recommendation_required && !isCR
                                                                                                }
                                                                                            }}
                                                                                            bandId="band4"
                                                                                            bandShown={showBand}
                                                                                            handleBandClick={this.handleBandClick}
                                                                                            showErrorBorder={showErrorBorder}
                                                                                            isFieldLocked={this.isFieldLocked}
                                                                                            onChange={value =>
                                                                                                this.setPriorityelement(
                                                                                                    { target: { name: i + 1, value } },
                                                                                                    item.id
                                                                                                )
                                                                                            }
                                                                                            isPriority
                                                                                        />
                                                                                    ) : (
                                                                                        <FormField
                                                                                            label={item.display_name || `Priority Element ${i + 1}`}
                                                                                            fieldKey={
                                                                                                item.display_name || `Priority Element ${i + 1}`
                                                                                            }
                                                                                            fieldParam=""
                                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                                            value={
                                                                                                parseInt(project?.priority_elements?.[i]?.element) ||
                                                                                                ""
                                                                                            }
                                                                                            name={i + 1}
                                                                                            fieldItem={{
                                                                                                rules: {
                                                                                                    mandatory: item.recommendation_required && !isCR
                                                                                                }
                                                                                            }}
                                                                                            bandId="band4"
                                                                                            bandShown={showBand}
                                                                                            handleBandClick={this.handleBandClick}
                                                                                            showErrorBorder={showErrorBorder}
                                                                                            isFieldLocked={this.isFieldLocked}
                                                                                            onChange={e => this.setPriorityelement(e, item.id)}
                                                                                            isPriority
                                                                                        />
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    ))}
                                                                    {priorityElementsData?.length ? (
                                                                        <FormField
                                                                            label="Criticality"
                                                                            fieldKey="criticality"
                                                                            fieldParam="criticality_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={project}
                                                                            fieldItem={{
                                                                                field_options: criticalityData,
                                                                                rules: { mandatory: false }
                                                                            }}
                                                                            bandId="band4"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={value => {
                                                                                this.setState({
                                                                                    project: {
                                                                                        ...project,
                                                                                        criticality_id: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                </>
                                                            }
                                                        />

                                                        {project.recommendation_type === "asset" && (
                                                            <RecommendationBand
                                                                label="Asset"
                                                                bandId="asset"
                                                                handleBandClick={this.handleBandClick}
                                                                isDefaultOpen
                                                                showBand={showBand}
                                                                MainFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Asset Name"
                                                                            fieldKey="asset_name"
                                                                            fieldParam="asset_name"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                rules: { mandatory: true }
                                                                            }}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={this.handleChangeAssetForm}
                                                                        />
                                                                        <FormField
                                                                            label="Asset Condition"
                                                                            fieldKey="asset_condition"
                                                                            fieldParam="client_asset_condition_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                field_options: dropDownList?.asset_conditions,
                                                                                rules: { mandatory: true }
                                                                            }}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={value =>
                                                                                this.handleChangeAssetForm({
                                                                                    target: { value, name: "client_asset_condition_id" }
                                                                                })
                                                                            }
                                                                        />
                                                                        <FormField
                                                                            label="Asset Tag"
                                                                            fieldKey="asset_tag"
                                                                            fieldParam="asset_tag"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                rules: { mandatory: miscellaneous?.asset_tag_required }
                                                                            }}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={this.handleChangeAssetForm}
                                                                        />
                                                                    </>
                                                                }
                                                                RestFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Serial Number"
                                                                            fieldKey="serial_number"
                                                                            fieldParam="serial_number"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                rules: { mandatory: miscellaneous?.serial_number_required }
                                                                            }}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={this.handleChangeAssetForm}
                                                                        />
                                                                        <FormField
                                                                            label="Installed Year"
                                                                            fieldKey="installed_year"
                                                                            fieldParam="installed_year"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                rules: { mandatory: false },
                                                                                format: "####"
                                                                            }}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    assetFormData: {
                                                                                        ...assetFormData,
                                                                                        installed_year: value,
                                                                                        usefull_life_remaining: getUsefullLifeRemaining(
                                                                                            value,
                                                                                            assetFormData.service_life
                                                                                        ),
                                                                                        current_age: getCurrentAge(value)
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                        <FormField
                                                                            label="Current Age"
                                                                            fieldKey="current_age"
                                                                            fieldParam="current_age"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                rules: { mandatory: false, read_only: true }
                                                                            }}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                        />
                                                                        <FormField
                                                                            label="Service Life"
                                                                            fieldKey="service_life"
                                                                            fieldParam="service_life"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                rules: { mandatory: false }
                                                                            }}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    assetFormData: {
                                                                                        ...assetFormData,
                                                                                        service_life: value,
                                                                                        usefull_life_remaining: getUsefullLifeRemaining(
                                                                                            assetFormData.installed_year,
                                                                                            value
                                                                                        )
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                        <FormField
                                                                            label="End of Service Life"
                                                                            fieldKey="end_of_service_life"
                                                                            fieldParam="end_of_service_life"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={
                                                                                assetFormData.usefull_life_remaining ||
                                                                                assetFormData.usefull_life_remaining === 0
                                                                                    ? `${
                                                                                          new Date().getFullYear() +
                                                                                          assetFormData.usefull_life_remaining
                                                                                      }`
                                                                                    : ""
                                                                            }
                                                                            fieldItem={{
                                                                                rules: { mandatory: false, read_only: true }
                                                                            }}
                                                                            bandId="asset"
                                                                            isAsset
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                        />
                                                                        <FormField
                                                                            label="Useful Life Remaining"
                                                                            fieldKey="useful_life_remaining"
                                                                            fieldParam="usefull_life_remaining"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                rules: { mandatory: false, read_only: true }
                                                                            }}
                                                                            bandId="asset"
                                                                            tooltip={
                                                                                assetFormData.usefull_life_remaining
                                                                                    ? `Year= ${
                                                                                          new Date().getFullYear() +
                                                                                          assetFormData.usefull_life_remaining
                                                                                      }`
                                                                                    : ""
                                                                            }
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    assetFormData: {
                                                                                        ...assetFormData,
                                                                                        service_life: value,
                                                                                        usefull_life_remaining: getUsefullLifeRemaining(
                                                                                            assetFormData.installed_year,
                                                                                            value
                                                                                        )
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                        <FormField
                                                                            label="Model Number"
                                                                            fieldKey="model_number"
                                                                            fieldParam="model_number"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                rules: { mandatory: miscellaneous?.model_number_required }
                                                                            }}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={this.handleChangeAssetForm}
                                                                        />
                                                                        <FormField
                                                                            label="Manufacturer"
                                                                            fieldKey="manufacturer"
                                                                            fieldParam="manufacturer"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={assetFormData?.manufacturer_id}
                                                                            onChange={(value, name) =>
                                                                                this.handleChangeAssetForm({
                                                                                    target: { value, name: "manufacturer", selectedName: name }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            fieldItem={{
                                                                                field_options: dropDownList?.manufacturers || [],
                                                                                rules: {
                                                                                    mandatory: { mandatory: miscellaneous?.manufacturer_required }
                                                                                }
                                                                            }}
                                                                            handleInputChange={this.handleInputChange}
                                                                            isManufacturer={true}
                                                                            isLoadingDrop={this.state?.isLoadingDrop}
                                                                            manufacturerCount={this.props.assetReducer?.manufacturerCount?.count}
                                                                            isAsset={true}
                                                                        />
                                                                        {/* <FormField
                                                                            label="Manufacturer"
                                                                            fieldKey="manufacturer"
                                                                            fieldParam="manufacturer"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                rules: { mandatory: miscellaneous?.manufacturer_required }
                                                                            }}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={this.handleChangeAssetForm}
                                                                        /> */}
                                                                        <FormField
                                                                            label="Capacity"
                                                                            fieldKey="capacity"
                                                                            fieldParam="capacity"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                rules: { mandatory: miscellaneous?.capacity_required }
                                                                            }}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={this.handleChangeAssetForm}
                                                                        />
                                                                        <FormField
                                                                            label="Capacity Unit"
                                                                            fieldKey="capacity_unit"
                                                                            fieldParam="capacity_unit"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                rules: { mandatory: miscellaneous?.capacity_unit_required }
                                                                            }}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={this.handleChangeAssetForm}
                                                                        />
                                                                        <FormField
                                                                            label="Refrigerant"
                                                                            fieldKey="refrigerant"
                                                                            fieldParam="refrigerant"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={assetFormData}
                                                                            fieldItem={{
                                                                                rules: { mandatory: miscellaneous?.refrigerant_required }
                                                                            }}
                                                                            bandId="asset"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={this.handleChangeAssetForm}
                                                                        />
                                                                    </>
                                                                }
                                                            />
                                                        )}

                                                        {project?.fmp === "yes" && (
                                                            <RecommendationBand
                                                                label="Facility Master Plan"
                                                                bandId="fmp"
                                                                handleBandClick={this.handleBandClick}
                                                                showBand={showBand}
                                                                MainFields={
                                                                    <>
                                                                        <FormField
                                                                            label="FMP ID"
                                                                            fieldKey="fmp_id"
                                                                            fieldParam="fmp_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            fieldItem={{}}
                                                                            value={project}
                                                                            bandId="fmp"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={e => {
                                                                                this.setState({
                                                                                    project: {
                                                                                        ...project,
                                                                                        fmp_id: e.target.value
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                        <FormField
                                                                            label="FMP Project"
                                                                            fieldKey="fmp_project"
                                                                            fieldParam="fmp_project"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            fieldItem={{}}
                                                                            value={project}
                                                                            bandId="fmp"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={e => {
                                                                                this.setState({
                                                                                    project: {
                                                                                        ...project,
                                                                                        fmp_project: e.target.value
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                        <FormField
                                                                            label="FMP Track"
                                                                            fieldKey="fmp_track"
                                                                            fieldParam="fmp_track"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            fieldItem={{}}
                                                                            value={project}
                                                                            bandId="fmp"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={e => {
                                                                                this.setState({
                                                                                    project: {
                                                                                        ...project,
                                                                                        fmp_track: e.target.value
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                    </>
                                                                }
                                                                RestFields={<></>}
                                                            />
                                                        )}

                                                        <RecommendationBand
                                                            label="Additional Details"
                                                            bandId="band6"
                                                            handleBandClick={this.handleBandClick}
                                                            // isDefaultOpen
                                                            showBand={showBand}
                                                            MainFields={
                                                                <>
                                                                    {project.recommendation_type === "building" ? (
                                                                        <FormField
                                                                            label="Condition"
                                                                            fieldKey="condition"
                                                                            fieldParam="asset_condition_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={project}
                                                                            fieldItem={{
                                                                                field_options:
                                                                                    this.props.recommendationsReducer?.getConditionBasedOnProject
                                                                                        ?.asset_conditions,
                                                                                rules: { mandatory: !isCR }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={value =>
                                                                                this.setState({
                                                                                    project: {
                                                                                        ...project,
                                                                                        asset_condition_id: value
                                                                                    }
                                                                                })
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            <FormField
                                                                                label="Status"
                                                                                fieldKey="status"
                                                                                fieldParam="status"
                                                                                fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                                value={project}
                                                                                hasEmptyOption={false}
                                                                                fieldItem={{
                                                                                    field_options: [
                                                                                        { id: "active", name: "Active" },
                                                                                        { id: "in_progress", name: "In Progress" },
                                                                                        { id: "pending", name: "Pending" },
                                                                                        { id: "on_hold", name: "On Hold" },
                                                                                        { id: "completed", name: "Completed" }
                                                                                    ],
                                                                                    rules: { mandatory: !isCR }
                                                                                }}
                                                                                bandId="band6"
                                                                                bandShown={showBand}
                                                                                handleBandClick={this.handleBandClick}
                                                                                showErrorBorder={showErrorBorder}
                                                                                isFieldLocked={this.isFieldLocked}
                                                                                onChange={value =>
                                                                                    this.setState({
                                                                                        project: {
                                                                                            ...project,
                                                                                            status: value
                                                                                        }
                                                                                    })
                                                                                }
                                                                            />
                                                                            <FormField
                                                                                label="Status Notes"
                                                                                fieldKey="status_notes"
                                                                                fieldParam="status_notes"
                                                                                fieldType={DYNAMIC_FIELD_TYPES.MEMO}
                                                                                value={project}
                                                                                hasEmptyOption={false}
                                                                                fieldItem={{
                                                                                    rules: { mandatory: false }
                                                                                }}
                                                                                bandId="band6"
                                                                                bandShown={showBand}
                                                                                handleBandClick={this.handleBandClick}
                                                                                showErrorBorder={showErrorBorder}
                                                                                isFieldLocked={this.isFieldLocked}
                                                                                onChange={value =>
                                                                                    this.setState({
                                                                                        project: {
                                                                                            ...project,
                                                                                            status_notes: value
                                                                                        }
                                                                                    })
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                    <FormField
                                                                        label="Category"
                                                                        fieldKey="category"
                                                                        fieldParam="category_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            field_options:
                                                                                this.props.recommendationsReducer?.getCategoryByProject?.categories,
                                                                            rules: { mandatory: !isCR }
                                                                        }}
                                                                        bandId="band6"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={value => {
                                                                            const bands =
                                                                                this.props.recommendationsReducer?.getCategoryByProject?.categories.find(
                                                                                    elem => elem.id === value
                                                                                )?.assigned_bands;
                                                                            this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    category_id: value,
                                                                                    energy_band_show: bands.includes("energy_band") ? "yes" : "no",
                                                                                    water_band_show: bands.includes("water_band") ? "yes" : "no"
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                    {project.recommendation_type === "building" && (
                                                                        <FormField
                                                                            label="Capital Type"
                                                                            fieldKey="capital_type"
                                                                            fieldParam="capital_type"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={project}
                                                                            fieldItem={{
                                                                                field_options:
                                                                                    this.props.recommendationsReducer?.getCapitalTypeBasedOnProject
                                                                                        ?.capital_types,
                                                                                rules: { mandatory: !isCR }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            isCapitalType
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={value => {
                                                                                this.setState({
                                                                                    project: {
                                                                                        ...project,
                                                                                        capital_type: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                    )}
                                                                </>
                                                            }
                                                            RestFields={
                                                                <>
                                                                    {project.recommendation_type === "building" ? (
                                                                        <>
                                                                            {isCR ? (
                                                                                <>
                                                                                    <FormField
                                                                                        label="CR Status"
                                                                                        fieldKey="cr_status"
                                                                                        fieldParam="cr_status"
                                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                                        value={project}
                                                                                        hasEmptyOption={false}
                                                                                        fieldItem={{
                                                                                            field_options: CAPITAL_REQUEST_STATUS,
                                                                                            rules: { mandatory: false, read_only: !isCRApprover }
                                                                                        }}
                                                                                        bandId="band6"
                                                                                        bandShown={showBand}
                                                                                        handleBandClick={this.handleBandClick}
                                                                                        showErrorBorder={showErrorBorder}
                                                                                        isFieldLocked={this.isFieldLocked}
                                                                                        onChange={value =>
                                                                                            this.setState({
                                                                                                project: {
                                                                                                    ...project,
                                                                                                    cr_status: value
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                    <FormField
                                                                                        label="CR Status Notes"
                                                                                        fieldKey="cr_status_notes"
                                                                                        fieldParam="cr_status_notes"
                                                                                        fieldType={DYNAMIC_FIELD_TYPES.MEMO}
                                                                                        value={project}
                                                                                        hasEmptyOption={false}
                                                                                        fieldItem={{
                                                                                            rules: { mandatory: false }
                                                                                        }}
                                                                                        bandId="band6"
                                                                                        bandShown={showBand}
                                                                                        handleBandClick={this.handleBandClick}
                                                                                        showErrorBorder={showErrorBorder}
                                                                                        isFieldLocked={this.isFieldLocked}
                                                                                        onChange={value =>
                                                                                            this.setState({
                                                                                                project: {
                                                                                                    ...project,
                                                                                                    cr_status_notes: value
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <FormField
                                                                                        label="Status"
                                                                                        fieldKey="status"
                                                                                        fieldParam="status"
                                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                                        value={project}
                                                                                        hasEmptyOption={false}
                                                                                        fieldItem={{
                                                                                            field_options: [
                                                                                                { id: "active", name: "Active" },
                                                                                                { id: "in_progress", name: "In Progress" },
                                                                                                { id: "pending", name: "Pending" },
                                                                                                { id: "on_hold", name: "On Hold" },
                                                                                                { id: "completed", name: "Completed" }
                                                                                            ],
                                                                                            rules: { mandatory: !isCR }
                                                                                        }}
                                                                                        bandId="band6"
                                                                                        bandShown={showBand}
                                                                                        handleBandClick={this.handleBandClick}
                                                                                        showErrorBorder={showErrorBorder}
                                                                                        isFieldLocked={this.isFieldLocked}
                                                                                        onChange={value =>
                                                                                            this.setState({
                                                                                                project: {
                                                                                                    ...project,
                                                                                                    status: value
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                    <FormField
                                                                                        label="Status Notes"
                                                                                        fieldKey="status_notes"
                                                                                        fieldParam="status_notes"
                                                                                        fieldType={DYNAMIC_FIELD_TYPES.MEMO}
                                                                                        value={project}
                                                                                        hasEmptyOption={false}
                                                                                        fieldItem={{
                                                                                            rules: { mandatory: false }
                                                                                        }}
                                                                                        bandId="band6"
                                                                                        bandShown={showBand}
                                                                                        handleBandClick={this.handleBandClick}
                                                                                        showErrorBorder={showErrorBorder}
                                                                                        isFieldLocked={this.isFieldLocked}
                                                                                        onChange={value =>
                                                                                            this.setState({
                                                                                                project: {
                                                                                                    ...project,
                                                                                                    status_notes: value
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            <FormField
                                                                                label="Installed Year"
                                                                                fieldKey="installed_year"
                                                                                fieldParam="installed_year"
                                                                                fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                                value={project}
                                                                                fieldItem={{
                                                                                    rules: {
                                                                                        allow_negative: false,
                                                                                        format: "####"
                                                                                    }
                                                                                }}
                                                                                bandId="band6"
                                                                                bandShown={showBand}
                                                                                handleBandClick={this.handleBandClick}
                                                                                showErrorBorder={showErrorBorder}
                                                                                isFieldLocked={this.isFieldLocked}
                                                                                onChange={values => {
                                                                                    const { value } = values;
                                                                                    this.setState({
                                                                                        project: {
                                                                                            ...project,
                                                                                            installed_year: value,
                                                                                            usefull_life_remaining: getUsefullLifeRemaining(
                                                                                                value,
                                                                                                project.service_life
                                                                                            )
                                                                                        }
                                                                                    });
                                                                                }}
                                                                            />
                                                                            <FormField
                                                                                label="Service Life"
                                                                                fieldKey="service_life"
                                                                                fieldParam="service_life"
                                                                                fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                                value={project}
                                                                                fieldItem={{
                                                                                    rules: {}
                                                                                }}
                                                                                bandId="band6"
                                                                                bandShown={showBand}
                                                                                handleBandClick={this.handleBandClick}
                                                                                showErrorBorder={showErrorBorder}
                                                                                isFieldLocked={this.isFieldLocked}
                                                                                onChange={e => {
                                                                                    this.setState({
                                                                                        project: {
                                                                                            ...project,
                                                                                            service_life: e.target.value,
                                                                                            usefull_life_remaining: getUsefullLifeRemaining(
                                                                                                project.installed_year,
                                                                                                e.target.value
                                                                                            )
                                                                                        }
                                                                                    });
                                                                                }}
                                                                            />
                                                                            <FormField
                                                                                label="Useful Life Remaining"
                                                                                fieldKey="usefull_life_remaining"
                                                                                fieldParam="usefull_life_remaining"
                                                                                fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                                value={project}
                                                                                fieldItem={{
                                                                                    rules: { read_only: true }
                                                                                }}
                                                                                bandId="band6"
                                                                                bandShown={showBand}
                                                                                handleBandClick={this.handleBandClick}
                                                                                showErrorBorder={showErrorBorder}
                                                                                isFieldLocked={this.isFieldLocked}
                                                                                tooltip={
                                                                                    project.usefull_life_remaining
                                                                                        ? `Year= ${
                                                                                              new Date().getFullYear() +
                                                                                              project.usefull_life_remaining
                                                                                          }`
                                                                                        : ""
                                                                                }
                                                                            />
                                                                            <FormField
                                                                                label="CRV"
                                                                                fieldKey="crv"
                                                                                fieldParam="crv"
                                                                                fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                                value={project}
                                                                                fieldItem={{
                                                                                    rules: {
                                                                                        prefix: "$ ",
                                                                                        thousand_separator: true,
                                                                                        allow_negative: false
                                                                                    }
                                                                                }}
                                                                                bandId="band6"
                                                                                bandShown={showBand}
                                                                                handleBandClick={this.handleBandClick}
                                                                                showErrorBorder={showErrorBorder}
                                                                                isFieldLocked={this.isFieldLocked}
                                                                                onChange={values => {
                                                                                    const { value } = values;
                                                                                    this.setState({
                                                                                        project: {
                                                                                            ...project,
                                                                                            crv: value
                                                                                        }
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <FormField
                                                                            label="Capital Type"
                                                                            fieldKey="capital_type"
                                                                            fieldParam="capital_type"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={project}
                                                                            fieldItem={{
                                                                                field_options:
                                                                                    this.props.recommendationsReducer?.getCapitalTypeBasedOnProject
                                                                                        ?.capital_types,
                                                                                rules: { mandatory: !isCR }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            isCapitalType
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            isFieldLocked={this.isFieldLocked}
                                                                            onChange={value => {
                                                                                this.setState({
                                                                                    project: {
                                                                                        ...project,
                                                                                        capital_type: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                    )}

                                                                    <FormField
                                                                        label="Department"
                                                                        fieldKey="department"
                                                                        fieldParam="department_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            field_options:
                                                                                this.props.recommendationsReducer?.getDepaartmentsByProject?.departments?.filter(
                                                                                    item => item.name !== null
                                                                                ),
                                                                            rules: { mandatory: false }
                                                                        }}
                                                                        bandId="band6"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={value =>
                                                                            this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    department_id: value
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                    <FormField
                                                                        label="Initiative"
                                                                        fieldKey="initiative"
                                                                        fieldParam="initiative_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            field_options:
                                                                                this.props.recommendationsReducer?.getInitiativeDropdown?.projects,
                                                                            rules: { mandatory: false }
                                                                        }}
                                                                        bandId="band6"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={value =>
                                                                            this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    initiative_id: value
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                    <FormField
                                                                        label="Funding"
                                                                        fieldKey="funding"
                                                                        fieldParam="funding_source_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            field_options:
                                                                                this.props.recommendationsReducer?.getFundingSourceByProject
                                                                                    ?.funding_sources,
                                                                            rules: { mandatory: miscellaneous?.funding_required && !isCR }
                                                                        }}
                                                                        bandId="band6"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={value => {
                                                                            this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    funding_source_id: value
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                    <div className="txt-rcm">
                                                                        <div className="content-inp-card">
                                                                            <div className="form-group">
                                                                                <label>Project</label>
                                                                                <input
                                                                                    type="text"
                                                                                    readOnly
                                                                                    autoComplete={"nope"}
                                                                                    className="custom-input form-control cursor-diabled"
                                                                                    placeholder="Project"
                                                                                    value={getProjectByIdResponse?.name}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <FormField
                                                                        label="Responsibility"
                                                                        fieldKey="responsibility"
                                                                        fieldParam="responsibility_id"
                                                                        fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                        value={project}
                                                                        fieldItem={{
                                                                            field_options:
                                                                                this.props.recommendationsReducer?.getResponsibilityByProject
                                                                                    ?.responsibilities,
                                                                            rules: { mandatory: miscellaneous?.responsibility_required && !isCR }
                                                                        }}
                                                                        bandId="band6"
                                                                        bandShown={showBand}
                                                                        handleBandClick={this.handleBandClick}
                                                                        showErrorBorder={showErrorBorder}
                                                                        isFieldLocked={this.isFieldLocked}
                                                                        onChange={value => {
                                                                            this.setState({
                                                                                project: {
                                                                                    ...project,
                                                                                    responsibility_id: value
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                    <div className="txt-rcm">
                                                                        <div className="content-inp-card">
                                                                            <div className="form-group">
                                                                                <label>Source</label>
                                                                                <input
                                                                                    type="text"
                                                                                    readOnly
                                                                                    autoComplete={"nope"}
                                                                                    className="custom-input form-control cursor-diabled"
                                                                                    placeholder="Source"
                                                                                    value={this.state.project.source}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="txt-rcm">
                                                                        <div className="content-inp-card">
                                                                            <div className="form-group">
                                                                                <label>Birth Year</label>
                                                                                <input
                                                                                    type="text"
                                                                                    readOnly
                                                                                    autoComplete={"nope"}
                                                                                    className="custom-input form-control cursor-diabled"
                                                                                    placeholder="Birth Year"
                                                                                    value={birthYear}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            ExtraFields={
                                                                <>
                                                                    <div className="form-row m-0">
                                                                        {(isCR || project.source === RECOMMENDATION_SOURCE_TYPES.CAPITAL_REQUEST) && (
                                                                            <>
                                                                                <div className="col-md-4 p-0">
                                                                                    <div className="outer-rcm">
                                                                                        <div className="txt-rcm w-100">
                                                                                            <div>
                                                                                                <img src="/img/icn1.png" alt="" />
                                                                                            </div>
                                                                                            <div className="txt-secn">
                                                                                                <h4>Requester</h4>
                                                                                                <div className="sel-recom-react">
                                                                                                    <SelectBox
                                                                                                        isClearable={false}
                                                                                                        value={this.state.project.requestor_id}
                                                                                                        className={`selectOtr not-draggable build-select-otr ${
                                                                                                            this.isFieldLocked("requestor")
                                                                                                                ? "grayed-out"
                                                                                                                : ""
                                                                                                        }`}
                                                                                                        isSurveyor
                                                                                                        disabled
                                                                                                        isRecommendation
                                                                                                        optionsList={
                                                                                                            this.props.projectReducer
                                                                                                                .getUserByProjectData?.users ?? []
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 p-0">
                                                                                    <div className="outer-rcm">
                                                                                        <div className="txt-rcm w-100">
                                                                                            <div>
                                                                                                <img src="/img/icn-2.png" alt="" />
                                                                                            </div>
                                                                                            <div className="txt-secn">
                                                                                                <h4>Requested Date</h4>
                                                                                                <DatePicker
                                                                                                    autoComplete={"nope"}
                                                                                                    placeholderText={`Inspection Date`}
                                                                                                    className={`form-control custom-wid ${
                                                                                                        this.isFieldLocked("inspection_date")
                                                                                                            ? "grayed-out"
                                                                                                            : ""
                                                                                                    }`}
                                                                                                    disabled
                                                                                                    selected={
                                                                                                        this.state.project.requested_date &&
                                                                                                        moment(
                                                                                                            this.state.project.requested_date
                                                                                                        ).isValid()
                                                                                                            ? new Date(
                                                                                                                  this.state.project.requested_date
                                                                                                              )
                                                                                                            : ""
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>{" "}
                                                                            </>
                                                                        )}

                                                                        {!isCR && (
                                                                            <>
                                                                                <div className="col-md-4 p-0">
                                                                                    <div className="outer-rcm">
                                                                                        <div className="txt-rcm w-100">
                                                                                            <div>
                                                                                                <img src="/img/icn1.png" alt="" />
                                                                                            </div>
                                                                                            <div className="txt-secn">
                                                                                                <h4>Surveyor</h4>
                                                                                                <div className="sel-recom-react">
                                                                                                    <SelectBox
                                                                                                        isClearable={false}
                                                                                                        value={this.state.project.surveyor_id}
                                                                                                        className={`selectOtr not-draggable build-select-otr ${
                                                                                                            this.isFieldLocked("surveyor")
                                                                                                                ? "grayed-out"
                                                                                                                : ""
                                                                                                        }`}
                                                                                                        showErrorBorder={
                                                                                                            miscellaneous?.responsibility_required &&
                                                                                                            !this.state.project.responsibility_id?.trim()
                                                                                                                .length
                                                                                                        }
                                                                                                        isSurveyor
                                                                                                        isRecommendation
                                                                                                        disabled={this.isFieldLocked("surveyor")}
                                                                                                        optionsList={
                                                                                                            this.props.projectReducer
                                                                                                                .getUserByProjectData?.users ?? []
                                                                                                        }
                                                                                                        handleChange={value => {
                                                                                                            const surveyorUser =
                                                                                                                this.props.projectReducer.getUserByProjectData?.users?.find(
                                                                                                                    ({ id }) => id === value
                                                                                                                );
                                                                                                            this.setState({
                                                                                                                project: {
                                                                                                                    ...project,
                                                                                                                    surveyor_id: value,
                                                                                                                    surveyor:
                                                                                                                        surveyorUser?.printed_name ||
                                                                                                                        surveyorUser?.name ||
                                                                                                                        ""
                                                                                                                }
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 p-0">
                                                                                    <div className="outer-rcm">
                                                                                        <div className="txt-rcm w-100">
                                                                                            <div>
                                                                                                <img src="/img/icn-2.png" alt="" />
                                                                                            </div>
                                                                                            <div className="txt-secn">
                                                                                                <h4>Inspection Date {isCR ? "" : "*"}</h4>
                                                                                                <DatePicker
                                                                                                    autoComplete={"nope"}
                                                                                                    placeholderText={`Inspection Date`}
                                                                                                    // className="form-control custom-wid"
                                                                                                    className={`${
                                                                                                        showErrorBorder &&
                                                                                                        !this.state.project.inspection_date
                                                                                                            ? "error-border "
                                                                                                            : ""
                                                                                                    }form-control custom-wid ${
                                                                                                        this.isFieldLocked("inspection_date")
                                                                                                            ? "grayed-out"
                                                                                                            : ""
                                                                                                    }`}
                                                                                                    disabled={this.isFieldLocked("inspection_date")}
                                                                                                    selected={
                                                                                                        this.state.project.inspection_date &&
                                                                                                        moment(
                                                                                                            this.state.project.inspection_date
                                                                                                        ).isValid()
                                                                                                            ? new Date(
                                                                                                                  this.state.project.inspection_date
                                                                                                              )
                                                                                                            : ""
                                                                                                    }
                                                                                                    onChange={date => {
                                                                                                        this.setState({
                                                                                                            project: {
                                                                                                                ...project,
                                                                                                                inspection_date: new Date(date)
                                                                                                            }
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            }
                                                        />

                                                        {project.energy_band_show === "yes" && (
                                                            <EnergyBand
                                                                energyData={project.energy_band}
                                                                bandShown={showBand.energyBand}
                                                                handleBandClick={this.handleBandClick}
                                                                handleChangeData={this.handleChangeBandData}
                                                                bandId="energyBand"
                                                                isFieldLocked={this.isFieldLocked}
                                                            />
                                                        )}
                                                        {project.water_band_show === "yes" && (
                                                            <WaterBand
                                                                waterData={project.water_band}
                                                                bandShown={showBand.waterBand}
                                                                handleBandClick={this.handleBandClick}
                                                                handleChangeData={this.handleChangeBandData}
                                                                bandId="waterBand"
                                                                isFieldLocked={this.isFieldLocked}
                                                            />
                                                        )}
                                                        <DynamicBand
                                                            bandData={project.dynamic_fields}
                                                            bandShown={showBand}
                                                            handleBandClick={this.handleBandClick}
                                                            handleChangeData={this.handleChangeBandData}
                                                            showErrorBorder={showErrorBorder}
                                                            isFieldLocked={this.isFieldLocked}
                                                            isRecommendation
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 back-set">
                                                    {selectedProject ? (
                                                        <div className="details-img-block details-img-new" style={{ height: "250px" }}>
                                                            {imageUrl ? <img src={imageUrl} alt="" /> : <img src="/img/no-image.png" alt="" />}
                                                        </div>
                                                    ) : query.type === RECOMMENDATION_SOURCE_TYPES.CAPITAL_REQUEST ? (
                                                        <div className="template pb-0" style={{ maxHeight: "350px" }}>
                                                            <div className={`heading`}>
                                                                <h3 className={`p-0`}>Capital Request Narrative</h3>
                                                                <div class="d-flex align-items-center">
                                                                    <button
                                                                        className="btn"
                                                                        onClick={() => this.handleChangeNote(this.state.cr_narrative)}
                                                                    >
                                                                        <i className="fas fa-copy" /> Copy to Rec. Narrative
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className={`cnt-temp-area notes-outer-area`} data-for="recommendation_details">
                                                                <CKEditor
                                                                    editor={Editor}
                                                                    config={{ ...CkeditorConfiguration, toolbar: [] }}
                                                                    id="text-form"
                                                                    data={this.state.cr_narrative || ""}
                                                                    onReady={editor => {
                                                                        editor?.enableReadOnlyMode("my-feature-id");
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : null}

                                                    <div className="template">
                                                        {/* <h1>{dimensions}</h1> */}
                                                        <div className={`heading ${this.isFieldLocked("note_html") ? "grayed-out" : ""}`}>
                                                            <h3 className={`${project.sub_system_id ? "col-5" : ""} p-0`}>
                                                                {isCR
                                                                    ? "Capital Request Narrative *"
                                                                    : miscellaneous?.recommendation_narrative_required
                                                                    ? "Recommendation Narrative *"
                                                                    : "Recommendation Narrative"}
                                                            </h3>
                                                            <div class="d-flex align-items-center">
                                                                {this.state.project.sub_system_id && (
                                                                    <button
                                                                        className="btn btn-clear"
                                                                        onClick={() => this.toggleShowReportNoteTemplateModal()}
                                                                    >
                                                                        <i className="fas fa-plus" /> Add Template
                                                                    </button>
                                                                )}
                                                                <div
                                                                    data-tip={
                                                                        this.isFieldLocked("note_html")
                                                                            ? "This field is locked and cannot be edited"
                                                                            : `Click to Expand ${
                                                                                  isCR ? "Capital Request" : "Recommendation"
                                                                              } Narrative`
                                                                    }
                                                                    data-for="recommendation_details"
                                                                    data-effect="solid"
                                                                    data-place="left"
                                                                    class="ml-2 btn-expand-bl"
                                                                    disabled={this.isFieldLocked("note_html")}
                                                                >
                                                                    <img
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => this.openReportNote()}
                                                                        src="/img/expand1.svg"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`cnt-temp-area notes-outer-area ${
                                                                showErrorBorder &&
                                                                miscellaneous?.recommendation_narrative_required &&
                                                                !project?.note_html?.trim()?.length
                                                                    ? "error-border"
                                                                    : ""
                                                            } ${this.isFieldLocked("note_html") ? "grayed-out" : ""}`}
                                                            style={{ maxHeight: `${this.state.editorHeight}px` }}
                                                            data-tip={
                                                                this.isFieldLocked("note_html") ? "This field is locked and cannot be edited" : ""
                                                            }
                                                            data-for="recommendation_details"
                                                        >
                                                            <CKEditor
                                                                editor={Editor}
                                                                config={
                                                                    this.isFieldLocked("note_html")
                                                                        ? { ...CkeditorConfiguration, toolbar: [] }
                                                                        : { ...CkeditorConfiguration, extraPlugins: [MyUploadAdapterPlugin] }
                                                                }
                                                                id="text-form"
                                                                data={this.state.project.note_html || ""}
                                                                onReady={editor => {
                                                                    if (this.isFieldLocked("note_html")) {
                                                                        editor?.enableReadOnlyMode("my-feature-id");
                                                                    }
                                                                }}
                                                                // disabled={this.isFieldLocked("note_html")}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    this.handleChangeNote(data);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row bg-grey-bottom-layer"></div>
                            </div>
                        ) : this.state.activeDetail === "Asset Details" ? (
                            <div className="tab-active location-sec recom-sec recommendation-form  addition-add">
                                {project.code && (
                                    <label htmlFor="" className="label-box-top">
                                        <span className="label-txt"> ID: </span> <span className="label-detl">{` ${project?.code}`}</span>{" "}
                                    </label>
                                )}
                                <div className="col-md-12 detail-recom">
                                    <div className="btn-assign">
                                        {/* <button
                                            className={`btn add-btn mr-2 ${
                                                !(project.region_id && project.site_id && project.building_id) ? "cursor-diabled" : ""
                                            }`}
                                            disabled={!(project.region_id && project.site_id && project.building_id)}
                                            onClick={() => {
                                                sessionStorage.setItem("currentFormData", JSON.stringify(project));
                                                sessionStorage.setItem("activeDetail", "Asset Details");
                                                addToBreadCrumpData({
                                                    key: "addAsset",
                                                    name: `Add Asset`,
                                                    path: `/assets/add`
                                                });
                                                history.push({
                                                    pathname: "/assets/add",
                                                    search: "?isRecomAsset=true"
                                                });
                                            }}
                                        >
                                            <i className="fas fa-plus" /> Add New Asset & Assign
                                        </button> */}
                                        <button
                                            className={`btn add-btn ${
                                                !(project.region_id && project.site_id && project.building_id) ? "cursor-diabled" : ""
                                            }`}
                                            disabled={!(project.region_id && project.site_id && project.building_id)}
                                            onClick={() => this.setState({ showAssetModal: true })}
                                        >
                                            <i className="fas fa-plus" /> Assign Asset
                                        </button>
                                    </div>
                                    <AssetForm
                                        assetFormData={assetFormData}
                                        clientList={clients}
                                        regionList={regionList}
                                        siteList={siteList}
                                        additionList={this.props.recommendationsReducer.getAdditionByBuilding.additions}
                                        buildingList={this.props.userReducer.getAllBuildingsDropdownResponse.buildings}
                                        floorList={this.props.recommendationsReducer.getFloorByBuilding.floors}
                                        tradeList={this.props.recommendationsReducer.getTradeByProject.trades}
                                        systemList={this.state.systemArray}
                                        subSystemList={this.state.subSystemArray}
                                        handleChangeAssetForm={this.handleChangeAssetForm}
                                        setAssetFormData={this.setAssetFormData}
                                        showErrorBorder={this.state.showErrorBorder}
                                        miscellaneous={miscellaneous}
                                        isFieldLocked={this.isFieldLocked}
                                    />
                                    {/* <div className="add-btn-wrapper">
                                        <span className="errorMessage">{this.state.showErrorBorder ? this.state.errorMessage : ""}</span>
                                        <button className="button btn-clear" onClick={() => this.confirmCancel()}>
                                            Cancel
                                        </button>

                                        {!this.state.loading ? (
                                            <button
                                                title={
                                                    this.state.locked === true
                                                        ? "You cannot update this item as this recommendation is locked!!!"
                                                        : ""
                                                }
                                                className={`button btn-save ml-2 ${this.state.locked === true ? "cursor-notallowed" : ""}`}
                                                disabled={this.state.lock === true ? true : false}
                                                onClick={() => this.addProject()}
                                            >
                                                {this.state.selectedProject ? "Update" : "Save"}
                                            </button>
                                        ) : (
                                            <button className="button btn-save ml-2">
                                                <div className="button-loader d-flex justify-content-center align-items-center">
                                                    <div className="spinner-border text-white" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </button>
                                        )}
                                    </div> */}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                {this.renderConfirmationModal()}
                {this.renderReportNoteTemplateModal()}
                {this.renderAssetModal()}
                {this.renderRecommendationTemplateModal()}
                {this.renderYearRemoveConfirmationModal()}
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = state => {
    const { projectReducer, buildingReducer, recommendationsReducer, commonReducer, userReducer, assetReducer } = state;
    return { projectReducer, buildingReducer, recommendationsReducer, commonReducer, userReducer, assetReducer };
};

export default withRouter(
    connect(mapStateToProps, { ...recommendationsActions, ...buildingActions, ...userActions, ...projectActions, ...assetActions })(
        RecommendationForm
    )
);

const assetFormInitialState = {
    code: "",
    asset_name: "",
    asset_tag: "",
    asset_note: "",
    client_asset_condition_id: "",
    installed_year: "",
    service_life: "",
    usefull_life_remaining: "",
    crv: "",
    manufacturer_id: "",
    year_manufactured: "",
    model_number: "",
    serial_number: "",
    capacity: "",
    criticality: "",
    area_served: "",

    trade_id: "",
    system_id: "",
    sub_system_id: "",

    client_id: "",
    region_id: "",
    site_id: "",
    building_id: "",
    addition_id: "",
    floor_id: "",
    room_number: "",
    room_name: "",
    location: "",
    architectural_room_number: "",
    additional_room_description: "",

    uniformat_level_1_id: "",
    uniformat_level_2_id: "",
    uniformat_level_3_id: "",
    uniformat_level_4_id: "",
    uniformat_level_5_id: "",

    asset_type_id: "",
    asset_description: "",
    asset_barcode: "",
    asset_client_id: "",
    asset_cmms_id: "",

    warranty_start: "",
    warranty_end: "",
    install_date: "",
    startup_date: "",
    upstream_asset_barcode_number: "",
    linked_asset_barcode_number: "",
    source_panel_barcode_number: "",
    source_panel: "",
    asset_status_id: "",
    notes: "",

    main_category_id: "",
    sub_category_1_id: "",
    sub_category_2_id: "",
    sub_category_3_id: "",
    guid: "",
    source_panel_name: "",
    skysite_hyperlink: "",

    quantity: "",
    rtls_tag: "",
    latitude: "",
    longitude: "",
    current_age: "",
    age: "",
    new_asset: "",
    parent_global_id: "",
    survey_global_id: "",
    survey_id: "",
    survey_property_note: "",
    capacity_status: "",
    installed_year_status: "",
    name_plate_status: "",
    qa_notes: "",
    additional_qa_notes: "",
    surveyor: "",
    editor: "",
    survey_date_created: "",
    survey_date_edited: "",
    description: "",
    uniformat_level_6_id: "",
    uniformat_level_6_description: "",
    subcategory2_description: "",
    capacity_unit: "",
    refrigerant: "",
    asset_additional: {},
    auxiliary: {}
};
