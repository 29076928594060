import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import actions from "../actions";
import BuildModalHeader from "../../common/components/BuildModalHeader";
import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";
class RecommendationTemplateModal extends Component {
    state = {
        recommendationTemplateList: [],
        totalCount: 0,
        selectedTemplates: {},
        searchKey: "",
        tempList: [],
        sortOrder: "asc"
    };

    componentDidMount = async () => {
        const {
            location: { search },
            sub_system_id
        } = this.props;
        let params = {};
        let dynamicUrl = `/sub_systems/${sub_system_id}/recommendation_templates`;
        let recommendationTemplateList = [];
        let totalCount = 0;
        await this.props.getRecommendationTemplates(params, dynamicUrl);
        recommendationTemplateList = this.props.recommendationsReducer.getRecommendationTemplatesResponse
            ? this.props.recommendationsReducer.getRecommendationTemplatesResponse.project_sub_system_recommendation_templates || []
            : [];
        totalCount = this.props.recommendationsReducer.getRecommendationTemplatesResponse
            ? this.props.recommendationsReducer.getRecommendationTemplatesResponse.count || 0
            : 0;
        await this.setState({
            recommendationTemplateList,
            tempList: recommendationTemplateList,
            totalCount
        });
    };

    setTextBandData = async (event, template) => {
        const { selectedTemplates } = this.state;
        await this.setState({
            selectedTemplates: template.id === selectedTemplates.id ? {} : template
        });
    };

    processSelectedTemplates = () => {
        const { selectedTemplates } = this.state;
        this.props.onOk(selectedTemplates);
        this.props.onCancel();
    };

    handleSearch = e => {
        const keyword = e.target.value;
        if (keyword !== "" && keyword?.trim().length) {
            const results = this.state.recommendationTemplateList.filter(item => {
                return (
                    item?.name?.toString().toLowerCase().includes(keyword.toLowerCase()) ||
                    item?.text_format?.toString().toLowerCase().includes(keyword.toLowerCase()) ||
                    item?.description?.toString().toLowerCase().includes(keyword.toLowerCase()) ||
                    item?.unit?.toString().toLowerCase().includes(keyword.toLowerCase()) ||
                    item?.cost_per_unit?.toString().toLowerCase().includes(keyword.toLowerCase())
                );
            });
            this.setState({ tempList: results });
        } else {
            this.setState({ tempList: this.state.recommendationTemplateList });
        }

        this.setState({ searchKey: keyword });
        this.debouncedChange();
    };

    debouncedChange = debounce(() => {
        ReactTooltip.rebuild();
    }, 1000);

    render() {
        const { onCancel } = this.props;
        const { tempList, searchKey, sortOrder, selectedTemplates } = this.state;
        let sortedList = [];
        if (sortOrder === "asc") {
            sortedList = tempList.sort((a, b) => (a.text_format.toLowerCase() > b.text_format.toLowerCase() ? 1 : -1));
        } else {
            sortedList = tempList.sort((a, b) => (a.text_format.toLowerCase() > b.text_format.toLowerCase() ? -1 : 1));
        }
        return (
            <React.Fragment>
                <div
                    className="modal select-template-modal slt-img-modl"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog select-rec-temp-modal" role="document">
                        <div className="modal-content">
                            <BuildModalHeader title="Select Template" onCancel={() => this.props.onCancel()} modalClass="select-rec-temp-modal" />
                            <div className="modal-body region-otr build-type-mod">
                                <div className="otr-templ-search">
                                    <div className="formInp search">
                                        <i className="fas fa-search" />
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={this.handleSearch}
                                            value={searchKey}
                                            placeholder="Search Now"
                                        />
                                    </div>
                                    <div className="sort">
                                        <select
                                            className="form-control"
                                            value={sortOrder}
                                            onChange={e => this.setState({ sortOrder: e.target.value })}
                                        >
                                            <option value="asc">ASC</option>
                                            <option value="desc">DESC</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="table-section table-scroll overflow-hght table-no-fixed">
                                    <table className="table table-common table-min-height sticky-table-otr">
                                        <thead>
                                            <tr>
                                                <th className="img-sq-box seting-type checkbox-container">
                                                    <img alt="" src="/img/sq-box.png" />
                                                </th>
                                                {tableConfig.keys.map(item => (
                                                    <th key={item} className={tableConfig.config[item].class}>
                                                        {tableConfig.config[item].label}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedList.length ? (
                                                sortedList.map((item, i) => (
                                                    <>
                                                        <tr key={item.id}>
                                                            <td className="img-sq-box seting-type checkbox-container">
                                                                <label class="container-checkbox cursor-hand m-0">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedTemplates.id === item.id}
                                                                        onChange={e => this.setTextBandData(e, item)}
                                                                    />
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </td>
                                                            {tableConfig.keys.map(key => (
                                                                <td key={key} data-tip={item[key]} data-for={`recom-template-table-row${item.id}`}>
                                                                    <span className="text-overflow">
                                                                        {item[key]
                                                                            ? tableConfig.config[key].type === "amount"
                                                                                ? `$${item.cost_per_unit}`
                                                                                : tableConfig.config[key].type === "object"
                                                                                ? tableConfig.config[key]?.hasDisplayName
                                                                                    ? item[key]?.display_name || "-"
                                                                                    : item[key]?.name || "-"
                                                                                : item[key]
                                                                            : "-"}
                                                                    </span>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <ReactTooltip
                                                            id={`recom-template-table-row${item.id}`}
                                                            effect="solid"
                                                            place="bottom"
                                                            backgroundColor="#007bff"
                                                            // className="rc-tooltip-custom-class"
                                                            clickable={true}
                                                            html={true}
                                                            multiline={true}
                                                            getContent={dataTip => dataTip?.replace(/(?:\r\n|\r|\n)/g, "<br>")}
                                                        />
                                                    </>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={5} className="text-center">
                                                        No Templates Found !!
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="btnOtr mt-3 text-right d-flex">
                                    <div>Count : {sortedList.length}</div>
                                    <button type="button" className="btn btn-secondary btnClr ml-auto" onClick={() => onCancel()}>
                                        Cancel
                                    </button>
                                    {tempList.length ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary btnRgion"
                                            disabled={!selectedTemplates.id}
                                            onClick={() => this.processSelectedTemplates()}
                                        >
                                            Add
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { recommendationsReducer } = state;
    return { recommendationsReducer };
};

export default withRouter(
    connect(mapStateToProps, {
        ...actions
    })(RecommendationTemplateModal)
);

const tableConfig = {
    keys: [
        "text_format",
        "recommendation_type",
        "criticality",
        "condition",
        "category",
        "capital_type",
        "funding_source",
        "responsibility",
        "cost_per_unit",
        "unit",
        "avg_useful_life",
        "uniformat_code",
        "uniformat_description",
        "masterformat_code",
        "action",
        "description",
        "template_description"
    ],
    config: {
        text_format: {
            isVisible: true,
            label: "Recommendation",
            class: "",
            type: "string"
        },
        recommendation_type: {
            isVisible: true,
            label: "Recommendation Type",
            class: "",
            type: "string"
        },
        criticality: {
            isVisible: true,
            label: "Criticality",
            class: "",
            type: "object"
        },
        condition: {
            isVisible: true,
            label: "Condition",
            class: "",
            type: "object"
        },
        category: {
            isVisible: true,
            label: "Category",
            class: "",
            type: "object"
        },
        capital_type: {
            isVisible: true,
            label: "Capital Type",
            hasDisplayName: true,
            class: "",
            type: "object"
        },
        funding_source: {
            isVisible: true,
            label: "Funding Source",
            class: "",
            type: "object"
        },
        responsibility: {
            isVisible: true,
            label: "Responsibility",
            class: "",
            type: "object"
        },
        cost_per_unit: {
            isVisible: true,
            label: "Cost Per Unit",
            class: "width-140px",
            type: "amount"
        },
        unit: {
            isVisible: true,
            label: "Unit",
            class: "width-140px",
            type: "string"
        },
        description: {
            isVisible: true,
            label: "Recommendation Narrative",
            class: "",
            type: "string"
        },
        avg_useful_life: {
            isVisible: true,
            label: "Average Useful Life Years",
            class: "width-140px",
            type: "string"
        },
        uniformat_code: {
            isVisible: true,
            label: "Uniformat Code",
            class: "width-140px",
            type: "string"
        },
        uniformat_description: {
            isVisible: true,
            label: "Uniformat Description",
            class: "",
            type: "string"
        },
        masterformat_code: {
            isVisible: true,
            label: "Masterformat Code",
            class: "",
            type: "string"
        },
        action: {
            isVisible: true,
            label: "Action",
            class: "width-140px",
            type: "string"
        },
        template_description: {
            isVisible: true,
            label: "Description",
            class: "width-220px",
            type: "string"
        }
    }
};
