import React, { useEffect, useState } from "react";
import userActions from "../../users/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/components/Loader";
import dashboardActions from "../../dashboard/actions";
import { showAlert } from "../../../config/utils";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import Portal from "../../common/components/Portal";
import { useHistory, useLocation } from "react-router-dom";
import imageActions from "../../images/actions";
export const DefaultProjectModal = props => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { getUserByIdResponse, getAllProjectsDropdownResponse, updateUserResponse } = useSelector(state => state.userReducer);
    const [isLoading, setLoading] = useState(true);
    const [defaultProject, setDefaultProject] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const userId = localStorage.getItem("userId");
    const [projects, setProjects] = useState([]);
    const [alertMessage, setAlertMessage] = useState("");
    const [showRedirectConfirmation, setShowRedirectConfirmation] = useState(false);
    useEffect(() => {
        setAlertMessage("");
        dispatch(userActions.getAllProjectsDropdown());
        dispatch(userActions.getUserById(userId));
    }, []);

    useEffect(() => {
        if (getUserByIdResponse?.success) {
            setDefaultProject(getUserByIdResponse?.default_project?.id);
            setLoading(false);
        }
    }, [getUserByIdResponse]);

    useEffect(() => {
        if (getAllProjectsDropdownResponse?.projects) {
            setProjects(getAllProjectsDropdownResponse?.projects);
        }
    }, [getAllProjectsDropdownResponse]);

    const cancel = async () => {
        setErrorMessage("");
        props.onCancel();
    };
    useEffect(() => {
        if (alertMessage) {
            showAlert(alertMessage);
            setAlertMessage("");
        }
    }, [alertMessage]);

    useEffect(() => {
        if (updateUserResponse) {
            setSubmitting(false);
            const { error, success, message } = updateUserResponse;
            if (success) {
                localStorage.setItem("default_project", defaultProject);
                dispatch(dashboardActions.updateDefaultProject(defaultProject));
                dispatch(imageActions.resetImageEntityParams());
                setAlertMessage("Default project updated successfully");
                setTimeout(() => {
                    cancel();
                    location.pathname !== "/dashboard" && history.push("/dashboard");
                }, 500);
            } else {
                setAlertMessage(error || "Failed to update default project");
            }
        }
        return () => {
            dispatch(userActions.clearUpdateUserResponse());
        };
    }, [updateUserResponse]);

    const validate = () => {
        setErrorMessage("");
        if (!defaultProject?.trim()?.length) {
            setErrorMessage("* Default project required");
            return false;
        }

        return true;
    };

    const handleSubmit = () => {
        if (validate()) {
            location.pathname !== "/dashboard" ? setShowRedirectConfirmation(true) : updateDefaultProject();
        }
    };

    const updateDefaultProject = () => {
        setSubmitting(true);
        dispatch(userActions.updateUser({ default_project_id: defaultProject }, userId));
    };

    const renderRedirectConfirmation = () => {
        if (!showRedirectConfirmation) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={"Changing the default project will redirect you to the dashboard"}
                        message={"Are you sure that you need to update default project?"}
                        onNo={() => setShowRedirectConfirmation(false)}
                        onYes={() => {
                            setShowRedirectConfirmation(false);
                            updateDefaultProject();
                        }}
                    />
                }
                onCancel={() => setShowRedirectConfirmation(false)}
            />
        );
    };
    return (
        <React.Fragment>
            {renderRedirectConfirmation()}
            {isLoading ? (
                <Loader />
            ) : (
                <div
                    className="modal modal-region rst-pwd"
                    style={{ display: "block" }}
                    id="modalId"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div class="modal-dialog " role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                    Default Project
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => props.onCancel()}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div class="modal-body region-otr build-type-mod">
                                <form autoComplete="nope">
                                    <div class="clr-list ">
                                        <div className="col-md-12 mb-4 p-0 ">
                                            <div className="codeOtr">
                                                <h4>Default Project</h4>
                                                <div className="custom-selecbox">
                                                    <select
                                                        autoComplete="nope"
                                                        className={`custom-selecbox form-control ${errorMessage ? "error-border" : ""}`}
                                                        onChange={async e => {
                                                            setDefaultProject(e.target.value);
                                                        }}
                                                        value={defaultProject}
                                                    >
                                                        <option value="">Select</option>
                                                        {projects?.length
                                                            ? projects.map((item, i) => (
                                                                  <option value={item.id} key={i}>
                                                                      {item.name}
                                                                  </option>
                                                              ))
                                                            : null}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-danger mb-2">{errorMessage}</div>
                                    </div>
                                </form>
                                <div class="col-md-12 text-center btnOtr d-flex p-0">
                                    <button type="button" class="btn btn-secondary btnClr col" onClick={() => cancel()} data-dismiss="modal">
                                        Cancel
                                    </button>
                                    <button type="button" class="btn btn-primary btnRgion col" onClick={() => handleSubmit()}>
                                        Update {isSubmitting && <span className="spinner-border spinner-border-sm pl-2" role="status"></span>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
