import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Draggable from "react-draggable";

import actions from "../actions";

const DocumentUploadModal = ({ uploadDocs, selectedDoc = null, defaultClient, editUserDoc, tabName, ...props }) => {
    const [formParams, setFormParams] = useState({
        name: "",
        file: null,
        client_id: defaultClient,
        notes: "",
        is_global_doc: "no"
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorBorder, setShowErrorBorder] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [attachmentChanged, setAttachmentChanged] = useState(false);
    const [templateData, setTemplateData] = useState(null);

    useEffect(() => {
        props.getClientDropDownData();
        if (selectedDoc) {
            setFormParams({
                name: selectedDoc.name || selectedDoc?.gallery_image?.caption || "",
                notes: selectedDoc.notes,
                file: selectedDoc.s3_file
            });
        }
    }, []);

    const handleFileChange = e => {
        let selectedFile = e?.target?.files?.[0];
        if (selectedFile) {
            if (selectedFile.size < 25000000) {
                setErrorMessage("");
                setFormParams(prevParams => {
                    return {
                        ...prevParams,
                        file: selectedFile
                    };
                });
            } else {
                setErrorMessage("File is too big. Files with size greater than 25MB is not allowed.");
            }
        }
    };

    const validate = () => {
        setShowErrorBorder(false);
        if (!formParams.name && !formParams.name.trim().length) {
            setShowErrorBorder(true);
            return false;
        }
        if (!selectedDoc && formParams.is_global_doc === "no" && !formParams.client_id && !formParams.client_id.trim().length) {
            setShowErrorBorder(true);
            return false;
        }
        if (!selectedDoc && !formParams.file) {
            setShowErrorBorder(true);
            return false;
        }
        return true;
    };

    const uploadDocument = async () => {
        const { name, file, client_id, notes } = formParams;
        if (validate()) {
            if (selectedDoc) {
                let params = { name, notes };
                if (templateData) params = { ...params, file: templateData };
                setIsUploading(true);
                await editUserDoc(selectedDoc.id, params);
                setIsUploading(false);
                props.onCancel();
            } else {
                let uploadParams = {
                    name,
                    file,
                    notes
                };
                if (formParams.is_global_doc === "yes") {
                    uploadParams.doc_type = "GLB";
                } else {
                    uploadParams.client_id = client_id;
                }
                setIsUploading(true);
                await uploadDocs({ ...uploadParams });
                setIsUploading(false);
                props.onCancel();
            }
        }
    };

    const handleAddAttachment = e => {
        if (e?.target?.files) {
            Object.values(e.target.files).map((attachment, i) => {
                let ext = attachment.name.split(".").pop();
                const acceptableExt = ["docx"];
                if (acceptableExt.includes(ext.toLowerCase())) {
                    if (attachment.size < 25000000) {
                        setAttachmentChanged(true);
                        setTemplateData(e.target.files[0]);
                        setUploadError(null);
                    } else {
                        setUploadError("File is too big. Files with size greater than 25MB is not allowed.");
                    }
                } else {
                    setUploadError("Accept only docx file format!");
                }
            });
        }
    };

    let clientList = props?.smartChartReducer?.getClientDropDownDataResponse?.data || [];

    let file_name = selectedDoc && formParams?.file?.split("/");
    file_name = file_name ? file_name[file_name?.length - 1] : "";

    return (
        <React.Fragment>
            <div className="modal modal-region modal-view inbox-modal add-new-template" id="modalId" tabIndex="-1" style={{ display: "block" }}>
                <Draggable handle=".draggable" cancel=".non-draggable">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    <div className="txt-hed">{selectedDoc ? `Edit ${tabName}` : `Upload ${tabName}`}</div>
                                </h5>
                                <button type="button" className="close non-draggable" onClick={props.onCancel}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body build-type-mod region-otr sm-chart-upload">
                                <div className="upload-area not-draggable">
                                    <div className="upload-sec cursor-hand" role="button" tabIndex="0">
                                        <input type="file" multiple="" autocomplete="off" tabIndex="-1" style={{ display: "none" }} />
                                    </div>
                                </div>
                                <div className="col-md-12 main-sec">
                                    <div className="form-row">
                                        {selectedDoc && (
                                            <div className="formInp">
                                                <label className={`drag-otr cursor-pointer drg-pos-doc-otr `} params htmlFor="attachmentFiles">
                                                    {templateData || file_name ? (
                                                        <>
                                                            <img src="/img/docIcon.webp" />
                                                            <p>{templateData?.name || file_name || ""}</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <i className={`fas fa-cloud-upload-alt`}></i>
                                                            <p>Click to upload</p>
                                                        </>
                                                    )}
                                                </label>
                                                <div className="text-center">
                                                    <small className="text-danger">{uploadError}</small>
                                                </div>
                                                <div className="col-md-12 upldFile btnAddCam p-0">
                                                    <input
                                                        type="file"
                                                        accept=".docx"
                                                        className="form-control"
                                                        id="attachmentFiles"
                                                        name="profilePic"
                                                        onChange={handleAddAttachment}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="form-group col-12 mb-4">
                                            <label>{tabName === "Image" ? "Caption" : "Name"}</label>
                                            <input
                                                type="text"
                                                value={formParams.name}
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    setFormParams(prevParams => {
                                                        return {
                                                            ...prevParams,
                                                            name: value
                                                        };
                                                    });
                                                }}
                                                className={`form-control ${
                                                    showErrorBorder && !formParams.name && !formParams.name.trim().length ? "error-border" : ""
                                                }`}
                                            />
                                        </div>
                                    </div>
                                    {!selectedDoc ? (
                                        <div className="form-row">
                                            <div className="form-group col-12 mb-4">
                                                <label>Is Global Document</label>
                                                <select
                                                    className={`form-control dropdown export-prop-select`}
                                                    value={formParams.is_global_doc}
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        setFormParams(prevParams => {
                                                            return {
                                                                ...prevParams,
                                                                is_global_doc: value
                                                            };
                                                        });
                                                    }}
                                                >
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </div>
                                        </div>
                                    ) : null}
                                    {!selectedDoc && formParams.is_global_doc === "no" ? (
                                        <div className="form-row">
                                            <div className="form-group col-12 mb-4">
                                                <label>Client</label>
                                                <select
                                                    className={`form-control dropdown export-prop-select ${
                                                        showErrorBorder && !formParams.client_id && !formParams.client_id.trim().length
                                                            ? "error-border"
                                                            : ""
                                                    }`}
                                                    value={formParams.client_id}
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        setFormParams(prevParams => {
                                                            return {
                                                                ...prevParams,
                                                                client_id: value
                                                            };
                                                        });
                                                    }}
                                                >
                                                    <option value="">Select</option>
                                                    {clientList.map(p => (
                                                        <option key={p.id} value={p.id}>
                                                            {p.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="form-row">
                                        <div className="form-group col-12 mb-4">
                                            <label>Notes</label>
                                            <textarea
                                                className={`form-control`}
                                                value={formParams.notes}
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    setFormParams(prevParams => {
                                                        return {
                                                            ...prevParams,
                                                            notes: value
                                                        };
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {!selectedDoc ? (
                                        <div class="drag-otr col-md-12">
                                            <p>Select file</p>
                                            <input
                                                type="file"
                                                multiple
                                                class="custome-file-input cursor-hand"
                                                id="customFile"
                                                onChange={handleFileChange}
                                                accept={tabName === "Image" ? "image/*" : ".docx"}
                                            />
                                            <span class="show-btn">Browse</span>
                                        </div>
                                    ) : null}
                                    {formParams.file ? (
                                        <div className="col-md-12 pl-0 mb-3 mt-3 pr-0">
                                            <div className="upload-sec">
                                                <div className="form-group uplod-sec-fld mb-2">
                                                    <div className="upload-files-nme mt-0">
                                                        <span className="badge-nme">
                                                            <label>{formParams.file.name} </label>
                                                            <i
                                                                className="material-icons close-icon"
                                                                onClick={e => {
                                                                    e.stopPropagation();

                                                                    setFormParams(prevParams => {
                                                                        return {
                                                                            ...prevParams,
                                                                            file: null
                                                                        };
                                                                    });
                                                                }}
                                                            >
                                                                {/* <img src="/img/close-icn-white.svg" /> */}
                                                            </i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {errorMessage ? (
                                        <div className="col-md-12 p-0 pb-4 text-right text-danger">
                                            <small>{`* ${errorMessage}`}</small>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="btn-sec">
                                    <div className="text-right btnOtr edit-cmn-btn">
                                        <button
                                            type="button"
                                            className="btn btn-primary btnRgion "
                                            class="btn btn-create save"
                                            onClick={() => uploadDocument()}
                                        >
                                            {selectedDoc ? "Update" : "Upload"}
                                            {isUploading ? <span className="spinner-border spinner-border-sm pl-2 ml-2" role="status"></span> : ""}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btnClr mr-1"
                                            data-dismiss="modal"
                                            onClick={() => props.onCancel()}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { smartChartReducer } = state;
    return { smartChartReducer };
};

const { uploadDocsForSmartReport, getClientDropDownData } = actions;

export default connect(mapStateToProps, { uploadDocsForSmartReport, getClientDropDownData })(DocumentUploadModal);
