import { fcaCRGateWay, fcaGateWay, fcaReportCRGateway, fcaReportGateway } from "../../services/authorizationService";
import * as serviceEndpoints from "../../config/serviceEndPoints";

export const addRequest = params => fcaCRGateWay.post(`${serviceEndpoints.capitalRequestEndpoints.capitalRequest}`, params);
export const updateRequest = (id, params) => fcaCRGateWay.put(`${serviceEndpoints.capitalRequestEndpoints.capitalRequest}/${id}`, params);
export const getCapitalRequestData = params => fcaReportGateway.get(`${serviceEndpoints.capitalRequestEndpoints.getCapitalRequestData}`, { params });
export const getCapitalRequestDataById = (id, params) =>
    fcaReportGateway.get(`${serviceEndpoints.capitalRequestEndpoints.getCapitalRequestData}${id}/`, { params });
export const getListForCapitalRequestFilter = (key, params) =>
    fcaReportGateway.get(`${serviceEndpoints.capitalRequestEndpoints.getListForCapitalRequestFilter}${key}/`, {
        params
    });
export const exportCapitalRequest = params =>
    fcaReportGateway.get(`${serviceEndpoints.capitalRequestEndpoints.exportCapitalRequest}`, { responseType: "blob", method: "GET", params });
export const submitEmail = params => fcaGateWay.post(`${serviceEndpoints.capitalRequestEndpoints.emailRegistration}`, params);
export const lockCapitalRequest = (id, params) => fcaCRGateWay.patch(`${serviceEndpoints.capitalRequestEndpoints.capitalRequest}/${id}/lock`, params);
export const verifyEmail = params => fcaGateWay.post(`${serviceEndpoints.capitalRequestEndpoints.verifyEmail}`, params);
export const deleteCapitalRequest = (id, params) =>
    fcaCRGateWay.delete(`${serviceEndpoints.capitalRequestEndpoints.capitalRequest}/${id}`, { params });
export const restoreCapitalRequest = id => fcaCRGateWay.post(`${serviceEndpoints.capitalRequestEndpoints.capitalRequest}/${id}/restore`);
export const exportCapitalRequestReportPdf = params =>
    fcaReportGateway.post(`${serviceEndpoints.capitalRequestEndpoints.getPdfforReport}`, params, { method: "POST", responseType: "blob" });
export const exportCapitalRequestReportDocx = params =>
    fcaReportGateway.post(`${serviceEndpoints.capitalRequestEndpoints.getDocxforReport}`, params, { method: "POST", responseType: "blob" });
export const linkCapitalRequestToRecommendation = params =>
    fcaGateWay.post(`${serviceEndpoints.capitalRequestEndpoints.linkCapitalRequestToRecommendation}`, params);
export const removeCapitalRequestLinkToRecommendation = params =>
    fcaGateWay.post(`${serviceEndpoints.capitalRequestEndpoints.removeCapitalRequestLinkToRecommendation}`, params);

export const getAnonCapitalRequestData = params =>
    fcaReportCRGateway.get(`${serviceEndpoints.anonCapitalRequestEndpoints.getCapitalRequestData}`, { params });
export const getAnonCapitalRequestDataById = (id, params) =>
    fcaReportCRGateway.get(`${serviceEndpoints.anonCapitalRequestEndpoints.getCapitalRequestData}${id}/`, { params });
export const getListForAnonCapitalRequestFilter = (key, params) =>
    fcaReportCRGateway.get(`${serviceEndpoints.anonCapitalRequestEndpoints.getListForCapitalRequestFilter}${key}/`, {
        params
    });
export const exportAnonCapitalRequestReportPdf = params =>
    fcaReportCRGateway.post(`${serviceEndpoints.anonCapitalRequestEndpoints.getPdfforReport}`, params, { method: "POST", responseType: "blob" });
export const exportAnonCapitalRequestReportDocx = params =>
    fcaReportCRGateway.post(`${serviceEndpoints.anonCapitalRequestEndpoints.getDocxforReport}`, params, { method: "POST", responseType: "blob" });
export const exportAnonCapitalRequest = params =>
    fcaReportCRGateway.get(`${serviceEndpoints.anonCapitalRequestEndpoints.exportCapitalRequest}`, { responseType: "blob", method: "GET", params });
export const deleteCRToken = () => fcaCRGateWay.delete(`${serviceEndpoints.capitalRequestEndpoints.deleteCrToken}`);
