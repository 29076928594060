export const GET_SOFT_COSTS_REQUEST = "GET_SOFT_COSTS_REQUEST";
export const GET_SOFT_COSTS_SUCCESS = "GET_SOFT_COSTS_SUCCESS";
export const GET_SOFT_COSTS_FAILURE = "GET_SOFT_COSTS_FAILURE";

export const SAVE_SOFT_COSTS_REQUEST = "SAVE_SOFT_COSTS_REQUEST";
export const SAVE_SOFT_COSTS_SUCCESS = "SAVE_SOFT_COSTS_SUCCESS";
export const SAVE_SOFT_COSTS_FAILURE = "SAVE_SOFT_COSTS_FAILURE";

export const EXPORT_EXCEL_REQUEST = "EXPORT_EXCEL_REQUEST";
export const EXPORT_EXCEL_SUCCESS = "EXPORT_EXCEL_SUCCESS";
export const EXPORT_EXCEL_FAILURE = "EXPORT_EXCEL_FAILURE";
