// Get all floors
export const GET_FLOORS_BASED_ON_BUILDING_REQUEST = "GET_FLOORS_BASED_ON_BUILDING_REQUEST";
export const GET_FLOORS_BASED_ON_BUILDING_SUCCESS = "GET_FLOORS_BASED_ON_BUILDING_SUCCESS";
export const GET_FLOORS_BASED_ON_BUILDING_FAILURE = "GET_FLOORS_BASED_ON_BUILDING_FAILURE";

// Add floor
export const ADD_FLOOR_REQUEST = "ADD_FLOOR_REQUEST";
export const ADD_FLOOR_SUCCESS = "ADD_FLOOR_SUCCESS";
export const ADD_FLOOR_FAILURE = "ADD_FLOOR_FAILURE";

// Update floor
export const UPDATE_FLOOR_REQUEST = "UPDATE_FLOOR_REQUEST";
export const UPDATE_FLOOR_SUCCESS = "UPDATE_FLOOR_SUCCESS";
export const UPDATE_FLOOR_FAILURE = "UPDATE_FLOOR_FAILURE";

// Delete floor
export const DELETE_FLOOR_REQUEST = "DELETE_FLOOR_REQUEST";
export const DELETE_FLOOR_SUCCESS = "DELETE_FLOOR_SUCCESS";
export const DELETE_FLOOR_FAILURE = "DELETE_FLOOR_FAILURE";

// Get Floor by Id
export const GET_FLOOR_BY_ID_REQUEST = "GET_FLOOR_BY_ID_REQUEST";
export const GET_FLOOR_BY_ID_SUCCESS = "GET_FLOOR_BY_ID_SUCCESS";
export const GET_FLOOR_BY_ID_FAILURE = "GET_FLOOR_BY_ID_FAILURE";

// Update Floor Entity Param
export const UPDATE_FLOOR_ENTITY_PARAMS_SUCCESS = "UPDATE_FLOOR_ENTITY_PARAMS_SUCCESS";
export const UPDATE_FLOOR_ENTITY_PARAMS_FAILURE = "UPDATE_FLOOR_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_FLOOR_LOG_REQUEST = "GET_ALL_FLOOR_LOG_REQUEST";
export const GET_ALL_FLOOR_LOG_SUCCESS = "GET_ALL_FLOOR_LOG_SUCCESS";
export const GET_ALL_FLOOR_LOG_FAILURE = "GET_ALL_FLOOR_LOG_FAILURE";

export const RESTORE_FLOOR_LOG_REQUEST = "RESTORE_FLOOR_LOG_REQUEST";
export const RESTORE_FLOOR_LOG_SUCCESS = "RESTORE_FLOOR_LOG_SUCCESS";
export const RESTORE_FLOOR_LOG_FAILURE = "RESTORE_FLOOR_LOG_FAILURE";

export const DELETE_FLOOR_LOG_REQUEST = "DELETE_FLOOR_LOG_REQUEST";
export const DELETE_FLOOR_LOG_SUCCESS = "DELETE_FLOOR_LOG_SUCCESS";
export const DELETE_FLOOR_LOG_FAILURE = "DELETE_FLOOR_LOG_FAILURE";

export const GET_FLOOR_EXPORT_REQUEST = "GET_FLOOR_EXPORT_REQUEST";
export const GET_FLOOR_EXPORT_SUCCESS = "GET_FLOOR_EXPORT_SUCCESS";
export const GET_FLOOR_EXPORT_FAILURE = "GET_FLOOR_EXPORT_FAILURE";

export const GET_ALL_CLIENT_USERS_REQUEST = "GET_ALL_CLIENT_USERS_REQUEST";
export const GET_ALL_CLIENT_USERS_SUCCESS = "GET_ALL_CLIENT_USERS_SUCCESS";
export const GET_ALL_CLIENT_USERS_FAILURE = "GET_ALL_CLIENT_USERS_FAILURE";


// Get all consultancy users
export const GET_ALL_CONSULTANCY_USERS_REQUEST = "GET_ALL_CONSULTANCY_USERS_REQUEST";
export const GET_ALL_CONSULTANCY_USERS_SUCCESS = "GET_ALL_CONSULTANCY_USERS_SUCCESS";
export const GET_ALL_CONSULTANCY_USERS_FAILURE = "GET_ALL_CONSULTANCY_USERS_FAILURE";


export const GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST = "GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST";
export const GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS = "GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS";
export const GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE = "GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE";


// Get all Clients
export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const GET_ALL_CLIENTS_FAILURE = "GET_ALL_CLIENTS_FAILURE";




export const GET_ALL_BUILDINGS_DROP_DOWN_REQUEST = "GET_ALL_BUILDINGS_DROP_DOWN_REQUEST";
export const GET_ALL_BUILDINGS_DROP_DOWN_SUCCESS = "GET_ALL_BUILDINGS_DROP_DOWN_SUCCESS";
export const GET_ALL_BUILDINGS_DROP_DOWN_FAILURE = "GET_ALL_BUILDINGS_DROP_DOWN_FAILURE";