import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import _ from "lodash";
import Loader from "./Loader";

class SummaryRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            errorMessage: "",
            region: props.region,
            summaryRow: {}
        };
    }

    setSummaryRowData = () => {
        const { tableData, keys, config, summaryRowData } = this.props;
        if (_.isEmpty(summaryRowData)) return null;
        let temSummaryRow = {};

        tableData.data.map(rowData => {
            keys.map(keyItem => {
                // if ((!isNaN(parseInt(rowData[keyItem])) && config && config[keyItem]?.type === "number") || keyItem == "area") {
                if ((config && config[keyItem]?.type === "number") || keyItem == "area") {
                    if (keyItem === "crv") {
                        temSummaryRow[keyItem] =
                            summaryRowData["crv_total"] === "isLoading" ? "isLoading" : parseFloat(summaryRowData["crv_total"]) || 0;
                    } else if (keyItem === "fca_cost") {
                        temSummaryRow[keyItem] = parseInt(summaryRowData["fca_cost_total"]) || 0;
                    } else if (keyItem === "project_total") {
                        temSummaryRow[keyItem] = summaryRowData[keyItem] === "isLoading" ? "isLoading" : parseInt(summaryRowData[keyItem]) || 0;
                        // } else if (keyItem === "cost") {
                        //     temSummaryRow[keyItem] =
                        //         summaryRowData["cost_total"] === "isLoading" ? "isLoading" : parseFloat(summaryRowData["cost_total"]) || 0;
                    } else if (keyItem === "area" || keyItem === "sf" || keyItem === "total_sf") {
                        temSummaryRow[keyItem] = summaryRowData["total_sf"] === "isLoading" ? "isLoading" : parseInt(summaryRowData["total_sf"]) || 0;
                    } else if (keyItem === "request_estimate") {
                        temSummaryRow[keyItem] =
                            summaryRowData["request_estimate"] === "isLoading" ? "isLoading" : parseInt(summaryRowData["request_estimate"]) || 0;
                    } else if (summaryRowData.hasOwnProperty(`total_${keyItem}`)) {
                        temSummaryRow[keyItem] =
                            summaryRowData[`total_${keyItem}`] === "isLoading" ? "isLoading" : parseInt(summaryRowData[`total_${keyItem}`]) || 0;
                    } else if (
                        keyItem !== "year_manufactured" &&
                        keyItem !== "service_life" &&
                        keyItem !== "usefull_life_remaining" &&
                        keyItem !== "area_served" &&
                        keyItem !== "installed_year" &&
                        keyItem !== "quantity" &&
                        keyItem !== "sort_order" &&
                        keyItem !== "current_age"
                    ) {
                        const yearSum = summaryRowData["year_totals"]?.[`${keyItem?.split("_")?.[1]}`] ?? "";
                        temSummaryRow[keyItem] = yearSum === "isLoading" ? "isLoading" : parseInt(yearSum);
                    }
                }
            });
        });
        return temSummaryRow;
    };

    render() {
        const { keys, config } = this.props;

        let summaryRow = this.setSummaryRowData();
        return (
            <React.Fragment>
                {!_.isEmpty(summaryRow) ? (
                    <tr>
                        <td className="text-center tot-recom column-sticky"></td>
                        {keys.map((keyItem, i) => {
                            return config[keyItem] && config[keyItem].isVisible ? (
                                <td
                                    key={i}
                                    style={{ ...config[keyItem]?.style }}
                                    className={`${config[keyItem].class} tot-recom ${config[keyItem].pinned ? "pinned" : ""}`}
                                >
                                    <span className="summary-row-column">
                                        {summaryRow[keyItem] !== "isLoading" ? (
                                            <NumberFormat
                                                prefix={["area", "sf", "total_sf", "recommendations_count"].includes(keyItem) ? "" : "$ "}
                                                value={
                                                    keyItem === "crv" || keyItem === "cost"
                                                        ? parseFloat(summaryRow[keyItem])
                                                        : parseInt(summaryRow[keyItem])
                                                }
                                                thousandSeparator={true}
                                                displayType={"text"}
                                            />
                                        ) : (
                                            <div className="">
                                                <Loader />
                                            </div>
                                        )}
                                    </span>
                                </td>
                            ) : null;
                        })}
                        <td className="bg-white tot-recom"></td>
                    </tr>
                ) : null}
            </React.Fragment>
        );
    }
}

export default withRouter(SummaryRow);
