import React, { useState } from "react";
import Autosuggest from "react-autosuggest";

export const FilterSearch = ({ fields = [], fieldSearch = "", setFieldSearch }) => {
    const [fieldSuggestions, setFieldSuggestions] = useState([]);

    const getSuggestions = value => {
        const inputValue = (value && value?.toString()?.trim()?.toLowerCase()) || "";
        return fields
            .map(field => field?.label)
            .sort()
            .filter(field => field?.toString()?.toLowerCase().includes(inputValue));
    };

    const getSuggestionValue = suggestion => suggestion;

    const renderSuggestion = suggestion => <div>{suggestion}</div>;

    const onSuggestionsFetchRequested = ({ value }) => {
        setFieldSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setFieldSuggestions([]);
    };
    return (
        <>
            <Autosuggest
                suggestions={fieldSuggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                shouldRenderSuggestions={() => true}
                onSuggestionSelected={(e, { suggestion }) => setFieldSearch(suggestion)}
                inputProps={{
                    type: "text",
                    placeholder: "Field Finder...",
                    value: fieldSearch ? fieldSearch?.toString() : "",
                    className: "custom-input form-control",
                    onChange: (e, { newValue }) => {
                        setFieldSearch(newValue);
                    }
                }}
            />
            <i className="fas fa-times cursor-pointer" onClick={() => setFieldSearch("")}></i>
        </>
    );
};
