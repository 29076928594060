export const GET_DOCUMENT_TYPE_REQUEST = "GET_DOCUMENT_TYPE_REQUEST";
export const GET_DOCUMENT_TYPE_SUCCESS = "GET_DOCUMENT_TYPE_SUCCESS";
export const GET_DOCUMENT_TYPE_FAILURE = "GET_DOCUMENT_TYPE_FAILURE";

export const ADD_DOCUMENT_TYPE_REQUEST = "ADD_DOCUMENT_TYPE_REQUEST";
export const ADD_DOCUMENT_TYPE_SUCCESS = "ADD_DOCUMENT_TYPE_SUCCESS";
export const ADD_DOCUMENT_TYPE_FAILURE = "ADD_DOCUMENT_TYPE_FAILURE";

export const GET_DOCUMENT_TYPE_BY_ID_REQUEST = "GET_DOCUMENT_TYPE_BY_ID_REQUEST";
export const GET_DOCUMENT_TYPE_BY_ID_SUCCESS = "GET_DOCUMENT_TYPE_BY_ID_SUCCESS";
export const GET_DOCUMENT_TYPE_BY_ID_FAILURE = "GET_DOCUMENT_TYPE_BY_ID_FAILURE";

export const UPDATE_DOCUMENT_TYPE_REQUEST = "UPDATE_DOCUMENT_TYPE_REQUEST";
export const UPDATE_DOCUMENT_TYPE_SUCCESS = "UPDATE_DOCUMENT_TYPE_SUCCESS";
export const UPDATE_DOCUMENT_TYPE_FAILURE = "UPDATE_DOCUMENT_TYPE_FAILURE";

export const UPDATE_DOCUMENT_TYPE_ENTITY_PARAMS_SUCCESS = "UPDATE_DOCUMENT_TYPE_ENTITY_PARAMS_SUCCESS";
export const UPDATE_DOCUMENT_TYPE_ENTITY_PARAMS_FAILURE = "UPDATE_DOCUMENT_TYPE_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_DOCUMENT_TYPE_LOG_REQUEST = "GET_ALL_DOCUMENT_TYPE_LOG_REQUEST";
export const GET_ALL_DOCUMENT_TYPE_LOG_SUCCESS = "GET_ALL_DOCUMENT_TYPE_LOG_SUCCESS";
export const GET_ALL_DOCUMENT_TYPE_LOG_FAILURE = "GET_ALL_DOCUMENT_TYPE_LOG_FAILURE";

export const RESTORE_DOCUMENT_TYPE_LOG_REQUEST = "RESTORE_DOCUMENT_TYPE_LOG_REQUEST";
export const RESTORE_DOCUMENT_TYPE_LOG_SUCCESS = "RESTORE_DOCUMENT_TYPE_LOG_SUCCESS";
export const RESTORE_DOCUMENT_TYPE_LOG_FAILURE = "RESTORE_DOCUMENT_TYPE_LOG_FAILURE";

export const DELETE_DOCUMENT_TYPE_LOG_REQUEST = "DELETE_DOCUMENT_TYPE_LOG_REQUEST";
export const DELETE_DOCUMENT_TYPE_LOG_SUCCESS = "DELETE_DOCUMENT_TYPE_LOG_SUCCESS";
export const DELETE_DOCUMENT_TYPE_LOG_FAILURE = "DELETE_DOCUMENT_TYPE_LOG_FAILURE";

export const GET_DOCUMENT_TYPE_EXPORT_REQUEST = "GET_DOCUMENT_TYPE_EXPORT_REQUEST";
export const GET_DOCUMENT_TYPE_EXPORT_SUCCESS = "GET_DOCUMENT_TYPE_EXPORT_SUCCESS";
export const GET_DOCUMENT_TYPE_EXPORT_FAILURE = "GET_DOCUMENT_TYPE_EXPORT_FAILURE";

export const DELETE_DOCUMENT_TYPE_REQUEST = "DELETE_DOCUMENT_TYPE_REQUEST";
export const DELETE_DOCUMENT_TYPE_SUCCESS = "DELETE_DOCUMENT_TYPE_SUCCESS";
export const DELETE_DOCUMENT_TYPE_FAILURE = "DELETE_DOCUMENT_TYPE_FAILURE";

export const GET_CLIENT_DROPDOWN_REQUEST = "GET_CLIENT_DROPDOWN_REQUEST";
export const GET_CLIENT_DROPDOWN_SUCCESS = "GET_CLIENT_DROPDOWN_SUCCESS";
export const GET_CLIENT_DROPDOWN_FAILURE = "GET_CLIENT_DROPDOWN_FAILURE";

