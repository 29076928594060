import React, { PureComponent } from "react";
import ReactPaginate from "react-paginate";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import history from "../../../config/history";
import { addToBreadCrumpData } from "../../../config/utils";

import Table from "../../common/components/Table";
import TableTopIcons from "../../common/components/TableTopIcons";
import { AdditionalFilters } from "./AdditionalFilters";

const specialClients = [
    "7b48bdbc-04d9-430e-be15-7293cc9c9b7a",
    "5c0083a4-8f16-4519-b818-8527e9bd8f19",
    "93c650de-313b-4700-8afd-5484f75ae5a2",
    "f8da186c-3f8e-4075-9909-a26fb2704eba"
];

class PropertyMain extends PureComponent {
    state = {
        showMoreFilter: false
    };

    backToDashboard = () => {
        const {
            location: { pathname }
        } = this.props;
        let tempPath = pathname.split("/");
        tempPath[tempPath.length - 1] = "assetcharts";
        tempPath = tempPath.join("/");
        addToBreadCrumpData({
            key: "info",
            name: "Charts & Graphs",
            path: tempPath
        });
        history.push(tempPath);
    };

    toggleMoreFilter = async data => {
        this.setState({ showMoreFilter: !this.state.showMoreFilter });
    };

    isMoreFiltered = () => {
        const { tableParams = {} } = this.props;
        if (tableParams.recommendation_assigned_true || tableParams.guid_assigned || tableParams.has_image) {
            return true;
        }
        return false;
    };

    render() {
        const {
            showWildCardFilter,
            paginationParams,
            currentViewAllUsers,
            showViewModal,
            tableData,
            handleGlobalSearch,
            toggleWildCardFilter,
            updateCurrentViewAllUsers,
            handleDelete,
            showEditPage,
            handlePerPageChange,
            handlePageClick,
            showAddForm,
            showInfoPage,
            updateSelectedRow,
            selectedRowId,
            globalSearchKey,
            updateWildCardFilter,
            wildCardFilter,
            handleHideColumn,
            getListForCommonFilterFloor,
            updateCommonFilter,
            commonFilter,
            resetAllFilters,
            resetAll,
            updateTableSortFilters,
            isColunmVisibleChanged,
            resetSort,
            tableParams,
            permissions,
            showAddButton,
            hasExport,
            hasEdit,
            hasDelete,
            hasInfoPage,
            showRestoreModal,
            entity,
            hasActionColumn,
            isAssignAsset,
            summaryRowData,
            tableRef,
            filterByRecommendationAssigned,
            isAssetsChartsFiltered,
            showUploadDataModal,
            updateLastSortFilter,
            filterByGuid,
            filterByImages,
            openFiltersPopup,
            toggleAppliedFiltersPopup,
            selectedRecomIds,
            clearSelection,
            showSelectedAssets,
            handleEditMultiSelectedData,
            recommendationAssignedCount,
            isAssignView
        } = this.props;
        const {
            match: {
                params: { section, id }
            }
        } = this.props;
        const showImportOption = showAddButton && section === "assetinfo";
        const showSpecialImportOption = showAddButton && section === "assetinfo" && specialClients.includes(id);
        const isEveryItemSelected = selectedRecomIds?.length === paginationParams.totalCount;
        return (
            <React.Fragment>
                <div className={`dtl-sec`}>
                    <div className="table-top-menu d-flex align-items-center allign-right tpr-zm">
                        {showImportOption && (
                            <button
                                className="add-btn upload-button position-relative btn-bl-nw"
                                data-tip="Upload Assets"
                                data-for="asset-table"
                                data-place="bottom"
                                onClick={() => showUploadDataModal("normal")}
                            >
                                <i className="fas fa-upload"></i>
                            </button>
                        )}
                        {showSpecialImportOption && (
                            <button
                                className="add-btn upload-button position-relative btn-bl-nw"
                                data-tip="Upload Assets (New Import Format)"
                                data-for="asset-table"
                                data-place="bottom"
                                onClick={() => showUploadDataModal("special")}
                            >
                                <i className="fas fa-file-import"></i>
                            </button>
                        )}
                        {isAssetsChartsFiltered && !this.props.isSmartChartView && (
                            <div className="back-btnotr">
                                <button className="btn-primary btn-back-chart" onClick={this.backToDashboard}>
                                    <i className="fas fa-backward pr-2"></i>Back To Charts &amp; Graphs
                                </button>
                            </div>
                        )}
                        <div className="rgt justify-content-between">
                            <TableTopIcons
                                tableData={tableData}
                                globalSearchKey={globalSearchKey}
                                handleGlobalSearch={handleGlobalSearch}
                                resetAllFilters={resetAllFilters}
                                resetAll={resetAll}
                                isColunmVisibleChanged={isColunmVisibleChanged}
                                toggleWildCardFilter={toggleWildCardFilter}
                                showViewModal={showViewModal}
                                resetSort={resetSort}
                                tableParams={tableParams}
                                exportTableXl={this.props.exportTableXl}
                                tableLoading={this.props.tableLoading}
                                customExcelExportLoading={this.props.customExcelExportLoading}
                                exportCustomExcel={this.props.exportCustomExcel}
                                isExport={hasExport}
                                entity={entity}
                                showWildCardFilter={showWildCardFilter}
                                hasNewlyCreated={true}
                                hasNewlyEdited={true}
                                updateLastSortFilter={updateLastSortFilter}
                                hasAdditionalFilters={false}
                                additionalFilterTooltip="More filters"
                                toggleMoreFilter={this.toggleMoreFilter}
                                isMoreFiltered={this.isMoreFiltered}
                                openFiltersPopup={openFiltersPopup}
                                toggleAppliedFiltersPopup={toggleAppliedFiltersPopup}
                                children={
                                    <>
                                        {showAddButton && (
                                            <button className="add-btn" onClick={() => showAddForm()}>
                                                <i className="fas fa-plus" /> {`Add New Asset`}
                                            </button>
                                        )}
                                    </>
                                }
                                isSmartChartView={this.props?.isSmartChartView}
                                filterByRecommendationAssigned={filterByRecommendationAssigned}
                                recommendationAssignedCount={recommendationAssignedCount}
                                isAssignView={isAssignView}
                            />
                        </div>
                    </div>
                    {this.state.showMoreFilter && (
                        <AdditionalFilters
                            tableParams={tableParams}
                            filterByRecommendationAssigned={filterByRecommendationAssigned}
                            filterByGuid={filterByGuid}
                            filterByImages={filterByImages}
                        />
                    )}
                    <div className="d-flex wrapper-icon-full">
                        {selectedRecomIds?.length > 0 && (
                            <>
                                <button className={"add-btn ml-2 btn-new-noti"} onClick={e => e.preventDefault()}>
                                    <i className="fas fa-check" />
                                    <span>{selectedRecomIds?.length}</span>
                                </button>
                                <button
                                    className={"add-btn ml-2 btn-new-noti"}
                                    onClick={e => {
                                        e.preventDefault();
                                        clearSelection();
                                    }}
                                >
                                    <i className="fas fa-times-circle mr-1" /> {`Clear`}
                                </button>
                                {!this.props.isSmartChartView && (
                                    <>
                                        {hasEdit && (
                                            <button className={"add-btn ml-2 btn-new-noti"} onClick={() => handleEditMultiSelectedData()}>
                                                <i className="fas fa-pencil-alt mr-1" /> {`Edit`}
                                            </button>
                                        )}
                                    </>
                                )}
                                <button
                                    className={"add-btn ml-2 btn-new-noti"}
                                    onClick={e => {
                                        e.preventDefault();
                                        showSelectedAssets();
                                    }}
                                >
                                    <i className="fas fa-eye mr-1" />
                                    {tableParams.asset_ids?.length ? " Show All" : " Show"}
                                </button>
                                {paginationParams?.totalCount > paginationParams?.perPage && (
                                    <button
                                        className={`add-btn ml-2 btn-new-noti btn-sel-all ${isEveryItemSelected ? "active" : ""}`}
                                        onClick={e => {
                                            e.preventDefault();
                                            this.props.selectWholeAssets();
                                        }}
                                    >
                                        <i className="fas fa-check-double mr-1" /> {isEveryItemSelected ? "Selected All" : "Select All"}{" "}
                                        <span>{paginationParams?.totalCount}</span>
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                    <Table
                        updateCurrentViewAllUsers={updateCurrentViewAllUsers}
                        tableData={tableData}
                        currentViewAllUsers={currentViewAllUsers}
                        handleDeleteItem={handleDelete}
                        showWildCardFilter={showWildCardFilter}
                        showEditPage={showEditPage}
                        showInfoPage={showInfoPage}
                        updateSelectedRow={updateSelectedRow}
                        selectedRowId={selectedRowId}
                        updateWildCardFilter={updateWildCardFilter}
                        wildCardFilter={wildCardFilter}
                        handleHideColumn={handleHideColumn}
                        getListForCommonFilter={getListForCommonFilterFloor}
                        updateCommonFilter={updateCommonFilter}
                        commonFilter={commonFilter}
                        updateTableSortFilters={updateTableSortFilters}
                        tableParams={tableParams}
                        permissions={permissions}
                        hasEdit={hasEdit}
                        hasDelete={hasDelete}
                        hasInfoPage={hasInfoPage}
                        showRestoreModal={showRestoreModal}
                        hasActionColumn={hasActionColumn}
                        isAssignAsset={isAssignAsset}
                        summaryRowData={summaryRowData}
                        handleSelectAsset={this.props.handleSelectAsset}
                        selectedAsset={this.props.selectedAsset}
                        tableRef={tableRef}
                        hasViewIcon={this.props.hasViewIcon}
                        tableActionClass="action-wid"
                        isSmartChartView={this.props.isSmartChartView}
                        hasMultiAction={this.props.hasMultiAction}
                        handleSelectRecom={this.props.handleSelectRecom}
                        selectedRecomIds={this.props.selectedRecomIds}
                        handleSelectAllRecom={this.props.handleSelectAllRecom}
                        isAsset
                        everyItemCheckedPerPage={this.props.everyItemCheckedPerPage}
                        isAssignView={isAssignView}
                    />
                    {tableData.data && tableData.data.length ? (
                        <div className="table-bottom d-flex">
                            <div className="count d-flex col-md-6">
                                <div className="count-dtl">
                                    Total Count: <span>{paginationParams.totalCount}</span>
                                </div>
                                <div className="col-md-2 pr-2 selbx">
                                    <select className="form-control" value={paginationParams.perPage} onChange={e => handlePerPageChange(e)}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                    </select>
                                </div>
                            </div>
                            <div className="pagination-sec col-md-6">
                                <ReactPaginate
                                    previousLabel={
                                        <span data-for="asset-table" data-tip={`Previous`}>
                                            &lt;
                                        </span>
                                    }
                                    nextLabel={
                                        <span data-for="asset-table" data-tip={`Next`}>
                                            &gt;
                                        </span>
                                    }
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={paginationParams.totalPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    activeLinkClassName={"active"}
                                    forcePage={paginationParams.currentPage}
                                />
                            </div>
                        </div>
                    ) : null}
                    <ReactTooltip effect="solid" place="top" backgroundColor="#007bff" id="asset-table" />
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(PropertyMain);
