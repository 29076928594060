/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../actions";
import { fields } from "../constants";
import { findPrevPathFromBreadCrumpData, popBreadCrumpOnPageClose } from "../../../config/utils";
const useCapitalRequestForm = ({ isProjectView }) => {
    const history = useHistory();
    const { state, search } = useLocation();
    const dispatch = useDispatch();
    const { addRequestResponse, updateRequestResponse, getCapitalRequestDataByIdResponse } = useSelector(state => state.capitalRequestReducer);
    const { code, id } = useParams();
    const email = localStorage.getItem("crRequestorMail");
    const [data, setData] = useState(initialState);
    const [initialValues, setInitialValues] = useState(initialState);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [richTextPopup, setShowRichTextPopup] = useState({});
    const [errorParams, setErrorParams] = useState({});
    const [showErrorBorder, setShowErrorBorder] = useState(false);
    const createDataRef = useRef(false);
    const updateDataRef = useRef(false);

    useEffect(() => {
        setData(initialState);
        if (id) {
            dispatch(actions.getCapitalRequestDataById(id, {}, isProjectView));
        }
    }, [id]);

    useEffect(() => {
        if (state?.crData) {
            handleDuplicate();
        }
    }, [search]);

    useEffect(() => {
        if (getCapitalRequestDataByIdResponse?.data && id) {
            const { data: backendData } = getCapitalRequestDataByIdResponse || {};
            let newData = {};
            Object.keys(fields).forEach(field => {
                newData[field] =
                    fields[field].type === "dropdown" || fields[field].type === "radio" ? backendData[field]?.id : backendData[field] || "";
            });
            setData({ ...newData });
            setInitialValues({ ...newData });
        }
    }, [getCapitalRequestDataByIdResponse]);

    useEffect(() => {
        if (alertMessage) {
            showAlert();
            setAlertMessage("");
        }
    }, [alertMessage]);

    useEffect(() => {
        if (createDataRef?.current && addRequestResponse) {
            createDataRef.current = false;
            setSubmitting(false);
            const { success, error, message, id = "" } = addRequestResponse;
            setAlertMessage(message);
            if (success) {
                setTimeout(() => {
                    cancelForm(id);
                }, 1000);
            } else if (error) {
                setAlertMessage("Oops..Something went wrong!");
            }
        }
    }, [addRequestResponse]);

    useEffect(() => {
        if (updateRequestResponse && updateDataRef.current) {
            updateDataRef.current = false;
            setSubmitting(false);
            const { success, error, message } = updateRequestResponse;
            setAlertMessage(message);
            if (success) {
                setTimeout(() => {
                    cancelForm();
                }, 1000);
            } else if (error) {
                setAlertMessage("Oops..Something went wrong!");
            }
        }
    }, [updateRequestResponse]);

    const handleSubmit = async () => {
        if (validateRequest()) {
            setSubmitting(true);
            let params = {};
            if (id) {
                //for admin/manager edit
                if (isProjectView) {
                    const { status, ...rest } = data;
                    params = {
                        status_action: status,
                        capital_request: { ...rest }
                    };
                } else {
                    //for normal user edit
                    params = {
                        capital_request: { ...data }
                    };
                }
            } else {
                params = {
                    project_code: code,
                    capital_request: { requestor_email: email, ...data }
                };
            }
            id ? dispatch(actions.updateRequest(id, params)) : dispatch(actions.addRequest(params));
            if (id) {
                updateDataRef.current = true;
            } else {
                createDataRef.current = true;
            }
        }
    };

    const isEmpty = value => {
        return value === null || value === undefined || value === "";
    };

    const validateRequest = () => {
        let errorParams = {};
        let showErrorBorder = false;
        Object.keys(fields).forEach(field => {
            let isDataEmpty = fields[field].type === "dropdown" || fields[field].type === "radio" ? isEmpty(data[field]) : !data[field]?.length;
            if (fields[field].isRequired && isDataEmpty) {
                errorParams[field] = `* ${fields[field].label} is required`;
                showErrorBorder = true;
            }
        });
        setShowErrorBorder(showErrorBorder);
        setErrorParams(errorParams);
        if (showErrorBorder) return false;
        return true;
    };

    const handleCancel = () => {
        if (_.isEqual(initialValues, data)) {
            cancelForm();
        } else {
            setShowConfirmModal(true);
        }
    };
    const showAlert = () => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show";
            x.innerText = alertMessage;
            setTimeout(function () {
                x.className = x.className.replace("show", "");
            }, 3000);
        }
    };
    const handleChange = e => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const cancelForm = newDataId => {
        setInitialValues({});
        setData({});
        if (isProjectView) {
            popBreadCrumpOnPageClose();
            history.push(findPrevPathFromBreadCrumpData());
        } else {
            if (state?.from === "viewPage") {
                if (newDataId) {
                    history.push(`/capital-request/${code}/info/${newDataId}`);
                } else if (state?.fromId) {
                    history.push(`/capital-request/${code}/info/${state.fromId}`);
                } else {
                    history.push(`/capital-request/${code}/info/${id}`);
                }
            } else {
                history.push(`/capital-request/${code}`);
            }
        }
    };

    const handleDuplicate = () => {
        const { crData } = state;
        if (crData) {
            let newData = {};
            Object.keys(fields)
                .filter(field => !fields[field].readOnly && !fields[field].inEditOnly)
                .forEach(field => {
                    newData[field] =
                        fields[field].type === "dropdown" || fields[field].type === "radio" ? crData[field]?.id ?? "" : crData[field] || "";
                });
            setData({ ...newData });
            setInitialValues({ ...newData });
        }
    };

    return {
        data,
        editMode: id ? true : false,
        setData,
        handleCancel,
        handleSubmit,
        handleChange,
        cancelForm,
        setShowConfirmModal,
        showConfirmModal,
        isSubmitting,
        richTextPopup,
        setShowRichTextPopup,
        showErrorBorder,
        errorParams
    };
};
export default useCapitalRequestForm;

const initialState = {
    requested_at: new Date(),
    requestor_first_name: "",
    requestor_last_name: "",
    department_name: "",
    funding_request_title: "",
    request_estimate: "",
    investment_priority: "",
    request_status: "",
    request_type: "",
    equipment_request_type: "",
    request_description: "",
    age_and_condition_of_replacement: "",
    improving_service_inquiry: "",
    problem_alleviation_inquiry: "",
    compliance_resolution_inquiry: "",
    investment_efficiency_inquiry: "",
    operations_cost_reduction_inquiry: "",
    safety_requirement_inquiry: "",
    approval_delay_impact_inquiry: "",
    future_plan_inquiry: "",
    receiver_inquiry: "",
    other_request_relationship_inquiry: "",
    alternative_inquiry: "",
    risk_inquiry: "",
    approver_name: "",
    has_funding_source_identified: "",
    funding_source_security_inquiry: "",
    comments: ""
};
