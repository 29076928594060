import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { connect, useSelector } from "react-redux";
import dashboardAction from "../../dashboard/actions";
import { BuildingStatistics } from "./BuildingStatistics";
import { BuildingBreakdown } from "./BuildingBreakdown";
import "./SidePanel.css";
const SidePanel = props => {
    const buttonRef1 = useRef(null);
    const collapseRef1 = useRef(null);
    const buttonRef2 = useRef(null);
    const collapseRef2 = useRef(null);
    const { dashboardResetFlag } = useSelector(state => state.dashboardReducer);
    const [statExpanded, setStatExpanded] = useState(true);

    useEffect(() => {
        collapseRef1.current.classList.add("show");
        buttonRef1.current.setAttribute("aria-expanded", "true");
        collapseRef2.current.classList.remove("show");
        buttonRef2.current.setAttribute("aria-expanded", "false");
    }, [dashboardResetFlag]);

    useEffect(() => {
        const observer = new MutationObserver(handleCollapseClassChange);
        if (collapseRef1.current) {
            observer.observe(collapseRef1.current, { attributes: true });
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    const handleCollapseClassChange = mutationsList => {
        for (let mutation of mutationsList) {
            if (mutation.attributeName === "class") {
                const isCollapseVisible = collapseRef1.current.classList.contains("show");
                setStatExpanded(isCollapseVisible);
            }
        }
    };

    const {
        sidePanelValues,
        totalCsp,
        filterValues,
        activeSection,
        activeBuildingType,
        handleBuildingView,
        handleRecommentationView,
        handleRegionView,
        handleSiteView,
        handleView,
        handleBuildingType,
        clearBuildingFilter
    } = props;
    let disableButton = sidePanelValues && sidePanelValues.project_ids && sidePanelValues.project_ids.length === 1 ? false : true;
    return (
        <div id="sidePanelAccordion">
            <div className="dash-side">
                <ReactTooltip id="filter-icons" />
                <div className="bld-brk">
                    <div className="table-sec">
                        <div className="head" id="sidePanelHeadingOne">
                            <h2
                                ref={buttonRef1}
                                className="d-flex hed-cls"
                                data-toggle="collapse"
                                data-target="#sidePanelCollapseOne"
                                aria-expanded={"true"}
                                aria-controls="sidePanelCollapseOne"
                            >
                                Building Statistics
                            </h2>
                        </div>

                        <div id="sidePanelCollapseOne" ref={collapseRef1} class={`collapse  show`} aria-labelledby="sidePanelHeadingOne">
                            <BuildingStatistics
                                sidePanelValues={sidePanelValues}
                                totalCsp={totalCsp}
                                disableButton={disableButton}
                                activeSection={activeSection}
                                handleBuildingView={handleBuildingView}
                                handleRecommentationView={handleRecommentationView}
                                handleRegionView={handleRegionView}
                                handleSiteView={handleSiteView}
                                handleView={handleView}
                            />
                        </div>
                    </div>
                </div>
                <div className={`${!_.isEmpty(filterValues) ? "scrol-side" : ""} ${statExpanded ? "" : "collapse-hide"} bld-brk`}>
                    <div className="table-sec">
                        <div className="head" id="sidePanelHeadingTwo">
                            <h2
                                ref={buttonRef2}
                                className="d-flex hed-cls"
                                data-toggle="collapse"
                                data-target="#sidePanelCollapseTwo"
                                aria-expanded={"false"}
                                aria-controls="sidePanelCollapseTwo"
                            >
                                Building Breakdown ({sidePanelValues ? sidePanelValues.buildings : 0})
                            </h2>
                        </div>
                        <div id="sidePanelCollapseTwo" ref={collapseRef2} class={`collapse`} aria-labelledby="sidePanelHeadingTwo">
                            <BuildingBreakdown
                                activeBuildingType={activeBuildingType}
                                activeSection={activeSection}
                                disableButton={disableButton}
                                sidePanelValues={sidePanelValues}
                                handleBuildingType={handleBuildingType}
                                clearBuildingFilter={clearBuildingFilter}
                            />
                        </div>
                    </div>
                </div>
                {!_.isEmpty(filterValues) && (
                    <div className="tag-ara">
                        <div className={"tag-scrol"}>
                            {Object.values(filterValues).map(f => {
                                return f.name !== "Years" ? (
                                    f?.value?.length > 0 && (
                                        <>
                                            <span className="tag active">{f.name} </span>
                                            {f.value.map(item => {
                                                return (
                                                    <span key={item} className="tag">
                                                        {item}
                                                    </span>
                                                );
                                            })}
                                            <br />
                                        </>
                                    )
                                ) : (
                                    <>
                                        <span className="tag active">{f.name} </span>
                                        <span className="tag">Start : {f.value.start}</span>
                                        <span className="tag">End : {f.value.end}</span>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    const { dashboardReducer } = state;
    return { dashboardReducer };
};
export default withRouter(
    connect(mapStateToProps, {
        ...dashboardAction
    })(SidePanel)
);
