import React, { memo } from "react";
import Row from "./Row";
import { GridItem } from "./GridItem";

export const RowList = memo(({ index, data, style }) => {
    const {
        columnCount,
        imageData,
        handleMultiSelectImage,
        selectedImages,
        handleEdit,
        handleFavClick,
        isAssignView,
        hasEdit,
        viewRecommendations,
        viewImageModal,
        showSelectBox,
        handleInputCaptionData,
        viewAssets,
        touchedImageId,
        isSmartChartView
    } = data;
    const dataIndex = index;
    const columnIndices = Array(Math.min(columnCount, imageData?.length - dataIndex * columnCount))
        .fill(dataIndex * columnCount)
        .map((base, i) => base + i);
    return (
        <Row style={style}>
            {columnIndices.map(columnIndex => (
                <GridItem
                    index={columnIndex}
                    handleMultiSelectImage={handleMultiSelectImage}
                    item={imageData[columnIndex]}
                    isSelected={!!selectedImages.find(img => img.id === imageData?.[columnIndex]?.id)}
                    isSmartChartView={isSmartChartView}
                    handleEdit={handleEdit}
                    handleFavClick={handleFavClick}
                    isAssignView={isAssignView}
                    hasEdit={hasEdit}
                    viewRecommendations={viewRecommendations}
                    viewImageModal={viewImageModal}
                    showSelectBox={showSelectBox}
                    handleInputCaptionData={handleInputCaptionData}
                    viewAssets={viewAssets}
                    isTouched={touchedImageId === imageData?.[columnIndex]?.id}
                    isFavorite={imageData?.[columnIndex]?.favourite}
                    isEdited={imageData?.[columnIndex]?.is_edited}
                    updatedAt={imageData?.[columnIndex]?.updated_at}
                />
            ))}
        </Row>
    );
});
