import React, { useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import Portal from "../../common/components/Portal";
import ChangeConfirmationModal from "../../common/components/ChangeConfirmationModal";
import actions from "../actions";
import Draggable from "react-draggable";

const AssignUserToFavoriteModal = ({ onCancel, clientId, onAssignFavoriteToUsers, selectedReportId }) => {
    const dispatch = useDispatch();
    const { getUserByClientData } = useSelector(state => state.smartChartReducer);
    const [assigned, setAssigned] = useState([]);
    const [initialAssigned, setInitialAssigned] = useState([]);
    const [available, setAvailable] = useState([]);
    const [itemIds, setItemIds] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [availableSearchKey, setAvailableSearchKey] = useState("");
    const [assignedSearchKey, setAssignedSearchKey] = useState("");
    const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
    const [roleFilter, setRoleFilter] = useState({
        available: "",
        assigned: ""
    });

    useEffect(() => {
        dispatch(actions.getUsersByClient({ client_id: clientId, smart_export_id: selectedReportId }));
        return () => {
            dispatch(actions.clearUserByClientData());
        };
    }, []);

    useEffect(() => {
        if (getUserByClientData?.data?.length) {
            let tempAvailable = getUserByClientData.data.filter(d => !d.favourite_smart_export_id);
            let tempAssigned = getUserByClientData.data.filter(d => d.favourite_smart_export_id);
            setAvailable([...tempAvailable]);
            setAssigned([...tempAssigned]);
            setItemIds(tempAssigned.map(item => item.id));
            setInitialAssigned(tempAssigned.map(item => item.id));
        }
    }, [getUserByClientData]);

    const searchInAvailable = async (availableSearchKey, roleFilterValue) => {
        let availableItems = getUserByClientData?.data || [];
        let assignedActivityIds = assigned.map(item => item.id);
        let result = availableItems.filter(item => !assignedActivityIds.includes(item.id));
        if (availableSearchKey.trim().length) {
            result = result.filter(({ name, role_name = null }) => name && name.toLowerCase().includes(availableSearchKey.toLowerCase()));
        }
        if (roleFilterValue) {
            result = result.filter(({ role_name = null }) => role_name === roleFilterValue);
        }
        setAvailableSearchKey(availableSearchKey);
        setAvailable([...result]);
    };

    const searchInAssigned = async (assignedSearchKey, roleFilterValue) => {
        let assignedItems = getUserByClientData?.data || [];
        let availableActivityIds = available.map(item => item.id);
        let result = assignedItems.filter(item => !availableActivityIds.includes(item.id));
        if (assignedSearchKey.trim().length) {
            result = result.filter(({ name }) => name && name.toLowerCase().includes(assignedSearchKey.toLowerCase()));
        }
        if (roleFilterValue) {
            result = result.filter(({ role_name = null }) => role_name === roleFilterValue);
        }
        setAssignedSearchKey(assignedSearchKey);
        setAssigned([...result]);
    };

    const updateAsigned = async () => {
        if (_.isEqual(initialAssigned.sort(), itemIds.sort())) {
            onCancel();
        } else {
            togglShowConfirmation();
        }
    };

    const onUpdateAssignedsConfirm = async () => {
        if (!_.isEqual(initialAssigned.sort(), itemIds.sort())) {
            let unAssignedIds = initialAssigned.filter(id => !itemIds.includes(id));
            onAssignFavoriteToUsers({ user_ids: [...itemIds], unassign_user_ids: unAssignedIds, smart_export_id: selectedReportId });
        }
        return true;
    };

    const togglShowConfirmation = () => {
        setShowConfirmation(prevValue => !prevValue);
    };

    const renderConfirmationModal = () => {
        if (!showConfirmation) return null;
        return (
            <Portal
                body={
                    <ChangeConfirmationModal
                        onCancel={togglShowConfirmation}
                        onOk={onUpdateAssignedsConfirm}
                        heading={"Update Assignment?"}
                        paragraph={"Are you sure that you need to assign?"}
                    />
                }
                onCancel={togglShowConfirmation}
            />
        );
    };

    const updateAssignedList = async (type, id) => {
        let itemObj = {};
        let tempAssigned = assigned;
        let tempAvailable = available;
        let tempActivityIds = [];

        if (id === "all") {
            if (type === "add") {
                tempAvailable.map(item => tempAssigned.push(item));
                tempAvailable = [];
            } else {
                tempAssigned.map(item => tempAvailable.push(item));
                tempAssigned = [];
            }
        } else {
            if (type === "add") {
                itemObj = available.find(item => item.id === id);
                tempAssigned.push(itemObj);
                tempAvailable = tempAvailable.filter(item => item.id !== id);
            } else {
                itemObj = assigned.find(item => item.id === id);
                tempAvailable.push(itemObj);
                tempAssigned = tempAssigned.filter(item => item.id !== id);
            }
        }
        tempAssigned = _.uniqBy(tempAssigned, "id");
        tempAvailable = _.uniqBy(tempAvailable, "id");
        tempActivityIds = tempAssigned.map(item => item.id);

        setAssigned([...tempAssigned]);
        setAvailable([...tempAvailable]);
        setItemIds([...tempActivityIds]);
    };

    const cancelModal = () => {
        if (showCancelConfirmModal) {
            setShowCancelConfirmModal(false);
            onCancel();
        } else if (!_.isEqual(initialAssigned.sort(), itemIds.sort())) {
            setShowCancelConfirmModal(true);
        } else {
            onCancel();
        }
    };

    const renderCancelConfirmationModal = () => {
        if (!showCancelConfirmModal) return null;
        return (
            <Portal
                body={
                    <ChangeConfirmationModal
                        heading={"Do you want to clear and lose all changes?"}
                        paragraph={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onCancel={() => setShowCancelConfirmModal(false)}
                        onOk={cancelModal}
                    />
                }
                onCancel={() => setShowCancelConfirmModal(false)}
            />
        );
    };

    const handleSelectRoleFilter = (key, value) => {
        setRoleFilter(prevFilterValue => {
            return {
                ...prevFilterValue,
                [key]: value
            };
        });
        if (key === "available") {
            searchInAvailable(availableSearchKey, value);
        } else {
            searchInAssigned(assignedSearchKey, value);
        }
    };

    return (
        <React.Fragment>
            <div className="modal assigned-modal three-col-modal" role="dialog" style={{ display: "block" }} id="modalId">
                <Draggable handle=".draggable-fav-assign" cancel=".close">
                    <div className="modal-dialog modal-dialog-centered assignModal">
                        <div className="modal-content">
                            <div className="modal-header draggable-fav-assign draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Assign Favorite Reports To Users
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={cancelModal}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="outer-act-build list-sec">
                                    <div className="build-tem1">
                                        <h4>Available </h4>
                                        <div className="outer-avl-bind">
                                            <div className="sr-sec search-section">
                                                <div className="sr-out">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        onChange={e => searchInAvailable(e.target.value, roleFilter.available)}
                                                        placeholder="Search"
                                                        value={availableSearchKey}
                                                    />
                                                    <span
                                                        className="clear-btn cursor-pointer"
                                                        onClick={() =>
                                                            availableSearchKey.trim().length ? searchInAvailable("", roleFilter.available) : null
                                                        }
                                                    >
                                                        Clear
                                                    </span>
                                                </div>
                                                <div class="sort-by-fl">
                                                    {/* <div class="sort-by-innr">
                                                    <label>Role</label>
                                                </div> */}
                                                    <div class="fl-outs d-flex">
                                                        <div class="selectOtr ">
                                                            <select
                                                                autocomplete="nope"
                                                                class="form-control "
                                                                value={roleFilter.available}
                                                                onChange={e => handleSelectRoleFilter("available", e.target.value)}
                                                            >
                                                                <option value="">All</option>
                                                                <option value="consultancy_user">Consultancy User</option>
                                                                <option value="client_user">Client User</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-section">
                                                <table className="table table-bordered file-system-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="img-sq-box">
                                                                <i
                                                                    className="fas fa-arrows-alt-h cursor-pointer"
                                                                    onClick={() => updateAssignedList("add", "all")}
                                                                ></i>
                                                            </th>

                                                            <th className="sel-all">Name</th>
                                                            <th className="sel-all">Role</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {available && available.length ? (
                                                            available.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className="img-sq-box">
                                                                        <i
                                                                            className="fas fa-arrows-alt-h cursor-pointer"
                                                                            onClick={() => updateAssignedList("add", item.id)}
                                                                        ></i>
                                                                    </td>
                                                                    <td>
                                                                        <Highlighter
                                                                            searchWords={[`${availableSearchKey}`]}
                                                                            textToHighlight={item.name}
                                                                            className="highlighter"
                                                                        />
                                                                    </td>
                                                                    <td>{item.role_name}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={4} className="text-center">
                                                                    No Records Found !!
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="popup-counter">Count : {available ? available.length : 0}</div>
                                    </div>
                                    <div className="build-tem3">
                                        <h4>Assigned </h4>
                                        <div className="outer-avl-bind">
                                            <div className="sr-sec search-section">
                                                <div className="sr-out">
                                                    <input
                                                        type="text"
                                                        onChange={e => searchInAssigned(e.target.value, roleFilter.assigned)}
                                                        className="form-control"
                                                        placeholder="Search"
                                                        value={assignedSearchKey}
                                                    />
                                                    <span
                                                        className="clear-btn cursor-pointer"
                                                        onClick={() =>
                                                            assignedSearchKey.trim().length ? searchInAssigned("", roleFilter.assigned) : null
                                                        }
                                                    >
                                                        Clear
                                                    </span>
                                                </div>
                                                <div class="sort-by-fl">
                                                    {/* <div class="sort-by-innr">
                                                    <label>Role</label>
                                                </div> */}
                                                    <div class="fl-outs d-flex">
                                                        <div class="selectOtr ">
                                                            <select
                                                                autocomplete="nope"
                                                                class="form-control "
                                                                value={roleFilter.assigned}
                                                                onChange={e => handleSelectRoleFilter("assigned", e.target.value)}
                                                            >
                                                                <option value="">All</option>
                                                                <option value="consultancy_user">Consultancy User</option>
                                                                <option value="client_user">Client User</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-section">
                                                <table className="table table-bordered file-system-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="img-sq-box">
                                                                <i
                                                                    className="fas fa-arrows-alt-h cursor-pointer"
                                                                    onClick={() => updateAssignedList("remove", "all")}
                                                                ></i>
                                                            </th>

                                                            <th className="sel-all">Name</th>
                                                            <th className="sel-all">Role</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {assigned && assigned.length ? (
                                                            assigned.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className="img-sq-box">
                                                                        <i
                                                                            className="fas fa-arrows-alt-h cursor-pointer"
                                                                            onClick={() => updateAssignedList("remove", item.id)}
                                                                        ></i>
                                                                    </td>
                                                                    <td className="title-tip title-tip-up" tooltip-content={item.name}>
                                                                        <Highlighter
                                                                            searchWords={[`${assignedSearchKey}`]}
                                                                            textToHighlight={item.name}
                                                                            className="highlighter"
                                                                        />
                                                                    </td>
                                                                    <td className="title-tip title-tip-up">
                                                                        <Highlighter
                                                                            searchWords={[`${assignedSearchKey}`]}
                                                                            textToHighlight={item.role_name}
                                                                            className="highlighter"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={2} className="text-center">
                                                                    No Records Found !!
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="popup-counter">Count : {assigned ? assigned.length : 0}</div>
                                    </div>
                                </div>

                                <div className={`btn-sec`}>
                                    <div className="btn-out-1 text-right">
                                        <button type="button" className="btn btn-primary btnRgion mr-1" onClick={() => updateAsigned()}>
                                            Update
                                        </button>
                                        <button type="button" className="btn btn-secondary btnClr" onClick={() => cancelModal()}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
                {renderConfirmationModal()}
                {renderCancelConfirmationModal()}
            </div>
        </React.Fragment>
    );
};

export default AssignUserToFavoriteModal;
