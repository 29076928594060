export const GET_RESPONSIBILITY_DATA_REQUEST = "GET_RESPONSIBILITY_DATA_REQUEST";
export const GET_RESPONSIBILITY_DATA_SUCCESS = "GET_RESPONSIBILITY_DATA_SUCCESS";
export const GET_RESPONSIBILITY_DATA_FAILURE = "GET_RESPONSIBILITY_DATA_FAILURE";

export const ADD_RESPONSIBILITY_REQUEST = "ADD_RESPONSIBILITY_REQUEST";
export const ADD_RESPONSIBILITY_SUCCESS = "ADD_RESPONSIBILITY_SUCCESS";
export const ADD_RESPONSIBILITY_FAILURE = "ADD_RESPONSIBILITY_FAILURE";

export const GET_RESPONSIBILITY_BY_ID_REQUEST = "GET_RESPONSIBILITY_BY_ID_REQUEST";
export const GET_RESPONSIBILITY_BY_ID_SUCCESS = "GET_RESPONSIBILITY_BY_ID_SUCCESS";
export const GET_RESPONSIBILITY_BY_ID_FAILURE = "GET_RESPONSIBILITY_BY_ID_FAILURE";

export const UPDATE_RESPONSIBILITY_REQUEST = "UPDATE_RESPONSIBILITY_REQUEST";
export const UPDATE_RESPONSIBILITY_SUCCESS = "UPDATE_RESPONSIBILITY_SUCCESS";
export const UPDATE_RESPONSIBILITY_FAILURE = "UPDATE_RESPONSIBILITY_FAILURE";

export const DELETE_RESPONSIBILITY_REQUEST = "DELETE_RESPONSIBILITY_REQUEST";
export const DELETE_RESPONSIBILITY_SUCCESS = "DELETE_RESPONSIBILITY_SUCCESS";
export const DELETE_RESPONSIBILITY_FAILURE = "DELETE_RESPONSIBILITY_FAILURE";

// Update Floor Entity Param
export const UPDATE_RESPONSIBILITY_ENTITY_PARAMS_SUCCESS = "UPDATE_RESPONSIBILITY_ENTITY_PARAMS_SUCCESS";
export const UPDATE_RESPONSIBILITY_ENTITY_PARAMS_FAILURE = "UPDATE_RESPONSIBILITY_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_RESPONSIBILITY_FILTER_REQUEST = "GET_LIST_FOR_RESPONSIBILITY_FILTER_REQUEST";
export const GET_LIST_FOR_RESPONSIBILITY_FILTER_SUCCESS = "GET_LIST_FOR_RESPONSIBILITY_FILTER_SUCCESS";
export const GET_LIST_FOR_RESPONSIBILITY_FILTER_FAILURE = "GET_LIST_FOR_RESPONSIBILITY_FILTER_FAILURE";

export const GET_ALL_RESPONSIBILITY_LOG_REQUEST = "GET_ALL_RESPONSIBILITY_LOG_REQUEST";
export const GET_ALL_RESPONSIBILITY_LOG_SUCCESS = "GET_ALL_RESPONSIBILITY_LOG_SUCCESS";
export const GET_ALL_RESPONSIBILITY_LOG_FAILURE = "GET_ALL_RESPONSIBILITY_LOG_FAILURE";

export const RESTORE_RESPONSIBILITY_LOG_REQUEST = "RESTORE_RESPONSIBILITY_LOG_REQUEST";
export const RESTORE_RESPONSIBILITY_LOG_SUCCESS = "RESTORE_RESPONSIBILITY_LOG_SUCCESS";
export const RESTORE_RESPONSIBILITY_LOG_FAILURE = "RESTORE_RESPONSIBILITY_LOG_FAILURE";

export const DELETE_RESPONSIBILITY_LOG_REQUEST = "DELETE_RESPONSIBILITY_LOG_REQUEST";
export const DELETE_RESPONSIBILITY_LOG_SUCCESS = "DELETE_RESPONSIBILITY_LOG_SUCCESS";
export const DELETE_RESPONSIBILITY_LOG_FAILURE = "DELETE_RESPONSIBILITY_LOG_FAILURE";

export const GET_RESPONSIBILITY_EXPORT_REQUEST = "GET_RESPONSIBILITY_EXPORT_REQUEST";
export const GET_RESPONSIBILITY_EXPORT_SUCCESS = "GET_RESPONSIBILITY_EXPORT_SUCCESS";
export const GET_RESPONSIBILITY_EXPORT_FAILURE = "GET_RESPONSIBILITY_EXPORT_FAILURE";
