export const GET_SUB_SYSTEM_SETTINGS_DATA_REQUEST = "GET_SUB_SYSTEM_SETTINGS_DATA_REQUEST";
export const GET_SUB_SYSTEM_SETTINGS_DATA_SUCCESS = "GET_SUB_SYSTEM_SETTINGS_DATA_SUCCESS";
export const GET_SUB_SYSTEM_SETTINGS_DATA_FAILURE = "GET_SUB_SYSTEM_SETTINGS_DATA_FAILURE";

export const ADD_SUB_SYSTEM_REQUEST = "ADD_SUB_SYSTEM_REQUEST";
export const ADD_SUB_SYSTEM_SUCCESS = "ADD_SUB_SYSTEM_SUCCESS";
export const ADD_SUB_SYSTEM_FAILURE = "ADD_SUB_SYSTEM_FAILURE";

export const GET_SUB_SYSTEM_BY_ID_REQUEST = "GET_SUB_SYSTEM_BY_ID_REQUEST";
export const GET_SUB_SYSTEM_BY_ID_SUCCESS = "GET_SUB_SYSTEM_BY_ID_SUCCESS";
export const GET_SUB_SYSTEM_BY_ID_FAILURE = "GET_SUB_SYSTEM_BY_ID_FAILURE";

export const UPDATE_SUB_SYSTEM_REQUEST = "UPDATE_SUB_SYSTEM_REQUEST";
export const UPDATE_SUB_SYSTEM_SUCCESS = "UPDATE_SUB_SYSTEM_SUCCESS";
export const UPDATE_SUB_SYSTEM_FAILURE = "UPDATE_SUB_SYSTEM_FAILURE";

export const DELETE_SUB_SYSTEM_REQUEST = "DELETE_SUB_SYSTEM_REQUEST";
export const DELETE_SUB_SYSTEM_SUCCESS = "DELETE_SUB_SYSTEM_SUCCESS";
export const DELETE_SUB_SYSTEM_FAILURE = "DELETE_SUB_SYSTEM_FAILURE";

export const GET_TRADE_SETTINGS_DROPDOWN_REQUEST = "GET_TRADE_SETTINGS_DROPDOWN_REQUEST";
export const GET_TRADE_SETTINGS_DROPDOWN_SUCCESS = "GET_TRADE_SETTINGS_DROPDOWN_SUCCESS";
export const GET_TRADE_SETTINGS_DROPDOWN_FAILURE = "GET_TRADE_SETTINGS_DROPDOWN_FAILURE";

export const GET_SYSTEM_SETTINGS_BY_TRADE_REQUEST = "GET_SYSTEM_SETTINGS_BY_TRADE_REQUEST";
export const GET_SYSTEM_SETTINGS_BY_TRADE_SUCCESS = "GET_SYSTEM_SETTINGS_BY_TRADE_SUCCESS";
export const GET_SYSTEM_SETTINGS_BY_TRADE_FAILURE = "GET_SYSTEM_SETTINGS_BY_TRADE_FAILURE";

// Update Floor Entity Param
export const UPDATE_SUB_SYSTEM_ENTITY_PARAMS_SUCCESS = "UPDATE_SUB_SYSTEM_ENTITY_PARAMS_SUCCESS";
export const UPDATE_SUB_SYSTEM_ENTITY_PARAMS_FAILURE = "UPDATE_SUB_SYSTEM_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_SUB_SYSTEM_LOG_REQUEST = "GET_ALL_SUB_SYSTEM_LOG_REQUEST";
export const GET_ALL_SUB_SYSTEM_LOG_SUCCESS = "GET_ALL_SUB_SYSTEM_LOG_SUCCESS";
export const GET_ALL_SUB_SYSTEM_LOG_FAILURE = "GET_ALL_SUB_SYSTEM_LOG_FAILURE";

export const RESTORE_SETTINGS_LOG_REQUEST = "RESTORE_SETTINGS_LOG_REQUEST";
export const RESTORE_SETTINGS_LOG_SUCCESS = "RESTORE_SETTINGS_LOG_SUCCESS";
export const RESTORE_SETTINGS_LOG_FAILURE = "RESTORE_SETTINGS_LOG_FAILURE";

export const DELETE_SETTINGS_LOG_REQUEST = "DELETE_SETTINGS_LOG_REQUEST";
export const DELETE_SETTINGS_LOG_SUCCESS = "DELETE_SETTINGS_LOG_SUCCESS";
export const DELETE_SETTINGS_LOG_FAILURE = "DELETE_SETTINGS_LOG_FAILURE";

export const GET_SUB_SYSTEM_EXPORT_REQUEST = "GET_SUB_SYSTEM_EXPORT_REQUEST";
export const GET_SUB_SYSTEM_EXPORT_SUCCESS = "GET_SUB_SYSTEM_EXPORT_SUCCESS";
export const GET_SUB_SYSTEM_EXPORT_FAILURE = "GET_SUB_SYSTEM_EXPORT_FAILURE";
