import React, { useState, useRef, useEffect } from "react";
import ReactTooltip from "react-tooltip";

export const EllipsisTooltipText = ({ text, className, asLabel = false, asHeading = false, isInfoField = false }) => {
    const id = text
        ?.toString()
        ?.toLowerCase()
        ?.replace(/[^a-zA-Z0-9_]/g, "_");

    const [showTooltip, setShowTooltip] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        const textElement = textRef.current;
        if (textElement?.scrollWidth > textElement?.clientWidth || (textElement?.scrollWidth === 0 && textElement?.clientWidth === 0)) {
            setShowTooltip(true);
        } else {
            setShowTooltip(false);
        }
    }, [text]);
    return (
        <>
            {asLabel ? (
                <label className={className} ref={textRef} data-for={id} data-tip={showTooltip ? text : null}>
                    {text}
                    {showTooltip && (
                        <ReactTooltip
                            id={id}
                            className="rc-tooltip-custom-class"
                            backgroundColor="#007bff"
                            place="top"
                            effect="solid"
                            delayShow={500}
                        />
                    )}
                </label>
            ) : asHeading ? (
                <h3 className={className} ref={textRef} data-for={id} data-tip={showTooltip ? text : null}>
                    {text}
                    {showTooltip && (
                        <ReactTooltip
                            id={id}
                            className="rc-tooltip-custom-class"
                            backgroundColor="#007bff"
                            place="top"
                            effect="solid"
                            delayShow={500}
                        />
                    )}
                </h3>
            ) : isInfoField ? (
                <p className={className} ref={textRef} data-for={id} data-tip={showTooltip ? text : null}>
                    {text}
                    {showTooltip && (
                        <ReactTooltip
                            id={id}
                            className="rc-tooltip-custom-class"
                            backgroundColor="#007bff"
                            place="top"
                            effect="solid"
                            delayShow={500}
                        />
                    )}
                </p>
            ) : (
                <span className={className} ref={textRef} data-for={id} data-tip={showTooltip ? text : null}>
                    {text}
                    {showTooltip && (
                        <ReactTooltip
                            id={id}
                            className="rc-tooltip-custom-class"
                            backgroundColor="#007bff"
                            place="top"
                            effect="solid"
                            delayShow={500}
                        />
                    )}
                </span>
            )}
        </>
    );
};
