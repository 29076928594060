import * as actionTypes from "./constants";
import * as Service from "./services";

const getSmartChartMasterFilterDropDown = (key, entity, params) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_MASTER_FILTER_IN_SMART_CHART_REQUEST });
            const res = await Service.getSmartChartMasterFilterDropDown(key, params);
            if (res && res.status === 200) {
                const dropdownData = res.data;
                dispatch({
                    type: actionTypes.GET_MASTER_FILTER_IN_SMART_CHART_SUCCESS,
                    response: dropdownData,
                    filterKey: key,
                    entity: entity
                });
            } else {
                dispatch({
                    type: actionTypes.GET_MASTER_FILTER_IN_SMART_CHART_FAILURE,
                    error: res.response && res.response.data,
                    filterKey: key,
                    entity: entity
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_MASTER_FILTER_IN_SMART_CHART_FAILURE,
                error: e.response && e.response.data,
                filterKey: key,
                entity: entity
            });
        }
    };
};

const exportSmartChartData = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.EXPORT_SMART_CHART_DATA_REQUEST });
            const res = await Service.exportSmartChartData(params);
            if (res && res.status === 200) {
                const smartChartData = res.data;
                dispatch({
                    type: actionTypes.EXPORT_SMART_CHART_DATA_SUCCESS,
                    response: smartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.EXPORT_SMART_CHART_DATA_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.EXPORT_SMART_CHART_DATA_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const saveSmartChartData = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.SAVE_SMART_CHART_DATA_REQUEST });
            const res = await Service.saveSmartChartData(params);
            if (res && res.status === 201) {
                const smartChartData = res.data;
                dispatch({
                    type: actionTypes.SAVE_SMART_CHART_DATA_SUCCESS,
                    response: smartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.SAVE_SMART_CHART_DATA_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.SAVE_SMART_CHART_DATA_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getExportedSmartChartList = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_EXPORTED_SMART_CHART_LIST_REQUEST });
            const res = await Service.getExportedSmartChartList(params);
            if (res && res.status === 200) {
                const smartChartList = res.data;
                dispatch({
                    type: actionTypes.GET_EXPORTED_SMART_CHART_LIST_SUCCESS,
                    response: smartChartList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_EXPORTED_SMART_CHART_LIST_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_EXPORTED_SMART_CHART_LIST_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const deleteSmartChartReport = id => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.DELETE_SMART_CHART_REPORT_REQUEST });
            const res = await Service.deleteSmartChartReport(id);
            if (res && res.status === 200) {
                const deleteSmartChartData = res.data;
                dispatch({
                    type: actionTypes.DELETE_SMART_CHART_REPORT_SUCCESS,
                    response: deleteSmartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.DELETE_SMART_CHART_REPORT_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.DELETE_SMART_CHART_REPORT_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const uploadDocsForSmartReport = params => {
    let docData = new FormData();
    docData.append("name", params.name);
    docData.append("file", params.file);
    docData.append("user", params.user);
    docData.append("notes", params.notes);
    if (params.client_id) {
        docData.append("client_id", params.client_id);
    }
    if (params.is_image) {
        docData.append("is_image", params.is_image);
    }
    if (params.doc_type) {
        docData.append("doc_type", params.doc_type);
    }
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.UPLOAD_DOC_FOR_SMART_REPORT_REQUEST });
            const res = await Service.uploadDocsForSmartReport(docData);
            if (res && res.status === 201) {
                const smartChartData = res.data;
                dispatch({
                    type: actionTypes.UPLOAD_DOC_FOR_SMART_REPORT_SUCCESS,
                    response: smartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.UPLOAD_DOC_FOR_SMART_REPORT_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.UPLOAD_DOC_FOR_SMART_REPORT_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getUploadedDocList = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_UPLOADED_DOC_LIST_REQUEST });
            const res = await Service.getUploadedDocList(params);
            if (res && res.status === 200) {
                const smartChartList = res.data;
                dispatch({
                    type: actionTypes.GET_UPLOADED_DOC_LIST_SUCCESS,
                    response: smartChartList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_UPLOADED_DOC_LIST_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_UPLOADED_DOC_LIST_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const updateSmartReportData = (id, params) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.UPDATE_SMART_REPORT_DATA_REQUEST });
            const res = await Service.updateSmartReportData(id, params);
            if (res && res.status === 200) {
                const smartChartData = res.data;
                dispatch({
                    type: actionTypes.UPDATE_SMART_REPORT_DATA_SUCCESS,
                    response: smartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.UPDATE_SMART_REPORT_DATA_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.UPDATE_SMART_REPORT_DATA_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};
const updateCoreReport = (id, params) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.UPDATE_CORE_REPORT_DATA_REQUEST });
            const res = await Service.updateSkeletonFromTemplate(id, params);
            if (res && res.status === 200) {
                const smartChartData = res.data;
                dispatch({
                    type: actionTypes.UPDATE_CORE_REPORT_DATA_SUCCESS,
                    response: smartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.UPDATE_CORE_REPORT_DATA_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.UPDATE_SMART_REPORT_DATA_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getTemplatePropertiesList = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_TEMPLATE_PROPERTIES_LIST_REQUEST });
            const res = await Service.getTemplatePropertiesList(params);
            if (res && res.status === 200) {
                const propertiesList = res.data;
                dispatch({
                    type: actionTypes.GET_TEMPLATE_PROPERTIES_LIST_SUCCESS,
                    response: propertiesList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_TEMPLATE_PROPERTIES_LIST_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_TEMPLATE_PROPERTIES_LIST_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};
const getTemplateList = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_TEMPLATE_LIST_REQUEST });
            const res = await Service.getTemplateList(params);
            if (res && res.status === 200) {
                const propertiesList = res.data;
                dispatch({
                    type: actionTypes.GET_TEMPLATE_LIST_SUCCESS,
                    response: propertiesList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_TEMPLATE_LIST_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_TEMPLATE_LIST_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const deleteUserDocs = id => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.DELETE_USER_DOC_REQUEST });
            const res = await Service.deleteUserDocs({ id: id });
            if (res && res.status === 204) {
                const deleteUserDocRes = res.data;
                dispatch({
                    type: actionTypes.DELETE_USER_DOC_SUCCESS,
                    response: deleteUserDocRes
                });
            } else {
                dispatch({
                    type: actionTypes.DELETE_USER_DOC_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.DELETE_USER_DOC_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getClientDropDownData = () => {
    let currentUser = localStorage.getItem("userId") || "";
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_CLIENTS_LIST_FOR_SMART_CHART_REQUEST });
            const res = await Service.getClientDropDownData({ user_id: currentUser });
            if (res && res.status === 200) {
                const clientList = res.data;
                dispatch({
                    type: actionTypes.GET_CLIENTS_LIST_FOR_SMART_CHART_SUCCESS,
                    response: clientList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_CLIENTS_LIST_FOR_SMART_CHART_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_CLIENTS_LIST_FOR_SMART_CHART_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const updateDocOrder = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.UPDATE_DOC_ORDER_REQUEST });
            const res = await Service.updateDocOrder(params);
            if (res && res.status === 200) {
                const docOrderData = res.data;
                dispatch({
                    type: actionTypes.UPDATE_DOC_ORDER_SUCCESS,
                    response: docOrderData
                });
            } else {
                dispatch({
                    type: actionTypes.UPDATE_DOC_ORDER_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.UPDATE_DOC_ORDER_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const updateUserDocData = (id, params) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.UPDATE_USER_DOC_DATA_REQUEST });
            let templateData = new FormData();
            templateData.append("name", params.name);
            if (params.file) templateData.append("file", params.file);
            templateData.append("notes", params.notes);
            const res = await Service.updateUserDocData(id, templateData);
            if (res && res.status === 200) {
                const userDocData = res.data;
                dispatch({
                    type: actionTypes.UPDATE_USER_DOC_DATA_SUCCESS,
                    response: userDocData
                });
            } else {
                dispatch({
                    type: actionTypes.UPDATE_USER_DOC_DATA_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.UPDATE_USER_DOC_DATA_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const updateSmartChartProperty = (id, params) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.UPDATE_SMART_CHART_PROPERTY_REQUEST });
            const res = await Service.updateSmartChartProperty(id, params);
            if (res && res.status === 200) {
                const smartChartData = res.data;
                dispatch({
                    type: actionTypes.UPDATE_SMART_CHART_PROPERTY_SUCCESS,
                    response: smartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.UPDATE_SMART_CHART_PROPERTY_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.UPDATE_SMART_CHART_PROPERTY_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getSmartChartPropertyList = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_SMART_CHART_PROPERTIES_LIST_REQUEST });
            const res = await Service.getSmartChartPropertyList(params);
            if (res && res.status === 200) {
                const smartChartList = res.data;
                dispatch({
                    type: actionTypes.GET_SMART_CHART_PROPERTIES_LIST_SUCCESS,
                    response: smartChartList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_SMART_CHART_PROPERTIES_LIST_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_SMART_CHART_PROPERTIES_LIST_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getSmartChartSourceTemplete = id => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_REQUEST });
            const res = await Service.getSmartChartPropertySourceTemplete(id, { user_id: localStorage.getItem("userId") || "" });
            if (res && res.status === 200) {
                const smartChartList = res.data;
                dispatch({
                    type: actionTypes.GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_SUCCESS,
                    response: smartChartList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getSmartChartPropertyById = id => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_SMART_CHART_PROPERTY_BY_ID_REQUEST });
            const res = await Service.getSmartChartPropertyById(id, { user_id: localStorage.getItem("userId") || "" });
            if (res && res.status === 200) {
                const smartChartData = res.data;
                dispatch({
                    type: actionTypes.GET_SMART_CHART_PROPERTY_BY_ID_SUCCESS,
                    response: smartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.GET_SMART_CHART_PROPERTY_BY_ID_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_SMART_CHART_PROPERTY_BY_ID_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const updateSmartReportsEntityParams = params => {
    return async dispatch => {
        try {
            if (params) {
                dispatch({
                    type: actionTypes.UPDATE_SMART_REPORT_ENTITY_PARAMS_SUCCESS,
                    mFilters: params?.mFilters || {},
                    entityParams: params?.entityParams || {}
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.UPDATE_SMART_REPORT_ENTITY_PARAMS_FAILURE,
                error: params
            });
        }
    };
};

const getUploadedImageList = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_UPLOADED_IMAGE_LIST_REQUEST });
            const res = await Service.getUploadedDocList(params);
            if (res && res.status === 200) {
                const ImageList = res.data;
                dispatch({
                    type: actionTypes.GET_UPLOADED_IMAGE_LIST_SUCCESS,
                    response: ImageList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_UPLOADED_IMAGE_LIST_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_UPLOADED_IMAGE_LIST_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const saveAndExportSmartChartData = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.SAVE_AND_EXPORT_SMART_CHART_DATA_REQUEST });
            const res = await Service.exportSmartChartData(params);
            if (res && res.status === 200) {
                const smartChartData = res.data;
                dispatch({
                    type: actionTypes.SAVE_AND_EXPORT_SMART_CHART_DATA_SUCCESS,
                    response: smartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.SAVE_AND_EXPORT_SMART_CHART_DATA_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.SAVE_AND_EXPORT_SMART_CHART_DATA_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const deleteSmartChartReportTemplate = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.DELETE_SMART_CHART_REPORT_TEMPLATE_REQUEST });
            const res = await Service.deleteSmartChartReportTemplate(params);
            if (res && res.status === 204) {
                const deleteSmartChartData = res.data;
                dispatch({
                    type: actionTypes.DELETE_SMART_CHART_REPORT_TEMPLATE_SUCCESS,
                    response: deleteSmartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.DELETE_SMART_CHART_REPORT_TEMPLATE_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.DELETE_SMART_CHART_REPORT_TEMPLATE_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getReportsByTemplateList = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_REPORTS_BY_TEMPLATE_LIST_REQUEST });
            const res = await Service.getExportedSmartChartList(params);
            if (res && res.status === 200) {
                const smartChartList = res.data;
                dispatch({
                    type: actionTypes.GET_REPORTS_BY_TEMPLATE_LIST_SUCCESS,
                    response: smartChartList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_REPORTS_BY_TEMPLATE_LIST_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_REPORTS_BY_TEMPLATE_LIST_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const assignImagesToSmartCharts = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.ASSIGN_IMAGES_TO_SMART_CHARTS_REQUEST });
            const res = await Service.assignImagesToSmartCharts(params);
            if (res && res.status === 200) {
                const smartChartData = res.data;

                dispatch({
                    type: actionTypes.ASSIGN_IMAGES_TO_SMART_CHARTS_SUCCESS,
                    response: smartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.ASSIGN_IMAGES_TO_SMART_CHARTS_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.ASSIGN_IMAGES_TO_SMART_CHARTS_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const lockSmartChartTemplate = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.LOCK_SMART_CHART_TEMPLATE_REQUEST });
            const res = await Service.lockSmartChartTemplate(params);
            if (res && res.status === 200) {
                const templateData = res.data;
                dispatch({
                    type: actionTypes.LOCK_SMART_CHART_TEMPLATE_SUCCESS,
                    response: templateData
                });
            } else {
                dispatch({
                    type: actionTypes.LOCK_SMART_CHART_TEMPLATE_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.LOCK_SMART_CHART_TEMPLATE_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getCAbandFilterDropDownData = (key, params) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_CA_BAND_FILTER_DROPDOWN_DATA_REQUEST });
            const res = await Service.getSmartChartMasterFilterDropDown(key, params);
            if (res && res.status === 200) {
                let dropdownData = res.data;
                if (key === "term" && dropdownData?.data?.length) {
                    dropdownData.data = dropdownData.data.map(termData => {
                        return { ...termData, description: termData.site_name };
                    });
                }
                dispatch({
                    type: actionTypes.GET_CA_BAND_FILTER_DROPDOWN_DATA_SUCCESS,
                    response: dropdownData
                });
            } else {
                dispatch({
                    type: actionTypes.GET_CA_BAND_FILTER_DROPDOWN_DATA_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_CA_BAND_FILTER_DROPDOWN_DATA_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const addSmartChartToFavorites = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.ADD_SMART_CHART_TO_FAVORITES_REQUEST });
            const res = await Service.addSmartChartToFavorites(params);
            if (res && res.status === 200) {
                const dropdownData = res.data;
                dispatch({
                    type: actionTypes.ADD_SMART_CHART_TO_FAVORITES_SUCCESS,
                    response: dropdownData
                });
            } else {
                dispatch({
                    type: actionTypes.ADD_SMART_CHART_TO_FAVORITES_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.ADD_SMART_CHART_TO_FAVORITES_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const updateEntityFilterParams = (key, filterParams = {}) => {
    return async dispatch => {
        dispatch({
            type: actionTypes.UPDATE_ENTITY_FILTER_PARAMS_SMART_CHARTS,
            filterParams: filterParams,
            key: key
        });
    };
};
const clearReducerData = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_SMART_CHART_REDUCER_DATA });
    };
};

const getCAbandTradeFilterDropDownData = (key, params) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_REQUEST });
            const res = await Service.getSmartChartMasterFilterDropDown(key, params);
            if (res && res.status === 200) {
                let dropdownData = res.data;
                dispatch({
                    type: actionTypes.GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_SUCCESS,
                    response: dropdownData
                });
            } else {
                dispatch({
                    type: actionTypes.GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const updateSmartReportTemplateEntityParams = params => {
    return async dispatch => {
        try {
            if (params) {
                dispatch({
                    type: actionTypes.UPDATE_SMART_REPORT_TEMPLATE_ENTITY_PARAMS_SUCCESS,
                    entityParams: params?.entityParams || {}
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.UPDATE_SMART_REPORT_TEMPLATE_ENTITY_PARAMS_FAILURE,
                error: params
            });
        }
    };
};

const getUsersByClient = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_USERS_BY_CLIENT_REQUEST });
            const res = await Service.getUsersByClient(params);
            if (res && res.status === 200) {
                const usersList = res.data;
                dispatch({
                    type: actionTypes.GET_USERS_BY_CLIENT_SUCCESS,
                    response: usersList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_USERS_BY_CLIENT_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_USERS_BY_CLIENT_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const assignFavoriteToUsers = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.ASSIGN_FAVORITE_TO_USERS_REQUEST });
            const res = await Service.assignFavoriteToUsers(params);
            if (res && res.status === 200) {
                const responseData = res.data;
                dispatch({
                    type: actionTypes.ASSIGN_FAVORITE_TO_USERS_SUCCESS,
                    response: responseData
                });
            } else {
                dispatch({
                    type: actionTypes.ASSIGN_FAVORITE_TO_USERS_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.ASSIGN_FAVORITE_TO_USERS_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const clearUserByClientData = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_USER_BY_CLIENT_DATA });
    };
};

const getExportPropertyListForSmartChartRecom = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_REQUEST });
            const res = await Service.getExportPropertyListForSmartChartRecom(params);
            if (res && res.status === 200) {
                const propertyList = res.data;
                dispatch({
                    type: actionTypes.GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_SUCCESS,
                    response: propertyList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getExportTemplateListForSmartChartRecom = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_REQUEST });
            const res = await Service.getExportTemplateListForSmartChartRecom(params);
            if (res && res.status === 200) {
                const propertyList = res.data;
                dispatch({
                    type: actionTypes.GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_SUCCESS,
                    response: propertyList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getExportSettingsForSmartChartRecom = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_EXPORT_SETTINGS_NORMAL_RECOM_REQUEST });
            const res = await Service.getExportSettingsForSmartChartRecom(params);
            if (res && res.status === 200) {
                const propertyList = res.data;
                dispatch({
                    type: actionTypes.GET_EXPORT_SETTINGS_NORMAL_RECOM_SUCCESS,
                    response: propertyList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_EXPORT_SETTINGS_NORMAL_RECOM_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_EXPORT_SETTINGS_NORMAL_RECOM_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const saveExportSettingsForSmartChartRecom = params => {
    let exportData = new FormData();
    if (params.template) exportData.append("template", params.template);
    exportData.append("settings_id", params.settings_id);
    exportData.append("properties", JSON.stringify(params.properties));
    exportData.append("recom_property", params.recom_property);
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.ADD_EXPORT_SETTINGS_NORMAL_RECOM_REQUEST });
            const res = await Service.saveExportSettingsForSmartChartRecom(exportData);
            if (res && res.status === 200) {
                const responseData = res.data;
                dispatch({
                    type: actionTypes.ADD_EXPORT_SETTINGS_NORMAL_RECOM_SUCCESS,
                    response: responseData
                });
            } else {
                dispatch({
                    type: actionTypes.ADD_EXPORT_SETTINGS_NORMAL_RECOM_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.ADD_EXPORT_SETTINGS_NORMAL_RECOM_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const clearPropertyByIdData = () => {
    return async dispatch => {
        dispatch({ type: actionTypes.CLEAR_PROPERTY_BY_ID_DATA });
    };
};
const getCAbandSystemFilterDropDownData = (key, params) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_REQUEST });
            const res = await Service.getSmartChartMasterFilterDropDown(key, params);
            if (res && res.status === 200) {
                let dropdownData = res.data;
                dispatch({
                    type: actionTypes.GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_SUCCESS,
                    response: dropdownData
                });
            } else {
                dispatch({
                    type: actionTypes.GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const createSkeletonFromTemplate = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.CREATE_SKELETON_FROM_TEMPLATE_REQUEST });
            const res = await Service.createSkeletonFromTemplate(params);
            const skeletonData = res.data;
            dispatch({
                type: actionTypes.CREATE_SKELETON_FROM_TEMPLATE_SUCCESS,
                response: skeletonData
            });
        } catch (e) {
            dispatch({
                type: actionTypes.CREATE_SKELETON_FROM_TEMPLATE_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getExportSettingsForAllSmartChartRecomTiles = (params, action) => {
    if (action === "clear") {
        return async dispatch => {
            dispatch({ type: actionTypes.CLEAR_SETTINGS_IDS_FOR_NORMAL_RECOM });
        };
    } else {
        return async dispatch => {
            try {
                dispatch({ type: actionTypes.GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_REQUEST });
                const res = await Service.getExportSettingsForSmartChartRecom(params);
                if (res && res.status === 200) {
                    const propertyList = res.data;
                    dispatch({
                        type: actionTypes.GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_SUCCESS,
                        response: propertyList
                    });
                } else {
                    dispatch({
                        type: actionTypes.GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_FAILURE,
                        error: res.response && res.response.data
                    });
                }
            } catch (e) {
                dispatch({
                    type: actionTypes.GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_FAILURE,
                    error: e.response && e.response.data
                });
            }
        };
    }
};

const createReportFromCoreReport = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.CREATE_REPORT_FROM_CORE_REPORT_REQUEST });
            const res = await Service.createReportFromCoreReport(params);
            if (res && res.status === 201) {
                const smartChartData = res.data;
                dispatch({
                    type: actionTypes.CREATE_REPORT_FROM_CORE_REPORT_SUCCESS,
                    response: smartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.CREATE_REPORT_FROM_CORE_REPORT_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.CREATE_REPORT_FROM_CORE_REPORT_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};
const getCaColumns = (params, type) => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_CA_COLUMNS_REQUEST });
            const res = await Service.getCaColumns(params, type);
            if (res && res.status === 200) {
                const smartChartData = res.data;
                dispatch({
                    type: actionTypes.GET_CA_COLUMNS_SUCCESS,
                    response: smartChartData
                });
            } else {
                dispatch({
                    type: actionTypes.GET_CA_COLUMNS_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_CA_COLUMNS_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};
const getSmartchartAssetExportSettings = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_EXPORT_SETTINGS_ASSET_REQUEST });
            const res = await Service.getSmartchartAssetExportSettings(params);
            if (res && res.status === 200) {
                const propertyList = res.data;
                dispatch({
                    type: actionTypes.GET_EXPORT_SETTINGS_ASSET_SUCCESS,
                    response: propertyList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_EXPORT_SETTINGS_ASSET_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_EXPORT_SETTINGS_ASSET_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};
const getExportPropertyListForSmartChartAsset = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_EXPORT_PROPERTY_LIST_ASSET_REQUEST });
            const res = await Service.getExportPropertyListForSmartChartAsset(params);
            if (res && res.status === 200) {
                const propertyList = res.data;
                dispatch({
                    type: actionTypes.GET_EXPORT_PROPERTY_LIST_ASSET_SUCCESS,
                    response: propertyList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_EXPORT_PROPERTY_LIST_ASSET_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_EXPORT_PROPERTY_LIST_ASSET_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const getExportTemplateListForSmartChartAsset = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_EXPORT_TEMPLATE_LIST_ASSET_REQUEST });
            const res = await Service.getExportTemplateListForSmartChartAsset(params);
            if (res && res.status === 200) {
                const propertyList = res.data;
                dispatch({
                    type: actionTypes.GET_EXPORT_TEMPLATE_LIST_ASSET_SUCCESS,
                    response: propertyList
                });
            } else {
                dispatch({
                    type: actionTypes.GET_EXPORT_TEMPLATE_LIST_ASSET_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.GET_EXPORT_TEMPLATE_LIST_ASSET_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};
const saveExportSettingsForSmartChartAsset = params => {
    let exportData = new FormData();
    if (params.template) exportData.append("template", params.template);
    exportData.append("settings_id", params.settings_id);
    exportData.append("template_property", params.template_property);
    exportData.append("properties", JSON.stringify(params.properties));
    exportData.append("client_id", params.clientId);
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.ADD_EXPORT_SETTINGS_ASSET_REQUEST });
            const res = await Service.saveExportSettingsForSmartChartAsset(exportData);
            if (res && res.status === 200) {
                const responseData = res.data;
                dispatch({
                    type: actionTypes.ADD_EXPORT_SETTINGS_ASSET_SUCCESS,
                    response: responseData
                });
            } else {
                dispatch({
                    type: actionTypes.ADD_EXPORT_SETTINGS_ASSET_FAILURE,
                    error: res.response && res.response.data
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.ADD_EXPORT_SETTINGS_ASSET_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

export default {
    getSmartChartMasterFilterDropDown,
    exportSmartChartData,
    saveSmartChartData,
    getExportedSmartChartList,
    deleteSmartChartReport,
    uploadDocsForSmartReport,
    getUploadedDocList,
    updateSmartReportData,
    getTemplatePropertiesList,
    getTemplateList,
    deleteUserDocs,
    getClientDropDownData,
    updateDocOrder,
    updateUserDocData,
    updateSmartChartProperty,
    getSmartChartPropertyList,
    getSmartChartPropertyById,
    updateSmartReportsEntityParams,
    getUploadedImageList,
    saveAndExportSmartChartData,
    deleteSmartChartReportTemplate,
    getReportsByTemplateList,
    assignImagesToSmartCharts,
    lockSmartChartTemplate,
    getCAbandFilterDropDownData,
    addSmartChartToFavorites,
    updateEntityFilterParams,
    clearReducerData,
    getCAbandTradeFilterDropDownData,
    updateSmartReportTemplateEntityParams,
    getUsersByClient,
    assignFavoriteToUsers,
    clearUserByClientData,
    getExportPropertyListForSmartChartRecom,
    getExportTemplateListForSmartChartRecom,
    getExportSettingsForSmartChartRecom,
    saveExportSettingsForSmartChartRecom,
    clearPropertyByIdData,
    getCAbandSystemFilterDropDownData,
    createSkeletonFromTemplate,
    getExportSettingsForAllSmartChartRecomTiles,
    createReportFromCoreReport,
    getSmartChartSourceTemplete,
    updateCoreReport,
    getCaColumns,
    getSmartchartAssetExportSettings,
    getExportTemplateListForSmartChartAsset,
    getExportPropertyListForSmartChartAsset,
    saveExportSettingsForSmartChartAsset
};
