import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import ChartSection from "./ChartSection";
import { getDisplayNameFromKey } from "../utils";
import FilterIcon from "../..//../assets/img/dashboard/filter-butn.svg";

const ChartMain = ({
    getSmartChartMasterFilterDropDown,
    projectsDropdownData,
    projectId,
    setSmartChartData,
    chartData,
    masterFilterList,
    openChartItemConfigModal,
    chartKeys = [],
    copyChartData,
    entity,
    selectedClient,
    isEdit,
    toggleImageListForBandModal,
    toggleImageBandDeleteConfirmationModal,
    editImageBand,
    addDocumentTile,
    toggleAddCAband,
    updatePageBreakConfig,
    addOnepagerTile,
    addOnepagerAssetTile,
    isExcelExport,
    isSkeletonView,
    requiredFields,
    setFilterOpenModal
}) => {
    const [filterOpen, setFilterOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    let filterCount = 0;
    const data = Object.keys(chartData[entity]?.band1?.mfilter)
        .filter(key => key !== "client_ids" && key !== "start_year" && key !== "update_fci" && chartData[entity]?.band1?.mfilter[key] !== "")
        .map(key => ({ [key]: chartData[entity]?.band1?.mfilter[key] }));
    data.forEach(obj => {
        Object.entries(obj).forEach(([key, value]) => {
            if (Array.isArray(value) && value.length > 0) {
                filterCount++;
            } else if (typeof value === "object" && value !== null) {
                Object.values(value).forEach(innerValue => {
                    if (innerValue && innerValue.key !== "") {
                        filterCount++;
                    }
                });
            } else if (key === "end_year" && value) {
                filterCount++;
            }
        });
    });

    return (
        <div class="card">
            <div className={`card-header ${isEdit && Object.keys(chartData[entity].band1.type).length ? "bg-th-filtered" : ""}`}>
                <div className="icon">
                    <img src="/img/icon-squre.svg" alt="icon" />
                </div>
                <div
                    className="heading-text"
                    aria-expanded={!isCollapsed}
                    aria-controls={`collapseOne${entity}`}
                    onClick={() => {
                        setIsCollapsed(!isCollapsed);
                        entity === "project" && setShowFilter(!showFilter);
                    }}
                >
                    <h3>{getDisplayNameFromKey(entity)}</h3>
                    {entity === "project" && showFilter && (
                        <button
                            className="filter-button blue-common-btn cursor-pointer btn-add-img ml-2"
                            data-tip="View Filters"
                            data-for="dashboard-icons-right"
                            onClick={e => {
                                e.stopPropagation();
                                setFilterOpen(true);
                            }}
                        >
                            <img src={FilterIcon} alt="Filter Icon" />
                            <span> Filters</span>
                            {filterCount > 0 && <span className="notifyTxt">{filterCount}</span>}
                        </button>
                    )}
                </div>

                <div className="check-box-area">
                    <label className="container-check green-check">
                        Select All Charts
                        <input
                            type="checkbox"
                            name="is_bold"
                            checked={chartData?.[entity]?.band1?.type && chartKeys.length === Object.keys(chartData[entity].band1.type).length}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSmartChartData("selectAll", {
                                    isSelectAll: e.target.checked,
                                    bandName: "band1",
                                    entity: entity
                                });
                            }}
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
            </div>
            <div id={`collapseOne${entity}`} className={`collapse ${!isCollapsed ? "show" : ""}`} aria-labelledby={`headingOne${entity}`}>
                <div className="card-body">
                    <div className="btn-sec">
                        <button
                            className={`btn btn-file-add  ml-2 ${isExcelExport ? "disabled-btn" : ""}`}
                            onClick={() => !isExcelExport && addDocumentTile(entity)}
                        >
                            <img src="/img/blue-pls.svg" /> User Document
                        </button>
                        {entity === "project" ? (
                            <>
                                <button className={`btn btn-file-add ml-2`} onClick={() => toggleAddCAband(entity)}>
                                    <img src="/img/blue-pls.svg" /> Comparative Analysis Band
                                </button>
                                <button
                                    className={`btn btn-file-add ml-2`}
                                    onClick={() =>
                                        setSmartChartData("custom_tile", {
                                            bandName: "band1",
                                            entity: entity,
                                            customTileKey: "normal_recom" + uuid(),
                                            customTileData: {
                                                band1: {
                                                    mfilter: { recommendations: [] },
                                                    settings_id: "",
                                                    name: "Recommendation Table",
                                                    remove_section_break: false
                                                }
                                            }
                                        })
                                    }
                                >
                                    <img src="/img/blue-pls.svg" /> Recommendation Table
                                </button>
                                <button
                                    className={`btn btn-file-add ml-2 ${isExcelExport ? "disabled-btn" : ""}`}
                                    onClick={() =>
                                        !isExcelExport &&
                                        setSmartChartData("custom_tile", {
                                            bandName: "band1",
                                            entity: entity,
                                            customTileKey: "multi_recommendation" + uuid(),
                                            customTileData: {
                                                with_notes: true,
                                                name: "",
                                                remove_section_break: false,
                                                recommendations: [],
                                                key_name: {}
                                            }
                                        })
                                    }
                                >
                                    <img src="/img/blue-pls.svg" /> Recommendation Summary
                                </button>

                                <button
                                    className={`btn btn-file-add ml-2 ${isExcelExport ? "disabled-btn" : ""}`}
                                    onClick={() => !isExcelExport && addOnepagerTile()}
                                >
                                    <img src="/img/blue-pls.svg" /> Recommendation One Pager
                                </button>
                                <button
                                    className={`btn btn-file-add ml-2 ${
                                        isExcelExport || Object.keys(chartData[entity]?.band1?.type ?? {}).some(e => e.startsWith("asset-onepager"))
                                            ? "disabled-btn"
                                            : ""
                                    }`}
                                    onClick={() => !isExcelExport && addOnepagerAssetTile("project")}
                                    disabled={Object.keys(chartData[entity]?.band1?.type ?? {}).some(e => e.startsWith("asset-onepager"))}
                                >
                                    <img src="/img/blue-pls.svg" /> Asset One Pager
                                </button>
                                <button
                                    className={`btn btn-file-add ml-2 ${isExcelExport ? "disabled-btn" : ""}`}
                                    onClick={() =>
                                        !isExcelExport &&
                                        setSmartChartData("custom_tile", {
                                            bandName: "band1",
                                            entity: entity,
                                            customTileKey: "memo_field_" + uuid(),
                                            customTileData: {
                                                name: "Text Band",
                                                remove_section_break: false,
                                                key_name: {}
                                            }
                                        })
                                    }
                                >
                                    <img src="/img/blue-pls.svg" />
                                    Text Band
                                </button>
                            </>
                        ) : null}

                        {entity === "assets" && (
                            <button
                                className={`btn btn-file-add ml-2 ${isExcelExport ? "disabled-btn" : ""}`}
                                onClick={() => !isExcelExport && addOnepagerAssetTile("assets")}
                            >
                                <img src="/img/blue-pls.svg" /> Asset One Pager
                            </button>
                        )}
                        <button
                            className={`btn btn-file-add ml-2 ${isExcelExport ? "disabled-btn" : ""}`}
                            onClick={() =>
                                !isExcelExport &&
                                setSmartChartData("custom_tile", {
                                    bandName: "band1",
                                    entity: entity,
                                    customTileKey: "list_images" + uuid(),
                                    customTileData: { data: [], config: { type: 1 } }
                                })
                            }
                        >
                            <img src="/img/blue-pls.svg" /> Image Band
                        </button>
                    </div>
                    {Object.keys(chartData[entity]).map((band, index) => {
                        return (
                            <ChartSection
                                currentBand={band}
                                currentBandIndex={index}
                                getSmartChartMasterFilterDropDown={getSmartChartMasterFilterDropDown}
                                projectsDropdownData={projectsDropdownData}
                                projectId={projectId}
                                setSmartChartData={setSmartChartData}
                                masterFilterList={masterFilterList}
                                chartSectionData={chartData[entity][band]}
                                openChartItemConfigModal={openChartItemConfigModal}
                                chartKeys={chartKeys}
                                copyChartSectionData={copyChartData?.[entity][band]}
                                entity={entity}
                                selectedClient={selectedClient}
                                toggleImageListForBandModal={toggleImageListForBandModal}
                                toggleImageBandDeleteConfirmationModal={toggleImageBandDeleteConfirmationModal}
                                editImageBand={editImageBand}
                                isEdit={isEdit}
                                updatePageBreakConfig={updatePageBreakConfig}
                                isExcelExport={isExcelExport}
                                isSkeletonView={isSkeletonView}
                                requiredFields={requiredFields}
                                setFilterOpenModal={setFilterOpenModal}
                                filterOpen={filterOpen}
                                setFilterOpen={setFilterOpen}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
export default ChartMain;
