import React from "react";
import NumberFormat from "react-number-format";

export const BuildingStatistics = ({
    sidePanelValues,
    totalCsp,
    disableButton,
    activeSection,
    handleBuildingView,
    handleRecommentationView,
    handleRegionView,
    handleSiteView,
    handleView
}) => {
    return (
        <>
            <div className="list-ara">
                <div className="lst">
                    <div className="sm-hed">Total CSP</div>

                    <div className="val-hed">
                        <span>
                            {sidePanelValues ? (
                                <NumberFormat
                                    value={parseFloat((totalCsp ? totalCsp : 1) / 1000000).toFixed(2)}
                                    suffix={"M"}
                                    thousandSeparator={true}
                                    displayType={"text"}
                                    prefix={"$ "}
                                />
                            ) : (
                                0
                            )}
                        </span>
                    </div>
                </div>
                <div className="lst">
                    <div className="sm-hed">Total SF</div>
                    <div className="val-hed">
                        <span>
                            {sidePanelValues ? (
                                <NumberFormat
                                    value={sidePanelValues.total_sf ? sidePanelValues.total_sf : 0}
                                    thousandSeparator={true}
                                    displayType={"text"}
                                />
                            ) : (
                                0
                            )}
                        </span>
                    </div>
                </div>

                <div className="lst">
                    <div className="sm-hed">Total CRV</div>
                    <div className="val-hed">
                        <span>
                            {sidePanelValues ? (
                                <NumberFormat
                                    value={parseFloat(
                                        (sidePanelValues.total_replacement_cost ? sidePanelValues.total_replacement_cost : 1) / 1000000
                                    ).toFixed(2)}
                                    suffix={"M"}
                                    thousandSeparator={true}
                                    displayType={"text"}
                                    prefix={"$ "}
                                />
                            ) : (
                                0
                            )}
                        </span>
                    </div>
                </div>
                <div
                    className={`${
                        !disableButton && sidePanelValues.recommendations
                            ? activeSection === "recommendation"
                                ? "lst cursor-pointer btn-clickable active-sectn"
                                : "lst cursor-pointer btn-clickable"
                            : "lst"
                    }`}
                    data-delay-show="500"
                    data-tip={`Click To View Recommendations`}
                    data-effect="solid"
                    data-for="filter-icons"
                    data-place="right"
                    data-background-color="#007bff"
                    onClick={!disableButton && sidePanelValues.recommendations ? () => handleRecommentationView() : null}
                >
                    <div>
                        <div className="sm-hed">Recommendations</div>
                        <div className="val-hed">
                            <span>
                                {sidePanelValues ? (
                                    <NumberFormat
                                        value={sidePanelValues.recommendations ? sidePanelValues.recommendations : 0}
                                        thousandSeparator={true}
                                        displayType={"text"}
                                    />
                                ) : (
                                    0
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="list-ara">
                <div
                    className={`${
                        !disableButton && sidePanelValues.projects
                            ? activeSection === "project"
                                ? "lst cursor-pointer btn-clickable d-flex active-sectn"
                                : "lst cursor-pointer btn-clickable d-flex"
                            : "lst d-flex"
                    }`}
                    data-delay-show="500"
                    data-tip={!disableButton && sidePanelValues.projects !== 0 ? `Click To View Projects` : ""}
                    data-effect="solid"
                    data-for="filter-icons"
                    data-place="bottom"
                    data-background-color="#007bff"
                    onClick={!disableButton && sidePanelValues.projects !== 0 ? () => handleView("projects", "Projects") : null}
                >
                    <div className="cursor-hand">
                        <div className="sm-hed mr-2 flex-grow-1">FCA Projects</div>
                        <div className="val-hed">{sidePanelValues ? sidePanelValues.projects : 0}</div>
                    </div>
                </div>
                <div
                    className={`${
                        !disableButton && sidePanelValues.regions !== 0
                            ? activeSection === "region"
                                ? "lst cursor-pointer btn-clickable d-flex active-sectn"
                                : "lst cursor-pointer btn-clickable d-flex"
                            : "lst d-flex"
                    }`}
                    data-delay-show="500"
                    data-tip={`Click To View Regions `}
                    data-effect="solid"
                    data-for="filter-icons"
                    data-place="right"
                    data-background-color="#007bff"
                    onClick={!disableButton && sidePanelValues.regions !== 0 ? () => handleRegionView() : null}
                >
                    <div>
                        <div
                            className="sm-hed mr-2 flex-grow-1"
                            onClick={() => handleView("regions", "Regions", { region_ids: sidePanelValues.region_ids })}
                        >
                            Regions
                        </div>
                        <div className="val-hed">{sidePanelValues ? sidePanelValues.regions : 0}</div>
                    </div>
                </div>
                <div
                    className={`${
                        !disableButton && sidePanelValues.sites !== 0
                            ? activeSection === "site"
                                ? "lst cursor-pointer btn-clickable d-flex active-sectn"
                                : "lst cursor-pointer btn-clickable d-flex"
                            : "lst d-flex"
                    }`}
                    data-delay-show="500"
                    data-tip={`Click To View Sites `}
                    data-effect="solid"
                    data-for="filter-icons"
                    data-place="bottom"
                    data-background-color="#007bff"
                    onClick={!disableButton && sidePanelValues.sites !== 0 ? () => handleSiteView() : null}
                >
                    <div>
                        <div className="sm-hed mr-2 flex-grow-1">Sites</div>
                        <div className="val-hed">{sidePanelValues ? sidePanelValues.sites : 0}</div>
                    </div>
                </div>
                <div
                    className={`${
                        !disableButton && sidePanelValues.buildings !== 0
                            ? activeSection === "building"
                                ? "lst cursor-pointer btn-clickable d-flex active-sectn"
                                : "lst cursor-pointer btn-clickable d-flex"
                            : "lst d-flex"
                    }`}
                    data-delay-show="500"
                    data-tip={`Click To View Buildings `}
                    data-effect="solid"
                    data-for="filter-icons"
                    data-place="right"
                    data-background-color="#007bff"
                    onClick={!disableButton && sidePanelValues.buildings !== 0 ? () => handleBuildingView() : null}
                >
                    <div>
                        <div className="sm-hed mr-2 flex-grow-1" onClick={() => handleBuildingView()}>
                            Bulidings
                        </div>
                        <div className="val-hed">{sidePanelValues ? sidePanelValues.buildings : 0}</div>
                    </div>
                </div>
            </div>
        </>
    );
};
