import React from "react";
import NumberFormat from "react-number-format";

export const Band = ({
    fieldType = "text",
    label,
    fieldKey,
    value,
    onChange,
    bandShown,
    bandId,
    handleBandClick,
    fieldItem,
    isFieldLocked,
    decimalScaleValue = null
}) => {
    const isLocked = isFieldLocked && isFieldLocked(fieldKey);
    let props = {};
    if (decimalScaleValue) props = { ...props, decimalScale: decimalScaleValue };
    return (
        <div className={`txt-rcm ${fieldItem.className ? fieldItem.className : ""}`} key={fieldKey}>
            <div className="content-inp-card">
                <div
                    className="form-group"
                    data-target={bandShown ? `#${bandId}` : "false"}
                    aria-expanded={bandShown ? true : "false"}
                    onClick={() => handleBandClick()}
                    data-tip={isLocked ? "This field is locked and cannot be edited" : ""}
                    data-for="recommendation_details"
                >
                    <label>{label}</label>
                    {fieldType === "text" ? (
                        <input
                            type="text"
                            autoComplete={"off"}
                            className={`custom-input form-control ${isLocked ? "grayed-out" : ""}`}
                            placeholder={label}
                            disabled={isLocked}
                            value={value}
                            name={fieldKey}
                            onChange={e => onChange(e.target.name, e.target.value)}
                        />
                    ) : (
                        <NumberFormat
                            autoComplete={"off"}
                            className={`form-control ${fieldItem.isReadOnly ? "cursor-diabled" : ""} ${isLocked ? "grayed-out" : ""}`}
                            placeholder={label}
                            thousandSeparator={true}
                            prefix={fieldItem.isCost ? "$ " : ""}
                            value={value || ""}
                            disabled={fieldItem.isReadOnly || isLocked}
                            displayType={"input"}
                            onValueChange={values => {
                                const { value } = values;
                                onChange(fieldKey, value);
                            }}
                            {...props}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
