import React, { useEffect, useState } from "react";
import EmailForm from "./components/RegisterEmailForm";
import { useHistory, useLocation, useParams, Redirect } from "react-router-dom";
import { CapitalRequestForm } from "./components/CapitalRequestForm";
import { CapitalRequestMain } from "./components/CapitalRequestMain";
import CapitalRequestView from "./components/CapitalRequestView";
import { Header } from "./components/Header";
import qs from "query-string";

import "./capitalRequest.css";
const CapitalRequest = props => {
    const { section } = useParams();
    const location = useLocation();
    const initialPath = location.pathname?.split("/")?.[1];
    const query = qs.parse(location?.search || "");
    const [freezeActions, setFreezeActions] = useState(false);

    useEffect(() => {
        if (section === "info" && !localStorage.getItem("crRequestorToken")) {
            localStorage.setItem("crPrevRoute", location.pathname);
        }
    }, [section]);

    return (
        <>
            {props?.isProjectView || initialPath === "project-capital-request" ? (
                <>
                    {(section === "add" || section === "edit") && !props?.isRecommendationView ? (
                        <CapitalRequestForm isProjectView {...props} />
                    ) : section === "info" ? (
                        <CapitalRequestView isProjectView isRecommendationView={query?.from_rec || false} {...props} />
                    ) : (
                        <CapitalRequestMain isProjectView {...props} />
                    )}
                </>
            ) : (
                <>
                    {!localStorage.getItem("crRequestorToken") ? (
                        <Redirect to={{ pathname: "/" }} />
                    ) : (
                        <div class="error-sec">
                            <div className="dashboard-outer">
                                <div className="outer-detail outer-height" id="outr">
                                    <div className="right-panel-section expandnavbarr" id="main">
                                        <>
                                            <Header />
                                            {section === "add" || section === "edit" ? (
                                                <CapitalRequestForm />
                                            ) : section === "info" ? (
                                                <CapitalRequestView freezeActions={freezeActions} />
                                            ) : (
                                                <CapitalRequestMain freezeActions={freezeActions} setFreezeActions={setFreezeActions} />
                                            )}
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default CapitalRequest;
