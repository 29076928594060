import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import NumberFormat from "react-number-format";

import { addToBreadCrumpData, popBreadCrumpData, findPrevPathFromBreadCrumpData } from "../../../config/utils";
import ConfirmationModal from "./ConfirmationModal";
import EditHistory from "../../region/components/EditHistory";
import Portal from "./Portal";
import ShowHelperModal from "../../helper/components/ShowHelperModal";
import { ImageBand } from "./ImageComponents/ImageBand";

class BasicDetails extends Component {
    state = {
        isHistoryView: false,
        showConfirmModalLog: false,
        selectedLog: "",
        logChanges: {},
        showHelperModal: false,
        selectedHelperItem: {},
        showConfirmModal: false,
        selectedBudgetPriorityData: false,
        showImageModal: false,
        selectedFMP: false,
        showFMPConfirmModal: false,
        showIrConfirmModal: false,
        selectedIr: false,
        showRLConfirmModal: false,
        selectedRL: false
    };

    setToolTip(basicDetails, name, className) {
        return (
            <div
                className={`txt-rcm ${name === "Installed Year" ? "wid-tw-rcm" : ""} ${className ? "" : "col-md-3 basic-box"}`}
                data-tip={basicDetails && basicDetails.length > 20 ? basicDetails || "" : ""}
                data-multiline={true}
                data-place="left"
                data-effect="solid"
                data-background-color="#007bff"
                data-for="recommandation_Asset"
            >
                <div className={`${className ? "txt-dtl" : "content-block-card"}`}>
                    <h4 className="m-label">{name}</h4>
                    <h3 className="m-details mt-2">
                        {basicDetails && basicDetails.length > 20 ? basicDetails.substring(0, 20) + "..." : basicDetails || "-"}
                    </h3>
                </div>
            </div>
        );
    }

    handleRestoreLog = async (id, choice, changes) => {
        await this.setState({
            showConfirmModalLog: true,
            selectedLog: id,
            logChanges: changes
        });
    };

    renderConfirmationModalLog = () => {
        const { showConfirmModalLog, logChanges } = this.state;
        if (!showConfirmModalLog) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to restore this log?"}
                        message={logChanges}
                        onNo={() => this.setState({ showConfirmModalLog: false })}
                        onYes={this.restoreLogOnConfirm}
                        isRestore={true}
                        type={"restore"}
                        isLogChange={true}
                    />
                }
                onCancel={() => this.setState({ showConfirmModalLog: false })}
            />
        );
    };

    restoreLogOnConfirm = async () => {
        const { selectedLog } = this.state;
        await this.props.HandleRestoreSettingsLog(selectedLog);
        await this.props.refreshinfoDetails();
        // await this.getLogData(this.props.match.params.id)
        // await this.props.getMenuItems();
        this.setState({
            showConfirmModalLog: false,
            selectedLog: null,
            isHistoryView: !this.state.isHistoryView
        });
    };

    showHelperModal = async (item, subItem) => {
        await this.setState({
            showHelperModal: true,
            selectedHelperItem: {
                item,
                subItem
            }
        });
    };

    renderUploadHelperModal = () => {
        const { showHelperModal, selectedHelperItem } = this.state;
        if (!showHelperModal) return null;
        return (
            <Portal
                body={<ShowHelperModal selectedHelperItem={selectedHelperItem} onCancel={() => this.setState({ showHelperModal: false })} />}
                onCancel={() => this.setState({ showHelperModal: false })}
            />
        );
    };

    findPriorityText = (element, options) => {
        let text = "-";
        let priorityObj = options.find(option => parseInt(option.value) === element);
        if (priorityObj) {
            text = priorityObj.name;
        }
        return text;
    };

    updateBudgetPriority = () => {
        const { selectedBudgetPriorityData } = this.state;
        this.setState({ showConfirmModal: false });
        this.props.updateBudget({ budget_priority: selectedBudgetPriorityData ? "yes" : "no" }, this.props.match.params.id);
    };

    updateFMP = () => {
        const { selectedFMP } = this.state;
        this.setState({ showFMPConfirmModal: false });
        this.props.updateFMP({ fmp: selectedFMP ? "yes" : "no" }, this.props.match.params.id);
    };
    updateIr = () => {
        const { selectedIr } = this.state;
        this.setState({ showIrConfirmModal: false });
        this.props.updateIR({ infrastructure_request: selectedIr ? "yes" : "no" }, this.props.match.params.id);
    };
    updateRL = () => {
        const { selectedRL } = this.state;
        this.setState({ showRLConfirmModal: false });
        this.props.updateRL({ red_line: selectedRL ? "yes" : "no" }, this.props.match.params.id);
    };

    showConfirmation = e => {
        this.setState({ showConfirmModal: true, selectedBudgetPriorityData: e.target.checked });
    };

    showFMPConfirmation = e => {
        this.setState({ showFMPConfirmModal: true, selectedFMP: e.target.checked });
    };
    showIrConfirmation = e => {
        this.setState({ showIrConfirmModal: true, selectedIr: e.target.checked });
    };

    showRLConfirmation = e => {
        this.setState({ showRLConfirmModal: true, selectedRL: e.target.checked });
    };

    renderConfirmationModal = () => {
        const { showConfirmModal, selectedBudgetPriorityData } = this.state;
        if (!showConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={
                            selectedBudgetPriorityData === false
                                ? "Do you want to remove from Budget Priority?"
                                : "Do you want to add to Budget Priority "
                        }
                        // message={"This action cannot be reverted, are you sure ?"}
                        onNo={() => this.setState({ showConfirmModal: false })}
                        onYes={this.updateBudgetPriority}
                    />
                }
                onCancel={() => this.setState({ showConfirmModal: false })}
            />
        );
    };

    renderFMPConfirmationModal = () => {
        const { showFMPConfirmModal, selectedFMP } = this.state;
        if (!showFMPConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={
                            selectedFMP === false
                                ? "Do you want to remove from Facility Master Plan ?"
                                : "Do you want to add to Facility Master Plan ?"
                        }
                        onNo={() => this.setState({ showFMPConfirmModal: false })}
                        onYes={this.updateFMP}
                    />
                }
                onCancel={() => this.setState({ showFMPConfirmModal: false })}
            />
        );
    };
    renderIrConfirmationModal = () => {
        const { showIrConfirmModal, selectedIr } = this.state;
        if (!showIrConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={
                            selectedIr === false
                                ? "Do you want to remove from Facility Master Plan ?"
                                : "Do you want to add to Facility Master Plan ?"
                        }
                        onNo={() => this.setState({ showIrConfirmModal: false })}
                        onYes={this.updateIr}
                    />
                }
                onCancel={() => this.setState({ showIrConfirmModal: false })}
            />
        );
    };

    renderRLConfirmationModal = () => {
        const { showRLConfirmModal, selectedRL } = this.state;
        if (!showRLConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={selectedRL === false ? "Do you want to remove from Redlining ?" : "Do you want to add to Redlining ?"}
                        onNo={() => this.setState({ showRLConfirmModal: false })}
                        onYes={this.updateRL}
                    />
                }
                onCancel={() => this.setState({ showRLConfirmModal: false })}
            />
        );
    };

    openImageModal = () => {
        this.setState({
            showImageModal: true
        });
    };

    render() {
        const {
            basicDetails,
            getAllSettingsLogs,
            logData,
            handlePerPageChangeHistory,
            handlePageClickHistory,
            handleGlobalSearchHistory,
            globalSearchKeyHistory,
            handleDeleteLog,
            historyPaginationParams,
            historyParams,
            permissions,
            logPermission,
            isNarrativeRecommendation,
            closeInfoPage,
            hasDelete = true,
            hasEdit = true,
            hasLogView = true,
            hasLogDelete = true,
            hasLogRestore = true,
            hasInfoPage = true,
            hasCreate = true
        } = this.props;
        const { isHistoryView } = this.state;
        const is_infrastructure_request_user = localStorage.getItem("infrastructure_request_user") === "yes" ? true : false;
        const is_fmp_user = localStorage.getItem("fmp_user") === "yes" ? true : false;

        return (
            <React.Fragment>
                <ReactTooltip id="recommandation_Asset" effect="solid" className="rc-tooltip-custom-class" backgroundColor="#007bff" />
                <div className="tab-active location-sec recom-sec add-recommendation asset-viewer-outer">
                    <div className="recom-top-nav-sec">
                        <div className="check-area-top mr-3">
                            <label htmlFor="">
                                <span className="label-txt"> ID:</span> <span className="label-detl">{basicDetails.code || "-"}</span>{" "}
                            </label>
                            <label htmlFor="">
                                <span className="label-txt"> Type: </span>
                                <span className="label-detl">
                                    {basicDetails.recommendation_type
                                        ? basicDetails.recommendation_type?.charAt(0)?.toUpperCase() + basicDetails.recommendation_type?.slice(1)
                                        : "-"}
                                </span>
                            </label>
                        </div>
                        <div className="otr-edit-delte text-right ed-dl d-flex align-items-center view-narrative-report">
                            <div className="budget-pr mr-2">
                                <label class={`container-check ${basicDetails?.locked ? "cursor-diabled" : ""}`}>
                                    <input
                                        type="checkbox"
                                        disabled={basicDetails?.locked}
                                        checked={basicDetails.red_line === "yes" ? true : false}
                                        onChange={this.showRLConfirmation}
                                    ></input>
                                    <span class="checkmark"></span>Redlining
                                </label>
                            </div>
                            <div className="budget-pr mr-2">
                                <label class={`container-check ${is_infrastructure_request_user || basicDetails?.locked ? "cursor-diabled" : ""}`}>
                                    <input
                                        type="checkbox"
                                        checked={basicDetails.infrastructure_request === "yes" ? true : false}
                                        onChange={this.showIrConfirmation}
                                        disabled={is_infrastructure_request_user || basicDetails?.locked}
                                    ></input>
                                    <span class="checkmark"></span>Capital Request
                                </label>
                            </div>
                            <div className="budget-pr mr-3">
                                <label class={`container-check ${basicDetails?.locked ? "cursor-diabled" : ""}`}>
                                    <input
                                        type="checkbox"
                                        disabled={basicDetails?.locked}
                                        checked={basicDetails.budget_priority === "yes" ? true : false}
                                        onChange={this.showConfirmation}
                                    ></input>
                                    <span class="checkmark"></span>Budget Priority
                                </label>
                            </div>
                            <div className="budget-pr mr-3">
                                <label class={`container-check ${is_fmp_user || basicDetails?.locked ? "cursor-diabled" : ""}`}>
                                    <input
                                        type="checkbox"
                                        disabled={is_fmp_user || basicDetails?.locked}
                                        checked={basicDetails.fmp === "yes" ? true : false}
                                        onChange={this.showFMPConfirmation}
                                    ></input>
                                    <span class="checkmark"></span>Facility Master Plan
                                </label>
                            </div>
                            <div className="reco-main-icon-link">
                                {isNarrativeRecommendation ? (
                                    <>
                                        {/* <span
                                        onClick={() => {
                                            this.props.downloadPdfReport({
                                                project_id: basicDetails?.project?.id,
                                                building_id: basicDetails?.building?.id,
                                                subsystem_id: basicDetails?.sub_system?.id
                                            });
                                        }}
                                        className="edit-icn-bx icon-btn-sec"
                                        data-tip=" View Narrative Report"
                                        data-for="recommandation_Asset"
                                        data-place="top"
                                    >
                                        <img src="/img/narrative-rec.svg" alt="" />
                                    </span> */}
                                        <span
                                            onClick={closeInfoPage}
                                            className="edit-icn-bx icon-btn-sec"
                                            data-tip="Close"
                                            data-for="recommandation_Asset"
                                            data-place="top"
                                        >
                                            <img src="/img/close-rec.svg" alt="" />
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        {/* <span
                                        onClick={() => {
                                            this.props.downloadPdfReport({
                                                project_id: basicDetails?.project?.id,
                                                building_id: basicDetails?.building?.id,
                                                subsystem_id: basicDetails?.sub_system?.id
                                            });
                                        }}
                                        className="edit-icn-bx icon-btn-sec"
                                        data-tip=" View Narrative Report"
                                        data-for="recommandation_Asset"
                                        data-place="top"
                                    >
                                        <img src="/img/narrative-rec.svg" alt="" />
                                    </span> */}
                                        {basicDetails && basicDetails.locked === true
                                            ? ""
                                            : hasLogView && (
                                                  <span
                                                      onClick={() => {
                                                          this.setState({ isHistoryView: !this.state.isHistoryView });
                                                      }}
                                                      className="edit-icn-bx icon-btn-sec"
                                                      data-tip={isHistoryView ? "View Details" : "View History"}
                                                      data-for="recommandation_Asset"
                                                      data-place="top"
                                                  >
                                                      <img src="/img/history-rec.svg" alt="" />
                                                  </span>
                                              )}
                                        <span
                                            onClick={() => {
                                                popBreadCrumpData();
                                                popBreadCrumpData();
                                                this.props.history.push(findPrevPathFromBreadCrumpData());
                                            }}
                                            className="edit-icn-bx icon-btn-sec"
                                            data-tip="Close"
                                            data-for="recommandation_Asset"
                                            data-place="top"
                                        >
                                            <img src="/img/close-rec.svg" alt="" />
                                        </span>
                                        {basicDetails && basicDetails.locked === true ? (
                                            ""
                                        ) : (
                                            <>
                                                {!basicDetails.deleted
                                                    ? hasEdit && (
                                                          <span
                                                              className="edit-icn-bx icon-btn-sec"
                                                              onClick={() => {
                                                                  addToBreadCrumpData({
                                                                      key: "edit",
                                                                      name: `Edit ${this.props.location.pathname.split("/")[1]}`,
                                                                      path: `/${this.props.location.pathname.split("/")[1]}/edit/${
                                                                          this.props.match.params.id
                                                                      }?p_id=${basicDetails?.project?.id}&c_id=${
                                                                          basicDetails?.client?.id
                                                                      }&active_tab=asset`
                                                                  });
                                                                  this.props.history.push(
                                                                      `/${this.props.location.pathname.split("/")[1]}/edit/${
                                                                          this.props.match.params.id
                                                                      }?p_id=${basicDetails?.project?.id}&c_id=${
                                                                          basicDetails?.client?.id
                                                                      }&active_tab=asset`
                                                                  );
                                                              }}
                                                              data-tip="Edit"
                                                              data-for="recommandation_Asset"
                                                              data-place="top"
                                                          >
                                                              <img src="/img/edit-rec.svg" alt="" />
                                                          </span>
                                                      )
                                                    : hasEdit && (
                                                          <span
                                                              onClick={() => this.props.restoreRecommendation()}
                                                              className="edit-icn-bx icon-btn-sec"
                                                              data-tip="Restore"
                                                              data-for="recommandation_Asset"
                                                              data-place="top"
                                                          >
                                                              <img src="/img/restore-reco.svg" alt="" />
                                                          </span>
                                                      )}
                                                {hasDelete && (
                                                    <span
                                                        className="edit-icn-bx icon-btn-sec del"
                                                        onClick={() => this.props.deleteRecommendation(basicDetails.deleted || false)}
                                                        data-tip="Delete"
                                                        data-for="recommandation_Asset"
                                                        data-place="top"
                                                    >
                                                        <img src="/img/delete-icon.svg" alt="" />
                                                    </span>
                                                )}

                                                <span
                                                    className="view-inner help-icon"
                                                    data-tip={`Help`}
                                                    data-effect="solid"
                                                    data-place="bottom"
                                                    data-background-color="#007bff"
                                                    onClick={() => {
                                                        this.showHelperModal("forms", "recommendations");
                                                    }}
                                                >
                                                    <img src="/img/question-mark-icon.png" alt="" className="fil-ico" />
                                                </span>
                                                {this.renderUploadHelperModal()}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {isHistoryView ? (
                        <EditHistory
                            getAllRegionLogs={getAllSettingsLogs}
                            // changeToHistory={this.changeToHistory}
                            handlePerPageChangeHistory={handlePerPageChangeHistory}
                            handlePageClickHistory={handlePageClickHistory}
                            handleGlobalSearchHistory={handleGlobalSearchHistory}
                            globalSearchKeyHistory={globalSearchKeyHistory}
                            logData={logData}
                            handleDeleteLog={handleDeleteLog}
                            historyPaginationParams={historyPaginationParams}
                            isProjectSettings={true}
                            handleRestoreLog={this.handleRestoreLog}
                            historyParams={historyParams}
                            permissions={permissions}
                            logPermission={logPermission}
                            hasLogDelete={hasLogDelete}
                            hasLogRestore={hasLogRestore}
                            hasEdit={hasEdit}
                            hasDelete={hasDelete}
                            hasInfoPage={hasInfoPage}
                        />
                    ) : (
                        <div className="col-md-12 detail-recom scorll-flow">
                            <div className="m-details-img-sec cnt-sec">
                                <div class="card">
                                    <div className={`card-header ${this.state.showBand && "asset-info"}`} id="headingOne">
                                        <div className="d-flex content-block-2 otr-recom-div no-bottom-bord">
                                            <button
                                                class="btn btn-link btn-right-pos"
                                                data-toggle="collapse"
                                                onClick={() => this.setState({ showBand: !this.state.showBand })}
                                                data-target="#collapseOne"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                            ></button>
                                            <div className="row align-items-stretch cnt-sec">
                                                <div className="col-md-8 m-details-content-outer">
                                                    <div className="m-details-content-block ">
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne">
                                                                <div className="d-flex content-block-2 otr-recom-div no-bottom-bord">
                                                                    <button
                                                                        class="btn btn-link"
                                                                        data-toggle="collapse"
                                                                        data-target="#collapseOne"
                                                                        aria-expanded="true"
                                                                        aria-controls="collapseOne"
                                                                        onClick={() => this.setState({ showBand: !this.state.showBand })}
                                                                    >
                                                                        <span>Basic Asset Information</span>
                                                                    </button>

                                                                    {this.setToolTip(basicDetails.asset?.code, "Asset Code")}
                                                                    {this.setToolTip(basicDetails.asset?.asset_name, "Asset Name")}
                                                                    {this.setToolTip(basicDetails.asset?.asset_description, "Asset Description")}
                                                                </div>
                                                            </div>
                                                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne">
                                                                <div class="card-body">
                                                                    <div class="outer-rcm mt-1 basic-dtl-otr p-0">
                                                                        <div class="col-md-12 p-0">
                                                                            <div className="d-flex content-block-2">
                                                                                {this.setToolTip(basicDetails.asset?.asset_type?.name, "Asset Type")}

                                                                                {this.setToolTip(
                                                                                    basicDetails.asset?.asset_status?.name,
                                                                                    "Asset Status"
                                                                                )}
                                                                                {this.setToolTip(
                                                                                    basicDetails.asset?.client_asset_condition?.name,
                                                                                    "Asset Condition"
                                                                                )}
                                                                                {this.setToolTip(
                                                                                    basicDetails.asset?.client_asset_condition?.description,
                                                                                    "Asset Condition Description"
                                                                                )}
                                                                            </div>
                                                                            <div className="d-flex content-block-2">
                                                                                {this.setToolTip(
                                                                                    basicDetails.asset?.asset_ownership,
                                                                                    "Asset Ownership"
                                                                                )}
                                                                                {this.setToolTip(basicDetails.asset?.criticality, "Criticality")}
                                                                                {this.setToolTip(basicDetails.asset?.asset_tag, "Asset Tag")}
                                                                                <div className="col-md-3 basic-box">
                                                                                    <div className="content-block-card">
                                                                                        <p className="m-label">GUID</p>
                                                                                        <p className="m-details">{basicDetails.asset?.guid || "-"}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex content-block-2">
                                                                                {this.setToolTip(basicDetails.asset?.model_number, "Model Number")}

                                                                                <div
                                                                                    className="col-md-3 basic-box"
                                                                                    data-tip={
                                                                                        basicDetails.asset?.capacity?.toString().length > 20
                                                                                            ? basicDetails.asset?.capacity || ""
                                                                                            : ""
                                                                                    }
                                                                                    data-multiline={true}
                                                                                    data-place="left"
                                                                                    data-for="recommandation_Asset"
                                                                                    data-effect="solid"
                                                                                    data-background-color="#007bff"
                                                                                >
                                                                                    <div className="content-block-card">
                                                                                        <p className="m-label">Capacity</p>
                                                                                        <p className="m-details">
                                                                                            {basicDetails.asset?.capacity?.toString().length > 20
                                                                                                ? basicDetails.asset?.capacity
                                                                                                      ?.toString()
                                                                                                      .substring(0, 20) + "..."
                                                                                                : basicDetails.asset?.capacity || "-"}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="col-md-3 basic-box"
                                                                                    data-tip={
                                                                                        basicDetails.asset?.capacity_unit?.toString().length > 20
                                                                                            ? basicDetails.asset?.capacity_unit || ""
                                                                                            : ""
                                                                                    }
                                                                                    data-multiline={true}
                                                                                    data-place="left"
                                                                                    data-for="recommandation_Asset"
                                                                                    data-effect="solid"
                                                                                    data-background-color="#007bff"
                                                                                >
                                                                                    <div className="content-block-card">
                                                                                        <p className="m-label">Capacity Unit</p>
                                                                                        <p className="m-details">
                                                                                            {basicDetails.asset?.capacity_unit?.toString().length > 20
                                                                                                ? basicDetails.asset?.capacity_unit
                                                                                                      ?.toString()
                                                                                                      .substring(0, 20) + "..."
                                                                                                : basicDetails.asset?.capacity_unit || "-"}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="col-md-3 basic-box"
                                                                                    data-tip={
                                                                                        basicDetails.asset?.capacity_status?.toString().length > 20
                                                                                            ? basicDetails.asset?.capacity_status || ""
                                                                                            : ""
                                                                                    }
                                                                                    data-multiline={true}
                                                                                    data-place="left"
                                                                                    data-for="recommandation_Asset"
                                                                                    data-effect="solid"
                                                                                    data-background-color="#007bff"
                                                                                >
                                                                                    <div className="content-block-card">
                                                                                        <p className="m-label">Capacity Status</p>
                                                                                        <p className="m-details">
                                                                                            {basicDetails.asset?.capacity_status?.toString().length >
                                                                                            20
                                                                                                ? basicDetails.asset?.capacity_status
                                                                                                      ?.toString()
                                                                                                      .substring(0, 20) + "..."
                                                                                                : basicDetails.asset?.capacity_status || "-"}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex content-block-2">
                                                                                <div
                                                                                    className="col-md-3 basic-box"
                                                                                    data-tip={
                                                                                        basicDetails.asset?.serial_number?.toString().length > 20
                                                                                            ? basicDetails.asset?.serial_number || ""
                                                                                            : ""
                                                                                    }
                                                                                    data-multiline={true}
                                                                                    data-for="recommandation_Asset"
                                                                                    data-place="left"
                                                                                    data-effect="solid"
                                                                                    data-background-color="#007bff"
                                                                                >
                                                                                    <div className="content-block-card">
                                                                                        <p className="m-label">Serial Number</p>
                                                                                        <p className="m-details">
                                                                                            {basicDetails.asset?.serial_number?.toString().length > 20
                                                                                                ? basicDetails.asset?.serial_number
                                                                                                      ?.toString()
                                                                                                      .substring(0, 20) + "..."
                                                                                                : basicDetails.asset?.serial_number || "-"}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>

                                                                                <div
                                                                                    className="col-md-3 basic-box"
                                                                                    data-tip={
                                                                                        basicDetails.asset?.asset_barcode?.toString().length > 20
                                                                                            ? basicDetails.asset?.asset_barcode || ""
                                                                                            : ""
                                                                                    }
                                                                                    data-multiline={true}
                                                                                    data-for="recommandation_Asset"
                                                                                    data-place="left"
                                                                                    data-effect="solid"
                                                                                    data-background-color="#007bff"
                                                                                >
                                                                                    <div className="content-block-card">
                                                                                        <p className="m-label">Asset Barcode</p>
                                                                                        <p className="m-details">
                                                                                            {basicDetails.asset?.asset_barcode?.toString().length > 20
                                                                                                ? basicDetails.asset?.asset_barcode
                                                                                                      ?.toString()
                                                                                                      .substring(0, 20) + "..."
                                                                                                : basicDetails.asset?.asset_barcode || "-"}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                {this.setToolTip(
                                                                                    basicDetails.asset?.asset_client_id,
                                                                                    "Asset Client ID"
                                                                                )}
                                                                                {this.setToolTip(basicDetails.asset?.asset_cmms_id, "Asset CMMS ID")}
                                                                            </div>
                                                                            <div className="d-flex content-block-2">
                                                                                {this.setToolTip(
                                                                                    basicDetails.asset?.name_plate_status,
                                                                                    "Name Plate Status"
                                                                                )}
                                                                                {this.setToolTip(basicDetails.asset?.rtls_tag, "RTLS Tag")}
                                                                                <div className="col-md-3 basic-box"></div>
                                                                                <div className="col-md-3 basic-box"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`col-md-4 m-details-img-outer main-image-outer2`}>
                                                    <ImageBand
                                                        basicDetails={basicDetails?.asset?.image?.id ? basicDetails?.asset : basicDetails}
                                                        hasEdit={false}
                                                        getAllImagesResponse={
                                                            basicDetails?.asset?.image?.id
                                                                ? {
                                                                      images: [
                                                                          {
                                                                              description: basicDetails.asset?.image?.description,
                                                                              id: basicDetails.asset?.image?.id,
                                                                              master_image: basicDetails.asset?.image
                                                                          }
                                                                      ]
                                                                  }
                                                                : this.props?.imageResponse
                                                        }
                                                    />
                                                </div>
                                                {/* <div
                                                    className={`col-md-4 m-details-img-outer main-image-outer2${
                                                        basicDetails.asset.image && basicDetails.asset.image.url ? " imgCrsr" : ""
                                                    }`}
                                                    data-tip={basicDetails?.asset?.image?.description}
                                                    data-for={basicDetails?.asset?.image?.description}
                                                    data-multiline={true}
                                                    data-effect="solid"
                                                    data-place="top"
                                                    data-background-color="#007bff"
                                                >
                                                    <div className="details-img-block" onClick={() => this.openImageModal()}>
                                                        {basicDetails?.asset?.image && basicDetails?.asset?.image.url ? (
                                                            <>
                                                                <img src={`${basicDetails?.asset?.image.url}`} alt="" />
                                                            </>
                                                        ) : (
                                                            <img src="/img/no-image.png" alt="" />
                                                        )}
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cnt-sec">
                                <div className="card ml-0">
                                    <div class="card-header" id="headingTwo">
                                        <div className="outer-rcm recommendations otr-recom-div">
                                            <button
                                                class="btn btn-link"
                                                data-toggle="collapse"
                                                data-target="#collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="collapseTwo"
                                            >
                                                Geo Hierarchy
                                            </button>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails?.asset?.client?.name?.toString().length > 20
                                                        ? basicDetails?.asset?.client?.name || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-for="recommandation_Asset"
                                                data-background-color="#007bff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Client</h4>
                                                    <h3>{basicDetails.asset?.client?.name || "-"}</h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.asset?.region?.name?.toString().length > 20
                                                        ? basicDetails.asset?.region?.name || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-for="recommandation_Asset"
                                                data-effect="solid"
                                                data-background-color="#007bff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Region</h4>
                                                    <h3>{basicDetails.asset?.region?.name || "-"}</h3>
                                                </div>
                                            </div>
                                            {this.setToolTip(basicDetails.asset?.site?.name, "Site", "band")}
                                        </div>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo">
                                        <div class="card-body">
                                            <div className="outer-rcm recommendations">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Building</h4>
                                                        <h3>{basicDetails.asset?.building?.name || "-"}</h3>
                                                    </div>
                                                </div>
                                                {this.setToolTip(basicDetails.asset?.building_type?.name, "Building Type", "band")}
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Addition</h4>
                                                        <h3>{basicDetails.asset?.addition?.name || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div
                                                    className="txt-rcm"
                                                    data-tip={
                                                        basicDetails.asset?.floor?.name?.toString().length > 20
                                                            ? basicDetails.asset?.floor?.name || ""
                                                            : ""
                                                    }
                                                    data-multiline={true}
                                                    data-place="left"
                                                    data-for="recommandation_Asset"
                                                    data-effect="solid"
                                                    data-background-color="#007bff"
                                                >
                                                    <div className="txt-dtl">
                                                        <h4>Floor</h4>
                                                        <h3>{basicDetails.asset?.floor?.name || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="outer-rcm recommendations ">
                                                <div
                                                    className="txt-rcm"
                                                    data-tip={
                                                        basicDetails.asset?.room_number?.toString().length > 20
                                                            ? basicDetails.asset?.room_number || ""
                                                            : ""
                                                    }
                                                    data-multiline={true}
                                                    data-place="left"
                                                    data-for="recommandation_Asset"
                                                    data-effect="solid"
                                                    data-background-color="#007bff"
                                                >
                                                    <div className="txt-dtl">
                                                        <h4>Room Number</h4>
                                                        <h3>{basicDetails.asset?.room_number || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Room Name</h4>
                                                        <h3>{basicDetails.asset?.room_name || "-"}</h3>
                                                    </div>
                                                </div>
                                                {this.setToolTip(basicDetails.asset?.location, "Location", "band")}
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Architectural Room number</h4>
                                                        <h3>{basicDetails.asset?.architectural_room_number || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="outer-rcm recommendations">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Additional Room Description</h4>
                                                        <h3>{basicDetails.asset?.additional_room_description || "-"}</h3>
                                                    </div>
                                                </div>
                                                {this.setToolTip(basicDetails.asset?.longitude, "Longitude", "band")}
                                                {this.setToolTip(basicDetails.asset?.latitude, "Latitude", "band")}
                                                <div className="txt-rcm"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cnt-sec">
                                <div className="card ml-0">
                                    <div class="card-header" id="headingThree">
                                        <div className="outer-rcm recommendations otr-recom-div">
                                            <button
                                                class="btn btn-link"
                                                data-toggle="collapse"
                                                data-target="#collapseThree"
                                                aria-expanded="false"
                                                aria-controls="collapseThree"
                                            >
                                                Extended Asset Information
                                            </button>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.asset?.manufacturer?.toString().length > 20
                                                        ? basicDetails.asset?.manufacturer || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-for="recommandation_Asset"
                                                data-effect="solid"
                                                data-background-color="#007bff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Manufacturer</h4>
                                                    <h3>{basicDetails.asset?.manufacturer?.name || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Year Manufactured</h4>
                                                    <h3>{basicDetails.asset?.year_manufactured || "-"}</h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.asset?.installed_year?.toString().length > 20
                                                        ? basicDetails.asset?.installed_year || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-for="recommandation_Asset"
                                                data-effect="solid"
                                                data-background-color="#007bff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Installed Year</h4>
                                                    <h3>{basicDetails.asset?.installed_year || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree">
                                        <div class="card-body">
                                            <div className="outer-rcm recommendations ">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Current Age</h4>
                                                        <h3>{basicDetails.asset?.current_age || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Service Life</h4>
                                                        <h3>{basicDetails.asset?.service_life || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>End of Service Life</h4>
                                                        <h3>
                                                            {basicDetails?.asset?.usefull_life_remaining ||
                                                            basicDetails?.asset?.usefull_life_remaining === 0
                                                                ? `${new Date().getFullYear() + basicDetails?.asset?.usefull_life_remaining}`
                                                                : "-"}
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div
                                                    className="txt-rcm"
                                                    data-tip={
                                                        basicDetails.asset?.usefull_life_remaining
                                                            ? `Year= ${new Date().getFullYear() + basicDetails.asset?.usefull_life_remaining}`
                                                            : ""
                                                    }
                                                    data-multiline={true}
                                                    data-place="left"
                                                    data-for="recommandation_Asset"
                                                    data-effect="solid"
                                                    data-background-color="#007bff"
                                                >
                                                    <div className="txt-dtl">
                                                        <h4>Useful Life Remaining</h4>
                                                        <h3>{basicDetails.asset?.usefull_life_remaining ?? "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="outer-rcm recommendations ">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>CSP Unit</h4>
                                                        <h3>{basicDetails.asset?.csp_unit || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>CRV</h4>
                                                        <h3>
                                                            <NumberFormat
                                                                value={parseInt(basicDetails.asset?.crv || 0)}
                                                                thousandSeparator={true}
                                                                displayType={"text"}
                                                                prefix={"$ "}
                                                            />
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>FCA Cost</h4>
                                                        <h3>
                                                            <NumberFormat
                                                                value={parseInt(basicDetails.asset?.fca_cost || 0)}
                                                                thousandSeparator={true}
                                                                displayType={"text"}
                                                                prefix={"$ "}
                                                            />
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div
                                                    className="txt-rcm"
                                                    data-tip={
                                                        basicDetails.asset?.refrigerant?.toString().length > 20
                                                            ? basicDetails.asset?.refrigerant || ""
                                                            : ""
                                                    }
                                                    data-multiline={true}
                                                    data-place="left"
                                                    data-for="recommandation_Asset"
                                                    data-effect="solid"
                                                    data-background-color="#007bff"
                                                >
                                                    <div className="txt-dtl">
                                                        <h4>Refrigerant</h4>
                                                        <h3>{basicDetails.asset?.refrigerant || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="outer-rcm recommendations ">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Warranty Start</h4>
                                                        {basicDetails.asset?.warranty_start
                                                            ? moment(basicDetails.asset?.warranty_start).format("MM-DD-YYYY") || "-"
                                                            : "-"}
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Warranty End</h4>
                                                        {basicDetails.asset?.warranty_end
                                                            ? moment(basicDetails.asset?.warranty_end).format("MM-DD-YYYY") || "-"
                                                            : "-"}
                                                    </div>
                                                </div>
                                                <div
                                                    className="txt-rcm"
                                                    data-tip={
                                                        basicDetails.asset.install_date?.toString().length > 20
                                                            ? basicDetails.asset?.install_date || ""
                                                            : ""
                                                    }
                                                    data-multiline={true}
                                                    data-place="left"
                                                    data-effect="solid"
                                                    data-for="recommandation_Asset"
                                                    data-background-color="#007bff"
                                                >
                                                    <div className="txt-dtl">
                                                        <h4>Install Date</h4>
                                                        <h3>
                                                            {basicDetails.asset?.install_date
                                                                ? moment(basicDetails.asset?.install_date).format("MM-DD-YYYY") || "-"
                                                                : "-"}
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div
                                                    className="txt-rcm"
                                                    data-tip={
                                                        basicDetails.asset?.startup_date?.toString().length > 20
                                                            ? basicDetails.asset?.startup_date || ""
                                                            : ""
                                                    }
                                                    data-multiline={true}
                                                    data-place="left"
                                                    data-effect="solid"
                                                    data-for="recommandation_Asset"
                                                    data-background-color="#007bff"
                                                >
                                                    <div className="txt-dtl">
                                                        <h4>Startup Date</h4>
                                                        <h3>
                                                            {basicDetails.asset?.startup_date
                                                                ? moment(basicDetails.asset?.startup_date).format("MM-DD-YYYY") || "-"
                                                                : "-"}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="outer-rcm recommendations">
                                                <div
                                                    className="txt-rcm"
                                                    data-tip={
                                                        basicDetails.asset?.uniformat_level_1?.name?.toString().length > 20
                                                            ? basicDetails.asset?.uniformat_level_1?.name || ""
                                                            : ""
                                                    }
                                                    data-multiline={true}
                                                    data-place="left"
                                                    data-effect="solid"
                                                    data-for="recommandation_Asset"
                                                    data-background-color="#007bff"
                                                >
                                                    <div className="txt-dtl">
                                                        <h4>Uniformat Level 1</h4>
                                                        <h3>{basicDetails.asset?.uniformat_level_1?.name || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div
                                                    className="txt-rcm"
                                                    data-tip={
                                                        basicDetails.asset?.uniformat_level_2?.name?.toString().length > 20
                                                            ? basicDetails.asset?.uniformat_level_2?.name || ""
                                                            : ""
                                                    }
                                                    data-multiline={true}
                                                    data-place="left"
                                                    data-effect="solid"
                                                    data-for="recommandation_Asset"
                                                    data-background-color="#007bff"
                                                >
                                                    <div className="txt-dtl">
                                                        <h4>Uniformat Level 2</h4>
                                                        <h3>{basicDetails.asset?.uniformat_level_2?.name || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Uniformat Level 3</h4>
                                                        <h3> {basicDetails.asset?.uniformat_level_3?.name || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Uniformat Level 4</h4>
                                                        <h3> {basicDetails.asset?.uniformat_level_4?.name || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="outer-rcm recommendations">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Uniformat Level 5</h4>
                                                        <h3> {basicDetails.asset?.uniformat_level_5?.name || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Uniformat Level 6</h4>
                                                        <h3> {basicDetails.asset?.uniformat_level_6?.name || "-"}</h3>
                                                    </div>
                                                </div>
                                                {this.setToolTip(
                                                    basicDetails.asset?.uniformat_level_6?.uniformat_level_6_description,
                                                    "Uniformat Level 6 Description",
                                                    "band"
                                                )}
                                                {this.setToolTip(
                                                    basicDetails.asset?.uniformat_level_3_custom_description,
                                                    "Uniformat Level 3 Custom Description",
                                                    "band"
                                                )}
                                            </div>
                                            <div className="outer-rcm recommendations">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Main Category</h4>
                                                        <h3>{basicDetails.asset?.main_category?.name || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Sub Category 1</h4>
                                                        <h3>{basicDetails.asset?.sub_category_1?.name || "-"}</h3>{" "}
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Sub Category 2</h4>
                                                        <h3>{basicDetails.asset?.sub_category_2?.name || "-"}</h3>{" "}
                                                    </div>
                                                </div>
                                                {this.setToolTip(
                                                    basicDetails?.asset?.sub_category_2?.subcategory2_description,
                                                    "Sub Category 2 Description",
                                                    "band"
                                                )}
                                            </div>
                                            <div className="outer-rcm recommendations">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Sub Category 3</h4>
                                                        <h3>{basicDetails.asset?.sub_category_3?.name || "-"}</h3>{" "}
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Trade</h4>
                                                        <h3>{basicDetails.asset?.trade?.name || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>System</h4>
                                                        <h3>{basicDetails.asset?.system?.name || "-"}</h3>{" "}
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Sub-System</h4>
                                                        <h3>{basicDetails.asset?.sub_system?.name || "-"}</h3>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="outer-rcm recommendations">
                                                <div
                                                    className="txt-rcm"
                                                    data-tip={
                                                        basicDetails.asset.asset_note && basicDetails.asset.asset_note.toString().length > 20
                                                            ? basicDetails.asset.asset_note || ""
                                                            : ""
                                                    }
                                                    data-multiline={true}
                                                    data-place="left"
                                                    data-effect="solid"
                                                    data-for="recommandation_Asset"
                                                    data-background-color="#007bff"
                                                >
                                                    <div className="txt-dtl">
                                                        <h4>Asset Notes</h4>
                                                        <h3>{(basicDetails.asset && basicDetails.asset.asset_note) || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cnt-sec">
                                <div className="card ml-0">
                                    <div class="card-header" id="headingFour">
                                        <div className="outer-rcm recommendations otr-recom-div">
                                            <button
                                                class="btn btn-link"
                                                data-toggle="collapse"
                                                data-target="#collapseFour"
                                                aria-expanded="false"
                                                aria-controls="collapseFour"
                                            >
                                                Asset QA/QC Status
                                            </button>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails?.asset?.survey_id && basicDetails?.asset?.survey_id.toString().length > 20
                                                        ? basicDetails?.asset?.survey_id || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-for="Asset_details"
                                                data-background-color="#4991ff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Asset Survey Id</h4>
                                                    <h3>{basicDetails?.asset?.survey_id || "-"}</h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails?.asset?.survey_property_note &&
                                                    basicDetails?.asset?.survey_property_note.toString().length > 20
                                                        ? basicDetails?.asset?.survey_property_note || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-for="Asset_details"
                                                data-background-color="#4991ff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Asset Survey Property Notes</h4>
                                                    <h3>{basicDetails?.asset?.survey_property_note || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Asset Survey QA/QC Notes</h4>
                                                    <h3>{basicDetails?.asset?.qa_notes || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour">
                                        <div class="card-body">
                                            <div className="outer-rcm recommendations ">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Capacity Status</h4>
                                                        <h3>{basicDetails?.asset?.capacity_status || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Install Year Status</h4>
                                                        <h3>{basicDetails?.asset?.installed_year_status || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Name Plate Status</h4>
                                                        <h3>{basicDetails?.asset?.name_plate_status || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Asset Survey Additional QA/QC Notes</h4>
                                                        <h3>{basicDetails?.asset?.additional_qa_notes || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="outer-rcm recommendations">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Asset Survey Date Created</h4>
                                                        <h3>{basicDetails?.asset?.survey_date_created || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Asset Survey Surveyor</h4>
                                                        <h3>{basicDetails?.asset?.source_panel || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Asset Survey Date Edited</h4>
                                                        <h3>{basicDetails?.asset?.survey_date_edited || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Asset Survey Editor</h4>
                                                        <h3>{basicDetails?.asset?.editor || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="outer-rcm recommendations top-margin">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Is This a New Asset</h4>
                                                        <h3>{basicDetails?.asset?.new_asset || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm"></div>
                                                <div className="txt-rcm"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cnt-sec">
                                <div className="card ml-0">
                                    <div class="card-header" id="headingFive">
                                        <div className="outer-rcm recommendations">
                                            <button
                                                class="btn btn-link"
                                                data-toggle="collapse"
                                                data-target="#collapseFive"
                                                aria-expanded="false"
                                                aria-controls="collapseFive"
                                            >
                                                Asset Parent/Child Hierarchy
                                            </button>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.asset?.area_served?.toString().length > 20
                                                        ? basicDetails.asset?.area_served || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-for="recommandation_Asset"
                                                data-background-color="#007bff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Area Served</h4>
                                                    <h3>
                                                        {basicDetails.asset?.area_served?.toString().length > 20
                                                            ? basicDetails.asset?.area_served?.toString().substring(0, 20) + "..."
                                                            : basicDetails.asset?.area_served || "-"}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.asset?.upstream_asset_barcode_number?.toString().length > 20
                                                        ? basicDetails.asset?.upstream_asset_barcode_number || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-for="recommandation_Asset"
                                                data-background-color="#007bff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Upstream Asset Barcode Number</h4>
                                                    <h3>{(basicDetails.asset && basicDetails.asset.upstream_asset_barcode_number) || "-"}</h3>
                                                </div>
                                            </div>
                                            <div
                                                className="txt-rcm"
                                                data-tip={
                                                    basicDetails.asset.linked_asset_barcode_number &&
                                                    basicDetails.asset.linked_asset_barcode_number.toString().length > 20
                                                        ? basicDetails.asset.linked_asset_barcode_number || ""
                                                        : ""
                                                }
                                                data-multiline={true}
                                                data-place="left"
                                                data-effect="solid"
                                                data-for="recommandation_Asset"
                                                data-background-color="#007bff"
                                            >
                                                <div className="txt-dtl">
                                                    <h4>Linked Asset Barcode Number</h4>
                                                    <h3>{(basicDetails.asset && basicDetails.asset.linked_asset_barcode_number) || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive">
                                        <div class="card-body">
                                            <div className="outer-rcm recommendations ">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Source Panel Name</h4>
                                                        <h3>{(basicDetails.asset && basicDetails.asset.source_panel_name) || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Source Panel Barcode Number</h4>
                                                        <h3>{(basicDetails.asset && basicDetails.asset.source_panel_barcode_number) || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Source Panel Emergency/ Normal</h4>
                                                        <h3>{(basicDetails.asset && basicDetails.asset.source_panel) || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Skysite Hyperlink</h4>
                                                        <h3>{(basicDetails.asset && basicDetails.asset.skysite_hyperlink) || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="outer-rcm recommendations">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Quantity</h4>
                                                        <h3>{basicDetails?.asset?.quantity ?? "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Parent Global ID</h4>
                                                        <h3>{basicDetails?.asset?.parent_global_id || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Created At</h4>
                                                        <h3>{moment(basicDetails.asset.created_at).format("MM-DD-YYYY h:mm A") || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Updated At</h4>
                                                        <h3>{moment(basicDetails.asset.updated_at).format("MM-DD-YYYY h:mm A") || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cnt-sec">
                                <div className="card ml-0">
                                    <div class="card-header" id="headingSix">
                                        <div className="outer-rcm recommendations otr-recom-div">
                                            <button
                                                class="btn btn-link"
                                                data-toggle="collapse"
                                                data-target="#collapseSix"
                                                aria-expanded="false"
                                                aria-controls="collapseSix"
                                            >
                                                Asset Additional
                                            </button>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Discharge</h4>
                                                    <h3>{basicDetails?.asset?.asset_additional?.discharge || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Return</h4>
                                                    <h3>{basicDetails?.asset?.asset_additional?.return || "-"}</h3>
                                                </div>
                                            </div>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Is Unit Properly Labeled</h4>
                                                    <h3>{basicDetails?.asset?.asset_additional?.unit_property_labeled || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseSix" class="collapse" aria-labelledby="headingSix">
                                        <div class="card-body">
                                            <div className="outer-rcm recommendations ">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Factory Mounted Disconnect</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.factory_mounted_disconnect || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Unit Voltage</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.unit_voltage || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>AHU Voltage</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.ahu_voltage || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Wire Type</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.wire_type || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Wire Size</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.wire_size || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Estimated Wire Run Length</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.estimated_wire_run_length || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Breaker Size</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.breaker_size || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>MOCP</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.mocp || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>MCA</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.mca || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Liquid Line</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.liquid_line || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Suction Line</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.suction_line || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Hot Gas By-Pass</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.hot_gas_by_pass || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Number of Circuits</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.number_of_circuits || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Length of Line Run</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.length_of_line || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Damper Type</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.damper_type || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Gas Heating</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.gas_heating || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Gas Output BTU</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.gas_output_btu || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Electric Heating</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.electric_heating || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Electric Heating KW</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.electric_heating_kw || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Heat Pump - Backup Heat KW</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.pump_backup_heat_kw || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Cooling Only</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.cooling_only || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Controls</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.controls || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Controls Type</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.control_type || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Smoke Detector</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.smoke_detector || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Smoke Detector Mounting</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.smoke_detector_mounting || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Smoke Detector - Supply</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.smoke_detector_supply || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Smoke Detector - Return</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.smoke_detector_return || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Curb</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.curb || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Curb Other Description</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.curb_description || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Vibration Isolation</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.vibration_isolation || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Burglar Bars</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.burglar_bars || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Existing Unit Installed on an Adapter Curb?</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.installed_on_adapter_curb || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Existing Curb Outside Dimensions - Length</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.curb_outside_dimension_length || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Existing Curb Outside Dimensions - Width</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.curb_outside_dimension_width || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Will Mall, Landlord, Municipality Allow a Curb Adapter?</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.allow_curb_adaptor || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Is This a Ducted or Concentric System</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.ducted_or_concentric_system || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Unit Weight</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.unit_weight || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Any Special Codes for Disconnects</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.codes_for_disconnects || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Any Special Codes for Convenience Outlets</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.codes_for_convenience_outlets || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Any Special Codes for Roof Lighting</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.codes_for_roof_lighting || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Current Duct Work Attached to Bottom of Unit</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.duct_work_attached_to_bottom_of_unit || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Was Aluminum Wire Used</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.aluminium_wire_used || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Are There Any Code or Safety Concerns</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.code_or_safety_concerns || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Distance (in Miles) from Body of Salt Water</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.distance_from_salt_water_body || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Recommend Coil Coating</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.recommend_coil_coating || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Do Electrical/Piping Roof Penetrations Need to be Resealed</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.need_resealed || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Split System - Are Line Sets in Good Condition</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.split_system_line_sets_good_condition || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Split System - Are Line Sets Damaged in Any Way</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.split_system_line_sets_damaged || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Split System - Are Line Sets Crimped</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.split_system_line_sets_crimped || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Split System - Are Line Sets Insulated</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.split_system_line_sets_insulated || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Split System - Can Line Sets Be Re-Used</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.split_system_line_sets_re_used || "-"}</h3>
                                                    </div>
                                                </div>
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Line Connection Type</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.line_connection_type || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cnt-sec">
                                <div className="card ml-0">
                                    <div class="card-header" id="headingSeven">
                                        <div className="outer-rcm recommendations otr-recom-div">
                                            <button
                                                class="btn btn-link"
                                                data-toggle="collapse"
                                                data-target="#collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="collapseSeven"
                                            >
                                                Boiler Fields
                                            </button>
                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Boiler Type</h4>
                                                    <h3>{basicDetails?.asset?.asset_additional?.boiler_type || "-"}</h3>
                                                </div>
                                            </div>

                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Boiler Burner Type</h4>
                                                    <h3>{basicDetails?.asset?.asset_additional?.boiler_burner_type || "-"}</h3>
                                                </div>
                                            </div>

                                            <div className="txt-rcm">
                                                <div className="txt-dtl">
                                                    <h4>Boiler BTU Input</h4>
                                                    <h3>{basicDetails?.asset?.asset_additional?.boiler_btu_input || "-"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven">
                                        <div class="card-body">
                                            <div className="outer-rcm recommendations ">
                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Boiler BTU Output</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.boiler_btu_output || "-"}</h3>
                                                    </div>
                                                </div>

                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Boiler HP</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.boiler_hp || "-"}</h3>
                                                    </div>
                                                </div>

                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Boiler Fuel</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.boiler_fuel || "-"}</h3>
                                                    </div>
                                                </div>

                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Boiler Gas Pressure</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.boiler_gas_pressure || "-"}</h3>
                                                    </div>
                                                </div>

                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Boiler KW Input</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.boiler_kw_input || "-"}</h3>
                                                    </div>
                                                </div>

                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Boiler Power Burner Type</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.boiler_power_burner_type || "-"}</h3>
                                                    </div>
                                                </div>

                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Boiler Burner Model</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.boiler_burner_model || "-"}</h3>
                                                    </div>
                                                </div>

                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Boiler Burner Serial</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.boiler_burner_serial || "-"}</h3>
                                                    </div>
                                                </div>

                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Boiler Operating Pressure Type</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.boiler_pressure_type || "-"}</h3>
                                                    </div>
                                                </div>

                                                <div className="txt-rcm">
                                                    <div className="txt-dtl">
                                                        <h4>Confirm Boiler Pressure</h4>
                                                        <h3>{basicDetails?.asset?.asset_additional?.confirm_boiler_pressure || "-"}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cnt-sec">
                                <div className="card ml-0">
                                    {(basicDetails?.asset?.auxiliary?.length ? basicDetails?.asset?.auxiliary : [{}])?.map(e => (
                                        <>
                                            <div class="card-header" id="headingEight">
                                                <div className="outer-rcm recommendations otr-recom-div">
                                                    <button
                                                        class="btn btn-link"
                                                        data-toggle="collapse"
                                                        data-target="#collapseEight"
                                                        aria-expanded="false"
                                                        aria-controls="collapseEight"
                                                    >
                                                        Auxiliary Equipment Fields
                                                    </button>
                                                    <div className="txt-rcm">
                                                        <div className="txt-dtl">
                                                            <h4>Equipment Name</h4>
                                                            <h3>{e?.auxiliary_equipment_name || "-"}</h3>
                                                        </div>
                                                    </div>

                                                    <div className="txt-rcm">
                                                        <div className="txt-dtl">
                                                            <h4>Model</h4>
                                                            <h3>{e?.auxiliary_model || "-"}</h3>
                                                        </div>
                                                    </div>

                                                    <div className="txt-rcm">
                                                        <div className="txt-dtl">
                                                            <h4>Serial</h4>
                                                            <h3>{e?.auxiliary_serial || "-"}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight">
                                                <div class="card-body">
                                                    <div className="outer-rcm recommendations ">
                                                        <div className="txt-rcm">
                                                            <div className="txt-dtl">
                                                                <h4>Date Manufactured</h4>
                                                                <h3>{e?.auxiliary_date_manufactured || "-"}</h3>
                                                            </div>
                                                        </div>

                                                        <div className="txt-rcm">
                                                            <div className="txt-dtl">
                                                                <h4>Manufacturer</h4>
                                                                <h3>{e?.auxiliary_manufacturer || "-"}</h3>
                                                            </div>
                                                        </div>

                                                        <div className="txt-rcm">
                                                            <div className="txt-dtl">
                                                                <h4>Condition</h4>
                                                                <h3>{e?.auxiliary_condition || "-"}</h3>
                                                            </div>
                                                        </div>

                                                        <div className="txt-rcm">
                                                            <div className="txt-dtl">
                                                                <h4>EUL</h4>
                                                                <h3>{e?.auxiliary_eul || "-"}</h3>
                                                            </div>
                                                        </div>

                                                        <div className="txt-rcm">
                                                            <div className="txt-dtl">
                                                                <h4>CRV</h4>
                                                                <NumberFormat
                                                                    value={parseInt(e?.auxiliary_crv || 0)}
                                                                    thousandSeparator={true}
                                                                    displayType={"text"}
                                                                    prefix={"$ "}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {this.renderConfirmationModalLog()}
                    {this.renderConfirmationModal()}
                    {this.renderFMPConfirmationModal()}
                    {this.renderIrConfirmationModal()}
                    {this.renderRLConfirmationModal()}
                </div>
                {/* <ReactTooltip /> */}
            </React.Fragment>
        );
    }
}

export default withRouter(BasicDetails);
