import React, { useState } from "react";
import Draggable from "react-draggable";

import { RECOMMENDATION_BANDS } from "../constants";

const ProjectOnePagerExport = ({ onCancel, exportRecommendation }) => {
    const handleExport = type => {
        exportRecommendation(type);
    };

    return (
        <React.Fragment>
            <div className="modal modal-region modal-view inbox-modal" id="modalId" tabIndex="-1" style={{ display: "block" }}>
                <Draggable handle=".modal-header">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    <div className="txt-hed">CR One Pager Export</div>
                                </h5>
                                <button type="button" className="close" onClick={onCancel}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body region-otr sm-chart-upload">
                                <div className="upload-area not-draggable">
                                    <div className="upload-sec cursor-hand" role="button" tabIndex="0">
                                        <input type="file" multiple="" autocomplete="off" tabIndex="-1" style={{ display: "none" }} />
                                    </div>
                                </div>
                                <div className="btn-sec">
                                    <div className="text-right btnOtr edit-cmn-btn">
                                        <button
                                            type="button"
                                            className="btn btn-secondary btnClr btn-cancel-one-pager"
                                            data-dismiss="modal"
                                            onClick={() => onCancel()}
                                        >
                                            Cancel
                                        </button>
                                        <button type="button" className="btn btn-create save" onClick={() => handleExport("Docx")}>
                                            Export to Docx
                                        </button>
                                        <button type="button" className="btn btn-create save mr-1" onClick={() => handleExport("pdf")}>
                                            Export to PDF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
        </React.Fragment>
    );
};

export default ProjectOnePagerExport;
