import * as actionType from "./constants";

const initialState = {
    getprojectsInMasterDropdownData: {},
    exportSmartChartDataResponse: {},
    masterFilterList: {
        project: {},
        energy_mng: {},
        documents: {}
    },
    saveSmartChartDataResponse: {},
    exportedSmartChartListResponse: {},
    deleteSmartChartReportResponse: {},
    uploadDocForSmartChartResponse: {},
    uploadedDocListResponse: {},
    updateSmartChartDataResponse: {},
    getTemplatePropertiesListResponse: {},
    getTemplateListResponse: {},
    getClientDropDownDataResponse: {},
    userDocOrderChangeResponse: {},
    deleteUserDocResponse: {},
    getSmartChartPropertiesListResponse: {},
    getSmartChartPropertyByIdResponse: {},
    uploadedImageListResponse: {},
    saveAndExportSmartChartDataResponse: {},
    deleteSmartChartReportTemplateResponse: {},
    reportsByTemplateListResponse: {},
    lockSmartChartTemplateResponse: {},
    getCAbandDropdownData: {},
    getCAbandTradeDropdownData: {},
    getUserByClientData: {},
    exportTemplateList: {},
    exportPropertyList: {},
    exportSettingsData: {},
    saveExportSettingsData: {},
    mFilters: {},
    getCAbandSystemDropdownData: {},
    allExportSettingsData: {},
    updateCoreReportDataResponse: {},
    entityParams: {
        entity: null,
        selectedEntity: null,
        selectedRowId: null,
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 0,
            search: "",
            filters: null,
            list: null,
            active: true
        },
        wildCardFilterParams: {},
        filterParams: {},
        tableConfig: null,
        historyPaginationParams: {
            totalPages: 0,
            perPage: 40,
            currentPage: 0,
            totalCount: 0
        },
        historyParams: {
            limit: 40,
            offset: 0,
            search: ""
        },
        selectedDropdown: "active",
        sortParams: {
            sort_by: "created_date",
            order: "desc"
        },
        filterKey: ""
    },
    propertyEntityParams: {
        entity: null,
        selectedEntity: null,
        selectedRowId: null,
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 0,
            search: "",
            filters: null,
            list: null,
            active: true
        },
        wildCardFilterParams: {},
        filterParams: {},
        tableConfig: null,
        historyPaginationParams: {
            totalPages: 0,
            perPage: 40,
            currentPage: 0,
            totalCount: 0
        },
        historyParams: {
            limit: 40,
            offset: 0,
            search: ""
        },
        selectedDropdown: "active",
        sortParams: {
            sort_by: "created_date",
            order: "desc"
        },
        filterKey: ""
    },
    docOrImageEntityParams: {
        documents: {},
        images: {}
    },
    getSourceTemplate: {},
    caColumns: {},
    AssetExportSettingsData: {},
    exportAssetPropertyList: {},
    exportAssetTemplateList: {},
    saveAssetExportSettingsData: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_MASTER_FILTER_IN_SMART_CHART_REQUEST:
            return {
                ...state
            };
        case actionType.GET_MASTER_FILTER_IN_SMART_CHART_SUCCESS:
            let dropDownData = [];
            if (
                action.filterKey === "fmp" ||
                action.filterKey === "infrastructure_requests" ||
                action.filterKey === "fci" ||
                action.filterKey === "building_use" ||
                action.filterKey === "secondary_use" ||
                action.filterKey === "sectors" ||
                action.filterKey === "internal_groups" ||
                action.filterKey === "division"
            ) {
                dropDownData = action.response?.data?.map(data => {
                    return { id: data.name, name: data.name, count: data.count };
                });
            } else {
                dropDownData = [...action.response?.data];
            }
            return {
                ...state,
                masterFilterList: {
                    ...state.masterFilterList,
                    [action.entity]: {
                        ...state.masterFilterList[action.entity],
                        [action.filterKey]: [...dropDownData]
                    }
                }
            };
        case actionType.GET_MASTER_FILTER_IN_SMART_CHART_FAILURE:
            return {
                ...state,
                masterFilterList: {
                    ...state.masterFilterList,
                    [action.entity]: {
                        ...state.masterFilterList[action.entity],
                        [action.filterKey]: []
                    }
                }
            };
        case actionType.EXPORT_SMART_CHART_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.EXPORT_SMART_CHART_DATA_SUCCESS:
            return {
                ...state,
                exportSmartChartDataResponse: { success: true, ...action.response }
            };
        case actionType.EXPORT_SMART_CHART_DATA_FAILURE:
            return {
                ...state,
                exportSmartChartDataResponse: { success: false, ...action.error }
            };
        case actionType.SAVE_SMART_CHART_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.SAVE_SMART_CHART_DATA_SUCCESS:
            return {
                ...state,
                saveSmartChartDataResponse: { success: true, ...action.response }
            };
        case actionType.SAVE_SMART_CHART_DATA_FAILURE:
            return {
                ...state,
                saveSmartChartDataResponse: { success: false, ...action.error }
            };
        case actionType.GET_EXPORTED_SMART_CHART_LIST_REQUEST:
            return {
                ...state
            };
        case actionType.GET_EXPORTED_SMART_CHART_LIST_SUCCESS:
            return {
                ...state,
                exportedSmartChartListResponse: { success: true, ...action.response }
            };
        case actionType.GET_EXPORTED_SMART_CHART_LIST_FAILURE:
            return {
                ...state,
                exportedSmartChartListResponse: { success: false, ...action.error }
            };
        case actionType.DELETE_SMART_CHART_REPORT_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_SMART_CHART_REPORT_SUCCESS:
            return {
                ...state,
                deleteSmartChartReportResponse: { success: true, ...action.response }
            };
        case actionType.DELETE_SMART_CHART_REPORT_FAILURE:
            return {
                ...state,
                deleteSmartChartReportResponse: { success: false, ...action.error }
            };
        case actionType.UPLOAD_DOC_FOR_SMART_REPORT_REQUEST:
            return {
                ...state
            };
        case actionType.UPLOAD_DOC_FOR_SMART_REPORT_SUCCESS:
            return {
                ...state,
                uploadDocForSmartChartResponse: { success: true, ...action.response }
            };
        case actionType.UPLOAD_DOC_FOR_SMART_REPORT_FAILURE:
            return {
                ...state,
                uploadDocForSmartChartResponse: { success: false, ...action.error }
            };
        case actionType.GET_UPLOADED_DOC_LIST_REQUEST:
            return {
                ...state
            };
        case actionType.GET_UPLOADED_DOC_LIST_SUCCESS:
            return {
                ...state,
                uploadedDocListResponse: { success: true, ...action.response }
            };
        case actionType.GET_UPLOADED_DOC_LIST_FAILURE:
            return {
                ...state,
                uploadedDocListResponse: { success: false, ...action.error }
            };
        case actionType.UPDATE_SMART_REPORT_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_SMART_REPORT_DATA_SUCCESS:
            return {
                ...state,
                updateSmartChartDataResponse: { success: true, ...action.response }
            };
        case actionType.UPDATE_SMART_REPORT_DATA_FAILURE:
            return {
                ...state,
                updateSmartChartDataResponse: { success: false, ...action.error }
            };
        case actionType.GET_TEMPLATE_PROPERTIES_LIST_REQUEST:
            return {
                ...state
            };
        case actionType.GET_TEMPLATE_PROPERTIES_LIST_SUCCESS:
            let propertyList = [...action.response?.data];
            return {
                ...state,
                getTemplatePropertiesListResponse: { success: true, data: [...propertyList] }
            };
        case actionType.GET_TEMPLATE_PROPERTIES_LIST_FAILURE:
            return {
                ...state,
                getTemplatePropertiesListResponse: { success: false, ...action.error }
            };
        case actionType.GET_TEMPLATE_LIST_REQUEST:
            return {
                ...state
            };
        case actionType.GET_TEMPLATE_LIST_SUCCESS:
            let templateList = [...action.response?.data];
            return {
                ...state,
                getTemplateListResponse: { success: true, data: [...templateList] }
            };
        case actionType.GET_TEMPLATE_LIST_FAILURE:
            return {
                ...state,
                getTemplateListResponse: { success: false, ...action.error }
            };
        case actionType.GET_CLIENTS_LIST_FOR_SMART_CHART_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CLIENTS_LIST_FOR_SMART_CHART_SUCCESS:
            return {
                ...state,
                getClientDropDownDataResponse: { success: true, ...action.response }
            };
        case actionType.GET_CLIENTS_LIST_FOR_SMART_CHART_FAILURE:
            return {
                ...state,
                getClientDropDownDataResponse: { success: false, ...action.error }
            };
        case actionType.UPDATE_DOC_ORDER_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_DOC_ORDER_SUCCESS:
            return {
                ...state,
                userDocOrderChangeResponse: { success: true, ...action.response }
            };
        case actionType.UPDATE_DOC_ORDER_FAILURE:
            return {
                ...state,
                userDocOrderChangeResponse: { success: false, ...action.error }
            };
        case actionType.DELETE_USER_DOC_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_USER_DOC_SUCCESS:
            return {
                ...state,
                deleteUserDocResponse: { success: true, ...action.response }
            };
        case actionType.DELETE_USER_DOC_FAILURE:
            return {
                ...state,
                deleteUserDocResponse: { success: false, ...action.error }
            };
        case actionType.GET_SMART_CHART_PROPERTIES_LIST_REQUEST:
            return {
                ...state
            };
        case actionType.GET_SMART_CHART_PROPERTIES_LIST_SUCCESS:
            return {
                ...state,
                getSmartChartPropertiesListResponse: { success: true, ...action.response }
            };
        case actionType.GET_SMART_CHART_PROPERTIES_LIST_FAILURE:
            return {
                ...state,
                getSmartChartPropertiesListResponse: { success: false, ...action.error }
            };
        case actionType.UPDATE_SMART_CHART_PROPERTY_SUCCESS:
            return {
                ...state,
                saveSmartChartDataResponse: { success: true, ...action.response }
            };
        case actionType.GET_SMART_CHART_PROPERTY_BY_ID_REQUEST:
            return {
                ...state
            };
        case actionType.GET_SMART_CHART_PROPERTY_BY_ID_SUCCESS:
            return {
                ...state,
                getSmartChartPropertyByIdResponse: { success: true, data: { ...action.response } }
            };
        case actionType.GET_SMART_CHART_PROPERTY_BY_ID_FAILURE:
            return {
                ...state,
                getSmartChartPropertyByIdResponse: { success: false, ...action.error }
            };
        case actionType.UPDATE_SMART_REPORT_ENTITY_PARAMS_SUCCESS:
            return {
                ...state,
                mFilters: { ...action.mFilters },
                entityParams: { ...state.entityParams, ...action.entityParams }
            };
        case actionType.GET_UPLOADED_IMAGE_LIST_REQUEST:
            return {
                ...state
            };
        case actionType.GET_UPLOADED_IMAGE_LIST_SUCCESS:
            return {
                ...state,
                uploadedImageListResponse: { success: true, ...action.response }
            };
        case actionType.GET_UPLOADED_IMAGE_LIST_FAILURE:
            return {
                ...state,
                uploadedImageListResponse: { success: false, ...action.error }
            };
        case actionType.SAVE_AND_EXPORT_SMART_CHART_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.SAVE_AND_EXPORT_SMART_CHART_DATA_SUCCESS:
            return {
                ...state,
                saveAndExportSmartChartDataResponse: { success: true, ...action.response }
            };
        case actionType.SAVE_AND_EXPORT_SMART_CHART_DATA_FAILURE:
            return {
                ...state,
                saveAndExportSmartChartDataResponse: { success: false, ...action.error }
            };
        case actionType.DELETE_SMART_CHART_REPORT_TEMPLATE_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_SMART_CHART_REPORT_TEMPLATE_SUCCESS:
            return {
                ...state,
                deleteSmartChartReportTemplateResponse: { success: true, ...action.response }
            };
        case actionType.DELETE_SMART_CHART_REPORT_TEMPLATE_FAILURE:
            return {
                ...state,
                deleteSmartChartReportTemplateResponse: { success: false, ...action.error }
            };
        case actionType.GET_REPORTS_BY_TEMPLATE_LIST_REQUEST:
            return {
                ...state
            };
        case actionType.GET_REPORTS_BY_TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                reportsByTemplateListResponse: { success: true, ...action.response }
            };
        case actionType.GET_REPORTS_BY_TEMPLATE_LIST_FAILURE:
            return {
                ...state,
                reportsByTemplateListResponse: { success: false, ...action.error }
            };
        case actionType.LOCK_SMART_CHART_TEMPLATE_REQUEST:
            return {
                ...state
            };
        case actionType.LOCK_SMART_CHART_TEMPLATE_SUCCESS:
            return {
                ...state,
                lockSmartChartTemplateResponse: { success: true, ...action.response }
            };
        case actionType.LOCK_SMART_CHART_TEMPLATE_FAILURE:
            return {
                ...state,
                lockSmartChartTemplateResponse: { success: false, ...action.error }
            };
        case actionType.GET_CA_BAND_FILTER_DROPDOWN_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CA_BAND_FILTER_DROPDOWN_DATA_SUCCESS:
            return {
                ...state,
                getCAbandDropdownData: { success: true, ...action.response }
            };
        case actionType.GET_CA_BAND_FILTER_DROPDOWN_DATA_FAILURE:
            return {
                ...state,
                getCAbandDropdownData: { success: false, ...action.error }
            };
        case actionType.UPDATE_ENTITY_FILTER_PARAMS_SMART_CHARTS:
            return {
                ...state,
                docOrImageEntityParams: { ...state.docOrImageEntityParams, [action.key]: { ...action.filterParams } }
            };
        case actionType.CLEAR_SMART_CHART_REDUCER_DATA:
            return {
                ...state,
                masterFilterList: {
                    project: {},
                    energy_mng: {},
                    documents: {}
                }
            };
        case actionType.GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_SUCCESS:
            return {
                ...state,
                getCAbandTradeDropdownData: { success: true, ...action.response }
            };
        case actionType.GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_FAILURE:
            return {
                ...state,
                getCAbandTradeDropdownData: { success: false, ...action.error }
            };
        case actionType.UPDATE_SMART_REPORT_TEMPLATE_ENTITY_PARAMS_SUCCESS:
            return {
                ...state,
                propertyEntityParams: { ...state.propertyEntityParams, ...action.entityParams }
            };
        case actionType.GET_USERS_BY_CLIENT_REQUEST:
            return {
                ...state
            };
        case actionType.GET_USERS_BY_CLIENT_SUCCESS:
            return {
                ...state,
                getUserByClientData: { success: true, ...action.response }
            };
        case actionType.GET_USERS_BY_CLIENT_FAILURE:
            return {
                ...state,
                getUserByClientData: { success: false, ...action.error }
            };
        case actionType.CLEAR_USER_BY_CLIENT_DATA:
            return {
                ...state,
                getUserByClientData: {}
            };
        case actionType.GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_REQUEST:
            return {
                ...state
            };
        case actionType.GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_SUCCESS:
            return {
                ...state,
                exportPropertyList: { success: true, ...action.response }
            };
        case actionType.GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_FAILURE:
            return {
                ...state,
                exportPropertyList: { success: false, ...action.error }
            };
        case actionType.GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_REQUEST:
            return {
                ...state
            };
        case actionType.GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_SUCCESS:
            return {
                ...state,
                exportTemplateList: { success: true, ...action.response }
            };
        case actionType.GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_FAILURE:
            return {
                ...state,
                exportTemplateList: { success: false, ...action.error }
            };
        case actionType.GET_EXPORT_SETTINGS_NORMAL_RECOM_REQUEST:
            return {
                ...state
            };
        case actionType.GET_EXPORT_SETTINGS_NORMAL_RECOM_SUCCESS:
            return {
                ...state,
                exportSettingsData: { success: true, ...action.response }
            };
        case actionType.GET_EXPORT_SETTINGS_NORMAL_RECOM_FAILURE:
            return {
                ...state,
                exportSettingsData: { success: false, ...action.error }
            };
        case actionType.ADD_EXPORT_SETTINGS_NORMAL_RECOM_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_EXPORT_SETTINGS_NORMAL_RECOM_SUCCESS:
            return {
                ...state,
                saveExportSettingsData: { success: true, ...action.response }
            };
        case actionType.ADD_EXPORT_SETTINGS_NORMAL_RECOM_FAILURE:
            return {
                ...state,
                saveExportSettingsData: { success: false, ...action.error }
            };
        case actionType.CLEAR_PROPERTY_BY_ID_DATA:
            return {
                ...state,
                getSmartChartPropertyByIdResponse: {}
            };
        case actionType.GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_SUCCESS:
            return {
                ...state,
                getCAbandSystemDropdownData: { success: true, ...action.response }
            };
        case actionType.GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_FAILURE:
            return {
                ...state,
                getCAbandSystemDropdownData: { success: false, ...action.error }
            };
        case actionType.GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_REQUEST:
            return {
                ...state
            };
        case actionType.GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_SUCCESS:
            return {
                ...state,
                allExportSettingsData: { success: true, ...action.response }
            };
        case actionType.GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_FAILURE:
            return {
                ...state,
                allExportSettingsData: { success: false, ...action.error }
            };
        case actionType.CLEAR_SETTINGS_IDS_FOR_NORMAL_RECOM:
            return {
                ...state,
                allExportSettingsData: {}
            };
        case actionType.GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_REQUEST:
            return {
                ...state
            };
        case actionType.GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_SUCCESS:
            return {
                ...state,
                getSourceTemplate: { success: true, data: { ...action.response } }
            };
        case actionType.GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_FAILURE:
            return {
                ...state,
                getSourceTemplate: { success: false, ...action.error }
            };

        case actionType.UPDATE_CORE_REPORT_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_CORE_REPORT_DATA_SUCCESS:
            return {
                ...state,
                updateCoreReportDataResponse: { success: true, ...action.response }
            };
        case actionType.UPDATE_CORE_REPORT_DATA_FAILURE:
            return {
                ...state,
                updateCoreReportDataResponse: { success: false, ...action.error }
            };
        case actionType.GET_CA_COLUMNS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_CA_COLUMNS_SUCCESS:
            return {
                ...state,
                caColumns: { success: true, list: action.response }
            };
        case actionType.GET_CA_COLUMNS_FAILURE:
            return {
                ...state,
                caColumns: { success: false, ...action.error }
            };
        case actionType.GET_EXPORT_SETTINGS_ASSET_REQUEST:
            return {
                ...state
            };
        case actionType.GET_EXPORT_SETTINGS_ASSET_SUCCESS:
            return {
                ...state,
                AssetExportSettingsData: { success: true, ...action.response }
            };
        case actionType.GET_EXPORT_SETTINGS_ASSET_FAILURE:
            return {
                ...state,
                AssetExportSettingsData: { success: false, ...action.error }
            };
        case actionType.GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_REQUEST:
            return {
                ...state
            };
        case actionType.GET_EXPORT_PROPERTY_LIST_ASSET_SUCCESS:
            return {
                ...state,
                exportAssetPropertyList: { success: true, ...action.response }
            };
        case actionType.GET_EXPORT_PROPERTY_LIST_ASSET_FAILURE:
            return {
                ...state,
                exportAssetPropertyList: { success: false, ...action.error }
            };
        case actionType.GET_EXPORT_TEMPLATE_LIST_ASSET_REQUEST:
            return {
                ...state
            };
        case actionType.GET_EXPORT_TEMPLATE_LIST_ASSET_SUCCESS:
            return {
                ...state,
                exportAssetTemplateList: { success: true, ...action.response }
            };
        case actionType.GET_EXPORT_TEMPLATE_LIST_ASSET_FAILURE:
            return {
                ...state,
                exportAssetTemplateList: { success: false, ...action.error }
            };
        case actionType.ADD_EXPORT_SETTINGS_ASSET_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_EXPORT_SETTINGS_ASSET_SUCCESS:
            return {
                ...state,
                saveAssetExportSettingsData: { success: true, ...action.response }
            };
        case actionType.ADD_EXPORT_SETTINGS_ASSET_FAILURE:
            return {
                ...state,
                saveAssetExportSettingsData: { success: false, ...action.error }
            };
        default:
            return state;
    }
};
