import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
import "semantic-ui-css/semantic.min.css";
import "./assets/css/style-main.css";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import posthog from "posthog-js";
import "bootstrap/dist/js/bootstrap.js"

// Bugsnag.start({
//     apiKey: "f2b07a0f5e771a31ea05f0b64c29cc6b",
//     plugins: [new BugsnagPluginReact()]
// });
// const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
// post hog initiate
// if (!window.location.host.includes("127.0.0.1") && !window.location.host.includes("localhost")) {
posthog.init("phc_6MnvOBKVB3udAtT3lB4tlRB9HHR1IEyR5loFgEUmGmV", { api_host: "https://app.posthog.com" });
// }
// creating the redux store
const store = configureStore();

ReactDOM.render(
    // <ErrorBoundary>
    <Provider store={store}>
        <div></div>
        <App />
    </Provider>,
    // </ErrorBoundary>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
