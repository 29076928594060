import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import { reorderArray } from "../../../../config/utils";
import OuterDraggable from "react-draggable";
import ReactSelect from "react-select";
import NumberFormat from "react-number-format";
export const CAColumnConfigModal = props => {
    const dispatch = useDispatch();
    const { caColumns, exportTemplateList, exportPropertyList } = useSelector(state => state.smartChartReducer);
    const [columns, setColumns] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [transform, setTransform] = useState({ left: 0, top: 0 });
    const [exportTemplates, setExportTemplates] = useState([]);
    const [exportProperties, setExportProperties] = useState([]);
    const [template, setTemplate] = useState("");
    const [property, setProperty] = useState("");
    const [showErrorBorder, setShowErrorBorder] = useState("");
    const [propertyDropdownClick, setPropertyDropDownClick] = useState(false);

    useEffect(() => {
        fetchColumns();
        setPropertyDropDownClick(false);
        dispatch(actions?.getExportTemplateListForSmartChartRecom({ project_id: props?.selectedProject, client_id: props?.selectedClient }));
        dispatch(actions?.getExportPropertyListForSmartChartRecom({ project_id: props?.selectedProject, client_id: props?.selectedClient }));
    }, []);
    useEffect(() => {
        if (caColumns?.success) {
            const initialColumns =
                props.selectedFields && Object?.keys(props.selectedFields).length
                    ? typeof props.selectedFields === "string"
                        ? JSON.parse(props.selectedFields)
                        : props.selectedFields
                    : caColumns?.list?.columns && typeof caColumns?.list?.columns === "string"
                    ? JSON.parse(caColumns.list.columns)
                    : caColumns.list.columns;
            setColumns(initialColumns);
            if (propertyDropdownClick) {
                const initialColumns =
                    caColumns?.list?.columns && typeof caColumns?.list?.columns === "string"
                        ? JSON.parse(caColumns.list.columns)
                        : caColumns.list.columns;
                setColumns(initialColumns);
            }
        }
    }, [caColumns]);
    useEffect(() => {
        let exportProperties = exportPropertyList || {};
        let exportTemplates = exportTemplateList || {};
        let master_props = [];
        let local_props = [];
        let local_templates = [];
        let master_templates = [];
        if (exportProperties?.without_client?.length) {
            exportProperties.without_client.forEach(elem => master_props.push({ value: elem.id, label: elem.name }));
        }
        if (exportProperties?.with_client?.length) {
            exportProperties.with_client.forEach(elem => local_props.push({ value: elem.id, label: elem.name }));
        }
        exportProperties = [
            {
                label: "From Local",
                options: local_props
            },
            {
                label: "From Master",
                options: master_props
            }
        ];
        if (exportTemplates?.without_client?.length) {
            exportTemplates.without_client.forEach(elem => master_templates.push({ value: elem.id, label: elem.name }));
        }
        if (exportTemplates?.with_client?.length) {
            exportTemplates.with_client.forEach(elem => local_templates.push({ value: elem.id, label: elem.name }));
        }
        exportTemplates = [
            {
                label: "From Local",
                options: local_templates
            },
            {
                label: "From Master",
                options: master_templates
            }
        ];

        const selectedRecomTemplate = [...master_templates, ...local_templates].find(elem => elem?.value === caColumns?.list?.template_id);
        let selectedRecomProperty = [...master_props, ...local_props].find(elem => elem?.value === caColumns?.list?.property_id);

        if (props?.selectedProperty) {
            let selectedRecomPropertyValue = [...master_props, ...local_props].find(elem => elem?.value === props?.selectedProperty);
            setProperty(selectedRecomPropertyValue);
        } else {
            setProperty(selectedRecomProperty);
        }
        if (props?.selectedTemplate) {
            const selectedRecomTemplateValue = [...master_templates, ...local_templates].find(elem => elem?.value === props?.selectedTemplate);
            setTemplate(selectedRecomTemplateValue);
        } else {
            setTemplate(selectedRecomTemplate);
        }
        setExportProperties(exportProperties);
        setExportTemplates(exportTemplates);
    }, [exportPropertyList, exportTemplateList]);

    const fetchColumns = async selectedProperty => {
        const { line, column } = props.selectedElements;
        let params = {
            keyword: column + "_" + line,
            client_id: props?.selectedClient || "",
            project_id: props?.selectedProject || ""
        };
        if (selectedProperty) {
            params = { ...params, property_id: selectedProperty };
        }
        await dispatch(actions.getCaColumns(params, column));
        setLoading(false);
    };

    const onEnd = result => {
        if (!result.destination) {
            return;
        }
        const arrayData = Object.keys(columns).map(key => ({
            [key]: columns?.[key]
        }));

        const keyList = reorderArray(arrayData, result.source.index, result.destination.index);
        const objectData = keyList.reduce((acc, obj) => {
            const [key, value] = Object.entries(obj)[0];
            return { ...acc, [key]: value };
        }, {});
        // const reOrderedList = reorderArray(columns, result.source.index, result.destination.index);
        setColumns(objectData);
    };

    const handleItemCheck = item => {
        const updatedObject = {};
        Object.keys(columns).forEach(key => {
            if (columns[key]?.label === item.label) {
                updatedObject[key] = { ...columns[key], selected: !item.selected };
            } else {
                updatedObject[key] = columns[key];
            }
        });
        // const updatedArray = columns.map(column => {
        //     if (column.key === item.key) {
        //         return { ...column, selected: !item.selected };
        //     }
        //     return column;
        // });
        setColumns(updatedObject);
    };

    const handleSave = () => {
        // const selectedColumnKeys = columns?.filter(column => column?.checked)?.map(column => column.key);
        const selectedColumnKeys = columns;
        if (validate()) {
            props.onSave({ selectedColumnKeys, property: property?.value, template: template?.value });
        }
    };

    const handleSelectAll = e => {
        const { checked } = e.target;
        const updatedObject = {};
        columns &&
            Object.keys(columns).forEach(key => {
                if (columns[key]?.label !== "Code") {
                    updatedObject[key] = { ...columns[key], selected: checked };
                } else {
                    updatedObject[key] = columns[key];
                }
            });
        setColumns(updatedObject);
    };

    let searchedList = columns;
    if (search?.trim()?.length) {
        let searchValue = search.toLowerCase();
        searchedList = Object.entries(columns)
            ?.filter(([key, column]) => column.label && column.label.toString().toLowerCase().includes(searchValue))
            ?.map(([key, column]) => column);
    }
    const handleDrag = (e, ui) => {
        const { x, y } = ui;
        setTransform({ left: x, top: y });
    };
    const handleSelectExportProperty = value => {
        setProperty(value);
        fetchColumns(value?.value);
        setPropertyDropDownClick(true);
    };
    const validate = () => {
        let showError = false;
        if (!property?.value?.trim()?.length) {
            showError = true;
        } else if (!template?.value?.trim()?.length) {
            showError = true;
        }
        setShowErrorBorder(showError);
        if (showError) return false;
        return true;
    };
    const handleWidth = async (e, keyItem) => {
        const updatedObject = {};
        Object.keys(columns).forEach(key => {
            if (columns[key]?.label === keyItem.label) {
                updatedObject[key] = { ...columns[key], width: Number(e) };
            } else {
                updatedObject[key] = columns[key];
            }
        });
        setColumns(updatedObject);
    };

    return (
        <React.Fragment>
            <div className="modal modal-region modal-view reco-view-mdl" id="modalId" style={{ display: "block" }} tabIndex="-1">
                {/* Render Confirmation Modal */}
                <div className="modal-dialog" role="document">
                    <OuterDraggable handle=".draggable" cancel=".not-draggable" onDrag={handleDrag}>
                        <div className="modal-content outerModal_draggable" style={transform}>
                            <div className="modal-header draggable">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    <div className="txt-hed">CA Column Settings</div>
                                </h5>

                                <button type="button" className="close not-draggable" onClick={props.onCancel}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body region-otr">
                                <DragDropContext onDragEnd={onEnd}>
                                    <Droppable droppableId="droppable">
                                        {provided => (
                                            <div ref={provided.innerRef} className="col-md-12 check-otr d-flex checkbox-sec height-redu">
                                                <div className="col-md-12 p-0 d-flex">
                                                    <div className="col-md-4 box-otr">
                                                        <div className="rem-txt">
                                                            <label className="container-check">
                                                                Select All
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        Object.keys(columns)?.length &&
                                                                        Object.values(columns).every(column => column.selected)
                                                                    }
                                                                    onChange={handleSelectAll}
                                                                />
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8 formInp search pl-0">
                                                        <i className="fas fa-search" />
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={e => setSearch(e.target.value)}
                                                            value={search}
                                                            placeholder="Search Columns"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 box-otr box-wide">
                                                    <div className="">
                                                        <label className="container-check pl-0 mb-0">Export Template</label>
                                                    </div>
                                                    <div className="export-property-select-box">
                                                        <ReactSelect
                                                            options={exportTemplates}
                                                            value={template}
                                                            isClearable
                                                            classNamePrefix="react-select"
                                                            onChange={value => setTemplate(value)}
                                                            isSearchable={false}
                                                            className={showErrorBorder && !template?.value?.trim().length ? "error-border " : ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 box-otr box-wide">
                                                    <div className="">
                                                        <label className="container-check pl-0 mb-0">Export Property</label>
                                                    </div>
                                                    <div className="export-property-select-box">
                                                        <ReactSelect
                                                            options={exportProperties}
                                                            value={property}
                                                            isClearable
                                                            classNamePrefix="react-select"
                                                            onChange={value => handleSelectExportProperty(value)}
                                                            isSearchable={false}
                                                            className={showErrorBorder && !property?.value?.trim().length ? "error-border " : ""}
                                                        />
                                                    </div>
                                                </div>
                                                {isLoading ? (
                                                    <div className="col-md-12 box-otr box-wide ">Loading...</div>
                                                ) : Object.keys(searchedList)?.length ? (
                                                    Object?.entries(searchedList).map(([key, item], i) =>
                                                        key === "code" ? (
                                                            <div className="col-md-12 box-otr box-wide ">
                                                                <div className="rem-txt">
                                                                    <label className="container-check">
                                                                        {item?.label}

                                                                        <input
                                                                            type="checkbox"
                                                                            checked={item.selected}
                                                                            onChange={() => handleItemCheck(item)}
                                                                            disabled={key === "code"}
                                                                        />
                                                                        <span className={`checkmark ${key === "code" ? "cursor-diabled" : ""}`} />
                                                                    </label>
                                                                </div>
                                                                <div className="right-box-content">
                                                                    <div className="right-box-content">
                                                                        <div class="input-box">
                                                                            <NumberFormat
                                                                                autoComplete={"nope"}
                                                                                className="custom-input form-control"
                                                                                value={item["width"]}
                                                                                suffix={" inch"}
                                                                                thousandSeparator={true}
                                                                                onValueChange={values => {
                                                                                    const { value } = values;
                                                                                    handleWidth(value, item);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="image-drag">
                                                                            <img src="/img/Group 2.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <Draggable key={i} draggableId={`draggable-${i}`} index={i}>
                                                                {provided => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        key={`draggable-${i}`}
                                                                        className="col-md-12 box-otr box-wide "
                                                                    >
                                                                        <div className="rem-txt">
                                                                            <label className="container-check">
                                                                                {item?.label}

                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={item.selected}
                                                                                    onChange={() => handleItemCheck(item)}
                                                                                />
                                                                                <span className={`checkmark `} />
                                                                            </label>
                                                                        </div>
                                                                        <div className="right-box-content">
                                                                            <div className="right-box-content">
                                                                                <div class="input-box">
                                                                                    <NumberFormat
                                                                                        autoComplete={"nope"}
                                                                                        className="custom-input form-control"
                                                                                        value={item["width"]}
                                                                                        suffix={" inch"}
                                                                                        thousandSeparator={true}
                                                                                        onValueChange={values => {
                                                                                            const { value } = values;
                                                                                            handleWidth(value, item);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="image-drag">
                                                                                    <img src="/img/Group 2.svg" alt="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    )
                                                ) : (
                                                    <div className="col-md-12 box-otr box-wide ">No records found!</div>
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <div className="col-md-12 p-0 mt-4 text-right btnOtr d-flex col-md-12">
                                    <div className="tot-btr">
                                        <button
                                            type="button"
                                            onClick={props.onCancel}
                                            className="btn btn-secondary btnClr col-md-3 mr-1"
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        <button type="button" onClick={handleSave} className="btn btn-primary btnRgion btn-wid">
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OuterDraggable>
                </div>
            </div>
        </React.Fragment>
    );
};
