export const dropdownValues = {
    status: [
        { id: 0, name: "Submitted" },
        // { id: 1, name: "Received" },
        { id: 2, name: "Under Review" },
        { id: 3, name: "Partially Approved" },
        { id: 4, name: "Approved" },
        { id: 5, name: "Not Approved" },
        { id: 6, name: "Deferred to next year" }
    ],
    investment_priority: [
        { id: 0, name: "Low Priority (All other requests)" },
        { id: 1, name: "Medium Priority (ROI/potential failure)" },
        { id: 2, name: "High Priority (compliance/safety/equipment failure)" }
    ],
    request_status: [
        { id: 0, name: "New Request" },
        { id: 1, name: "Previously submitted but not approved" },
        { id: 2, name: "Approved and deferred from previous year" },
        { id: 3, name: "Other" }
    ],
    request_type: [
        { id: 0, name: "Capital improvement/renovation" },
        { id: 1, name: "Capital Equipment" },
        { id: 2, name: "Scientific Equipment" },
        { id: 3, name: "Vehicle" },
        { id: 4, name: "Annual Reserve/Allowance Request" },
        { id: 5, name: "Other" }
    ],
    equipment_request_type: [
        { id: 0, name: "New" },
        { id: 1, name: "Replacement" }
    ],
    radio_button: [
        { id: true, name: "Yes" },
        { id: false, name: "No" }
    ]
};

export const fields = {
    requested_at: {
        type: "date",
        label: "Date",
        isRequired: false,
        readOnly: true
    },
    code: {
        type: "text",
        label: "Code",
        isRequired: false,
        readOnly: true,
        inEditOnly: true
    },
    requestor_first_name: {
        type: "text",
        label: "First Name",
        isRequired: true
    },
    requestor_last_name: {
        type: "text",
        label: "Last Name",
        isRequired: true
    },
    status: {
        type: "dropdown",
        label: "Status",
        isRequired: false,
        adminOnly: true,
        inEditOnly: true
    },
    status_note: {
        type: "textArea",
        isRichText: true,
        label: "Status Notes",
        isRequired: false,
        adminOnly: true,
        inEditOnly: true
    },
    department_name: {
        type: "text",
        label: "Department",
        isRequired: true
    },
    funding_request_title: {
        type: "text",
        label: "Capital Funding Request Title",
        isRequired: true
    },
    request_estimate: {
        type: "amount",
        label: "Amount of Capital Request (Estimate in $)",
        isRequired: true
    },
    investment_priority: {
        type: "dropdown",
        label: "Priority",
        isRequired: true
    },
    request_status: {
        type: "dropdown",
        label: "Request Status",
        isRequired: true
    },
    request_type: {
        type: "dropdown",
        label: "Capital Request Type",
        isRequired: true
    },
    equipment_request_type: {
        type: "dropdown",
        label: "For Equipment or Vehicle Requests",
        isRequired: false
    },
    age_and_condition_of_replacement: {
        type: "text",
        label: "For Replacement requests, what is the age and condition of the existing equipment/vehicle",
        isRequired: false
    },
    request_description: {
        type: "textArea",
        isRichText: true,
        label: "Capital Request Description (please describe the project or item you are requesting)",
        isRequired: true
    },
    improving_service_inquiry: {
        type: "textArea",
        isRichText: true,
        label: "How will this capital investment improve services to research or other constituents?",
        isRequired: true
    },
    problem_alleviation_inquiry: {
        type: "textArea",
        isRichText: true,
        label: "What present or anticipated problem will this capital investment alleviate?",
        isRequired: true
    },
    compliance_resolution_inquiry: {
        type: "radio",
        label: "Is the capital investment needed to correct compliance with any laws or regulations?",
        isRequired: true
    },
    investment_efficiency_inquiry: {
        type: "radio",
        label: "Will this capital investment increase efficiency or have a return on investment?",
        isRequired: true
    },
    operations_cost_reduction_inquiry: {
        type: "radio",
        label: "Will this capital investment reduce your annual operating costs in some manner?",
        isRequired: true
    },
    safety_requirement_inquiry: {
        type: "radio",
        label: "Is safety a critical factor with regard to needing this capital investment?",
        isRequired: true
    },
    approval_delay_impact_inquiry: {
        type: "textArea",
        isRichText: true,
        label: "How would delays in the approval of the capital investment impact research or operations?  ",
        isRequired: true
    },
    future_plan_inquiry: {
        type: "textArea",
        isRichText: true,
        label: "How does this capital investment fit plans for the future and the objectives of the department?",
        isRequired: true
    },
    receiver_inquiry: {
        type: "text",
        label: "Who will it serve?",
        isRequired: true
    },
    other_request_relationship_inquiry: {
        type: "text",
        label: "What is the relationship, if any, to other capital requests?",
        isRequired: true
    },
    alternative_inquiry: {
        type: "text",
        label: "What, if any, are the alternatives to this investment?",
        isRequired: true
    },
    risk_inquiry: {
        type: "text",
        label: "What research or operations are at risk if not approved?",
        isRequired: true
    },
    vendor_name: {
        type: "text",
        label: "What source or vendor supplied the proposed cost/budget?",
        isRequired: true
    },
    has_funding_source_identified: {
        type: "radio",
        label: "Has a specific grant or a gift been identified as a potential source of funding for this request?",
        isRequired: true
    },
    funding_source_security_inquiry: {
        type: "radio",
        label: "If grant/gift: is the funding source secure?",
        isRequired: false
    },
    comments: {
        type: "textArea",
        isRichText: true,
        label: "Please use this field to add any additional notes or comments",
        isRequired: false
    }
};

export const ADD_REQUEST_REQUEST = "ADD_REQUEST_REQUEST";
export const ADD_REQUEST_SUCCESS = "ADD_REQUEST_SUCCESS";
export const ADD_REQUEST_FAILURE = "ADD_REQUEST_FAILURE";

export const UPDATE_REQUEST_REQUEST = "UPDATE_REQUEST_REQUEST";
export const UPDATE_REQUEST_SUCCESS = "UPDATE_REQUEST_SUCCESS";
export const UPDATE_REQUEST_FAILURE = "UPDATE_REQUEST_FAILURE";

export const GET_CAPITAL_REQUEST_DATA_REQUEST = "GET_CAPITAL_REQUEST_DATA_REQUEST";
export const GET_CAPITAL_REQUEST_DATA_SUCCESS = "GET_CAPITAL_REQUEST_DATA_SUCCESS";
export const GET_CAPITAL_REQUEST_DATA_FAILURE = "GET_CAPITAL_REQUEST_DATA_FAILURE";

export const GET_CAPITAL_REQUEST_DATA_BY_ID_REQUEST = "GET_CAPITAL_REQUEST_DATA_BY_ID_REQUEST";
export const GET_CAPITAL_REQUEST_DATA_BY_ID_SUCCESS = "GET_CAPITAL_REQUEST_DATA_BY_ID_SUCCESS";
export const GET_CAPITAL_REQUEST_DATA_BY_ID_FAILURE = "GET_CAPITAL_REQUEST_DATA_BY_ID_FAILURE";

export const GET_COMMON_FILTER_LIST_FOR_CR_REQUEST = "GET_COMMON_FILTER_LIST_FOR_CR_REQUEST";
export const GET_COMMON_FILTER_LIST_FOR_CR_SUCCESS = "GET_COMMON_FILTER_LIST_FOR_CR_SUCCESS";
export const GET_COMMON_FILTER_LIST_FOR_CR_FAILURE = "GET_COMMON_FILTER_LIST_FOR_CR_FAILURE";

export const GET_CAPITAL_REQUEST_DATA_EXPORT_REQUEST = "GET_CAPITAL_REQUEST_DATA_EXPORT_REQUEST";
export const GET_CAPITAL_REQUEST_DATA_EXPORT_SUCCESS = "GET_CAPITAL_REQUEST_DATA_EXPORT_SUCCESS";
export const GET_CAPITAL_REQUEST_DATA_EXPORT_FAILURE = "GET_CAPITAL_REQUEST_DATA_EXPORT_FAILURE";

export const SUBMIT_EMAIL_REQUEST = "SUBMIT_EMAIL_REQUEST";
export const SUBMIT_EMAIL_SUCCESS = "SUBMIT_EMAIL_SUCCESS";
export const SUBMIT_EMAIL_FAILURE = "SUBMIT_EMAIL_FAILURE";

export const LOCK_CAPITAL_REQUEST_REQUEST = "LOCK_CAPITAL_REQUEST_REQUEST";
export const LOCK_CAPITAL_REQUEST_SUCCESS = "LOCK_CAPITAL_REQUEST_SUCCESS";
export const LOCK_CAPITAL_REQUEST_FAILURE = "LOCK_CAPITAL_REQUEST_FAILURE";

export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const DELETE_CAPITAL_REQUEST_REQUEST = "DELETE_CAPITAL_REQUEST_REQUEST";
export const DELETE_CAPITAL_REQUEST_SUCCESS = "DELETE_CAPITAL_REQUEST_SUCCESS";
export const DELETE_CAPITAL_REQUEST_FAILURE = "DELETE_CAPITAL_REQUEST_FAILURE";

export const CR_USER_LOGOUT_REQUEST = "CR_USER_LOGOUT_REQUEST";
export const CR_USER_LOGOUT_SUCCESS = "CR_USER_LOGOUT_SUCCESS";
export const CR_USER_LOGOUT_FAILURE = "CR_USER_LOGOUT_FAILURE";

export const RESTORE_CR_REQUEST = "RESTORE_CR_REQUEST";
export const RESTORE_CR_SUCCESS = "RESTORE_CR_SUCCESS";
export const RESTORE_CR_FAILURE = "RESTORE_CR_FAILURE";

export const EMPTY_VIEW_PAGE_DATA = "EMPTY_VIEW_PAGE_DATA";
export const CLEAR_RESPONSES = "CLEAR_RESPONSES";

export const EXPORT_CAPITAL_REQUEST_REPORT_PDF_REQUEST = "EXPORT_CAPITAL_REQUEST_REPORT_PDF_REQUEST";

export const LINK_CAPITAL_REQUEST_TO_RECOMMENDATION_REQUEST = "LINK_CAPITAL_REQUEST_TO_RECOMMENDATION_REQUEST";
export const LINK_CAPITAL_REQUEST_TO_RECOMMENDATION_SUCCESS = "LINK_CAPITAL_REQUEST_TO_RECOMMENDATION_SUCCESS";
export const LINK_CAPITAL_REQUEST_TO_RECOMMENDATION_FAILURE = "LINK_CAPITAL_REQUEST_TO_RECOMMENDATION_FAILURE";

export const GET_CAPITAL_REQUEST_DATA_FOR_RECOMMENDATION_VIEW_REQUEST = "GET_CAPITAL_REQUEST_DATA_FOR_RECOMMENDATION_VIEW_REQUEST";
export const GET_CAPITAL_REQUEST_DATA_FOR_RECOMMENDATION_VIEW_SUCCESS = "GET_CAPITAL_REQUEST_DATA_FOR_RECOMMENDATION_VIEW_SUCCESS";
export const GET_CAPITAL_REQUEST_DATA_FOR_RECOMMENDATION_VIEW_FAILURE = "GET_CAPITAL_REQUEST_DATA_FOR_RECOMMENDATION_VIEW_FAILURE";

export const REMOVE_CAPITAL_REQUEST_LINK_TO_RECOMMENDATION_REQUEST = "REMOVE_CAPITAL_REQUEST_LINK_TO_RECOMMENDATION_REQUEST";
export const REMOVE_CAPITAL_REQUEST_LINK_TO_RECOMMENDATION_SUCCESS = "REMOVE_CAPITAL_REQUEST_LINK_TO_RECOMMENDATION_SUCCESS";
export const REMOVE_CAPITAL_REQUEST_LINK_TO_RECOMMENDATION_FAILURE = "REMOVE_CAPITAL_REQUEST_LINK_TO_RECOMMENDATION_FAILURE";

export const UPDATE_CR_ENTITY_PARAMS_SUCCESS = "UPDATE_CR_ENTITY_PARAMS_SUCCESS";
export const UPDATE_CR_ENTITY_PARAMS_FAILURE = "UPDATE_CR_ENTITY_PARAMS_FAILURE";
