import React, { useEffect, useState } from "react";
import { getPresignedUrl } from "../../common/actions";

export const VideoPlayer = ({ publicUrl = "", poster = "", className = "" }) => {
    const [videoUrl, setVideoUrl] = useState("");

    useEffect(() => {
        const fetchVideoUrl = async () => {
            const url = await getPresignedUrl(publicUrl);
            setVideoUrl(url);
        };

        fetchVideoUrl();
    }, [publicUrl]);

    return videoUrl ? (
        <video className={className} poster={poster} controls disablePictureInPicture>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    ) : (
        <p>Loading...</p>
    );
};
