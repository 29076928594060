import React from "react";

import { DOCUMENT_SORT_KEYS, ADVANCED_FILTER_KEYS } from "../constants";

const DocumentFilters = ({ handleSortData, sortParams = {}, handleSelectViewType, viewType, isImage = false, entityKey, isFavoriteView }) => {
    const renderAdvancedFilter = () => {
        if (ADVANCED_FILTER_KEYS?.[entityKey]) {
            return (
                <div className="sort-by-fl">
                    <div className="sort-by-innr">
                        <label>{ADVANCED_FILTER_KEYS?.[entityKey]?.label || ""}</label>
                    </div>
                    <div className="fl-outs d-flex">
                        <div className="selectOtr">
                            <select
                                autoComplete="nope"
                                className="form-control"
                                value={viewType}
                                onChange={e => handleSelectViewType(e.target.value)}
                            >
                                {ADVANCED_FILTER_KEYS?.[entityKey]?.options?.map((filterItem, index) => (
                                    <option key={index} value={filterItem.key}>
                                        {filterItem.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    const renderSortByFilter = () => (
        <div className="sort-by-fl">
            <div className="sort-by-innr">
                <label>Sort By</label>
            </div>
            <div className="fl-outs d-flex">
                <div className="selectOtr">
                    <select
                        autoComplete="nope"
                        className="form-control"
                        value={sortParams.sort_by}
                        onChange={e => handleSortData("sort_by", e.target.value)}
                    >
                        {DOCUMENT_SORT_KEYS?.[entityKey]?.map(sortItem => (
                            <option key={sortItem.key} value={sortItem.key}>
                                {sortItem.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="selectOtr">
                    <select
                        autoComplete="nope"
                        className="form-control"
                        value={sortParams.order}
                        onChange={e => handleSortData("order", e.target.value)}
                    >
                        <option value="asc">ASC</option>
                        <option value="desc">DESC</option>
                    </select>
                </div>
            </div>
        </div>
    );

    return (
        <div className="sort">
            <div className="form-item">
                {renderAdvancedFilter()}
                {renderSortByFilter()}
            </div>
        </div>
    );
};

export default DocumentFilters;
