import { cloneDeep, isEqual } from "lodash";
import React, { Component } from "react";
import ConfirmationModal from "./components/ConfirmationModal";
import Portal from "./components/Portal";
import Draggable from "react-draggable";
import { DragDropContext, Droppable, Draggable as ColumnDraggable } from "react-beautiful-dnd";
import { showAlert } from "../../config/utils";

export class DynamicColumnModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewFilter: "all",
            searchKey: "",
            tableData: cloneDeep(this.props.tableData),
            showConfirmModal: false,
            initialData: cloneDeep(this.props.tableData),
            transform: { left: 0, top: 0 }
        };
    }

    handleDropdownChange = event => {
        this.setState({
            viewFilter: event.target.value
        });
    };

    handleChangeColumn = async keyItem => {
        this.setState({
            tableData: {
                ...this.state.tableData,
                config: {
                    ...this.state.tableData.config,
                    [keyItem]: {
                        ...this.state.tableData.config[keyItem],
                        isVisible: !this.state.tableData.config[keyItem].isVisible
                    }
                }
            }
        });
    };
    handleChangeSelectAll = checked => {
        const { tableData } = this.state;
        const updatedTableData = { ...tableData };
        tableData.keys.forEach(keyItem => {
            updatedTableData.config[keyItem] = {
                ...updatedTableData.config[keyItem],
                isVisible: !checked
            };
        });
        this.setState({
            tableData: updatedTableData
        });
    };

    handleSearch = event => {
        this.setState({
            searchKey: event.target.value
        });
    };

    handleSave = () => {
        this.props.handleHideColumn(this.state.tableData);
        this.props.onCancel();
    };

    confirmCancel = () => {
        if (isEqual(this.state.tableData, this.state.initialData)) {
            this.props.onCancel();
        } else {
            this.setState({ showConfirmModal: true });
        }
    };

    renderConfirmationModal = () => {
        const { showConfirmModal } = this.state;
        if (!showConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Do you want to cancel and lose all changes?`}
                        type="cancel"
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => this.setState({ showConfirmModal: false })}
                        onYes={() => {
                            this.setState({ showConfirmModal: false });
                            this.props.onCancel();
                        }}
                    />
                }
                onCancel={() => this.setState({ showConfirmModal: false })}
            />
        );
    };

    handleDrag = (e, ui) => {
        const { x, y } = ui;
        this.setState({ transform: { left: x, top: y } });
    };

    onDragEnd = result => {
        if (!result.destination) return;
        if (!!this.state.searchKey?.trim()?.length) {
            showAlert("You can't drag and drop fields while searching!");
            return;
        }

        const reorderedKeyList = Array.from(this.state.tableData?.keys);
        const [removed] = reorderedKeyList.splice(result.source.index, 1);
        reorderedKeyList.splice(result.destination.index, 0, removed);
        this.setState({ tableData: { ...this.state.tableData, keys: reorderedKeyList } });
    };

    render() {
        const {
            viewFilter,
            tableData: { keys, config },
            searchKey
        } = this.state;
        const { onCancel } = this.props;
        const allSelected = keys.every(key => config[key].isVisible);
        let keyList = keys;
        if (viewFilter === "visible") {
            keyList = keys.filter(key => config[key].isVisible);
        } else if (viewFilter === "inVisible") {
            keyList = keys.filter(key => !config[key].isVisible);
        }
        if (searchKey?.trim()?.length) {
            keyList = keyList.filter(keyItem => {
                return config[keyItem]?.label?.toString()?.toLowerCase()?.includes(searchKey.toLowerCase());
            });
        }
        return (
            <React.Fragment>
                {this.renderConfirmationModal()}
                <div className="modal modal-region modal-view" id="modalId" style={{ display: "block" }} tabIndex="-1">
                    <Draggable
                        handle=".draggable"
                        cancel=".not-draggable"
                        onDrag={(e, ui) => {
                            this.props?.isDraggable && this.props?.isDraggable(false);
                            this.handleDrag(e, ui);
                        }}
                        onStart={this.props?.isDraggable && this.props?.isDraggable(true)}
                    >
                        <div className="modal-dialog outerModal_draggable" role="document" style={this.state.transform}>
                            <div className="modal-content">
                                <div className="modal-header draggable">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        <div className="txt-hed">View Details</div>
                                        <div className="selct-otr not-draggable">
                                            <select
                                                className="form-control"
                                                onChange={e => this.handleDropdownChange(e)}
                                                value={this.state.viewFilter}
                                            >
                                                <option value="all">All</option>
                                                <option value="visible">Visible</option>
                                                <option value="inVisible">Not Visible</option>
                                            </select>
                                        </div>
                                    </h5>
                                    <div className="not-draggable">
                                        <button type="button" className="close" onClick={this.confirmCancel}>
                                            <span aria-hidden="true">
                                                <img src="/img/close.svg" alt="" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="modal-body region-otr">
                                    <div className="col-md-12 formInp search">
                                        <i className="fas fa-search" />
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={this.handleSearch}
                                            value={this.state.searchKey}
                                            placeholder="Search Now"
                                        />
                                    </div>

                                    <div className="col-md-12 check-otr d-flex checkbox-sec not-draggable">
                                        {keyList.length ? (
                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                <Droppable droppableId="droppable-columns">
                                                    {provided => (
                                                        <div {...provided.droppableProps} ref={provided.innerRef} className="row">
                                                            {viewFilter === "all" && (
                                                                <>
                                                                    <div className="col-md-6 box-otr">
                                                                        <div className="rem-txt">
                                                                            <label className="container-check">
                                                                                Select All
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={allSelected}
                                                                                    onChange={() => this.handleChangeSelectAll(allSelected)}
                                                                                />
                                                                                <span className="checkmark" />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6"></div>
                                                                </>
                                                            )}
                                                            {keyList.map((keyItem, index) => (
                                                                <ColumnDraggable key={keyItem} draggableId={keyItem} index={index}>
                                                                    {provided => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className="col-md-12 box-otr box-wide"
                                                                        >
                                                                            <div className="rem-txt">
                                                                                <label className="container-check">
                                                                                    {config[keyItem]?.label}
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={config[keyItem]?.isVisible}
                                                                                        onChange={() => this.handleChangeColumn(keyItem)}
                                                                                    />
                                                                                    <span className="checkmark" />
                                                                                </label>
                                                                            </div>
                                                                            {!searchKey?.trim()?.length && (
                                                                                <div className="right-box-content">
                                                                                    <div className="image-drag">
                                                                                        <img src="/img/Group 2.svg" alt="" />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </ColumnDraggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        ) : (
                                            <div className="col-md-12 text-center mt-5">No records found!</div>
                                        )}
                                    </div>
                                    <div class="col-md-12 p-0 mt-4 text-right btnOtr d-flex col-md-12">
                                        <div className="tot-btr">
                                            <button
                                                type="button"
                                                onClick={this.confirmCancel}
                                                className="btn btn-secondary btnClr col-md-3 mr-1"
                                                data-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button type="button" onClick={this.handleSave} className="btn btn-primary btnRgion btn-wid">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
            </React.Fragment>
        );
    }
}
