// Get all trades
export const GET_ACCOUNTS_BASED_ON_BUILDING_REQUEST = "GET_ACCOUNTS_BASED_ON_BUILDING_REQUEST";
export const GET_ACCOUNTS_BASED_ON_BUILDING_SUCCESS = "GET_ACCOUNTS_BASED_ON_BUILDING_SUCCESS";
export const GET_ACCOUNTS_BASED_ON_BUILDING_FAILURE = "GET_ACCOUNTS_BASED_ON_BUILDING_FAILURE";

// Add trade
export const ADD_ACCOUNT_REQUEST = "ADD_ACCOUNT_REQUEST";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE";

// Update trade
export const UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE";

// Delete trade
export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

// Get Trade by Id
export const GET_ACCOUNT_BY_ID_REQUEST = "GET_ACCOUNT_BY_ID_REQUEST";
export const GET_ACCOUNT_BY_ID_SUCCESS = "GET_ACCOUNT_BY_ID_SUCCESS";
export const GET_ACCOUNT_BY_ID_FAILURE = "GET_ACCOUNT_BY_ID_FAILURE";

// Update Trade Entity Param
export const UPDATE_ACCOUNT_ENTITY_PARAMS_SUCCESS = "UPDATE_ACCOUNT_ENTITY_PARAMS_SUCCESS";
export const UPDATE_ACCOUNT_ENTITY_PARAMS_FAILURE = "UPDATE_ACCOUNT_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_ALL_ACCOUNT_LOG_REQUEST = "GET_ALL_ACCOUNT_LOG_REQUEST";
export const GET_ALL_ACCOUNT_LOG_SUCCESS = "GET_ALL_ACCOUNT_LOG_SUCCESS";
export const GET_ALL_ACCOUNT_LOG_FAILURE = "GET_ALL_ACCOUNT_LOG_FAILURE";

export const RESTORE_ACCOUNT_LOG_REQUEST = "RESTORE_ACCOUNT_LOG_REQUEST";
export const RESTORE_ACCOUNT_LOG_SUCCESS = "RESTORE_ACCOUNT_LOG_SUCCESS";
export const RESTORE_ACCOUNT_LOG_FAILURE = "RESTORE_ACCOUNT_LOG_FAILURE";

export const DELETE_ACCOUNT_LOG_REQUEST = "DELETE_ACCOUNT_LOG_REQUEST";
export const DELETE_ACCOUNT_LOG_SUCCESS = "DELETE_ACCOUNT_LOG_SUCCESS";
export const DELETE_ACCOUNT_LOG_FAILURE = "DELETE_ACCOUNT_LOG_FAILURE";

export const GET_ACCOUNT_EXPORT_REQUEST = "GET_ACCOUNT_EXPORT_REQUEST";
export const GET_ACCOUNT_EXPORT_SUCCESS = "GET_ACCOUNT_EXPORT_SUCCESS";
export const GET_ACCOUNT_EXPORT_FAILURE = "GET_ACCOUNT_EXPORT_FAILURE";

export const GET_ALL_CLIENT_USERS_REQUEST = "GET_ALL_CLIENT_USERS_REQUEST";
export const GET_ALL_CLIENT_USERS_SUCCESS = "GET_ALL_CLIENT_USERS_SUCCESS";
export const GET_ALL_CLIENT_USERS_FAILURE = "GET_ALL_CLIENT_USERS_FAILURE";

// Get all consultancy users
export const GET_ALL_CONSULTANCY_USERS_REQUEST = "GET_ALL_CONSULTANCY_USERS_REQUEST";
export const GET_ALL_CONSULTANCY_USERS_SUCCESS = "GET_ALL_CONSULTANCY_USERS_SUCCESS";
export const GET_ALL_CONSULTANCY_USERS_FAILURE = "GET_ALL_CONSULTANCY_USERS_FAILURE";

export const GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST = "GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST";
export const GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS = "GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS";
export const GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE = "GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE";

// Get all Clients
export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const GET_ALL_CLIENTS_FAILURE = "GET_ALL_CLIENTS_FAILURE";

export const GET_ALL_BUILDINGS_DROP_DOWN_REQUEST = "GET_ALL_BUILDINGS_DROP_DOWN_REQUEST";
export const GET_ALL_BUILDINGS_DROP_DOWN_SUCCESS = "GET_ALL_BUILDINGS_DROP_DOWN_SUCCESS";
export const GET_ALL_BUILDINGS_DROP_DOWN_FAILURE = "GET_ALL_BUILDINGS_DROP_DOWN_FAILURE";

export const GET_ASSIGN_MODAL_DETAILS_REQUEST = "GET_ASSIGN_MODAL_DETAILS_REQUEST";
export const GET_ASSIGN_MODAL_DETAILS_SUCCESS = "GET_ASSIGN_MODAL_DETAILS_SUCCESS";
export const GET_ASSIGN_MODAL_DETAILS_FAILURE = "GET_ASSIGN_MODAL_DETAILS_FAILURE";

export const ASSIGN_ITEMS_REQUEST = "ASSIGN_ITEMS_REQUEST";
export const ASSIGN_ITEMS_SUCCESS = "ASSIGN_ITEMS_SUCCESS";
export const ASSIGN_ITEMS_FAILURE = "ASSIGN_ITEMS_FAILURE";
