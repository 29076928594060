import React, { Component } from "react";
import isEqual from "lodash/isEqual";
import { withRouter } from "react-router-dom";
import Loader from "../../common/components/Loader";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import Portal from "../../common/components/Portal";
import LoadingOverlay from "react-loading-overlay";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import actions from "../actions";
import commonActions from "../../common/actions";
import NumberFormat from "react-number-format";
import qs from "query-string";
import exclmIcon from "../../../assets/img/recom-icon.svg";
import { getCurrentAge, getUsefullLifeRemaining, toArrayOfObjects } from "../../../config/utils";
import ReactTooltip from "react-tooltip";
import clientActions from "../../client/actions";
import { RecommendationBand as AssetBand } from "../../recommendations/components/Bands/RecommendationBand";
import { DYNAMIC_FIELD_TYPES } from "../../common/constants";
import { FormField } from "../../recommendations/components/Bands/FormField";
import moment from "moment";
import { DynamicBand } from "../../recommendations/components/Bands/DynamicBand";
import _ from "lodash";
const initialData = {
    asset_name: "",
    asset_tag: "",
    asset_note: "",
    client_asset_condition_id: "",
    description: "",
    installed_year: "",
    service_life: "",
    usefull_life_remaining: "",
    crv: "",
    csp_unit: "",
    manufacturer_id: "",
    year_manufactured: "",
    model_number: "",
    core_model_number: "",
    serial_number: "",
    capacity: "",
    capacity_unit: "",
    asset_ownership: "",
    criticality: "",
    area_served: "",
    energy_use_type: "",
    unit_cost: "",

    client_id: "",
    system_id: "",
    sub_system_id: "",
    trade_id: "",
    region_id: "",
    site_id: "",
    building_id: "",
    addition_id: "",
    floor_id: "",
    room_number: "",
    room_name: "",
    location: "",
    architectural_room_number: "",
    additional_room_description: "",

    uniformat_level_1_id: "",
    uniformat_level_2_id: "",
    uniformat_level_3_id: "",
    uniformat_level_4_id: "",
    uniformat_level_5_id: "",
    uniformat_level_6_id: "",
    uniformat_level_6_description: "",
    uniformat_level_3_custom_description: "",

    asset_type_id: "",
    asset_description: "",
    asset_barcode: "",
    asset_client_id: "",
    asset_cmms_id: "",

    warranty_start: "",
    warranty_end: "",
    install_date: "",
    startup_date: "",
    upstream_asset_barcode_number: "",
    linked_asset_barcode_number: "",
    source_panel_barcode_number: "",
    source_panel_emergency: "",
    asset_status_id: "",
    notes: "",

    main_category_id: "",
    sub_category_1_id: "",
    sub_category_2_id: "",
    subcategory2_description: "",
    sub_category_3_id: "",
    guid: "",
    source_panel_name: "",
    skysite_hyperlink: "",
    quantity: "",

    rtls_tag: "",
    latitude: "",
    longitude: "",
    current_age: "",
    age: "",
    new_asset: "",
    parent_global_id: "",
    survey_global_id: "",
    survey_id: "",
    survey_property_note: "",
    capacity_status: "",
    installed_year_status: "",
    name_plate_status: "",
    qa_notes: "",
    additional_qa_notes: "",
    surveyor: "",
    editor: "",
    survey_date_created: "",
    survey_date_edited: "",
    refrigerant: "",
    discharge: "",
    return: "",
    unit_property_labeled: "",
    factory_mounted_disconnect: "",
    unit_voltage: "",
    ahu_voltage: "",
    wire_type: "",
    wire_size: "",
    estimated_wire_run_length: "",
    breaker_size: "",
    mocp: "",
    mca: "",
    liquid_line: "",
    suction_line: "",
    hot_gas_by_pass: "",
    number_of_circuits: "",
    length_of_line: "",
    damper_type: "",
    gas_heating: "",
    gas_output_btu: "",
    electric_heating: "",
    electric_heating_kw: "",
    pump_backup_heat_kw: "",
    cooling_only: "",
    controls: "",
    control_type: "",
    smoke_detector: "",
    smoke_detector_mounting: "",
    smoke_detector_supply: "",
    smoke_detector_return: "",
    curb: "",
    curb_description: "",
    vibration_isolation: "",
    burglar_bars: "",
    installed_on_adapter_curb: "",
    curb_outside_dimension_length: "",
    curb_outside_dimension_width: "",
    allow_curb_adaptor: "",
    ducted_or_concentric_system: "",
    unit_weight: "",
    codes_for_disconnects: "",
    codes_for_convenience_outlets: "",
    codes_for_roof_lighting: "",
    duct_work_attached_to_bottom_of_unit: "",
    aluminium_wire_used: "",
    code_or_safety_concerns: "",
    distance_from_salt_water_body: "",
    recommend_coil_coating: "",
    need_resealed: "",
    split_system_line_sets_good_condition: "",
    split_system_line_sets_damaged: "",
    split_system_line_sets_crimped: "",
    split_system_line_sets_insulated: "",
    split_system_line_sets_re_used: "",
    line_connection_type: "",
    boiler_type: "",
    boiler_burner_type: "",
    boiler_btu_input: "",
    boiler_btu_output: "",
    boiler_hp: "",
    boiler_fuel: "",
    boiler_gas_pressure: "",
    boiler_kw_input: "",
    boiler_power_burner_type: "",
    boiler_burner_model: "",
    boiler_burner_serial: "",
    boiler_pressure_type: "",
    confirm_boiler_pressure: "",
    auxiliary_equipment_name: "",
    auxiliary_model: "",
    auxiliary_serial: "",
    auxiliary_date_manufactured: "",
    auxiliary_manufacturer: "",
    auxiliary_condition: "",
    auxiliary_eul: "",
    auxiliary_crv: "",
    dynamic_fields: {}
};
class Form extends Component {
    constructor(props) {
        super(props);
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        this.state = {
            isLoading: true,
            isUploading: false,
            errorMessage: "",
            data: { ...initialData, client_id: query.c_id, region_id: query.r_id, site_id: query.s_id, building_id: query.b_id },
            errorParams: {
                name: ""
            },
            initialValues: {},
            showConfirmModal: false,
            showErrorBorder: false,
            showBand: {
                band1: true,
                band2: false,
                band3: false,
                band4: false,
                band5: false
            },
            isLoadingDrop: false
        };
    }

    componentDidMount = async () => {
        this.getInitialDropdowns();
        if (this.props.selectedData) {
            const data = await this.props.getAssetById();
            if (data.success) {
                const dynamicFieldValue = {};
                if (data?.dynamic_fields && Object.keys(data?.dynamic_fields)?.length) {
                    Object.keys(data?.dynamic_fields).forEach(field => {
                        dynamicFieldValue[field] = data.dynamic_fields[field]?.value;
                    });
                }

                await this.setState({
                    data: {
                        asset_name: data.asset_name,
                        asset_tag: data.asset_tag,
                        asset_note: data.asset_note,
                        client_asset_condition_id: data.client_asset_condition?.id,
                        description: data.client_asset_condition?.description,
                        installed_year: data.installed_year,
                        service_life: data.service_life,
                        usefull_life_remaining: data.usefull_life_remaining,
                        crv: data.crv,
                        energy_use_type: data.energy_use_type,
                        unit_cost: data.unit_cost,
                        csp_unit: data.csp_unit,
                        manufacturer_id: data.manufacturer?.id,
                        year_manufactured: data.year_manufactured,
                        model_number: data.model_number,
                        serial_number: data.serial_number,
                        core_model_number: data.core_model_number,
                        capacity: data.capacity,
                        capacity_unit: data.capacity_unit,
                        asset_ownership: data.asset_ownership,
                        criticality: data.criticality,
                        area_served: data.area_served,
                        client_id: data.client?.id,
                        trade_id: data.trade?.id,
                        system_id: data.system?.id,
                        sub_system_id: data.sub_system?.id,
                        region_id: data.region?.id,
                        site_id: data.site?.id,
                        building_id: data.building?.id,
                        addition_id: data.addition?.id,
                        floor_id: data.floor?.id,
                        room_number: data.room_number,
                        room_name: data.room_name,
                        location: data.location,
                        architectural_room_number: data.architectural_room_number,
                        additional_room_description: data.additional_room_description,
                        uniformat_level_1_id: data.uniformat_level_1?.id,
                        uniformat_level_2_id: data.uniformat_level_2?.id,
                        uniformat_level_3_id: data.uniformat_level_3?.id,
                        uniformat_level_4_id: data.uniformat_level_4?.id,
                        uniformat_level_5_id: data.uniformat_level_5?.id,
                        uniformat_level_6_id: data.uniformat_level_6?.id,
                        asset_type_id: data.asset_type?.id,
                        asset_description: data.asset_description,
                        asset_barcode: data.asset_barcode,
                        asset_client_id: data.asset_client_id,
                        asset_cmms_id: data.asset_cmms_id,
                        warranty_start: data.warranty_start,
                        warranty_end: data.warranty_end,
                        install_date: data.install_date,
                        startup_date: data.startup_date,
                        upstream_asset_barcode_number: data.upstream_asset_barcode_number,
                        linked_asset_barcode_number: data.linked_asset_barcode_number,
                        source_panel_barcode_number: data.source_panel_barcode_number,
                        source_panel: data.source_panel,
                        asset_status_id: data.asset_status?.id,
                        notes: data.notes,
                        building_type: data?.building_type?.name,
                        main_category_id: data?.main_category?.id,
                        sub_category_1_id: data?.sub_category_1?.id,
                        sub_category_2_id: data?.sub_category_2?.id,
                        sub_category_3_id: data?.sub_category_3?.id,
                        guid: data?.guid,
                        source_panel_name: data?.source_panel_name,
                        skysite_hyperlink: data?.skysite_hyperlink,
                        quantity: data.quantity,
                        uniformat_level_6_description: data.uniformat_level_6?.uniformat_level_6_description,
                        uniformat_level_3_custom_description: data?.uniformat_level_3_custom_description,
                        rtls_tag: data?.rtls_tag,
                        latitude: data?.latitude,
                        longitude: data?.longitude,
                        current_age: data?.current_age,
                        age: data?.age,
                        new_asset: data?.new_asset,
                        parent_global_id: data?.parent_global_id,
                        survey_global_id: data?.survey_global_id,
                        survey_id: data?.survey_id,
                        survey_property_note: data?.survey_property_note,
                        capacity_status: data?.capacity_status,
                        installed_year_status: data?.installed_year_status,
                        name_plate_status: data?.name_plate_status,
                        qa_notes: data?.qa_notes,
                        additional_qa_notes: data?.additional_qa_notes,
                        surveyor: data?.surveyor,
                        editor: data?.editor,
                        survey_date_created: data?.survey_date_created,
                        survey_date_edited: data?.survey_date_edited,
                        subcategory2_description: data?.sub_category_2?.subcategory2_description,
                        refrigerant: data?.refrigerant,
                        discharge: data?.asset_additional?.discharge,
                        return: data?.asset_additional?.return,
                        unit_property_labeled: data?.asset_additional?.unit_property_labeled,
                        factory_mounted_disconnect: data?.asset_additional?.factory_mounted_disconnect,
                        unit_voltage: data?.asset_additional?.unit_voltage,
                        ahu_voltage: data?.asset_additional?.ahu_voltage,
                        wire_type: data?.asset_additional?.wire_type,
                        wire_size: data?.asset_additional?.wire_size,
                        estimated_wire_run_length: data?.asset_additional?.estimated_wire_run_length,
                        breaker_size: data?.asset_additional?.breaker_size,
                        mocp: data?.asset_additional?.mocp,
                        mca: data?.asset_additional?.mca,
                        liquid_line: data?.asset_additional?.liquid_line,
                        suction_line: data?.asset_additional?.suction_line,
                        hot_gas_by_pass: data?.asset_additional?.hot_gas_by_pass,
                        number_of_circuits: data?.asset_additional?.number_of_circuits,
                        length_of_line: data?.asset_additional?.length_of_line,
                        damper_type: data?.asset_additional?.damper_type,
                        gas_heating: data?.asset_additional?.gas_heating,
                        gas_output_btu: data?.asset_additional?.gas_output_btu,
                        electric_heating: data?.asset_additional?.electric_heating,
                        electric_heating_kw: data?.asset_additional?.electric_heating_kw,
                        pump_backup_heat_kw: data?.asset_additional?.pump_backup_heat_kw,
                        cooling_only: data?.asset_additional?.cooling_only,
                        controls: data?.asset_additional?.controls,
                        control_type: data?.asset_additional?.control_type,
                        smoke_detector: data?.asset_additional?.smoke_detector,
                        smoke_detector_mounting: data?.asset_additional?.smoke_detector_mounting,
                        smoke_detector_supply: data?.asset_additional?.smoke_detector_supply,
                        smoke_detector_return: data?.asset_additional?.smoke_detector_return,
                        curb: data?.asset_additional?.curb,
                        curb_description: data?.asset_additional?.curb_description,
                        vibration_isolation: data?.asset_additional?.vibration_isolation,
                        burglar_bars: data?.asset_additional?.burglar_bars,
                        installed_on_adapter_curb: data?.asset_additional?.installed_on_adapter_curb,
                        curb_outside_dimension_length: data?.asset_additional?.curb_outside_dimension_length,
                        curb_outside_dimension_width: data?.asset_additional?.curb_outside_dimension_width,
                        allow_curb_adaptor: data?.asset_additional?.allow_curb_adaptor,
                        ducted_or_concentric_system: data?.asset_additional?.ducted_or_concentric_system,
                        unit_weight: data?.asset_additional?.unit_weight,
                        codes_for_disconnects: data?.asset_additional?.codes_for_disconnects,
                        codes_for_convenience_outlets: data?.asset_additional?.codes_for_convenience_outlets,
                        codes_for_roof_lighting: data?.asset_additional?.codes_for_roof_lighting,
                        duct_work_attached_to_bottom_of_unit: data?.asset_additional?.duct_work_attached_to_bottom_of_unit,
                        aluminium_wire_used: data?.asset_additional?.aluminium_wire_used,
                        code_or_safety_concerns: data?.asset_additional?.code_or_safety_concerns,
                        distance_from_salt_water_body: data?.asset_additional?.distance_from_salt_water_body,
                        recommend_coil_coating: data?.asset_additional?.recommend_coil_coating,
                        need_resealed: data?.asset_additional?.need_resealed,
                        split_system_line_sets_good_condition: data?.asset_additional?.split_system_line_sets_good_condition,
                        split_system_line_sets_damaged: data?.asset_additional?.split_system_line_sets_damaged,
                        split_system_line_sets_crimped: data?.asset_additional?.split_system_line_sets_crimped,
                        split_system_line_sets_insulated: data?.asset_additional?.split_system_line_sets_insulated,
                        split_system_line_sets_re_used: data?.asset_additional?.split_system_line_sets_re_used,
                        line_connection_type: data?.asset_additional?.line_connection_type,
                        boiler_type: data?.asset_additional?.boiler_type,
                        boiler_burner_type: data?.asset_additional?.boiler_burner_type,
                        boiler_btu_input: data?.asset_additional?.boiler_btu_input,
                        boiler_btu_output: data?.asset_additional?.boiler_btu_output,
                        boiler_hp: data?.asset_additional?.boiler_hp,
                        boiler_fuel: data?.asset_additional?.boiler_fuel,
                        boiler_gas_pressure: data?.asset_additional?.boiler_gas_pressure,
                        boiler_kw_input: data?.asset_additional?.boiler_kw_input,
                        boiler_power_burner_type: data?.asset_additional?.boiler_power_burner_type,
                        boiler_burner_model: data?.asset_additional?.boiler_burner_model,
                        boiler_burner_serial: data?.asset_additional?.boiler_burner_serial,
                        boiler_pressure_type: data?.asset_additional?.boiler_pressure_type,
                        confirm_boiler_pressure: data?.asset_additional?.confirm_boiler_pressure,
                        auxiliary_equipment_name: data?.auxiliary?.length ? data?.auxiliary[0].auxiliary_equipment_name : "",
                        auxiliary_model: data?.auxiliary?.length ? data?.auxiliary[0]?.auxiliary_model : "",
                        auxiliary_serial: data?.auxiliary?.length ? data?.auxiliary[0]?.auxiliary_serial : "",
                        auxiliary_date_manufactured: data?.auxiliary?.length ? data?.auxiliary[0].auxiliary_date_manufactured : "",
                        auxiliary_manufacturer: data?.auxiliary?.length ? data?.auxiliary[0].auxiliary_manufacturer : "",
                        auxiliary_condition: data?.auxiliary?.length ? data?.auxiliary[0].auxiliary_condition : "",
                        auxiliary_eul: data?.auxiliary?.length ? data?.auxiliary[0].auxiliary_eul : "",
                        auxiliary_crv: data?.auxiliary?.length ? data?.auxiliary[0].auxiliary_crv : "",
                        auxiliary_id: data?.auxiliary?.length ? data?.auxiliary[0].id : "",
                        dynamic_fields: dynamicFieldValue
                    }
                });
                this.getSelectedDataDropdown();
            }
        } else {
            await this.getSelectedRecommendationData();
        }
        this.props.getClientById(this.state.data.client_id);
        await this.setState({
            initialValues: this.state.data,
            isLoading: false
        });
    };

    componentDidUpdate = () => {
        if (!isEqual(this.state.initialValues, this.state.data)) {
            this.props.setFormDirty(true);
        } else this.props.setFormDirty(false);
    };

    getSelectedRecommendationData = async () => {
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        if (query?.isRecomAsset) {
            const fd = JSON.parse(sessionStorage.getItem("currentFormData"));
            await this.setState({
                data: {
                    client_id: fd?.client_id,
                    // trade_id:fd?.trade_id,
                    region_id: fd?.region_id,
                    site_id: fd?.site_id,
                    building_id: fd?.building_id,
                    addition_id: fd?.addition_id,
                    floor_id: fd?.floor_id,
                    room_number: fd?.room,
                    building_type: fd?.building_type
                }
            });
            this.props.getDropdownList("asset_statuses", {
                client_id: fd?.client_id
            });
            this.props.getDropdownList("asset_types", {
                client_id: fd?.client_id
            });
            this.props.getDropdownList("asset_conditions", {
                client_id: fd?.client_id
            });
            this.props.getDropdownList("regions", {
                client_id: fd?.client_id
            });
            this.props.getDropdownList("sites", {
                region_id: fd?.region_id
            });
            this.props.getDropdownList("buildings", {
                site_id: fd?.site_id
            });
            this.props.getDropdownList("floors", {
                building_id: fd?.building_id
            });
            this.props.getDropdownList("additions", {
                building_id: fd?.building_id
            });
        }
    };

    getInitialDropdowns = () => {
        this.props.getDropdownList("clients");
        this.props.getDropdownList("uniformat_level_1s");
        if (!this.props.selectedData) {
            const { client_id, region_id, site_id, building_id, trade_id, system_id } = this.state.data;
            if (client_id) {
                this.props.getDropdownList("trades", {
                    client_id: this.state.data.client_id
                });
                this.props.getDropdownList("asset_statuses", {
                    client_id: this.state.data.client_id
                });
                this.props.getDropdownList("asset_types", {
                    client_id: this.state.data.client_id
                });
                this.props.getDropdownList("asset_conditions", {
                    client_id: this.state.data.client_id
                });
                this.props.getDropdownList("regions", {
                    client_id: this.state.data.client_id
                });
                this.props.getDropdownList("main_categories", {
                    client_id: this.state.data.client_id
                });
            }
            if (region_id)
                this.props.getDropdownList("sites", {
                    region_id: this.state.data.region_id
                });
            if (site_id)
                this.props.getDropdownList("buildings", {
                    site_id: this.state.data.site_id
                });
            if (building_id) {
                this.props.getDropdownList("floors", {
                    building_id: this.state.data.building_id
                });
                this.props.getDropdownList("additions", {
                    building_id: this.state.data.building_id
                });
            }
            if (trade_id)
                this.props.getDropdownList("systems", {
                    client_id: this.state.data.client_id,
                    trade_id: this.state.data.trade_id
                });
            if (system_id)
                this.props.getDropdownList("sub_systems", {
                    client_id: this.state.data.client_id,
                    system_id: this.state.data.system_id
                });
        }
    };
    getSelectedDataDropdown = () => {
        this.props.getDropdownList("manufacturers", { current_manufacturer: this.state.data?.manufacturer_id && this.state.data?.manufacturer_id });
        this.props.getDropdownList("asset_statuses", {
            client_id: this.state.data.client_id
        });
        this.props.getDropdownList("asset_types", {
            client_id: this.state.data.client_id
        });
        this.props.getDropdownList("asset_conditions", {
            client_id: this.state.data.client_id
        });
        this.props.getDropdownList("regions", {
            client_id: this.state.data.client_id
        });
        this.props.getDropdownList("sites", {
            region_id: this.state.data.region_id
        });
        this.props.getDropdownList("buildings", {
            site_id: this.state.data.site_id
        });
        this.props.getDropdownList("floors", {
            building_id: this.state.data.building_id
        });
        this.props.getDropdownList("additions", {
            building_id: this.state.data.building_id
        });
        this.props.getDropdownList("uniformat_level_2s", {
            uniformat_level_1_id: this.state.data.uniformat_level_1_id
        });
        this.props.getDropdownList("uniformat_level_3s", {
            uniformat_level_1_id: this.state.data.uniformat_level_1_id,
            uniformat_level_2_id: this.state.data.uniformat_level_2_id
        });
        this.props.getDropdownList("uniformat_level_4s", {
            uniformat_level_1_id: this.state.data.uniformat_level_1_id,
            uniformat_level_2_id: this.state.data.uniformat_level_2_id,
            uniformat_level_3_id: this.state.data.uniformat_level_3_id
        });
        this.props.getDropdownList("uniformat_level_5s", {
            uniformat_level_1_id: this.state.data.uniformat_level_1_id,
            uniformat_level_2_id: this.state.data.uniformat_level_2_id,
            uniformat_level_3_id: this.state.data.uniformat_level_3_id,
            uniformat_level_4_id: this.state.data.uniformat_level_4_id
        });
        this.props.getDropdownList("uniformat_level_6s", {
            uniformat_level_1_id: this.state.data.uniformat_level_1_id,
            uniformat_level_2_id: this.state.data.uniformat_level_2_id,
            uniformat_level_3_id: this.state.data.uniformat_level_3_id,
            uniformat_level_4_id: this.state.data.uniformat_level_4_id,
            uniformat_level_5_id: this.state.data.uniformat_level_5_id
        });
        this.props.getDropdownList("main_categories", {
            client_id: this.state.data.client_id
        });
        this.props.getDropdownList("sub_category_1s", {
            client_id: this.state.data.client_id,
            main_category_id: this.state.data.main_category_id
        });
        this.props.getDropdownList("sub_category_2s", {
            client_id: this.state.data.client_id,
            main_category_id: this.state.data.main_category_id,
            sub_category_1_id: this.state.data.sub_category_1_id
        });
        this.props.getDropdownList("sub_category_3s", {
            client_id: this.state.data.client_id,
            main_category_id: this.state.data.main_category_id,
            sub_category_1_id: this.state.data.sub_category_1_id,
            sub_category_2_id: this.state.data.sub_category_2_id
        });
        this.props.getDropdownList("trades", {
            client_id: this.state.data.client_id
        });
        this.props.getDropdownList("systems", {
            client_id: this.state.data.client_id,
            trade_id: this.state.data.trade_id
        });
        this.props.getDropdownList("sub_systems", {
            client_id: this.state.data.client_id,
            system_id: this.state.data.system_id
        });
    };

    validate = () => {
        const { data } = this.state;
        let errorParams = {};
        let showErrorBorder = false;
        const { miscellaneous } = this.props.getClientByIdResponse;
        let validateDynamicFields = this.validateDynamicFields();
        if (!data?.asset_name?.trim().length) {
            errorParams.asset_name = "* Asset Name is required";
            showErrorBorder = true;
        }
        if (!data.client_id?.trim().length) {
            errorParams.client_id = "* Client is required";
            showErrorBorder = true;
        }
        if (!data.region_id?.trim().length) {
            errorParams.region_id = "* Region is required";
            showErrorBorder = true;
        }
        if (!data.site_id?.trim().length) {
            errorParams.site_id = "* Site is required";
            showErrorBorder = true;
        }
        if (!data.building_id?.trim().length) {
            errorParams.building_id = "* Building is required";
            showErrorBorder = true;
        }
        if (miscellaneous?.addition_required && !data.addition_id?.trim()?.length) {
            showErrorBorder = true;
            errorParams.addition_id = "* Addition  is required";
        }
        if (miscellaneous?.floor_required && !data.floor_id?.trim()?.length) {
            showErrorBorder = true;
            errorParams.floor_id = "* Floor  is required";
        }
        if (miscellaneous?.room_required && !data?.room_number?.trim()?.length) {
            showErrorBorder = true;
            errorParams.room_number = "* Room Number is required";
        }
        if (miscellaneous?.asset_tag_required && !data?.asset_tag?.trim()?.length) {
            showErrorBorder = true;
            errorParams.asset_tag = "* Asset Tag is required";
        }
        if (miscellaneous?.model_number_required && !data?.model_number?.trim()?.length) {
            showErrorBorder = true;
            errorParams.model_number = "* Model Number is required";
        }
        if (miscellaneous?.serial_number_required && !data?.serial_number?.trim()?.length) {
            showErrorBorder = true;
            errorParams.serial_number = "* Serial Number is required";
        }
        if (miscellaneous?.manufacturer_required && !data?.manufacturer_id?.trim()?.length) {
            showErrorBorder = true;
            errorParams.manufacturer = "* Manufacturer is required";
        }
        if (miscellaneous?.capacity_required && !data?.capacity?.trim()?.length) {
            showErrorBorder = true;
            errorParams.capacity = "* Capacity is required";
        }
        if (miscellaneous?.capacity_unit_required && !data?.capacity_unit?.trim()?.length) {
            showErrorBorder = true;
            errorParams.capacity_unit = "* Capacity unit is required";
        }
        if (miscellaneous?.refrigerant_required && !data?.refrigerant?.trim()?.length) {
            showErrorBorder = true;
            errorParams.refrigerant = "* Refrigerant is required";
        }
        if (!validateDynamicFields.isValidate) {
            showErrorBorder = true;
            errorParams[validateDynamicFields.fieldKey] = validateDynamicFields.errorMessage;
        }
        this.setState({
            showErrorBorder,
            errorParams
        });
        if (showErrorBorder) return false;
        return true;
    };

    validateDynamicFields = () => {
        const {
            data: { dynamic_fields }
        } = this.state;
        const { asset_custom_field: bands = [] } = this.props.assetReducer.assetDynamicFields;
        let isValidate = true;
        let errorMessage = "";
        let fieldKey = "";

        for (const [band, fields] of Object.entries(bands)) {
            for (const field of fields) {
                const fieldValue = dynamic_fields?.[field?.field_name];
                const isRequired = field?.rules?.mandatory && !fieldValue?.toString()?.trim()?.length;
                const regex = new RegExp(field?.rules?.regex);
                const invalid = fieldValue?.toString()?.trim()?.length && regex && !regex?.test(fieldValue);
                if (isRequired) {
                    isValidate = false;
                    errorMessage = `* ${field.label} is required`;
                    return { isValidate, errorMessage };
                } else if (invalid) {
                    isValidate = false;
                    errorMessage = `* Please enter a valid ${field.label}`;
                    fieldKey = field?.field_name;
                    return { isValidate, errorMessage, fieldKey };
                }
            }
        }

        return { isValidate, errorMessage, fieldKey };
    };

    addData = async () => {
        const { data } = this.state;
        const {
            discharge,
            unit_property_labeled,
            factory_mounted_disconnect,
            unit_voltage,
            ahu_voltage,
            wire_type,
            wire_size,
            estimated_wire_run_length,
            breaker_size,
            mocp,
            mca,
            liquid_line,
            suction_line,
            hot_gas_by_pass,
            number_of_circuits,
            length_of_line,
            damper_type,
            gas_heating,
            gas_output_btu,
            electric_heating,
            electric_heating_kw,
            pump_backup_heat_kw,
            cooling_only,
            controls,
            control_type,
            smoke_detector,
            smoke_detector_mounting,
            smoke_detector_supply,
            smoke_detector_return,
            curb,
            curb_description,
            vibration_isolation,
            burglar_bars,
            installed_on_adapter_curb,
            curb_outside_dimension_length,
            curb_outside_dimension_width,
            allow_curb_adaptor,
            ducted_or_concentric_system,
            unit_weight,
            codes_for_disconnects,
            codes_for_convenience_outlets,
            codes_for_roof_lighting,
            duct_work_attached_to_bottom_of_unit,
            aluminium_wire_used,
            code_or_safety_concerns,
            distance_from_salt_water_body,
            recommend_coil_coating,
            need_resealed,
            split_system_line_sets_good_condition,
            split_system_line_sets_damaged,
            split_system_line_sets_crimped,
            split_system_line_sets_insulated,
            split_system_line_sets_re_used,
            line_connection_type,
            boiler_type,
            boiler_burner_type,
            boiler_btu_input,
            boiler_btu_output,
            boiler_hp,
            boiler_fuel,
            boiler_gas_pressure,
            boiler_kw_input,
            boiler_power_burner_type,
            boiler_burner_model,
            boiler_burner_serial,
            boiler_pressure_type,
            confirm_boiler_pressure,
            auxiliary_equipment_name,
            auxiliary_model,
            auxiliary_serial,
            auxiliary_date_manufactured,
            auxiliary_manufacturer,
            auxiliary_condition,
            auxiliary_eul,
            auxiliary_crv,
            auxiliary_id,
            ...rest
        } = data;

        rest.dynamic_fields = toArrayOfObjects(rest.dynamic_fields || {});

        const submitData = {
            ...rest,
            asset_additional: {
                discharge,
                unit_property_labeled,
                factory_mounted_disconnect,
                unit_voltage,
                ahu_voltage,
                wire_type,
                wire_size,
                estimated_wire_run_length,
                breaker_size,
                mocp,
                mca,
                liquid_line,
                suction_line,
                hot_gas_by_pass,
                number_of_circuits,
                length_of_line,
                damper_type,
                gas_heating,
                gas_output_btu,
                electric_heating,
                electric_heating_kw,
                pump_backup_heat_kw,
                cooling_only,
                controls,
                control_type,
                smoke_detector,
                smoke_detector_mounting,
                smoke_detector_supply,
                smoke_detector_return,
                curb,
                curb_description,
                vibration_isolation,
                burglar_bars,
                installed_on_adapter_curb,
                curb_outside_dimension_length,
                curb_outside_dimension_width,
                allow_curb_adaptor,
                ducted_or_concentric_system,
                unit_weight,
                codes_for_disconnects,
                codes_for_convenience_outlets,
                codes_for_roof_lighting,
                duct_work_attached_to_bottom_of_unit,
                aluminium_wire_used,
                code_or_safety_concerns,
                distance_from_salt_water_body,
                recommend_coil_coating,
                need_resealed,
                split_system_line_sets_good_condition,
                split_system_line_sets_damaged,
                split_system_line_sets_crimped,
                split_system_line_sets_insulated,
                split_system_line_sets_re_used,
                line_connection_type,
                boiler_type,
                boiler_burner_type,
                boiler_btu_input,
                boiler_btu_output,
                boiler_hp,
                boiler_fuel,
                boiler_gas_pressure,
                boiler_kw_input,
                boiler_power_burner_type,
                boiler_burner_model,
                boiler_burner_serial,
                boiler_pressure_type,
                confirm_boiler_pressure,
                return: this.state.data.return
            },
            asset_auxiliary: {
                auxiliary: [
                    {
                        auxiliary_equipment_name,
                        auxiliary_model,
                        auxiliary_serial,
                        auxiliary_date_manufactured,
                        auxiliary_manufacturer,
                        auxiliary_condition,
                        auxiliary_eul,
                        auxiliary_crv
                    }
                ]
            }
        };
        const { handleAddData } = this.props;
        if (this.validate()) {
            this.setState({
                isUploading: true
            });
            await handleAddData(submitData);
            this.props.setFormDirty(false);
            this.setState({
                isUploading: false
            });
        }
    };

    updateData = async () => {
        const { data } = this.state;
        const { handleUpdateData } = this.props;
        if (this.validate()) {
            this.setState({
                isUploading: true
            });

            const additionalAssetFieldsArray = [
                "discharge",
                "return",
                "unit_property_labeled",
                "factory_mounted_disconnect",
                "unit_voltage",
                "ahu_voltage",
                "wire_type",
                "wire_size",
                "estimated_wire_run_length",
                "breaker_size",
                "mocp",
                "mca",
                "liquid_line",
                "suction_line",
                "hot_gas_by_pass",
                "number_of_circuits",
                "length_of_line",
                "damper_type",
                "gas_heating",
                "gas_output_btu",
                "electric_heating",
                "electric_heating_kw",
                "pump_backup_heat_kw",
                "cooling_only",
                "controls",
                "control_type",
                "smoke_detector",
                "smoke_detector_mounting",
                "smoke_detector_supply",
                "smoke_detector_return",
                "curb",
                "curb_description",
                "vibration_isolation",
                "burglar_bars",
                "installed_on_adapter_curb",
                "curb_outside_dimension_length",
                "curb_outside_dimension_width",
                "allow_curb_adaptor",
                "ducted_or_concentric_system",
                "unit_weight",
                "codes_for_disconnects",
                "codes_for_convenience_outlets",
                "codes_for_roof_lighting",
                "duct_work_attached_to_bottom_of_unit",
                "aluminium_wire_used",
                "code_or_safety_concerns",
                "distance_from_salt_water_body",
                "recommend_coil_coating",
                "need_resealed",
                "split_system_line_sets_good_condition",
                "split_system_line_sets_damaged",
                "split_system_line_sets_crimped",
                "split_system_line_sets_insulated",
                "split_system_line_sets_re_used",
                "line_connection_type",
                "boiler_type",
                "boiler_burner_type",
                "boiler_btu_input",
                "boiler_btu_output",
                "boiler_hp",
                "boiler_fuel",
                "boiler_gas_pressure",
                "boiler_kw_input",
                "boiler_power_burner_type",
                "boiler_burner_model",
                "boiler_burner_serial",
                "boiler_pressure_type",
                "confirm_boiler_pressure"
            ];

            const {
                auxiliary_equipment_name,
                auxiliary_model,
                auxiliary_serial,
                auxiliary_date_manufactured,
                auxiliary_manufacturer,
                auxiliary_condition,
                auxiliary_eul,
                auxiliary_crv,
                auxiliary_id
            } = data;

            let auxiliaryFields = {
                auxiliary_equipment_name,
                auxiliary_model,
                auxiliary_serial,
                auxiliary_date_manufactured,
                auxiliary_manufacturer,
                auxiliary_condition,
                auxiliary_eul,
                auxiliary_crv
            };

            let newData = {};

            Object.entries(data).forEach(([key, value]) => {
                if (!_.isEqual(value, this.state.initialValues[key])) {
                    if (additionalAssetFieldsArray.includes(key)) {
                        newData = { ...newData, asset_additional: { ...newData.asset_additional, [key]: value } };
                    } else newData = { ...newData, [key]: value };
                }
            });

            newData.dynamic_fields = toArrayOfObjects(data.dynamic_fields || {});

            let asset_auxiliary = {};

            Object.entries(auxiliaryFields).forEach(([key, value]) => {
                if (!_.isEqual(value, this.state.initialValues[key])) {
                    asset_auxiliary = { ...asset_auxiliary, [key]: value };
                }
            });

            if (Object.keys(asset_auxiliary).length > 0)
                newData.asset_auxiliary = {
                    auxiliary: [
                        {
                            id: auxiliary_id ? auxiliary_id : undefined,
                            ...asset_auxiliary
                        }
                    ]
                };

            await handleUpdateData(newData);
            this.props.setFormDirty(false);
            this.setState({
                isUploading: false
            });
        }
    };

    renderConfirmationModal = () => {
        const { showConfirmModal } = this.state;
        if (!showConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={"Do you want to clear and lose all changes?"}
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => this.setState({ showConfirmModal: false })}
                        onYes={this.clearForm}
                    />
                }
                onCancel={() => this.setState({ showConfirmModal: false })}
            />
        );
    };

    cancelForm = () => {
        if (isEqual(this.state.initialValues, this.state.data)) {
            this.clearForm();
        } else {
            this.setState({
                showConfirmModal: true
            });
        }
    };

    clearForm = async () => {
        const { cancelForm } = this.props;
        await this.setState({
            data: initialData
        });
        cancelForm();
    };

    handleChange = async e => {
        const { name, value } = e.target;
        const {
            assetReducer: { dropDownList }
        } = this.props;
        if (name === "manufacturer") {
            await this.setState({
                data: {
                    ...this.state.data,
                    manufacturer_id: e.target?.value
                }
            });
        } else {
            await this.setState({
                data: {
                    ...this.state.data,
                    [name]: value
                }
            });
        }
        if (name === "uniformat_level_1_id") {
            this.handleLevel1Select();
        } else if (name === "uniformat_level_2_id") {
            this.handleLevel2Select();
        } else if (name === "uniformat_level_3_id") {
            this.handleLevel3Select();
        } else if (name === "uniformat_level_4_id") {
            this.handleLevel4Select();
        } else if (name === "uniformat_level_5_id") {
            this.handleLevel5Select();
        } else if (name === "uniformat_level_6_id") {
            this.handleLevel6Select();
            let res = value && value.length && dropDownList?.uniformat_level_6s.filter(item => item.id === value);
            this.setState({
                data: {
                    ...this.state.data,
                    uniformat_level_6_description: res.length && res[0]?.uniformat_level_6_description
                }
            });
        } else if (name === "client_id") {
            this.handleClientSelect();
        } else if (name === "region_id") {
            this.handleRegionSelect();
        } else if (name === "site_id") {
            this.handleSiteSelect();
        } else if (name === "building_id") {
            this.handleBuildingSelect();
        } else if (name === "main_category_id") {
            this.handleMainCategorySelect();
        } else if (name === "sub_category_1_id") {
            this.handleSubCategory1Select();
        } else if (name === "sub_category_2_id") {
            this.handleSubCategory2Select();
            let res = value && value.length && dropDownList?.sub_category_2s.filter(item => item?.id === value);
            this.setState({
                data: {
                    ...this.state.data,
                    subcategory2_description: res.length && res[0]?.subcategory2_description
                }
            });
        } else if (name === "trade_id") {
            this.handleTradeSelect();
        } else if (name === "system_id") {
            this.handleSystemSelect();
        } else if (name === "client_asset_condition_id") {
            let res = value && value.length && dropDownList?.asset_conditions.filter(item => item.id === value);
            this.setState({
                data: {
                    ...this.state.data,
                    description: res.length && res[0]?.description
                }
            });
        }
    };

    handleLevel1Select = async () => {
        this.setState({
            data: {
                ...this.state.data,
                uniformat_level_2_id: "",
                uniformat_level_3_id: "",
                uniformat_level_4_id: "",
                uniformat_level_5_id: ""
            }
        });
        this.props.getDropdownList("uniformat_level_2s", {
            uniformat_level_1_id: this.state.data.uniformat_level_1_id
        });
    };

    handleLevel2Select = async () => {
        this.setState({
            data: {
                ...this.state.data,
                uniformat_level_3_id: "",
                uniformat_level_4_id: "",
                uniformat_level_5_id: ""
            }
        });
        this.props.getDropdownList("uniformat_level_3s", {
            uniformat_level_1_id: this.state.data.uniformat_level_1_id,
            uniformat_level_2_id: this.state.data.uniformat_level_2_id
        });
    };
    handleLevel3Select = async () => {
        this.setState({
            data: {
                ...this.state.data,
                uniformat_level_4_id: "",
                uniformat_level_5_id: ""
            }
        });
        this.props.getDropdownList("uniformat_level_4s", {
            uniformat_level_1_id: this.state.data.uniformat_level_1_id,
            uniformat_level_2_id: this.state.data.uniformat_level_2_id,
            uniformat_level_3_id: this.state.data.uniformat_level_3_id
        });
    };
    handleLevel4Select = async () => {
        this.setState({
            data: {
                ...this.state.data,
                uniformat_level_5_id: ""
            }
        });
        this.props.getDropdownList("uniformat_level_5s", {
            uniformat_level_1_id: this.state.data.uniformat_level_1_id,
            uniformat_level_2_id: this.state.data.uniformat_level_2_id,
            uniformat_level_3_id: this.state.data.uniformat_level_3_id,
            uniformat_level_4_id: this.state.data.uniformat_level_4_id
        });
    };
    handleLevel5Select = async () => {
        this.setState({
            data: {
                ...this.state.data,
                uniformat_level_6_id: ""
            }
        });
        this.props.getDropdownList("uniformat_level_6s", {
            uniformat_level_1_id: this.state.data.uniformat_level_1_id,
            uniformat_level_2_id: this.state.data.uniformat_level_2_id,
            uniformat_level_3_id: this.state.data.uniformat_level_3_id,
            uniformat_level_4_id: this.state.data.uniformat_level_4_id,
            uniformat_level_5_id: this.state.data.uniformat_level_5_id
        });
    };
    handleLevel6Select = async () => {
        this.props.getDropdownList("uniformat_level_6s", {
            uniformat_level_1_id: this.state.data.uniformat_level_1_id,
            uniformat_level_2_id: this.state.data.uniformat_level_2_id,
            uniformat_level_3_id: this.state.data.uniformat_level_3_id,
            uniformat_level_4_id: this.state.data.uniformat_level_4_id,
            uniformat_level_5_id: this.state.data.uniformat_level_5_id,
            uniformat_level_6_id: this.state.data.uniformat_level_6_id
        });
    };

    handleClientSelect = async () => {
        this.setState({
            data: {
                ...this.state.data,
                asset_type_id: "",
                asset_status_id: "",
                client_asset_condition_id: "",
                region_id: "",
                site_id: "",
                building_id: "",
                addition_id: "",
                floor_id: ""
            }
        });
        this.props.getDropdownList("regions", {
            client_id: this.state.data.client_id
        });
        this.props.getDropdownList("asset_statuses", {
            client_id: this.state.data.client_id
        });
        this.props.getDropdownList("asset_types", {
            client_id: this.state.data.client_id
        });
        this.props.getDropdownList("asset_conditions", {
            client_id: this.state.data.client_id
        });
        this.props.getDropdownList("main_categories", {
            client_id: this.state.data.client_id
        });
    };

    handleRegionSelect = async () => {
        this.setState({
            data: {
                ...this.state.data,
                site_id: "",
                building_id: "",
                addition_id: "",
                floor_id: ""
            }
        });
        this.props.getDropdownList("sites", {
            region_id: this.state.data.region_id
        });
    };
    handleTradeSelect = async () => {
        this.setState({
            data: {
                ...this.state.data,
                system_id: "",
                sub_system_id: ""
            }
        });
        this.props.getDropdownList("systems", {
            trade_id: this.state.data.trade_id,
            client_id: this.state.data.client_id
        });
    };
    handleSystemSelect = async () => {
        this.setState({
            data: {
                ...this.state.data,
                sub_system_id: ""
            }
        });
        this.props.getDropdownList("sub_systems", {
            client_id: this.state.data.client_id,
            system_id: this.state.data.system_id
        });
    };

    handleSiteSelect = async () => {
        this.setState({
            data: {
                ...this.state.data,
                building_id: "",
                addition_id: "",
                floor_id: ""
            }
        });
        this.props.getDropdownList("buildings", {
            site_id: this.state.data.site_id
        });
    };

    handleBuildingSelect = async () => {
        this.setState({
            data: {
                ...this.state.data,
                addition_id: "",
                floor_id: "",
                building_type: this.props.assetReducer.dropDownList?.buildings?.find(item => item.id === this.state.data.building_id)?.building_type
            }
        });
        this.props.getDropdownList("floors", {
            building_id: this.state.data.building_id
        });
        this.props.getDropdownList("additions", {
            building_id: this.state.data.building_id
        });
    };

    handleMainCategorySelect = async () => {
        this.setState({
            data: {
                ...this.state.data,
                sub_category_1_id: "",
                sub_category_2_id: "",
                sub_category_3_id: ""
            }
        });
        this.props.getDropdownList("sub_category_1s", {
            client_id: this.state.data.client_id,
            main_category_id: this.state.data.main_category_id
        });
    };

    handleSubCategory1Select = async () => {
        this.setState({
            data: {
                ...this.state.data,
                sub_category_2_id: "",
                sub_category_3_id: ""
            }
        });
        this.props.getDropdownList("sub_category_2s", {
            client_id: this.state.data.client_id,
            main_category_id: this.state.data.main_category_id,
            sub_category_1_id: this.state.data.sub_category_1_id
        });
    };

    handleSubCategory2Select = async () => {
        this.setState({
            data: {
                ...this.state.data,
                sub_category_3_id: ""
            }
        });
        this.props.getDropdownList("sub_category_3s", {
            client_id: this.state.data.client_id,
            main_category_id: this.state.data.main_category_id,
            sub_category_1_id: this.state.data.sub_category_1_id,
            sub_category_2_id: this.state.data.sub_category_2_id
        });
    };
    handleBandClick = (key, value) => {
        this.setState({
            showBand: {
                ...this.state.showBand,
                [key]: value
            }
        });
    };
    fetchOptions = async inputValue => {
        this.setState({ isLoadingDrop: true, inputValue });
        const response = await this.props.getDropdownList("manufacturers", {
            search: inputValue,
            current_manufacturer: this.state.data?.manufacturer_id && this.state.data?.manufacturer_id
        });
        this.setState({ isLoadingDrop: false });
    };

    handleInputChange = (newValue, { action }) => {
        if (action === "input-change" || action === "input-click" || action === "input-blur") {
            this.fetchOptions(newValue);
            return newValue;
        }
    };
    render() {
        const { isLoading } = this.state;
        const { data, showErrorBorder, errorParams, showBand } = this.state;
        const {
            selectedData,
            assetReducer: { dropDownList }
        } = this.props;
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        const errorMessage =
            errorParams && Object.keys(errorParams)?.length ? errorParams?.[Object.keys(errorParams)[0]] : "* Please fill all the required fields!";
        const { miscellaneous } = this.props.getClientByIdResponse;
        const {
            addition_required,
            floor_required,
            room_required,
            asset_tag_required,
            serial_number_required,
            model_number_required,
            manufacturer_required,
            capacity_required,
            capacity_unit_required,
            refrigerant_required
        } = miscellaneous || {};
        return (
            <LoadingOverlay active={isLoading} spinner={<Loader />} fadeSpeed={10}>
                <div className="dtl-sec col-md-12">
                    <div className="tab-dtl region-mng">
                        <ul>
                            <div className="recom-notify-img">
                                <img src={exclmIcon} alt="" />
                            </div>
                            <li className="active pl-4">{selectedData ? "Edit Asset" : "Add Asset"}</li>
                        </ul>
                        <form autoComplete={"nope"}>
                            <div className="tab-dtl region-mng additional-dtl addition-edit">
                                <div className="tab-active location-sec recom-sec main-dtl-add recommendation-form add-recommendation">
                                    <div className="col-md-12 detail-recom add-details-outer">
                                        <div className="outer-rcm recommendations">
                                            <div className="cnt-sec">
                                                <div className="row">
                                                    <div className="col-md-12 pr-0 asset-edit-port">
                                                        <div id="accordion">
                                                            <AssetBand
                                                                label="Basic Asset Information"
                                                                bandId="band1"
                                                                handleBandClick={this.handleBandClick}
                                                                isDefaultOpen
                                                                showBand={showBand}
                                                                MainFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Asset Name"
                                                                            fieldKey="Asset Name"
                                                                            fieldParam="asset_name"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.asset_name}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: { mandatory: true }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Asset Description "
                                                                            fieldKey="asset_description"
                                                                            fieldParam="asset_description"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.asset_description}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Asset Type"
                                                                            fieldKey="asset_type_id"
                                                                            fieldParam="asset_type_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.asset_type_id}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "asset_type_id" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: dropDownList?.asset_types
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                                RestFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Asset Status"
                                                                            fieldKey="asset_status_id"
                                                                            fieldParam="asset_status_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.asset_status_id}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({ target: { value: e, name: "asset_status_id" } })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: dropDownList?.asset_statuses
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Asset Condition"
                                                                            fieldKey="client_asset_condition_id"
                                                                            fieldParam="client_asset_condition_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.client_asset_condition_id}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e => {
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "client_asset_condition_id" }
                                                                                });
                                                                            }}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: dropDownList?.asset_conditions
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Asset Condition Description"
                                                                            fieldKey="asset_condition_description"
                                                                            fieldParam="asset_condition_description"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data?.description ? data?.description || "" : ""}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    read_only: true
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Asset Ownership"
                                                                            fieldKey="asset_ownership"
                                                                            fieldParam="asset_ownership"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.asset_ownership}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e => {
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "asset_ownership" }
                                                                                });
                                                                            }}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Owned", name: "Owned" },
                                                                                    { id: "Leased", name: "Leased" },
                                                                                    { id: "Other", name: "Other" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Criticality"
                                                                            fieldKey="criticality"
                                                                            fieldParam="criticality"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.criticality}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Asset Tag"
                                                                            fieldKey="asset_tag"
                                                                            fieldParam="asset_tag"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.asset_tag}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: { mandatory: asset_tag_required }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="GUID"
                                                                            fieldKey="guid"
                                                                            fieldParam="guid"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.guid}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Model Number"
                                                                            fieldKey="model_number"
                                                                            fieldParam="model_number"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.model_number}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: { mandatory: model_number_required }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Core Model Number"
                                                                            fieldKey="core_model_number"
                                                                            fieldParam="core_model_number"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.core_model_number}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Serial Number"
                                                                            fieldKey="serial_number"
                                                                            fieldParam="serial_number"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.serial_number}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: { mandatory: serial_number_required }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Capacity"
                                                                            fieldKey="capacity"
                                                                            fieldParam="capacity"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.capacity}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: { mandatory: capacity_required }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Capacity Unit"
                                                                            fieldKey="capacity_unit"
                                                                            fieldParam="capacity_unit"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.capacity_unit}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: { mandatory: capacity_unit_required }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Capacity Status"
                                                                            fieldKey="capacity_status"
                                                                            fieldParam="capacity_status"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.capacity_status}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Unit Cost"
                                                                            placeholder="Unit Cost"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={parseFloat(data.unit_cost) || ""}
                                                                            bandId="band3"
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    decimal_scale: 6,
                                                                                    prefix: "$"
                                                                                }
                                                                            }}
                                                                            decimalScale={1}
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            thousandSeparator={true}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        unit_cost: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Asset Barcode"
                                                                            fieldKey="asset_barcode"
                                                                            fieldParam="asset_barcode"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.asset_barcode}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Asset Client ID"
                                                                            fieldKey="asset_client_id"
                                                                            fieldParam="asset_client_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.asset_client_id}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Asset CMMS ID"
                                                                            fieldKey="asset_cmms_id"
                                                                            fieldParam="asset_cmms_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.asset_cmms_id}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="RTLS Tag"
                                                                            fieldKey="rtls_tag"
                                                                            fieldParam="rtls_tag"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.rtls_tag}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                            />
                                                            {/* ----------------------------- */}
                                                            <AssetBand
                                                                label="Geo Hierarchy"
                                                                bandId="band2"
                                                                handleBandClick={this.handleBandClick}
                                                                showBand={showBand}
                                                                MainFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Client"
                                                                            fieldKey="client_id"
                                                                            fieldParam="client_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.client_id}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({ target: { value: e, name: "client_id" } })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: dropDownList?.clients || [],
                                                                                rules: { mandatory: true, read_only: query.isRecomAsset || query.c_i }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Region"
                                                                            fieldKey="region_id"
                                                                            fieldParam="region_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data?.region_id}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({ target: { value: e, name: "region_id" } })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data.client_id ? dropDownList?.regions : [],
                                                                                rules: {
                                                                                    mandatory: true,
                                                                                    read_only: query.isRecomAsset || query.r_id
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Site"
                                                                            fieldKey="site_id"
                                                                            fieldParam="site_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.site_id}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({ target: { value: e, name: "site_id" } })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            disabled={query.isRecomAsset || query.s_id}
                                                                            fieldItem={{
                                                                                field_options: data?.region_id ? dropDownList?.sites : [],
                                                                                rules: { mandatory: true }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                                RestFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Building"
                                                                            fieldKey="building_id"
                                                                            fieldParam="building_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.building_id}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({ target: { value: e, name: "building_id" } })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data.site_id ? dropDownList?.buildings : [],
                                                                                rules: {
                                                                                    mandatory: true,
                                                                                    read_only: query.isRecomAsset || query.b_id
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Building Type"
                                                                            fieldKey="building_type"
                                                                            fieldParam="building_type"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.building_type}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    read_only: true
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Addition"
                                                                            fieldKey="addition_id"
                                                                            fieldParam="addition_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.addition_id}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({ target: { value: e, name: "addition_id" } })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data.building_id ? dropDownList?.additions : [],
                                                                                rules: { mandatory: addition_required, read_only: query.isRecomAsset }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Floor"
                                                                            fieldKey="floor_id"
                                                                            fieldParam="floor_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.floor_id}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({ target: { value: e, name: "floor_id" } })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data.building_id ? dropDownList?.floors : [],
                                                                                rules: { mandatory: floor_required, read_only: query.isRecomAsset }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Room Number"
                                                                            fieldKey="room_number"
                                                                            fieldParam="room_number"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.room_number}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: { mandatory: room_required, read_only: query.isRecomAsset }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Room Name"
                                                                            fieldKey="room_name"
                                                                            fieldParam="room_name"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.room_name}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Location"
                                                                            fieldKey="location"
                                                                            fieldParam="location"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.location}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Architectural Room Number"
                                                                            fieldKey="architectural_room_number"
                                                                            fieldParam="architectural_room_number"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.architectural_room_number}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Additional Room Description"
                                                                            fieldKey="additional_room_description"
                                                                            fieldParam="additional_room_description"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.additional_room_description}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Longitude"
                                                                            fieldKey="longitude"
                                                                            fieldParam="longitude"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={data.longitude}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        longitude: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    decimal_scale: 6
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Latitude"
                                                                            fieldKey="latitude"
                                                                            fieldParam="latitude"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={data.latitude}
                                                                            bandId="band2"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    decimal_scale: 6
                                                                                }
                                                                            }}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        latitude: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                            />
                                                            {/* ----------------------------- */}
                                                            <AssetBand
                                                                label="Extended Asset Information"
                                                                bandId="band3"
                                                                handleBandClick={this.handleBandClick}
                                                                showBand={showBand}
                                                                MainFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Manufacturer"
                                                                            fieldKey="manufacturer"
                                                                            fieldParam="manufacturer"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.manufacturer_id}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={(e, name) => {
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "manufacturer", selectedName: name }
                                                                                });
                                                                            }}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: dropDownList?.manufacturers || [],
                                                                                rules: {
                                                                                    mandatory: true
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                            handleInputChange={this.handleInputChange}
                                                                            isManufacturer={true}
                                                                            isLoadingDrop={this.state?.isLoadingDrop}
                                                                            manufacturerCount={this.props.assetReducer?.manufacturerCount?.count}
                                                                        />

                                                                        <FormField
                                                                            label="Year Manufactured"
                                                                            placeholder="Year Manufactored"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={parseInt(data.year_manufactured) || ""}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        year_manufactured: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Installed Year"
                                                                            placeholder="Installed Year"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={parseInt(data.installed_year) || ""}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        installed_year: value,
                                                                                        usefull_life_remaining: getUsefullLifeRemaining(
                                                                                            value,
                                                                                            data.service_life
                                                                                        ),
                                                                                        current_age: getCurrentAge(value)
                                                                                    }
                                                                                });
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                                RestFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Current Age"
                                                                            fieldKey="current_age"
                                                                            fieldParam="current_age"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.current_age}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    read_only: true
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Service Life"
                                                                            placeholder="Service Life"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={parseInt(data.service_life) || ""}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        service_life: value,
                                                                                        usefull_life_remaining: getUsefullLifeRemaining(
                                                                                            data.installed_year,
                                                                                            value
                                                                                        )
                                                                                    }
                                                                                });
                                                                            }}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="End of Service Life"
                                                                            fieldKey="end_of_service_life"
                                                                            fieldParam="end_of_service_life"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={
                                                                                data.usefull_life_remaining
                                                                                    ? `${new Date().getFullYear() + data.usefull_life_remaining}`
                                                                                    : ""
                                                                            }
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    read_only: true
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Useful Life Remaining"
                                                                            fieldKey="usefull_life_remaining"
                                                                            fieldParam="usefull_life_remaining"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.usefull_life_remaining}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    read_only: true
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="CSP Unit"
                                                                            fieldKey="csp_unit"
                                                                            fieldParam="csp_unit"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.csp_unit}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="CRV"
                                                                            placeholder="CRV"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={parseInt(data.crv) || ""}
                                                                            bandId="band3"
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    prefix: "$ ",
                                                                                    thousand_separator: true
                                                                                }
                                                                            }}
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            thousandSeparator={true}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        crv: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Refrigerant"
                                                                            fieldKey="refrigerant"
                                                                            fieldParam="refrigerant"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.refrigerant}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            mandatory={refrigerant_required}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: miscellaneous?.refrigerant_required
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        {/* ------------------------------ */}
                                                                        <div className="txt-rcm">
                                                                            <div className="content-inp-card">
                                                                                <div className="form-group">
                                                                                    <label>Warranty Start</label>
                                                                                    <DatePicker
                                                                                        autoComplete={"nope"}
                                                                                        placeholderText="MM/DD/YYYY"
                                                                                        className="custom-input form-control"
                                                                                        selected={
                                                                                            data.warranty_start ? new Date(data.warranty_start) : ""
                                                                                        }
                                                                                        onChange={date => {
                                                                                            this.setState({
                                                                                                data: {
                                                                                                    ...data,
                                                                                                    warranty_start: date
                                                                                                        ? moment(date).format("YYYY-MM-DD")
                                                                                                        : null
                                                                                                }
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="txt-rcm">
                                                                            <div className="content-inp-card">
                                                                                <div className="form-group">
                                                                                    <label>Warranty End</label>
                                                                                    <DatePicker
                                                                                        autoComplete={"nope"}
                                                                                        placeholderText="MM/DD/YYYY"
                                                                                        className="custom-input form-control"
                                                                                        selected={
                                                                                            data.warranty_end ? new Date(data.warranty_end) : ""
                                                                                        }
                                                                                        onChange={date => {
                                                                                            this.setState({
                                                                                                data: {
                                                                                                    ...data,
                                                                                                    warranty_end: date
                                                                                                        ? moment(date).format("YYYY-MM-DD")
                                                                                                        : null
                                                                                                }
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="txt-rcm">
                                                                            <div className="content-inp-card">
                                                                                <div className="form-group">
                                                                                    <label>Install Date</label>
                                                                                    <DatePicker
                                                                                        autoComplete={"nope"}
                                                                                        placeholderText="MM/DD/YYYY"
                                                                                        className="custom-input form-control"
                                                                                        selected={
                                                                                            data.install_date ? new Date(data.install_date) : ""
                                                                                        }
                                                                                        onChange={date => {
                                                                                            this.setState({
                                                                                                data: {
                                                                                                    ...data,
                                                                                                    install_date: date
                                                                                                        ? moment(date).format("YYYY-MM-DD")
                                                                                                        : null
                                                                                                }
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="txt-rcm">
                                                                            <div className="content-inp-card">
                                                                                <div className="form-group">
                                                                                    <label>Startup Date</label>
                                                                                    <DatePicker
                                                                                        autoComplete={"nope"}
                                                                                        placeholderText="MM/DD/YYYY"
                                                                                        className="custom-input form-control"
                                                                                        selected={
                                                                                            data.startup_date ? new Date(data.startup_date) : ""
                                                                                        }
                                                                                        onChange={date => {
                                                                                            this.setState({
                                                                                                data: {
                                                                                                    ...data,
                                                                                                    startup_date: date
                                                                                                        ? moment(date).format("YYYY-MM-DD")
                                                                                                        : null
                                                                                                }
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* ------------------------------ */}
                                                                        <FormField
                                                                            label="Uniformat Level 1"
                                                                            fieldKey="uniformat_level_1_id"
                                                                            fieldParam="uniformat_level_1_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.uniformat_level_1_id}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "uniformat_level_1_id" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: dropDownList?.uniformat_level_1s || []
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Uniformat Level 2"
                                                                            fieldKey="uniformat_level_2_id"
                                                                            fieldParam="uniformat_level_2_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.uniformat_level_2_id}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "uniformat_level_2_id" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data.uniformat_level_1_id
                                                                                    ? dropDownList?.uniformat_level_2s
                                                                                    : []
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Uniformat Level 3"
                                                                            fieldKey="uniformat_level_3_id"
                                                                            fieldParam="uniformat_level_3_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.uniformat_level_3_id}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "uniformat_level_3_id" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data.uniformat_level_2_id
                                                                                    ? dropDownList?.uniformat_level_3s
                                                                                    : []
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Uniformat Level 4"
                                                                            fieldKey="uniformat_level_4_id"
                                                                            fieldParam="uniformat_level_4_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.uniformat_level_4_id}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "uniformat_level_4_id" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data.uniformat_level_3_id
                                                                                    ? dropDownList?.uniformat_level_4s
                                                                                    : []
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Uniformat Level 5"
                                                                            fieldKey="uniformat_level_5_id"
                                                                            fieldParam="uniformat_level_5_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.uniformat_level_5_id}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "uniformat_level_5_id" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data.uniformat_level_4_id
                                                                                    ? dropDownList?.uniformat_level_5s
                                                                                    : []
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Uniformat Level 6"
                                                                            fieldKey="uniformat_level_6_id"
                                                                            fieldParam="uniformat_level_6_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.uniformat_level_6_id}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "uniformat_level_6_id" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data.uniformat_level_5_id
                                                                                    ? dropDownList?.uniformat_level_6s
                                                                                    : []
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Uniformat Level 6 Description"
                                                                            fieldKey="uniformat_level_6_description"
                                                                            fieldParam="uniformat_level_6_description"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={
                                                                                data.uniformat_level_6_id && data?.uniformat_level_6_description
                                                                                    ? data.uniformat_level_6_description || ""
                                                                                    : ""
                                                                            }
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    read_only: true
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Uniformat Level 3 Custom Description"
                                                                            fieldKey="uniformat_level_3_custom_description"
                                                                            fieldParam="uniformat_level_3_custom_description"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.uniformat_level_3_custom_description || ""}
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Main Category"
                                                                            fieldKey="main_category_id"
                                                                            fieldParam="main_category_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.main_category_id}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "main_category_id" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data?.client_id ? dropDownList?.main_categories : []
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Sub Category 1 "
                                                                            fieldKey="sub_category_1_id"
                                                                            fieldParam="sub_category_1_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.sub_category_1_id}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "sub_category_1_id" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data.main_category_id
                                                                                    ? dropDownList?.sub_category_1s
                                                                                    : []
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Sub Category 2 "
                                                                            fieldKey="sub_category_2_id"
                                                                            fieldParam="sub_category_2_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.sub_category_2_id}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "sub_category_2_id" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data.sub_category_1_id
                                                                                    ? dropDownList?.sub_category_2s
                                                                                    : []
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Sub Category 2 Description"
                                                                            fieldKey="subcategory2_description"
                                                                            fieldParam="subcategory2_description"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={
                                                                                data?.subcategory2_description
                                                                                    ? data?.subcategory2_description || ""
                                                                                    : ""
                                                                            }
                                                                            bandId="band3"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    read_only: true
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Sub Category 3 "
                                                                            fieldKey="sub_category_3_id"
                                                                            fieldParam="sub_category_3_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.sub_category_3_id}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "sub_category_3_id" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: data.sub_category_2_id
                                                                                    ? dropDownList?.sub_category_3s
                                                                                    : []
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Trade"
                                                                            fieldKey="trade_id"
                                                                            fieldParam="trade_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.trade_id}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "trade_id" }
                                                                                })
                                                                            }
                                                                            fieldItem={{
                                                                                field_options: data.client_id ? dropDownList?.trades : [],
                                                                                rules: { read_only: query.isRecomAsset || query.r_id }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="System"
                                                                            fieldKey="system_id"
                                                                            fieldParam="system_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.system_id}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "system_id" }
                                                                                })
                                                                            }
                                                                            fieldItem={{
                                                                                field_options: data.trade_id ? dropDownList?.systems : [],
                                                                                rules: { read_only: query.isRecomAsset || query.r_id }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Sub-System"
                                                                            fieldKey="sub_system_id"
                                                                            fieldParam="sub_system_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.sub_system_id}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "sub_system_id" }
                                                                                })
                                                                            }
                                                                            fieldItem={{
                                                                                field_options: data.system_id ? dropDownList?.sub_systems : [],
                                                                                rules: { read_only: query.isRecomAsset || query.r_id }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Asset Note"
                                                                            fieldKey="asset_note"
                                                                            fieldParam="asset_note"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.asset_note}
                                                                            bandId="band5"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Energy Use Type"
                                                                            fieldKey="energy_use_type"
                                                                            fieldParam="energy_use_type"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.energy_use_type}
                                                                            bandId="band4"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                            />
                                                            {/* ----------------------------- */}
                                                            <AssetBand
                                                                label="Asset QA/QC Status"
                                                                bandId="band4"
                                                                handleBandClick={this.handleBandClick}
                                                                showBand={showBand}
                                                                MainFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Asset Survey Id"
                                                                            fieldKey="survey_id"
                                                                            fieldParam="survey_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.survey_id}
                                                                            bandId="band4"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Asset Survey Property Notes"
                                                                            fieldKey="survey_property_note"
                                                                            fieldParam="survey_property_note"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.survey_property_note}
                                                                            bandId="band4"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Capacity Status"
                                                                            fieldKey="capacity_status"
                                                                            fieldParam="capacity_status"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.capacity_status}
                                                                            bandId="band4"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                                RestFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Install Year Status"
                                                                            fieldKey="installed_year_status"
                                                                            fieldParam="installed_year_status"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.installed_year_status}
                                                                            bandId="band4"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Name Plate Status"
                                                                            fieldKey="name_plate_status"
                                                                            fieldParam="name_plate_status"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.name_plate_status}
                                                                            bandId="band4"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Asset Survey QA/QC Notes"
                                                                            fieldKey="qa_notes"
                                                                            fieldParam="qa_notes"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.qa_notes}
                                                                            bandId="band4"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Asset Survey Additional QA/QC Notes"
                                                                            fieldKey="additional_qa_notes"
                                                                            fieldParam="additional_qa_notes"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.additional_qa_notes}
                                                                            bandId="band4"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <div className="txt-rcm">
                                                                            <div className="content-inp-card">
                                                                                <div className="form-group">
                                                                                    <label>Asset Survey Date Created</label>
                                                                                    <DatePicker
                                                                                        autoComplete={"nope"}
                                                                                        placeholderText="MM/DD/YYYY"
                                                                                        className="custom-input form-control"
                                                                                        selected={
                                                                                            data.survey_date_created
                                                                                                ? new Date(data.survey_date_created)
                                                                                                : ""
                                                                                        }
                                                                                        onChange={date => {
                                                                                            this.setState({
                                                                                                data: {
                                                                                                    ...data,
                                                                                                    survey_date_created: date
                                                                                                        ? moment(date).format("YYYY-MM-DD")
                                                                                                        : null
                                                                                                }
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <FormField
                                                                            label="Asset Survey Surveyor"
                                                                            fieldKey="surveyor"
                                                                            fieldParam="surveyor"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.surveyor}
                                                                            bandId="band4"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <div className="txt-rcm">
                                                                            <div className="content-inp-card">
                                                                                <div className="form-group">
                                                                                    <label>Asset Survey Date Edited</label>
                                                                                    <DatePicker
                                                                                        autoComplete={"nope"}
                                                                                        placeholderText="MM/DD/YYYY"
                                                                                        className="custom-input form-control"
                                                                                        selected={
                                                                                            data.survey_date_edited
                                                                                                ? new Date(data.survey_date_edited)
                                                                                                : ""
                                                                                        }
                                                                                        onChange={date => {
                                                                                            this.setState({
                                                                                                data: {
                                                                                                    ...data,
                                                                                                    survey_date_edited: date
                                                                                                        ? moment(date).format("YYYY-MM-DD")
                                                                                                        : null
                                                                                                }
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <FormField
                                                                            label="Asset Survey Editor"
                                                                            fieldKey="editor"
                                                                            fieldParam="editor"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.editor}
                                                                            bandId="band4"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Is This a New Asset"
                                                                            fieldKey="new_asset"
                                                                            fieldParam="new_asset"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.new_asset}
                                                                            bandId="band1"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "new_asset" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                            />
                                                            {/* ------------------------------------------------ */}
                                                            <AssetBand
                                                                label="Asset Parent/Child Hierarchy"
                                                                bandId="band5"
                                                                handleBandClick={this.handleBandClick}
                                                                showBand={showBand}
                                                                MainFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Area Served"
                                                                            fieldKey="area_served"
                                                                            fieldParam="area_served"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.area_served}
                                                                            bandId="band5"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Upstream Asset Barcode Numbers"
                                                                            fieldKey="upstream_asset_barcode_number"
                                                                            fieldParam="upstream_asset_barcode_number"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.upstream_asset_barcode_number}
                                                                            bandId="band5"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Linked Asset Barcode Numbersd"
                                                                            fieldKey="linked_asset_barcode_number"
                                                                            fieldParam="linked_asset_barcode_number"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.linked_asset_barcode_number}
                                                                            bandId="band5"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                                RestFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Source Panel Name"
                                                                            fieldKey="source_panel_name"
                                                                            fieldParam="source_panel_name"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.source_panel_name}
                                                                            bandId="band5"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Source Panel Barcode Number"
                                                                            fieldKey="source_panel_barcode_number"
                                                                            fieldParam="source_panel_barcode_number"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.source_panel_barcode_number}
                                                                            bandId="band5"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Source Panel Emergency/ Normal"
                                                                            fieldKey="source_panel"
                                                                            fieldParam="source_panel"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.source_panel}
                                                                            bandId="band5"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Skysite Hyperlink"
                                                                            fieldKey="skysite_hyperlink"
                                                                            fieldParam="skysite_hyperlink"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.skysite_hyperlink}
                                                                            bandId="band5"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Quantity"
                                                                            placeholder="Quantity"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={parseInt(data.quantity) || ""}
                                                                            thousandSeparator
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    thousandSeparator: true
                                                                                }
                                                                            }}
                                                                            bandId="band5"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        quantity: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Parent Global ID"
                                                                            fieldKey="parent_global_id"
                                                                            fieldParam="parent_global_id"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.parent_global_id}
                                                                            bandId="band5"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                            />
                                                            <AssetBand
                                                                label="Asset Additional"
                                                                bandId="band6"
                                                                handleBandClick={this.handleBandClick}
                                                                showBand={showBand}
                                                                MainFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Discharge"
                                                                            fieldKey="discharge"
                                                                            fieldParam="discharge"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.discharge}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "discharge" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Down", name: "Down" },
                                                                                    { id: "Side", name: "Side" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Return"
                                                                            fieldKey="return"
                                                                            fieldParam="return"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.return}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "return" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Down", name: "Down" },
                                                                                    { id: "Side", name: "Side" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Is Unit Properly Labeled"
                                                                            fieldKey="unit_property_labeled"
                                                                            fieldParam="unit_property_labeled"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.unit_property_labeled}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "unit_property_labeled" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                                RestFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Factory Mounted Disconnect"
                                                                            fieldKey="factory_mounted_disconnect"
                                                                            fieldParam="factory_mounted_disconnect"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.factory_mounted_disconnect}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "factory_mounted_disconnect" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Unit Voltage"
                                                                            fieldKey="unit_voltage"
                                                                            fieldParam="unit_voltage"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.unit_voltage}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "unit_voltage" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "480-3PH", name: "480-3PH" },
                                                                                    { id: "230-3PH", name: "230-3PH" },
                                                                                    { id: "230-1PH", name: "230-1PH" },
                                                                                    { id: "208-1PH", name: "208-1PH" },
                                                                                    { id: "110-1PH", name: "110-1PH" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="AHU Voltage"
                                                                            fieldKey="ahu_voltage"
                                                                            fieldParam="ahu_voltage"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.ahu_voltage}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "ahu_voltage" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "480-3PH", name: "480-3PH" },
                                                                                    { id: "230-3PH", name: "230-3PH" },
                                                                                    { id: "230-1PH", name: "230-1PH" },
                                                                                    { id: "208-1PH", name: "208-1PH" },
                                                                                    { id: "110-1PH", name: "110-1PH" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Wire Type"
                                                                            fieldKey="wire_type"
                                                                            fieldParam="wire_type"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.wire_type}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Wire Size"
                                                                            fieldKey="wire_size"
                                                                            fieldParam="wire_size"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.wire_size || ""}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Estimated Wire Run Length"
                                                                            fieldKey="estimated_wire_run_length"
                                                                            fieldParam="estimated_wire_run_length"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.estimated_wire_run_length || ""}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Breaker Size"
                                                                            fieldKey="breaker_size"
                                                                            fieldParam="breaker_size"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.breaker_size || ""}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="MOCP"
                                                                            fieldKey="mocp"
                                                                            fieldParam="mocp"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={parseInt(data.mocp) || ""}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        mocp: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="MCA"
                                                                            fieldKey="mca"
                                                                            fieldParam="mca"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={data.mca || ""}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    decimal_scale: 6
                                                                                }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        mca: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Liquid Line"
                                                                            fieldKey="liquid_line"
                                                                            fieldParam="liquid_line"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.liquid_line}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Suction Line"
                                                                            fieldKey="suction_line"
                                                                            fieldParam="suction_line"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.suction_line}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Hot Gas By-Pass"
                                                                            fieldKey="hot_gas_by_pass"
                                                                            fieldParam="hot_gas_by_pass"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.hot_gas_by_pass}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "hot_gas_by_pass" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Number of Circuits"
                                                                            fieldKey="number_of_circuits"
                                                                            fieldParam="number_of_circuits"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={parseInt(data.number_of_circuits) || ""}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        number_of_circuits: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Length of Line Run"
                                                                            fieldKey="length_of_line"
                                                                            fieldParam="length_of_line"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.length_of_line || ""}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Damper Type"
                                                                            fieldKey="damper_type"
                                                                            fieldParam="damper_type"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.damper_type}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "damper_type" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Manual", name: "Manual" },
                                                                                    { id: "Modulating", name: "Modulating" },
                                                                                    { id: "Economizer", name: "Economizer" },
                                                                                    { id: "Power Exhaust", name: "Power Exhaust" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Gas Heating"
                                                                            fieldKey="gas_heating"
                                                                            fieldParam="gas_heating"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.gas_heating}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "gas_heating" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Natural Gas", name: "Natural Gas" },
                                                                                    { id: "LP Gas", name: "LP Gas" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Gas Output BTU"
                                                                            fieldKey="gas_output_btu"
                                                                            fieldParam="gas_output_btu"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.gas_output_btu || ""}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Electric Heating"
                                                                            fieldKey="electric_heating"
                                                                            fieldParam="electric_heating"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.electric_heating}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "electric_heating" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Electric Heating KW"
                                                                            fieldKey="electric_heating_kw"
                                                                            fieldParam="electric_heating_kw"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.electric_heating_kw || ""}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Heat Pump - Backup Heat KW"
                                                                            fieldKey="pump_backup_heat_kw"
                                                                            fieldParam="pump_backup_heat_kw"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.pump_backup_heat_kw || ""}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "pump_backup_heat_kw" }
                                                                                })
                                                                            }
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Cooling Only"
                                                                            fieldKey="cooling_only"
                                                                            fieldParam="cooling_only"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.cooling_only}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "cooling_only" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Controls"
                                                                            fieldKey="controls"
                                                                            fieldParam="controls"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.controls}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "controls" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Thermostat", name: "Thermostat" },
                                                                                    { id: "Remote Sensor", name: "Remote Sensor" },
                                                                                    { id: "DDC/EMS Controls", name: "DDC/EMS Controls" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Controls Type"
                                                                            fieldKey="control_type"
                                                                            fieldParam="control_type"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.control_type}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Smoke Detector"
                                                                            fieldKey="smoke_detector"
                                                                            fieldParam="smoke_detector"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.smoke_detector}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "smoke_detector" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Central Alarm Panel", name: "Central Alarm Panel" },
                                                                                    { id: "Self Standing", name: "Self Standing" },
                                                                                    { id: "Annunciator Required", name: "Annunciator Required" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Smoke Detector Mounting"
                                                                            fieldKey="smoke_detector_mounting"
                                                                            fieldParam="smoke_detector_mounting"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.smoke_detector_mounting}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "smoke_detector_mounting" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Unit", name: "Unit" },
                                                                                    { id: "Duct", name: "Duct" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Smoke Detector - Supply"
                                                                            fieldKey="smoke_detector_supply"
                                                                            fieldParam="smoke_detector_supply"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.smoke_detector_supply}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "smoke_detector_supply" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Smoke Detector - Return"
                                                                            fieldKey="smoke_detector_return"
                                                                            fieldParam="smoke_detector_return"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.smoke_detector_return}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "smoke_detector_return" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Curb"
                                                                            fieldKey="curb"
                                                                            fieldParam="curb"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.curb}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "curb" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Standard", name: "Standard" },
                                                                                    { id: "Seismic", name: "Seismic" },
                                                                                    { id: "Pitched", name: "Pitched" },
                                                                                    { id: "Other", name: "Other" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Curb Other Description"
                                                                            fieldKey="curb_description"
                                                                            fieldParam="curb_description"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.curb_description}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Vibration Isolation"
                                                                            fieldKey="vibration_isolation"
                                                                            fieldParam="vibration_isolation"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.vibration_isolation}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "vibration_isolation" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Burglar Bars"
                                                                            fieldKey="burglar_bars"
                                                                            fieldParam="burglar_bars"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.burglar_bars}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "burglar_bars" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Existing Unit Installed on an Adapter Curb?"
                                                                            fieldKey="installed_on_adapter_curb"
                                                                            fieldParam="installed_on_adapter_curb"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.installed_on_adapter_curb}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "installed_on_adapter_curb" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Existing Curb Outside Dimensions - Length"
                                                                            fieldKey="curb_outside_dimension_length"
                                                                            fieldParam="curb_outside_dimension_length"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.curb_outside_dimension_length || ""}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Existing Curb Outside Dimensions - Width"
                                                                            fieldKey="curb_outside_dimension_width"
                                                                            fieldParam="curb_outside_dimension_width"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.curb_outside_dimension_width || ""}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Will Mall, Landlord, Municipality Allow a Curb Adapter?"
                                                                            fieldKey="allow_curb_adaptor"
                                                                            fieldParam="allow_curb_adaptor"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.allow_curb_adaptor}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "allow_curb_adaptor" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Is This a Ducted or Concentric System"
                                                                            fieldKey="ducted_or_concentric_system"
                                                                            fieldParam="ducted_or_concentric_system"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.ducted_or_concentric_system}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "ducted_or_concentric_system" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Ducted", name: "Ducted" },
                                                                                    { id: "Concentric", name: "Concentric" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Unit Weight"
                                                                            fieldKey="unit_weight"
                                                                            fieldParam="unit_weight"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.unit_weight}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Any Special Codes for Disconnects"
                                                                            fieldKey="codes_for_disconnects"
                                                                            fieldParam="codes_for_disconnects"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.codes_for_disconnects}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "codes_for_disconnects" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Any Special Codes for Convenience Outlets"
                                                                            fieldKey="codes_for_convenience_outlets"
                                                                            fieldParam="codes_for_convenience_outlets"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.codes_for_convenience_outlets}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "codes_for_convenience_outlets" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Any Special Codes for Roof Lighting"
                                                                            fieldKey="codes_for_roof_lighting"
                                                                            fieldParam="codes_for_roof_lighting"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.codes_for_roof_lighting}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "codes_for_roof_lighting" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Current Duct Work Attached to Bottom of Unit"
                                                                            fieldKey="duct_work_attached_to_bottom_of_unit"
                                                                            fieldParam="duct_work_attached_to_bottom_of_unit"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.duct_work_attached_to_bottom_of_unit}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "duct_work_attached_to_bottom_of_unit" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Was Aluminum Wire Used"
                                                                            fieldKey="aluminium_wire_used"
                                                                            fieldParam="aluminium_wire_used"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.aluminium_wire_used}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "aluminium_wire_used" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Are There Any Code or Safety Concerns"
                                                                            fieldKey="code_or_safety_concerns"
                                                                            fieldParam="code_or_safety_concerns"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.code_or_safety_concerns}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "code_or_safety_concerns" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Distance (in Miles) from Body of Salt Water"
                                                                            fieldKey="distance_from_salt_water_body"
                                                                            fieldParam="distance_from_salt_water_body"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.distance_from_salt_water_body || ""}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Recommend Coil Coating"
                                                                            fieldKey="recommend_coil_coating"
                                                                            fieldParam="recommend_coil_coating"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.recommend_coil_coating}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "recommend_coil_coating" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Do Electrical/Piping Roof Penetrations Need to be Resealed"
                                                                            fieldKey="need_resealed"
                                                                            fieldParam="need_resealed"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.need_resealed}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "need_resealed" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Split System - Are Line Sets in Good Condition"
                                                                            fieldKey="split_system_line_sets_good_condition"
                                                                            fieldParam="split_system_line_sets_good_condition"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.split_system_line_sets_good_condition}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: {
                                                                                        value: e,
                                                                                        name: "split_system_line_sets_good_condition"
                                                                                    }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Split System - Are Line Sets Damaged in Any Way"
                                                                            fieldKey="split_system_line_sets_damaged"
                                                                            fieldParam="split_system_line_sets_damaged"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data.split_system_line_sets_damaged}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "split_system_line_sets_damaged" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Split System - Are Line Sets Crimped"
                                                                            fieldKey="split_system_line_sets_crimped"
                                                                            fieldParam="split_system_line_sets_crimped"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data?.split_system_line_sets_crimped}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "split_system_line_sets_crimped" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Split System - Are Line Sets Insulated"
                                                                            fieldKey="split_system_line_sets_insulated"
                                                                            fieldParam="split_system_line_sets_insulated"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data?.split_system_line_sets_insulated}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "split_system_line_sets_insulated" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Split System - Can Line Sets Be Re-Used"
                                                                            fieldKey="split_system_line_sets_re_used"
                                                                            fieldParam="split_system_line_sets_re_used"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data?.split_system_line_sets_re_used}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "split_system_line_sets_re_used" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: [
                                                                                    { id: "Yes", name: "Yes" },
                                                                                    { id: "No", name: "No" },
                                                                                    { id: "NA", name: "NA" }
                                                                                ]
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Line Connection Type"
                                                                            fieldKey="line_connection_type"
                                                                            fieldParam="line_connection_type"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data?.line_connection_type}
                                                                            bandId="band6"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                            />
                                                            <AssetBand
                                                                label="Boiler Fields"
                                                                bandId="band7"
                                                                handleBandClick={this.handleBandClick}
                                                                showBand={showBand}
                                                                MainFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Boiler Type"
                                                                            fieldKey="boiler_type"
                                                                            fieldParam="boiler_type"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.boiler_type}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Boiler Burner Type"
                                                                            fieldKey="boiler_burner_type"
                                                                            fieldParam="boiler_burner_type"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.boiler_burner_type}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Boiler BTU Input"
                                                                            fieldKey="boiler_btu_input"
                                                                            fieldParam="boiler_btu_input"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.boiler_btu_input || ""}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                                RestFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Boiler BTU Output"
                                                                            fieldParam="boiler_btu_output"
                                                                            fieldKey="boiler_btu_output"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.boiler_btu_output || ""}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Boiler HP"
                                                                            fieldParam="boiler_hp"
                                                                            fieldKey="boiler_hp"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={data.boiler_hp || ""}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        boiler_hp: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    decimal_scale: 6
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Boiler Fuel"
                                                                            fieldKey="boiler_fuel"
                                                                            fieldParam="boiler_fuel"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.boiler_fuel}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Boiler Gas Pressure"
                                                                            fieldKey="boiler_gas_pressure"
                                                                            fieldParam="boiler_gas_pressure"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.boiler_gas_pressure || ""}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Boiler KW Input"
                                                                            fieldParam="boiler_kw_input"
                                                                            fieldKey="boiler_kw_input"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.boiler_kw_input || ""}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Boiler Power Burner Type"
                                                                            fieldKey="boiler_power_burner_type"
                                                                            fieldParam="boiler_power_burner_type"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data?.boiler_power_burner_type}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Boiler Burner Model"
                                                                            fieldKey="boiler_burner_model"
                                                                            fieldParam="boiler_burner_model"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data?.boiler_burner_model}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Boiler Burner Serial"
                                                                            fieldKey="boiler_burner_serial"
                                                                            fieldParam="boiler_burner_serial"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data?.boiler_burner_serial}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Boiler Operating Pressure Type"
                                                                            fieldKey="boiler_pressure_type"
                                                                            fieldParam="boiler_pressure_type"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                            value={data?.boiler_pressure_type}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={e =>
                                                                                this.handleChange({
                                                                                    target: { value: e, name: "boiler_pressure_type" }
                                                                                })
                                                                            }
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                field_options: ["Low", "Medium", "High", "NA"].map(option => ({
                                                                                    id: option,
                                                                                    name: option
                                                                                }))
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                        <FormField
                                                                            label="Confirm Boiler Pressure"
                                                                            fieldKey="confirm_boiler_pressure"
                                                                            fieldParam="confirm_boiler_pressure"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data?.confirm_boiler_pressure}
                                                                            bandId="band7"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                            />
                                                            <AssetBand
                                                                label="Auxiliary Equipment Fields"
                                                                bandId="band8"
                                                                handleBandClick={this.handleBandClick}
                                                                showBand={showBand}
                                                                MainFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Equipment Name"
                                                                            fieldKey="auxiliary_equipment_name"
                                                                            fieldParam="auxiliary_equipment_name"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.auxiliary_equipment_name}
                                                                            bandId="band8"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Model"
                                                                            fieldKey="auxiliary_model"
                                                                            fieldParam="auxiliary_model"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.auxiliary_model}
                                                                            bandId="band8"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Serial"
                                                                            fieldKey="auxiliary_serial"
                                                                            fieldParam="auxiliary_serial"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.auxiliary_serial}
                                                                            bandId="band8"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                                RestFields={
                                                                    <>
                                                                        <FormField
                                                                            label="Date Manufactured"
                                                                            fieldKey="auxiliary_date_manufactured"
                                                                            fieldParam="auxiliary_date_manufactured"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={data.auxiliary_date_manufactured || ""}
                                                                            bandId="band8"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        auxiliary_date_manufactured: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Manufacturer"
                                                                            fieldKey="auxiliary_manufacturer"
                                                                            fieldParam="auxiliary_manufacturer"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.auxiliary_manufacturer}
                                                                            bandId="band8"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="Condition"
                                                                            fieldKey="auxiliary_condition"
                                                                            fieldParam="auxiliary_condition"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                            value={data.auxiliary_condition}
                                                                            bandId="band8"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={this.handleChange}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="EUL"
                                                                            fieldKey="auxiliary_eul"
                                                                            fieldParam="auxiliary_eul"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={data.auxiliary_eul || ""}
                                                                            bandId="band8"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        auxiliary_eul: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />

                                                                        <FormField
                                                                            label="CRV"
                                                                            fieldKey="auxiliary_crv"
                                                                            fieldParam="auxiliary_crv"
                                                                            fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                            value={data.auxiliary_crv || ""}
                                                                            bandId="band8"
                                                                            bandShown={showBand}
                                                                            handleBandClick={this.handleBandClick}
                                                                            onChange={values => {
                                                                                const { value } = values;
                                                                                this.setState({
                                                                                    data: {
                                                                                        ...data,
                                                                                        auxiliary_crv: value
                                                                                    }
                                                                                });
                                                                            }}
                                                                            showErrorBorder={showErrorBorder}
                                                                            errorParams={errorParams}
                                                                            fieldItem={{
                                                                                rules: {
                                                                                    mandatory: false,
                                                                                    prefix: "$ ",
                                                                                    thousand_separator: true
                                                                                }
                                                                            }}
                                                                            isAsset
                                                                        />
                                                                    </>
                                                                }
                                                            />
                                                            <DynamicBand
                                                                bandData={data.dynamic_fields}
                                                                bandShown={showBand}
                                                                handleBandClick={this.handleBandClick}
                                                                handleChangeData={value =>
                                                                    this.handleChange({
                                                                        target: { name: "dynamic_fields", value: value.dynamic_fields }
                                                                    })
                                                                }
                                                                showErrorBorder={showErrorBorder}
                                                                isFieldLocked={this.isFieldLocked}
                                                                isAsset
                                                            />
                                                            {/* -------------------------------------- */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-right btnOtr edit-cmn-btn bg-grey-bottom-layer asset-pd">
                                        <span className="errorMessage">{showErrorBorder ? errorMessage : ""}</span>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btnClr col-md-2 mr-1"
                                            data-dismiss="modal"
                                            onClick={() => this.cancelForm()}
                                        >
                                            Cancel
                                        </button>
                                        {selectedData ? (
                                            <button type="button" className="btn btn-primary btnRgion col-md-2" onClick={() => this.updateData()}>
                                                Update
                                            </button>
                                        ) : (
                                            <button type="button" className="btn btn-primary btnRgion col-md-2" onClick={() => this.addData()}>
                                                Add
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {this.renderConfirmationModal()}
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = state => {
    const {
        assetReducer,
        clientReducer: { getClientByIdResponse }
    } = state;
    return { assetReducer, getClientByIdResponse };
};
const { getClientById } = clientActions;
const { setFormDirty } = commonActions;
export default withRouter(
    connect(mapStateToProps, {
        ...actions,
        getClientById,
        setFormDirty
    })(Form)
);
