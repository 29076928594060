import * as actionType from "./constants";

const initialState = {
    entityParams: {
        entity: null,
        selectedEntity: null,
        selectedRowId: null,
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 0,
            search: "",
            filters: null,
            list: null,
            order: null
        },
        wildCardFilterParams: {},
        filterParams: {},
        tableConfig: null
    },
    shiftCspHistory: { loading: true },
    deleteShiftCspHistory: null,
    getListForCommonFilterResponse: null
};

const reducers = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_SHIFT_CSP_HISTORY_REQUEST:
            return {
                ...state,
                shiftCspHistory: { loading: true }
            };
        case actionType.GET_SHIFT_CSP_HISTORY_SUCCESS:
            return {
                ...state,
                shiftCspHistory: { loading: false, success: true, ...action.response }
            };
        case actionType.GET_SHIFT_CSP_HISTORY_FAILURE:
            return {
                ...state,
                shiftCspHistory: { loading: false, success: false, ...action.error }
            };

        case actionType.DELETE_SHIFT_HISTORY_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_SHIFT_HISTORY_SUCCESS:
            return {
                ...state,
                deleteShiftCspHistory: { success: true, ...action.response }
            };
        case actionType.DELETE_SHIFT_HISTORY_FAILURE:
            return {
                ...state,
                deleteShiftCspHistory: { success: false, ...action.error }
            };
        case actionType.GET_LIST_FOR_SHIFT_HISTORY_FILTER_REQUEST:
            return {
                ...state
            };
        case actionType.GET_LIST_FOR_SHIFT_HISTORY_FILTER_SUCCESS:
            return {
                ...state,
                getListForCommonFilterResponse: { success: true, ...action.response }
            };
        case actionType.GET_LIST_FOR_SHIFT_HISTORY_FILTER_FAILURE:
            return {
                ...state,
                getListForCommonFilterResponse: { success: false, ...action.error }
            };
        default:
            return state;
    }
};

export default reducers;
