// Get all regions
export const GET_ALL_CONSULTANCY_REQUEST = "GET_ALL_CONSULTANCY_REQUEST";
export const GET_ALL_CONSULTANCY_SUCCESS = "GET_ALL_CONSULTANCY_SUCCESS";
export const GET_ALL_CONSULTANCY_FAILURE = "GET_ALL_CONSULTANCY_FAILURE";

// Add Region
export const ADD_CONSULTANCY_REQUEST = "ADD_CONSULTANCY_REQUEST";
export const ADD_CONSULTANCY_SUCCESS = "ADD_CONSULTANCY_SUCCESS";
export const ADD_CONSULTANCY_FAILURE = "ADD_CONSULTANCY_FAILURE";

// Update Region
export const UPDATE_CONSULTANCY_REQUEST = "UPDATE_CONSULTANCY_REQUEST";
export const UPDATE_CONSULTANCY_SUCCESS = "UPDATE_CONSULTANCY_SUCCESS";
export const UPDATE_CONSULTANCY_FAILURE = "UPDATE_CONSULTANCY_FAILURE";

// Delete Region
export const DELETE_CONSULTANCY_REQUEST = "DELETE_CONSULTANCY_REQUEST";
export const DELETE_CONSULTANCY_SUCCESS = "DELETE_CONSULTANCY_SUCCESS";
export const DELETE_CONSULTANCY_FAILURE = "DELETE_CONSULTANCY_FAILURE";

// Get all consultancy users
export const GET_ALL_CONSULTANCY_USERS_REQUEST = "GET_ALL_CONSULTANCY_USERS_REQUEST";
export const GET_ALL_CONSULTANCY_USERS_SUCCESS = "GET_ALL_CONSULTANCY_USERS_SUCCESS";
export const GET_ALL_CONSULTANCY_USERS_FAILURE = "GET_ALL_CONSULTANCY_USERS_FAILURE";

// Get all Clients
export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const GET_ALL_CLIENTS_FAILURE = "GET_ALL_CLIENTS_FAILURE";

// Get Region by Id
export const GET_CONSULTANCY_BY_ID_REQUEST = "GET_CONSULTANCY_BY_ID_REQUEST";
export const GET_CONSULTANCY_BY_ID_SUCCESS = "GET_CONSULTANCY_BY_ID_SUCCESS";
export const GET_CONSULTANCY_BY_ID_FAILURE = "GET_CONSULTANCY_BY_ID_FAILURE";

// Upload Image
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

// Get all Images
export const GET_ALL_IMAGES_REQUEST = "GET_ALL_IMAGES_REQUEST";
export const GET_ALL_IMAGES_SUCCESS = "GET_ALL_IMAGES_SUCCESS";
export const GET_ALL_IMAGES_FAILURE = "GET_ALL_IMAGES_FAILURE";

// Delete Images
export const DELETE_IMAGES_REQUEST = "DELETE_IMAGES_REQUEST";
export const DELETE_IMAGES_SUCCESS = "DELETE_IMAGES_SUCCESS";
export const DELETE_IMAGES_FAILURE = "DELETE_IMAGES_FAILURE";

// Update Comments Images
export const UPDATE_IMAGE_COMMENT_REQUEST = "UPDATE_IMAGE_COMMENT_REQUEST";
export const UPDATE_IMAGE_COMMENT_SUCCESS = "UPDATE_IMAGE_COMMENT_SUCCESS";
export const UPDATE_IMAGE_COMMENT_FAILURE = "UPDATE_IMAGE_COMMENT_FAILURE";

// Update Region Entity Params
export const UPDATE_CONSULTANCY_ENTITY_PARAMS_SUCCESS = "UPDATE_CONSULTANCY_ENTITY_PARAMS_SUCCESS";
export const UPDATE_CONSULTANCY_ENTITY_PARAMS_FAILURE = "UPDATE_CONSULTANCY_ENTITY_PARAMS_FAILURE";

// Update Comments Images
export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_CONSULTANCY_EXPORT_REQUEST = "GET_CONSULTANCY_EXPORT_REQUEST";
export const GET_CONSULTANCY_EXPORT_SUCCESS = "GET_CONSULTANCY_EXPORT_SUCCESS";
export const GET_CONSULTANCY_EXPORT_FAILURE = "GET_CONSULTANCY_EXPORT_FAILURE";

export const GET_ALL_CONSULTANCY_LOGS_REQUEST = "GET_ALL_CONSULTANCY_LOGS_REQUEST";
export const GET_ALL_CONSULTANCY_LOGS_SUCCESS = "GET_ALL_CONSULTANCY_LOGS_SUCCESS";
export const GET_ALL_CONSULTANCY_LOGS_FAILURE = "GET_ALL_CONSULTANCY_LOGS_FAILURE";

export const RESTORE_CONSULTANCY_LOG_REQUEST = "RESTORE_CONSULTANCY_LOG_REQUEST";
export const RESTORE_CONSULTANCY_LOG_SUCCESS = "RESTORE_CONSULTANCY_LOG_SUCCESS";
export const RESTORE_CONSULTANCY_LOG_FAILURE = "RESTORE_CONSULTANCY_LOG_FAILURE";

export const DELETE_CONSULTANCY_LOG_REQUEST = "DELETE_CONSULTANCY_LOG_REQUEST";
export const DELETE_CONSULTANCY_LOG_SUCCESS = "DELETE_CONSULTANCY_LOG_SUCCESS";
export const DELETE_CONSULTANCY_LOG_FAILURE = "DELETE_CONSULTANCY_LOG_FAILURE";

export const GET_CHART_REGION_REQUEST = "GET_CHART_REGION_REQUEST";
export const GET_CHART_REGION_SUCCESS = "GET_CHART_REGION_SUCCESS";
export const GET_CHART_REGION_FAILURE = "GET_CHART_REGION_FAILURE";

export const GET_PROJECTS_BASED_ON_CLIENT_REQUEST = "GET_PROJECTS_BASED_ON_CLIENT_REQUEST";
export const GET_PROJECTS_BASED_ON_CLIENT_SUCCESS = "GET_PROJECTS_BASED_ON_CLIENT_SUCCESS";
export const GET_PROJECTS_BASED_ON_CLIENT_FAILURE = "GET_PROJECTS_BASED_ON_CLIENT_FAILURE";

export const GET_EFCI_BASED_ON_REGION_REQUEST = "GET_EFCI_BASED_ON_REGION_REQUEST";
export const GET_EFCI_BASED_ON_REGION_SUCCESS = "GET_EFCI_BASED_ON_REGION_SUCCESS";
export const GET_EFCI_BASED_ON_REGION_FAILURE = "GET_EFCI_BASED_ON_REGION_FAILURE";

export const GET_CHART_EFCI_REGION_REQUEST = "GET_CHART_EFCI_REGION_REQUEST";
export const GET_CHART_EFCI_REGION_SUCCESS = "GET_CHART_EFCI_REGION_SUCCESS";
export const GET_CHART_EFCI_REGION_FAILURE = "GET_CHART_EFCI_REGION_FAILURE";

export const SAVE_EFCI_REGION_REQUEST = "SAVE_EFCI_REGION_REQUEST";
export const SAVE_EFCI_REGION_SUCCESS = "SAVE_EFCI_REGION_SUCCESS";
export const SAVE_EFCI_REGION_FAILURE = "SAVE_EFCI_REGION_FAILURE";

export const LOAD_EFCI_REGION_REQUEST = "LOAD_EFCI_REGION_REQUEST";
export const LOAD_EFCI_REGION_SUCCESS = "LOAD_EFCI_REGION_SUCCESS";
export const LOAD_EFCI_REGION_FAILURE = "LOAD_EFCI_REGION_FAILURE";

export const UPDATE_REGION_FUNDING_COST_REQUEST = "UPDATE_REGION_FUNDING_COST_REQUEST";
export const UPDATE_REGION_FUNDING_COST_SUCCESS = "UPDATE_REGION_FUNDING_COST_SUCCESS";
export const UPDATE_REGION_FUNDING_COST_FAILURE = "UPDATE_REGION_FUNDING_COST_FAILURE";

export const UPDATE_REGION_FUNDING_COST_EFCI_REQUEST = "UPDATE_REGION_FUNDING_COST_EFCI_REQUEST";
export const UPDATE_REGION_FUNDING_COST_EFCI_SUCCESS = "UPDATE_REGION_FUNDING_COST_EFCI_SUCCESS";
export const UPDATE_REGION_FUNDING_COST_EFCI_FAILURE = "UPDATE_REGION_FUNDING_COST_EFCI_FAILURE";

export const UPDATE_REGION_ANNUAL_FUNDING_REQUEST = "UPDATE_REGION_ANNUAL_FUNDING_REQUEST";
export const UPDATE_REGION_ANNUAL_FUNDING_SUCCESS = "UPDATE_REGION_ANNUAL_FUNDING_SUCCESS";
export const UPDATE_REGION_ANNUAL_FUNDING_FAILURE = "UPDATE_REGION_ANNUAL_FUNDING_FAILURE";

export const UPDATE_REGION_ANNUAL_EFCI_REQUEST = "UPDATE_REGION_ANNUAL_EFCI_REQUEST";
export const UPDATE_REGION_ANNUAL_EFCI_SUCCESS = "UPDATE_REGION_ANNUAL_EFCI_SUCCESS";
export const UPDATE_REGION_ANNUAL_EFCI_FAILURE = "UPDATE_REGION_ANNUAL_EFCI_FAILURE";
