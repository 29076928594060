import React, { memo, useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import Loader from "../../../common/components/Loader";
import { WildCardFilterItem } from "./WildCardFilterItem";
import { areEqual } from "react-window";
import _ from "lodash";

export const FilterItem = memo(
    ({
        elem,
        showDropDown,
        handleFilterView,
        isLoading,
        options = [],
        handleFilterUpdate,
        filterData: filterParams,
        yearValues,
        setShowDropdown,
        setWildCardFilter,
        isAssetFilter,
        isSmartChartFilter = false,
        isCoreReportFilters = false,
        core_report_req_filters = {},
        isDashboard = false
    }) => {
        const initialData =
            elem.key === "year"
                ? {
                      start_year: filterParams?.start_year || "",
                      end_year: filterParams?.end_year || "",
                      update_fci: filterParams?.update_fci || true
                  }
                : [...(filterParams?.[elem.paramKey] || []), ...(filterParams?.exclude_filters?.[elem.paramKey] || [])] || [];
        const [filterData, setFilterData] = useState(initialData);
        const [search, setSearch] = useState("");
        const [yearErrorMessage, setYearErrorMessage] = useState("");
        const dropdownRef = useRef();
        const [exclude, setExclude] = useState(false);
        useEffect(() => {
            setFilterData(initialData);
            if (filterParams?.exclude_filters?.[elem.paramKey]?.length > 0) {
                setExclude(true);
            } else {
                setExclude(false);
            }
        }, [filterParams, showDropDown]);
        const handleChange = (item, label) => {
            if (label === "FCA Projects" && (isCoreReportFilters || isSmartChartFilter)) {
                const tempList = [item];
                setFilterData(tempList);
            } else {
                const tempList = filterData.includes(item) ? filterData.filter(elem => elem !== item) : [...filterData, item];
                setFilterData(tempList);
            }
        };

        const handleChangeYear = (e, clear) => {
            if (clear) {
                setFilterData({
                    ...filterData,
                    start_year: "",
                    end_year: "",
                    update_fci: true
                });
                setYearErrorMessage("");
            } else {
                const { name, value, checked } = e.target;
                let endYear = {};
                if (name === "start_year" && parseInt(filterData?.end_year) < parseInt(value)) {
                    endYear = { end_year: parseInt(value) };
                }
                if (name === "start_year" && !value) {
                    endYear = { end_year: "" };
                }
                if (name === "start_year" && yearErrorMessage === "startYear" && value) {
                    setYearErrorMessage("");
                }
                if (name === "end_year" && yearErrorMessage === "endYear" && value) {
                    setYearErrorMessage("");
                }
                setFilterData({ ...filterData, [name]: name === "update_fci" ? checked : value ? parseInt(value) : "", ...endYear });
            }
        };

        const handleSelectAll = e => {
            setFilterData(e.target.checked ? searchList.map(opt => opt.id) : []);
        };

        const handleClearSelection = () => {
            setFilterData([]);
            setExclude(false);
        };

        if ((elem.key === "building_types" || isAssetFilter) && options.length) {
            options = options?.map(item => ({
                id: item.name,
                name: item.name === true ? "Yes" : item.name === false ? "No" : item.name,
                count: item.count
            }));
        }
        const searchList = !search
            ? options
            : options?.filter(
                  item =>
                      item?.name?.toLowerCase()?.includes(search?.toLowerCase()) || item?.description?.toLowerCase()?.includes(search?.toLowerCase())
              );
        const availableOptions = searchList.filter(opt => elem.key !== "year" && !filterData.includes(opt.id));
        const selectedOptions = options.filter(opt => elem.key !== "year" && filterData.includes(opt.id));
        const isAllSelected = selectedOptions?.length > 0 && searchList.every(elem => elem.key !== "year" && filterData.includes(elem.id));
        const isFiltered =
            elem.key === "year"
                ? filterParams?.start_year || filterParams?.end_year
                : filterParams?.[elem.paramKey]?.length > 0 || filterParams?.exclude_filters?.[elem.paramKey]?.length > 0;
        useEffect(() => {
            if (selectedOptions.length || availableOptions.length) {
                ReactTooltip.rebuild();
            }
        }, [selectedOptions.length, availableOptions.length, options]);
        return (
            <div key={elem.key} ref={dropdownRef} className={`col list-prop-sec dshb`}>
                <ReactTooltip
                    id="master_filter_dropdown"
                    className="rc-tooltip-custom-class"
                    effect="solid"
                    place="left"
                    backgroundColor="#007bff"
                    delayShow={500}
                />
                <>
                    {elem.isWildCardFilter && ["text", "number", "year", "date", "select"].includes(elem.fieldType) ? (
                        <WildCardFilterItem
                            elem={elem}
                            showDropDown={showDropDown}
                            setShowDropdown={setShowDropdown}
                            filter={filterParams.filters?.[elem.paramKey] || {}}
                            topNFilter={filterParams?.top_n?.[elem.paramKey] || {}}
                            setWildCardFilter={setWildCardFilter}
                            dropdownRef={dropdownRef}
                            handleFilterView={handleFilterView}
                        />
                    ) : (
                        <div className={showDropDown === elem.key ? "dropdown-container dropdown-open" : "dropdown-container"}>
                            <label>{""}</label>
                            <div
                                className={`dropdown-toggle click-dropdown ${isFiltered ? "bg-th-filtered" : ""}`}
                                onClick={() => {
                                    handleFilterView(elem, dropdownRef);
                                    setYearErrorMessage("");
                                }}
                            >
                                <span className="text-drp-overflw">
                                    {elem.label + (isCoreReportFilters && core_report_req_filters[elem.paramKey]?.required ? "*" : "")}
                                </span>
                                <span className="close-reg">
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                            </div>

                            <div className={showDropDown === elem.key ? "dropdown-menu  dropdown-active " : "dropdown-menu "}>
                                {isLoading ? (
                                    <div className="col-md-12 p-4">
                                        <Loader />
                                    </div>
                                ) : options?.length || elem.key === "year" ? (
                                    <>
                                        <div
                                            className={`dropdown-menu drop-filtr pos-abs ${elem.key !== "year" ? "dp-rcm-overflow" : ""} mt-0`}
                                            onClick={e => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            <>
                                                {elem.key === "year" ? (
                                                    <YearFilter
                                                        year={filterData}
                                                        yearValues={(options ?? [])?.map(e => e?.id)}
                                                        handleChangeYear={handleChangeYear}
                                                        yearErrorMessage={yearErrorMessage}
                                                    />
                                                ) : (
                                                    <>
                                                        <div className="col-md-12 p-0 slt">
                                                            <span className="dropdown-item build_search_drp">
                                                                <input
                                                                    type="search"
                                                                    placeholder="Search..."
                                                                    value={search}
                                                                    onChange={e => setSearch(e.target.value)}
                                                                />
                                                                <i class="fas fa-times cursor-pointer cls-close" onClick={() => setSearch("")} />
                                                                <div className="container-check fil-container">
                                                                    <label>
                                                                        {`Select All (${selectedOptions.length}/${
                                                                            selectedOptions?.length + availableOptions?.length
                                                                        })`}
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={isAllSelected}
                                                                            onChange={handleSelectAll}
                                                                            disabled={
                                                                                (isCoreReportFilters || isSmartChartFilter) &&
                                                                                elem?.label === "FCA Projects"
                                                                            }
                                                                        />
                                                                        <span
                                                                            className={`checkmark ${
                                                                                (isCoreReportFilters || isSmartChartFilter) &&
                                                                                elem?.label === "FCA Projects"
                                                                                    ? "cursor-diabled"
                                                                                    : ""
                                                                            }`}
                                                                        ></span>
                                                                    </label>

                                                                    {isDashboard && (
                                                                        <div className="exclude-container">
                                                                            <span className="dropdown-item">
                                                                                <label className="container-check">
                                                                                    Exclude
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={exclude}
                                                                                        onClick={() => {
                                                                                            setExclude(!exclude);
                                                                                        }}
                                                                                    />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    <button
                                                                        className={`clear-btn-selection ${
                                                                            (isCoreReportFilters || isSmartChartFilter) &&
                                                                            elem?.label === "FCA Projects"
                                                                                ? "cursor-diabled"
                                                                                : ""
                                                                        }`}
                                                                        onClick={handleClearSelection}
                                                                        disabled={
                                                                            (isCoreReportFilters || isSmartChartFilter) &&
                                                                            elem?.label === "FCA Projects"
                                                                        }
                                                                    >
                                                                        Clear
                                                                    </button>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        {selectedOptions?.length > 0 && (
                                                            <div className="col-md-12 p-0 slt-ara border-bottom">
                                                                {selectedOptions.map(item => (
                                                                    <span className="dropdown-item" key={item.id}>
                                                                        <label className="container-check d-flex align-items-center">
                                                                            <span
                                                                                className="text-short"
                                                                                data-tip={`${
                                                                                    item.description ? `${item.name} (${item.description})` : ""
                                                                                }`}
                                                                                data-for="master_filter_dropdown"
                                                                            >
                                                                                {item.name || "-"} {item.description ? `(${item.description})` : ""}
                                                                            </span>
                                                                            <span className="count-num"> ({item.count})</span>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={true}
                                                                                onChange={() => handleChange(item.id, elem?.label)}
                                                                                disabled={
                                                                                    selectedOptions?.length === 1 &&
                                                                                    (isCoreReportFilters || isSmartChartFilter) &&
                                                                                    elem?.label === "FCA Projects"
                                                                                }
                                                                            />
                                                                            <span
                                                                                className={`checkmark ${
                                                                                    selectedOptions?.length === 1 &&
                                                                                    (isCoreReportFilters || isSmartChartFilter) &&
                                                                                    elem?.label === "FCA Projects"
                                                                                        ? `cursor-diabled`
                                                                                        : ""
                                                                                }`}
                                                                            ></span>
                                                                        </label>
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        )}
                                                        {availableOptions?.length > 0 && (
                                                            <div className="col-md-12 p-0 slt-ara">
                                                                {availableOptions.map(item => (
                                                                    <span className="dropdown-item" key={item.id}>
                                                                        <label className="container-check d-flex align-items-center">
                                                                            <span
                                                                                className="text-short"
                                                                                data-tip={`${
                                                                                    item.description ? `${item.name} (${item.description})` : ""
                                                                                }`}
                                                                                data-for="master_filter_dropdown"
                                                                            >
                                                                                {item.name || "-"} {item.description ? `(${item.description})` : ""}
                                                                            </span>
                                                                            <span className="count-num"> ({item.count})</span>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={false}
                                                                                onChange={() => handleChange(item.id, elem?.label)}
                                                                            />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                            {/* <div className="col-md-6 no-wrap">NO MORE VALUES TO DISPLAY</div> */}
                                            <div className="col-md-12 mt-3 drp-btn">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btnRgion mr-2"
                                                    onClick={() =>
                                                        elem?.label === "Years"
                                                            ? !filterData?.start_year && filterData?.end_year
                                                                ? setYearErrorMessage("startYear")
                                                                : !filterData?.end_year && filterData?.start_year
                                                                ? setYearErrorMessage("endYear")
                                                                : handleFilterUpdate(elem, filterData, exclude)
                                                            : handleFilterUpdate(elem, filterData, exclude)
                                                    }
                                                >
                                                    OK
                                                </button>
                                                <button type="button" className="btn btn-primary btnClr" onClick={() => handleFilterView(elem)}>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col-md-12 p-2"> No Data Found</div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            </div>
        );
    },
    areEqual
);
const YearFilter = ({ yearValues = [], year, updateFCI, handleChangeYear, yearErrorMessage }) => {
    const startYear = year?.start_year ?? null;
    const startYearOptions = yearValues;
    const endYearOptions = yearValues?.filter(yr => startYear === null || yr >= startYear);
    return (
        <div className="drop-filtr pb-3">
            <div className="col-md-12 p-0 slt-ara">
                <div className="year-outer ">
                    <div
                        class="clear-list-sec col-md-12 text-right p-0"
                        onClick={e => {
                            handleChangeYear(e, "clear");
                        }}
                    >
                        <span class="edit-icn-bx">Clear</span>
                    </div>
                    <div className="col-xl-12 selecbox-otr p-0">
                        <span className="dropdown-item p-0">
                            <label>Start Year</label>
                            <div className={`custom-selecbox w-100 ${yearErrorMessage === "startYear" ? "error-border" : ""}`}>
                                <select
                                    value={year?.start_year}
                                    name={"start_year"}
                                    onChange={e => {
                                        handleChangeYear(e);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {startYearOptions.map(yearOption => (
                                        <option key={yearOption} value={yearOption}>
                                            {yearOption}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </span>
                    </div>
                    <div className="col-xl-12 p-0 selecbox-otr p-0">
                        <span className="dropdown-item  p-0">
                            <label>End Year</label>
                            <div className={`custom-selecbox w-100 ${yearErrorMessage === "endYear" ? "error-border" : ""}`}>
                                <select
                                    value={year?.end_year}
                                    name={"end_year"}
                                    onChange={e => {
                                        handleChangeYear(e);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {endYearOptions.map(yearOption => (
                                        <option key={yearOption} value={yearOption}>
                                            {yearOption}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </span>
                    </div>
                </div>
            </div>

            <div className="col-md-12 p-0  pt-1">
                <span className="dropdown-item">
                    <label className="container-check">
                        Static FCI
                        <input
                            type="checkbox"
                            checked={year?.update_fci}
                            name="update_fci"
                            onChange={e => {
                                handleChangeYear(e);
                            }}
                        />
                        <span className="checkmark"></span>
                    </label>
                </span>
            </div>
        </div>
    );
};
