import moment from "moment";
import { useSelector } from "react-redux";
import { energy_fields } from "./Bands/EnergyBand";
import { water_fields } from "./Bands/WaterBand";

export const getUpdatedCommonFilters = (filters = {}, list = {}, tableConfig) => {
    let result = {};

    result = Object.entries(filters)?.reduce((filtersData, [currentDataKey, currentDataValue]) => {
        let [selectedItemKey, selectedItemValue] =
            tableConfig?.find(([key, value]) => {
                let searchKeyName = value?.filterIdKey || value?.searchKey;
                if (searchKeyName === currentDataKey) return [key, value];
            }) || [];
        let currentFilterKey = selectedItemValue?.smartChartCommonSearchKey || "";
        let currentFilterValue = currentDataValue || [];
        if (currentFilterKey) {
            return {
                ...filtersData,
                [currentFilterKey]: currentFilterKey === "buildings_descriptions" ? list && list["buildings.description"] : [...currentFilterValue]
            };
        }
        return {
            ...filtersData
        };
    }, {});
    return result;
};
export const getUpdatedWildCardFilters = (filters = {}, tableConfigs, priorityElementsDropDownResponse, exportSettingsData) => {
    let result = {};
    let tableConfig = exportSettingsData?.data?.properties || exportSettingsData?.properties || [];
    if (tableConfig?.length) {
        let additionalKeys = ["is_dynamic_field", "is_static_field", "is_energy_band", "is_water_band"];
        result = Object.entries(filters)?.reduce((filtersData, [currentDataKey, currentDataValue], index) => {
            let updatedData = { ...currentDataValue } || {};
            let [selectedItemKey, selectedItemValue] = tableConfigs?.find(([key, value]) => value?.searchKey == currentDataKey) || [];
            let finalKey = "";
            let fieldType = "";
            if (selectedItemValue?.getListTable == "maintenance_year") {
                finalKey = currentDataKey?.split(".")?.[1];
                fieldType = "is_maintenance_year";
            } else if (selectedItemValue?.getListTable == "priority_element") {
                let currentPriorityElement =
                    priorityElementsDropDownResponse?.priority_elements?.[parseInt(currentDataKey?.split(".")?.[1]) - 1] || {};
                finalKey = currentPriorityElement?.id;
                fieldType = "is_priority_element";
            } else if (selectedItemKey?.includes("energy_band_")) {
                let energyBandIndex = selectedItemKey?.split("_")?.pop();
                let currentSelected = energy_fields?.[energyBandIndex] || {};
                finalKey = currentSelected?.key;
                fieldType = "is_energy_band";
            } else if (selectedItemKey?.includes("water_band_")) {
                let energyBandIndex = selectedItemKey?.split("_")?.pop();
                let currentSelected = water_fields?.[energyBandIndex] || {};
                finalKey = currentSelected?.key;
                fieldType = "is_water_band";
            } else {
                let currentSelected = tableConfig.find(k => k.key == selectedItemKey) || {};
                finalKey = currentSelected?.key || "";
                fieldType = additionalKeys.find(type => Object.keys(currentSelected).includes(type));
                if (selectedItemValue?.type === "date" && currentDataValue?.key !== null) {
                    finalKey = currentSelected?.key || "";
                    fieldType = additionalKeys.find(type => Object.keys(currentSelected).includes(type));
                    updatedData = {
                        ...updatedData,
                        key: {
                            from: moment(currentDataValue?.key?.from).format("YYYY-MM-DD"),
                            to: moment(currentDataValue?.key?.to).format("YYYY-MM-DD")
                        }
                    };
                }
                if (selectedItemValue?.smartChartWildCardFilterKey) {
                    finalKey = selectedItemValue?.smartChartWildCardFilterKey;
                    fieldType = "is_static_field";
                }
            }
            if (finalKey && currentDataValue?.key !== null) {
                return {
                    ...filtersData,
                    [finalKey]: { ...updatedData, [fieldType]: true }
                };
            }
            return {
                ...filtersData
            };
        }, {});
    }
    return result;
};

export const getUpdatedSortFilters = (filters = {}, tableConfigs, params, exportSettingsData) => {
    let tableConfig = exportSettingsData?.data?.properties || exportSettingsData?.properties || [];
    let result = {};
    if (tableConfig?.length) {
        result = Object.entries(filters)?.reduce((filtersData, [currentDataKey, currentDataValue], index) => {
            let updatedData = { order: currentDataValue };
            let [selectedItemKey, selectedItemValue] = tableConfigs?.find(([key, value]) => value?.searchKey == currentDataKey) || [];
            let finalKey = "";
            let fieldType = "";
            if (currentDataKey == "maintenance_years.amount") {
                finalKey = params?.maintenance_year?.[0];
                fieldType = "is_maintenance_year";
            } else if (currentDataKey?.includes("dynamic_fields")) {
                finalKey = currentDataKey?.split(".")?.[1];
                fieldType = "is_dynamic_field";
            } else {
                let currentSelected = tableConfig?.find(k => k.key == selectedItemKey) || {};
                finalKey = currentSelected?.key || "";
                fieldType = "is_static_field";
                if (selectedItemValue?.smartChartWildCardFilterKey) {
                    finalKey = selectedItemValue?.smartChartWildCardFilterKey;
                    fieldType = "is_static_field";
                }
            }
            if (finalKey) {
                return {
                    ...filtersData,
                    [finalKey]: { ...updatedData, [fieldType]: true }
                };
            }
            return {
                ...filtersData
            };
        }, {});
    }
    return result;
};
