import React, { useEffect } from "react";
import RightArrowIcon from "../../../assets/img/arrow-right-btn.svg";
import Draggable from "react-draggable";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import qs from "query-string";
import { useDispatch } from "react-redux";
import actions from "../actions";

const ExportModal = ({ onCancel, exportLoader, viewExportSettings, exportData }) => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const query = qs.parse(search);
    const client_id = query?.client_id ?? "";

    useEffect(() => {
        if (client_id) dispatch(actions.getExportProperties({ client_id }));
    }, []);

    return (
        <div className="modal modal-region add-image-modal img-pop-small" id="modalId" tabIndex={-1} style={{ display: "block" }}>
            <Draggable handle=".draggable" cancel=".not-draggable">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className={`modal-body region-otr draggable`}>
                            <div className="close-btn-area">
                                <button type="button" className="close not-draggable" onClick={onCancel}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>

                            <div className="content-area mt-3 not-draggable">
                                <h3 className="heading">Asset One Pager Export</h3>
                                <div className="down-area">
                                    <button className="btn btn-download mb-2" onClick={() => !exportLoader && exportData("word")}>
                                        Export as Word
                                        {(exportLoader === "sortedWord" || exportLoader === "sortedExcel") && (
                                            <span className="spinner-border spinner-border-sm pl-2 ml-1" role="status"></span>
                                        )}
                                    </button>
                                    <button className="btn btn-download mb-2" onClick={() => !exportLoader && exportData("pdf")}>
                                        Export as Pdf
                                        {(exportLoader === "sortedWord" || exportLoader === "sortedExcel") && (
                                            <span className="spinner-border spinner-border-sm pl-2 ml-1" role="status"></span>
                                        )}
                                    </button>
                                    <button className="btn btn-download outdated" onClick={viewExportSettings}>
                                        One Pager Settings <img src={RightArrowIcon} class="ml-1" alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    );
};

export default ExportModal;
