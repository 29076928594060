import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { addToBreadCrumpData, checkPermission, popBreadCrumpData } from "../../../config/utils";
import { Dropdown } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import Portal from "./Portal";
import AddIssueModal from "./AddIssueModal";
import { connect } from "react-redux";
import ConfirmationModal from "./ConfirmationModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/css/info-tab.css";
import { RECOMMENDATION_SOURCE_TYPES } from "../constants";

class InfoTabs extends Component {
    constructor(props) {
        super(props);
        this.sliderRef = React.createRef();
        this.state = {
            isIssueAdding: false,
            showAddIssueConfirmationModal: false,
            startIndex: 0,
            SlickBtnShow: false,
            rightArrow: false
        };
    }

    handleTabClick = async item => {
        const {
            history,
            match: {
                params: { section }
            }
        } = this.props;

        switch (item.key) {
            case "basicdetails":
                addToBreadCrumpData({
                    key: "info",
                    name: "Basic Details",
                    path: item.path
                });
                break;
            case "additionaldetails":
                addToBreadCrumpData({
                    key: "info",
                    name: "Additional Details",
                    path: item.path
                });
                break;
            case "assetdetails":
                addToBreadCrumpData({
                    key: "info",
                    name: "Asset Details",
                    path: item.path
                });
                break;
            case "maindetails":
                addToBreadCrumpData({
                    key: "info",
                    name: "Main Details",
                    path: item.path
                });
                break;
            case "infoimages":
                addToBreadCrumpData({
                    key: "info",
                    name: "Images",
                    path: item.path
                });
                break;
            case "infomap":
                addToBreadCrumpData({
                    key: "info",
                    name: "Map",
                    path: item.path
                });
                break;
            case "sites":
                addToBreadCrumpData({
                    key: "info",
                    name: "Sites",
                    path: item.path
                });
                break;
            case "regions":
                addToBreadCrumpData({
                    key: "info",
                    name: "Regions",
                    path: item.path
                });
                break;
            case "recommendations":
                addToBreadCrumpData({
                    key: "info",
                    name: "Recommendations",
                    path: item.path
                });
                break;

            case "buildings":
                addToBreadCrumpData({
                    key: "info",
                    name: "Buildings",
                    path: item.path
                });
                break;
            case "floors":
                addToBreadCrumpData({
                    key: "info",
                    name: "Floors",
                    path: item.path
                });
                break;
            case "buildingAddition":
                addToBreadCrumpData({
                    key: "info",
                    name: "Addition",
                    path: item.path
                });
                break;
            case "settings":
                addToBreadCrumpData({
                    key: "info",
                    name: item.bcName || "Settings",
                    path: item.path
                });
                break;
            // case "reports":
            //     addToBreadCrumpData({
            //         key: "info",
            //         name: item.bcName || "Reports",
            //         path: item.path
            //     });
            //     break;
            case "futurecapital":
                addToBreadCrumpData({
                    key: "info",
                    name: "Future Capital",
                    path: item.path
                });
                break;
            case "deferredmaintenance":
                addToBreadCrumpData({
                    key: "info",
                    name: "Deferred Maintenance",
                    path: item.path
                });
                break;
            case "efci":
                addToBreadCrumpData({
                    key: "info",
                    name: "EFCI",
                    path: item.path
                });
                break;
            case "softCosts":
                addToBreadCrumpData({
                    key: "info",
                    name: "Soft Costs",
                    path: item.path
                });
                break;
            case "efcisandbox":
                addToBreadCrumpData({
                    key: "info",
                    name: "EFCI Sandbox",
                    path: item.path
                });
                break;

            case "dashboard":
                addToBreadCrumpData({
                    key: "info",
                    name: "Charts & Graphs",
                    path: item.path
                });
                break;

            case "clients":
                addToBreadCrumpData({
                    key: "info",
                    name: "Clients",
                    path: item.path
                });
                break;
            case "settingsbasicdata":
                addToBreadCrumpData({
                    key: "info",
                    name: "Settings Info",
                    path: item.path
                });
                break;
            case "history":
                addToBreadCrumpData({
                    key: "info",
                    name: "Import History",
                    path: item.path
                });
                break;
            case "escalation":
                addToBreadCrumpData({
                    key: "info",
                    name: "Soft Costs",
                    path: item.path
                });
                break;
            case "exporthistory":
                addToBreadCrumpData({
                    key: "info",
                    name: "Export History",
                    path: item.path
                });
                break;
            case "projects":
                addToBreadCrumpData({
                    key: "info",
                    name: "FCA Projects",
                    path: item.path
                });
                break;
            case "color_code":
                addToBreadCrumpData({
                    key: "info",
                    name: "FCI Color Codes",
                    path: item.path
                });
                break;
            case "initiatives":
                popBreadCrumpData();
                break;
            case "recommendation":
                addToBreadCrumpData({
                    key: "info",
                    name: "Recommendations",
                    path: item.path
                });
                break;
            case "assignednarrativetemplate":
                addToBreadCrumpData({
                    key: "info",
                    name: "Assigned Narrative Template",
                    path: item.path
                });
                break;
            case "assignedtabletemplate":
                addToBreadCrumpData({
                    key: "info",
                    name: "Assigned Table Template",
                    path: item.path
                });
                break;
            case "assignedreportnotetemplate":
                addToBreadCrumpData({
                    key: "info",
                    name: "Assigned Recommendation Narrative Template",
                    path: item.path
                });
                break;
            case "assignedrecommendationtemplate":
                addToBreadCrumpData({
                    key: "info",
                    name: "Assigned Recommendation Template",
                    path: item.path
                });
                break;
            case "fontnames":
                addToBreadCrumpData({
                    key: "info",
                    name: "Font Names",
                    path: item.path
                });
                break;
            case "tablestyles":
                addToBreadCrumpData({
                    key: "info",
                    name: "Table Styles",
                    path: item.path
                });
                break;
            case "Electricity":
                addToBreadCrumpData({
                    key: "info",
                    name: "Electricity",
                    path: item.path
                });
                break;
            case "Gas":
                addToBreadCrumpData({
                    key: "info",
                    name: "Gas",
                    path: item.path
                });
                break;
            case "Water":
                addToBreadCrumpData({
                    key: "info",
                    name: "Water",
                    path: item.path
                });
                break;
            case "Sewer":
                addToBreadCrumpData({
                    key: "info",
                    name: "Sewer",
                    path: item.path
                });
                break;
            case "energyStarRating":
                addToBreadCrumpData({
                    key: "info",
                    name: "Energy Star Rating",
                    path: item.path
                });
                break;
            case "energydashboard":
                addToBreadCrumpData({
                    key: "info",
                    name: "Energy Dashboard",
                    path: item.path
                });
                break;
            case "assets":
                addToBreadCrumpData({
                    key: "info",
                    name: "Assets",
                    path: item.path
                });
                break;
            case "assetcharts":
                addToBreadCrumpData({
                    key: "info",
                    name: "Charts & Graphs",
                    path: item.path
                });
                break;
            case "documents":
                addToBreadCrumpData({
                    key: "info",
                    name: "Documents",
                    path: item.path
                });
                break;
            case "chart_templates":
                addToBreadCrumpData({
                    key: "info",
                    name: "Chart Templates",
                    path: item.path
                });
                break;
            case "chartProperties":
                addToBreadCrumpData({
                    key: "info",
                    name: "Chart Properties",
                    path: item.path
                });
                break;

            case "passwordsettings":
                addToBreadCrumpData({
                    key: "info",
                    name: "Password Settings",
                    path: item.path
                });
                break;
            case "capitalrequest":
                addToBreadCrumpData({
                    key: "info",
                    name: "CR",
                    path: item.path
                });
                break;
            case "cr-recommendations":
                addToBreadCrumpData({
                    key: "info",
                    name: "Capital Request",
                    path: item.path
                });
                break;
            case "qaqc":
                addToBreadCrumpData({
                    key: "info",
                    name: "Qa/Qc",
                    path: item.path
                });
                break;
            case "recommendationdynamicfield":
                addToBreadCrumpData({
                    key: "info",
                    name: "Recommendation Dynamic Fields",
                    path: item.path
                });
                break;
            case "assetdynamicfield":
                addToBreadCrumpData({
                    key: "info",
                    name: "Assets Dynamic Fields",
                    path: item.path
                });
                break;
            case "buildingdynamicfield":
                addToBreadCrumpData({
                    key: "info",
                    name: "Buildings Dynamic Fields",
                    path: item.path
                });
                break;
            case "bandsdynamicfield":
                addToBreadCrumpData({
                    key: "info",
                    name: "Dynamic Field Bands",
                    path: item.path
                });
                break;
            case "shift-csp-history":
                addToBreadCrumpData({
                    key: "info",
                    name: "Shift History",
                    path: item.path
                });
                break;
            case "linked-recommendations":
                addToBreadCrumpData({
                    key: "info",
                    name: "Recommendations",
                    path: item.path,
                    isCr: true
                });
                break;
            case "linked-cr-recommendations":
                addToBreadCrumpData({
                    key: "info",
                    name: "Capital Request",
                    path: item.path
                });
                break;
            default:
                addToBreadCrumpData({
                    key: "info",
                    name: "Sites",
                    path: item.path
                });
                break;
        }

        if (section === "efciinfo") {
            await this.props.setActiveTab(item);
        }
        if (!item.path) {
            this.props.handleInnerTabClick(item.key);
        } else {
            history.push(item.path, item.state);
        }
    };

    lockIcon(basicDetails) {
        const hasLock = checkPermission("forms", "recommendations", "lock");
        return (
            <>
                <ReactTooltip id="lock" place="bottom" effect="solid" backgroundColor="#007bff" />
                <div
                    data-tip={this.props.isRecommendation ? "Click to Unlock this Recommendation" : ""}
                    data-for="lock"
                    onClick={() => hasLock && this.props.lockItem && this.props.lockItem()}
                    className={`${basicDetails === true ? "locked" : "unlocked"} locking-center cursor-hand`}
                >
                    {this.props.lockloading1 || this.props.lockloading ? (
                        <span className="spinner-border spinner-border-sm pl-2" role="status"></span>
                    ) : (
                        <img src="/img/lock-whi.svg" alt="" className="export" />
                    )}
                </div>
            </>
        );
    }

    unlockIcon(basicDetails) {
        const hasLock = checkPermission("forms", "recommendations", "lock");
        return (
            <>
                <ReactTooltip id="unlock" place="bottom" effect="solid" backgroundColor="#007bff" />
                <div
                    data-tip={this.props.isRecommendation ? "Click to Lock this Recommendation" : ""}
                    data-for="unlock"
                    onClick={() => hasLock && this.props.lockItem && this.props.lockItem()}
                    className={`${basicDetails === true ? "locked" : "unlocked"} locking-center cursor-hand`}
                >
                    {this.props.lockloading1 || this.props.lockloading ? (
                        <span className="spinner-border spinner-border-sm pl-2" role="status"></span>
                    ) : (
                        <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            width="17.064px"
                            height="15.289px"
                            viewBox="0 0.147 17.064 15.289"
                            enableBackground="new 0 0.147 17.064 15.289"
                            xmlSpace="preserve"
                        >
                            <g>
                                <g>
                                    <path
                                        fill="none"
                                        d="M1.123,8.031v5.6c0.001,0.375,0.308,0.682,0.684,0.683h7.467c0.375-0.001,0.682-0.308,0.684-0.683v-5.6
C9.957,7.653,9.649,7.345,9.274,7.345H1.807C1.43,7.346,1.123,7.654,1.123,8.031z M5.54,8.09c0.994,0.001,1.805,0.811,1.807,1.804
c0,0.8-0.525,1.473-1.246,1.71v1.403C6.099,13.32,5.847,13.57,5.54,13.57H5.537c-0.309-0.001-0.56-0.254-0.558-0.563v-1.403
c-0.72-0.236-1.245-0.909-1.245-1.708C3.734,8.9,4.544,8.09,5.54,8.09z"
                                    />
                                    <circle fill="none" cx="5.54" cy="9.897" r="0.684" />
                                    <path
                                        fill="#707070"
                                        d="M0,8.029v5.602c0.001,0.995,0.811,1.805,1.806,1.806h7.468c0.994-0.001,1.805-0.811,1.806-1.806V8.028
c-0.001-0.797-0.524-1.469-1.245-1.705H1.241C0.523,6.563,0.001,7.232,0,8.029z M9.274,7.345c0.375,0,0.683,0.308,0.684,0.686v5.6
c-0.002,0.375-0.309,0.682-0.684,0.683H1.807c-0.376-0.001-0.683-0.308-0.684-0.683v-5.6c0-0.377,0.307-0.685,0.684-0.686H9.274z"
                                    />
                                    <path
                                        fill="#707070"
                                        d="M4.979,11.604v1.403c-0.002,0.309,0.249,0.562,0.558,0.563H5.54c0.307,0,0.559-0.25,0.561-0.563v-1.403
c0.721-0.237,1.246-0.91,1.246-1.71C7.345,8.901,6.534,8.091,5.54,8.09c-0.996,0-1.806,0.81-1.806,1.806
C3.734,10.695,4.259,11.367,4.979,11.604z M5.54,9.213c0.377,0,0.684,0.307,0.684,0.682c0,0.377-0.308,0.685-0.684,0.686
c-0.376-0.001-0.683-0.308-0.683-0.685C4.857,9.52,5.164,9.213,5.54,9.213z"
                                    />
                                </g>
                                <path
                                    fill="#707070"
                                    d="M9.596,4.442c0-1.749,1.424-3.173,3.173-3.173c1.75,0,3.173,1.423,3.173,3.173v1.881h1.123V4.442
c-0.003-2.365-1.93-4.292-4.295-4.295c-2.366,0.003-4.292,1.93-4.295,4.295v1.881h1.121V4.442z"
                                />
                            </g>
                        </svg>
                    )}
                </div>
            </>
        );
    }

    toggleAddIssueModal = () => {
        if (this.props.recommendationsReducer.getRecommendationByIdResponse?.qa_passed && !this.state.isIssueAdding)
            this.setState({ showAddIssueConfirmationModal: true });
        else this.setState({ isIssueAdding: !this.state.isIssueAdding });
    };

    renderAddIssueModal = () => {
        if (!this.state?.isIssueAdding) return null;

        return <Portal body={<AddIssueModal onCancel={this.toggleAddIssueModal} />} onCancel={this.toggleAddIssueModal} />;
    };

    renderAddIssueConfirmationModal = () => {
        if (!this.state.showAddIssueConfirmationModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"This recommendation is marked as QA/QC passed."}
                        message={"Are you sure you want to create a new QA/QC issue for this recommendation ?"}
                        isUnAssign
                        type="unassign"
                        onNo={() => this.setState({ showAddIssueConfirmationModal: false })}
                        onYes={() => {
                            this.setState({ isIssueAdding: true, showAddIssueConfirmationModal: false });
                        }}
                    />
                }
                onCancel={() => this.setState({ showAddIssueConfirmationModal: false })}
            />
        );
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.observeTableTopMenuWidth();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    observeTableTopMenuWidth() {
        let resizeTimeout = null;
        this.resizeObserver = new ResizeObserver(entries => {
            if (!resizeTimeout) {
                resizeTimeout = setTimeout(() => {
                    resizeTimeout = null;
                    for (let entry of entries) {
                        this.handleResize();
                        break;
                    }
                }, 100);
            }
        });
        const tableTopMenu = document.querySelector(".table-top-menu.outer-flx-section");
        if (tableTopMenu) {
            this.resizeObserver.observe(tableTopMenu);
        }
    }

    handleResize = () => {
        const tableTopMenu = document.querySelector(".table-top-menu.outer-flx-section");
        if (!tableTopMenu) {
            return;
        }
        const tableTopMenuWidth = tableTopMenu.offsetWidth;
        const innerSliderNode = this.sliderRef.current && this.sliderRef.current.innerSlider;
        if (!innerSliderNode) {
            return;
        }
        const trackNode = innerSliderNode.list;

        const slides = trackNode.querySelectorAll(".slick-slide");
        let totalWidth = 0;
        slides.forEach(slide => {
            const contentWrapper = slide.firstElementChild;
            totalWidth += contentWrapper.offsetWidth;
        });
        const showArrows = totalWidth > tableTopMenuWidth + 36;
        this.setState({ SlickBtnShow: showArrows });
        this.setState({ showArrows });
    };
    preNextBtnClick = () => {
        const tableTopMenu = document.querySelector(".slick-list");
        if (!tableTopMenu) {
            return;
        }
        const tableTopMenuWidth = tableTopMenu?.offsetWidth || 0;
        const innerSliderNode = this.sliderRef.current && this.sliderRef.current.innerSlider;
        if (!innerSliderNode) {
            return;
        }
        const trackNode = innerSliderNode.list;
        const slides = trackNode.querySelectorAll(".slick-slide");
        let totalWidth = 0;

        let reachedCurrentSlide = false;
        slides.forEach(slide => {
            if (slide.classList.contains("slick-current")) {
                reachedCurrentSlide = true;
            }
            if (reachedCurrentSlide) {
                const contentWrapper = slide.firstElementChild;
                totalWidth += contentWrapper.offsetWidth;
            }
        });
        const showArrows = totalWidth < tableTopMenuWidth;
        this.setState({ rightArrow: showArrows });
    };

    render() {
        const similarFields = ["Trade", "System", "Sub System", "Recommendation Type", "Status", "Inspection Date"];

        const geoHierarchyFields = ["Region", "Site", "Building", "Building Type", "Addition", "Floor", "Room"];

        const settings = {
            infinite: false,
            variableWidth: true,
            slidesToScroll: 1,
            swipe: false,
            arrows: this.state.SlickBtnShow,
            afterChange: () => {
                this.preNextBtnClick();
            },
            prevArrow: (
                <button type="button" class="slick-prev pull-left">
                    <i class="fas fa-chevron-left"></i>
                </button>
            ),
            nextArrow: (
                <button
                    type="button"
                    class={`slick-next pull-right ${this.state.rightArrow ? "slick-disabled next-btn-disabled" : ""}`}
                    disabled={this.state.rightArrow}
                >
                    <i class="fas fa-chevron-right"></i>
                </button>
            )
        };
        const {
            infoTabsData,
            basicDetails,
            isSettingSubTab,
            isTabWideClass = false,
            hasCreate,
            isQaQcReviewerUser,
            qaqcPendingStatus,
            entityName
        } = this.props;
        const currentUser = localStorage.getItem("userId");
        const isSameOwner = currentUser === basicDetails?.surveyor_id;
        return (
            <React.Fragment>
                {this.renderAddIssueConfirmationModal()}

                <div
                    className={`table-top-menu outer-flx-section ${hasCreate ? "qaqc-section" : ""} ${
                        this.props?.CrBasedRecommendationBtn ? "cap-req" : ""
                    }`}
                >
                    <ul className={this.props.isTabClass ? `tab-data ${isTabWideClass ? "top-head-wide" : ""}` : null}>
                        {/* {infoTabsData && infoTabsData.length
                            ? infoTabsData.map((item, i) => {
                                  let show = true;
                                  if (item.hasOwnProperty("show")) {
                                      show = item.show;
                                  }
                                  return (
                                      show && (
                                        //   <li
                                        //       className={`cursor-pointer${
                                        //           isSettingSubTab && this.props.match.params.subTab === item.key
                                        //               ? "active"
                                        //               : this.props.match.params.tab === item.key ||
                                        //                 (item.key == "initiatives" && this.props.match.params.section != "initiativeInfo")
                                        //               ? " active"
                                        //               : ""
                                        //       }`}
                                        //       key={i}
                                        //       onClick={() => this.handleTabClick(item)}
                                        //   >
                                        //       {item.showIndicator && !!qaqcPendingStatus && (
                                        //           <span className="dots">
                                        //               <img src={`/img/${qaqcPendingStatus}.svg`} class="status-icons dots" alt="" />
                                        //           </span>
                                        //       )}
                                        //       {item.name}
                                        //   </li>
                                        
                                      )
                                  );
                              })
                            : null}
                        {isQaQcReviewerUser && !basicDetails.locked && (
                            <button className="issue-new-btn" onClick={this.toggleAddIssueModal}>
                                Add New QA/QC Issue
                            </button>
                        )} */}
                        <Slider {...settings} ref={this.sliderRef}>
                            {infoTabsData && infoTabsData.length
                                ? infoTabsData.map((item, i) => {
                                      let show = true;
                                      if (item.hasOwnProperty("show")) {
                                          show = item.show;
                                      }
                                      return (
                                          show && (
                                              <div>
                                                  <li
                                                      className={`cursor-pointer${
                                                          isSettingSubTab && this.props.match.params.subTab === item.key
                                                              ? " active"
                                                              : this.props.match.params.tab === item.key ||
                                                                (item.key === "initiatives" && this.props.match.params.section !== "initiativeInfo")
                                                              ? " active"
                                                              : ""
                                                      } ${item.showIndicator && !!qaqcPendingStatus ? "d-flex" : ""}`}
                                                      key={i}
                                                      onClick={() => this.handleTabClick(item)}
                                                  >
                                                      {item.showIndicator && !!qaqcPendingStatus && (
                                                          <span className="dots">
                                                              <img src={`/img/${qaqcPendingStatus}.svg`} className="status-icons dots" alt="" />
                                                          </span>
                                                      )}
                                                      {item.name}
                                                  </li>
                                              </div>
                                          )
                                      );
                                  })
                                : null}
                            {isQaQcReviewerUser && !basicDetails.locked && (
                                <button className="issue-new-btn" onClick={this.toggleAddIssueModal}>
                                    Add New QA/QC Issue
                                </button>
                            )}
                        </Slider>
                    </ul>
                    <div className="rgt m-0 p-0">
                        {hasCreate && (
                            <div
                                className={`${this.props?.CrBasedRecommendationBtn ? "" : "create-new-btn"} recom-lock-btn ${
                                    this.props.match.params.tab === "qaqc" ? "mt-0" : ""
                                } ${this.props?.CrBasedRecommendationBtn ? "mr-2" : ""}`}
                            >
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className="add-btn">
                                        <span className="edit-icn-bx">
                                            <i className="fas fa-plus pad-bot"></i> Add New {entityName}
                                        </span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            data-tip={`This option will generate a new ${entityName}, a blank form, no data copied over.`}
                                            data-for="regular"
                                            onClick={() => this.props.createNewData(basicDetails, RECOMMENDATION_SOURCE_TYPES.REGULAR)}
                                        >
                                            <ReactTooltip id="regular" place="left" effect="solid" backgroundColor="#007bff" />
                                            Add New {entityName}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            data-for="similar"
                                            data-tip={`This option will generate a new ${entityName}, Similar to the current one, while copying over the following fields:<div class="head-tag mt-2">Geo Hierarchy:</div>${geoHierarchyFields
                                                .map(field => `<div class="sub-head-tag" key="${field}">${field}</div>`)
                                                .join("")}<div class="head-tag mt-2">Recommendation:</div>${similarFields
                                                .map(field => `<div class="sub-head-tag" key="${field}">${field}</div>`)
                                                .join("")}`}
                                            onClick={() => this.props.createNewData(basicDetails, RECOMMENDATION_SOURCE_TYPES.SIMILAR)}
                                        >
                                            <ReactTooltip
                                                id="similar"
                                                place="bottom"
                                                effect="solid"
                                                backgroundColor="#007bff"
                                                html={true}
                                                className="tooltip-re-cm div"
                                            />
                                            Add Similar {entityName}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            data-tip={
                                                isSameOwner
                                                    ? `This option will generate a new ${entityName}, a Duplicate of the current one, while copying over ALL fields, except if any dynamic field value is linked to BBI or BSI.`
                                                    : `This option will generate a new ${entityName}, a Duplicate of the current one, while copying over ALL fields except the inspection date, surveyor field, and any dynamic field value linked to BBI or BSI.`
                                            }
                                            data-for="duplicate"
                                            onClick={() => this.props.createNewData(basicDetails, RECOMMENDATION_SOURCE_TYPES.DUPPLICATE)}
                                        >
                                            <ReactTooltip id="duplicate" place="left" effect="solid" backgroundColor="#007bff" />
                                            Add Duplicate {entityName}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            data-tip={`This option will generate a new ${entityName} while copying over the Geo Hierarchy fields:${geoHierarchyFields
                                                .map(field => `<div class="sub-head-tag" key="${field}">${field}</div>`)
                                                .join("")}`}
                                            data-for="geohierarchy"
                                            onClick={() => this.props.createNewData(basicDetails, RECOMMENDATION_SOURCE_TYPES.GEO_HIERARCHY)}
                                        >
                                            <ReactTooltip
                                                id="geohierarchy"
                                                place="bottom"
                                                effect="solid"
                                                backgroundColor="#007bff"
                                                html="true"
                                                className="tooltip-re-cm"
                                            />
                                            Add Same Geo Hierarchy {entityName}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )}
                        {this.props?.CrBasedRecommendationBtn && (
                            <button
                                className={"add-btn mr-2"}
                                onClick={() => this.props?.createRecommendationFromCr(this.props?.recommendationDetails)}
                            >
                                <i class="fas fa-calendar-plus"></i>Convert to Recommendation
                            </button>
                        )}
                    </div>
                </div>
                {basicDetails && basicDetails ? (
                    <div className="lock-main">
                        {basicDetails && basicDetails.locked === true ? this.lockIcon(basicDetails.locked) : this.unlockIcon(basicDetails.locked)}
                    </div>
                ) : (
                    ""
                )}
                {this.renderAddIssueModal()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { recommendationsReducer } = state;
    return { recommendationsReducer };
};

export default withRouter(connect(mapStateToProps)(InfoTabs));
