import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Loader from "../../common/components/Loader";
import documentsActions from "../../documents/actions";
import dashboardActions from "../../dashboard/actions";
import { connect } from "react-redux";
import Draggable from "react-draggable";
import LoadingOverlay from "react-loading-overlay";
import Portal from "../../common/components/Portal";
import ReportsFullView from "./ReportsFullView";
import ReactTooltip from "react-tooltip";
import { ReportItem } from "./ReportItem";

import LoginPoster from "../../../assets/img/video-thumbnails/login-video-thumb.png";
import ReportPoster from "../../../assets/img/video-thumbnails/report-video-thumb.png";
import DashboardThumb1 from "../../../assets/img/video-thumbnails/DashboardThumb1.png";
import DashboardThumb2 from "../../../assets/img/video-thumbnails/DashboardThumb2.png";
import DashboardThumb3 from "../../../assets/img/video-thumbnails/DashboardThumb3.png";
import DashboardThumb4 from "../../../assets/img/video-thumbnails/DashboardThumb4.png";
import DashboardThumb5 from "../../../assets/img/video-thumbnails/DashboardThumb5.png";
import DashboardThumb6 from "../../../assets/img/video-thumbnails/DashboardThumb6.png";
import DashboardThumb7 from "../../../assets/img/video-thumbnails/DashboardThumb7.png";
import { S3_HOST } from "../../../config/constants";

const tutorialsVideos = [
    {
        id: "1",
        file_name: "Login to Landing Page & Dashboard.mp4",
        description: "Login to Landing Page & Dashboard",
        url: `${S3_HOST}/tutorials/Login.mp4`,
        poster: LoginPoster
    },
    {
        id: "2",
        file_name: "Smart Reports & Charts - Running Reports.mp4",
        description: "Smart Reports & Charts - Running Reports",
        url: `${S3_HOST}/tutorials/Report_Generation.mp4`,
        poster: ReportPoster
    },
    {
        id: "3",
        file_name: "Capital Spending Plan Dashboard - General and Quadrants.mp4",
        description: "Capital Spending Plan Dashboard - General and Quadrants",
        url: `${S3_HOST}/tutorials/Capital_Spending_Plan_Dashboard_General_and_Quadrents.mp4`,
        poster: DashboardThumb1
    },
    {
        id: "4",
        file_name: "Capital Spending Plan Dashboard - Master Filter Line.mp4",
        description: "Capital Spending Plan Dashboard - Master Filter Line",
        url: `${S3_HOST}/tutorials/Capital_Spending_Plan_Dashboard_Master_Filter_Line.mp4`,
        poster: DashboardThumb2
    },
    {
        id: "5",
        file_name: "Capital Spending Plan Dashboard - Statistics Panel.mp4",
        description: "Capital Spending Plan Dashboard - Statistics Panel",
        url: `${S3_HOST}/tutorials/Capital_Spending_Plan_Dashboard_Statistics_Panel.mp4`,
        poster: DashboardThumb3
    },
    {
        id: "6",
        file_name: "Recommendation Table View - Global and Local Filtering.mp4",
        description: "Recommendation Table View - Global and Local Filtering",
        url: `${S3_HOST}/tutorials/Recommendation_Table_View_Global_and_Local_Filtering.mp4`,
        poster: DashboardThumb4
    },
    {
        id: "7",
        file_name: "Recommendation Table View - Sorting and Filtering.mp4",
        description: "Recommendation Table View - Sorting and Filtering",
        url: `${S3_HOST}/tutorials/Recommendation_Table_View_Sorting_and_Filtering.mp4`,
        poster: DashboardThumb5
    },
    {
        id: "8",
        file_name: "Recommendation Table View - Viewing and Editing Recommendations.mp4",
        description: "Recommendation Table View - Viewing and Editing Recommendations",
        url: `${S3_HOST}/tutorials/Recommendation_Table_View_Viewing_and_Editing_Recommendations.mp4`,
        poster: DashboardThumb6
    },
    {
        id: "9",
        file_name: "Recommendation Table View - Export to Excel.mp4",
        description: "Recommendation Table View - Export to Excel",
        url: `${S3_HOST}/tutorials/Recommendation_Table_View_Export_to_Excel.mp4`,
        poster: DashboardThumb7
    }
];
class DeliverableReports extends Component {
    state = {
        isLoading: true,
        params: {
            client_id: this.props.clientId
        },
        showFullViewModal: false,
        selectedReport: {},
        documentList: "",
        sort_type: "description",
        sort: "asc",
        search: ""
    };

    componentDidMount = async () => {
        let clientId = localStorage.getItem("clientId");
        let { doc_id } = this.props;
        let { doc_name } = this.props;
        if (doc_id === "tutorials") {
            this.setState({
                isLoading: false,
                documentList: tutorialsVideos
            });
        } else {
            if (clientId && clientId !== "null") {
                await this.props.getLandingPageReports({ client_id: clientId, document_type_id: doc_id });
            } else {
                await this.props.getLandingPageReports({ document_type_id: doc_id });
            }
            this.setState({
                isLoading: false,
                documentList:
                    this.props.dashboardReducer?.landingPageReport.documents?.sort((a, b) =>
                        a?.description.localeCompare(b?.description, "en", { numeric: true })
                    ) || ""
            });
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.documentList[0]?.file_name !== this.state.documentList[0]?.file_name) {
            ReactTooltip.rebuild();
        }
    };

    viewSingleReport = doc => {
        this.setState({ selectedReport: doc });
        this.toggleFullViewModal();
    };

    toggleFullViewModal = () => {
        this.setState({ showFullViewModal: !this.state.showFullViewModal });
    };

    handleSortBy = async value => {
        await this.setState({ sort_type: value });
        const { sort } = this.state;
        let temp;
        if (value === "file_name") {
            if (sort === "desc") {
                temp = [...this.state.documentList]?.sort((a, b) => b?.file_name.localeCompare(a?.file_name, "en", { numeric: true }));
            } else {
                temp = [...this.state.documentList]?.sort((a, b) => a?.file_name.localeCompare(b?.file_name, "en", { numeric: true }));
            }
        } else if (value === "description") {
            if (sort === "desc") {
                temp = [...this.state.documentList]?.sort((a, b) => b?.description.localeCompare(a?.description, "en", { numeric: true }));
            } else {
                temp = [...this.state.documentList]?.sort((a, b) => a?.description.localeCompare(b?.description, "en", { numeric: true }));
            }
        } else if (value === "identifier") {
            if (sort === "desc") {
                temp = [...this.state.documentList]?.sort((a, b) => b?.identifier.localeCompare(a?.identifier, "en", { numeric: true }));
            } else {
                temp = [...this.state.documentList]?.sort((a, b) => a?.identifier.localeCompare(b?.identifier, "en", { numeric: true }));
            }
        } else {
            if (sort === "desc") {
                temp = [...this.state.documentList]?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));
            } else {
                temp = [...this.state.documentList]?.sort((a, b) => new Date(a?.created_at) - new Date(b?.created_at));
            }
        }
        this.setState({ documentList: temp });
    };

    handleSortOrder = async value => {
        await this.setState({ sort: value });
        const { sort_type } = this.state;
        let temp;
        if (sort_type === "file_name") {
            if (value === "desc") {
                temp = [...this.state.documentList]?.sort((a, b) => b?.file_name.localeCompare(a?.file_name, "en", { numeric: true }));
            } else {
                temp = [...this.state.documentList]?.sort((a, b) => a?.file_name.localeCompare(b?.file_name, "en", { numeric: true }));
            }
        } else if (sort_type === "description") {
            if (value === "desc") {
                temp = [...this.state.documentList]?.sort((a, b) => b?.description.localeCompare(a?.description, "en", { numeric: true }));
            } else {
                temp = [...this.state.documentList]?.sort((a, b) => a?.description.localeCompare(b?.description, "en", { numeric: true }));
            }
        } else if (sort_type === "identifier") {
            if (value === "desc") {
                temp = [...this.state.documentList]?.sort((a, b) => b?.identifier.localeCompare(a?.identifier, "en", { numeric: true }));
            } else {
                temp = [...this.state.documentList]?.sort((a, b) => a?.identifier.localeCompare(b?.identifier, "en", { numeric: true }));
            }
        } else {
            if (value === "desc") {
                temp = [...this.state.documentList]?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));
            } else {
                temp = [...this.state.documentList]?.sort((a, b) => new Date(a?.created_at) - new Date(b?.created_at));
            }
        }

        this.setState({ documentList: temp });
    };

    handleGlobalSearch = value => {
        this.setState({ search: value });
    };

    globalSearchOnSubmit = async (e, reset) => {
        if (e) e.preventDefault();
        this.setState({ isLoading: true });
        const { search } = this.state;
        let { doc_id } = this.props;
        let clientId = localStorage.getItem("clientId");
        let params;
        if (clientId && clientId !== "null") {
            params = { client_id: clientId, document_type_id: doc_id, search };
            if (reset) {
                const { search, ...rest } = params;
                params = rest;
            }
            await this.props.getLandingPageReports(params);
        } else {
            params = { document_type_id: doc_id, search };
            if (reset) {
                const { search, ...rest } = params;
                params = rest;
            }
            await this.props.getLandingPageReports(params);
        }
        this.setState({
            isLoading: false,
            documentList:
                this.props.dashboardReducer?.landingPageReport.documents?.sort((a, b) =>
                    a?.file_name.localeCompare(b?.file_name, "en", { numeric: true })
                ) || ""
        });
    };

    render() {
        const { isLoading, selectedReport, showFullViewModal, search } = this.state;
        let { documentList } = this.state;
        const {
            dashboardReducer: { landingPageReport }
        } = this.props;

        if (this.props.doc_id === "tutorials" && search?.trim()?.length) {
            documentList = documentList?.filter(
                item =>
                    item?.file_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    item?.description?.toLowerCase()?.includes(search?.toLowerCase())
            );
        }
        return (
            <>
                {showFullViewModal && (
                    <Portal
                        body={<ReportsFullView selectedReport={selectedReport} onCancel={this.toggleFullViewModal} />}
                        onCancel={this.toggleFullViewModal}
                    />
                )}

                <div
                    class={`modal slt-img-modl pdf-mini-mod narr`}
                    style={{ display: "block" }}
                    id="Modal-region"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <Draggable handle=".draggable" cancel=".not-draggable" positionOffset={{ x: "0%", y: "0%" }}>
                        <div>
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header draggable">
                                        <div
                                            className={`d-flex col-md-12 pl-0 align-items-center ${
                                                this.props.doc_id === "tutorials" ? "video-dshb-head" : ""
                                            }`}
                                        >
                                            <div className="col-md-4 pl-0">
                                                <h5 class="modal-title" id="exampleModalLabel">
                                                    FCA {this.props.doc_name}
                                                </h5>
                                            </div>
                                            <div className="col-md-8 d-flex align-items-center outer-right-sort not-draggable">
                                                <form className="condition-search-outer" onSubmit={this.globalSearchOnSubmit}>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Search Here"
                                                        onChange={e => {
                                                            const {
                                                                target: { value }
                                                            } = e;
                                                            this.handleGlobalSearch(value);
                                                            if (!value && this.props.doc_id !== "tutorials") this.globalSearchOnSubmit(e, true);
                                                        }}
                                                        value={search}
                                                    />
                                                    {search && (
                                                        <img
                                                            src="/img/close.svg"
                                                            alt=""
                                                            onClick={e => {
                                                                this.setState({ search: "" });
                                                                this.props.doc_id !== "tutorials" && this.globalSearchOnSubmit(e, true);
                                                                e.stopPropagation();
                                                            }}
                                                        />
                                                    )}
                                                </form>
                                                {this.props.doc_id !== "tutorials" && (
                                                    <>
                                                        <div className="sort d-flex align-items-center">
                                                            <label className="label-txt d-flex align-items-center">Sort By</label>
                                                            <div className="frm-area d-flex">
                                                                <select
                                                                    className="form-control"
                                                                    value={this.state.sort_type}
                                                                    onChange={e => this.handleSortBy(e.target.value)}
                                                                >
                                                                    <option value="description">Description</option>
                                                                    <option value="created_at">Uploaded Date</option>
                                                                    <option value="file_name">File Name</option>
                                                                    <option value="identifier">Identifier</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="sort" style={{ marginLeft: "11px" }}>
                                                            <select
                                                                className="form-control"
                                                                value={this.state.sort}
                                                                onChange={e => this.handleSortOrder(e.target.value, "sort")}
                                                            >
                                                                <option value="asc">ASC</option>
                                                                <option value="desc">DESC</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            class="close not-draggable"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => this.props.onCancel()}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <LoadingOverlay active={isLoading} spinner={<Loader />} fadeSpeed={10}>
                                        <div
                                            class={`modal-body region-otr build-type-mod ${
                                                landingPageReport?.documents?.length || this.props.doc_id === "tutorials"
                                                    ? "pdf-mini-modal"
                                                    : "no-data-modal"
                                            } `}
                                        >
                                            <div class="pdf-miniature-otr">
                                                {documentList?.length ? (
                                                    documentList.map(doc => <ReportItem doc={doc} viewSingleReport={this.viewSingleReport} />)
                                                ) : (
                                                    <div className={`coming-soon no-data no-dat col-12`}>
                                                        <div className="coming-soon-img">
                                                            <img src="/img/no-data.svg" alt="" />
                                                        </div>
                                                        <div className="coming-txt">
                                                            <h3>NO DATA FOUND</h3>
                                                            <h4>There is no data to show you right now!!!</h4>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </LoadingOverlay>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    const { dashboardReducer } = state;
    return { dashboardReducer };
};

export default withRouter(
    connect(mapStateToProps, {
        ...documentsActions,
        ...dashboardActions
    })(DeliverableReports)
);
