import React from "react";
import DragM from "dragm";

class BuildModalHeader extends React.Component {
    updateTransform = transformStr => {
        this.modalDom.style.transform = transformStr;
    };
    componentDidMount() {
        const { modalClass } = this.props;
        this.modalDom = document.getElementsByClassName(modalClass)[0];
    }
    render() {
        const {
            title,
            onCancel,
            showData,
            entityData,
            exportPDF,
            exportDoc,
            hasBBIPreview = false,
            isExporting = false,
            isAsset = false
        } = this.props;
        return (
            <DragM updateTransform={this.updateTransform}>
                <div className="modal-header align-items-center">
                    <h5 className="modal-title pr-5" id="exampleModalLabel">
                        {title}
                    </h5>
                    {showData && (
                        <div class="pull-img-recom ml-2">
                            <div className="text-label">
                                {/* {isAsset ? (
                                    <label>Asset: {`[${entityData?.code}] ${entityData?.description || ""}`}</label>
                                ) : (
                                    <label>Recommendation: {`[${entityData?.code}] ${entityData?.description || ""}`}</label>
                                )} */}
                            </div>
                        </div>
                    )}
                    {hasBBIPreview && (
                        <div class="pull-img-recom mr-4">
                            <button
                                class="btn btn-reset-fl-cmn mr-2"
                                data-place="bottom"
                                data-effect="solid"
                                data-tip={`Reset all Filters and Sort`}
                                data-background-color="#007bff"
                                onClick={e => {
                                    e.preventDefault();
                                    exportPDF && exportPDF();
                                }}
                            >
                                Export as PDF
                            </button>
                            <button
                                class="btn btn-reset-fl-cmn"
                                data-place="bottom"
                                data-effect="solid"
                                data-tip={`Reset all Filters and Sort`}
                                data-background-color="#007bff"
                                onClick={e => {
                                    e.preventDefault();
                                    exportDoc && exportDoc();
                                }}
                                disabled={isExporting}
                            >
                                Export as Word
                                {isExporting && <span className="spinner-border spinner-border-sm pl-2 ml-1" role="status"></span>}
                            </button>
                        </div>
                    )}
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onCancel()}>
                        <span aria-hidden="true">
                            <img src="/img/close.svg" alt="" />
                        </span>
                    </button>
                </div>
            </DragM>
        );
    }
}

export default BuildModalHeader;
