// Get all regions
export const GET_ALL_REGIONS_REQUEST = "GET_ALL_REGIONS_REQUEST";
export const GET_ALL_REGIONS_SUCCESS = "GET_ALL_REGIONS_SUCCESS";
export const GET_ALL_REGIONS_FAILURE = "GET_ALL_REGIONS_FAILURE";

// Add Region
export const ADD_REGION_REQUEST = "ADD_REGION_REQUEST";
export const ADD_REGION_SUCCESS = "ADD_REGION_SUCCESS";
export const ADD_REGION_FAILURE = "ADD_REGION_FAILURE";

// Update Region
export const UPDATE_REGION_REQUEST = "UPDATE_REGION_REQUEST";
export const UPDATE_REGION_SUCCESS = "UPDATE_REGION_SUCCESS";
export const UPDATE_REGION_FAILURE = "UPDATE_REGION_FAILURE";

// Delete Region
export const DELETE_REGION_REQUEST = "DELETE_REGION_REQUEST";
export const DELETE_REGION_SUCCESS = "DELETE_REGION_SUCCESS";
export const DELETE_REGION_FAILURE = "DELETE_REGION_FAILURE";

// Get all consultancy users
export const GET_ALL_CONSULTANCY_USERS_REQUEST = "GET_ALL_CONSULTANCY_USERS_REQUEST";
export const GET_ALL_CONSULTANCY_USERS_SUCCESS = "GET_ALL_CONSULTANCY_USERS_SUCCESS";
export const GET_ALL_CONSULTANCY_USERS_FAILURE = "GET_ALL_CONSULTANCY_USERS_FAILURE";

// Get all Clients
export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const GET_ALL_CLIENTS_FAILURE = "GET_ALL_CLIENTS_FAILURE";

// Get Region by Id
export const GET_REGION_BY_ID_REQUEST = "GET_REGION_BY_ID_REQUEST";
export const GET_REGION_BY_ID_SUCCESS = "GET_REGION_BY_ID_SUCCESS";
export const GET_REGION_BY_ID_FAILURE = "GET_REGION_BY_ID_FAILURE";

// Upload Image
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

// Get all Images
export const GET_ALL_IMAGES_REQUEST = "GET_ALL_IMAGES_REQUEST";
export const GET_ALL_IMAGES_SUCCESS = "GET_ALL_IMAGES_SUCCESS";
export const GET_ALL_IMAGES_FAILURE = "GET_ALL_IMAGES_FAILURE";

// Delete Images
export const DELETE_IMAGES_REQUEST = "DELETE_IMAGES_REQUEST";
export const DELETE_IMAGES_SUCCESS = "DELETE_IMAGES_SUCCESS";
export const DELETE_IMAGES_FAILURE = "DELETE_IMAGES_FAILURE";

// Update Comments Images
export const UPDATE_IMAGE_COMMENT_REQUEST = "UPDATE_IMAGE_COMMENT_REQUEST";
export const UPDATE_IMAGE_COMMENT_SUCCESS = "UPDATE_IMAGE_COMMENT_SUCCESS";
export const UPDATE_IMAGE_COMMENT_FAILURE = "UPDATE_IMAGE_COMMENT_FAILURE";

// Update Region Entity Params
export const UPDATE_REGION_ENTITY_PARAMS_SUCCESS = "UPDATE_REGION_ENTITY_PARAMS_SUCCESS";
export const UPDATE_REGION_ENTITY_PARAMS_FAILURE = "UPDATE_REGION_ENTITY_PARAMS_FAILURE";

// Update Comments Images
export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_REGION_EXPORT_REQUEST = "GET_REGION_EXPORT_REQUEST";
export const GET_REGION_EXPORT_SUCCESS = "GET_REGION_EXPORT_SUCCESS";
export const GET_REGION_EXPORT_FAILURE = "GET_REGION_EXPORT_FAILURE";

export const GET_ALL_LOGS_REQUEST = "GET_ALL_LOGS_REQUEST";
export const GET_ALL_LOGS_SUCCESS = "GET_ALL_LOGS_SUCCESS";
export const GET_ALL_LOGS_FAILURE = "GET_ALL_LOGS_FAILURE";

export const RESTORE_LOG_REQUEST = "RESTORE_LOG_REQUEST";
export const RESTORE_LOG_SUCCESS = "RESTORE_LOG_SUCCESS";
export const RESTORE_LOG_FAILURE = "RESTORE_LOG_FAILURE";

export const DELETE_LOG_REQUEST = "DELETE_LOG_REQUEST";
export const DELETE_LOG_SUCCESS = "DELETE_LOG_SUCCESS";
export const DELETE_LOG_FAILURE = "DELETE_LOG_FAILURE";

export const GET_CHART_REGION_REQUEST = "GET_CHART_REGION_REQUEST";
export const GET_CHART_REGION_SUCCESS = "GET_CHART_REGION_SUCCESS";
export const GET_CHART_REGION_FAILURE = "GET_CHART_REGION_FAILURE";

export const GET_PROJECTS_BASED_ON_CLIENT_REQUEST = "GET_PROJECTS_BASED_ON_CLIENT_REQUEST";
export const GET_PROJECTS_BASED_ON_CLIENT_SUCCESS = "GET_PROJECTS_BASED_ON_CLIENT_SUCCESS";
export const GET_PROJECTS_BASED_ON_CLIENT_FAILURE = "GET_PROJECTS_BASED_ON_CLIENT_FAILURE";

export const GET_EFCI_BASED_ON_REGION_REQUEST = "GET_EFCI_BASED_ON_REGION_REQUEST";
export const GET_EFCI_BASED_ON_REGION_SUCCESS = "GET_EFCI_BASED_ON_REGION_SUCCESS";
export const GET_EFCI_BASED_ON_REGION_FAILURE = "GET_EFCI_BASED_ON_REGION_FAILURE";

export const GET_CHART_EFCI_REGION_REQUEST = "GET_CHART_EFCI_REGION_REQUEST";
export const GET_CHART_EFCI_REGION_SUCCESS = "GET_CHART_EFCI_REGION_SUCCESS";
export const GET_CHART_EFCI_REGION_FAILURE = "GET_CHART_EFCI_REGION_FAILURE";

export const SAVE_EFCI_REGION_REQUEST = "SAVE_EFCI_REGION_REQUEST";
export const SAVE_EFCI_REGION_SUCCESS = "SAVE_EFCI_REGION_SUCCESS";
export const SAVE_EFCI_REGION_FAILURE = "SAVE_EFCI_REGION_FAILURE";

export const LOAD_EFCI_REGION_REQUEST = "LOAD_EFCI_REGION_REQUEST";
export const LOAD_EFCI_REGION_SUCCESS = "LOAD_EFCI_REGION_SUCCESS";
export const LOAD_EFCI_REGION_FAILURE = "LOAD_EFCI_REGION_FAILURE";

export const UPDATE_REGION_FUNDING_COST_REQUEST = "UPDATE_REGION_FUNDING_COST_REQUEST";
export const UPDATE_REGION_FUNDING_COST_SUCCESS = "UPDATE_REGION_FUNDING_COST_SUCCESS";
export const UPDATE_REGION_FUNDING_COST_FAILURE = "UPDATE_REGION_FUNDING_COST_FAILURE";

export const UPDATE_REGION_FUNDING_COST_EFCI_REQUEST = "UPDATE_REGION_FUNDING_COST_EFCI_REQUEST";
export const UPDATE_REGION_FUNDING_COST_EFCI_SUCCESS = "UPDATE_REGION_FUNDING_COST_EFCI_SUCCESS";
export const UPDATE_REGION_FUNDING_COST_EFCI_FAILURE = "UPDATE_REGION_FUNDING_COST_EFCI_FAILURE";

export const UPDATE_REGION_ANNUAL_FUNDING_REQUEST = "UPDATE_REGION_ANNUAL_FUNDING_REQUEST";
export const UPDATE_REGION_ANNUAL_FUNDING_SUCCESS = "UPDATE_REGION_ANNUAL_FUNDING_SUCCESS";
export const UPDATE_REGION_ANNUAL_FUNDING_FAILURE = "UPDATE_REGION_ANNUAL_FUNDING_FAILURE";

export const UPDATE_REGION_ANNUAL_EFCI_REQUEST = "UPDATE_REGION_ANNUAL_EFCI_REQUEST";
export const UPDATE_REGION_ANNUAL_EFCI_SUCCESS = "UPDATE_REGION_ANNUAL_EFCI_SUCCESS";
export const UPDATE_REGION_ANNUAL_EFCI_FAILURE = "UPDATE_REGION_ANNUAL_EFCI_FAILURE";

export const UPDATE_REGION_CSP_SUMMARY_REQUEST = "UPDATE_REGION_CSP_SUMMARY_REQUEST";
export const UPDATE_REGION_CSP_SUMMARY_SUCCESS = "UPDATE_REGION_CSP_SUMMARY_SUCCESS";
export const UPDATE_REGION_CSP_SUMMARY_FAILURE = "UPDATE_REGION_CSP_SUMMARY_FAILURE";

export const GET_REGION_CSP_LOG_REQUEST = "GET_REGION_CSP_LOG_REQUEST";
export const GET_REGION_CSP_LOG_SUCCESS = "GET_REGION_CSP_LOG_SUCCESS";
export const GET_REGION_CSP_LOG_FAILURE = "GET_REGION_CSP_LOG_FAILURE";

export const SAVE_CHART_REGION_REQUEST = "SAVE_CHART_REGION_REQUEST";
export const SAVE_CHART_REGION_SUCCESS = "SAVE_CHART_REGION_SUCCESS";
export const SAVE_CHART_REGION_FAILURE = "SAVE_CHART_REGION_FAILURE";

export const GET_ALL_CLIENT_USERS_REQUEST = "GET_ALL_CLIENT_USERS_REQUEST";
export const GET_ALL_CLIENT_USERS_SUCCESS = "GET_ALL_CLIENT_USERS_SUCCESS";
export const GET_ALL_CLIENT_USERS_FAILURE = "GET_ALL_CLIENT_USERS_FAILURE";

export const LOCK_REGION_REQUEST = "LOCK_REGION_REQUEST";
export const LOCK_REGION_SUCCESS = "LOCK_REGION_SUCCESS";
export const LOCK_REGION_FAILURE = "LOCK_REGION_FAILURE";

export const GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST = "GET_ALL_CONSULTANCIES_DROPDOWN_REQUEST";
export const GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS = "GET_ALL_CONSULTANCIES_DROPDOWN_SUCCESS";
export const GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE = "GET_ALL_CONSULTANCIES_DROPDOWN_FAILURE";

export const HIDE_FUNDING_OPTION_CHART_REQUEST = "HIDE_FUNDING_OPTION_CHART_REQUEST";
export const HIDE_FUNDING_OPTION_CHART_SUCCESS = "HIDE_FUNDING_OPTION_CHART_SUCCESS";
export const HIDE_FUNDING_OPTION_CHART_FAILURE = "HIDE_FUNDING_OPTION_CHART_FAILURE";

export const HIDE_FUNDING_OPTION_REQUEST = "HIDE_FUNDING_OPTION_REQUEST";
export const HIDE_FUNDING_OPTION_SUCCESS = "HIDE_FUNDING_OPTION_SUCCESS";
export const HIDE_FUNDING_OPTION_FAILURE = "HIDE_FUNDING_OPTION_FAILURE";
