import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import RecommendationMainDetails from "./InfoPages/RecommendationMainDetails";
import InfoTabs from "../../common/components/InfoTabs";
import InfoImages from "../../common/components/InfoImages1";
import { LOCK_STATUS, RECOMMENDATION_SOURCE_TYPES, entities } from "../../common/constants";
import RecommendationAssetDetails from "../../common/components/RecommendationAssetDetails";
import { addToBreadCrumpData, getTableSliderData, popBreadCrumpRecData } from "../../../config/utils";
import ReactTooltip from "react-tooltip";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../common/components/Loader";
import Documents from "../../documents/index";
import CapitalRequest from "../../capitalRequest/index";
import QaQcBand from "./QaQcBand";
import qs from "query-string";
import Recommendations from "../index";
class RecommendationsInfo extends Component {
    showQaQcBandTab =
        localStorage.getItem("role") === "client_user"
            ? []
            : [
                  {
                      key: "qaqc",
                      name: "QA/QC",
                      path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/qaqc`
                  }
              ];
    state = {
        loading: true,
        isloading: true,
        basicDetails: {
            id: "",
            code: "",
            description: "",
            trade: "",
            system: "",
            sub_system: "",
            building: "",
            floor: "",
            addition: "",
            department: "",
            hospital_name: "",
            category: "",
            room: "",
            asset_name: "",
            asset_tag: "",
            asset_notes: "",
            installed_year: "",
            notes: "",
            note_html: "",
            condition: "",
            priority: "",
            funding: "",
            responsibility: "",
            deffered_maintenance: "",
            future_capital: "",
            inspection_date: "",
            project_total: "",
            surveyor: "",
            surveyor_id: "",
            status: "",
            status_notes: "",
            cr_status: "",
            cr_status_notes: "",
            image: "",
            capital_type_display_name: "",
            capital_type: "",
            initiative: "",
            budget_priority: "",
            infrastructure_request: "",
            serial_number: "",
            priority_elements: [
                { index: "1", element: "" },
                { index: "2", element: "" },
                { index: "3", element: "" },
                { index: "4", element: "" },
                { index: "5", element: "" },
                { index: "6", element: "" },
                { index: "7", element: "" },
                { index: "8", element: "" }
            ],
            priority_elements_data: [],
            maintenance_years: [],
            asset: {},
            recommendation_type: "",
            fmp: "",
            fmp_id: "",
            fmp_project: "",
            fmp_track: "",
            red_line: "",
            criticality: "",
            source: "",
            energy_band: {},
            water_band: {},
            energy_band_show: "no",
            water_band_show: "no",
            locked: false,
            dynamic_fields: {},
            showQaQcBand: !localStorage.getItem("role") === "client_user",
            birth_year: "",
            requestor: "",
            requested_date: ""
        },
        imageList: [],
        infoTabsData: [
            {
                key: "maindetails",
                name: "Main Details",
                path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/maindetails`
            },
            {
                key: "infoimages",
                name: "Images",
                path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/infoimages`
            },
            {
                key: "documents",
                name: "Documents",
                path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/documents`
            },
            ...this.showQaQcBandTab
        ]
    };

    componentDidMount = async () => {
        await this.refreshinfoDetails();
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            if (
                ![prevProps.match.params.tab, this.props.match.params.tab].includes("linked-cr-recommendations") &&
                ![prevProps.match.params.tab, this.props.match.params.tab].includes("linked-recommendations")
            ) {
                const {
                    location: { search = "" }
                } = this.props;
                const currentParams = qs.parse(search);
                this.props.showInfoPage(this.props.match.params.id, null, null, currentParams?.is_cr);
            }
            await this.refreshinfoDetails();
        }
        if (prevProps.match.params.tab !== this.props.match.params.tab) {
            if (this.state.basicDetails?.showQaQcBand) {
                await this.props.getQaQcData({
                    recommendation_id: this.props.match.params.id,
                    order: {
                        [`recommendation_reviews.created_at`]: "desc"
                    }
                });
            }
        }
    };

    setTabs = () => {
        const {
            location: { search = "" }
        } = this.props;
        const isCapitalRequest = qs.parse(search)?.is_cr === "true";
        const isCrUser = localStorage.getItem("infrastructure_request_user") === "yes";

        const showQaQcBandTab =
            localStorage.getItem("role") === "client_user" && !this.state?.basicDetails?.isQaQcReviewerUser
                ? []
                : [
                      {
                          key: "qaqc",
                          name: "QA/QC",
                          showIndicator: true,
                          path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/qaqc`
                      }
                  ];
        if (this.state.basicDetails.recommendation_type === "asset") {
            this.setState({
                infoTabsData: [
                    {
                        key: "maindetails",
                        name: "Recommendation",
                        path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/maindetails`
                    },
                    {
                        key: "assetdetails",
                        name: "Asset Details",
                        path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/assetdetails`
                    },
                    {
                        key: "infoimages",
                        name: "Images",
                        path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/infoimages`
                    },
                    {
                        key: "documents",
                        name: "Documents",
                        path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/documents`
                    },
                    // {
                    //     key: "capitalrequest",
                    //     name: "Capital Request",
                    //     path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/capitalrequest`
                    // },
                    ...showQaQcBandTab
                ]
            });
        } else {
            this.setState({
                infoTabsData: [
                    {
                        key: "maindetails",
                        name: isCapitalRequest ? "Capital Request" : "Recommendation",
                        path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/maindetails${search}`
                    },
                    {
                        key: "infoimages",
                        name: "Images",
                        path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/infoimages${search}`
                    },
                    {
                        key: "documents",
                        name: "Documents",
                        path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/documents${search}`
                    },
                    ...(isCrUser
                        ? []
                        : isCapitalRequest
                        ? [
                              {
                                  key: "linked-recommendations",
                                  name: "Recommendations",
                                  path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/linked-recommendations${search}`
                              }
                          ]
                        : [
                              {
                                  key: "linked-cr-recommendations",
                                  name: "Capital Request",
                                  path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/linked-cr-recommendations${search}`
                              }
                          ]),
                    // {
                    //     key: "capitalrequest",
                    //     name: "Capital Request",
                    //     path: `/recommendations/recommendationsinfo/${this.props.match.params.id}/capitalrequest`
                    // },
                    ...(isCapitalRequest ? [] : showQaQcBandTab)
                ]
            });
        }
    };

    refreshinfoDetails = async () => {
        this.setState({
            loading: true
        });
        let recommendationsData = await this.props.getDataById(this.props.match.params.id);
        let priorityElementsData = await this.props.getPriorityElementDropDownData(recommendationsData.project?.id || "");

        let showQaQcBand = localStorage.getItem("role") !== "client_user";
        let isQaQcReviewerUser = localStorage.getItem("role") === "super_admin";
        if (!isQaQcReviewerUser) {
            const userProjectReviewerData = await this.props.isProjectReviewer({
                project_id: recommendationsData.project?.id || "",
                user_id: localStorage.getItem("userId") || "",
                is_active: true
            });
            isQaQcReviewerUser = userProjectReviewerData;
        }
        showQaQcBand = showQaQcBand || isQaQcReviewerUser;
        if (showQaQcBand) {
            await this.props.getQaQcData({
                recommendation_id: this.props.match.params.id,
                order: {
                    [`recommendation_reviews.created_at`]: "desc"
                }
            });
        }
        this.props.getDynamicFields({ client_id: recommendationsData.client?.id, project_id: recommendationsData.project?.id });
        this.props.getAllImageList(this.props.match.params.id, { limit: 100, offset: 1 });
        if (recommendationsData && recommendationsData.success) {
            this.setState(
                {
                    basicDetails: {
                        id: recommendationsData.id,
                        code: recommendationsData.code,
                        description: recommendationsData.description,
                        trade: recommendationsData.trade,
                        system: recommendationsData.system,
                        sub_system: recommendationsData.sub_system,
                        client: recommendationsData.client,
                        consultancy: recommendationsData.consultancy,
                        region: recommendationsData.region,
                        site: recommendationsData.site,
                        project: recommendationsData.project,
                        building: recommendationsData.building,
                        floor: recommendationsData.floor,
                        addition: recommendationsData.addition,
                        hospital_name: recommendationsData.building.hospital_name,
                        department: recommendationsData.department,
                        category: recommendationsData.category,
                        room: recommendationsData.room,
                        asset_name: recommendationsData.asset_name,
                        asset_tag: recommendationsData.asset_tag,
                        asset_notes: recommendationsData.asset_notes,
                        installed_year: recommendationsData.installed_year,
                        notes: recommendationsData.notes,
                        note_html: recommendationsData.note_html || recommendationsData.notes?.replace(/\n/g, "<br />"),
                        condition: recommendationsData.condition,
                        priority: recommendationsData.priority,
                        funding: recommendationsData.funding,
                        responsibility: recommendationsData.responsibility,
                        deffered_maintenance: recommendationsData.deffered_maintenance,
                        future_capital: recommendationsData.future_capital,
                        inspection_date: recommendationsData.inspection_date,
                        inspection_time: recommendationsData.inspection_time,
                        project_total: recommendationsData.project_total,
                        surveyor: recommendationsData.surveyor,
                        surveyor_id: recommendationsData.surveyor_id,
                        status: recommendationsData.status,
                        status_notes: recommendationsData.status_notes,
                        cr_status: recommendationsData.cr_status,
                        cr_status_notes: recommendationsData.cr_status_notes,
                        initiative: recommendationsData.initiative,
                        capital_type_display_name: recommendationsData.capital_type_display_name,
                        capital_type: recommendationsData.capital_type,
                        priority_elements: recommendationsData.priority_elements,
                        priority_elements_data: [
                            ...priorityElementsData.priority_elements,
                            ...recommendationsData.priority_elements.slice(
                                priorityElementsData.priority_elements.length,
                                recommendationsData.priority_elements.length
                            )
                        ],
                        maintenance_years: recommendationsData.maintenance_years,
                        image: recommendationsData.image,
                        capacity: recommendationsData.capacity,
                        service_life: recommendationsData.service_life,
                        usefull_life_remaining: recommendationsData.usefull_life_remaining,
                        crv: recommendationsData.crv,
                        serial_number: recommendationsData.serial_number,
                        model_number: recommendationsData.model_number,
                        created_at: recommendationsData.created_at,
                        updated_at: recommendationsData.updated_at,
                        area_served: recommendationsData.area_served,
                        funding_source: recommendationsData.funding_source,
                        locked: recommendationsData.lock_status === LOCK_STATUS.LOCKED,
                        deleted: recommendationsData.deleted,
                        essential: recommendationsData.essential,
                        manufacturer: recommendationsData.manufacturer?.name,
                        quantity: recommendationsData.quantity,
                        unit: recommendationsData.unit,
                        cost_per_unit: recommendationsData.cost_per_unit,
                        options_cost: recommendationsData.options_cost,
                        budget_priority: recommendationsData.budget_priority,
                        asset: recommendationsData.asset,
                        recommendation_type: recommendationsData.recommendation_type || "building",
                        fmp: recommendationsData.fmp,
                        fmp_id: recommendationsData.fmp_id,
                        fmp_project: recommendationsData.fmp_project,
                        fmp_track: recommendationsData.fmp_track,
                        infrastructure_request: recommendationsData.infrastructure_request,
                        red_line: recommendationsData.red_line,
                        criticality: recommendationsData.criticality || "-",
                        source: recommendationsData.source || "-",
                        energy_band: recommendationsData.energy_band,
                        water_band: recommendationsData.water_band,
                        energy_band_show: recommendationsData.energy_band_show,
                        water_band_show: recommendationsData.water_band_show,
                        dynamic_fields: recommendationsData?.dynamic_fields,
                        showQaQcBand,
                        isQaQcReviewerUser,
                        birth_year: recommendationsData?.birth_year,
                        requestor: recommendationsData.requestor,
                        requested_date: recommendationsData?.requested_date
                    },
                    isloading: false
                },
                () => this.setTabs()
            );
        }
        this.setState({
            loading: false
        });
        return true;
    };

    uploadImages = async imageData => {
        this.setState({
            isloading: true
        });
        await this.props.uploadImages(imageData);
        await this.refreshinfoDetails();
    };

    deleteImage = async imageId => {
        this.setState({
            loading: true
        });
        await this.props.deleteImages(imageId);
        await this.refreshinfoDetails();
    };

    updateImage = async imageData => {
        this.setState({
            isloading: true
        });
        await this.props.updateRecommendationImageComment(imageData);
        await this.refreshinfoDetails();
    };
    setSelectedImage = async i => {
        const { imageList } = this.state;
        await this.setState({
            selectedImage: { image: imageList[i], index: i }
        });
    };

    handleAssignToRecom = async imgData => {
        let res = await this.props.assignImagesToRecom(imgData);
        await this.refreshinfoDetails();
        return res;
    };
    updateBudgetPriority = async (params, id) => {
        this.props.updateBudget(params, id);
        this.setState({ basicDetails: { ...this.state.basicDetails, budget_priority: params.budget_priority } });
    };
    updateFMP = (params, id) => {
        this.props.updateFMP(params, id);
        this.setState({ basicDetails: { ...this.state.basicDetails, fmp: params.fmp } });
    };
    updateIR = (params, id) => {
        this.props.updateIR(params, id);
        this.setState({ basicDetails: { ...this.state.basicDetails, infrastructure_request: params.infrastructure_request } });
    };
    updateRL = (params, id) => {
        this.props.updateRL(params, id);
        this.setState({ basicDetails: { ...this.state.basicDetails, red_line: params.red_line } });
    };
    handleNext = id => {
        const {
            location: { search = "" },
            match: {
                params: { tab }
            },
            tableData
        } = this.props;
        const { infoTabsData } = this.state;
        let current_index = tableData?.findIndex(item => item.id === id);
        const tableSliderData = getTableSliderData();
        popBreadCrumpRecData();
        addToBreadCrumpData({
            key: "Name",
            name: tableData[current_index + 1].code,
            path: `/recommendations/recommendationsinfo/${tableData[current_index + 1].id}/maindetails${search}`,
            tableSliderData
        });
        addToBreadCrumpData({
            key: "info",
            name: infoTabsData.find(elem => elem.key === tab)?.name,
            path: `/recommendations/recommendationsinfo/${tableData[current_index + 1].id}/${tab}${search}`
        });
        this.props.history.push(`/recommendations/recommendationsinfo/${tableData[current_index + 1].id}/${tab}${search}`);
        this.props.updateSelectedRow(tableData[current_index + 1].id);
    };
    handlePrev = id => {
        const {
            location: { search = "" },
            match: {
                params: { tab }
            },
            tableData
        } = this.props;
        const { infoTabsData } = this.state;
        let current_index = tableData?.findIndex(item => item.id === id);
        const tableSliderData = getTableSliderData();
        popBreadCrumpRecData();
        addToBreadCrumpData({
            key: "Name",
            name: tableData[current_index - 1].code,
            path: `/recommendations/recommendationsinfo/${tableData[current_index - 1].id}/maindetails${search}`,
            tableSliderData
        });
        addToBreadCrumpData({
            key: "info",
            name: infoTabsData.find(elem => elem.key === tab)?.name,
            path: `/recommendations/recommendationsinfo/${tableData[current_index - 1].id}/${tab}${search}`
        });
        this.props.history.push(`/recommendations/recommendationsinfo/${tableData[current_index - 1].id}/${tab}${search}`);
        this.props.updateSelectedRow(tableData[current_index - 1].id);
    };

    lockRecommendation = () => {
        const { basicDetails } = this.state;
        this.setState({
            basicDetails: { ...basicDetails, locked: !basicDetails.locked }
        });
        this.props.lockRecommendation(!basicDetails.locked);
    };

    addClick = (basicDetails, type = RECOMMENDATION_SOURCE_TYPES.REGULAR) => {
        const {
            location: { search = "" }
        } = this.props;
        if (this.props.match.params.id && basicDetails?.project?.id) {
            if (type !== RECOMMENDATION_SOURCE_TYPES.REGULAR) {
                sessionStorage.setItem("currentRecommendationData", JSON.stringify(basicDetails));
            }
            const currentParams = qs.parse(search);
            const newParams = {
                ...currentParams,
                p_id: basicDetails?.project?.id,
                c_id: basicDetails?.client?.id,
                type: type
            };

            const isCapitalRequest = qs.parse(search)?.is_cr === "true";
            const newPath = `/recommendations/add?${qs.stringify(newParams)}`;

            addToBreadCrumpData({
                key: "add",
                name: isCapitalRequest ? `Add Capital Request` : `Add Recommendation`,
                path: newPath
            });

            this.props.history.push(newPath);
        }
    };

    render() {
        const { isLoading } = this.state;
        if (isLoading) {
            return null;
        }

        const {
            keys,
            config,
            match: {
                params: { tab }
            },
            getAllSettingsLogs,
            logData,
            handlePerPageChangeHistory,
            handlePageClickHistory,
            handleGlobalSearchHistory,
            globalSearchKeyHistory,
            handleDeleteLog,
            historyPaginationParams,
            handleRestoreLog,
            historyParams,
            updateLogSortFilters,
            permissions,
            logPermission,
            imageResponse,
            getAllImageList,
            hasDelete,
            hasEdit,
            hasLogView,
            hasLogDelete,
            hasLogRestore,
            hasInfoPage,
            hasCreate,
            downloadPdfReport,
            entity,
            postExport
        } = this.props;

        const { basicDetails, infoTabsData } = this.state;
        const {
            location: { search = "" }
        } = this.props;
        const isCapitalRequest = qs.parse(search)?.is_cr === "true";
        return (
            <LoadingOverlay active={this.state.loading} spinner={<Loader />} fadeSpeed={10}>
                <React.Fragment>
                    <div className="dtl-sec col-md-12">
                        <div className={`tab-dtl region-mng lockset ${tab === "qaqc" ? "qa-band-tab" : ""}`}>
                            <InfoTabs
                                infoTabsData={infoTabsData}
                                basicDetails={basicDetails}
                                lockItem={this.lockRecommendation}
                                createNewData={this.addClick}
                                hasCreate={
                                    hasCreate &&
                                    tab !== "linked-recommendations" &&
                                    basicDetails?.source !== RECOMMENDATION_SOURCE_TYPES.CAPITAL_REQUEST
                                }
                                isRecommendation
                                entityName={isCapitalRequest ? "Capital Request" : "Recommendation"}
                                isQaQcReviewerUser={isCapitalRequest ? false : basicDetails?.isQaQcReviewerUser}
                                qaqcPendingStatus={this.props.qaqcPendingStatus}
                                CrBasedRecommendationBtn={isCapitalRequest && tab === "maindetails"}
                                createRecommendationFromCr={this.props?.createRecommendationFromCr}
                                recommendationDetails={basicDetails}
                            />
                            {tab === "maindetails" ? (
                                <RecommendationMainDetails
                                    keys={keys}
                                    config={config}
                                    basicDetails={basicDetails}
                                    deleteRecommendation={this.props.showDeleteBox}
                                    restoreRecommendation={this.props.showRestoreBox}
                                    getAllSettingsLogs={getAllSettingsLogs}
                                    handlePerPageChangeHistory={handlePerPageChangeHistory}
                                    handlePageClickHistory={handlePageClickHistory}
                                    handleGlobalSearchHistory={handleGlobalSearchHistory}
                                    globalSearchKeyHistory={globalSearchKeyHistory}
                                    logData={logData}
                                    handleDeleteLog={handleDeleteLog}
                                    historyPaginationParams={historyPaginationParams}
                                    HandleRestoreSettingsLog={handleRestoreLog}
                                    refreshinfoDetails={this.refreshinfoDetails}
                                    historyParams={historyParams}
                                    updateLogSortFilters={updateLogSortFilters}
                                    permissions={permissions}
                                    logPermission={logPermission}
                                    hasDelete={hasDelete}
                                    hasEdit={hasEdit}
                                    hasLogView={hasLogView}
                                    hasLogDelete={hasLogDelete}
                                    hasLogRestore={hasLogRestore}
                                    hasInfoPage={hasInfoPage}
                                    downloadPdfReport={downloadPdfReport}
                                    entity={entity}
                                    updateBudget={this.updateBudgetPriority}
                                    updateFMP={this.updateFMP}
                                    updateIR={this.updateIR}
                                    updateRL={this.updateRL}
                                    postExport={postExport}
                                    exportLoader={this.props.exportLoader}
                                    updateImageComment={this.props.updateRecommendationImageComment}
                                    deleteImage={this.deleteImage}
                                    imageResponse={imageResponse}
                                    refreshImageList={() => this.props.getAllImageList(this.props.match.params.id, { limit: 100, offset: 1 })}
                                    isCapitalRequest={isCapitalRequest}
                                />
                            ) : tab === "assetdetails" ? (
                                <RecommendationAssetDetails
                                    keys={keys}
                                    config={config}
                                    basicDetails={basicDetails}
                                    deleteRecommendation={this.props.showDeleteBox}
                                    restoreRecommendation={this.props.showRestoreBox}
                                    getAllSettingsLogs={getAllSettingsLogs}
                                    handlePerPageChangeHistory={handlePerPageChangeHistory}
                                    handlePageClickHistory={handlePageClickHistory}
                                    handleGlobalSearchHistory={handleGlobalSearchHistory}
                                    globalSearchKeyHistory={globalSearchKeyHistory}
                                    logData={logData}
                                    handleDeleteLog={handleDeleteLog}
                                    historyPaginationParams={historyPaginationParams}
                                    HandleRestoreSettingsLog={handleRestoreLog}
                                    refreshinfoDetails={this.refreshinfoDetails}
                                    historyParams={historyParams}
                                    updateLogSortFilters={updateLogSortFilters}
                                    permissions={permissions}
                                    logPermission={logPermission}
                                    hasDelete={hasDelete}
                                    hasEdit={hasEdit}
                                    hasLogView={hasLogView}
                                    hasLogDelete={hasLogDelete}
                                    hasLogRestore={hasLogRestore}
                                    hasInfoPage={hasInfoPage}
                                    downloadPdfReport={downloadPdfReport}
                                    entity={entity}
                                    updateBudget={this.updateBudgetPriority}
                                    updateFMP={this.updateFMP}
                                    updateIR={this.updateIR}
                                    updateRL={this.updateRL}
                                    imageResponse={imageResponse}
                                />
                            ) : tab === "documents" ? (
                                <div className="tab-active recomdn-table bg-grey-table">
                                    <Documents recommendationId={this.props.match.params.id} basicDetails={basicDetails} />
                                </div>
                            ) : tab === "capitalrequest" ? (
                                <div className="tab-active recomdn-table bg-grey-table">
                                    <CapitalRequest isRecommendationView isProjectView projectIdForSelection={basicDetails.project?.id || ""} />
                                </div>
                            ) : tab === "linked-recommendations" && basicDetails?.id ? (
                                <div className="tab-active recomdn-table bg-grey-table">
                                    <Recommendations isCrLinkedRecommendations recommendationDetails={basicDetails} />
                                </div>
                            ) : tab === "linked-cr-recommendations" ? (
                                <div className="tab-active recomdn-table bg-grey-table">
                                    <Recommendations isCapitalRequest recommendationDetails={basicDetails} />
                                </div>
                            ) : tab === "qaqc" ? (
                                <div className="tab-active recomdn-table ">
                                    <QaQcBand
                                        showData={this.state.basicDetails?.showQaQcBand}
                                        recommendationId={this.props.match.params.id}
                                        basicDetails={basicDetails}
                                        isQaQcReviewerUser={this.state.basicDetails?.isQaQcReviewerUser}
                                        refreshinfoDetails={this.refreshinfoDetails}
                                    />
                                </div>
                            ) : tab === "infoimages" ? (
                                <InfoImages
                                    setSelectedImage={this.setSelectedImage}
                                    selectedImage={this.state.selectedImage}
                                    uploadImages={this.uploadImages}
                                    imageList={this.state.imageList}
                                    deleteImage={this.deleteImage}
                                    updateImage={this.updateImage}
                                    refreshinfoDetails={this.refreshinfoDetails}
                                    locked={basicDetails.locked}
                                    isBuildingLocked={this.props.isBuildingLocked}
                                    isDeleted={basicDetails.deleted}
                                    permissions={permissions}
                                    imageResponse={imageResponse}
                                    getAllImageList={getAllImageList}
                                    updateImageComment={this.props.updateRecommendationImageComment}
                                    deleteImages={this.props.deleteImages}
                                    hasCreate={hasCreate}
                                    hasEdit={hasEdit}
                                    hasPullFromMasterImages={true}
                                    handleAssignImagesFromMaster={this.handleAssignToRecom}
                                    entity={entities.RECOMMENDATIONS}
                                    basicDetails={basicDetails}
                                    hasDelete={hasDelete}
                                />
                            ) : null}
                            <div className="button-slide-outer">
                                <button
                                    className={`prev-btn ${
                                        !this.props.tableData?.length ||
                                        (this.props.tableData && this.state.basicDetails?.id === this.props.tableData[0]?.id)
                                            ? "cursor-diabled"
                                            : ""
                                    }`}
                                    disabled={
                                        !this.props.tableData?.length ||
                                        (this.props.tableData && this.state.basicDetails?.id === this.props.tableData[0]?.id)
                                    }
                                    onClick={() => this.handlePrev(basicDetails.id)}
                                    data-place="top"
                                    data-effect="solid"
                                    data-background-color="#007bff"
                                    data-tip="Previous"
                                    data-for="prv-nxt"
                                >
                                    <i class="fas fa-chevron-left"></i>
                                </button>
                                <button
                                    className={`next-btn ${
                                        !this.props.tableData?.length ||
                                        (this.props.tableData &&
                                            this.state.basicDetails?.id === this.props.tableData[this.props.tableData?.length - 1]?.id)
                                            ? "cursor-diabled"
                                            : ""
                                    }`}
                                    disabled={
                                        !this.props.tableData?.length ||
                                        (this.props.tableData &&
                                            this.state.basicDetails?.id === this.props.tableData[this.props.tableData?.length - 1]?.id)
                                    }
                                    onClick={() => this.handleNext(basicDetails.id)}
                                    data-place="top"
                                    data-effect="solid"
                                    data-background-color="#007bff"
                                    data-tip="Next"
                                    data-for="prv-nxt"
                                >
                                    <i class="fas fa-chevron-right"></i>
                                </button>
                                <ReactTooltip id="prv-nxt" />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </LoadingOverlay>
        );
    }
}

export default withRouter(RecommendationsInfo);
