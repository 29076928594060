export const editorConfiguration = {
    toolbar: [
        "bold",
        "italic",
        "underLine",
        "|",
        "alignment:left",
        "alignment:right",
        "alignment:center",
        "alignment:justify",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "highlight",
        "|",
        "undo",
        "redo"
    ],
    removePlugins: ["Title", "ListStyle"],
    alignment: {
        options: ["left", "right", "center", "justify"]
    },
    highlight: {
        options: [
            {
                model: "yellowMarker",
                class: "marker-yellow",
                title: "Yellow marker",
                color: "var(--ck-highlight-marker-yellow)",
                type: "marker"
            },
            {
                model: "greenMarker",
                class: "marker-green",
                title: "Green marker",
                color: "#32CD32",
                type: "marker"
            },
            {
                model: "pinkMarker",
                class: "marker-pink",
                title: "Pink marker",
                color: "#FF00FF",
                type: "marker"
            },
            {
                model: "blueMarker",
                class: "marker-blue",
                title: "Blue marker",
                color: "#0000FF",
                type: "marker"
            }
        ]
    },
    placeholder: "Type Here..."
};
