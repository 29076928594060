import React from "react";
import ReactTooltip from "react-tooltip";
export const RadioBox = ({ label, name, value, handleChange, showErrorBorder, isDisabled = false, col = 3, dataTip, optionsList = [] }) => {
    const onChange = e => {
        let { value: newValue } = e.target;
        newValue = newValue === "true";
        handleChange(name, value === newValue ? "" : newValue);
    };
    return (
        <div className={`col-md-${col} basic-box`}>
            <ReactTooltip id="input_field" effect="solid" backgroundColor="#007bff" place="top" />
            <div className="codeOtr" data-tip={dataTip} data-for="input_field">
                <h4>{label}</h4>
                <div class={`flex-otr-check ${showErrorBorder ? "error-radio" : ""}`}>
                    <label class="container-check" for={"yes-" + name}>
                        <span>Yes</span>
                        <input type="checkbox" checked={value === true} onChange={onChange} id={"yes-" + name} name={"yes-" + name} value="true" />
                        <span class="checkmark"></span>
                    </label>
                    <label class="container-check" for={"no-" + name}>
                        <span>No</span>
                        <input type="checkbox" checked={value === false} onChange={onChange} id={"no-" + name} name={"no-" + name} value="false" />
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
        </div>
    );
};
