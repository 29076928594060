export const getAppliedFiltersCount = filterParams => {
    let filteredCount = 0;
    if (
        (filterParams && Object.keys(filterParams)?.length) ||
        (filterParams?.exclude_filters && Object.keys(filterParams?.exclude_filters)?.length)
    ) {
        filteredCount = Object.entries(filterParams || filterParams?.exclude_filters)?.filter(([key, value]) => value?.length)?.length || 0;
        filteredCount +=
            (filterParams?.exclude_filters && Object.entries(filterParams?.exclude_filters)?.filter(([key, value]) => value?.length)?.length) || 0;
        if (filterParams?.start_year || filterParams?.end_year) {
            filteredCount += 1;
        }
        if (filterParams?.year) {
            filteredCount += 1;
        }
        if (filterParams.filters && Object.keys(filterParams.filters)?.length) {
            Object.values(filterParams.filters).forEach(value => {
                if (value.key || value.filters?.includes("null") || value.filters?.includes("not_null")) {
                    filteredCount += 1;
                }
            });
        }
        if (filterParams.top_n && Object.keys(filterParams.top_n)?.length) {
            Object.values(filterParams.top_n).forEach(value => {
                if (value) {
                    filteredCount += 1;
                }
            });
        }
    }
    return filteredCount;
};

export const getAppliedWildCardFilterCount = filter => {
    let filterCount = 0;
    if (filter && Object.keys(filter)?.length) {
        Object.values(filter).forEach(value => {
            if (value.key || value.filters?.includes("null") || value.filters?.includes("not_null")) {
                filterCount += 1;
            }
        });
    }
    return filterCount;
};

export const getAppliedListFilterCount = filter => {
    let filterCount = 0;
    const processFilter = obj => {
        if (obj && typeof obj === "object") {
            Object.values(obj).forEach(value => {
                if (Array.isArray(value) && value?.length > 0) {
                    filterCount += 1;
                } else if (typeof value === "object") {
                    // Recursive call for nested objects
                    processFilter(value);
                }
            });
        }
    };
    processFilter(filter);
    return filterCount;
};

export const flattenObject = (obj, prefix = "") => {
    let result = {};
    for (let key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
            const nestedKeys = flattenObject(obj[key], prefix + key + ".");
            result = { ...result, ...nestedKeys };
        } else if (Array.isArray(obj[key])) {
            result[prefix + key] = obj[key];
        } else {
            result[prefix + key] = obj[key];
        }
    }
    return result;
};
