import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../images/actions";
import Draggable from "react-draggable";

const ImageCaptionEditModal = ({ onClose, selectedImages, refreshImageList, handleClearAll }) => {
    const [caption, setCaption] = useState("");
    const [error, setError] = useState("");
    const [isChecked, setIsChecked] = useState(false);

    const updateImageRef = useRef(null);
    const textareaRef = useRef(null);

    const { imageUpdateGlobalResponse } = useSelector(state => state.imageReducer);

    const dispatch = useDispatch();
    const handleCheckboxChange = event => {
        setIsChecked(event.target.checked);
    };

    const handleCaptionChange = e => {
        setError("");

        setCaption(e.target.value);
    };

    const selectedImagesIds = selectedImages?.map(({ id }) => id);

    const onUpdateCaption = () => {
        if (caption.trim() === "") {
            setError("Caption is required.");
            return;
        }

        setError("");
        dispatch(actions.updateImageGlobal({ image_ids: selectedImagesIds, description: caption, update_global_caption: isChecked }));
        updateImageRef.current = true;
    };

    const getCommonDescription = () => {
        const descriptions = selectedImages?.map(({ description }) => description);
        const uniqueDescriptions = [...new Set(descriptions)];
        return uniqueDescriptions?.length === 1 ? uniqueDescriptions[0] : "";
    };

    useEffect(() => {
        const commonDescription = getCommonDescription();
        setCaption(commonDescription);
    }, [selectedImages]);

    useEffect(() => {
        if (caption) {
            textareaRef.current.focus();
        }
    }, [caption]);

    useEffect(() => {
        if (imageUpdateGlobalResponse.success && updateImageRef?.current) {
            alertFunction("Image Caption Updated Successfully");
            updateImageRef.current = false;
            onClose();
            refreshImageList();
            handleClearAll();
        } else if (imageUpdateGlobalResponse?.error && updateImageRef?.current) {
            alertFunction("Oops, something went wrong!");
            updateImageRef.current = false;
        }
    }, [imageUpdateGlobalResponse]);

    const alertFunction = message => {
        if (message !== "") {
            const alertElement = document.getElementById("sucess-alert");
            if (alertElement) {
                alertElement.className = "show";
                alertElement.innerText = message;
                setTimeout(() => {
                    alertElement.className = alertElement.className.replace("show", "");
                }, 3000);
            }
        }
    };

    return (
        <div id="modalId" className="modal modal-region modal-img-magamnt modal-caption" style={{ display: "block" }}>
            <Draggable cancel=".not-draggable" handle=".draggable">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close not-draggable" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                            <span aria-hidden="true">
                                <img src="/img/close.svg" alt="" />
                            </span>
                        </button>
                        <div className="modal-header draggable">
                            <div class="heading position-relative">
                                <h5 className="modal-title"> Edit Caption</h5>{" "}
                            </div>
                        </div>

                        <div className="modal-body region-otr core-rpt-dt-mod sm-chart-upload cursor-default">
                            <div className="col-md-12 main-sec">
                                <span className="sel-chart-badge"> Selected Images : {selectedImages?.length || "-"}</span>
                                <div className="form-row">
                                    <div className="form-group col-12 mb-4">
                                        <label>Caption</label>
                                        <textarea
                                            ref={textareaRef}
                                            className={` ${error ? "error-border" : ""} form-control textarea`}
                                            value={caption}
                                            onChange={handleCaptionChange}
                                        ></textarea>
                                    </div>
                                    {error && <span className="errorMessage mb-3">{error}</span>}
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-12 mb-4">
                                        <label class="container-check checkmark-caption ">
                                            <input className="cursor-pointer" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />{" "}
                                            <span class="checkmark checkmark-top"></span> If checked, the global caption will be updated
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="btnOtr">
                                <button type="button" className="btn btn-secondary btnClr not-draggable" onClick={onClose}>
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    disabled={imageUpdateGlobalResponse?.isLoading}
                                    className="btn btn-primary btnRgion ml-2"
                                    onClick={onUpdateCaption}
                                >
                                    {imageUpdateGlobalResponse?.isLoading ? (
                                        <div className="spinner-border text-white spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        "Update"
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    );
};

export default ImageCaptionEditModal;
