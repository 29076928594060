import * as actionType from "./constants";

const initialState = {
    getResponsibilityDataResponse: null,
    addResponsibilityResponse: null,
    getResponsibilityByIdResponse: null,
    updateResponsibilityResponse: null,
    deleteResponsibilityResponse: null,
    getListForResponsibilityResponse: null,
    getAllResponsibilityLogsResponse: null,
    restoreResponsibilityLogResponse: null,
    deleteResponsibilityLogResponse: null,
    responsibilityExportResponse: null,
    entityParams: {
        entity: null,
        selectedEntity: null,
        selectedRowId: null,
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 0,
            search: "",
            filters: null,
            list: null
        },
        wildCardFilterParams: {},
        filterParams: {},
        tableConfig: null,
        historyPaginationParams: {
            totalPages: 0,
            perPage: 40,
            currentPage: 0,
            totalCount: 0
        },
        historyParams: {
            limit: 40,
            offset: 0,
            search: ""
        }
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_RESPONSIBILITY_ENTITY_PARAMS_SUCCESS:
            return {
                ...state,
                entityParams: { ...action.response }
            };
        case actionType.UPDATE_RESPONSIBILITY_ENTITY_PARAMS_FAILURE:
            return {
                ...state,
                entityParams: { ...action.error }
            };
        case actionType.GET_LIST_FOR_RESPONSIBILITY_FILTER_REQUEST:
            return {
                ...state
            };
        case actionType.GET_LIST_FOR_RESPONSIBILITY_FILTER_SUCCESS:
            return {
                ...state,
                getListForResponsibilityResponse: { success: true, ...action.response }
            };
        case actionType.GET_LIST_FOR_RESPONSIBILITY_FILTER_FAILURE:
            return {
                ...state,
                getListForRESPONSIBILITYFilterResponse: { success: false, ...action.error }
            };
        case actionType.GET_RESPONSIBILITY_DATA_REQUEST:
            return {
                ...state
            };
        case actionType.GET_RESPONSIBILITY_DATA_SUCCESS:
            return {
                ...state,
                getResponsibilityDataResponse: { success: true, ...action.response }
            };
        case actionType.GET_RESPONSIBILITY_DATA_FAILURE:
            return {
                ...state,
                getResponsibilityDataResponse: { success: false, ...action.error }
            };
        case actionType.ADD_RESPONSIBILITY_REQUEST:
            return {
                ...state
            };
        case actionType.ADD_RESPONSIBILITY_SUCCESS:
            return {
                ...state,
                addResponsibilityResponse: { success: true, ...action.response }
            };
        case actionType.ADD_RESPONSIBILITY_FAILURE:
            return {
                ...state,
                addResponsibilityResponse: { success: false, ...action.error }
            };
        case actionType.GET_RESPONSIBILITY_BY_ID_REQUEST:
            return {
                ...state
            };
        case actionType.GET_RESPONSIBILITY_BY_ID_SUCCESS:
            return {
                ...state,
                getResponsibilityByIdResponse: { success: true, ...action.response }
            };
        case actionType.GET_RESPONSIBILITY_BY_ID_FAILURE:
            return {
                ...state,
                getResponsibilityByIdResponse: { success: false, ...action.error }
            };
        case actionType.UPDATE_RESPONSIBILITY_REQUEST:
            return {
                ...state
            };
        case actionType.UPDATE_RESPONSIBILITY_SUCCESS:
            return {
                ...state,
                updateResponsibilityResponse: { success: true, ...action.response }
            };
        case actionType.UPDATE_RESPONSIBILITY_FAILURE:
            return {
                ...state,
                updateResponsibilityResponse: { success: false, ...action.error }
            };
        case actionType.DELETE_RESPONSIBILITY_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_RESPONSIBILITY_SUCCESS:
            return {
                ...state,
                deleteResponsibilityResponse: { success: true, ...action.response }
            };
        case actionType.DELETE_RESPONSIBILITY_FAILURE:
            return {
                ...state,
                deleteResponsibilityResponse: { success: false, ...action.error }
            };
        case actionType.GET_ALL_RESPONSIBILITY_LOG_REQUEST:
            return {
                ...state
            };
        case actionType.GET_ALL_RESPONSIBILITY_LOG_SUCCESS:
            return {
                ...state,
                getAllResponsibilityLogsResponse: { success: true, ...action.response }
            };
        case actionType.GET_ALL_RESPONSIBILITY_LOG_FAILURE:
            return {
                ...state,
                getAllResponsibilityLogsResponse: { success: false, ...action.error }
            };
        case actionType.RESTORE_RESPONSIBILITY_LOG_REQUEST:
            return {
                ...state
            };
        case actionType.RESTORE_RESPONSIBILITY_LOG_SUCCESS:
            return {
                ...state,
                restoreResponsibilityLogResponse: { success: true, ...action.response }
            };
        case actionType.RESTORE_RESPONSIBILITY_LOG_FAILURE:
            return {
                ...state,
                restoreResponsibilityLogResponse: { success: false, ...action.error }
            };

        case actionType.DELETE_RESPONSIBILITY_LOG_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_RESPONSIBILITY_LOG_SUCCESS:
            return {
                ...state,
                deleteResponsibilityLogResponse: { success: true, ...action.response }
            };
        case actionType.DELETE_RESPONSIBILITY_LOG_FAILURE:
            return {
                ...state,
                deleteResponsibilityLogResponse: { success: false, ...action.error }
            };
        case actionType.GET_RESPONSIBILITY_EXPORT_REQUEST:
            return {
                ...state
            };
        case actionType.GET_RESPONSIBILITY_EXPORT_SUCCESS:
            return {
                ...state,
                responsibilityExportResponse: { success: true, ...action.response }
            };
        case actionType.GET_RESPONSIBILITY_EXPORT_FAILURE:
            return {
                ...state,
                responsibilityExportResponse: { success: false, ...action.error }
            };

        default:
            return state;
    }
};
