// Get all initiatives
export const GET_ALL_INITIATIVES_REQUEST = "GET_ALL_INITIATIVES_REQUEST";
export const GET_ALL_INITIATIVES_SUCCESS = "GET_ALL_INITIATIVES_SUCCESS";
export const GET_ALL_INITIATIVES_FAILURE = "GET_ALL_INITIATIVES_FAILURE";

export const UPDATE_INITIATIVE_ENTITY_PARAMS_SUCCESS = "UPDATE_INITIATIVE_ENTITY_PARAMS_SUCCESS"
export const UPDATE_INITIATIVE_ENTITY_PARAMS_FAILURE = "UPDATE_INITIATIVE_ENTITY_PARAMS_FAILURE"

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_INITIATIVE_EXPORT_REQUEST = "GET_INITIATIVE_EXPORT_REQUEST";
export const GET_INITIATIVE_EXPORT_SUCCESS = "GET_INITIATIVE_EXPORT_SUCCESS";
export const GET_INITIATIVE_EXPORT_FAILURE = "GET_INITIATIVE_EXPORT_FAILURE";

export const GET_ALL_PROJECT_DROPDOWN_REQUEST = "GET_ALL_PROJECT_DROPDOWN_REQUEST";
export const GET_ALL_PROJECT_DROPDOWN_SUCCESS = "GET_ALL_PROJECT_DROPDOWN_SUCCESS";
export const GET_ALL_PROJECT_DROPDOWN_FAILURE = "GET_ALL_PROJECT_DROPDOWN_FAILURE";

export const ADD_INITIATIVES_REQUEST = "ADD_INITIATIVES_REQUEST";
export const ADD_INITIATIVES_SUCCESS = "ADD_INITIATIVES_SUCCESS";
export const ADD_INITIATIVES_FAILURE = "ADD_INITIATIVES_FAILURE";

export const UPDATE_INITIATIVES_REQUEST = "UPDATE_INITIATIVES_REQUEST";
export const UPDATE_INITIATIVES_SUCCESS = "UPDATE_INITIATIVES_SUCCESS";
export const UPDATE_INITIATIVES_FAILURE = "UPDATE_INITIATIVES_FAILURE";

export const DELETE_INITIATIVES_REQUEST = "DELETE_INITIATIVES_REQUEST";
export const DELETE_INITIATIVES_SUCCESS = "DELETE_INITIATIVES_SUCCESS";
export const DELETE_INITIATIVES_FAILURE = "DELETE_INITIATIVES_FAILURE";

export const GET_INITIATIVE_REQUEST = "GET_INITIATIVE_REQUEST";
export const GET_INITIATIVE_SUCCESS = "GET_INITIATIVE_SUCCESS";
export const GET_INITIATIVE_FAILURE = "GET_INITIATIVE_FAILURE";

export const GET_INITIATIVE_LOG_REQUEST = "GET_INITIATIVE_LOG_REQUEST";
export const GET_INITIATIVE_LOG_SUCCESS = "GET_INITIATIVE_LOG_SUCCESS";
export const GET_INITIATIVE_LOG_FAILURE = "GET_INITIATIVE_LOG_FAILURE";

export const DELETE_INITIATIVE_LOG_REQUEST = "DELETE_INITIATIVE_LOG_REQUEST";
export const DELETE_INITIATIVE_LOG_SUCCESS = "DELETE_INITIATIVE_LOG_SUCCESS";
export const DELETE_INITIATIVE_LOG_FAILURE = "DELETE_INITIATIVE_LOG_FAILURE";

export const RESTORE_INITIATIVE_LOG_REQUEST = "RESTORE_INITIATIVE_LOG_REQUEST";
export const RESTORE_INITIATIVE_LOG_SUCCESS = "RESTORE_INITIATIVE_LOG_SUCCESS";
export const RESTORE_INITIATIVE_LOG_FAILURE = "RESTORE_INITIATIVE_LOG_FAILURE";

export const ASSIGN_INITIATIVE_REQUEST = "ASSIGN_INITIATIVE_REQUEST";
export const ASSIGN_INITIATIVE_SUCCESS = "ASSIGN_INITIATIVE_SUCCESS";
export const ASSIGN_INITIATIVE_FAILURE = "ASSIGN_INITIATIVE_FAILURE";

export const UN_ASSIGN_INITIATIVE_REQUEST = "UN_ASSIGN_INITIATIVE_REQUEST";
export const UN_ASSIGN_INITIATIVE_SUCCESS = "UN_ASSIGN_INITIATIVE_SUCCESS";
export const UN_ASSIGN_INITIATIVE_FAILURE = "UN_ASSIGN_INITIATIVE_FAILURE";