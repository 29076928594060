import React, { useState, useEffect, useRef } from "react";
import _, { property } from "lodash";
import { v4 as uuid } from "uuid";
import Draggable from "react-draggable";

import { CA_GEO_HIERARCHY_ELEMENTS, CA_RECOMMENDATION_ELEMENTS } from "../constants";
import Portal from "../../common/components/Portal";
import { CAColumnConfigModal } from "./CaBand/ColumnConfigModal";
import { showAlert } from "../../../config/utils";
import actions from "../actions";
import { useDispatch, useSelector } from "react-redux";
const AddCAbandModal = ({
    onCancel,
    setSmartChartData,
    entity,
    getCAbandFilterDropDownData,
    getCAbandTradeFilterDropDownData,
    getCAbandDropdownData = [],
    getCAbandTradeDropdownData = [],
    entityMfilters = {},
    currentData = {},
    selectedConfig = null,
    getCAbandSystemFilterDropDownData,
    getCAbandSystemDropdownData = [],
    selectedClient,
    selectedProject,
    isExcelExport = false,
    mfilter = {}
}) => {
    const dispatch = useDispatch();
    const { caColumns } = useSelector(state => state.smartChartReducer);
    const [selectedElements, setSelectedElements] = useState(() => {
        if (selectedConfig?.chartKey?.includes("fci_benchmarking")) {
            const processedChartKey = selectedConfig?.chartKey?.split("_")?.slice(0, 2).join("_");

            return {
                line: processedChartKey || "",
                column: selectedConfig?.chartKey?.split("_")?.[2] || ""
            };
        } else {
            return {
                line: selectedConfig?.chartKey?.split("_")?.[0] || "",
                column: selectedConfig?.chartKey?.split("_")?.[1] || ""
            };
        }
    });
    const [showErrorBorder, setShowErrorBorder] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const [selectedFilterValues, setSelectedFilterValues] = useState([]);
    const [selectedFilterName, setSelectedFilterName] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [filterLabel, setFilterLabel] = useState(null);
    const initialElements = useRef("");
    const [isSwitchBandActive, setIsSwitchBandActive] = useState(false);
    const [showTradeDropDown, setShowTradeDropDown] = useState(false);
    const [searchValueTrade, setSearchValueTrade] = useState("");
    const [selectedFilterValuesTrade, setSelectedFilterValuesTrade] = useState(
        (selectedConfig?.chartKey?.includes("system") ||
            selectedConfig?.chartKey?.includes("subsystem") ||
            selectedConfig?.chartKey?.includes("trade")) &&
            currentData[selectedConfig.chartKey]?.trade_ids?.length
            ? [...currentData[selectedConfig.chartKey]?.trade_ids]
            : []
    );
    const [selectedFilterTrade, setSelectedFilterTrade] = useState(
        (selectedConfig?.chartKey?.includes("system") ||
            selectedConfig?.chartKey?.includes("subsystem") ||
            selectedConfig?.chartKey?.includes("trade")) &&
            currentData[selectedConfig.chartKey]?.config_filter_names?.CA_BandFilter?.Trade?.length
            ? [...currentData[selectedConfig.chartKey]?.config_filter_names?.CA_BandFilter?.Trade]
            : []
    );
    const [showSystemDropDown, setShowSystemDropDown] = useState(false);
    const [searchValueSystem, setSearchValueSystem] = useState("");
    const [selectedFilterValuesSystem, setSelectedFilterValuesSystem] = useState(
        selectedConfig?.chartKey?.includes("subsystem") && currentData[selectedConfig.chartKey]?.system_ids?.length
            ? [...currentData[selectedConfig.chartKey]?.system_ids]
            : []
    );
    const [selectedFilterSystem, setSelectedFilterSystem] = useState(
        selectedConfig?.chartKey?.includes("subsystem") && currentData[selectedConfig.chartKey]?.config_filter_names?.CA_BandFilter?.System?.length
            ? [...currentData[selectedConfig.chartKey]?.config_filter_names?.CA_BandFilter?.System]
            : []
    );
    let isEdit = selectedConfig ? true : false;
    const [selectedBreakdowns, setSelectedBreakdowns] = useState(currentData?.[selectedConfig?.chartKey]?.grouping || {});
    const filterRefs = {};
    const [showColumnConfigModal, setColumnConfigModal] = useState(false);
    const [selectedFields, setSelectedFields] = useState(currentData?.[selectedConfig?.chartKey]?.config?.fields || {});
    const [selectedTemplate, setSelectedTemplate] = useState(currentData?.[selectedConfig?.chartKey]?.config?.template || "");
    const [selectedProperty, setSelectedProperty] = useState(currentData?.[selectedConfig?.chartKey]?.config?.property || "");
    const [pivotTable, setPivotTable] = useState(currentData?.[selectedConfig?.chartKey]?.config?.is_pivot_table || false);
    const [dropdowns, setDropdowns] = useState(getCAbandDropdownData);
    const [systemDropdown, setSystemDropdown] = useState(getCAbandSystemDropdownData);
    const [tradeDropdown, setTradeDropdown] = useState(getCAbandTradeDropdownData);
    const [systemData, setSystemData] = useState([]);
    const [dataValue, setData] = useState([]);
    const [tradeData, setTradeData] = useState([]);
    const [systemDataName, setSystemDataName] = useState([]);
    const [dataName, setDataName] = useState([]);
    const [tradeDataName, setTradeDataName] = useState([]);
    useEffect(() => {
        let FilterData = [];
        let selectedFilterEntity = CA_RECOMMENDATION_ELEMENTS.find(fi => fi.key == selectedElements.line);
        if (selectedFilterEntity) {
            FilterData = currentData?.[selectedConfig?.chartKey]?.[selectedFilterEntity?.paramKey]
                ? [...currentData?.[selectedConfig?.chartKey][selectedFilterEntity.paramKey]]
                : [];
        }
        if (selectedElements[isSwitchBandActive ? "column" : "line"] === "subsystem") {
            getCAbandTradeFilterDropDownData("trades", {
                ...getParamsForMasterFilter("trade"),
                system_id: [...selectedFilterValuesSystem],
                sub_system_id: [...FilterData]
            });
        } else if (selectedElements[isSwitchBandActive ? "column" : "line"] === "system") {
            getCAbandTradeFilterDropDownData("trades", {
                ...getParamsForMasterFilter("trade"),
                system_id: [...FilterData]
            });
        } else {
            getCAbandTradeFilterDropDownData("trades", getParamsForMasterFilter("trade"));
        }
        if (selectedConfig?.chartKey && currentData?.[selectedConfig.chartKey]) {
            if (selectedConfig?.chartKey?.includes("subsystem")) {
                getCAbandSystemFilterDropDownData("system", {
                    ...getParamsForMasterFilter("system"),
                    sub_system_id: FilterData?.length > 0 ? [...FilterData] : mfilter?.sub_system_ids,
                    trade_id: selectedFilterValuesTrade?.length > 0 ? [...selectedFilterValuesTrade] : mfilter?.trade_ids
                });
            }
            let isSwitched = currentData?.[selectedConfig.chartKey]?.reverse_cmp_analysis || false;
            if (isSwitched) {
                switchBandElements();
            }
            let selectedFilterEntity = CA_RECOMMENDATION_ELEMENTS.find(fi => fi.key == selectedElements.line);
            if (selectedFilterEntity) {
                let tempSelectedFilterValues = currentData[selectedConfig.chartKey]?.[selectedFilterEntity?.paramKey]
                    ? [...currentData[selectedConfig.chartKey][selectedFilterEntity.paramKey]]
                    : [];
                initialElements.current = selectedConfig?.chartKey;
                if (selectedFilterEntity.hasFilter) {
                    if (selectedConfig?.chartKey?.includes("subsystem")) {
                        getCAbandFilterDropDownData("subsystem", {
                            ...getParamsForMasterFilter("subsystem"),
                            system_id: selectedFilterValuesSystem?.length > 0 ? [...selectedFilterValuesSystem] : mfilter?.system_ids,
                            trade_id: selectedFilterValuesTrade?.length > 0 ? [...selectedFilterValuesTrade] : mfilter?.trade_ids
                        });
                    } else {
                        getCAbandFilterDropDownData(selectedFilterEntity.key, getParamsForMasterFilter(selectedFilterEntity.key));
                    }
                    setSelectedFilterValues(tempSelectedFilterValues);
                    setSelectedFilterName(
                        currentData[selectedConfig.chartKey]?.config_filter_names?.CA_BandFilter?.[selectedFilterEntity?.label]
                            ? [...currentData[selectedConfig.chartKey]?.config_filter_names?.CA_BandFilter?.[selectedFilterEntity?.label]]
                            : []
                    );
                    setFilterLabel(selectedFilterEntity);
                }
            }
        }
    }, []);

    const handleSelectElements = (elementType, value, changeFilterItems = false) => {
        setSelectedElements(prevValue => {
            return {
                ...prevValue,
                [elementType]: value
            };
        });
        if (changeFilterItems) {
            let selectedFilterItem = CA_RECOMMENDATION_ELEMENTS.find(fi => fi.key === value);
            setFilterLabel(selectedFilterItem);
            setSelectedFilterValues([]);
            setSelectedFilterValuesTrade([]);
            setSelectedFilterValuesSystem([]);
            setSelectedFilterTrade([]);
            setSelectedFilterSystem([]);
            setSelectedFilterName([]);
            if (selectedFilterItem?.hasFilter) {
                getCAbandFilterDropDownData(value, getParamsForMasterFilter(value));
                if (value === "subsystem") {
                    getCAbandSystemFilterDropDownData("system", getParamsForMasterFilter("system"));
                }
            }
        }
        elementType === "line" && setSelectedBreakdowns({});
        setSelectedFields([]);
    };
    useEffect(() => {
        if (getCAbandDropdownData && mfilter?.[filterLabel?.paramKey == "subsystem_ids" ? "sub_system_ids" : filterLabel?.paramKey]?.length > 0) {
            setDropdowns(
                getCAbandDropdownData?.filter(value =>
                    mfilter?.[filterLabel?.paramKey == "subsystem_ids" ? "sub_system_ids" : filterLabel?.paramKey]?.includes(value?.id)
                )
            );
        } else {
            setDropdowns(getCAbandDropdownData);
        }
        if (getCAbandSystemDropdownData && mfilter?.["system_ids"]?.length > 0) {
            setSystemDropdown(getCAbandSystemDropdownData?.filter(value => mfilter?.["system_ids"]?.includes(value?.id)));
        } else {
            setSystemDropdown(getCAbandSystemDropdownData);
        }

        if (getCAbandTradeDropdownData && mfilter?.["trade_ids"]?.length > 0) {
            setTradeDropdown(getCAbandTradeDropdownData?.filter(value => mfilter?.["trade_ids"]?.includes(value?.id)));
        } else {
            setTradeDropdown(getCAbandTradeDropdownData);
        }
    }, [getCAbandDropdownData, getCAbandSystemDropdownData, getCAbandTradeDropdownData]);
    useEffect(() => {
        if (dropdowns?.length > 0 && selectedFilterValues?.length > 0) {
            const ids = [];
            const names = [];

            dropdowns.forEach(item => {
                if (selectedFilterValues.includes(item.id)) {
                    ids.push(item.id);
                    names.push(item.name);
                }
            });
            setSelectedFilterValues(ids);
            setSelectedFilterName(names);
        }
        if (systemDropdown?.length > 0 && selectedFilterValuesSystem?.length > 0) {
            const ids = [];
            const names = [];

            systemDropdown.forEach(item => {
                if (selectedFilterValuesSystem.includes(item.id)) {
                    ids.push(item.id);
                    names.push(item.name);
                }
            });
            setSelectedFilterValuesSystem(ids);
            setSelectedFilterSystem(names);
        }
        if (tradeDropdown?.length > 0 && selectedFilterValuesTrade?.length > 0) {
            const ids = [];
            const names = [];

            tradeDropdown.forEach(item => {
                if (selectedFilterValuesTrade.includes(item.id)) {
                    ids.push(item.id);
                    names.push(item.name);
                }
            });
            setSelectedFilterValuesTrade(ids);
            setSelectedFilterTrade(names);
        }
    }, [systemDropdown, dropdowns, tradeDropdown]);
    const getParamsForMasterFilter = key => {
        let currentUser = localStorage.getItem("userId") || "";
        const { project_ids, region_ids, site_ids, building_ids, building_type_ids, ...restMfilter } = mfilter;
        switch (key) {
            case "category":
                return {
                    project_id: entityMfilters?.project_ids ? [...entityMfilters.project_ids] : null,
                    region_id: entityMfilters?.region_ids ? [...entityMfilters.region_ids] : null,
                    site_id: entityMfilters?.site_ids ? [...entityMfilters.site_ids] : null,
                    building_id: entityMfilters?.building_ids ? [...entityMfilters.building_ids] : null,
                    building_type_id: entityMfilters?.building_types ? [...entityMfilters.building_types] : null,
                    user_id: currentUser,
                    ...restMfilter
                };

            case "system":
                // const { trade_ids: systemTradeIds, ...systemRestMfilter } = restMfilter;
                return {
                    project_id: entityMfilters?.project_ids ? [...entityMfilters.project_ids] : null,
                    region_id: entityMfilters?.region_ids ? [...entityMfilters.region_ids] : null,
                    site_id: entityMfilters?.site_ids ? [...entityMfilters.site_ids] : null,
                    building_id: entityMfilters?.building_ids ? [...entityMfilters.building_ids] : null,
                    building_type_id: entityMfilters?.building_types ? [...entityMfilters.building_types] : null,
                    user_id: currentUser,
                    trade_id: selectedFilterValuesTrade.length ? [...selectedFilterValuesTrade] : null,
                    ...restMfilter
                };
            case "criticality":
                return {
                    project_id: entityMfilters?.project_ids ? [...entityMfilters.project_ids] : null,
                    region_id: entityMfilters?.region_ids ? [...entityMfilters.region_ids] : null,
                    site_id: entityMfilters?.site_ids ? [...entityMfilters.site_ids] : null,
                    building_id: entityMfilters?.building_ids ? [...entityMfilters.building_ids] : null,
                    building_type_id: entityMfilters?.building_types ? [...entityMfilters.building_types] : null,
                    user_id: currentUser,
                    ...restMfilter
                };
            case "term":
                return {
                    project_id: entityMfilters?.project_ids ? [...entityMfilters.project_ids] : null,
                    region_id: entityMfilters?.region_ids ? [...entityMfilters.region_ids] : null,
                    site_id: entityMfilters?.site_ids ? [...entityMfilters.site_ids] : null,
                    building_id: entityMfilters?.building_ids ? [...entityMfilters.building_ids] : null,
                    building_type_id: entityMfilters?.building_types ? [...entityMfilters.building_types] : null,
                    user_id: currentUser,
                    ...restMfilter
                };
            case "fundingsrc":
                return {
                    project_id: entityMfilters?.project_ids ? [...entityMfilters.project_ids] : null,
                    region_id: entityMfilters?.region_ids ? [...entityMfilters.region_ids] : null,
                    site_id: entityMfilters?.site_ids ? [...entityMfilters.site_ids] : null,
                    building_id: entityMfilters?.building_ids ? [...entityMfilters.building_ids] : null,
                    building_type_id: entityMfilters?.building_types ? [...entityMfilters.building_types] : null,
                    user_id: currentUser,
                    ...restMfilter
                };
            case "trade":
                return {
                    project_id: entityMfilters?.project_ids ? [...entityMfilters.project_ids] : null,
                    region_id: entityMfilters?.region_ids ? [...entityMfilters.region_ids] : null,
                    site_id: entityMfilters?.site_ids ? [...entityMfilters.site_ids] : null,
                    building_id: entityMfilters?.building_ids ? [...entityMfilters.building_ids] : null,
                    building_type_id: entityMfilters?.building_types ? [...entityMfilters.building_types] : null,
                    user_id: currentUser,
                    ...restMfilter
                };
            case "capital":
                return {
                    project_id: entityMfilters?.project_ids ? [...entityMfilters.project_ids] : null,
                    region_id: entityMfilters?.region_ids ? [...entityMfilters.region_ids] : null,
                    site_id: entityMfilters?.site_ids ? [...entityMfilters.site_ids] : null,
                    building_id: entityMfilters?.building_ids ? [...entityMfilters.building_ids] : null,
                    building_type_id: entityMfilters?.building_types ? [...entityMfilters.building_types] : null,
                    user_id: currentUser,
                    ...restMfilter
                };
            case "subsystem":
                // const { trade_ids: subsystemTradeIds, system_ids: subsystemsystemIds, ...subsystemRestMfilter } = restMfilter;
                return {
                    project_id: entityMfilters?.project_ids ? [...entityMfilters.project_ids] : null,
                    region_id: entityMfilters?.region_ids ? [...entityMfilters.region_ids] : null,
                    site_id: entityMfilters?.site_ids ? [...entityMfilters.site_ids] : null,
                    building_id: entityMfilters?.building_ids ? [...entityMfilters.building_ids] : null,
                    building_type_id: entityMfilters?.building_types ? [...entityMfilters.building_types] : null,
                    user_id: currentUser,
                    trade_id: selectedFilterValuesTrade.length ? [...selectedFilterValuesTrade] : null,
                    system_id: selectedFilterValuesSystem.length ? [...selectedFilterValuesSystem] : null,
                    ...restMfilter
                };
            default:
                return {};
        }
    };

    const validate = () => {
        setShowErrorBorder(false);
        if (!selectedElements.line || !selectedElements.column) {
            setShowErrorBorder(true);
            return false;
        }
        if (CA_RECOMMENDATION_ELEMENTS.find(item => item.key === selectedElements?.column)?.hasColumnSelect && !Object.keys(selectedFields).length) {
            showAlert("Please select columns");
            return false;
        }
        return true;
    };

    const handleSaveConfig = () => {
        if (validate()) {
            let configValue = {
                reverse_cmp_analysis: isSwitchBandActive,
                table_view: "",
                remove_section_break: false
            };
            if (selectedFilterValues.length) {
                configValue = {
                    [filterLabel.paramKey]: [...selectedFilterValues],
                    reverse_cmp_analysis: isSwitchBandActive,
                    table_view: "",
                    remove_section_break: false,
                    config_filter_names: {
                        ...configValue.config_filter_names,
                        CA_BandFilter: {
                            ...(configValue.config_filter_names && configValue.config_filter_names.CA_BandFilter),
                            [filterLabel?.label]: [...selectedFilterName]
                        }
                    }
                };
            }
            if (Object.keys(selectedBreakdowns)?.length) {
                configValue = {
                    ...configValue,
                    grouping: selectedBreakdowns
                };
            }
            if ((selectedElements.column === "recommendations" || selectedElements.column === "asset") && Object.keys(selectedFields).length) {
                configValue = {
                    ...configValue,
                    config: {
                        template: selectedTemplate,
                        property: selectedProperty,
                        fields: selectedFields
                    }
                };
            }
            if ((selectedElements.column === "recommendations" || selectedElements.column === "asset") && isExcelExport) {
                configValue = {
                    ...configValue,
                    config: {
                        ...configValue?.config,
                        is_pivot_table: pivotTable
                    }
                };
            }
            let selectedTileKey = isSwitchBandActive
                ? selectedElements.column + "_" + selectedElements.line
                : selectedElements.line + "_" + selectedElements.column;
            if (selectedTileKey.includes("system") || selectedTileKey.includes("subsystem") || selectedTileKey.includes("trade")) {
                configValue = {
                    ...configValue,
                    trade_ids: [...selectedFilterValuesTrade],
                    config_filter_names: {
                        ...configValue.config_filter_names,
                        CA_BandFilter: {
                            ...(configValue.config_filter_names && configValue.config_filter_names.CA_BandFilter),
                            Trade: [...selectedFilterTrade]
                        }
                    }
                };
                if (selectedTileKey.includes("subsystem")) {
                    configValue = {
                        ...configValue,
                        system_ids: [...selectedFilterValuesSystem],
                        config_filter_names: {
                            ...configValue.config_filter_names,
                            CA_BandFilter: {
                                ...(configValue.config_filter_names && configValue.config_filter_names.CA_BandFilter),
                                System: [...selectedFilterSystem]
                            }
                        }
                    };
                }
            }
            if (isEdit && initialElements.current !== selectedTileKey) {
                setSmartChartData("replace_ca_band", {
                    bandName: "band1",
                    entity: entity,
                    customTileKey: selectedTileKey + "_" + uuid(),
                    customTileData: {
                        ...configValue
                    },
                    prevTileKey: initialElements.current
                });
            } else {
                setSmartChartData("custom_tile", {
                    bandName: "band1",
                    entity: entity,
                    customTileKey: selectedTileKey + "_" + uuid(),
                    customTileData: {
                        ...configValue
                    },
                    isEditing: isEdit
                });
            }
            onCancel();
        }
    };

    const renderFilteredList = key => {
        let filteredList = [];
        if (key === "trade") {
            if (tradeDropdown?.length) {
                filteredList = searchValueTrade?.trim()?.length
                    ? tradeDropdown.filter(item => item.name?.toString()?.toLowerCase()?.includes(searchValueTrade?.toLocaleLowerCase()))
                    : tradeDropdown;
            }
        } else if (key === "system") {
            if (systemDropdown?.length) {
                filteredList = searchValueSystem?.trim()?.length
                    ? systemDropdown.filter(item => item.name?.toString()?.toLowerCase()?.includes(searchValueSystem?.toLocaleLowerCase()))
                    : systemDropdown;
            }
        } else {
            if (dropdowns?.length) {
                filteredList = searchValue?.trim()?.length
                    ? dropdowns.filter(item => item.name?.toString()?.toLowerCase()?.includes(searchValue?.toLocaleLowerCase()))
                    : dropdowns;
            }
        }

        return filteredList;
    };

    const handleClickDropdown = (label, type) => {
        let id = {};
        if (label === "Sub-System" || label == "System") {
            if (label === "Sub-System") {
                // id = { trade_ids: [...selectedFilterValuesTrade], system_ids: [...selectedFilterValuesSystem] };
                if (selectedFilterValuesTrade.length > 0) {
                    id.trade_ids = [...selectedFilterValuesTrade];
                }
                if (selectedFilterValuesSystem.length > 0) {
                    id.system_ids = [...selectedFilterValuesSystem];
                }

                getCAbandFilterDropDownData("subsystem", {
                    ...getParamsForMasterFilter("subsystem"),
                    ...id
                });
            }
            if (label === "System") {
                // id = { trade_ids: [...selectedFilterValuesTrade] };
                if (selectedFilterValuesTrade.length > 0) {
                    id.trade_ids = [...selectedFilterValuesTrade];
                }
                getCAbandFilterDropDownData("system", {
                    ...getParamsForMasterFilter("system"),
                    ...id
                });
            }
        }
        if (type === "submit") {
            setSelectedFilterValues(dataValue);
            setSelectedFilterName(dataName);
        }
        if (type === "cancel") {
            setSelectedFilterValues([...selectedFilterValues]);
            setSelectedFilterName([...selectedFilterName]);
        }
        if (type === "dropdown") {
            setData([...selectedFilterValues]);
            setDataName([...selectedFilterName]);
        }
        setShowDropDown(prevValue => {
            return !prevValue;
        });

        setShowTradeDropDown(false);
        setShowSystemDropDown(false);
    };

    useEffect(() => {
        if (filterRefs["common"] && showDropDown) {
            filterRefs["common"]?.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
    }, [showDropDown, filterRefs]);
    useEffect(() => {
        if (filterRefs["trade"] && showTradeDropDown) {
            filterRefs["trade"]?.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
    }, [showTradeDropDown, filterRefs]);
    useEffect(() => {
        if (filterRefs["system"] && showSystemDropDown) {
            filterRefs["system"]?.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
    }, [showSystemDropDown, filterRefs]);

    useEffect(() => {
        if (
            selectedElements.line &&
            selectedElements.column &&
            CA_RECOMMENDATION_ELEMENTS.find(item => item.key === selectedElements?.column)?.hasColumnSelect &&
            !Object.keys(selectedFields).length
        ) {
            fetchColumns();
        }
    }, [selectedElements]);

    // useEffect(() => {
    //     if (caColumns?.success && !Object.keys(selectedFields).length) {
    //         const defaultColumns = typeof caColumns?.list?.columns === "string" ? JSON.parse(caColumns.list.columns) : caColumns.list.columns;
    //         setSelectedFields(defaultColumns);
    //         setSelectedProperty(caColumns?.list?.property_id);
    //         setSelectedTemplate(caColumns?.list?.template?._id);
    //     }
    // }, [caColumns]);

    const fetchColumns = async () => {
        const { line, column } = selectedElements;
        const params = {
            keyword: column + "_" + line,
            client_id: selectedClient || "",
            project_id: selectedProject || ""
        };
        dispatch(actions.getCaColumns(params, column));
    };

    const handleSelectDropdown = (selectedId, key, data) => {
        if (key === "trade" || key === "Trade") {
            let tempSelectedItems = [...tradeData];
            let tempSelectedName = [...tradeDataName];
            if (tradeData.includes(selectedId)) {
                tempSelectedItems = tempSelectedItems.filter(id => id !== selectedId);
                tempSelectedName = tempSelectedName.filter(item => item !== data?.name);
            } else {
                tempSelectedItems.push(selectedId);
                tempSelectedName.push(data?.name);
            }
            setTradeData([...tempSelectedItems]);
            setTradeDataName([...tempSelectedName]);
            if (key === "Trade") {
                setData([...tempSelectedItems]);
                setDataName([...tempSelectedName]);
            }
        } else if (key === "system") {
            let tempSelectedItems = [...systemData];
            let tempSelectedName = [...systemDataName];
            if (systemData.includes(selectedId)) {
                tempSelectedItems = tempSelectedItems.filter(id => id !== selectedId);
                tempSelectedName = tempSelectedName.filter(item => item !== data?.name);
            } else {
                tempSelectedItems.push(selectedId);
                tempSelectedName.push(data?.name);
            }
            setSystemData([...tempSelectedItems]);
            setSystemDataName([...tempSelectedName]);
        } else {
            let tempSelectedItems = [...dataValue];
            let tempSelectedName = [...dataName];
            if (dataValue.includes(selectedId)) {
                tempSelectedItems = tempSelectedItems.filter(id => id !== selectedId);
                tempSelectedName = tempSelectedName.filter(item => item !== data?.name);
            } else {
                tempSelectedItems.push(selectedId);
                tempSelectedName.push(data?.name);
            }
            setData([...tempSelectedItems]);
            setDataName([...tempSelectedName]);
        }
    };

    const handleSelectAll = async (isChecked, key) => {
        let temp = [];
        let tempValue = [];
        if (key === "trade") {
            if (tradeDropdown?.length) {
                tradeDropdown.map(data => temp.push(data.id));
                tradeDropdown.map(data => tempValue.push(data.name));
            }
            setTradeData(isChecked ? [...temp] : []);
            setTradeDataName(isChecked ? [...tempValue] : []);
        } else if (key === "system") {
            if (systemDropdown?.length) {
                systemDropdown.map(data => temp.push(data.id));
                systemDropdown.map(data => tempValue.push(data.name));
            }
            setSystemData(isChecked ? [...temp] : []);
            setSystemDataName(isChecked ? [...tempValue] : []);
        } else {
            if (dropdowns?.length) {
                dropdowns.map(data => temp.push(data.id));
                dropdowns.map(data => tempValue.push(data.name));
            }
            setData(isChecked ? [...temp] : []);
            setDataName(isChecked ? [...tempValue] : []);
        }
    };

    const switchBandElements = () => {
        setIsSwitchBandActive(prevValue => !prevValue);
        setSelectedElements(prevValue => {
            return {
                line: [...CA_GEO_HIERARCHY_ELEMENTS, ...CA_RECOMMENDATION_ELEMENTS].find(item => item.key === prevValue.column)?.columnOnly
                    ? ""
                    : prevValue.column,
                column: prevValue.line
            };
        });
    };

    const handleClickTradeDropdown = type => {
        if (type === "dropdown") {
            setTradeData([...selectedFilterValuesTrade]);
            setTradeDataName([...selectedFilterTrade]);
        }
        if (type === "submit") {
            setSelectedFilterValuesTrade(tradeData);
            setSelectedFilterTrade(tradeDataName);
        }
        if (type === "cancel") {
            setTradeData([...selectedFilterValuesTrade]);
            setTradeDataName([...selectedFilterTrade]);
        }

        let id = {};
        // if (selectedElements?.line === "subsystem" || selectedElements?.column === "subsystem") {
        //     id = { system_ids: [...selectedFilterValuesSystem], sub_system_ids: [...selectedFilterValues] };
        // } else if (selectedElements?.line === "system" || selectedElements?.column === "system") {
        //     id = { system_ids: [...selectedFilterValues] };
        // }
        if (selectedElements.line === "subsystem" || selectedElements.column === "subsystem") {
            if (selectedFilterValuesSystem.length > 0) {
                id.system_ids = [...selectedFilterValuesSystem];
            }
            if (selectedFilterValues.length > 0) {
                id.sub_system_ids = [...selectedFilterValues];
            }
        } else if (selectedElements.line === "system" || selectedElements.column === "system") {
            if (selectedFilterValues.length > 0) {
                id.system_ids = [...selectedFilterValues];
            }
        }
        getCAbandTradeFilterDropDownData("trade", {
            ...getParamsForMasterFilter("trade"),
            ...id
        });
        setShowTradeDropDown(prevValue => {
            return !prevValue;
        });
        setShowDropDown(false);
    };

    const handleClickSystemDropdown = type => {
        if (type === "dropdown") {
            setSystemData([...selectedFilterValuesSystem]);
            setSystemDataName([...selectedFilterSystem]);
        }
        if (type === "submit") {
            setSelectedFilterValuesSystem(systemData);
            setSelectedFilterSystem(systemDataName);
        }
        if (type === "cancel") {
            setSystemData([...selectedFilterValuesSystem]);
            setSystemDataName([...selectedFilterSystem]);
        }
        let id = {};
        // if (selectedElements?.line === "subsystem" || selectedElements?.column === "subsystem") {
        //     id = { trade_ids: [...selectedFilterValuesTrade], sub_system_ids: [...selectedFilterValues] };
        // } else if (selectedElements?.line === "system" || selectedElements?.column === "system") {
        //     id = { trade_ids: [...selectedFilterValuesTrade] };
        // }
        if (selectedElements.line === "subsystem" || selectedElements.column === "subsystem") {
            if (selectedFilterValuesTrade.length > 0) {
                id.trade_ids = [...selectedFilterValuesTrade];
            }
            if (selectedFilterValues.length > 0) {
                id.sub_system_ids = [...selectedFilterValues];
            }
        } else if (selectedElements.line === "system" || selectedElements.column === "system") {
            if (selectedFilterValuesTrade.length > 0) {
                id.trade_ids = [...selectedFilterValuesTrade];
            }
        }
        getCAbandSystemFilterDropDownData("system", {
            ...getParamsForMasterFilter("system"),
            ...id
        });
        setShowSystemDropDown(prevValue => {
            return !prevValue;
        });
        setShowDropDown(false);
    };

    const handleSelectBreakdown = e => {
        const { name, checked } = e.target;
        setSelectedBreakdowns({ ...selectedBreakdowns, [name]: checked });
    };

    const renderFilters = type => {
        return (
            <>
                {CA_RECOMMENDATION_ELEMENTS.find(item => item.key === selectedElements?.[type])?.hasFilter && (
                    <>
                        {selectedElements[type] === "system" || selectedElements[type] === "subsystem" ? (
                            <>
                                <div className={`otr-form col-md-12 p-0 d-flex mt-3`} ref={node => (filterRefs["trade"] = node)}>
                                    <div className={`selct-otr col-md-12 p-0`}>
                                        <div className="grid-inner d-flex category-selc-drp">
                                            <div
                                                className={`grid-box position-relative ${selectedFilterValuesTrade?.length ? "bg-th-filtered" : ""}`}
                                                onClick={() => handleClickTradeDropdown("dropdown")}
                                            >
                                                <div className={`dropdown-container ${showTradeDropDown ? "dropdown-open" : ""} `}>
                                                    <div className="dropdown-toggle click-dropdown">
                                                        Trade
                                                        <span className="close-reg">
                                                            <i className="fas fa-chevron-down" />
                                                        </span>
                                                    </div>
                                                    {showTradeDropDown && (
                                                        <div className={`dropdown-menu p-0 ${showTradeDropDown ? "dropdown-active" : ""}`}>
                                                            <div className="drop-filtr dp-rcm-overflow" onClick={e => e.stopPropagation()}>
                                                                <div className="col-md-12 p-0 border-bottom pt-1">
                                                                    <span className="dropdown-item build_search_drp">
                                                                        <input
                                                                            type="search"
                                                                            placeholder="Search..."
                                                                            value={searchValueTrade}
                                                                            onChange={e => {
                                                                                let value = e.target.value;
                                                                                setSearchValueTrade(prevSearch => {
                                                                                    return value;
                                                                                });
                                                                            }}
                                                                        />
                                                                        <i
                                                                            className="fas fa-times cursor-pointer cls-close"
                                                                            onClick={() =>
                                                                                setSearchValueTrade(prevSearch => {
                                                                                    return "";
                                                                                })
                                                                            }
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-12 p-0 border-bottom pt-1">
                                                                    <div className="dropdown-item select_section_outer">
                                                                        <label className="container-check">
                                                                            Select all
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={
                                                                                    tradeData.length && tradeDropdown?.length == tradeData.length
                                                                                }
                                                                                onClick={e => handleSelectAll(e.target.checked, "trade")}
                                                                            />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        <button
                                                                            className="clear-btn-selection"
                                                                            onClick={() => {
                                                                                setTradeData([]);
                                                                                setTradeDataName([]);
                                                                            }}
                                                                        >
                                                                            Clear
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 p-0 slt-ara border-bottom dpr-wide-txt">
                                                                    {tradeDropdown
                                                                        ?.filter(fi => tradeData?.includes(fi.id))
                                                                        ?.map(data => (
                                                                            <span className="dropdown-item" key={data.id}>
                                                                                <label className="container-check">
                                                                                    <span
                                                                                        className="text-short"
                                                                                        data-tip={
                                                                                            data.name?.length + data?.description?.length > 25
                                                                                                ? `${data.name} ${
                                                                                                      data.description ? `(${data.description})` : ""
                                                                                                  }`
                                                                                                : null
                                                                                        }
                                                                                        data-effect="solid"
                                                                                        data-for="smart-chart-master-filter"
                                                                                        data-place="left"
                                                                                        data-background-color="#007bff"
                                                                                        data-delay-show="500"
                                                                                    >
                                                                                        {data.name} {data.description ? `(${data.description})` : ""}
                                                                                    </span>
                                                                                    {data.count ? (
                                                                                        <span className="count-num"> ({data.count})</span>
                                                                                    ) : null}
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={tradeData.includes(data.id)}
                                                                                        onChange={e => handleSelectDropdown(data.id, "trade", data)}
                                                                                    />
                                                                                    <span className="checkmark" />
                                                                                </label>
                                                                            </span>
                                                                        ))}
                                                                </div>
                                                                {renderFilteredList("trade")?.filter(fi => !tradeData?.includes(fi.id))?.length ? (
                                                                    <div className="col-md-12 p-0 slt-ara overflw-ht dpr-wide-txt">
                                                                        {renderFilteredList("trade")
                                                                            ?.filter(fi => !tradeData?.includes(fi.id))
                                                                            ?.map(data => (
                                                                                <span className="dropdown-item" key={data.id}>
                                                                                    <label className="container-check">
                                                                                        <span
                                                                                            className="text-short"
                                                                                            data-tip={
                                                                                                data.name?.length + data?.description?.length > 25
                                                                                                    ? `${data.name} ${
                                                                                                          data.description
                                                                                                              ? `(${data.description})`
                                                                                                              : ""
                                                                                                      }`
                                                                                                    : null
                                                                                            }
                                                                                            data-effect="solid"
                                                                                            data-for="smart-chart-master-filter"
                                                                                            data-place="left"
                                                                                            data-background-color="#007bff"
                                                                                            data-delay-show="500"
                                                                                        >
                                                                                            {data.trade ? `${data.trade}-` : ""}
                                                                                            {data.name}{" "}
                                                                                            {data.description ? `(${data.description})` : ""}
                                                                                        </span>
                                                                                        {data.count ? (
                                                                                            <span className="count-num"> ({data.count})</span>
                                                                                        ) : null}
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={false}
                                                                                            onChange={e =>
                                                                                                handleSelectDropdown(data.id, "trade", data)
                                                                                            }
                                                                                        />
                                                                                        <span className="checkmark" />
                                                                                    </label>
                                                                                </span>
                                                                            ))}
                                                                    </div>
                                                                ) : (
                                                                    <div className={`col-md-12`}>
                                                                        {renderFilteredList("trade")?.filter(
                                                                            fi => !selectedFilterValuesTrade?.includes(fi.id)
                                                                        )?.length === 0
                                                                            ? "No data Found"
                                                                            : "No more values to display"}
                                                                    </div>
                                                                )}
                                                                <div className="col-md-12 drp-btn">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        onClick={() => handleClickTradeDropdown("submit")}
                                                                    >
                                                                        ok
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btnClr ml-2"
                                                                        onClick={() => handleClickTradeDropdown("cancel")}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {selectedElements[isSwitchBandActive ? "column" : "line"] === "subsystem" ? (
                                    <div
                                        className={`otr-form col-md-12 p-0 d-flex mt-3 ${
                                            isSwitchBandActive ? "justify-content-end" : "justify-content-start"
                                        }`}
                                        ref={node => (filterRefs["system"] = node)}
                                    >
                                        <div className={`selct-otr col-md-12 p-0`}>
                                            <div className="grid-inner d-flex category-selc-drp">
                                                <div
                                                    className={`grid-box position-relative ${
                                                        selectedFilterValuesSystem?.length ? "bg-th-filtered" : ""
                                                    }`}
                                                    onClick={() => handleClickSystemDropdown("dropdown")}
                                                >
                                                    <div className={`dropdown-container ${showSystemDropDown ? "dropdown-open" : ""} `}>
                                                        <div className="dropdown-toggle click-dropdown">
                                                            System
                                                            <span className="close-reg">
                                                                <i className="fas fa-chevron-down" />
                                                            </span>
                                                        </div>
                                                        {showSystemDropDown && (
                                                            <div className={`dropdown-menu p-0 ${showSystemDropDown ? "dropdown-active" : ""}`}>
                                                                <div className="drop-filtr dp-rcm-overflow" onClick={e => e.stopPropagation()}>
                                                                    <div className="col-md-12 p-0 border-bottom pt-1">
                                                                        <span className="dropdown-item build_search_drp">
                                                                            <input
                                                                                type="search"
                                                                                placeholder="Search..."
                                                                                value={searchValueSystem}
                                                                                onChange={e => {
                                                                                    let value = e.target.value;
                                                                                    setSearchValueSystem(prevSearch => {
                                                                                        return value;
                                                                                    });
                                                                                }}
                                                                            />
                                                                            <i
                                                                                className="fas fa-times cursor-pointer cls-close"
                                                                                onClick={() =>
                                                                                    setSearchValueSystem(prevSearch => {
                                                                                        return "";
                                                                                    })
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-12 p-0 border-bottom pt-1">
                                                                        <div className="dropdown-item select_section_outer">
                                                                            <label className="container-check">
                                                                                Select all
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={
                                                                                        systemData.length &&
                                                                                        systemDropdown?.length == systemData.length
                                                                                    }
                                                                                    onClick={e => handleSelectAll(e.target.checked, "system")}
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            <button
                                                                                className="clear-btn-selection"
                                                                                onClick={() => {
                                                                                    setSystemData([]);
                                                                                    setSystemDataName([]);
                                                                                }}
                                                                            >
                                                                                Clear
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 p-0 slt-ara border-bottom dpr-wide-txt">
                                                                        {systemDropdown
                                                                            ?.filter(fi => systemData?.includes(fi.id))
                                                                            ?.map(data => (
                                                                                <span className="dropdown-item" key={data.id}>
                                                                                    <label className="container-check">
                                                                                        <span
                                                                                            className="text-short"
                                                                                            data-tip={
                                                                                                data.name?.length + data?.description?.length > 25
                                                                                                    ? `${data.name} ${
                                                                                                          data.description
                                                                                                              ? `(${data.description})`
                                                                                                              : ""
                                                                                                      }`
                                                                                                    : null
                                                                                            }
                                                                                            data-effect="solid"
                                                                                            data-for="smart-chart-master-filter"
                                                                                            data-place="left"
                                                                                            data-background-color="#007bff"
                                                                                            data-delay-show="500"
                                                                                        >
                                                                                            {data.name}{" "}
                                                                                            {data.description ? `(${data.description})` : ""}
                                                                                        </span>
                                                                                        {data.count ? (
                                                                                            <span className="count-num"> ({data.count})</span>
                                                                                        ) : null}
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={systemData.includes(data.id)}
                                                                                            onChange={e =>
                                                                                                handleSelectDropdown(data.id, "system", data)
                                                                                            }
                                                                                        />
                                                                                        <span className="checkmark" />
                                                                                    </label>
                                                                                </span>
                                                                            ))}
                                                                    </div>
                                                                    {renderFilteredList("system")?.filter(fi => !systemData?.includes(fi.id))
                                                                        ?.length ? (
                                                                        <div className="col-md-12 p-0 slt-ara overflw-ht dpr-wide-txt">
                                                                            {renderFilteredList("system")
                                                                                ?.filter(fi => !systemData?.includes(fi.id))
                                                                                ?.map(data => (
                                                                                    <span className="dropdown-item" key={data.id}>
                                                                                        <label className="container-check">
                                                                                            <span
                                                                                                className="text-short"
                                                                                                data-tip={
                                                                                                    data.name?.length + data?.description?.length > 25
                                                                                                        ? `${data.name} ${
                                                                                                              data.description
                                                                                                                  ? `(${data.description})`
                                                                                                                  : ""
                                                                                                          }`
                                                                                                        : null
                                                                                                }
                                                                                                data-effect="solid"
                                                                                                data-for="smart-chart-master-filter"
                                                                                                data-place="left"
                                                                                                data-background-color="#007bff"
                                                                                                data-delay-show="500"
                                                                                            >
                                                                                                {data.trade ? `${data.trade}-` : ""}
                                                                                                {data.name}{" "}
                                                                                                {data.description ? `(${data.description})` : ""}
                                                                                            </span>
                                                                                            {data.count ? (
                                                                                                <span className="count-num"> ({data.count})</span>
                                                                                            ) : null}
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={false}
                                                                                                onChange={e =>
                                                                                                    handleSelectDropdown(data.id, "system", data)
                                                                                                }
                                                                                            />
                                                                                            <span className="checkmark" />
                                                                                        </label>
                                                                                    </span>
                                                                                ))}
                                                                        </div>
                                                                    ) : (
                                                                        <div className={`col-md-12`}>
                                                                            {renderFilteredList("system")?.filter(fi => !systemData?.includes(fi.id))
                                                                                ?.length === 0
                                                                                ? "No data Found"
                                                                                : "No more values to display"}
                                                                        </div>
                                                                    )}
                                                                    <div className="col-md-12 drp-btn">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary"
                                                                            onClick={() => handleClickSystemDropdown("submit")}
                                                                        >
                                                                            ok
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary btnClr ml-2"
                                                                            onClick={() => handleClickSystemDropdown("cancel")}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                        <div className={`otr-form col-md-12 p-0 d-flex mt-3`} ref={node => (filterRefs["common"] = node)}>
                            <div className={`selct-otr col-md-12 p-0`}>
                                <div className="grid-inner d-flex category-selc-drp">
                                    <div
                                        className={`grid-box position-relative ${selectedFilterValues?.length ? "bg-th-filtered" : ""}`}
                                        onClick={() => handleClickDropdown(filterLabel?.label, "dropdown")}
                                    >
                                        <div className={`dropdown-container ${showDropDown ? "dropdown-open" : ""}`}>
                                            <div className="dropdown-toggle click-dropdown">
                                                {filterLabel?.label || "-"}
                                                <span className="close-reg">
                                                    <i className="fas fa-chevron-down" />
                                                </span>
                                            </div>
                                            {showDropDown && (
                                                <div className={`dropdown-menu p-0 ${showDropDown ? "dropdown-active" : ""}`}>
                                                    <div className="drop-filtr dp-rcm-overflow" onClick={e => e.stopPropagation()}>
                                                        <div className="col-md-12 p-0 border-bottom pt-1">
                                                            <span className="dropdown-item build_search_drp">
                                                                <input
                                                                    type="search"
                                                                    placeholder="Search..."
                                                                    value={searchValue}
                                                                    onChange={e => {
                                                                        let value = e.target.value;
                                                                        setSearchValue(prevSearch => {
                                                                            return value;
                                                                        });
                                                                    }}
                                                                />
                                                                <i
                                                                    className="fas fa-times cursor-pointer cls-close"
                                                                    onClick={() =>
                                                                        setSearchValue(prevSearch => {
                                                                            return "";
                                                                        })
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className="col-md-12 p-0 border-bottom pt-1">
                                                            <div className="dropdown-item select_section_outer">
                                                                <label className="container-check">
                                                                    Select all
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={dataValue.length && dropdowns?.length == dataValue.length}
                                                                        onClick={e => handleSelectAll(e.target.checked, filterLabel?.label)}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                                <button
                                                                    className="clear-btn-selection"
                                                                    onClick={() => {
                                                                        setData([]);
                                                                        setDataName([]);
                                                                    }}
                                                                >
                                                                    Clear
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 p-0 slt-ara border-bottom dpr-wide-txt">
                                                            {dropdowns
                                                                ?.filter(fi => dataValue?.includes(fi.id))
                                                                ?.map(data => (
                                                                    <span className="dropdown-item" key={data.id}>
                                                                        <label className="container-check">
                                                                            <span
                                                                                className="text-short"
                                                                                data-tip={
                                                                                    data.name?.length + data?.description?.length > 25
                                                                                        ? `${data.name} ${
                                                                                              data.description ? `(${data.description})` : ""
                                                                                          }`
                                                                                        : null
                                                                                }
                                                                                data-effect="solid"
                                                                                data-for="smart-chart-master-filter"
                                                                                data-place="left"
                                                                                data-background-color="#007bff"
                                                                                data-delay-show="500"
                                                                            >
                                                                                {data.trade ? `${data.trade}-` : ""}
                                                                                {data.name} {data.description ? `(${data.description})` : ""}
                                                                            </span>
                                                                            {data.count ? <span className="count-num"> ({data.count})</span> : null}
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={dataValue.includes(data.id)}
                                                                                onChange={e => handleSelectDropdown(data.id, filterLabel, data)}
                                                                            />
                                                                            <span className="checkmark" />
                                                                        </label>
                                                                    </span>
                                                                ))}
                                                        </div>
                                                        {renderFilteredList()?.filter(fi => !dataValue?.includes(fi.id))?.length ? (
                                                            <div className="col-md-12 p-0 slt-ara overflw-ht dpr-wide-txt">
                                                                {renderFilteredList()
                                                                    ?.filter(fi => !dataValue?.includes(fi.id))
                                                                    ?.map(data => (
                                                                        <span className="dropdown-item" key={data.id}>
                                                                            <label className="container-check">
                                                                                <span
                                                                                    className="text-short"
                                                                                    data-tip={
                                                                                        data.name?.length + data?.description?.length > 25
                                                                                            ? `${data.name} ${
                                                                                                  data.description ? `(${data.description})` : ""
                                                                                              }`
                                                                                            : null
                                                                                    }
                                                                                    data-effect="solid"
                                                                                    data-for="smart-chart-master-filter"
                                                                                    data-place="left"
                                                                                    data-background-color="#007bff"
                                                                                    data-delay-show="500"
                                                                                >
                                                                                    {data.trade ? `${data.trade}-` : ""}
                                                                                    {data.name} {data.description ? `(${data.description})` : ""}
                                                                                </span>
                                                                                {data.count ? (
                                                                                    <span className="count-num"> ({data.count})</span>
                                                                                ) : null}
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={false}
                                                                                    onChange={e =>
                                                                                        handleSelectDropdown(data.id, filterLabel?.label, data)
                                                                                    }
                                                                                />
                                                                                <span className="checkmark" />
                                                                            </label>
                                                                        </span>
                                                                    ))}
                                                            </div>
                                                        ) : (
                                                            <div className={`col-md-12`}>
                                                                {renderFilteredList()?.filter(fi => !dataValue?.includes(fi.id))?.length === 0
                                                                    ? "No data Found"
                                                                    : "No more values to display"}
                                                            </div>
                                                        )}
                                                        <div className="col-md-12 drp-btn">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => handleClickDropdown(filterLabel?.label, "submit")}
                                                            >
                                                                ok
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btnClr ml-2"
                                                                onClick={() => handleClickDropdown("cancel")}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    const renderColumnConfigModal = () => {
        if (!showColumnConfigModal) return null;
        return (
            <Portal
                body={
                    <CAColumnConfigModal
                        selectedElements={selectedElements}
                        onSave={({ selectedColumnKeys, property, template }) => {
                            setSelectedFields(selectedColumnKeys);
                            setSelectedProperty(property);
                            setSelectedTemplate(template);
                            setColumnConfigModal(false);
                        }}
                        onCancel={() => setColumnConfigModal(false)}
                        selectedFields={selectedFields}
                        selectedProperty={selectedProperty}
                        selectedTemplate={selectedTemplate}
                        selectedClient={selectedClient}
                        selectedProject={selectedProject}
                        isEdit={isEdit}
                    />
                }
                onCancel={() => setColumnConfigModal(false)}
            />
        );
    };

    return (
        <div
            className="modal modal-region smart-chart-popup smart-dtl-pop add-image-modal img-pop-sing add-ca-band"
            id="modalId"
            tabIndex="-1"
            style={{ display: "block" }}
        >
            {renderColumnConfigModal()}
            <Draggable handle=".draggable" cancel=".not-draggable">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header draggable">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <div className="txt-hed">{isEdit ? "Edit Comparative Analysis Band" : "Add Comparative Analysis Band"}</div>
                            </h5>
                            <button type="button" className="close not-draggable" onClick={() => onCancel()}>
                                <span aria-hidden="true">
                                    <img src="/img/close.svg" alt="" />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body region-otr p-0 overflow-inherit">
                            <div className="img-repr col-12 d-flex">
                                <div className="img-rep-otr d-flex flex-wrap col-6 line-element">
                                    <div className="img-rep-item col-md-12">
                                        <div className="cont-sec">
                                            <div className="img-sec">
                                                <img src="/img/ca-band-line.svg" />
                                            </div>
                                            <h3>Line Element</h3>
                                        </div>
                                    </div>
                                    <div className="otr-form col-md-12 p-0 d-flex mt-3">
                                        <div className="selct-otr col-12 p-0">
                                            <label>{isSwitchBandActive ? "Geo Hierarchy" : "Recommendation Elements"}</label>
                                            <select
                                                className={`form-control ${showErrorBorder && !selectedElements.line ? "error-border" : ""}`}
                                                onChange={e => handleSelectElements("line", e.target.value, !isSwitchBandActive)}
                                                value={selectedElements.line}
                                            >
                                                <option value="">Select</option>
                                                {(isSwitchBandActive ? CA_GEO_HIERARCHY_ELEMENTS : CA_RECOMMENDATION_ELEMENTS)
                                                    .filter(elem => !elem?.columnOnly)
                                                    .map((lElement, index) => (
                                                        <option value={lElement.key} key={index}>
                                                            {lElement.label}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        <div className="img-swap cursor-pointer" onClick={() => switchBandElements()}>
                                            <img src="/img/swap_02.svg" alt="" />
                                        </div>
                                    </div>
                                    {selectedElements.line &&
                                        [...CA_GEO_HIERARCHY_ELEMENTS, ...CA_RECOMMENDATION_ELEMENTS].find(item => item.key === selectedElements.line)
                                            ?.breakdowns?.length && (
                                            <div class="otr-form col-md-12 p-0 d-flex mt-3 justify-content-start">
                                                <div class="input-txt col-md-12 p-0">
                                                    <label>Choose Breakdown Summary</label>
                                                    <div className="d-flex">
                                                        {[...CA_GEO_HIERARCHY_ELEMENTS, ...CA_RECOMMENDATION_ELEMENTS]
                                                            .find(item => item.key === selectedElements.line)
                                                            ?.breakdowns.map(item => (
                                                                <div class="img-sq-box seting-type checkbox-container check-field mt-0 mr-2">
                                                                    <label class="container-checkbox cursor-hand m-0">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedBreakdowns[item.key]}
                                                                            name={item.key}
                                                                            onChange={handleSelectBreakdown}
                                                                        />
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                    {item.label}
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    {!isSwitchBandActive && renderFilters("line")}
                                </div>
                                <div className="img-rep-otr d-flex flex-wrap col-6 column-element">
                                    <div className="img-rep-item col-md-12">
                                        <div className="cont-sec">
                                            <div className="img-sec">
                                                <img src="/img/ca-band-column.svg" />
                                            </div>
                                            <h3>Column Element</h3>
                                        </div>
                                    </div>
                                    <div className="otr-form col-md-12 p-0 d-flex mt-3">
                                        <div className="selct-otr col-12 p-0">
                                            <label>{isSwitchBandActive ? "Recommendation Elements" : "Geo Hierarchy"}</label>
                                            <select
                                                className={`form-control ${showErrorBorder && !selectedElements.column ? "error-border" : ""}`}
                                                onChange={e => handleSelectElements("column", e.target.value, isSwitchBandActive)}
                                                value={selectedElements.column}
                                            >
                                                <option value="">Select</option>
                                                {(isSwitchBandActive ? CA_RECOMMENDATION_ELEMENTS : CA_GEO_HIERARCHY_ELEMENTS).map(
                                                    (cElement, index) => (
                                                        <option value={cElement.key} key={index}>
                                                            {cElement.label}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    {isSwitchBandActive && renderFilters("column")}
                                    {selectedElements?.line &&
                                        selectedElements?.column &&
                                        CA_RECOMMENDATION_ELEMENTS.find(item => item.key === selectedElements?.column)?.hasColumnSelect && (
                                            <div className="otr-form col-md-12 p-0 d-flex mt-3">
                                                <button className="btn-colm-blue" onClick={() => setColumnConfigModal(true)}>
                                                    <span class="edit-icn-bx">
                                                        <i class="fas fa-arrow-right mr-2"></i>Select Columns
                                                    </span>
                                                </button>
                                            </div>
                                        )}
                                    {(selectedElements.column === "recommendations" || selectedElements.column === "asset") && isExcelExport && (
                                        <div className=" input-txt mt-3">
                                            <div class="img-sq-box seting-type checkbox-container check-field mt-0 mr-2">
                                                <label class="container-checkbox cursor-hand m-0">
                                                    <input type="checkbox" checked={pivotTable} onChange={() => setPivotTable(!pivotTable)} />
                                                    <span class="checkmark"></span>
                                                </label>
                                                Enable Pivot Table
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="footer-otr col-12">
                                <div className="btn-otrr">
                                    <button className="btn-img-rpt mr-2" onClick={() => handleSaveConfig()}>
                                        {isEdit ? "Update" : "Save"}
                                    </button>
                                    <button className="btn-cancel" onClick={() => onCancel()}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    );
};

export default AddCAbandModal;
