import { bandPositions, bands } from "./FilterModal/constants";

export const assetTableData = {
    keys: [
        "code",
        "asset_name",
        "asset_description",
        "client_asset_condition",
        "description",
        "csp_unit",
        "unit_cost",
        "crv",
        "fca_cost",
        "asset_status",
        "asset_tag",
        "model_number",
        "core_model_number",
        "serial_number",
        "client",
        "region",
        "site",
        "building",
        "buildingDescription",
        "building_type",
        "addition",
        "floor",
        "room_number",
        "architectural_room_number",
        "room_name",
        "location",
        "additional_room_description",
        "year_manufactured",
        "manufacturer",
        "installed_year",
        "installed_year_status",
        "current_age",
        "service_life",
        "end_of_service_life",
        "usefull_life_remaining",
        "refrigerant",
        "warranty_start",
        "warranty_end",
        "install_date",
        "startup_date",
        "uniformat_level_1",
        "uniformat_level_2",
        "uniformat_level_3",
        "uniformat_level_4",
        "uniformat_level_5",
        "uniformat_level_6",
        "uniformat_level_6_description",
        "uniformat_level_3_custom_description",
        "main_category",
        "sub_category_1",
        "sub_category_2",
        "subcategory2_description",
        "sub_category_3",
        "trade",
        "system",
        "sub_system",
        "guid",
        "new_asset",
        "has_recommendation",
        "asset_type",
        "asset_ownership",
        "criticality",
        "capacity",
        "capacity_unit",
        "asset_barcode",
        "asset_client_id",
        "asset_cmms_id",
        "area_served",
        "upstream_asset_barcode_number",
        "linked_asset_barcode_number",
        "source_panel_name",
        "source_panel_barcode_number",
        "source_panel",
        "skysite_hyperlink",
        "asset_note",
        "energy_use_type",
        "quantity",
        "rtls_tag",
        "latitude",
        "longitude",
        // "age",
        "parent_global_id",
        "survey_id",
        "survey_property_note",
        "capacity_status",
        "name_plate_status",
        "qa_notes",
        "additional_qa_notes",
        "surveyor",
        "editor",
        "survey_date_created",
        "survey_date_edited",

        "discharge",
        "return",
        "unit_property_labeled",
        "factory_mounted_disconnect",
        "unit_voltage",
        "ahu_voltage",
        "wire_type",
        "wire_size",
        "estimated_wire_run_length",
        "breaker_size",
        "mocp",
        "mca",
        "liquid_line",
        "suction_line",
        "hot_gas_by_pass",
        "number_of_circuits",
        "length_of_line",
        "damper_type",
        "gas_heating",
        "gas_output_btu",
        "electric_heating",
        "electric_heating_kw",
        "pump_backup_heat_kw",
        "cooling_only",
        "controls",
        "control_type",
        "smoke_detector",
        "smoke_detector_mounting",
        "smoke_detector_supply",
        "smoke_detector_return",
        "curb",
        "curb_description",
        "vibration_isolation",
        "burglar_bars",
        "installed_on_adapter_curb",
        "curb_outside_dimension_length",
        "curb_outside_dimension_width",
        "allow_curb_adaptor",
        "ducted_or_concentric_system",
        "unit_weight",
        "codes_for_disconnects",
        "codes_for_convenience_outlets",
        "codes_for_roof_lighting",
        "duct_work_attached_to_bottom_of_unit",
        "aluminium_wire_used",
        "code_or_safety_concerns",
        "distance_from_salt_water_body",
        "recommend_coil_coating",
        "need_resealed",
        "split_system_line_sets_good_condition",
        "split_system_line_sets_damaged",
        "split_system_line_sets_crimped",
        "split_system_line_sets_insulated",
        "split_system_line_sets_re_used",
        "line_connection_type",
        "boiler_type",
        "boiler_burner_type",
        "boiler_btu_input",
        "boiler_btu_output",
        "boiler_hp",
        "boiler_fuel",
        "boiler_gas_pressure",
        "boiler_kw_input",
        "boiler_power_burner_type",
        "boiler_burner_model",
        "boiler_burner_serial",
        "boiler_pressure_type",
        "confirm_boiler_pressure",
        // "auxiliary_equipment_name",
        // "auxiliary_model",
        // "auxiliary_serial",
        // "auxiliary_date_manufactured",
        // "auxiliary_manufacturer",
        // "auxiliary_condition",
        // "auxiliary_eul",
        // "auxiliary_crv",
        "created_at",
        "updated_at"
    ],
    config: {
        code: {
            isVisible: true,
            label: "Asset Code",
            class: "width-130px",
            searchKey: "assets.code",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "code",
            band: bands.basic_asset_information
        },
        asset_name: {
            isVisible: true,
            label: "Asset Name",
            class: "width-220px",
            searchKey: "assets.asset_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "asset_name",
            band: bands.basic_asset_information
        },
        asset_description: {
            isVisible: true,
            label: "Asset Description",
            class: "width-180px",
            searchKey: "asset_description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "asset_description",
            band: bands.basic_asset_information
        },
        asset_type: {
            isVisible: false,
            label: "Asset Type",
            class: "width-160px",
            searchKey: "asset_types.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "assets",
            commonSearchKey: "asset_types",
            commonSearchObjectKey: "name",
            band: bands.basic_asset_information
        },
        asset_status: {
            isVisible: true,
            label: "Asset Status",
            class: "width-160px",
            searchKey: "asset_statuses.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_statuses.id", "asset_statuses.name"],
            commonSearchKey: "asset_statuses",
            commonSearchObjectKey: "name",
            band: bands.basic_asset_information
        },
        client_asset_condition: {
            isVisible: true,
            label: "Asset Condition",
            class: "width-180px",
            searchKey: "client_asset_conditions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["client_asset_conditions.id", "client_asset_conditions.name"],
            commonSearchKey: "client_asset_conditions",
            commonSearchObjectKey: "name",
            band: bands.basic_asset_information
        },
        description: {
            isVisible: true,
            label: "Asset Condition Description",
            class: "width-180px",
            searchKey: "client_asset_conditions.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "client_asset_conditions",
            commonSearchKey: "client_asset_conditions",
            commonSearchObjectKey: "description",
            band: bands.basic_asset_information
        },
        asset_ownership: {
            isVisible: true,
            label: "Asset Ownership",
            class: "width-180px",
            searchKey: "asset_ownership",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["assets.asset_ownership"],
            commonSearchKey: "assets",
            commonSearchObjectKey: "asset_ownership",
            filterIdKey: "asset_ownership",
            band: bands.basic_asset_information,
            smartChartCommonSearchKey: "asset_ownership"
        },
        criticality: {
            isVisible: false,
            label: "Criticality",
            class: "width-180px",
            searchKey: "assets.criticality",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "criticality",
            band: bands.basic_asset_information
        },
        asset_tag: {
            isVisible: true,
            label: "Asset Tag",
            class: "width-140px",
            searchKey: "assets.asset_tag",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "asset_tag",
            band: bands.basic_asset_information
        },
        guid: {
            isVisible: true,
            label: "GUID",
            class: "",
            searchKey: "guid",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "guid",
            band: bands.basic_asset_information
        },
        model_number: {
            isVisible: true,
            label: "Model Number",
            class: "width-160px",
            searchKey: "assets.model_number",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "model_number",
            band: bands.basic_asset_information
        },
        core_model_number: {
            isVisible: true,
            label: "Core Model Number",
            class: "width-160px",
            searchKey: "assets.core_model_number",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "core_model_number",
            band: bands.basic_asset_information
        },
        serial_number: {
            isVisible: true,
            label: "Serial Number",
            class: "width-160px",
            searchKey: "assets.serial_number",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "serial_number",
            band: bands.basic_asset_information
        },
        capacity: {
            isVisible: false,
            label: "Capacity",
            class: "width-140px",
            searchKey: "assets.capacity",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "capacity",
            band: bands.basic_asset_information
        },
        capacity_unit: {
            isVisible: false,
            label: "Capacity Unit",
            class: "width-140px",
            searchKey: "assets.capacity_unit",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "capacity_unit",
            band: bands.basic_asset_information
        },
        capacity_status: {
            isVisible: false,
            label: "Capacity Status",
            class: "width-160px",
            searchKey: "assets.capacity_status",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "capacity_status",
            band: bands.basic_asset_information
        },
        asset_barcode: {
            isVisible: false,
            label: "Asset Barcode",
            class: "width-160px",
            searchKey: "asset_barcode",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "asset_barcode",
            band: bands.basic_asset_information
        },
        asset_client_id: {
            isVisible: false,
            label: "Asset Client ID",
            class: "width-160px",
            searchKey: "asset_client_id",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "asset_client_id",
            band: bands.basic_asset_information
        },
        asset_cmms_id: {
            isVisible: false,
            label: "Asset CMMS ID",
            class: "width-160px",
            searchKey: "asset_cmms_id",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "asset_cmms_id",
            band: bands.basic_asset_information
        },
        rtls_tag: {
            isVisible: false,
            label: "RTLS Tag",
            class: "width-160px",
            searchKey: "assets.rtls_tag",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "rtls_tag",
            band: bands.basic_asset_information
        },
        manufacturer: {
            isVisible: true,
            label: "Manufacturer",
            class: "width-160px",
            searchKey: "assets.manufacturer",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "manufacturer",
            band: bands.extended_asset_information
        },
        year_manufactured: {
            isVisible: false,
            label: "Year Manufactured",
            class: "width-180px",
            searchKey: "year_manufactured",
            type: "number",
            isYear: true,
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "year_manufactured",
            band: bands.extended_asset_information
        },
        installed_year: {
            isVisible: true,
            label: "Installed Year",
            class: "width-140px",
            searchKey: "assets.installed_year",
            type: "number",
            isYear: true,
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "installed_year",
            band: bands.extended_asset_information
        },
        current_age: {
            isVisible: true,
            label: "Current Age",
            class: "width-160px",
            searchKey: "assets.current_age",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "current_age",
            band: bands.extended_asset_information
        },
        service_life: {
            isVisible: true,
            label: "Service Life",
            class: "width-140px",
            searchKey: "assets.service_life",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "service_life",
            band: bands.extended_asset_information
        },
        end_of_service_life: {
            isVisible: true,
            label: "End of Service Life",
            class: "width-140px",
            searchKey: "assets.end_of_service_life",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "end_of_service_life",
            band: bands.extended_asset_information
        },
        usefull_life_remaining: {
            isVisible: true,
            label: "Useful Life Remaining",
            class: "width-140px",
            searchKey: "assets.usefull_life_remaining",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "usefull_life_remaining",
            band: bands.extended_asset_information
        },
        csp_unit: {
            isVisible: true,
            label: "CSP Unit",
            class: "width-200px",
            searchKey: "assets.csp_unit",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "csp_unit",
            band: bands.extended_asset_information
        },
        unit_cost: {
            isVisible: true,
            label: "Unit Cost",
            class: "width-200px",
            searchKey: "assets.unit_cost",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "unit_cost",
            band: bands.extended_asset_information
        },
        crv: {
            isVisible: true,
            label: "CRV",
            class: "width-140px",
            searchKey: "assets.crv",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "crv",
            band: bands.extended_asset_information
        },
        energy_use_type: {
            isVisible: true,
            label: "Energy Use Type",
            class: "width-140px",
            searchKey: "assets.energy_use_type",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "energy_use_type",
            band: bands.extended_asset_information
        },
        refrigerant: {
            isVisible: true,
            label: "Refrigerant",
            class: "width-140px",
            searchKey: "assets.refrigerant",
            type: "text",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "refrigerant",
            band: bands.extended_asset_information
        },
        warranty_start: {
            isVisible: false,
            label: "Warranty Start",
            class: "width-180px",
            searchKey: "warranty_start",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "warranty_start",
            band: bands.extended_asset_information
        },
        warranty_end: {
            isVisible: false,
            label: "Warranty End",
            class: "width-180px",
            searchKey: "warranty_end",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "warranty_end",
            band: bands.extended_asset_information
        },
        install_date: {
            isVisible: false,
            label: "Install Date",
            class: "width-180px",
            searchKey: "install_date",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "install_date",
            band: bands.extended_asset_information
        },
        startup_date: {
            isVisible: false,
            label: "Startup Date",
            class: "width-180px",
            searchKey: "startup_date",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "startup_date",
            band: bands.extended_asset_information
        },
        fca_cost: {
            isVisible: true,
            label: "FCA Cost",
            class: "width-140px",
            searchKey: "assets.fca_cost",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "fca_cost",
            band: bands.extended_asset_information
        },
        uniformat_level_1: {
            isVisible: true,
            label: "Uniformat Level 1",
            class: "",
            searchKey: "uniformat_level_1s.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["uniformat_level_1s.id", "uniformat_level_1s.name"],
            commonSearchKey: "uniformat_level_1s",
            commonSearchObjectKey: "name",
            filterIdKey: "uniformat_level_1_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "uniformat_level_1_ids"
        },
        uniformat_level_2: {
            isVisible: true,
            label: "Uniformat Level 2",
            class: "",
            searchKey: "uniformat_level_2s.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["uniformat_level_2s.id", "uniformat_level_2s.name"],
            commonSearchKey: "uniformat_level_2s",
            commonSearchObjectKey: "name",
            filterIdKey: "uniformat_level_2_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "uniformat_level_2_ids"
        },
        uniformat_level_3: {
            isVisible: true,
            label: "Uniformat Level 3",
            class: "",
            searchKey: "uniformat_level_3s.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["uniformat_level_3s.id", "uniformat_level_3s.name"],
            commonSearchKey: "uniformat_level_3s",
            commonSearchObjectKey: "name",
            filterIdKey: "uniformat_level_3_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "uniformat_level_3_ids"
        },
        uniformat_level_4: {
            isVisible: true,
            label: "Uniformat Level 4",
            class: "",
            searchKey: "uniformat_level_4s.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["uniformat_level_4s.id", "uniformat_level_4s.name"],
            commonSearchKey: "uniformat_level_4s",
            commonSearchObjectKey: "name",
            filterIdKey: "uniformat_level_4_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "uniformat_level_4_ids"
        },
        uniformat_level_5: {
            isVisible: true,
            label: "Uniformat Level 5",
            class: "",
            searchKey: "uniformat_level_5s.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["uniformat_level_5s.id", "uniformat_level_5s.name"],
            commonSearchKey: "uniformat_level_5s",
            commonSearchObjectKey: "name",
            filterIdKey: "uniformat_level_5_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "uniformat_level_5_ids"
        },
        uniformat_level_6: {
            isVisible: true,
            label: "Uniformat Level 6",
            class: "",
            searchKey: "uniformat_level_6s.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["uniformat_level_6s.id", "uniformat_level_6s.name"],
            commonSearchKey: "uniformat_level_6s",
            commonSearchObjectKey: "name",
            filterIdKey: "uniformat_level_6_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "uniformat_level_6_ids"
        },
        uniformat_level_6_description: {
            isVisible: true,
            label: "Uniformat Level 6 Description",
            class: "",
            searchKey: "uniformat_level_6_description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "uniformat_level_6_description",
            commonSearchKey: "uniformat_level_6_description",
            commonSearchObjectKey: "name",
            band: bands.extended_asset_information
        },
        uniformat_level_3_custom_description: {
            isVisible: true,
            label: "Uniformat Level 3 Custom Description",
            class: "",
            searchKey: "uniformat_level_3_custom_description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "uniformat_level_3_custom_description",
            band: bands.extended_asset_information
        },

        main_category: {
            isVisible: true,
            label: "Main Category",
            class: "",
            searchKey: "main_categories.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["main_categories.id", "main_categories.name"],
            commonSearchKey: "main_categories",
            commonSearchObjectKey: "name",
            filterIdKey: "main_category_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "main_category_ids"
        },
        sub_category_1: {
            isVisible: true,
            label: "Sub Category 1",
            class: "",
            searchKey: "sub_category_1.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["sub_category_1.id", "sub_category_1.name"],
            commonSearchKey: "sub_category_1",
            commonSearchObjectKey: "name",
            filterIdKey: "sub_category_1_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "sub_category_1_ids"
        },
        sub_category_2: {
            isVisible: true,
            label: "Sub Category 2",
            class: "",
            searchKey: "sub_category_2.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["sub_category_2.id", "sub_category_2.name"],
            commonSearchKey: "sub_category_2",
            commonSearchObjectKey: "name",
            filterIdKey: "sub_category_2_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "sub_category_2_ids"
        },
        subcategory2_description: {
            isVisible: true,
            label: "Sub Category 2 Description",
            class: "",
            searchKey: "subcategory2_description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "sub_category_2",
            commonSearchKey: "sub_category_2",
            commonSearchObjectKey: "subcategory2_description",
            band: bands.extended_asset_information
        },
        sub_category_3: {
            isVisible: true,
            label: "Sub Category 3",
            class: "",
            searchKey: "sub_category_3.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["sub_category_3.id", "sub_category_3.name"],
            commonSearchKey: "sub_category_3",
            commonSearchObjectKey: "name",
            filterIdKey: "sub_category_3_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "sub_category_3_ids"
        },
        trade: {
            isVisible: true,
            label: "Trade",
            class: "width-140px",
            searchKey: "trades.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["trades.id", "trades.name"],
            commonSearchKey: "trades",
            commonSearchObjectKey: "name",
            filterIdKey: "trade_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "trade_ids"
        },
        system: {
            isVisible: true,
            label: "System",
            class: "width-160px",
            searchKey: "systems.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["systems.id", "systems.name"],
            commonSearchKey: "systems",
            commonSearchObjectKey: "name",
            filterIdKey: "system_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "system_ids"
        },
        sub_system: {
            isVisible: true,
            label: "Sub-System",
            class: "width-160px",
            searchKey: "sub_systems.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["sub_systems.id", "sub_systems.name"],
            commonSearchKey: "sub_systems",
            commonSearchObjectKey: "name",
            filterIdKey: "sub_system_ids",
            band: bands.extended_asset_information,
            smartChartCommonSearchKey: "sub_system_ids"
        },
        asset_note: {
            isVisible: false,
            label: "Asset Note",
            class: "width-120px",
            searchKey: "asset_note",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "asset_note",
            band: bands.extended_asset_information
        },
        client: {
            isVisible: true,
            label: "Client",
            class: "width-200px",
            searchKey: "clients.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["clients.id", "clients.name"],
            commonSearchKey: "clients",
            commonSearchObjectKey: "name",
            band: bands.geo_hierarchy
        },
        region: {
            isVisible: true,
            label: "Region",
            class: "width-140px",
            searchKey: "regions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["regions.id", "regions.name"],
            commonSearchKey: "regions",
            commonSearchObjectKey: "name",
            filterIdKey: "region_ids",
            band: bands.geo_hierarchy,
            smartChartCommonSearchKey: "region_ids"
        },
        site: {
            isVisible: true,
            label: "Site",
            class: "width-200px",
            searchKey: "sites.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["sites.id", "sites.name"],
            commonSearchKey: "sites",
            commonSearchObjectKey: "name",
            filterIdKey: "site_ids",
            band: bands.geo_hierarchy,
            smartChartCommonSearchKey: "site_ids"
        },
        building: {
            isVisible: true,
            label: "Building",
            class: "width-140px",
            searchKey: "buildings.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["buildings.id", "buildings.name"],
            commonSearchKey: "buildings",
            commonSearchObjectKey: "name",
            filterIdKey: "building_ids",
            band: bands.geo_hierarchy,
            smartChartCommonSearchKey: "building_ids"
        },
        buildingDescription: {
            isVisible: true,
            label: "Building Description",
            class: "width-200px",
            searchKey: "buildings.description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: "building",
            commonSearchKey: "buildings",
            commonSearchObjectKey: "description",
            band: bands.geo_hierarchy
        },
        building_type: {
            isVisible: true,
            label: "Building Type",
            class: "width-160px",
            searchKey: "main_building_types.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["main_building_types.id", "main_building_types.name"],
            commonSearchKey: "main_building_types",
            commonSearchObjectKey: "name",
            filterIdKey: "building_type_ids",
            band: bands.geo_hierarchy,
            smartChartCommonSearchKey: "building_type_ids"
        },
        addition: {
            isVisible: true,
            label: "Addition",
            class: "width-140px",
            searchKey: "additions.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["additions.id", "additions.name"],
            commonSearchKey: "additions",
            commonSearchObjectKey: "name",
            filterIdKey: "addition_ids",
            band: bands.geo_hierarchy,
            smartChartCommonSearchKey: "addition_ids"
        },
        floor: {
            isVisible: true,
            label: "Floor",
            class: "width-180px",
            searchKey: "floors.name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["floors.id", "floors.name"],
            commonSearchKey: "floors",
            commonSearchObjectKey: "name",
            filterIdKey: "floor_ids",
            band: bands.geo_hierarchy,
            smartChartCommonSearchKey: "floor_ids"
        },
        room_number: {
            isVisible: true,
            label: "Room Number",
            class: "width-160px",
            searchKey: "room_number",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "room_number",
            band: bands.geo_hierarchy
        },
        room_name: {
            isVisible: false,
            label: "Room Name",
            class: "width-160px",
            searchKey: "room_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "room_name",
            band: bands.geo_hierarchy
        },
        location: {
            isVisible: false,
            label: "Location",
            class: "width-160px",
            searchKey: "location",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "location",
            band: bands.geo_hierarchy
        },
        architectural_room_number: {
            isVisible: true,
            label: "Architectural Room Number",
            class: "width-200px",
            searchKey: "architectural_room_number",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "architectural_room_number",
            band: bands.geo_hierarchy
        },
        additional_room_description: {
            isVisible: false,
            label: "Additional Room Description",
            class: "width-200px",
            searchKey: "additional_room_description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "additional_room_description",
            band: bands.geo_hierarchy
        },
        latitude: {
            isVisible: false,
            label: "Latitude",
            class: "width-160px",
            searchKey: "assets.latitude",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "latitude",
            band: bands.geo_hierarchy
        },
        longitude: {
            isVisible: false,
            label: "Longitude",
            class: "width-160px",
            searchKey: "assets.longitude",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "longitude",
            band: bands.geo_hierarchy
        },
        area_served: {
            isVisible: false,
            label: "Area Served",
            class: "width-120px",
            searchKey: "area_served",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "area_served",
            band: bands.asset_parent_hierarchy
        },
        upstream_asset_barcode_number: {
            isVisible: false,
            label: "Upstream Asset Barcode Numbers",
            class: "",
            searchKey: "upstream_asset_barcode_number",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "upstream_asset_barcode_number",
            band: bands.asset_parent_hierarchy
        },
        linked_asset_barcode_number: {
            isVisible: false,
            label: "Linked Asset Barcode Numbers",
            class: "",
            searchKey: "linked_asset_barcode_number",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "linked_asset_barcode_number",
            band: bands.asset_parent_hierarchy
        },
        source_panel_name: {
            isVisible: false,
            label: "Source Panel Name",
            class: "",
            searchKey: "source_panel_name",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "source_panel_name",
            band: bands.asset_parent_hierarchy
        },
        source_panel_barcode_number: {
            isVisible: false,
            label: "Source Panel Barcode Number",
            class: "",
            searchKey: "source_panel_barcode_number",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "source_panel_barcode_number",
            band: bands.asset_parent_hierarchy
        },
        source_panel: {
            isVisible: false,
            label: "Source Panel Emergency/Normal",
            class: "",
            searchKey: "source_panel",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "source_panel",
            band: bands.asset_parent_hierarchy
        },
        skysite_hyperlink: {
            isVisible: false,
            label: "Skysite Hyperlink",
            class: "",
            searchKey: "skysite_hyperlink",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "skysite_hyperlink",
            band: bands.asset_parent_hierarchy
        },
        quantity: {
            isVisible: false,
            label: "Quantity",
            class: "width-120px",
            searchKey: "quantity",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "quantity",
            commonSearchObjectKey: "quantity",
            band: bands.asset_parent_hierarchy
        },
        parent_global_id: {
            isVisible: false,
            label: "Parent Global ID",
            class: "width-160px",
            searchKey: "assets.parent_global_id",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "parent_global_id",
            band: bands.asset_parent_hierarchy
        },
        // age: {
        //     isVisible: true,
        //     label: "Age",
        //     class: "width-160px",
        //     searchKey: "assets.age",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "assets",
        //     commonSearchKey: "assets",
        //     commonSearchObjectKey: "age",
        // },

        has_recommendation: {
            isVisible: true,
            label: "Has Recommendation",
            class: "width-160px",
            searchKey: "assets.has_recommendation",
            type: "boolean",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["assets.has_recommendation"],
            commonSearchKey: "assets",
            commonSearchObjectKey: "has_recommendation",
            filterIdKey: "has_recommendation",
            band: bands.basic_asset_information,
            smartChartCommonSearchKey: "has_recommendation"
        },
        has_image: {
            label: "Has Image",
            searchKey: "has_image"
        },
        survey_id: {
            isVisible: false,
            label: "Asset Survey ID",
            class: "width-160px",
            searchKey: "assets.survey_id",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "survey_id",
            band: bands.asset_qa_qc
        },
        survey_property_note: {
            isVisible: false,
            label: "Asset Survey Property Notes",
            class: "width-160px",
            searchKey: "assets.survey_property_note",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "survey_property_note",
            band: bands.asset_qa_qc
        },
        installed_year_status: {
            isVisible: false,
            label: "Install Year Status",
            class: "width-160px",
            searchKey: "assets.installed_year_status",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "installed_year_status",
            band: bands.asset_qa_qc
        },
        name_plate_status: {
            isVisible: false,
            label: "Name Plate Status",
            class: "width-160px",
            searchKey: "assets.name_plate_status",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "name_plate_status",
            band: bands.asset_qa_qc
        },
        qa_notes: {
            isVisible: false,
            label: "Asset Survey QA/QC Notes",
            class: "width-160px",
            searchKey: "assets.qa_notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "qa_notes",
            band: bands.asset_qa_qc
        },
        additional_qa_notes: {
            isVisible: false,
            label: "Asset Survey Additional QA/QC Notes",
            class: "width-160px",
            searchKey: "assets.additional_qa_notes",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "additional_qa_notes",
            band: bands.asset_qa_qc
        },
        survey_date_created: {
            isVisible: false,
            label: "Asset Survey Date Created",
            class: "width-160px",
            searchKey: "assets.survey_date_created",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "survey_date_created"
        },
        surveyor: {
            isVisible: false,
            label: "Asset Survey Surveyor",
            class: "width-160px",
            searchKey: "assets.surveyor",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "surveyor",
            band: bands.asset_qa_qc
        },
        survey_date_edited: {
            isVisible: false,
            label: "Asset Survey Date Edited",
            class: "width-160px",
            searchKey: "assets.survey_date_edited",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "survey_date_edited"
        },
        editor: {
            isVisible: false,
            label: "Asset Survey Editor",
            class: "width-160px",
            searchKey: "assets.editor",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "editor",
            band: bands.asset_qa_qc
        },
        new_asset: {
            isVisible: true,
            label: "Is This a New Asset",
            class: "width-160px",
            searchKey: "assets.new_asset",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["assets.new_asset"],
            commonSearchKey: "assets",
            commonSearchObjectKey: "new_asset",
            filterIdKey: "is_new_asset",
            band: bands.asset_qa_qc,
            smartChartCommonSearchKey: "is_new_asset"
        },

        discharge: {
            isVisible: false,
            label: "Discharge",
            class: "width-180px",
            searchKey: "asset_additionals.discharge",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.discharge"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "discharge",
            band: bands.asset_additional
        },
        return: {
            isVisible: false,
            label: "Return",
            class: "width-180px",
            searchKey: "asset_additionals.return",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.return"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "return",
            band: bands.asset_additional
        },
        unit_property_labeled: {
            isVisible: false,
            label: "Is Unit Properly Labeled",
            class: "width-180px",
            searchKey: "asset_additionals.unit_property_labeled",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.unit_property_labeled"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "unit_property_labeled",
            band: bands.asset_additional
        },
        factory_mounted_disconnect: {
            isVisible: false,
            label: "Factory Mounted Disconnect",
            class: "width-180px",
            searchKey: "asset_additionals.factory_mounted_disconnect",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.factory_mounted_disconnect"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "factory_mounted_disconnect",
            band: bands.asset_additional
        },
        unit_voltage: {
            isVisible: false,
            label: "Unit Voltage",
            class: "width-180px",
            searchKey: "asset_additionals.unit_voltage",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.unit_voltage"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "unit_voltage",
            band: bands.asset_additional
        },
        ahu_voltage: {
            isVisible: false,
            label: "Ahu Voltage",
            class: "width-180px",
            searchKey: "asset_additionals.ahu_voltage",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.ahu_voltage"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "ahu_voltage",
            band: bands.asset_additional
        },
        wire_type: {
            isVisible: false,
            label: "Wire Type",
            class: "width-180px",
            searchKey: "asset_additionals.wire_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.wire_type"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "wire_type",
            band: bands.asset_additional
        },
        wire_size: {
            isVisible: false,
            label: "Wire Size",
            class: "width-180px",
            searchKey: "asset_additionals.wire_size",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.wire_size"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "wire_size",
            band: bands.asset_additional
        },
        estimated_wire_run_length: {
            isVisible: false,
            label: "Estimated Wire Run Length",
            class: "width-180px",
            searchKey: "asset_additionals.estimated_wire_run_length",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.estimated_wire_run_length"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "estimated_wire_run_length",
            band: bands.asset_additional
        },
        breaker_size: {
            isVisible: false,
            label: "Breaker Size",
            class: "width-180px",
            searchKey: "asset_additionals.breaker_size",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.breaker_size"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "breaker_size",
            band: bands.asset_additional
        },
        mocp: {
            isVisible: false,
            label: "MOCP",
            class: "width-180px",
            searchKey: "asset_additionals.mocp",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.mocp"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "mocp",
            band: bands.asset_additional
        },
        mca: {
            isVisible: false,
            label: "MCA",
            class: "width-180px",
            searchKey: "asset_additionals.mca",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.mca"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "mca",
            band: bands.asset_additional
        },
        liquid_line: {
            isVisible: false,
            label: "Liquid Line",
            class: "width-180px",
            searchKey: "asset_additionals.liquid_line",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.liquid_line"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "liquid_line",
            band: bands.asset_additional
        },
        suction_line: {
            isVisible: false,
            label: "Suction Line",
            class: "width-180px",
            searchKey: "asset_additionals.suction_line",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.suction_line"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "suction_line",
            band: bands.asset_additional
        },
        hot_gas_by_pass: {
            isVisible: false,
            label: "Hot Gas By-Pass",
            class: "width-180px",
            searchKey: "asset_additionals.hot_gas_by_pass",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.hot_gas_by_pass"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "hot_gas_by_pass",
            band: bands.asset_additional
        },
        number_of_circuits: {
            isVisible: false,
            label: "Number Of Circuits",
            class: "width-180px",
            searchKey: "asset_additionals.number_of_circuits",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.number_of_circuits"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "number_of_circuits",
            band: bands.asset_additional
        },
        length_of_line: {
            isVisible: false,
            label: "Length of Line Run",
            class: "width-180px",
            searchKey: "asset_additionals.length_of_line",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.length_of_line"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "length_of_line",
            band: bands.asset_additional
        },
        damper_type: {
            isVisible: false,
            label: "Damper Type",
            class: "width-180px",
            searchKey: "asset_additionals.damper_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.damper_type"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "damper_type",
            band: bands.asset_additional
        },
        gas_heating: {
            isVisible: false,
            label: "Gas Heating",
            class: "width-180px",
            searchKey: "asset_additionals.gas_heating",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.gas_heating"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "gas_heating",
            band: bands.asset_additional
        },
        gas_output_btu: {
            isVisible: false,
            label: "Gas Output BTU",
            class: "width-180px",
            searchKey: "asset_additionals.gas_output_btu",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.gas_output_btu"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "gas_output_btu",
            band: bands.asset_additional
        },
        electric_heating: {
            isVisible: false,
            label: "Electric Heating",
            class: "width-180px",
            searchKey: "asset_additionals.electric_heating",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.electric_heating"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "electric_heating",
            band: bands.asset_additional
        },
        electric_heating_kw: {
            isVisible: false,
            label: "Electric Heating KW",
            class: "width-180px",
            searchKey: "asset_additionals.electric_heating_kw",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.electric_heating_kw"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "electric_heating_kw",
            band: bands.asset_additional
        },
        pump_backup_heat_kw: {
            isVisible: false,
            label: "Heat Pump - Backup Heat KW",
            class: "width-180px",
            searchKey: "asset_additionals.pump_backup_heat_kw",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.pump_backup_heat_kw"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "pump_backup_heat_kw",
            band: bands.asset_additional
        },
        cooling_only: {
            isVisible: false,
            label: "Cooling Only",
            class: "width-180px",
            searchKey: "asset_additionals.cooling_only",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.cooling_only"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "cooling_only",
            band: bands.asset_additional
        },
        controls: {
            isVisible: false,
            label: "Controls",
            class: "width-180px",
            searchKey: "asset_additionals.controls",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.controls"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "controls",
            band: bands.asset_additional
        },
        control_type: {
            isVisible: false,
            label: "Controls Type",
            class: "width-180px",
            searchKey: "asset_additionals.control_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.control_type"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "control_type",
            band: bands.asset_additional
        },
        smoke_detector: {
            isVisible: false,
            label: "Smoke Detector",
            class: "width-180px",
            searchKey: "asset_additionals.smoke_detector",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.smoke_detector"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "smoke_detector",
            band: bands.asset_additional
        },
        smoke_detector_mounting: {
            isVisible: false,
            label: "Smoke Detector Mounting",
            class: "width-180px",
            searchKey: "asset_additionals.smoke_detector_mounting",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.smoke_detector_mounting"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "smoke_detector_mounting",
            band: bands.asset_additional
        },
        smoke_detector_supply: {
            isVisible: false,
            label: "Smoke Detector-Supply",
            class: "width-180px",
            searchKey: "asset_additionals.smoke_detector_supply",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.smoke_detector_supply"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "smoke_detector_supply",
            band: bands.asset_additional
        },
        smoke_detector_return: {
            isVisible: false,
            label: "Smoke Detector-Return",
            class: "width-180px",
            searchKey: "asset_additionals.smoke_detector_return",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.smoke_detector_return"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "smoke_detector_return",
            band: bands.asset_additional
        },
        curb: {
            isVisible: false,
            label: "Curb",
            class: "width-180px",
            searchKey: "asset_additionals.curb",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.curb"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "curb",
            band: bands.asset_additional
        },
        curb_description: {
            isVisible: false,
            label: "Curb Other Description",
            class: "width-180px",
            searchKey: "asset_additionals.curb_description",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.curb_description"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "curb_description",
            band: bands.asset_additional
        },
        vibration_isolation: {
            isVisible: false,
            label: "Vibration Isolation",
            class: "width-180px",
            searchKey: "asset_additionals.vibration_isolation",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.vibration_isolation"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "vibration_isolation",
            band: bands.asset_additional
        },
        burglar_bars: {
            isVisible: false,
            label: "Burglar Bars",
            class: "width-180px",
            searchKey: "asset_additionals.burglar_bars",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.burglar_bars"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "burglar_bars",
            band: bands.asset_additional
        },
        installed_on_adapter_curb: {
            isVisible: false,
            label: "Existing Unit Installed on an Adapter Curb?",
            class: "width-180px",
            searchKey: "asset_additionals.installed_on_adapter_curb",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.installed_on_adapter_curb"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "installed_on_adapter_curb",
            band: bands.asset_additional
        },
        curb_outside_dimension_length: {
            isVisible: false,
            label: "Existing Curb Outside Dimension Length",
            class: "width-180px",
            searchKey: "asset_additionals.curb_outside_dimension_length",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.curb_outside_dimension_length"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "curb_outside_dimension_length",
            band: bands.asset_additional
        },
        curb_outside_dimension_width: {
            isVisible: false,
            label: "Existing Curb Outside Dimension Width",
            class: "width-180px",
            searchKey: "asset_additionals.curb_outside_dimension_width",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.curb_outside_dimension_width"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "curb_outside_dimension_width",
            band: bands.asset_additional
        },
        allow_curb_adaptor: {
            isVisible: false,
            label: "Will Mall, Landlord, Municipality Allow a Curb Adapter?",
            class: "width-180px",
            searchKey: "asset_additionals.allow_curb_adaptor",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.allow_curb_adaptor"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "allow_curb_adaptor",
            band: bands.asset_additional
        },
        ducted_or_concentric_system: {
            isVisible: false,
            label: "Is this Ducted or Concentric System",
            class: "width-180px",
            searchKey: "asset_additionals.ducted_or_concentric_system",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.ducted_or_concentric_system"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "ducted_or_concentric_system",
            band: bands.asset_additional
        },
        unit_weight: {
            isVisible: false,
            label: "Unit Weight",
            class: "width-180px",
            searchKey: "asset_additionals.unit_weight",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.unit_weight"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "unit_weight",
            band: bands.asset_additional
        },
        codes_for_disconnects: {
            isVisible: false,
            label: "Any Special Codes for Disconnects",
            class: "width-180px",
            searchKey: "asset_additionals.codes_for_disconnects",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.codes_for_disconnects"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "codes_for_disconnects",
            band: bands.asset_additional
        },
        codes_for_convenience_outlets: {
            isVisible: false,
            label: "Any Special Codes for Convenience Outlets",
            class: "width-180px",
            searchKey: "asset_additionals.codes_for_convenience_outlets",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.codes_for_convenience_outlets"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "codes_for_convenience_outlets",
            band: bands.asset_additional
        },
        codes_for_roof_lighting: {
            isVisible: false,
            label: "Any Special Codes for Roof Lighting",
            class: "width-180px",
            searchKey: "asset_additionals.codes_for_roof_lighting",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.codes_for_roof_lighting"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "codes_for_roof_lighting",
            band: bands.asset_additional
        },
        duct_work_attached_to_bottom_of_unit: {
            isVisible: false,
            label: "Current Duct Work Attached to Bottom of Unit",
            class: "width-180px",
            searchKey: "asset_additionals.duct_work_attached_to_bottom_of_unit",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.duct_work_attached_to_bottom_of_unit"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "duct_work_attached_to_bottom_of_unit",
            band: bands.asset_additional
        },
        aluminium_wire_used: {
            isVisible: false,
            label: "Was Aluminium Wire Used",
            class: "width-180px",
            searchKey: "asset_additionals.aluminium_wire_used",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.aluminium_wire_used"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "aluminium_wire_used",
            band: bands.asset_additional
        },
        code_or_safety_concerns: {
            isVisible: false,
            label: "Are there any Codes or Safety Concerns",
            class: "width-180px",
            searchKey: "asset_additionals.code_or_safety_concerns",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.code_or_safety_concerns"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "code_or_safety_concerns",
            band: bands.asset_additional
        },
        distance_from_salt_water_body: {
            isVisible: false,
            label: "Distance from Body Of Salt Water",
            class: "width-180px",
            searchKey: "asset_additionals.distance_from_salt_water_body",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.distance_from_salt_water_body"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "distance_from_salt_water_body",
            band: bands.asset_additional
        },
        recommend_coil_coating: {
            isVisible: false,
            label: "Recommend Coil Coating",
            class: "width-180px",
            searchKey: "asset_additionals.recommend_coil_coating",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.recommend_coil_coating"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "recommend_coil_coating",
            band: bands.asset_additional
        },
        need_resealed: {
            isVisible: false,
            label: "Do Electrical/Piping Roof Penetrations Need To Be Resealed",
            class: "width-180px",
            searchKey: "asset_additionals.need_resealed",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.need_resealed"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "need_resealed",
            band: bands.asset_additional
        },
        split_system_line_sets_good_condition: {
            isVisible: false,
            label: "Split System - Are Line Sets Good Condition",
            class: "width-180px",
            searchKey: "asset_additionals.split_system_line_sets_good_condition",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.split_system_line_sets_good_condition"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "split_system_line_sets_good_condition",
            band: bands.asset_additional
        },
        split_system_line_sets_damaged: {
            isVisible: false,
            label: "Split System - Are Line Sets Damaged in Any Way",
            class: "width-180px",
            searchKey: "asset_additionals.split_system_line_sets_damaged",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.split_system_line_sets_damaged"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "split_system_line_sets_damaged",
            band: bands.asset_additional
        },
        split_system_line_sets_crimped: {
            isVisible: false,
            label: "Split System - Are Line Sets Crimped",
            class: "width-180px",
            searchKey: "asset_additionals.split_system_line_sets_crimped",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.split_system_line_sets_crimped"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "split_system_line_sets_crimped",
            band: bands.asset_additional
        },
        split_system_line_sets_insulated: {
            isVisible: false,
            label: "Split System - Are Line Sets Insulated",
            class: "width-180px",
            searchKey: "asset_additionals.split_system_line_sets_insulated",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.split_system_line_sets_insulated"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "split_system_line_sets_insulated",
            band: bands.asset_additional
        },
        split_system_line_sets_re_used: {
            isVisible: false,
            label: "Split System - Can Line Sets Be Re-Used",
            class: "width-180px",
            searchKey: "asset_additionals.split_system_line_sets_re_used",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.split_system_line_sets_re_used"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "split_system_line_sets_re_used",
            band: bands.asset_additional
        },
        line_connection_type: {
            isVisible: false,
            label: "Line Connection Type",
            class: "width-180px",
            searchKey: "asset_additionals.line_connection_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.line_connection_type"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "line_connection_type",
            band: bands.asset_additional
        },
        boiler_type: {
            isVisible: false,
            label: "Boiler Type",
            class: "width-180px",
            searchKey: "asset_additionals.boiler_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.boiler_type"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "boiler_type",
            band: bands.boiler_fields
        },
        boiler_burner_type: {
            isVisible: false,
            label: "Boiler Burner Type",
            class: "width-180px",
            searchKey: "asset_additionals.boiler_burner_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.boiler_burner_type"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "boiler_burner_type",
            band: bands.boiler_fields
        },
        boiler_btu_input: {
            isVisible: false,
            label: "Boiler BTU Input",
            class: "width-180px",
            searchKey: "asset_additionals.boiler_btu_input",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.boiler_btu_input"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "boiler_btu_input",
            band: bands.boiler_fields
        },
        boiler_btu_output: {
            isVisible: false,
            label: "Boiler BTU Output",
            class: "width-180px",
            searchKey: "asset_additionals.boiler_btu_output",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.boiler_btu_output"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "boiler_btu_output",
            band: bands.boiler_fields
        },
        boiler_hp: {
            isVisible: false,
            label: "Boiler HP",
            class: "width-180px",
            searchKey: "asset_additionals.boiler_hp",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.boiler_hp"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "boiler_hp",
            band: bands.boiler_fields
        },
        boiler_fuel: {
            isVisible: false,
            label: "Boiler Fuel",
            class: "width-180px",
            searchKey: "asset_additionals.boiler_fuel",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.boiler_fuel"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "boiler_fuel",
            band: bands.boiler_fields
        },
        boiler_gas_pressure: {
            isVisible: false,
            label: "Boiler Gas Pressure",
            class: "width-180px",
            searchKey: "asset_additionals.boiler_gas_pressure",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.boiler_gas_pressure"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "boiler_gas_pressure",
            band: bands.boiler_fields
        },
        boiler_kw_input: {
            isVisible: false,
            label: "Boiler KW Input",
            class: "width-180px",
            searchKey: "asset_additionals.boiler_kw_input",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.boiler_kw_input"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "boiler_kw_input",
            band: bands.boiler_fields
        },
        boiler_power_burner_type: {
            isVisible: false,
            label: "Boiler Power Burner Type",
            class: "width-180px",
            searchKey: "asset_additionals.boiler_power_burner_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.boiler_power_burner_type"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "boiler_power_burner_type",
            band: bands.boiler_fields
        },
        boiler_burner_model: {
            isVisible: false,
            label: "Boiler Burner Model",
            class: "width-180px",
            searchKey: "asset_additionals.boiler_burner_model",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.boiler_burner_model"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "boiler_burner_model",
            band: bands.boiler_fields
        },
        boiler_burner_serial: {
            isVisible: false,
            label: "Boiler Burner Serial",
            class: "width-180px",
            searchKey: "asset_additionals.boiler_burner_serial",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.boiler_burner_serial"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "boiler_burner_serial",
            band: bands.boiler_fields
        },
        boiler_pressure_type: {
            isVisible: false,
            label: "Boiler Operating Pressure Type",
            class: "width-180px",
            searchKey: "asset_additionals.boiler_pressure_type",
            type: "string",
            hasWildCardSearch: true,
            hasCommonSearch: true,
            getListTable: ["asset_additionals.boiler_pressure_type"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "boiler_pressure_type",
            band: bands.boiler_fields
        },
        confirm_boiler_pressure: {
            isVisible: false,
            label: "Confirm Boiler Pressure",
            class: "width-180px",
            searchKey: "asset_additionals.confirm_boiler_pressure",
            type: "number",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: ["asset_additionals.confirm_boiler_pressure"],
            commonSearchKey: "asset_additionals",
            commonSearchObjectKey: "confirm_boiler_pressure",
            band: bands.boiler_fields
        },
        // auxiliary_equipment_name: {
        //     isVisible: false,
        //     label: "Auxiliary Equipment Name",
        //     class: "width-180px",
        //     searchKey: "assets.auxiliary_equipment_name",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "assets",
        //     commonSearchKey: "assets",
        //     commonSearchObjectKey: "auxiliary_equipment_name",
        //     band: bands.auxiliary_equipment_fields
        // },
        // auxiliary_model: {
        //     isVisible: false,
        //     label: "Auxiliary Model",
        //     class: "width-180px",
        //     searchKey: "assets.auxiliary_model",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "assets",
        //     commonSearchKey: "assets",
        //     commonSearchObjectKey: "auxiliary_model",
        //     band: bands.auxiliary_equipment_fields
        // },
        // auxiliary_serial: {
        //     isVisible: false,
        //     label: "Auxiliary Serial",
        //     class: "width-180px",
        //     searchKey: "assets.auxiliary_serial",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "assets",
        //     commonSearchKey: "assets",
        //     commonSearchObjectKey: "auxiliary_serial",
        //     band: bands.auxiliary_equipment_fields
        // },
        // auxiliary_date_manufactured: {
        //     isVisible: false,
        //     label: "Auxiliary Date Manufactured",
        //     class: "width-180px",
        //     searchKey: "assets.auxiliary_date_manufactured",
        //     type: "date",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "assets",
        //     commonSearchKey: "assets",
        //     commonSearchObjectKey: "auxiliary_date_manufactured",
        //     band: bands.auxiliary_equipment_fields
        // },
        // auxiliary_manufacturer: {
        //     isVisible: false,
        //     label: "Auxiliary Manufacturer",
        //     class: "width-180px",
        //     searchKey: "assets.auxiliary_manufacturer",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "assets",
        //     commonSearchKey: "assets",
        //     commonSearchObjectKey: "auxiliary_manufacturer",
        //     band: bands.auxiliary_equipment_fields
        // },
        // auxiliary_condition: {
        //     isVisible: false,
        //     label: "Auxiliary Condition",
        //     class: "width-180px",
        //     searchKey: "assets.auxiliary_condition",
        //     type: "string",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "assets",
        //     commonSearchKey: "assets",
        //     commonSearchObjectKey: "auxiliary_condition",
        //     band: bands.auxiliary_equipment_fields
        // },
        // auxiliary_eul: {
        //     isVisible: false,
        //     label: "Auxiliary EUL",
        //     class: "width-180px",
        //     searchKey: "assets.auxiliary_eul",
        //     type: "number",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "assets",
        //     commonSearchKey: "assets",
        //     commonSearchObjectKey: "auxiliary_eul",
        //     band: bands.auxiliary_equipment_fields
        // },
        // auxiliary_crv: {
        //     isVisible: false,
        //     label: "Auxiliary CRV",
        //     class: "width-180px",
        //     searchKey: "assets.auxiliary_crv",
        //     type: "number",
        //     hasWildCardSearch: true,
        //     hasCommonSearch: false,
        //     getListTable: "assets",
        //     commonSearchKey: "assets",
        //     commonSearchObjectKey: "auxiliary_crv",
        //     band: bands.auxiliary_equipment_fields
        // },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "width-180px",
            searchKey: "assets.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "width-180px",
            searchKey: "assets.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasCommonSearch: false,
            getListTable: "assets",
            commonSearchKey: "assets",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};
