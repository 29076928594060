import React from "react";

export const BuildingBreakdown = ({ activeBuildingType, activeSection, disableButton, sidePanelValues, handleBuildingType, clearBuildingFilter }) => {
    return (
        <div className="list-ara bid-break ">
            {sidePanelValues &&
                sidePanelValues.building_types &&
                Object.keys(sidePanelValues.building_types)
                    .sort()
                    .map(test => {
                        return (
                            <div
                                className={`${
                                    !disableButton ? (activeSection === test ? "lst cursor-pointer active-sectn btn-clickable" : "lst cursor-pointer btn-clickable") : "lst"
                                }`}
                                data-delay-show="500"
                                data-tip={activeBuildingType === test ? `` : `Click To View Selected Building Types`}
                                data-effect="solid"
                                data-for="filter-icons"
                                data-place="right"
                                data-background-color="#007bff"
                                key={test}
                            >
                                <div>
                                    <div
                                        onClick={
                                            !disableButton ? async e => await handleBuildingType(sidePanelValues.building_types[test], test, e) : null
                                        }
                                    >
                                        <div className="sm-hed">{test}</div>

                                        <div className="val-hed">{sidePanelValues.building_types[test].length}</div>
                                    </div>

                                    {activeBuildingType === test ? (
                                        <button
                                            type="button"
                                            class="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={e => clearBuildingFilter(e, test)}
                                        >
                                            <div
                                                className="cursor-hand"
                                                data-delay-show="500"
                                                data-tip={`Clear Building Types `}
                                                data-effect="solid"
                                                data-for="filter-icons"
                                                data-place="right"
                                                data-background-color="#007bff"
                                            >
                                                <span aria-hidden="true">
                                                    <img src="/img/close.svg" alt="" />
                                                </span>
                                            </div>
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        );
                    })}
        </div>
    );
};
