import React, { useEffect, useState } from "react";
import ImageGrid from "./ImageGrid";
import ImageList from "./ImageList";
import "../../../../assets/css/image-management.css";
import "../../../../assets/css/image-management-listing.css";
import MasterFilter from "./MasterFilter";
import BottomControls from "./BottomControls";
import { useDispatch } from "react-redux";
import imageActions from "../../../images/actions";
import ImageCaptionEditModal from "./ImageCaptionEditModal";
import Portal from "../Portal";

const ImageMain = props => {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        isGridView: true,
        selectedImages: [],
        exportImageZipLoader: false
    });

    useEffect(() => {
        setState(prevState => {
            const selectedImages = prevState.selectedImages.filter(selectedImage => props.imageList.some(image => image.id === selectedImage.id));
            return { ...prevState, selectedImages };
        });
    }, [props.imageList]);

    const setGridView = async bool => {
        setState({ ...state, isGridView: bool });
    };

    const handleMultiSelectImage = (img, isChecked) => {
        const { selectedImages } = state;
        setState({
            ...state,
            selectedImages: isChecked ? [...selectedImages, img] : selectedImages.filter(t => t.id !== img.id)
        });
    };

    const handleSelectAll = async e => {
        setState({ ...state, selectedImages: e.target.checked ? props.imageList : [] });
    };

    const handleClearAll = () => {
        setState({ ...state, selectedImages: [] });
    };

    const exportImagesZip = async () => {
        setState({ ...state, exportImageZipLoader: true });
        let params = {
            recom_code: props.basicDetails?.code,
            username: localStorage.getItem("user"),
            image_ids: selectedImages.map(i => i.master_image?.id),
            is_recom: true
        };
        await dispatch(imageActions.exportImagesZip(params));
        setState({ ...state, exportImageZipLoader: false });
    };

    const { isGridView, selectedImages, exportImageZipLoader } = state;

    const {
        imageList,
        imageParams,
        totalCount,
        fetchMoreImages,
        handleSelectImage,
        handleClickImage,
        handleLimitChange,
        hasCheckBox,
        isReportImage,
        checkIfNarrativeImageUsed,
        imagesNotUsedIds,
        hasMore,
        hasViewRecom = false,
        viewRecommendations,
        hasEdit = false,
        handleEdit,
        handleDelete,
        hasDelete = false,
        updateImage,
        handleInputCaptionData,
        refreshImageList
    } = props;

    const [showImageModal, setShowImageModal] = useState(false);
    const onEditCaption = () => {
        setShowImageModal(true);
    };

    return (
        <>
            {showImageModal && (
                <Portal
                    body={
                        <ImageCaptionEditModal
                            onClose={() => setShowImageModal(false)}
                            updateImage={updateImage}
                            selectedImages={selectedImages}
                            refreshImageList={refreshImageList}
                            handleClearAll={handleClearAll}
                        />
                    }
                />
            )}

            <div className={`dtl-sec col-md-12 image-manage info-images p-0`} id="main">
                <div className="filtr-otr">
                    <div className="flt-area-new">
                        <MasterFilter
                            handleSelectAll={handleSelectAll}
                            handleClearAll={handleClearAll}
                            selectedImages={selectedImages}
                            setGridView={setGridView}
                            allSelected={selectedImages?.length === imageList?.length}
                            exportImagesZip={exportImagesZip}
                            exportImageZipLoader={exportImageZipLoader}
                            isAsset={props.isAsset}
                            onEditCaption={onEditCaption}
                        />
                    </div>
                </div>
                {isGridView ? (
                    <div className={"image-sec"}>
                        <ImageGrid
                            imageData={imageList}
                            handleSelect={handleSelectImage}
                            fetchMoreImages={fetchMoreImages}
                            handleClickImage={handleClickImage}
                            hasMore={hasMore}
                            hasCheckBox={hasCheckBox}
                            isReportImage={isReportImage}
                            checkIfNarrativeImageUsed={checkIfNarrativeImageUsed}
                            imagesNotUsedIds={imagesNotUsedIds}
                            hasViewRecom={hasViewRecom}
                            viewRecommendations={viewRecommendations}
                            hasEdit={hasEdit}
                            handleEdit={handleEdit}
                            updateImageFromInfoImages1={updateImage}
                            handleInputCaptionData={handleInputCaptionData}
                            handleDelete={handleDelete}
                            hasDelete={hasDelete}
                            handleMultiSelectImage={handleMultiSelectImage}
                            selectedImages={selectedImages}
                            isAsset={props.isAsset}
                        />
                    </div>
                ) : (
                    <div className={"cnt-sec"}>
                        <ImageList
                            imageData={imageList}
                            handleSelect={handleSelectImage}
                            fetchMoreImages={fetchMoreImages}
                            handleClickImage={handleClickImage}
                            hasMore={hasMore}
                            hasCheckBox={hasCheckBox}
                            isReportImage={isReportImage}
                            checkIfNarrativeImageUsed={checkIfNarrativeImageUsed}
                            imagesNotUsedIds={imagesNotUsedIds}
                            hasViewRecom={hasViewRecom}
                            viewRecommendations={viewRecommendations}
                            hasEdit={hasEdit}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            hasDelete={hasDelete}
                            updateImageFromInfoImages1={updateImage}
                            handleInputCaptionData={handleInputCaptionData}
                            handleMultiSelectImage={handleMultiSelectImage}
                            selectedImages={selectedImages}
                            isAsset={props.isAsset}
                        />
                    </div>
                )}
                <BottomControls
                    handleLimitChange={handleLimitChange}
                    loadedCount={imageList.length}
                    totalCount={totalCount}
                    limit={imageParams.limit}
                />
            </div>
        </>
    );
};

export default ImageMain;
