import React, { useState } from "react";
import RightArrowIcon from "../../../assets/img/arrow-right-btn.svg";
import { FILE_TYPE } from "../../common/constants";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";

export const ExportPopup = ({
    onSortedDownload,
    onRegularDownload,
    viewSortedExportSettings,
    isRegularExportLoading,
    onCancel,
    exportLoader,
    handleNormalRecomGrouping,
    isNormalRecomGroupingLoading
}) => {
    const [fileType, setFileType] = useState(FILE_TYPE.EXCEL);
    const [sortType, setSortType] = useState("building");
    const { getProjectByIdResponse } = useSelector(state => state.projectReducer);
    const { getNormalRecomDownloadResponse } = useSelector(state => state.recommendationsReducer);
    const { show_crosswalk_export } = getProjectByIdResponse?.miscellaneous || {};
    const [groupingFileType, setGroupingFileType] = useState(FILE_TYPE.EXCEL);
    const handleSortedExport = () => {
        onSortedDownload(sortType, fileType);
    };
    return (
        <div className="modal modal-region add-image-modal img-pop-small excel-accord-modal" id="modalId" tabIndex={-1} style={{ display: "block" }}>
            <Draggable handle=".draggable" cancel=".not-draggable">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className={`modal-body region-otr draggable ${show_crosswalk_export ? "excel-mod-outer" : ""}`}>
                            <div className="close-btn-area">
                                <button type="button" className="close not-draggable" onClick={onCancel}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className="export-accord-outer">
                                <div class="card content-area mt-3 not-draggable">
                                    <div id="headingOne" className="cursor-pointer heading">
                                        Simple Excel Export
                                    </div>
                                    <div class="card-body">
                                        <div className="down-area">
                                            <button
                                                className="btn btn-download"
                                                onClick={() => onRegularDownload()}
                                                disabled={isRegularExportLoading}
                                            >
                                                Download File{" "}
                                                {isRegularExportLoading && (
                                                    <span className="spinner-border spinner-border-sm pl-2 ml-1" role="status"></span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div id="accordion">
                                    <div class="card content-area mt-3 not-draggable">
                                        <div
                                            id="headingtwo"
                                            className="cursor-pointer heading accordion"
                                            data-toggle="collapse"
                                            data-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            Normal Recommendation Grouping
                                        </div>
                                        <div id="collapseTwo" class="collapse" aria-labelledby="headingtwo">
                                            <div class="card-body pb-0">
                                                <div className="radio-area">
                                                    <div className="heading">
                                                        <h3>Please Select File Type</h3>
                                                    </div>
                                                    <div className="radio-sec">
                                                        <div className="button-group">
                                                            <div className="button">
                                                                <label className="container cursor-hand">
                                                                    <input
                                                                        type="radio"
                                                                        checked={groupingFileType === FILE_TYPE.EXCEL}
                                                                        onChange={() => setGroupingFileType(FILE_TYPE.EXCEL)}
                                                                    />
                                                                    <span className="checkmark" />
                                                                    Excel
                                                                </label>
                                                            </div>
                                                            <div className="button">
                                                                <label className="container cursor-hand">
                                                                    <input
                                                                        type="radio"
                                                                        checked={groupingFileType === FILE_TYPE.WORD}
                                                                        onChange={() => setGroupingFileType(FILE_TYPE.WORD)}
                                                                        defaultChecked=""
                                                                    />
                                                                    <span className="checkmark" />
                                                                    Word
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="down-area mt-4">
                                            <button
                                                className="btn btn-download outdated"
                                                onClick={() => viewSortedExportSettings("normalRecomGrouping")}
                                            >
                                                Advanced Export Settings <img src={RightArrowIcon} class="ml-1" alt="" />
                                            </button>
                                            <button
                                                className="btn btn-download"
                                                onClick={() => handleNormalRecomGrouping(groupingFileType)}
                                                disabled={isNormalRecomGroupingLoading}
                                            >
                                                Download File
                                                {isNormalRecomGroupingLoading && (
                                                    <span className="spinner-border spinner-border-sm pl-2 ml-1" role="status"></span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {show_crosswalk_export && (
                                    <div class="card content-area mt-3 not-draggable ">
                                        <div id="headingthree" className="heading cursor-pointer">
                                            CrossWalk Excel Export
                                        </div>
                                        <div class="card-body">
                                            <div className="down-area">
                                                <button
                                                    className="btn btn-download"
                                                    onClick={() => onRegularDownload("crossWalk")}
                                                    disabled={exportLoader}
                                                >
                                                    Download File{" "}
                                                    {exportLoader === "crossWalk" && (
                                                        <span className="spinner-border spinner-border-sm pl-2 ml-1" role="status"></span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div id="accordion">
                                    <div class="card content-area mt-3 not-draggable">
                                        <div
                                            id="headingFour"
                                            class="cursor-pointer heading accordion"
                                            data-toggle="collapse"
                                            data-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            Advanced Excel Export (Criticality Based)
                                        </div>
                                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour">
                                            <div class="card-body pb-0">
                                                <div className="radio-area">
                                                    <div className="heading">
                                                        <h3>Please Select Breakdown Type.</h3>
                                                    </div>
                                                    <div className="radio-sec">
                                                        <div className="button-group">
                                                            <div className="button">
                                                                <label className="container cursor-hand">
                                                                    <input
                                                                        onChange={() => setSortType("building")}
                                                                        checked={sortType === "building"}
                                                                        type="radio"
                                                                    />
                                                                    <span className="checkmark" />
                                                                    Building
                                                                </label>
                                                            </div>
                                                            <div className="button">
                                                                <label className="container cursor-hand">
                                                                    <input
                                                                        onChange={() => setSortType("addition")}
                                                                        checked={sortType === "addition"}
                                                                        type="radio"
                                                                    />
                                                                    <span className="checkmark" />
                                                                    Addition
                                                                </label>
                                                            </div>
                                                            <div className="button">
                                                                <label className="container cursor-hand">
                                                                    <input
                                                                        onChange={() => setSortType("responsibility")}
                                                                        checked={sortType === "responsibility"}
                                                                        type="radio"
                                                                    />
                                                                    <span className="checkmark" />
                                                                    Responsibility
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="radio-area">
                                                    <div className="heading">
                                                        <h3>Please Select File Type</h3>
                                                    </div>
                                                    <div className="radio-sec">
                                                        <div className="button-group">
                                                            <div className="button">
                                                                <label className="container cursor-hand">
                                                                    <input
                                                                        type="radio"
                                                                        checked={fileType === FILE_TYPE.EXCEL}
                                                                        onChange={() => setFileType(FILE_TYPE.EXCEL)}
                                                                    />
                                                                    <span className="checkmark" />
                                                                    Excel
                                                                </label>
                                                            </div>
                                                            <div className="button">
                                                                <label className="container cursor-hand">
                                                                    <input
                                                                        type="radio"
                                                                        checked={fileType === FILE_TYPE.WORD}
                                                                        onChange={() => setFileType(FILE_TYPE.WORD)}
                                                                        defaultChecked=""
                                                                    />
                                                                    <span className="checkmark" />
                                                                    Word
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="down-area mt-4">
                                            <button className="btn btn-download outdated" onClick={viewSortedExportSettings}>
                                                Advanced Export Settings <img src={RightArrowIcon} class="ml-1" alt="" />
                                            </button>
                                            <button className="btn btn-download" onClick={handleSortedExport} disabled={exportLoader}>
                                                Download File
                                                {(exportLoader === "sortedWord" || exportLoader === "sortedExcel") && (
                                                    <span className="spinner-border spinner-border-sm pl-2 ml-1" role="status"></span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    );
};
