const getDisplayNameFromKey = (key, isDocName = false) => {
    if (isDocName) {
        return key;
    }
    switch (key) {
        case "trades":
            return "Trade";
        case "categories":
            return "Categories";
        case "priorities":
            return "Term";
        case "funding_sources":
            return "Funding Sources";
        case "criticality":
            return "Criticality";
        case "capital_type":
            return "Capital Type";
        case "additions":
            return "Additions";
        case "EFCI":
            return "CSP & EFCI";
        case "regions":
            return "Regions";
        case "sites":
            return "Sites";
        case "buildings":
            return "Buildings";
        case "sorted_recom":
            return "Sorted Recommendations";
        case "energy_band":
            return "Energy Band";
        case "water_band":
            return "Water Band";
        case "project":
            return "Capital Spending Plan Reporting";
        case "energy_mng":
            return "Energy Management Reporting";
        case "em_eui_building_breakdown":
            return "Building Breakdown Energy Use Intensity (kBTU per SF)";
        case "bld_brkdown_energy_usage":
            return "Annual Average Building Breakdown Total Energy Usage";
        case "em_eui_site_brkdown_energy_use":
            return "Site Breakdown Energy Use Intensity (kBTU per SF)";
        case "em_eui_energy_usage":
            return "Annual Average Energy Unit Usage Analysis (kBTU)";
        case "bld_brkdown_energy_cost":
            return "Annual Average Building Breakdown Total Energy Cost ($)";
        case "em_eci_site_cost":
            return "Site Breakdown Energy Cost Index ($ per SF)";
        case "em_eci_energy_cost":
            return "Annual Average Energy Unit Cost Analysis ($ per MMBTU)";
        case "assets":
            return "Asset Management Reporting";
        case "end_servicelife_year_condition":
            return "Assets End of Service Life by Year and Condition";
        case "asset_age_by_condition":
            return "Assets Age by Condition";
        case "asset_capital_spending_plan":
            return "Assets Capital Spending Plan by End of Service Life";
        case "asset_system_facility":
            return "System Facility Condition Index";
        case "system":
            return "System";
        case "summary_view":
            return "Summary View";
        case "detailed_view":
            return "Detailed View";
        case "table_view":
            return "Table View";
        case "pie_2d":
            return "Pie 2D";
        case "pie_3d":
            return "Pie 3D";
        case "donut_2d":
            return "Donut 2D";
        case "donut_3d":
            return "Donut 3D";
        case "stacked_column_2d":
            return "Stacked Column 2D";
        case "stacked_column_3d":
            return "Stacked Column 3D";
        case "line_column_2d":
            return "Line Column 2D";
        case "multi_recommendation":
            return "Recommendation Summary";
        case "responsibilities":
            return "Responsibility";
        case "normal_recom":
            return "Recommendation Table";
        case "asset_band":
            return "Asset Band";
        case "subsystem":
            return "Sub-System";
        case "recom_onepager":
            return "Recommendation One Pager";
        case "asset-onepager":
            return "Asset One Pager";
        case "filter_report1":
            return "Master Filters";
        default:
            return key;
    }
};

const shiftObjectProps = (data, keyList = []) => {
    let shiftedObjectData = {};
    shiftedObjectData = keyList.reduce((resultData, currentProp, index) => {
        if (!data.hasOwnProperty(currentProp)) {
            return resultData;
        }
        return { ...resultData, [currentProp]: data[currentProp] };
    }, {});
    return shiftedObjectData;
};

const getEnityNameForCAband = key => {
    if (key) {
        switch (key) {
            case "capital":
                return "Capital Type";
            case "fundingsrc":
                return "Funding Source";
            case "fciefci":
                return "FCI, EFCI";
            case "subsystem":
                return "Sub-System";
            default:
                return key.charAt(0).toUpperCase() + key.slice(1);
        }
    }
    return "-";
};

const isRecomSectionBreak = (data = {}) => {
    let sectionBreak = true;
    if (data?.band1?.hasOwnProperty("remove_section_break")) {
        sectionBreak = data.band1.remove_section_break;
    }
    if (data?.hasOwnProperty("remove_section_break")) {
        sectionBreak = data.remove_section_break;
    }
    if (data?.hasOwnProperty("remove_tbl_section_break")) {
        sectionBreak = data.remove_tbl_section_break;
    }
    return sectionBreak;
};

const getWildCardFiltersFromConfigData = (configData = {}, tableConfig = {}, priorityElementsDropDownResponse = {}) => {
    let result = {};
    let customFilterKeys = Object.values(tableConfig)
        .filter(cf => cf.smartChartWildCardFilterKey)
        .map(c => c.smartChartWildCardFilterKey);
    result = Object.entries(configData).reduce((filtersData, currentValue, index) => {
        let finalKey = "";
        let finalValue = {};
        if (currentValue?.[1]?.is_maintenance_year) {
            finalKey = `maintenance_years.${currentValue?.[0]}`;
            finalValue = { key: currentValue?.[1]?.key, filters: currentValue?.[1]?.filters };
        } else if (currentValue?.[1]?.is_priority_element) {
            let currentPriorityElement = priorityElementsDropDownResponse?.priority_elements?.findIndex(pEl => pEl.id == currentValue?.[0]);
            finalKey = `priority_elements.${currentPriorityElement + 1}`;
            finalValue = { key: currentValue?.[1]?.key, filters: currentValue?.[1]?.filters };
        } else if (currentValue?.[1]?.is_dynamic_field) {
            finalKey = `dynamic_fields.${currentValue?.[0]}`;
            finalValue = { key: currentValue?.[1]?.key, filters: currentValue?.[1]?.filters };
        } else {
            if (customFilterKeys.includes(currentValue?.[0])) {
                let selectedCustomFieldConfig = Object.values(tableConfig).find(c => c.smartChartWildCardFilterKey === currentValue?.[0]);
                finalKey = selectedCustomFieldConfig?.searchKey;
                finalValue = { key: currentValue?.[1]?.key, filters: currentValue?.[1]?.filters };
            } else {
                let selectedCustomFieldConfig = Object.entries(tableConfig).find(([key, value]) => key === currentValue?.[0]);
                finalKey = selectedCustomFieldConfig?.[1]?.searchKey;
                finalValue = { key: currentValue?.[1]?.key, filters: currentValue?.[1]?.filters };
            }
        }
        return {
            ...filtersData,
            [finalKey]: { ...finalValue }
        };
    }, {});
    return result;
};

const getCommonFiltersFromConfigData = (filters = {}, config = {}) => {
    let tableConfig = Object.entries(config);
    let result = {};
    result = Object.entries(filters).reduce((filtersData, [currentDataKey, currentDataValue]) => {
        let [selectedItemKey, selectedItemValue] = tableConfig.find(([key, value]) => value?.smartChartCommonSearchKey == currentDataKey) || {};
        let currentFilterKey = selectedItemValue?.searchKey || "";
        let currentFilterValue = currentDataValue || [];
        return {
            ...filtersData,
            [currentFilterKey]: [...currentFilterValue]
        };
    }, {});
    return result;
};

const hasOnePagerConfig = (propertyData = {}) => {
    const {
        project: {
            band1: { type: projectTypes = {} }
        },
        assets: {
            band1: { type: assetTypes = {} }
        },
        energy_mng: {
            band1: { type: energyTypes = {} }
        }
    } = propertyData;
    return Object.keys({ ...projectTypes, ...assetTypes, ...energyTypes }).some(typeKey => typeKey.includes("recom_onepager"));
};

const hasNormalRecomConfig = (propertyData = {}, type) => {
    const {
        project: {
            band1: { type: projectTypes = {} }
        }
    } = propertyData;
    if (type === "ids") {
        return Object.keys({ ...projectTypes }).reduce((acc, typeKey) => {
            if (typeKey.includes("normal_recom")) {
                return [...acc, projectTypes?.[typeKey]?.band1?.settings_id];
            }
            return acc;
        }, []);
    }
    return Object.keys({ ...projectTypes }).some(typeKey => typeKey.includes("normal_recom"));
};

const getUpdatedChartConfigData = (selectedConfig = {}) => {
    return Object.entries(selectedConfig).reduce((accObjData, [currentDataKey, currentDataValue]) => {
        if (currentDataKey === "summary_view" || currentDataKey === "detailed_view") {
            return {
                ...accObjData,
                [currentDataKey]: {
                    ...currentDataValue,
                    config: currentDataValue?.config || { legend: true, header: true, total: true }
                }
            };
        }
        return {
            ...accObjData,
            [currentDataKey]: currentDataValue
        };
    }, {});
};

export {
    getDisplayNameFromKey,
    shiftObjectProps,
    getEnityNameForCAband,
    isRecomSectionBreak,
    getWildCardFiltersFromConfigData,
    getCommonFiltersFromConfigData,
    hasOnePagerConfig,
    hasNormalRecomConfig,
    getUpdatedChartConfigData
};
