export const GET_MEDIAN_REQUEST = "GET_MEDIAN_REQUEST";
export const GET_MEDIAN_SUCCESS = "GET_MEDIAN_SUCCESS";
export const GET_MEDIAN_FAILURE = "GET_MEDIAN_FAILURE";

export const POST_MEDIAN_REQUEST = "POST_MEDIAN_REQUEST";
export const POST_MEDIAN_SUCCESS = "POST_MEDIAN_SUCCESS";
export const POST_MEDIAN_FAILURE = "POST_MEDIAN_FAILURE";

export const PATCH_MEDIAN_REQUEST = "PATCH_MEDIAN_REQUEST";
export const PATCH_MEDIAN_SUCCESS = "PATCH_MEDIAN_SUCCESS";
export const PATCH_MEDIAN_FAILURE = "PATCH_MEDIAN_FAILURE";

export const DELETE_MEDIAN_REQUEST = "DELETE_MEDIAN_REQUEST";
export const DELETE_MEDIAN_SUCCESS = "DELETE_MEDIAN_SUCCESS";
export const DELETE_MEDIAN_FAILURE = "DELETE_MEDIAN_FAILURE";

export const GET_MEDIAN_ID_REQUEST = "GET_MEDIAN_ID_REQUEST";
export const GET_MEDIAN_ID_SUCCESS = "GET_MEDIAN_ID_SUCCESS";
export const GET_MEDIAN_ID_FAILURE = "GET_MEDIAN_ID_FAILURE";

export const UPDATE_MEDIAN_DATA_ENTITY_PARAMS_SUCCESS = "UPDATE_MEDIAN_DATA_ENTITY_PARAMS_SUCCESS";
export const UPDATE_MEDIAN_DATA_ENTITY_PARAMS_FAILURE = "UPDATE_MEDIAN_DATA_ENTITY_PARAMS_FAILURE";

export const CLEAR_REDUCER = "CLEAR_REDUCER";
