import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import actions from "../actions";
import { getDisplayNameFromKey } from "../utils";
import { MASTER_FILTER_KEYS } from "../constants";
import ChartSection from "./CoreReports/ChartSection";
import Portal from "../../common/components/Portal";
import { ExportModal } from "./ExportModal";
import ReactTooltip from "react-tooltip";
import FilterIcon from "../../../assets/img/dashboard/filter-butn.svg";

const CreateReportFromCoreReportsModal = ({ onCancel, selectedCoreReport, mFilters, isModalView = false, changeTabsInFavModal }) => {
    const { getClientDropDownDataResponse, getTemplatePropertiesListResponse, getTemplateListResponse, masterFilterList } =
        useSelector(state => state.smartChartReducer) || {};
    const history = useHistory();

    const [selectedClient, setSelectedClient] = useState("");
    const [selectedExportProperty, setSelectedExportProperty] = useState("");
    const [selectedExportTemplate, setSelectedExportTemplate] = useState("");
    const [filterOpen, setFilterOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const [chartData, setChartData] = useState({
        name: "",
        notes: "",
        properties_text: {
            project: {
                band1: {
                    mfilter: {},
                    type: {}
                }
            },
            assets: {
                band1: {
                    mfilter: {},
                    type: {}
                }
            },
            energy_mng: {
                band1: {
                    mfilter: {},
                    type: {}
                }
            }
        }
    });
    const [showErrorBorder, setShowErrorBorder] = useState(false);
    const [requiredFields, setRequiredFields] = useState({
        project: [],
        energy_mng: [],
        assets: []
    });
    const [showExportTypeSelectModal, setShowExportTypeSelectModal] = useState(false);
    const changeDefaultProjectRef = useRef(true);

    const dispatch = useDispatch();
    useEffect(() => {
        if (selectedCoreReport) {
            let propertyData = JSON.parse(selectedCoreReport.properties_text);
            setChartData(prevChartData => {
                return {
                    ...prevChartData,
                    properties_text: propertyData
                };
            });
            setRequiredFields({
                project: [
                    ...(MASTER_FILTER_KEYS["project"]
                        ?.map(obj => obj.paramKey)
                        ?.filter(key => Object.keys(propertyData?.project?.band1?.mfilter || {})?.includes(key)) || [])
                ],
                energy_mng: [...Object.keys(propertyData?.energy_mng?.band1?.mfilter || {})],
                assets: [...Object.keys(propertyData?.assets?.band1?.mfilter || {})]
            });
        }
        dispatch(actions.getClientDropDownData());
    }, []);
    useEffect(() => {
        let clientList = getClientDropDownDataResponse?.data || [];
        if (clientList.length) {
            let defaultClient = mFilters?.client_ids?.[0] ? { id: mFilters?.client_ids?.[0] } : clientList.find(client => client.default == true);
            if (defaultClient) {
                setSelectedClient(defaultClient.id);
                dispatch(actions.getTemplatePropertiesList({ client_id: defaultClient.id }));
                dispatch(actions.getTemplateList({ client_id: defaultClient.id }));
                getMasterFiltersData("projects", "project", {
                    client_id: defaultClient?.id || null,
                    user_id: localStorage.getItem("userId") || ""
                });
            }
        }
    }, [getClientDropDownDataResponse]);

    useEffect(() => {
        if (selectedCoreReport && selectedCoreReport.template_property_id) {
            setSelectedExportProperty(selectedCoreReport.template_property_id);
        } else {
            let propertyList = getTemplatePropertiesListResponse?.data || [];
            if (propertyList.length) {
                let defaultProperty =
                    propertyList.find(property => property.sm_default === true) || propertyList.find(property => property.default === true);
                if (defaultProperty) {
                    setSelectedExportProperty(defaultProperty.id);
                }
            }
        }

        if (selectedCoreReport && selectedCoreReport.template_id) {
            setSelectedExportTemplate(selectedCoreReport.template_id);
        } else {
            let templateList = getTemplateListResponse?.data || [];
            if (templateList.length) {
                let defaultTemplate =
                    templateList.find(property => property.sm_default === true) || templateList.find(property => property.default === true);
                if (defaultTemplate) {
                    setSelectedExportTemplate(defaultTemplate.id);
                }
            }
        }
    }, [getTemplatePropertiesListResponse, getTemplateListResponse, selectedCoreReport]);

    useEffect(() => {
        let projectList = masterFilterList?.project?.projects || [];
        if (projectList.length) {
            changeDefaultProjectRef.current = false;
            let selectedProjectIds = [];
            if (projectList.length) {
                selectedProjectIds = [projectList[0]?.id];
                setSelectedMasterFilters("", {
                    mfilterKey: "project_ids",
                    bandName: "band1",
                    filterValues: selectedProjectIds,
                    entity: "project",
                    filterKey: "projects",
                    dropDownData: [...projectList] || [],
                    filterLabel: "Projects"
                });
            }
        }
        let propertyData = JSON.parse(selectedCoreReport.properties_text);
        Object.keys(propertyData).forEach(entity => {
            const coreReportFilters = propertyData[entity]?.band1?.core_report_req_filters;
            if (coreReportFilters) {
                Object.keys(coreReportFilters).forEach(filterKey => {
                    const filter = coreReportFilters[filterKey];
                    if (filter && filter.keep_values) {
                        const entityFilters = MASTER_FILTER_KEYS[entity]?.find(e => e?.paramKey === filterKey);
                        setSelectedMasterFilters("", {
                            mfilterKey: filterKey,
                            bandName: "band1",
                            filterValues: propertyData[entity]?.band1?.mfilter[filterKey],
                            entity: entity,
                            filterKey: entityFilters?.key,
                            filterLabel: entityFilters?.key
                        });
                    }
                });
            }
        });
    }, [masterFilterList?.project?.projects]);

    const handleSelectClient = async value => {
        setSelectedClient(value);
        setSelectedExportProperty("");
        if (value) {
            changeDefaultProjectRef.current = true;
            dispatch(actions.getTemplatePropertiesList({ client_id: value }));
        }
    };

    const getMasterFiltersData = (key, entity, params) => {
        dispatch(actions.getSmartChartMasterFilterDropDown(key, entity, params));
    };

    const setSelectedMasterFilters = (actionType, params = {}) => {
        const { bandName, mfilterKey = "", filterValues = [], entity, filterKey, dropDownData = [], filterLabel } = params;
        if (entity === "project" && mfilterKey === "years") {
            setChartData(prevState => {
                return {
                    ...prevState,
                    properties_text: {
                        ...prevState.properties_text,
                        [entity]: {
                            ...prevState.properties_text[entity],
                            [bandName]: {
                                ...prevState.properties_text[entity][bandName],
                                mfilter: {
                                    ...removeMfilterEmptyKeys({
                                        ...prevState.properties_text[entity][bandName].mfilter,
                                        start: filterValues.start,
                                        end: filterValues.end
                                    })
                                },
                                filter_label: prevState.properties_text[entity][bandName]?.filter_label
                                    ? {
                                          ...removeMfilterEmptyKeys({
                                              ...prevState.properties_text[entity][bandName].filter_label,
                                              ["start_year"]: filterValues.start ? [filterValues.start] : [],
                                              ["end_year"]: filterValues.end ? [filterValues.end] : []
                                          })
                                      }
                                    : {
                                          ...removeMfilterEmptyKeys({
                                              ["start_Year"]: filterValues.start ? [filterValues.start] : [],
                                              ["end_Year"]: filterValues.end ? [filterValues.end] : []
                                          })
                                      }
                            }
                        }
                    }
                };
            });
        } else if (mfilterKey === "energy_mng_custom_years") {
            setChartData(prevState => {
                return {
                    ...prevState,
                    properties_text: {
                        ...prevState.properties_text,
                        [entity]: {
                            ...prevState.properties_text[entity],
                            [bandName]: {
                                ...prevState.properties_text[entity][bandName],
                                mfilter: {
                                    ...removeMfilterEmptyKeys({
                                        ...prevState.properties_text[entity][bandName].mfilter,
                                        ...filterValues
                                    })
                                }
                            }
                        }
                    }
                };
            });
        } else {
            setChartData(prevState => {
                return {
                    ...prevState,
                    properties_text: {
                        ...prevState.properties_text,
                        [entity]: {
                            ...prevState.properties_text[entity],
                            [bandName]: {
                                ...prevState.properties_text[entity][bandName],
                                mfilter: {
                                    ...removeMfilterEmptyKeys({
                                        ...prevState.properties_text[entity][bandName].mfilter,
                                        [mfilterKey]: [...filterValues],
                                        ...removeMfilterKeys(filterKey)
                                    })
                                },
                                filter_label: prevState.properties_text[entity][bandName]?.filter_label
                                    ? {
                                          ...removeMfilterEmptyKeys({
                                              ...prevState.properties_text[entity][bandName].filter_label,
                                              [filterLabel]: [...getSelectedFilterNameFormIds(dropDownData, filterValues)]
                                          })
                                      }
                                    : {
                                          ...removeMfilterEmptyKeys({
                                              [filterLabel]: [...getSelectedFilterNameFormIds(dropDownData, filterValues)]
                                          })
                                      }
                            }
                        }
                    }
                };
            });
        }
    };

    const removeMfilterEmptyKeys = (data, keyList = []) => {
        let shiftedObjectData = {};
        shiftedObjectData = Object.keys(data).reduce((resultData, currentProp, index) => {
            if ((currentProp === "start" || currentProp === "end") && data[currentProp] === "") {
                return resultData;
            } else if (currentProp !== "start" && currentProp !== "end" && !data[currentProp]?.length) {
                return resultData;
            }
            return { ...resultData, [currentProp]: data[currentProp] };
        }, {});
        return shiftedObjectData;
    };

    const removeMfilterKeys = key => {
        switch (key) {
            case "projects":
                return {
                    region_ids: [],
                    site_ids: [],
                    building_types: [],
                    building_ids: [],
                    start: "",
                    end: "",
                    fmp: [],
                    infrastructure_requests: [],
                    addition_ids: [],
                    color_scale: [],
                    responsibility_ids: []
                };
            case "regions":
                return {
                    // site_ids: [],
                    // building_types: [],
                    // building_ids: [],
                    fmp: [],
                    infrastructure_requests: [],
                    addition_ids: [],
                    color_scale: []
                };
            case "sites":
                return {
                    // building_types: [],
                    // building_ids: [],
                    fmp: [],
                    infrastructure_requests: [],
                    addition_ids: [],
                    color_scale: []
                };
            case "energy_mng_regions":
                return {
                    site_ids: [],
                    building_types: [],
                    building_ids: [],
                    years: []
                };
            case "energy_mng_sites":
                return {
                    building_types: [],
                    building_ids: [],
                    years: []
                };
            case "energy_mng_buildings":
                return {
                    years: []
                };
            case "asset_regions":
                return {
                    site_ids: [],
                    // building_types: [],
                    building_ids: []
                };
            case "asset_sites":
                return {
                    // building_types: [],
                    building_ids: []
                };
            default:
                return {};
        }
    };

    const getSelectedFilterNameFormIds = (dropDownData = [], selectedIds = []) => {
        let filteredNames = [];
        if (selectedIds.length) {
            dropDownData.map(data => {
                if (selectedIds.includes(data.id)) {
                    filteredNames.push(data.name + (data.description ? " (" + data.description + ")" : ""));
                }
            });
        }
        return filteredNames;
    };

    const validate = () => {
        setShowErrorBorder(false);
        if (!selectedClient?.trim()?.length) {
            setShowErrorBorder(true);
            return false;
        }
        if (!selectedExportProperty?.trim()?.length) {
            setShowErrorBorder(true);
            return false;
        }
        if (!selectedExportTemplate?.trim()?.length) {
            setShowErrorBorder(true);
            return false;
        }
        if (!chartData.name && !chartData.name.trim().length) {
            setShowErrorBorder(true);
            return false;
        }
        if (chartData.name && chartData.name.trim().length > 100) {
            setShowErrorBorder(true);
            return false;
        }
        if (!checkRequiredFilterSelected()) {
            return false;
        }
        return true;
    };

    const checkRequiredFilterSelected = () => {
        let propertyData = JSON.parse(selectedCoreReport.properties_text);
        let lastValidationError = null;
        for (let entity of Object.keys(propertyData)) {
            const coreReportFilters = propertyData[entity]?.band1?.core_report_req_filters;
            if (coreReportFilters) {
                for (let filterKey of Object.keys(coreReportFilters)) {
                    const filter = coreReportFilters[filterKey];
                    if (filter && filter.required) {
                        const dataExists = chartData?.properties_text?.[entity]?.band1?.mfilter?.[filterKey];
                        const filterKeyName = MASTER_FILTER_KEYS["project"]?.find(data => data?.paramKey === filterKey);
                        if (!dataExists || dataExists?.length === 0) {
                            lastValidationError = `Required data missing for filter ${filterKeyName?.label} in ${entity}`;
                            showLongAlert(lastValidationError);
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    };

    // const checkRequiredFilterSelected = () => {
    //     console.log(chartData);
    //     const typesToCheck = ["project", "assets", "energy_mng"];
    //     let isValid = true;

    //     for (const type of typesToCheck) {
    //         const typeData = chartData.properties_text?.[type]?.band1;
    //         if (typeData) {
    //             const typeKeys = Object.keys(typeData.type);
    //             if (typeKeys.length !== 0) {
    //                 const isTypeValid = requiredFields[type]
    //                     ?.filter(key => key !== "client_ids")
    //                     ?.every(filterKey => {
    //                         const fieldValue = typeData.mfilter?.[filterKey];
    //                         return Array.isArray(fieldValue) ? fieldValue.length > 0 : fieldValue;
    //                     });
    //                 isValid = isValid && isTypeValid;
    //             }
    //         }
    //     }

    //     return isValid;
    // };

    const exportReport = () => {
        if (validate()) {
            setShowExportTypeSelectModal(true);
        }
    };

    const exportReportConfirm = async (exportType = 1, selectedExportType, selectedFileExtension) => {
        let tempSaveChartData = { ...chartData };
        let updatedProjectDataWithDocs = updateDataWithUserDocs({ ...tempSaveChartData.properties_text?.project?.band1?.type }, "project");
        let updatedEnergyMngDataWithDocs = updateDataWithUserDocs({ ...tempSaveChartData.properties_text?.energy_mng?.band1?.type }, "energy_mng");
        let updatedAssetMngDataWithDocs = updateDataWithUserDocs({ ...tempSaveChartData.properties_text?.assets?.band1?.type }, "assets");
        if (tempSaveChartData?.properties_text?.project?.band1?.mfilter && tempSaveChartData.properties_text?.project?.band1?.type?.sorted_recom) {
            tempSaveChartData.properties_text.project.band1.type.sorted_recom.band1.mfilter = {
                ...tempSaveChartData.properties_text.project.band1.mfilter
            };
        }
        tempSaveChartData.properties_text.project.band1.type = {
            ...updatedProjectDataWithDocs
        };
        tempSaveChartData.properties_text.energy_mng.band1.type = {
            ...updatedEnergyMngDataWithDocs
        };

        tempSaveChartData.properties_text.assets.band1.type = {
            ...updatedAssetMngDataWithDocs
        };

        ["project", "energy_mng", "assets"].forEach(
            bandKey =>
                (tempSaveChartData.properties_text[bandKey].band1.mfilter = {
                    ...tempSaveChartData.properties_text[bandKey].band1.mfilter,
                    client_ids: [selectedClient]
                })
        );
        let saveChartData = {
            ...chartData,
            properties_text: JSON.stringify(tempSaveChartData.properties_text)
        };
        if (selectedExportProperty) {
            saveChartData.template_property_id = selectedExportProperty;
        }
        if (selectedExportTemplate) {
            saveChartData.template_id = selectedExportTemplate;
        }
        saveChartData = {
            ...saveChartData,
            client_id: selectedClient,
            property_type: "SKEL_PROP",
            skelton_property_id: selectedCoreReport?.id,
            user: localStorage.getItem("userId"),
            is_export: true,
            skelton_property_type: selectedCoreReport?.property_type,
            export_for_each:
                selectedExportType === "MultipleDataSet"
                    ? MASTER_FILTER_KEYS.project
                          ?.filter(k => Object.keys(chartData?.properties_text?.project?.band1?.mfilter).includes(k.paramKey))
                          .pop()?.paramKey
                    : null,
            orientation: Object.keys(chartData?.properties_text?.project?.band1?.type || {}).some(
                key =>
                    key.includes("user_doc") ||
                    key.includes("multi_recommendation") ||
                    key.includes("normal_recom") ||
                    key.includes("sorted_recom") ||
                    key.includes("recom_onepager") ||
                    key.includes("bbi_report") ||
                    key.includes("bsi_report") ||
                    key?.startsWith("recommendations_site") ||
                    key?.startsWith("recommendations_building") ||
                    key?.startsWith("recommendations_region") ||
                    key?.startsWith("recommendations_project") ||
                    key?.startsWith("recommendations_addition") ||
                    key?.startsWith("asset_site") ||
                    key?.startsWith("asset_building") ||
                    key?.startsWith("asset_region") ||
                    key?.startsWith("asset_project") ||
                    key?.startsWith("asset_addition")
            ),
            export_type: exportType,
            is_pdf: selectedFileExtension === "is_pdf" ? true : false,
            is_excel: selectedFileExtension === "is_excel" ? true : false
        };
        await dispatch(actions.createReportFromCoreReport(saveChartData));
        onCancel();
        showLongAlert("Report generation initiated and will be ready for download shortly. Please click the Refresh button to check for status.");
        if (isModalView) {
            changeTabsInFavModal("reports");
        } else {
            history.push("/smartcharts/reports");
        }
    };

    const updateDataWithUserDocs = (entityTypeData, entity) => {
        let updatedData = Object.keys(entityTypeData).reduce((resultData, currentProp, index) => {
            if (currentProp.includes("multi_recommendation")) {
                const { number_of_recom, ...restRecomBandData } = entityTypeData[currentProp];
                return {
                    ...resultData,
                    ["multi_recommendation" + (index + 1)]: {
                        ...restRecomBandData,
                        mfilter: {
                            ...entityTypeData?.[currentProp]?.mfilter,
                            ...chartData.properties_text.project.band1.mfilter
                        },
                        key_name:
                            entityTypeData[currentProp]?.key_name &&
                            typeof entityTypeData[currentProp].key_name === "object" &&
                            entityTypeData[currentProp]?.key_name !== null
                                ? {
                                      ...entityTypeData[currentProp]?.key_name,
                                      ...getKeyNamesForMultiRecom()
                                  }
                                : { ...getKeyNamesForMultiRecom() }
                    }
                };
            }
            if (currentProp.includes("normal_recom")) {
                let currentData = entityTypeData[currentProp] || { band1: { mfilter: {} } };
                const { number_of_recom, ...restRecomBandData } = currentData?.band1;
                return {
                    ...resultData,
                    ["normal_recom" + (index + 1)]: {
                        band1: {
                            ...restRecomBandData,
                            mfilter: {
                                ...currentData?.band1?.mfilter,
                                ...chartData.properties_text.project.band1.mfilter,
                                client_ids: [selectedClient]
                            }
                        }
                    }
                };
            }
            if (currentProp.includes("recom_onepager")) {
                let currentData = entityTypeData[currentProp] || { band1: { mfilter: {} } };
                const { number_of_recom, ...restRecomBandData } = currentData?.band1;
                return {
                    ...resultData,
                    ["recom_onepager" + (index + 1)]: {
                        band1: {
                            ...restRecomBandData,
                            mfilter: {
                                ...currentData?.band1?.mfilter,
                                ...chartData.properties_text.project.band1.mfilter
                            }
                        }
                    }
                };
            }
            if (currentProp.includes("asset-onepager")) {
                let currentData = entityTypeData[currentProp] || { band1: { mfilter: {} } };
                const { number_of_recom = [], ...restRecomBandData } = currentData?.band1 ?? {};
                return {
                    ...resultData,
                    ["asset-onepager" + (index + 1)]: {
                        band1: {
                            ...restRecomBandData,
                            mfilter: {
                                ...currentData?.band1?.mfilter,
                                ...chartData.properties_text[entity].band1.mfilter,
                                client_ids: [selectedClient]
                            }
                        }
                    }
                };
            }
            if (currentProp.includes("bbi_report")) {
                let currentData = entityTypeData[currentProp] || { band1: { mfilter: {}, name: "BBI" } };
                const { ...restRecomBandData } = currentData?.band1;
                return {
                    ...resultData,
                    ["bbi_report" + (index + 1)]: {
                        band1: {
                            ...restRecomBandData,
                            mfilter: {
                                ...currentData?.band1?.mfilter,
                                ...chartData.properties_text.project.band1.mfilter
                            }
                        }
                    }
                };
            }
            if (currentProp.includes("bsi_report")) {
                let currentData = entityTypeData[currentProp] || { band1: { mfilter: {}, name: "BSI" } };
                const { ...restRecomBandData } = currentData?.band1;
                return {
                    ...resultData,
                    ["bsi_report" + (index + 1)]: {
                        band1: {
                            ...restRecomBandData,
                            mfilter: {
                                ...currentData?.band1?.mfilter,
                                ...chartData.properties_text.project.band1.mfilter
                            }
                        }
                    }
                };
            }
            if (currentProp.includes("system")) {
                let currentData = entityTypeData[currentProp] || { band1: { mfilter: {} } };
                const { ...restRecomBandData } = currentData;
                return {
                    ...resultData,
                    [currentProp]: {
                        ...restRecomBandData,
                        normal_recom: {
                            ...currentData?.normal_recom,
                            band1: {
                                ...currentData?.normal_recom?.band1,
                                mfilter: {
                                    ...currentData?.normal_recom?.band1?.mfilter,
                                    ...chartData.properties_text.project.band1.mfilter
                                }
                            }
                        }
                    }
                };
            }
            return { ...resultData, [currentProp]: entityTypeData[currentProp] };
        }, {});
        return updatedData;
    };

    const getKeyNamesForMultiRecom = () => {
        let tempKeys = chartData?.properties_text?.project?.band1?.filter_label
            ? { ...chartData?.properties_text?.project?.band1?.filter_label }
            : {};
        let removeKey = Object.keys(tempKeys).find(keyValue => keyValue.toLowerCase().includes("project")) || null;
        if (removeKey) {
            delete tempKeys[removeKey];
        }
        return tempKeys;
    };

    const showLongAlert = msg => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show-long-alert";
            x.innerText = msg;
            setTimeout(function () {
                x.className = x.className.replace("show-long-alert", "");
            }, 6000);
        }
    };
    const renderExportTypeSelectModal = () => {
        if (!showExportTypeSelectModal) return null;
        return (
            <Portal
                body={
                    <ExportModal
                        onCancel={() => setShowExportTypeSelectModal(false)}
                        onSelection={(type, file, extension) => onExportTypeSelection(type, file, extension)}
                        requiredFields={requiredFields}
                        chartData={chartData}
                    />
                }
                onCancel={() => setShowExportTypeSelectModal(false)}
            />
        );
    };

    const onExportTypeSelection = (selectedExportType, selectedFileType, selectedFileExtension) => {
        setShowExportTypeSelectModal(false);
        if (selectedExportType === "MultipleDataSet") {
            if (selectedFileType === "SeparateFile") {
                exportReportConfirm(2, selectedExportType, selectedFileExtension);
            } else {
                exportReportConfirm(3, selectedExportType, selectedFileExtension);
            }
        } else {
            exportReportConfirm(1, selectedExportType, selectedFileExtension);
        }
    };

    const getDropdownTooltip = (itemId = "", optionArray = []) => {
        const item = optionArray?.find(option => option.id === itemId);
        let content = item?.name;
        if (optionArray?.some(option => option?.sm_default)) {
            if (item?.sm_default) {
                content += ` (Active)`;
            }
        } else if (item?.default) {
            content += ` (Active)`;
        }
        return content;
    };

    return (
        <div class="modal modal-region smart-chart-popup smart-dtl-pop core-rpt-popup" id="modalId" tabIndex="-1" style={{ display: "block" }}>
            <ReactTooltip
                id="core-report"
                className="rc-tooltip-custom-class"
                backgroundColor="#007bff"
                place="right"
                effect="solid"
                delayShow={500}
            />
            <Draggable handle=".modal-header">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div class="modal-header draggable">
                            <h5 class="modal-title" id="exampleModalLabel">
                                <div class="txt-hed">
                                    Create Report From Core report{" "}
                                    {/* {selectedCoreReport?.entityExportType ? "(Multiple Data Set)" : "(Single Data Set)"} */}
                                </div>
                                <div class="btn-mod-headotr">
                                    <button class="btn-edit btn-exp" data-for="edit-report-template-modal" onClick={() => exportReport()}>
                                        Export
                                    </button>
                                    <button class="btn-cancel" onClick={() => onCancel()}>
                                        Close
                                    </button>
                                </div>
                            </h5>
                        </div>
                        <div class="modal-body region-otr dshb core-report">
                            <div class="tab-dtl region-mng chart-drage-area">
                                <div class="tab-active location-sec smart-detail-scroll height-scroll">
                                    <div className="row m-0">
                                        <div className="col-md-12">
                                            <div className="chart-hed-main">
                                                <div className="form-row">
                                                    <div className="form-group col-4">
                                                        <label>Client</label>
                                                        <select
                                                            className={`form-control dropdown export-prop-select cursor-diabled`}
                                                            value={selectedClient}
                                                            name="font_id"
                                                            onChange={e => handleSelectClient(e.target.value)}
                                                            disabled={true}
                                                            data-for="core-report"
                                                            data-tip={getDropdownTooltip(selectedClient, getClientDropDownDataResponse?.data)}
                                                        >
                                                            <option value="">Select</option>
                                                            {getClientDropDownDataResponse?.data?.map(p => (
                                                                <option key={p.id} value={p.id}>
                                                                    {p.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-4">
                                                        <label>Export Property</label>
                                                        <select
                                                            className={`${
                                                                showErrorBorder && !selectedExportProperty?.trim()?.length ? "error-border" : ""
                                                            } form-control dropdown export-prop-select`}
                                                            value={selectedExportProperty}
                                                            name="font_id"
                                                            onChange={e => {
                                                                let value = e.target.value;
                                                                setSelectedExportProperty(value);
                                                            }}
                                                            data-for="core-report"
                                                            data-tip={getDropdownTooltip(
                                                                selectedExportProperty,
                                                                getTemplatePropertiesListResponse?.data
                                                            )}
                                                        >
                                                            <option value="">Select</option>
                                                            {selectedClient &&
                                                                getTemplatePropertiesListResponse?.data?.map(p => (
                                                                    <option key={p.id} value={p.id}>
                                                                        {p.name}{" "}
                                                                        {getTemplatePropertiesListResponse?.data?.some(temp => temp?.sm_default)
                                                                            ? p?.sm_default
                                                                                ? "(Active)"
                                                                                : ""
                                                                            : p?.default
                                                                            ? "(Active)"
                                                                            : ""}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-4">
                                                        <label>Export Template</label>
                                                        <select
                                                            className={`${
                                                                showErrorBorder && !selectedExportTemplate?.trim()?.length ? "error-border" : ""
                                                            } form-control dropdown export-prop-select`}
                                                            value={selectedExportTemplate}
                                                            name="font_id"
                                                            onChange={e => {
                                                                let value = e.target.value;
                                                                setSelectedExportTemplate(value);
                                                            }}
                                                            data-for="core-report"
                                                            data-tip={getDropdownTooltip(selectedExportTemplate, getTemplateListResponse?.data)}
                                                        >
                                                            <option value="">Select</option>
                                                            {selectedClient &&
                                                                getTemplateListResponse?.data?.map(p => {
                                                                    return (
                                                                        <option key={p.id} value={p.id}>
                                                                            {p.name}{" "}
                                                                            {getTemplateListResponse?.data?.some(temp => temp?.sm_default)
                                                                                ? p?.sm_default
                                                                                    ? "(Active)"
                                                                                    : ""
                                                                                : p?.default
                                                                                ? "(Active)"
                                                                                : ""}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-4">
                                                        <label>Name</label>
                                                        <input
                                                            type="text"
                                                            className={`${
                                                                (showErrorBorder && !chartData.name.trim().length) ||
                                                                (showErrorBorder && chartData.name.trim().length > 100)
                                                                    ? "error-border"
                                                                    : ""
                                                            } form-control`}
                                                            value={chartData.name}
                                                            onChange={e => {
                                                                let value = e.target.value;
                                                                setChartData(prevChartData => {
                                                                    return {
                                                                        ...prevChartData,
                                                                        name: value
                                                                    };
                                                                });
                                                            }}
                                                        />
                                                        {showErrorBorder && chartData.name.trim().length > 100 && (
                                                            <small className="text-danger pl-2 er-danger">
                                                                Name should not exceed 100 characters.
                                                            </small>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-4">
                                                        <label>Notes</label>
                                                        <textarea
                                                            className="form-control textarea area-hgt"
                                                            value={chartData.notes}
                                                            onChange={e => {
                                                                let value = e.target.value;
                                                                setChartData(prevChartData => {
                                                                    return {
                                                                        ...prevChartData,
                                                                        notes: value
                                                                    };
                                                                });
                                                            }}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-md-12">
                                            <div id="accordion">
                                                {Object.keys(chartData.properties_text)
                                                    ?.filter(
                                                        entityKey => Object.keys(chartData.properties_text?.[entityKey]?.band1?.type || {}).length
                                                    )
                                                    .map((entity, index) => (
                                                        <div class="card">
                                                            <div class={`card-header`} id={`headingOne${entity}`}>
                                                                <div class="icon">
                                                                    <img src="/img/icon-squre.svg" />
                                                                </div>
                                                                <div
                                                                    class="heading-text"
                                                                    data-toggle="collapse"
                                                                    aria-expanded={!isCollapsed}
                                                                    onClick={() => {
                                                                        setIsCollapsed(!isCollapsed);
                                                                        entity === "project" && setShowFilter(!showFilter);
                                                                    }}
                                                                >
                                                                    <h3>{getDisplayNameFromKey(entity)}</h3>
                                                                    {entity === "project" && showFilter && (
                                                                        <div className="btn-sec check-box-area">
                                                                            <button
                                                                                className={`filter-button blue-common-btn cursor-pointer btn-add-img ml-2 fil-core `}
                                                                                data-tip={`View Filters`}
                                                                                data-for="dashboard-icons-right"
                                                                                onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    setFilterOpen(true);
                                                                                }}
                                                                            >
                                                                                <img src={FilterIcon} alt="" />
                                                                                <span> Filters</span>
                                                                                {chartData.properties_text?.[entity]?.band1?.mfilter &&
                                                                                    Object.keys(chartData.properties_text?.[entity]?.band1?.mfilter)
                                                                                        ?.length > 0 && (
                                                                                        <span className="notifyTxt">
                                                                                            {chartData.properties_text?.[entity]?.band1?.mfilter &&
                                                                                                Object.keys(
                                                                                                    chartData.properties_text?.[entity]?.band1
                                                                                                        ?.mfilter
                                                                                                )?.length > 0 &&
                                                                                                chartData.properties_text?.[entity]?.band1?.mfilter &&
                                                                                                Object.keys(
                                                                                                    chartData.properties_text?.[entity]?.band1
                                                                                                        ?.mfilter
                                                                                                )?.filter(key => key !== "client_ids")?.length}
                                                                                        </span>
                                                                                    )}
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id={`collapseOne${entity}`}
                                                                className={`collapse ${!isCollapsed ? "show" : ""}`}
                                                                aria-labelledby={`headingOne${entity}`}
                                                            >
                                                                <div className="card-body">
                                                                    <ChartSection
                                                                        masterFilterList={masterFilterList}
                                                                        chartSectionData={chartData.properties_text?.[entity]?.band1}
                                                                        entity={entity}
                                                                        selectedClient={selectedClient}
                                                                        getMasterFiltersData={getMasterFiltersData}
                                                                        setSelectedMasterFilters={setSelectedMasterFilters}
                                                                        requiredFields={requiredFields}
                                                                        setFilterOpen={setFilterOpen}
                                                                        filterOpen={filterOpen}
                                                                        projectSectionDatas={chartData.properties_text?.["project"]?.band1}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
            {renderExportTypeSelectModal()}
        </div>
    );
};

export default CreateReportFromCoreReportsModal;
