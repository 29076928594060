import React, { useRef } from "react";
import Draggable from "react-draggable";
import LoadingOverlay from "react-loading-overlay";
import ReactTooltip from "react-tooltip";
import Loader from "../../../common/components/Loader";
import { RecommendationBand } from "../../../recommendations/components/Bands/RecommendationBand";
import { tabs as consTabs } from "./constants";
import { FilterItem } from "./FilterItem";
import useMyFilters from "./useMyFilters";
import "../../../../assets/css/filter-dashboard-modal.css";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import Portal from "../../../common/components/Portal";
import { FilterSearch } from "../../../assets/components/FilterModal/FilterSearch";
import { AppliedFilters } from "../../../common/AppliedFilters";
import SaveFilterModal from "../SaveQueryModal";

export const MyFilters = ({
    dashboardFilterParams,
    onCancel,
    yearValues,
    submitMasterFilter,
    getListParams = {},
    isRecommendationTable,
    openAppliedFiltersPopup,
    filterCount,
    isDashboard,
    isSmartChartFilter = false,
    isCoreReportFilters = false,
    requiredFields = {},
    core_report_req_filters = {},
    queries,
    saveFilterSubmit,
    filterName
}) => {
    const {
        showBands,
        showDropdown,
        handleFilterView,
        masterFilterList,
        handleBandClick,
        dropdownLoader,
        handleFilterUpdate,
        filterData,
        isDirty,
        handleSubmit,
        activeTab,
        setActiveTab,
        handleCancel,
        showCancelConfirmation,
        onCancelConfirm,
        setShowCancelConfirmation,
        dynamicBands,
        buildingDynamicBands,
        filterFields: initialFields,
        clearAllFilters,
        getFilterCountByBand,
        setShowDropdown,
        setWildCardFilter,
        isLoading,
        fieldSearch,
        setFieldSearch,
        showAppliedFilters,
        setShowAppliedFilters,
        totalFilterCount,
        emptyDivRef,
        sectionRef,
        showWildcard,
        setShowWildcard,
        handleSaveFilerModal,
        showSaveFilterModal,
        setShowSaveFilterModal,
        handleSaveFilter
    } = useMyFilters({
        dashboardFilterParams,
        submitMasterFilter,
        onCancel,
        getListParams,
        isRecommendationTable,
        queries,
        saveFilterSubmit,
        isDashboard
    });
    const renderConfirmationModal = () => {
        if (!showCancelConfirmation) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to cancel and lose all changes?"}
                        type="cancel"
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => setShowCancelConfirmation(false)}
                        onYes={onCancelConfirm}
                    />
                }
                onCancel={() => setShowCancelConfirmation(false)}
            />
        );
    };
    const renserSaveFilterModal = () => {
        if (!showSaveFilterModal) return null;
        return (
            <Portal
                body={
                    <SaveFilterModal
                        onCancel={() => setShowSaveFilterModal(false)}
                        onFilterSave={data => {
                            handleSaveFilter(data);
                        }}
                    />
                }
                onCancel={() => setShowCancelConfirmation(false)}
            />
        );
    };
    const filteredFilterData =
        filterData &&
        Object.keys(filterData)?.reduce((acc, key) => {
            if (key === "filters") {
                const filteredFilters = Object.fromEntries(
                    Object.entries(filterData[key]).filter(
                        ([key, value]) =>
                            (value?.key !== "" && value?.key !== null) ||
                            ((value?.filters[0] === "null" || value?.filters[0] === "not_null" || value?.filters[0] === "count_gt_1") &&
                                (value?.key === "" || value?.key === null))
                    )
                );
                if (Object.keys(filteredFilters)?.length > 0) {
                    acc[key] = filteredFilters;
                }
            } else {
                acc[key] = filterData[key];
            }
            return acc;
        }, {});
    const hasKeys = obj => Object.keys(obj).length > 0;
    const hasSaveFilter = filterData
        ? hasKeys(Object.fromEntries(Object.entries(filterData).filter(([key]) => key !== "exclude_filters"))) ||
          hasKeys(filterData.exclude_filters || {})
        : false;

    const renderAppliedFilters = () => {
        if (!showAppliedFilters) return null;
        return (
            <Portal
                body={
                    <AppliedFilters
                        myFilters={isSmartChartFilter || isCoreReportFilters ? filteredFilterData : filterData}
                        filterValues={{}}
                        showFiltersIcon={false}
                        onCancel={() => setShowAppliedFilters(false)}
                        source="dashboard"
                        MyfilterName={filterName}
                    />
                }
                onCancel={() => setShowAppliedFilters(false)}
            />
        );
    };

    const tabs = {
        ...consTabs,
        building: { ...consTabs.building, bands: [...consTabs.building.bands, ...buildingDynamicBands] },

        recommendation: {
            ...consTabs.recommendation,
            bands: [...consTabs.recommendation.bands, ...dynamicBands]
        }
    };
    let filterFields = isSmartChartFilter
        ? initialFields.filter(({ paramKey }) => paramKey !== "client_ids")
        : isCoreReportFilters
        ? initialFields?.filter(field => requiredFields.includes(field.paramKey))
        : initialFields;
    if (fieldSearch && fieldSearch?.toString()?.trim()?.length) {
        filterFields = filterFields.filter(field => field?.label?.toString()?.toLowerCase()?.includes(fieldSearch?.toString()?.toLowerCase()));
    }
    filterCount = isDashboard ? totalFilterCount : filterCount;
    return (
        <LoadingOverlay active={isLoading} spinner={<Loader />} fadeSpeed={10}>
            {renderConfirmationModal()}
            {renserSaveFilterModal()}
            {renderAppliedFilters()}
            <div className="dtl-sec modal-recom-tab-view col-md-12">
                <Draggable handle=".draggable" cancel=".not-draggable">
                    <div className="tab-dtl filters-popup region-mng additional-dtl addition-edit">
                        <div className="close-btn-otr">
                            <button type="button" className="close not-draggable" data-dismiss="modal" aria-label="Close" onClick={handleCancel}>
                                <span aria-hidden="true">
                                    <img src="/img/close.svg" alt="" />
                                </span>
                            </button>
                        </div>
                        <div className="header-mod-top draggable">
                            <h3 className="filter-head">
                                Filters
                                {isDashboard &&
                                    ((filterData && Object.keys(filterData || {})?.filter(key => key !== "exclude_filters")?.length > 0) ||
                                        Object.keys(filterData?.exclude_filters || {})?.length > 0 ||
                                        queries?.length > 0) && (
                                        <span className="query-badge">
                                            {`${
                                                Object.keys(filterData)?.filter(key => key !== "exclude_filters")?.length > 0 ||
                                                Object.keys(filterData?.exclude_filters || {}).length > 0
                                                    ? "Active Filter(s)"
                                                    : ""
                                            }${
                                                (Object.keys(filterData)?.filter(key => key !== "exclude_filters")?.length > 0 ||
                                                    Object.keys(filterData?.exclude_filters || {})?.length > 0) &&
                                                queries.length > 0
                                                    ? " and"
                                                    : ""
                                            } ${
                                                queries && queries?.length > 0 ? "Query(s)" : ""
                                            } are applied. You are viewing a partial/filtered dataset`}
                                        </span>
                                    )}
                            </h3>
                        </div>
                        <div className="tab-active location-sec recom-sec main-dtl-add recommendation-form add-recommendation not-draggable filter-dashboard-popup">
                            <div className="col-md-12 detail-recom add-details-outer">
                                <div className="table-top-menu outer-flx-section">
                                    <ul className={``}>
                                        {Object.keys(tabs).map(tab => (
                                            <li className={`${activeTab === tab ? "active" : ""}`} key={tab} onClick={() => setActiveTab(tab)}>
                                                {tabs[tab].label}
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="clr-btn">
                                        <div className="search search-box-asset mr-2">
                                            <FilterSearch fieldSearch={fieldSearch} setFieldSearch={setFieldSearch} fields={initialFields} />
                                        </div>
                                        {(filterCount > 0 || isSmartChartFilter || isCoreReportFilters) && (
                                            <>
                                                <ReactTooltip
                                                    id="recommandation_detils"
                                                    effect="solid"
                                                    className="rc-tooltip-custom-class"
                                                    backgroundColor="#007bff"
                                                    place="left"
                                                />
                                                <div
                                                    className="view view-filter-otr cursor-pointer"
                                                    onClick={
                                                        isDashboard || isSmartChartFilter || isCoreReportFilters
                                                            ? () => setShowAppliedFilters(true)
                                                            : openAppliedFiltersPopup
                                                    }
                                                >
                                                    <div className="view-inner filter-all">
                                                        <img
                                                            src="/img/filter.svg"
                                                            alt=""
                                                            className={"filtrImg"}
                                                            data-tip="Applied Filters"
                                                            data-for="recommandation_detils"
                                                        />
                                                        <div className="arrow-sec"></div>
                                                        <span className="notifyTxt">
                                                            {isSmartChartFilter || isCoreReportFilters
                                                                ? filterData &&
                                                                  Object.keys(filterData)?.reduce((count, key) => {
                                                                      if (["client_ids", "end_year", "update_fci"]?.includes(key)) return count;
                                                                      if (key === "filters") {
                                                                          const filterObjects = Object.values(filterData[key]);
                                                                          const filteredObjects = filterObjects.filter(
                                                                              obj =>
                                                                                  (obj?.key !== "" && obj?.key !== null) ||
                                                                                  ((obj?.filters[0] === "null" ||
                                                                                      obj?.filters[0] === "not_null" ||
                                                                                      obj?.filters[0] === "count_gt_1") &&
                                                                                      (obj?.key === "" || obj?.key === null))
                                                                          );
                                                                          return count + filteredObjects.length;
                                                                      }
                                                                      return (key === "start_year" && filterData[key]) ||
                                                                          (Array.isArray(filterData[key]) && filterData[key]?.length > 0)
                                                                          ? count + 1
                                                                          : count;
                                                                  }, 0)
                                                                : filterCount}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <button className="btn" onClick={() => clearAllFilters(isSmartChartFilter || isCoreReportFilters)}>
                                            <i class="fas fa-times mr-2"></i>
                                            {isRecommendationTable ? "Clear LOCAL Filters Only" : "Clear All"}
                                        </button>
                                    </div>
                                </div>
                                <div className="outer-rcm recommendations">
                                    <div className="cnt-sec" ref={sectionRef}>
                                        <div className="row">
                                            <div className="col-md-12 pr-0">
                                                <div id="accordion">
                                                    {tabs[activeTab].bands
                                                        .filter(band => filterFields.some(field => field.band === band))
                                                        .map(band => (
                                                            <RecommendationBand
                                                                bandId={band?.toLowerCase()?.split(" ").join("_")}
                                                                label={band}
                                                                showBand={showBands}
                                                                handleBandClick={handleBandClick}
                                                                key={band}
                                                                filteredCount={getFilterCountByBand(band, isSmartChartFilter || isCoreReportFilters)}
                                                                MainFields={
                                                                    <>
                                                                        {filterFields
                                                                            .filter(elem => elem.band === band && !elem.hide)
                                                                            .filter((elem, index) => index < 3)
                                                                            .map(field => (
                                                                                <FilterItem
                                                                                    elem={field}
                                                                                    showDropDown={showDropdown}
                                                                                    handleFilterView={handleFilterView}
                                                                                    options={field.options || masterFilterList[field.key]}
                                                                                    isLoading={dropdownLoader[field.key]}
                                                                                    key={field.key}
                                                                                    filterData={filterData}
                                                                                    handleFilterUpdate={handleFilterUpdate}
                                                                                    yearValues={yearValues}
                                                                                    setShowDropdown={setShowDropdown}
                                                                                    showWildcard={showWildcard}
                                                                                    setShowWildcard={setShowWildcard}
                                                                                    setWildCardFilter={setWildCardFilter}
                                                                                    isSmartChartFilter={isSmartChartFilter}
                                                                                    isCoreReportFilters={isCoreReportFilters}
                                                                                    core_report_req_filters={core_report_req_filters}
                                                                                    isDashboard={isDashboard}
                                                                                />
                                                                            ))}
                                                                    </>
                                                                }
                                                                RestFields={
                                                                    <>
                                                                        {filterFields
                                                                            .filter(elem => elem.band === band && !elem.hide)
                                                                            .filter((elem, index) => index >= 3)
                                                                            .map(field => (
                                                                                <FilterItem
                                                                                    elem={field}
                                                                                    showDropDown={showDropdown}
                                                                                    handleFilterView={handleFilterView}
                                                                                    options={field.options || masterFilterList[field.key]}
                                                                                    isLoading={dropdownLoader[field.key]}
                                                                                    key={field.key}
                                                                                    filterData={filterData}
                                                                                    handleFilterUpdate={handleFilterUpdate}
                                                                                    yearValues={yearValues}
                                                                                    setShowDropdown={setShowDropdown}
                                                                                    showWildcard={showWildcard}
                                                                                    setShowWildcard={setShowWildcard}
                                                                                    setWildCardFilter={setWildCardFilter}
                                                                                    isSmartChartFilter={isSmartChartFilter}
                                                                                    isCoreReportFilters={isCoreReportFilters}
                                                                                    core_report_req_filters={core_report_req_filters}
                                                                                    isDashboard={isDashboard}
                                                                                />
                                                                            ))}
                                                                    </>
                                                                }
                                                            />
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div ref={emptyDivRef} className="d-none" style={{ height: "500px", width: "100%" }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className={`col-md-12 text-right btnOtr mt-3 mb-2 ${isDashboard ? "btn-filter-qr" : ""}`}>
                                    {isDashboard && (
                                        <div>
                                            <button
                                                type="button"
                                                className={`btn btn-primary btnRgion ml-2 ${!hasSaveFilter ? "cursor-diabled" : ""} save-fil-btn`}
                                                onClick={handleSaveFilerModal}
                                                disabled={!hasSaveFilter}
                                            >
                                                Save Filter
                                            </button>
                                        </div>
                                    )}
                                    <div>
                                        <button type="button" className={`btn btn-secondary btnClr`} onClick={handleCancel}>
                                            Cancel
                                        </button>

                                        <button
                                            type="button"
                                            className={`btn btn-primary btnRgion ml-2 ${!isDirty ? "cursor-diabled" : ""}`}
                                            disabled={!isDirty}
                                            onClick={() => handleSubmit(isCoreReportFilters || isSmartChartFilter)}
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
        </LoadingOverlay>
    );
};
