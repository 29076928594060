import React from "react";
import NumberFormat from "react-number-format";
export const NumberBox = ({
    placeholder,
    label,
    name,
    value,
    handleChange,
    type,
    showErrorBorder,
    isDisabled = false,
    col = 3,
    prefix = "",
    suffix = "",
    hasThousandSeparator
}) => {
    return (
        <div className={`col-md-${col} basic-box`}>
            <div className="codeOtr">
                <h4>{label}</h4>
                <NumberFormat
                    autoComplete={"off"}
                    type={type}
                    className={`${showErrorBorder ? "error-border " : ""} ${isDisabled ? "cursor-diabled" : ""} custom-input form-control`}
                    value={value}
                    onValueChange={values => handleChange(name, values.value)}
                    placeholder={placeholder || label.replace("*", "")}
                    disabled={isDisabled}
                    thousandSeparator={hasThousandSeparator}
                    prefix={prefix}
                    suffix={suffix}
                />
            </div>
        </div>
    );
};
