import React from "react";

import { getDisplayNameFromKey } from "../../utils";

const ViewChartItemForModal = ({ item }) => {
    return (
        <div className="drag-item">
            <div className="top-sec">
                <div className="icon">
                    <img src="/img/icon-squre.svg" />
                </div>
            </div>
            <div className="cont-sec">
                <div className="icons-sec">
                    <img src={`/img/${item.entityIcon}`} />
                </div>
                <h3>{getDisplayNameFromKey(item.name, item.isUserDoc)}</h3>
            </div>
        </div>
    );
};
export default ViewChartItemForModal;
