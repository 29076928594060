import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ImageManagementCaption from "../../../images/actions";
import _ from "lodash";
import Draggable from "react-draggable";
import ConfirmationModal from "../ConfirmationModal";
import Portal from "../Portal";
import Loader from "../Loader";
import CaptionChangeModal from "../../../images/components/CaptionChangeModal";
import { resetCursor, toTitleCase } from "../../../../config/utils";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import TuiImageEditor from "tui-image-editor";
import imageActions from "../../../images/actions";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import { getPresignedUrl } from "../../actions";

const initialState = {
    showErrorBorder: false,
    errorMessage: "",
    initialValues: {},
    showConfirmModal: false,
    isUpdating: false,
    missingRequiredFields: false,
    image: {},
    captionSync: false,
    forceChildCaptionChange: false,
    showPrevConfirmationModal: false,
    showNextConfirmationModal: false,
    showConfirmDeleteModal: false,
    showGraphicEdit: false,
    imageData: {},
    showRestoreConfirmModal: false
};
export class UploadForm extends Component {
    constructor(props) {
        super(props);
        this.imageEditorRef = React.createRef();
        this.state = initialState;
        this.isImageEdit = false;
    }

    componentDidMount = () => {
        const { images } = this.props;
        const master_image = images?.master_image;
        const imageData = {
            ...this.state.imageData,
            user_id: master_image?.user?.id,
            caption: master_image?._caption,
            project_id: master_image?.project.id,
            building_id: master_image.building.id,
            trade_id: master_image?.trade.id,
            system_id: master_image?.system.id,
            subsystem_id: master_image?.sub_system.id,
            client_id: master_image?.client?.id,
            region_id: master_image?.region?.id,
            site_id: master_image?.site?.id
        };
        this.setState(
            { image: images, captionSync: images?.description === images?.master_image?.caption || !images?.master_image?.caption, imageData },
            () => this.setState({ initialValues: this.state.image })
        );
    };
    componentDidUpdate = prevProps => {
        if (prevProps?.images?.id !== this.props.images?.id) {
            this.setState({ image: this.props.images }, () => this.setState({ initialValues: this.state.image }));
        }
    };

    cancelForm = () => {
        const { initialValues, image } = this.state;
        if (_.isEqual(initialValues, image)) {
            this.props.onCancel();
        } else {
            this.setState({
                showConfirmModal: true
            });
        }
    };

    renderConfirmationModal = () => {
        const { showConfirmModal } = this.state;
        if (!showConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={"Do you want to clear and lose all changes?"}
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => this.setState({ showConfirmModal: false })}
                        onYes={() => this.props.onCancel()}
                    />
                }
                onCancel={() => this.setState({ showConfirmModal: false })}
            />
        );
    };

    showAlert = () => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show";
            x.innerText = this.state.alertMessage;
            setTimeout(function () {
                x.className = x.className.replace("show", "");
            }, 3000);
        }
    };

    handleUpdateComment = async () => {
        const { image, initialValues } = this.state;
        if (!image.description) {
            this.setState({
                isUpdating: this.isImageEdit ? this.state.isUpdating : false,
                missingRequiredFields: true
            });
            return false;
        }
        let usedImageFound = false;
        if (this.props.isReportImage) {
            usedImageFound = this.props.checkIfNarrativeImageUsed(image.id);
        }
        if (usedImageFound && this.props.narrativeCompleted) {
            this.setState({ showNarrCompletedComfirmModal: true });
        } else if (image.master_image?.caption !== initialValues.master_image?.caption) {
            this.setState({ changecaptionmodal: true });
        } else {
            await this.updateComment();
        }
    };

    updateComment = async () => {
        const { image, initialValues, forceChildCaptionChange } = this.state;
        if (!this.isImageEdit)
            this.setState({
                isUpdating: true
            });
        // updating the global image caption
        if (image.master_image?.caption !== initialValues.master_image?.caption) {
            let params = {
                image_ids: [image.master_image?.id],
                caption: image.master_image?.caption,
                removed_tags: [],
                tags: image.master_image?.labels?.map(tag => tag.name).filter(tag => tag) || [],
                captionchange: forceChildCaptionChange
            };
            await this.props.updateGlobalCaption(params);
        }
        await this.props.updateImage({
            id: image.id,
            description: forceChildCaptionChange ? image.master_image?.caption : image.description,
            printable: image.printable
        });
        let tempState = {
            image: {},
            missingRequiredFields: false,
            forceChildCaptionChange: false
        };
        if (!this.isImageEdit) tempState = { ...tempState, isUpdating: false };
        await this.setState({ ...this.state, ...tempState });
        if (!this.isImageEdit) this.props.onCancel();
    };

    handleDescription = async e => {
        e.preventDefault();
        const { image, captionSync } = this.state;
        const { value } = e.target;
        resetCursor(e);
        await this.setState({
            image: {
                ...image,
                description: toTitleCase(value),
                master_image: {
                    ...image.master_image,
                    caption: captionSync ? toTitleCase(value) : image.master_image?.caption
                }
            }
        });
    };
    handleGlobalCaption = async e => {
        const { image, captionSync } = this.state;
        const { value } = e.target;
        resetCursor(e);
        this.setState({
            image: {
                ...image,
                description: captionSync ? toTitleCase(value) : image.description,
                master_image: {
                    ...image.master_image,
                    caption: toTitleCase(value)
                }
            }
        });
    };
    renderNarrCompletedConfirmationModal = () => {
        const { showNarrCompletedComfirmModal } = this.state;
        if (!showNarrCompletedComfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={"Do you really want to update this Image?"}
                        message={"This narrative is marked as completed. This action will mark the narrative as incomplete."}
                        onNo={() => this.setState({ showNarrCompletedComfirmModal: false })}
                        onYes={() => {
                            this.setState({ showNarrCompletedComfirmModal: false });
                            this.updateComment();
                        }}
                    />
                }
                onCancel={() => this.setState({ showNarrCompletedComfirmModal: false })}
            />
        );
    };

    renderCaptionChangeConfirmationModal = () => {
        const { changecaptionmodal } = this.state;
        if (!changecaptionmodal) return null;
        return (
            <Portal
                body={
                    <CaptionChangeModal
                        onCancel={() => this.setState({ changecaptionmodal: false })}
                        message={"Do you want the updated Caption pushed to ALL image Captions in recommendations with this image?"}
                        buttonNo={{ label: "No", value: "no", note: "Caption updated ONLY in Image Management Gallery" }}
                        buttonYes={{
                            label: "Yes",
                            value: "yes",
                            note: "Caption updated in Image Management Gallery AND in ALL Recommendations containing this image"
                        }}
                        hasCancelButton={true}
                        onSelection={this.onImageCaptionSelect}
                    />
                }
            />
        );
    };
    onImageCaptionSelect = async type => {
        switch (type) {
            case "no":
                await this.setState({ forceChildCaptionChange: false, changecaptionmodal: false });
                this.updateComment();
                break;
            case "yes":
                await this.setState({ forceChildCaptionChange: true, changecaptionmodal: false });
                this.updateComment();
                break;

            default:
                break;
        }
    };

    confirmViewNext = async () => {
        const { handleNext } = this.props;
        const { initialValues, image } = this.state;
        if (_.isEqual(initialValues, image)) {
            await handleNext(image.id);
        } else {
            this.setState({
                showNextConfirmationModal: true
            });
        }
    };
    confirmViewPrev = async () => {
        const { handlePrev } = this.props;
        const { initialValues, image } = this.state;
        if (_.isEqual(initialValues, image)) {
            await handlePrev(image.id);
        } else {
            this.setState({
                showPrevConfirmationModal: true
            });
        }
    };
    renderNextConfirmationModal = () => {
        const { handleNext } = this.props;
        const { showNextConfirmationModal, image } = this.state;
        if (!showNextConfirmationModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={"Do you want to clear and lose all changes?"}
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => this.setState({ showNextConfirmationModal: false })}
                        onYes={() => {
                            handleNext(image.id);
                            this.setState(initialState);
                        }}
                        cancel={() => this.setState({ showNextConfirmationModal: false })}
                    />
                }
                onCancel={() => this.setState({ showNextConfirmationModal: false })}
            />
        );
    };
    renderPrevConfirmationModal = () => {
        const { handlePrev } = this.props;
        const { showPrevConfirmationModal, image } = this.state;
        if (!showPrevConfirmationModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={"Do you want to clear and lose all changes?"}
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => this.setState({ showPrevConfirmationModal: false })}
                        onYes={() => {
                            handlePrev(image.id);
                            this.setState(initialState);
                        }}
                        cancel={() => this.setState({ showPrevConfirmationModal: false })}
                    />
                }
                onCancel={() => this.setState({ showPrevConfirmationModal: false })}
            />
        );
    };

    setImageEditor = async () => {
        let _this = this;
        if (!this.imageEditorRef.current) {
            return;
        }
        const myTheme = {
            "header.display": "none"
        };
        const selectedImages = this.state.image?.master_image;
        let imageUrl = selectedImages?.is_edited
            ? `${selectedImages?.s3_eimage_key}
            `
            : `${selectedImages?.s3_image_key}`;
        let presignedUrl = await getPresignedUrl(imageUrl);

        const tuiEditor = new TuiImageEditor(this.imageEditorRef.current, {
            includeUI: {
                loadImage: {
                    path: presignedUrl,
                    name: "SampleImage"
                },
                uiSize: {
                    height: "495px"
                },

                shape: {
                    strokeWidth: 20
                },
                menu: ["crop", "flip", "rotate", "draw", "shape", "icon", "mask", "text", "filter"],
                menuBarPosition: "left",
                theme: myTheme
            },
            selectionStyle: {
                cornerSize: 50,
                rotatingPointOffset: 1000,
                cornerStyle: "circle",
                cornerStrokeColor: "#FFFFFF",
                cornerColor: "#FFFFFF",
                borderColor: "#FFFFFF",
                transparentCorners: false
            }
        });

        this.setState({
            imgchang: tuiEditor
        });

        tuiEditor.ui.shape.options.strokeWidth = 20;
        tuiEditor.ui.shape.options.stroke = "#ff4040";
        tuiEditor.ui.text._els.textRange._value = 250;
        tuiEditor.ui.text._els.textColorpicker._color = "#ff4040";
        tuiEditor.ui.draw.color = "#ff4040";
        tuiEditor.ui.icon._els.iconColorpicker._color = "#ff4040";
        window.fabric.Object.prototype.controls.mtr.offsetY = -110;
        document.getElementById("#imageDownload").addEventListener("click", async function (e) {
            _this.isImageEdit = true;
            let currentImageFormat = selectedImages?.s3_image_key.split(/[#?]/)[0].split(".").pop().trim();
            let currentImageId = selectedImages?.id;
            let imageUrl = tuiEditor.toDataURL({
                format: currentImageFormat === "jpg" || currentImageFormat === "jpeg" ? "jpeg" : "png",
                quality: 1
            });
            const blobImage = await (await fetch(imageUrl)).blob();
            let formData = new FormData();
            formData.append("image_upload_id", currentImageId);
            formData.append("edit_image", blobImage);
            _this.setState({ isUpdating: true });
            await _this.props.saveEditedImage(formData);
            _this.setState(
                {
                    isUpdating: false,
                    alertMessage: "Image saved successfully"
                },
                async () => {
                    _this.props.onCancel();
                    _this.showAlert();
                    _this.props.refreshImageList();
                }
            );
        });

        const deleteButton = document.querySelector(".tie-btn-delete");
        if (deleteButton) {
            deleteButton.remove();
        }
    };

    setTab = e => {
        this.setState({ showGraphicEdit: e }, () => {
            if (e) this.setImageEditor();
        });
    };

    handleRestoreEditedImage = () => {
        this.setState({
            showRestoreConfirmModal: true
        });
    };

    renderRestoreConfirmationModal = () => {
        const { showRestoreConfirmModal } = this.state;
        if (!showRestoreConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to restore this image?"}
                        message={""}
                        onNo={() => this.setState({ showRestoreConfirmModal: false })}
                        onYes={this.handleRestoreEditedImageConfirm}
                        type={"restore"}
                        isRestore={true}
                    />
                }
                onCancel={() => this.setState({ showRestoreConfirmModal: false })}
            />
        );
    };

    handleRestoreEditedImageConfirm = async () => {
        const { image = {} } = this.state;
        let currentImageId = image?.master_image?.id;
        this.setState({
            showRestoreConfirmModal: false
        });
        await this.props.restoreEditedImage({ image_upload_id: currentImageId });
        this.props.refreshImageList();
        this.setState(
            {
                alertMessage: "Restored image successfully"
            },
            () => {
                this.props.onCancel();
                this.showAlert();
            }
        );
    };

    render() {
        const { image, isUpdating, missingRequiredFields, errorMessage, showGraphicEdit } = this.state;
        const { onCancel, imageList, hasNextPrev } = this.props;

        const { is_edited, s3_eimage_key, s3_image_key } = image?.master_image || {};
        const imageUrl = is_edited ? `${s3_eimage_key}` : `${s3_image_key}`;

        return (
            <React.Fragment>
                {this.renderConfirmationModal()}
                {this.renderCaptionChangeConfirmationModal()}
                {this.renderNextConfirmationModal()}
                {this.renderPrevConfirmationModal()}
                {this.renderRestoreConfirmationModal()}
                {/* {this.renderConfirmationDeleteModal()} */}

                <div
                    id="modalId"
                    className="modal modal-region modal-img-upload edit-img-wrap zm-edit-img-modal"
                    style={{ display: "block", cursor: "move" }}
                >
                    <ReactTooltip id="recmmendation-image-form" effect="solid" backgroundColor="#007bff" />
                    <Draggable cancel=".not-draggable">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                {/* {images?.length === 1  && ( */}
                                {hasNextPrev && (
                                    <div>
                                        <button
                                            className={`arrow-butn-left ${image?.id === imageList[0]?.id ? "cursor-diabled" : ""}`}
                                            disabled={image?.id === imageList[0]?.id}
                                            onClick={() => this.confirmViewPrev()}
                                            data-place="top"
                                            data-effect="solid"
                                            data-tip="Previous"
                                            data-for="recmmendation-image-form"
                                        >
                                            <i class="fas fa-chevron-left"></i>
                                        </button>

                                        <button
                                            class={`arrow-butn-right ${image?.id === imageList[imageList.length - 1]?.id ? "cursor-diabled" : ""}`}
                                            disabled={image?.id === imageList[imageList.length - 1]?.id}
                                            onClick={() => this.confirmViewNext()}
                                            data-place="top"
                                            data-effect="solid"
                                            data-tip="Next"
                                            data-for="recmmendation-image-form"
                                        >
                                            <i class="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                )}
                                {/* )} */}
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancel}>
                                        <span aria-hidden="true">
                                            <img src="/img/close.svg" alt="" />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body region-otr outer-right-tabpane">
                                    <ul class="nav-ul-tab">
                                        <li class={`cursor-hand ${!showGraphicEdit ? "active" : ""}`} onClick={() => this.setTab(false)}>
                                            Edit Details
                                        </li>
                                        <li class={`cursor-hand ${showGraphicEdit ? "active" : ""}`} onClick={() => this.setTab(true)}>
                                            Edit Image
                                        </li>
                                    </ul>

                                    {showGraphicEdit ? (
                                        <>
                                            <div ref={this.imageEditorRef} className="not-draggable tui-image-place-holder"></div>
                                        </>
                                    ) : (
                                        <div className="form-area flex-wrap">
                                            <div className="form-sec">
                                                <div className="form-group">
                                                    <label>User</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        defaultValue={image?.master_image?.user?.name || ""}
                                                        className="form-control not-draggable"
                                                        placeholder="Username"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Project</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        defaultValue={image?.master_image?.project?.name || ""}
                                                        className="form-control not-draggable"
                                                        placeholder="Project"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Building</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        defaultValue={image?.master_image?.building?.name || ""}
                                                        className="form-control not-draggable"
                                                        placeholder="Building"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Trade</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        defaultValue={image?.master_image?.trade?.name || ""}
                                                        className="form-control not-draggable"
                                                        placeholder="Trade"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>System</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        defaultValue={image?.master_image?.system?.name || ""}
                                                        className="form-control not-draggable"
                                                        placeholder="System"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Sub-System</label>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        defaultValue={image?.master_image?.sub_system?.name || ""}
                                                        className="form-control not-draggable"
                                                        placeholder="System"
                                                    />
                                                </div>

                                                <div className="form-group col-md-12 p-0">
                                                    <label>Recommendation (Local) Caption</label>
                                                    <textarea
                                                        className={`${
                                                            missingRequiredFields && !image.description ? "error-border" : ""
                                                        } form-control not-draggable`}
                                                        placeholder="Enter Recommendation Caption"
                                                        value={image?.description || ""}
                                                        onChange={e => this.handleDescription(e)}
                                                    />
                                                    <label class="container-check mt-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            checked={this.state.captionSync}
                                                            onChange={() => this.setState({ captionSync: !this.state.captionSync })}
                                                            className="form-control not-draggable"
                                                        />
                                                        <span class="checkmark"></span>Caption Sync
                                                    </label>
                                                </div>
                                                <div className="form-group col-md-12 p-0">
                                                    <label>Image Management (Global) Caption</label>
                                                    <textarea
                                                        className={`form-control not-draggable`}
                                                        placeholder="Enter Image Management Caption"
                                                        value={image?.master_image?.caption || ""}
                                                        onChange={e => this.handleGlobalCaption(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="upload-area">
                                                <label className={`drag-otr cursor-pointer`} params htmlFor="attachmentFiles">
                                                    <img src={imageUrl} alt="" />
                                                    <p>{image?.name}</p>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                    <div className="btnOtr">
                                        <span className="errorMessage">{errorMessage}</span>

                                        <>
                                            <button
                                                type="button"
                                                className="btn btn-secondary btnClr not-draggable"
                                                data-dismiss="modal"
                                                onClick={this.cancelForm}
                                            >
                                                Cancel
                                            </button>
                                            {this.state.showGraphicEdit && image?.master_image?.is_edited ? (
                                                <>
                                                    <ReactTooltip
                                                        id={image?.master_image?.id}
                                                        effect="solid"
                                                        place="left"
                                                        backgroundColor="#1383D9"
                                                    />
                                                    <button
                                                        class="btn-save btn-reset"
                                                        onClick={() => this.handleRestoreEditedImage()}
                                                        data-place="top"
                                                        data-effect="solid"
                                                        data-tip="Restore Original Image"
                                                        data-for={image?.master_image?.id}
                                                    >
                                                        Restore
                                                    </button>
                                                </>
                                            ) : null}
                                            <button
                                                type="button"
                                                className="btn btn-primary btnDlt not-draggable"
                                                data-dismiss="modal"
                                                onClick={() => this.props.handleDelete(image.id)}
                                            >
                                                Remove
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary btnRgion not-draggable"
                                                id="#imageDownload"
                                                onClick={() => this.handleUpdateComment()}
                                            >
                                                Update
                                                {isUpdating && <span className="spinner-border spinner-border-sm pl-2 ml-2" role="status"></span>}
                                            </button>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { imageReducer } = state;
    return { imageReducer };
};
const { updateImage: updateGlobalCaption } = ImageManagementCaption;

export default withRouter(connect(mapStateToProps, { ...imageActions, updateGlobalCaption })(UploadForm));
