/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import { withRouter } from "react-router-dom";
import history from "../../../config/history";
import { resetBreadCrumpData } from "../../../config/utils";

function PageNotFound({ isCR = false }) {
    const goToHome = () => {
        if (isCR) {
            const default_project_id = localStorage.getItem("default_project");
            const path = `/project/projectinfo/${default_project_id}/cr-recommendations`;
            resetBreadCrumpData({
                key: "info",
                name: "CR Recommendations",
                path: path
            });
            history.push(path);
        } else {
            resetBreadCrumpData({
                key: "main",
                name: "Dashboard",
                path: "/dashboard"
            });
            history.push("/dashboard");
        }
    };
    return (
        <>
            <div class="error-sec">
                <div class="col-md-12 navbarOtr">
                    <nav class="navbar navbar-expand-lg col-md-12">
                        <a class="navbar-brand" onClick={() => goToHome()}>
                            <img src="/img/fca-logo.svg" alt="" />
                        </a>
                    </nav>
                </div>

                <div class="error-page">
                    <div class="error-otr">
                        <div class="error-img">
                            <img src="/img/404.png" alt="" />
                        </div>
                        <h3>Oops!</h3>
                        <h4>Page Not Found no service</h4>
                        <p>The Link you followed is either outdated,inaccurate or the server has been instructed not to let you have it</p>
                        <button class="back-btn" onClick={() => goToHome()}>
                            Go to Home
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(PageNotFound);
