import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Portal from "../../common/components/Portal";
import FilterValue from "../../site/components/FilterValues";
import ReactTooltip from "react-tooltip";
import ShowHelperModal from "../../helper/components/ShowHelperModal";
import SelectExportTypeModal from "./SelectExportTypeModal";
import NewlyCreated from "../../../assets/img/Group7.svg";
import NewlyEdited from "../../../assets/img/Group6.svg";
import GlobalSearch from "./GlobalSearch";
import { ExportPopup } from "../../recommendations/components/ExportPopup";
import FilterIcon from "../../../assets/img/dashboard/filter-butn.svg";
import { getAppliedFiltersCount, getAppliedListFilterCount, getAppliedWildCardFilterCount } from "../../../utils/filtersUtils";
import ReactSelect from "react-select";
import ConfirmationModal from "./ConfirmationModal";
import { connect } from "react-redux";

class TableTopIcons extends Component {
    state = { viewFilterModal: false, showHelperModal: false, selectedHelperItem: {}, showResetTableConfigModal: false };
    isFiltered = () => {
        const { tableParams = {} } = this.props;
        if (tableParams.list && !_.isEmpty(tableParams.list) && Object.values(tableParams.list).some(e => e)) {
            return true;
        }
        if (tableParams.filters && !_.isEmpty(tableParams.filters)) {
            const filters = Object.keys(tableParams.filters);
            if (Object.values(tableParams.filters).every(item => item.key === null)) {
                return false;
            }
            for (const item of filters) {
                if (tableParams.filters[item] && tableParams.filters[item].key && tableParams.filters[item].key.length) {
                    return true;
                }
                if (
                    tableParams.filters[item] &&
                    tableParams.filters[item].filters &&
                    (tableParams.filters[item].filters.includes("not_null") || tableParams.filters[item].filters.includes("null"))
                ) {
                    return true;
                }
            }
            return true;
        }
        if (
            tableParams.recommendation_ids?.length ||
            this.props.globalSearchKey ||
            tableParams.image_or_not ||
            tableParams.review_status ||
            tableParams.qa_passed ||
            tableParams.recommendation_assigned_true ||
            tableParams.guid_assigned ||
            tableParams.has_image ||
            tableParams?.surveyor ||
            tableParams?.infrastructure_request ||
            tableParams?.water ||
            tableParams?.energy ||
            tableParams?.fmp ||
            tableParams?.facility_master_plan ||
            tableParams?.recommendation_type ||
            tableParams?.budget_priority ||
            tableParams?.recommendation_ids?.length ||
            (tableParams?.selectedDropdown && tableParams?.selectedDropdown !== "active")
        ) {
            return true;
        }
        return false;
    };
    isSorted = () => {
        const { tableParams = {} } = this.props;
        if (tableParams.order && !_.isEmpty(tableParams.order)) {
            return true;
        }

        return false;
    };

    isSelected = () => {
        const { tableParams = {} } = this.props;
        if (tableParams.deleted || tableParams.active || tableParams.locked || tableParams.unlocked) {
            return true;
        }

        return false;
    };

    setFilterModal = () => {
        this.setState({
            viewFilterModal: true
        });
    };

    showHelperModal = async (item, subItem) => {
        await this.setState({
            showHelperModal: true,
            selectedHelperItem: {
                item,
                subItem
            }
        });
    };

    renderUploadHelperModal = () => {
        const { showHelperModal, selectedHelperItem } = this.state;
        if (!showHelperModal) return null;
        return (
            <Portal
                body={<ShowHelperModal selectedHelperItem={selectedHelperItem} onCancel={() => this.setState({ showHelperModal: false })} />}
                onCancel={() => this.setState({ showHelperModal: false })}
            />
        );
    };

    showSelectExportTypeWordModal = async showSelectExportTypeWordModal => {
        await this.setState({
            showSelectExportTypeWordModal
        });
    };

    renderSelectExportTypeWordModal = () => {
        const { showSelectExportTypeWordModal } = this.state;
        const { exportToWord, exportToExcel } = this.props;
        if (!showSelectExportTypeWordModal) return null;
        return (
            <Portal
                body={
                    <ExportPopup
                        onRegularDownload={this.props.exportTableXl}
                        isRegularExportLoading={this.props.tableLoading}
                        exportLoader={this.props.exportWordLoading}
                        onSortedDownload={(sort_type, file_type) => {
                            if (file_type === "word") {
                                exportToWord(sort_type);
                            } else {
                                exportToExcel(sort_type);
                            }
                        }}
                        viewSortedExportSettings={this.props.showViewModalExport}
                        onCancel={() => this.showSelectExportTypeWordModal(false)}
                        handleNormalRecomGrouping={this.props?.handleNormalRecomGrouping}
                        isNormalRecomGroupingLoading={this.props?.isNormalRecomGroupingLoading}
                    />
                }
                onCancel={() => this.showSelectExportTypeWordModal(false)}
            />
        );
    };

    showSelectExportTypeExcelModal = async showSelectExportTypeExcelModal => {
        await this.setState({
            showSelectExportTypeExcelModal
        });
    };

    showSelectExportTypeModal = async showSelectExportTypeModal => {
        await this.setState({
            showSelectExportTypeModal
        });
    };

    renderSelectExportTypeModal = () => {
        const { showSelectExportTypeModal } = this.state;
        const { exportExcelAllTrades } = this.props;
        if (!showSelectExportTypeModal) return null;
        return (
            <Portal
                body={
                    <SelectExportTypeModal
                        type="cancel"
                        heading={"Please select export type."}
                        onCancel={() => this.showSelectExportTypeModal(false)}
                        onOk={export_type => {
                            this.showSelectExportTypeModal(false);
                            exportExcelAllTrades(export_type);
                        }}
                    />
                }
                onCancel={() => this.showSelectExportTypeModal(false)}
            />
        );
    };
    setSortOrderParamsByArrow = async (event, searchKey, val, tableData) => {
        if (tableData && tableData.data && tableData.data.length) {
            await this.props.updateLastSortFilter(searchKey, val);
        }
    };

    renderResetTableConfigConfirmationModal = () => {
        const { showResetTableConfigModal } = this.state;
        if (!showResetTableConfigModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Do you want to reset table display properties?`}
                        type="cancel"
                        message={"This action cannot be reverted, are you sure?"}
                        onNo={() => this.setState({ showResetTableConfigModal: false })}
                        onYes={() => {
                            this.setState({ showResetTableConfigModal: false });
                            this.props.resetAllTableConfig();
                        }}
                    />
                }
                onCancel={() => this.setState({ showResetTableConfigModal: false })}
            />
        );
    };

    render() {
        const {
            handleGlobalSearch,
            globalSearchKey,
            resetAllFilters,
            resetSort,
            toggleWildCardFilter,
            showViewModal,
            exportTableXl,
            hasSort = true,
            entity = null,
            isColunmVisibleChanged = null,
            tableParams,
            hasHelp = true,
            hasGlobalSearch = true,
            hasWildCardFilter = true,
            hasView = true,
            exportCustomExcel = null,
            exportToWord = null,
            customExcelExportLoading = false,
            hasNewlyCreated,
            hasNewlyEdited,
            tableData,
            resetAll = null,
            showWildCardFilter = false,
            selectedRecomIds,
            clearSelection,
            exportToExcelFromExportHistory,
            hasAdditionalFilters,
            isBudgetPriority,
            hasTableCellEdit,
            toggleLineEditing,
            lineEditingEnabled,
            isSmartChartView = false,
            unPinAllColumn = null,
            isMoreFiltered = null,
            toggleMoreFilter,
            openFiltersPopup = null,
            dashboardFilterParams = {},
            myFilters = {},
            toggleAppliedFiltersPopup,
            filterByRecommendationAssigned = null,
            recommendationAssignedCount,
            isAssignView = false,
            resetAllTableConfig = null
        } = this.props;

        const withRecommendationCount = recommendationAssignedCount?.list?.find(elem => elem.id === true)?.count || 0;
        const withoutRecommendationCount = recommendationAssignedCount?.list?.find(elem => elem.id === false)?.count || 0;

        const recommendationFilterOptions = [
            { label: "All", value: "", count: withRecommendationCount + withoutRecommendationCount },

            { label: isAssignView ? "With Recommendation" : "Complete CSP Information", value: "true", count: withRecommendationCount },
            { label: isAssignView ? "Without Recommendation" : "Incomplete CSP Information", value: "false", count: withoutRecommendationCount }
        ];

        let filterCount = 0;
        tableParams &&
            Object.keys(tableParams)
                .filter(f => f !== "list" && f !== "filters")
                .map(f =>
                    (f === "search" ||
                        f === "surveyor" ||
                        f === "image_or_not" ||
                        f === "infrastructure_request" ||
                        f === "water" ||
                        f === "energy" ||
                        f === "fmp" ||
                        f === "facility_master_plan" ||
                        f === "recommendation_type" ||
                        f === "budget_priority" ||
                        f === "recommendation_assigned_true") &&
                    tableParams[f]
                        ? (filterCount = filterCount + 1)
                        : null
                );
        let dashboardFilterCount = getAppliedFiltersCount(
            this.props?.dashboardReducer?.dashboardExtraFilters?.length > 0 && Object.keys(dashboardFilterParams).length > 0
                ? { ...this.props?.dashboardReducer?.filterValues, ...this.props?.dashboardReducer?.tempChartFilters, dashboard: true }
                : dashboardFilterParams
        );
        let myFiltersCount = getAppliedFiltersCount(myFilters);
        filterCount +=
            dashboardFilterCount +
            (dashboardFilterParams
                ? Object.keys(dashboardFilterParams || {})?.length > 0
                    ? this.props?.dashboardReducer?.dashboardExtraFilters?.length || 0
                    : 0
                : 0) +
            myFiltersCount +
            getAppliedWildCardFilterCount(tableParams?.filters) +
            getAppliedListFilterCount(tableParams?.list);
        const hasPinned = tableData?.keys?.some(key => tableData.config && (tableData.config[key]?.pinned || tableData.config[key]?.style));
        const isTableConfigChanged = hasPinned || (isColunmVisibleChanged && isColunmVisibleChanged());

        return (
            <React.Fragment>
                {openFiltersPopup && !isSmartChartView && (
                    <div className="fil-but ml-2 d-flex">
                        <button
                            type="button"
                            className={`filter-button blue-common-btn ${myFiltersCount > 0 ? "bg-th-filtered" : ""} mr-2`}
                            data-tip={`View Filters`}
                            data-for="dashboard-icons-right"
                            onClick={() => openFiltersPopup()}
                        >
                            <img src={FilterIcon} alt="" />
                            <span> Filters</span>
                            {/* {myFiltersCount > 0 ? <span className="notifyTxt">{myFiltersCount}</span> : null} */}
                        </button>
                        {tableParams && filterCount !== 0 ? (
                            <div className="view" onClick={toggleAppliedFiltersPopup}>
                                <div className="view-inner filter-all">
                                    <img src=" /img/filter.svg" alt="" className={"filtrImg"} data-tip="Applied Filters" data-for="table-top-icons" />
                                    <div className="arrow-sec"></div>
                                    <span className="notifyTxt">{filterCount}</span>
                                </div>
                            </div>
                        ) : null}

                        {filterByRecommendationAssigned && (
                            <div className="categ-select-box asset-categ ml-2">
                                <ReactSelect
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    value={recommendationFilterOptions.find(elem => elem.value === (tableParams.recommendation_assigned_true || ""))}
                                    onChange={value => filterByRecommendationAssigned(value.value)}
                                    options={recommendationFilterOptions}
                                    isSearchable={false}
                                    formatOptionLabel={(option, meta) => (
                                        <>
                                            {option.label}
                                            {meta.context === "menu" && <span className="float-right">({option.count})</span>}
                                        </>
                                    )}
                                    isLoading={recommendationAssignedCount?.loading}
                                    // menuIsOpen
                                />
                            </div>
                        )}
                    </div>
                )}

                <div className="d-flex icon-rc-outer">
                    {hasGlobalSearch ? (
                        <GlobalSearch
                            handleGlobalSearch={handleGlobalSearch}
                            globalSearchKey={globalSearchKey}
                            additionalParentClass={filterByRecommendationAssigned ? "sr-ast" : ""}
                        />
                    ) : null}
                    {hasWildCardFilter && this.state.viewFilterModal ? (
                        <Portal
                            body={
                                <FilterValue
                                    filterValues={tableParams}
                                    myFilters={myFilters}
                                    dashboardFilterParams={dashboardFilterParams}
                                    onCancel={() => this.setState({ viewFilterModal: false })}
                                />
                            }
                            onCancel={() => this.setState({ showViewModal: false })}
                        />
                    ) : null}

                    {hasTableCellEdit && !isSmartChartView && (
                        <div className="view">
                            <div
                                className={`view-inner ${lineEditingEnabled ? "bg-th-filtered" : ""}`}
                                data-for="table-top-icons"
                                data-tip={lineEditingEnabled ? `Disable Line Editing` : `Enable Line Editing`}
                                onClick={() => toggleLineEditing()}
                            >
                                <img src="/img/Edit.svg" alt="" className="sort-ico flr-crs" />
                            </div>
                        </div>
                    )}
                    {hasSort ? (
                        <div className="view">
                            <div
                                className={`view-inner ${this.isSorted() ? " bg-th-filtered" : ""}`}
                                data-for="table-top-icons"
                                data-tip={`Reset Sort`}
                                onClick={() => this.isSorted() && resetSort()}
                            >
                                <img src="/img/t-arrow-off.svg" alt="" className="sort-ico flr-crs" />
                            </div>
                        </div>
                    ) : null}

                    {hasNewlyCreated ? (
                        <div className="view date-sort">
                            <div
                                className={`view-inner ${
                                    tableParams.order && tableParams.order[tableData?.config?.["created_at"]?.searchKey]
                                        ? " bg-th-filtered bg-filt-icon"
                                        : ""
                                }`}
                                data-for="table-top-icons"
                                data-tip={`Sort by Newly Created`}
                                onClick={event =>
                                    this.setSortOrderParamsByArrow(event, tableData?.config?.["created_at"]?.searchKey, "Created At", tableData)
                                }
                            >
                                <img src={NewlyCreated} alt="" className="sort-ico flr-crs" />
                                {tableParams.order && tableParams.order[tableData?.config?.["created_at"]?.searchKey] === "asc" ? (
                                    <i className={`fas fa-long-arrow-alt-up table-param-rep calend-recom-icon text-danger`} />
                                ) : tableParams.order && tableParams.order[tableData?.config?.["created_at"]?.searchKey] === "desc" ? (
                                    <i className={`fas fa-long-arrow-alt-down table-param-rep calend-recom-icon text-danger`} />
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                    {hasNewlyEdited ? (
                        <div className="view date-sort">
                            <div
                                className={`view-inner ${
                                    tableParams.order && tableParams.order[tableData?.config?.["updated_at"]?.searchKey]
                                        ? " bg-th-filtered bg-filt-icon"
                                        : ""
                                }`}
                                data-for="table-top-icons"
                                data-tip={`Sort by Newly Edited `}
                                onClick={event =>
                                    this.setSortOrderParamsByArrow(event, tableData?.config?.["updated_at"]?.searchKey, "Updated At", tableData)
                                }
                            >
                                <img src={NewlyEdited} alt="" className="sort-ico flr-crs" />
                                {tableParams.order && tableParams.order[tableData?.config?.["updated_at"]?.searchKey] === "asc" ? (
                                    <i className={`fas fa-long-arrow-alt-up table-param-rep calend-recom-icon text-danger`} />
                                ) : tableParams.order && tableParams.order[tableData?.config?.["updated_at"]?.searchKey] === "desc" ? (
                                    <i className={`fas fa-long-arrow-alt-down table-param-rep calend-recom-icon text-danger`} />
                                ) : null}
                            </div>
                        </div>
                    ) : null}

                    {hasWildCardFilter ? (
                        <>
                            <div className="view">
                                <div
                                    className={`view-inner ${this.isFiltered() ? " bg-th-filtered" : ""}`}
                                    data-for="table-top-icons"
                                    data-tip={`Filter`}
                                    onClick={() => toggleWildCardFilter()}
                                >
                                    <img src="/img/filter.svg" alt="" />
                                </div>
                            </div>
                            <div className="view">
                                <div
                                    className={`view-inner  ${this.isFiltered() ? "bg-th-filtered" : ""}`}
                                    data-tip={`Reset Filters`}
                                    data-for="table-top-icons"
                                    onClick={() => {
                                        if (showWildCardFilter) toggleWildCardFilter();
                                        if (this.isFiltered() || !tableParams?.active) resetAllFilters();
                                    }}
                                >
                                    <img src="/img/filter-off.svg" alt="" className="fil-ico" />
                                </div>
                            </div>
                        </>
                    ) : null}

                    {exportToExcelFromExportHistory ? (
                        <div className="view ">
                            {this.props.exportExcelHistoryLoading ? (
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                <div
                                    className="view-inner"
                                    onClick={() => exportToExcelFromExportHistory()}
                                    data-for="table-top-icons"
                                    data-tip="Export to Excel "
                                >
                                    <img src="/img/excell-new.svg" alt="" className="export" />
                                </div>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                    {hasView && (
                        <div className="view">
                            <div
                                className={`view-inner ${isTableConfigChanged ? "bg-th-filtered" : ""}`}
                                onClick={() => showViewModal()}
                                data-for="table-top-icons"
                                data-tip={`Column Hide/Unhide`}
                            >
                                <img src="/img/eye-ico.svg" alt="" className="eye-ico" />
                            </div>
                        </div>
                    )}
                    {resetAllTableConfig && isTableConfigChanged && (
                        <div className="view">
                            <div
                                className={`view-inner bg-th-filtered`}
                                onClick={e => {
                                    e.preventDefault();
                                    this.setState({ showResetTableConfigModal: true });
                                }}
                                data-for="table-top-icons"
                                data-tip={`Reset Table Display Properties`}
                            >
                                <img src="/img/reset-eye-icon.svg" alt="" className="eye-ico" />
                            </div>
                        </div>
                    )}

                    {!exportToWord && this.props.isExport && (
                        <div className="view ">
                            {this.props.tableLoading ? (
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                <div className="view-inner" onClick={() => exportTableXl()} data-for="table-top-icons" data-tip="Export to Excel">
                                    <img src="/img/excell-new.svg" alt="" className="export" />
                                </div>
                            )}
                        </div>
                    )}
                    {exportToWord && !isSmartChartView ? (
                        <div className="view ">
                            <div
                                className="view-inner"
                                onClick={() => this.showSelectExportTypeWordModal(true)}
                                data-for="table-top-icons"
                                data-tip="Export to Excel/Word"
                                data-place="bottom"
                            >
                                <img src="/img/excell-new.svg" alt="" />
                            </div>
                        </div>
                    ) : null}
                    {exportCustomExcel && (
                        <div className="view ">
                            {customExcelExportLoading ? (
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                <div
                                    className="view-inner"
                                    onClick={() => exportCustomExcel()}
                                    data-for="table-top-icons"
                                    data-tip="Export to Custom Excel"
                                >
                                    <img src="/img/excell-new.svg" alt="" className="export export-all-trades" />
                                </div>
                            )}{" "}
                        </div>
                    )}

                    {!openFiltersPopup && tableParams && filterCount !== 0 ? (
                        <div className="view ">
                            <div className="view-inner filter-all position-relative" onClick={this.setFilterModal}>
                                <img src=" /img/filter.svg" alt="" className={"filtrImg"} data-tip="Applied Filters" data-for="table-top-icons" />
                                <div className="arrow-sec"></div>
                                <span className="notifyTxt">{filterCount}</span>
                            </div>
                        </div>
                    ) : null}
                    {/* {entity && hasHelp ? (
                        <div className={`view ${isBudgetPriority ? "" : "brd-rht"}`}>
                            <div
                                className="view-inner help-icon"
                                data-tip={`Help`}
                                data-for="table-top-icons"
                                onClick={() => {
                                    this.showHelperModal("tables", entity);
                                }}
                            >
                                <img src="/img/question-mark-icon.png" alt="" className="fil-ico" />
                                <ReactTooltip id="table-top-icons" effect="solid" place="bottom" backgroundColor="#007bff" />
                            </div>
                        </div>
                    ) : null} */}
                    {hasAdditionalFilters && (
                        <button
                            data-tip={this.props.additionalFilterTooltip || `Recommendation Types`}
                            onClick={e => {
                                e.preventDefault();
                                toggleMoreFilter();
                            }}
                            data-for="table-top-icons"
                            class={`btn btn-more-fl mr-2 ${isMoreFiltered && isMoreFiltered() ? " bg-th-filtered" : "active-bg"}`}
                        >
                            More <img src="/img/filter.svg" alt="" />
                        </button>
                    )}

                    {resetAll && (
                        <button
                            class="btn btn-reset-fl-cmn table-btn mr-2"
                            data-for="table-top-icons"
                            data-tip={this.props.isRecommendationTable ? "Reset all LOCAL Filters & Sort" : `Reset all Filters & Sort`}
                            onClick={e => {
                                e.preventDefault();
                                if (showWildCardFilter) toggleWildCardFilter();
                                if (selectedRecomIds?.length) clearSelection();
                                if (lineEditingEnabled) toggleLineEditing();
                                if (!resetAllTableConfig && hasPinned && unPinAllColumn) unPinAllColumn();
                                if (
                                    this.isSorted() ||
                                    this.isFiltered() ||
                                    !tableParams?.active ||
                                    myFiltersCount > 0 ||
                                    (isTableConfigChanged && !resetAllTableConfig)
                                )
                                    resetAll();
                            }}
                        >
                            {this.props.isRecommendationTable ? "Reset LOCAL Filters & Sort" : "Reset Filters & Sort"}
                        </button>
                    )}

                    {this.props.children}
                </div>

                {this.renderUploadHelperModal()}
                {this.renderSelectExportTypeModal()}
                {this.renderSelectExportTypeWordModal()}
                {this.renderResetTableConfigConfirmationModal()}
                <ReactTooltip id="table-top-icons" effect="solid" place="bottom" backgroundColor="#007bff" />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { dashboardReducer } = state;
    return {
        dashboardReducer
    };
};

export default withRouter(connect(mapStateToProps)(TableTopIcons));
