import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Row from "./Row";
import SummaryRow from "./SummaryRow";
import SummaryRowEnergy from "./SummaryRowEnergy";
import Loader from "./Loader";
import WildCardFilter from "./WildCardFilter";
import NewWildCardFilter from "../../chartTemplates/components/TableComponents/WildCardFilter";
import { EllipsisTooltipText } from "./EllipsisTextWithTooltip";
import { debounce, isEqual } from "lodash";
import styled from "styled-components";
import MoveIcon from "../../../assets/img/column-move-icon.svg";

const Th = styled.th`
    width: ${props => (props.$thStyle.width ? `${props.$thStyle.width} !important` : "auto")};
    max-width: ${props => (props.$thStyle.maxWidth ? `${props.$thStyle.maxWidth} !important` : "auto")};
    min-width: ${props => (props.$thStyle.minWidth ? `${props.$thStyle.minWidth} !important` : "auto")};
    left: ${props => (props.$thStyle.left ? `${props.$thStyle.left} !important` : "auto")};
`;
class Table extends Component {
    state = {
        selectedFieldForCommonSearch: null,
        filterList: [],
        commonFilterParams: this.props.commonFilter || {},
        selectAll: "",
        idArray: [],
        isCancelApi: false,
        isOkApi: false,
        isLoadingDrop: false,
        valuee: "",
        sourceData: [],
        exportFilters: this.props.exportFilters || {},
        filterCount: 0,
        isResizing: false
    };
    newFilterLogic =
        ["assets", "buildings", "sites", "recommendations", "recommendation", "cr-recommendations"].includes(this.props.match.params.tab) ||
        this?.props?.isAssignView ||
        [("/building", "/site")].includes(this.props.match.path) ||
        this.props.isSmartChartView;

    componentDidMount = async () => {
        document.addEventListener("mousedown", this.handleClickOutside);
        const {
            match: {
                params: { tab }
            },
            isNewTableFilters = false
        } = this.props;

        if (this.props.updateColumnWidthInConfig) this.initiateColumnResize();

        if (this.props.commonFilter) {
            if (!this.state.isOkApi && Object.keys(this.props.commonFilter).length !== 0) {
                let test = {};
                Object.keys(this.props.commonFilter).map(fil => {
                    if (
                        tab === "recommendations" ||
                        tab === "cr-recommendations" ||
                        tab === "assets" ||
                        tab === "dashboard" ||
                        tab === "recommendation" ||
                        this.props.match.path === "/initiatives" ||
                        this.props.match.path === "/dashboard" ||
                        this.props.match.path === "/documents" ||
                        isNewTableFilters ||
                        this.props.isSmartChartView ||
                        this?.props?.isAssignView
                    ) {
                        test = { ...test, [fil]: true };
                    } else {
                        let keyName = [fil] + "." + Object.keys(this.props.commonFilter[fil])[0];
                        test = { ...test, [keyName]: true };
                    }
                });
                this.setState({
                    isOkApi: test,
                    isCancelApi: test
                });
            }
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.sortFilters !== this.props.sortFilters) {
            this.setState({
                sortFilters: this.props.sortFilters || {}
            });
        }
        if (
            prevProps.commonFilter !== this.props.commonFilter ||
            this.state.selectedFieldForCommonSearch !== prevState.selectedFieldForCommonSearch
        ) {
            if (this.props.commonFilter) {
                const { selectAll } = [];
                let temp = selectAll;
                Object.keys(this.props.commonFilter).forEach(fil => {
                    if (this.props.commonFilter?.[fil]?.name?.length === this.state.filterList?.length) {
                        temp = { ...selectAll, [fil]: true };
                    }
                });
                this.setState({
                    selectAll: temp,
                    commonFilterParams: this.props.commonFilter || {},
                    exportFilters: this.props.exportFilters || {}
                });
            } else {
                this.setState({
                    selectAll: {},
                    commonFilterParams: this.props.commonFilter || {},
                    exportFilters: this.props.exportFilters || {}
                });
            }
        }
        if (prevProps.recomentationIds != this.props.recomentationIds) {
            if (!this.props.recomentationIds.length) {
                this.setState({
                    idArray: []
                });
                localStorage.removeItem("recommendationIds");
                localStorage.removeItem("selectAll");
            } else if (this.props.isAssignProject) {
                this.setState({
                    idArray: this.props.recomentationIds
                });
            }
        }
        if (prevProps.selectedAllClicked !== this.props.selectedAllClicked) {
            let selectAllClicked = localStorage.getItem("selectAllClicked");
            if (!this.props.selectedAllClicked || !selectAllClicked) {
                this.setState({
                    idArray: []
                });
                localStorage.removeItem("recommendationIds");
                localStorage.removeItem("selectAll");
            }
        }
        if (prevProps.tableData !== this.props.tableData) {
            ReactTooltip.rebuild();
        }
        if (
            (prevProps.tableData?.config !== this.props.tableData?.config || prevProps.tableData?.keys !== this.props.tableData?.keys) &&
            this.props.updateColumnWidthInConfig
        ) {
            this.initiateColumnResize();
        }
        if (this.props?.isNewTableFilters && this.props?.commonFilterData !== prevProps?.commonFilterData) {
            this.setState({
                filterList: this.props?.commonFilterData?.list?.length ? [...this.props.commonFilterData?.list] : [],
                isLoadingDrop: false
            });
        }
    };

    componentWillUnmount = () => {
        document.removeEventListener("mousedown", this.handleClickOutside);
    };

    setWrapperRef = node => {
        this.wrapperRef = node;
    };

    handleClickOutside = event => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                selectedFieldForCommonSearch: ""
            });
        }
    };

    handleSelectRow = (e, id) => {
        if (
            (this.props.match.params.tab === "recommendations" ||
                this.props.match.params.tab === "cr-recommendations" ||
                this.props.match.params.tab === "recommendation") &&
            !this.props.isAssignProject
        ) {
            let tempIds = localStorage.getItem("recommendationIds") ? JSON.parse(localStorage.getItem("recommendationIds")) : [];
            if (e.target.checked) {
                tempIds.push(id);
                this.setState({
                    idArray: tempIds
                });
                localStorage.setItem("recommendationIds", JSON.stringify(tempIds));
            } else {
                tempIds = tempIds.filter(t => t != id);
                this.setState({
                    idArray: tempIds
                });
                localStorage.setItem("recommendationIds", JSON.stringify(tempIds));
                localStorage.removeItem("selectAll");
                this.props.resetSelect();
            }
        } else {
            this.props.handleSelect(e, id);
        }
    };

    handleSelectAllRow = (e, dataItem) => {
        if (
            (this.props.match.params.tab === "recommendations" ||
                this.props.match.params.tab === "cr-recommendations" ||
                this.props.match.params.tab === "recommendation") &&
            !this.props.isAssignProject
        ) {
            let temp = [];
            if (e.target.checked) {
                if (dataItem && dataItem.data) {
                    dataItem.data.map(d => temp.push(d.id));
                }
                localStorage.setItem("selectAll", true);
            } else {
                temp = [];
                localStorage.removeItem("selectAll");
                localStorage.setItem("selectAllClicked", false);
            }
            this.setState({
                idArray: temp
            });
            localStorage.setItem("recommendationIds", JSON.stringify(temp));
            this.props.handleSelectAll(e, dataItem);
        } else {
            this.props.handleSelectAll(e, dataItem);
        }
    };

    setSortOrderParams = async (event, searchKey, val, tableData) => {
        if (tableData && tableData.data && tableData.data.length) {
            var thIconsContainer = document.getElementById(`thIconsContainer_${searchKey}`);
            if (thIconsContainer && !thIconsContainer.contains(event.target)) {
                await this.props.updateTableSortFilters(searchKey, val);
            }
        }
    };
    setSortOrderParamsByArrow = async (event, searchKey, val, tableData) => {
        if (tableData && tableData.data && tableData.data.length) {
            await this.props.updateTableSortFilters(searchKey, val);
        }
    };

    showCommonSearchDropDown = async keyItem => {
        const {
            tableData,
            match: {
                params: { tab, settingType }
            },
            isNewTableFilters = false
        } = this.props;
        const searchKey =
            tab === "recommendations" ||
            tab === "recommendation" ||
            tab === "cr-recommendations" ||
            tab === "dashboard" ||
            this.props.isSmartChartView ||
            settingType === "recommendationTemplate"
                ? tableData.config[keyItem]?.getListTable
                : tableData.config[keyItem]?.searchKey;

        this.setState({
            isLoadingDrop: true,
            selectedFieldForCommonSearch: this.state.selectedFieldForCommonSearch === keyItem ? null : keyItem,
            valuee: ""
        });
        let getListParsms = { field: searchKey, searchKey: tableData.config[keyItem]?.searchKey };
        if (this.newFilterLogic) {
            let { list = [], count = 0 } =
                this.state.selectedFieldForCommonSearch === keyItem ? {} : await this.props.getListForCommonFilter(getListParsms);
            this.setState({
                filterList: list,
                filterCount: count,
                isLoadingDrop: false
            });
        } else {
            let filterList = this.state.selectedFieldForCommonSearch === keyItem ? null : await this.props.getListForCommonFilter(getListParsms);
            if (!isNewTableFilters) {
                this.setState({
                    filterList,
                    isLoadingDrop: false
                });
            }
        }
    };

    setCommonFilterParams = async (keyItem, data) => {
        const {
            match: {
                params: { tab }
            },
            tableData: { config },
            isNewTableFilters = false
        } = this.props;
        const { commonSearchKey, commonSearchObjectKey, searchKey, filterIdKey } = config[keyItem] || {};
        const { id: valueId, name: value } = data;
        let tempList = this.state.commonFilterParams;
        let exportFilters = this.state.exportFilters;
        if (
            tab === "recommendations" ||
            tab === "cr-recommendations" ||
            tab === "assets" ||
            tab === "dashboard" ||
            tab === "recommendation" ||
            this.props.match.path === "/initiatives" ||
            this.props.match.path === "/dashboard" ||
            this.props.match.path === "/documents" ||
            isNewTableFilters ||
            this.props.isSmartChartView ||
            this?.props?.isAssignView
        ) {
            if (tempList[searchKey]) {
                if (!tempList[searchKey].includes(value)) {
                    tempList = { ...tempList, [searchKey]: [...tempList[searchKey], value] };
                } else {
                    tempList = { ...tempList, [searchKey]: tempList[searchKey].filter(item => item !== value) };

                    if (!tempList[searchKey].length) {
                        tempList = { ...tempList, [searchKey]: undefined };
                    }
                }
            } else {
                tempList = { ...tempList, [searchKey]: [value] };
            }
            /***************** for data export filters  #start*/
            if ((filterIdKey && valueId) || (isNewTableFilters && filterIdKey)) {
                if (exportFilters[filterIdKey]?.length) {
                    if (!exportFilters[filterIdKey].includes(valueId)) {
                        exportFilters[filterIdKey].push(valueId);
                    } else {
                        exportFilters[filterIdKey] = exportFilters[filterIdKey].filter(item => item !== valueId);

                        if (!exportFilters[filterIdKey].length) delete exportFilters[filterIdKey];
                    }
                } else {
                    exportFilters[filterIdKey] = [valueId];
                }
            } else {
                if (exportFilters[searchKey]) {
                    if (!exportFilters[searchKey].includes(value)) {
                        exportFilters[searchKey].push(value);
                    } else {
                        exportFilters[searchKey] = exportFilters[searchKey].filter(item => item !== value);

                        if (!exportFilters[searchKey].length) delete exportFilters[searchKey];
                    }
                } else {
                    exportFilters[searchKey] = [value];
                }
            }
            /***************** for data export filters  #end*/
            let isSelectAll;
            if (tempList && tempList[searchKey] && tempList[searchKey].length === this.state.filterList.length) {
                isSelectAll = true;
            } else {
                isSelectAll = false;
            }

            this.setState(prevState => ({
                commonFilterParams: tempList,
                selectAll: { ...prevState.selectAll, [searchKey]: isSelectAll }
            }));
        } else {
            if (tempList[commonSearchKey] && tempList[commonSearchKey][commonSearchObjectKey]) {
                if (!tempList[commonSearchKey][commonSearchObjectKey].includes(value)) {
                    tempList = {
                        ...tempList,
                        [commonSearchKey]: {
                            ...tempList[commonSearchKey],
                            [commonSearchObjectKey]: [...tempList[commonSearchKey][commonSearchObjectKey], value]
                        }
                    };
                } else {
                    tempList = {
                        ...tempList,
                        [commonSearchKey]: {
                            ...tempList[commonSearchKey],
                            [commonSearchObjectKey]: tempList[commonSearchKey][commonSearchObjectKey].filter(item => item !== value)
                        }
                    };

                    if (!tempList[commonSearchKey][commonSearchObjectKey].length)
                        tempList = {
                            ...tempList,
                            [commonSearchKey]: {
                                ...tempList[commonSearchKey],
                                [commonSearchObjectKey]: undefined
                            }
                        };
                    if (!Object.values(tempList[commonSearchKey]).some(e => e)) tempList = { ...tempList, [commonSearchKey]: undefined };
                }
            } else {
                tempList = { ...tempList, [commonSearchKey]: { ...tempList[commonSearchKey], [commonSearchObjectKey]: [value] } };
            }
            // let isSelectAll;
            // if (tempList && tempList[commonSearchKey] && tempList[commonSearchKey][commonSearchObjectKey].length === this.state.filterList.length) {
            //     isSelectAll = true;
            // } else {
            //     isSelectAll = false;
            // }
            this.setState(prevState => ({
                commonFilterParams: tempList
                // selectAll: { ...prevState.selectAll, [commonSearchKey]: isSelectAll }
            }));
        }
    };

    updateCommonFilterHandler = searchKey => {
        const { commonFilterParams, exportFilters } = this.state;
        this.setState({
            valuee: ""
        });
        if (Object.keys(commonFilterParams).length !== 0) {
            this.props.updateCommonFilter(commonFilterParams, exportFilters);

            this.setState(prevState => ({
                isOkApi: { ...prevState.isOkApi, [searchKey]: true },
                isCancelApi: { ...prevState.isCancelApi, [searchKey]: true }
            }));
        }
        if (this.state.isOkApi[searchKey] || this.checkHasCommonFilters() || this.state.isCancelApi[searchKey]) {
            this.props.updateCommonFilter(this.state.commonFilterParams, this.state.exportFilters);
            this.setState(prevState => ({
                isOkApi: { ...prevState.isOkApi, [searchKey]: false },
                isCancelApi: { ...prevState.isCancelApi, [searchKey]: false }
            }));
        }
        this.setState({
            selectedFieldForCommonSearch: null
        });
    };

    cancelCommonFilterHandler = keyItem => {
        const {
            match: {
                params: { tab }
            },
            tableData: { config },
            isNewTableFilters = false
        } = this.props;
        const { commonSearchKey, searchKey, filterIdKey } = config[keyItem] || {};
        let tempList = this.state.commonFilterParams;
        let { exportFilters } = this.state;
        this.setState({
            valuee: ""
        });
        if (
            tab === "recommendations" ||
            tab === "cr-recommendations" ||
            tab === "assets" ||
            tab === "dashboard" ||
            tab === "recommendation" ||
            this.props.match.path === "/initiatives" ||
            this.props.match.path === "/dashboard" ||
            this.props.match.path === "/documents" ||
            isNewTableFilters ||
            this.props.isSmartChartView ||
            this?.props?.isAssignView
        ) {
            if (tempList[searchKey]) {
                tempList = { ...tempList, [searchKey]: undefined };
                if (filterIdKey && exportFilters[filterIdKey]) {
                    delete exportFilters[filterIdKey];
                } else if (exportFilters[searchKey]) {
                    delete exportFilters[searchKey];
                }
            }

            this.setState(prevState => ({
                selectedFieldForCommonSearch: null,
                selectAll: { ...prevState.selectAll, [searchKey]: false },
                commonFilterParams: tempList
            }));
        } else {
            if (tempList[commonSearchKey]) {
                tempList = { ...tempList, [commonSearchKey]: undefined };
            }

            this.setState(prevState => ({
                commonFilterParams: tempList,
                selectedFieldForCommonSearch: null,
                selectAll: { ...prevState.selectAll, [commonSearchKey]: false }
            }));
        }
    };
    selectAllHandler = async (keyItem, allSelect, availableValues) => {
        const {
            match: {
                params: { tab }
            },
            isNewTableFilters = false
        } = this.props;
        if (
            tab === "recommendations" ||
            tab === "cr-recommendations" ||
            tab === "assets" ||
            tab === "dashboard" ||
            tab === "recommendation" ||
            this.props.match.path === "/initiatives" ||
            this.props.match.path === "/dashboard" ||
            this.props.match.path === "/documents" ||
            isNewTableFilters ||
            this.props.isSmartChartView ||
            this?.props?.isAssignView
        ) {
            const selctAll = !allSelect;
            let { exportFilters } = this.state;
            let filterListName = availableValues.map(item => item.name);
            let filterListId = availableValues.map(item => item.id);
            let tempList = this.state.commonFilterParams;
            if (selctAll) {
                const prevData = tempList?.[keyItem?.searchKey] || [];
                tempList = { ...tempList, [keyItem.searchKey]: [...prevData, ...filterListName] };
            } else {
                tempList = { ...tempList, [keyItem.searchKey]: undefined };
            }

            /***************** for data export filters  #start*/
            if (keyItem.filterIdKey) {
                const prevData = exportFilters?.[keyItem?.filterIdKey] || [];
                if (selctAll) {
                    exportFilters[keyItem.filterIdKey] = [...prevData, ...filterListId];
                } else if (exportFilters.hasOwnProperty(keyItem.filterIdKey)) {
                    delete exportFilters[keyItem.filterIdKey];
                }
            } else {
                const prevData = exportFilters?.[keyItem?.searchKey] || [];
                if (selctAll) {
                    exportFilters[keyItem.searchKey] = [...prevData, ...filterListName];
                } else if (exportFilters.hasOwnProperty(keyItem.searchKey)) {
                    delete exportFilters[keyItem.searchKey];
                }
            }
            /***************** for data export filters  #end*/

            this.setState(prevState => ({
                selectAll: { ...prevState.selectAll, [keyItem.searchKey]: selctAll },
                commonFilterParams: tempList,
                exportFilters
            }));
        } else {
            const selctAll = !allSelect;
            let filterListName = availableValues.map(item => item.name);
            let tempList = this.state.commonFilterParams;

            if (selctAll) {
                const prevData = tempList[keyItem?.commonSearchKey]?.[keyItem?.commonSearchObjectKey] || [];
                tempList = {
                    ...tempList,
                    [keyItem.commonSearchKey]: {
                        ...tempList[keyItem.commonSearchKey],
                        [keyItem.commonSearchObjectKey]: [...prevData, ...filterListName]
                    }
                };
                this.setState(prevState => ({
                    selectAll: { ...prevState.selectAll, [keyItem.commonSearchKey]: selctAll },
                    commonFilterParams: tempList
                }));
            } else {
                tempList = {
                    ...tempList,
                    [keyItem.commonSearchKey]: { ...tempList[keyItem.commonSearchKey], [keyItem.commonSearchObjectKey]: undefined }
                };
                if (!Object.values(tempList[keyItem.commonSearchKey]).some(e => e)) tempList = { ...tempList, [keyItem.commonSearchKey]: undefined };
                this.setState(prevState => ({
                    selectAll: { ...prevState.selectAll, [keyItem.commonSearchKey]: selctAll },
                    commonFilterParams: tempList
                }));
            }
        }
    };

    clearDropdown = (commonSearchKey, commonSearchObjectKey, keyItem, searchKey) => {
        const {
            match: {
                params: { tab }
            },
            isNewTableFilters = false
        } = this.props;
        let tempList = this.state.commonFilterParams;
        let tempExportFilterList = this.state.exportFilters;
        if (
            tab === "recommendations" ||
            tab === "cr-recommendations" ||
            tab === "assets" ||
            tab === "dashboard" ||
            tab === "recommendation" ||
            this.props.match.path === "/initiatives" ||
            this.props.match.path === "/dashboard" ||
            this.props.match.path === "/documents" ||
            isNewTableFilters ||
            this.props.isSmartChartView ||
            this?.props?.isAssignView
        ) {
            if (tempList[searchKey]) {
                tempList = { ...tempList, [searchKey]: undefined };
                delete tempExportFilterList[searchKey];
            }

            this.setState(prevState => ({
                commonFilterParams: tempList,
                selectAll: { ...prevState.selectAll, [keyItem.searchKey]: false }
            }));
        } else {
            if (tempList[commonSearchKey]) {
                tempList = { ...tempList, [commonSearchKey]: { ...tempList[commonSearchKey], [keyItem.commonSearchObjectKey]: undefined } };
                if (!Object.values(tempList[commonSearchKey]).some(e => e)) tempList = { ...tempList, [commonSearchKey]: undefined };
            }

            this.setState(prevState => ({
                commonFilterParams: tempList,
                selectAll: { ...prevState.selectAll, [keyItem.commonSearchKey]: false }
            }));
        }
    };

    handleFilterSearch = async (value, filterKey) => {
        const { filterCount, filterList } = this.state;
        this.setState({
            valuee: value
        });
        if (this.newFilterLogic && filterCount > 0 && filterCount !== filterList?.length) {
            this.debounceSearch(filterKey);
        }
    };

    debounceSearch = debounce(async keyItem => {
        this.setState({
            isLoadingDrop: true
        });
        const {
            tableData,
            match: {
                params: { tab }
            }
        } = this.props;
        const { valuee } = this.state;
        const searchKey =
            tab === "recommendations" ||
            tab === "recommendation" ||
            tab === "cr-recommendations" ||
            tab === "dashboard" ||
            this.props.isSmartChartView
                ? tableData.config[keyItem]?.getListTable
                : tableData.config[keyItem]?.searchKey;

        let getListParsms = {
            field: searchKey,
            searchKey: tableData.config[keyItem]?.searchKey
        };
        if (valuee && valuee.trim()?.length) {
            getListParsms.search_filters = { [getListParsms.searchKey]: { key: valuee } };
        }
        let { list = [], count = 0 } = (await this.props.getListForCommonFilter(getListParsms)) || {};
        this.setState({
            // filterCount: count,
            filterList: list,
            isLoadingDrop: false
        });
    }, 1000);

    renderFiltersForCommonSearch = keyItem => {
        const {
            match: {
                params: { tab }
            },
            isNewTableFilters = false
        } = this.props;
        if (this.state.selectedFieldForCommonSearch !== keyItem) {
            return null;
        }
        const {
            tableData: { config }
        } = this.props;

        let { filterList, valuee, commonFilterParams, isLoadingDrop, filterCount } = this.state;
        filterList = filterList?.filter(item => item.name !== null && item.name !== "");
        let searchedList = filterList;
        if (valuee && filterCount <= 100) {
            searchedList = filterList?.filter(item => {
                let itemName = item?.name?.toString()?.toLowerCase();
                const itemDescription = item?.description?.toString()?.toLowerCase();
                const searchValue = valuee?.toLowerCase();
                itemName = itemName === "true" ? "yes" : itemName === "false" ? "no" : itemName;
                return itemName?.includes(searchValue) || itemDescription?.includes(searchValue);
            });
        }

        let filterListToCheck =
            tab === "recommendations" ||
            tab === "cr-recommendations" ||
            tab === "assets" ||
            tab === "dashboard" ||
            tab === "recommendation" ||
            this.props.match.path === "/initiatives" ||
            this.props.match.path === "/documents" ||
            this.props.match.path === "/dashboard" ||
            isNewTableFilters ||
            this?.props?.isAssignView ||
            this.props.isSmartChartView
                ? commonFilterParams?.[config?.[keyItem]?.searchKey]?.length
                    ? commonFilterParams[config[keyItem].searchKey]
                    : []
                : commonFilterParams?.[config?.[keyItem]?.commonSearchKey]?.[config?.[keyItem]?.commonSearchObjectKey]?.length
                ? commonFilterParams?.[config?.[keyItem]?.commonSearchKey]?.[config?.[keyItem]?.commonSearchObjectKey]
                : [];
        let selectedValues = filterList?.filter(item => filterListToCheck?.includes(item.name)) || [];
        let availableValues = searchedList?.filter(item => !filterListToCheck?.includes(item.name)) || [];
        const isAllSelected = selectedValues?.length > 0 && isEqual(searchedList, selectedValues);
        return (
            <div className="dropdown-menu drop-filtr pos-abs dp-rcm-overflow">
                <div className="col-md-12 p-0 slt">
                    <span className="dropdown-item build_search_drp">
                        <input
                            type="search"
                            placeholder="Search..."
                            value={this.state.valuee}
                            onChange={e => this.handleFilterSearch(e.target.value, keyItem)}
                        />
                        <i class="fas fa-times cursor-pointer cls-close" onClick={() => this.handleFilterSearch("", keyItem)} />
                        <label className="container-check">
                            {`Select All (${selectedValues?.length}/${selectedValues?.length + availableValues?.length}) ${
                                filterCount > 100 ? `Total: ${filterCount}` : ""
                            }`}
                            <input
                                type="checkbox"
                                checked={isAllSelected}
                                onChange={() => this.selectAllHandler(config[keyItem], isAllSelected, availableValues)}
                            />
                            <span className="checkmark"></span>
                            <button
                                className="clear-btn-selection"
                                onClick={e => {
                                    e.preventDefault();
                                    this.clearDropdown(
                                        config[keyItem].commonSearchKey,
                                        config[keyItem].commonSearchObjectKey,
                                        config[keyItem],
                                        config[keyItem].searchKey
                                    );
                                }}
                            >
                                Clear
                            </button>
                        </label>
                    </span>
                </div>
                <>
                    {!isLoadingDrop ? (
                        <>
                            {selectedValues?.length > 0 && (
                                <div className="col-md-12 p-0 slt-ara border-bottom">
                                    {selectedValues?.map((item, i) => (
                                        <span key={i} className="dropdown-item">
                                            <ReactTooltip id="building-dropdown" />
                                            {item.name !== null && item.name !== "" ? (
                                                <FilterItem
                                                    item={item}
                                                    keyItem={keyItem}
                                                    getCapitalTypeData={this.getCapitalTypeData}
                                                    setCommonFilterParams={this.setCommonFilterParams}
                                                    checked={true}
                                                />
                                            ) : null}
                                        </span>
                                    ))}
                                </div>
                            )}
                            <div className="col-md-12 p-0 slt-ara">
                                {availableValues?.length > 0 &&
                                    availableValues?.map((item, i) => (
                                        <span key={i} className="dropdown-item">
                                            <ReactTooltip id="building-dropdown" />
                                            {item.name !== null && item.name !== "" ? (
                                                <FilterItem
                                                    item={item}
                                                    keyItem={keyItem}
                                                    getCapitalTypeData={this.getCapitalTypeData}
                                                    setCommonFilterParams={this.setCommonFilterParams}
                                                    checked={false}
                                                />
                                            ) : null}
                                        </span>
                                    ))}

                                {!availableValues?.length && !selectedValues?.length ? (
                                    <div className="col-md-6 no-wrap">NO DATA</div>
                                ) : (
                                    availableValues?.length === 0 && <div className="col-md-6 no-wrap">NO MORE VALUES TO DISPLAY</div>
                                )}
                                {this.newFilterLogic && availableValues?.length > 100 && (
                                    <div className="col-md-6 no-wrap">Search to display more values</div>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="col-md-12 p-3">
                            <Loader />
                        </div>
                    )}
                </>

                {filterList?.length || selectedValues?.length ? (
                    <div className="col-md-12 mt-3 drp-btn">
                        <button
                            type="button"
                            className="btn btn-primary mr-2"
                            onClick={() => this.updateCommonFilterHandler(config[keyItem].searchKey)}
                        >
                            OK
                        </button>
                        <button type="button" className="btn btn-primary btnClr" onClick={() => this.cancelCommonFilterHandler(keyItem)}>
                            Cancel
                        </button>
                    </div>
                ) : null}
            </div>
        );
    };

    checkHasCommonFilters = (commonSearchKey, commonSearchObjectKey, searchKey) => {
        const {
            match: {
                params: { tab }
            },
            isNewTableFilters = false
        } = this.props;
        const { tableParams = null } = this.props;
        if (tableParams) {
            if (
                tab === "recommendations" ||
                tab === "cr-recommendations" ||
                tab === "assets" ||
                tab === "dashboard" ||
                tab === "recommendation" ||
                this.props.match.path === "/initiatives" ||
                this.props.match.path === "/dashboard" ||
                this.props.match.path === "/documents" ||
                isNewTableFilters ||
                this.props.isSmartChartView ||
                this?.props?.isAssignView
            ) {
                if (tableParams.list && tableParams.list[searchKey] && tableParams.list[searchKey].length) {
                    return true;
                }
            } else {
                if (
                    tableParams.list &&
                    tableParams.list[commonSearchKey] &&
                    tableParams.list[commonSearchKey][commonSearchObjectKey] &&
                    tableParams.list[commonSearchKey][commonSearchObjectKey].length
                ) {
                    return true;
                }
            }
        }
        return false;
    };

    getCapitalTypeData = key => {
        switch (key) {
            case "NI":
                return "Non-Infrastructure";
            case "DM":
                return "Deferred Maintenance";
            case "FC":
                return "Future Capital";
            default:
                return key;
        }
    };

    initiateColumnResize = () => {
        const { updateColumnWidthInConfig } = this.props;
        const tableHeaders = document.querySelectorAll("[class*='resize-col-']");
        const minWidth = 130;
        const maxWidth = 1000;

        tableHeaders.forEach((tableColumn, index) => {
            let columnKey = tableColumn.getAttribute("data-key-item");

            if (tableColumn.querySelector(`.resizer-${columnKey}`)) {
                return;
            }

            let resizer = document.createElement("div");
            tableColumn.classList.add("resizable");
            resizer.className = `resizer-${columnKey} resize-drag`;
            resizer.id = `resizer-${index}`;
            resizer.style.cursor = "ew-resize";
            resizer.style.width = "15px";
            resizer.style.height = "25px";
            resizer.style.position = "absolute";
            resizer.style.top = "18px";
            resizer.style.right = "4px";
            resizer.style.bottom = "0";
            resizer.style.zIndex = "1";
            resizer.style.borderRadius = "5px";
            resizer.style.textAlign = "center";
            resizer.style.display = "flex";
            resizer.style.alignItems = "center";
            resizer.style.justifyContent = "center";
            resizer.style.marginRight = "8px";

            let icon = document.createElement("img");
            icon.src = MoveIcon;
            icon.alt = "Move Icon";

            resizer.appendChild(icon);
            tableColumn.appendChild(resizer);

            let startX, startWidth;

            const initDrag = e => {
                e.preventDefault();
                startX = e.clientX;
                startWidth = parseInt(document.defaultView.getComputedStyle(tableColumn).width, 10);

                document.documentElement.addEventListener("mousemove", doDrag, false);
                document.documentElement.addEventListener("mouseup", stopDrag, false);
            };

            const doDrag = e => {
                this.setState({ isResizing: true });
                let currentWidth = startWidth + e.clientX - startX;
                if (currentWidth < minWidth) {
                    currentWidth = minWidth;
                } else if (currentWidth > maxWidth) {
                    currentWidth = maxWidth;
                }
                tableColumn.style.setProperty("width", `${currentWidth}px`, "important");
                tableColumn.style.setProperty("min-width", `${currentWidth}px`, "important");
                tableColumn.style.setProperty("max-width", `${currentWidth}px`, "important");
            };

            const stopDrag = e => {
                document.documentElement.removeEventListener("mousemove", doDrag, false);
                document.documentElement.removeEventListener("mouseup", stopDrag, false);

                const finalWidth = parseInt(tableColumn.style.width, 10);

                const style = {
                    width: `${finalWidth}px`,
                    minWidth: `${finalWidth}px`,
                    maxWidth: `${finalWidth}px`
                };

                if (updateColumnWidthInConfig) {
                    updateColumnWidthInConfig(columnKey, style);
                    tableColumn.style.removeProperty("width");
                    tableColumn.style.removeProperty("min-width");
                    tableColumn.style.removeProperty("max-width");
                }
                setTimeout(() => {
                    this.setState({ isResizing: false });
                }, 1000);
            };

            resizer.addEventListener("mousedown", initDrag, false);
        });
    };

    render() {
        const {
            tableData: originalTableData,
            updateCurrentViewAllUsers,
            currentViewAllUsers,
            handleDeleteItem,
            showWildCardFilter,
            showEditPage,
            showInfoPage,
            hasInfoPage = true,
            selectedRowId,
            updateSelectedRow,
            updateWildCardFilter,
            wildCardFilter,
            handleHideColumn,
            hasColumnClose = false,
            hasSort = true,
            hasActionColumn = true,
            tableParams = null,
            isBuildingLocked,
            handleCutPaste,
            summaryRowData,
            summaryRowDataEnergy,
            showRestoreModal,
            isImportHistory = false,
            handleDownloadItem,
            hasPadding = false,
            permissions,
            handleSelect,
            isAssignProject,
            hasEdit,
            hasDelete,
            hasAssignToTrade = false,
            hasAssignToSystem = false,
            hasAssignToSubSystem = false,
            handleAssignToTrade,
            handleAssignToSystem,
            handleAssignToSubSystem,
            hasExport,
            isReportTemplate = false,
            handleToggleSlider,
            hasWildCardOptions = true,
            selectedRecomIds,
            handleSelectRecom,
            handleSelectAllRecom,
            handleSpecialReportActions,
            isBudgetPriority,
            isFullscreen,
            tableRef,
            hasAssignToSpecialReport = null,
            hasAssignToReportParagraph = null,
            hasAssignToChildParagraph = null,
            hasMultiAction = false,
            handleMultiSelect,
            everyItemCheckedPerPage,
            priorityElementsData = [],
            hasTableImport,
            handleDownloadItemImport,
            showEditExportPage,
            tableActionClass = "",
            hasTabActive,
            isInputMode,
            handleCellFocus,
            handleCellValueChange,
            handleColumnPin,
            updateColumnWidthInConfig,
            pinnedColumnsRef,
            hasPin,
            lineEditingEnabled,
            isNewTableFilters = false,
            isCRSelection,
            additionalTableClass = "",
            isNewWildCardFilter = false,
            isAssignView = false
        } = this.props;

        // sorting the table keys if any of the column is pinned
        const { keys, config } = originalTableData || {};
        const pinnedKeys = keys.filter(key => config && config[key] && config[key].pinned);
        const unpinnedKeys = keys.filter(key => config && !(config[key] && config[key].pinned));

        const sortedKeys = [...pinnedKeys, ...unpinnedKeys];
        let tableData = { ...originalTableData, keys: sortedKeys };
        let columnCount = 2;
        return (
            <div
                ref={tableRef}
                className={`table-section table-scroll table-gen-overflow ${
                    isBudgetPriority && !isFullscreen ? "overflow-table-hght" : isBudgetPriority && isFullscreen ? "recom-max-view" : ""
                } overflow-hght ${hasPadding ? "table-top-padd" : ""} ${!tableData?.data?.length ? "no-data-table" : ""} ${
                    hasActionColumn && !isBuildingLocked ? "" : "table-no-fixed"
                } ${additionalTableClass}`}
            >
                <table
                    className={`table table-common table-min-height ${hasMultiAction ? "sticky-table-otr" : ""} ${
                        this.props?.lineEditDropdownValue ? "rcm-pjt-height" : ""
                    }`}
                >
                    <thead>
                        <tr ref={this.setWrapperRef}>
                            {this.props.match.params.section === "imageInfo" || hasMultiAction || isCRSelection ? (
                                <th className="img-sq-box seting-type checkbox-container">
                                    <label className="container-checkbox cursor-hand m-0">
                                        <input
                                            type="checkbox"
                                            checked={tableData.data?.length >= 1 && everyItemCheckedPerPage}
                                            onChange={e => handleSelectAllRecom(e.target.checked)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </th>
                            ) : (
                                <th className="img-sq-box seting-type checkbox-container column-sticky-th">
                                    <img alt="" src="/img/sq-box.png" />
                                </th>
                            )}
                            {tableData.keys &&
                                tableData.keys.map((keyItem, i) => {
                                    return tableData.config && tableData.config[keyItem] && tableData.config[keyItem].isVisible ? (
                                        <Th
                                            key={`${keyItem}-${i}`}
                                            data-key-item={keyItem}
                                            className={`resize-col-${keyItem} ${updateColumnWidthInConfig ? "pr-24" : ""} ${
                                                tableData.config[keyItem].class
                                            }${
                                                (tableParams &&
                                                    tableParams.filters &&
                                                    tableParams.filters[tableData.config[keyItem].searchKey] &&
                                                    tableParams.filters[tableData.config[keyItem].searchKey].key) ||
                                                (tableParams &&
                                                    tableParams.filters &&
                                                    (tableParams.filters[tableData.config[keyItem].searchKey]?.filters.includes("not_null") ||
                                                        tableParams.filters[tableData.config[keyItem].searchKey]?.filters.includes("null"))) ||
                                                this.checkHasCommonFilters(
                                                    tableData.config[keyItem].commonSearchKey,
                                                    tableData.config[keyItem].commonSearchObjectKey,
                                                    tableData.config[keyItem].searchKey
                                                )
                                                    ? " bg-th-filtered"
                                                    : ""
                                            } ${tableData.config[keyItem]?.pinned ? "pinned" : ""} cursor-pointer`}
                                            ref={pinnedColumnsRef ? ref => (pinnedColumnsRef[keyItem] = ref) : null}
                                            $thStyle={{ ...tableData.config[keyItem]?.style }}
                                        >
                                            <div
                                                className="table-item-wrapper"
                                                onClick={event => {
                                                    if (!this.state.isResizing && hasSort) {
                                                        this.setSortOrderParams(
                                                            event,
                                                            tableData.config[keyItem].searchKey,
                                                            tableData.config[keyItem].label,
                                                            tableData
                                                        );
                                                    }
                                                }}
                                            >
                                                <EllipsisTooltipText className="text-line-clamp-2" text={tableData.config[keyItem].label} />
                                                <span id={`thIconsContainer_${tableData.config[keyItem].searchKey}`}>
                                                    {hasPin && (
                                                        <button
                                                            type="button"
                                                            className="close"
                                                            onClick={() => {
                                                                ReactTooltip.hide();
                                                                handleColumnPin(keyItem);
                                                            }}
                                                        >
                                                            <span
                                                                className="close-reg cut-close ml-2"
                                                                data-place="top"
                                                                data-effect="solid"
                                                                data-tip={tableData.config[keyItem]?.pinned ? `Unpin Column` : `Pin Column`}
                                                                data-background-color="#007bff"
                                                            >
                                                                <i class="fas fa-thumbtack"></i>
                                                            </span>
                                                        </button>
                                                    )}
                                                    {tableData.config[keyItem].hasCommonSearch ? (
                                                        <>
                                                            <span
                                                                className="close-reg dropdown-toggle"
                                                                onClick={e => {
                                                                    this.showCommonSearchDropDown(keyItem);
                                                                }}
                                                            >
                                                                <i className="fas fa-chevron-down"></i>
                                                            </span>

                                                            {this.renderFiltersForCommonSearch(keyItem)}
                                                        </>
                                                    ) : hasColumnClose ? (
                                                        <button type="button" className="close" onClick={() => handleHideColumn(keyItem)}>
                                                            <span aria-hidden="true">
                                                                <img src="/img/close.svg" alt="" />
                                                            </span>
                                                        </button>
                                                    ) : tableData.config[keyItem].hasCutPaste ? (
                                                        permissions && permissions.edit == false ? (
                                                            ""
                                                        ) : (
                                                            <button type="button" className="close" onClick={() => handleCutPaste(keyItem)}>
                                                                <span
                                                                    aria-hidden="true"
                                                                    className="close-reg cut-close"
                                                                    data-place="top"
                                                                    data-effect="solid"
                                                                    data-tip={`Move Costs to Another Year`}
                                                                    data-background-color="#007bff"
                                                                >
                                                                    <i className="fas fa-cut" />
                                                                </span>
                                                            </button>
                                                        )
                                                    ) : null}

                                                    {tableParams && tableParams.order && tableParams.order[tableData.config[keyItem].searchKey] ? (
                                                        <>
                                                            {tableParams.order[tableData.config[keyItem].searchKey] === "asc" ? (
                                                                <i
                                                                    className={`fas fa-long-arrow-alt-up table-param-rep text-danger`}
                                                                    onClick={event => {
                                                                        if (hasSort) {
                                                                            this.setSortOrderParamsByArrow(
                                                                                event,
                                                                                tableData.config[keyItem].searchKey,
                                                                                tableData.config[keyItem].label,
                                                                                tableData
                                                                            );
                                                                            this.setState({ isResizing: false });
                                                                        }
                                                                    }}
                                                                ></i>
                                                            ) : (
                                                                <i
                                                                    className={`fas fa-long-arrow-alt-down table-param-rep text-danger`}
                                                                    onClick={event =>
                                                                        hasSort
                                                                            ? this.setSortOrderParamsByArrow(
                                                                                  event,
                                                                                  tableData.config[keyItem].searchKey,
                                                                                  tableData.config[keyItem].label,
                                                                                  tableData
                                                                              )
                                                                            : null
                                                                    }
                                                                ></i>
                                                            )}
                                                        </>
                                                    ) : null}
                                                    {tableParams &&
                                                    tableParams.order &&
                                                    tableParams.maintenance_year &&
                                                    tableParams.maintenance_year.length &&
                                                    keyItem.split("_")[1] == tableParams.maintenance_year[0] ? (
                                                        <>
                                                            {tableParams.order["maintenance_years.amount"] === "asc" ? (
                                                                <i
                                                                    className={`fas fa-long-arrow-alt-up table-param-rep text-danger`}
                                                                    onClick={event =>
                                                                        hasSort
                                                                            ? this.setSortOrderParamsByArrow(
                                                                                  event,
                                                                                  tableData.config[keyItem].searchKey,
                                                                                  tableData.config[keyItem].label,
                                                                                  tableData
                                                                              )
                                                                            : null
                                                                    }
                                                                ></i>
                                                            ) : tableParams.order["maintenance_years.amount"] === "desc" ? (
                                                                <i
                                                                    className={`fas fa-long-arrow-alt-down table-param-rep text-danger`}
                                                                    onClick={event =>
                                                                        hasSort
                                                                            ? this.setSortOrderParamsByArrow(
                                                                                  event,
                                                                                  tableData.config[keyItem].searchKey,
                                                                                  tableData.config[keyItem].label,
                                                                                  tableData
                                                                              )
                                                                            : null
                                                                    }
                                                                ></i>
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                    {tableParams &&
                                                    tableParams.order &&
                                                    tableParams.index &&
                                                    tableParams.index.length &&
                                                    keyItem.split("t")[2] == tableParams.index[0] ? (
                                                        <>
                                                            {tableParams.order["options.name"] === "asc" ? (
                                                                <i
                                                                    className={`fas fa-long-arrow-alt-up table-param-rep text-danger`}
                                                                    onClick={event =>
                                                                        hasSort
                                                                            ? this.setSortOrderParamsByArrow(
                                                                                  event,
                                                                                  tableData.config[keyItem].searchKey,
                                                                                  tableData.config[keyItem].label,
                                                                                  tableData
                                                                              )
                                                                            : null
                                                                    }
                                                                ></i>
                                                            ) : tableParams.order["options.name"] === "desc" ? (
                                                                <i
                                                                    className={`fas fa-long-arrow-alt-down table-param-rep text-danger`}
                                                                    onClick={event =>
                                                                        hasSort
                                                                            ? this.setSortOrderParamsByArrow(
                                                                                  event,
                                                                                  tableData.config[keyItem].searchKey,
                                                                                  tableData.config[keyItem].label,
                                                                                  tableData
                                                                              )
                                                                            : null
                                                                    }
                                                                ></i>
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                </span>
                                            </div>
                                        </Th>
                                    ) : null;
                                })}
                            {hasActionColumn && !isBuildingLocked && !isAssignProject ? (
                                <th
                                    className={`action ${
                                        isReportTemplate
                                            ? "w-action"
                                            : isImportHistory || hasMultiAction || isNewTableFilters
                                            ? "imp-history-action"
                                            : handleSpecialReportActions ||
                                              (hasAssignToSpecialReport && hasAssignToReportParagraph && hasAssignToChildParagraph)
                                            ? "wide-action"
                                            : isBudgetPriority
                                            ? "action-wide"
                                            : tableActionClass
                                    }`}
                                >
                                    Action
                                </th>
                            ) : null}
                        </tr>
                    </thead>
                    <tbody id="common-table-body">
                        {showWildCardFilter ? (
                            !isNewWildCardFilter ? (
                                <WildCardFilter
                                    updateWildCardFilter={updateWildCardFilter}
                                    wildCardFilter={wildCardFilter}
                                    hasWildCardOptions={hasWildCardOptions}
                                    hasActionColumn={hasActionColumn}
                                    keys={tableData.keys}
                                    config={tableData.config}
                                />
                            ) : (
                                <NewWildCardFilter
                                    updateWildCardFilter={updateWildCardFilter}
                                    wildCardFilter={wildCardFilter}
                                    hasWildCardOptions={hasWildCardOptions}
                                    hasActionColumn={hasActionColumn}
                                    keys={tableData.keys}
                                    config={tableData.config}
                                    isNewTableFilters={isNewTableFilters}
                                />
                            )
                        ) : null}
                        {tableData.data && tableData.data.length ? (
                            <>
                                {tableData.data.map((dataItem, i) => (
                                    <Row
                                        key={dataItem?.id}
                                        keys={tableData.keys}
                                        config={tableData.config}
                                        rowData={dataItem}
                                        rowIndex={i}
                                        updateCurrentViewAllUsers={updateCurrentViewAllUsers}
                                        currentViewAllUsers={currentViewAllUsers}
                                        handleDeleteItem={handleDeleteItem}
                                        showEditPage={showEditPage}
                                        showInfoPage={showInfoPage}
                                        hasInfoPage={isAssignProject ? false : hasInfoPage}
                                        updateSelectedRow={updateSelectedRow}
                                        selectedRowId={selectedRowId || null}
                                        hasActionColumn={isAssignProject ? false : hasActionColumn}
                                        tableParams={tableParams}
                                        isBuildingLocked={isBuildingLocked}
                                        showRestoreModal={showRestoreModal}
                                        isImportHistory={isImportHistory}
                                        handleDownloadItem={handleDownloadItem}
                                        permissions={permissions}
                                        handleSelect={this.handleSelectRow}
                                        isAssignProject={isAssignProject}
                                        recomentationIds={
                                            this.props.match.params.tab === "recommendations" || this.props.match.params.tab === "cr-recommendations"
                                                ? this.state.idArray
                                                : this.props.recomentationIds
                                        }
                                        selectedAllClicked={this.props.selectedAllClicked}
                                        hasEdit={hasEdit}
                                        hasDelete={hasDelete}
                                        hasAssignToTrade={hasAssignToTrade}
                                        hasAssignToSystem={hasAssignToSystem}
                                        hasAssignToSubSystem={hasAssignToSubSystem}
                                        handleAssignToTrade={handleAssignToTrade}
                                        handleAssignToSystem={handleAssignToSystem}
                                        handleAssignToSubSystem={handleAssignToSubSystem}
                                        isReportTemplate={isReportTemplate}
                                        handleToggleSlider={handleToggleSlider}
                                        hasExport={hasExport}
                                        handleSelectRecom={handleSelectRecom}
                                        selectedRecomIds={selectedRecomIds}
                                        handleSpecialReportActions={handleSpecialReportActions}
                                        isAssignAsset={this.props.isAssignAsset}
                                        handleSelectAsset={this.props.handleSelectAsset}
                                        selectedAsset={this.props.selectedAsset}
                                        hasAssignToSpecialReport={hasAssignToSpecialReport}
                                        hasAssignToReportParagraph={hasAssignToReportParagraph}
                                        hasAssignToChildParagraph={hasAssignToChildParagraph}
                                        hasMultiAction={hasMultiAction}
                                        handleMultiSelect={handleMultiSelect}
                                        priorityElementsData={priorityElementsData}
                                        isBudgetPriority={isBudgetPriority}
                                        hasViewIcon={this.props.hasViewIcon}
                                        hasTabActive={hasTabActive}
                                        isInputMode={isInputMode}
                                        handleCellFocus={handleCellFocus}
                                        handleCellValueChange={handleCellValueChange}
                                        lineEditingEnabled={lineEditingEnabled}
                                        isCRSelection={isCRSelection}
                                        isShiftHistory={this.props.isShiftHistory}
                                        getListForCommonFilter={this.props.getListForCommonFilter}
                                        lineEditDropdownValue={this.props?.lineEditDropdownValue}
                                        isDropdownLoading={this.props?.isDropdownLoading}
                                    />
                                ))}
                                <SummaryRow
                                    keys={tableData.keys}
                                    config={tableData.config}
                                    tableData={tableData}
                                    tableParams={tableParams}
                                    summaryRowData={summaryRowData}
                                />
                                <SummaryRowEnergy
                                    keys={tableData.keys}
                                    config={tableData.config}
                                    tableData={tableData}
                                    tableParams={tableParams}
                                    summaryRowData={summaryRowDataEnergy}
                                />
                            </>
                        ) : (
                            <tr className="text-center">
                                {tableData.keys.map(keyItem => {
                                    if (tableData.config && tableData.config[keyItem]?.isVisible) {
                                        columnCount += 1;
                                    }
                                })}
                                <td className="noRecordsColumn" colSpan={columnCount}>
                                    No records found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export const FilterItem = ({ item, keyItem, setCommonFilterParams, getCapitalTypeData, checked }) => {
    return (
        <label className="container-check d-flex align-items-center">
            <span
                className="text-short"
                data-tip={
                    item.name?.length + item?.description?.length > 25
                        ? `${keyItem === "capital_type" ? getCapitalTypeData(item.name) : item.name} ${
                              item.description ? `(${item.description})` : ""
                          }`
                        : null
                }
                data-effect="solid"
                data-for="building-dropdown"
                data-place="left"
                data-background-color="#007bff"
                data-delay-show="500"
            >
                {keyItem === "capital_type"
                    ? getCapitalTypeData(item.name)
                    : item.name === true || item.name === "yes"
                    ? "Yes"
                    : item.name === false || item.name === "no"
                    ? "No"
                    : item.name}{" "}
                {item.description ? `(${item.description})` : ""}
            </span>
            <span className="count-num"> ({item.count})</span>
            <input
                type="checkbox"
                checked={checked}
                onClick={e => {
                    setCommonFilterParams(keyItem, item);
                }}
            />
            <span className="checkmark"></span>
        </label>
    );
};

export default withRouter(Table);
